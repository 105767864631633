import React, { Component } from "react";
import axios from 'axios';

import Dropdown from 'antd/es/dropdown';
import Icon from 'antd/es/icon';
import Menu from 'antd/es/menu';
import Input from 'antd/es/input';
import message from 'antd/es/message';

// import { Dropdown, Icon, Menu, Input, message } from 'antd';
import '../../../styles/icon_image.css';
import '../../../styles/Background/ego_manager.css';
import '../../../styles/Background/UI_Module/ads_management.css';
import { UserActiveUpdate } from "../../../functions/DataStatistic.js"

const g_strMessageKey = 'updating';

class AdsManagement extends Component {
    constructor(props) {
        super();
        this.state = {
            arrCurrentAds: [0, 0, 0, 0, 0, 0]
        };
        this.arrAdsTypeNum = [10, 10, 10, 10, 10, 10];
        this.arrAdsPos = [];
        for (let i = 0; i < this.arrAdsTypeNum.length; i++) {
            let arrAdsPos = [];
            for (let j = 0; j < this.arrAdsTypeNum[i]; j++) {
                let strAdsPos = "广告位 " + (j + 1).toString();
                arrAdsPos.push(strAdsPos);
            }
            this.arrAdsPos.push(arrAdsPos);
        }
        // 数据存储用
        this.arrAdsImageData = [];
        for (let i = 0; i < this.arrAdsTypeNum.length; i++) {
            for (let j = 0; j < this.arrAdsTypeNum[i]; j++) {
                let objAdsRecord = { ads_type: i, ads_order: j, store_id: i + j };
                this.arrAdsImageData.push(objAdsRecord);
            }
        }
        this.iCurrentIndex = 0;
    }
    // 广告位下拉菜单响应
    AdsMenuClick = (iMenu, iAds) => {
        let arrNewCurrentAds = this.state.arrCurrentAds;
        arrNewCurrentAds[iMenu] = iAds;
        let iAdsIndex = iMenu * 10 + iAds;
        let strInputKey = "ads_input_" + iMenu.toString();
        this.refs[strInputKey].state.value = this.arrAdsImageData[iAdsIndex].store_id;
        this.setState({
            arrCurrentAds: arrNewCurrentAds
        });
    }
    // 提交商家ID
    SubmitStoreID = (iMenu) => {
        let iAds = this.state.arrCurrentAds[iMenu];
        let iAdsIndex = iMenu * 10 + iAds;
        let strInputKey = "ads_input_" + iMenu.toString();
        let iStoreID = parseInt(this.refs[strInputKey].state.value, 10);
        this.arrAdsImageData[iAdsIndex].store_id = iStoreID;
        if (iStoreID <= 0) {
            message.warning('商家 id 不能 ≤ 0， 重置请填 1 ！');
            return;
        }
        axios.post('/UpdateAdsInfo', { ads_type: iMenu, ads_order: iAds, store_id: iStoreID })
            .then((response) => {
                if (response.data.name === "error")
                    message.error('未更新成功，商家 id 不存在，请检查 ！');
                else {
                    message.success('广告位信息更新成功！');
                    // DataStatistic
                    UserActiveUpdate(3, {
                        active: "管理后台: 广告管理操作",
                        ads_type: iMenu, ads_order: iAds, store_id: iStoreID
                    });
                }
            });
    }
    // 获取广告位对应商家ID
    GetAdsImageInfo = () => {
        message.loading({ content: '数据加载中……', key: g_strMessageKey });
        axios('/GetAdsInfo')
            .then(({ data }) => {
                message.success({ content: "数据加载完成！", key: g_strMessageKey, duration: 2 });
                this.arrAdsImageData = data;
                for (let i = 0; i < this.state.arrCurrentAds.length; i++) {
                    let strInputKey = "ads_input_" + i.toString();
                    if (this.refs[strInputKey])
                        if (this.refs[strInputKey].state)
                            this.refs[strInputKey].state.value = this.arrAdsImageData[i * 10].store_id;
                }
                this.setState({
                    ...this.state,
                    arrCurrentAds: [0, 0, 0, 0, 0, 0]
                });
            });
    }
    // 卸载异步操作设置状态
    componentWillUnmount() {
        clearTimeout(this.timeouter)
        this.setState = (state, callback) => {
            return;
        }
    }
    // 渲染完毕……
    componentDidMount() {
        this.GetAdsImageInfo();
    }

    render() {

        let arrMenu = [];
        for (let i = 0; i < this.arrAdsPos.length; i++) {
            const uiMenu = (
                <Menu >
                    {this.arrAdsPos[i].map((item, index) => {
                        return (
                            <Menu.Item key={index} onClick={this.AdsMenuClick.bind(this, i, index)}> {item} </Menu.Item>
                        );
                    })}
                </Menu>
            );
            arrMenu.push(uiMenu);
        }

        let arrDropDown = [];
        for (let i = 0; i < this.arrAdsPos.length; i++) {
            const uiAdsDropDown = (
                <Dropdown className="ads_manager_filter"
                    overlay={arrMenu[i]} trigger={['click']} >
                    <div>{this.arrAdsPos[i][this.state.arrCurrentAds[i]]}
                        <Icon type="caret-down" /></div>
                </Dropdown >
            );
            arrDropDown.push(uiAdsDropDown);
        }

        let uiCategoryAdsSettingArea = [];
        for (let i = 0; i < 4; i++) {
            let strTitle = "主分类 " + (i + 1).toString() + " 的<Mouthing优惠>位 (最多10个，可滑动区域)";
            let iTypeIndex = 2 + i;
            uiCategoryAdsSettingArea.push(
                <div key={i}>
                    <div className="ads_type_title">{strTitle}</div>
                    <div className="ads_type_show_area">
                        <div className="ads_type_img2" />
                        <div className="ads_type_img2" />
                        {arrDropDown[iTypeIndex]}
                        <Input className="ads_manager_input" placeholder="商家ID"
                            ref={"ads_input_" + iTypeIndex} defaultValue={this.arrAdsImageData[this.state.arrCurrentAds[iTypeIndex]].store_id} />
                        <div className="ads_manager_btn" onClick={this.SubmitStoreID.bind(this, iTypeIndex)}>       提　交
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className="manage_contents manage_contents_less_top">
                <div className="ads_management_page">
                    <div className="ads_type_title">启动广告图 (最多10个，随机展示)</div>
                    <div className="ads_type_show_area">
                        <div className="ads_type_img0" />
                        {arrDropDown[0]}
                        <Input className="ads_manager_input" placeholder="商家ID" ref="ads_input_0"
                            defaultValue={this.arrAdsImageData[this.state.arrCurrentAds[0]].store_id} />
                        <div className="ads_manager_btn" onClick={this.SubmitStoreID.bind(this, 0)}>提　交</div>
                    </div>
                    <div className="ads_type_title">首页头图 (最多10个, 轮播)</div>
                    <div className="ads_type_show_area">
                        <div className="ads_type_img1" />
                        {arrDropDown[1]}
                        <Input className="ads_manager_input" placeholder="商家ID" ref="ads_input_1"
                            defaultValue={this.arrAdsImageData[this.state.arrCurrentAds[1]].store_id} />
                        <div className="ads_manager_btn" onClick={this.SubmitStoreID.bind(this, 1)}>提　交</div>
                    </div>
                    {/* <div className="ads_type_title">首页中间条幅 (6个, 轮播)</div>
                    <div className="ads_type_show_area">
                        <div className="ads_type_img2" />
                        {arrDropDown[2]}
                        <Input className="ads_manager_input" placeholder="商家ID" ref="ads_input_2"
                            defaultValue={this.arrAdsImageData[this.state.arrCurrentAds[2]].store_id} />
                        <div className="ads_manager_btn" onClick={this.SubmitStoreID.bind(this, 2)}>提　交</div>
                    </div> */}
                    {uiCategoryAdsSettingArea}
                </div>
            </div >
        );
    }
}
export default AdsManagement;
