import React, { Component } from 'react';
import axios from 'axios';
import XLSX from 'xlsx';
import Clipboard from 'react-clipboard.js';

import Spin from 'antd/es/spin';
import Table from 'antd/es/table';
import Button from 'antd/es/button';
import Input from 'antd/es/input';
import Icon from 'antd/es/icon';
import Modal from 'antd/es/modal';
import Menu from 'antd/es/menu';
import Dropdown from 'antd/es/dropdown';
import Checkbox from 'antd/es/checkbox';
import message from 'antd/es/message';

import '../../../styles/Background/ego_manager.css';
import '../../../styles/Background/UI_Module/user_management.css';
import '../../../styles/Background/UI_Module/express_enter.css';
import { UserActiveUpdate } from "../../../functions/DataStatistic.js";

const g_strMessageKey = 'updating';

// 表格
const arrTableHeader = [
    { title: '合箱编码', dataIndex: 'package_no', width: 125, ellipsis: true },
    { title: '合箱状态', dataIndex: 'status_check', width: 125, ellipsis: true },
    { title: '货运方式', dataIndex: 'transit_way_str', width: 100, ellipsis: true },
    { title: '货物品类', dataIndex: 'package_type_str', width: 100, ellipsis: true },
    { title: '计费重量', dataIndex: 'price_weight_str', width: 125, ellipsis: true },
    { title: '合箱体积', dataIndex: 'total_value_str', width: 125, ellipsis: true },
    { title: '合箱费用', dataIndex: 'total_price_str', width: 125, ellipsis: true },
    { title: '备注', dataIndex: 'comments', width: 150, ellipsis: true }
];
const arrTableHeader_m = [
    { title: '合箱编码', dataIndex: 'package_no', width: 125 },
    { title: '合箱状态', dataIndex: 'status_check', width: 100 },
    { title: '货运方式', dataIndex: 'transit_way_str', width: 100 },
    { title: '货物品类', dataIndex: 'package_type_str', width: 100 },
    { title: '总重量', dataIndex: 'price_weight_str', width: 125 },
    { title: '总费用', dataIndex: 'total_price_str', width: 125 },
    { title: '总货值', dataIndex: 'total_value_str', width: 125 }
];

// csv转sheet对象
// function csv2sheet(csv) {
//     var sheet = {}; // 将要生成的sheet
//     csv = csv.split('\n');
//     csv.forEach(function (row, i) {
//         row = row.split(',');
//         if (i == 0) sheet['!ref'] = 'A1:' + String.fromCharCode(65 + row.length - 1) + (csv.length - 1);
//         row.forEach(function (col, j) {
//             sheet[String.fromCharCode(65 + j) + (i + 1)] = { v: col };
//         });
//     });
//     return sheet;
// }
// 将一个sheet转成最终的excel文件的blob对象，然后利用URL.createObjectURL下载
function sheet2blob(sheet, sheetName) {
    sheetName = sheetName || 'sheet1';
    var workbook = {
        SheetNames: [sheetName],
        Sheets: {}
    };
    workbook.Sheets[sheetName] = sheet;
    // 生成excel的配置项
    var wopts = {
        bookType: 'xlsx', // 要生成的文件类型
        bookSST: false, // 是否生成Shared String Table，官方解释是，如果开启生成速度会下降，但在低版本IOS设备上有更好的兼容性
        type: 'binary'
    };
    var wbout = XLSX.write(workbook, wopts);
    var blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });
    // 字符串转ArrayBuffer
    function s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
    }
    return blob;
}
//  通用的打开下载对话框方法，没有测试过具体兼容性
//  @param url 下载地址，也可以是一个blob对象，必选
//  @param saveName 保存文件名，可选
function openDownloadDialog(url, saveName) {
    if (typeof url == 'object' && url instanceof Blob) {
        url = URL.createObjectURL(url); // 创建blob地址
    }
    var aLink = document.createElement('a');
    aLink.href = url;
    aLink.download = saveName || ''; // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，file:///模式下不会生效
    var event;
    if (window.MouseEvent) event = new MouseEvent('click');
    else {
        event = document.createEvent('MouseEvents');
        event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
    }
    aLink.dispatchEvent(event);
}
// 传入csv，执行后就会弹出下载框
// function exportExcel(csv) {
//     var sheet = csv2sheet(csv);
//     var blob = sheet2blob(sheet);
//     openDownloadDialog(blob, '导出.xlsx');
// }

class PackageCombine extends Component {
    constructor(props) {
        super();

        this.strToday = new Date();

        this.state = {
            btnControlDisable: [false, true, true, true],  // 操作按钮控制
            selectedRowKeys: [],  // Check here to configure the default column
            iDlgType: -1,
            bShowDlg: false,
            bLoading: true,
            iStatus: 0,
            bItemValueChange: false,
            iTransitType: 0,
            iPackageType: 0,
            iShipDate: 0,
            iStatusFilter: 0,
            bViewQRCodeDlg: false,
            bPackageInfoChange: false,
            bViewItemInfoDlg: false
        };
        // 成员变量
        this.arrTableData = [];  // 用于显示的数组
        this.arrTableDataOge = [];  // 原始合箱数据
        this.objCurrentPackageCombine = {};
        this.arrCurrentExpressInfo = [];
        this.tableHead = arrTableHeader;
        if (props.bMStation)
            this.tableHead = arrTableHeader_m;

        this.arrExpressTypeFilter = ["全部", "其他", "EMS", "顺丰", "圆通", "中通", "申通", "天天", "韵达", "百世", "DHL", "UPS", "德邦"];
        this.iExpressTypeFilter = 0;
        this.arrExpressType = ["其他", "EMS", "顺丰", "圆通", "中通", "申通", "天天", "韵达", "百世", "DHL", "UPS", "德邦"];

        this.strYear = this.strToday.toISOString().split('-')[0];
        this.strMonth = this.strToday.toISOString().split('-')[1];
        this.strDay = this.strToday.toISOString().split('-')[2].split('T')[0];

        this.strKeyWord = "";
        this.arrTransitType = ["海运", "空运", "小包裹"];
        this.arrPackageType = ["普货", "食品", "敏感"];
        this.arrShipDate = ["月中(15号)", "月底(30号)"];
        this.fCurrentTotalWeight = 0.0;
        this.fCurrentTotalPrice = 0.0;
        this.fCurrentTotalValue = 0.0;

        this.arrStatusFilter = ["全部", "合箱中", "合箱完成"];
        this.bViewQRCodeDlg = false;

        this.arrCurrentItemInfo = [];
        this.arrSelectedPackageCombine = [];
        this.arrSelectedPackageCombineItemInfo = [];
    }

    // 选中某行的响应函数
    selectRow = (record) => {
        const selectedRowKeys = [...this.state.selectedRowKeys];
        if (selectedRowKeys.indexOf(record.key) >= 0) {
            selectedRowKeys.splice(selectedRowKeys.indexOf(record.key), 1);
        } else {
            selectedRowKeys.push(record.key);
        }
        this.SelectedRowStateSetting(selectedRowKeys, false);
    }
    // 双击某行的响应函数
    DoubleClickRow = (record) => {
        const selectedRowKeys = [record.key];
        this.SelectedRowStateSetting(selectedRowKeys, true);
    }
    // 选中的行发生变化的响应函数
    onSelectedRowKeysChange = (selectedRowKeys) => {
        this.SelectedRowStateSetting(selectedRowKeys, false);
    }
    // 处理选中的表行行
    SelectedRowStateSetting = (selectedRowKeys, bDoubleClick) => {
        let btnControlDisable = [];
        this.arrSelectedPackageCombine = [];
        switch (selectedRowKeys.length) {
            case 0: {
                btnControlDisable = [false, true, true, true];
                this.objCurrentPackageCombine = {};
                break;
            }
            case 1: {
                btnControlDisable = [false, false, false, false];
                for (let i = 0; i < this.arrTableData.length; i++) {
                    if (this.arrTableData[i].key === selectedRowKeys[0]) {
                        this.objCurrentPackageCombine = Object.assign({}, this.arrTableData[i]);
                        console.log("SelectedRowStateSetting: ", this.objCurrentPackageCombine.express_info, this.objCurrentPackageCombine.store_id);
                        this.arrSelectedPackageCombine.push(this.arrTableData[i]);
                        break;
                    }
                }
                break;
            }
            default: {
                btnControlDisable = [false, true, true, false];
                for (let j = 0; j < selectedRowKeys.length; j++) {
                    for (let i = 0; i < this.arrTableData.length; i++) {
                        if (this.arrTableData[i].key === selectedRowKeys[j]) {
                            this.arrSelectedPackageCombine.push(this.arrTableData[i]);
                            break;
                        }
                    }
                }
                break;
            }
        }
        this.setState({
            ...this.state,
            selectedRowKeys,
            btnControlDisable: btnControlDisable
        });
        if (bDoubleClick)
            this.ShowDlg(1);
    }
    //弹出对话框
    ShowDlg = (iDlgType, e) => {
        if (iDlgType === 0) {
            if (this.refs.package_no) {
                this.refs.package_no.state.value = "";
                this.refs.comments.state.value = "";
                this.refs.total_price.state.value = 0.0;
            }
            this.arrCurrentExpressInfo = [];
            this.objCurrentPackageCombine = {
                package_no: '', package_type: 0, transit_way: 0, total_price: 0.0, total_value: 0.0, total_weight: 0.0, status: 0, comments: '',
                value_weight: 0.0, length: 0.0, width: 0.0, height: 0.0, price_weight: 0.0, ship_date: 0
            };
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true,
                iStatus: 0
            });
        }
        if (iDlgType === 1) {
            if (this.refs.package_no) {
                this.refs.package_no.state.value = this.objCurrentPackageCombine.package_no;
                this.refs.comments.state.value = this.objCurrentPackageCombine.comments;
                this.refs.total_price.state.value = this.objCurrentPackageCombine.total_price;
            }

            console.log("Show Dlg 1: ", this.objCurrentPackageCombine.express_info);

            axios.post('/UpdatePackageCombineExpressInfo', {
                objPackageCombine: this.objCurrentPackageCombine
            }).then(({ data }) => {
                console.log("UpdatePackageCombineExpressInfo : ", data);
                this.objCurrentPackageCombine.express_info = data;
                this.arrCurrentExpressInfo = [];
                if (this.objCurrentPackageCombine.express_info.length > 0) {
                    for (let i = 0; i < this.objCurrentPackageCombine.express_info.length; i++) {
                        this.arrCurrentExpressInfo.push(this.objCurrentPackageCombine.express_info[i]);
                    }
                }
                console.log("CurrentExpressInfo : ", this.arrCurrentExpressInfo);
                if (this.objCurrentPackageCombine.express_info === null || this.objCurrentPackageCombine.express_info === "")
                    this.arrCurrentExpressInfo = [];

                if (this.objCurrentPackageCombine.ship_date !== 0 && this.objCurrentPackageCombine.ship_date !== 1)
                    this.objCurrentPackageCombine.ship_date = 0;
                this.setState({
                    ...this.state,
                    iDlgType: iDlgType,
                    bShowDlg: true,
                    iStatus: this.objCurrentPackageCombine.status,
                    iTransitType: this.objCurrentPackageCombine.transit_way,
                    iShipDate: this.objCurrentPackageCombine.ship_date,
                    iPackageType: this.objCurrentPackageCombine.package_type,
                });
            }).catch(function (error) { console.log(error); });
        }
        if (iDlgType === 2) {
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true
            });
        }
    }
    // 获取新输入的信息
    GetNewInputInfo = () => {
        let strPackageNo = this.refs.package_no.state.value;
        let strTotalPrice = this.refs.total_price.state.value;
        if (strPackageNo === null || strPackageNo.trim() === "") {
            message.warning("合箱号不能为空！"); return -1;
        }
        if (this.arrCurrentExpressInfo.length <= 0) {
            this.arrCurrentExpressInfo = [];
        }
        this.objCurrentPackageCombine.package_no = strPackageNo;
        this.objCurrentPackageCombine.status = this.state.iStatus;
        this.objCurrentPackageCombine.transit_way = this.state.iTransitType;
        this.objCurrentPackageCombine.ship_date = this.state.iShipDate;
        this.objCurrentPackageCombine.package_type = this.state.iPackageType;

        this.objCurrentPackageCombine.total_price = strTotalPrice;
        this.objCurrentPackageCombine.comments = this.refs.comments.state.value;
        return 1;

    }
    // 弹窗确认按钮响应
    handleOk = e => {

        if (this.props.iUserLevel <= 5) {
            message.warning("权限不够，只能查看，无法编辑！");
            return;
        }

        this.objCurrentPackageCombine.store_id = this.props.iStoreID;
        delete this.objCurrentPackageCombine.key;
        delete this.objCurrentPackageCombine.package_no_qr;
        delete this.objCurrentPackageCombine.status_check;
        delete this.objCurrentPackageCombine.package_type_str;
        delete this.objCurrentPackageCombine.transit_way_str;
        delete this.objCurrentPackageCombine.total_price_str;
        delete this.objCurrentPackageCombine.total_value_str;
        delete this.objCurrentPackageCombine.price_weight_str;
        if (this.state.iDlgType === 0) {
            delete this.objCurrentPackageCombine.id;
            if (this.GetNewInputInfo() === -1)
                return;
            message.loading({ content: '正在新增合箱信息……', key: g_strMessageKey });
            if (this.arrCurrentExpressInfo.length > 0) {
                let arrExpressOrder = [];
                for (let i = 0; i < this.arrCurrentExpressInfo.length; i++) {
                    arrExpressOrder.push(this.arrCurrentExpressInfo[i].express_order);
                }
                axios('/GetPackageUserCode', { params: { arrExpressOrder: arrExpressOrder, store_id: this.props.iStoreID } })
                    .then(({ data }) => {
                        this.arrCurrentExpressInfo = data;
                        this.objCurrentPackageCombine.express_info = JSON.stringify(this.arrCurrentExpressInfo);
                        axios.post('/NewPackageCombine', {
                            objPackageCombine: this.objCurrentPackageCombine
                        }).then(({ data }) => {
                            if (data.status === 1) {
                                this.arrCurrentExpressInfo = [];
                                // DataStatistic
                                UserActiveUpdate(4, { active: "商家后台: 合箱信息管理操作", action: "新建" });
                                this.GetPackageCombineData();
                            } else {
                                message.warning({ content: "合箱编码已存在！请重新输入", key: g_strMessageKey, duration: 2 });
                            }
                        }).catch(function (error) { console.log(error); });
                    }).catch(function (error) { console.log(error); });
            } else {
                this.objCurrentPackageCombine.express_info = JSON.stringify(this.arrCurrentExpressInfo);
                axios.post('/NewPackageCombine', {
                    objPackageCombine: this.objCurrentPackageCombine
                }).then(({ data }) => {
                    if (data.status === 1) {
                        this.arrCurrentExpressInfo = [];
                        // DataStatistic
                        UserActiveUpdate(4, { active: "商家后台: 合箱信息管理操作", action: "新建" });
                        this.GetPackageCombineData();
                    } else {
                        message.warning({ content: "合箱编码已存在！请重新输入", key: g_strMessageKey, duration: 2 });
                    }
                }).catch(function (error) { console.log(error); });
            }
        }
        if (this.state.iDlgType === 1) {
            if (this.GetNewInputInfo() === -1)
                return;
            message.loading({ content: '正在更新合箱信息……', key: g_strMessageKey });
            if (this.arrCurrentExpressInfo.length > 0) {
                let arrExpressOrder = [];
                for (let i = 0; i < this.arrCurrentExpressInfo.length; i++) {
                    arrExpressOrder.push(this.arrCurrentExpressInfo[i].express_order);
                }
                axios('/GetPackageUserCode', { params: { arrExpressOrder: arrExpressOrder, store_id: this.props.iStoreID } })
                    .then(({ data }) => {
                        this.arrCurrentExpressInfo = data;
                        this.objCurrentPackageCombine.express_info = JSON.stringify(this.arrCurrentExpressInfo);
                        axios.post('/UpdatePackageCombine', {
                            objPackageCombine: this.objCurrentPackageCombine
                        }).then(({ data }) => {
                            this.GetPackageCombineData();
                            // DataStatistic
                            UserActiveUpdate(4, { active: "商家后台:合箱记录管理操作", action: "编辑" });
                        }).catch(function (error) { console.log(error); });
                    }).catch(function (error) { console.log(error); });
            } else {
                this.objCurrentPackageCombine.express_info = JSON.stringify(this.arrCurrentExpressInfo);
                axios.post('/UpdatePackageCombine', {
                    objPackageCombine: this.objCurrentPackageCombine
                }).then(({ data }) => {
                    this.GetPackageCombineData();
                    // DataStatistic
                    UserActiveUpdate(4, { active: "商家后台:合箱记录管理操作", action: "编辑" });

                }).catch(function (error) { console.log(error); });
            }
        }
        if (this.state.iDlgType === 2) {
            message.loading({ content: '合箱记录删除中……', key: g_strMessageKey });
            axios.post('/DeletePackageCombine', { id: this.objCurrentPackageCombine.id }).then(
                ({ data }) => {
                    // DataStatistic
                    UserActiveUpdate(4, { active: "管理后台: 用户管理操作", action: "删除" });
                    this.GetPackageCombineData();
                }).catch(function (error) { console.log(error); });
        }
    };
    // 弹窗取消按钮响应
    handleCancel = e => {
        this.objCurrentPackageCombine = {};
        this.arrCurrentExpressInfo = [];
        this.setState({
            bShowDlg: false,
            selectedRowKeys: [],
            btnControlDisable: [false, true, true, true]
        });
    };
    // 查看合箱二维码
    ViewQRCode = (index, bShow) => {
        if (index >= 0)
            console.log("View QRCode : ", this.arrTableData[index].package_no, bShow);
        this.bViewQRCodeDlg = bShow;
        this.setState({
            ...this.state,
            bViewQRCodeDlg: bShow
        });
    }
    // 从数据库获取当前的数据结构
    GetPackageCombineData() {
        message.loading({ content: '数据加载中……', key: g_strMessageKey });
        axios('/GetPackageCombine', {
            params: {
                search: this.strKeyWord,
                store_id: this.props.iStoreID
            }
        }).then(({ data }) => {
            this.arrTableData = data;
            this.arrTableDataOge = data;
            if (this.arrTableData.length > 0) {
                for (let i = 0; i < this.arrTableData.length; i++) {
                    this.arrTableData[i].key = i;
                    this.arrTableData[i].package_no_qr = (
                        <div className="package_combine_view_qrcode" style={{ color: 'darkcyan' }}
                            onClick={this.ViewQRCode.bind(this, i, true)}>查看</div>
                    );
                    if (this.arrTableData[i].express_info === null || this.arrTableData[i].express_info === "")
                        this.arrTableData[i].express_info = [];
                    else {
                        if (typeof (this.arrTableData[i].express_info) === 'string')
                            this.arrTableData[i].express_info = JSON.parse(this.arrTableData[i].express_info);
                    }

                    this.arrTableData[i].transit_way_str = this.arrTransitType[this.arrTableData[i].transit_way];
                    this.arrTableData[i].package_type_str = this.arrPackageType[this.arrTableData[i].package_type];
                    this.arrTableData[i].total_price_str = "$ " + parseFloat(this.arrTableData[i].total_price).toFixed(2);
                    this.arrTableData[i].total_value_str = parseFloat(this.arrTableData[i].total_value).toFixed(2) + " cm3";
                    this.arrTableData[i].price_weight_str = parseFloat(this.arrTableData[i].price_weight).toFixed(2) + " kg";
                    this.arrTableData[i].status_check = (
                        <Checkbox className="express_enter_checkbox" checked={true} disabled={true} >合箱完成</Checkbox>
                    );
                    if (this.arrTableData[i].status === 0)
                        this.arrTableData[i].status_check = (
                            <Checkbox className="express_enter_checkbox" checked={false} disabled={true} >合箱中</Checkbox>
                        );
                }
                this.arrTableDataOge = this.arrTableData;
            }
            if (this.state.bShowDlg) {
                if (this.state.iDlgType === 0) {
                    message.success({ content: "新增合箱信息信息完成！", key: g_strMessageKey, duration: 2 });
                }
                if (this.state.iDlgType === 1) {
                    message.success({ content: "合箱信息信息已更新！", key: g_strMessageKey, duration: 2 });
                }
                if (this.state.iDlgType === 2) {
                    message.success({ content: "合箱信息新信息已删除！", key: g_strMessageKey, duration: 2 })
                }
            } else {
                message.success({ content: "数据加载完成！", key: g_strMessageKey, duration: 2 });
            }
            this.objCurrentPackageCombine = {};
            this.setState({
                ...this.state,
                bLoading: false,
                bShowDlg: false,
                btnControlDisable: [false, true, true, true],
                selectedRowKeys: []
            });
        }).catch(function (error) { console.log(error); });
    }
    // 合箱信息信息查询
    PackageCombineLookup = () => {
        let strKeyWord = this.refs.search.state.value;
        if (strKeyWord.trim() === "") {
            message.warning('搜索内容不能为空！');
            return;
        }
        this.strKeyWord = strKeyWord;
        this.GetPackageCombineData();
    }
    // 后续运输方式切换
    SwitchTransitType = (index) => {
        let fTransitPrice = 6000;
        if (index === 2)
            fTransitPrice = 5000;
        if (this.arrCurrentExpressInfo.length > 0) {
            for (let i = 0; i < this.arrCurrentExpressInfo.length; i++) {
                let fValueWeight = parseFloat(this.arrCurrentExpressInfo[i].length) * parseFloat(this.arrCurrentExpressInfo[i].width) *
                    parseFloat(this.arrCurrentExpressInfo[i].height) / parseFloat(fTransitPrice);
                this.arrCurrentExpressInfo[i].value_weight = fValueWeight;
                if (fValueWeight >= parseFloat(this.arrCurrentExpressInfo[i].item_weight))
                    this.arrCurrentExpressInfo[i].price_weight = fValueWeight;
                else
                    this.arrCurrentExpressInfo[i].price_weight = this.arrCurrentExpressInfo[i].item_weight;
            }
        }

        this.ComputePriceWeight(index);
        this.setState({
            ...this.state,
            iTransitType: index,
            bItemValueChange: true,
            bPackageInfoChange: true
        });
    }
    // 包裹品类切换
    SwitchPackageType = (index) => {
        this.setState({
            ...this.state,
            iPackageType: index
        });
    }
    // 包裹品类切换
    SwitchShipDate = (index) => {
        this.setState({
            ...this.state,
            iShipDate: index
        });
    }
    // 状态更新
    StatusChange = (e) => {
        if (e.target.checked)
            this.setState({
                ...this.state,
                iStatus: 1
            });
        else
            this.setState({
                ...this.state,
                iStatus: 0
            });
    }
    // 需要导出合箱记录中包含的快递进仓数量计算
    ExportExpressGroupNum = () => {
        let arrExpressOrder = [];
        let iExpressNum = 0;
        let iGroupNum = 0;
        for (let i = 0; i < this.arrSelectedPackageCombine.length; i++) {
            if (this.arrSelectedPackageCombine[i].express_info.length > 0) {
                iExpressNum += this.arrSelectedPackageCombine[i].express_info.length;
            }
        }
        iGroupNum = iExpressNum / 100 + 1;
        for (let i = 0; i < iGroupNum; i++) {
            arrExpressOrder.push([]);
        }
        return arrExpressOrder;
    }
    // 获取包裹物品信息
    GetPackageCombineItemDetails = (iCurrentGroup, arrExpressOrder) => {
        axios('/GetPackageCombineItemDetails', {
            params: {
                express_order: arrExpressOrder[iCurrentGroup],
                store_id: this.props.iStoreID
            }
        }).then(({ data }) => {

            console.log("GetPackageCombineItemDetails : ", data);

            if (data.length > 0)
                this.arrSelectedPackageCombineItemInfo = this.arrSelectedPackageCombineItemInfo.concat(data);
            if (iCurrentGroup < arrExpressOrder.length - 1) {
                iCurrentGroup++;
                if (arrExpressOrder[iCurrentGroup].length > 0)
                    this.GetPackageCombineItemDetails(iCurrentGroup, arrExpressOrder);
                else {
                    this.ExportExcelFile();
                }
            } else {
                this.ExportExcelFile();
            }
        }).catch(function (error) { console.log(error); });
    }
    // 点击了导出Excel文档按钮
    ClickExportButton = () => {
        message.loading({ content: '数据处理中……', key: g_strMessageKey });
        if (this.arrSelectedPackageCombine.length > 100) {
            message.warning({ content: "导出数据不能超过100行！", key: g_strMessageKey, duration: 2 });
            return;
        }
        // 获取选中合箱编码对应的快递列表和物品列表信息
        let arrExpressOrder = this.ExportExpressGroupNum();
        let iExpressNum = 0;
        let iGroupNum = 0;
        for (let i = 0; i < this.arrSelectedPackageCombine.length; i++) {
            if (this.arrSelectedPackageCombine[i].express_info.length > 0) {
                for (let j = 0; j < this.arrSelectedPackageCombine[i].express_info.length; j++) {
                    if (iExpressNum < 100) {
                        iExpressNum++;
                        arrExpressOrder[iGroupNum].push(this.arrSelectedPackageCombine[i].express_info[j].express_order);
                    } else {
                        iExpressNum = 0;
                        iGroupNum++;
                        arrExpressOrder[iGroupNum].push(this.arrSelectedPackageCombine[i].express_info[j].express_order);
                    }
                }
            }
        }
        console.log('arrExpressOrder : ', arrExpressOrder);
        this.arrSelectedPackageCombineItemInfo = [];
        this.GetPackageCombineItemDetails(0, arrExpressOrder);

    }
    // 导出Excel文档
    ExportExcelFile = () => {
        let arrExportData = [['合箱编码', 'Check Mark', '货架位置', '客户签收', '备注', '货运方式', '物品种类', '总重量', '总体积', '总费用', '备注', '合箱状态', '快递单号', '收件人名', '用户编码', '货物信息']];
        for (let i = 0; i < this.arrSelectedPackageCombine.length; i++) {
            if (i > 0)
                arrExportData.push(['']);
            let arrData = [];
            if (this.arrSelectedPackageCombine[i].package_no !== null && this.arrSelectedPackageCombine[i].package_no !== "")
                arrData.push(this.arrSelectedPackageCombine[i].package_no);
            else
                arrData.push("");
            arrData.push("");
            arrData.push("");
            arrData.push("");
            arrData.push("");
            arrData.push(this.arrTransitType[this.arrSelectedPackageCombine[i].transit_way]);
            arrData.push(this.arrPackageType[this.arrSelectedPackageCombine[i].package_type]);
            if (this.arrSelectedPackageCombine[i].price_weight !== null && this.arrSelectedPackageCombine[i].price_weight !== "")
                arrData.push(this.arrSelectedPackageCombine[i].price_weight + " kg");
            else
                arrData.push("");
            if (this.arrSelectedPackageCombine[i].total_value !== null && this.arrSelectedPackageCombine[i].total_value !== "")
                arrData.push(this.arrSelectedPackageCombine[i].total_value + " cm3");
            else
                arrData.push("");
            arrData.push("$ " + this.arrSelectedPackageCombine[i].total_value);
            if (this.arrSelectedPackageCombine[i].comments !== null && this.arrSelectedPackageCombine[i].comments !== "")
                arrData.push(this.arrSelectedPackageCombine[i].comments);
            else
                arrData.push("");
            if (this.arrSelectedPackageCombine[i].status === 0)
                arrData.push('×');
            else
                arrData.push('√');

            // 如果有快递和货物信息
            if (this.arrSelectedPackageCombine[i].express_info.length > 0) {
                arrData.push(this.arrSelectedPackageCombine[i].express_info[0].express_order);
                arrData.push(this.arrSelectedPackageCombine[i].express_info[0].receiver);
                arrData.push(this.arrSelectedPackageCombine[i].express_info[0].storage_code);
                // 获取货物列表信息
                this.GetPackageCombineExpressInfo(arrExportData, arrData, i, 0);
                if (this.arrSelectedPackageCombine[i].express_info.length > 1) {
                    // 插入空行
                    let arrExpressInfo = ['', '', '', '', '', '', '', '', '', '', '', '', '', ''];
                    for (let j = 1; j < this.arrSelectedPackageCombine[i].express_info.length; j++) {
                        arrExpressInfo = ['', '', '', '', '', '', '', '', '', '', '', ''];
                        arrExpressInfo.push(this.arrSelectedPackageCombine[i].express_info[j].express_order);
                        arrExpressInfo.push(this.arrSelectedPackageCombine[i].express_info[j].receiver);
                        arrExpressInfo.push(this.arrSelectedPackageCombine[i].express_info[j].storage_code);
                        // 获取货物列表信息
                        this.GetPackageCombineExpressInfo(arrExportData, arrExpressInfo, i, j);
                    }
                }
            }
            // 没有货物信息
            else {
                arrExportData.push(arrData);
            }
        }
        message.success({ content: "数据处理完成！", key: g_strMessageKey, duration: 2 });
        var sheet = XLSX.utils.aoa_to_sheet(arrExportData);
        openDownloadDialog(sheet2blob(sheet), '合箱记录.xlsx');
    }
    // 获取指定合箱/指定快递单号中的货物信息
    GetPackageCombineExpressInfo = (arrParentData, arrData, i, indexExpress) => {
        for (let j = 0; j < this.arrSelectedPackageCombineItemInfo.length; j++) {
            if (this.arrSelectedPackageCombineItemInfo[j].package_no === this.arrSelectedPackageCombine[i].package_no &&
                this.arrSelectedPackageCombineItemInfo[j].express_order === this.arrSelectedPackageCombine[i].express_info[indexExpress].express_order) {
                if (this.arrSelectedPackageCombineItemInfo[j].express_info !== null && this.arrSelectedPackageCombineItemInfo[j].express_info !== "") {
                    let objExpressInfo = JSON.parse(this.arrSelectedPackageCombineItemInfo[j].express_info);
                    console.log('GetPackageCombineExpressInfo : ', objExpressInfo);
                    if (objExpressInfo.item_info !== undefined) {
                        if (objExpressInfo.item_info !== null && objExpressInfo.item_info !== "") {
                            if (objExpressInfo.item_info.length > 0) {
                                for (let k = 0; k < objExpressInfo.item_info.length; k++) {
                                    if (k === 0) {

                                        arrData.push(objExpressInfo.item_info[k].name);
                                        arrData.push(objExpressInfo.item_info[k].num.toString());
                                    }
                                    else {
                                        arrData.push("");
                                        arrData.push("");
                                        arrData.push("");
                                        arrData.push("");
                                        arrData.push('', '', '', '', '', '', '', '', '', '', '');
                                        arrData.push(objExpressInfo.item_info[k].name);
                                        arrData.push(objExpressInfo.item_info[k].num.toString());
                                    }
                                    arrParentData.push(arrData);
                                    arrData = [];
                                }
                            } else {
                                arrParentData.push(arrData);
                                arrData = [];
                            }
                        } else {
                            arrParentData.push(arrData);
                            arrData = [];
                        }
                    } else {
                        arrParentData.push(arrData);
                        arrData = [];
                    }
                } else {
                    console.log("No Express Info");
                }
            }
        }
    }
    // 显示全部进仓信息
    ShowAllPackageCombine = () => {
        this.iExpressTypeFilter = 0;
        this.strKeyWord = "";
        this.refs.search.state.value = "";
        this.setState({
            ...this.state,
            iExpressTypeFilter: 0
        });
        this.GetPackageCombineData();
    }
    // 新增进仓信息
    AddNewExpressEnterInfo = () => {
        let objExpressEnter = {
            express_order: '', receiver: ''
        };
        this.arrCurrentExpressInfo.push(objExpressEnter);
        this.setState({
            ...this.state,
            bItemValueChange: true
        });
    }
    // 移除进仓信息
    RemoveExpressInfo = (index) => {
        this.arrCurrentExpressInfo.splice(index, 1);
        console.log("this.objCurrentPackageCombine.express_info = ", this.objCurrentPackageCombine.express_info);
        this.setState({
            ...this.state,
            bItemValueChange: true
        });
    }
    // 物品信息发生变化
    ExpressInfoChange = (index, value, e) => {
        if (value === 0) {
            this.arrCurrentExpressInfo[index].express_order = e.target.value;
        }
        if (value === 1) {
            this.arrCurrentExpressInfo[index].receiver = e.target.value;
        }
        if (value === 2) {
            this.arrCurrentExpressInfo[index].storage_code = e.target.value;
        }
        this.setState({
            ...this.state,
            bItemValueChange: true
        });
    }
    // 合箱状态过滤器切换
    SwitchStatusFilter = (index) => {

        this.arrTableData = [];
        if (index > 0) {
            let iStatus = index - 1;
            for (let i = 0; i < this.arrTableDataOge.length; i++) {
                if (iStatus === this.arrTableDataOge[i].status)
                    this.arrTableData.push(this.arrTableDataOge[i]);
            }
        } else {
            this.arrTableData = this.arrTableDataOge;
        }
        this.setState({
            ...this.state,
            iStatusFilter: index
        });
    }
    // 合箱信息更新
    PackageInfoChange = (index, e) => {

        // 净重
        if (index === 0) {
            this.objCurrentPackageCombine.total_weight = e.target.value;
        }
        // 长度
        if (index === 1) {
            this.objCurrentPackageCombine.length = e.target.value;
        }
        // 宽度
        if (index === 2) {
            this.objCurrentPackageCombine.width = e.target.value;
        }
        // 高度
        if (index === 3) {
            this.objCurrentPackageCombine.height = e.target.value;
        }

        this.ComputePriceWeight(-1);

        this.setState({
            bPackageInfoChange: true
        });
    }
    // 体积重比较计算
    ComputePriceWeight = (type) => {
        if (type < 0)
            type = this.state.iTransitType;
        let fTransitPrice = 6000;
        if (type === 2)
            fTransitPrice = 5000;

        this.objCurrentPackageCombine.total_value = parseFloat(this.objCurrentPackageCombine.length);
        this.objCurrentPackageCombine.total_value *= parseFloat(this.objCurrentPackageCombine.width);
        this.objCurrentPackageCombine.total_value *= parseFloat(this.objCurrentPackageCombine.height);
        this.objCurrentPackageCombine.value_weight = this.objCurrentPackageCombine.total_value / fTransitPrice;

        if (this.objCurrentPackageCombine.total_weight === "") {
            this.objCurrentPackageCombine.price_weight = this.objCurrentPackageCombine.value_weight;
        } else {
            if (this.objCurrentPackageCombine.length === "" && this.objCurrentPackageCombine.width === "" && this.objCurrentPackageCombine.height === "") {
                this.objCurrentPackageCombine.price_weight = this.objCurrentPackageCombine.total_weight;
            } else {
                this.objCurrentPackageCombine.price_weight = this.objCurrentPackageCombine.total_weight;
                let fCurrentTotalWeight = parseFloat(this.objCurrentPackageCombine.total_weight);
                let fCurrentValueWeight = parseFloat(this.objCurrentPackageCombine.value_weight);
                if (fCurrentTotalWeight < fCurrentValueWeight)
                    this.objCurrentPackageCombine.price_weight = this.objCurrentPackageCombine.value_weight;
            }
        }
    }
    // 查看某合箱记录中的快递单号对应的货物信息
    ViewItemDetails = (strOrder) => {
        message.loading({ content: '数据加载中……', key: g_strMessageKey });
        axios('/GetExpressItemDetails', {
            params: {
                express_order: strOrder,
                store_id: this.props.iStoreID
            }
        }).then(({ data }) => {
            console.log(data);
            if (data !== null && data.length > 0) {
                message.success({ content: "数据加载完成！", key: g_strMessageKey, duration: 2 });
                this.arrCurrentItemInfo = [];
                let objExpressInfo = JSON.parse(data[0].express_info);
                this.arrCurrentItemInfo = objExpressInfo.item_info;
                console.log(this.arrCurrentItemInfo);
                if (this.arrCurrentItemInfo.length > 0) {
                    this.setState({
                        ...this.state,
                        bViewItemInfoDlg: true
                    });
                } else
                    message.info({ content: "未找到任何货物信息，请检查该快递单号！", key: g_strMessageKey, duration: 2 });
            } else {
                message.info({ content: "未找到任何货物信息，请检查该快递单号！", key: g_strMessageKey, duration: 2 });
            }
        }).catch(function (error) { console.log(error); });
    }
    // 关闭查看货物明细对话框
    CloseViewItemDetailsDlg = () => {
        this.setState({
            ...this.state,
            bViewItemInfoDlg: false
        })
    }
    // 复制合箱编码
    CopyPackageOrder = () => {
        // console.log("Copy Package Order !", this.arrSelectedPackageCombine);
        // let strCopyContents = "";
        // if (this.arrSelectedPackageCombine.length > 0) {
        //     for (let i = 0; i < this.arrSelectedPackageCombine.length; i++) {
        //         strCopyContents += this.arrSelectedPackageCombine[i].package_no + "\n";
        //     }
        message.info("已复制选中的合箱编码！");
        //     navigator.clipboard.writeText(strCopyContents);
        // }
    }
    /* React自带函数 */
    // 卸载异步操作设置状态
    componentWillUnmount() {
        clearTimeout(this.timeouter)
        this.setState = (state, callback) => {
            return;
        }
    }
    // 渲染完毕……
    componentDidMount() {
        this.GetPackageCombineData();
    }

    // 渲染函数
    render() {

        /* 用户管理页面控制栏 */
        let strAreaStyle = "store_manage_contents store_manage_top_no_space";
        let strLoadingStyle = "manage_empty_loading";
        let uiTitleArea = null;
        // 运输方式下拉菜单
        const menuTransitType = (
            <Menu>
                {this.arrTransitType.map((item, index) => {
                    return (
                        <Menu.Item key={index} onClick={this.SwitchTransitType.bind(this, index)}>
                            {item}
                        </Menu.Item>
                    );
                })}
            </Menu>
        );
        // 货物品类下拉菜单
        const menuPackageType = (
            <Menu>
                {this.arrPackageType.map((item, index) => {
                    return (
                        <Menu.Item key={index} onClick={this.SwitchPackageType.bind(this, index)}>
                            {item}
                        </Menu.Item>
                    );
                })}
            </Menu>
        );
        // 货物品类下拉菜单
        const menuShipDate = (
            <Menu>
                {this.arrShipDate.map((item, index) => {
                    return (
                        <Menu.Item key={index} onClick={this.SwitchShipDate.bind(this, index)}>
                            {item}
                        </Menu.Item>
                    );
                })}
            </Menu>
        );
        // 合箱状态过滤器
        const menuStatusFilter = (
            <Menu>
                {this.arrStatusFilter.map((item, index) => {
                    return (
                        <Menu.Item key={index} onClick={this.SwitchStatusFilter.bind(this, index)}>
                            {item}
                        </Menu.Item>
                    );
                })}
            </Menu>
        );
        // 顶部栏操作区域
        // strAreaStyle = "store_manage_contents store_manage_top_space";
        strAreaStyle = "store_manage_contents store_manage_top_space_double";
        let strCopy = "";
        if (this.arrSelectedPackageCombine.length > 0) {
            for (let i = 0; i < this.arrSelectedPackageCombine.length; i++) {
                strCopy += this.arrSelectedPackageCombine[i].package_no + "\n";
            }
        }
        uiTitleArea = (
            <div>
                <div className="store_contents_title" >
                    <Button className="manage_contents_title_margin manage_contents_title_margin_right" type="primary" onClick={this.ShowAllPackageCombine}>显示全部</Button>
                    <div className="transit_price_title" style={{ minWidth: '80px' }}>{"合箱状态"}</div>
                    <Dropdown className="order_detail_dropdown" trigger={['click']}
                        overlay={menuStatusFilter} placement="bottomLeft">
                        <Button className="transit_type_filter_dropdown_button">
                            <div className="order_details_dropdown_text">{this.arrStatusFilter[this.state.iStatusFilter]}</div>
                            <Icon type="caret-down" />
                        </Button>
                    </Dropdown>
                    <div className="order_filter_title">关键字</div>
                    <Input className="order_search_input" ref="search" defaultValue="" />
                    <Button className="manage_contents_title_margin" type="primary" onClick={this.PackageCombineLookup} >
                        <Icon type="search" />搜索</Button>
                </div >
                <div className="store_contents_title_sec">
                    <Button className="manage_contents_title_margin new_button_general_style"
                        onClick={this.ShowDlg.bind(this, 0)} disabled={this.state.btnControlDisable[0]}>
                        <Icon type="plus" />新建</Button>
                    <Button className="manage_contents_title_margin" type="primary" onClick={this.ShowDlg.bind(this, 1)} disabled={this.state.btnControlDisable[1]}>
                        <Icon type="edit" />编辑</Button>
                    <Button className="manage_contents_title_margin" type="danger" onClick={this.ShowDlg.bind(this, 2)} disabled={this.state.btnControlDisable[2]}>
                        <Icon type="close" />删除</Button>
                    <Button className="manage_contents_title_margin export_button_style" onClick={this.ClickExportButton} disabled={this.state.btnControlDisable[3]}>
                        <Icon type="export" />导出Excel</Button>
                    {/* <Button className="manage_contents_title_margin export_button_style" onClick={this.CopyPackageOrder} disabled={this.state.btnControlDisable[3]}>
                        <Icon type="copy" />复制合箱编码</Button> */}
                    <Clipboard data-clipboard-text={strCopy} onClick={this.CopyPackageOrder} className="manage_contents_title_margin export_button_style"
                        style={{ border: 'none', width: '110px', height: '32.5px', borderRadius: '5px' }}>
                        复制合箱编码
                    </Clipboard>
                </div>
            </div>
        );

        // 代理
        if (this.props.iUserLevel <= 5) {
            uiTitleArea = (
                <div>
                    <div className="store_contents_title" >
                        <Button className="manage_contents_title_margin manage_contents_title_margin_right" type="primary" onClick={this.ShowAllPackageCombine}>显示全部</Button>
                        <div className="transit_price_title" style={{ minWidth: '80px' }}>{"合箱状态"}</div>
                        <Dropdown className="order_detail_dropdown" trigger={['click']}
                            overlay={menuStatusFilter} placement="bottomLeft">
                            <Button className="transit_type_filter_dropdown_button">
                                <div className="order_details_dropdown_text">{this.arrStatusFilter[this.state.iStatusFilter]}</div>
                                <Icon type="caret-down" />
                            </Button>
                        </Dropdown>
                        <div className="order_filter_title">关键字</div>
                        <Input className="order_search_input" ref="search" defaultValue="" />
                        <Button className="manage_contents_title_margin" type="primary" onClick={this.PackageCombineLookup} >
                            <Icon type="search" />搜索</Button>
                    </div >
                    <div className="store_contents_title_sec">
                        {/* <Button className="manage_contents_title_margin new_button_general_style"
                            onClick={this.ShowDlg.bind(this, 0)} disabled={this.state.btnControlDisable[0]}>
                            <Icon type="plus" />新建</Button> */}
                        <Button className="manage_contents_title_margin" type="primary" onClick={this.ShowDlg.bind(this, 1)} disabled={this.state.btnControlDisable[1]}>
                            <Icon type="edit" />查看</Button>
                        {/* <Button className="manage_contents_title_margin" type="danger" onClick={this.ShowDlg.bind(this, 2)} disabled={this.state.btnControlDisable[2]}>
                            <Icon type="close" />删除</Button> */}
                        <Button className="manage_contents_title_margin export_button_style" onClick={this.ClickExportButton} disabled={this.state.btnControlDisable[3]}>
                            <Icon type="export" />导出Excel</Button>
                        {/* <Button className="manage_contents_title_margin export_button_style" onClick={this.CopyPackageOrder} disabled={this.state.btnControlDisable[3]}>
                        <Icon type="copy" />复制合箱编码</Button> */}
                        <Clipboard data-clipboard-text={strCopy} onClick={this.CopyPackageOrder} className="manage_contents_title_margin export_button_style"
                            style={{ border: 'none', width: '110px', height: '32.5px', borderRadius: '5px' }}>
                            复制合箱编码
                        </Clipboard>
                    </div>
                </div>
            );
        }

        // M站针对加载状态时的界面处理
        if (this.props.bMStation) {
            uiTitleArea = null;
            strLoadingStyle = "manage_empty_loading_m";
        }

        // 如果界面上需要显示的内容已经从数据库获得
        if (!this.state.bLoading) {
            // 主体表格相关
            const { selectedRowKeys } = this.state;
            const rowSelection = {
                selectedRowKeys,
                onChange: this.onSelectedRowKeysChange,
            };
            // 表格分页属性
            let paginationProps = {
                showSizeChanger: true,
                showQuickJumper: false,
                showTotal: () => `共 ${this.arrTableData.length} 条 价格信息`,
                defaultPageSize: 10,
                defaultCurrent: this.state.iCurrentPage,
                pageSizeOptions: ['10', '20', '30', '40', '50'],
                total: this.arrTableData.length,
                position: 'bottom'
            };
            // 用户信息编辑弹窗
            let strTitle = "新建合箱信息";
            if (this.state.iDlgType === 1)
                strTitle = "编辑合箱信息";

            let bDefaultChecked = false;
            if (this.state.iStatus === 1)
                bDefaultChecked = true;


            // 进仓列表
            let uiExpressEnterList = null;
            if (this.arrCurrentExpressInfo.length > 0) {
                uiExpressEnterList = (
                    this.arrCurrentExpressInfo.map((item, index) => {
                        let strItemBlockStyle = "express_item_info_block";
                        if (index % 2 === 0)
                            strItemBlockStyle = "express_item_info_block_light";
                        return (
                            <div key={index} className={strItemBlockStyle}>
                                <div className="express_item_info_row">
                                    <div className="express_enter_edit_title">物流单号：</div>
                                    <Input style={{ width: '200px', minWidth: '200px', maxWidth: '200px' }} ref={"express_order_" + index}
                                        defaultValue={item.express_order} value={item.express_order}
                                        onChange={this.ExpressInfoChange.bind(this, index, 0)} />
                                    <div className="express_enter_edit_title">收件人名：</div>
                                    <Input style={{ width: '125px', minWidth: '125px', maxWidth: '125px' }} ref={"receive_" + index}
                                        defaultValue={item.receiver} value={item.receiver}
                                        onChange={this.ExpressInfoChange.bind(this, index, 1)} />
                                    <div className="express_enter_edit_title">用户编码：</div>
                                    <Input style={{ width: '125px', minWidth: '125px', maxWidth: '125px' }} ref={"storage_code_" + index}
                                        defaultValue={item.storage_code} value={item.storage_code}
                                        onChange={this.ExpressInfoChange.bind(this, index, 2)} />
                                    <div className="express_enter_edit_title package_combine_view_item_details no_mouse_select"
                                        onClick={this.ViewItemDetails.bind(this, item.express_order)}> 货物明细</div>
                                    <div className="express_item_delete_button" style={{ marginLeft: 'auto', marginRight: '0px' }}
                                        onClick={this.RemoveExpressInfo.bind(this, index)}>
                                        <Icon type="delete" theme="filled" />
                                    </div>
                                </div>
                            </div>
                        );
                    })
                );
            }
            // 货物明细弹窗
            let uiViewItemDetails = null;
            if (this.arrCurrentItemInfo.length > 0) {
                uiViewItemDetails = (
                    <Modal width={500} title={"货物明细"} visible={this.state.bViewItemInfoDlg} footer={null}
                        onCancel={this.CloseViewItemDetailsDlg} >
                        <div className="view_item_details_area">
                            {this.arrCurrentItemInfo.map((item, index) => {
                                return (
                                    <div key={index} className="view_item_details_row">
                                        <div className="express_item_info_row">
                                            {item.name + " x " + item.num}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </Modal>
                );
            }
            let uiModal = (
                <Modal title={strTitle} width={950} visible={this.state.bShowDlg}
                    okText="确定" cancelText="取消" onOk={this.handleOk} onCancel={this.handleCancel} >
                    <div className="user_edit_info_row">
                        <div className="express_enter_edit_title">合箱编号：</div>
                        <Input className="user_edit_info_input" ref="package_no"
                            defaultValue={this.objCurrentPackageCombine.package_no} />
                        <Checkbox className="express_enter_checkbox" onChange={this.StatusChange.bind(this)} checked={bDefaultChecked}>合箱完成</Checkbox>
                    </div>
                    <div className="user_edit_info_row">
                        <div className="express_enter_edit_title">货运方式：</div>
                        <Dropdown overlay={menuTransitType} trigger={['click']}>
                            <Button className="transit_type_dropdown_button">
                                <div className="order_details_dropdown_text">{this.arrTransitType[this.state.iTransitType]}</div>
                                <Icon type="caret-down" />
                            </Button>
                        </Dropdown>
                        <div className="express_enter_edit_title">货物品类：</div>
                        <Dropdown overlay={menuPackageType} trigger={['click']}>
                            <Button className="transit_type_dropdown_button">
                                <div className="order_details_dropdown_text">{this.arrPackageType[this.state.iPackageType]}</div>
                                <Icon type="caret-down" />
                            </Button>
                        </Dropdown>
                        <div className="express_enter_edit_title">本月船期：</div>
                        <Dropdown overlay={menuShipDate} trigger={['click']}>
                            <Button className="transit_type_dropdown_button">
                                <div className="order_details_dropdown_text">{this.arrShipDate[this.state.iShipDate]}</div>
                                <Icon type="caret-down" />
                            </Button>
                        </Dropdown>
                    </div>
                    <div className="user_edit_info_row">
                        <div className="express_enter_edit_title">备注信息：</div>
                        <Input className="express_enter_item_info_input_full" ref="comments"
                            defaultValue={this.objCurrentPackageCombine.comments} />
                    </div>
                    <div className="user_edit_info_row">
                        <div className="express_enter_edit_title">合箱长度：</div>
                        <Input className="user_edit_info_input" ref="package_length" addonAfter={"cm"} value={this.objCurrentPackageCombine.length}
                            defaultValue={this.objCurrentPackageCombine.length} onChange={this.PackageInfoChange.bind(this, 1)} />
                        <div className="express_enter_edit_title">合箱宽度：</div>
                        <Input className="user_edit_info_input" ref="package_width" addonAfter={"cm"} value={this.objCurrentPackageCombine.width}
                            defaultValue={this.objCurrentPackageCombine.width} onChange={this.PackageInfoChange.bind(this, 2)} />
                        <div className="express_enter_edit_title">合箱高度：</div>
                        <Input className="user_edit_info_input" ref="package_height" addonAfter={"cm"} value={this.objCurrentPackageCombine.height}
                            defaultValue={this.objCurrentPackageCombine.height} onChange={this.PackageInfoChange.bind(this, 3)} />
                    </div>
                    <div className="user_edit_info_row">
                        <div className="express_enter_edit_title">合箱净重：</div>
                        <Input className="user_edit_info_input" ref="total_weight" addonAfter={"kg"} value={this.objCurrentPackageCombine.total_weight}
                            defaultValue={this.objCurrentPackageCombine.total_weight} onChange={this.PackageInfoChange.bind(this, 0)} />
                        <div className="express_enter_edit_title">合箱费用：</div>
                        <Input className="user_edit_info_input" ref="total_price" addonBefore={"$"}
                            defaultValue={this.objCurrentPackageCombine.total_price} />
                    </div>
                    <div className="user_edit_info_row">
                        <div className="express_enter_edit_title">
                            {"合箱体积：" + parseFloat(this.objCurrentPackageCombine.total_value).toFixed(2) + " cm3"}</div>
                        <div className="express_enter_edit_title">
                            {"计费重量：" + parseFloat(this.objCurrentPackageCombine.price_weight).toFixed(2) + " kg"}</div>
                        <div className="express_enter_express_info_add_button" onClick={this.AddNewExpressEnterInfo}>+ 添加快递</div>
                    </div>
                    {uiExpressEnterList}
                    {uiViewItemDetails}
                </Modal >
            );
            if (this.state.iDlgType === 2) {
                uiModal = (
                    <Modal width={350} title={"删除合箱记录"} visible={this.state.bShowDlg} okType="danger"
                        onOk={this.handleOk} okText="确定" onCancel={this.handleCancel} cancelText="取消">
                        <div className="modal_item">
                            <div>{"合箱编码：" + this.objCurrentPackageCombine.package_no}
                                <br /><br />确定删除选中的合箱记录？
                            </div>
                        </div>
                    </Modal>
                );
            }

            let uiTable = (
                <Table style={{ marginLeft: '20px', marginRight: '20px', width: '95%', overflowX: 'auto' }}
                    rowSelection={rowSelection} pagination={paginationProps}
                    columns={this.tableHead} dataSource={this.arrTableData} bordered
                    onRow={(record) => ({
                        onClick: () => { this.selectRow(record); },
                        onDoubleClick: () => { this.DoubleClickRow(record); }
                    })} />
            );
            let uiViewQRCodeDlg = (
                <Modal title={strTitle} width={950} visible={this.bViewQRCodeDlg} footer={null}
                    okText="确定" cancelText="取消" onOk={this.ViewQRCode.bind(this, -1, false)}
                    onCancel={this.ViewQRCode.bind(this, -1, false)} >
                    <div className="user_edit_info_row">
                        this is qr code
                    </div>
                </Modal >
            );

            // M站布局
            if (this.props.bMStation) {
                strAreaStyle = "store_manage_contents_m";
                paginationProps = {
                    showSizeChanger: true,
                    showQuickJumper: false,
                    showTotal: () => `共 ${this.arrTableData.length} 条 合箱信息`,
                    defaultPageSize: 9,
                    defaultCurrent: this.state.iCurrentPage,
                    total: this.arrTableData.length,
                    position: 'bottom'
                };
                let strNewButtonStyle = { backgroundColor: 'lightseagreen', color: 'white' };
                uiTitleArea = (
                    <div>
                        <div className="store_contents_title_m" >
                            <div className="order_filter_title">关键字</div>
                            <Input className="order_search_input" ref="search" defaultValue="" />
                            <Button className="store_manager_control_button" type="primary" onClick={this.PackageCombineLookup} >
                                <Icon type="search" />
                            </Button>
                        </div >
                        <div className="store_contents_title_sec_m" >
                            <Button className="manage_contents_title_margin manage_contents_title_margin_right" type="primary" onClick={this.ShowAllPackageCombine} >
                                显示全部</Button>
                        </div>
                        <div className="store_contents_title_thd_m" >
                            <Button className="store_manager_control_button" style={strNewButtonStyle} onClick={this.ShowDlg.bind(this, 0)}
                                disabled={this.state.btnControlDisable[0]}>
                                <Icon type="plus" /></Button>
                            <Button className="store_manager_control_button" type="primary" onClick={this.ShowDlg.bind(this, 1)} disabled={this.state.btnControlDisable[1]}>
                                <Icon type="edit" /></Button>
                            <Button className="store_manager_control_button" type="danger" onClick={this.ShowDlg.bind(this, 2)} disabled={this.state.btnControlDisable[2]}>
                                <Icon type="close" /></Button>
                        </div>
                    </div>
                );
            }

            return (
                <div className={strAreaStyle}>
                    {uiTitleArea}
                    {uiModal}
                    {uiTable}
                    {uiViewQRCodeDlg}
                </div >
            );

        } else {
            return (
                <div className={strAreaStyle}>
                    {uiTitleArea}
                    <Spin className={strLoadingStyle} size="large" tip="加载中……" />
                </div>
            );
        }
    }
}
export default PackageCombine;
