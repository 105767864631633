import React, { Component } from 'react';
import axios from 'axios';
import XLSX from 'xlsx';

import Spin from 'antd/es/spin';
import Table from 'antd/es/table';
import Button from 'antd/es/button';
import Input from 'antd/es/input';
import Icon from 'antd/es/icon';
import Modal from 'antd/es/modal';
import Collapse from 'antd/es/collapse';
import Dropdown from 'antd/es/dropdown';
import Menu from 'antd/es/menu';
import Steps from 'antd/es/steps';
import Checkbox from 'antd/es/checkbox';
import message from 'antd/es/message';
import '../../../styles/Background/ego_manager.css';
import '../../../styles/Background/UI_Module/user_management.css';
import '../../../styles/Background/UI_Module/jeton_order.css';

const { Panel } = Collapse;
const { Step } = Steps;
const g_strMessageKey = 'updating';

// 表格
const arrTableHeader1 = [
    // { title: 'id', dataIndex: 'id', width: 35, ellipsis: true },
    { title: 'UPS单号', dataIndex: 'tracking_no', width: 85, ellipsis: true },
    { title: '类别', dataIndex: 'type_str', width: 65, ellipsis: true },
    { title: '收件人', dataIndex: 'receiver_name', width: 65, ellipsis: true },
    { title: '费用(CAD)', dataIndex: 'final_price', width: 50, ellipsis: true },
    { title: '创建日期', dataIndex: 'create_time_str', width: 50, ellipsis: true },
    { title: '状态', dataIndex: 'status_str', width: 40, ellipsis: true },
    { title: '备注', dataIndex: 'comments', width: 100, ellipsis: true },
];
const arrTableHeader2 = [
    // { title: 'id', dataIndex: 'id', width: 35, ellipsis: true },
    { title: 'UPS单号', dataIndex: 'tracking_no', width: 85, ellipsis: true },
    { title: '类别', dataIndex: 'type_str', width: 65, ellipsis: true },
    { title: '用户', dataIndex: 'user_name', width: 55, ellipsis: true },
    { title: '收件人', dataIndex: 'receiver_name', width: 65, ellipsis: true },
    { title: '折扣', dataIndex: 'discount_str', width: 40, ellipsis: true },
    { title: '收费(CAD)', dataIndex: 'final_price', width: 50, ellipsis: true },
    { title: '创建日期', dataIndex: 'create_time_str', width: 50, ellipsis: true },
    { title: '状态', dataIndex: 'status_str', width: 40, ellipsis: true },
    { title: '备注', dataIndex: 'comments', width: 100, ellipsis: true },
];

// 将一个sheet转成最终的excel文件的blob对象，然后利用URL.createObjectURL下载
function sheet2blob(sheet, sheetName) {
    sheetName = sheetName || 'sheet1';
    var workbook = {
        SheetNames: [sheetName],
        Sheets: {}
    };
    workbook.Sheets[sheetName] = sheet;
    // 生成excel的配置项
    var wopts = {
        bookType: 'xlsx', // 要生成的文件类型
        bookSST: false, // 是否生成Shared String Table，官方解释是，如果开启生成速度会下降，但在低版本IOS设备上有更好的兼容性
        type: 'binary'
    };
    var wbout = XLSX.write(workbook, wopts);
    var blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });
    // 字符串转ArrayBuffer
    function s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
    }
    return blob;
}
//  通用的打开下载对话框方法，没有测试过具体兼容性
//  @param url 下载地址，也可以是一个blob对象，必选
//  @param saveName 保存文件名，可选
function openDownloadDialog(url, saveName) {
    if (typeof url == 'object' && url instanceof Blob) {
        url = URL.createObjectURL(url); // 创建blob地址
    }
    var aLink = document.createElement('a');
    aLink.href = url;
    aLink.download = saveName || ''; // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，file:///模式下不会生效
    var event;
    if (window.MouseEvent) event = new MouseEvent('click');
    else {
        event = document.createEvent('MouseEvents');
        event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
    }
    aLink.dispatchEvent(event);
}

class UPSShippingOrder extends Component {
    constructor(props) {
        super();
        this.state = {
            btnControlDisable: [false, true, true],  // 操作按钮控制
            selectedRowKeys: [],  // Check here to configure the default column
            iDlgType: -1,
            bShowDlg: false,
            bLoading: true,
            iCurrentShipperProvince: 1,
            iCurrentReceiverProvince: 1,
            iShipperDlg: -1,
            bShipperDlg: false,
            iReceiverDlg: -1,
            bReceiverDlg: false,
            iCurrentUnit: 0,
            iCurrentUPSExpress: 0,
            arrCurrentPackage: [0],
            bPackageInfoChange: false,
            bTrackingDlg: false,
            bPriceDetailDlg: false,
            bExportExcel: false,
            iUserDlg: -1,
            bUserDlg: false,
            iPriceDlgType: 0
        };
        // 成员变量
        this.arrOgeTableData = [];
        this.arrTableData = [];  // 用于表格的数组
        this.arrSelectedJetonOrderID = [];
        this.arrSelectedJetonOrderIndex = [];
        this.objCurrentJetonOrder = {};
        this.objCurrentShipper = {};
        this.objCurrentReceiver = {};
        this.arrPackageInfo = [];
        // this.strBase64Label = "";

        this.bPriceSettled = false;
        this.bPriceCalculating = false;
        this.strUPSMessage = "";
        // this.arrStatus = ["已下单，等待UPS揽件", "已取走，UPS运输中", "已签收", "已取消"];
        this.arrStatus = ["正常", "已取消"];
        this.arrCAProvince = ["AB", "BC", "MB", "NB", "NL", "NS", "NT", "NU", "ON", "PE", "QC", "SK", "YT"];
        this.arrUnit = ['kg & cm', 'lb & in'];
        this.arrUPSExpress = [
            { name: '------ 请选择 ------', id: '00', category: '' },
            { name: 'UPS Express', id: '01', category: 'Canadian domestic shipments' },
            { name: 'UPS Expedited', id: '02', category: 'Canadian domestic shipments' },
            { name: 'UPS Standard', id: '11', category: 'Shipments originating in Canada' },
            { name: 'UPS Express Saver', id: '13', category: 'Canadian domestic shipments' },
            { name: 'UPS Worldwide Express', id: '07', category: 'International shipments originating in Canada' },
            { name: 'UPS Worldwide Expedited', id: '08', category: 'International shipments originating in Canada' },
            { name: 'UPS Worldwide Express Saver', id: '65', category: 'International shipments originating in Canada' }
        ];
        this.arrUPSTransitType = [
            { code: 'D', value: 'Delivered' },
            { code: 'I', value: 'In Transit' },
            { code: 'M', value: 'Received' },
            { code: 'MV', value: 'Voided' },
            { code: 'P', value: 'Pickup' },
            { code: 'X', value: 'Exception' },
            { code: 'RS', value: 'Returned to Shipper' },
            { code: 'DO', value: 'Delivered' },
            { code: 'DD', value: 'Delivered' },
            { code: 'W', value: 'Warehousing' },
            { code: 'NA', value: 'Not Avaiable' },
            { code: 'O', value: 'Out for Delivery' },
        ];
        this.arrTrackingActivies = [];
        this.fMoney = 0.0;
        this.arrTableHeader = arrTableHeader1;
        if (props.iLevel >= 9)
            this.arrTableHeader = arrTableHeader2;
        this.iVIP = props.iVIP;
        if (this.iVIP === null || this.iVIP === undefined || this.iVIP === '' || this.iVIP < 0)
            this.iVIP = 0;
        this.fMoney = props.fMoney;
        if (this.fMoney === null || this.fMoney === undefined || this.fMoney === '' || this.fMoney < 0.0)
            this.fMoney = 0.0;
        this.arrVIP = [1.5, 1.3, 1.25, 1.2, 1.15, 1.1, 1.08, 1.05];
        this.arrLevel = ['MB', 'VIP', 'VVIP', 'SVIP', 'SSVIP', 'CRVIP', 'SCRVIP', 'FLAT'];
        this.objRatedShipment = null;
        this.arrSelectedOrder = [];
    }

    // 选中某行的响应函数
    selectRow = (record) => {
        const selectedRowKeys = [...this.state.selectedRowKeys];
        if (selectedRowKeys.indexOf(record.key) >= 0) {
            selectedRowKeys.splice(selectedRowKeys.indexOf(record.key), 1);
        } else {
            selectedRowKeys.push(record.key);
        }
        this.SelectedRowStateSetting(selectedRowKeys);
    }
    // 选中的行发生变化的响应函数
    onSelectedRowKeysChange = (selectedRowKeys) => {
        this.SelectedRowStateSetting(selectedRowKeys);
    }
    // 处理选中的表行行
    SelectedRowStateSetting = (selectedRowKeys) => {
        let btnControlDisable = [];
        let btnCopyPasteDisable = [];
        let bExportExcel = false;
        this.arrSelectedOrder = [];
        switch (selectedRowKeys.length) {
            case 0: {
                btnControlDisable = [false, true, true];
                btnCopyPasteDisable = [true, true];
                this.objCurrentJetonOrder = {};
                bExportExcel = false;
                break;
            }
            case 1: {
                btnControlDisable = [false, false, false];
                btnCopyPasteDisable = [false, true];
                for (let i = 0; i < this.arrTableData.length; i++) {
                    if (this.arrTableData[i].key === selectedRowKeys[0]) {
                        this.objCurrentJetonOrder = this.arrTableData[i];
                        this.arrSelectedOrder.push(this.arrTableData[i]);
                        break;
                    }
                }
                bExportExcel = true;
                break;
            }
            default: {
                bExportExcel = true;
                btnControlDisable = [false, true, true];
                btnCopyPasteDisable = [true, true];
                for (let j = 0; j < selectedRowKeys.length; j++) {
                    for (let i = 0; i < this.arrTableData.length; i++) {
                        if (this.arrTableData[i].key === selectedRowKeys[j]) {
                            this.arrSelectedOrder.push(this.arrTableData[i]);
                            break;
                        }
                    }
                }
                break;

            }
        }
        this.setState({
            ...this.state,
            selectedRowKeys,
            btnControlDisable: btnControlDisable,
            btnCopyPasteDisable: btnCopyPasteDisable,
            bExportExcel: bExportExcel
        });
    }
    // 关键词处理
    KeywordsProcess = (strKeywords) => {
        let newKeywords = "";
        for (let i = 0; i < strKeywords.length; i++) {
            if (strKeywords[i] !== " ") {
                if (i === strKeywords.length - 1)
                    newKeywords += strKeywords[i];
                else
                    newKeywords += strKeywords[i] + "%";
            }
        }
        return newKeywords;
    }
    // 搜索运单名
    JetonOrderSearch = () => {
        let strKeywords = this.refs.order_search.state.value;
        if (strKeywords === undefined)
            strKeywords = "";
        if (strKeywords.trim() === "") {
            this.GetJetonOrdersData();
        } else {
            this.setState({
                ...this.state,
                bLoading: true,
                selectedRowKeys: [],
                btnControlDisable: [false, true, true]
            });
            axios('/SearchUPSOrder', { params: { name: this.KeywordsProcess(strKeywords), store_id: this.props.iStoreID, user_id: this.props.iUserID } })
                .then(({ data }) => {
                    console.log("SearchUPSOrder : ", data);
                    this.arrTableData = this.OrganizeJetonOrdersData(data);
                    this.setState({
                        ...this.state,
                        bLoading: false
                    });
                }).catch(function (error) { console.log(error); });
        }
    }
    /* 自定义成员函数 */
    //弹出对话框
    ShowDlg = (iDlgType, e) => {
        this.objRatedShipment = null;
        if (iDlgType === 0) {
            // if (this.refs.shipper_name !== undefined) {
            //     this.refs.shipper_name.state.value = "";
            //     this.refs.shipper_company.state.value = "";
            //     this.refs.shipper_postcode.state.value = "";
            // }
            this.objCurrentShipper = {
                name: "", company: "", phone: "", email: "", postcode: "", country: "CA", province: 1, city: "", address: ""
            };
            this.objCurrentReceiver = {
                name: "", company: "", phone: "", email: "", postcode: "", country: "CA", province: 1, city: "", address: ""
            }

            this.iVIP = this.props.iVIP;
            if (this.iVIP === null || this.iVIP === undefined || this.iVIP === '' || this.iVIP < 0)
                this.iVIP = 0;
            this.fMoney = this.props.fMoney;
            if (this.fMoney === null || this.fMoney === undefined || this.fMoney === '' || this.fMoney < 0.0)
                this.fMoney = 0.0;

            this.bPriceSettled = false;
            this.bPriceCalculating = false;
            this.strUPSMessage = "";
            this.arrPackageInfo = [];
            this.objCurrentJetonOrder.total_cost = 0.00;
            this.objCurrentJetonOrder.discount = 100;
            this.objCurrentJetonOrder.final_price = 0.00;
            this.objTotalCharges = null;
            this.fClientCharges = 0.00;

            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true,
                iCurrentUPSExpress: 0,
                iCurrentShipperProvince: 1,
                iCurrentReceiverProvince: 1
            });
        }
        if (iDlgType === 1) {
            this.arrTrackingActivies = [];
            // 获得目标Shipper
            for (let i = 0; i < global.arrShipperData.length; i++) {
                if (global.arrShipperData[i].id === this.objCurrentJetonOrder.shipper_id) {
                    this.objCurrentShipper = global.arrShipperData[i];
                    break;
                }
            }
            // 获得目标Receiver
            for (let i = 0; i < global.arrReceiverData.length; i++) {
                if (global.arrReceiverData[i].id === this.objCurrentJetonOrder.receiver_id) {
                    this.objCurrentReceiver = global.arrReceiverData[i];
                    break;
                }
            }
            // 获得目标用户
            for (let i = 0; i < global.arrUserData.length; i++) {
                if (global.arrUserData[i].id === parseInt(this.objCurrentJetonOrder.user_id)) {
                    this.iVIP = global.arrUserData[i].vip;
                    if (this.iVIP === null || this.iVIP === undefined || this.iVIP === '' || this.iVIP < 0)
                        this.iVIP = 0;
                    this.fMoney = global.arrUserData[i].money;
                    if (this.fMoney === null || this.fMoney === undefined || this.fMoney === '' || this.fMoney < 0.0)
                        this.fMoney = 0.0;
                    break;
                }
            }
            console.log("shipper data : ", this.objCurrentShipper);
            console.log("receiver data : ", this.objCurrentReceiver);
            // 获得目标包裹信息
            this.arrPackageInfo = JSON.parse(this.objCurrentJetonOrder.package_info);
            this.bSignAll = false;
            for (let i = 0; i < this.arrPackageInfo.length; i++) {
                if (this.arrPackageInfo[i].signature) {
                    this.bSignAll = true; break;
                }

            }
            this.bPriceSettled = false;
            this.bPriceCalculating = false;
            this.strUPSMessage = "";
            this.objRatedShipment = JSON.parse(this.objCurrentJetonOrder.price_detail);
            this.objTotalCharges = {
                name: 'Total Charges',
                rate: this.objCurrentJetonOrder.total_cost
            }
            this.fClientCharges = this.objCurrentJetonOrder.final_price;
            this.PriceDetailSetting();
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true,
                iCurrentUPSExpress: this.objCurrentJetonOrder.type,
                iCurrentShipperProvince: this.objCurrentShipper.province,
                iCurrentReceiverProvince: this.objCurrentReceiver.province,
            });
        }
        if (iDlgType === 2) {
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true
            });
        }
    }
    // 弹窗确认按钮响应
    handleOk = e => {
        console.log("下单 click : ", this.state.iDlgType, this.props.iLevel);
        // return;
        if (this.state.iDlgType <= 1) {
            if ((!this.bPriceSettled || this.bPriceCalculating) && this.state.iDlgType === 0) {
                message.info("价格计算中，请耐心等待……");
                return;
            }
            let strOID = "JT";
            strOID += new Date().getTime();
            let fTotalCost = this.objCurrentJetonOrder.total_cost;
            if (fTotalCost === null || fTotalCost === undefined || fTotalCost === "") {
                message.warning("价格计算有误，请检查地址、包裹等信息是否正确填写！");
                return;
            }
            fTotalCost = parseFloat(fTotalCost);
            if (fTotalCost < 0.0) {
                message.warning("价格计算有误，请检查地址、包裹等信息是否正确填写！");
                return;
            }
            fTotalCost = fTotalCost.toFixed(2);

            console.log("confirm total cost : ", fTotalCost, this.fMoney);
            console.log("confirm total cost : ", this.objCurrentJetonOrder);

            this.fMoney = parseFloat(this.fMoney);
            if (this.fMoney < fTotalCost) {
                message.warning("余额不足，请联系客户充值！");
                return;
            }
            let strUserName = this.props.strUserName;
            let iUserID = this.props.iUserID;
            if (this.props.iLevel >= 9) {
                strUserName = this.objCurrentJetonOrder.user_name;
                iUserID = this.objCurrentJetonOrder.user_id;
                if (strUserName === undefined || strUserName === null || strUserName === '') {
                    strUserName = this.props.strUserName;
                    iUserID = this.props.iUserID;
                    this.iVIP = this.props.iVIP;
                    if (this.iVIP === null || this.iVIP === undefined || this.iVIP === '' || this.iVIP < 0)
                        this.iVIP = 0;
                    this.fMoney = this.props.fMoney;
                    if (this.fMoney === null || this.fMoney === undefined || this.fMoney === '' || this.fMoney < 0.0)
                        this.fMoney = 0.0;
                }
            }
            //  编辑
            if (this.state.iDlgType === 1) {
                let objJetonOrder = {
                    id: this.objCurrentJetonOrder.id,
                    user_id: iUserID,
                    user_name: strUserName,
                    comments: this.refs.comments.state.value,
                    update_time: new Date()
                }
                message.loading({ content: '正在更新运单信息……', key: g_strMessageKey });
                axios.post('/UpdateUPSOrderInfo', {
                    objJetonOrder: objJetonOrder
                }).then(({ data }) => {
                    console.log("UpdateUPSOrderInfo : ", data);
                    if (data.status === 1) {
                        this.GetJetonOrdersData();
                    }
                    else {
                        this.strUPSMessage = data.message;
                        message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
                        this.setState({
                            ...this.state,
                            bPackageInfoChange: true,
                            bExportExcel: false
                        });
                    }
                }).catch(function (error) { console.log(error); });
            }
            // 新建
            else {
                if (this.objCurrentShipper.id !== undefined && this.objCurrentReceiver.id !== undefined) {
                    // this.SaveShipperInfo(false);
                    // this.SaveReceiverInfo(false);
                    let objJetonOrder = {
                        store_id: this.props.iStoreID,
                        user_id: iUserID,
                        user_name: strUserName,
                        oid: strOID,
                        type: this.state.iCurrentUPSExpress,
                        status: 0,
                        tracking_no: "",
                        shipper_id: this.objCurrentShipper.id,
                        shipper_name: this.objCurrentShipper.name,
                        receiver_id: this.objCurrentReceiver.id,
                        receiver_name: this.objCurrentReceiver.name,
                        package_info: JSON.stringify(this.arrPackageInfo),
                        total_cost: fTotalCost,
                        comments: this.refs.comments.state.value,
                        create_time: new Date(),
                        update_time: new Date(),
                        price_detail: JSON.stringify(this.objRatedShipment),
                        discount: this.objCurrentJetonOrder.discount,
                        final_price: this.objCurrentJetonOrder.final_price
                    }
                    console.log("new UPS Order : ", objJetonOrder);
                    return;
                    let arrUnit = ["CM", "KGS"];
                    if (this.state.iCurrentUnit === 1)
                        arrUnit = ["IN", "LBS"]
                    let arrPackage = [];
                    for (let i = 0; i < this.arrPackageInfo.length; i++) {
                        let arrRefs = [];
                        let objRef1 = this.arrPackageInfo[i].ref1;
                        let objRef2 = this.arrPackageInfo[i].ref2;
                        if (objRef1 !== null && objRef1 !== undefined && objRef1 !== "")
                            arrRefs.push({
                                "BarCodeIndecator": "TRUE",
                                "Value": objRef1
                            });
                        if (objRef2 !== null && objRef2 !== undefined && objRef2 !== "")
                            arrRefs.push({
                                "BarCodeIndecator": "TRUE",
                                "Value": objRef2
                            });
                        if (arrRefs.length <= 0)
                            arrRefs = null;
                        let objPackage = {
                            "Packaging": {
                                "Code": "02",
                                "Description": "Package"
                            },
                            "Dimensions": {
                                "UnitOfMeasurement": {
                                    "Code": arrUnit[0]
                                },
                                "Length": this.arrPackageInfo[i].length,
                                "Width": this.arrPackageInfo[i].width,
                                "Height": this.arrPackageInfo[i].height,
                            },
                            "PackageWeight": {
                                "UnitOfMeasurement": {
                                    "Code": arrUnit[1]
                                },
                                "Weight": this.arrPackageInfo[i].weight
                            },
                            "ReferenceNumber": arrRefs
                        };
                        if (this.arrPackageInfo[i].signature) {
                            objPackage = {
                                "Packaging": {
                                    "Code": "02",
                                    "Description": "Package"
                                },
                                "Dimensions": {
                                    "UnitOfMeasurement": {
                                        "Code": arrUnit[0]
                                    },
                                    "Length": this.arrPackageInfo[i].length,
                                    "Width": this.arrPackageInfo[i].width,
                                    "Height": this.arrPackageInfo[i].height,
                                },
                                "PackageWeight": {
                                    "UnitOfMeasurement": {
                                        "Code": arrUnit[1]
                                    },
                                    "Weight": this.arrPackageInfo[i].weight
                                },
                                "ReferenceNumber": arrRefs,
                                "PackageServiceOptions": {
                                    "DeliveryConfirmation": {
                                        "DCISType": "2"
                                    }
                                }
                            };
                        }
                        let iBox = parseInt(this.arrPackageInfo[i].box);
                        if (iBox > 1) {
                            for (let i = 0; i < iBox; i++)
                                arrPackage.push(objPackage);
                        } else {
                            arrPackage.push(objPackage);
                        }
                    }
                    let data = {
                        "ShipmentRequest": {
                            "Shipment": {
                                "DeliveryTimeInformation": "TRUE",
                                "ShipmentRatingOptions": {
                                    "NegotiatedRatesIndicator": "TRUE"
                                },
                                "Shipper": {
                                    "Name": this.objCurrentShipper.name,
                                    "Company": this.objCurrentShipper.name.company,
                                    "Phone": { "Number": this.objCurrentShipper.phone },
                                    "Email": this.objCurrentShipper.gmail,
                                    "ShipperNumber": "00R549",
                                    "Address": {
                                        "AddressLine": this.objCurrentShipper.address,
                                        "City": this.objCurrentShipper.city,
                                        "StateProvinceCode": this.arrCAProvince[this.objCurrentShipper.province],
                                        "PostalCode": this.objCurrentShipper.postcode,
                                        "CountryCode": "CA"
                                    }
                                },
                                "ShipFrom": {
                                    "Name": this.objCurrentShipper.name,
                                    "Company": this.objCurrentShipper.name.company,
                                    "Phone": { "Number": this.objCurrentShipper.phone },
                                    "Email": this.objCurrentShipper.gmail,
                                    "Address": {
                                        "AddressLine": this.objCurrentShipper.address,
                                        "City": this.objCurrentShipper.city,
                                        "StateProvinceCode": this.arrCAProvince[this.objCurrentShipper.province],
                                        "PostalCode": this.objCurrentShipper.postcode,
                                        "CountryCode": "CA"
                                    }
                                },
                                "ShipTo": {
                                    "Name": this.objCurrentReceiver.name,
                                    "Company": this.objCurrentReceiver.name.company,
                                    "Phone": { "Number": this.objCurrentReceiver.phone },
                                    "Email": this.objCurrentReceiver.gmail,
                                    "Address": {
                                        "AddressLine": this.objCurrentReceiver.address,
                                        "City": this.objCurrentReceiver.city,
                                        "StateProvinceCode": this.arrCAProvince[this.objCurrentReceiver.province],
                                        "PostalCode": this.objCurrentReceiver.postcode,
                                        "CountryCode": "CA"
                                    }
                                },
                                "PaymentInformation": {
                                    "ShipmentCharge": {
                                        "Type": "01",
                                        "BillShipper": {
                                            "AccountNumber": "00R549"
                                        }
                                    }
                                },
                                "Service": {
                                    "Code": this.arrUPSExpress[this.state.iCurrentUPSExpress].id,
                                    "Description": "UPS Shippinig Option"
                                },
                                "Package": arrPackage
                            },
                            "LabelSpecification": {
                                "LabelImageFormat": {
                                    "Code": "GIF"
                                }
                            }
                        }
                    }
                    console.log("ups data:", data);
                    message.loading({ content: '正在更新运单信息……', key: g_strMessageKey });
                    axios.post('/UpdateUPSOrder', {
                        objJetonOrder: objJetonOrder,
                        data: data
                    }).then(({ data }) => {
                        console.log("UpdateUPSOrder response : ", data);
                        if (data.status === 1) {
                            this.GetJetonOrdersData();
                            // 更新用户余额
                            this.fMoney -= fTotalCost;
                            this.fMoney = this.fMoney.toFixed(2);
                            axios.post('/UpdateUserMoney', {
                                id: iUserID,//this.props.iUserID,
                                money: this.fMoney
                            }).then(({ data }) => {
                                console.log("UpdateUserMoney response : ", data);
                            }).catch(function (error) { console.log(error); });
                        }
                        else {
                            this.strUPSMessage = data.message;
                            message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
                            this.setState({
                                ...this.state,
                                bPackageInfoChange: true,
                                bExportExcel: false
                            });
                        }

                    }).catch(function (error) { console.log(error); });
                } else {
                    let objJetonOrder = {
                        store_id: this.props.iStoreID,
                        user_id: iUserID,
                        user_name: strUserName,
                        oid: strOID,
                        type: this.state.iCurrentUPSExpress,
                        status: 0,
                        tracking_no: "",
                        shipper_id: this.objCurrentShipper.id,
                        shipper_name: this.objCurrentShipper.name,
                        receiver_id: this.objCurrentReceiver.id,
                        receiver_name: this.objCurrentReceiver.name,
                        package_info: JSON.stringify(this.arrPackageInfo),
                        total_cost: fTotalCost,
                        comments: this.refs.comments.state.value,
                        create_time: new Date(),
                        update_time: new Date(),
                        price_detail: JSON.stringify(this.objRatedShipment),
                        discount: this.objCurrentJetonOrder.discount,
                        final_price: this.objCurrentJetonOrder.final_price
                    }
                    this.SaveAll(objJetonOrder, iUserID);
                }
            }
        }
        if (this.state.iDlgType === 2) {
            message.loading({ content: '运单取消中……', key: g_strMessageKey });
            console.log("void order id = ", this.objCurrentJetonOrder.id);
            axios.post('/VoidUPSOrder', { id: this.objCurrentJetonOrder.id, tracking_no: this.objCurrentJetonOrder.tracking_no })
                .then(({ data }) => {
                    console.log("data : ", data);
                    if (data.status !== 1) {
                        message.warning({
                            content: "取消失败，请重新尝试！",
                            key: g_strMessageKey, duration: 2
                        });
                        this.setState({
                            ...this.state,
                            bLoading: false,
                            bShowDlg: false,
                            selectedRowKeys: []
                        });
                    } else {
                        this.GetJetonOrdersData();
                    }
                }).catch(function (error) { console.log(error); });
        }
    };
    // 弹窗取消按钮响应
    handleCancel = e => {
        this.setState({
            bShowDlg: false,
            selectedRowKeys: [],
            btnControlDisable: [false, true, true],  // 操作按钮控制
            bExportExcel: false
        });
    };
    // 保存寄件人、收件人、运单信息
    SaveAll = (objJetonOrder, iUserID) => {
        // 1. 保存寄件人信息
        let strName = this.refs.shipper_name.state.value;
        if (strName === undefined || strName === null || strName === "") {
            message.warning("寄件人姓名不能为空！");
            return;
        }
        let strPhone = this.refs.shipper_phone.state.value;
        if (strPhone === undefined || strPhone === null || strPhone === "") {
            message.warning("寄件人电话不能为空！");
            return;
        }
        let strAddress = this.refs.shipper_address.state.value;
        if (strAddress === undefined || strAddress === null || strAddress === "") {
            message.warning("寄件人电话不能为空！");
            return;
        }
        let strPostcode = this.refs.shipper_postcode.state.value;
        if (strPostcode === undefined || strPostcode === null || strPostcode === "") {
            message.warning("寄件人邮编不能为空！");
            return;
        }
        this.objCurrentShipper.name = strName;
        this.objCurrentShipper.phone = strPhone;
        this.objCurrentShipper.address = strAddress;
        this.objCurrentShipper.postcode = strPostcode;
        this.objCurrentShipper.company = this.refs.shipper_company.state.value;
        this.objCurrentShipper.email = this.refs.shipper_email.state.value;
        this.objCurrentShipper.country = "CA";
        this.objCurrentShipper.province = this.state.iCurrentShipperProvince;
        this.objCurrentShipper.city = this.refs.shipper_city.state.value;
        this.objCurrentShipper.store_id = this.props.iStoreID;
        this.objCurrentShipper.user_id = this.props.iUserID;
        delete this.objCurrentShipper.province_str;
        delete this.objCurrentShipper.key;
        console.log(this.objCurrentShipper);
        axios.post('/UpdateJetonShipper', {
            objCurrentShipper: this.objCurrentShipper
        }).then(({ data }) => {
            if (data.status === 1) {
                // 2. 保存收件人信息
                let strName = this.refs.receiver_name.state.value;
                if (strName === undefined || strName === null || strName === "") {
                    message.warning("收件人姓名不能为空！");
                    return;
                }
                let strPhone = this.refs.receiver_phone.state.value;
                if (strPhone === undefined || strPhone === null || strPhone === "") {
                    message.warning("收件人电话不能为空！");
                    return;
                }
                let strAddress = this.refs.receiver_address.state.value;
                if (strAddress === undefined || strAddress === null || strAddress === "") {
                    message.warning("收件人地址不能为空！");
                    return;
                }
                let strPostcode = this.refs.receiver_postcode.state.value;
                if (strPostcode === undefined || strPostcode === null || strPostcode === "") {
                    message.warning("收件人邮编不能为空！");
                    return;
                }
                if (data.id !== undefined)
                    this.objCurrentShipper.id = data.id;
                this.objCurrentReceiver.name = strName;
                this.objCurrentReceiver.phone = strPhone;
                this.objCurrentReceiver.address = strAddress;
                this.objCurrentReceiver.postcode = strPostcode;
                this.objCurrentReceiver.company = this.refs.receiver_company.state.value;
                this.objCurrentReceiver.email = this.refs.receiver_email.state.value;
                this.objCurrentReceiver.country = "CA";
                this.objCurrentReceiver.province = this.state.iCurrentReceiverProvince;
                this.objCurrentReceiver.city = this.refs.receiver_city.state.value;
                this.objCurrentReceiver.store_id = this.props.iStoreID;
                this.objCurrentReceiver.user_id = this.props.iUserID;
                delete this.objCurrentReceiver.province_str;
                delete this.objCurrentReceiver.key;

                axios.post('/UpdateJetonReceiver', {
                    objCurrentReceiver: this.objCurrentReceiver
                }).then(({ data }) => {
                    console.log("data:", data);
                    console.log("data:", data.id);
                    let ID = data.id;
                    if (data.status === 1) {
                        if (ID !== undefined)
                            this.objCurrentReceiver.id = ID;

                        // 3. 运单信息保存
                        let arrUnit = ["CM", "KGS"];
                        if (this.state.iCurrentUnit === 1)
                            arrUnit = ["IN", "LBS"]
                        let arrPackage = [];
                        for (let i = 0; i < this.arrPackageInfo.length; i++) {
                            let arrRefs = [];
                            let objRef1 = this.arrPackageInfo[i].ref1;
                            let objRef2 = this.arrPackageInfo[i].ref2;
                            if (objRef1 !== null && objRef1 !== undefined && objRef1 !== "")
                                arrRefs.push({
                                    "BarCodeIndecator": "TRUE",
                                    "Value": objRef1
                                });
                            if (objRef2 !== null && objRef2 !== undefined && objRef2 !== "")
                                arrRefs.push({
                                    "BarCodeIndecator": "TRUE",
                                    "Value": objRef2
                                });
                            if (arrRefs.length <= 0)
                                arrRefs = null;
                            let objPackage = {
                                "Packaging": {
                                    "Code": "02",
                                    "Description": "Package"
                                },
                                "Dimensions": {
                                    "UnitOfMeasurement": {
                                        "Code": arrUnit[0]
                                    },
                                    "Length": this.arrPackageInfo[i].length,
                                    "Width": this.arrPackageInfo[i].width,
                                    "Height": this.arrPackageInfo[i].height,
                                },
                                "PackageWeight": {
                                    "UnitOfMeasurement": {
                                        "Code": arrUnit[1]
                                    },
                                    "Weight": this.arrPackageInfo[i].weight
                                },
                                "ReferenceNumber": arrRefs
                            };
                            if (this.arrPackageInfo[i].signature) {
                                objPackage = {
                                    "Packaging": {
                                        "Code": "02",
                                        "Description": "Package"
                                    },
                                    "Dimensions": {
                                        "UnitOfMeasurement": {
                                            "Code": arrUnit[0]
                                        },
                                        "Length": this.arrPackageInfo[i].length,
                                        "Width": this.arrPackageInfo[i].width,
                                        "Height": this.arrPackageInfo[i].height,
                                    },
                                    "PackageWeight": {
                                        "UnitOfMeasurement": {
                                            "Code": arrUnit[1]
                                        },
                                        "Weight": this.arrPackageInfo[i].weight
                                    },
                                    "ReferenceNumber": arrRefs,
                                    "PackageServiceOptions": {
                                        "DeliveryConfirmation": {
                                            "DCISType": "2"
                                        }
                                    }
                                };
                            }
                            let iBox = parseInt(this.arrPackageInfo[i].box);
                            if (iBox > 1) {
                                for (let i = 0; i < iBox; i++)
                                    arrPackage.push(objPackage);
                            } else {
                                arrPackage.push(objPackage);
                            }
                        }
                        let data = {
                            "ShipmentRequest": {
                                "Shipment": {
                                    "DeliveryTimeInformation": "TRUE",
                                    "ShipmentRatingOptions": {
                                        "NegotiatedRatesIndicator": "TRUE"
                                    },
                                    "Shipper": {
                                        "Name": this.objCurrentShipper.name,
                                        "Company": this.objCurrentShipper.name.company,
                                        "Phone": { "Number": this.objCurrentShipper.phone },
                                        "Email": this.objCurrentShipper.gmail,
                                        "ShipperNumber": "00R549",
                                        "Address": {
                                            "AddressLine": this.objCurrentShipper.address,
                                            "City": this.objCurrentShipper.city,
                                            "StateProvinceCode": this.arrCAProvince[this.objCurrentShipper.province],
                                            "PostalCode": this.objCurrentShipper.postcode,
                                            "CountryCode": "CA"
                                        }
                                    },
                                    "ShipFrom": {
                                        "Name": this.objCurrentShipper.name,
                                        "Company": this.objCurrentShipper.name.company,
                                        "Phone": { "Number": this.objCurrentShipper.phone },
                                        "Email": this.objCurrentShipper.gmail,
                                        "Address": {
                                            "AddressLine": this.objCurrentShipper.address,
                                            "City": this.objCurrentShipper.city,
                                            "StateProvinceCode": this.arrCAProvince[this.objCurrentShipper.province],
                                            "PostalCode": this.objCurrentShipper.postcode,
                                            "CountryCode": "CA"
                                        }
                                    },
                                    "ShipTo": {
                                        "Name": this.objCurrentReceiver.name,
                                        "Company": this.objCurrentReceiver.name.company,
                                        "Phone": { "Number": this.objCurrentReceiver.phone },
                                        "Email": this.objCurrentReceiver.gmail,
                                        "Address": {
                                            "AddressLine": this.objCurrentReceiver.address,
                                            "City": this.objCurrentReceiver.city,
                                            "StateProvinceCode": this.arrCAProvince[this.objCurrentReceiver.province],
                                            "PostalCode": this.objCurrentReceiver.postcode,
                                            "CountryCode": "CA"
                                        }
                                    },
                                    "PaymentInformation": {
                                        "ShipmentCharge": {
                                            "Type": "01",
                                            "BillShipper": {
                                                "AccountNumber": "00R549"
                                            }
                                        }
                                    },
                                    "Service": {
                                        "Code": this.arrUPSExpress[this.state.iCurrentUPSExpress].id,
                                        "Description": "UPS Shippinig Option"
                                    },
                                    "Package": arrPackage
                                },
                                "LabelSpecification": {
                                    "LabelImageFormat": {
                                        "Code": "GIF"
                                    }
                                }
                            }
                        }
                        objJetonOrder.shipper_id = this.objCurrentShipper.id;
                        objJetonOrder.receiver_id = this.objCurrentReceiver.id;
                        objJetonOrder.receiver_name = this.objCurrentReceiver.name;
                        console.log("Update UPS Order : ", objJetonOrder);
                        message.loading({ content: '正在更新运单信息……', key: g_strMessageKey });
                        axios.post('/UpdateUPSOrder', {
                            objJetonOrder: objJetonOrder,
                            data: data
                        }).then(({ data }) => {
                            console.log("UpdateUPSOrder response : ", data);
                            this.GetShipperData();
                            this.GetReceiverData();
                            if (data.status === 1) {
                                this.GetJetonOrdersData();
                                // 更新用户余额
                                this.fMoney -= this.objCurrentJetonOrder.total_cost;
                                this.fMoney = this.fMoney.toFixed(2);

                                axios.post('/UpdateUserMoney', {
                                    id: iUserID,//this.props.iUserID,
                                    money: this.fMoney
                                }).then(({ data }) => {
                                    console.log("UpdateUserMoney response : ", data);
                                }).catch(function (error) { console.log(error); });
                            }
                            else {
                                this.strUPSMessage = data.message;
                                message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
                                this.setState({
                                    ...this.state,
                                    bPackageInfoChange: true,
                                    bExportExcel: false
                                });
                            }

                        }).catch(function (error) { console.log(error); });

                    } else
                        message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
                }).catch(function (error) { console.log(error); });


            } else
                message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
        }).catch(function (error) { console.log(error); });
    }
    // 整理JetonOrders数据
    OrganizeJetonOrdersData(data) {
        let arrTableData = [];
        for (let i = 0; i < data.length; i++) {
            let objJetonOrderData = data[i];
            objJetonOrderData.key = data[i].id;
            objJetonOrderData.type_str = this.arrUPSExpress[data[i].type].name;
            objJetonOrderData.discount_str = data[i].discount + " %";
            objJetonOrderData.final_price_str = "CAD " + data[i].final_price + "（含税）";
            objJetonOrderData.create_time_str = data[i].create_time.split('T')[0];
            let strStyle = "darkcyan";
            if (data[i].status === 1)
                strStyle = "lightcoral";
            objJetonOrderData.status_str = (
                <div style={{ color: strStyle, fontWeight: 'bold' }}>{this.arrStatus[data[i].status] + " √"}</div>
            );

            arrTableData.push(objJetonOrderData);
        }
        return arrTableData;
    }
    // 从数据库获取当前的数据结构
    GetJetonOrdersData() {
        message.loading({ content: '数据加载中……', key: g_strMessageKey });
        axios('/GetUPSOrders', { params: { store_id: this.props.iStoreID, user_id: this.props.iUserID, level: this.props.iLevel } })
            .then(({ data }) => {
                console.log("ups data : ", data);
                this.arrOgeTableData = this.OrganizeJetonOrdersData(data);
                this.arrTableData = this.arrOgeTableData;
                if (this.state.bShowDlg) {
                    if (this.state.iDlgType === 0) {
                        message.success({ content: "运单创建成功！", key: g_strMessageKey, duration: 2 });
                    }
                    if (this.state.iDlgType === 1) {
                        message.success({ content: "运单信息已更新！", key: g_strMessageKey, duration: 2 });
                    }
                    if (this.state.iDlgType === 2) {
                        message.success({ content: "运单已取消！", key: g_strMessageKey, duration: 2 })
                    }
                } else {
                    message.success({ content: "数据加载完成！", key: g_strMessageKey, duration: 2 });
                }
                this.setState({
                    ...this.state,
                    bLoading: false,
                    bShowDlg: false,
                    selectedRowKeys: [],
                    btnControlDisable: [false, true, true],  // 操作按钮控制
                    bExportExcel: false
                });
            }).catch(function (error) { console.log(error); });
    }
    // 显示全部
    ShowAllOrders = () => {
        this.refs.order_search.state.value = "";
        this.GetJetonOrdersData();
    }
    // UPS API Tracking
    UPSTrackingAPI = () => {
        axios('/UPS_API_Tracking', { params: { tracking_no: this.objCurrentJetonOrder.tracking_no } })
            .then(({ data }) => {
                console.log(data);
            }).catch(function (error) { console.log(error); });

    }
    // UPS API Label Recovery
    UPSLabelAPI = () => {
        if (this.objCurrentJetonOrder.status !== 0) {
            message.warning("运单已取消，无法查看面单！");
            return;
        }
        message.loading({ content: '获取面单链接中……', key: g_strMessageKey });
        axios('/UPS_API_Label', { params: { tracking_no: this.objCurrentJetonOrder.tracking_no } })
            .then(({ data }) => {
                console.log(data.LabelRecoveryResponse);

                message.success({ content: "获取成功！", key: g_strMessageKey, duration: 2 });
                let arrLabelResults = data.LabelRecoveryResponse.LabelResults;
                if (arrLabelResults.length > 1) {
                    // console.log("arrLabelResults : ", arrLabelResults);
                    // strLink = arrLabelResults[0].LabelImage.URL;
                    this.strPDFData = data.LabelRecoveryResponse.LabelResults[0].LabelImage.GraphicImage;
                } else {
                    // strLink = arrLabelResults.LabelImage.URL;
                    this.strPDFData = arrLabelResults.LabelImage.GraphicImage;
                }

                // console.log(this.strPDFData);
                let pdfWindow = window.open("");
                pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(this.strPDFData) + "'></iframe>");

            }).catch(function (error) { console.log(error); });

    }
    // 切换地区
    SwitchCAProvince = (index, ship) => {
        if (ship === 0) {
            this.objCurrentShipper.province = index;
            this.setState({
                ...this.state,
                iCurrentShipperProvince: index
            });
        }
        if (ship === 1) {
            this.objCurrentReceiver.province = index;
            this.setState({
                ...this.state,
                iCurrentReceiverProvince: index
            });
        }

        this.CalculatePrice(this.state.iCurrentUnit, this.state.iCurrentUPSExpress);
    }
    // 从数据库获取当前的数据结构
    GetShipperData = () => {
        axios('/GetJetonShippers', { params: { store_id: this.props.iStoreID, user_id: this.props.iUserID, level: this.props.iLevel } })
            .then(({ data }) => {
                global.arrShipperData = data;
                console.log("GetShipperData : ", data);
            }).catch(function (error) { console.log(error); });
    }
    // 从数据库获取当前的数据结构
    GetReceiverData = () => {
        axios('/GetJetonReceivers', { params: { store_id: this.props.iStoreID, user_id: this.props.iUserID, level: this.props.iLevel } })
            .then(({ data }) => {
                global.arrReceiverData = data;
                console.log("GetReceiverData : ", data);
            }).catch(function (error) { console.log(error); });
    }
    // 显示寄件人对话框
    ShowShipperDlg = (iShow, bShow) => {
        if (iShow === 1) {
            this.setState({
                ...this.state,
                iShipperDlg: iShow,
                bShipperDlg: bShow,
                iCurrentShipperProvince: this.objCurrentShipper.province
            });
            this.CalculatePrice(this.state.iCurrentUnit, this.state.iCurrentUPSExpress);
        } else
            this.setState({
                ...this.state,
                iShipperDlg: iShow,
                bShipperDlg: bShow
            });
    }
    // 选择寄件人
    SelectShipper = (index) => {
        this.objCurrentShipper = global.arrReceiverData[index];
        this.refs.shipper_name.state.value = this.objCurrentShipper.name;
        this.refs.shipper_phone.state.value = this.objCurrentShipper.phone;
        this.refs.shipper_email.state.value = this.objCurrentShipper.email;
        this.refs.shipper_address.state.value = this.objCurrentShipper.address;
        this.refs.shipper_postcode.state.value = this.objCurrentShipper.postcode;
        this.refs.shipper_company.state.value = this.objCurrentShipper.company;
        this.refs.shipper_city.state.value = this.objCurrentShipper.city;
        this.objCurrentJetonOrder.shipper_postcode = this.objCurrentShipper.postcode;
        this.ShowShipperDlg(1, false);
    }
    // 显示收件人对话框
    ShowReceiverDlg = (iShow, bShow) => {
        if (iShow === 1) {
            this.setState({
                ...this.state,
                iReceiverDlg: iShow,
                bReceiverDlg: bShow,
                iCurrentReceiverProvince: this.objCurrentReceiver.province
            });
            this.CalculatePrice(this.state.iCurrentUnit, this.state.iCurrentUPSExpress);
        }
        else
            this.setState({
                ...this.state,
                iReceiverDlg: iShow,
                bReceiverDlg: bShow
            });

    }
    // 选择收件人
    SelectReceiver = (index) => {
        this.objCurrentReceiver = global.arrReceiverData[index];
        // console.log("SelectReceiver : ", this.objCurrentReceiver);
        this.refs.receiver_name.state.value = this.objCurrentReceiver.name;
        this.refs.receiver_phone.state.value = this.objCurrentReceiver.phone;
        this.refs.receiver_email.state.value = this.objCurrentReceiver.email;
        this.refs.receiver_address.state.value = this.objCurrentReceiver.address;
        this.refs.receiver_postcode.state.value = this.objCurrentReceiver.postcode;
        this.refs.receiver_company.state.value = this.objCurrentReceiver.company;
        this.refs.receiver_city.state.value = this.objCurrentReceiver.city;
        this.objCurrentJetonOrder.receiver_postcode = this.objCurrentReceiver.postcode;
        this.ShowReceiverDlg(1, false);
    }
    // 保存寄件人
    SaveShipperInfo = (bMessage) => {
        let strName = this.refs.shipper_name.state.value;
        if (strName === undefined || strName === null || strName === "") {
            message.warning("寄件人姓名不能为空！");
            return;
        }
        let strPhone = this.refs.shipper_phone.state.value;
        if (strPhone === undefined || strPhone === null || strPhone === "") {
            message.warning("寄件人电话不能为空！");
            return;
        }
        let strAddress = this.refs.shipper_address.state.value;
        if (strAddress === undefined || strAddress === null || strAddress === "") {
            message.warning("寄件人电话不能为空！");
            return;
        }
        let strPostcode = this.refs.shipper_postcode.state.value;
        if (strPostcode === undefined || strPostcode === null || strPostcode === "") {
            message.warning("寄件人邮编不能为空！");
            return;
        }
        this.objCurrentShipper.name = strName;
        this.objCurrentShipper.phone = strPhone;
        this.objCurrentShipper.address = strAddress;
        this.objCurrentShipper.postcode = strPostcode;
        this.objCurrentShipper.company = this.refs.shipper_company.state.value;
        this.objCurrentShipper.email = this.refs.shipper_email.state.value;
        this.objCurrentShipper.country = "CA";
        this.objCurrentShipper.province = this.state.iCurrentShipperProvince;
        this.objCurrentShipper.city = this.refs.shipper_city.state.value;
        this.objCurrentShipper.store_id = this.props.iStoreID;
        this.objCurrentShipper.user_id = this.props.iUserID;
        delete this.objCurrentShipper.province_str;
        delete this.objCurrentShipper.key;
        console.log(this.objCurrentShipper);
        if (bMessage)
            message.loading({ content: '正在保存寄件人……', key: g_strMessageKey });
        axios.post('/UpdateJetonShipper', {
            objCurrentShipper: this.objCurrentShipper
        }).then(({ data }) => {
            // console.log("UpdateJetonShipper response : ", data);
            if (data.status === 1) {
                if (bMessage)
                    message.success({ content: "寄件人已保存！", key: g_strMessageKey, duration: 2 });
                if (data.id !== undefined)
                    this.objCurrentShipper.id = data.id;
                this.GetShipperData();
            } else
                message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
        }).catch(function (error) { console.log(error); });
        // if (this.objCurrentShipper.id !== undefined) {

        // } else {
        //     axios.post('/NewJetonShipper', this.objCurrentShipper).then(({ data }) => {
        //         // console.log("NewShipper response : ", data);
        //         if (data.status === 1) {
        //             if (bMessage)
        //                 message.success({ content: "寄件人已保存！", key: g_strMessageKey, duration: 2 });
        //             this.GetShipperData();
        //         } else
        //             message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
        //     }).catch(function (error) { console.log(error); });
        // }

    }
    // 保存收件人
    SaveReceiverInfo = (bMessage) => {
        // console.log("save receiver info");
        let strName = this.refs.receiver_name.state.value;
        if (strName === undefined || strName === null || strName === "") {
            message.warning("收件人姓名不能为空！");
            return;
        }
        let strPhone = this.refs.receiver_phone.state.value;
        if (strPhone === undefined || strPhone === null || strPhone === "") {
            message.warning("收件人电话不能为空！");
            return;
        }
        let strAddress = this.refs.receiver_address.state.value;
        if (strAddress === undefined || strAddress === null || strAddress === "") {
            message.warning("收件人地址不能为空！");
            return;
        }
        let strPostcode = this.refs.receiver_postcode.state.value;
        if (strPostcode === undefined || strPostcode === null || strPostcode === "") {
            message.warning("收件人邮编不能为空！");
            return;
        }
        this.objCurrentReceiver.name = strName;
        this.objCurrentReceiver.phone = strPhone;
        this.objCurrentReceiver.address = strAddress;
        this.objCurrentReceiver.postcode = strPostcode;
        this.objCurrentReceiver.company = this.refs.receiver_company.state.value;
        this.objCurrentReceiver.email = this.refs.receiver_email.state.value;
        this.objCurrentReceiver.country = "CA";
        this.objCurrentReceiver.province = this.state.iCurrentReceiverProvince;
        this.objCurrentReceiver.city = this.refs.receiver_city.state.value;
        this.objCurrentReceiver.store_id = this.props.iStoreID;
        this.objCurrentReceiver.user_id = this.props.iUserID;
        delete this.objCurrentReceiver.province_str;
        delete this.objCurrentReceiver.key;
        console.log(this.objCurrentReceiver);
        if (bMessage)
            message.loading({ content: '正在保存收件人……', key: g_strMessageKey });
        // if (this.objCurrentReceiver.id !== undefined) {
        axios.post('/UpdateJetonReceiver', {
            objCurrentReceiver: this.objCurrentReceiver
        }).then(({ data }) => {
            // console.log("UpdateJetonReceiver response : ", data);
            if (data.status === 1) {
                if (bMessage)
                    message.success({ content: "收件人已保存！", key: g_strMessageKey, duration: 2 });
                if (data.id !== undefined)
                    this.objCurrentReceiver.id = data.id;
                this.GetReceiverData();
            } else
                message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
        }).catch(function (error) { console.log(error); });
        // } else {
        //     axios.post('/NewJetonReceiver', this.objCurrentReceiver).then(({ data }) => {
        //         // console.log("NewReceiver response : ", data);
        //         if (data.status === 1) {
        //             if (bMessage)
        //                 message.success({ content: "收件人已保存！", key: g_strMessageKey, duration: 2 });
        //             this.GetReceiverData();
        //         } else
        //             message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
        //     }).catch(function (error) { console.log(error); });
        // }

    }
    // 切换单位
    SwitchUnit = (index) => {
        this.CalculatePrice(index, this.state.iCurrentUPSExpress);
        this.setState({
            ...this.state,
            iCurrentUnit: index
        });
    }
    // 切换 UPS Express
    SwitchUPSExpress = (index) => {
        if (index === 0) {
            message.warning("请选择一种UPS服务！");
            this.strUPSMessage = "请选择一种UPS服务！";
            this.objCurrentJetonOrder.total_cost = 0.0;
            // this.refs.price.state.value = "CAD 0.0";
            this.bPriceSettled = false;
        } else {
            this.CalculatePrice(this.state.iCurrentUnit, index);
        }
        this.setState({
            ...this.state,
            iCurrentUPSExpress: index
        });
    }
    // 包裹列表面板相应
    PackagePanelChange = (key) => {
        console.log("PackagePanelChange: ", key)
        this.setState({
            ...this.state,
            arrCurrentPackage: key
        });
    }
    // 新增包裹
    AddNewPackage = () => {
        if (!this.bPriceSettled && this.state.iUPSExpress > 0) {
            message.info("价格计算中，请耐心等待……");
            return;
        }
        let objPackage = {
            weight: '0.1', length: '1.0', width: '1.0', height: '1.0', unit: 0, value: '0.0', ref1: '', ref2: '', signature: false, box: 1
        }
        if (this.bSignAll)
            objPackage = {
                weight: '0.1', length: '1.0', width: '1.0', height: '1.0', unit: 0, value: '0.0', ref1: '', ref2: '', signature: true, box: 1
            }
        this.arrPackageInfo.push(objPackage);
        let arrCurrentPackage = this.state.arrCurrentPackage;
        arrCurrentPackage.push(this.arrPackageInfo.length - 1);
        this.setState({
            ...this.state,
            bPackageInfoChange: true,
            arrCurrentPackage: arrCurrentPackage
        });
        this.CalculatePrice(this.state.iCurrentUnit, this.state.iCurrentUPSExpress);
    }
    // 删除包裹
    DeletePackage = (index) => {
        if (!this.bPriceSettled && this.state.iUPSExpress > 0) {
            message.info("价格计算中，请耐心等待……");
            return;
        }
        console.log("Void Package : ", index);
        if (this.arrPackageInfo.length <= 1) {
            message.warning("还少要有一个包裹！");
            return;
        }
        this.UpdatePackageInfo();
        this.arrPackageInfo.splice(index, 1);
        this.SetPackageInfo();
        this.setState({
            ...this.state,
            bPackageInfoChange: true,
        });
        this.CalculatePrice(this.state.iCurrentUnit, this.state.iCurrentUPSExpress);
    }
    // 更新/记录包裹信息
    UpdatePackageInfo = (e) => {
        for (let i = 0; i < this.arrPackageInfo.length; i++) {
            if (this.refs["weight" + i] === undefined || this.refs["weight" + i] === null)
                continue;
            else {
                this.arrPackageInfo[i].weight = this.refs["weight" + i].state.value;
                this.arrPackageInfo[i].length = this.refs["length" + i].state.value;
                this.arrPackageInfo[i].width = this.refs["width" + i].state.value;
                this.arrPackageInfo[i].height = this.refs["height" + i].state.value;
                this.arrPackageInfo[i].value = this.refs["value" + i].state.value;
                this.arrPackageInfo[i].ref1 = this.refs["ref1_" + i].state.value;
                this.arrPackageInfo[i].ref2 = this.refs["ref2_" + i].state.value;
                this.arrPackageInfo[i].box = this.refs["box_" + i].state.value;
            }

        }
        console.log("update package info : ", this.arrPackageInfo);
    }
    // 设置包裹信息
    SetPackageInfo = () => {
        for (let i = 0; i < this.arrPackageInfo.length; i++) {
            this.refs["weight" + i].state.value = this.arrPackageInfo[i].weight;
            this.refs["length" + i].state.value = this.arrPackageInfo[i].length;
            this.refs["width" + i].state.value = this.arrPackageInfo[i].width;
            this.refs["height" + i].state.value = this.arrPackageInfo[i].height;
            this.refs["value" + i].state.value = this.arrPackageInfo[i].value;
            this.refs["ref1_" + i].state.value = this.arrPackageInfo[i].ref1;
            this.refs["ref2_" + i].state.value = this.arrPackageInfo[i].ref2;
            this.refs["box_" + i].state.value = this.arrPackageInfo[i].box;
        }
        console.log("SetPackageInfo : ", this.arrPackageInfo);
    }
    // 检查包裹信息
    CheckPackageInfo = () => {
        this.UpdatePackageInfo();
        for (let i = 0; i < this.arrPackageInfo.length; i++) {
            // weight 称重
            if (isNaN(this.arrPackageInfo[i].weight) || this.arrPackageInfo[i].weight === null || this.arrPackageInfo[i].weight === undefined) {
                message.info("包裹" + (i + 1).toString() + "的称重不是有效数值，不能是字母、空格、逗号等特殊符号！");
                return -1;
            } else {
                if (parseFloat(this.arrPackageInfo[i].weight) <= 0.0) {
                    message.info("包裹" + (i + 1).toString() + "的称重必须大于 0 ！");
                    return -1;
                }
                if (this.arrPackageInfo[i].weight === "" || this.arrPackageInfo[i].weight.trim() === "") {
                    message.info("包裹" + (i + 1).toString() + "的称重不能空白！");
                    return -1;
                }
            }
            // length 称重
            if (isNaN(this.arrPackageInfo[i].length) || this.arrPackageInfo[i].length === null || this.arrPackageInfo[i].length === undefined) {
                message.info("包裹" + (i + 1).toString() + "的长度不是有效数值，不能是字母、空格、逗号等特殊符号！");
                return -1;
            } else {
                if (parseFloat(this.arrPackageInfo[i].length) <= 0.0) {
                    message.info("包裹" + (i + 1).toString() + "的长度必须大于 0 ！");
                    return -1;
                }
                if (this.arrPackageInfo[i].length === "" || this.arrPackageInfo[i].length.trim() === "") {
                    message.info("包裹" + (i + 1).toString() + "的长度不能空白！");
                    return -1;
                }
            }
            // width 宽度
            if (isNaN(this.arrPackageInfo[i].width) || this.arrPackageInfo[i].width === null || this.arrPackageInfo[i].width === undefined) {
                message.info("包裹" + (i + 1).toString() + "的宽度不是有效数值，不能是字母、空格、逗号等特殊符号！");
                return -1;
            } else {
                if (parseFloat(this.arrPackageInfo[i].width) <= 0.0) {
                    message.info("包裹" + (i + 1).toString() + "的宽度必须大于 0 ！");
                    return -1;
                }
                if (this.arrPackageInfo[i].width === "" || this.arrPackageInfo[i].width.trim() === "") {
                    message.info("包裹" + (i + 1).toString() + "的宽度不能空白！");
                    return -1;
                }
            }
            // height 高度
            if (isNaN(this.arrPackageInfo[i].height) || this.arrPackageInfo[i].height === null || this.arrPackageInfo[i].height === undefined) {
                message.info("包裹" + (i + 1).toString() + "的高度不是有效数值，不能是字母、空格、逗号等特殊符号！");
                return -1;
            } else {
                if (parseFloat(this.arrPackageInfo[i].height) <= 0.0) {
                    message.info("包裹" + (i + 1).toString() + "的高度必须大于 0 ！");
                    return -1;
                }
                if (this.arrPackageInfo[i].height === "" || this.arrPackageInfo[i].height.trim() === "") {
                    message.info("包裹" + (i + 1).toString() + "的高度不能空白！");
                    return -1;
                }
            }
        }
        console.log("CheckPackageInfo : ", this.arrPackageInfo);
        return 1;
    }
    // 更新/记录包裹信息
    UpdatePeopleInfo = (e) => {
        this.objCurrentShipper.name = this.refs.shipper_name.state.value;
        this.objCurrentShipper.company = this.refs.shipper_company.state.value;
        this.objCurrentShipper.phone = this.refs.shipper_phone.state.value;
        this.objCurrentShipper.email = this.refs.shipper_email.state.value;
        this.objCurrentShipper.city = this.refs.shipper_city.state.value;
        this.objCurrentShipper.address = this.refs.shipper_address.state.value;
        this.objCurrentShipper.postcode = this.refs.shipper_postcode.state.value;

        this.objCurrentShipper.name = this.refs.shipper_name.state.value;
        this.objCurrentShipper.company = this.refs.shipper_company.state.value;
        this.objCurrentShipper.phone = this.refs.shipper_phone.state.value;
        this.objCurrentShipper.email = this.refs.shipper_email.state.value;
        this.objCurrentShipper.city = this.refs.shipper_city.state.value;
        this.objCurrentShipper.address = this.refs.shipper_address.state.value;
        this.objCurrentShipper.postcode = this.refs.shipper_postcode.state.value;
        console.log("UpdatePeopleInfo : ", this.objCurrentShipper, this.objCurrentReceiver);
    }
    // 检查寄件人收件人信息
    CheckPeopleInfo = () => {
        this.UpdatePeopleInfo();
        // 寄件人
        let strName = this.refs.shipper_name.state.value;
        if (strName === null || strName === undefined || strName === "") {
            message.warning("寄件人姓名不能为空！");
            return -1;
        }
        let strCompany = this.refs.shipper_company.state.value;
        if (strCompany === null || strCompany === undefined || strCompany === "") {
            this.refs.shipper_company.state.value = "";
        }
        let strPhone = this.refs.shipper_phone.state.value;
        if (strPhone === null || strPhone === undefined || strPhone === "") {
            message.warning("寄件人电话不能为空！");
            return -1;
        }
        let strEmail = this.refs.shipper_email.state.value;
        if (strEmail === null || strEmail === undefined || strEmail === "") {
            this.refs.shipper_email.state.value = "";
        }
        let strPostcode = this.refs.shipper_postcode.state.value;
        if (strPostcode === null || strPostcode === undefined || strPostcode === "") {
            message.warning("寄件人邮编不能为空！");
            return -1;
        }
        let strCity = this.refs.shipper_city.state.value;
        if (strCity === null || strCity === undefined || strCity === "") {
            this.refs.shipper_city.state.value = "";
        }
        let strAdress = this.refs.shipper_address.state.value;
        if (strAdress === null || strAdress === undefined || strAdress === "") {
            message.warning("寄件人地址不能为空！");
            return -1;
        }
        // 收件人
        strName = this.refs.receiver_name.state.value;
        if (strName === null || strName === undefined || strName === "") {
            message.warning("收件人姓名不能为空！");
            return -1;
        }
        strCompany = this.refs.receiver_company.state.value;
        if (strCompany === null || strCompany === undefined || strCompany === "") {
            this.refs.receiver_company.state.value = "";
        }
        strPhone = this.refs.receiver_phone.state.value;
        if (strPhone === null || strPhone === undefined || strPhone === "") {
            message.warning("收件人电话不能为空！");
            return -1;
        }
        strEmail = this.refs.receiver_email.state.value;
        if (strEmail === null || strEmail === undefined || strEmail === "") {
            this.refs.receiver_email.state.value = "";
        }
        strPostcode = this.refs.receiver_postcode.state.value;
        if (strPostcode === null || strPostcode === undefined || strPostcode === "") {
            message.warning("收件人邮编不能为空！");
            return -1;
        }
        strCity = this.refs.receiver_city.state.value;
        if (strCity === null || strCity === undefined || strCity === "") {
            this.refs.receiver_city.state.value = "";
        }
        strAdress = this.refs.receiver_address.state.value;
        if (strAdress === null || strAdress === undefined || strAdress === "") {
            message.warning("收件人地址不能为空！");
            return -1;
        }
        return 1;
    }
    // 寄件人、收件人、货物等信息发生变化
    PackageInfoChange = (pack, index, e) => {
        if (!this.state.bShowDlg || this.bPriceCalculating)
            return;
        this.bPriceSettled = false;
        if (index === 0) {
            this.refs["weight" + pack.toString()].state.value = e.target.value;
            this.arrPackageInfo[pack].weight = e.target.value;

        }
        if (index === 1) {
            this.refs["length" + pack.toString()].state.value = e.target.value;
            this.arrPackageInfo[pack].length = e.target.value;

        }
        if (index === 2) {
            this.refs["width" + pack.toString()].state.value = e.target.value;
            this.arrPackageInfo[pack].width = e.target.value;

        }
        if (index === 3) {
            this.refs["height" + pack.toString()].state.value = e.target.value;
            this.arrPackageInfo[pack].height = e.target.value;

        }
        if (index === 4) {
            this.refs["box_" + pack.toString()].state.value = e.target.value;
            let iTotalBox = 0;
            for (let i = 0; i < this.arrPackageInfo.length; i++) {
                let iBox = parseInt(this.arrPackageInfo[i].box);
                if (iBox >= 1 && i !== pack)
                    iTotalBox++;
            }
            let iBoxAdd = parseInt(e.target.value);
            if (iBoxAdd <= 0)
                iBoxAdd = 1;
            if (isNaN(iBoxAdd) || iBoxAdd === null || iBoxAdd === undefined || iBoxAdd === "")
                iBoxAdd = 1;
            if (typeof (iBoxAdd) === "string")
                if (iBoxAdd.trim() === "")
                    iBoxAdd = 1;

            if ((iTotalBox + iBoxAdd) > 50) {
                message.warning("所有包裹的总箱数累计不能超过50！请检查！");
                iBoxAdd = 50 - iTotalBox;

            }
            // else {
            //     this.arrPackageInfo[pack].box = iBoxAdd;//e.target.value;
            // }
            this.arrPackageInfo[pack].box = iBoxAdd;

        }
        this.setState({
            ...this.state,
            bPackageInfoChange: true
        });
        if (this.state.iCurrentUPSExpress > 0)
            this.CalculatePrice(this.state.iCurrentUnit, this.state.iCurrentUPSExpress);

    }
    // 寄件人、收件人信息变化
    PeopleInfoChange = (type, e) => {
        if (!this.state.bShowDlg)
            return;
        if (type === 0 /*&& this.refs.shipper_postcode !== undefined*/) {
            // this.refs.shipper_postcode.state.value = e.target.value;
            this.objCurrentShipper.postcode = e.target.value;
            this.objCurrentJetonOrder.shipper_postcode = e.target.value;
        }
        if (type === 1/* && this.refs.receiver_postcode !== undefined*/) {
            // this.refs.receiver_postcode.state.value = e.target.value;
            this.objCurrentReceiver.postcode = e.target.value;
            this.objCurrentJetonOrder.receiver_postcode = e.target.value;
        }

        if (this.state.iCurrentUPSExpress > 0) {
            this.bPriceSettled = false;
            this.bPriceCalculating = false;
            this.strUPSMessage = "";
            this.setState({
                ...this.state,
                bPackageInfoChange: true,
                iCurrentUPSExpress: 0
            });
        } else {
            this.setState({
                ...this.state,
                bPackageInfoChange: true
            });
        }

        // if (this.state.iCurrentUPSExpress > 0)
        //     this.CalculatePrice(this.state.iCurrentUnit, this.state.iCurrentUPSExpress);
    }
    // 价格预估
    CalculatePrice = (iUnit, iUPSExpress) => {
        if (iUPSExpress === 0 || this.state.iDlgType === 1) {
            return;
        }

        if (this.CheckPeopleInfo() !== 1)
            return;
        if (this.CheckPackageInfo() !== 1)
            return;
        let arrUnit = ["CM", "KGS"];
        if (iUnit === 1)
            arrUnit = ["IN", "LBS"]
        let arrPackage = [];
        for (let i = 0; i < this.arrPackageInfo.length; i++) {
            let objPackage = {
                "PackagingType": {
                    "Code": "02",
                    "Description": "Package"
                },
                "Dimensions": {
                    "UnitOfMeasurement": {
                        "Code": arrUnit[0]
                    },
                    "Length": this.arrPackageInfo[i].length,
                    "Width": this.arrPackageInfo[i].width,
                    "Height": this.arrPackageInfo[i].height,
                },
                "PackageWeight": {
                    "UnitOfMeasurement": {
                        "Code": arrUnit[1]
                    },
                    "Weight": this.arrPackageInfo[i].weight
                }
            };
            // if (this.arrPackageInfo[i].signature)  // 需要签字服务
            if (this.bSignAll)
                objPackage = {
                    "PackagingType": {
                        "Code": "02",
                        "Description": "Package"
                    },
                    "Dimensions": {
                        "UnitOfMeasurement": {
                            "Code": arrUnit[0]
                        },
                        "Length": this.arrPackageInfo[i].length,
                        "Width": this.arrPackageInfo[i].width,
                        "Height": this.arrPackageInfo[i].height,
                    },
                    "PackageWeight": {
                        "UnitOfMeasurement": {
                            "Code": arrUnit[1]
                        },
                        "Weight": this.arrPackageInfo[i].weight
                    },
                    "PackageServiceOptions": {
                        "DeliveryConfirmation": {
                            "DCISType": "2"
                        }
                    }
                };
            let iBox = parseInt(this.arrPackageInfo[i].box);
            if (iBox > 1) {
                for (let i = 0; i < iBox; i++)
                    arrPackage.push(objPackage);
            } else {
                arrPackage.push(objPackage);
            }
        }
        let data = {
            "RateRequest": {
                "Request": {
                    "SubVersion": "1703",
                    "TransactionReference": {
                        "CustomerContext": ""
                    }
                },
                "Shipment": {
                    "DeliveryTimeInformation": "TRUE",
                    "ShipmentRatingOptions": {
                        "NegotiatedRatesIndicator": "TRUE"
                    },
                    "Shipper": {
                        "Name": this.refs.shipper_name.state.value,
                        "ShipperNumber": "00R549",
                        "Address": {
                            "AddressLine": this.refs.shipper_address.state.value,
                            "City": this.refs.shipper_city.state.value,
                            "StateProvinceCode": this.arrCAProvince[this.objCurrentShipper.province],
                            "PostalCode": this.refs.shipper_postcode.state.value,
                            "CountryCode": "CA"
                        }
                    },
                    "ShipFrom": {
                        "Name": this.refs.shipper_name.state.value,
                        "Address": {
                            "AddressLine": this.refs.shipper_address.state.value,
                            "City": this.refs.shipper_city.state.value,
                            "StateProvinceCode": this.arrCAProvince[this.objCurrentShipper.province],
                            "PostalCode": this.refs.shipper_postcode.state.value,
                            "CountryCode": "CA"
                        }
                    },
                    "ShipTo": {
                        "Name": this.refs.receiver_name.state.value,
                        "Address": {
                            "AddressLine": this.refs.receiver_address.state.value,
                            "City": this.refs.receiver_city.state.value,
                            "StateProvinceCode": this.arrCAProvince[this.objCurrentReceiver.province],
                            "PostalCode": this.refs.receiver_postcode.state.value,
                            "CountryCode": "CA"
                        }
                    },
                    "Service": {
                        "Code": this.arrUPSExpress[iUPSExpress].id,
                        "Description": "UPS Shippinig Option"
                    },
                    "Package":
                        arrPackage
                }
            }
        }
        // console.log("data : ", data);
        message.loading({ content: '价格计算中……', key: g_strMessageKey });
        this.bPriceSettled = false;
        this.bPriceCalculating = true;
        this.strUPSMessage = "";
        axios('/UPS_API_Rating', { params: { data: data } })
            .then(({ data }) => {
                // console.log("UPS_API_Rating : response : ", data);
                if (data.RateResponse !== undefined) {
                    // console.log("UPS_API_Rating :", data.RateResponse.RatedShipment);
                    this.objRatedShipment = data.RateResponse.RatedShipment;
                    console.log("UPS Oge Data, objRatedShipment :", this.objRatedShipment);
                    this.PriceDetailSetting();

                    // UPS 报的优惠价格
                    this.fClientCharges = this.objRatedShipment.NegotiatedRateCharges.TotalCharge.MonetaryValue;
                    // 加上VIP等级的用户报价
                    this.arrVIP = [1.5, 1.3, 1.25, 1.2, 1.15, 1.1, 1.08, 1.05];  // 临时VIP设置
                    this.fClientCharges *= 1.0;//this.arrVIP[this.iVIP];
                    this.fClientCharges = this.fClientCharges.toFixed(2);
                    // console.log("fClientCharges :", this.fClientCharges);

                    this.bPriceCalculating = false;
                    if (data.status === -1) {
                        console.log("Rating Get Failed 1 !");
                        message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
                        this.strUPSMessage = data.message;
                        this.objCurrentJetonOrder.total_cost = 0.0;
                        // this.refs.price.state.value = "CAD 0.0";
                        this.bPriceSettled = false;
                        this.bPriceCalculating = false;

                    } else {
                        let objResult = data.RateResponse.Response.ResponseStatus;
                        if (objResult.Code === 1 || objResult.Code === "1") {
                            message.success({ content: "价格已更新！", key: g_strMessageKey, duration: 2 });
                            let fPrice = parseFloat(this.fClientCharges);  // 不进行一下float转换，后面的toFixed会报错
                            this.objCurrentJetonOrder.total_cost = fPrice;// * this.arrVIP[this.iVIP];
                            this.objCurrentJetonOrder.total_cost = this.objCurrentJetonOrder.total_cost.toFixed(2);
                            this.objCurrentJetonOrder.discount = parseFloat(this.objCurrentJetonOrder.discount);
                            if (this.objCurrentJetonOrder.discount === undefined || this.objCurrentJetonOrder.discount === null ||
                                this.objCurrentJetonOrder.discount === "" ||
                                isNaN(this.objCurrentJetonOrder.discount))
                                this.objCurrentJetonOrder.discount = 100.0;

                            this.objCurrentJetonOrder.final_price = (this.objCurrentJetonOrder.total_cost * this.objCurrentJetonOrder.discount) / 100.0 * 1.05; // GST
                            this.objCurrentJetonOrder.final_price = this.objCurrentJetonOrder.final_price.toFixed(2);
                            // total cost - UPS报价， final price - 给用户的报价
                            // console.log("jeton order: ", this.objCurrentJetonOrder);
                            // console.log(this.objCurrentJetonOrder.total_cost, this.objCurrentJetonOrder.final_price);
                            this.bPriceSettled = true;
                            this.strUPSMessage = " √ ";
                        }
                    }
                } else {
                    // console.log("Rating Get Failed 2 !");
                    if (data.message !== undefined)
                        message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
                    this.strUPSMessage = data.message;
                    this.objCurrentJetonOrder.total_cost = 0.0;
                    // this.refs.price.state.value = "CAD 0.0";
                    this.bPriceSettled = false;
                    this.bPriceCalculating = false;
                }

                this.setState({
                    ...this.state,
                    bPackageInfoChange: true
                })
            }).catch(function (error) { console.log(error); });
    }
    // 价格细节设置
    PriceDetailSetting = () => {
        // console.log("PriceDeitalSetting");
        this.objUPSStandard = { name: 'UPS Standard', rate: this.objRatedShipment.BaseServiceCharge.MonetaryValue };
        this.objFuelSurcharge = { name: 'Fuel Surcharge', rate: 0.0 };
        this.objResidentialAddressSurcharge = {
            name: 'Residential Address Surcharge',
            rate: 0.0
        }
        this.objPeakDemandSurcharge = {
            name: 'Peak and Demand Surcharge',
            rate: 0.0
        }
        if (this.objRatedShipment.ItemizedCharges.length >= 1) {
            this.objFuelSurcharge = { name: 'Fuel Surcharge', rate: this.objRatedShipment.ItemizedCharges[0].MonetaryValue };
            if (this.objRatedShipment.ItemizedCharges.length >= 2)
                this.objResidentialAddressSurcharge = {
                    name: 'Residential Address Surcharge',
                    rate: this.objRatedShipment.ItemizedCharges[1].MonetaryValue
                }
            if (this.objRatedShipment.ItemizedCharges.length >= 3)
                this.objPeakDemandSurcharge = {
                    name: 'Peak and Demand Surcharge',
                    rate: this.objRatedShipment.ItemizedCharges[2].MonetaryValue
                }
        } else {
            this.objFuelSurcharge = { name: 'Fuel Surcharge', rate: this.objRatedShipment.ItemizedCharges.MonetaryValue };
            this.objResidentialAddressSurcharge = {
                name: 'Residential Address Surcharge',
                rate: 0.0
            }
            this.objPeakDemandSurcharge = {
                name: 'Peak and Demand Surcharge',
                rate: 0.0
            }
        }
        this.objDeliveryConfirmation = null;
        this.objAdditionalHandling = null;
        // if (this.objRatedShipment.RatedPackage.ItemizedCharges !== undefined) {
        let bOversize = false;
        for (let i = 0; i < this.arrPackageInfo.length; i++) {
            let fLength = this.arrPackageInfo[i].length;
            fLength = parseFloat(fLength);
            let fWidth = this.arrPackageInfo[i].width;
            fWidth = parseFloat(fWidth);
            let fHeight = this.arrPackageInfo[i].height;
            fHeight = parseFloat(fHeight);
            if (fLength >= 122.5 || fWidth >= 122.5 || fHeight >= 122.5) {
                bOversize = true; break;
            }
        }
        if (this.arrPackageInfo.length <= 1) {
            let iBox = parseInt(this.arrPackageInfo[0].box);
            let fDeliveryRate = 0.0;
            let fHandlingRate = 0.0;
            if (this.bSignAll) {
                if (bOversize) {
                    if (iBox <= 1) {
                        fDeliveryRate = this.objRatedShipment.RatedPackage.ItemizedCharges[0].MonetaryValue;
                        fHandlingRate = this.objRatedShipment.RatedPackage.ItemizedCharges[1].MonetaryValue;
                    } else {
                        fDeliveryRate = this.objRatedShipment.RatedPackage[0].ItemizedCharges[0].MonetaryValue * iBox;
                        fHandlingRate = this.objRatedShipment.RatedPackage[0].ItemizedCharges[1].MonetaryValue * iBox;
                    }
                } else {
                    if (iBox <= 1) {
                        fDeliveryRate = this.objRatedShipment.RatedPackage.ItemizedCharges.MonetaryValue
                    } else {
                        fDeliveryRate = this.objRatedShipment.RatedPackage[0].ItemizedCharges.MonetaryValue * iBox;
                    }
                }
            } else {
                if (bOversize) {
                    if (iBox <= 1) {
                        fDeliveryRate = this.objRatedShipment.RatedPackage.ItemizedCharges.MonetaryValue
                    } else {
                        fDeliveryRate = this.objRatedShipment.RatedPackage[0].ItemizedCharges.MonetaryValue * iBox;
                    }
                }
            }
            this.objDeliveryConfirmation = {
                name: 'Delivery Confirmation',
                rate: fDeliveryRate
            }
            this.objAdditionalHandling = {
                name: 'Additional Handling',
                rate: fHandlingRate
            }
        } else {
            let fDeliveryRate = 0.0;
            let fHandlingRate = 0.0;
            for (let i = 0; i < this.arrPackageInfo.length; i++) {
                bOversize = false;
                let fLength = this.arrPackageInfo[i].length;
                fLength = parseFloat(fLength);
                let fWidth = this.arrPackageInfo[i].width;
                fWidth = parseFloat(fWidth);
                let fHeight = this.arrPackageInfo[i].height;
                fHeight = parseFloat(fHeight);
                if (fLength >= 122.5 || fWidth >= 122.5 || fHeight >= 122.5) {
                    bOversize = true;
                }
                let iBox = parseInt(this.arrPackageInfo[i].box);
                if (this.bSignAll) {
                    if (bOversize) {
                        // fDeliveryRate += parseFloat(this.objRatedShipment.RatedPackage[i].ItemizedCharges[0].MonetaryValue) * iBox;
                        // fHandlingRate += parseFloat(this.objRatedShipment.RatedPackage[i].ItemizedCharges[1].MonetaryValue) * iBox;
                        fDeliveryRate += 5.70 * iBox;
                        fHandlingRate += 20.45 * iBox;
                    } else {
                        fDeliveryRate += 5.70 * iBox;
                    }

                } else {
                    if (bOversize)
                        fHandlingRate += 20.45 * iBox;
                }
            }
            fDeliveryRate = fDeliveryRate.toFixed(2);
            fHandlingRate = fHandlingRate.toFixed(2);
            this.objDeliveryConfirmation = {
                name: 'Delivery Confirmation',
                rate: fDeliveryRate
            }
            this.objAdditionalHandling = {
                name: 'Additional Handling',
                rate: fHandlingRate
            }
        }

        this.objTotalCharges = {
            name: 'Total Charges',
            rate: this.objRatedShipment.TotalCharges.MonetaryValue
        }
        console.log("UPS Oge Price, this.objTotalCharges rate = ", this.objTotalCharges.rate);
    }
    // 物流追踪
    TrackingPackage = () => {
        message.loading({ content: '物流状态获取中……', key: g_strMessageKey });
        // this.objCurrentJetonOrder.tracking_no = "1Z00R5496817756385"; // test
        axios('/UPS_API_Tracking', { params: { tracking_no: this.objCurrentJetonOrder.tracking_no } })
            .then(({ data }) => {
                message.info({ content: "物流状态获取完成！", key: g_strMessageKey, duration: 2 });
                this.arrTrackingActivies = [];
                let arrActivities = data.trackResponse.shipment[0].package[0].activity;
                for (let i = arrActivities.length - 1; i >= 0; i--) {
                    this.arrTrackingActivies.push(arrActivities[i]);
                }
                console.log(this.arrTrackingActivies);
                this.ShowTrackingDlg(true);
            }).catch(function (error) { console.log(error); });
    }
    // 物流状态窗口
    ShowTrackingDlg = (bShow) => {
        this.setState({
            ...this.state,
            bTrackingDlg: bShow
        });
    }
    // 获取物流状态名称
    GetTrackingActivityName = (code) => {
        let strName = "";
        for (let i = 0; i < this.arrUPSTransitType.length; i++) {
            if (this.arrUPSTransitType[i].code === code) {
                strName = this.arrUPSTransitType[i].value;
                break;
            }
        }
        return strName;
    }
    // 签字确认
    SignatureCheck = (index, e) => {
        if (this.bPriceCalculating)
            return;
        // console.log('SignatureCheck =', e.target.checked, index);
        if (index === -1) {
            for (let i = 0; i < this.arrPackageInfo.length; i++) {
                this.arrPackageInfo[i].signature = e.target.checked;
            }
            this.bSignAll = e.target.checked;
        } else {
            this.arrPackageInfo[index].signature = e.target.checked;
        }
        this.CalculatePrice(this.state.iCurrentUnit, this.state.iCurrentUPSExpress);
        this.setState({
            ...this.state,
            bLoading: false
        })
    }
    // 查看价格明细
    UPSPriceDetail = (bShow) => {
        if (bShow) {
            console.log(this.objRatedShipment);
        }
        this.setState({
            ...this.state,
            bPriceDetailDlg: bShow,
            iPriceDlgType: 0
        })
    }
    // 查看价格明细
    ClientPriceDetail = (bShow) => {
        if (bShow) {
            console.log(this.objRatedShipment);
        }

        this.setState({
            ...this.state,
            bPriceDetailDlg: bShow,
            iPriceDlgType: 1
        })
    }
    // 导出Excel文档
    ExportExcelFile = () => {

        let arrExportData = [
            ['用户名', '下单日期', 'Tracking No.', 'Service', 'Postal Code', 'Billing Weight',
                // 'Shipping Fee', 'Additional Option Fees', 'Combined Charges',
                'UPS Contract Rated', 'UPS Tax', 'UPS Total (With Tax and Discount)', '折扣', 'Provincial Tax', '实际收费（含Tax）',
                '包裹信息', '称重', '单位', '货值', '长度', '宽度', '高度', 'Ref#1', 'Ref#2', '签字确认 ']
        ];
        let arrExportSpace = ['', '', '', '', '', '', '', '', '', '', '', ''];
        for (let i = 0; i < this.arrSelectedOrder.length; i++) {
            let arrData = [];
            arrData.push(this.arrSelectedOrder[i].user_name);
            arrData.push(this.arrSelectedOrder[i].create_time.split('T')[0]);
            arrData.push(this.arrSelectedOrder[i].tracking_no);
            arrData.push(this.arrUPSExpress[this.arrSelectedOrder[i].type].name);
            // 获得目标Receiver Postcode
            for (let j = 0; j < global.arrReceiverData.length; j++) {
                if (global.arrReceiverData[j].id === this.arrSelectedOrder[i].receiver_id) {
                    arrData.push(global.arrReceiverData[j].postcode);
                    break;
                }
            }
            let objRatedShipment = JSON.parse(this.arrSelectedOrder[i].price_detail);
            arrData.push(objRatedShipment.BillingWeight.Weight);
            // arrData.push(objRatedShipment.TransportationCharges.MonetaryValue);
            // arrData.push(objRatedShipment.ServiceOptionsCharges.MonetaryValue);
            // arrData.push(objRatedShipment.TotalCharges.MonetaryValue);
            let fTotal = parseFloat(objRatedShipment.NegotiatedRateCharges.TotalCharge.MonetaryValue);
            arrData.push(fTotal);
            arrData.push((fTotal * 0.05).toFixed(2));
            arrData.push((fTotal * 1.05).toFixed(2));
            arrData.push(this.arrSelectedOrder[i].discount);
            let strGST = (parseFloat(this.arrSelectedOrder[i].final_price) / 1.05) * 0.05;
            strGST = strGST.toFixed(2);
            arrData.push(strGST);
            arrData.push(this.arrSelectedOrder[i].final_price);

            // 包裹信息
            let arrPackageInfo = JSON.parse(this.arrSelectedOrder[i].package_info);
            if (arrPackageInfo.length > 1) {
                arrData.push('包裹 1');
                arrData.push(arrPackageInfo[0].weight);
                arrData.push(this.arrUnit[arrPackageInfo[0].unit]);
                arrData.push(arrPackageInfo[0].value);
                arrData.push(arrPackageInfo[0].length);
                arrData.push(arrPackageInfo[0].width);
                arrData.push(arrPackageInfo[0].height);
                arrData.push(arrPackageInfo[0].ref1);
                arrData.push(arrPackageInfo[0].ref2);
                if (arrPackageInfo[0].signature)
                    arrData.push('是');
                else
                    arrData.push('否');
                arrExportData.push(arrData);
                arrExportSpace = ['', '', '', '', '', '', '', '', '', '', '', ''];
                arrData = arrExportSpace;
                for (let j = 1; j < arrPackageInfo.length; j++) {
                    arrData.push('包裹 ' + (j + 1).toString());
                    arrData.push(arrPackageInfo[j].weight);
                    arrData.push(this.arrUnit[arrPackageInfo[j].unit]);
                    arrData.push(arrPackageInfo[j].value);
                    arrData.push(arrPackageInfo[j].length);
                    arrData.push(arrPackageInfo[j].width);
                    arrData.push(arrPackageInfo[j].height);
                    arrData.push(arrPackageInfo[j].ref1);
                    arrData.push(arrPackageInfo[j].ref2);
                    if (arrPackageInfo[j].signature)
                        arrData.push('是');
                    else
                        arrData.push('否');
                    arrExportData.push(arrData);
                }
            } else {
                arrData.push('包裹 1');
                arrData.push(arrPackageInfo[0].weight);
                arrData.push(this.arrUnit[arrPackageInfo[0].unit]);
                arrData.push(arrPackageInfo[0].value);
                arrData.push(arrPackageInfo[0].length);
                arrData.push(arrPackageInfo[0].width);
                arrData.push(arrPackageInfo[0].height);
                arrData.push(arrPackageInfo[0].ref1);
                arrData.push(arrPackageInfo[0].ref2);
                if (arrPackageInfo[0].signature)
                    arrData.push('是');
                else
                    arrData.push('否');
                arrExportData.push(arrData);
            }
            // arrExportData.push(this.arrSelectedOrder[i].comments);

            // 运单之间的空行
            arrExportSpace = ['', '', '', '', '', '', '', '', '', '', '', ''];
            arrExportData.push(arrExportSpace);

        }
        console.log("export excel file", arrExportData);
        var sheet = XLSX.utils.aoa_to_sheet(arrExportData);
        openDownloadDialog(sheet2blob(sheet), 'UPS运单明细.xlsx');

    }
    // 折扣编辑
    DiscountChange = (e) => {
        this.objCurrentJetonOrder.discount = e.target.value;
        // this.refs.discount.state.value = e.target.value;
        this.objCurrentJetonOrder.discount = parseFloat(this.objCurrentJetonOrder.discount);
        if (this.objCurrentJetonOrder.discount === undefined || this.objCurrentJetonOrder.discount === null || this.objCurrentJetonOrder.discount === "" ||
            isNaN(this.objCurrentJetonOrder.discount)
        ) this.objCurrentJetonOrder.discount = 100.0;
        // if (this.objCurrentJetonOrder.discount !== null && this.objCurrentJetonOrder.discount !== undefined && this.objCurrentJetonOrder.discount !== "" &&
        //     !isNaN(this.objCurrentJetonOrder.discount)) {
        this.objCurrentJetonOrder.final_price = ((this.objCurrentJetonOrder.discount * this.objCurrentJetonOrder.total_cost) / 100.0) * 1.05;
        this.objCurrentJetonOrder.final_price = this.objCurrentJetonOrder.final_price.toFixed(2);
        // }
        this.fClientCharges = this.objCurrentJetonOrder.final_price / 1.05;
        this.fClientCharges = parseFloat(this.fClientCharges);
        this.fClientCharges = this.fClientCharges.toFixed(2);

        this.setState({
            ...this.state,
            bLoading: false
        })
    }
    // 最终价格编辑
    FinalPriceModify = (e) => {
        this.objCurrentJetonOrder.final_price = e.target.value;
        // this.refs.final_price.state.value = e.target.value;
        this.setState({
            ...this.state,
            bLoading: false
        })
    }
    // 选择用户
    SelectOrderUser = (index, bDlg) => {
        console.log("SelectOrderUser : ", index, bDlg);
        if (index >= 0) {
            this.objCurrentJetonOrder.user_id = global.arrUserData[index].id;
            this.objCurrentJetonOrder.user_name = global.arrUserData[index].name;
            this.fMoney = global.arrUserData[index].money;
            this.iVIP = global.arrUserData[index].vip;
            if (this.iVIP === null || this.iVIP === undefined || this.iVIP === '' || this.iVIP < 0)
                this.iVIP = 0;
            if (this.fMoney === null || this.fMoney === undefined || this.fMoney === '' || this.fMoney < 0.0)
                this.fMoney = 0.0;
            this.CalculatePrice(this.state.iCurrentUnit, this.state.iCurrentUPSExpress);
        }

        this.setState({
            ...this.state,
            bUserDlg: bDlg
        })
    }
    // 从数据库获取当前的数据结构
    GetUsersData = () => {
        message.loading({ content: '数据加载中……', key: g_strMessageKey });
        let objParms = {
            store_id: this.props.iStoreID,
            level: this.props.iLevel
        }
        if (this.props.iLevel < 9)
            objParms = {
                store_id: this.props.iStoreID,
                user_id: this.props.iUserID
            }
        axios('/GetUsers', { params: objParms })
            .then(({ data }) => {
                // console.log(data);
                global.arrUserData = data;
            }).catch(function (error) { console.log(error); });
    }
    // 从数据库获取当前的数据结构
    GetVipPriceData = () => {
        message.loading({ content: '数据加载中……', key: g_strMessageKey });
        axios('/GetVipPrice', { params: { store_id: this.props.iStoreID } })
            .then(({ data }) => {
                console.log(data);
                global.arrVipData = data;
                this.arrLevel = [];
                this.arrVIP = [];
                if (global.arrVipData.length >= 1) {
                    for (let i = global.arrVipData.length - 1; i >= 0; i--) {
                        let fPrice = parseFloat(global.arrVipData[i].price);
                        fPrice = fPrice.toFixed(2);
                        this.arrVIP.push(fPrice);
                        this.arrLevel.push(global.arrVipData[i].name);
                    }
                }
            }).catch(function (error) { console.log(error); });
    }

    /* React自带函数 */
    // 卸载异步操作设置状态
    componentWillUnmount() {
        clearTimeout(this.timeouter)
        this.setState = (state, callback) => {
            return;
        }
    }
    // 渲染完毕……
    componentDidMount() {

        this.GetJetonOrdersData();
        // if (global.arrShipperData.length <= 0)
        this.GetShipperData();
        // if (global.arrReceiverData.length <= 0)
        this.GetReceiverData();
        if (global.arrUserData.length <= 0 && this.props.iLevel >= 9)
            this.GetUsersData();
        if (global.arrVipData.length <= 0)
            this.GetVipPriceData();
        else {
            this.arrLevel = [];
            this.arrVIP = [];
            if (global.arrVipData.length >= 1) {
                for (let i = global.arrVipData.length - 1; i >= 0; i--) {
                    let fPrice = parseFloat(global.arrVipData[i].price);
                    fPrice = fPrice.toFixed(2);
                    this.arrVIP.push(fPrice);
                    this.arrLevel.push(global.arrVipData[i].name);
                }
            }
        }
    }
    // 渲染函数
    render() {

        let uiNewButton = null;
        let uiEditButton = (
            <Button className="manage_contents_title_margin" type="primary" onClick={this.ShowDlg.bind(this, 1)} disabled={this.state.btnControlDisable[1]}>
                <Icon type="search" />查看</Button>
        );
        let uiCancelButton = null;
        let uiExportExcelButton = null;
        // if (this.props.iLevel >= 5) {
        uiNewButton = (
            <Button className="manage_contents_title_margin" type="primary" onClick={this.ShowDlg.bind(this, 0)} disabled={this.state.btnControlDisable[0]}>
                <Icon type="edit" />新建</Button>
        );
        uiCancelButton = (
            <Button className="manage_contents_title_margin" type="danger" onClick={this.ShowDlg.bind(this, 2)} disabled={this.state.btnControlDisable[2]}>
                <Icon type="close" />取消</Button>
        );
        if (this.props.iLevel >= 9) {
            uiExportExcelButton = (<Button className="manage_contents_title_margin manage_contents_title_margin_right export_button_style" onClick={this.ExportExcelFile}
                disabled={!this.state.bExportExcel}>
                <Icon type="export" /> 导出Excel</Button>);
            uiEditButton = (
                <Button className="manage_contents_title_margin" type="primary" onClick={this.ShowDlg.bind(this, 1)} disabled={this.state.btnControlDisable[1]}>
                    <Icon type="edit" />编辑</Button>
            );
        }

        // }

        /* 运单管理页面控制栏 */
        let uiTitleArea = (
            < div className="store_contents_title" >
                <div className="manage_stretch_right">
                    {uiNewButton}
                    {uiEditButton}
                    {uiCancelButton}
                    <Input placeholder="单号/用户/收寄件人/备注" defaultValue={this.state.strKeywords} style={{ marginLeft: '20px' }} ref="order_search" />
                    <Button className="manage_contents_title_margin"
                        type="primary" onClick={this.JetonOrderSearch}>搜 索
                    </Button>
                    <Button className="manage_contents_title_margin" style={{ marginRight: '20px' }}
                        type="primary" onClick={this.ShowAllOrders}>显示全部
                    </Button>
                    {uiExportExcelButton}
                </div>
            </div >
        );

        // 如果界面上需要显示的内容已经从数据库获得
        if (!this.state.bLoading) {
            // 主体表格相关
            const { selectedRowKeys } = this.state;
            const rowSelection = {
                selectedRowKeys,
                onChange: this.onSelectedRowKeysChange,
                columnWidth: 25
            };
            // 表格分页属性
            const paginationProps = {
                showSizeChanger: true,
                showQuickJumper: false,
                showTotal: () => `共 ${this.arrTableData.length} 个 运单`,
                defaultPageSize: 10,
                defaultCurrent: this.state.iCurrentPage,
                pageSizeOptions: ['10', '20', '50', '100'],
                total: this.arrTableData.length,
                position: 'bottom'
            };
            // 运单信息编辑弹窗
            let bDisable = false;
            let uiDlgTitle = null;
            let strDlgTitle = "新建 UPS 运单";
            if (this.state.iDlgType === 2)
                strDlgTitle = "取消 UPS 运单";
            uiDlgTitle = (<div>{strDlgTitle}</div>);
            if (this.state.iDlgType === 1) {
                strDlgTitle = "UPS 运单 " + this.objCurrentJetonOrder.tracking_no;
                bDisable = false;
                if (this.objCurrentJetonOrder.status === 1)
                    bDisable = true;
                uiDlgTitle = (
                    <div className="jeton_order_dlg_title">
                        {strDlgTitle}
                        <Button style={{ marginLeft: 'auto', marginRight: '10px' }} /*disabled={bDisable}*/
                            type="primary" onClick={this.TrackingPackage}>查看物流状态
                        </Button>
                        <Button className="manage_contents_title_margin" style={{ marginLeft: '10px' }} disabled={bDisable}
                            type="primary" onClick={this.UPSLabelAPI}>打印面单
                        </Button>
                        <div style={{ color: 'lightcoral', marginLeft: '10px', marginRight: '50px' }}>* 推荐使用Chrome浏览 *</div>
                    </div>
                );
            }

            // 地区下拉菜单
            const menuCNAreasShip = (
                <Menu>
                    {this.arrCAProvince.map((province, index) => {
                        return (
                            <Menu.Item key={index} onClick={this.SwitchCAProvince.bind(this, index, 0)}>
                                {province}
                            </Menu.Item>
                        );
                    })}
                </Menu>
            );
            const menuCNAreasReceive = (
                <Menu>
                    {this.arrCAProvince.map((province, index) => {
                        return (
                            <Menu.Item key={index} onClick={this.SwitchCAProvince.bind(this, index, 1)}>
                                {province}
                            </Menu.Item>
                        );
                    })}
                </Menu>
            );
            // 单位下拉菜单
            const menuUnit = (
                <Menu>
                    {this.arrUnit.map((unit, index) => {
                        return (
                            <Menu.Item key={index} onClick={this.SwitchUnit.bind(this, index)}>
                                {unit}
                            </Menu.Item>
                        );
                    })}
                </Menu>
            );
            // UPS快递方式菜单
            const menuUPSExpress = (
                <Menu>
                    {this.arrUPSExpress.map((ups, index) => {
                        return (
                            <Menu.Item key={index} onClick={this.SwitchUPSExpress.bind(this, index)}>
                                {ups.name}
                            </Menu.Item>
                        );
                    })}
                </Menu>
            );

            if (this.arrPackageInfo.length === 0) {
                let objPackage = {
                    weight: '0.1', length: '1.0', width: '1.0', height: '1.0', unit: 0, value: '0.0', ref1: '', ref2: '', signature: false, box: 1
                }
                this.arrPackageInfo.push(objPackage);
            }
            let uiPackageList = null;
            let strUnit1 = 'kg'; let strUnit2 = 'cm';
            if (this.state.iCurrentUnit === 1) {
                strUnit1 = 'lb'; strUnit2 = 'in';
            }

            uiPackageList = (
                < Collapse defaultActiveKey={[0]} activeKey={this.state.arrCurrentPackage} onChange={this.PackagePanelChange}>
                    {this.arrPackageInfo.map((pack, index) => {
                        return (
                            <Panel header={"包裹 " + (index + 1).toString()} key={index} >
                                <div className="jeton_order_row">
                                    <div className="jeton_order_row_title jeton_order_row_title_require" >称重：</div>
                                    <Input className="jeton_order_row_input" ref={"weight" + index} defaultValue={pack.weight} addonAfter={strUnit1}
                                        onChange={this.PackageInfoChange.bind(this, index, 0)} value={pack.weight} disabled={bDisable} />
                                    <div className="jeton_order_row_title" >单位：</div>
                                    <Dropdown overlay={menuUnit} trigger={['click']} placement="bottomLeft" disabled={bDisable}
                                        overlayStyle={{ maxHeight: '75vh', overflow: 'auto' }} style={{ maxHeight: '75vh', overflow: 'auto' }}>
                                        <Button className="jeton_order_row_input">
                                            <div className="order_details_dropdown_text">{this.arrUnit[this.state.iCurrentUnit]}</div>
                                            <Icon type="caret-down" />
                                        </Button>
                                    </Dropdown>
                                    <div className="jeton_order_row_title" >货值：</div>
                                    <Input className="jeton_order_row_input" ref={"value" + index} defaultValue={pack.value} addonBefore={'CAD'}
                                        disabled={bDisable} />
                                </div>
                                <div className="jeton_order_row">
                                    <div className="jeton_order_row_title jeton_order_row_title_require" >长度：</div>
                                    <Input className="jeton_order_row_input" ref={"length" + index} defaultValue={pack.length} addonAfter={strUnit2}
                                        onChange={this.PackageInfoChange.bind(this, index, 1)} value={pack.length} disabled={bDisable} />
                                    <div className="jeton_order_row_title jeton_order_row_title_require" >宽度：</div>
                                    <Input className="jeton_order_row_input" ref={"width" + index} defaultValue={pack.width} addonAfter={strUnit2}
                                        onChange={this.PackageInfoChange.bind(this, index, 2)} value={pack.width} disabled={bDisable} />
                                    <div className="jeton_order_row_title jeton_order_row_title_require" >高度：</div>
                                    <Input className="jeton_order_row_input" ref={"height" + index} defaultValue={pack.height} addonAfter={strUnit2}
                                        onChange={this.PackageInfoChange.bind(this, index, 3)} value={pack.height} disabled={bDisable} />
                                </div>
                                <div className="jeton_order_row">
                                    <div className="jeton_order_row_title " >Ref #1</div>
                                    <Input className="jeton_order_row_input" ref={"ref1_" + index} defaultValue={pack.ref1} disabled={bDisable} />
                                    <div className="jeton_order_row_title " >Ref #2</div>
                                    <Input className="jeton_order_row_input" ref={"ref2_" + index} defaultValue={pack.ref2} disabled={bDisable} />
                                </div>
                                <div className="jeton_order_row">
                                    <div className="jeton_order_row_title " >箱数：</div>
                                    <Input className="jeton_order_row_input" ref={"box_" + index} defaultValue={pack.box} value={pack.box} disabled={bDisable}
                                        placeholder={"每一单不能超过50"} onChange={this.PackageInfoChange.bind(this, index, 4)} />
                                    {/* <Checkbox onChange={this.SignatureCheck.bind(this, index)} checked={pack.signature} disabled={bDisable} >
                                        <b>签字确认</b></Checkbox> */}
                                    <Button type="danger" className="jeton_order_delete_button" onClick={this.DeletePackage.bind(this, index)}
                                        disabled={bDisable}>×</Button>
                                </div>
                            </Panel>
                        );
                    })}
                </Collapse >
            );
            // 新建/查看对话框
            let uiModal = null;
            if (this.state.bShowDlg) {
                let uiFooter = (
                    <div className="jeton_order_row">
                        <Button className="jeton_order_block_button" onClick={this.handleCancel}
                            style={{ marginLeft: 'auto', marginRight: '10px' }}>取 消</Button>
                        <Button type="primary" className="jeton_order_block_button" disabled={!this.bPriceSettled}
                            onClick={this.handleOk}>下 单</Button>
                    </div>
                );
                let bDisabled = false;
                if (this.state.iDlgType === 1) {
                    uiFooter = null;
                    bDisabled = true;
                    if (this.props.iLevel >= 9) {
                        uiFooter = (
                            <div className="jeton_order_row">
                                <Button className="jeton_order_block_button" onClick={this.handleCancel}
                                    style={{ marginLeft: 'auto', marginRight: '10px' }}>取 消</Button>
                                <Button type="primary" className="jeton_order_block_button"
                                    onClick={this.handleOk}>保 存</Button>
                            </div>
                        );
                    }
                }

                let uiMessageLoading = null;
                if (this.bPriceCalculating)
                    uiMessageLoading = <Spin style={{ marginLeft: '20px' }} />;

                let bPriceDetailDisabled = true;
                if (this.bPriceSettled || this.state.iDlgType === 1)
                    bPriceDetailDisabled = false;

                let uiPriceDetail = null;
                if (this.props.iLevel >= 9) {
                    if (this.objTotalCharges !== null && this.objTotalCharges !== undefined)
                        this.objCurrentJetonOrder.total_cost = this.objTotalCharges.rate;
                    // console.log("render show new dlg : ", this.objCurrentJetonOrder.total_cost, this.objTotalCharges);
                    uiPriceDetail = (
                        <div className="jeton_order_row">
                            <div className="jeton_order_row_title jeton_order_row_title_require" >UPS价格</div>
                            <Input className="jeton_order_row_input" ref="total_cost"
                                value={this.objCurrentJetonOrder.total_cost + " （不含Tax）"} disabled
                                style={{ background: 'white', color: 'brown', fontWeight: 'bold' }} addonBefore='CAD' />
                            <Button type="primary" className="jeton_view_detail_button" disabled={bPriceDetailDisabled}
                                onClick={this.UPSPriceDetail.bind(this, true)}>UPS明细</Button>
                        </div>
                    );
                }

                let uiUserInfo = (
                    <div className="jeton_order_row">
                        <div className="jeton_order_row_title jeton_order_row_title_require" >余额：</div>
                        <Input className="jeton_order_row_input" value={"CAD " + this.fMoney} disabled
                            style={{ background: 'white', color: 'darkcyan', fontWeight: 'bold' }} />
                        <div className="jeton_order_row_title jeton_order_row_title_require" >报价：</div>
                        <Input className="jeton_order_row_input" ref="final_price" value={this.objCurrentJetonOrder.final_price}
                            onChange={this.FinalPriceModify.bind(this)} disabled={bDisabled}
                            style={{ background: 'white', color: 'brown', fontWeight: 'bold' }} addonBefore='CAD' />
                        <Button type="primary" className="jeton_view_detail_button" disabled={bPriceDetailDisabled}
                            onClick={this.ClientPriceDetail.bind(this, true)}>报价明细</Button>
                    </div>
                );
                // if (this.props.iLevel >= 9) {
                //     let bUserSelect = false;
                //     if (this.state.iDlgType === 1)
                //         bUserSelect = true;
                //     uiUserInfo = (
                //         <div className="jeton_order_row">
                //             <div className="jeton_order_row_title jeton_order_row_title_require" >用户</div>
                //             <Input className="jeton_order_row_input" ref="total_cost" value={this.objCurrentJetonOrder.user_name} disabled />
                //             <Button type="primary" className="jeton_view_detail_button" disabled={bUserSelect}
                //                 onClick={this.SelectOrderUser.bind(this, -1, true)}>选 择</Button>
                //             <div className="jeton_order_row_title jeton_order_row_title_require" >余额：</div>
                //             <Input className="jeton_order_row_input" value={"CAD " + this.fMoney} disabled
                //                 style={{ background: 'white', color: 'darkcyan', fontWeight: 'bold' }} />
                //         </div>
                //     );
                // }

                if (this.state.iDlgType === 0 && this.fClientCharges !== null && this.fClientCharges !== undefined) {
                    this.objCurrentJetonOrder.final_price = this.fClientCharges * 1.05;
                    this.objCurrentJetonOrder.final_price = parseFloat(this.objCurrentJetonOrder.final_price);
                    this.objCurrentJetonOrder.final_price = (this.objCurrentJetonOrder.final_price).toFixed(2);
                }
                let strGST = (this.objCurrentJetonOrder.final_price / 1.05) * 0.05;
                if (strGST !== null && strGST !== undefined && strGST !== "" && isNaN(strGST))
                    strGST = 0.0;
                strGST = strGST.toFixed(2);
                let uiPriceControl = null;
                let uiPriceInfo = null;
                // if (this.props.iLevel >= 9) {
                //     uiPriceControl = (
                //         <div className="jeton_order_row">
                //             <div className="jeton_order_row_title jeton_order_row_title_require" >折扣率：</div>
                //             <Input className="jeton_order_row_input" ref="discount" value={this.objCurrentJetonOrder.discount}
                //                 onChange={this.DiscountChange.bind(this)} disabled={bDisabled}
                //                 style={{ background: 'white', color: 'brown', fontWeight: 'bold' }} addonAfter='%' />
                //             <div className="jeton_order_row_title jeton_order_row_title_require" >Tax：</div>
                //             <Input className="jeton_order_row_input" ref="final_price" value={strGST}
                //                 disabled style={{ background: 'white', color: 'brown', fontWeight: 'bold' }} addonBefore='CAD' />

                //         </div>
                //     );
                //     uiPriceInfo = (
                //         <div className="jeton_order_row">
                //             <div className="jeton_order_row_title jeton_order_row_title_require" >报价：</div>
                //             <Input className="jeton_order_row_input" ref="final_price" value={this.objCurrentJetonOrder.final_price}
                //                 onChange={this.FinalPriceModify.bind(this)} disabled={bDisabled}
                //                 style={{ background: 'white', color: 'brown', fontWeight: 'bold' }} addonBefore='CAD' />
                //             <Button type="primary" className="jeton_view_detail_button" disabled={bPriceDetailDisabled}
                //                 onClick={this.ClientPriceDetail.bind(this, true)}>报价明细</Button>
                //         </div>
                //     );
                // }
                let bCommentDisable = false;
                if (this.state.iDlgType === 1 && this.props.iLevel < 9)
                    bCommentDisable = true;

                uiModal = (
                    <Modal title={uiDlgTitle} width={999} visible={this.state.bShowDlg} footer={uiFooter}
                        /*okText="确定" cancelText="取消" onOk={this.handleOk}*/ onCancel={this.handleCancel} >
                        <div className="jeton_order_block jeton_order_block_ups">
                            <div className="jeton_order_block_title" >
                                寄件人信息
                                <Button type="primary" className="jeton_order_block_button" onClick={this.ShowShipperDlg.bind(this, 0, true)}
                                    disabled={bDisable}>选择</Button>
                                {/* <Button type="primary" className="jeton_order_block_button" style={{ marginLeft: '0px' }} disabled={bDisable}
                                    onClick={this.SaveShipperInfo.bind(this, true)}>保存</Button> */}
                            </div>
                            <div className="jeton_order_row">
                                <div className="jeton_order_row_title jeton_order_row_title_require" >寄件人：</div>
                                <Input className="jeton_order_row_input" ref="shipper_name" disabled={bDisable}
                                    defaultValue={this.objCurrentShipper.name} />
                                <div className="jeton_order_row_title" >公司：</div>
                                <Input className="jeton_order_row_input" ref="shipper_company" disabled={bDisable}
                                    defaultValue={this.objCurrentShipper.company} />
                            </div>
                            <div className="jeton_order_row">
                                <div className="jeton_order_row_title jeton_order_row_title_require" >电话：</div>
                                <Input className="jeton_order_row_input" ref="shipper_phone" disabled={bDisable}
                                    defaultValue={this.objCurrentShipper.phone} />
                                <div className="jeton_order_row_title" >邮箱：</div>
                                <Input className="jeton_order_row_input" ref="shipper_email" disabled={bDisable}
                                    defaultValue={this.objCurrentShipper.email} />
                            </div>
                            <div className="jeton_order_row">
                                <div className="jeton_order_row_title jeton_order_row_title_require" >国家地区</div>
                                <Input className="jeton_order_row_input" ref="shipper_country" disabled value={"CA"}
                                    defaultValue={this.objCurrentShipper.country} />
                                <div className="jeton_order_row_title jeton_order_row_title_require" >省份：</div>
                                <Dropdown overlay={menuCNAreasShip} trigger={['click']} placement="bottomLeft" className={"jeton_order_row_input"}
                                    overlayStyle={{ maxHeight: '75vh', overflow: 'auto' }} style={{ maxHeight: '75vh', overflow: 'auto' }} disabled={bDisable} >
                                    <Button className="jeton_order_row_input">
                                        <div className="order_details_dropdown_text">{this.arrCAProvince[this.state.iCurrentShipperProvince]}</div>
                                        <Icon type="caret-down" />
                                    </Button>
                                </Dropdown>
                                <div className="jeton_order_row_title" >城市：</div>
                                <Input className="jeton_order_row_input" ref="shipper_city" disabled={bDisable}
                                    defaultValue={this.objCurrentShipper.city} value={this.objCurrentShipper.city} />
                            </div>
                            <div className="jeton_order_row">
                                <div className="jeton_order_row_title jeton_order_row_title_require" >详细地址</div>
                                <Input className="jeton_order_row_input_long" ref="shipper_address" disabled={bDisable}
                                    defaultValue={this.objCurrentShipper.address} value={this.objCurrentShipper.address} />
                                <div className="jeton_order_row_title jeton_order_row_title_require" >邮编：</div>
                                <Input className="jeton_order_row_input" ref="shipper_postcode" defaultValue={this.objCurrentShipper.postcode} disabled={bDisable}
                                    onChange={this.PeopleInfoChange.bind(this, 0)} value={this.objCurrentShipper.postcode} />
                            </div>
                        </div>
                        <div className="jeton_order_block jeton_order_block_ups">
                            <div className="jeton_order_block_title" >
                                收件人信息
                                <Button type="primary" className="jeton_order_block_button" onClick={this.ShowReceiverDlg.bind(this, 0, true)}
                                    disabled={bDisable}>选择</Button>
                                {/* <Button type="primary" className="jeton_order_block_button" style={{ marginLeft: '0px' }} disabled={bDisable}
                                    onClick={this.SaveReceiverInfo.bind(this, true)}>保存</Button> */}
                            </div>
                            <div className="jeton_order_row">
                                <div className="jeton_order_row_title jeton_order_row_title_require" >收件人：</div>
                                <Input className="jeton_order_row_input" ref="receiver_name" disabled={bDisable}
                                    defaultValue={this.objCurrentReceiver.name} />
                                <div className="jeton_order_row_title" >公司：</div>
                                <Input className="jeton_order_row_input" ref="receiver_company" disabled={bDisable}
                                    defaultValue={this.objCurrentReceiver.company} />
                            </div>
                            <div className="jeton_order_row">
                                <div className="jeton_order_row_title jeton_order_row_title_require" >电话：</div>
                                <Input className="jeton_order_row_input" ref="receiver_phone" disabled={bDisable}
                                    defaultValue={this.objCurrentReceiver.phone} />
                                <div className="jeton_order_row_title" >邮箱：</div>
                                <Input className="jeton_order_row_input" ref="receiver_email" disabled={bDisable}
                                    defaultValue={this.objCurrentReceiver.email} />
                            </div>
                            <div className="jeton_order_row">
                                <div className="jeton_order_row_title jeton_order_row_title_require" >国家地区</div>
                                <Input className="jeton_order_row_input" ref="receiver_address" disabled value={"CA"}
                                    defaultValue={this.objCurrentReceiver.address} />
                                <div className="jeton_order_row_title jeton_order_row_title_require" >省份：</div>
                                <Dropdown overlay={menuCNAreasReceive} trigger={['click']} placement="bottomLeft" disabled={bDisable}
                                    overlayStyle={{ maxHeight: '75vh', overflow: 'auto' }} style={{ maxHeight: '75vh', overflow: 'auto' }}>
                                    <Button className="jeton_order_row_input" >
                                        <div className="order_details_dropdown_text">{this.arrCAProvince[this.state.iCurrentReceiverProvince]}</div>
                                        <Icon type="caret-down" />
                                    </Button>
                                </Dropdown>
                                <div className="jeton_order_row_title" >城市：</div>
                                <Input className="jeton_order_row_input" ref="receiver_city" disabled={bDisable}
                                    defaultValue={this.objCurrentReceiver.city} />
                            </div>
                            <div className="jeton_order_row">
                                <div className="jeton_order_row_title jeton_order_row_title_require" >详细地址</div>
                                <Input className="jeton_order_row_input_long" ref="receiver_address" disabled={bDisable}
                                    defaultValue={this.objCurrentReceiver.address} />
                                <div className="jeton_order_row_title jeton_order_row_title_require" >邮编：</div>
                                <Input className="jeton_order_row_input" ref="receiver_postcode" onChange={this.PeopleInfoChange.bind(this, 1)} disabled={bDisable}
                                    defaultValue={this.objCurrentReceiver.postcode} value={this.objCurrentReceiver.postcode} />
                            </div>
                        </div>
                        <div className="jeton_order_block jeton_order_block_ups">
                            <div className="jeton_order_block_title" >
                                包裹信息
                                <Checkbox onChange={this.SignatureCheck.bind(this, -1)} checked={this.bSignAll} disabled={bDisable}
                                    className="jeton_order_block_button" style={{ minWidth: '100px' }} >
                                    <b>签字确认</b></Checkbox>
                                <Button type="primary" className="jeton_order_block_button" style={{ marginLeft: '20px' }}
                                    onClick={this.AddNewPackage}
                                    disabled={bDisable}>+ 添加</Button>
                            </div>
                            <div className="jeton_order_package_area">
                                {uiPackageList}
                            </div>
                            <div className="jeton_order_row" style={{ marginTop: '15px', marginBottom: '5px' }}>
                                <div className="jeton_order_row_title" >备注:</div>
                                <Input className="jeton_order_row_input_full" ref="comments" style={{ marginRight: '0px' }} disabled={bCommentDisable}
                                    defaultValue={this.objCurrentJetonOrder.comments} />
                            </div>
                        </div>
                        <div className="jeton_order_block jeton_order_block_ups">
                            <div className="jeton_order_block_title" >
                                UPS 快递服务
                                {uiMessageLoading}
                                <div className="jeton_order_block_details">
                                    {this.strUPSMessage}
                                </div>
                            </div>
                            <div className="jeton_order_row">
                                <div className="jeton_order_row_title jeton_order_row_title_require" >类别：</div>
                                <Dropdown overlay={menuUPSExpress} trigger={['click']} placement="bottomLeft" disabled={bDisable}
                                    overlayStyle={{ maxHeight: '75vh', overflow: 'auto' }} style={{ maxHeight: '75vh', overflow: 'auto' }}>
                                    <Button className="jeton_order_row_input">
                                        <div className="order_details_dropdown_text">{this.arrUPSExpress[this.state.iCurrentUPSExpress].name}</div>
                                        <Icon type="caret-down" />
                                    </Button>
                                </Dropdown>
                                <div className="jeton_order_row_title jeton_order_row_title_require" >描述：</div>
                                <Input className="jeton_order_row_input_long" value={this.arrUPSExpress[this.state.iCurrentUPSExpress].category} disabled
                                    style={{ background: 'white' }} />
                            </div>
                            {uiUserInfo}
                            {uiPriceDetail}
                            {uiPriceControl}
                            {uiPriceInfo}
                        </div>
                    </Modal>
                );
            }
            // 取消运单对话框
            if (this.state.iDlgType === 2) {
                uiModal = (
                    <Modal width={500} title={"取消运单"} visible={this.state.bShowDlg} okType="danger"
                        onOk={this.handleOk} okText="确定"
                        onCancel={this.handleCancel} cancelText="取消">
                        <div className="modal_item">
                            <div>{"运单：" + this.objCurrentJetonOrder.tracking_no}
                                <br /><br />确定取消该运单？（此操作不可逆）
                            </div>
                        </div>
                    </Modal>
                );
            }
            let uiShipperModal = null;
            if (this.state.iShipperDlg === 0) {
                uiShipperModal = (
                    <Modal width={600} title={"寄件人列表"} visible={this.state.bShipperDlg} footer={null} onCancel={this.ShowShipperDlg.bind(this, -1, false)} >
                        <div className="jeton_order_list_row_disable">
                            <div className="jeton_order_list_content jeton_order_list_content_bold" >{"姓名"}</div>
                            <div className="jeton_order_list_content jeton_order_list_content_bold" >{"电话"}</div>
                            <div className="jeton_order_list_content jeton_order_list_content_bold" >{"邮编"}</div>
                        </div>
                        {global.arrReceiverData.map((shipper, index) => {
                            return (
                                <div className="jeton_order_list_row" onClick={this.SelectShipper.bind(this, index)} key={index}>
                                    <div className="jeton_order_list_content" >{shipper.name}</div>
                                    <div className="jeton_order_list_content" >{shipper.phone}</div>
                                    <div className="jeton_order_list_content" >{shipper.postcode}</div>
                                </div>
                            );
                        })}


                    </Modal>
                );
            }
            let uiReceiverModal = null;
            if (this.state.iReceiverDlg === 0) {
                uiReceiverModal = (
                    <Modal width={600} title={"收件人列表"} visible={this.state.bReceiverDlg} footer={null} onCancel={this.ShowReceiverDlg.bind(this, -1, false)} >
                        <div className="jeton_order_list_row_disable">
                            <div className="jeton_order_list_content jeton_order_list_content_bold" >{"姓名"}</div>
                            <div className="jeton_order_list_content jeton_order_list_content_bold" >{"电话"}</div>
                            <div className="jeton_order_list_content jeton_order_list_content_bold" >{"邮编"}</div>
                        </div>
                        {global.arrReceiverData.map((receiver, index) => {
                            return (
                                <div className="jeton_order_list_row" onClick={this.SelectReceiver.bind(this, index)} key={index}>
                                    <div className="jeton_order_list_content" >{receiver.name}</div>
                                    <div className="jeton_order_list_content" >{receiver.phone}</div>
                                    <div className="jeton_order_list_content" >{receiver.postcode}</div>
                                </div>
                            );
                        })}


                    </Modal>
                );
            }
            let uiTrackingModal = null;
            if (this.state.bTrackingDlg) {
                uiTrackingModal = (
                    <Modal width={450} title={"运单：" + this.objCurrentJetonOrder.tracking_no} visible={this.state.bTrackingDlg} footer={null}
                        onCancel={this.ShowTrackingDlg.bind(this, false)} >
                        <Steps direction="vertical" size="default" current={this.arrTrackingActivies.length - 1} progressDot >
                            {this.arrTrackingActivies.map((activity, index) => {
                                let strName = this.GetTrackingActivityName(activity.status.type);
                                let strCity = activity.location.address.city;
                                let strCountry = activity.location.address.country;
                                let strInfo = strName;
                                if (strCity !== "")
                                    strInfo += ", " + strCity;
                                if (strCountry !== "")
                                    strInfo += ", " + strCountry;
                                return (
                                    <Step title={strInfo} description={activity.status.description} key={index} />
                                );
                            })}
                        </Steps>



                    </Modal>
                );
            }

            let uiPriceDetailDlg = null;
            if (this.state.bPriceDetailDlg) {
                let strTitle = "运单：" + this.objCurrentJetonOrder.tracking_no;
                if (this.objCurrentJetonOrder.tracking_no === undefined || this.objCurrentJetonOrder.tracking_no === null)
                    strTitle = "运单：暂无";

                let fDeliveryPer = 0.0;
                let uiDeliveryConfirmation = null;
                if (this.objDeliveryConfirmation !== null && this.objDeliveryConfirmation !== undefined) {
                    if (this.state.iPriceDlgType === 0)
                        uiDeliveryConfirmation = (
                            <div className="jeton_order_list_row_disable jeton_row_bottom_line">
                                <div className="jeton_order_list_content jeton_price_title" >{this.objDeliveryConfirmation.name}</div>
                                <div className="jeton_order_list_content jeton_order_list_content_bold" >
                                    {this.objDeliveryConfirmation.rate}</div>
                            </div>
                        );
                    fDeliveryPer = this.objDeliveryConfirmation.rate / this.objTotalCharges.rate;
                    if (this.state.iPriceDlgType === 1)
                        uiDeliveryConfirmation = (
                            <div className="jeton_order_list_row_disable jeton_row_bottom_line">
                                <div className="jeton_order_list_content jeton_price_title" >{this.objDeliveryConfirmation.name}</div>
                                <div className="jeton_order_list_content jeton_order_list_content_bold" >
                                    {parseFloat(this.fClientCharges * fDeliveryPer).toFixed(2)}</div>
                            </div>
                        );
                }
                let fAddtionalPer = 0.0;
                let uiHandling = null;
                if (this.objAdditionalHandling !== null && this.objAdditionalHandling !== undefined) {
                    if (this.state.iPriceDlgType === 0)
                        uiHandling = (
                            <div className="jeton_order_list_row_disable jeton_row_bottom_line">
                                <div className="jeton_order_list_content jeton_price_title" >{this.objAdditionalHandling.name}</div>
                                <div className="jeton_order_list_content jeton_order_list_content_bold" >
                                    {this.objAdditionalHandling.rate}</div>
                            </div>
                        );
                    fAddtionalPer = this.objAdditionalHandling.rate / this.objTotalCharges.rate;
                    if (this.state.iPriceDlgType === 1)
                        uiHandling = (
                            <div className="jeton_order_list_row_disable jeton_row_bottom_line">
                                <div className="jeton_order_list_content jeton_price_title" >{this.objAdditionalHandling.name}</div>
                                <div className="jeton_order_list_content jeton_order_list_content_bold" >
                                    {parseFloat(this.fClientCharges * fAddtionalPer).toFixed(2)}</div>
                            </div>
                        );
                }

                if (this.state.iPriceDlgType === 0) {
                    uiPriceDetailDlg = (
                        <Modal width={500} title={strTitle} visible={this.state.bPriceDetailDlg} footer={null}
                            onCancel={this.UPSPriceDetail.bind(this, false)} >
                            <div className="jeton_order_list_row_disable">
                                <div className="jeton_order_list_content jeton_price_title" >{"Subtotals (UPS Charges)"}</div>
                            </div>
                            <div className="jeton_order_list_row_disable jeton_row_bottom_line">
                                <div className="jeton_order_list_content jeton_price_title" >{this.objUPSStandard.name}</div>
                                <div className="jeton_order_list_content jeton_order_list_content_bold" >{this.objUPSStandard.rate}</div>
                            </div>
                            <div className="jeton_order_list_row_disable jeton_row_bottom_line">
                                <div className="jeton_order_list_content jeton_price_title" >{this.objFuelSurcharge.name}</div>
                                <div className="jeton_order_list_content jeton_order_list_content_bold" >{this.objFuelSurcharge.rate}</div>
                            </div>
                            <div className="jeton_order_list_row_disable jeton_row_bottom_line">
                                <div className="jeton_order_list_content jeton_price_title" >{this.objResidentialAddressSurcharge.name}</div>
                                <div className="jeton_order_list_content jeton_order_list_content_bold" >{this.objResidentialAddressSurcharge.rate}</div>
                            </div>
                            <div className="jeton_order_list_row_disable jeton_row_bottom_line">
                                <div className="jeton_order_list_content jeton_price_title" >{this.objPeakDemandSurcharge.name}</div>
                                <div className="jeton_order_list_content jeton_order_list_content_bold" >{this.objPeakDemandSurcharge.rate}</div>
                            </div>
                            {uiDeliveryConfirmation}
                            {uiHandling}
                            <div className="jeton_order_list_row_disable jeton_row_bottom_line">
                                <div className="jeton_order_list_content jeton_price_title" >{this.objTotalCharges.name}</div>
                                <div className="jeton_order_list_content jeton_order_list_content_bold" >
                                    {this.objTotalCharges.rate}</div>
                            </div>

                        </Modal>
                    );
                }
                if (this.state.iPriceDlgType === 1) {

                    let arrPercentage = [
                        this.objUPSStandard.rate / this.objTotalCharges.rate,
                        this.objFuelSurcharge.rate / this.objTotalCharges.rate,
                        this.objResidentialAddressSurcharge.rate / this.objTotalCharges.rate,
                        this.objPeakDemandSurcharge.rate / this.objTotalCharges.rate
                    ];
                    let arrClientPrice = [
                        parseFloat(this.fClientCharges * arrPercentage[0]).toFixed(2),
                        parseFloat(this.fClientCharges * arrPercentage[1]).toFixed(2),
                        parseFloat(this.fClientCharges * arrPercentage[2]).toFixed(2),
                        parseFloat(this.fClientCharges * arrPercentage[3]).toFixed(2)
                    ];
                    uiPriceDetailDlg = (
                        <Modal width={500} title={strTitle} visible={this.state.bPriceDetailDlg} footer={null}
                            onCancel={this.UPSPriceDetail.bind(this, false)} >
                            <div className="jeton_order_list_row_disable">
                                <div className="jeton_order_list_content jeton_price_title" >{"Subtotals (Charges)"}</div>
                            </div>
                            <div className="jeton_order_list_row_disable jeton_row_bottom_line">
                                <div className="jeton_order_list_content jeton_price_title" >{this.objUPSStandard.name}</div>
                                <div className="jeton_order_list_content jeton_order_list_content_bold" >
                                    {arrClientPrice[0]}</div>
                            </div>
                            <div className="jeton_order_list_row_disable jeton_row_bottom_line">
                                <div className="jeton_order_list_content jeton_price_title" >{this.objFuelSurcharge.name}</div>
                                <div className="jeton_order_list_content jeton_order_list_content_bold" >
                                    {arrClientPrice[1]}</div>
                            </div>
                            <div className="jeton_order_list_row_disable jeton_row_bottom_line">
                                <div className="jeton_order_list_content jeton_price_title" >{this.objResidentialAddressSurcharge.name}</div>
                                <div className="jeton_order_list_content jeton_order_list_content_bold" >
                                    {arrClientPrice[2]}</div>
                            </div>
                            <div className="jeton_order_list_row_disable jeton_row_bottom_line">
                                <div className="jeton_order_list_content jeton_price_title" >{this.objPeakDemandSurcharge.name}</div>
                                <div className="jeton_order_list_content jeton_order_list_content_bold" >
                                    {arrClientPrice[3]}</div>
                            </div>
                            {uiDeliveryConfirmation}
                            {uiHandling}
                            <div className="jeton_order_list_row_disable jeton_row_bottom_line">
                                <div className="jeton_order_list_content jeton_price_title" >{"Total Charges"}</div>
                                <div className="jeton_order_list_content jeton_order_list_content_bold" >
                                    {this.fClientCharges}</div>
                            </div>
                        </Modal>
                    );
                }
            }

            let uiUserModal = null;
            if (this.state.bUserDlg) {
                uiUserModal = (
                    <Modal width={600} title={"用户列表"} visible={this.state.bUserDlg} footer={null} onCancel={this.SelectOrderUser.bind(this, -1, false)} >
                        <div className="jeton_order_list_row_disable">
                            <div className="jeton_order_list_content jeton_order_list_content_bold" >{"姓名"}</div>
                            <div className="jeton_order_list_content jeton_order_list_content_bold" >{"电话"}</div>
                        </div>
                        {global.arrUserData.map((user, index) => {
                            return (
                                <div className="jeton_order_list_row" onClick={this.SelectOrderUser.bind(this, index, false)} key={index}>
                                    <div className="jeton_order_list_content" >{user.name}</div>
                                    <div className="jeton_order_list_content" >{user.phone}</div>
                                </div>
                            );
                        })}


                    </Modal>
                );
            }

            return (
                <div className="store_manage_contents store_manage_top_space">
                    {uiTitleArea}
                    {uiModal}
                    {uiShipperModal}
                    {uiReceiverModal}
                    {uiTrackingModal}
                    {uiPriceDetailDlg}
                    {uiUserModal}
                    {/* <img src={"data:image/png;base64, " + this.strBase64Label} alt="" /> */}
                    {/* 表格主体 */}
                    <Table style={{ margin: '10px', width: '97.5%', overflowX: 'auto' }}
                        rowSelection={rowSelection} pagination={paginationProps}
                        columns={this.arrTableHeader} dataSource={this.arrTableData} bordered
                        onRow={(record) => ({ onClick: () => { this.selectRow(record); } })} />
                </div >
            );
        } else {
            return (
                <div className="store_manage_contents store_manage_top_no_space">
                    {uiTitleArea}
                    <Spin className="manage_empty_loading" size="large" tip="加载中……" />
                </div>
            );
        }
    }
}
export default UPSShippingOrder;
