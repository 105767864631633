import React, { Component } from "react";
import axios from 'axios';
import XLSX from 'xlsx';
import cookie from 'react-cookies';

import Icon from 'antd/es/icon';
import Input from 'antd/es/input';
import Progress from 'antd/es/progress';
import Table from 'antd/es/table';
import Modal from 'antd/es/modal';

import "../../../styles/Background/UI_Module/hscode_manager.css";
import { message } from "antd";

// const g_strMessageKey = 'updating';

const columns = [
    { title: '品名(中)', dataIndex: 'cn', key: 'cn' },
    { title: '品名(英)', dataIndex: 'en', key: 'en' },
    { title: 'HS CODE', dataIndex: 'hscode', key: 'hscode' },
    { title: 'Duty（%）', dataIndex: 'duty', key: 'duty' },
    { title: 'Material', dataIndex: 'material', key: 'material' },
    { title: '备注', dataIndex: 'comments', key: 'comments' },
    { title: '操作', dataIndex: 'operation', key: 'operation' }
]

class HSCodeManager extends Component {
    constructor(props) {
        super();
        this.state = {
            iLogin: -1,
            iProgress: 0,
            bLoading: true,
            bHSCodeDlg: false,
            iHSCodeDlg: -1
        };
        this.arrPassword = ["123456", "12345678", "123456789"];
        this.strHSCode = "";
        this.arrHSCodeData = [];
        this.iCurrenProgress = 0;
        this.iCurrentHSCodeIndex = 0;
    }

    // 提交按钮响应
    SubmitAction = () => {
        // 登录操作
        if (this.state.iLogin < 0) {
            let iLogin = -1;
            for (let i = 0; i < this.arrPassword.length; i++) {
                if (this.refs.search_input.state.value === this.arrPassword[i]) {
                    iLogin = i; break;
                }
            }
            if (iLogin < 0)
                message.warning("识别码错误，无法解锁！");
            else
                message.info("已解锁！");
            this.refs.search_input.state.value = "";
            cookie.save('wm_hscode', iLogin, { path: '/' });
            this.setState({
                ...this.state,
                iLogin: iLogin
            });
        }
        // 搜索操作
        else {
            if (this.refs.search_input.state.value === null || this.refs.search_input.state.value.trim() === "")
                message.warning("搜索内容不能为空！");
            else {
                this.arrHSCodeData = [];
                this.setState({
                    ...this.state,
                    bLoading: true
                });
                axios('/GetHSCode', { params: { search: this.refs.search_input.state.value } })
                    .then(({ data }) => {
                        this.arrHSCodeData = data;
                        for (let i = 0; i < this.arrHSCodeData.length; i++) {
                            let uiOperation = (
                                <div className="hs_code_operation_area" key={i}>
                                    <div className="hs_code_operation_button" style={{ color: 'darkcyan' }} onClick={this.HSCodeOperation.bind(this, 1, i)}>编辑</div>
                                    <div className="hs_code_operation_button" style={{ color: 'lightcoral' }} onClick={this.HSCodeOperation.bind(this, 2, i)}>删除</div>
                                </div>);
                            this.arrHSCodeData[i].operation = uiOperation;
                        }
                        this.setState({
                            ...this.state,
                            bLoading: false
                        })
                    }).catch(function (error) { console.log(error); });
            }
        }
    }
    // 锁定界面
    LockHSCode = () => {
        message.info("已锁定！");
        cookie.save('wm_hscode', -1, { path: '/' });
        this.arrHSCodeData = [];
        this.refs.search_input.state.value = "";
        this.setState({
            ...this.state,
            iLogin: -1
        });
    }
    // 上传文件响应
    onImportExcel = file => {
        // 获取上传的文件对象
        const { files } = file.target;
        console.log("files : ", files);
        // 通过FileReader对象读取文件
        const fileReader = new FileReader();
        fileReader.onload = event => {
            try {
                const { result } = event.target;
                // 以二进制流方式读取得到整份excel表格对象
                const workbook = XLSX.read(result, { type: 'binary' });
                // 存储获取到的数据
                let data = [];
                // 遍历每张工作表进行读取（这里默认只读取第一张表）
                for (const sheet in workbook.Sheets) {
                    // esline-disable-next-line
                    if (workbook.Sheets.hasOwnProperty(sheet)) {
                        // 利用 sheet_to_json 方法将 excel 转成 json 数据
                        data = data.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
                        // break; // 如果只取第一张表，就取消注释这行
                    }
                }
                // 最终获取到并且格式化后的 json 数据
                message.success('上传成功！');
                if (data.length > 0) {
                    this.ProcessHSCodeData(data);
                } else {
                    message.warning("文件中的数据为空，请重新选择！")
                }
            } catch (e) {
                // 这里可以抛出文件类型错误不正确的相关提示
                message.error('文件类型不正确！');
            }
        };
        // 以二进制方式打开文件
        fileReader.readAsBinaryString(files[0]);
    }
    // 逐一处理HSCode数据
    ProcessHSCodeData = (data) => {
        this.iCurrenProgress = 0;
        for (let i = 0; i < data.length; i++) {
            let objData = { cn: "", en: "", hscode: "", material: "" };
            if (data[i].cn)
                objData.cn = data[i].cn;
            if (data[i].en)
                objData.en = data[i].en;
            if (data[i].hscode)
                objData.hscode = data[i].hscode;
            if (data[i].material)
                objData.material = data[i].material;
            if (data[i].duty)
                objData.duty = data[i].duty;
            if (data[i].comments)
                objData.comments = data[i].comments;
            axios.post('/AddHSCode', { objHSCode: objData })
                .then(({ result }) => {
                    this.iCurrenProgress++;
                    this.setState({
                        ...this.state,
                        iProgress: parseFloat((this.iCurrenProgress * 100) / data.length).toFixed(0)
                    });
                }).catch(function (error) { console.log(error); });
        }
    }
    // 新建/编辑/删除
    HSCodeOperation = (iDlg, index) => {
        this.iCurrentHSCodeIndex = index;
        // 新建
        if (iDlg === 0) {
            let objHSCode = { cn: "", en: "", hscode: "", duty: "", material: "", comments: "" };
            if (this.refs.cn_input) {
                this.refs.cn_input.state.value = "";
                this.refs.en_input.state.value = "";
                this.refs.hscode_input.state.value = "";
                this.refs.duty_input.state.value = "";
                this.refs.material_input.state.value = "";
                this.refs.comments_input.state.value = "";
            }
            this.iCurrentHSCodeIndex = 0;
            this.arrHSCodeData = [];
            this.arrHSCodeData.push(objHSCode);
            let uiOperation = (
                <div className="hs_code_operation_area" key={0}>
                    <div className="hs_code_operation_button" onClick={this.HSCodeOperation.bind(this, 1, 0)}>编辑</div>
                    <div className="hs_code_operation_button" style={{ color: 'red' }} onClick={this.HSCodeOperation.bind(this, 2, 0)}>删除</div>
                </div>);
            this.arrHSCodeData[0].operation = uiOperation;
        }
        // 编辑
        if (iDlg === 1) {
            if (this.refs.cn_input) {
                this.refs.cn_input.state.value = this.arrHSCodeData[index].cn;
                this.refs.en_input.state.value = this.arrHSCodeData[index].en;
                this.refs.hscode_input.state.value = this.arrHSCodeData[index].hscode;
                this.refs.duty_input.state.value = this.arrHSCodeData[index].duty;
                this.refs.material_input.state.value = this.arrHSCodeData[index].material;
                this.refs.comments_input.state.value = this.arrHSCodeData[index].comments;
            }
        }
        // 删除
        if (iDlg === 2) {

        }
        this.setState({
            bHSCodeDlg: true,
            iHSCodeDlg: iDlg
        });
    }
    // 确认操作
    handleOk = e => {
        if (this.state.iHSCodeDlg === 0 || this.state.iHSCodeDlg === 1) {
            if (this.state.iHSCodeDlg === 0) {
                let objHSCode = {
                    cn: this.refs.cn_input.state.value,
                    en: this.refs.en_input.state.value,
                    hscode: this.refs.hscode_input.state.value,
                    duty: this.refs.duty_input.state.value,
                    material: this.refs.material_input.state.value,
                    comments: this.refs.comments_input.state.value
                }
                this.arrHSCodeData[0].cn = this.refs.cn_input.state.value;
                this.arrHSCodeData[0].en = this.refs.en_input.state.value;
                this.arrHSCodeData[0].hscode = this.refs.hscode_input.state.value;
                this.arrHSCodeData[0].duty = this.refs.duty_input.state.value;
                this.arrHSCodeData[0].material = this.refs.material_input.state.value;
                this.arrHSCodeData[0].comments = this.refs.comments_input.state.value;
                axios.post('/AddHSCode', { objHSCode: objHSCode })
                    .then(({ data }) => {
                        this.arrHSCodeData[0].id = data.message[0];
                        message.info("新建 HS CODE 完成！")
                        this.setState({
                            iHSCodeDlg: -1,
                        });
                    }).catch(function (error) { console.log(error); });
            }
            if (this.state.iHSCodeDlg === 1) {
                let objHSCode = Object.assign({}, this.arrHSCodeData[this.iCurrentHSCodeIndex]);
                // let objHSCode = this.arrHSCodeData[this.iCurrentHSCodeIndex];
                objHSCode.cn = this.refs.cn_input.state.value;
                objHSCode.en = this.refs.en_input.state.value;
                objHSCode.hscode = this.refs.hscode_input.state.value;
                objHSCode.duty = this.refs.duty_input.state.value;
                objHSCode.material = this.refs.material_input.state.value;
                objHSCode.comments = this.refs.comments_input.state.value;
                this.arrHSCodeData[this.iCurrentHSCodeIndex] = Object.assign({}, objHSCode);
                delete objHSCode.operation;
                axios.post('/UpdateHSCode', { objHSCode: objHSCode })
                    .then(({ data }) => {
                        console.log("update result : ", data);
                        message.info("更新 HS CODE 完成！")
                        this.setState({
                            iHSCodeDlg: -1,
                        });
                    }).catch(function (error) { console.log(error); });
            }
        }
        if (this.state.iHSCodeDlg === 2) {
            axios.post('/DeleteHSCode', { id: this.arrHSCodeData[this.iCurrentHSCodeIndex].id })
                .then(({ data }) => {
                    this.arrHSCodeData.splice(this.iCurrentHSCodeIndex, 1);
                    message.info("HS CODE 已删除！");
                    this.setState({
                        iHSCodeDlg: -1,
                    });
                }).catch(function (error) { console.log(error); });
        }
        this.setState({
            bHSCodeDlg: false,
        });
    };
    // 取消操作
    handleCancel = e => {
        this.setState({
            bHSCodeDlg: false,
        });
    };
    // 渲染完毕……
    componentDidMount() {
        let strHSCode = cookie.load('wm_hscode');
        if (strHSCode) {
            this.strHSCode = strHSCode;
            this.setState({
                ...this.state,
                iLogin: parseInt(this.strHSCode, 10)
            })
        } else {
            cookie.save('wm_hscode', this.state.iLogin, { path: '/' });
        }
    }

    render() {
        let uiLock = (<Icon type="lock" style={{ fontWeight: 'bold', fontSize: '17.5px', marginRight: '1vw' }} />);
        let strTitle = "输入身份识别码";
        let strButton = "解 锁";
        let uiInput = <Input size="large" ref="search_input" />;
        let uiAddHSCode = null;
        if (this.state.iLogin >= 0) {
            strTitle = "输入HSCode/物品名称";
            uiLock = (<Icon type="unlock" style={{ fontWeight: 'bold', fontSize: '17.5px', marginRight: '1vw' }} />);
            strButton = "查 找";
            uiAddHSCode = (
                <div className="add_hscode_button" onClick={this.HSCodeOperation.bind(this, 0, -1)}>
                    <Icon type="plus" style={{ fontWeight: 'bold', fontSize: '20px', color: 'white' }} />
                </div>
            );
        }
        let uiUpload = null;
        if (this.state.iLogin === 2)
            uiUpload = (
                <div className="hscode_upload_area">
                    <input type='file' accept='.xlsx, .xls' onChange={this.onImportExcel} />
                </div>
            );
        let uiLockButton = (
            <div className="hscode_lock_button" onClick={this.LockHSCode}>
                <Icon type="lock" style={{ fontWeight: 'bold', fontSize: '20px', color: 'white' }} />
            </div>
        );

        let uiUploadProgress = null;
        if (this.state.iProgress > 0)
            uiUploadProgress = (<Progress percent={this.state.iProgress} />);
        let uiHSCodeTable = null;
        if (!this.state.bLoading) {
            uiHSCodeTable = (<Table columns={columns} dataSource={this.arrHSCodeData} />);
        }
        let uiHSCodeDlg = null;
        if (this.state.bHSCodeDlg) {
            let strDlgTitle = "新建 HS CODE";
            if (this.state.iHSCodeDlg === 1)
                strDlgTitle = "编辑 HS CODE";
            uiHSCodeDlg = (
                <Modal title={strDlgTitle} visible={this.state.bHSCodeDlg} centered={true} width={400}
                    okText="确定" cancelText="取消"
                    onOk={this.handleOk} onCancel={this.handleCancel} >
                    <div className="hscode_input_row">
                        <div className="hscode_input_title">品名(中)</div>
                        <Input size="normal" ref="cn_input" defaultValue={this.arrHSCodeData[this.iCurrentHSCodeIndex].cn} />
                    </div>
                    <div className="hscode_input_row">
                        <div className="hscode_input_title">品名(英)</div>
                        <Input size="normal" ref="en_input" defaultValue={this.arrHSCodeData[this.iCurrentHSCodeIndex].en} />
                    </div>
                    <div className="hscode_input_row">
                        <div className="hscode_input_title">HS CODE</div>
                        <Input size="normal" ref="hscode_input" defaultValue={this.arrHSCodeData[this.iCurrentHSCodeIndex].hscode} />
                    </div>
                    <div className="hscode_input_row">
                        <div className="hscode_input_title">Duty(%)</div>
                        <Input size="normal" ref="duty_input" defaultValue={this.arrHSCodeData[this.iCurrentHSCodeIndex].duty} />
                    </div>
                    <div className="hscode_input_row">
                        <div className="hscode_input_title">Material</div>
                        <Input size="normal" ref="material_input" defaultValue={this.arrHSCodeData[this.iCurrentHSCodeIndex].material} />
                    </div>
                    <div className="hscode_input_row">
                        <div className="hscode_input_title">备注</div>
                        <Input size="normal" ref="comments_input" defaultValue={this.arrHSCodeData[this.iCurrentHSCodeIndex].comments} />
                    </div>
                </Modal>
            );
            if (this.state.iHSCodeDlg === 2) {
                strDlgTitle = "删除 HS CODE";
                uiHSCodeDlg = (
                    <Modal title={strDlgTitle} visible={this.state.bHSCodeDlg} centered={true} width={400}
                        okText="确定" cancelText="取消"
                        onOk={this.handleOk} onCancel={this.handleCancel} >
                        <div className="hscode_input_row">
                            {"品名：" + this.arrHSCodeData[this.iCurrentHSCodeIndex].cn + " , HS CODE：" + this.arrHSCodeData[this.iCurrentHSCodeIndex].hscode}
                        </div>
                    </Modal>
                );
            }
        }
        return (
            <div className="hscode_manager_page">
                {uiLockButton}
                {uiAddHSCode}
                {uiUpload}
                <div className="hscode_main_area">
                    <div className="hscode_login_area">
                        <div className="hscode_title">
                            {uiLock}
                            {strTitle}
                        </div>
                        <div className="hscode_input_area">
                            {uiInput}
                        </div>
                        <div className="hscode_submit_button" onClick={this.SubmitAction}>
                            {strButton}
                        </div>
                    </div>
                    <div className="hscode_contents_area">
                        {uiUploadProgress}
                        {uiHSCodeTable}
                    </div>
                </div>
                {uiHSCodeDlg}
            </div>
        );
    }
}
export default HSCodeManager;
