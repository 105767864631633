import React, { Component } from "react";
import axios from 'axios';

import Tabs from 'antd/es/tabs';
// import Spin from 'antd/es/spin';
import Table from 'antd/es/table';
import Button from 'antd/es/button';
import Input from 'antd/es/input';
import Modal from 'antd/es/modal';
import message from 'antd/es/message';

import "../../../styles/Background/UI_Module/event_management.css";

import { UserActiveUpdate } from "../../../functions/DataStatistic.js"

const g_strMessageKey = 'updating';

const { TabPane } = Tabs;

// 表格
const arrTableHeader = [
    { title: '数量', dataIndex: 'num' },
    { title: '批次', dataIndex: 'batch' },
    { title: '备注', dataIndex: 'comments' },
    { title: '日期', dataIndex: 'generate' }
];

class EventManagement extends Component {
    constructor(props) {
        super();
        this.state = {
            bLoading: false,
            bShowDlg: false,
            iDlgType: -1,
        };
        this.arrTableData = [];  // 用于表格的数组
    }

    EventTabChange = (key) => {
        // console.log(key);
    }

    GenerateIviteCode = () => {
        this.setState({
            ...this.state,
            bShowDlg: true,
            iDlgType: 0
        });
    }

    handleCancel = () => {
        this.setState({
            ...this.state,
            bShowDlg: false
        });
    }

    IviteCodeInfoConfirm = () => {
        let iCodeNum = this.refs.invite_code_num.state.value;
        let iBatch = parseInt(this.refs.invite_code_batch.state.value, 10);
        let strComments = this.refs.invite_code_comments.state.value;

        if (iCodeNum <= 0) {
            message.warning("邀请码的发行数必须大于0！");
            return;
        }

        if (!((typeof iBatch === 'number') && (iBatch % 1 === 0))) {
            message.warning("邀请码的批号必须是整数！");
            return;
        };
        if (iBatch <= 0) {
            message.warning("邀请的批号必须大于0！");
            return;
        }

        message.loading({ content: '正在生成邀请码……', key: g_strMessageKey });
        axios.post('/GenerateEventCode', { iCodeNum: iCodeNum, iBatch: iBatch, strComments: strComments })
            .then(({ data }) => {

                this.RefreshCodeTable(data);
                // DataStatistic
                UserActiveUpdate(9, { active: "管理后台: 邀请码操作", action: "生成" });
                message.success({ content: '成功生成一批新的邀请码！', key: g_strMessageKey, duration: 2 });
            });

        this.setState({
            ...this.state,
            bShowDlg: false
        });
    }

    RefreshCodeTable = (data) => {

        console.log("RefrechCodeTable >>> ");
        console.log(data);

        this.setState({
            ...this.state,
            bLoading: true
        });

        this.arrTableData = [];

        for (let i = 0; i < data.length; i++) {
            let objInviteCode = {
                key: i,
                num: data[i].code_num,
                batch: data[i].batch,
                generate: data[i].generate.split('T')[0],
                comments: data[i].comments,
            }
            this.arrTableData.push(objInviteCode);
        }

        this.setState({
            ...this.state,
            bLoading: false,
            bShowDlg: false
        });
    }

    GetCodeInfo = () => {
        axios.get('/GetCodeListInfo')
            .then(({ data }) => {
                this.RefreshCodeTable(data);
            });
    }

    componentDidMount() {
        this.GetCodeInfo();
    }

    render() {

        // 邀请码编辑弹窗
        let uiInviteCodeModal = (
            <Modal title="邀请码信息" width={350} visible={this.state.bShowDlg}
                okText="确定" cancelText="取消" onOk={this.IviteCodeInfoConfirm} onCancel={this.handleCancel} >
                <div className="user_edit_info_row">
                    <div className="user_edit_info_title">数量：</div>
                    <Input className="user_edit_info_input" ref="invite_code_num" defaultValue={0} />
                </div>
                <div className="user_edit_info_row">
                    <div className="user_edit_info_title">批次：</div>
                    <Input className="user_edit_info_input" ref="invite_code_batch" defaultValue={0} />
                </div>
                <div className="user_edit_info_row">
                    <div className="user_edit_info_title">备注：</div>
                    <Input className="user_edit_info_input" ref="invite_code_comments" defaultValue={""} />
                </div>
            </Modal>
        );
        if (this.state.iDlgType === 2) {
            // uiInviteCodeModal = (
            //     <Modal width={500} title={"删除用户信息"} visible={this.state.bShowDlg} okType="danger"
            //         onOk={this.handleOk} okText="确定"
            //         onCancel={this.handleCancel} cancelText="取消">
            //         <div className="modal_item">
            //             <div>{this.objCurrentUser.name}
            //                 <br /><br />选中用户的所有信息都将被删除……<br />确定删除选中的用户？
            //             </div>
            //         </div>
            //     </Modal>
            // );
        }

        // 表格分页属性
        const paginationProps = {
            showSizeChanger: true,
            showQuickJumper: false,
            showTotal: () => `共 ${this.arrTableData.length} 批 邀请码`,
            defaultPageSize: 10,
            defaultCurrent: this.state.iCurrentPage,
            pageSizeOptions: ['10', '20', '30', '40', '50'],
            total: this.arrTableData.length,
            position: 'bottom'
        };

        return (
            <div className="event_manage_page">
                <Tabs onChange={this.EventTabChange} defaultActiveKey="1" >
                    <TabPane tab="邀请码" key="1">
                        <div className="event_tabs_area">
                            <Button className="event_tab_invite_code_generate_button" type="primary" onClick={this.GenerateIviteCode}>
                                生成邀请码</Button>
                            {/* 表格主体 */}
                            <Table style={{ width: '30vw' }}
                                /*rowSelection={rowSelection}*/ pagination={paginationProps}
                                columns={arrTableHeader} dataSource={this.arrTableData}
                                /*onRow={(record) => ({ onClick: () => { this.selectRow(record); } })}*/ />
                        </div>
                        {uiInviteCodeModal}
                    </TabPane>
                    <TabPane tab="兑换码" key="2">
                        <div className="event_tabs_area">
                            - 暂无 -
                        </div>
                    </TabPane>
                    <TabPane tab="平台优惠券" key="3">
                        <div className="event_tabs_area">
                            - 暂无 -
                        </div>
                    </TabPane>
                </Tabs>
            </div>
        );
    }
}
export default EventManagement;
