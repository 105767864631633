import React, { Component } from 'react';
import axios from 'axios';

import Spin from 'antd/es/spin';
import Table from 'antd/es/table';
import Button from 'antd/es/button';
import Input from 'antd/es/input';
import Menu from 'antd/es/menu';
import Dropdown from 'antd/es/dropdown';
import Icon from 'antd/es/icon';
import Modal from 'antd/es/modal';
import message from 'antd/es/message';

// import { Spin, Table, Button, Input, Menu, Dropdown, Icon, Modal, message } from 'antd';
import '../../../styles/Background/ego_manager.css';
import '../../../styles/Background/UI_Module/user_management.css';
import { UserActiveUpdate } from "../../../functions/DataStatistic.js"

const g_strMessageKey = 'updating';

// 表格
const arrTableHeader = [
    { title: 'id', dataIndex: 'id', width: 75, ellipsis: true },
    { title: 'name', dataIndex: 'name', width: 150, ellipsis: true },
    { title: 'level', dataIndex: 'level', width: 125, ellipsis: true },
    { title: 'status', dataIndex: 'status', width: 100, ellipsis: true },
    { title: 'code', dataIndex: 'code', width: 175, ellipsis: true },
    { title: 'store_id', dataIndex: 'store_id', width: 100, ellipsis: true },
    { title: 'storage_code', dataIndex: 'storage_code', width: 125, ellipsis: true },
    { title: 'invite_code', dataIndex: 'invite_code', width: 125, ellipsis: true },
    { title: 'price_off', dataIndex: 'price_off_str', width: 100, ellipsis: true },
    { title: 'register_date', dataIndex: 'register_date', width: 125, ellipsis: true },
    { title: 'last_login', dataIndex: 'last_login', width: 125, ellipsis: true },

    { title: 'phone', dataIndex: 'phone', width: 175, ellipsis: true },
    { title: 'address', dataIndex: 'address', width: 300, ellipsis: true },
    { title: 'email', dataIndex: 'email', width: 175, ellipsis: true },
    { title: 'gender', dataIndex: 'gender', width: 75, ellipsis: true },
    { title: 'birthday', dataIndex: 'birthday', width: 100, ellipsis: true },
    { title: 'qq', dataIndex: 'qq', width: 75, ellipsis: true },
    { title: 'wechat', dataIndex: 'wechat', width: 75, ellipsis: true },
    { title: 'collect_stores', dataIndex: 'collect_stores', width: 300, ellipsis: true },
];

class DBUserManager extends Component {
    constructor(props) {
        super();
        this.state = {
            btnControlDisable: [false, true, true],  // 操作按钮控制
            btnCopyPasteDisable: [true, true],  // 操作按钮控制
            selectedRowKeys: [],  // Check here to configure the default column
            objFilter: {
                strKeyword: "", iCurrentUserStatus: 0, iCurrentUserType: 0
            },
            iDlgType: -1,
            bShowDlg: false,
            bLoading: true,
            strNewPassword: "",
            strConfirmPassword: ""
        };
        // 成员变量
        this.arrOgeTableData = [];
        this.arrTableData = [];  // 用于表格的数组
        this.arrFilterUserStatus = ['全部', '正常使用', '审核中', '已禁用'];
        this.arrFilterUserType = ['全部', '普通用户', '商家', '管理员', '超级管理员'];
        this.arrSortType = ['用户名A-Z', '用户名Z-A', '注册时间↓', '注册时间↑'];
        this.arrSelectedUserID = [];
        this.arrSelectedUserIndex = [];
        this.objCurrentUser = {};
    }

    // 选中某行的响应函数
    selectRow = (record) => {
        const selectedRowKeys = [...this.state.selectedRowKeys];
        if (selectedRowKeys.indexOf(record.key) >= 0) {
            selectedRowKeys.splice(selectedRowKeys.indexOf(record.key), 1);
        } else {
            selectedRowKeys.push(record.key);
        }
        this.SelectedRowStateSetting(selectedRowKeys);
    }
    // 选中的行发生变化的响应函数
    onSelectedRowKeysChange = (selectedRowKeys) => {
        this.SelectedRowStateSetting(selectedRowKeys);
    }
    // 处理选中的表行行
    SelectedRowStateSetting = (selectedRowKeys) => {
        let btnControlDisable = [];
        let btnCopyPasteDisable = [];
        switch (selectedRowKeys.length) {
            case 0: {
                btnControlDisable = [false, true, true];
                btnCopyPasteDisable = [true, true];
                this.objCurrentUser = {};
                break;
            }
            case 1: {
                btnControlDisable = [false, false, false];
                btnCopyPasteDisable = [false, true];
                for (let i = 0; i < this.arrTableData.length; i++) {
                    if (this.arrTableData[i].key === selectedRowKeys[0]) {
                        this.objCurrentUser = this.arrTableData[i];
                        break;
                    }
                }
                break;
            }
            default: {
                btnControlDisable = [false, true, true]; btnCopyPasteDisable = [true, true]; break;
            }
        }
        this.setState({
            ...this.state,
            selectedRowKeys,
            btnControlDisable: btnControlDisable,
            btnCopyPasteDisable: btnCopyPasteDisable
        });
    }
    // 关键词处理
    KeywordsProcess = (strKeywords) => {
        let newKeywords = "";
        for (let i = 0; i < strKeywords.length; i++) {
            if (strKeywords[i] !== " ") {
                if (i === strKeywords.length - 1)
                    newKeywords += strKeywords[i];
                else
                    newKeywords += strKeywords[i] + "%";
            }
        }
        return newKeywords;
    }
    // 搜索用户名
    UserNameSearch = () => {
        let strKeywords = this.refs.user_name_search.state.value;
        if (strKeywords === undefined)
            strKeywords = "";
        if (strKeywords.trim() === "") {
            this.GetUsersData();
        } else {
            this.setState({
                ...this.state,
                bLoading: true,
                selectedRowKeys: [],
                btnControlDisable: [false, true, true]
            });
            axios('/SearchUsers', { params: { name: this.KeywordsProcess(strKeywords) } })
                .then(({ data }) => {
                    this.arrTableData = this.OrganizeUsersData(data);
                    this.setState({
                        ...this.state,
                        bLoading: false,
                        objFilter: {
                            strKeyword: strKeywords,
                            iCurrentUserStatus: 0,
                            iCurrentUserType: 0
                        }
                    });
                }).catch(function (error) { console.log(error); });
        }
    }
    // 点击了用户类别过滤器
    UserTypeFilterClicked = (iUserType) => {
        let strTargetType = this.arrFilterUserType[iUserType];
        if (iUserType !== 0) {
            this.arrTableData = [];
            for (let i = 0; i < this.arrOgeTableData.length; i++) {
                if (strTargetType === this.arrOgeTableData[i].level) {
                    this.arrTableData.push(this.arrOgeTableData[i]);
                }
            }
        } else {
            this.arrTableData = this.arrOgeTableData;
        }
        if (this.refs.user_name_search !== undefined)
            this.refs.user_name_search.state.value = "";
        this.setState({
            ...this.state,
            objFilter: {
                strKeyword: "",
                iCurrentUserStatus: this.state.objFilter.iCurrentUserStatus,
                iCurrentUserType: iUserType
            }
        });
    }
    // 点击了用户状态过滤器
    // UserStatusFilterClicked = (iStatus) => {
    //     this.setState({
    //         ...this.state,
    //         objFilter: {
    //             strKeyword: "", iCurrentUserStatus: iStatus, iCurrentUserType: this.state.objFilter.iCurrentUserType
    //         }
    //     });
    // }
    /* 自定义成员函数 */
    //弹出对话框
    ShowDlg = (iDlgType, e) => {
        // if (iDlgType === 0) {
        //     this.PresetNewDlg();
        //     this.setState({
        //         ...this.state,
        //         iDlgType: iDlgType,
        //         dlgInfo: { iMainCategory: 0, iSubCategory: 0, iLocation: 0, iStatus: 0 },
        //         bShowDlg: true
        //     });
        // }
        if (iDlgType === 1) {
            if (this.refs.user_name !== undefined) {
                this.refs.user_name.state.value = this.objCurrentUser.name;
                this.refs.invite_code.state.value = this.objCurrentUser.invite_code;
                this.refs.price_off.state.value = this.objCurrentUser.price_off;
            }
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true,
                strNewPassword: "",
                strConfirmPassword: ""
            });
        }
        if (iDlgType === 2) {
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true
            });
        }
    }
    // 弹窗确认按钮响应
    handleOk = e => {
        if (this.state.iDlgType === 1) {
            let strName = this.refs.user_name.state.value;
            let strPriceOff = this.refs.price_off.state.value;
            if (strName === null || strName.trim() === "") {
                message.warning("用户名不能为空！"); return;
            }
            if (this.state.strConfirmPassword !== this.state.strNewPassword) {
                message.warning("两次输入密码不一致！"); return;
            }
            let objNewUser = {};
            if (this.state.strNewPassword === "") {
                objNewUser = {
                    name: strName,
                    price_off: strPriceOff,
                    invite_code: this.refs.invite_code.state.value
                }
            } else {
                objNewUser = {
                    name: strName,
                    price_off: strPriceOff,
                    invite_code: this.refs.invite_code.state.value,
                    password: this.state.strNewPassword
                }
            }
            console.log("Update User Info: ");
            console.log(objNewUser);
            message.loading({ content: '正在更新用户信息……', key: g_strMessageKey });
            axios.post('/UpdateUserKeyInfo', {
                objCurrentUser: this.objCurrentUser,
                objNewUser: objNewUser
            }).then(
                ({ data }) => {
                    if (data.status === 1) {
                        // DataStatistic
                        UserActiveUpdate(4, { active: "管理后台: 用户管理操作", action: "编辑" });
                        this.GetUsersData();
                    }
                    else
                        message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
                }).catch(function (error) { console.log(error); });
        }
        if (this.state.iDlgType === 2) {
            message.loading({ content: '用户删除中……', key: g_strMessageKey });
            axios.post('/DeleteUser', { id: this.objCurrentUser.id }).then(
                ({ data }) => {
                    if (data.status === -1) {
                        message.warning({
                            content: "删除失败，该账户已绑定商家，请先检查并删除商家！",
                            key: g_strMessageKey, duration: 2
                        });
                        this.setState({
                            ...this.state,
                            bLoading: false,
                            bShowDlg: false,
                            selectedRowKeys: []
                        });
                    } else {
                        // DataStatistic
                        UserActiveUpdate(4, { active: "管理后台: 用户管理操作", action: "删除" });
                        this.GetUsersData();
                    }
                }).catch(function (error) { console.log(error); });
        }
    };
    // 弹窗取消按钮响应
    handleCancel = e => {
        console.log(e);
        this.setState({
            bShowDlg: false,
        });
    };
    // 整理Users数据
    OrganizeUsersData(data) {
        let arrTableData = [];
        for (let i = 0; i < data.length; i++) {
            let strRegisterDate = data[i].register_date;
            let strLoginDate = data[i].last_login;
            if (strRegisterDate !== null) {
                if (strRegisterDate.indexOf('T') >= 0)
                    strRegisterDate = strRegisterDate.split('T')[0];
            }
            if (strLoginDate !== null) {
                if (strLoginDate.indexOf('T') >= 0)
                    strLoginDate = strLoginDate.split('T')[0];
            }
            let strPhone = data[i].phone;
            if (strPhone === null || strPhone === "") {
                strPhone = data[i].contact_phone;
            }
            if (data[i].price_off === null || data[i].price_off === "")
                data[i].price_off = 0;
            let objUserData = {
                key: i, id: data[i].id,
                name: data[i].name,
                storage_code: data[i].storage_code,
                invite_code: data[i].invite_code,
                code: data[i].code,
                phone: strPhone,
                status: this.arrFilterUserStatus[data[i].status],
                store_id: data[i].store_id,
                register_date: strRegisterDate,
                last_login: strLoginDate,
                price_off: data[i].price_off,
                price_off_str: data[i].price_off + "%"
            }
            if (data[i].level >= 15)
                objUserData.level = this.arrFilterUserType[4];
            if (data[i].level >= 10 && data[i].level < 15)
                objUserData.level = this.arrFilterUserType[3];
            if (data[i].level >= 5 && data[i].level < 10)
                objUserData.level = this.arrFilterUserType[2];
            if (data[i].level >= 0 && data[i].level < 5)
                objUserData.level = this.arrFilterUserType[1];
            arrTableData.push(objUserData);
        }
        return arrTableData;
    }
    // 从数据库获取当前的数据结构
    GetUsersData() {
        message.loading({ content: '数据加载中……', key: g_strMessageKey });
        axios('/DB/GetAllUsers')
            .then(({ data }) => {
                // console.log("GetAllUsers", data);
                this.arrOgeTableData = this.OrganizeUsersData(data);
                this.arrTableData = this.arrOgeTableData;
                if (this.state.bShowDlg) {
                    if (this.state.iDlgType === 1) {
                        message.success({ content: "用户信息已更新！", key: g_strMessageKey, duration: 2 });
                    }
                    if (this.state.iDlgType === 2) {
                        message.success({ content: "用户已删除！", key: g_strMessageKey, duration: 2 })
                    }
                } else {
                    message.success({ content: "数据加载完成！", key: g_strMessageKey, duration: 2 });
                }
                this.setState({
                    ...this.state,
                    bLoading: false,
                    bShowDlg: false,
                    selectedRowKeys: []
                });
            }).catch(function (error) { console.log(error); });
    }
    /* React自带函数 */
    // 卸载异步操作设置状态
    componentWillUnmount() {
        clearTimeout(this.timeouter)
        this.setState = (state, callback) => {
            return;
        }
    }
    // 渲染完毕……
    componentDidMount() {
        this.GetUsersData();
    }
    // 渲染函数
    render() {

        // 顶部栏用户类别下拉菜单
        const uiUserTypeMenu = (
            <Menu>
                {this.arrFilterUserType.map((item, index) => {
                    return (
                        <Menu.Item key={index} onClick={this.UserTypeFilterClicked.bind(this, index)}>
                            {item}
                        </Menu.Item>
                    );
                })}
            </Menu>
        );
        // 顶部栏用户状态下拉菜单
        // const uiUserStatusMenu = (
        //     <Menu>
        //         {this.arrFilterUserStatus.map((item, index) => {
        //             return (
        //                 <Menu.Item key={index} onClick={this.UserStatusFilterClicked.bind(this, index)}>
        //                     {item}
        //                 </Menu.Item>
        //             );
        //         })}
        //     </Menu>
        // );

        /* 用户管理页面控制栏 */
        let uiTitleArea = (
            < div className="db_manager_contents_title" >
                <div className="manage_stretch_right">
                    <Input placeholder="搜索用户名/代码" ref="user_name_search" defaultValue={this.state.strKeywords} />
                    <Button className="manage_contents_title_margin"
                        type="primary" onClick={this.UserNameSearch}>搜索
                    </Button>
                </div>
                <div className="manage_title_filter_area">
                    <span className="manage_stretch_right_tiny">用户类别</span>
                    <Dropdown className="manage_contents_title_margin manage_stretch_right"
                        overlay={uiUserTypeMenu} placement="bottomLeft">
                        <Button>
                            {this.arrFilterUserType[this.state.objFilter.iCurrentUserType]}
                            <Icon className="dropdown_icon" type="down" />
                        </Button>
                    </Dropdown>

                </div>
                <div className="manage_title_button_right_side">
                    <Button className="manage_contents_title_margin" type="primary" onClick={this.ShowDlg.bind(this, 1)} disabled={this.state.btnControlDisable[1]}>
                        <Icon type="edit" /> 编辑</Button>
                    {/* <Button className="manage_contents_title_margin" type="danger" onClick={this.ShowDlg.bind(this, 2)} disabled={this.state.btnControlDisable[2]}>
                        <Icon type="close" /> 删除</Button> */}
                </div>
            </div >
        );

        // 如果界面上需要显示的内容已经从数据库获得
        if (!this.state.bLoading) {
            // 主体表格相关
            const { selectedRowKeys } = this.state;
            const rowSelection = {
                selectedRowKeys,
                onChange: this.onSelectedRowKeysChange,
            };
            // 表格分页属性
            const paginationProps = {
                showSizeChanger: true,
                showQuickJumper: false,
                showTotal: () => `共 ${this.arrTableData.length} 个 用户`,
                defaultPageSize: 10,
                defaultCurrent: this.state.iCurrentPage,
                pageSizeOptions: ['10', '20', '50', '100'],
                total: this.arrTableData.length,
                position: 'bottom'
            };
            // 用户信息编辑弹窗
            let uiModal = (
                <Modal title="编辑账户" width={350} visible={this.state.bShowDlg}
                    okText="确定" cancelText="取消" onOk={this.handleOk} onCancel={this.handleCancel} >
                    <div className="user_edit_info_row">
                        <div className="user_edit_info_title">账户名：</div>
                        <Input className="user_edit_info_input" ref="user_name"
                            defaultValue={this.objCurrentUser.name} />
                    </div>
                    <div className="user_edit_info_row">
                        <div className="user_edit_info_title">进仓码：</div>
                        <Input className="user_edit_info_input" ref="invite_code"
                            defaultValue={this.objCurrentUser.invite_code} />
                    </div>
                    <div className="user_edit_info_row">
                        <div className="user_edit_info_title">优惠折扣：</div>
                        <Input className="user_edit_info_input" ref="price_off" suffix={"%"}
                            defaultValue={this.objCurrentUser.price_off} />
                    </div>
                    <div className="user_edit_info_row">
                        <div className="user_edit_info_title">新的密码：</div>
                        <Input.Password className="user_edit_info_input" ref="new_password"
                            onChange={e => this.setState({ strNewPassword: e.target.value })}
                            defaultValue={""} value={this.state.strNewPassword} />
                    </div>
                    <div className="user_edit_info_row">
                        <div className="user_edit_info_title">确认密码：</div>
                        <Input.Password className="user_edit_info_input" ref="comfirm_password"
                            onChange={e => this.setState({ strConfirmPassword: e.target.value })}
                            defaultValue={""} value={this.state.strConfirmPassword} />
                    </div>
                </Modal>
            );
            if (this.state.iDlgType === 2) {
                uiModal = (
                    <Modal width={500} title={"删除用户信息"} visible={this.state.bShowDlg} okType="danger"
                        onOk={this.handleOk} okText="确定"
                        onCancel={this.handleCancel} cancelText="取消">
                        <div className="modal_item">
                            <div>{this.objCurrentUser.name}
                                <br /><br />选中用户的所有信息都将被删除……<br />确定删除选中的用户？
                            </div>
                        </div>
                    </Modal>
                );
            }

            return (
                <div className="store_manage_contents store_manage_top_space">
                    {uiTitleArea}
                    {uiModal}
                    {/* 表格主体 */}
                    <Table style={{ margin: '10px', width: '97.5%', overflowX: 'auto' }}
                        rowSelection={rowSelection} pagination={paginationProps}
                        columns={arrTableHeader} dataSource={this.arrTableData} bordered
                        onRow={(record) => ({ onClick: () => { this.selectRow(record); } })} />
                </div >
            );
        } else {
            return (
                <div className="store_manage_contents store_manage_top_no_space">
                    {uiTitleArea}
                    <Spin className="manage_empty_loading" size="large" tip="加载中……" />
                </div>
            );
        }
    }
}
export default DBUserManager;
