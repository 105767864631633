import React, { Component } from "react";
import { Resizable } from 'react-resizable';
import axios from 'axios';
import moment from 'moment';

import "../../../styles/Background/UI_Module/store_item_management.css";
import "../../../styles/Background/UI_Module/order_management.css";
import "../../../styles/Background/UI_Module/auto_order_management.css";
import '../../../styles/Background/store_manager.css';
import '../../../styles/Background/ego_manager.css';

import Table from 'antd/es/table';
import Icon from 'antd/es/icon';
import Button from 'antd/es/button';
import Modal from 'antd/es/modal';
import Menu from 'antd/es/menu';
import Dropdown from 'antd/es/dropdown';
import Checkbox from 'antd/es/checkbox';
import Input from 'antd/es/input';
import message from 'antd/es/message';
import Spin from 'antd/es/spin';
import DatePicker from 'antd/es/date-picker';
import Switch from 'antd/es/switch';
import Radio from 'antd/es/radio';
import Steps from 'antd/es/steps';
import Collapse from 'antd/es/collapse';

const g_strMessageKey = 'updating';
const { MonthPicker, RangePicker } = DatePicker;
const { Step } = Steps;
const { TextArea } = Input;
const { Panel } = Collapse;

const ResizeableTitle = props => {
    const { onResize, width, ...restProps } = props;

    if (!width) {
        return <th {...restProps} />;
    }

    return (
        <Resizable
            width={width}
            height={0}
            onResize={onResize}
            draggableOpts={{ enableUserSelectHack: false }}
        >
            <th {...restProps} />
        </Resizable>
    );
};
// 管理员
const arrTableHeader = [
    { title: '运单号', dataIndex: 'oid', width: 105, ellipsis: true },
    { title: '物流单号', dataIndex: 'express', width: 145, ellipsis: true },
    { title: '客户', dataIndex: 'client', width: 100, ellipsis: true },
    { title: '柜号', dataIndex: 'closet', width: 125, ellipsis: true },
    { title: '物流状态', dataIndex: 'status_str', width: 85, ellipsis: true },
    { title: '目的城市', dataIndex: 'destination', width: 85, ellipsis: true },
    { title: '预计到港', dataIndex: 'eta', width: 100, ellipsis: true },
    { title: '预计到仓', dataIndex: 'etd', width: 100, ellipsis: true },
    { title: '备注', dataIndex: 'comments', width: 150, ellipsis: true },
    { title: '海关文件', dataIndex: 'cf_button', width: 85, ellipsis: true }
];
// 客户看到的/海派列表
const arrTableHeader1 = [
    { title: '运单号', dataIndex: 'oid', width: 105, ellipsis: true },
    { title: '物流单号', dataIndex: 'express', width: 145, ellipsis: true },
    { title: '客户', dataIndex: 'client', width: 100, ellipsis: true },
    { title: '柜号', dataIndex: 'closet', width: 125, ellipsis: true },
    { title: '物流状态', dataIndex: 'status_str', width: 85, ellipsis: true },
    { title: '目的城市', dataIndex: 'destination', width: 85, ellipsis: true },
    { title: '预计到港', dataIndex: 'eta', width: 100, ellipsis: true },
    { title: '预计到仓', dataIndex: 'etd', width: 100, ellipsis: true },
    { title: '备注', dataIndex: 'comments', width: 175, ellipsis: true }
];
// const arrTableHeader2 = [
//     { title: '运单号', dataIndex: 'oid', width: 125, ellipsis: true },
//     { title: '物流单号', dataIndex: 'express', width: 150, ellipsis: true },
//     { title: '客户', dataIndex: 'client', width: 125, ellipsis: true },
//     { title: '柜号', dataIndex: 'closet', width: 125, ellipsis: true },
//     { title: '物流状态', dataIndex: 'status_str', width: 85, ellipsis: true },
//     { title: 'ETA', dataIndex: 'eta', width: 100, ellipsis: true },
//     { title: '目的地', dataIndex: 'destination', width: 80, ellipsis: true },
//     { title: '备注', dataIndex: 'comments', width: 175, ellipsis: true }
// ];
// 派送列表
const arrTableHeaderDeliver = [
    { title: '运单号', dataIndex: 'oid', width: 105, ellipsis: true },
    { title: '客户', dataIndex: 'client', width: 125, ellipsis: true },
    { title: '发货人', dataIndex: 'deliver_name', width: 125, ellipsis: true },
    { title: '收货人', dataIndex: 'receiver_name', width: 125, ellipsis: true },
    { title: '放货', dataIndex: 'release_mark', width: 100, ellipsis: true },
    { title: 'Reference', dataIndex: 'reference', width: 100, ellipsis: true },
    { title: '备注', dataIndex: 'comments', width: 300, ellipsis: true }
];

class LogisticOrder extends Component {
    constructor(props) {
        super();
        this.strToday = new Date();
        this.strToday = this.strToday.toISOString().split('T')[0];
        this.tableHead = arrTableHeader;
        if (props.iType === 5) {
            this.tableHead = arrTableHeader1;
        }
        if (props.iUserLevel < 5)
            this.tableHead = arrTableHeader1;
        if (props.iType >= 10)
            this.tableHead = arrTableHeaderDeliver;
        this.strOrderTitle = "";
        if (props.objStoreInfo.id === 10000) {
            this.strOrderTitle = "LK";
        }

        this.state = {
            btnControlDisable: [false, true, true],  // 操作按钮控制
            selectedRowKeys: [],  // 选中的行
            bDlgShow: false,  // 是否显示弹窗
            iDlgType: 0,  // 弹窗类别
            // dlgInfo: { iOrderType: 0, iOrderPaymentStatus: 0, iOrderTransitStatus: 0, iOrderTransitDetails: 0 },
            // filterInfo: { iOrderType: 0, iOrderPaymentStatus: 0 },
            iCurrentPage: 1,
            bLoading: true,
            columns: this.tableHead,
            etd: this.strToday,
            eta: this.strToday,
            bMultiEdit: false,
            bMultiEditDlg: false,
            iMultiEditTransitStatus: 0,
            iMultiEditTransitDetails: 0,
            iDateDesc: 1,
            iOrderDesc: 1,
            iOrderOrDate: 0,
            bHighlight: false,
            iHighlightFilter: 0,
            selectedFile: null,
            selectedFiles: null,
            bFileDelete: false,
            bFilterStatusChange: false,
            // iServiceType: 0,
            // iServiceTypeFilter: 0,
            bSelectClientDlg: false,
            bSelectClientFilterDlg: false,
            bSelectAgentDlg: false,
            bSelectAgentFilterDlg: false,
            arrMultiEditOption: [false, false, true, false],
            bHouseBillDlg: false,
            iHouseBillType: -1,
            iDlgTab: 0,
            iShipRelease: 0,
            arrPortRelease: [0, 0, 0],
            arrStorageRelease: [0],
            iDirectClient: 0,
            iClearRelease: 0,
            free_use_date: this.strToday,
            box_use_date: this.strToday,
            free_storage_date: this.strToday,
            bSelectStorageDlg: false,
            bSelectStorageFilterDlg: false,
            bSelectTruckDlg: false,
            bSelectTruckFilterDlg: false,
            bOrderInfoChange: false,
            iCurrency: 0,
            iStorageCustom: 0,
            bStorageFeeChange: false,
            iPath: 1,
            bClearFeeChange: false,
            bHBFeeChange: false,
            iPOD: 0,
            iLKRelease: 0,
            iClientRelease: 0,
            bSelectCarTeamDlg: false,
            bTransferFeeChange: false,
            iShipFeeConfirm: 0,
            iPortFeeConfirm: 0,
            iStorageFeeConfirm: 0,
            iClearFeeConfirm: 0,
            iTransitFeeConfirm: 0,
            iDeliverFeeConfirm: 0,
            iPickupFeeConfirm: 0,

            iCurrentOrderStatusFilter: 0,
            bSelectUserDlg: false,
            bSelectUserFilterDlg: false,

            bClientConfirmDlg: false,

            bEditComments: false,
            bNewComment: false,
            bViewAllComments: false,

            bReportDlg: false,
            bReleaseDlg: false,
            bAllFeeChange: false,
            iCurrentAllFee: 0
        };

        this.arrOrderTypeShort = ["F", "D", "O", "O", "A", "T", "S"];
        this.arrOrderType = ["Fedex", "DHL", "Ocean-整柜", "Ocean-散柜", "Air", "Truking", "Sales"];
        this.arrOrderTransitStatus = ["未发货", "国内收货", "已发货", "已到港", "到目的地", "海派中", "已签收"];
        this.arrOrderTransitDetails = [];
        this.arrFilterOrderType = ["全部", "Fedex", "DHL", "Ocean-整柜", "Ocean-散柜", "Air", "Truking", "Sales"];
        this.arrFilterPaymentStatus = ["全部", "未入账", "入账中", "已入账", "已作废"];
        this.arrFilterTransitStatus = ["全部", "未发货", "国内收货", "已发货", "已到港", "到目的地", "海派中", "已签收"];
        this.arrCurrency = ['加币 CAD', '美元 USD'];
        this.arrStatus = ['发船', '到港', '到仓', '清关', '换单', '海派', '自提'];
        this.arrOrderStatus = ['全部', '发船', '到港', '到仓', '清关', '换单', '海派', '自提'];
        this.arrTeamFilter = ['全部', 'Team A', 'Team B', 'Team C', 'Team D', 'Team E'];

        this.arrTableData = [];
        this.arrOrderData = [];
        this.arrFilterData = [];

        this.objCurrentOrder = {};
        // this.objDeleteOrder = {};
        this.arrSelectOrders = [];
        this.strDeleteOrderID = "";
        this.arrTransitList = [];
        this.dateDefault = moment();
        this.strPackageInfo = "";
        this.arrCustomFile = [];
        this.arrFileWaitList = [];
        this.bDeletingFile = false;
        this.dateCurrent = "";
        this.dateArrive = ["", ""];
        this.iPaymentStatus = 0;
        this.strKeyword = "";
        this.strHBLKeyword = "";
        this.strClientSearch = "";
        this.strUserSearch = "";
        this.iIDOrder = 0;
        this.iArriveOrder = 1;
        this.iHightLightOrder = -1;
        this.arrServiceTypeFilter = ['全部', '清关', '海派', '换单', '清关海派', '清关自提', '拆柜换单', '未确定'];
        this.arrServiceType = ['清关', '海派', '换单', '清关海派', '清关自提', '拆柜换单', '未确定'];

        this.iCurrentHouseBill = -1;
        this.arrHouseBillNo = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J', 'K', 'L', 'M', 'N', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z',
            'AA', 'BB', 'CC', 'DD', 'EE'
        ];
        for (let i = 0; i < this.arrHouseBillNo.length; i++) {
            let objHouseBillInfo = { no: this.arrHouseBillNo[i], create: false };
            this.arrHouseBillNo[i] = objHouseBillInfo;
        }
        this.arrHouseBill = [];
        this.bSaving = false;
        this.arrStorageFee = [
            { name: '打板', active: 0 },
            { name: '贴标', active: 0 },
            { name: '超规', active: 0 },
            { name: '仓储', active: 0 },
            { name: '出仓', active: 0 },
            { name: '其他', active: 0 },
        ]
        this.iCurrentStorageFee = 0;
        this.arrClearFee = [
            { name: '超项', active: 0 },
            { name: 'CFIA/OGD', active: 0 },
            { name: '查验', active: 0 },
            { name: '其他', active: 0 },
        ]
        this.arrHBFee = [
            { name: '报关', active: 0 },  // 0
            { name: '关税', active: 0 },  // 1
            { name: 'GST', active: 0 },  // 2
            { name: '打板', active: 0 },  // 3
            { name: '贴标', active: 0 },  // 4
            { name: '超规', active: 0 },  // 5
            { name: '仓储', active: 0 },  // 6
            { name: '出仓', active: 0 },  // 7
            { name: '超项', active: 0 },  // 8
            { name: 'CFIA/OGD', active: 0 },  //9
            { name: '查验', active: 0 },  // 10
            { name: '换单', active: 0 },  // 11
            { name: '派送', active: 0 },  // 12
            { name: '托盘', active: 0 },  // 13
            { name: '等候', active: 0 },  // 14
            { name: '其他', active: 0 },  // 15
        ]
        this.iCurrentHBFee = 0;
        this.arrPath = ['换单', '海派', '自提'];
        this.arrTransferFee = [
            { name: '托盘', active: 0 },
            { name: '等候', active: 0 },
            { name: '其他', active: 0 },
        ]
        this.iCurrentTransferFee = 0;

        this.iChargeUserID = -1;
        this.iChargeUserTeam = -1;

        this.iCurrentAgent = -1;
        this.iCurrentClient = -1;
        this.iCurrentTruck = -1;
        this.iCurrentStorage = -1;
        this.iCurrentCarTeam = -1;

        this.objDeliverOrder = {

        }

        this.iCurrentOrderStatusFilter = 0;
        this.iCurrentTeamFilter = 0;
        this.iConfirmCode = 1234;

        this.arrOrderComments = [];
        this.arrHBLComments = [];

        this.iDeleteComment = -1;

        this.bFeeChange = false;
        this.abHBFeeChange = false;

        this.strClientSearch = "";

        // this.strEmailText = "Dear...,\n\n";
        // this.strEmailText += "This is Lekon Logistics, we are appointed as your local agent in Canada by your Chinese freight forwarder. We will be handling your shipment 麦头 after they have arrived in Canada, for example customs clearance, deconsolidation, delivery service, and more.\n\n";
        // this.strEmailText += "您好，\n\n这里是Lekon Logistics , 我们受您的中国货代公司委托，处理一批唛头为：麦头 的货物到达加拿大以后的目的港服务（如进口清关，拆柜，派送等）。\n\n";
        // this.strEmailText += "Please be advised of the schedule of your shipment: \n以下是您的货物的预计行程：\n\n";
        // this.strEmailText += "· ETA 港口城市 port: eta 预计到港 \n· ETA 目的地城市 warehouse: etd 预计到仓\n\n";
        // this.strEmailText +=
        //     "Please confirm your delivery address and contact below: \n请确认以下的派送地址和联系方式是否正确：\n 详细地址\n 联系电话\n";
        // this.strEmailText += "………………………………\n\n";
        // this.strEmailText += "Please confirm if you have received this email notification and let us know if you have any concerns.\n";
        // this.strEmailText += "烦请回复邮件以示收到通知，如您有任何问题欢迎您随时联系我们。\n"
        this.strEmailTextHaiPai = "Dear 名字,\n\n";
        this.strEmailTextHaiPai += "This is Lekon Logistics, we are appointed as your local agent in Canada by your Chinese freight forwarder. We will be handling your shipment 麦头 after they have arrived in Canada, for example customs clearance, deconsolidation, delivery service, and more.\n\n";
        this.strEmailTextHaiPai += "您好，名字\n\n这里是Lekon Logistics , 我们受您的中国货代公司委托，处理一批唛头为：麦头 的货物到达加拿大以后的目的港服务（如进口清关，拆柜，派送等）。\n\n";
        this.strEmailTextHaiPai += "Please be advised of the schedule of your shipment: \n以下是您的货物的预计行程：\n\n";
        this.strEmailTextHaiPai += "· ETA 港口城市 port: eta 预计到港 \n· ETA 目的地城市 warehouse: etd 预计到仓\n\n";
        this.strEmailTextHaiPai +=
            "Please confirm your delivery address and contact below: \n请确认以下的派送地址和联系方式是否正确：\n ADDRESS\n PHONE\n";
        this.strEmailTextHaiPai += "………………………………\n\n";
        this.strEmailTextHaiPai += "Please confirm if you have received this email notification and let us know if you have any concerns.\n";
        this.strEmailTextHaiPai += "烦请回复邮件以示收到通知，如您有任何问题欢迎您随时联系我们。\n"
        this.strEmailTextOgeHaiPai = this.strEmailTextHaiPai;

        this.strEmailTextZiTi = "Dear 名字,\n\n";
        this.strEmailTextZiTi += "This is Lekon Logistics, we are appointed as your local agent in Canada by your Chinese freight forwarder. We will be handling your shipment 麦头 after they have arrived in Canada, for example customs clearance, deconsolidation, delivery service, and more.\n\n";
        this.strEmailTextZiTi += "您好，名字\n\n这里是Lekon Logistics , 我们受您的中国货代公司委托，处理一批唛头为：麦头 的货物到达加拿大以后的目的港服务（如进口清关，拆柜，提货等）。\n\n";
        this.strEmailTextZiTi += "Please be advised of the schedule of your shipment: \n以下是您的货物的预计行程：\n\n";
        this.strEmailTextZiTi += "· ETA 港口城市 port: eta 预计到港 \n· ETA 目的地城市 warehouse: etd 预计到仓\n\n";
        this.strEmailTextZiTi +=
            "We will handle the customs clearance and will provide you with the pick-up reference & location once shipment is released by CBSA.\n";
        this.strEmailTextZiTi +=
            "我们会负责为您的货物清关，后续会通知您提货细节和提货地址。\n\n";
        this.strEmailTextZiTi += "………………………………\n\n";
        this.strEmailTextZiTi += "Please confirm if you have received this email notification and let us know if you have any concerns.\n";
        this.strEmailTextZiTi += "烦请回复邮件以示收到通知，如您有任何问题欢迎您随时联系我们。\n"
        this.strEmailTextOgeZiTi = this.strEmailTextZiTi;

        this.strEmailTextHuanDan = "Dear 名字,\n\n";
        this.strEmailTextHuanDan += "This is Lekon Logistics, we are appointed as your local agent in Canada by your Chinese freight forwarder. We will be handling your shipment 麦头 after they have arrived in Canada.\n\n";
        this.strEmailTextHuanDan += "您好，名字\n\n这里是Lekon Logistics , 我们受您的中国货代公司委托，处理一批唛头为：麦头 的货物到达加拿大以后的目的港服务。\n\n";
        this.strEmailTextHuanDan += "Please be advised of the schedule of your shipment: \n以下是您的货物的预计行程：\n\n";
        this.strEmailTextHuanDan += "· ETA 港口城市 port: eta 预计到港 \n· ETA 目的地城市 warehouse: etd 预计到仓\n\n";
        this.strEmailTextHuanDan += "Please keep in mind to deal with the customs clearance and pick-up by yourself after the shipment arrival.\n";
        this.strEmailTextHuanDan += "Please find the attached e-manifest and bill of lading.\n";
        this.strEmailTextHuanDan += "It is pleased to notify us whenever your shipment is released by the CBSA, thank you.\n";
        this.strEmailTextHuanDan += "货物到港后请自行安排清关和提货，请查收附件清关用的e-manifest 和提单。海关放行后请通知我以便给您放货，谢谢\n";
        this.strEmailTextHuanDan += "………………………………\n\n";
        this.strEmailTextHuanDan += "Please confirm if you have received this email notification and let us know if you have any concerns.\n";
        this.strEmailTextHuanDan += "烦请回复邮件以示收到通知，如您有任何问题欢迎您随时联系我们。\n"
        this.strEmailTextOgeHuanDan = this.strEmailTextHuanDan;


        // this.strReleaseText = "Dear customer,\n\n";
        // this.strReleaseText += "Shipment has been released by CBSA.\nThis shipment has been released to 收货人.\n";
        // this.strReleaseText += "Please pick-up before 免仓储日 to avoid any extra storage charge.\n\n";
        // this.strReleaseText += "Pick-up reference：编号\nTotal 包裹数 packages\n\n";
        // this.strReleaseText += "Warehouse address and hour：\n仓库信息\n\n";
        // this.strReleaseText += "Notes: please verify the cargo detail upon pick-up, our company WILL NOT take any responsibility if pick-up the wrong shipment or pick-up shortage.\nThank you.\n";

        this.strReleaseText = "Hi\n\n";
        this.strReleaseText += "Shipment has been released by the customs, and here is your pickup information.\n\n";
        this.strReleaseText += "This shipment has been released to 收货人\n";
        this.strReleaseText += "Please pick it up on or before (免仓储日) to avoid any extra storage charge.\n"
        this.strReleaseText += "Pick-up reference：编号\n\n";
        this.strReleaseText += "Warehouse address and hour：\n仓库信息\n\n";
        this.strReleaseText += "Notes: please verify the cargo detail upon pick-up, our company WILL NOT take any responsibility if pick-up the wrong shipment or pick-up shortage.\n\n";
        this.strReleaseText += "Pick-up policy:\n\n";
        this.strReleaseText += "1.     Please pick up all items within one attempt, multiple pick-up attempts will result in extra dock fees at your own expense.\n";
        this.strReleaseText += "2.     Please be aware, some warehouses require a pickup deposit upon arriving at the warehouse, the deposit will be returned after the pickup process is done.\n";
        this.strReleaseText += "3.     Please do not leave any garbage at the warehouse. Garbage disposal fees will be applied to your bill if garbage is left behind.\n";
        this.strReleaseText += "4.     Please verify the cargo details upon pick-up (包裹数 packages). Our company WILL NOT take any responsibility if picked up the wrong shipment or pick up a pick-up shortage.\n";
        this.strReleaseText += "5.     Please clear the balance below with the warehouse when picking up your shipment (if applicable): Dock Fee\n";

        this.strReleaseTextOge = this.strReleaseText;

        this.arrAllFee = [
            { name: '代理', active: 0 },
            { name: '船东', active: 0 },
            { name: '码头', active: 0 },
            { name: '拖柜', active: 0 },
            { name: '预提柜', active: 0 },
            { name: '堆场', active: 0 },
            { name: '车架', active: 0 },
            { name: '危险品', active: 0 },
            { name: '空跑', active: 0 },
            { name: '拆柜', active: 0 },
            { name: '分货', active: 0 },
            { name: '打板', active: 0 },
            { name: '贴标', active: 0 },
            { name: '超规', active: 0 },
            { name: '仓储', active: 0 },
            { name: '出仓', active: 0 },
            { name: '报关', active: 0 },
            { name: '关税', active: 0 },
            { name: 'GST', active: 0 },
            { name: '超项', active: 0 },
            { name: 'CFIA/OGD', active: 0 },
            { name: '查验', active: 0 },
            { name: '其他', active: 0 }
        ]
        this.iCurrentAllFee = 0;
        this.bAllFeeChange = false;

    }

    // 预计到仓更改
    StorageDate = (date, dateString) => {
        this.setState({
            ...this.state,
            etd: dateString
        });
    }
    // 预计到港日期更改
    ArriveDateChange = (date, dateString) => {
        this.setState({
            ...this.state,
            eta: dateString
        });
    }
    // 码头免租期更改
    FreeUseDateChange = (date, dateString) => {
        this.setState({
            ...this.state,
            free_use_date: dateString
        });
    }
    // 箱使期限
    BoxUseDateChange = (date, dateString) => {
        this.setState({
            ...this.state,
            box_use_date: dateString
        });
    }
    // 表格列款调整
    components = {
        header: {
            cell: ResizeableTitle,
        },
    };
    handleResize = index => (e, { size }) => {
        this.setState(({ columns }) => {
            const nextColumns = [...columns];
            nextColumns[index] = {
                ...nextColumns[index],
                width: size.width,
            };
            return { columns: nextColumns };
        });
    };
    ResetTableLayout = () => {
        this.setState({
            ...this.state,
            columns: arrTableHeader
        })
    }
    // 选中某行的响应函数
    selectRow = (record) => {
        const selectedRowKeys = [...this.state.selectedRowKeys];
        if (selectedRowKeys.indexOf(record.key) >= 0) {
            selectedRowKeys.splice(selectedRowKeys.indexOf(record.key), 1);
        } else {
            selectedRowKeys.push(record.key);
        }
        this.SelectedRowStateSetting(selectedRowKeys);
    }
    // 选中的行发生变化的响应函数
    onSelectedRowKeysChange = (selectedRowKeys) => {
        this.SelectedRowStateSetting(selectedRowKeys);
    }
    // 处理选中的表行行
    SelectedRowStateSetting = (selectedRowKeys) => {
        let btnControlDisable = [];
        if (selectedRowKeys.length === 1) {
            btnControlDisable = [false, false, false];
            this.objCurrentOrder = this.arrOrderData[selectedRowKeys[0]];
            // this.objDeleteOrder = this.arrOrderData[selectedRowKeys[0]];
            // if (this.iCurrentOrderStatusFilter = 0 || this.iCurrentTeamFilter > 0)
            //     this.objDeleteOrder = this.arrFilterData[selectedRowKeys[0]];

        } else {
            btnControlDisable = [false, true, true];
            this.objCurrentOrder = {};
        }
        // 选中多行，为统一编辑作准备
        // this.arrSelectOrders = [];
        // for (let i = 0; i < selectedRowKeys.length; i++) {
        //     this.arrSelectOrders.push(this.arrTableData[selectedRowKeys[i]]);
        // }
        // let bMultiEdit = false;
        // if (this.arrSelectOrders.length > 1) {
        //     bMultiEdit = true;
        // }
        let bHighlight = false;
        if (this.arrSelectOrders.length > 0) {
            bHighlight = true;
        }
        this.setState({
            ...this.state,
            selectedRowKeys,
            btnControlDisable: btnControlDisable,
            // bMultiEdit: bMultiEdit,
            bHighlight: bHighlight
        });
    }
    //显示弹窗前预置编辑的内容
    PresetNewDlg() {
        // 派送列表
        if (this.props.iType >= 10) {
            this.objDeliverOrder.reference = "";
            this.objDeliverOrder.receiver_name = "";
            this.objDeliverOrder.receiver_phone = "";
            this.objDeliverOrder.receiver_email = "";
            this.objDeliverOrder.tyreceiver_addresspe = "";

            this.objDeliverOrder.deliver_name = "";
            this.objDeliverOrder.deliver_phone = "";
            this.objDeliverOrder.deliver_email = "";
            this.objDeliverOrder.deliver_address = "";

            this.objDeliverOrder.oversize = "";
            // this.objDeliverOrder.deliver_comments = "";
            this.objDeliverOrder.carteam = "";

            this.objDeliverOrder.deliver_fee = 0.00;
            this.objDeliverOrder.storage_export_fee = 0.00;
            this.objDeliverOrder.on_plate_fee = 0.00;
            this.objDeliverOrder.waiting_fee = 0.00;
            this.objDeliverOrder.deliver_other_fee = 0.00;

            this.objDeliverOrder.deliver_cost = 0.00;
            this.objDeliverOrder.storage_export_cost = 0.00;
            this.objDeliverOrder.on_plate_cost = 0.00;
            this.objDeliverOrder.waiting_cost = 0.00;
            this.objDeliverOrder.deliver_other_cost = 0.00;

            this.objDeliverOrder.deliver_other_fee_comments = "";
            this.objDeliverOrder.comments = "";

            this.objDeliverOrder.deliver_total_fee = 0.00;
            this.objDeliverOrder.deliver_total_cost = 0.00;
            this.objDeliverOrder.deliver_total_benefit = 0.00;
            if (this.refs.receiver_name !== undefined) {
                this.refs.user.state.value = "";
                this.refs.client.state.value = "";

                this.refs.receiver_name.state.value = "";
                this.refs.receiver_phone.state.value = "";
                this.refs.receiver_email.state.value = "";
                this.refs.receiver_address.state.value = "";

                this.refs.deliver_name.state.value = "";
                this.refs.deliver_phone.state.value = "";
                this.refs.deliver_email.state.value = "";
                this.refs.deliver_address.state.value = "";

                this.refs.oversize.state.value = "";
                // this.refs.deliver_comments.state.value = "";
                this.refs.carteam.state.value = "";

                this.refs.deliver_fee.state.value = 0.00;
                this.refs.storage_export_fee.state.value = 0.00;
                this.refs.on_plate_fee.state.value = 0.00;
                this.refs.waiting_fee.state.value = 0.00;
                this.refs.deliver_other_fee.state.value = 0.00;
                this.refs.deliver_cost.state.value = 0.00;
                this.refs.storage_export_cost.state.value = 0.00;
                this.refs.on_plate_cost.state.value = 0.00;
                this.refs.waiting_cost.state.value = 0.00;
                this.refs.deliver_other_cost.state.value = 0.00;

                this.refs.deliver_other_comments.state.value = "";


                this.refs.deliver_total_fee.state.value = 0.00;
                this.refs.deliver_total_cost.state.value = 0.00;
                this.refs.deliver_total_benefit.state.value = 0.00;
            }
        }
        else {
            // 通用部分
            if (this.refs.closet !== undefined) {
                this.refs.oid_before.state.value = "";

                this.refs.user.state.value = "";
                this.refs.client.state.value = "";
                this.refs.agent.state.value = "";

                this.refs.express.state.value = "";
                this.refs.closet.state.value = "";

                this.refs.num.state.value = "0";
                this.refs.cbm.state.value = "0";
                this.refs.weight.state.value = "0";

                this.refs.destination.state.value = "";
                this.refs.comments.state.value = "";
            }
        }

    }
    //弹出对话框
    ShowDlg = (iDlgType) => {
        switch (iDlgType) {
            // 新建运单
            case 0: {
                this.PresetNewDlg();
                this.arrOrderTransitDetails = [];
                this.arrHouseBill = [];
                // 派送FTL
                if (this.props.iType >= 10) {

                    this.setState({
                        ...this.state,
                        bDlgShow: true,
                        iDlgType: iDlgType,
                        iDlgTab: 5,
                        iCurrency: 0,
                        iClientRelease: 0,
                        iLKRelease: 0,
                        iPOD: 0
                    });
                }
                // 其他情况
                else {
                    this.setState({
                        ...this.state,
                        bDlgShow: true,
                        iDlgType: iDlgType,
                        iDlgTab: 0,
                        eta: this.strToday,
                        etd: this.strToday,
                        free_use_date: this.strToday,
                        box_use_date: this.strToday,
                        bMarkable: false,
                        iCurrency: 0
                    });
                }
                break;
            }
            // 编辑运单
            case 1: {

                this.GetComments();

                let iSelectedRow = this.state.selectedRowKeys[0];

                this.iCurrentClient = this.arrOrderData[iSelectedRow].client_id;
                this.iChargeUserID = this.arrOrderData[iSelectedRow].user_id;
                this.iChargeUserTeam = this.arrOrderData[iSelectedRow].team;

                let bMarkable = true;
                if (this.arrOrderData[iSelectedRow].markable !== undefined && this.arrOrderData[iSelectedRow].markable !== null) {
                    if (this.arrOrderData[iSelectedRow].markable === -1)
                        bMarkable = false;
                }
                let strETA = this.arrOrderData[iSelectedRow].eta;
                if (strETA === null || strETA.isNaN || strETA === "")
                    strETA = this.strToday;
                let strETD = this.arrOrderData[iSelectedRow].etd;
                if (strETD === null || strETD.isNaN || strETD === "")
                    strETD = this.strToday;
                // let strFreeUseDate = this.arrOrderData[iSelectedRow].free_use_date;
                // if (strFreeUseDate === "" || strFreeUseDate === null || strFreeUseDate.isNaN) {
                //     strFreeUseDate = this.strToday;
                // } else {
                //     if (strFreeUseDate.indexOf('T'))
                //         strFreeUseDate = strFreeUseDate.split('T')[0];
                // }
                // let strBoxUseDate = this.arrOrderData[iSelectedRow].box_use_date;
                // if (strBoxUseDate === "" || strBoxUseDate === null || strBoxUseDate.isNaN) {
                //     strBoxUseDate = this.strToday;
                // } else {
                //     if (strBoxUseDate.indexOf('T'))
                //         strBoxUseDate = strBoxUseDate.split('T')[0];
                // }
                let strFreeStorageDate = this.arrOrderData[iSelectedRow].free_storage_date;
                if (strFreeStorageDate === "" || strFreeStorageDate === null || strFreeStorageDate.isNaN) {
                    strFreeStorageDate = this.strToday;
                } else {
                    if (strFreeStorageDate.indexOf('T'))
                        strFreeStorageDate = strFreeStorageDate.split('T')[0];
                }


                // 统一费用计算
                for (let i = 0; i < this.arrAllFee.length; i++)
                    this.arrAllFee[i].active = 0;

                // 0. 代理费
                if (this.arrOrderData[iSelectedRow].agent_active === 1)
                    this.arrAllFee[0].active = 1;
                // 1. 船东费
                if (this.arrOrderData[iSelectedRow].ship_active === 1)
                    this.arrAllFee[1].active = 1;
                // 2. 码头费
                if (this.arrOrderData[iSelectedRow].port_active === 1)
                    this.arrAllFee[2].active = 1;
                // 3. 拖柜费
                if (this.arrOrderData[iSelectedRow].truck_active === 1)
                    this.arrAllFee[3].active = 1;
                // 4. 预提柜
                if (this.arrOrderData[iSelectedRow].prepick_active === 1)
                    this.arrAllFee[4].active = 1;
                // 5. 堆场费
                if (this.arrOrderData[iSelectedRow].stack_active === 1)
                    this.arrAllFee[5].active = 1;
                // 6. 车架费
                if (this.arrOrderData[iSelectedRow].car_frame_active === 1)
                    this.arrAllFee[6].active = 1;
                // 7. 危险费
                if (this.arrOrderData[iSelectedRow].danger_active === 1)
                    this.arrAllFee[7].active = 1;
                // 8. 空跑费
                if (this.arrOrderData[iSelectedRow].null_active === 1)
                    this.arrAllFee[8].active = 1;
                // 9. 拆柜费
                if (this.arrOrderData[iSelectedRow].open_active === 1)
                    this.arrAllFee[9].active = 1;
                // 10. 分货费
                if (this.arrOrderData[iSelectedRow].divide_active === 1)
                    this.arrAllFee[10].active = 1;
                // 11. 打板费
                if (this.arrOrderData[iSelectedRow].on_plate_active === 1)
                    this.arrAllFee[11].active = 1;
                // 12. 贴标费
                if (this.arrOrderData[iSelectedRow].tag_active === 1)
                    this.arrAllFee[12].active = 1;
                // 13. 超规费
                if (this.arrOrderData[iSelectedRow].oversize_active === 1)
                    this.arrAllFee[13].active = 1;
                // 14. 仓储费
                if (this.arrOrderData[iSelectedRow].storage_active === 1)
                    this.arrAllFee[14].active = 1;
                // 15. 出仓费
                if (this.arrOrderData[iSelectedRow].storage_export_active === 1)
                    this.arrAllFee[15].active = 1;
                // 16. 报关费
                if (this.arrOrderData[iSelectedRow].custom_active === 1)
                    this.arrAllFee[16].active = 1;
                // 17. 关税费
                if (this.arrOrderData[iSelectedRow].tax_active === 1)
                    this.arrAllFee[17].active = 1;
                // 18. GST费
                if (this.arrOrderData[iSelectedRow].gst_active === 1)
                    this.arrAllFee[18].active = 1;
                // 19. 超项费
                if (this.arrOrderData[iSelectedRow].overitem_active === 1)
                    this.arrAllFee[19].active = 1;
                // 20. CFIA费
                if (this.arrOrderData[iSelectedRow].cfia_active === 1)
                    this.arrAllFee[20].active = 1;
                // 21. 检查费
                if (this.arrOrderData[iSelectedRow].check_active === 1)
                    this.arrAllFee[21].active = 1;
                // 22. 其他费用
                if (this.arrOrderData[iSelectedRow].other_active === 1)
                    this.arrAllFee[22].active = 1;
                this.iCurrentAllFee = -1;
                for (let i = 0; i < this.arrAllFee.length; i++) {
                    if (this.arrAllFee[i].active === 0) {
                        this.iCurrentAllFee = i;
                        break;
                    }
                }


                let bDeliver = false;
                if (this.props.iType >= 10)
                    bDeliver = true;
                if (!bDeliver) {
                    if (this.arrOrderData[iSelectedRow].storage_custom === null)
                        this.arrOrderData[iSelectedRow].storage_custom = 0;
                    // 仓库收费初始化
                    // this.arrStorageFee = [
                    //     { name: '打板', active: 0 },
                    //     { name: '贴标', active: 0 },
                    //     { name: '超规', active: 0 },
                    //     { name: '仓储', active: 0 },
                    //     { name: '出仓', active: 0 },
                    //     { name: '其他', active: 0 }
                    // ];
                    // if (this.arrOrderData[iSelectedRow].on_plate_active === 1) {
                    //     this.arrStorageFee[0].active = 1;
                    // }
                    // if (this.arrOrderData[iSelectedRow].divide_item_active === 1) {
                    //     this.arrStorageFee[1].active = 1;
                    // }
                    // if (this.arrOrderData[iSelectedRow].tag_active === 1) {
                    //     this.arrStorageFee[2].active = 1;
                    // }
                    // if (this.arrOrderData[iSelectedRow].oversize_active === 1) {
                    //     this.arrStorageFee[3].active = 1;
                    // }
                    // if (this.arrOrderData[iSelectedRow].storage_active === 1) {
                    //     this.arrStorageFee[4].active = 1;
                    // }
                    // if (this.arrOrderData[iSelectedRow].storage_export_active === 1) {
                    //     this.arrStorageFee[5].active = 1;
                    // }
                    // if (this.arrOrderData[iSelectedRow].storage_other_active === 1) {
                    //     this.arrStorageFee[6].active = 1;
                    // }
                    this.iCurrentStorageFee = -1;
                    // for (let i = 0; i < this.arrStorageFee.length; i++) {
                    //     if (this.arrStorageFee[i].active === 0) {
                    //         this.iCurrentStorageFee = i;
                    //         break;
                    //     }
                    // }
                    // // 清关收费设置
                    // this.arrClearFee = [
                    //     { name: '超项', active: 0 },
                    //     { name: 'CFIA/OGD', active: 0 },
                    //     { name: '查验', active: 0 },
                    //     { name: '其他', active: 0 },
                    // ]
                    // if (this.arrOrderData[iSelectedRow].overitem_active === 1) {
                    //     this.arrClearFee[0].active = 1;
                    // }
                    // if (this.arrOrderData[iSelectedRow].cfia_active === 1) {
                    //     this.arrClearFee[1].active = 1;
                    // }
                    // if (this.arrOrderData[iSelectedRow].check_active === 1) {
                    //     this.arrClearFee[2].active = 1;
                    // }
                    // if (this.arrOrderData[iSelectedRow].custom_other_active === 1) {
                    //     this.arrClearFee[3].active = 1;
                    // }
                    // this.iCurrentClearFee = -1;
                    // for (let i = 0; i < this.arrClearFee.length; i++) {
                    //     if (this.arrClearFee[i].active === 0) {
                    //         this.iCurrentClearFee = i;
                    //         break;
                    //     }
                    // }


                    axios('/GetHouseBill', { params: { store_id: this.props.objStoreInfo.id, oid: this.arrOrderData[iSelectedRow].oid } }).then(({ data }) => {
                        this.arrHouseBill = data;
                        this.iCurrentHouseBill = -1;
                        // 设置当前聚焦的Tab
                        let iDlgTab = this.arrOrderData[iSelectedRow].status;
                        if (iDlgTab >= 4) {
                            iDlgTab = 3;
                            let bTransit = false;
                            let bDeliver = false;
                            let bPickup = false;
                            if (this.arrOrderData[iSelectedRow].storage_custom === 0) {
                                for (let i = 0; i < this.arrHouseBill.length; i++) {
                                    if (this.arrHouseBill[i].path === 0)
                                        bTransit = true;
                                    if (this.arrHouseBill[i].path === 1)
                                        bDeliver = true;
                                    if (this.arrHouseBill[i].path === 2)
                                        bPickup = true;
                                }
                            }
                            if (this.arrOrderData[iSelectedRow].storage_custom >= 1) {
                                for (let i = 0; i < this.arrHouseBill.length; i++) {
                                    if (this.arrHouseBill[i].path === 0)
                                        bTransit = true;
                                    if (this.arrHouseBill[i].path === 1)
                                        bDeliver = true;
                                    if (this.arrHouseBill[i].path === 2)
                                        bPickup = true;
                                }
                            }
                            if (bPickup)
                                iDlgTab = 6;
                            else {
                                if (bDeliver)
                                    iDlgTab = 5;
                                else
                                    if (bTransit)
                                        iDlgTab = 4;
                            }
                        }
                        if (this.props.iType === 5) {
                            iDlgTab = 5;
                        }
                        if (this.arrOrderData[iSelectedRow].ship_fee_confirm === null || this.arrOrderData[iSelectedRow].ship_fee_confirm === undefined)
                            this.arrOrderData[iSelectedRow].ship_fee_confirm = 0;
                        if (this.arrOrderData[iSelectedRow].port_fee_confirm === null || this.arrOrderData[iSelectedRow].port_fee_confirm === undefined)
                            this.arrOrderData[iSelectedRow].port_fee_confirm = 0;
                        if (this.arrOrderData[iSelectedRow].storage_fee_confirm === null || this.arrOrderData[iSelectedRow].storage_fee_confirm === undefined)
                            this.arrOrderData[iSelectedRow].storage_fee_confirm = 0;
                        if (this.arrOrderData[iSelectedRow].clear_fee_confirm === null || this.arrOrderData[iSelectedRow].clear_fee_confirm === undefined)
                            this.arrOrderData[iSelectedRow].clear_fee_confirm = 0;
                        if (this.arrOrderData[iSelectedRow].transit_fee_confirm === null || this.arrOrderData[iSelectedRow].transit_fee_confirm === undefined)
                            this.arrOrderData[iSelectedRow].transit_fee_confirm = 0;
                        if (this.arrOrderData[iSelectedRow].deliver_fee_confirm === null || this.arrOrderData[iSelectedRow].deliver_fee_confirm === undefined)
                            this.arrOrderData[iSelectedRow].deliver_fee_confirm = 0;
                        if (this.arrOrderData[iSelectedRow].pickup_fee_confirm === null || this.arrOrderData[iSelectedRow].pickup_fee_confirm === undefined)
                            this.arrOrderData[iSelectedRow].pickup_fee_confirm = 0;

                        if (this.strHBLKeyword !== undefined && this.strHBLKeyword !== null && this.strHBLKeyword !== "") {
                            iDlgTab = 0;
                        }
                        this.setState({
                            ...this.state,
                            bDlgShow: true,
                            iDlgType: iDlgType,
                            eta: strETA,
                            etd: strETD,
                            free_use_date: "",//strFreeUseDate,
                            box_use_date: "",//strBoxUseDate,
                            bMarkable: bMarkable,
                            iDlgTab: iDlgTab,
                            arrPortRelease: [
                                this.arrOrderData[iSelectedRow].order_release,
                                this.arrOrderData[iSelectedRow].custom_release,
                                this.arrOrderData[iSelectedRow].goc_release
                            ],
                            arrStorageRelease: [this.arrOrderData[iSelectedRow].open_release],
                            iShipRelease: this.arrOrderData[iSelectedRow].ship_release,
                            iClearRelease: this.arrOrderData[iSelectedRow].clear_release,
                            iCurrency: this.arrOrderData[iSelectedRow].currency,
                            iStorageCustom: this.arrOrderData[iSelectedRow].storage_custom,
                            iShipFeeConfirm: this.arrOrderData[iSelectedRow].ship_fee_confirm,
                            iPortFeeConfirm: this.arrOrderData[iSelectedRow].port_fee_confirm,
                            iStorageFeeConfirm: this.arrOrderData[iSelectedRow].storage_fee_confirm,
                            iClearFeeConfirm: this.arrOrderData[iSelectedRow].clear_fee_confirm,
                            iTransitFeeConfirm: this.arrOrderData[iSelectedRow].transit_fee_confirm,
                            iDeliverFeeConfirm: this.arrOrderData[iSelectedRow].deliver_fee_confirm,
                            iPickupFeeConfirm: this.arrOrderData[iSelectedRow].pickup_fee_confirm,
                            iCurrentAllFee: this.iCurrentAllFee
                        });
                    }).catch(function (error) { console.log(error); });
                    break;
                } else {
                    this.setState({
                        ...this.state,
                        bDlgShow: true,
                        iDlgType: 1,
                        bMarkable: bMarkable,
                        iCurrency: this.arrOrderData[iSelectedRow].currency,
                        iClientRelease: this.arrOrderData[iSelectedRow].client_release,
                        iLKRelease: this.arrOrderData[iSelectedRow].lk_release,
                        iPOD: this.arrOrderData[iSelectedRow].pod,
                        iCurrentAllFee: this.iCurrentAllFee
                    });
                    break;
                }

            }
            // 删除运单
            case 2: {
                // message.error("暂无权限删除运单！");
                // message.info("可以修改运单状态为'作废'或重新利用它！");
                this.strDeleteOrderID = this.objCurrentOrder.oid;
                this.setState({
                    ...this.state,
                    iDlgType: iDlgType,
                    bDlgShow: true,
                });
                break;
            }
            // 批量处理
            case 3: {
                this.TransitDetailsUpdate(0);
                this.setState({
                    ...this.state,
                    bMultiEditDlg: true,
                    iMultiEditTransitStatus: 0,
                    iMultiEditTransitDetails: 0
                });
                break;
            }
            // 高光标记
            case 4: {
                let arrOrderID = [];
                for (let i = 0; i < this.arrSelectOrders.length; i++) {
                    arrOrderID.push(this.arrSelectOrders[i].oid);
                }
                for (let j = 0; j < this.arrTableData.length; j++) {
                    for (let i = 0; i < this.arrSelectOrders.length; i++) {
                        if (this.arrTableData[j].oid === this.arrSelectOrders[i].oid) {
                            this.arrTableData[j].color = '#table_row_highlight';
                        }
                    }
                }
                axios.post('/HighlightAutoOrder', {
                    arrOrderID: arrOrderID,
                    highlight: '#table_row_highlight',
                    store_id: this.props.objStoreInfo.id
                }).then(({ data }) => {
                    console.log("hightlighted!", data);
                    this.setState({
                        ...this.state,
                        selectedRowKeys: [],
                        bLoading: false
                    });
                    // this.RefreshOrderTable(data);
                });
                break;
            }
            // 取消高光标记
            case 5: {
                let arrOrderID = [];
                for (let i = 0; i < this.arrSelectOrders.length; i++) {
                    arrOrderID.push(this.arrSelectOrders[i].oid);
                }
                for (let j = 0; j < this.arrTableData.length; j++) {
                    for (let i = 0; i < this.arrSelectOrders.length; i++) {
                        if (this.arrTableData[j].oid === this.arrSelectOrders[i].oid) {
                            this.arrTableData[j].color = '#table_row_white';
                        }
                    }
                }
                axios.post('/HighlightAutoOrder', {
                    arrOrderID: arrOrderID,
                    highlight: '#table_row_white',
                    store_id: this.props.objStoreInfo.id
                }).then(({ data }) => {
                    console.log("un-hightlighted!", data);
                    this.setState({
                        ...this.state,
                        selectedRowKeys: [],
                        bLoading: false
                    });
                    // this.RefreshOrderTable(data);
                });
                break;
            }
            default: break;
        }
    }
    // 获取弹窗中的更新数据
    GetNewInputData(iStep) {

        let objInputInfo = {};

        objInputInfo.type = this.props.iType;
        let strOID = this.strOrderTitle;
        if (this.props.objStoreInfo.id === 10000)
            strOID = this.strOrderTitle + "O";
        // 在派送列表新建时才会出现LKT开头的运单
        if (this.props.iType >= 10 && this.state.iDlgType === 0)
            strOID = this.strOrderTitle + "T";
        objInputInfo.oid = strOID;
        objInputInfo.client = this.refs.client.state.value;
        objInputInfo.client_id = this.iCurrentClient;
        objInputInfo.user_name = this.refs.user.state.value;
        objInputInfo.user_id = this.iChargeUserID;
        objInputInfo.team = this.iChargeUserTeam;
        if (objInputInfo.user_id < 0) {
            if (this.state.iDlgType === 0) {
                objInputInfo.user_name = this.props.strUserName;
                objInputInfo.user_id = this.props.iUserID;
                objInputInfo.team = this.props.iUserTeam;
            } else {
                objInputInfo.user_name = this.arrOrderData[this.state.selectedRowKeys[0]].user_name;
                objInputInfo.user_id = this.arrOrderData[this.state.selectedRowKeys[0]].user_id;
                objInputInfo.team = this.arrOrderData[this.state.selectedRowKeys[0]].team;
            }
        }
        if (this.state.iDlgType === 0 && this.props.iUserLevel < 9) {
            objInputInfo.user_id = this.props.iUserID;
            objInputInfo.user_name = this.props.strUserName;
        }
        objInputInfo.highlight = "#table_row_white";
        objInputInfo.etd = this.state.etd;
        objInputInfo.eta = this.state.eta;
        objInputInfo.ship_fee_confirm = this.state.iShipFeeConfirm;
        objInputInfo.port_fee_confirm = this.state.iPortFeeConfirm;
        objInputInfo.storage_fee_confirm = this.state.iStorageFeeConfirm;
        objInputInfo.clear_fee_confirm = this.state.iClearFeeConfirm;
        objInputInfo.transit_fee_confirm = this.state.iTransitFeeConfirm;
        objInputInfo.deliver_fee_confirm = this.state.iDeliverFeeConfirm;
        objInputInfo.pickup_fee_confirm = this.state.iPickupFeeConfirm;

        // 派送列表专用
        if (this.props.iType >= 10) {
            objInputInfo.status = 5;
            objInputInfo.currency = this.state.iCurrency;

            objInputInfo.client_release = this.state.iClientRelease;
            objInputInfo.lk_release = this.state.iLKRelease;
            objInputInfo.pod = this.state.iPOD;
            objInputInfo.complete = 0;
            if (objInputInfo.pod === 1)
                objInputInfo.complete = 1;

            objInputInfo.reference = this.refs.reference.state.value;

            objInputInfo.receiver_name = this.refs.receiver_name.state.value;
            objInputInfo.receiver_phone = this.refs.receiver_phone.state.value;
            objInputInfo.receiver_email = this.refs.receiver_email.state.value;
            objInputInfo.receiver_address = this.refs.receiver_address.state.value;

            objInputInfo.deliver_name = this.refs.deliver_name.state.value;
            objInputInfo.deliver_phone = this.refs.deliver_phone.state.value;
            objInputInfo.deliver_email = this.refs.deliver_email.state.value;
            objInputInfo.deliver_address = this.refs.deliver_address.state.value;

            objInputInfo.oversize = this.refs.oversize.state.value;
            // objInputInfo.deliver_comments = this.refs.deliver_comments.state.value;
            objInputInfo.carteam = this.refs.carteam.state.value;
            objInputInfo.carteam_id = this.iCurrentCarTeam;

            objInputInfo.deliver_fee = this.refs.deliver_fee.state.value;
            objInputInfo.storage_export_fee = this.refs.storage_export_fee.state.value;
            objInputInfo.on_plate_fee = this.refs.on_plate_fee.state.value;
            objInputInfo.waiting_fee = this.refs.waiting_fee.state.value;
            objInputInfo.deliver_other_fee = this.refs.deliver_other_fee.state.value;
            objInputInfo.deliver_cost = this.refs.deliver_cost.state.value;
            objInputInfo.storage_export_cost = this.refs.storage_export_cost.state.value;
            objInputInfo.on_plate_cost = this.refs.on_plate_cost.state.value;
            objInputInfo.waiting_cost = this.refs.waiting_cost.state.value;
            objInputInfo.deliver_other_cost = this.refs.deliver_other_cost.state.value;

            objInputInfo.deliver_other_fee_comments = this.refs.deliver_other_fee_comments.state.value;
        }
        // 进口预报
        else {
            objInputInfo.storage_custom = 0;//this.state.iStorageCustom;

            if (objInputInfo.eta.indexOf('T'))
                objInputInfo.eta = objInputInfo.eta.split('T')[0];

            if (this.state.iDlgType === 1)
                objInputInfo.status = this.arrOrderData[this.state.selectedRowKeys[0]].status;
            // Tab 0 发船
            if (this.state.iDlgTab === 0) {

                objInputInfo.ship_release = this.objCurrentOrder.ship_release;
                if (iStep === 1)
                    objInputInfo.ship_release = 1;
                objInputInfo.agent = this.refs.agent.state.value;
                objInputInfo.agent_id = this.iCurrentAgent;

                objInputInfo.currency = this.state.iCurrency;
                objInputInfo.oid_before = this.refs.oid_before.state.value;
                objInputInfo.closet = this.refs.closet.state.value;
                objInputInfo.express = this.refs.express.state.value;
                objInputInfo.num = this.refs.num.state.value;
                objInputInfo.weight = this.refs.weight.state.value;
                objInputInfo.cbm = this.refs.cbm.state.value;
                objInputInfo.destination = this.refs.destination.state.value;
                objInputInfo.port = this.refs.port.state.value;
                this.objCurrentOrder.oid_before = objInputInfo.oid_before;
                this.objCurrentOrder.destination = objInputInfo.destination;
                this.objCurrentOrder.port = objInputInfo.port;
                this.objCurrentOrder.eta = objInputInfo.eta;
                this.objCurrentOrder.etd = objInputInfo.etd;

                if (this.state.iDlgType === 1) {
                    this.GetAllFee(objInputInfo);
                }
                // 发船必填项
                if (objInputInfo.closet !== undefined && objInputInfo.closet !== null && objInputInfo.closet !== "" &&
                    objInputInfo.ship_release === 1 && objInputInfo.status < 1) {
                    objInputInfo.status = 1;
                }
                if (objInputInfo.ship_release === 0)
                    objInputInfo.status = 0;
            }
            // Tab 1 到港
            if (this.state.iDlgTab === 1) {

                objInputInfo.storage = this.refs.storage.state.value;
                objInputInfo.storage_id = this.iCurrentStorage;

                objInputInfo.order_release = this.objCurrentOrder.order_release;//this.state.arrPortRelease[0];
                objInputInfo.custom_release = this.objCurrentOrder.custom_release;//this.state.arrPortRelease[1];
                objInputInfo.goc_release = this.objCurrentOrder.goc_release;//this.state.arrPortRelease[2];

                if (this.iCurrentTruck >= 0 && this.iCurrentTruck < global.arrTruckData.length)
                    objInputInfo.truck = global.arrTruckData[this.iCurrentTruck].name;

                if (this.state.iDlgType === 1) {
                    this.GetAllFee(objInputInfo);
                }
                if (iStep === 1) {
                    objInputInfo.order_release = 1;
                    objInputInfo.custom_release = 1;
                    objInputInfo.goc_release = 1;
                    objInputInfo.status = 2;
                }
                if (iStep === 0) {
                    objInputInfo.status = 1;
                }
                if (iStep === -1) {
                    objInputInfo.ship_release = 0;
                    objInputInfo.status = 0;
                }

            }
            // Tab 2 到仓
            if (this.state.iDlgTab === 2) {
                objInputInfo.open_release = this.objCurrentOrder.open_release;//this.state.arrStorageRelease[0];
                objInputInfo.direct_client = this.state.iDirectClient;
                objInputInfo.free_storage_date = this.refs.mb_free_storage_date.state.value;
                if (this.state.iDlgType === 1) {
                    this.GetAllFee(objInputInfo);
                }
                if (iStep === 1) {

                    objInputInfo.open_release = 1;
                    let bExpress = false;
                    let bDeliver = false;
                    let bPickup = false;
                    for (let i = 0; i < this.arrHouseBill.length; i++) {
                        if (this.arrHouseBill[i].path === 0)
                            bExpress = true;
                        if (this.arrHouseBill[i].path === 1)
                            bDeliver = true;
                        if (this.arrHouseBill[i].path === 2)
                            bPickup = true;
                    }
                    if (bExpress)
                        objInputInfo.status = 4;  // 换单
                    if (bDeliver)
                        objInputInfo.status = 5;  // 海派
                    if (bPickup)
                        objInputInfo.status = 6;  // 自提
                    console.log(bExpress, bDeliver, bPickup);
                }
                if (iStep === 0) {
                    objInputInfo.status = 2;
                }
                if (iStep === -1) {
                    objInputInfo.order_release = 0;
                    objInputInfo.custom_release = 0;
                    objInputInfo.goc_release = 0;
                    objInputInfo.status = 1;
                }
            }
            // Tab 3 清关
            if (this.state.iDlgTab === 3) {
                objInputInfo.clear_release = this.state.iClearRelease;
                // 清关->仓库 / 清关->直派
                if (this.state.iStorageCustom >= 1) {
                    objInputInfo.custom_fee = this.refs.custom_fee.state.value;
                    objInputInfo.custom_cost = this.refs.custom_cost.state.value;
                    objInputInfo.tax_fee = this.refs.tax_fee.state.value;
                    objInputInfo.tax_cost = this.refs.tax_cost.state.value;
                    objInputInfo.gst_fee = this.refs.gst_fee.state.value;
                    objInputInfo.gst_cost = this.refs.gst_cost.state.value;
                    objInputInfo.overitem_active = this.arrClearFee[0].active;
                    if (this.arrClearFee[0].active === 1) {
                        objInputInfo.overitem_fee = this.refs.overitem_fee.state.value;
                        objInputInfo.overitem_cost = this.refs.overitem_cost.state.value;
                    }
                    objInputInfo.cfia_active = this.arrClearFee[1].active;
                    if (this.arrClearFee[1].active === 1) {
                        objInputInfo.cfia_fee = this.refs.cfia_fee.state.value;
                        objInputInfo.cfia_cost = this.refs.cfia_cost.state.value;
                    }
                    objInputInfo.check_active = this.arrClearFee[2].active;
                    if (this.arrClearFee[2].active === 1) {
                        objInputInfo.check_fee = this.refs.check_fee.state.value;
                        objInputInfo.check_cost = this.refs.check_cost.state.value;
                    }
                    objInputInfo.custom_other_active = this.arrClearFee[3].active;
                    if (this.arrClearFee[3].active === 1) {
                        objInputInfo.custom_other_fee = this.refs.custom_other_fee.state.value;
                        objInputInfo.custom_other_cost = this.refs.custom_other_cost.state.value;
                        objInputInfo.custom_other_fee_comments = this.refs.custom_other_fee_comments.state.value;
                    }
                    // 清关必填项
                    if (this.state.iClearRelease === 1) {
                        if (this.state.iStorageCustom === 1)
                            objInputInfo.status = 2;  // 仓库
                        // 直送
                        else {
                            console.log("清关直送开通！");
                            objInputInfo.open_release = 1;  // 直派会跳过仓库阶段，所以需要直接开启拆柜标志
                            objInputInfo.status = 5;  // 海派
                        }
                    } else {
                        objInputInfo.open_release = 0;  // 直派会跳过仓库阶段，所以需要直接开启拆柜标志
                        objInputInfo.status = 3;  // 清关
                    }
                }
                // 仓库-> 清关
                else {
                    // 清关必填项
                    let bClearRelease = false;
                    for (let i = 0; i < this.arrHouseBill.length; i++) {
                        if (this.arrHouseBill[i].clear_release === 1) {
                            bClearRelease = true;
                            break;
                        }
                    }
                    if (bClearRelease) {
                        let bExpress = false;
                        let bDeliver = false;
                        let bPickup = false;
                        for (let i = 0; i < this.arrHouseBill.length; i++) {
                            if (this.arrHouseBill[i].path === 0 && this.arrHouseBill[i].clear_release === 1)
                                bExpress = true;
                            if (this.arrHouseBill[i].path === 1 && this.arrHouseBill[i].clear_release === 1)
                                bDeliver = true;
                            if (this.arrHouseBill[i].path === 2 && this.arrHouseBill[i].clear_release === 1)
                                bPickup = true;
                        }
                        if (bExpress)
                            objInputInfo.status = 4;  // 换单
                        if (bDeliver)
                            objInputInfo.status = 5;  // 海派
                        if (bPickup)
                            objInputInfo.status = 6;  // 自提
                    } else {
                        objInputInfo.status = 3;  // 清关
                    }

                }
            }
            // Tab 4、5 换单&自提
            if (this.state.iDlgTab >= 4) {
                if (iStep === -1) {
                    objInputInfo.open_release = 0;
                    objInputInfo.status = 2;
                }
                if (iStep === -2) {
                    objInputInfo.complete = 0;
                }
                if (iStep === 2) {
                    objInputInfo.complete = 1;
                }
                // // 判断运单当前状态
                // objInputInfo.transit_hb = 0;
                // objInputInfo.deliver_hb = 0;
                // objInputInfo.pickup_hb = 0;
                // for (let i = 0; i < this.arrHouseBill.length; i++) {
                //     if (this.arrHouseBill[i].path === 0)
                //         objInputInfo.transit_hb = 1;
                //     if (this.arrHouseBill[i].path === 1)
                //         objInputInfo.deliver_hb = 1;
                //     if (this.arrHouseBill[i].path === 2)
                //         objInputInfo.pickup_hb = 1;
                // }
            }
            // HB处理，主要针对Tab>=4的情况
            if (this.arrHouseBill.length > 0) {
                objInputInfo.transit_hb = 0;
                objInputInfo.deliver_hb = 0;
                objInputInfo.pickup_hb = 0;
                for (let i = 0; i < this.arrHouseBill.length; i++) {
                    if (this.arrHouseBill[i].path === 0)
                        objInputInfo.transit_hb = 1;
                    if (this.arrHouseBill[i].path === 1)
                        objInputInfo.deliver_hb = 1;
                    if (this.arrHouseBill[i].path === 2)
                        objInputInfo.pickup_hb = 1;
                }
            }
            if (this.state.iStorageCustom === 2)
                if (objInputInfo.status >= 4)
                    objInputInfo.deliver_hb = 1;
            // 海派专用
            if (this.props.iType === 5) {
                if (this.state.iDlgType === 1) {
                    if (this.arrOrderData[this.state.selectedRowKeys[0]].type !== 5)
                        objInputInfo.storage = this.refs.storage.state.value;
                    else {
                        objInputInfo.deliver_name = this.refs.deliver_name.state.value;
                        objInputInfo.deliver_phone = this.refs.deliver_phone.state.value;
                        objInputInfo.deliver_email = this.refs.deliver_email.state.value;
                        objInputInfo.deliver_address = this.refs.deliver_address.state.value;
                        objInputInfo.currency = this.state.iCurrency;
                        objInputInfo.transit_hb = 1;
                        objInputInfo.deliver_hb = 1;
                    }
                }

            }
            // 非海派时，当运单状态>=4，需要重新判断和更新运单状态
            else {
                if (objInputInfo.status >= 4) {
                    if (objInputInfo.transit_hb === 1)
                        objInputInfo.status = 4;
                    if (objInputInfo.deliver_hb === 1)
                        objInputInfo.status = 5;
                    if (objInputInfo.pickup_hb === 1)
                        objInputInfo.status = 6;
                    if (objInputInfo.storage_custom === 2)
                        objInputInfo.status = 5;
                }
            }
            // 判断是否结单
            // objInputInfo.complete = 0;
            // let bComplete = true;
            // if (this.arrHouseBill.length > 0)
            //     for (let i = 0; i < this.arrHouseBill.length; i++) {
            //         if (this.arrHouseBill[i].pod === 0) {
            //             bComplete = false; break;
            //         }
            //     }
            // else
            //     bComplete = false;
            // if (bComplete) {
            //     objInputInfo.complete = 1;
            // } else
            //     objInputInfo.complete = 0;
        }

        return objInputInfo;
    }
    // 确认按钮响应
    ConfirmButtonClick = (iStep) => {
        console.log("ConfirmButtonClick : ", iStep, this.props.iType);

        if (this.state.iDlgTab === 7)
            return;
        if (this.state.bMultiEditDlg) {
            let arrOrderID = [];
            let arrNewOrderID = [];
            for (let i = 0; i < this.arrSelectOrders.length; i++) {
                arrOrderID.push(this.arrSelectOrders[i].oid);
                let strDate = this.arrSelectOrders[i].oid.split(this.strOrderTitle)[1];
                strDate = strDate.substr(1);
                if (this.props.objStoreInfo.id === 10000) {
                    let strNewOid = this.strOrderTitle + this.arrOrderTypeShort[this.state.dlgInfo.iOrderType] + strDate;
                    arrNewOrderID.push(strNewOid);
                } else {
                    arrNewOrderID.push(this.strOrderTitle + strDate);
                }
            }
            let iMarkable = -1;
            if (this.state.arrMultiEditOption[2])
                iMarkable = 0;
            let arrUpdateOption = [];
            for (let i = 0; i < this.state.arrMultiEditOption.length; i++) {
                if (this.state.arrMultiEditOption[i])
                    arrUpdateOption.push(1);
                else
                    arrUpdateOption.push(0);
            }
            message.loading({ content: '正在更新运单信息……', key: g_strMessageKey });
            axios.post('/MultiEditAutoOrder', {
                arrOrderID: arrOrderID,
                arrNewOrderID: arrNewOrderID,
                type: this.state.dlgInfo.iOrderType,
                transit_status: this.state.iMultiEditTransitStatus,
                transit_details: this.state.iMultiEditTransitDetails,
                eta: this.state.eta,
                markable: iMarkable,
                store_id: this.props.objStoreInfo.id,
                arrUpdateOption: arrUpdateOption
            }).then(({ data }) => {
                message.success({ content: '更新完成！', key: g_strMessageKey, duration: 2 });
                // this.RefreshOrderTable(data);
                this.GetTargetOrders(-1);
            });
            return;
        }
        // 客户端
        if (this.props.iUserLevel < 5) {
            if (this.props.iType === 7 || this.props.iType === 13) {
                message.error("已结单，无法保存修改，请联系管理员！");
                return;
            }
            this.setState({
                ...this.state,
                bClientConfirmDlg: true
            });

        }
        // 员工
        else {
            switch (this.state.iDlgType) {
                case 0: {
                    let objNewOrder = this.GetNewInputData(iStep);
                    if (objNewOrder === -1)
                        return;
                    objNewOrder.store_id = this.props.objStoreInfo.id;
                    objNewOrder.status = 0;
                    objNewOrder.complete = 0;
                    // 海派专用
                    if (this.props.iType === 5)
                        objNewOrder.status = 5;
                    message.loading({ content: '正在生成新的运单……', key: g_strMessageKey });
                    axios.post('/GenerateLogisticOrder', { objNewOrder: objNewOrder })
                        .then(({ data }) => {
                            console.log("GenerateLogisticOrder: ", data);
                            if (data.status === 1) {
                                message.success({ content: '创建完成！', key: g_strMessageKey, duration: 2 });
                                this.GetTargetOrders(-1);
                            } else {
                                message.error({ content: '创建失败！', key: g_strMessageKey, duration: 2 });
                            }
                        });
                    break;
                }
                case 1: {
                    if (this.props.iUserLevel < 9 && (this.props.iType === 7 || this.props.iType === 13)) {
                        message.error("已结单，无法保存修改，请联系管理员！");
                        return;
                    }
                    let objEditOrder = this.GetNewInputData(iStep);
                    if (objEditOrder === -1)
                        return;
                    this.bSaving = true;
                    objEditOrder.store_id = this.props.objStoreInfo.id;
                    objEditOrder.id = this.arrOrderData[this.state.selectedRowKeys[0]].id;
                    objEditOrder.oid = this.arrOrderData[this.state.selectedRowKeys[0]].oid;
                    objEditOrder.type = this.arrOrderData[this.state.selectedRowKeys[0]].type;

                    // 费用变化记录
                    let objActionRecord = null;
                    if (this.bFeeChange) {
                        let strRecord = "";
                        // 派送列表 
                        if (this.props.iType >= 10) {
                            strRecord = "派送列表\n收费\n派送：" + objEditOrder.deliver_fee +
                                ", 出仓：" + objEditOrder.storage_export_fee +
                                ", 托盘：" + objEditOrder.on_plate_fee +
                                ", 等候：" + objEditOrder.waiting_fee +
                                ", 其他：" + objEditOrder.deliver_other_fee;
                            strRecord += "\n成本\n派送：" + objEditOrder.deliver_cost +
                                ", 出仓：" + objEditOrder.storage_export_cost +
                                ", 托盘：" + objEditOrder.on_plate_cost +
                                ", 等候：" + objEditOrder.waiting_cost +
                                ", 其他：" + objEditOrder.deliver_other_cost;
                        } else {
                            // 发船
                            if (this.state.iDlgTab === 0) {
                                strRecord = "发船\n收费\n代理：" + objEditOrder.agent_fee +
                                    ", 船东：" + objEditOrder.ship_fee +
                                    ", 码头：" + objEditOrder.port_fee +
                                    ", 其他：" + objEditOrder.ship_other_fee;
                                strRecord += "\n成本\n代理：" + objEditOrder.agent_cost +
                                    ", 船东：" + objEditOrder.ship_cost +
                                    ", 码头：" + objEditOrder.port_cost +
                                    ", 其他：" + objEditOrder.ship_other_cost;
                            }
                            // 到港
                            if (this.state.iDlgTab === 1) {
                                strRecord = "到港\n收费\n拖柜：" + objEditOrder.truck_fee +
                                    ", 预提柜：" + objEditOrder.prepick_fee +
                                    ", 堆场：" + objEditOrder.stack_fee +
                                    ", 车架：" + objEditOrder.car_frame_fee +
                                    ", 危险品：" + objEditOrder.danger_fee +
                                    ", 空跑：" + objEditOrder.null_fee +
                                    ", 其他：" + objEditOrder.port_other_fee;
                                strRecord += "\n成本\n拖柜：" + objEditOrder.truck_cost +
                                    ", 预提柜：" + objEditOrder.prepick_cost +
                                    ", 堆场：" + objEditOrder.stack_cost +
                                    ", 车架：" + objEditOrder.car_frame_cost +
                                    ", 危险品：" + objEditOrder.stack_cost +
                                    ", 空跑：" + objEditOrder.null_cost +
                                    ", 其他：" + objEditOrder.port_other_cost;
                            }
                            // 仓库
                            if (this.state.iDlgTab === 2) {
                                strRecord = "仓库\n收费\n拆柜：" + objEditOrder.open_closet_fee +
                                    ", 分货：" + objEditOrder.divide_item_fee;
                                strRecord += "\n成本\n拆柜：" + objEditOrder.open_closet_cost +
                                    ", 分货：" + objEditOrder.divide_item_cost;
                            }
                            // 清关
                            if (this.state.iDlgTab === 3) {
                                if (objEditOrder.storage_custom >= 1) {
                                    strRecord = "清关\n收费\n报关：" + objEditOrder.custom_fee + ", 关税：" + objEditOrder.tax_fee + ", GST：" + objEditOrder.gst_fee;
                                    if (objEditOrder.overitem_fee !== null && objEditOrder.overitem_fee !== undefined && objEditOrder.overitem_fee !== "")
                                        strRecord += ", 超项：" + objEditOrder.overitem_fee;
                                    if (objEditOrder.cfia_fee !== null && objEditOrder.cfia_fee !== undefined && objEditOrder.cfia_fee !== "")
                                        strRecord += ", CFIA：" + objEditOrder.cfia_fee;
                                    if (objEditOrder.check_fee !== null && objEditOrder.check_fee !== undefined && objEditOrder.check_fee !== "")
                                        strRecord += ", 查验：" + objEditOrder.check_fee;
                                    if (objEditOrder.custom_other_fee !== null && objEditOrder.custom_other_fee !== undefined && objEditOrder.custom_other_fee !== "")
                                        strRecord += ", 其他：" + objEditOrder.custom_other_fee;
                                    strRecord += "\n成本\n拖柜：" + objEditOrder.custom_cost + ", 关税：" + objEditOrder.tax_cost + ", GST：" + objEditOrder.gst_cost;
                                    if (objEditOrder.overitem_cost !== null && objEditOrder.overitem_cost !== undefined && objEditOrder.overitem_cost !== "")
                                        strRecord += ", 超项：" + objEditOrder.overitem_cost;
                                    if (objEditOrder.cfia_fee !== null && objEditOrder.cfia_fee !== undefined && objEditOrder.cfia_fee !== "")
                                        strRecord += ", CFIA：" + objEditOrder.cfia_fee;
                                    if (objEditOrder.check_cost !== null && objEditOrder.check_cost !== undefined && objEditOrder.check_cost !== "")
                                        strRecord += ", 查验：" + objEditOrder.check_cost;
                                    if (objEditOrder.custom_other_cost !== null && objEditOrder.custom_other_cost !== undefined && objEditOrder.custom_other_cost !== "")
                                        strRecord += ", 其他：" + objEditOrder.custom_other_cost;
                                }
                            }
                        }

                        objActionRecord = {
                            store_id: this.props.objStoreInfo.id,
                            oid: this.objCurrentOrder.oid,
                            mark: "",
                            user_id: this.props.iUserID,
                            user_name: this.props.strUserName,
                            charger_id: this.objCurrentOrder.user_id,
                            charger_name: this.objCurrentOrder.user_name,
                            team: this.objCurrentOrder.team,
                            tab: this.state.iDlgTab,
                            record: strRecord,
                            update_time: new Date()
                        }
                    }
                    // 派送列表结单
                    if (this.props.iType >= 10 && iStep === 10) {
                        objEditOrder.complete = 1;
                    }
                    if (this.props.iType >= 10 && iStep === -10) {
                        objEditOrder.complete = 0;
                    }

                    message.loading({ content: '正在更新运单信息……', key: g_strMessageKey });
                    axios.post('/EditLogisticOrder', { objEditOrder: objEditOrder, objActionRecord: objActionRecord })
                        .then(({ data }) => {
                            this.bSaving = false;
                            if (data === -1) {
                                message.error({ content: '更新失败，请刷新后重新尝试！', key: g_strMessageKey, duration: 2 });
                            } else {
                                message.success({ content: '运单信息已保存！', key: g_strMessageKey, duration: 2 });
                                this.arrOrderData[this.state.selectedRowKeys[0]] = data[0];
                                // 收费默认值为0
                                this.SetDefaultFee(data[0]);
                                // 费用变化记录
                                if (this.bFeeChange) {
                                    this.bFeeChange = false;
                                    axios.post('/LogisticsActionRecord', { objActionRecord: objActionRecord })
                                        .then(({ data }) => {
                                            // console.log("LogisticsActionRecord: ", data);
                                        });
                                }
                            }
                            this.setState({
                                ...this.state,
                                bLoading: false
                            })
                        });
                    break;
                }
                case 2: {
                    this.setState({
                        ...this.state,
                        bLoading: false,
                        bDlgShow: false,
                        iDlgType: -1
                    });
                    // message.error("暂无权限删除运单！");
                    // message.info("可以修改运单状态为'作废'或重新利用它！");
                    message.loading({ content: '删除运单中……', key: g_strMessageKey });
                    axios.post('/DeleteLogisticOrder', {
                        arrDeleteOrders: [this.strDeleteOrderID], store_id: this.props.objStoreInfo.id
                    }).then(({ data }) => {
                        message.success({ content: '删除完成！', key: g_strMessageKey, duration: 2 });

                        this.GetTargetOrders(-1);
                    });
                    break;
                }
                default: break;
            }
        }
    }
    // 用户确认后的运单信息保存
    ClientConfirm = (iType) => {
        if (iType >= 1) {
            let iConfirmCode = parseInt(this.refs.confirm_code.state.value, 10);
            if (this.iConfirmCode === iConfirmCode) {
                let strComments = this.arrOrderData[this.state.selectedRowKeys[0]].comemnts;
                if (this.refs.comments !== undefined)
                    strComments = this.refs.comments.state.value;
                let objEditOrder = {
                    ship_fee_confirm: this.arrOrderData[this.state.selectedRowKeys[0]].ship_fee_confirm,
                    port_fee_confirm: this.arrOrderData[this.state.selectedRowKeys[0]].port_fee_confirm,
                    comments: strComments,
                    // client_notes: this.refs.client_notes.state.value
                }
                switch (this.state.iDlgTab) {
                    case 0: {
                        objEditOrder.ship_fee_confirm = this.state.iShipFeeConfirm;
                        break;
                    }
                    case 1: {
                        objEditOrder.port_fee_confirm = this.state.iPortFeeConfirm;
                        break;
                    }
                    case 2: {
                        objEditOrder.storage_fee_confirm = this.state.iStorageFeeConfirm;
                        break;
                    }
                    case 3: {
                        objEditOrder.clear_fee_confirm = this.state.iClearFeeConfirm;
                        break;
                    }
                    case 4: {
                        objEditOrder.transit_fee_confirm = this.state.iTransitFeeConfirm;
                        break;
                    }
                    case 5: {
                        objEditOrder.deliver_fee_confirm = this.state.iDeliverFeeConfirm;
                        break;
                    }
                    case 6: {
                        objEditOrder.pickup_fee_confirm = this.state.iPickupFeeConfirm;
                        break;
                    }
                    default: break;
                }
                objEditOrder.id = this.arrOrderData[this.state.selectedRowKeys[0]].id;
                axios.post('/EditLogisticOrder', { objEditOrder: objEditOrder })
                    .then(({ data }) => {
                        if (data === -1) {
                            message.error({ content: '更新失败，请刷新后重新尝试！', key: g_strMessageKey, duration: 2 });
                        } else {
                            message.success({ content: '已保存！', key: g_strMessageKey, duration: 2 });
                            this.arrOrderData[this.state.selectedRowKeys[0]] = data[0];
                            // 收费默认值为0
                            this.SetDefaultFee(data[0]);
                        }
                        this.setState({
                            ...this.state,
                            bLoading: false,
                            bClientConfirmDlg: false
                        })
                    });
            } else {
                message.error("验证码错误，请重新输入！");
            }

        } else {
            this.setState({
                ...this.state,
                bLoading: false,
                bClientConfirmDlg: false
            })
        }

    }
    // 取消按钮响应
    CancelButtonClick = () => {
        this.bFeeChange = false;
        if (this.state.iDlgType === 1) {
            this.GetTargetOrders(-1);
        } else {
            this.setState({
                ...this.state,
                iDlgType: 0,
                bDlgShow: false,
                bMultiEditDlg: false,
                selectedRowKeys: [],
                btnControlDisable: [false, true, true]
            });
        }
    }
    // 打开关联的海关文件
    OpenCustomFile = (iType, strOrderNo) => {
        console.log("OpenCustomFile : ", iType, strOrderNo, this.props.iType);
        if (iType === 0) {

        }
        if (iType === 1) {

        }
        this.props.AutoOrderToCustomFile(iType, strOrderNo, this.props.iType);
    }
    // 打开关联的发票文件
    OpenInvoiceFile = (iType, strOrderNo) => {
        if (iType === 0) {
        }
        if (iType === 1) {

        }
        this.props.AutoOrderToInvoiceFile(iType, strOrderNo);
    }
    // 设置默认收费
    SetDefaultFee = (data) => {
        // House Bill
        if (this.iCurrentHouseBill >= 0) {
            if (this.arrHouseBill[this.iCurrentHouseBill].custom_fee === null || this.arrHouseBill[this.iCurrentHouseBill].custom_fee === ""
                || this.arrHouseBill[this.iCurrentHouseBill].custom_fee === undefined)
                this.arrHouseBill[this.iCurrentHouseBill].custom_fee = 0;

            if (this.arrHouseBill[this.iCurrentHouseBill].custom_cost === null || this.arrHouseBill[this.iCurrentHouseBill].custom_cost === ""
                || this.arrHouseBill[this.iCurrentHouseBill].custom_cost === undefined)
                this.arrHouseBill[this.iCurrentHouseBill].custom_cost = 0;

            if (this.arrHouseBill[this.iCurrentHouseBill].tax_fee === null || this.arrHouseBill[this.iCurrentHouseBill].tax_fee === ""
                || this.arrHouseBill[this.iCurrentHouseBill].tax_fee === undefined)
                this.arrHouseBill[this.iCurrentHouseBill].tax_fee = 0;

            if (this.arrHouseBill[this.iCurrentHouseBill].tax_cost === null || this.arrHouseBill[this.iCurrentHouseBill].tax_cost === ""
                || this.arrHouseBill[this.iCurrentHouseBill].tax_cost === undefined)
                this.arrHouseBill[this.iCurrentHouseBill].tax_cost = 0;

            if (this.arrHouseBill[this.iCurrentHouseBill].gst_fee === null || this.arrHouseBill[this.iCurrentHouseBill].gst_fee === ""
                || this.arrHouseBill[this.iCurrentHouseBill].gst_fee === undefined)
                this.arrHouseBill[this.iCurrentHouseBill].gst_fee = 0;

            if (this.arrHouseBill[this.iCurrentHouseBill].gst_cost === null || this.arrHouseBill[this.iCurrentHouseBill].gst_cost === ""
                || this.arrHouseBill[this.iCurrentHouseBill].gst_cost === undefined)
                this.arrHouseBill[this.iCurrentHouseBill].gst_cost = 0;

            // 仓库
            if (this.arrHouseBill[this.iCurrentHouseBill].on_plate_fee === null || this.arrHouseBill[this.iCurrentHouseBill].on_plate_fee === ""
                || this.arrHouseBill[this.iCurrentHouseBill].on_plate_fee === undefined)
                this.arrHouseBill[this.iCurrentHouseBill].on_plate_fee = 0;

            if (this.arrHouseBill[this.iCurrentHouseBill].on_plate_cost === null || this.arrHouseBill[this.iCurrentHouseBill].on_plate_cost === ""
                || this.arrHouseBill[this.iCurrentHouseBill].on_plate_cost === undefined)
                this.arrHouseBill[this.iCurrentHouseBill].on_plate_cost = 0;

            if (this.arrHouseBill[this.iCurrentHouseBill].tag_fee === null || this.arrHouseBill[this.iCurrentHouseBill].tag_fee === ""
                || this.arrHouseBill[this.iCurrentHouseBill].tag_fee === undefined)
                this.arrHouseBill[this.iCurrentHouseBill].tag_fee = 0;

            if (this.arrHouseBill[this.iCurrentHouseBill].tag_cost === null || this.arrHouseBill[this.iCurrentHouseBill].tag_cost === ""
                || this.arrHouseBill[this.iCurrentHouseBill].tag_cost === undefined)
                this.arrHouseBill[this.iCurrentHouseBill].tag_cost = 0;

            if (this.arrHouseBill[this.iCurrentHouseBill].oversize_fee === null || this.arrHouseBill[this.iCurrentHouseBill].oversize_fee === ""
                || this.arrHouseBill[this.iCurrentHouseBill].oversize_fee === undefined)
                this.arrHouseBill[this.iCurrentHouseBill].oversize_fee = 0;

            if (this.arrHouseBill[this.iCurrentHouseBill].oversize_cost === null || this.arrHouseBill[this.iCurrentHouseBill].oversize_cost === ""
                || this.arrHouseBill[this.iCurrentHouseBill].oversize_cost === undefined)
                this.arrHouseBill[this.iCurrentHouseBill].oversize_cost = 0;

            if (this.arrHouseBill[this.iCurrentHouseBill].storage_fee === null || this.arrHouseBill[this.iCurrentHouseBill].storage_fee === ""
                || this.arrHouseBill[this.iCurrentHouseBill].storage_fee === undefined)
                this.arrHouseBill[this.iCurrentHouseBill].storage_fee = 0;

            if (this.arrHouseBill[this.iCurrentHouseBill].storage_cost === null || this.arrHouseBill[this.iCurrentHouseBill].storage_cost === ""
                || this.arrHouseBill[this.iCurrentHouseBill].storage_cost === undefined)
                this.arrHouseBill[this.iCurrentHouseBill].storage_cost = 0;

            if (this.arrHouseBill[this.iCurrentHouseBill].storage_export_fee === null || this.arrHouseBill[this.iCurrentHouseBill].storage_export_fee === ""
                || this.arrHouseBill[this.iCurrentHouseBill].storage_export_fee === undefined)
                this.arrHouseBill[this.iCurrentHouseBill].storage_export_fee = 0;

            if (this.arrHouseBill[this.iCurrentHouseBill].storage_other_fee === null || this.arrHouseBill[this.iCurrentHouseBill].storage_other_fee === ""
                || this.arrHouseBill[this.iCurrentHouseBill].storage_other_fee === undefined)
                this.arrHouseBill[this.iCurrentHouseBill].storage_other_fee = 0;

            if (this.arrHouseBill[this.iCurrentHouseBill].storage_export_cost === null || this.arrHouseBill[this.iCurrentHouseBill].storage_export_cost === ""
                || this.arrHouseBill[this.iCurrentHouseBill].storage_export_cost === undefined)
                this.arrHouseBill[this.iCurrentHouseBill].storage_export_cost = 0;

            if (this.arrHouseBill[this.iCurrentHouseBill].storage_other_cost === null || this.arrHouseBill[this.iCurrentHouseBill].storage_other_cost === ""
                || this.arrHouseBill[this.iCurrentHouseBill].storage_other_cost === undefined)
                this.arrHouseBill[this.iCurrentHouseBill].storage_other_cost = 0;

            // 清关
            if (this.arrHouseBill[this.iCurrentHouseBill].overitem_fee === null || this.arrHouseBill[this.iCurrentHouseBill].overitem_fee === ""
                || this.arrHouseBill[this.iCurrentHouseBill].overitem_fee === undefined)
                this.arrHouseBill[this.iCurrentHouseBill].overitem_fee = 0;

            if (this.arrHouseBill[this.iCurrentHouseBill].overitem_cost === null || this.arrHouseBill[this.iCurrentHouseBill].overitem_cost === ""
                || this.arrHouseBill[this.iCurrentHouseBill].overitem_cost === undefined)
                this.arrHouseBill[this.iCurrentHouseBill].overitem_cost = 0;

            if (this.arrHouseBill[this.iCurrentHouseBill].cfia_fee === null || this.arrHouseBill[this.iCurrentHouseBill].cfia_fee === ""
                || this.arrHouseBill[this.iCurrentHouseBill].cfia_fee === undefined)
                this.arrHouseBill[this.iCurrentHouseBill].cfia_fee = 0;

            if (this.arrHouseBill[this.iCurrentHouseBill].cfia_cost === null || this.arrHouseBill[this.iCurrentHouseBill].cfia_cost === ""
                || this.arrHouseBill[this.iCurrentHouseBill].cfia_cost === undefined)
                this.arrHouseBill[this.iCurrentHouseBill].cfia_cost = 0;

            if (this.arrHouseBill[this.iCurrentHouseBill].check_fee === null || this.arrHouseBill[this.iCurrentHouseBill].check_fee === ""
                || this.arrHouseBill[this.iCurrentHouseBill].check_fee === undefined)
                this.arrHouseBill[this.iCurrentHouseBill].check_fee = 0;

            if (this.arrHouseBill[this.iCurrentHouseBill].check_cost === null || this.arrHouseBill[this.iCurrentHouseBill].check_cost === ""
                || this.arrHouseBill[this.iCurrentHouseBill].check_cost === undefined)
                this.arrHouseBill[this.iCurrentHouseBill].check_cost = 0;

            if (this.arrHouseBill[this.iCurrentHouseBill].hb_other_fee === null || this.arrHouseBill[this.iCurrentHouseBill].hb_other_fee === ""
                || this.arrHouseBill[this.iCurrentHouseBill].hb_other_fee === undefined)
                this.arrHouseBill[this.iCurrentHouseBill].hb_other_fee = 0;

            if (this.arrHouseBill[this.iCurrentHouseBill].hb_other_cost === null || this.arrHouseBill[this.iCurrentHouseBill].hb_other_cost === ""
                || this.arrHouseBill[this.iCurrentHouseBill].hb_other_cost === undefined)
                this.arrHouseBill[this.iCurrentHouseBill].hb_other_cost = 0;
            // 换单、自提
            if (this.arrHouseBill[this.iCurrentHouseBill].transit_fee === null || this.arrHouseBill[this.iCurrentHouseBill].transit_fee === ""
                || this.arrHouseBill[this.iCurrentHouseBill].transit_fee === undefined)
                this.arrHouseBill[this.iCurrentHouseBill].transit_fee = 0;

            if (this.arrHouseBill[this.iCurrentHouseBill].transit_cost === null || this.arrHouseBill[this.iCurrentHouseBill].transit_cost === ""
                || this.arrHouseBill[this.iCurrentHouseBill].transit_cost === undefined)
                this.arrHouseBill[this.iCurrentHouseBill].transit_cost = 0;

            if (this.arrHouseBill[this.iCurrentHouseBill].deliver_fee === null || this.arrHouseBill[this.iCurrentHouseBill].deliver_fee === ""
                || this.arrHouseBill[this.iCurrentHouseBill].deliver_fee === undefined)
                this.arrHouseBill[this.iCurrentHouseBill].deliver_fee = 0;

            if (this.arrHouseBill[this.iCurrentHouseBill].deliver_cost === null || this.arrHouseBill[this.iCurrentHouseBill].deliver_cost === ""
                || this.arrHouseBill[this.iCurrentHouseBill].deliver_cost === undefined)
                this.arrHouseBill[this.iCurrentHouseBill].deliver_cost = 0;

            if (this.arrHouseBill[this.iCurrentHouseBill].storage_export45_fee === null || this.arrHouseBill[this.iCurrentHouseBill].storage_export45_fee === ""
                || this.arrHouseBill[this.iCurrentHouseBill].storage_export45_fee === undefined)
                this.arrHouseBill[this.iCurrentHouseBill].storage_export45_fee = 0;

            if (this.arrHouseBill[this.iCurrentHouseBill].storage_export45_cost === null || this.arrHouseBill[this.iCurrentHouseBill].storage_export45_cost === ""
                || this.arrHouseBill[this.iCurrentHouseBill].storage_export45_cost === undefined)
                this.arrHouseBill[this.iCurrentHouseBill].storage_export45_cost = 0;

            if (this.arrHouseBill[this.iCurrentHouseBill].on_plate45_fee === null || this.arrHouseBill[this.iCurrentHouseBill].on_plate45_fee === ""
                || this.arrHouseBill[this.iCurrentHouseBill].on_plate45_fee === undefined)
                this.arrHouseBill[this.iCurrentHouseBill].on_plate45_fee = 0;

            if (this.arrHouseBill[this.iCurrentHouseBill].on_plate45_cost === null || this.arrHouseBill[this.iCurrentHouseBill].on_plate45_cost === ""
                || this.arrHouseBill[this.iCurrentHouseBill].on_plate45_cost === undefined)
                this.arrHouseBill[this.iCurrentHouseBill].on_plate45_cost = 0;

            if (this.arrHouseBill[this.iCurrentHouseBill].waiting_fee === null || this.arrHouseBill[this.iCurrentHouseBill].waiting_fee === ""
                || this.arrHouseBill[this.iCurrentHouseBill].waiting_fee === undefined)
                this.arrHouseBill[this.iCurrentHouseBill].waiting_fee = 0;

            if (this.arrHouseBill[this.iCurrentHouseBill].waiting_cost === null || this.arrHouseBill[this.iCurrentHouseBill].waiting_cost === ""
                || this.arrHouseBill[this.iCurrentHouseBill].waiting_cost === undefined)
                this.arrHouseBill[this.iCurrentHouseBill].waiting_cost = 0;

            if (this.arrHouseBill[this.iCurrentHouseBill].hb_other45_fee === null || this.arrHouseBill[this.iCurrentHouseBill].hb_other45_fee === ""
                || this.arrHouseBill[this.iCurrentHouseBill].hb_other45_fee === undefined)
                this.arrHouseBill[this.iCurrentHouseBill].hb_other45_fee = 0;

            if (this.arrHouseBill[this.iCurrentHouseBill].hb_other45_cost === null || this.arrHouseBill[this.iCurrentHouseBill].hb_other45_cost === ""
                || this.arrHouseBill[this.iCurrentHouseBill].hb_other45_cost === undefined)
                this.arrHouseBill[this.iCurrentHouseBill].hb_other45_cost = 0;
        }
        // Master Bill
        else {
            // Tab 0 发船
            if (data.agent_fee === null || data.agent_fee === "")
                data.agent_fee = 0;
            if (data.ship_fee === null || data.ship_fee === "")
                data.ship_fee = 0;
            if (data.port_fee === null || data.port_fee === "")
                data.port_fee = 0;
            if (data.ship_other_fee === null || data.ship_other_fee === "")
                data.ship_other_fee = 0;
            if (data.agent_cost === null || data.agent_cost === "")
                data.agent_cost = 0;
            if (data.ship_cost === null || data.ship_cost === "")
                data.ship_cost = 0;
            if (data.port_cost === null || data.port_cost === "")
                data.port_cost = 0;
            if (data.ship_other_cost === null || data.ship_other_cost === "")
                data.ship_other_cost = 0;
            // Tab 1 到港
            // console.log("set default fee : ", data.truck_fee, data.truck_cost);
            if (data.truck_fee === null || data.truck_fee === "" || data.truck_fee === undefined)
                data.truck_fee = 0;
            if (data.prepick_fee === null || data.prepick_fee === "")
                data.prepick_fee = 0;
            if (data.car_frame_fee === null || data.car_frame_fee === "")
                data.car_frame_fee = 0;
            if (data.stack_fee === null || data.stack_fee === "")
                data.stack_fee = 0;
            if (data.danger_fee === null || data.danger_fee === "")
                data.danger_fee = 0;
            if (data.null_fee === null || data.null_fee === "")
                data.null_fee = 0;
            if (data.port_other_fee === null || data.port_other_fee === "")
                data.port_other_fee = 0;
            if (data.truck_cost === null || data.truck_cost === "")
                data.truck_cost = 0;
            if (data.prepick_cost === null || data.prepick_cost === "")
                data.prepick_cost = 0;
            if (data.car_frame_cost === null || data.car_frame_cost === "")
                data.car_frame_cost = 0;
            if (data.stack_cost === null || data.stack_cost === "")
                data.stack_cost = 0;
            if (data.danger_cost === null || data.danger_cost === "")
                data.danger_cost = 0;
            if (data.null_cost === null || data.null_cost === "")
                data.null_cost = 0;
            if (data.port_other_cost === null || data.port_other_cost === "")
                data.port_other_cost = 0;
            // Tab 2 仓库
            if (data.open_closet_fee === null || data.open_closet_fee === "")
                data.open_closet_fee = 0;
            if (data.open_closet_cost === null || data.open_closet_cost === "")
                data.open_closet_cost = 0;
            if (data.on_plate_fee === null || data.on_plate_fee === "")
                data.on_plate_fee = 0;
            if (data.on_plate_cost === null || data.on_plate_cost === "")
                data.on_plate_cost = 0;
            if (data.divide_item_fee === null || data.divide_item_fee === "")
                data.divide_item_fee = 0;
            if (data.divide_item_cost === null || data.divide_item_cost === "")
                data.divide_item_cost = 0;
            if (data.tag_fee === null || data.tag_fee === "")
                data.tag_fee = 0;
            if (data.tag_cost === null || data.tag_cost === "")
                data.tag_cost = 0;
            if (data.oversize_fee === null || data.oversize_fee === "")
                data.oversize_fee = 0;
            if (data.oversize_cost === null || data.oversize_cost === "")
                data.oversize_cost = 0;
            if (data.storage_fee === null || data.storage_fee === "")
                data.storage_fee = 0;
            if (data.storage_cost === null || data.storage_cost === "")
                data.storage_cost = 0;
            if (data.storage_export_fee === null || data.storage_export_fee === "")
                data.storage_export_fee = 0;
            if (data.storage_export_cost === null || data.storage_export_cost === "")
                data.storage_export_cost = 0;
            if (data.storage_other_fee === null || data.storage_other_fee === "")
                data.storage_other_fee = 0;
            if (data.storage_other_cost === null || data.storage_other_cost === "")
                data.storage_other_cost = 0;
            // Tab 3 清关
            if (data.custom_fee === null || data.custom_fee === "")
                data.custom_fee = 0;
            if (data.custom_cost === null || data.custom_cost === "")
                data.custom_cost = 0;
            if (data.tax_fee === null || data.tax_fee === "")
                data.tax_fee = 0;
            if (data.tax_cost === null || data.tax_cost === "")
                data.tax_cost = 0;
            if (data.gst_fee === null || data.gst_fee === "")
                data.gst_fee = 0;
            if (data.gst_cost === null || data.gst_cost === "")
                data.gst_cost = 0;
            if (data.overitem_fee === null || data.overitem_fee === "")
                data.overitem_fee = 0;
            if (data.overitem_cost === null || data.overitem_cost === "")
                data.overitem_cost = 0;
            if (data.cfia_fee === null || data.cfia_fee === "")
                data.cfia_fee = 0;
            if (data.cfia_cost === null || data.cfia_cost === "")
                data.cfia_cost = 0;
            if (data.check_fee === null || data.check_fee === "")
                data.check_fee = 0;
            if (data.check_cost === null || data.check_cost === "")
                data.check_cost = 0;
            if (data.custom_other_fee === null || data.custom_other_fee === "")
                data.custom_other_fee = 0;
            if (data.custom_other_cost === null || data.custom_other_cost === "")
                data.custom_other_cost = 0;
            // 派送列表
            if (data.deliver_fee === null || data.deliver_fee === "")
                data.deliver_fee = 0;
            if (data.deliver_other_fee === null || data.deliver_other_fee === "")
                data.deliver_other_fee = 0;
            if (data.waiting_fee === null || data.waiting_fee === "")
                data.waiting_fee = 0;
            if (data.deliver_cost === null || data.deliver_cost === "")
                data.deliver_cost = 0;
            if (data.deliver_other_cost === null || data.deliver_other_cost === "")
                data.deliver_other_cost = 0;
            if (data.waiting_cost === null || data.waiting_cost === "")
                data.waiting_cost = 0;
        }

    }
    // 刷新表格中的数据
    RefreshOrderTable(order_data) {
        // console.log("RefreshOrderTable : ", order_data);
        this.arrTableData = [];
        this.arrFilterData = [];
        for (let i = 0; i < order_data.length; i++) {

            let strETA = order_data[i].eta;
            if (strETA === "" || strETA === null || strETA.isNaN) {
                strETA = "";
            } else {
                if (strETA.indexOf('T'))
                    strETA = strETA.split('T')[0];
            }
            let strETD = order_data[i].etd;
            if (strETD === "" || strETD === null || strETD.isNaN) {
                strETD = "";
            } else {
                if (strETD.indexOf('T'))
                    strETD = strETD.split('T')[0];
            }
            // let strFreeUseDate = order_data[i].free_use_date;
            // if (strFreeUseDate === "" || strFreeUseDate === null || strFreeUseDate.isNaN) {
            //     strFreeUseDate = "";
            // } else {
            //     if (strFreeUseDate.indexOf('T'))
            //         strFreeUseDate = strFreeUseDate.split('T')[0];
            // }
            // let strBoxUseDate = order_data[i].box_use_date;
            // if (strBoxUseDate === "" || strBoxUseDate === null || strBoxUseDate.isNaN) {
            //     strBoxUseDate = "";
            // } else {
            //     if (strBoxUseDate.indexOf('T'))
            //         strBoxUseDate = strBoxUseDate.split('T')[0];
            // }
            let strColor = order_data[i].highlight;
            if (order_data[i].highlight === "" || order_data[i].highlight === null)
                strColor = "#table_row_white";
            // 预计到港提醒颜色标记
            if (order_data[i].markable === undefined || order_data[i].markable === null)
                order_data[i].markable = -1;
            if (order_data[i].markable === -1) {
                let dateToday = new Date();
                if (order_data[i].eta !== undefined && order_data[i].eta !== null) {
                    let dateArrive = new Date(order_data[i].eta);
                    let date10Day = new Date(order_data[i].eta);
                    date10Day.setDate(dateArrive.getDate() - 10)
                    if (dateToday >= date10Day && (dateArrive - dateToday) >= 0) {
                        // b10DayRemind = true;
                        strColor = "#table_row_10day";
                    }
                    let date5Day = new Date(order_data[i].eta);
                    date5Day.setDate(dateArrive.getDate() - 5)
                    if (dateToday >= date5Day && (dateArrive - dateToday) >= 0) {
                        // b5DayRemind = true;
                        strColor = "#table_row_5day";
                    }
                }
            }

            let iStatus = 0;
            if (order_data[i].status !== undefined && order_data[i].status !== null && order_data[i].status !== "")
                iStatus = order_data[i].status;
            if (order_data[i].transit_hb === undefined || order_data[i].transit_hb === null || order_data[i].transit_hb === "")
                order_data[i].transit_hb = 0;
            if (order_data[i].deliver_hb === undefined || order_data[i].deliver_hb === null || order_data[i].deliver_hb === "")
                order_data[i].deliver_hb = 0;
            if (order_data[i].pickup_hb === undefined || order_data[i].pickup_hb === null || order_data[i].pickup_hb === "")
                order_data[i].pickup_hb = 0;
            if (order_data[i].agent === undefined || order_data[i].agent === null || order_data[i].agent === "")
                order_data[i].agent = "";

            // 收费默认值为0
            this.SetDefaultFee(order_data[i]);

            let uiReleaseMark = null;
            if (this.props.iType >= 10) {
                if (order_data[i].client_release === 1 && order_data[i].lk_release === 1)
                    uiReleaseMark = (
                        <Icon type="check" style={{ color: 'darkcyan', fontWeight: 'bold' }} />
                    );
            }

            // 海关文件
            let uiCFStatus = null;
            if (order_data[i].cf_status === null || order_data[i].cf_status === undefined || order_data[i].cf_status === 0)
                uiCFStatus = (
                    <div className="custom_file_link" onClick={this.OpenCustomFile.bind(this, 0, order_data[i].oid)}>
                        创建</div>
                );
            else
                uiCFStatus = (
                    <div className="custom_file_link" onClick={this.OpenCustomFile.bind(this, 1, order_data[i].oid)}>
                        查看</div>
                );

            let objOrderData = {
                key: i, oid: order_data[i].oid,
                color: strColor,
                type: this.arrOrderType[order_data[i].type],
                status: iStatus,
                transit_hb: order_data[i].transit_hb,
                deliver_hb: order_data[i].deliver_hb,
                pickup_hb: order_data[i].pickup_hb,
                status_str: <div style={{ color: 'darkcyan', fontWeight: 'bold' }}>{this.arrStatus[iStatus]}</div>,
                service_type: this.arrServiceType[order_data[i].service_type],
                eta: strETA,
                etd: strETD,
                express: order_data[i].express,
                closet: order_data[i].closet,
                oid_before: order_data[i].oid_before,
                destination: order_data[i].destination,
                port: order_data[i].port,
                comments: order_data[i].comments,
                generate_time: order_data[i].generate_time.split('T')[0],
                client: order_data[i].client,
                user_id: order_data[i].user_id,
                user_name: order_data[i].user_name,
                team: order_data[i].team,
                markable: order_data[i].markable,
                agent: order_data[i].agent,
                storage: order_data[i].storage,
                agent_fee: order_data[i].agent_fee,
                ship_fee: order_data[i].ship_fee,
                ship_other_fee: order_data[i].ship_other_fee,
                ship_other_fee_comments: order_data[i].ship_other_fee_comments,
                port_fee: order_data[i].port_fee,
                agent_cost: order_data[i].agent_cost,
                ship_cost: order_data[i].ship_cost,
                port_cost: order_data[i].port_cost,
                ship_release: order_data[i].ship_release,
                order_release: order_data[i].order_release,
                custom_release: order_data[i].custom_release,
                open_release: order_data[i].open_release,
                goc_release: order_data[i].goc_release,
                clear_release: order_data[i].clear_release,
                direct_client: order_data[i].direct_client,
                free_use_date: "",//strFreeUseDate,
                box_use_date: "",//strBoxUseDate,
                free_storage_date: order_data[i].free_storage_date, //strFreeStorageDate,
                truck: order_data[i].truck,
                truck_fee: order_data[i].truck_fee,
                prepick_fee: order_data[i].prepick_fee,
                car_frame_fee: order_data[i].car_frame_fee,
                danger_fee: order_data[i].danger_fee,
                null_fee: order_data[i].null_fee,
                port_other_fee: order_data[i].port_other_fee,
                currency: order_data[i].currency,
                open_closet_fee: order_data[i].open_closet_fee,
                open_closet_cost: order_data[i].open_closet_cost,
                on_plate_fee: order_data[i].on_plate_fee,
                on_plate_cost: order_data[i].on_plate_cost,
                on_plate_active: order_data[i].on_plate_active,
                divide_item_fee: order_data[i].divide_item_fee,
                divide_item_cost: order_data[i].divide_item_cost,
                divide_item_active: order_data[i].divide_item_active,
                tag_fee: order_data[i].tag_fee,
                tag_cost: order_data[i].tag_cost,
                tag_active: order_data[i].tag_active,
                oversize_fee: order_data[i].oversize_fee,
                oversize_cost: order_data[i].oversize_cost,
                oversize_active: order_data[i].oversize_active,
                storage_fee: order_data[i].storage_fee,
                storage_cost: order_data[i].storage_cost,
                storage_active: order_data[i].storage_active,
                storage_export_fee: order_data[i].storage_export_fee,
                storage_export_cost: order_data[i].storage_export_cost,
                storage_export_active: order_data[i].storage_export_active,
                storage_other_fee: order_data[i].storage_other_fee,
                storage_other_cost: order_data[i].storage_other_cost,
                storage_other_active: order_data[i].storage_other_active,

                release_mark: uiReleaseMark,
                client_release: order_data[i].client_release,
                lk_release: order_data[i].lk_release,
                pod: order_data[i].pod,

                receiver_name: order_data[i].receiver_name,
                receiver_phone: order_data[i].receiver_phone,
                receiver_email: order_data[i].receiver_email,
                receiver_address: order_data[i].receiver_address,

                deliver_name: order_data[i].deliver_name,
                deliver_phone: order_data[i].deliver_phone,
                deliver_email: order_data[i].deliver_email,
                deliver_address: order_data[i].deliver_address,

                oversize: order_data[i].oversize,
                // deliver_comments: order_data[i].deliver_comments,
                carteam: order_data[i].carteam,

                deliver_fee: order_data[i].deliver_fee,
                deliver_cost: order_data[i].deliver_cost,
                deliver_other_fee: order_data[i].deliver_other_fee,
                deliver_other_cost: order_data[i].deliver_other_cost,
                waiting_fee: order_data[i].waiting_fee,
                waiting_cost: order_data[i].waiting_cost,

                cf_status: order_data[i].cf_status,
                cf_button: uiCFStatus,

                reference: order_data[i].reference
            }
            // 获取最后一条备注
            axios('/GetLogisticComments', { params: { store_id: this.props.objStoreInfo.id, oid: order_data[i].oid } })
                .then(({ data }) => {
                    if (data.length > 0) {
                        objOrderData.comments = data[0].comments;
                    }
                    this.setState({
                        ...this.state,
                        bEditComments: false,
                        bNewComment: false
                    })
                }).catch(function (error) { console.log(error); });
            order_data[i].color = strColor;

            let bStatusFilter = false; let bTeamFilter = false;
            if (this.iCurrentOrderStatusFilter === 0) {
                bStatusFilter = true;
            } else {
                if (order_data[i].status === this.iCurrentOrderStatusFilter - 1) {
                    bStatusFilter = true;
                }
            }
            if (this.iCurrentTeamFilter === 0) {
                bTeamFilter = true;
            } else {
                if (order_data[i].team === this.iCurrentTeamFilter - 1) {
                    bTeamFilter = true;
                }
            }
            if (this.props.iUserLevel >= 5) {
                if (bStatusFilter && bTeamFilter) {
                    this.arrTableData.push(objOrderData);
                    this.arrFilterData.push(objOrderData);
                }
            } else {
                if (bStatusFilter && bTeamFilter) {
                    this.arrTableData.push(objOrderData);
                    this.arrFilterData.push(objOrderData);
                }
            }
        }
        // console.log("refresh table : ", this.arrTableData);
        this.arrOrderData = order_data;
        if (this.state.iDlgType === 2) {
            this.setState({
                ...this.state,
                btnControlDisable: [false, true, true],
                selectedRowKeys: [],
                iDlgType: 2,
                bDlgShow: false,
                bLoading: false,
                filterInfo: { iOrderType: 0, iOrderPaymentStatus: 0 },
                bMultiEditDlg: false,
                arrMultiEditOption: [false, false, true, false]
            });
        } else {
            this.setState({
                ...this.state,
                btnControlDisable: [false, true, true],
                selectedRowKeys: [],
                iDlgType: 0,
                bDlgShow: false,
                bLoading: false,
                filterInfo: { iOrderType: 0, iOrderPaymentStatus: 0 },
                bMultiEditDlg: false,
                arrMultiEditOption: [false, false, true, false]
            });
        }
    }
    // 从数据库获取当前的数据结构
    GetOrdersData = () => {
        message.loading({ content: '加载数据中……', key: g_strMessageKey });
        // 初始化显示全部运单
        this.iIDOrder = 0;
        this.iArriveOrder = 1;
        this.OrderTypeClicked(this.props.iType);
    }
    // 显示全部运单
    ShowAllOrders = () => {
        this.dateCurrent = "";
        this.dateArrive = ["", ""];
        // this.iOrderType = 0;
        // this.iServiceType = 0;
        this.iPaymentStatus = 0;
        this.strKeyword = "";
        this.strHBLKeyword = "";
        this.strClientSearch = "";
        this.strUserSearch = "";
        this.iCurrentTeamFilter = 0;
        this.iCurrentOrderStatusFilter = 0;
        this.refs.client_filter.state.value = "";
        this.refs.user_filter.state.value = "";
        this.refs.order_search.state.value = "";
        this.refs.hbl_search.state.value = "";
        this.setState({
            ...this.state,
            bFilterStatusChange: true
        });
        this.iIDOrder = 0;
        this.iArriveOrder = 1;
        this.GetTargetOrders(-1);
    }
    // 获取指定范围运单
    GetTargetOrders = (iOrder) => {

        console.log("GetTargetOrders1 : " + iOrder);

        let iIDOrder = 0;
        let iArriveOrder = 0;
        if (iOrder === -1) {
            iOrder = 0;
            this.iIDOrder = -1;
            // this.iHightLightOrder = -1;
            // iIDOrder = this.iIDOrder;
            // if (this.iIDOrder === 0)
            //     iArriveOrder = this.iArriveOrder;
        }
        // 按照ID排序控制
        if (iOrder === 0) {
            console.log("GetTargetOrders2 : " + iOrder);
            if (this.iIDOrder === 0)
                this.iIDOrder = 1;
            this.iIDOrder *= -1;
            iIDOrder = this.iIDOrder;
            console.log("ID Order = " + this.iIDOrder);
        }
        if (iOrder === 1) {
            this.iArriveOrder *= -1;
            // iIDOrder = this.iIDOrder;
            iArriveOrder = this.iArriveOrder;
        }
        if (iOrder === 2) {
            this.iHightLightOrder *= -1;
            iIDOrder = this.iIDOrder;
            // iArriveOrder = this.iArriveOrder;
        }
        this.setState({
            ...this.state,
            bFilterStatusChange: true
        });

        if (this.strClientSearch === undefined || this.strClientSearch === null)
            this.strClientSearch = "";
        if (this.strUserSearch === undefined || this.strUserSearch === null)
            this.strUserSearch = "";
        if (this.strHBLKeyword === undefined || this.strHBLKeyword === null)
            this.strHBLKeyword = "";
        if (this.props.strTargetOrderNo !== null && this.props.strTargetOrderNo !== "") {
            this.strKeyword = this.props.strTargetOrderNo;
            this.refs.order_search.state.value = this.strKeyword;
            this.props.TargetOrderNoReset();
        }
        let iClient = -1;
        if (this.props.iUserLevel < 5)
            iClient = this.props.iClientID;
        // console.log("iType = " + this.props.iType);
        axios('/GetLogisticOrders', {
            params: {
                store_id: parseInt(this.props.objStoreInfo.id, 10),
                type: this.props.iType,
                level: this.props.iUserLevel,
                team: this.props.iUserTeam,

                id_order: iIDOrder,
                arrive_order: iArriveOrder,
                highlight: this.iHightLightOrder,

                order_date: this.dateCurrent,
                eta: this.dateArrive,

                strKeyword: this.strKeyword,
                strClientSearch: this.strClientSearch,
                strUserSearch: this.strUserSearch,
                strHBLKeyword: this.strHBLKeyword,

                client_id: iClient
            }
        }).then(({ data }) => {
            // console.log("GetLogisticOrders : ", data);
            message.success({ content: "数据加载完成！", key: g_strMessageKey, duration: 2 });
            this.RefreshOrderTable(data);
        }).catch(function (error) { console.log(error); });
    }
    // 更新筛选出来的运单信息
    UpdateOrderData = (i) => {
        let strDate = this.arrOrderData[i].eta;
        if (strDate === "" || strDate === null || strDate.isNaN) {
            strDate = "";
        } else {
            if (strDate.indexOf('T'))
                strDate = strDate.split('T')[0];
        }

        let strColor = this.arrOrderData[i].highlight;
        if (strColor === "" || strColor === null)
            strColor = "#table_row_white";
        // 预计到港提醒颜色标记
        if (this.arrOrderData[i].markable === undefined || this.arrOrderData[i].markable === null)
            this.arrOrderData[i].markable = 0;
        if (this.arrOrderData[i].markable === 0) {
            let dateToday = new Date();
            if (this.arrOrderData[i].eta !== undefined && this.arrOrderData[i].eta !== null) {
                let dateArrive = new Date(this.arrOrderData[i].eta);
                let date10Day = new Date(this.arrOrderData[i].eta);
                date10Day.setDate(dateArrive.getDate() - 10)
                if (dateToday >= date10Day && (dateArrive - dateToday) >= 0) {
                    strColor = "#table_row_10day";
                }
                let date5Day = new Date(this.arrOrderData[i].eta);
                date5Day.setDate(dateArrive.getDate() - 5)
                if (dateToday >= date5Day && (dateArrive - dateToday) >= 0) {
                    strColor = "#table_row_5day";
                }
            }
        }

        let iMarkable = 0;
        if (!this.state.bMarkable)
            iMarkable = -1;
        let objOrderData = {
            key: i, oid: this.arrOrderData[i].oid,
            color: strColor,
            type: this.arrOrderType[this.arrOrderData[i].type],
            channel: this.arrOrderData[i].channel,
            payment_status: this.arrOrderPaymentStatus[this.arrOrderData[i].payment_status],
            transit_status: this.arrOrderTransitStatus[this.arrOrderData[i].transit_status],
            eta: this.arrOrderData[i].eta,
            tracking_no: this.arrOrderData[i].tracking_no,
            closet: this.arrOrderData[i].closet,
            charge: this.arrOrderData[i].charge,
            comments: this.arrOrderData[i].comments,
            google_drive: "",
            generate_time: this.arrOrderData[i].generate_time.split('T')[0],
            markable: iMarkable,
            reference: this.arrOrderData[i].reference
        }
        return objOrderData;
    }
    // 货运类型下拉菜单点击
    OrderTypeClicked = (index) => {
        console.log("OrderTypeClicked : " + index);
        // 对话框过滤器
        if (this.state.bDlgShow || this.state.bMultiEditDlg) {
            this.setState({
                ...this.state,
                dlgInfo: {
                    iOrderPaymentStatus: this.state.dlgInfo.iOrderPaymentStatus,
                    iOrderTransitStatus: this.state.dlgInfo.iOrderTransitStatus,
                    iOrderType: index
                }
            });
        }
        // 标题栏过滤器
        else {
            // this.iOrderType = index;
            this.FilterConditionChanged();
            this.setState({
                ...this.state,
                bFilterStatusChange: true
            });
        }
    }
    // 支付状态下拉菜单点击
    OrderPaymentStatusClicked = (index) => {
        // 对话框
        if (this.state.bDlgShow) {
            this.setState({
                ...this.state,
                dlgInfo: {
                    iOrderPaymentStatus: index,
                    iOrderTransitStatus: this.state.dlgInfo.iOrderTransitStatus,
                    iOrderTransitDetails: this.state.dlgInfo.iOrderTransitDetails,
                    iOrderType: this.state.dlgInfo.iOrderType
                }
            });
        }
        // 标题栏
        else {
            // this.arrTableData = [];
            // if (index === 0) {
            //     for (let i = 0; i < this.arrOrderData.length; i++) {
            //         if ((this.state.filterInfo.iOrderType !== 0 && this.arrOrderData[i].type === this.state.filterInfo.iOrderType - 1) ||
            //             this.state.filterInfo.iOrderType === 0) {
            //             let objOrderData = this.UpdateOrderData(i);
            //             this.arrTableData.push(objOrderData);
            //         }
            //     }
            // }
            // else {
            //     index--;
            //     for (let i = 0; i < this.arrOrderData.length; i++) {
            //         if (this.arrOrderData[i].payment_status === index) {
            //             if ((this.state.filterInfo.iOrderType !== 0 && this.arrOrderData[i].type === this.state.filterInfo.iOrderType - 1) ||
            //                 this.state.filterInfo.iOrderType === 0) {
            //                 let objOrderData = this.UpdateOrderData(i);
            //                 this.arrTableData.push(objOrderData);
            //             }
            //         }
            //     }
            //     index++;
            // }
            // this.setState({
            //     ...this.state,
            //     filterInfo: {
            //         iOrderPaymentStatus: index,
            //         iOrderType: this.state.filterInfo.iOrderType
            //     }
            // });
            this.iPaymentStatus = index;
            this.FilterConditionChanged();
            this.setState({
                ...this.state,
                bFilterStatusChange: true
            });
        }
    }
    // 物流状态下拉菜单点击
    OrderTransitStatusClicked = (index) => {
        if (this.state.bDlgShow) {
            this.TransitDetailsUpdate(index);
            this.setState({
                ...this.state,
                dlgInfo: {
                    iOrderPaymentStatus: this.state.dlgInfo.iOrderPaymentStatus,
                    iOrderTransitStatus: index,
                    iOrderTransitDetails: 0,
                    iOrderType: this.state.dlgInfo.iOrderType
                }
            });
        }
        if (this.state.bMultiEditDlg) {
            this.TransitDetailsUpdate(index);
            this.setState({
                ...this.state,
                iMultiEditTransitStatus: index,
                iMultiEditTransitDetails: 0
            });
        }
    }
    // 切换物流状态时，物流状态描述的更新
    TransitDetailsUpdate = (index) => {
        this.arrOrderTransitDetails = [];
        if (index >= 1) {
            if (this.arrTransitList[index - 1].sub_cn_name !== null) {
                if (this.arrTransitList[index - 1].sub_cn_name.trim() !== "") {
                    let arrTransitCN = this.arrTransitList[index - 1].sub_cn_name.split(',');
                    let arrTransitEN = this.arrTransitList[index - 1].sub_en_name.split(',');
                    for (let i = 0; i < arrTransitCN.length; i++) {
                        this.arrOrderTransitDetails.push(arrTransitCN[i] + " | " + arrTransitEN[i]);
                    }
                }
            }
        }
    }
    // 状态描述下拉菜单点击
    OrderTransitDetailsClicked = (index) => {
        if (this.state.bDlgShow) {
            this.setState({
                ...this.state,
                dlgInfo: {
                    iOrderPaymentStatus: this.state.dlgInfo.iOrderPaymentStatus,
                    iOrderTransitStatus: this.state.dlgInfo.iOrderTransitStatus,
                    iOrderTransitDetails: index,
                    iOrderType: this.state.dlgInfo.iOrderType
                }
            });
        }
        if (this.state.bMultiEditDlg) {
            this.setState({
                ...this.state,
                iMultiEditTransitDetails: index
            });
        }
    }
    // 搜索MBL
    SearchOrder = () => {
        let strKeyword = this.refs.order_search.state.value;
        if (strKeyword === null || strKeyword === undefined)
            strKeyword = "";
        if (this.props.iUserLevel === 1) {
            if (strKeyword === "") {
                message.error('Master Bill搜索内容不能为空！');
                return;
            }
            this.strClientSearch = "";
            this.strUserSearch = "";
            this.strKeyword = strKeyword;
        } else {
            let strClient = this.refs.client_filter.state.value;
            let strUser = this.refs.user_filter.state.value;
            if (strKeyword === "" && strClient === "" && strUser === "") {
                message.error('客户/负责人/Master Bill搜索内容不能为空！');
                return;
            }
            this.strClientSearch = strClient;
            this.strUserSearch = strUser;
            this.strKeyword = strKeyword;
        }


        this.FilterConditionChanged();
    }
    // 搜索HBL
    SearchHBL = () => {
        let strKeyword = this.refs.hbl_search.state.value;
        if (strKeyword === null || strKeyword === undefined)
            strKeyword = "";
        if (strKeyword === "") {
            message.error('HBL搜索内容不能为空！');
            return;
        }

        if (this.props.iUserLevel === 1) {
            this.strClientSearch = "";
            this.strUserSearch = "";
            this.strHBLKeyword = strKeyword;
        } else {
            let strClient = this.refs.client_filter.state.value;
            let strUser = this.refs.user_filter.state.value;

            this.strClientSearch = strClient;
            this.strUserSearch = strUser;
            this.strHBLKeyword = strKeyword;
        }

        this.FilterConditionChanged();
    }
    // 去除重复运单
    RemoveRepeatOrder = (data) => {
        let arrOrders = [];
        for (let i = 0; i < data.length; i++) {
            if (arrOrders.length > 0) {
                let bRepeat = false;
                for (let j = 0; j < arrOrders.length; j++) {
                    if (arrOrders[j].id === data[i].id) {
                        bRepeat = true;
                        break;
                    }
                }
                if (!bRepeat)
                    arrOrders.push(data[i]);
            } else {
                arrOrders.push(data[i]);
            }
        }
        console.log(arrOrders);
        return arrOrders;
    }
    // 月份变化
    MonthPickerChange = (date, dateString) => {
        this.dateCurrent = dateString;
        this.FilterConditionChanged();
        this.setState({
            ...this.state,
            bFilterStatusChange: true
        });
        // this.GetOrderList(this.state.iDateDesc, 0, 0, this.state.iHighlightFilter);
    }
    // 预计到港日期变化
    ArriveDateRangeChange = (dates, dateStrings) => {
        this.dateArrive = dateStrings;
        this.FilterConditionChanged();
        this.setState({
            ...this.state,
            bFilterStatusChange: true
        });
    }
    // 表格翻页
    TablePageChange = (page, pageSize) => {
        // console.log("TablePageChange : ", page, pageSize);
        this.setState({
            ...this.state,
            iCurrentPage: page
        });
    }
    // 选择单个文件按钮响应
    SelectSingleFile = event => {
        this.setState({
            ...this.state,
            selectedFile: event.target.files[0]
        });
        console.log(event.target.files[0]);
        // 获取上传的文件对象
        // const { files } = file.target;
        // console.log("files : ", files);
        // console.log("file = ", file);
    }
    // 选择多个文件按钮响应
    SelectMultipleFile = event => {
        this.arrFileWaitList = [];
        for (let i = 0; i < event.target.files.length; i++)
            this.arrFileWaitList.push(event.target.files[i].name);
        if (event.target.files.length > 20) {
            message.error("一次最多上传20个文件!请重新选择");
            return;
        }
        this.setState({
            ...this.state,
            selectedFiles: event.target.files
        });
    }
    // 单个文件上传按钮响应
    singleFileUploadHandler = () => {
        const data = new FormData();
        // If file selected
        if (this.state.selectedFile) {
            data.append('profileImage', this.state.selectedFile, this.state.selectedFile.name);
            console.log("文件数据整理完成 : ", data);
            axios.post('/aws-profile-upload', data, {
                headers: {
                    'accept': 'application/json',
                    'Accept-Language': 'en-US,en;q=0.8',
                    'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
                    store_id: this.props.objStoreInfo.id
                }
            }).then((response) => {
                if (200 === response.status) {
                    // If file size is larger than expected.
                    if (response.data.error) {
                        if ('LIMIT_FILE_SIZE' === response.data.error.code) {
                            message.error("文件大小不能超过2Mb!");
                        } else {
                            console.log(response.data);
                            message.error("文件类型不正确！");
                        }
                    } else {
                        // Success
                        let fileName = response.data;
                        console.log('fileName', fileName);
                    }
                }
            }).catch((error) => {
                // If another error
                console.log(error);
                message.error(error);
            });
        } else {
            // if file not selected throw error
            message.error("请选择需要上传的文件！");
        }
    };
    // 多个文件上传按钮响应
    multipleFileUploadHandler = () => {
        let data = new FormData();
        let selectedFiles = this.state.selectedFiles;
        // If file selected
        if (selectedFiles) {
            for (let i = 0; i < selectedFiles.length; i++) {
                data.append('galleryImage', selectedFiles[i], selectedFiles[i].name);
            }
            message.loading({ content: '文件上传中……', key: g_strMessageKey });
            axios.post('/aws-multiple-file-upload', data, {
                headers: {
                    'accept': 'application/json',
                    'Accept-Language': 'en-US,en;q=0.8',
                    'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
                    action: 1,
                    store_id: this.props.objStoreInfo.id,
                    oid: this.arrOrderData[this.state.selectedRowKeys[0]].oid
                }
            }).then((response) => {
                // console.log('res', response);
                if (200 === response.status) {
                    // If file size is larger than expected.
                    if (response.data.error) {
                        if ('LIMIT_FILE_SIZE' === response.data.error.code) {
                            message.error("单个文件大小不能超过2Mb!");
                        } else if ('LIMIT_UNEXPECTED_FILE' === response.data.error.code) {
                            message.error("最多同时选择20个文件!");
                        } else {
                            // If not the given file type
                            console.log(response.data.error);
                            message.error("文件类型不正确！");
                        }
                    } else {
                        // Success
                        let fileName = response.data;
                        message.success({ content: "文件上传成功！", key: g_strMessageKey, duration: 2 });
                        let strNewFileList = "";
                        for (let i = 0; i < fileName.locationArray.length; i++) {
                            strNewFileList += fileName.locationArray[i]
                            if (i !== fileName.locationArray.length - 1)
                                strNewFileList += ",";
                        }
                        if (this.arrOrderData[this.state.selectedRowKeys[0]].s3_link !== null && this.arrOrderData[this.state.selectedRowKeys[0]].s3_link !== "")
                            this.arrOrderData[this.state.selectedRowKeys[0]].s3_link = strNewFileList + "," + this.arrOrderData[this.state.selectedRowKeys[0]].s3_link;
                        else
                            this.arrOrderData[this.state.selectedRowKeys[0]].s3_link = strNewFileList;
                        this.arrFileWaitList = [];
                        if (this.refs.file_upload) {
                            document.getElementById("file_upload").value = "";
                        }
                        this.setState({
                            ...this.state,
                            selectedFile: null,
                            selectedFiles: null
                        })
                    }
                }
            }).catch((error) => {
                // If another error
                console.log(error);
                message.error(error);
            });
        } else {
            // if file not selected throw error
            message.error("请选择需要上传的文件！");
        }
    };
    // 下载文件
    DownloadFile = (index) => {
        window.open("https://mouthingmanager.s3.amazonaws.com/" + this.arrOrderData[this.state.selectedRowKeys[0]].s3_link.split(',')[index]);
    }
    // 删除文件
    DeleteFile = (index) => {
        if (this.bDeletingFile)
            return;
        this.bDeletingFile = true;
        this.setState({
            ...this.state,
            bFileDelete: true
        })
        let strTargetS3Link = this.arrOrderData[this.state.selectedRowKeys[0]].s3_link;
        if (this.arrOrderData[this.state.selectedRowKeys[0]].s3_link.indexOf(',') >= 0) {
            strTargetS3Link = this.arrOrderData[this.state.selectedRowKeys[0]].s3_link.split(',')[index];
        }
        axios.post('/aws-profile-delete', {
            store_id: this.props.objStoreInfo.id,
            action: 1,
            oid: this.arrOrderData[this.state.selectedRowKeys[0]].oid,
            s3_link: strTargetS3Link,
            index: index
        }).then(({ data }) => {
            message.success({ content: '文件删除完成！', key: g_strMessageKey, duration: 2 });
            this.arrOrderData[this.state.selectedRowKeys[0]].s3_link = data;
            this.bDeletingFile = false;
            this.setState({
                ...this.state,
                bFileDelete: false
            })
        });
    }
    // 筛选条件变化
    FilterConditionChanged = () => {
        this.GetTargetOrders(-1);
    }
    // 获取负责人数据
    GetUsersData() {
        axios('/GetUsers', { params: { store_id: this.props.objStoreInfo.id } })
            .then(({ data }) => {
                global.arrUserData = data;
            }).catch(function (error) { console.log(error); });
    }
    // 选择负责人
    SelectUser = () => {
        console.log("select user : ", this.state.bDlgShow);
        if (this.state.bDlgShow)
            this.setState({
                ...this.state,
                bSelectUserDlg: true
            });
        else
            this.setState({
                ...this.state,
                bSelectUserFilterDlg: true
            });
    }
    // 选择负责人弹窗结果
    UserSelectResult = (index) => {
        if (this.state.bDlgShow) {
            if (index >= 0) {
                this.refs.user.state.value = global.arrUserData[index].name;
                this.iChargeUserID = global.arrUserData[index].id;
                this.iChargeUserTeam = global.arrUserData[index].team;
            }
        } else {
            if (index >= 0) {
                this.refs.user_filter.state.value = global.arrUserData[index].name;
                this.iChargeUserID = global.arrUserData[index].id;
                this.iChargeUserTeam = global.arrUserData[index].team;
            }
        }
        this.setState({
            ...this.state,
            bSelectUserDlg: false,
            bSelectUserFilterDlg: false
        })
    }
    // 获取客户数据
    GetClientsData() {
        axios('/GetClients', { params: { store_id: this.props.objStoreInfo.id } })
            .then(({ data }) => {
                global.arrClientData = data;
            }).catch(function (error) { console.log(error); });
    }
    // 选择客户
    SelectClient = () => {
        if (this.refs.client_search !== undefined)
            this.refs.client_search.state.value = "";
        if (this.state.bDlgShow)
            this.setState({
                ...this.state,
                bSelectClientDlg: true
            });
        else
            this.setState({
                ...this.state,
                bSelectClientFilterDlg: true
            });
    }
    // 选择客户弹窗结果
    ClientSelectResult = (index) => {
        if (this.state.bDlgShow) {
            if (index >= 0) {
                this.refs.client.state.value = global.arrClientData[index].name;
                this.iCurrentClient = global.arrClientData[index].id;
            }
        } else {
            if (index >= 0) {
                this.refs.client_filter.state.value = global.arrClientData[index].name;
            }
        }

        this.setState({
            ...this.state,
            bSelectClientDlg: false,
            bSelectClientFilterDlg: false
        })
    }
    // 获取代理数据
    GetAgentsData() {
        axios('/GetAgents', { params: { store_id: this.props.objStoreInfo.id } })
            .then(({ data }) => {
                global.arrAgentData = data;
            }).catch(function (error) { console.log(error); });
    }
    // 选择代理
    SelectAgent = () => {
        if (this.state.bDlgShow)
            this.setState({
                ...this.state,
                bSelectAgentDlg: true
            });
        else
            this.setState({
                ...this.state,
                bSelectAgentFilterDlg: true
            });
    }
    // 选择代理弹窗结果
    AgentSelectResult = (index) => {
        if (this.state.bDlgShow) {
            if (index >= 0) {
                if (global.arrAgentData[index].name === null || global.arrAgentData[index].name === undefined)
                    global.arrAgentData[index].name = "";
                if (global.arrAgentData[index].phone === null || global.arrAgentData[index].phone === undefined)
                    global.arrAgentData[index].phone = "";
                if (global.arrAgentData[index].email === null || global.arrAgentData[index].email === undefined)
                    global.arrAgentData[index].email = "";
                if (global.arrAgentData[index].address === null || global.arrAgentData[index].address === undefined)
                    global.arrAgentData[index].address = "";
                this.refs.agent.state.value = global.arrAgentData[index].name;// + " " + global.arrAgentData[index].phone + " " + global.arrAgentData[index].email +
                // " " + global.arrAgentData[index].address;
                this.iCurrentAgent = global.arrAgentData[index].id;
            }
        }
        this.setState({
            ...this.state,
            bSelectAgentDlg: false,
            bSelectAgentFilterDlg: false
        })
    }
    // 跳转到绑定的正式单
    JumpToAutoOrder = () => {
        this.props.AutoOrderAndPrediction(1, this.objCurrentOrder.oid);
    }
    // 预计到港提醒自动标记
    MarkableChange = (index, e) => {
        switch (index) {
            case 0: {
                this.setState({
                    ...this.state,
                    bMarkable: e.target.checked
                });
                break;
            }
            case 1: {
                this.setState({
                    ...this.state,
                    bCI: e.target.checked
                });
                break;
            }
            case 2: {
                this.setState({
                    ...this.state,
                    bB3: e.target.checked
                });
                break;
            }
            case 3: {
                this.setState({
                    ...this.state,
                    bPODEIR: e.target.checked
                });
                break;
            }
            default: break;
        }

    }
    // 预计到港提醒过滤
    ArriveDateFilter = (iDay) => {
        console.log("ArriaveDateFilter", iDay);
        if (iDay === 5) {
            this.arrTableData = [];
            for (let i = 0; i < this.arrOrderData.length; i++) {
                if (this.arrFilterData[i].color === '#table_row_5day')
                    this.arrTableData.push(this.arrFilterData[i]);
            }
        }
        if (iDay === 10) {
            this.arrTableData = [];
            for (let i = 0; i < this.arrFilterData.length; i++) {
                if (this.arrFilterData[i].color === '#table_row_10day')
                    this.arrTableData.push(this.arrFilterData[i]);
            }
        }
        this.setState({
            ...this.state,
            bLoading: false
        });
    }
    // 批量编辑选项
    MultiEditOption = (index, e) => {
        let arrMultiEditOption = this.state.arrMultiEditOption;
        arrMultiEditOption[index] = e.target.checked;
        this.setState({
            ...this.state,
            arrMultiEditOption: arrMultiEditOption
        });
    }
    // 创建/编辑HouseBill
    CreateEditHouseBill = (iHouseBillType, index) => {
        console.log("CreateEditHouseBill : ", iHouseBillType)
        this.abHBFeeChange = false;

        this.iCurrentHouseBill = index;
        let iPath = 1;
        // 有指定的HB
        if (index >= 0) {
            iPath = this.arrHouseBill[index].path;
            this.SetDefaultFee(null);
        }
        let iClearRelease = 0;


        // 新建
        if (iHouseBillType === 0) {
            this.arrHBFee = [
                { name: '报关', active: 0 },  // 0
                { name: '关税', active: 0 },  // 1
                { name: 'GST', active: 0 },  // 2
                { name: '打板', active: 0 },  // 3
                { name: '贴标', active: 0 },  // 4
                { name: '超规', active: 0 },  // 5
                { name: '仓储', active: 0 },  // 6
                { name: '出仓', active: 0 },  // 7
                { name: '超项', active: 0 },  // 8
                { name: 'CFIA/OGD', active: 0 },  //9
                { name: '查验', active: 0 },  // 10
                { name: '换单', active: 0 },  // 11
                { name: '派送', active: 0 },  // 12
                { name: '托盘', active: 0 },  // 13
                { name: '等候', active: 0 },  // 14
                { name: '其他', active: 0 },  // 15
            ];

            let objHouseBill = {
                oid: this.arrOrderData[this.state.selectedRowKeys[0]].oid,
                house_bill_no: this.GetHouseBillNo().no, mark: '',
                receiver_name: '', receiver_phone: '', receiver_address: '', path: iPath,
                num_pkg: 0, num_crate: 0, cbm: 0, weight: 0, plate: 0, oversize: '',
                gst_fee: 0, gst_cost: 0, tax_fee: 0, tax_cost: 0, custom_fee: 0, custom_cost: 0,
                on_plate_fee: 0, on_plate_cost: 0, on_plate_active: 0, tag_fee: 0, tag_cost: 0, tag_active: 0,
                oversize_fee: 0, oversize_cost: 0, oversize_active: 0, storage_fee: 0, storage_cost: 0, storage_active: 0,
                storage_export_fee: 0, storage_export_cost: 0, storage_export_active: 0,
                storage_other_fee: 0, storage_other_cost: 0, storage_other_active: 0,
                overitem_fee: 0, overitem_cost: 0, overitem_active: 0, cfia_fee: 0, cfia_cost: 0, cfia_active: 0,
                check_fee: 0, check_cost: 0, check_active: 0, hb_other_fee: 0, hb_other_cost: 0, hb_other_active: 0,
                clear_release: 0, pod: 0, lk_release: 0, client_release: 0, carteam: "", deliver_comments: "",
                deliver_fee: 0, deliver_cost: 0, storage_export45_fee: 0, storage_export45_cost: 0,
                transit_fee: 0, transit_cost: 0,
                on_plate45_active: 0, waiting_active: 0, hb_other45_active: 0,
                on_plate45_fee: 0, on_plate45_cost: 0, waiting_fee: 0, waiting_cost: 0, hb_other45_fee: 0, hb_other45_cost: 0,
                comments: ""
            }
            this.iCurrentHBFee = 0;
            this.iCurrentTransferFee = 0;
            this.arrHouseBill.splice(0, 0, objHouseBill);
            this.iCurrentHouseBill = 0;
        }
        // 编辑
        if (iHouseBillType === 1) {
            this.arrHBFee = [
                { name: '报关', active: 0 },  // 0
                { name: '关税', active: 0 },  // 1
                { name: 'GST', active: 0 },  // 2
                { name: '打板', active: 0 },  // 3
                { name: '贴标', active: 0 },  // 4
                { name: '超规', active: 0 },  // 5
                { name: '仓储', active: 0 },  // 6
                { name: '出仓', active: 0 },  // 7
                { name: '超项', active: 0 },  // 8
                { name: 'CFIA/OGD', active: 0 },  //9
                { name: '查验', active: 0 },  // 10
                { name: '换单', active: 0 },  // 11
                { name: '派送', active: 0 },  // 12
                { name: '托盘', active: 0 },  // 13
                { name: '等候', active: 0 },  // 14
                { name: '其他', active: 0 },  // 15
            ];
            console.log(this.arrHouseBill[this.iCurrentHouseBill]);
            this.arrHBFee[0].active = this.arrHouseBill[this.iCurrentHouseBill].custom_active;
            this.arrHBFee[1].active = this.arrHouseBill[this.iCurrentHouseBill].tax_active;
            this.arrHBFee[2].active = this.arrHouseBill[this.iCurrentHouseBill].gst_active;
            this.arrHBFee[3].active = this.arrHouseBill[this.iCurrentHouseBill].on_plate_active;
            this.arrHBFee[4].active = this.arrHouseBill[this.iCurrentHouseBill].tag_active;
            this.arrHBFee[5].active = this.arrHouseBill[this.iCurrentHouseBill].oversize_active;
            this.arrHBFee[6].active = this.arrHouseBill[this.iCurrentHouseBill].storage_active;
            this.arrHBFee[7].active = this.arrHouseBill[this.iCurrentHouseBill].storage_export_active;
            this.arrHBFee[8].active = this.arrHouseBill[this.iCurrentHouseBill].overitem_active;
            this.arrHBFee[9].active = this.arrHouseBill[this.iCurrentHouseBill].cfia_active;
            this.arrHBFee[10].active = this.arrHouseBill[this.iCurrentHouseBill].check_active;
            this.arrHBFee[11].active = this.arrHouseBill[this.iCurrentHouseBill].transit_active;
            this.arrHBFee[12].active = this.arrHouseBill[this.iCurrentHouseBill].deliver_active;
            this.arrHBFee[13].active = this.arrHouseBill[this.iCurrentHouseBill].on_plate45_active;
            this.arrHBFee[14].active = this.arrHouseBill[this.iCurrentHouseBill].waiting_active;
            this.arrHBFee[15].active = this.arrHouseBill[this.iCurrentHouseBill].hb_other_active;
            this.iCurrentHBFee = -1;
            for (let i = 0; i < this.arrHBFee.length; i++) {
                if (this.arrHBFee[i].active === null || this.arrHBFee[i].active === undefined)
                    this.arrHBFee[i].active = 0;
                if (this.arrHBFee[i].active === 0) {
                    this.iCurrentHBFee = i; break;
                }
            }
        }
        if (this.state.iStorageCustom === 0)
            this.setState({
                ...this.state,
                bHouseBillDlg: true,
                iHouseBillType: iHouseBillType,
                iPath: iPath,
                iPOD: this.arrHouseBill[this.iCurrentHouseBill].pod,
                iClearRelease: iClearRelease,
                iLKRelease: this.arrHouseBill[this.iCurrentHouseBill].lk_release,
                iClientRelease: this.arrHouseBill[this.iCurrentHouseBill].client_release
            });
        else
            this.setState({
                ...this.state,
                bHouseBillDlg: true,
                iHouseBillType: iHouseBillType,
                iPath: iPath,
                iPOD: this.arrHouseBill[this.iCurrentHouseBill].pod,
                iLKRelease: this.arrHouseBill[this.iCurrentHouseBill].lk_release,
                iClientRelease: this.arrHouseBill[this.iCurrentHouseBill].client_release
            });
    }
    // HouseBill 信息变化
    HouseBillInfoChange = (index, e) => {
        if (index === 0)
            this.refs.num_pkg.state.value = e.target.value;
        if (index === 1)
            this.refs.num_crate.state.value = e.target.value;
        let fTotal = parseFloat(this.refs.num_pkg.state.value) + parseFloat(this.refs.num_crate.state.value);
        if (typeof (fTotal) === 'number')
            fTotal = fTotal.toFixed(2);
        this.refs.num_total.state.value = fTotal;

        this.setState({
            ...this.state,
            bOrderInfoChange: true
        })
    }
    // 获取空余的HouseBill编号
    GetHouseBillNo = () => {
        let strNo = "";
        let iIndex = -1;
        for (let i = 0; i < this.arrHouseBillNo.length; i++) {
            if (!this.arrHouseBillNo[i].create) {
                strNo = this.arrHouseBillNo[i].no;
                iIndex = i;
                break;
            }
        }
        return { no: strNo, index: iIndex };
    }
    // 重新排序HouseBill
    // ReorderHouseBill = (data) => {
    //     this.arrHouseBill = [];
    //     for (let j = 0; j < this.arrHouseBillNo.length; j++) {
    //         for (let i = 0; i < data.length; i++) {
    //             if (parseInt(data[i].path, 10) !== 0 && parseInt(data[i].path, 10) !== 1 && parseInt(data[i].path, 10) !== 2)
    //                 data[i].path = 0;
    //             if (data[i].house_bill_no === this.arrHouseBillNo[j].no) {
    //                 this.arrHouseBill.splice(0, 0, data[i]);
    //             }
    //         }
    //     }
    // }
    // 确认创建/保存HouseBill
    ConfirmHouseBill = () => {
        if (this.props.iUserLevel < 9 && this.props.iType === 7) {
            message.error("已结单，无法保存修改，请联系管理员！");
            return;
        }
        let objHouseBill = {};
        // let objNewHouseBillInfo = {};
        let objActionRecord = null;
        // 客户端
        if (this.props.iUserLevel < 5) {
            if (this.state.iDlgTab < 4) {
                message.error("无法保存，如有需要，请联系客服修改信息！");
                return;
            }
            // 客户放货专用
            else {
                objHouseBill = {
                    oid: this.arrOrderData[this.state.selectedRowKeys[0]].oid,
                    client_release: this.state.iClientRelease,
                    mark: this.refs.mark.state.value,
                    // deliver_comments: this.refs.deliver_comments.state.value
                }
                objHouseBill.id = this.arrHouseBill[this.iCurrentHouseBill].id;
                objHouseBill.house_bill_no = this.arrHouseBill[this.iCurrentHouseBill].house_bill_no;
                if (objHouseBill.client_release === 0)
                    objHouseBill.pod = 0;
            }
        }
        // 管理后台
        else {
            let strHouseBillNo = this.refs.house_bill_no.state.value;
            if (strHouseBillNo === undefined || strHouseBillNo === null || strHouseBillNo === "") {
                message.warning("House Bill 编号不能为空！");
                return;
            }
            if (strHouseBillNo.trim() === "") {
                message.warning("House Bill 编号不能为空！");
                return;
            }
            // HB基础信息
            // objHouseBill = {
            //     oid: this.arrOrderData[this.state.selectedRowKeys[0]].oid,
            //     house_bill_no: strHouseBillNo,
            //     mark: this.refs.mark.state.value,
            //     path: this.state.iPath,
            //     pod: this.state.iPOD,
            //     lk_release: this.state.iLKRelease,
            //     client_release: this.state.iClientRelease,
            //     comments: this.refs.hb_comments.state.value
            // }
            // 到仓之前的Tab
            if (this.state.iDlgTab < 4) {
                objHouseBill = {
                    oid: this.arrOrderData[this.state.selectedRowKeys[0]].oid,
                    house_bill_no: "",
                    mark: this.refs.mark.state.value,
                    receiver_name: this.refs.receiver_name.state.value,
                    receiver_phone: this.refs.receiver_phone.state.value,
                    receiver_email: this.refs.receiver_email.state.value,
                    receiver_address: this.refs.receiver_address.state.value,
                    num_pkg: this.refs.num_pkg.state.value,
                    num_crate: this.refs.num_crate.state.value,
                    cbm: this.refs.hb_cbm.state.value,
                    weight: this.refs.hb_weight.state.value,
                    plate: this.refs.hb_plate.state.value,
                    oversize: this.refs.oversize.state.value,
                    path: this.state.iPath,
                    pod: this.state.iPOD,
                    clear_release: this.state.iClearRelease,
                    lk_release: this.state.iLKRelease,
                    client_release: this.state.iClientRelease,
                    comments: this.refs.hb_comments.state.value,

                }
            }
            // 后三个Tab
            else {
                objHouseBill = {
                    oid: this.arrOrderData[this.state.selectedRowKeys[0]].oid,
                    house_bill_no: "",
                    mark: this.refs.mark.state.value,
                    receiver_name: this.refs.receiver_name.state.value,
                    receiver_phone: this.refs.receiver_phone.state.value,
                    receiver_email: this.refs.receiver_email.state.value,
                    receiver_address: this.refs.receiver_address.state.value,
                    num_pkg: this.refs.num_pkg.state.value,
                    num_crate: this.refs.num_crate.state.value,
                    cbm: this.refs.hb_cbm.state.value,
                    weight: this.refs.hb_weight.state.value,
                    plate: this.refs.hb_plate.state.value,
                    oversize: this.refs.oversize.state.value,
                    path: this.state.iPath,
                    pod: this.state.iPOD,
                    clear_release: this.state.iClearRelease,
                    lk_release: this.state.iLKRelease,
                    client_release: this.state.iClientRelease,
                    comments: this.refs.hb_comments.state.value,
                    carteam_id: this.iCurrentCarTeam,
                    carteam: this.refs.carteam.state.value,
                    pickup_date: this.refs.pickup_date.state.value
                }
            }
            if (this.state.iHouseBillType === 0) {
                objHouseBill = {
                    oid: this.arrOrderData[this.state.selectedRowKeys[0]].oid,
                    house_bill_no: "",
                    mark: this.refs.mark.state.value,
                    receiver_name: this.refs.receiver_name.state.value,
                    receiver_phone: this.refs.receiver_phone.state.value,
                    receiver_email: this.refs.receiver_email.state.value,
                    receiver_address: this.refs.receiver_address.state.value,
                    num_pkg: this.refs.num_pkg.state.value,
                    num_crate: this.refs.num_crate.state.value,
                    cbm: this.refs.hb_cbm.state.value,
                    weight: this.refs.hb_weight.state.value,
                    plate: this.refs.hb_plate.state.value,
                    oversize: this.refs.oversize.state.value,
                    path: this.state.iPath,
                    pod: this.state.iPOD,
                    clear_release: this.state.iClearRelease,
                    lk_release: this.state.iLKRelease,
                    client_release: this.state.iClientRelease,
                    comments: this.refs.hb_comments.state.value,
                    gst_fee: 0, gst_cost: 0, tax_fee: 0, tax_cost: 0, custom_fee: 0, custom_cost: 0,
                    on_plate_fee: 0, on_plate_cost: 0, on_plate_active: 0, tag_fee: 0, tag_cost: 0, tag_active: 0,
                    oversize_fee: 0, oversize_cost: 0, oversize_active: 0, storage_fee: 0, storage_cost: 0, storage_active: 0,
                    storage_export_fee: 0, storage_export_cost: 0, storage_export_active: 0,
                    storage_other_fee: 0, storage_other_cost: 0, storage_other_active: 0,
                    overitem_fee: 0, overitem_cost: 0, overitem_active: 0, cfia_fee: 0, cfia_cost: 0, cfia_active: 0,
                    check_fee: 0, check_cost: 0, check_active: 0, hb_other_fee: 0, hb_other_cost: 0, hb_other_active: 0,

                    deliver_fee: 0, deliver_cost: 0, storage_export45_fee: 0, storage_export45_cost: 0,
                    transit_fee: 0, transit_cost: 0,
                    on_plate45_active: 0, waiting_active: 0, hb_other45_active: 0,
                    on_plate45_fee: 0, on_plate45_cost: 0, waiting_fee: 0, waiting_cost: 0, hb_other45_fee: 0, hb_other45_cost: 0
                }
            }
            // 编辑
            else {
                objHouseBill.id = this.arrHouseBill[this.iCurrentHouseBill].id;
            }
            objHouseBill.house_bill_no = this.refs.house_bill_no.state.value;
            this.arrHouseBill[this.iCurrentHouseBill].house_bill_no = this.refs.house_bill_no.state.value;

            if (this.state.iDlgTab >= 2) {
                // HBL Fee 费用信息和操作记录
                objActionRecord = {
                    store_id: this.props.objStoreInfo.id,
                    oid: this.objCurrentOrder.oid,
                    mark: objHouseBill.mark,
                    user_id: this.props.iUserID,
                    user_name: this.props.strUserName,
                    charger_id: this.objCurrentOrder.user_id,
                    charger_name: this.objCurrentOrder.user_name,
                    team: this.objCurrentOrder.team,
                    tab: this.state.iDlgTab,
                    record: "",
                    update_time: new Date()
                }
                this.GetHBFeeInfo(objHouseBill, objActionRecord);
                console.log(objHouseBill, objActionRecord);
            }
            else {
                this.abHBFeeChange = false;
            }

        }

        // 创建/更新的HouseBill
        message.loading({ content: '正在保存HouseBill……', key: g_strMessageKey });
        axios.post('/EditHouseBill', objHouseBill)
            .then(({ data }) => {
                if (data.status === 1) {
                    this.arrHouseBill = data.hb_list;
                    // console.log("hb list : ", data.hb_list);
                    let strMessage = "创建成功！";
                    if (this.state.iHouseBillType === 0) {
                        this.setState({
                            ...this.state,
                            bHouseBillDlg: false
                        });
                    } else {
                        strMessage = "更新成功！";
                        if (this.props.iUserLevel < 5) {
                            this.arrHouseBill[this.iCurrentHouseBill].client_release = objHouseBill.client_release;
                        }
                    }
                    message.success({ content: strMessage, key: g_strMessageKey, duration: 2 });

                    // 费用变化记录
                    if (this.abHBFeeChange) {
                        this.abHBFeeChange = false;
                        axios.post('/LogisticsActionRecord', { objActionRecord: objActionRecord })
                            .then(({ data }) => {
                                console.log("LogisticsActionRecord: ", data);
                            });
                    }

                } else {
                    message.error({ content: data.message, key: g_strMessageKey, duration: 2 });
                    if (this.state.iHouseBillType === 0)
                        this.arrHouseBill.splice(this.iCurrentHouseBill, 1);
                }
            }).catch(function (error) { console.log(error); });
    }
    // 关闭HouseBill弹窗
    CloseHouseBill = () => {
        this.abHBFeeChange = false;
        // 新建但是选择关闭时，需要从列表中删掉刚建的HB
        if (this.state.iHouseBillType === 0) {
            this.arrHouseBill.splice(this.iCurrentHouseBill, 1);
        }


        this.iCurrentHouseBill = -1;
        this.setState({
            ...this.state,
            bHouseBillDlg: false
        })
    }
    // 删除HouseBill
    DeleteHouseBill = (index) => {
        if (this.props.iUserLevel < 9 && this.props.iType === 7) {
            message.error("已结单，无法保存修改，请联系管理员！");
            return;
        }
        this.iCurrentHouseBill = index;
        this.setState({
            ...this.state,
            bHouseBillDlg: true,
            iHouseBillType: 2
        })
    }
    // 确认删除HouseBill
    ConfirmDeleteHouseBill = (iDelete) => {
        if (iDelete === 1) {
            message.loading({ content: 'House Bill 删除中……', key: g_strMessageKey });
            axios.post('/DeleteHouseBill', { id: this.arrHouseBill[this.iCurrentHouseBill].id })
                .then(({ data }) => {
                    if (data.status === -1) {
                        message.error({
                            content: "删除 House Bill 失败，请重新尝试！",
                            key: g_strMessageKey, duration: 2
                        });
                    } else {
                        message.success({ content: "House Bill 已删除！", key: g_strMessageKey, duration: 2 });
                        for (let i = 0; i < this.arrHouseBillNo.length; i++) {
                            if (this.arrHouseBillNo[i].no === this.arrHouseBill[this.iCurrentHouseBill].house_bill_no) {
                                this.arrHouseBillNo[i].create = false;
                                break;
                            }
                        }
                        this.arrHouseBill.splice(this.iCurrentHouseBill, 1);
                        this.iCurrentHouseBill = -1;
                        this.setState({
                            ...this.state,
                            bHouseBillDlg: false,
                            iHouseBillType: -1
                        })
                    }
                }).catch(function (error) { console.log(error); });
        } else {
            this.iCurrentHouseBill = -1;
            this.setState({
                ...this.state,
                bHouseBillDlg: false,
                iHouseBillType: -1
            })
        }
    }
    // 切换Order Dlg Tab
    SwitchDlgTab = (iTab) => {
        if (this.bSaving)
            return;
        if (this.state.iDlgType === 0)
            return;

        this.bFeeChange = false;
        this.abHBFeeChange = false;

        let iStatus = this.arrOrderData[this.state.selectedRowKeys[0]].status;
        let iTransitHB = this.arrOrderData[this.state.selectedRowKeys[0]].transit_hb;
        let iDeliverHB = this.arrOrderData[this.state.selectedRowKeys[0]].deliver_hb;
        let iPickupHB = this.arrOrderData[this.state.selectedRowKeys[0]].pickup_hb;

        let bTransit = false;
        let bDeliver = false;
        let bPickup = false;

        if (this.arrOrderData[this.state.selectedRowKeys[0]].storage_custom === 0) {
            for (let i = 0; i < this.arrHouseBill.length; i++) {
                if (this.arrHouseBill[i].path === 0) {
                    bTransit = true;
                }
                if (this.arrHouseBill[i].path === 1) {
                    bDeliver = true;
                }
                if (this.arrHouseBill[i].path === 2) {
                    bPickup = true;
                }
            }
            // for (let i = 0; i < this.arrHouseBill.length; i++) {
            //     if (this.arrHouseBill[i].clear_release === 1 && this.arrHouseBill[i].path === 0) {
            //         bTransit = true;
            //     }
            //     if (this.arrHouseBill[i].clear_release === 1 && this.arrHouseBill[i].path === 1) {
            //         bDeliver = true;
            //     }
            //     if (this.arrHouseBill[i].clear_release === 1 && this.arrHouseBill[i].path === 2) {
            //         bPickup = true;
            //     }
            // }
        } else {
            if (this.arrOrderData[this.state.selectedRowKeys[0]].open_release === 1) {
                if (iTransitHB === 1)
                    bTransit = true;
                if (iDeliverHB === 1)
                    bDeliver = true;
                if (iPickupHB === 1)
                    bPickup = true;
            }
            if (this.arrOrderData[this.state.selectedRowKeys[0]].storage_custom === 2) {
                bTransit = false; bPickup = false; bDeliver = true;
                // if (iTab === 2)
                //     return;
            }
        }

        if (iTab === 4 && !bTransit)
            return;
        if (iTab === 5 && !bDeliver)
            // 海派列表
            if (this.props.iType !== 5)
                return;
        if (iTab === 6 && !bPickup)
            return;
        if (iDeliverHB === 1 && iTab === 2 && this.state.iStorageCustom === 2)
            return;
        if (iTab > iStatus && iTab <= 3) {
            if (iTab === 3 && iStatus === 2)
                console.log("先清关再仓库！");
            else {
                if (iTab !== 7)
                    return;
            }
        }
        if (iTab === 7) {
            let objOrder = this.arrOrderData[this.state.selectedRowKeys[0]];
            // 发船
            if (objOrder.agent_fee === null || objOrder.agent_fee === undefined || objOrder.agent_fee === "")
                objOrder.agent_fee = 0.0;
            if (objOrder.agent_cost === null || objOrder.agent_cost === undefined || objOrder.agent_cost === "")
                objOrder.agent_cost = 0.0;
            if (objOrder.ship_fee === null || objOrder.ship_fee === undefined || objOrder.ship_fee === "")
                objOrder.ship_fee = 0.0;
            if (objOrder.ship_cost === null || objOrder.ship_cost === undefined || objOrder.ship_cost === "")
                objOrder.ship_cost = 0.0;
            if (objOrder.port_fee === null || objOrder.port_fee === undefined || objOrder.shiport_feep_fee === "")
                objOrder.port_fee = 0.0;
            if (objOrder.port_cost === null || objOrder.port_cost === undefined || objOrder.port_cost === "")
                objOrder.port_cost = 0.0;
            if (objOrder.ship_other_fee === null || objOrder.ship_other_fee === undefined || objOrder.ship_other_fee === "")
                objOrder.ship_other_fee = 0.0;
            if (objOrder.ship_other_cost === null || objOrder.ship_other_cost === undefined || objOrder.ship_other_cost === "")
                objOrder.ship_other_cost = 0.0;
            objOrder.agent_benefit = objOrder.agent_fee - objOrder.agent_cost;
            objOrder.ship_benefit = objOrder.ship_fee - objOrder.ship_cost;
            objOrder.port_benefit = objOrder.port_fee - objOrder.port_cost;
            objOrder.ship_other_benefit = objOrder.ship_other_fee - objOrder.ship_other_cost;
            objOrder.ship_total_fee = parseFloat(objOrder.agent_fee) + parseFloat(objOrder.ship_fee) + parseFloat(objOrder.port_fee) + parseFloat(objOrder.ship_other_fee);
            objOrder.ship_total_cost = parseFloat(objOrder.agent_cost) + parseFloat(objOrder.ship_cost) + parseFloat(objOrder.port_cost) + parseFloat(objOrder.ship_other_cost);
            objOrder.ship_total_benefit = objOrder.ship_total_fee - objOrder.ship_total_cost;
            // 到港
            if (objOrder.truck_fee === null || objOrder.truck_fee === undefined || objOrder.truck_fee === "")
                objOrder.truck_fee = 0.0;
            if (objOrder.truck_cost === null || objOrder.truck_cost === undefined || objOrder.truck_cost === "")
                objOrder.truck_cost = 0.0;
            if (objOrder.prepick_fee === null || objOrder.prepick_fee === undefined || objOrder.prepick_fee === "")
                objOrder.prepick_fee = 0.0;
            if (objOrder.prepick_cost === null || objOrder.prepick_cost === undefined || objOrder.prepick_cost === "")
                objOrder.prepick_cost = 0.0;
            if (objOrder.stack_fee === null || objOrder.stack_fee === undefined || objOrder.stack_fee === "")
                objOrder.stack_fee = 0.0;
            if (objOrder.stack_cost === null || objOrder.stack_cost === undefined || objOrder.stack_cost === "")
                objOrder.stack_cost = 0.0;
            if (objOrder.danger_fee === null || objOrder.danger_fee === undefined || objOrder.danger_fee === "")
                objOrder.danger_fee = 0.0;
            if (objOrder.danger_cost === null || objOrder.danger_cost === undefined || objOrder.danger_cost === "")
                objOrder.danger_cost = 0.0;
            if (objOrder.null_fee === null || objOrder.null_fee === undefined || objOrder.null_fee === "")
                objOrder.null_fee = 0.0;
            if (objOrder.null_cost === null || objOrder.null_cost === undefined || objOrder.null_cost === "")
                objOrder.null_cost = 0.0;
            if (objOrder.null_fee === null || objOrder.null_fee === undefined || objOrder.null_fee === "")
                objOrder.null_fee = 0.0;
            if (objOrder.null_cost === null || objOrder.null_cost === undefined || objOrder.null_cost === "")
                objOrder.null_cost = 0.0;
            objOrder.truck_benefit = objOrder.truck_fee - objOrder.truck_cost;
            objOrder.prepick_benefit = objOrder.prepick_fee - objOrder.prepick_cost;
            objOrder.car_frame_benefit = objOrder.car_frame_fee - objOrder.car_frame_cost;
            objOrder.stack_benefit = objOrder.stack_fee - objOrder.stack_cost;
            objOrder.danger_benefit = objOrder.danger_fee - objOrder.danger_cost;
            objOrder.null_benefit = objOrder.null_fee - objOrder.null_cost;
            objOrder.port_other_benefit = objOrder.port_other_fee - objOrder.port_other_cost;
            objOrder.port_total_fee = parseFloat(objOrder.truck_fee) + parseFloat(objOrder.prepick_fee) + parseFloat(objOrder.car_frame_fee) + parseFloat(objOrder.stack_fee) +
                parseFloat(objOrder.danger_fee) + parseFloat(objOrder.null_fee) + parseFloat(objOrder.port_other_fee);
            objOrder.port_total_cost = parseFloat(objOrder.truck_cost) + parseFloat(objOrder.prepick_cost) + parseFloat(objOrder.car_frame_cost) +
                parseFloat(objOrder.stack_cost) + parseFloat(objOrder.danger_cost) + parseFloat(objOrder.null_cost) + parseFloat(objOrder.port_other_cost);
            objOrder.port_total_benefit = objOrder.port_total_fee - objOrder.port_total_cost;
            // 仓库
            if (objOrder.open_closet_fee === null || objOrder.open_closet_fee === undefined || objOrder.open_closet_fee === "")
                objOrder.open_closet_fee = 0.0;
            if (objOrder.open_closet_cost === null || objOrder.open_closet_cost === undefined || objOrder.open_closet_cost === "")
                objOrder.open_closet_cost = 0.0;
            if (objOrder.divide_item_fee === null || objOrder.divide_item_fee === undefined || objOrder.divide_item_fee === "")
                objOrder.divide_item_fee = 0.0;
            if (objOrder.divide_item_cost === null || objOrder.divide_item_cost === undefined || objOrder.divide_item_cost === "")
                objOrder.divide_item_cost = 0.0;
            objOrder.open_closet_benefit = objOrder.open_closet_fee - objOrder.open_closet_cost;
            objOrder.divide_item_benefit = objOrder.divide_item_fee - objOrder.divide_item_cost;
            objOrder.storage_total_fee = parseFloat(objOrder.open_closet_fee) + parseFloat(objOrder.divide_item_fee);
            objOrder.storage_total_cost = parseFloat(objOrder.open_closet_cost) + parseFloat(objOrder.divide_item_cost);
            objOrder.storage_total_benefit = objOrder.storage_total_fee - objOrder.storage_total_cost;
            //清关
            if (objOrder.custom_fee === null || objOrder.custom_fee === undefined || objOrder.custom_fee === "")
                objOrder.custom_fee = 0.0;
            if (objOrder.custom_cost === null || objOrder.custom_cost === undefined || objOrder.custom_cost === "")
                objOrder.custom_cost = 0.0;
            if (objOrder.tax_fee === null || objOrder.tax_fee === undefined || objOrder.tax_fee === "")
                objOrder.tax_fee = 0.0;
            if (objOrder.tax_cost === null || objOrder.tax_cost === undefined || objOrder.tax_cost === "")
                objOrder.tax_cost = 0.0;
            if (objOrder.gst_fee === null || objOrder.gst_fee === undefined || objOrder.gst_fee === "")
                objOrder.gst_fee = 0.0;
            if (objOrder.gst_cost === null || objOrder.gst_cost === undefined || objOrder.gst_cost === "")
                objOrder.gst_cost = 0.0;
            objOrder.custom_benefit = objOrder.custom_fee - objOrder.custom_cost;
            objOrder.tax_benefit = objOrder.tax_fee - objOrder.tax_cost;
            objOrder.gst_benefit = objOrder.gst_fee - objOrder.gst_cost;
            let fCustomOtherFee = 0.0; let fCustomOtherCost = 0.0;
            if (objOrder.overitem_active === 1) {
                objOrder.overitem_benefit = objOrder.overitem_fee - objOrder.overitem_cost;
                fCustomOtherFee += parseFloat(objOrder.overitem_fee);
                fCustomOtherCost += parseFloat(objOrder.overitem_cost);
            }
            if (objOrder.cfia_active === 1) {
                objOrder.cfia_benefit = objOrder.cfia_fee - objOrder.cfia_cost;
                fCustomOtherFee += parseFloat(objOrder.cfia_fee);
                fCustomOtherCost += parseFloat(objOrder.cfia_cost);
            }
            if (objOrder.check_active === 1) {
                objOrder.check_benefit = objOrder.check_fee - objOrder.check_cost;
                fCustomOtherFee += parseFloat(objOrder.check_fee);
                fCustomOtherCost += parseFloat(objOrder.check_cost);
            }
            if (objOrder.custom_other_active === 1) {
                objOrder.custom_other_benefit = objOrder.custom_other_fee - objOrder.custom_other_cost;
                fCustomOtherFee += parseFloat(objOrder.custom_other_fee);
                fCustomOtherCost += parseFloat(objOrder.custom_other_cost);
            }
            objOrder.custom_total_fee = parseFloat(objOrder.custom_fee) + parseFloat(objOrder.tax_fee) + parseFloat(objOrder.gst_fee);
            objOrder.custom_total_cost = parseFloat(objOrder.custom_cost) + parseFloat(objOrder.tax_cost) + parseFloat(objOrder.gst_cost);
            if (fCustomOtherFee > 0.0)
                objOrder.custom_total_fee += fCustomOtherFee;
            if (fCustomOtherCost > 0.0)
                objOrder.custom_total_cost += fCustomOtherCost;
            // HB费用
            let fStorageHBFee = 0.0; let fStorageHBCost = 0.0; let fStorageHBBenefit = 0.0;
            let fCustomHBFee = 0.0; let fCustomHBCost = 0.0; let fCustomHBBenefit = 0.0;
            let fTransitHBFee = 0.0; let fTransitHBCost = 0.0; let fTransitHBBenefit = 0.0;
            let fDeliverHBFee = 0.0; let fDeliverHBCost = 0.0; let fDeliverHBBenefit = 0.0;
            let fPickupHBFee = 0.0; let fPickupHBCost = 0.0; let fPickupHBBenefit = 0.0;
            for (let i = 0; i < this.arrHouseBill.length; i++) {
                // 仓库
                if (this.arrHouseBill[i].on_plate_active === 1) {
                    if (this.arrHouseBill[i].on_plate_fee === null || this.arrHouseBill[i].on_plate_fee === undefined || this.arrHouseBill[i].on_plate_fee === "")
                        this.arrHouseBill[i].on_plate_fee = 0.0;
                    if (this.arrHouseBill[i].on_plate_cost === null || this.arrHouseBill[i].on_plate_cost === undefined || this.arrHouseBill[i].on_plate_cost === "")
                        this.arrHouseBill[i].on_plate_cost = 0.0;
                    fStorageHBFee += parseFloat(this.arrHouseBill[i].on_plate_fee);
                    fStorageHBCost += parseFloat(this.arrHouseBill[i].on_plate_cost);
                }
                if (this.arrHouseBill[i].tag_active === 1) {
                    if (this.arrHouseBill[i].tag_fee === null || this.arrHouseBill[i].tag_fee === undefined || this.arrHouseBill[i].tag_fee === "")
                        this.arrHouseBill[i].tag_fee = 0.0;
                    if (this.arrHouseBill[i].tag_cost === null || this.arrHouseBill[i].tag_cost === undefined || this.arrHouseBill[i].tag_cost === "")
                        this.arrHouseBill[i].tag_cost = 0.0;
                    fStorageHBFee += parseFloat(this.arrHouseBill[i].tag_fee);
                    fStorageHBCost += parseFloat(this.arrHouseBill[i].tag_cost);
                }
                if (this.arrHouseBill[i].oversize_active === 1) {
                    if (this.arrHouseBill[i].oversize_fee === null || this.arrHouseBill[i].oversize_fee === undefined || this.arrHouseBill[i].oversize_fee === "")
                        this.arrHouseBill[i].oversize_fee = 0.0;
                    if (this.arrHouseBill[i].oversize_cost === null || this.arrHouseBill[i].oversize_cost === undefined || this.arrHouseBill[i].oversize_cost === "")
                        this.arrHouseBill[i].oversize_cost = 0.0;
                    fStorageHBFee += parseFloat(this.arrHouseBill[i].oversize_fee);
                    fStorageHBCost += parseFloat(this.arrHouseBill[i].oversize_cost);
                }
                if (this.arrHouseBill[i].storage_export_active === 1) {
                    if (this.arrHouseBill[i].storage_export_fee === null || this.arrHouseBill[i].storage_export_fee === undefined || this.arrHouseBill[i].storage_export_fee === "")
                        this.arrHouseBill[i].storage_export_fee = 0.0;
                    if (this.arrHouseBill[i].storage_export_cost === null || this.arrHouseBill[i].storage_export_cost === undefined || this.arrHouseBill[i].storage_export_cost === "")
                        this.arrHouseBill[i].storage_export_cost = 0.0;
                    fStorageHBFee += parseFloat(this.arrHouseBill[i].storage_export_fee);
                    fStorageHBCost += parseFloat(this.arrHouseBill[i].storage_export_cost);
                }
                if (this.arrHouseBill[i].storage_active === 1) {
                    if (this.arrHouseBill[i].storage_fee === null || this.arrHouseBill[i].storage_fee === undefined || this.arrHouseBill[i].storage_fee === "")
                        this.arrHouseBill[i].storage_fee = 0.0;
                    if (this.arrHouseBill[i].storage_cost === null || this.arrHouseBill[i].storage_cost === undefined || this.arrHouseBill[i].storage_cost === "")
                        this.arrHouseBill[i].storage_cost = 0.0;
                    fStorageHBFee += parseFloat(this.arrHouseBill[i].storage_fee);
                    fStorageHBCost += parseFloat(this.arrHouseBill[i].storage_cost);
                }
                if (this.arrHouseBill[i].storage_other_active === 1) {
                    if (this.arrHouseBill[i].storage_other_fee === null || this.arrHouseBill[i].storage_other_fee === undefined || this.arrHouseBill[i].storage_other_fee === "")
                        this.arrHouseBill[i].storage_other_fee = 0.0;
                    if (this.arrHouseBill[i].storage_other_cost === null || this.arrHouseBill[i].storage_other_cost === undefined || this.arrHouseBill[i].storage_other_cost === "")
                        this.arrHouseBill[i].storage_other_cost = 0.0;
                    fStorageHBFee += parseFloat(this.arrHouseBill[i].storage_other_fee);
                    fStorageHBCost += parseFloat(this.arrHouseBill[i].storage_other_cost);
                }
                // 清关
                if (this.arrHouseBill[i].custom_fee === null || this.arrHouseBill[i].custom_fee === undefined || this.arrHouseBill[i].custom_fee === "")
                    this.arrHouseBill[i].custom_fee = 0.0;
                if (this.arrHouseBill[i].custom_cost === null || this.arrHouseBill[i].custom_cost === undefined || this.arrHouseBill[i].custom_cost === "")
                    this.arrHouseBill[i].custom_cost = 0.0;
                if (this.arrHouseBill[i].tax_fee === null || this.arrHouseBill[i].tax_fee === undefined || this.arrHouseBill[i].tax_fee === "")
                    this.arrHouseBill[i].tax_fee = 0.0;
                if (this.arrHouseBill[i].tax_cost === null || this.arrHouseBill[i].tax_cost === undefined || this.arrHouseBill[i].tax_cost === "")
                    this.arrHouseBill[i].tax_cost = 0.0;
                if (this.arrHouseBill[i].gst_fee === null || this.arrHouseBill[i].gst_fee === undefined || this.arrHouseBill[i].gst_fee === "")
                    this.arrHouseBill[i].gst_fee = 0.0;
                if (this.arrHouseBill[i].gst_cost === null || this.arrHouseBill[i].gst_cost === undefined || this.arrHouseBill[i].gst_cost === "")
                    this.arrHouseBill[i].gst_cost = 0.0;
                fCustomHBFee += parseFloat(this.arrHouseBill[i].custom_fee);
                fCustomHBCost += parseFloat(this.arrHouseBill[i].custom_cost);
                fCustomHBFee += parseFloat(this.arrHouseBill[i].tax_fee);
                fCustomHBCost += parseFloat(this.arrHouseBill[i].tax_cost);
                fCustomHBFee += parseFloat(this.arrHouseBill[i].gst_fee);
                fCustomHBCost += parseFloat(this.arrHouseBill[i].gst_cost);
                if (this.arrHouseBill[i].overitem_active === 1) {
                    if (this.arrHouseBill[i].overitem_fee === null || this.arrHouseBill[i].overitem_fee === undefined || this.arrHouseBill[i].overitem_fee === "")
                        this.arrHouseBill[i].overitem_fee = 0.0;
                    if (this.arrHouseBill[i].overitem_cost === null || this.arrHouseBill[i].overitem_cost === undefined || this.arrHouseBill[i].overitem_cost === "")
                        this.arrHouseBill[i].overitem_cost = 0.0;
                    fCustomHBFee += parseFloat(this.arrHouseBill[i].overitem_fee);
                    fCustomHBCost += parseFloat(this.arrHouseBill[i].overitem_cost);
                }
                if (this.arrHouseBill[i].cfia_active === 1) {
                    if (this.arrHouseBill[i].cfia_fee === null || this.arrHouseBill[i].cfia_fee === undefined || this.arrHouseBill[i].cfia_fee === "")
                        this.arrHouseBill[i].cfia_fee = 0.0;
                    if (this.arrHouseBill[i].cfia_cost === null || this.arrHouseBill[i].cfia_cost === undefined || this.arrHouseBill[i].cfia_cost === "")
                        this.arrHouseBill[i].cfia_cost = 0.0;
                    fCustomHBFee += parseFloat(this.arrHouseBill[i].cfia_fee);
                    fCustomHBCost += parseFloat(this.arrHouseBill[i].cfia_cost);
                }
                if (this.arrHouseBill[i].check_active === 1) {
                    if (this.arrHouseBill[i].check_fee === null || this.arrHouseBill[i].check_fee === undefined || this.arrHouseBill[i].check_fee === "")
                        this.arrHouseBill[i].check_fee = 0.0;
                    if (this.arrHouseBill[i].check_cost === null || this.arrHouseBill[i].check_cost === undefined || this.arrHouseBill[i].check_cost === "")
                        this.arrHouseBill[i].check_cost = 0.0;
                    fCustomHBFee += parseFloat(this.arrHouseBill[i].check_fee);
                    fCustomHBCost += parseFloat(this.arrHouseBill[i].check_cost);
                }
                if (this.arrHouseBill[i].hb_other_active === 1) {
                    if (this.arrHouseBill[i].storage_fee === null || this.arrHouseBill[i].storage_fee === undefined || this.arrHouseBill[i].storage_fee === "")
                        this.arrHouseBill[i].storage_fee = 0.0;
                    if (this.arrHouseBill[i].storage_cost === null || this.arrHouseBill[i].storage_cost === undefined || this.arrHouseBill[i].storage_cost === "")
                        this.arrHouseBill[i].storage_cost = 0.0;
                    fCustomHBFee += parseFloat(this.arrHouseBill[i].hb_other_fee);
                    fCustomHBCost += parseFloat(this.arrHouseBill[i].hb_other_cost);
                }
                if (this.arrHouseBill[i].transit_fee === null || this.arrHouseBill[i].transit_fee === undefined || this.arrHouseBill[i].transit_fee === "")
                    this.arrHouseBill[i].transit_fee = 0.0;
                if (this.arrHouseBill[i].transit_cost === null || this.arrHouseBill[i].transit_cost === undefined || this.arrHouseBill[i].transit_cost === "")
                    this.arrHouseBill[i].transit_cost = 0.0;
                if (this.arrHouseBill[i].deliver_fee === null || this.arrHouseBill[i].deliver_fee === undefined || this.arrHouseBill[i].deliver_fee === "")
                    this.arrHouseBill[i].deliver_fee = 0.0;
                if (this.arrHouseBill[i].deliver_cost === null || this.arrHouseBill[i].deliver_cost === undefined || this.arrHouseBill[i].deliver_cost === "")
                    this.arrHouseBill[i].deliver_cost = 0.0;
                if (this.arrHouseBill[i].storage_export45_fee === null || this.arrHouseBill[i].storage_export45_fee === undefined ||
                    this.arrHouseBill[i].storage_export45_fee === "")
                    this.arrHouseBill[i].storage_export45_fee = 0.0;
                if (this.arrHouseBill[i].storage_export45_cost === null || this.arrHouseBill[i].storage_export45_cost === undefined ||
                    this.arrHouseBill[i].storage_export45_cost === "")
                    this.arrHouseBill[i].storage_export45_cost = 0.0;
                // 换单
                if (this.arrHouseBill[i].path === 0) {

                    fTransitHBFee += parseFloat(this.arrHouseBill[i].transit_fee);
                    fTransitHBCost += parseFloat(this.arrHouseBill[i].transit_cost);
                    fTransitHBFee += parseFloat(this.arrHouseBill[i].deliver_fee);
                    fTransitHBCost += parseFloat(this.arrHouseBill[i].deliver_cost);
                    fTransitHBFee += parseFloat(this.arrHouseBill[i].storage_export45_fee);
                    fTransitHBCost += parseFloat(this.arrHouseBill[i].storage_export45_cost);
                    if (this.arrHouseBill[i].on_plate45_active === 1) {
                        if (this.arrHouseBill[i].on_plate45_fee === null || this.arrHouseBill[i].on_plate45_fee === undefined || this.arrHouseBill[i].on_plate45_fee === "")
                            this.arrHouseBill[i].on_plate45_fee = 0.0;
                        if (this.arrHouseBill[i].on_plate45_cost === null || this.arrHouseBill[i].on_plate45_cost === undefined || this.arrHouseBill[i].on_plate45_cost === "")
                            this.arrHouseBill[i].on_plate45_cost = 0.0;
                        fTransitHBFee += parseFloat(this.arrHouseBill[i].on_plate45_fee);
                        fTransitHBCost += parseFloat(this.arrHouseBill[i].on_plate45_cost);
                    }
                    if (this.arrHouseBill[i].waiting_active === 1) {
                        if (this.arrHouseBill[i].waiting_fee === null || this.arrHouseBill[i].waiting_fee === undefined || this.arrHouseBill[i].waiting_fee === "")
                            this.arrHouseBill[i].waiting_fee = 0.0;
                        if (this.arrHouseBill[i].waiting_cost === null || this.arrHouseBill[i].waiting_cost === undefined || this.arrHouseBill[i].waiting_cost === "")
                            this.arrHouseBill[i].waiting_cost = 0.0;
                        fTransitHBFee += parseFloat(this.arrHouseBill[i].waiting_fee);
                        fTransitHBCost += parseFloat(this.arrHouseBill[i].waiting_cost);
                    }
                    if (this.arrHouseBill[i].hb_other45_active === 1) {
                        if (this.arrHouseBill[i].hb_other45_fee === null || this.arrHouseBill[i].hb_other45_fee === undefined || this.arrHouseBill[i].hb_other45_fee === "")
                            this.arrHouseBill[i].hb_other45_fee = 0.0;
                        if (this.arrHouseBill[i].hb_other45_cost === null || this.arrHouseBill[i].hb_other45_cost === undefined || this.arrHouseBill[i].hb_other45_cost === "")
                            this.arrHouseBill[i].hb_other45_cost = 0.0;
                        fTransitHBFee += parseFloat(this.arrHouseBill[i].hb_other45_fee);
                        fTransitHBCost += parseFloat(this.arrHouseBill[i].hb_other45_cost);
                    }
                }
                // 派送
                if (this.arrHouseBill[i].path === 1) {
                    fDeliverHBFee += parseFloat(this.arrHouseBill[i].deliver_fee);
                    fDeliverHBCost += parseFloat(this.arrHouseBill[i].deliver_cost);
                    fDeliverHBFee += parseFloat(this.arrHouseBill[i].storage_export45_fee);
                    fDeliverHBCost += parseFloat(this.arrHouseBill[i].storage_export45_cost);
                    if (this.arrHouseBill[i].on_plate45_active === 1) {
                        if (this.arrHouseBill[i].on_plate45_fee === null || this.arrHouseBill[i].on_plate45_fee === undefined || this.arrHouseBill[i].on_plate45_fee === "")
                            this.arrHouseBill[i].on_plate45_fee = 0.0;
                        if (this.arrHouseBill[i].on_plate45_cost === null || this.arrHouseBill[i].on_plate45_cost === undefined || this.arrHouseBill[i].on_plate45_cost === "")
                            this.arrHouseBill[i].on_plate45_cost = 0.0;
                        fDeliverHBFee += parseFloat(this.arrHouseBill[i].on_plate45_fee);
                        fDeliverHBCost += parseFloat(this.arrHouseBill[i].on_plate45_cost);
                    }
                    if (this.arrHouseBill[i].waiting_active === 1) {
                        if (this.arrHouseBill[i].waiting_fee === null || this.arrHouseBill[i].waiting_fee === undefined || this.arrHouseBill[i].waiting_fee === "")
                            this.arrHouseBill[i].waiting_fee = 0.0;
                        if (this.arrHouseBill[i].waiting_cost === null || this.arrHouseBill[i].waiting_cost === undefined || this.arrHouseBill[i].waiting_cost === "")
                            this.arrHouseBill[i].waiting_cost = 0.0;
                        fDeliverHBFee += parseFloat(this.arrHouseBill[i].waiting_fee);
                        fDeliverHBCost += parseFloat(this.arrHouseBill[i].waiting_cost);
                    }
                    if (this.arrHouseBill[i].hb_other45_active === 1) {
                        if (this.arrHouseBill[i].hb_other45_fee === null || this.arrHouseBill[i].hb_other45_fee === undefined || this.arrHouseBill[i].hb_other45_fee === "")
                            this.arrHouseBill[i].hb_other45_fee = 0.0;
                        if (this.arrHouseBill[i].hb_other45_cost === null || this.arrHouseBill[i].hb_other45_cost === undefined || this.arrHouseBill[i].hb_other45_cost === "")
                            this.arrHouseBill[i].hb_other45_cost = 0.0;
                        fDeliverHBFee += parseFloat(this.arrHouseBill[i].hb_other45_fee);
                        fDeliverHBCost += parseFloat(this.arrHouseBill[i].hb_other45_cost);
                    }
                }
                // 自提
                if (this.arrHouseBill[i].path === 2) {
                    fPickupHBFee += parseFloat(this.arrHouseBill[i].storage_export45_fee);
                    fPickupHBCost += parseFloat(this.arrHouseBill[i].storage_export45_cost);
                    if (this.arrHouseBill[i].on_plate45_active === 1) {
                        if (this.arrHouseBill[i].on_plate45_fee === null || this.arrHouseBill[i].on_plate45_fee === undefined || this.arrHouseBill[i].on_plate45_fee === "")
                            this.arrHouseBill[i].on_plate45_fee = 0.0;
                        if (this.arrHouseBill[i].on_plate45_cost === null || this.arrHouseBill[i].on_plate45_cost === undefined ||
                            this.arrHouseBill[i].on_plate45_cost === "")
                            this.arrHouseBill[i].on_plate45_cost = 0.0;
                        fPickupHBFee += parseFloat(this.arrHouseBill[i].on_plate45_fee);
                        fPickupHBCost += parseFloat(this.arrHouseBill[i].on_plate45_cost);
                    }
                    if (this.arrHouseBill[i].waiting_active === 1) {
                        if (this.arrHouseBill[i].waiting_fee === null || this.arrHouseBill[i].waiting_fee === undefined || this.arrHouseBill[i].waiting_fee === "")
                            this.arrHouseBill[i].waiting_fee = 0.0;
                        if (this.arrHouseBill[i].waiting_cost === null || this.arrHouseBill[i].waiting_cost === undefined || this.arrHouseBill[i].waiting_cost === "")
                            this.arrHouseBill[i].waiting_cost = 0.0;
                        fPickupHBFee += parseFloat(this.arrHouseBill[i].waiting_fee);
                        fPickupHBCost += parseFloat(this.arrHouseBill[i].waiting_cost);
                    }
                    if (this.arrHouseBill[i].hb_other45_active === 1) {
                        if (this.arrHouseBill[i].hb_other45_fee === null || this.arrHouseBill[i].hb_other45_fee === undefined || this.arrHouseBill[i].hb_other45_fee === "")
                            this.arrHouseBill[i].hb_other45_fee = 0.0;
                        if (this.arrHouseBill[i].hb_other45_cost === null || this.arrHouseBill[i].hb_other45_cost === undefined || this.arrHouseBill[i].hb_other45_cost === "")
                            this.arrHouseBill[i].hb_other45_cost = 0.0;
                        fPickupHBFee += parseFloat(this.arrHouseBill[i].hb_other45_fee);
                        fPickupHBCost += parseFloat(this.arrHouseBill[i].hb_other45_cost);
                    }
                }
            }
            // 仓库
            fStorageHBBenefit = fStorageHBFee - fStorageHBCost;
            if (typeof (fStorageHBFee) === 'number')
                fStorageHBFee = fStorageHBFee.toFixed(2);
            if (typeof (fStorageHBCost) === 'number')
                fStorageHBCost = fStorageHBCost.toFixed(2);
            if (typeof (fStorageHBBenefit) === 'number')
                fStorageHBBenefit = fStorageHBBenefit.toFixed(2);
            objOrder.storage_hb_total_fee = fStorageHBFee;
            objOrder.storage_hb_total_cost = fStorageHBCost;
            objOrder.storage_hb_total_benefit = fStorageHBBenefit;
            objOrder.storage_total_fee += parseFloat(fStorageHBFee);
            objOrder.storage_total_cost += parseFloat(fStorageHBCost);
            objOrder.storage_total_benefit = objOrder.storage_total_fee - objOrder.storage_total_cost;
            if (typeof (objOrder.storage_total_fee) === 'number')
                objOrder.storage_total_fee = objOrder.storage_total_fee.toFixed(2);
            if (typeof (objOrder.storage_total_cost) === 'number')
                objOrder.storage_total_cost = objOrder.storage_total_cost.toFixed(2);
            if (typeof (objOrder.storage_total_benefit) === 'number')
                objOrder.storage_total_benefit = objOrder.storage_total_benefit.toFixed(2);
            // 清关
            fCustomHBBenefit = fCustomHBFee - fCustomHBCost;
            if (typeof (fCustomHBFee) === 'number')
                fCustomHBFee = fCustomHBFee.toFixed(2);
            if (typeof (fCustomHBCost) === 'number')
                fCustomHBCost = fCustomHBCost.toFixed(2);
            if (typeof (fCustomHBBenefit) === 'number')
                fCustomHBBenefit = fCustomHBBenefit.toFixed(2);
            objOrder.custom_hb_total_fee = fCustomHBFee;
            objOrder.custom_hb_total_cost = fCustomHBCost;
            objOrder.custom_hb_total_benefit = fCustomHBBenefit;
            objOrder.custom_total_fee = parseFloat(objOrder.custom_total_fee) + parseFloat(fCustomHBFee);
            objOrder.custom_total_cost = parseFloat(objOrder.custom_total_cost) + parseFloat(fCustomHBCost);
            objOrder.custom_total_benefit = objOrder.custom_total_fee - objOrder.custom_total_cost;
            if (typeof (objOrder.custom_total_fee) === 'number')
                objOrder.custom_total_fee = objOrder.custom_total_fee.toFixed(2);
            if (typeof (objOrder.custom_total_cost) === 'number')
                objOrder.custom_total_cost = objOrder.custom_total_cost.toFixed(2);
            if (typeof (objOrder.custom_total_benefit) === 'number')
                objOrder.custom_total_benefit = objOrder.custom_total_benefit.toFixed(2);
            // 换单
            fTransitHBBenefit = fTransitHBFee - fTransitHBCost;
            if (typeof (fTransitHBFee) === 'number')
                fTransitHBFee = fTransitHBFee.toFixed(2);
            if (typeof (fTransitHBCost) === 'number')
                fTransitHBCost = fTransitHBCost.toFixed(2);
            if (typeof (fTransitHBBenefit) === 'number')
                fTransitHBBenefit = fTransitHBBenefit.toFixed(2);
            objOrder.transit_total_fee = fTransitHBFee;
            objOrder.transit_total_cost = fTransitHBCost;
            objOrder.transit_total_benefit = fTransitHBBenefit;
            if (typeof (objOrder.transit_total_fee) === 'number')
                objOrder.transit_total_fee = objOrder.transit_total_fee.toFixed(2);
            if (typeof (objOrder.transit_total_cost) === 'number')
                objOrder.transit_total_cost = objOrder.transit_total_cost.toFixed(2);
            if (typeof (objOrder.transit_total_benefit) === 'number')
                objOrder.transit_total_benefit = objOrder.transit_total_benefit.toFixed(2);
            // 派送
            fDeliverHBBenefit = fDeliverHBFee - fDeliverHBCost;
            if (typeof (fDeliverHBFee) === 'number')
                fDeliverHBFee = fDeliverHBFee.toFixed(2);
            if (typeof (fDeliverHBCost) === 'number')
                fDeliverHBCost = fDeliverHBCost.toFixed(2);
            if (typeof (fDeliverHBBenefit) === 'number')
                fDeliverHBBenefit = fDeliverHBBenefit.toFixed(2);
            objOrder.deliver_total_fee = fDeliverHBFee;
            objOrder.deliver_total_cost = fDeliverHBCost;
            objOrder.deliver_total_benefit = fDeliverHBBenefit;
            if (typeof (objOrder.deliver_total_fee) === 'number')
                objOrder.deliver_total_fee = objOrder.deliver_total_fee.toFixed(2);
            if (typeof (objOrder.deliver_total_cost) === 'number')
                objOrder.deliver_total_cost = objOrder.deliver_total_cost.toFixed(2);
            if (typeof (objOrder.deliver_total_benefit) === 'number')
                objOrder.deliver_total_benefit = objOrder.deliver_total_benefit.toFixed(2);
            // 自提
            fPickupHBBenefit = fPickupHBFee - fPickupHBCost;
            if (typeof (fPickupHBFee) === 'number')
                fPickupHBFee = fPickupHBFee.toFixed(2);
            if (typeof (fPickupHBCost) === 'number')
                fPickupHBCost = fPickupHBCost.toFixed(2);
            if (typeof (fPickupHBBenefit) === 'number')
                fPickupHBBenefit = fPickupHBBenefit.toFixed(2);
            objOrder.pickup_total_fee = fPickupHBFee;
            objOrder.pickup_total_cost = fPickupHBCost;
            objOrder.pickup_total_benefit = fPickupHBBenefit;
            if (typeof (objOrder.pickup_total_fee) === 'number')
                objOrder.pickup_total_fee = objOrder.pickup_total_fee.toFixed(2);
            if (typeof (objOrder.pickup_total_cost) === 'number')
                objOrder.pickup_total_cost = objOrder.pickup_total_cost.toFixed(2);
            if (typeof (objOrder.pickup_total_benefit) === 'number')
                objOrder.pickup_total_benefit = objOrder.pickup_total_benefit.toFixed(2);

            // 费用总计
            objOrder.mb_total_fee = 0.0;
            objOrder.mb_total_fee += parseFloat(objOrder.ship_total_fee) + parseFloat(objOrder.port_total_fee) + parseFloat(objOrder.storage_total_fee) +
                parseFloat(objOrder.custom_total_fee) + parseFloat(objOrder.transit_total_fee) + parseFloat(objOrder.deliver_total_fee) +
                parseFloat(objOrder.pickup_total_fee);
            objOrder.mb_total_cost = 0.0;
            objOrder.mb_total_cost += parseFloat(objOrder.ship_total_cost) + parseFloat(objOrder.port_total_cost) + parseFloat(objOrder.storage_total_cost) +
                parseFloat(objOrder.custom_total_cost) + parseFloat(objOrder.transit_total_cost) + parseFloat(objOrder.deliver_total_cost) +
                parseFloat(objOrder.pickup_total_cost);
            objOrder.mb_total_benefit = 0.0;
            objOrder.mb_total_benefit = objOrder.mb_total_fee - objOrder.mb_total_cost;
            if (typeof (objOrder.mb_total_fee) === 'number')
                objOrder.mb_total_fee = objOrder.mb_total_fee.toFixed(2);
            if (typeof (objOrder.mb_total_cost) === 'number')
                objOrder.mb_total_cost = objOrder.mb_total_cost.toFixed(2);
            if (typeof (objOrder.mb_total_benefit) === 'number')
                objOrder.mb_total_benefit = objOrder.mb_total_benefit.toFixed(2);
        }
        this.setState({
            ...this.state,
            iDlgTab: iTab
        })
    }
    // 到港Release切换
    PortReleaseSwitch = (iIndex, bChecked) => {
        let arrPortRelease = this.state.arrPortRelease;
        arrPortRelease[iIndex] = 0;
        if (bChecked)
            arrPortRelease[iIndex] = 1;
        this.setState({
            ...this.state,
            arrPortRelease: arrPortRelease
        });
    }
    // 获取拖车数据
    GetTrucksData() {
        axios('/GetTrucks', { params: { store_id: this.props.objStoreInfo.id } })
            .then(({ data }) => {
                global.arrTruckData = data;
            }).catch(function (error) { console.log(error); });
    }
    // 选择仓库
    SelectTruck = () => {
        if (this.state.bDlgShow)
            this.setState({
                ...this.state,
                bSelectTruckDlg: true
            });
        else
            this.setState({
                ...this.state,
                bSelectTruckFilterDlg: true
            });
    }
    // 选择仓库弹窗结果
    TruckSelectResult = (index) => {
        if (this.state.bDlgShow) {
            if (index >= 0) {
                if (global.arrTruckData[index].name === null || global.arrTruckData[index].name === undefined)
                    global.arrTruckData[index].name = "";
                if (global.arrTruckData[index].phone === null || global.arrTruckData[index].phone === undefined)
                    global.arrTruckData[index].phone = "";
                if (global.arrTruckData[index].email === null || global.arrTruckData[index].email === undefined)
                    global.arrTruckData[index].email = "";
                if (global.arrTruckData[index].address === null || global.arrTruckData[index].address === undefined)
                    global.arrTruckData[index].address = "";
                this.refs.truck.state.value = global.arrTruckData[index].name;// + " " + global.arrTruckData[index].phone + " " + global.arrTruckData[index].email +
                // " " + global.arrTruckData[index].address;
                this.iCurrentTruck = index;//global.arrTruckData[index].id;
            }
        }
        this.setState({
            ...this.state,
            bSelectTruckDlg: false
        })
    }
    // 获取仓库数据
    GetStoragesData() {
        axios('/GetLogisticStorages', { params: { store_id: this.props.objStoreInfo.id } })
            .then(({ data }) => {
                global.arrStorageData = data;
            }).catch(function (error) { console.log(error); });
    }
    // 选择仓库
    SelectStorage = () => {
        if (this.state.bDlgShow)
            this.setState({
                ...this.state,
                bSelectStorageDlg: true
            });
        else
            this.setState({
                ...this.state,
                bSelectStorageFilterDlg: true
            });
    }
    // 选择仓库弹窗结果
    StorageSelectResult = (index) => {
        if (this.state.bDlgShow) {
            if (index >= 0) {
                if (global.arrStorageData[index].name === null || global.arrStorageData[index].name === undefined)
                    global.arrStorageData[index].name = "";
                if (global.arrStorageData[index].phone === null || global.arrStorageData[index].phone === undefined)
                    global.arrStorageData[index].phone = "";
                if (global.arrStorageData[index].email === null || global.arrStorageData[index].email === undefined)
                    global.arrStorageData[index].email = "";
                if (global.arrStorageData[index].address === null || global.arrStorageData[index].address === undefined)
                    global.arrStorageData[index].address = "";
                this.refs.storage.state.value = global.arrStorageData[index].name;// + " " + global.arrStorageData[index].phone + " " + global.arrStorageData[index].email +
                // " " + global.arrStorageData[index].address;
                this.iCurrentStorage = global.arrStorageData[index].id;
            }
        }
        this.setState({
            ...this.state,
            bSelectStorageDlg: false,
            bSelectStorageFilterDlg: false
        })
    }
    // 获取车队数据
    GetCarTeamsData() {
        axios('/GetCarTeams', { params: { store_id: this.props.objStoreInfo.id } })
            .then(({ data }) => {
                global.arrCarTeamData = data;
            }).catch(function (error) { console.log(error); });
    }
    // 选择代理
    SelectCarTeam = () => {
        this.setState({
            ...this.state,
            bSelectCarTeamDlg: true
        });
    }
    // 选择仓库弹窗结果
    CarTeamSelectResult = (index) => {
        if (index >= 0) {
            if (global.arrCarTeamData[index].name === null || global.arrCarTeamData[index].name === undefined)
                global.arrCarTeamData[index].name = "";
            if (global.arrCarTeamData[index].phone === null || global.arrCarTeamData[index].phone === undefined)
                global.arrCarTeamData[index].phone = "";
            if (global.arrCarTeamData[index].email === null || global.arrCarTeamData[index].email === undefined)
                global.arrCarTeamData[index].email = "";
            if (global.arrCarTeamData[index].address === null || global.arrCarTeamData[index].address === undefined)
                global.arrCarTeamData[index].address = "";
            this.refs.carteam.state.value = global.arrCarTeamData[index].name;// + " " + global.arrCarTeamData[index].phone + " " + global.arrCarTeamData[index].email +
            // " " + global.arrCarTeamData[index].address;
            this.iCurrentCarTeam = global.arrCarTeamData[index].id;
        }
        this.setState({
            ...this.state,
            bSelectCarTeamDlg: false
        })
    }
    // 开船收费信息变化
    ShipFeeInfoChange = (objOrder, index, e) => {
        // 对输入内容的处理
        let strInput = e.target.value;
        if (strInput.indexOf(' ') >= 0) {
            strInput = strInput.replaceAll(' ', '')
        }
        if (strInput.indexOf(',') >= 0) {
            strInput = strInput.replaceAll(',', '')
        }
        if (strInput.indexOf('，') >= 0) {
            strInput = strInput.replaceAll('，', '')
        }
        if (strInput === '' || strInput === null || strInput === undefined)
            strInput = 0.00;

        let fResult = 0.00;
        // 客户端
        if (this.props.iUserLevel < 5) {
            if (index === 0) {
                this.refs.agent_fee.state.value = strInput;
                objOrder.agent_fee = this.refs.agent_fee.state.value;
            }
            if (index === 2) {
                this.refs.ship_fee.state.value = strInput;
                objOrder.ship_fee = this.refs.ship_fee.state.value;
            }
            if (index === 4) {
                this.refs.port_fee.state.value = strInput;
                objOrder.port_fee = this.refs.port_fee.state.value;
            }
            if (index === 6) {
                this.refs.ship_other_fee.state.value = strInput;
                objOrder.ship_other_fee = this.refs.ship_other_fee.state.value;
            }
        }
        // 内部
        else {
            if (index === 0) {
                this.refs.agent_fee.state.value = strInput;
                objOrder.agent_fee = this.refs.agent_fee.state.value;
                fResult = parseFloat(this.refs.agent_fee.state.value) - parseFloat(this.refs.agent_cost.state.value);
                if (typeof (fResult) === 'number') {
                    fResult = fResult.toFixed(2);
                }
                this.refs.agent_benefit.state.value = fResult;
            }
            if (index === 1) {
                this.refs.agent_cost.state.value = strInput;
                objOrder.agent_cost = this.refs.agent_cost.state.value;
                fResult = parseFloat(this.refs.agent_fee.state.value) - parseFloat(this.refs.agent_cost.state.value);
                if (typeof (fResult) === 'number')
                    fResult = fResult.toFixed(2);
                this.refs.agent_benefit.state.value = fResult;
            }
            if (index === 2) {
                this.refs.ship_fee.state.value = strInput;
                objOrder.ship_fee = this.refs.ship_fee.state.value;
                fResult = parseFloat(this.refs.ship_fee.state.value) - parseFloat(this.refs.ship_cost.state.value);
                if (typeof (fResult) === 'number')
                    fResult = fResult.toFixed(2);
                this.refs.ship_benefit.state.value = fResult;
            }
            if (index === 3) {
                this.refs.ship_cost.state.value = strInput;
                objOrder.ship_cost = this.refs.ship_cost.state.value;
                fResult = parseFloat(this.refs.ship_fee.state.value) - parseFloat(this.refs.ship_cost.state.value);
                if (typeof (fResult) === 'number')
                    fResult = fResult.toFixed(2);
                this.refs.ship_benefit.state.value = fResult;
            }
            if (index === 4) {
                this.refs.port_fee.state.value = strInput;
                objOrder.port_fee = this.refs.port_fee.state.value;
                fResult = parseFloat(this.refs.port_fee.state.value) - parseFloat(this.refs.port_cost.state.value);
                if (typeof (fResult) === 'number')
                    fResult = fResult.toFixed(2);
                this.refs.port_benefit.state.value = fResult;
            }
            if (index === 5) {
                this.refs.port_cost.state.value = strInput;
                objOrder.port_cost = this.refs.port_cost.state.value;
                fResult = parseFloat(this.refs.port_fee.state.value) - parseFloat(this.refs.port_cost.state.value);
                if (typeof (fResult) === 'number')
                    fResult = fResult.toFixed(2);
                this.refs.port_benefit.state.value = fResult;
            }
            if (index === 6) {
                this.refs.ship_other_fee.state.value = strInput;
                objOrder.ship_other_fee = this.refs.ship_other_fee.state.value;
                fResult = parseFloat(this.refs.ship_other_fee.state.value) - parseFloat(this.refs.ship_other_cost.state.value);
                if (typeof (fResult) === 'number')
                    fResult = fResult.toFixed(2);
                this.refs.ship_other_benefit.state.value = fResult;
            }
            if (index === 7) {
                this.refs.ship_other_cost.state.value = strInput;
                objOrder.ship_other_cost = this.refs.ship_other_cost.state.value;
                fResult = parseFloat(this.refs.ship_other_fee.state.value) - parseFloat(this.refs.ship_other_cost.state.value);
                if (typeof (fResult) === 'number')
                    fResult = fResult.toFixed(2);
                this.refs.ship_other_benefit.state.value = fResult;
            }
            if (index >= 0)
                this.bFeeChange = true;
        }

        this.setState({
            ...this.state,
            bOrderInfoChange: true
        });
    }
    // 开船收费统计
    CalculateShipFee = (objOrder) => {

        let fShipTotalFee = parseFloat(objOrder.agent_fee) + parseFloat(objOrder.ship_fee) +
            parseFloat(objOrder.ship_other_fee) + parseFloat(objOrder.port_fee);
        if (typeof (fShipTotalFee) === 'number')
            fShipTotalFee = fShipTotalFee.toFixed(2);
        let fShipTotalCost = parseFloat(objOrder.agent_cost) + parseFloat(objOrder.ship_cost) +
            parseFloat(objOrder.ship_other_cost) + parseFloat(objOrder.port_cost);
        if (typeof (fShipTotalCost) === 'number')
            fShipTotalCost = fShipTotalCost.toFixed(2);
        let fShipTotalBenefit = parseFloat(fShipTotalFee) - parseFloat(fShipTotalCost);
        if (typeof (fShipTotalBenefit) === 'number')
            fShipTotalBenefit = fShipTotalBenefit.toFixed(2);

        objOrder.ship_total_fee = fShipTotalFee;
        objOrder.ship_total_cost = fShipTotalCost;
        objOrder.ship_total_benefit = fShipTotalBenefit;

        if (this.refs.ship_total_fee !== undefined) {
            this.refs.ship_total_fee.state.value = fShipTotalFee;
            if (this.props.iUserLevel >= 5) {
                this.refs.ship_total_cost.state.value = fShipTotalCost;
                this.refs.ship_total_benefit.state.value = fShipTotalBenefit;
            }
        }

        return objOrder;
    }
    // 到港收费信息变化
    PortFeeInfoChange = (objOrder, index, e) => {
        // 对输入内容的处理
        let strInput = e.target.value;
        if (strInput.indexOf(' ') >= 0) {
            strInput = strInput.replaceAll(' ', '')
        }
        if (strInput.indexOf(',') >= 0) {
            strInput = strInput.replaceAll(',', '')
        }
        if (strInput.indexOf('，') >= 0) {
            strInput = strInput.replaceAll('，', '')
        }
        if (strInput === '' || strInput === null || strInput === undefined)
            strInput = 0.00;


        let fResult = 0.00;

        if (index === 0) {
            this.refs.truck_fee.state.value = strInput;
            objOrder.truck_fee = this.refs.truck_fee.state.value;
            fResult = parseFloat(this.refs.truck_fee.state.value) - parseFloat(this.refs.truck_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.truck_benefit.state.value = fResult;
        }
        if (index === 1) {
            this.refs.truck_cost.state.value = strInput;
            objOrder.truck_cost = this.refs.truck_cost.state.value;
            fResult = parseFloat(this.refs.truck_fee.state.value) - parseFloat(this.refs.truck_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.truck_benefit.state.value = fResult;
        }
        if (index === 2) {
            this.refs.prepick_fee.state.value = strInput;
            objOrder.prepick_fee = this.refs.prepick_fee.state.value;
            fResult = parseFloat(this.refs.prepick_fee.state.value) - parseFloat(this.refs.prepick_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.prepick_benefit.state.value = fResult;
        }
        if (index === 3) {
            this.refs.prepick_cost.state.value = strInput;
            objOrder.prepick_cost = this.refs.prepick_cost.state.value;
            fResult = parseFloat(this.refs.prepick_fee.state.value) - parseFloat(this.refs.prepick_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.prepick_benefit.state.value = fResult;
        }
        if (index === 4) {
            this.refs.stack_fee.state.value = strInput;
            objOrder.stack_fee = this.refs.stack_fee.state.value;
            fResult = parseFloat(this.refs.stack_fee.state.value) - parseFloat(this.refs.stack_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.stack_benefit.state.value = fResult;
        }
        if (index === 5) {
            this.refs.stack_cost.state.value = strInput;
            objOrder.stack_cost = this.refs.stack_cost.state.value;
            fResult = parseFloat(this.refs.stack_fee.state.value) - parseFloat(this.refs.stack_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.stack_benefit.state.value = fResult;
        }
        if (index === 6) {
            this.refs.car_frame_fee.state.value = strInput;
            objOrder.car_frame_fee = this.refs.car_frame_fee.state.value;
            fResult = parseFloat(this.refs.car_frame_fee.state.value) - parseFloat(this.refs.car_frame_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.car_frame_benefit.state.value = fResult;
        }
        if (index === 7) {
            this.refs.car_frame_cost.state.value = strInput;
            objOrder.car_frame_cost = this.refs.car_frame_cost.state.value;
            fResult = parseFloat(this.refs.car_frame_fee.state.value) - parseFloat(this.refs.car_frame_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.car_frame_benefit.state.value = fResult;
        }

        if (index === 8) {
            this.refs.danger_fee.state.value = strInput;
            objOrder.danger_fee = this.refs.danger_fee.state.value;
            fResult = parseFloat(this.refs.danger_fee.state.value) - parseFloat(this.refs.danger_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.danger_benefit.state.value = fResult;
        }
        if (index === 9) {
            this.refs.danger_cost.state.value = strInput;
            objOrder.danger_cost = this.refs.danger_cost.state.value;
            fResult = parseFloat(this.refs.danger_fee.state.value) - parseFloat(this.refs.danger_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.danger_benefit.state.value = fResult;
        }
        if (index === 10) {
            this.refs.null_fee.state.value = strInput;
            objOrder.null_fee = this.refs.null_fee.state.value;
            fResult = parseFloat(this.refs.null_fee.state.value) - parseFloat(this.refs.null_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.null_benefit.state.value = fResult;
        }
        if (index === 11) {
            this.refs.null_cost.state.value = strInput;
            objOrder.null_cost = this.refs.null_cost.state.value;
            fResult = parseFloat(this.refs.null_fee.state.value) - parseFloat(this.refs.null_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.null_benefit.state.value = fResult;
        }
        if (index === 12) {
            this.refs.port_other_fee.state.value = strInput;
            objOrder.port_other_fee = this.refs.port_other_fee.state.value;
            fResult = parseFloat(this.refs.port_other_fee.state.value) - parseFloat(this.refs.port_other_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.port_other_benefit.state.value = fResult;
        }
        if (index === 13) {
            this.refs.port_other_cost.state.value = strInput;
            objOrder.port_other_cost = this.refs.port_other_cost.state.value;
            fResult = parseFloat(this.refs.port_other_fee.state.value) - parseFloat(this.refs.port_other_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.port_other_benefit.state.value = fResult;
        }

        this.bFeeChange = true;

        this.setState({
            ...this.state,
            bOrderInfoChange: true
        })
    }
    // 到港收费统计
    CalculatePortFee = (objOrder) => {
        let fPortTotalFee = parseFloat(objOrder.truck_fee) + parseFloat(objOrder.prepick_fee) +
            parseFloat(objOrder.stack_fee) + parseFloat(objOrder.car_frame_fee) + parseFloat(objOrder.danger_fee) +
            parseFloat(objOrder.null_fee) + parseFloat(objOrder.port_other_fee);
        let fPortTotalCost = parseFloat(objOrder.truck_cost) + parseFloat(objOrder.prepick_cost) +
            parseFloat(objOrder.stack_cost) + parseFloat(objOrder.car_frame_cost) +
            parseFloat(objOrder.danger_cost) + parseFloat(objOrder.null_cost) + parseFloat(objOrder.port_other_cost);
        let fPortTotalBenefit = parseFloat(fPortTotalFee) - parseFloat(fPortTotalCost);
        if (typeof (fPortTotalFee) === 'number')
            fPortTotalFee = fPortTotalFee.toFixed(2);
        if (typeof (fPortTotalCost) === 'number')
            fPortTotalCost = fPortTotalCost.toFixed(2);
        if (typeof (fPortTotalBenefit) === 'number')
            fPortTotalBenefit = fPortTotalBenefit.toFixed(2);

        objOrder.port_total_fee = fPortTotalFee;
        objOrder.port_total_cost = fPortTotalCost;
        objOrder.port_total_benefit = fPortTotalBenefit;

        if (this.refs.port_total_fee !== undefined) {
            this.refs.port_total_fee.state.value = fPortTotalFee;
            if (this.props.iUserLevel >= 5) {
                this.refs.port_total_cost.state.value = fPortTotalCost;
                this.refs.port_total_benefit.state.value = fPortTotalBenefit;
            }

        }

        return objOrder;
    }
    // 币种变更
    CurrencyChange = (index) => {
        this.setState({
            ...this.state,
            iCurrency: index
        })
    }
    // 切换下一步流程
    SwitchNextStep = (e) => {
        this.setState({
            ...this.state,
            iStorageCustom: e.target.value,
        })
    }
    // 切换路径
    SwitchPath = (e) => {
        this.setState({
            ...this.state,
            iPath: e.target.value,
        })
    }
    // 仓储收费信息变化
    StorageFeeInfoChange = (objOrder, index, e) => {
        // 对输入内容的处理
        let strInput = e.target.value;
        if (strInput.indexOf(' ') >= 0) {
            strInput = strInput.replaceAll(' ', '')
        }
        if (strInput.indexOf(',') >= 0) {
            strInput = strInput.replaceAll(',', '')
        }
        if (strInput.indexOf('，') >= 0) {
            strInput = strInput.replaceAll('，', '')
        }
        if (strInput === '' || strInput === null || strInput === undefined)
            strInput = 0.00;

        let fResult = 0.00;

        // 拆柜
        if (index === 0) {
            this.refs.open_closet_fee.state.value = strInput;
            objOrder.open_closet_fee = this.refs.open_closet_fee.state.value;
            fResult = parseFloat(this.refs.open_closet_fee.state.value) - parseFloat(this.refs.open_closet_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.open_closet_benefit.state.value = fResult;
        }
        if (index === 1) {
            this.refs.open_closet_cost.state.value = strInput;
            objOrder.open_closet_cost = this.refs.open_closet_cost.state.value;
            fResult = parseFloat(this.refs.open_closet_fee.state.value) - parseFloat(this.refs.open_closet_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.open_closet_benefit.state.value = fResult;
        }
        // 分货
        if (index === 2) {
            this.refs.divide_item_fee.state.value = strInput;
            objOrder.divide_item_fee = this.refs.divide_item_fee.state.value;
            fResult = parseFloat(this.refs.divide_item_fee.state.value) -
                parseFloat(this.refs.divide_item_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.divide_item_benefit.state.value = fResult;
        }
        if (index === 3) {
            this.refs.divide_item_cost.state.value = strInput;
            objOrder.divide_item_cost = this.refs.divide_item_cost.state.value;
            fResult = parseFloat(this.refs.divide_item_fee.state.value) -
                parseFloat(this.refs.divide_item_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.divide_item_benefit.state.value = fResult;
        }
        // HB Storage Other Fee
        if (index === 4) {
            this.refs.storage_other_fee.state.value = strInput;
            objOrder.storage_other_fee = this.refs.storage_other_fee.state.value;
            fResult = parseFloat(this.refs.storage_other_fee.state.value) -
                parseFloat(this.refs.storage_other_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.storage_other_benefit.state.value = fResult;
        }
        if (index === 5) {
            this.refs.storage_other_cost.state.value = strInput;
            objOrder.storage_other_cost = this.refs.storage_other_cost.state.value;
            fResult = parseFloat(this.refs.storage_other_fee.state.value) -
                parseFloat(this.refs.storage_other_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.storage_other_benefit.state.value = fResult;
        }

        this.bFeeChange = true;

        this.setState({
            ...this.state,
            bOrderInfoChange: true
        })
    }
    // 仓储收费统计
    CalculateStorageFee = (objOrder) => {

        let fStorageTotalFee = 0.0;
        let fStorageTotalCost = 0.0;
        // 计算仓库HB费用
        if (this.state.bHouseBillDlg) {
            if (this.arrStorageFee[0].active === 1)
                fStorageTotalFee += parseFloat(objOrder.on_plate_fee);
            if (this.arrStorageFee[1].active === 1)
                fStorageTotalFee += parseFloat(objOrder.tag_fee);
            if (this.arrStorageFee[2].active === 1)
                fStorageTotalFee += parseFloat(objOrder.oversize_fee);
            if (this.arrStorageFee[3].active === 1)
                fStorageTotalFee += parseFloat(objOrder.storage_fee);
            if (this.arrStorageFee[4].active === 1)
                fStorageTotalFee += parseFloat(objOrder.storage_export_fee);
            if (this.arrStorageFee[5].active === 1)
                fStorageTotalFee += parseFloat(objOrder.storage_other_fee);
            if (this.arrStorageFee[0].active === 1)
                fStorageTotalCost += parseFloat(objOrder.on_plate_cost);
            if (this.arrStorageFee[1].active === 1)
                fStorageTotalCost += parseFloat(objOrder.tag_cost);
            if (this.arrStorageFee[2].active === 1)
                fStorageTotalCost += parseFloat(objOrder.oversize_cost);
            if (this.arrStorageFee[3].active === 1)
                fStorageTotalCost += parseFloat(objOrder.storage_cost);
            if (this.arrStorageFee[4].active === 1)
                fStorageTotalCost += parseFloat(objOrder.storage_export_cost);
            if (this.arrStorageFee[5].active === 1)
                fStorageTotalCost += parseFloat(objOrder.storage_other_cost);
            let fStorageTotalBenefit = parseFloat(fStorageTotalFee) - parseFloat(fStorageTotalCost);
            if (typeof (fStorageTotalFee) === 'number')
                fStorageTotalFee = fStorageTotalFee.toFixed(2);
            if (typeof (fStorageTotalCost) === 'number')
                fStorageTotalCost = fStorageTotalCost.toFixed(2);
            if (typeof (fStorageTotalBenefit) === 'number')
                fStorageTotalBenefit = fStorageTotalBenefit.toFixed(2);
            objOrder.hb_total_fee = fStorageTotalFee;
            objOrder.hb_total_cost = fStorageTotalCost;
            objOrder.hb_total_benefit = fStorageTotalBenefit;
            if (this.refs.hb_total_fee !== undefined) {
                this.refs.hb_total_fee.state.value = fStorageTotalFee;
                this.refs.hb_total_cost.state.value = fStorageTotalCost;
                this.refs.hb_total_benefit.state.value = fStorageTotalBenefit;
            }
        }
        // 计算仓库Master Bill费用
        else {
            fStorageTotalFee = parseFloat(objOrder.open_closet_fee) + parseFloat(objOrder.divide_item_fee);
            fStorageTotalCost = parseFloat(objOrder.open_closet_cost) + parseFloat(objOrder.divide_item_cost);
            let fStorageTotalBenefit = parseFloat(fStorageTotalFee) - parseFloat(fStorageTotalCost);
            if (typeof (fStorageTotalFee) === 'number')
                fStorageTotalFee = fStorageTotalFee.toFixed(2);
            if (typeof (fStorageTotalCost) === 'number')
                fStorageTotalCost = fStorageTotalCost.toFixed(2);
            if (typeof (fStorageTotalBenefit) === 'number')
                fStorageTotalBenefit = fStorageTotalBenefit.toFixed(2);
            objOrder.storage_total_fee = fStorageTotalFee;
            objOrder.storage_total_cost = fStorageTotalCost;
            objOrder.storage_total_benefit = fStorageTotalBenefit;
            if (this.refs.storage_total_fee !== undefined) {
                this.refs.storage_total_fee.state.value = fStorageTotalFee;
                if (this.props.iUserLevel >= 5) {
                    this.refs.storage_total_cost.state.value = fStorageTotalCost;
                    this.refs.storage_total_benefit.state.value = fStorageTotalBenefit;
                }
            }
        }
        return objOrder;
    }
    // 发船Release切换
    ShipReleaseSwitch = (iIndex, bChecked) => {
        let iShipRelease = this.state.iShipRelease;
        iShipRelease = 0;
        if (bChecked)
            iShipRelease = 1;
        this.setState({
            ...this.state,
            iShipRelease: iShipRelease
        })
    }
    // 仓库Release切换
    StorageReleaseSwitch = (iIndex, bChecked) => {
        let arrStorageRelease = this.state.arrStorageRelease;
        arrStorageRelease[iIndex] = 0;
        if (bChecked)
            arrStorageRelease[iIndex] = 1;
        this.setState({
            ...this.state,
            arrStorageRelease: arrStorageRelease
        })
    }
    // 免仓租日期更改
    FreeStorageDateChange = (date, dateString) => {
        this.setState({
            ...this.state,
            free_storage_date: dateString
        });
    }
    // 清关Release切换
    ClearReleaseSwitch = (bChecked) => {
        let iClearRelease = this.state.iClearRelease;
        iClearRelease = 0;
        if (bChecked)
            iClearRelease = 1;
        this.setState({
            ...this.state,
            iClearRelease: iClearRelease
        })
    }
    // 清关收费信息变化
    ClearFeeInfoChange = (objOrder, index, e) => {

        // 对输入内容的处理
        let strInput = e.target.value;
        if (strInput.indexOf(' ') >= 0) {
            strInput = strInput.replaceAll(' ', '')
        }
        if (strInput.indexOf(',') >= 0) {
            strInput = strInput.replaceAll(',', '')
        }
        if (strInput.indexOf('，') >= 0) {
            strInput = strInput.replaceAll('，', '')
        }
        if (strInput === '' || strInput === null || strInput === undefined)
            strInput = 0.00;

        let fResult = 0.00;

        if (index === 0) {
            this.refs.custom_fee.state.value = strInput;
            objOrder.custom_fee = this.refs.custom_fee.state.value;
            fResult = parseFloat(this.refs.custom_fee.state.value) - parseFloat(this.refs.custom_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.custom_benefit.state.value = fResult;
        }
        if (index === 1) {
            this.refs.custom_cost.state.value = strInput;
            objOrder.custom_cost = this.refs.custom_cost.state.value;
            fResult = parseFloat(this.refs.custom_fee.state.value) - parseFloat(this.refs.custom_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.custom_benefit.state.value = fResult;
        }
        if (index === 2) {
            this.refs.tax_fee.state.value = strInput;
            objOrder.tax_fee = this.refs.tax_fee.state.value;
            fResult = parseFloat(this.refs.tax_fee.state.value) - parseFloat(this.refs.tax_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.tax_benefit.state.value = fResult;
        }
        if (index === 3) {
            this.refs.tax_cost.state.value = strInput;
            objOrder.tax_cost = this.refs.tax_cost.state.value;
            fResult = parseFloat(this.refs.tax_fee.state.value) - parseFloat(this.refs.tax_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.tax_benefit.state.value = fResult;
        }
        if (index === 4) {
            this.refs.gst_fee.state.value = strInput;
            objOrder.gst_fee = this.refs.gst_fee.state.value;
            fResult = parseFloat(this.refs.gst_fee.state.value) - parseFloat(this.refs.gst_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.gst_benefit.state.value = fResult;
        }
        if (index === 5) {
            this.refs.gst_cost.state.value = strInput;
            objOrder.gst_cost = this.refs.gst_cost.state.value;
            fResult = parseFloat(this.refs.gst_fee.state.value) - parseFloat(this.refs.gst_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.gst_benefit.state.value = fResult;
        }
        if (index === 6) {
            this.refs.overitem_fee.state.value = strInput;
            objOrder.overitem_fee = this.refs.overitem_fee.state.value;
            fResult = parseFloat(this.refs.overitem_fee.state.value) - parseFloat(this.refs.overitem_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.overitem_benefit.state.value = fResult;
        }
        if (index === 7) {
            this.refs.overitem_cost.state.value = strInput;
            objOrder.overitem_cost = this.refs.overitem_cost.state.value;
            fResult = parseFloat(this.refs.overitem_fee.state.value) - parseFloat(this.refs.overitem_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.overitem_benefit.state.value = fResult;
        }
        if (index === 8) {
            this.refs.cfia_fee.state.value = strInput;
            objOrder.cfia_fee = this.refs.cfia_fee.state.value;
            fResult = parseFloat(this.refs.cfia_fee.state.value) - parseFloat(this.refs.cfia_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.cfia_benefit.state.value = fResult;
        }
        if (index === 9) {
            this.refs.cfia_cost.state.value = strInput;
            objOrder.cfia_cost = this.refs.cfia_cost.state.value;
            fResult = parseFloat(this.refs.cfia_fee.state.value) - parseFloat(this.refs.cfia_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.cfia_benefit.state.value = fResult;
        }
        if (index === 10) {
            this.refs.check_fee.state.value = strInput;
            objOrder.check_fee = this.refs.check_fee.state.value;
            fResult = parseFloat(this.refs.check_fee.state.value) - parseFloat(this.refs.check_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.check_benefit.state.value = fResult;
        }
        if (index === 11) {
            this.refs.check_cost.state.value = strInput;
            objOrder.check_cost = this.refs.check_cost.state.value;
            fResult = parseFloat(this.refs.check_fee.state.value) - parseFloat(this.refs.check_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.check_benefit.state.value = fResult;
        }
        if (index === 12) {
            this.refs.custom_other_fee.state.value = strInput;
            objOrder.custom_other_fee = this.refs.custom_other_fee.state.value;
            fResult = parseFloat(this.refs.custom_other_fee.state.value) - parseFloat(this.refs.custom_other_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.custom_other_benefit.state.value = fResult;
        }
        if (index === 13) {
            this.refs.custom_other_cost.state.value = strInput;
            objOrder.custom_other_cost = this.refs.custom_other_cost.state.value;
            fResult = parseFloat(this.refs.custom_other_fee.state.value) - parseFloat(this.refs.custom_other_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.custom_other_benefit.state.value = fResult;
        }

        this.bFeeChange = true;
        this.setState({
            ...this.state,
            bOrderInfoChange: true
        })
    }
    // 清关收费统计
    CalculateClearFee = (objOrder) => {
        let fClearTotalFee = parseFloat(objOrder.custom_fee) + parseFloat(objOrder.tax_fee) + parseFloat(objOrder.gst_fee);
        if (this.arrClearFee[0].active === 1)
            fClearTotalFee += parseFloat(objOrder.overitem_fee);
        if (this.arrClearFee[1].active === 1)
            fClearTotalFee += parseFloat(objOrder.cfia_fee);
        if (this.arrClearFee[2].active === 1)
            fClearTotalFee += parseFloat(objOrder.check_fee);
        if (this.arrClearFee[3].active === 1)
            fClearTotalFee += parseFloat(objOrder.custom_other_fee);

        let fClearTotalCost = parseFloat(objOrder.custom_cost) + parseFloat(objOrder.tax_cost) + parseFloat(objOrder.gst_cost);
        if (this.arrClearFee[0].active === 1)
            fClearTotalCost += parseFloat(objOrder.overitem_cost);
        if (this.arrClearFee[1].active === 1)
            fClearTotalCost += parseFloat(objOrder.cfia_cost);
        if (this.arrClearFee[2].active === 1)
            fClearTotalCost += parseFloat(objOrder.check_cost);
        if (this.arrClearFee[3].active === 1)
            fClearTotalCost += parseFloat(objOrder.custom_other_cost);

        let fClearTotalBenefit = parseFloat(fClearTotalFee) - parseFloat(fClearTotalCost);
        if (typeof (fClearTotalFee) === 'number')
            fClearTotalFee = fClearTotalFee.toFixed(2);
        if (typeof (fClearTotalCost) === 'number')
            fClearTotalCost = fClearTotalCost.toFixed(2);
        if (typeof (fClearTotalBenefit) === 'number')
            fClearTotalBenefit = fClearTotalBenefit.toFixed(2);

        objOrder.custom_total_fee = fClearTotalFee;
        objOrder.custom_total_cost = fClearTotalCost;
        objOrder.custom_total_benefit = fClearTotalBenefit;

        if (this.refs.custom_total_fee !== undefined) {
            this.refs.custom_total_fee.state.value = fClearTotalFee;
            if (this.props.iUserLevel >= 5) {
                this.refs.custom_total_cost.state.value = fClearTotalCost;
                this.refs.custom_total_benefit.state.value = fClearTotalBenefit;
            }
        }
        return objOrder;
    }
    // 切换 Clear Fee
    ClearFeeSwitch = (index) => {
        this.iCurrentClearFee = index;
        this.setState({
            ...this.state,
            bClearFeeChange: true
        })
    }
    // 新增 Clear Fee
    AddClearFee = (index) => {
        if (index < 0 || index > 6)
            return;
        this.arrClearFee[index].active = 1;
        if (index === 0) {
            this.arrOrderData[this.state.selectedRowKeys[0]].overitem_active = 1;
        }
        if (index === 1) {
            this.arrOrderData[this.state.selectedRowKeys[0]].cfia_active = 1;
        }
        if (index === 2) {
            this.arrOrderData[this.state.selectedRowKeys[0]].check_active = 1;
        }
        if (index === 3) {
            this.arrOrderData[this.state.selectedRowKeys[0]].custom_other_active = 1;
        }

        this.iCurrentClearFee = -1;
        for (let i = 0; i < this.arrClearFee.length; i++) {
            if (this.arrClearFee[i].active === 0) {
                this.iCurrentClearFee = i;
                break;
            }
        }

        this.setState({
            ...this.state,
            bClearFeeChange: true
        });
    }
    // 删除 Clear Fee
    DeleteClearFee = (index) => {
        this.arrClearFee[index].active = 0;
        if (index === 0) {
            this.arrOrderData[this.state.selectedRowKeys[0]].overitem_active = 0;
        }
        if (index === 1) {
            this.arrOrderData[this.state.selectedRowKeys[0]].cfia_active = 0;
        }
        if (index === 2) {
            this.arrOrderData[this.state.selectedRowKeys[0]].check_active = 0;
        }
        if (index === 3) {
            this.arrOrderData[this.state.selectedRowKeys[0]].custom_other_active = 0;
        }

        this.iCurrentClearFee = -1;
        for (let i = 0; i < this.arrClearFee.length; i++) {
            if (this.arrClearFee[i].active === 0) {
                this.iCurrentClearFee = i;
                break;
            }
        }
        this.setState({
            ...this.state,
            bClearFeeChange: true
        });
    }
    // 保存时所有HBL信息获取
    GetHBFeeInfo = (objHouseBill, objActionRecord) => {
        // 费用信息获取
        objHouseBill.custom_active = this.arrHBFee[0].active;
        if (this.arrHBFee[0].active === 1) {
            objHouseBill.custom_fee = this.refs.hb_custom_fee.state.value;
            objHouseBill.custom_cost = this.refs.hb_custom_cost.state.value;
        }
        objHouseBill.tax_active = this.arrHBFee[1].active;
        if (this.arrHBFee[1].active === 1) {
            objHouseBill.tax_fee = this.refs.hb_tax_fee.state.value;
            objHouseBill.tax_cost = this.refs.hb_tax_cost.state.value;
        }
        objHouseBill.gst_active = this.arrHBFee[2].active;
        if (this.arrHBFee[2].active === 1) {
            objHouseBill.gst_fee = this.refs.hb_gst_fee.state.value;
            objHouseBill.gst_cost = this.refs.hb_gst_cost.state.value;
        }
        objHouseBill.on_plate_active = this.arrHBFee[3].active;
        if (this.arrHBFee[3].active === 1) {
            objHouseBill.on_plate_fee = this.refs.hb_on_plate_fee.state.value;
            objHouseBill.on_plate_cost = this.refs.hb_on_plate_cost.state.value;
        }
        objHouseBill.tag_active = this.arrHBFee[4].active;
        if (this.arrHBFee[4].active === 1) {
            objHouseBill.tag_fee = this.refs.hb_tag_fee.state.value;
            objHouseBill.tag_cost = this.refs.hb_tag_cost.state.value;
        }
        objHouseBill.oversize_active = this.arrHBFee[5].active;
        if (this.arrHBFee[5].active === 1) {
            objHouseBill.oversize_fee = this.refs.hb_oversize_fee.state.value;
            objHouseBill.oversize_cost = this.refs.hb_oversize_cost.state.value;
        }
        objHouseBill.storage_active = this.arrHBFee[6].active;
        if (this.arrHBFee[6].active === 1) {
            objHouseBill.storage_fee = this.refs.hb_storage_fee.state.value;
            objHouseBill.storage_cost = this.refs.hb_storage_cost.state.value;
        }
        objHouseBill.storage_export_active = this.arrHBFee[7].active;
        if (this.arrHBFee[7].active === 1) {
            objHouseBill.storage_export_fee = this.refs.hb_storage_export_fee.state.value;
            objHouseBill.storage_export_cost = this.refs.hb_storage_export_cost.state.value;
        }
        objHouseBill.overitem_active = this.arrHBFee[8].active;
        if (this.arrHBFee[8].active === 1) {
            objHouseBill.overitem_fee = this.refs.hb_overitem_fee.state.value;
            objHouseBill.overitem_cost = this.refs.hb_overitem_cost.state.value;
        }
        objHouseBill.cfia_active = this.arrHBFee[9].active;
        if (this.arrHBFee[9].active === 1) {
            objHouseBill.cfia_fee = this.refs.hb_cfia_fee.state.value;
            objHouseBill.cfia_cost = this.refs.hb_cfia_cost.state.value;
        }
        objHouseBill.check_active = this.arrHBFee[10].active;
        if (this.arrHBFee[10].active === 1) {
            objHouseBill.check_fee = this.refs.hb_check_fee.state.value;
            objHouseBill.check_cost = this.refs.hb_check_cost.state.value;
        }
        objHouseBill.transit_active = this.arrHBFee[11].active;
        if (this.arrHBFee[11].active === 1) {
            objHouseBill.transit_fee = this.refs.hb_transit_fee.state.value;
            objHouseBill.transit_cost = this.refs.hb_transit_cost.state.value;
        }
        objHouseBill.deliver_active = this.arrHBFee[12].active;
        if (this.arrHBFee[12].active === 1) {
            objHouseBill.deliver_fee = this.refs.hb_deliver_fee.state.value;
            objHouseBill.deliver_cost = this.refs.hb_deliver_cost.state.value;
        }
        objHouseBill.on_plate45_active = this.arrHBFee[13].active;
        if (this.arrHBFee[13].active === 1) {
            objHouseBill.on_plate45_fee = this.refs.on_plate45_fee.state.value;
            objHouseBill.on_plate45_cost = this.refs.on_plate45_cost.state.value;
        }
        objHouseBill.waiting_active = this.arrHBFee[14].active;
        if (this.arrHBFee[14].active === 1) {
            objHouseBill.waiting_fee = this.refs.hb_waiting_fee.state.value;
            objHouseBill.waiting_cost = this.refs.hb_waiting_cost.state.value;
        }
        objHouseBill.hb_other_active = this.arrHBFee[15].active;
        if (this.arrHBFee[15].active === 1) {
            objHouseBill.hb_other_fee = this.refs.hb_other_fee.state.value;
            objHouseBill.hb_other_cost = this.refs.hb_other_cost.state.value;
            objHouseBill.hb_other_fee_comments = this.refs.hb_other_fee_comments.state.value;
        }

        // HBL费用操作记录
        let strRecord = "HBL收费\n"
        if (objHouseBill.custom_fee !== null && objHouseBill.custom_fee !== undefined && objHouseBill.custom_fee !== "")
            strRecord += "报关：" + objHouseBill.custom_fee;
        if (objHouseBill.tax_fee !== null && objHouseBill.tax_fee !== undefined && objHouseBill.tax_fee !== "")
            strRecord += ", 关税：" + objHouseBill.tax_fee;
        if (objHouseBill.gst_fee !== null && objHouseBill.gst_fee !== undefined && objHouseBill.gst_fee !== "")
            strRecord += ", GST：" + objHouseBill.gst_fee;
        if (objHouseBill.on_plate_fee !== null && objHouseBill.on_plate_fee !== undefined && objHouseBill.on_plate_fee !== "")
            strRecord += ", 打板：" + objHouseBill.on_plate_fee;
        if (objHouseBill.tag_fee !== null && objHouseBill.tag_fee !== undefined && objHouseBill.tag_fee !== "")
            strRecord += ", 贴标：" + objHouseBill.tag_fee;
        if (objHouseBill.oversize_fee !== null && objHouseBill.oversize_fee !== undefined && objHouseBill.oversize_fee !== "")
            strRecord += ", 超规：" + objHouseBill.oversize_fee;
        if (objHouseBill.storage_fee !== null && objHouseBill.storage_fee !== undefined && objHouseBill.storage_fee !== "")
            strRecord += ", 仓储：" + objHouseBill.storage_fee;
        if (objHouseBill.storage_export_fee !== null && objHouseBill.storage_export_fee !== undefined &&
            objHouseBill.storage_export_fee !== "")
            strRecord += ", 出仓：" + objHouseBill.storage_export_fee;
        if (objHouseBill.overitem_fee !== null && objHouseBill.overitem_fee !== undefined && objHouseBill.overitem_fee !== "")
            strRecord += ", 超项：" + objHouseBill.overitem_fee;
        if (objHouseBill.cfia_fee !== null && objHouseBill.cfia_fee !== undefined && objHouseBill.cfia_fee !== "")
            strRecord += ", CFIA：" + objHouseBill.cfia_fee;
        if (objHouseBill.check_fee !== null && objHouseBill.check_fee !== undefined && objHouseBill.check_fee !== "")
            strRecord += ", 查验：" + objHouseBill.check_fee;
        if (objHouseBill.transit_fee !== null && objHouseBill.transit_fee !== undefined && objHouseBill.transit_fee !== "")
            strRecord += ", 换单：" + objHouseBill.transit_fee;
        if (objHouseBill.deliver_fee !== null && objHouseBill.deliver_fee !== undefined && objHouseBill.deliver_fee !== "")
            strRecord += ", 派送：" + objHouseBill.deliver_fee;
        if (objHouseBill.on_plate45_fee !== null && objHouseBill.on_plate45_fee !== undefined && objHouseBill.on_plate45_fee !== "")
            strRecord += ", 托盘：" + objHouseBill.on_plate45_fee;
        if (objHouseBill.waiting_fee !== null && objHouseBill.waiting_fee !== undefined && objHouseBill.waiting_fee !== "")
            strRecord += ", 等候：" + objHouseBill.waiting_fee;
        if (objHouseBill.hb_other_fee !== null && objHouseBill.hb_other_fee !== undefined && objHouseBill.hb_other_fee !== "")
            strRecord += ",其他：" + objHouseBill.hb_other45_fee;

        strRecord += "\nHBL成本\n";
        if (objHouseBill.custom_cost !== null && objHouseBill.custom_cost !== undefined && objHouseBill.custom_cost !== "")
            strRecord += "报关：" + objHouseBill.custom_cost;
        if (objHouseBill.tax_cost !== null && objHouseBill.tax_cost !== undefined && objHouseBill.tax_cost !== "")
            strRecord += ", 关税：" + objHouseBill.tax_cost;
        if (objHouseBill.gst_cost !== null && objHouseBill.gst_cost !== undefined && objHouseBill.gst_cost !== "")
            strRecord += ", GST：" + objHouseBill.gst_cost;
        if (objHouseBill.on_plate_cost !== null && objHouseBill.on_plate_cost !== undefined && objHouseBill.on_plate_cost !== "")
            strRecord += ", 打板：" + objHouseBill.on_plate_cost;
        if (objHouseBill.tag_cost !== null && objHouseBill.tag_cost !== undefined && objHouseBill.tag_cost !== "")
            strRecord += ", 贴标：" + objHouseBill.tag_cost;
        if (objHouseBill.oversize_cost !== null && objHouseBill.oversize_cost !== undefined && objHouseBill.oversize_cost !== "")
            strRecord += ", 超规：" + objHouseBill.oversize_cost;
        if (objHouseBill.storage_cost !== null && objHouseBill.storage_cost !== undefined && objHouseBill.storage_cost !== "")
            strRecord += ", 仓储：" + objHouseBill.storage_cost;
        if (objHouseBill.storage_export_cost !== null && objHouseBill.storage_export_cost !== undefined &&
            objHouseBill.storage_export_cost !== "")
            strRecord += ", 出仓：" + objHouseBill.storage_export_cost;
        if (objHouseBill.overitem_cost !== null && objHouseBill.overitem_cost !== undefined && objHouseBill.overitem_cost !== "")
            strRecord += ", 超项：" + objHouseBill.overitem_cost;
        if (objHouseBill.cfia_cost !== null && objHouseBill.cfia_cost !== undefined && objHouseBill.cfia_cost !== "")
            strRecord += ", CFIA：" + objHouseBill.cfia_cost;
        if (objHouseBill.check_cost !== null && objHouseBill.check_cost !== undefined && objHouseBill.check_cost !== "")
            strRecord += ", 查验：" + objHouseBill.check_cost;
        if (objHouseBill.transit_cost !== null && objHouseBill.transit_cost !== undefined && objHouseBill.transit_cost !== "")
            strRecord += ", 换单：" + objHouseBill.transit_cost;
        if (objHouseBill.deliver_cost !== null && objHouseBill.deliver_cost !== undefined && objHouseBill.deliver_cost !== "")
            strRecord += ", 派送：" + objHouseBill.deliver_cost;
        if (objHouseBill.on_plate45_cost !== null && objHouseBill.on_plate45_cost !== undefined && objHouseBill.on_plate45_cost !== "")
            strRecord += ", 托盘：" + objHouseBill.on_plate45_cost;
        if (objHouseBill.waiting_cost !== null && objHouseBill.waiting_cost !== undefined && objHouseBill.waiting_cost !== "")
            strRecord += ", 等候：" + objHouseBill.waiting_cost;
        if (objHouseBill.hb_other_cost !== null && objHouseBill.hb_other_cost !== undefined && objHouseBill.hb_other_cost !== "")
            strRecord += ",其他：" + objHouseBill.hb_other_cost;

        objActionRecord.record = strRecord;

    }
    // HB收费信息变化
    HBFeeInfoChange = (objHouseBill, index, e) => {
        // 对输入内容的处理
        let strInput = e.target.value;
        if (strInput.indexOf(' ') >= 0) {
            strInput = strInput.replaceAll(' ', '')
        }
        if (strInput.indexOf(',') >= 0) {
            strInput = strInput.replaceAll(',', '')
        }
        if (strInput.indexOf('，') >= 0) {
            strInput = strInput.replaceAll('，', '')
        }
        if (strInput === '' || strInput === null || strInput === undefined)
            strInput = 0.00;

        this.abHBFeeChange = true;
        let fResult = 0.00;
        // 清关
        if (index === 0) {
            this.refs.hb_custom_fee.state.value = strInput;
            objHouseBill.custom_fee = this.refs.hb_custom_fee.state.value;
            fResult = parseFloat(this.refs.hb_custom_fee.state.value) - parseFloat(this.refs.hb_custom_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.hb_custom_benefit.state.value = fResult;
        }
        if (index === 1) {
            this.refs.hb_custom_cost.state.value = strInput;
            objHouseBill.custom_cost = this.refs.hb_custom_cost.state.value;
            fResult = parseFloat(this.refs.hb_custom_fee.state.value) - parseFloat(this.refs.hb_custom_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.hb_custom_benefit.state.value = fResult;
        }
        // Tax
        if (index === 2) {
            this.refs.hb_tax_fee.state.value = strInput;
            objHouseBill.tax_fee = this.refs.hb_tax_fee.state.value;
            fResult = parseFloat(this.refs.hb_tax_fee.state.value) - parseFloat(this.refs.hb_tax_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.hb_tax_benefit.state.value = fResult;
        }
        if (index === 3) {
            this.refs.hb_tax_cost.state.value = strInput;
            objHouseBill.tax_cost = this.refs.hb_tax_cost.state.value;
            fResult = parseFloat(this.refs.hb_tax_fee.state.value) - parseFloat(this.refs.hb_tax_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.hb_tax_benefit.state.value = fResult;
        }
        // GST
        if (index === 4) {
            this.refs.hb_gst_fee.state.value = strInput;
            objHouseBill.gst_fee = this.refs.hb_gst_fee.state.value;
            fResult = parseFloat(this.refs.hb_gst_fee.state.value) - parseFloat(this.refs.hb_gst_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.hb_gst_benefit.state.value = fResult;
        }
        if (index === 5) {
            this.refs.hb_gst_cost.state.value = strInput;
            objHouseBill.gst_cost = this.refs.hb_gst_cost.state.value;
            fResult = parseFloat(this.refs.hb_gst_fee.state.value) - parseFloat(this.refs.hb_gst_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.hb_gst_benefit.state.value = fResult;
        }
        // 打板
        if (index === 6) {
            this.refs.hb_on_plate_fee.state.value = strInput;
            objHouseBill.on_plate_fee = this.refs.hb_on_plate_fee.state.value;
            fResult = parseFloat(this.refs.hb_on_plate_fee.state.value) - parseFloat(this.refs.hb_on_plate_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.hb_on_plate_benefit.state.value = fResult;
        }
        if (index === 7) {
            this.refs.hb_on_plate_cost.state.value = strInput;
            objHouseBill.on_plate_cost = this.refs.hb_on_plate_cost.state.value;
            fResult = parseFloat(this.refs.hb_on_plate_fee.state.value) - parseFloat(this.refs.hb_on_plate_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.hb_on_plate_benefit.state.value = fResult;
        }
        // 贴标
        if (index === 8) {
            this.refs.hb_tag_fee.state.value = strInput;
            objHouseBill.tag_fee = this.refs.hb_tag_fee.state.value;
            fResult = parseFloat(this.refs.hb_tag_fee.state.value) - parseFloat(this.refs.hb_tag_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.hb_tag_benefit.state.value = fResult;
        }
        if (index === 9) {
            this.refs.hb_tag_cost.state.value = strInput;
            objHouseBill.tag_cost = this.refs.hb_tag_cost.state.value;
            fResult = parseFloat(this.refs.hb_tag_fee.state.value) - parseFloat(this.refs.hb_tag_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.hb_tag_benefit.state.value = fResult;
        }
        // 超规
        if (index === 10) {
            this.refs.hb_oversize_fee.state.value = strInput;
            objHouseBill.oversize_fee = this.refs.hb_oversize_fee.state.value;
            fResult = parseFloat(this.refs.hb_oversize_fee.state.value) - parseFloat(this.refs.hb_oversize_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.hb_oversize_benefit.state.value = fResult;
        }
        if (index === 11) {
            this.refs.hb_oversize_cost.state.value = strInput;
            objHouseBill.oversize_cost = this.refs.hb_oversize_cost.state.value;
            fResult = parseFloat(this.refs.hb_oversize_fee.state.value) - parseFloat(this.refs.hb_oversize_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.hb_oversize_benefit.state.value = fResult;
        }
        // 仓储
        if (index === 12) {
            this.refs.hb_storage_fee.state.value = strInput;
            objHouseBill.storage_fee = this.refs.hb_storage_fee.state.value;
            fResult = parseFloat(this.refs.hb_storage_fee.state.value) - parseFloat(this.refs.hb_storage_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.hb_storage_benefit.state.value = fResult;
        }
        if (index === 13) {
            this.refs.hb_storage_cost.state.value = strInput;
            objHouseBill.storage_cost = this.refs.hb_storage_cost.state.value;
            fResult = parseFloat(this.refs.hb_storage_fee.state.value) - parseFloat(this.refs.hb_storage_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.hb_storage_benefit.state.value = fResult;
        }
        // 出仓
        if (index === 14) {
            this.refs.hb_storage_export_fee.state.value = strInput;
            objHouseBill.storage_export_fee = this.refs.hb_storage_export_fee.state.value;
            fResult = parseFloat(this.refs.hb_storage_export_fee.state.value) -
                parseFloat(this.refs.hb_storage_export_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.hb_storage_export_benefit.state.value = fResult;
        }
        if (index === 15) {
            this.refs.hb_storage_export_cost.state.value = strInput;
            objHouseBill.storage_export_cost = this.refs.hb_storage_export_cost.state.value;
            fResult = parseFloat(this.refs.hb_storage_export_fee.state.value) -
                parseFloat(this.refs.hb_storage_export_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.hb_storage_export_benefit.state.value = fResult;
        }
        // 超项
        if (index === 16) {
            this.refs.hb_overitem_fee.state.value = strInput;
            objHouseBill.overitem_fee = this.refs.hb_overitem_fee.state.value;
            fResult = parseFloat(this.refs.hb_overitem_fee.state.value) -
                parseFloat(this.refs.hb_overitem_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.hb_overitem_benefit.state.value = fResult;
        }
        if (index === 17) {
            this.refs.hb_overitem_cost.state.value = strInput;
            objHouseBill.overitem_cost = this.refs.hb_overitem_cost.state.value;
            fResult = parseFloat(this.refs.hb_overitem_fee.state.value) -
                parseFloat(this.refs.hb_overitem_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.hb_overitem_benefit.state.value = fResult;
        }
        // CFIA
        if (index === 18) {
            this.refs.hb_cfia_fee.state.value = strInput;
            objHouseBill.cfia_fee = this.refs.hb_cfia_fee.state.value;
            fResult = parseFloat(this.refs.hb_cfia_fee.state.value) -
                parseFloat(this.refs.hb_cfia_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.hb_cfia_benefit.state.value = fResult;
        }
        if (index === 19) {
            this.refs.hb_cfia_cost.state.value = strInput;
            objHouseBill.cfia_cost = this.refs.hb_cfia_cost.state.value;
            fResult = parseFloat(this.refs.hb_cfia_fee.state.value) -
                parseFloat(this.refs.hb_cfia_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.hb_cfia_benefit.state.value = fResult;
        }
        // 查验
        if (index === 20) {
            this.refs.hb_check_fee.state.value = strInput;
            objHouseBill.check_fee = this.refs.hb_check_fee.state.value;
            fResult = parseFloat(this.refs.hb_check_fee.state.value) -
                parseFloat(this.refs.hb_check_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.hb_check_benefit.state.value = fResult;
        }
        if (index === 21) {
            this.refs.hb_check_cost.state.value = strInput;
            objHouseBill.check_cost = this.refs.hb_check_cost.state.value;
            fResult = parseFloat(this.refs.hb_check_fee.state.value) -
                parseFloat(this.refs.hb_check_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.hb_check_benefit.state.value = fResult;
        }
        // 换单
        if (index === 22) {
            this.refs.hb_transit_fee.state.value = strInput;
            objHouseBill.transit_fee = this.refs.hb_transit_fee.state.value;
            fResult = parseFloat(this.refs.hb_transit_fee.state.value) -
                parseFloat(this.refs.hb_transit_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.hb_transit_benefit.state.value = fResult;
        }
        if (index === 23) {
            this.refs.hb_transit_cost.state.value = strInput;
            objHouseBill.transit_cost = this.refs.hb_transit_cost.state.value;
            fResult = parseFloat(this.refs.hb_transit_fee.state.value) -
                parseFloat(this.refs.hb_transit_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.hb_transit_benefit.state.value = fResult;
        }
        // 派送
        if (index === 24) {
            this.refs.hb_deliver_fee.state.value = strInput;
            objHouseBill.deliver_fee = this.refs.hb_deliver_fee.state.value;
            fResult = parseFloat(this.refs.hb_deliver_fee.state.value) -
                parseFloat(this.refs.hb_deliver_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.hb_deliver_benefit.state.value = fResult;
        }
        if (index === 25) {
            this.refs.hb_deliver_cost.state.value = strInput;
            objHouseBill.deliver_cost = this.refs.hb_deliver_cost.state.value;
            fResult = parseFloat(this.refs.hb_deliver_fee.state.value) -
                parseFloat(this.refs.hb_deliver_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.hb_deliver_benefit.state.value = fResult;
        }
        // 托盘
        if (index === 26) {
            this.refs.on_plate45_fee.state.value = strInput;
            objHouseBill.on_plate45_fee = this.refs.on_plate45_fee.state.value;
            fResult = parseFloat(this.refs.on_plate45_fee.state.value) -
                parseFloat(this.refs.on_plate45_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.on_plate45_benefit.state.value = fResult;
        }
        if (index === 27) {
            this.refs.on_plate45_cost.state.value = strInput;
            objHouseBill.on_plate45_cost = this.refs.on_plate45_cost.state.value;
            fResult = parseFloat(this.refs.on_plate45_fee.state.value) -
                parseFloat(this.refs.on_plate45_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.on_plate45_benefit.state.value = fResult;
        }
        // 等候
        if (index === 28) {
            this.refs.hb_waiting_fee.state.value = strInput;
            objHouseBill.waiting_fee = this.refs.hb_waiting_fee.state.value;
            fResult = parseFloat(this.refs.hb_waiting_fee.state.value) -
                parseFloat(this.refs.hb_waiting_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.hb_waiting_benefit.state.value = fResult;
        }
        if (index === 29) {
            this.refs.hb_waiting_cost.state.value = strInput;
            objHouseBill.waiting_cost = this.refs.hb_waiting_cost.state.value;
            fResult = parseFloat(this.refs.hb_waiting_fee.state.value) -
                parseFloat(this.refs.hb_waiting_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.hb_waiting_benefit.state.value = fResult;
        }
        // 其他
        if (index === 30) {
            this.refs.hb_other_fee.state.value = strInput;
            objHouseBill.hb_other_fee = this.refs.hb_other_fee.state.value;
            fResult = parseFloat(this.refs.hb_other_fee.state.value) -
                parseFloat(this.refs.hb_other_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.hb_other_benefit.state.value = fResult;
        }
        if (index === 31) {
            this.refs.hb_other_cost.state.value = strInput;
            objHouseBill.hb_other_cost = this.refs.hb_other_cost.state.value;
            fResult = parseFloat(this.refs.hb_other_fee.state.value) -
                parseFloat(this.refs.hb_other_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.hb_other_benefit.state.value = fResult;
        }

        this.setState({
            ...this.state,
            bHBFeeChange: true
        })
    }
    // 仓储收费统计
    CalculateHBFee = (objHouseBill) => {
        let fHBTotalFee = 0.0;
        if (this.arrHBFee[0].active === 1)
            fHBTotalFee += parseFloat(objHouseBill.custom_fee);
        if (this.arrHBFee[1].active === 1)
            fHBTotalFee += parseFloat(objHouseBill.tax_fee);
        if (this.arrHBFee[2].active === 1)
            fHBTotalFee += parseFloat(objHouseBill.gst_fee);
        if (this.arrHBFee[3].active === 1)
            fHBTotalFee += parseFloat(objHouseBill.on_plate_fee);
        if (this.arrHBFee[4].active === 1)
            fHBTotalFee += parseFloat(objHouseBill.tag_fee);
        if (this.arrHBFee[5].active === 1)
            fHBTotalFee += parseFloat(objHouseBill.oversize_fee);
        if (this.arrHBFee[6].active === 1)
            fHBTotalFee += parseFloat(objHouseBill.storage_fee);
        if (this.arrHBFee[7].active === 1)
            fHBTotalFee += parseFloat(objHouseBill.storage_export_fee);
        if (this.arrHBFee[8].active === 1)
            fHBTotalFee += parseFloat(objHouseBill.overitem_fee);
        if (this.arrHBFee[9].active === 1)
            fHBTotalFee += parseFloat(objHouseBill.cfia_fee);
        if (this.arrHBFee[10].active === 1)
            fHBTotalFee += parseFloat(objHouseBill.check_fee);
        if (this.arrHBFee[11].active === 1)
            fHBTotalFee += parseFloat(objHouseBill.transit_fee);
        if (this.arrHBFee[12].active === 1)
            fHBTotalFee += parseFloat(objHouseBill.deliver_fee);
        if (this.arrHBFee[13].active === 1)
            fHBTotalFee += parseFloat(objHouseBill.on_plate45_fee);
        if (this.arrHBFee[14].active === 1)
            fHBTotalFee += parseFloat(objHouseBill.waiting_fee);
        if (this.arrHBFee[15].active === 1)
            fHBTotalFee += parseFloat(objHouseBill.hb_other_fee);
        let fHBTotalCost = 0.0;
        if (this.arrHBFee[0].active === 1)
            fHBTotalCost += parseFloat(objHouseBill.custom_cost);
        if (this.arrHBFee[1].active === 1)
            fHBTotalCost += parseFloat(objHouseBill.tax_cost);
        if (this.arrHBFee[2].active === 1)
            fHBTotalCost += parseFloat(objHouseBill.gst_cost);
        if (this.arrHBFee[3].active === 1)
            fHBTotalCost += parseFloat(objHouseBill.on_plate_cost);
        if (this.arrHBFee[4].active === 1)
            fHBTotalCost += parseFloat(objHouseBill.tag_cost);
        if (this.arrHBFee[5].active === 1)
            fHBTotalCost += parseFloat(objHouseBill.oversize_cost);
        if (this.arrHBFee[6].active === 1)
            fHBTotalCost += parseFloat(objHouseBill.storage_cost);
        if (this.arrHBFee[7].active === 1)
            fHBTotalCost += parseFloat(objHouseBill.storage_export_cost);
        if (this.arrHBFee[8].active === 1)
            fHBTotalCost += parseFloat(objHouseBill.overitem_cost);
        if (this.arrHBFee[9].active === 1)
            fHBTotalCost += parseFloat(objHouseBill.cfia_cost);
        if (this.arrHBFee[10].active === 1)
            fHBTotalCost += parseFloat(objHouseBill.check_cost);
        if (this.arrHBFee[11].active === 1)
            fHBTotalCost += parseFloat(objHouseBill.transit_cost);
        if (this.arrHBFee[12].active === 1)
            fHBTotalCost += parseFloat(objHouseBill.deliver_cost);
        if (this.arrHBFee[13].active === 1)
            fHBTotalCost += parseFloat(objHouseBill.on_plate45_cost);
        if (this.arrHBFee[14].active === 1)
            fHBTotalCost += parseFloat(objHouseBill.waiting_cost);
        if (this.arrHBFee[15].active === 1)
            fHBTotalCost += parseFloat(objHouseBill.hb_other_cost);

        let fHBTotalBenefit = parseFloat(fHBTotalFee) - parseFloat(fHBTotalCost);
        if (typeof (fHBTotalBenefit) === 'number')
            fHBTotalBenefit = fHBTotalBenefit.toFixed(2);
        if (typeof (fHBTotalFee) === 'number')
            fHBTotalFee = fHBTotalFee.toFixed(2);
        if (typeof (fHBTotalCost) === 'number')
            fHBTotalCost = fHBTotalCost.toFixed(2);
        if (typeof (fHBTotalBenefit) === 'number')
            fHBTotalBenefit = fHBTotalBenefit.toFixed(2);

        objHouseBill.hb_total_fee = fHBTotalFee;
        objHouseBill.hb_total_cost = fHBTotalCost;
        objHouseBill.hb_total_benefit = fHBTotalBenefit;

        if (this.refs.hb_total_fee !== undefined) {
            this.refs.hb_total_fee.state.value = fHBTotalFee;
            if (this.props.iUserLevel >= 5) {
                this.refs.hb_total_cost.state.value = fHBTotalCost;
                this.refs.hb_total_benefit.state.value = fHBTotalBenefit;
            }

        }

        return objHouseBill;
    }
    // 切换 HB Fee
    HBFeeSwitch = (index) => {
        this.iCurrentHBFee = index;
        this.setState({
            ...this.state,
            bHBFeeChange: true
        })
    }
    // 新增 HB Fee
    AddHBFee = (index) => {

        if (index < 0 || index > this.arrHBFee.length - 1)
            return;
        this.arrHBFee[index].active = 1;

        if (index === 0) {
            this.arrHouseBill[this.iCurrentHouseBill].custom_active = 1;
        }
        if (index === 1) {
            this.arrHouseBill[this.iCurrentHouseBill].tax_active = 1;
        }
        if (index === 2) {
            this.arrHouseBill[this.iCurrentHouseBill].gst_active = 1;
        }
        if (index === 3) {
            this.arrHouseBill[this.iCurrentHouseBill].on_plate_active = 1;
        }
        if (index === 4) {
            this.arrHouseBill[this.iCurrentHouseBill].tag_active = 1;
        }
        if (index === 5) {
            this.arrHouseBill[this.iCurrentHouseBill].oversize_active = 1;
        }
        if (index === 6) {
            this.arrHouseBill[this.iCurrentHouseBill].storage_active = 1;
        }
        if (index === 7) {
            this.arrHouseBill[this.iCurrentHouseBill].storage_export_active = 1;
        }
        if (index === 8) {
            this.arrHouseBill[this.iCurrentHouseBill].overitem_active = 1;
        }
        if (index === 9) {
            this.arrHouseBill[this.iCurrentHouseBill].cfia_active = 1;
        }
        if (index === 10) {
            this.arrHouseBill[this.iCurrentHouseBill].check_active = 1;
        }
        if (index === 11) {
            this.arrHouseBill[this.iCurrentHouseBill].transit_active = 1;
        }
        if (index === 12) {
            this.arrHouseBill[this.iCurrentHouseBill].deliver_active = 1;
        }
        if (index === 13) {
            this.arrHouseBill[this.iCurrentHouseBill].on_plate45_active = 1;
        }
        if (index === 14) {
            this.arrHouseBill[this.iCurrentHouseBill].waiting_active = 1;
        }
        if (index === 15) {
            this.arrHouseBill[this.iCurrentHouseBill].hb_other_active = 1;
        }

        this.iCurrentHBFee = -1;
        for (let i = 0; i < this.arrHBFee.length; i++) {
            if (this.arrHBFee[i].active === 0) {
                this.iCurrentHBFee = i;
                break;
            }
        }

        this.setState({
            ...this.state,
            bHBFeeChange: true
        });
    }
    // 删除 HB Fee
    DeleteHBFee = (index) => {
        this.arrHBFee[index].active = 0;
        if (index === 0) {
            this.arrHouseBill[this.iCurrentHouseBill].custom_active = 0;
        }
        if (index === 1) {
            this.arrHouseBill[this.iCurrentHouseBill].tax_active = 0;
        }
        if (index === 2) {
            this.arrHouseBill[this.iCurrentHouseBill].gst_active = 0;
        }
        if (index === 3) {
            this.arrHouseBill[this.iCurrentHouseBill].on_plate_active = 0;
        }
        if (index === 4) {
            this.arrHouseBill[this.iCurrentHouseBill].tag_active = 0;
        }
        if (index === 5) {
            this.arrHouseBill[this.iCurrentHouseBill].oversize_active = 0;
        }
        if (index === 6) {
            this.arrHouseBill[this.iCurrentHouseBill].storage_active = 0;
        }
        if (index === 7) {
            this.arrHouseBill[this.iCurrentHouseBill].storage_export_active = 0;
        }
        if (index === 8) {
            this.arrHouseBill[this.iCurrentHouseBill].overitem_active = 0;
        }
        if (index === 9) {
            this.arrHouseBill[this.iCurrentHouseBill].cfia_active = 0;
        }
        if (index === 10) {
            this.arrHouseBill[this.iCurrentHouseBill].check_active = 0;
        }
        if (index === 11) {
            this.arrHouseBill[this.iCurrentHouseBill].transit_active = 0;
        }
        if (index === 12) {
            this.arrHouseBill[this.iCurrentHouseBill].deliver_active = 0;
        }
        if (index === 13) {
            this.arrHouseBill[this.iCurrentHouseBill].on_plate45_active = 0;
        }
        if (index === 14) {
            this.arrHouseBill[this.iCurrentHouseBill].waiting_active = 0;
        }
        if (index === 15) {
            this.arrHouseBill[this.iCurrentHouseBill].hb_other_active = 0;
        }
        this.iCurrentHBFee = -1;
        for (let i = 0; i < this.arrHBFee.length; i++) {
            if (this.arrHBFee[i].active === 0) {
                this.iCurrentHBFee = i;
                break;
            }
        }

        this.setState({
            ...this.state,
            bHBFeeChange: true
        });
    }
    // 切换 Storage Fee
    StorageFeeSwitch = (index) => {
        this.abHBFeeChange = true;
        this.iCurrentStorageFee = index;
        this.setState({
            ...this.state,
            bHBFeeChange: true
        })
    }
    // 新增 HB Fee
    AddStorageFee = (index) => {
        if (index < 0 || index > this.arrStorageFee.length - 1)
            return;
        this.arrStorageFee[index].active = 1;

        if (index === 0) {
            this.arrHouseBill[this.iCurrentHouseBill].on_plate_active = 1;
        }
        if (index === 1) {
            this.arrHouseBill[this.iCurrentHouseBill].tag_active = 1;
        }
        if (index === 2) {
            this.arrHouseBill[this.iCurrentHouseBill].oversize_active = 1;
        }
        if (index === 3) {
            this.arrHouseBill[this.iCurrentHouseBill].storage_active = 1;
        }
        if (index === 4) {
            this.arrHouseBill[this.iCurrentHouseBill].storage_export_active = 1;
        }
        if (index === 5) {
            this.arrHouseBill[this.iCurrentHouseBill].storage_other_active = 1;
        }

        this.iCurrentStorageFee = -1;

        for (let i = 0; i < this.arrStorageFee.length; i++) {
            if (this.arrStorageFee[i].active === 0) {
                this.iCurrentStorageFee = i;
                break;
            }
        }

        this.setState({
            ...this.state,
            bHBFeeChange: true
        });
    }
    // 删除 HB Fee
    DeleteStorageFee = (index) => {

        this.arrStorageFee[index].active = 0;
        if (index === 0) {
            this.arrHouseBill[this.iCurrentHouseBill].on_plate_active = 0;
        }
        if (index === 1) {
            this.arrHouseBill[this.iCurrentHouseBill].tag_active = 0;
        }
        if (index === 2) {
            this.arrHouseBill[this.iCurrentHouseBill].oversize_active = 0;
        }
        if (index === 3) {
            this.arrHouseBill[this.iCurrentHouseBill].storage_active = 0;
        }
        if (index === 4) {
            this.arrHouseBill[this.iCurrentHouseBill].storage_export_active = 0;
        }
        if (index === 5) {
            this.arrHouseBill[this.iCurrentHouseBill].storage_other_active = 0;
        }
        this.iCurrentStorageFee = -1;
        for (let i = 0; i < this.arrStorageFee.length; i++) {
            if (this.arrStorageFee[i].active === 0) {
                this.iCurrentStorageFee = i;
                break;
            }
        }
        this.setState({
            ...this.state,
            bHBFeeChange: true
        });
    }
    // POD 状态切换
    PODSwitch = (index, bChecked) => {
        // 海派
        if (this.props.iType === 5) {
            // 仓库->清关 需要每个House Bill 清关放行
            if (this.arrOrderData[this.state.selectedRowKeys[0]].storage_custom === 0
                && this.arrHouseBill[this.iCurrentHouseBill].clear_release <= 0) {
                message.error("还未清关 / 仓库未放行！");
                return;
            }
            // 清关-> 仓库 需要仓库放行
            if (this.arrOrderData[this.state.selectedRowKeys[0]].storage_custom >= 1 && this.arrOrderData[this.state.selectedRowKeys[0]].open_release <= 0) {
                message.error("还未清关 / 仓库未放行！");
                return;
            }
        }
        let iPOD = this.state.iPOD;
        let iLK = this.state.iLKRelease;
        let iClient = this.state.iClientRelease;
        if (index === 0) {
            if (bChecked)
                iPOD = 1;
            else
                iPOD = 0;
        }
        if (index === 1) {
            if (bChecked)
                iLK = 1;
            else
                iLK = 0;
        }
        if (index === 2) {
            if (bChecked)
                iClient = 1;
            else
                iClient = 0;
        }
        if (iLK === 0 || iClient === 0) {
            iPOD = 0;
        }
        this.setState({
            ...this.state,
            iPOD: iPOD,
            iLKRelease: iLK,
            iClientRelease: iClient
        })
    }
    // 换单自提收费信息变化
    TransferFeeInfoChange = (objHouseBill, index, e) => {
        // 对输入内容的处理
        let strInput = e.target.value;
        if (strInput.indexOf(' ') >= 0) {
            strInput = strInput.replaceAll(' ', '')
        }
        if (strInput.indexOf(',') >= 0) {
            strInput = strInput.replaceAll(',', '')
        }
        if (strInput.indexOf('，') >= 0) {
            strInput = strInput.replaceAll('，', '')
        }
        if (strInput === '' || strInput === null || strInput === undefined)
            strInput = 0.00;

        this.abHBFeeChange = true;
        let fResult = 0.00;
        // 固定收费
        if (index === 0) {
            this.refs.transit_fee.state.value = strInput;
            objHouseBill.transit_fee = this.refs.transit_fee.state.value;
            fResult = parseFloat(this.refs.transit_fee.state.value) -
                parseFloat(this.refs.transit_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.transit_benefit.state.value = fResult;
        }
        if (index === 1) {
            this.refs.transit_cost.state.value = strInput;
            objHouseBill.transit_cost = this.refs.transit_cost.state.value;
            fResult = parseFloat(this.refs.transit_fee.state.value) -
                parseFloat(this.refs.transit_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.transit_benefit.state.value = fResult;
        }
        if (index === 2) {
            this.refs.deliver_fee.state.value = strInput;
            objHouseBill.deliver_fee = this.refs.deliver_fee.state.value;
            fResult = parseFloat(this.refs.deliver_fee.state.value) -
                parseFloat(this.refs.deliver_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.deliver_benefit.state.value = fResult;
        }
        if (index === 3) {
            this.refs.deliver_cost.state.value = strInput;
            objHouseBill.deliver_cost = this.refs.deliver_cost.state.value;
            fResult = parseFloat(this.refs.deliver_fee.state.value) -
                parseFloat(this.refs.deliver_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.deliver_benefit.state.value = fResult;
        }
        if (index === 4) {
            this.refs.storage_export45_fee.state.value = strInput;
            objHouseBill.storage_export45_fee = this.refs.storage_export45_fee.state.value;
            fResult = parseFloat(this.refs.storage_export45_fee.state.value) - parseFloat(this.refs.storage_export45_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.storage_export45_benefit.state.value = fResult;
        }
        if (index === 5) {
            this.refs.storage_export45_cost.state.value = strInput;
            objHouseBill.storage_export45_cost = this.refs.storage_export45_cost.state.value;
            fResult = parseFloat(this.refs.storage_export45_fee.state.value) - parseFloat(this.refs.storage_export45_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.storage_export45_benefit.state.value = fResult;
        }
        // 自由收费
        if (index === 6) {
            this.refs.on_plate45_fee.state.value = strInput;
            objHouseBill.on_plate45_fee = this.refs.on_plate45_fee.state.value;
            fResult = parseFloat(this.refs.on_plate45_fee.state.value) - parseFloat(this.refs.on_plate45_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.on_plate45_benefit.state.value = fResult;
        }
        if (index === 7) {
            this.refs.on_plate45_cost.state.value = strInput;
            objHouseBill.on_plate45_cost = this.refs.on_plate45_cost.state.value;
            fResult = parseFloat(this.refs.on_plate45_fee.state.value) - parseFloat(this.refs.on_plate45_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.on_plate45_benefit.state.value = fResult;
        }
        if (index === 8) {
            this.refs.waiting_fee.state.value = strInput;
            objHouseBill.waiting_fee = this.refs.waiting_fee.state.value;
            fResult = parseFloat(this.refs.waiting_fee.state.value) - parseFloat(this.refs.waiting_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.waiting_benefit.state.value = fResult;
        }
        if (index === 9) {
            this.refs.waiting_cost.state.value = strInput;
            objHouseBill.waiting_cost = this.refs.waiting_cost.state.value;
            fResult = parseFloat(this.refs.waiting_fee.state.value) - parseFloat(this.refs.waiting_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.waiting_benefit.state.value = fResult;
        }
        if (index === 10) {
            this.refs.hb_other45_fee.state.value = strInput;
            objHouseBill.hb_other45_fee = this.refs.hb_other45_fee.state.value;
            fResult = parseFloat(this.refs.hb_other45_fee.state.value) - parseFloat(this.refs.hb_other45_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.hb_other45_benefit.state.value = fResult;
        }
        if (index === 11) {
            this.refs.hb_other45_cost.state.value = strInput;
            objHouseBill.hb_other45_cost = this.refs.hb_other45_cost.state.value;
            fResult = parseFloat(this.refs.hb_other45_fee.state.value) - parseFloat(this.refs.hb_other45_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.hb_other45_benefit.state.value = fResult;
        }

        this.setState({
            ...this.state,
            bOrderInfoChange: true
        })
    }
    // 切换 Transfer Fee
    TransferFeeSwitch = (index) => {
        this.iCurrentTransferFee = index;
        console.log("this.iCurrentTransferFee = " + this.iCurrentTransferFee);
        this.setState({
            ...this.state,
            bTransferFeeChange: true
        })
    }
    // 新增 Transfer Fee
    AddTransferFee = (index) => {
        if (index < 0 || index > this.arrTransferFee.length - 1)
            return;
        this.arrTransferFee[index].active = 1;

        // 打板
        if (index === 0) {
            this.arrHouseBill[this.iCurrentHouseBill].on_plate45_active = 1;
        }
        // 等候
        if (index === 1) {
            this.arrHouseBill[this.iCurrentHouseBill].waiting_active = 1;
        }
        // 其他
        if (index === 2) {
            this.arrHouseBill[this.iCurrentHouseBill].hb_other45_active = 1;
        }
        this.iCurrentTransferFee = -1;
        for (let i = 0; i < this.arrTransferFee.length; i++) {
            if (this.arrTransferFee[i].active === 0) {
                this.iCurrentTransferFee = i;
                break;
            }
        }
        this.setState({
            ...this.state,
            bHBFeeChange: true
        });
    }
    // 删除 HB Fee
    DeleteTransferFee = (index) => {
        console.log("DeleteTransferFee : " + index);
        this.arrTransferFee[index].active = 0;
        if (index === 0) {
            this.arrHouseBill[this.iCurrentHouseBill].on_plate45_active = 0;
        }
        if (index === 1) {
            this.arrHouseBill[this.iCurrentHouseBill].waiting_active = 0;
        }
        if (index === 2) {
            this.arrHouseBill[this.iCurrentHouseBill].hb_other45_active = 0;
        }
        this.iCurrentTransferFee = -1;
        for (let i = 0; i < this.arrTransferFee.length; i++) {
            if (this.arrTransferFee[i].active === 0) {
                this.iCurrentTransferFee = i;
                break;
            }
        }
        this.setState({
            ...this.state,
            bHBFeeChange: true
        });
    }
    // 派送费信息变化
    DeliverFeeChange = (objOrder, index, e) => {
        // 对输入内容的处理
        let strInput = e.target.value;
        if (strInput.indexOf(' ') >= 0) {
            strInput = strInput.replaceAll(' ', '')
        }
        if (strInput.indexOf(',') >= 0) {
            strInput = strInput.replaceAll(',', '')
        }
        if (strInput.indexOf('，') >= 0) {
            strInput = strInput.replaceAll('，', '')
        }
        if (strInput === '' || strInput === null || strInput === undefined)
            strInput = 0.00;



        let fResult = 0.00;
        if (index === 0) {
            this.refs.deliver_fee.state.value = strInput;
            objOrder.deliver_fee = this.refs.deliver_fee.state.value;
            fResult = parseFloat(this.refs.deliver_fee.state.value) - parseFloat(this.refs.deliver_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.deliver_benefit.state.value = fResult;
        }
        if (index === 1) {
            this.refs.deliver_cost.state.value = strInput;
            objOrder.deliver_cost = this.refs.deliver_cost.state.value;
            fResult = parseFloat(this.refs.deliver_fee.state.value) - parseFloat(this.refs.deliver_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.deliver_benefit.state.value = fResult;
        }
        if (index === 2) {
            this.refs.storage_export_fee.state.value = strInput;
            objOrder.storage_export_fee = this.refs.storage_export_fee.state.value;
            fResult = parseFloat(this.refs.storage_export_fee.state.value) - parseFloat(this.refs.storage_export_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.storage_export_benefit.state.value = fResult;
        }
        if (index === 3) {
            this.refs.storage_export_cost.state.value = strInput;
            objOrder.storage_export_cost = this.refs.storage_export_cost.state.value;
            fResult = parseFloat(this.refs.storage_export_fee.state.value) - parseFloat(this.refs.storage_export_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.storage_export_benefit.state.value = fResult;
        }
        if (index === 4) {
            this.refs.on_plate_fee.state.value = strInput;
            objOrder.on_plate_fee = this.refs.on_plate_fee.state.value;
            fResult = parseFloat(this.refs.on_plate_fee.state.value) - parseFloat(this.refs.on_plate_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.on_plate_benefit.state.value = fResult;
        }
        if (index === 5) {
            this.refs.on_plate_cost.state.value = strInput;
            objOrder.on_plate_cost = this.refs.on_plate_cost.state.value;
            fResult = parseFloat(this.refs.on_plate_fee.state.value) - parseFloat(this.refs.on_plate_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.on_plate_benefit.state.value = fResult;
        }
        if (index === 6) {
            this.refs.waiting_fee.state.value = strInput;
            objOrder.waiting_fee = this.refs.waiting_fee.state.value;
            fResult = parseFloat(this.refs.waiting_fee.state.value) - parseFloat(this.refs.waiting_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.waiting_benefit.state.value = fResult;
        }
        if (index === 7) {
            this.refs.waiting_cost.state.value = strInput;
            objOrder.waiting_cost = this.refs.waiting_cost.state.value;
            fResult = parseFloat(this.refs.waiting_fee.state.value) - parseFloat(this.refs.waiting_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.waiting_benefit.state.value = fResult;
        }
        if (index === 8) {
            this.refs.deliver_other_fee.state.value = strInput;
            objOrder.deliver_other_fee = this.refs.deliver_other_fee.state.value;
            fResult = parseFloat(this.refs.deliver_other_fee.state.value) - parseFloat(this.refs.deliver_other_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.deliver_other_benefit.state.value = fResult;
        }
        if (index === 9) {
            this.refs.deliver_other_cost.state.value = strInput;
            objOrder.deliver_other_cost = this.refs.deliver_other_cost.state.value;
            fResult = parseFloat(this.refs.deliver_other_fee.state.value) - parseFloat(this.refs.deliver_other_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.deliver_other_benefit.state.value = fResult;
        }
        this.bFeeChange = true;
        this.setState({
            ...this.state,
            bOrderInfoChange: true
        })
    }
    // 派送费统计
    CalculateDeliverFee = (objOrder) => {
        let fDeliverTotalFee = parseFloat(objOrder.deliver_fee) + parseFloat(objOrder.storage_export_fee) + parseFloat(objOrder.on_plate_fee) +
            parseFloat(objOrder.waiting_fee) + parseFloat(objOrder.deliver_other_fee);
        let fDeliverTotalCost = parseFloat(objOrder.deliver_cost) + parseFloat(objOrder.storage_export_cost) + parseFloat(objOrder.on_plate_cost) +
            parseFloat(objOrder.waiting_cost) + parseFloat(objOrder.deliver_other_cost);
        let fDeliverTotalBenefit = fDeliverTotalFee - fDeliverTotalCost;
        if (typeof (fDeliverTotalFee) === 'number')
            fDeliverTotalFee = fDeliverTotalFee.toFixed(2);
        if (typeof (fDeliverTotalCost) === 'number')
            fDeliverTotalCost = fDeliverTotalCost.toFixed(2);
        if (typeof (fDeliverTotalBenefit) === 'number')
            fDeliverTotalBenefit = fDeliverTotalBenefit.toFixed(2);

        objOrder.deliver_total_fee = fDeliverTotalFee;
        objOrder.deliver_total_cost = fDeliverTotalCost;
        objOrder.deliver_total_benefit = fDeliverTotalBenefit;

        if (typeof (objOrder.deliver_total_fee) === 'number')
            objOrder.deliver_total_fee = objOrder.deliver_total_fee.toFixed(2);
        if (typeof (objOrder.deliver_total_cost) === 'number')
            objOrder.deliver_total_cost = objOrder.deliver_total_cost.toFixed(2);
        if (typeof (objOrder.deliver_total_benefit) === 'number')
            objOrder.deliver_total_benefit = objOrder.deliver_total_benefit.toFixed(2);

        if (this.refs.deliver_total_fee !== undefined) {
            this.refs.deliver_total_fee.state.value = objOrder.deliver_total_fee;
            if (this.props.iUserLevel >= 5) {
                this.refs.deliver_total_cost.state.value = objOrder.deliver_total_cost;
                this.refs.deliver_total_benefit.state.value = objOrder.deliver_total_benefit;
            }

        }

        return objOrder;
    }
    // 费用细节折叠框
    OpenFeeDetail = (index, key) => {
        console.log("OpenFeeDetail : ", index, key);
    }
    // 客户端确认收费开关
    FeeConfirmSwitch = (iIndex, bChecked) => {
        switch (iIndex) {
            case 0: {
                let iShipFeeConfirm = this.state.iShipFeeConfirm;
                iShipFeeConfirm = 0;
                if (bChecked)
                    iShipFeeConfirm = 1;
                this.setState({
                    ...this.state,
                    iShipFeeConfirm: iShipFeeConfirm
                })
                break;
            }
            case 1: {
                let iPortFeeConfirm = this.state.iPortFeeConfirm;
                iPortFeeConfirm = 0;
                if (bChecked)
                    iPortFeeConfirm = 1;
                this.setState({
                    ...this.state,
                    iPortFeeConfirm: iPortFeeConfirm
                })
                break;
            }
            case 2: {
                let iStorageFeeConfirm = this.state.iStorageFeeConfirm;
                iStorageFeeConfirm = 0;
                if (bChecked)
                    iStorageFeeConfirm = 1;
                this.setState({
                    ...this.state,
                    iStorageFeeConfirm: iStorageFeeConfirm
                })
                break;
            }
            case 3: {
                let iClearFeeConfirm = this.state.iClearFeeConfirm;
                iClearFeeConfirm = 0;
                if (bChecked)
                    iClearFeeConfirm = 1;
                this.setState({
                    ...this.state,
                    iClearFeeConfirm: iClearFeeConfirm
                })
                break;
            }
            case 4: {
                let iTransitFeeConfirm = this.state.iTransitFeeConfirm;
                iTransitFeeConfirm = 0;
                if (bChecked)
                    iTransitFeeConfirm = 1;
                this.setState({
                    ...this.state,
                    iTransitFeeConfirm: iTransitFeeConfirm
                })
                break;
            }
            case 5: {
                let iDeliverFeeConfirm = this.state.iDeliverFeeConfirm;
                iDeliverFeeConfirm = 0;
                if (bChecked)
                    iDeliverFeeConfirm = 1;
                this.setState({
                    ...this.state,
                    iDeliverFeeConfirm: iDeliverFeeConfirm
                })
                break;
            }
            case 6: {
                let iPickupFeeConfirm = this.state.iPickupFeeConfirm;
                iPickupFeeConfirm = 0;
                if (bChecked)
                    iPickupFeeConfirm = 1;
                this.setState({
                    ...this.state,
                    iPickupFeeConfirm: iPickupFeeConfirm
                })
                break;
            }
            default: break;
        }
    }
    // 运单状态过滤器
    OrderStatusFilter = (index) => {
        this.iCurrentOrderStatusFilter = index;
        this.RefreshOrderTable(this.arrOrderData);
        // this.setState({
        //     ...this.state,
        //     iCurrentOrderStatusFilter: index
        // })
    }
    // 运单状态过滤器
    TeamFilter = (index) => {
        this.iCurrentTeamFilter = index;
        this.RefreshOrderTable(this.arrOrderData);
    }
    // 获取备注
    GetComments = () => {
        axios('/GetLogisticComments', { params: { store_id: this.props.objStoreInfo.id, oid: this.objCurrentOrder.oid } })
            .then(({ data }) => {

                for (let i = 0; i < data.length; i++) {
                    if (data[i].generate_time === undefined || data[i].generate_time === null || data[i].generate_time === "")
                        data[i].generate_time = data[i].update_time;
                    if (this.refs['comments' + i.toString()] !== undefined)
                        this.refs['comments' + i.toString()].state.value = data[i].comments;
                    data[i].edit = false;
                }
                this.arrOrderComments = data;
                if (this.arrOrderComments.length <= 0) {
                    if (this.objCurrentOrder.comments !== undefined && this.objCurrentOrder.comments !== null && this.objCurrentOrder.comments !== "" &&
                        this.iDeleteComment < 0) {
                        let objComment = {
                            oid: this.objCurrentOrder.oid,
                            store_id: this.props.objStoreInfo.id,
                            user_id: this.objCurrentOrder.user_id,
                            user_name: this.objCurrentOrder.user_name,
                            mark: "",
                            comments: this.objCurrentOrder.comments,
                            generate_time: this.objCurrentOrder.generate_time,
                            update_time: this.objCurrentOrder.generate_time,
                            delete: 1
                        }
                        this.arrOrderComments.push(objComment);
                        axios.post('/EditLogisticComments', objComment)
                            .then(({ data }) => {
                                this.objCurrentOrder.comments = "";
                                if (data.status === 1)
                                    this.GetComments();
                            }).catch(function (error) { console.log(error); });
                    }
                }
                this.iDeleteComment = -1;
                this.setState({
                    ...this.state,
                    bEditComments: false,
                    bNewComment: false
                })
            }).catch(function (error) { console.log(error); });
    }
    // 新建备注
    NewComment = () => {
        this.objNewComment = {
            oid: this.objCurrentOrder.oid,
            store_id: this.props.objStoreInfo.id,
            user_id: this.props.iUserID,
            user_name: this.props.strUserName,
            mark: "",
            comments: "",
            update_time: new Date(),
            generate_time: new Date()
        }
        this.setState({
            ...this.state,
            bNewComment: true
        })
    }
    // 提交备注
    SubmitComments = (type, index) => {
        let strComments = "";
        if (type === 0)
            strComments = this.refs.new_comment.state.value;
        else {
            if (this.state.bViewAllComments)
                strComments = this.refs['view_comments' + index.toString()].state.value;
            else
                strComments = this.refs['comments' + index.toString()].state.value;
        }
        if (strComments === null || strComments === undefined || strComments === "") {
            message.warning('备注不能为空');
            return;
        }
        let objComments = null;
        if (type === 0)
            objComments = {
                oid: this.objCurrentOrder.oid,
                store_id: this.props.objStoreInfo.id,
                user_id: this.props.iUserID,
                user_name: this.props.strUserName,
                mark: "",
                comments: strComments,
                update_time: new Date(),
                generate_time: new Date()
            }
        else {
            objComments = this.arrOrderComments[index];
            objComments.comments = strComments;
            objComments.update_time = new Date();
            delete objComments.edit;
        }
        objComments.update_time = objComments.update_time.toISOString();
        this.refs.new_comment.state.value = "";
        // 创建/更新的HouseBill
        message.loading({ content: '正在提交备注……', key: g_strMessageKey });
        axios.post('/EditLogisticComments', objComments)
            .then(({ data }) => {

                if (data.status === 1) {
                    let strMessage = "提交成功！";
                    this.GetComments();
                    message.success({ content: strMessage, key: g_strMessageKey, duration: 2 });


                } else {
                    message.error({ content: data.message, key: g_strMessageKey, duration: 2 });
                }
            }).catch(function (error) { console.log(error); });

    }
    // 编辑备注
    EditComments = (index) => {
        this.arrOrderComments[index].edit = true;

        this.setState({
            ...this.state,
            bEditComments: true
        })
    }
    // 删除备注
    DeleteComments = (type, index) => {
        if (type === 0 || index < 0) {
            this.setState({
                ...this.state,
                bNewComment: false
            })
        } else {
            this.iDeleteComment = index;
            this.setState({
                ...this.state,
                bDeleteComments: true
            })
        }
    }
    // 确认删除备注
    ConfirmDeleteComments = (type) => {
        if (type === 1) {
            axios.post('/DeleteLogisticComments', { id: this.arrOrderComments[this.iDeleteComment].id })
                .then(({ data }) => {
                    if (data.status === -1)
                        message.error({ content: "删除备注失败，请重新尝试！", key: g_strMessageKey, duration: 2 });
                    else
                        message.success({ content: "备注已删除！", key: g_strMessageKey, duration: 2 });

                    this.GetComments();
                    this.setState({
                        ...this.state,
                        bDeleteComments: false
                    })
                }).catch(function (error) { console.log(error); });
        } else {
            this.setState({
                ...this.state,
                bDeleteComments: false
            })
        }
    }
    // 搜索/筛选客户
    SearchClient = () => {
        this.setState({
            ...this.state,
            bLoading: false
        })
    }
    // 打开预报文本
    OpenReportText = (index) => {
        this.strEmailTextHaiPai = this.strEmailTextOgeHaiPai;
        if (this.arrHouseBill[index].path === 0) // 换单
            this.strEmailTextHaiPai = this.strEmailTextOgeHuanDan;
        if (this.arrHouseBill[index].path === 2) // 自提
            this.strEmailTextHaiPai = this.strEmailTextOgeZiTi;


        console.log("OpenReportText : ", index, this.arrHouseBill[index]);
        if (this.arrHouseBill[index].receiver_name === null || this.arrHouseBill[index].receiver_name === undefined)
            this.arrHouseBill[index].receiver_name = "";
        this.strEmailTextHaiPai = this.strEmailTextHaiPai.replaceAll('名字', this.arrHouseBill[index].receiver_name);
        this.strEmailTextHaiPai = this.strEmailTextHaiPai.replaceAll('麦头', this.arrHouseBill[index].mark);
        this.strEmailTextHaiPai = this.strEmailTextHaiPai.replaceAll('港口城市', this.objCurrentOrder.port);
        this.strEmailTextHaiPai = this.strEmailTextHaiPai.replaceAll('目的地城市', this.objCurrentOrder.destination);
        this.strEmailTextHaiPai = this.strEmailTextHaiPai.replaceAll('eta', this.objCurrentOrder.eta);
        this.strEmailTextHaiPai = this.strEmailTextHaiPai.replaceAll('etd', this.objCurrentOrder.etd);
        this.strEmailTextHaiPai = this.strEmailTextHaiPai.replaceAll('ADDRESS', this.arrHouseBill[index].receiver_address);
        this.strEmailTextHaiPai = this.strEmailTextHaiPai.replaceAll('PHONE', this.arrHouseBill[index].receiver_phone);
        this.setState({
            ...this.state,
            bReportDlg: true
        })
    }
    CloseReportDlg = () => {
        this.setState({
            ...this.state,
            bReportDlg: false
        })
    }
    // 打开放货文本
    OpenReleaseText = (index) => {

        this.iCurrentHouseBill = index;

        this.strReleaseText = this.strReleaseTextOge;

        // console.log("仓库名 : ", this.objCurrentOrder.storage);
        // console.log("仓库ID : ", this.objCurrentOrder.storage_id);

        // console.log("仓库信息: ", global.arrStorageData);

        let objTargetStorage = null;
        for (let i = 0; i < global.arrStorageData.length; i++) {
            if (global.arrStorageData[i].id === this.objCurrentOrder.storage_id) {
                objTargetStorage = global.arrStorageData[i];
                break;
            }
        }
        if (objTargetStorage == null) {
            for (let i = 0; i < global.arrStorageData.length; i++) {
                if (global.arrStorageData[i].name === this.objCurrentOrder.storage) {
                    objTargetStorage = global.arrStorageData[i];
                    break;
                }
            }
            if (objTargetStorage != null) {
                if (objTargetStorage.address == null)
                    objTargetStorage.address = "";
                if (objTargetStorage.phone == null)
                    objTargetStorage.phone = "";
                if (objTargetStorage.comments == null)
                    objTargetStorage.comments = "";
            }

        }
        let strStorageInfo = this.objCurrentOrder.storage + "\n";
        if (objTargetStorage != null) {
            strStorageInfo += objTargetStorage.address + "\n";
            strStorageInfo += "T: " + objTargetStorage.phone + "\n";
            strStorageInfo += objTargetStorage.comments;
        }
        let strReceiverInfo = this.arrHouseBill[this.iCurrentHouseBill].receiver_name + " " + this.arrHouseBill[this.iCurrentHouseBill].receiver_address;

        console.log("strStorageInfo : ", objTargetStorage);

        // console.log("唛头 : ", this.arrHouseBill[this.iCurrentHouseBill].mark);
        // console.log("收件人 : ", this.arrHouseBill[this.iCurrentHouseBill].receiver_name);
        // console.log("免仓储日 : ", this.objCurrentOrder.free_storage_date);
        // console.log("编号 : ", this.objCurrentOrder.oid);
        console.log("HouseBill : ", this.arrHouseBill[this.iCurrentHouseBill]);
        // console.log("包裹数 : ", this.objCurrentOrder.num);

        this.strReleaseText = this.strReleaseText.replaceAll('收货人', this.arrHouseBill[this.iCurrentHouseBill].receiver_name);
        this.strReleaseText = this.strReleaseText.replaceAll('免仓储日', this.objCurrentOrder.free_storage_date);
        this.strReleaseText = this.strReleaseText.replaceAll('编号', this.objCurrentOrder.oid_before +
            this.objCurrentOrder.oid + this.arrHouseBill[this.iCurrentHouseBill].house_bill_no);
        this.strReleaseText = this.strReleaseText.replaceAll('仓库信息', strStorageInfo);
        this.strReleaseText = this.strReleaseText.replaceAll('包裹数', this.arrHouseBill[this.iCurrentHouseBill].num_pkg);
        this.strReleaseText = this.strReleaseText.replaceAll('收件信息', strReceiverInfo);
        console.log("this.strReleaseText : ", this.strReleaseText);

        this.setState({
            ...this.state,
            bReleaseDlg: true
        })
    }
    CloseReleaseDlg = () => {
        this.setState({
            ...this.state,
            bReleaseDlg: false
        })
    }
    // 复制粘贴
    CopyPasteAction = () => {
        message.info("预报信息已复制！");
        if (this.state.bReleaseDlg)
            navigator.clipboard.writeText(this.strReleaseText);
        if (this.state.bReportDlg)
            navigator.clipboard.writeText(this.strEmailTextHaiPai);
    }
    // 统一收费信息变化
    AllFeeInfoChange = (objOrder, index, e) => {

        // 对输入内容的处理
        let strInput = e.target.value;
        if (strInput.indexOf(' ') >= 0) {
            strInput = strInput.replaceAll(' ', '')
        }
        if (strInput.indexOf(',') >= 0) {
            strInput = strInput.replaceAll(',', '')
        }
        if (strInput.indexOf('，') >= 0) {
            strInput = strInput.replaceAll('，', '')
        }
        if (strInput === '' || strInput === null || strInput === undefined)
            strInput = 0.00;

        let fResult = 0.00;
        // 0. 代理费
        if (index === 0) {
            this.refs.agent_fee.state.value = strInput;
            objOrder.agent_fee = this.refs.agent_fee.state.value;
            fResult = parseFloat(this.refs.agent_fee.state.value) - parseFloat(this.refs.agent_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.agent_benefit.state.value = fResult;
        }
        if (index === 1) {
            this.refs.agent_cost.state.value = strInput;
            objOrder.agent_cost = this.refs.agent_cost.state.value;
            fResult = parseFloat(this.refs.agent_fee.state.value) - parseFloat(this.refs.agent_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.agent_benefit.state.value = fResult;
        }
        // 1. 开船费
        if (index === 2) {
            this.refs.ship_fee.state.value = strInput;
            objOrder.ship_fee = this.refs.ship_fee.state.value;
            fResult = parseFloat(this.refs.ship_fee.state.value) - parseFloat(this.refs.ship_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.ship_benefit.state.value = fResult;
        }
        if (index === 3) {
            this.refs.ship_cost.state.value = strInput;
            objOrder.ship_cost = this.refs.ship_cost.state.value;
            fResult = parseFloat(this.refs.ship_fee.state.value) - parseFloat(this.refs.ship_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.ship_benefit.state.value = fResult;
        }
        // 2. 码头费
        if (index === 4) {
            this.refs.port_fee.state.value = strInput;
            objOrder.port_fee = this.refs.port_fee.state.value;
            fResult = parseFloat(this.refs.port_fee.state.value) - parseFloat(this.refs.port_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.port_benefit.state.value = fResult;
        }
        if (index === 5) {
            this.refs.port_cost.state.value = strInput;
            objOrder.port_cost = this.refs.port_cost.state.value;
            fResult = parseFloat(this.refs.port_fee.state.value) - parseFloat(this.refs.port_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.port_benefit.state.value = fResult;
        }
        // 3. 拖柜费
        if (index === 6) {
            this.refs.truck_fee.state.value = strInput;
            objOrder.truck_fee = this.refs.truck_fee.state.value;
            fResult = parseFloat(this.refs.truck_fee.state.value) - parseFloat(this.refs.truck_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.truck_benefit.state.value = fResult;
        }
        if (index === 7) {
            this.refs.truck_cost.state.value = strInput;
            objOrder.truck_cost = this.refs.truck_cost.state.value;
            fResult = parseFloat(this.refs.truck_fee.state.value) - parseFloat(this.refs.truck_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.truck_benefit.state.value = fResult;
        }
        // 4. 预提柜费
        if (index === 8) {
            this.refs.prepick_fee.state.value = strInput;
            objOrder.prepick_fee = this.refs.prepick_fee.state.value;
            fResult = parseFloat(this.refs.prepick_fee.state.value) - parseFloat(this.refs.prepick_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.prepick_benefit.state.value = fResult;
        }
        if (index === 9) {
            this.refs.prepick_cost.state.value = strInput;
            objOrder.prepick_cost = this.refs.prepick_cost.state.value;
            fResult = parseFloat(this.refs.prepick_fee.state.value) - parseFloat(this.refs.prepick_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.prepick_benefit.state.value = fResult;
        }
        // 5. 堆场费
        if (index === 10) {
            this.refs.stack_fee.state.value = strInput;
            objOrder.stack_fee = this.refs.stack_fee.state.value;
            fResult = parseFloat(this.refs.stack_fee.state.value) - parseFloat(this.refs.stack_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.stack_benefit.state.value = fResult;
        }
        if (index === 11) {
            this.refs.stack_cost.state.value = strInput;
            objOrder.stack_cost = this.refs.stack_cost.state.value;
            fResult = parseFloat(this.refs.stack_fee.state.value) - parseFloat(this.refs.stack_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.stack_benefit.state.value = fResult;
        }
        // 6. 车架费
        if (index === 12) {
            this.refs.car_frame_fee.state.value = strInput;
            objOrder.car_frame_fee = this.refs.car_frame_fee.state.value;
            fResult = parseFloat(this.refs.car_frame_fee.state.value) - parseFloat(this.refs.car_frame_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.car_frame_benefit.state.value = fResult;
        }
        if (index === 13) {
            this.refs.car_frame_cost.state.value = strInput;
            objOrder.car_frame_cost = this.refs.car_frame_cost.state.value;
            fResult = parseFloat(this.refs.car_frame_fee.state.value) - parseFloat(this.refs.car_frame_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.car_frame_benefit.state.value = fResult;
        }
        // 7. 危险费
        if (index === 14) {
            this.refs.danger_fee.state.value = strInput;
            objOrder.danger_fee = this.refs.danger_fee.state.value;
            fResult = parseFloat(this.refs.danger_fee.state.value) - parseFloat(this.refs.danger_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.danger_benefit.state.value = fResult;
        }
        if (index === 15) {
            this.refs.danger_cost.state.value = strInput;
            objOrder.danger_cost = this.refs.danger_cost.state.value;
            fResult = parseFloat(this.refs.danger_fee.state.value) - parseFloat(this.refs.danger_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.danger_benefit.state.value = fResult;
        }
        // 8. 空跑费
        if (index === 16) {
            this.refs.null_fee.state.value = strInput;
            objOrder.null_fee = this.refs.null_fee.state.value;
            fResult = parseFloat(this.refs.null_fee.state.value) - parseFloat(this.refs.null_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.null_benefit.state.value = fResult;
        }
        if (index === 17) {
            this.refs.null_cost.state.value = strInput;
            objOrder.null_cost = this.refs.null_cost.state.value;
            fResult = parseFloat(this.refs.null_fee.state.value) - parseFloat(this.refs.null_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.null_benefit.state.value = fResult;
        }
        // 9. 拆柜费
        if (index === 18) {
            this.refs.open_closet_fee.state.value = strInput;
            objOrder.open_closet_fee = this.refs.open_closet_fee.state.value;
            fResult = parseFloat(this.refs.open_closet_fee.state.value) - parseFloat(this.refs.open_closet_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.open_closet_benefit.state.value = fResult;
        }
        if (index === 19) {
            this.refs.open_closet_cost.state.value = strInput;
            objOrder.open_closet_cost = this.refs.open_closet_cost.state.value;
            fResult = parseFloat(this.refs.open_closet_fee.state.value) - parseFloat(this.refs.open_closet_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.open_closet_benefit.state.value = fResult;
        }
        // 10. 分货费
        if (index === 20) {
            this.refs.divide_item_fee.state.value = strInput;
            objOrder.divide_item_fee = this.refs.divide_item_fee.state.value;
            fResult = parseFloat(this.refs.divide_item_fee.state.value) - parseFloat(this.refs.divide_item_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.divide_item_benefit.state.value = fResult;
        }
        if (index === 21) {
            this.refs.divide_item_cost.state.value = strInput;
            objOrder.divide_item_cost = this.refs.divide_item_cost.state.value;
            fResult = parseFloat(this.refs.divide_item_fee.state.value) - parseFloat(this.refs.divide_item_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.divide_item_benefit.state.value = fResult;
        }
        // 11. 打板费
        if (index === 22) {
            this.refs.on_plate_fee.state.value = strInput;
            objOrder.on_plate_fee = this.refs.on_plate_fee.state.value;
            fResult = parseFloat(this.refs.on_plate_fee.state.value) - parseFloat(this.refs.on_plate_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.on_plate_benefit.state.value = fResult;
        }
        if (index === 23) {
            this.refs.on_plate_cost.state.value = strInput;
            objOrder.on_plate_cost = this.refs.on_plate_cost.state.value;
            fResult = parseFloat(this.refs.on_plate_fee.state.value) - parseFloat(this.refs.on_plate_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.on_plate_benefit.state.value = fResult;
        }
        // 12. 拖柜费
        if (index === 24) {
            this.refs.tag_fee.state.value = strInput;
            objOrder.tag_fee = this.refs.tag_fee.state.value;
            fResult = parseFloat(this.refs.tag_fee.state.value) - parseFloat(this.refs.tag_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.tag_benefit.state.value = fResult;
        }
        if (index === 25) {
            this.refs.tag_cost.state.value = strInput;
            objOrder.tag_cost = this.refs.tag_cost.state.value;
            fResult = parseFloat(this.refs.tag_fee.state.value) - parseFloat(this.refs.tag_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.tag_benefit.state.value = fResult;
        }
        // 13. 超规费
        if (index === 26) {
            this.refs.oversize_fee.state.value = strInput;
            objOrder.oversize_fee = this.refs.oversize_fee.state.value;
            fResult = parseFloat(this.refs.oversize_fee.state.value) - parseFloat(this.refs.oversize_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.oversize_benefit.state.value = fResult;
        }
        if (index === 27) {
            this.refs.oversize_cost.state.value = strInput;
            objOrder.oversize_cost = this.refs.oversize_cost.state.value;
            fResult = parseFloat(this.refs.oversize_fee.state.value) - parseFloat(this.refs.oversize_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.oversize_benefit.state.value = fResult;
        }
        // 14. 仓储费
        if (index === 28) {
            this.refs.storage_fee.state.value = strInput;
            objOrder.storage_fee = this.refs.storage_fee.state.value;
            fResult = parseFloat(this.refs.storage_fee.state.value) - parseFloat(this.refs.storage_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.storage_benefit.state.value = fResult;
        }
        if (index === 29) {
            this.refs.storage_cost.state.value = strInput;
            objOrder.storage_cost = this.refs.storage_cost.state.value;
            fResult = parseFloat(this.refs.storage_fee.state.value) - parseFloat(this.refs.storage_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.storage_benefit.state.value = fResult;
        }
        // 15. 出仓费
        if (index === 30) {
            this.refs.storage_export_fee.state.value = strInput;
            objOrder.storage_export_fee = this.refs.storage_export_fee.state.value;
            fResult = parseFloat(this.refs.storage_export_fee.state.value) - parseFloat(this.refs.storage_export_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.storage_export_benefit.state.value = fResult;
        }
        if (index === 31) {
            this.refs.storage_export_cost.state.value = strInput;
            objOrder.storage_export_cost = this.refs.storage_export_cost.state.value;
            fResult = parseFloat(this.refs.storage_export_fee.state.value) - parseFloat(this.refs.storage_export_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.storage_export_benefit.state.value = fResult;
        }
        // 16. 报关费
        if (index === 32) {
            this.refs.custom_fee.state.value = strInput;
            objOrder.custom_fee = this.refs.custom_fee.state.value;
            fResult = parseFloat(this.refs.custom_fee.state.value) - parseFloat(this.refs.custom_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.custom_benefit.state.value = fResult;
        }
        if (index === 33) {
            this.refs.custom_cost.state.value = strInput;
            objOrder.custom_cost = this.refs.custom_cost.state.value;
            fResult = parseFloat(this.refs.custom_fee.state.value) - parseFloat(this.refs.custom_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.custom_benefit.state.value = fResult;
        }
        // 17. 关税
        if (index === 34) {
            this.refs.tax_fee.state.value = strInput;
            objOrder.tax_fee = this.refs.tax_fee.state.value;
            fResult = parseFloat(this.refs.tax_fee.state.value) - parseFloat(this.refs.tax_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.tax_benefit.state.value = fResult;
        }
        if (index === 35) {
            this.refs.tax_cost.state.value = strInput;
            objOrder.tax_cost = this.refs.tax_cost.state.value;
            fResult = parseFloat(this.refs.tax_fee.state.value) - parseFloat(this.refs.tax_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.tax_benefit.state.value = fResult;
        }
        // 18. GAT费
        if (index === 36) {
            this.refs.gst_fee.state.value = strInput;
            objOrder.gst_fee = this.refs.gst_fee.state.value;
            fResult = parseFloat(this.refs.gst_fee.state.value) - parseFloat(this.refs.gst_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.gst_benefit.state.value = fResult;
        }
        if (index === 37) {
            this.refs.gst_cost.state.value = strInput;
            objOrder.gst_cost = this.refs.gst_cost.state.value;
            fResult = parseFloat(this.refs.gst_fee.state.value) - parseFloat(this.refs.gst_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.gst_benefit.state.value = fResult;
        }
        // 19. 超项费
        if (index === 38) {
            this.refs.overitem_fee.state.value = strInput;
            objOrder.overitem_fee = this.refs.overitem_fee.state.value;
            fResult = parseFloat(this.refs.overitem_fee.state.value) - parseFloat(this.refs.overitem_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.overitem_benefit.state.value = fResult;
        }
        if (index === 39) {
            this.refs.overitem_cost.state.value = strInput;
            objOrder.overitem_cost = this.refs.overitem_cost.state.value;
            fResult = parseFloat(this.refs.overitem_fee.state.value) - parseFloat(this.refs.overitem_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.overitem_benefit.state.value = fResult;
        }
        // 20. CFIA费
        if (index === 40) {
            this.refs.cfia_fee.state.value = strInput;
            objOrder.cfia_fee = this.refs.cfia_fee.state.value;
            fResult = parseFloat(this.refs.cfia_fee.state.value) - parseFloat(this.refs.cfia_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.cfia_benefit.state.value = fResult;
        }
        if (index === 41) {
            this.refs.cfia_cost.state.value = strInput;
            objOrder.cfia_cost = this.refs.cfia_cost.state.value;
            fResult = parseFloat(this.refs.cfia_fee.state.value) - parseFloat(this.refs.cfia_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.cfia_benefit.state.value = fResult;
        }
        // 21. 报关费
        if (index === 42) {
            this.refs.check_fee.state.value = strInput;
            objOrder.check_fee = this.refs.check_fee.state.value;
            fResult = parseFloat(this.refs.check_fee.state.value) - parseFloat(this.refs.check_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.check_benefit.state.value = fResult;
        }
        if (index === 43) {
            this.refs.check_cost.state.value = strInput;
            objOrder.check_cost = this.refs.check_cost.state.value;
            fResult = parseFloat(this.refs.check_fee.state.value) - parseFloat(this.refs.check_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.check_benefit.state.value = fResult;
        }
        // 22. 
        if (index === 44) {
            this.refs.other_fee.state.value = strInput;
            objOrder.other_fee = this.refs.other_fee.state.value;
            fResult = parseFloat(this.refs.other_fee.state.value) - parseFloat(this.refs.other_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.other_benefit.state.value = fResult;
        }
        if (index === 45) {
            this.refs.other_cost.state.value = strInput;
            objOrder.other_cost = this.refs.other_cost.state.value;
            fResult = parseFloat(this.refs.other_fee.state.value) - parseFloat(this.refs.other_cost.state.value);
            if (typeof (fResult) === 'number')
                fResult = fResult.toFixed(2);
            this.refs.other_benefit.state.value = fResult;
        }

        this.bAllFeeChange = true;
        this.setState({
            ...this.state,
            bAllFeeChange: true
        })
    }
    // 统一收费统计
    CalculateAllFee = (objOrder) => {
        // 总收费计算
        let fAllTotalFee = 0.0;
        if (this.arrAllFee[0].active === 1)
            fAllTotalFee += parseFloat(objOrder.agent_fee);
        if (this.arrAllFee[1].active === 1)
            fAllTotalFee += parseFloat(objOrder.ship_fee);
        if (this.arrAllFee[2].active === 1)
            fAllTotalFee += parseFloat(objOrder.port_fee);
        if (this.arrAllFee[3].active === 1)
            fAllTotalFee += parseFloat(objOrder.truck_fee);
        if (this.arrAllFee[4].active === 1)
            fAllTotalFee += parseFloat(objOrder.prepick_fee);
        if (this.arrAllFee[5].active === 1)
            fAllTotalFee += parseFloat(objOrder.stack_fee);
        if (this.arrAllFee[6].active === 1)
            fAllTotalFee += parseFloat(objOrder.car_frame_fee);
        if (this.arrAllFee[7].active === 1)
            fAllTotalFee += parseFloat(objOrder.danger_fee);
        if (this.arrAllFee[8].active === 1)
            fAllTotalFee += parseFloat(objOrder.null_fee);
        if (this.arrAllFee[9].active === 1)
            fAllTotalFee += parseFloat(objOrder.open_closet_fee);
        if (this.arrAllFee[10].active === 1)
            fAllTotalFee += parseFloat(objOrder.divide_item_fee);
        if (this.arrAllFee[11].active === 1)
            fAllTotalFee += parseFloat(objOrder.on_plate_fee);
        if (this.arrAllFee[12].active === 1)
            fAllTotalFee += parseFloat(objOrder.tag_fee);
        if (this.arrAllFee[13].active === 1)
            fAllTotalFee += parseFloat(objOrder.oversize_fee);
        if (this.arrAllFee[14].active === 1)
            fAllTotalFee += parseFloat(objOrder.storage_fee);
        if (this.arrAllFee[15].active === 1)
            fAllTotalFee += parseFloat(objOrder.storage_export_fee);
        if (this.arrAllFee[16].active === 1)
            fAllTotalFee += parseFloat(objOrder.custom_fee);
        if (this.arrAllFee[17].active === 1)
            fAllTotalFee += parseFloat(objOrder.tax_fee);
        if (this.arrAllFee[18].active === 1)
            fAllTotalFee += parseFloat(objOrder.gst_fee);
        if (this.arrAllFee[19].active === 1)
            fAllTotalFee += parseFloat(objOrder.overitem_fee);
        if (this.arrAllFee[20].active === 1)
            fAllTotalFee += parseFloat(objOrder.cfia_fee);
        if (this.arrAllFee[21].active === 1)
            fAllTotalFee += parseFloat(objOrder.check_fee);
        if (this.arrAllFee[22].active === 1)
            fAllTotalFee += parseFloat(objOrder.other_fee);
        // 总成本计算
        let fAllTotalCost = 0.0;
        if (this.arrAllFee[0].active === 1)
            fAllTotalCost += parseFloat(objOrder.agent_cost);
        if (this.arrAllFee[1].active === 1)
            fAllTotalCost += parseFloat(objOrder.ship_cost);
        if (this.arrAllFee[2].active === 1)
            fAllTotalCost += parseFloat(objOrder.port_cost);
        if (this.arrAllFee[3].active === 1)
            fAllTotalCost += parseFloat(objOrder.truck_cost);
        if (this.arrAllFee[4].active === 1)
            fAllTotalCost += parseFloat(objOrder.prepick_cost);
        if (this.arrAllFee[5].active === 1)
            fAllTotalCost += parseFloat(objOrder.stack_cost);
        if (this.arrAllFee[6].active === 1)
            fAllTotalCost += parseFloat(objOrder.car_frame_cost);
        if (this.arrAllFee[7].active === 1)
            fAllTotalCost += parseFloat(objOrder.danger_cost);
        if (this.arrAllFee[8].active === 1)
            fAllTotalCost += parseFloat(objOrder.null_cost);
        if (this.arrAllFee[9].active === 1)
            fAllTotalCost += parseFloat(objOrder.open_closet_cost);
        if (this.arrAllFee[10].active === 1)
            fAllTotalCost += parseFloat(objOrder.divide_item_cost);
        if (this.arrAllFee[11].active === 1)
            fAllTotalCost += parseFloat(objOrder.on_plate_cost);
        if (this.arrAllFee[12].active === 1)
            fAllTotalCost += parseFloat(objOrder.tag_cost);
        if (this.arrAllFee[13].active === 1)
            fAllTotalCost += parseFloat(objOrder.oversize_cost);
        if (this.arrAllFee[14].active === 1)
            fAllTotalCost += parseFloat(objOrder.storage_cost);
        if (this.arrAllFee[15].active === 1)
            fAllTotalCost += parseFloat(objOrder.storage_export_cost);
        if (this.arrAllFee[16].active === 1)
            fAllTotalCost += parseFloat(objOrder.custom_cost);
        if (this.arrAllFee[17].active === 1)
            fAllTotalCost += parseFloat(objOrder.tax_cost);
        if (this.arrAllFee[18].active === 1)
            fAllTotalCost += parseFloat(objOrder.gst_cost);
        if (this.arrAllFee[19].active === 1)
            fAllTotalCost += parseFloat(objOrder.overitem_cost);
        if (this.arrAllFee[20].active === 1)
            fAllTotalCost += parseFloat(objOrder.cfia_cost);
        if (this.arrAllFee[21].active === 1)
            fAllTotalCost += parseFloat(objOrder.check_cost);
        if (this.arrAllFee[22].active === 1)
            fAllTotalCost += parseFloat(objOrder.other_cost);

        let fAllTotalBenefit = parseFloat(fAllTotalFee) - parseFloat(fAllTotalCost);
        if (typeof (fAllTotalFee) === 'number')
            fAllTotalFee = fAllTotalFee.toFixed(2);
        if (typeof (fAllTotalCost) === 'number')
            fAllTotalCost = fAllTotalCost.toFixed(2);
        if (typeof (fAllTotalBenefit) === 'number')
            fAllTotalBenefit = fAllTotalBenefit.toFixed(2);

        objOrder.all_total_fee = fAllTotalFee;
        objOrder.all_total_cost = fAllTotalCost;
        objOrder.all_total_benefit = fAllTotalBenefit;
        // this.refs.state.all_total_fee.value = fAllTotalFee;
        // this.refs.state.all_total_cost.value = fAllTotalCost;
        // this.refs.state.all_total_benefit.value = fAllTotalBenefit;

        this.iCurrentAllFee = -1;
        for (let i = 0; i < this.arrAllFee.length; i++) {
            if (this.arrAllFee[i].active === 0) {
                this.iCurrentAllFee = i;
                break;
            }
        }
        // console.log("Calculate All Fee ", this.iCurrentAllFee);

        return objOrder;
    }
    // 切换 All Fee
    AllFeeSwitch = (index) => {
        // this.iCurrentAllFee = index;
        this.setState({
            ...this.state,
            bAllFeeChange: true,
            iCurrentAllFee: index
        })
    }
    // 新增 All Fee
    AddAllFee = (index) => {
        if (index < 0 || index >= this.arrAllFee.length)
            return;
        this.arrAllFee[index].active = 1;
        // 0. 代理费
        if (index === 0) {
            this.arrOrderData[this.state.selectedRowKeys[0]].agent_active = 1;
        }
        // 1. 开船费
        if (index === 1) {
            this.arrOrderData[this.state.selectedRowKeys[0]].ship_active = 1;
        }
        // 2. 码头费
        if (index === 2) {
            this.arrOrderData[this.state.selectedRowKeys[0]].port_active = 1;
        }
        // 3. 拖柜费
        if (index === 3) {
            this.arrOrderData[this.state.selectedRowKeys[0]].truck_active = 1;
        }
        // 4. 预提柜费
        if (index === 4) {
            this.arrOrderData[this.state.selectedRowKeys[0]].prepick_active = 1;
        }
        // 5. 堆场费
        if (index === 5) {
            this.arrOrderData[this.state.selectedRowKeys[0]].stack_active = 1;
        }
        // 6. 车架费
        if (index === 6) {
            this.arrOrderData[this.state.selectedRowKeys[0]].car_frame_active = 1;
        }
        // 7. 危险费
        if (index === 7) {
            this.arrOrderData[this.state.selectedRowKeys[0]].danger_active = 1;
        }
        // 8. 空跑费
        if (index === 8) {
            this.arrOrderData[this.state.selectedRowKeys[0]].null_active = 1;
        }
        // 9. 拆柜费
        if (index === 9) {
            this.arrOrderData[this.state.selectedRowKeys[0]].open_active = 1;
        }
        // 10. 分货费
        if (index === 10) {
            this.arrOrderData[this.state.selectedRowKeys[0]].divide_active = 1;
        }
        // 11. 打板费
        if (index === 11) {
            this.arrOrderData[this.state.selectedRowKeys[0]].on_plate_active = 1;
        }
        // 12. 贴标费
        if (index === 12) {
            this.arrOrderData[this.state.selectedRowKeys[0]].tag_active = 1;
        }
        // 13. 超规费
        if (index === 13) {
            this.arrOrderData[this.state.selectedRowKeys[0]].oversize_active = 1;
        }
        // 14. 仓储费
        if (index === 14) {
            this.arrOrderData[this.state.selectedRowKeys[0]].storage_active = 1;
        }
        // 15. 出仓费
        if (index === 15) {
            this.arrOrderData[this.state.selectedRowKeys[0]].storage_export_active = 1;
        }
        // 16. 报关费
        if (index === 16) {
            this.arrOrderData[this.state.selectedRowKeys[0]].custom_active = 1;
        }
        // 17. Tax费
        if (index === 17) {
            this.arrOrderData[this.state.selectedRowKeys[0]].tax_active = 1;
        }
        // 18. GST费
        if (index === 18) {
            this.arrOrderData[this.state.selectedRowKeys[0]].gst_active = 1;
        }
        // 19. 超项费
        if (index === 19) {
            this.arrOrderData[this.state.selectedRowKeys[0]].overitem_active = 1;
        }
        // 20. CFIA费
        if (index === 20) {
            this.arrOrderData[this.state.selectedRowKeys[0]].cfia_active = 1;
        }
        // 21. 查验费
        if (index === 21) {
            this.arrOrderData[this.state.selectedRowKeys[0]].check_active = 1;
        }
        // 22. 其他费用
        if (index === 22) {
            this.arrOrderData[this.state.selectedRowKeys[0]].other_active = 1;
        }


        this.iCurrentAllFee = -1;
        for (let i = 0; i < this.arrAllFee.length; i++) {
            if (this.arrAllFee[i].active === 0) {
                this.iCurrentAllFee = i;
                break;
            }
        }

        this.setState({
            ...this.state,
            bAllFeeChange: true,
            iCurrentAllFee: this.iCurrentAllFee
        });
    }
    // 删除 All Fee
    DeleteAllFee = (index) => {
        this.arrAllFee[index].active = 0;
        // 0. 代理费
        if (index === 0) {
            this.arrOrderData[this.state.selectedRowKeys[0]].agent_active = 0;
            this.objCurrentOrder.agent_active = 0;
        }
        // 1. 开船费
        if (index === 1) {
            this.arrOrderData[this.state.selectedRowKeys[0]].ship_active = 0;
        }
        // 2. 码头费
        if (index === 2) {
            this.arrOrderData[this.state.selectedRowKeys[0]].port_active = 0;
        }
        // 3. 拖柜费
        if (index === 3) {
            this.arrOrderData[this.state.selectedRowKeys[0]].truck_active = 0;
        }
        // 4. 预提柜费
        if (index === 4) {
            this.arrOrderData[this.state.selectedRowKeys[0]].prepick_active = 0;
        }
        // 5. 堆场费
        if (index === 5) {
            this.arrOrderData[this.state.selectedRowKeys[0]].stack_active = 0;
        }
        // 6. 车架费
        if (index === 6) {
            this.arrOrderData[this.state.selectedRowKeys[0]].car_frame_active = 0;
        }
        // 7. 危险费
        if (index === 7) {
            this.arrOrderData[this.state.selectedRowKeys[0]].danger_active = 0;
        }
        // 8. 空跑费
        if (index === 8) {
            this.arrOrderData[this.state.selectedRowKeys[0]].null_active = 0;
        }
        // 9. 拆柜费
        if (index === 9) {
            this.arrOrderData[this.state.selectedRowKeys[0]].open_active = 0;
        }
        // 10. 分货费
        if (index === 10) {
            this.arrOrderData[this.state.selectedRowKeys[0]].divide_active = 0;
        }
        // 11. 打板费
        if (index === 11) {
            this.arrOrderData[this.state.selectedRowKeys[0]].on_plate_active = 0;
        }
        // 12. 贴标费
        if (index === 12) {
            this.arrOrderData[this.state.selectedRowKeys[0]].tag_active = 0;
        }
        // 13. 超规费
        if (index === 13) {
            this.arrOrderData[this.state.selectedRowKeys[0]].oversize_active = 0;
        }
        // 14. 仓储费
        if (index === 14) {
            this.arrOrderData[this.state.selectedRowKeys[0]].storage_active = 0;
        }
        // 15. 出仓费
        if (index === 15) {
            this.arrOrderData[this.state.selectedRowKeys[0]].storage_export_active = 0;
        }
        // 16. 报关费
        if (index === 16) {
            this.arrOrderData[this.state.selectedRowKeys[0]].custom_active = 0;
        }
        // 17. Tax费
        if (index === 17) {
            this.arrOrderData[this.state.selectedRowKeys[0]].tax_active = 0;
        }
        // 18. GST费
        if (index === 18) {
            this.arrOrderData[this.state.selectedRowKeys[0]].gst_active = 0;
        }
        // 19. 超项费
        if (index === 19) {
            this.arrOrderData[this.state.selectedRowKeys[0]].overitem_active = 0;
        }
        // 20. CFIA费
        if (index === 20) {
            this.arrOrderData[this.state.selectedRowKeys[0]].cfia_active = 0;
        }
        // 21. 查验费
        if (index === 21) {
            this.arrOrderData[this.state.selectedRowKeys[0]].check_active = 0;
        }
        // 22. 其他费用
        if (index === 22) {
            this.arrOrderData[this.state.selectedRowKeys[0]].other_active = 0;
        }

        this.iCurrentAllFee = -1;
        for (let i = 0; i < this.arrAllFee.length; i++) {
            if (this.arrAllFee[i].active === 0) {
                this.iCurrentAllFee = i;
                break;
            }
        }
        this.setState({
            ...this.state,
            bAllFeeChange: true
        });
    }
    // 保存前获取 All Fee
    GetAllFee = (objInputInfo) => {
        objInputInfo.agent_active = 0;
        if (this.arrAllFee[0].active === 1 && this.refs.agent_fee !== undefined) {
            objInputInfo.agent_active = 1;
            objInputInfo.agent_fee = this.refs.agent_fee.state.value;
            objInputInfo.agent_cost = this.refs.agent_cost.state.value;
        }
        objInputInfo.ship_active = 0;
        if (this.arrAllFee[1].active === 1 && this.refs.ship_fee !== undefined) {
            objInputInfo.ship_active = 1;
            objInputInfo.ship_fee = this.refs.ship_fee.state.value;
            objInputInfo.ship_cost = this.refs.ship_cost.state.value;
        }
        objInputInfo.port_active = 0;
        if (this.arrAllFee[2].active === 1 && this.refs.port_fee !== undefined) {
            objInputInfo.port_active = 1;
            objInputInfo.port_fee = this.refs.port_fee.state.value;
            objInputInfo.port_cost = this.refs.port_cost.state.value;
        }
        objInputInfo.truck_active = 0;
        if (this.arrAllFee[3].active === 1 && this.refs.truck_fee !== undefined) {
            objInputInfo.truck_active = 1;
            objInputInfo.truck_fee = this.refs.truck_fee.state.value;
            objInputInfo.truck_cost = this.refs.truck_cost.state.value;
        }
        objInputInfo.prepick_active = 0;
        if (this.arrAllFee[4].active === 1 && this.refs.prepick_fee !== undefined) {
            objInputInfo.prepick_active = 1;
            objInputInfo.prepick_fee = this.refs.prepick_fee.state.value;
            objInputInfo.prepick_cost = this.refs.prepick_cost.state.value;
        }
        objInputInfo.stack_active = 0;
        if (this.arrAllFee[5].active === 1 && this.refs.stack_fee !== undefined) {
            objInputInfo.stack_active = 1;
            objInputInfo.stack_fee = this.refs.stack_fee.state.value;
            objInputInfo.stack_cost = this.refs.stack_cost.state.value;
        }
        objInputInfo.car_frame_active = 0;
        if (this.arrAllFee[6].active === 1 && this.refs.car_frame_fee !== undefined) {
            objInputInfo.car_frame_active = 1;
            objInputInfo.car_frame_fee = this.refs.car_frame_fee.state.value;
            objInputInfo.car_frame_cost = this.refs.car_frame_cost.state.value;
        }
        objInputInfo.danger_active = 0;
        if (this.arrAllFee[7].active === 1 && this.refs.danger_fee !== undefined) {
            objInputInfo.danger_active = 1;
            objInputInfo.danger_fee = this.refs.danger_fee.state.value;
            objInputInfo.danger_cost = this.refs.danger_cost.state.value;
        }
        objInputInfo.null_active = 0;
        if (this.arrAllFee[8].active === 1 && this.refs.null_fee !== undefined) {
            objInputInfo.null_active = 1;
            objInputInfo.null_fee = this.refs.null_fee.state.value;
            objInputInfo.null_cost = this.refs.null_cost.state.value;
        }
        objInputInfo.open_active = 0;
        if (this.arrAllFee[9].active === 1 && this.refs.open_closet_fee !== undefined) {
            objInputInfo.open_active = 1;
            objInputInfo.open_closet_fee = this.refs.open_closet_fee.state.value;
            objInputInfo.open_closet_cost = this.refs.open_closet_cost.state.value;
        }
        objInputInfo.divide_active = 0;
        if (this.arrAllFee[10].active === 1 && this.refs.divide_item_fee !== undefined) {
            objInputInfo.divide_active = 1;
            objInputInfo.divide_item_fee = this.refs.divide_item_fee.state.value;
            objInputInfo.divide_item_cost = this.refs.divide_item_cost.state.value;
        }
        objInputInfo.on_plate_active = 0;
        if (this.arrAllFee[11].active === 1 && this.refs.on_plate_fee !== undefined) {
            objInputInfo.on_plate_active = 1;
            objInputInfo.on_plate_fee = this.refs.on_plate_fee.state.value;
            objInputInfo.on_plate_cost = this.refs.on_plate_cost.state.value;
        }
        objInputInfo.tag_active = 0;
        if (this.arrAllFee[12].active === 1 && this.refs.tag_fee !== undefined) {
            objInputInfo.tag_active = 1;
            objInputInfo.tag_fee = this.refs.tag_fee.state.value;
            objInputInfo.tag_cost = this.refs.tag_cost.state.value;
        }
        objInputInfo.oversize_active = 0;
        if (this.arrAllFee[13].active === 1 && this.refs.oversize_fee !== undefined) {
            objInputInfo.oversize_active = 1;
            objInputInfo.oversize_fee = this.refs.oversize_fee.state.value;
            objInputInfo.oversize_cost = this.refs.oversize_cost.state.value;
        }
        objInputInfo.storage_active = 0;
        if (this.arrAllFee[14].active === 1 && this.refs.storage_fee !== undefined) {
            objInputInfo.storage_active = 1;
            objInputInfo.storage_fee = this.refs.storage_fee.state.value;
            objInputInfo.storage_cost = this.refs.storage_cost.state.value;
        }
        objInputInfo.storage_export_active = 0;
        if (this.arrAllFee[15].active === 1 && this.refs.storage_export_fee !== undefined) {
            objInputInfo.storage_export_active = 1;
            objInputInfo.storage_export_fee = this.refs.storage_export_fee.state.value;
            objInputInfo.storage_export_cost = this.refs.storage_export_cost.state.value;
        }
        objInputInfo.custom_active = 0;
        if (this.arrAllFee[16].active === 1 && this.refs.custom_fee !== undefined) {
            objInputInfo.custom_active = 1;
            objInputInfo.custom_fee = this.refs.custom_fee.state.value;
            objInputInfo.custom_cost = this.refs.custom_cost.state.value;
        }
        objInputInfo.tax_active = 0;
        if (this.arrAllFee[17].active === 1 && this.refs.tax_fee !== undefined) {
            objInputInfo.tax_active = 1;
            objInputInfo.tax_fee = this.refs.tax_fee.state.value;
            objInputInfo.tax_cost = this.refs.tax_cost.state.value;
        }
        objInputInfo.gst_active = 0;
        if (this.arrAllFee[18].active === 1 && this.refs.gst_fee !== undefined) {
            objInputInfo.gst_active = 1;
            objInputInfo.gst_fee = this.refs.gst_fee.state.value;
            objInputInfo.gst_cost = this.refs.gst_cost.state.value;
        }
        objInputInfo.overitem_active = 0;
        if (this.arrAllFee[19].active === 1 && this.refs.overitem_fee !== undefined) {
            objInputInfo.overitem_active = 1;
            objInputInfo.overitem_fee = this.refs.overitem_fee.state.value;
            objInputInfo.overitem_cost = this.refs.overitem_cost.state.value;
        }
        objInputInfo.cfia_active = 0;
        if (this.arrAllFee[20].active === 1 && this.refs.cfia_fee !== undefined) {
            objInputInfo.cfia_active = 1;
            objInputInfo.cfia_fee = this.refs.cfia_fee.state.value;
            objInputInfo.cfia_cost = this.refs.cfia_cost.state.value;
        }
        objInputInfo.check_active = 0;
        if (this.arrAllFee[21].active === 1 && this.refs.check_fee !== undefined) {
            objInputInfo.check_active = 1;
            objInputInfo.check_fee = this.refs.check_fee.state.value;
            objInputInfo.check_cost = this.refs.check_cost.state.value;
        }
        objInputInfo.other_active = 0;
        if (this.arrAllFee[22].active === 1 && this.refs.other_fee !== undefined) {
            objInputInfo.other_active = 1;
            objInputInfo.other_fee = this.refs.other_fee.state.value;
            objInputInfo.other_cost = this.refs.other_cost.state.value;
            objInputInfo.other_fee_comments = this.refs.other_fee_comments.state.value;
        }


    }

    /* React自带函数 */
    // 卸载异步操作设置状态
    componentWillUnmount() {
        clearTimeout(this.timeouter)
        this.setState = (state, callback) => {
            return;
        }
    }
    // 渲染完毕……
    componentDidMount() {
        document.title = "物流管理系统";
        this.GetOrdersData();
        if (global.arrUserData.length <= 0)
            this.GetUsersData();
        if (global.arrClientData.length <= 0)
            this.GetClientsData();
        if (global.arrAgentData.length <= 0)
            this.GetAgentsData();
        if (global.arrTruckData.length <= 0)
            this.GetTrucksData();
        if (global.arrStorageData.length <= 0)
            this.GetStoragesData();
        if (global.arrCarTeamData.length <= 0)
            this.GetCarTeamsData();
    }

    render() {

        // 主体表格相关
        const { selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectedRowKeysChange,
        };
        // 创建对话框
        let objOrder = {
            key: 0, store_id: this.props.objStoreInfo.id, oid: '', type: 0, status: 0, service_type: 0,
            user_name: '', client: '', agent: '', storage: '', closet: '', express: '',

            num: 0, cbm: 0, weight: 0,
            etd: this.strToday, eta: this.strToday,
            oid_before: '',
            destination: '', comments: '', highlight: '',

            agent_fee: '', ship_fee: '', port_fee: '',

            order_release: 0, custom_release: 0,
            free_use_date: this.strToday, box_use_date: this.strToday,
            truck: '', truck_fee: '', prepick_fee: '', car_frame_fee: '',
            stack_fee: '', danger_fee: '', null_fee: '', port_other_fee: ''
        }
        let strDlgTitle = "";
        // 新建
        if (this.state.iDlgType === 0) {
            strDlgTitle = "新建运单";
        }
        // 编辑
        if (this.state.iDlgType === 1) {
            objOrder = this.arrOrderData[this.state.selectedRowKeys[0]];
            if (this.state.iDlgType === 1)
                this.objDeliverOrder = this.arrOrderData[this.state.selectedRowKeys[0]];
            strDlgTitle = objOrder.oid;
        }
        // 删除
        if (this.state.iDlgType === 2) {
            objOrder = this.arrOrderData[this.state.selectedRowKeys[0]];
            strDlgTitle = "删除运单";
        }
        if (this.state.iDlgType >= 0)
            if (objOrder.client === null || objOrder.client === undefined)
                objOrder.client = "";

        let uiUserSelectButton = null;
        let uiFeeTab = null;

        let uiDlgTitle = null;
        let uiCurrencyFilter = (
            <Menu>
                {this.arrCurrency.map((currency, index) => {
                    return (
                        <Menu.Item key={index} onClick={this.CurrencyChange.bind(this, index)}>
                            {currency}
                        </Menu.Item>
                    );
                })}
            </Menu>
        );
        const dateFormat1 = 'YYYY-MM';
        const dateFormat2 = 'YYYY-MM-DD';

        let uiOrderDlg = null;
        let uiUserFilterDlg = null;
        let uiUserDlg = null;
        let uiClientFilterDlg = null;
        let uiClientDlg = null;
        let uiAgentFilterDlg = null;
        let uiAgentDlg = null;
        let uiHouseBillDlg = null;
        let uiDeleteHouseBillDlg = null;
        let uiStorageDlg = null;
        let uiTruckDlg = null;
        let uiCarTeamDlg = null;
        let uiClientConfirmDlg = null;

        // 选择客户弹窗
        let uiClientList = (
            <div className="storage_sku_list_area">
                {global.arrClientData.map((client, index) => {
                    if (this.refs.client_search !== undefined) {
                        let strClientSearch = this.refs.client_search.state.value;
                        if (strClientSearch !== "" && strClientSearch !== null) {
                            if (client.name.toLowerCase().indexOf(strClientSearch.toLowerCase()) >= 0)
                                return (
                                    <div className="storage_sku_list_row" key={index} onClick={this.ClientSelectResult.bind(this, index)}>
                                        {client.name}
                                    </div>
                                );
                            else return null
                        } else {
                            return (
                                <div className="storage_sku_list_row" key={index} onClick={this.ClientSelectResult.bind(this, index)}>
                                    {client.name}
                                </div>
                            );
                        }
                    } else {
                        return (
                            <div className="storage_sku_list_row" key={index} onClick={this.ClientSelectResult.bind(this, index)}>
                                {client.name}
                            </div>
                        );
                    }
                })}
            </div>
        );
        let uiClientSearchTitle = (
            <div className="logistic_row">
                <div >选择客户</div>
                <Input className="logistic_search_input" ref="client_search" defaultValue="" style={{ marginRight: '0px', marginLeft: '10px' }} allowClear />
                <Button type="primary" onClick={this.SearchClient} style={{ width: '30px', marginRight: 'auto' }} className="logistic_row">
                    <Icon type="search" /></Button>
            </div>);
        uiClientFilterDlg = (
            <Modal width={450} title={uiClientSearchTitle} visible={this.state.bSelectClientFilterDlg} closable={true} maskClosable={true} footer={null}
                onCancel={this.ClientSelectResult.bind(this, -1)}
            > {uiClientList} </Modal>);
        // 选择负责人弹窗
        let uiUserList = (
            <div className="storage_sku_list_area">
                {global.arrUserData.map((user, index) => {
                    return (
                        <div className="storage_sku_list_row" key={index} onClick={this.UserSelectResult.bind(this, index)}>
                            {user.name}
                        </div>
                    );
                })}
            </div>
        );
        uiUserFilterDlg = (
            <Modal width={450} title={"选择负责人"} visible={this.state.bSelectUserFilterDlg} closable={true} maskClosable={true} footer={null}
                onCancel={this.UserSelectResult.bind(this, -1)} > {uiUserList} </Modal>);

        let uiDeleteCommentsDlg = null;
        let uiReportDlg = null;
        let uiReleaseDlg = null;
        /******** 运单弹窗主体 ********/
        // Order 弹窗显示时
        if (this.state.bDlgShow && this.state.iDlgType !== 2) {
            /* 备注区域 */
            let uiCommentsArea = null;
            let uiDeliverNotes = null;
            if (this.state.iDlgType === 1) {
                let uiCommentsList = null;
                let uiSubmitButton = (
                    <Button type='primary' onClick={this.SubmitComments.bind(this, 0, -1)}
                        style={{ marginLeft: '5px', marginRight: '5px' }} > 提交</Button>
                );
                if (this.arrOrderComments.length > 0) {
                    uiCommentsList = this.arrOrderComments.map((comments, index) => {
                        let uiEditButton = (
                            <Button type='primary' onClick={this.EditComments.bind(this, index)} disabled={comments.edit}
                                style={{ marginLeft: 'auto', marginRight: '5px', maxWidth: '30px', Height: '30px' }} className="logistic_comment_button">
                                <Icon type="edit" style={{ color: 'white', fontWeight: 'bold', fontSize: '20px' }} />
                            </Button>
                        );
                        let uiConfirmButton = (
                            <Button type='primary' onClick={this.SubmitComments.bind(this, 1, index)} disabled={!comments.edit}
                                style={{ marginLeft: '5px', marginRight: '5px', maxWidth: '30px', maxHeight: '30px' }} className="logistic_comment_button">
                                <Icon type="check" style={{ color: 'white', fontWeight: 'bold', fontSize: '20px' }} /></Button>
                        );
                        let uiDeleteButton = (
                            <Button type='danger' onClick={this.DeleteComments.bind(this, 1, index)}
                                style={{ marginLeft: '5px', marginRight: '5px', width: '30px', height: '30px' }} className="logistic_comment_button">
                                <Icon type="delete" style={{ color: 'white', fontWeight: 'bold', fontSize: '20px' }} /></Button>
                        );
                        if (this.props.iUserLevel < 9 && comments.user_id !== this.props.iUserID) {
                            uiEditButton = null; uiConfirmButton = null; uiDeleteButton = null;
                        }
                        if (comments.generate_time === null)
                            comments.generate_time = comments.update_time;
                        let strTime = new Date(new Date(comments.generate_time).getTime() - 7 * 60 * 60 * 1000).toISOString();
                        strTime = strTime.split('T')[0] + " " + strTime.split('T')[1].split('.')[0];
                        return (
                            <div>
                                <div className="auto_order_info_row">
                                    <div className="order_detail_title">{comments.user_name}</div>
                                    <div style={{ marginLeft: '5px', minWidth: '100px', color: 'grey' }}>{strTime}</div>

                                </div>
                                <div className="auto_order_info_row">
                                    <TextArea size="large" autoSize={{ minRows: 2 }} allowClear={true} ref={"comments" + index.toString()}
                                        style={{ marginBottom: '0px' }} placeholder="" defaultValue={comments.comments} disabled={!comments.edit} />
                                </div>
                                <div className="auto_order_info_row">
                                    {uiEditButton}
                                    {uiConfirmButton}
                                    {uiDeleteButton}
                                </div>
                            </div>
                        );
                    });
                }

                // let bClientNotes = true;
                // if (this.props.iUserLevel < 5) {
                //     bClientNotes = false;
                // }
                // uiDeliverNotes = (
                //     <div className="auto_order_info_block">
                //         <div className="auto_order_info_row">
                //             <div className="order_detail_title" style={{ marginTop: '2.5px', marginBottom: 'auto' }}>操作指示：</div>
                //             <TextArea size="large" autoSize={{ minRows: 2 }} allowClear={true} ref="client_notes" style={{ marginBottom: '0px' }}
                //                 placeholder="" defaultValue={objOrder.client_notes} disabled={bClientNotes} />
                //         </div>
                //     </div>
                // );
                uiCommentsArea = (
                    <div className="logistic_comments_area" >
                        <div className="auto_order_info_row" >
                            <div className="order_detail_title" >备注：</div>
                        </div>
                        <div className="auto_order_info_block logistic_comments_block" >
                            {uiCommentsList}
                        </div>
                        <div className="auto_order_info_row" style={{ marginTop: 'auto', marginBottom: '0px' }}>
                            <TextArea size="large" autoSize={{ minRows: 1 }} allowClear={true} ref="new_comment" placeholder="" defaultValue={''} />
                            {uiSubmitButton}
                        </div>
                    </div>
                );
                // if (this.props.iUserLevel < 5)
                //     uiCommentsArea = (
                //         <div className="auto_order_info_block">
                //             <div className="auto_order_info_row">
                //                 <div className="order_detail_title" style={{ marginTop: '2.5px', marginBottom: 'auto' }}>操作指示：</div>
                //                 <TextArea size="large" autoSize={{ minRows: 2 }} allowClear={true} ref="client_notes" style={{ marginBottom: '0px' }}
                //                     placeholder="" defaultValue={objOrder.client_notes} disabled={bClientNotes} />
                //             </div>
                //         </div>
                //     );
                if (this.state.bDeleteComments)
                    uiDeleteCommentsDlg = (
                        <Modal title={'删除备注'} visible={this.state.bDeleteComments} okText="确定" cancelText="取消"
                            onOk={this.ConfirmDeleteComments.bind(this, 1)} onCancel={this.ConfirmDeleteComments.bind(this, -1)} >
                            <div style={{ maxHeight: '350px' }}>
                                {"是否删除这条备注？"}<br />
                                {this.arrOrderComments[this.iDeleteComment].comments}
                            </div>
                        </Modal>
                    );
            }
            let uiCommentsDlg = uiCommentsArea;
            if (this.state.iDlgType === 0 || !this.state.bDlgShow || this.props.iUserLevel < 5)
                uiCommentsDlg = null;

            /* 对话框顶部Tab设置 */
            let arrStatus = [];

            for (let i = 0; i < 8; i++) {
                arrStatus.push('logistics_dlg_main_button');
                if (i === this.state.iDlgTab)
                    arrStatus[i] += " logistics_dlg_main_button_select"
                if (i === 3 && this.state.iDlgTab === 3 && this.state.iStorageCustom === 1)
                    arrStatus[i] += " logistics_dlg_main_button_select"
                if (i > objOrder.status)
                    arrStatus[i] = "logistics_dlg_main_button_disable";
            }
            arrStatus[7] = "logistics_dlg_main_button";
            if (this.state.iDlgTab === 7) {
                arrStatus[7] += " logistics_dlg_main_button_select";
            }
            // 管理员可以选择负责人，查看收费面板
            if (this.props.iUserLevel >= 9 && this.props.iType < 10) {
                uiFeeTab = (
                    <div className={arrStatus[7]} style={{ marginLeft: '20px' }} onClick={this.SwitchDlgTab.bind(this, 7)}>收费</div>
                );
            }
            if (this.props.iUserLevel >= 9)
                uiUserSelectButton = (
                    <Button type='primary' onClick={this.SelectUser.bind(this)} style={{ marginLeft: '5px', marginRight: '5px' }} >选择</Button>
                );
            uiDlgTitle = (
                <div className="auto_order_dlg_title">
                    {strDlgTitle}
                    {/* <div className="auto_order_dlg_title_client_info" style={{ marginLeft: '30px', marginRight: '0px' }}>前缀</div>
                    <Input className="logistics_detail_input" ref="client" defaultValue={objOrder.client} style={{ marginLeft: '5px', marginRight: '0px' }} /> */}
                    <div className="auto_order_dlg_title_client_info" style={{ marginLeft: '30px', marginRight: '0px' }}>客户</div>
                    <Input className="logistics_detail_input" ref="client" defaultValue={objOrder.client} style={{ marginLeft: '5px', marginRight: '0px' }} />
                    <Button type='primary' onClick={this.SelectClient.bind(this)} style={{ marginLeft: '5px', marginRight: '5px' }} >选择</Button>
                    <div className="auto_order_dlg_title_client_info" style={{ marginLeft: '20px', marginRight: '0px' }}>负责人</div>
                    <Input className="logistics_detail_input" ref="user" defaultValue={objOrder.user_name} style={{ marginLeft: '5px', marginRight: '0px' }} disabled />
                    {uiUserSelectButton}
                    {uiFeeTab}
                </div >
            );
            if (this.props.iUserLevel < 5) {
                uiDlgTitle = (
                    <div className="auto_order_dlg_title">
                        {strDlgTitle}
                    </div >
                );
            }
            if (objOrder.storage_custom >= 1) {
                if (objOrder.status === 3 || (objOrder.status === 5 && objOrder.storage_custom === 2)) {
                    arrStatus[2] = "logistics_dlg_main_button_disable";
                }
                if (objOrder.status === 2) {
                    arrStatus[3] = "logistics_dlg_main_button";
                    if (this.state.iDlgTab === 3)
                        arrStatus[3] += " logistics_dlg_main_button_select"
                }
                if (objOrder.transit_hb === 1 && objOrder.open_release === 1) {
                    arrStatus[4] = "logistics_dlg_main_button";
                    if (this.state.iDlgTab === 4)
                        arrStatus[4] += " logistics_dlg_main_button_select";
                    if (objOrder.storage_custom >= 1 && (objOrder.free_storage_date === null || objOrder.free_storage_date === undefined ||
                        objOrder.free_storage_date === '')) {
                        arrStatus[4] = "logistics_dlg_main_button_disable";
                    }
                } else
                    arrStatus[4] = "logistics_dlg_main_button_disable";
                if (objOrder.deliver_hb === 1 && objOrder.open_release === 1) {
                    arrStatus[5] = "logistics_dlg_main_button";
                    if (this.state.iDlgTab === 5)
                        arrStatus[5] += " logistics_dlg_main_button_select";
                    if (objOrder.storage_custom === 1 && (objOrder.free_storage_date === null || objOrder.free_storage_date === undefined ||
                        objOrder.free_storage_date === '')) {
                        arrStatus[5] = "logistics_dlg_main_button_disable";
                    }
                } else
                    arrStatus[5] = "logistics_dlg_main_button_disable";
                if (objOrder.pickup_hb === 1 && objOrder.open_release === 1) {
                    arrStatus[6] = "logistics_dlg_main_button";
                    if (this.state.iDlgTab === 6)
                        arrStatus[6] += " logistics_dlg_main_button_select";
                    if (objOrder.storage_custom >= 1 && (objOrder.free_storage_date === null || objOrder.free_storage_date === undefined ||
                        objOrder.free_storage_date === '')) {
                        arrStatus[6] = "logistics_dlg_main_button_disable";
                    }
                } else
                    arrStatus[6] = "logistics_dlg_main_button_disable";
            } else {
                arrStatus[4] = "logistics_dlg_main_button_disable";
                arrStatus[5] = "logistics_dlg_main_button_disable";
                arrStatus[6] = "logistics_dlg_main_button_disable";
                if (this.arrHouseBill.length > 0) {
                    for (let i = 0; i < this.arrHouseBill.length; i++) {
                        // if (objOrder.open_release === 1 && this.arrHouseBill[i].path === 0) {
                        if (this.arrHouseBill[i].path === 0) {
                            arrStatus[4] = "logistics_dlg_main_button";
                            if (this.state.iDlgTab === 4)
                                arrStatus[4] += " logistics_dlg_main_button_select";
                        }
                        // if (objOrder.open_release === 1 && this.arrHouseBill[i].path === 1) {
                        if (this.arrHouseBill[i].path === 1) {
                            arrStatus[5] = "logistics_dlg_main_button";
                            if (this.state.iDlgTab === 5)
                                arrStatus[5] += " logistics_dlg_main_button_select";
                        }
                        if (objOrder.open_release === 1 && this.arrHouseBill[i].path === 2) {
                            // if (objOrder.open_release === 1 && this.arrHouseBill[i].path === 2) {
                            arrStatus[6] = "logistics_dlg_main_button";
                            if (this.state.iDlgTab === 6)
                                arrStatus[6] += " logistics_dlg_main_button_select";
                        }

                    }
                }
            }
            /* 基础信息部分 */
            // let strCancel = "取消";
            // let strConfirm = "确定";
            // let uiAddBefore;
            // 发船
            let uiBasicInfo0 = null;
            let uiAgent = null;
            if (this.state.iDlgTab === 0) {
                uiAgent = (
                    <div className="auto_order_info_row">
                        <div className="order_detail_title" >编号前缀：</div>
                        <Input className="order_detail_input" ref="oid_before" defaultValue={objOrder.oid_before} />
                        <div className="order_detail_title">目的港代理</div>
                        <Input className="order_detail_input_long" ref="agent" defaultValue={objOrder.agent} disabled
                            style={{ background: 'white', color: 'rgba(0, 0, 0, 0.75)' }} />
                        <Button type='primary' onClick={this.SelectAgent.bind(this)} style={{ marginLeft: '10px', marginRight: '5px' }} >
                            选择</Button>
                    </div>
                );
                uiBasicInfo0 = (
                    <div className="auto_order_info_block">
                        {uiAgent}
                        <div className="auto_order_info_row">
                            <div className="order_detail_title" style={{ color: 'lightcoral' }}>柜号：</div>
                            <Input className="order_detail_input" ref="closet" defaultValue={objOrder.closet} />
                            <div className="order_detail_title" >物流单号：</div>
                            <Input className="order_detail_input" ref="express" defaultValue={objOrder.express} />
                            <div className="order_detail_title">重量：</div>
                            <Input className="order_detail_input" ref="weight" defaultValue={objOrder.weight} addonAfter={'KG'} />
                        </div>
                        <div className="auto_order_info_row">
                            <div className="order_detail_title">到港城市：</div>
                            <Input className="order_detail_input" ref="port" defaultValue={objOrder.port} />
                            <div className="order_detail_title">预计到港：</div>
                            <DatePicker className="order_date_pickup" onChange={this.ArriveDateChange}
                                value={moment(this.state.eta, dateFormat2)} placeholder="请选择日期" />
                            <div className="order_detail_title">件数：</div>
                            <Input className="order_detail_input" ref="num" defaultValue={objOrder.num} addonAfter={'PKG'} />
                        </div>
                        <div className="auto_order_info_row">
                            <div className="order_detail_title">目的城市：</div>
                            <Input className="order_detail_input" ref="destination" defaultValue={objOrder.destination} />
                            <div className="order_detail_title">预计到仓：</div>
                            <DatePicker className="order_date_pickup" onChange={this.StorageDate}
                                value={moment(this.state.etd, dateFormat2)} placeholder="请选择日期" />
                            <div className="order_detail_title">方数：</div>
                            <Input className="order_detail_input" ref="cbm" defaultValue={objOrder.cbm} addonAfter={'CBM'} />
                        </div>
                    </div>
                );
            }
            // 到港
            let uiBasicInfo1 = null;
            if (this.state.iDlgTab === 1) {
                let uiStorage = (
                    <div className="auto_order_info_row"
                        style={{ background: 'rgba(240, 128, 128, 0.5)', padding: '5px', borderRadius: '5px' }}>
                        <div className="order_detail_title">仓库：</div>
                        <Input className="order_detail_input_long" ref="storage" defaultValue={objOrder.storage} disabled
                            style={{ background: 'rgba(240, 128, 128, 0.25);', color: 'rgba(0, 0, 0, 0.75)' }} />
                        <Button type='primary' onClick={this.SelectStorage.bind(this)} style={{ marginLeft: '5px', marginRight: '5px' }} >选择</Button>
                    </div>
                );
                uiBasicInfo1 = (
                    <div className="auto_order_info_block">
                        {uiStorage}
                        <div className="logistic_info_area">
                            <div className="auto_order_info_row" style={{ margin: '0px' }}>
                                <div style={{ fontWeight: 'bold' }}>编号前缀：</div>
                                {objOrder.oid_before}
                                <div style={{ minWidth: '15px' }} />
                                <div style={{ fontWeight: 'bold' }}>柜号：</div>
                                {objOrder.closet}
                                <div style={{ minWidth: '15px' }} />
                                <div style={{ fontWeight: 'bold' }}>物流：</div>
                                {objOrder.express}
                                <div style={{ minWidth: '15px' }} />
                                <div style={{ fontWeight: 'bold' }}>到港城市：</div>
                                {objOrder.port}
                                <div style={{ minWidth: '15px' }} />
                                <div style={{ fontWeight: 'bold' }}>目的城市：</div>
                                {objOrder.destination}
                                {/* <div style={{ minWidth: '15px' }} />
                                <div style={{ fontWeight: 'bold' }}>件数：</div>
                                {objOrder.num + " PKG"}
                                <div style={{ minWidth: '15px' }} />
                                <div style={{ fontWeight: 'bold' }}>重量：</div>
                                {objOrder.weight + " KG"}
                                <div style={{ minWidth: '15px' }} />
                                <div style={{ fontWeight: 'bold' }}>方数：</div>
                                {objOrder.cbm + " CBM"} */}
                            </div>
                            <div className="auto_order_info_row" style={{ margin: '0px' }}>
                                {/* <div style={{ fontWeight: 'bold' }}>柜号：</div>
                                {objOrder.closet}
                                <div style={{ minWidth: '15px' }} />
                                <div style={{ fontWeight: 'bold' }}>物流：</div>
                                {objOrder.express}
                                <div style={{ minWidth: '15px' }} />
                                <div style={{ fontWeight: 'bold' }}>到港城市：</div>
                                {objOrder.port}
                                <div style={{ minWidth: '15px' }} />
                                <div style={{ fontWeight: 'bold' }}>目的城市：</div>
                                {objOrder.destination}
                                <div style={{ minWidth: '15px' }} /> */}
                                <div style={{ fontWeight: 'bold' }}>件数：</div>
                                {objOrder.num + " PKG"}
                                <div style={{ minWidth: '15px' }} />
                                <div style={{ fontWeight: 'bold' }}>重量：</div>
                                {objOrder.weight + " KG"}
                                <div style={{ minWidth: '15px' }} />
                                <div style={{ fontWeight: 'bold' }}>方数：</div>
                                {objOrder.cbm + " CBM"}
                            </div>
                        </div>
                        <div className="auto_order_info_row">
                            <div className="order_detail_title">预计到仓：</div>
                            <DatePicker className="order_date_pickup" onChange={this.StorageDate}
                                value={moment(this.state.etd, dateFormat2)} placeholder="请选择日期" />
                            <div className="order_detail_title">预计到港：</div>
                            <DatePicker className="order_date_pickup" onChange={this.ArriveDateChange}
                                value={moment(this.state.eta, dateFormat2)} placeholder="请选择日期" />
                        </div>
                        {/* <div className="auto_order_info_row">
                            <div className="order_detail_title" style={{ marginTop: '2.5px', marginBottom: 'auto' }}>备注：</div>
                            <TextArea size="large" autoSize={{ minRows: 2 }} allowClear={true} ref="comments" style={{ marginBottom: '0px' }}
                                placeholder="" defaultValue={objOrder.comments} />
                        </div>
                        <div className="auto_order_info_row">
                            <div className="order_detail_title" style={{ marginTop: '2.5px', marginBottom: 'auto' }}>操作指示：</div>
                            <TextArea size="large" autoSize={{ minRows: 2 }} allowClear={true} ref="client_notes" style={{ marginBottom: '0px' }}
                                placeholder="" defaultValue={objOrder.client_notes} disabled />
                        </div> */}
                    </div>
                );
            }
            // 仓库
            let uiBasicInfo2 = null;
            if (this.state.iDlgTab === 2) {
                let uiStorage = (
                    <div className="auto_order_info_row">
                        <div className="order_detail_title">仓库：</div>
                        <Input className="order_detail_input_long" ref="storage" defaultValue={objOrder.storage} disabled
                            style={{ background: 'white', color: 'rgba(0, 0, 0, 0.75)' }} />
                    </div>
                );
                uiBasicInfo2 = (
                    <div className="auto_order_info_block">
                        {uiStorage}
                        <div className="logistic_info_area">
                            <div className="auto_order_info_row" style={{ margin: '0px', marginBottom: '10px' }}>
                                <div style={{ fontWeight: 'bold' }}>编号前缀：</div>
                                {objOrder.oid_before}
                                <div style={{ minWidth: '15px' }} />
                                <div style={{ fontWeight: 'bold' }}>柜号：</div>
                                {objOrder.closet}
                                <div style={{ minWidth: '15px' }} />
                                <div style={{ fontWeight: 'bold' }}>物流：</div>
                                {objOrder.express}
                                <div style={{ minWidth: '15px' }} />
                                <div style={{ fontWeight: 'bold' }}>到港城市：</div>
                                {objOrder.port}
                                <div style={{ minWidth: '15px' }} />
                                <div style={{ fontWeight: 'bold' }}>目的城市：</div>
                                {objOrder.destination}
                                {/* <div style={{ minWidth: '15px' }} />
                                <div style={{ fontWeight: 'bold' }}>件数：</div>
                                {objOrder.num + " PKG"}
                                <div style={{ minWidth: '15px' }} />
                                <div style={{ fontWeight: 'bold' }}>重量：</div>
                                {objOrder.weight + " KG"}
                                <div style={{ minWidth: '15px' }} />
                                <div style={{ fontWeight: 'bold' }}>方数：</div>
                                {objOrder.cbm + " CBM"} */}
                            </div>
                            <div className="auto_order_info_row" style={{ margin: '0px' }}>
                                {/* <div style={{ fontWeight: 'bold' }}>柜号：</div>
                                {objOrder.closet}
                                <div style={{ minWidth: '15px' }} />
                                <div style={{ fontWeight: 'bold' }}>物流：</div>
                                {objOrder.express}
                                <div style={{ minWidth: '15px' }} />
                                <div style={{ fontWeight: 'bold' }}>到港城市：</div>
                                {objOrder.port}
                                <div style={{ minWidth: '15px' }} />
                                <div style={{ fontWeight: 'bold' }}>目的城市：</div>
                                {objOrder.destination}
                                <div style={{ minWidth: '15px' }} /> */}
                                <div style={{ fontWeight: 'bold' }}>件数：</div>
                                {objOrder.num + " PKG"}
                                <div style={{ minWidth: '15px' }} />
                                <div style={{ fontWeight: 'bold' }}>重量：</div>
                                {objOrder.weight + " KG"}
                                <div style={{ minWidth: '15px' }} />
                                <div style={{ fontWeight: 'bold' }}>方数：</div>
                                {objOrder.cbm + " CBM"}
                            </div>
                        </div>
                        <div className="auto_order_info_row">
                            <div className="order_detail_title">预计到仓：</div>
                            <DatePicker className="order_date_pickup" onChange={this.StorageDate} disabled
                                value={moment(this.state.etd, dateFormat2)} placeholder="请选择日期" />
                            <div className="order_detail_title" style={{ color: 'lightcoral', fontWeight: 'bold' }}>免仓租日期</div>
                            <Input className="order_detail_input" ref="mb_free_storage_date" defaultValue={objOrder.free_storage_date} placeholder={'YYYY-MM-DD'} />
                        </div>
                        {/* <div className="auto_order_info_row">
                            <div className="order_detail_title" style={{ marginTop: '2.5px', marginBottom: 'auto' }}>备注：</div>
                            <TextArea size="large" autoSize={{ minRows: 2 }} allowClear={true} ref="comments" style={{ marginBottom: '0px' }}
                                placeholder="" defaultValue={objOrder.comments} />
                        </div>
                        <div className="auto_order_info_row">
                            <div className="order_detail_title" style={{ marginTop: '2.5px', marginBottom: 'auto' }}>操作指示：</div>
                            <TextArea size="large" autoSize={{ minRows: 2 }} allowClear={true} ref="client_notes" style={{ marginBottom: '0px' }}
                                placeholder="" defaultValue={objOrder.client_notes} disabled />
                        </div> */}
                    </div>
                );
            }
            // 清关
            let uiBasicInfo3 = null;
            if (this.state.iDlgTab === 3) {
                let uiStorage = (
                    <div className="auto_order_info_row">
                        <div className="order_detail_title">仓库：</div>
                        <Input className="order_detail_input_long" ref="storage" defaultValue={objOrder.storage} disabled
                            style={{ background: 'white', color: 'rgba(0, 0, 0, 0.75)' }} />
                    </div>
                );
                if (this.props.iUserLevel < 5) {
                    uiStorage = null;
                }

                uiBasicInfo3 = (
                    <div className="auto_order_info_block">
                        {uiStorage}
                        <div className="logistic_info_area">
                            <div className="auto_order_info_row" style={{ margin: '0px', marginBottom: '10px' }}>
                                <div style={{ fontWeight: 'bold' }}>编号前缀：</div>
                                {objOrder.oid_before}
                                <div style={{ minWidth: '15px' }} />
                                <div style={{ fontWeight: 'bold' }}>柜号：</div>
                                {objOrder.closet}
                                <div style={{ minWidth: '15px' }} />
                                <div style={{ fontWeight: 'bold' }}>物流：</div>
                                {objOrder.express}
                                <div style={{ minWidth: '15px' }} />
                                <div style={{ fontWeight: 'bold' }}>到港城市：</div>
                                {objOrder.port}
                                <div style={{ minWidth: '15px' }} />
                                <div style={{ fontWeight: 'bold' }}>目的城市：</div>
                                {objOrder.destination}
                                <div style={{ minWidth: '15px' }} />
                                <div style={{ fontWeight: 'bold' }}>件数：</div>
                                {objOrder.num + " PKG"}
                                <div style={{ minWidth: '15px' }} />
                                <div style={{ fontWeight: 'bold' }}>重量：</div>
                                {objOrder.weight + " KG"}
                                <div style={{ minWidth: '15px' }} />
                                <div style={{ fontWeight: 'bold' }}>方数：</div>
                                {objOrder.cbm + " CBM"}
                            </div>
                        </div>
                        {/* <div className="auto_order_info_row">
                            <div className="order_detail_title" style={{ marginTop: '2.5px', marginBottom: 'auto' }}>备注：</div>
                            <TextArea size="large" autoSize={{ minRows: 2 }} allowClear={true} ref="comments" style={{ marginBottom: '0px' }}
                                placeholder="" defaultValue={objOrder.comments} />
                        </div>
                        <div className="auto_order_info_row">
                            <div className="order_detail_title" style={{ marginTop: '2.5px', marginBottom: 'auto' }}>操作指示：</div>
                            <TextArea size="large" autoSize={{ minRows: 2 }} allowClear={true} ref="client_notes" style={{ marginBottom: '0px' }}
                                placeholder="" defaultValue={objOrder.client_notes} disabled={bClientNotes} />
                        </div> */}
                    </div>
                );
            }
            // 换单
            let uiBasicInfo456 = null;
            if (this.state.iDlgTab >= 4) {
                uiBasicInfo456 = null;
                // (
                // <div className="auto_order_info_block">
                //     <div className="auto_order_info_row">
                //         <div className="order_detail_title" style={{ marginTop: '2.5px', marginBottom: 'auto' }}>备注：</div>
                //         <TextArea size="large" autoSize={{ minRows: 2 }} allowClear={true} ref="comments" style={{ marginBottom: '0px' }}
                //             placeholder="" defaultValue={objOrder.comments} />
                //     </div>
                //     <div className="auto_order_info_row">
                //         <div className="order_detail_title" style={{ marginTop: '2.5px', marginBottom: 'auto' }}>操作指示：</div>
                //         <TextArea size="large" autoSize={{ minRows: 2 }} allowClear={true} ref="client_notes" style={{ marginBottom: '0px' }}
                //             placeholder="" defaultValue={objOrder.client_notes} disabled />
                //     </div>
                // </div>
                // );
            }

            // 统一费用计算
            for (let i = 0; i < this.arrAllFee.length; i++)
                this.arrAllFee[i].active = 0;

            // 0. 代理费
            let uiAgentFee = null;
            let fAgentBenefit = parseFloat(objOrder.agent_fee) - parseFloat(objOrder.agent_cost);
            if (typeof (fAgentBenefit) === 'number')
                fAgentBenefit = fAgentBenefit.toFixed(2);
            if (objOrder.agent_active === 1) {
                this.arrAllFee[0].active = 1;
                uiAgentFee = (
                    <div className="auto_order_info_row">
                        <div className="order_detail_title">{this.arrAllFee[0].name + "："}</div>
                        <Input className="logistic_fee_title_column_short" ref="agent_cost" value={objOrder.agent_cost}
                            onChange={this.AllFeeInfoChange.bind(this, objOrder, 1)} />
                        <Input className="logistic_fee_title_column_short" ref="agent_fee" value={objOrder.agent_fee}
                            onChange={this.AllFeeInfoChange.bind(this, objOrder, 0)} style={{ background: 'rgba(32, 178, 171, 0.2)' }} />
                        <Input className="logistic_fee_title_column_short" ref="agent_benefit" value={fAgentBenefit}
                            disabled />
                        <Icon type="delete" className="logistic_house_bill_icon"
                            style={{ color: 'lightcoral', marginLeft: '0px' }}
                            onClick={this.DeleteAllFee.bind(this, 0)} />
                    </div>
                );
            }
            // 1. 船东费
            let uiShipFee = null;
            let fShipBenefit = parseFloat(objOrder.ship_fee) - parseFloat(objOrder.ship_cost);
            if (typeof (fShipBenefit) === 'number')
                fShipBenefit = fShipBenefit.toFixed(2);
            if (objOrder.ship_active === 1) {
                this.arrAllFee[1].active = 1;
                uiShipFee = (
                    <div className="auto_order_info_row">
                        <div className="order_detail_title">{this.arrAllFee[1].name + "："}</div>
                        <Input className="logistic_fee_title_column_short" ref="ship_cost" value={objOrder.ship_cost}
                            onChange={this.AllFeeInfoChange.bind(this, objOrder, 3)} />
                        <Input className="logistic_fee_title_column_short" ref="ship_fee" value={objOrder.ship_fee}
                            onChange={this.AllFeeInfoChange.bind(this, objOrder, 2)} style={{ background: 'rgba(32, 178, 171, 0.2)' }} />
                        <Input className="logistic_fee_title_column_short" ref="ship_benefit" value={fShipBenefit}
                            disabled />
                        <Icon type="delete" className="logistic_house_bill_icon"
                            style={{ color: 'lightcoral', marginLeft: '0px' }}
                            onClick={this.DeleteAllFee.bind(this, 1)} />
                    </div>
                );
            }
            // 2. 码头费
            let uiPortFee = null;
            let fPortBenefit = parseFloat(objOrder.port_fee) - parseFloat(objOrder.port_cost);
            if (typeof (fPortBenefit) === 'number')
                fPortBenefit = fPortBenefit.toFixed(2);
            if (objOrder.port_active === 1) {
                this.arrAllFee[2].active = 1;
                uiPortFee = (
                    <div className="auto_order_info_row">
                        <div className="order_detail_title">{this.arrAllFee[2].name + "："}</div>
                        <Input className="logistic_fee_title_column_short" ref="port_cost" value={objOrder.port_cost}
                            onChange={this.AllFeeInfoChange.bind(this, objOrder, 5)} />
                        <Input className="logistic_fee_title_column_short" ref="port_fee" value={objOrder.port_fee}
                            onChange={this.AllFeeInfoChange.bind(this, objOrder, 4)} style={{ background: 'rgba(32, 178, 171, 0.2)' }} />
                        <Input className="logistic_fee_title_column_short" ref="port_benefit" value={fPortBenefit}
                            disabled />
                        <Icon type="delete" className="logistic_house_bill_icon"
                            style={{ color: 'lightcoral', marginLeft: '0px' }}
                            onClick={this.DeleteAllFee.bind(this, 2)} />
                    </div>
                );
            }
            // 3. 拖柜费
            let uiTruckFee = null;
            let fTruckBenefit = parseFloat(objOrder.truck_fee) - parseFloat(objOrder.truck_cost);
            if (typeof (fTruckBenefit) === 'number')
                fTruckBenefit = fTruckBenefit.toFixed(2);
            if (objOrder.truck_active === 1) {
                this.arrAllFee[3].active = 1;
                uiTruckFee = (
                    <div className="auto_order_info_row">
                        <div className="order_detail_title">{this.arrAllFee[3].name + "："}</div>
                        <Input className="logistic_fee_title_column_short" ref="truck_cost" value={objOrder.truck_cost}
                            onChange={this.AllFeeInfoChange.bind(this, objOrder, 7)} />
                        <Input className="logistic_fee_title_column_short" ref="truck_fee" value={objOrder.truck_fee}
                            onChange={this.AllFeeInfoChange.bind(this, objOrder, 6)} style={{ background: 'rgba(32, 178, 171, 0.2)' }} />
                        <Input className="logistic_fee_title_column_short" ref="truck_benefit" value={fTruckBenefit}
                            disabled />
                        <Icon type="delete" className="logistic_house_bill_icon"
                            style={{ color: 'lightcoral', marginLeft: '0px' }}
                            onClick={this.DeleteAllFee.bind(this, 3)} />
                    </div>
                );
            }
            // 4. 预提柜
            let uiPrepickFee = null;
            let fPrePickBenefit = parseFloat(objOrder.prepick_fee) - parseFloat(objOrder.prepick_cost);
            if (typeof (fPrePickBenefit) === 'number')
                fPrePickBenefit = fPrePickBenefit.toFixed(2);
            if (objOrder.prepick_active === 1) {
                this.arrAllFee[4].active = 1;
                uiPrepickFee = (
                    <div className="auto_order_info_row">
                        <div className="order_detail_title">{this.arrAllFee[4].name + "："}</div>
                        <Input className="logistic_fee_title_column_short" ref="prepick_cost" value={objOrder.prepick_cost}
                            onChange={this.AllFeeInfoChange.bind(this, objOrder, 9)} />
                        <Input className="logistic_fee_title_column_short" ref="prepick_fee" value={objOrder.prepick_fee}
                            onChange={this.AllFeeInfoChange.bind(this, objOrder, 8)} style={{ background: 'rgba(32, 178, 171, 0.2)' }} />
                        <Input className="logistic_fee_title_column_short" ref="prepick_benefit" value={fPrePickBenefit}
                            disabled />
                        <Icon type="delete" className="logistic_house_bill_icon"
                            style={{ color: 'lightcoral', marginLeft: '0px' }}
                            onClick={this.DeleteAllFee.bind(this, 4)} />
                    </div>
                );
            }
            // 5. 堆场费
            let uiStackFee = null;
            let fStackBenefit = parseFloat(objOrder.stack_fee) - parseFloat(objOrder.stack_cost);
            if (typeof (fStackBenefit) === 'number')
                fStackBenefit = fStackBenefit.toFixed(2);
            if (objOrder.stack_active === 1) {
                this.arrAllFee[5].active = 1;
                uiStackFee = (
                    <div className="auto_order_info_row">
                        <div className="order_detail_title">{this.arrAllFee[5].name + "："}</div>
                        <Input className="logistic_fee_title_column_short" ref="stack_cost" value={objOrder.stack_cost}
                            onChange={this.AllFeeInfoChange.bind(this, objOrder, 11)} />
                        <Input className="logistic_fee_title_column_short" ref="stack_fee" value={objOrder.stack_fee}
                            onChange={this.AllFeeInfoChange.bind(this, objOrder, 10)} style={{ background: 'rgba(32, 178, 171, 0.2)' }} />
                        <Input className="logistic_fee_title_column_short" ref="stack_benefit" value={fStackBenefit}
                            disabled />
                        <Icon type="delete" className="logistic_house_bill_icon"
                            style={{ color: 'lightcoral', marginLeft: '0px' }}
                            onClick={this.DeleteAllFee.bind(this, 5)} />
                    </div>
                );
            }
            // 6. 车架费
            let uiCarFrameFee = null;
            let fCarFrameBenefit = parseFloat(objOrder.car_frame_fee) - parseFloat(objOrder.car_frame_cost);
            if (typeof (fCarFrameBenefit) === 'number')
                fCarFrameBenefit = fCarFrameBenefit.toFixed(2);
            if (objOrder.car_frame_active === 1) {
                this.arrAllFee[6].active = 1;
                uiCarFrameFee = (
                    <div className="auto_order_info_row">
                        <div className="order_detail_title">{this.arrAllFee[6].name + "："}</div>
                        <Input className="logistic_fee_title_column_short" ref="car_frame_cost" value={objOrder.car_frame_cost}
                            onChange={this.AllFeeInfoChange.bind(this, objOrder, 13)} />
                        <Input className="logistic_fee_title_column_short" ref="car_frame_fee" value={objOrder.car_frame_fee}
                            onChange={this.AllFeeInfoChange.bind(this, objOrder, 12)} style={{ background: 'rgba(32, 178, 171, 0.2)' }} />
                        <Input className="logistic_fee_title_column_short" ref="car_frame_benefit" value={fCarFrameBenefit}
                            disabled />
                        <Icon type="delete" className="logistic_house_bill_icon"
                            style={{ color: 'lightcoral', marginLeft: '0px' }}
                            onClick={this.DeleteAllFee.bind(this, 6)} />
                    </div>
                );
            }
            // 7. 危险费
            let uiDangerFee = null;
            let fDangerBenefit = parseFloat(objOrder.danger_fee) - parseFloat(objOrder.danger_cost);
            if (typeof (fDangerBenefit) === 'number')
                fDangerBenefit = fDangerBenefit.toFixed(2);
            if (objOrder.danger_active === 1) {
                this.arrAllFee[7].active = 1;
                uiDangerFee = (
                    <div className="auto_order_info_row">
                        <div className="order_detail_title">{this.arrAllFee[7].name + "："}</div>
                        <Input className="logistic_fee_title_column_short" ref="danger_cost" value={objOrder.danger_cost}
                            onChange={this.AllFeeInfoChange.bind(this, objOrder, 15)} />
                        <Input className="logistic_fee_title_column_short" ref="danger_fee" value={objOrder.danger_fee}
                            onChange={this.AllFeeInfoChange.bind(this, objOrder, 14)} style={{ background: 'rgba(32, 178, 171, 0.2)' }} />
                        <Input className="logistic_fee_title_column_short" ref="danger_benefit" value={fDangerBenefit}
                            disabled />
                        <Icon type="delete" className="logistic_house_bill_icon"
                            style={{ color: 'lightcoral', marginLeft: '0px' }}
                            onClick={this.DeleteAllFee.bind(this, 7)} />
                    </div>
                );
            }
            // 8. 空跑费
            let uiNullFee = null;
            let fNullBenefit = parseFloat(objOrder.null_fee) - parseFloat(objOrder.null_cost);
            if (typeof (fNullBenefit) === 'number')
                fNullBenefit = fNullBenefit.toFixed(2);
            if (objOrder.null_active === 1) {
                this.arrAllFee[8].active = 1;
                uiNullFee = (
                    <div className="auto_order_info_row">
                        <div className="order_detail_title">{this.arrAllFee[8].name + "："}</div>
                        <Input className="logistic_fee_title_column_short" ref="null_cost" value={objOrder.null_cost}
                            onChange={this.AllFeeInfoChange.bind(this, objOrder, 17)} />
                        <Input className="logistic_fee_title_column_short" ref="null_fee" value={objOrder.null_fee}
                            onChange={this.AllFeeInfoChange.bind(this, objOrder, 16)} style={{ background: 'rgba(32, 178, 171, 0.2)' }} />
                        <Input className="logistic_fee_title_column_short" ref="null_benefit" value={fNullBenefit}
                            disabled />
                        <Icon type="delete" className="logistic_house_bill_icon"
                            style={{ color: 'lightcoral', marginLeft: '0px' }}
                            onClick={this.DeleteAllFee.bind(this, 8)} />
                    </div>
                );
            }
            // 9. 拆柜费
            let uiOpenFee = null;
            let fOpenClosetBenefit = parseFloat(objOrder.open_closet_fee) - parseFloat(objOrder.open_closet_cost);
            if (typeof (fOpenClosetBenefit) === 'number')
                fOpenClosetBenefit = fOpenClosetBenefit.toFixed(2);
            if (objOrder.open_active === 1) {
                this.arrAllFee[9].active = 1;
                uiOpenFee = (
                    <div className="auto_order_info_row">
                        <div className="order_detail_title">{this.arrAllFee[9].name + "："}</div>
                        <Input className="logistic_fee_title_column_short" ref="open_closet_cost" value={objOrder.open_closet_cost}
                            onChange={this.AllFeeInfoChange.bind(this, objOrder, 19)} />
                        <Input className="logistic_fee_title_column_short" ref="open_closet_fee" value={objOrder.open_closet_fee}
                            onChange={this.AllFeeInfoChange.bind(this, objOrder, 18)} style={{ background: 'rgba(32, 178, 171, 0.2)' }} />
                        <Input className="logistic_fee_title_column_short" ref="open_closet_benefit" value={fOpenClosetBenefit}
                            disabled />
                        <Icon type="delete" className="logistic_house_bill_icon"
                            style={{ color: 'lightcoral', marginLeft: '0px' }}
                            onClick={this.DeleteAllFee.bind(this, 9)} />
                    </div>
                );
            }
            // 10. 分货费
            let uiDivideFee = null;
            let fDivideItemBenefit = parseFloat(objOrder.divide_item_fee) - parseFloat(objOrder.divide_item_cost);
            if (typeof (fDivideItemBenefit) === 'number')
                fDivideItemBenefit = fDivideItemBenefit.toFixed(2);
            if (objOrder.divide_active === 1) {
                this.arrAllFee[10].active = 1;
                uiDivideFee = (
                    <div className="auto_order_info_row">
                        <div className="order_detail_title">{this.arrAllFee[10].name + "："}</div>
                        <Input className="logistic_fee_title_column_short" ref="divide_item_cost" value={objOrder.divide_item_cost}
                            onChange={this.AllFeeInfoChange.bind(this, objOrder, 21)} />
                        <Input className="logistic_fee_title_column_short" ref="divide_item_fee" value={objOrder.divide_item_fee}
                            onChange={this.AllFeeInfoChange.bind(this, objOrder, 20)} style={{ background: 'rgba(32, 178, 171, 0.2)' }} />
                        <Input className="logistic_fee_title_column_short" ref="divide_item_benefit" value={fDivideItemBenefit} disabled />
                        <Icon type="delete" className="logistic_house_bill_icon"
                            style={{ color: 'lightcoral', marginLeft: '0px' }}
                            onClick={this.DeleteAllFee.bind(this, 10)} />
                    </div>
                );
            }
            // 11. 打板费
            let uiOnPlateFee = null;
            let fOnPlateBenefit = parseFloat(objOrder.on_plate_fee) - parseFloat(objOrder.on_plate_cost);
            if (typeof (fOnPlateBenefit) === 'number')
                fOnPlateBenefit = fOnPlateBenefit.toFixed(2);
            if (objOrder.on_plate_active === 1) {
                this.arrAllFee[11].active = 1;
                uiOnPlateFee = (
                    <div className="auto_order_info_row">
                        <div className="order_detail_title">{this.arrAllFee[11].name + "："}</div>
                        <Input className="logistic_fee_title_column_short" ref="on_plate_cost" value={objOrder.on_plate_cost}
                            onChange={this.AllFeeInfoChange.bind(this, objOrder, 23)} />
                        <Input className="logistic_fee_title_column_short" ref="on_plate_fee" value={objOrder.on_plate_fee}
                            onChange={this.AllFeeInfoChange.bind(this, objOrder, 22)} style={{ background: 'rgba(32, 178, 171, 0.2)' }} />
                        <Input className="logistic_fee_title_column_short" ref="on_plate_benefit" value={fOnPlateBenefit} disabled />
                        <Icon type="delete" className="logistic_house_bill_icon"
                            style={{ color: 'lightcoral', marginLeft: '0px' }}
                            onClick={this.DeleteAllFee.bind(this, 11)} />
                    </div>
                );
            }
            // 12. 贴标费
            let uiTagFee = null;
            let fTagBenefit = parseFloat(objOrder.tag_fee) - parseFloat(objOrder.tag_cost);
            if (typeof (fTagBenefit) === 'number')
                fTagBenefit = fTagBenefit.toFixed(2);
            if (objOrder.tag_active === 1) {
                this.arrAllFee[12].active = 1;
                uiTagFee = (
                    <div className="auto_order_info_row">
                        <div className="order_detail_title">{this.arrAllFee[12].name + "："}</div>
                        <Input className="logistic_fee_title_column_short" ref="tag_cost" value={objOrder.tag_cost}
                            onChange={this.AllFeeInfoChange.bind(this, objOrder, 25)} />
                        <Input className="logistic_fee_title_column_short" ref="tag_fee" value={objOrder.tag_fee}
                            onChange={this.AllFeeInfoChange.bind(this, objOrder, 24)} style={{ background: 'rgba(32, 178, 171, 0.2)' }} />
                        <Input className="logistic_fee_title_column_short" ref="tag_benefit" value={fTagBenefit} disabled />
                        <Icon type="delete" className="logistic_house_bill_icon"
                            style={{ color: 'lightcoral', marginLeft: '0px' }}
                            onClick={this.DeleteAllFee.bind(this, 12)} />
                    </div>
                );
            }
            // 13. 超规费
            let uiOversizeFee = null;
            if (objOrder.oversize_active === 1) {
                let fOversizeBenefit = parseFloat(objOrder.oversize_fee) - parseFloat(objOrder.oversize_cost);
                if (typeof (fOversizeBenefit) === 'number')
                    fOversizeBenefit = fOversizeBenefit.toFixed(2);
                this.arrAllFee[13].active = 1;
                uiOversizeFee = (
                    <div className="auto_order_info_row">
                        <div className="order_detail_title">{this.arrAllFee[13].name + "："}</div>
                        <Input className="logistic_fee_title_column_short" ref="oversize_cost" value={objOrder.oversize_cost}
                            onChange={this.AllFeeInfoChange.bind(this, objOrder, 27)} />
                        <Input className="logistic_fee_title_column_short" ref="oversize_fee" value={objOrder.oversize_fee}
                            onChange={this.AllFeeInfoChange.bind(this, objOrder, 26)} style={{ background: 'rgba(32, 178, 171, 0.2)' }} />
                        <Input className="logistic_fee_title_column_short" ref="oversize_benefit" value={fOversizeBenefit} disabled />
                        <Icon type="delete" className="logistic_house_bill_icon"
                            style={{ color: 'lightcoral', marginLeft: '0px' }}
                            onClick={this.DeleteAllFee.bind(this, 13)} />
                    </div>
                );
            }
            // 14. 仓储费
            let uiStorageFee = null;
            if (objOrder.storage_active === 1) {
                let fStorageBenefit = parseFloat(objOrder.storage_fee) - parseFloat(objOrder.storage_cost);
                if (typeof (fStorageBenefit) === 'number')
                    fStorageBenefit = fStorageBenefit.toFixed(2);
                this.arrAllFee[14].active = 1;
                uiStorageFee = (
                    <div className="auto_order_info_row">
                        <div className="order_detail_title">{this.arrAllFee[14].name + "："}</div>
                        <Input className="logistic_fee_title_column_short" ref="storage_cost" value={objOrder.storage_cost}
                            onChange={this.AllFeeInfoChange.bind(this, objOrder, 29)} />
                        <Input className="logistic_fee_title_column_short" ref="storage_fee" value={objOrder.storage_fee}
                            onChange={this.AllFeeInfoChange.bind(this, objOrder, 28)} style={{ background: 'rgba(32, 178, 171, 0.2)' }} />
                        <Input className="logistic_fee_title_column_short" ref="storage_benefit" value={fStorageBenefit}
                            disabled />
                        <Icon type="delete" className="logistic_house_bill_icon"
                            style={{ color: 'lightcoral', marginLeft: '0px' }}
                            onClick={this.DeleteAllFee.bind(this, 14)} />
                    </div>
                );
            }
            // 15. 出仓费
            let uiExportFee = null;
            if (objOrder.storage_export_active === 1) {
                let fExportBenefit = parseFloat(objOrder.storage_export_fee) - parseFloat(objOrder.storage_export_cost);
                if (typeof (fExportBenefit) === 'number')
                    fExportBenefit = fExportBenefit.toFixed(2);
                this.arrAllFee[15].active = 1;
                uiExportFee = (
                    <div className="auto_order_info_row">
                        <div className="order_detail_title">{this.arrAllFee[15].name + "："}</div>
                        <Input className="logistic_fee_title_column_short" ref="storage_export_cost" value={objOrder.storage_export_cost}
                            onChange={this.AllFeeInfoChange.bind(this, objOrder, 31)} />
                        <Input className="logistic_fee_title_column_short" ref="storage_export_fee" value={objOrder.storage_export_fee}
                            onChange={this.AllFeeInfoChange.bind(this, objOrder, 30)} style={{ background: 'rgba(32, 178, 171, 0.2)' }} />
                        <Input className="logistic_fee_title_column_short" ref="storage_export_benefit" value={fExportBenefit}
                            disabled />
                        <Icon type="delete" className="logistic_house_bill_icon"
                            style={{ color: 'lightcoral', marginLeft: '0px' }}
                            onClick={this.DeleteAllFee.bind(this, 15)} />
                    </div>
                );
            }
            // 16. 报关费
            let uiCustomFee = null;
            if (objOrder.custom_active === 1) {
                let fCustomBenefit = parseFloat(objOrder.custom_fee) - parseFloat(objOrder.custom_cost);
                if (typeof (fCustomBenefit) === 'number')
                    fCustomBenefit = fCustomBenefit.toFixed(2);
                this.arrAllFee[16].active = 1;
                uiCustomFee = (
                    <div className="auto_order_info_row">
                        <div className="order_detail_title">{this.arrAllFee[16].name + "："}</div>
                        <Input className="logistic_fee_title_column_short" ref="custom_cost" value={objOrder.custom_cost}
                            onChange={this.AllFeeInfoChange.bind(this, objOrder, 33)} />
                        <Input className="logistic_fee_title_column_short" ref="custom_fee" value={objOrder.custom_fee}
                            onChange={this.AllFeeInfoChange.bind(this, objOrder, 32)} style={{ background: 'rgba(32, 178, 171, 0.2)' }} />
                        <Input className="logistic_fee_title_column_short" ref="custom_benefit" value={fCustomBenefit} disabled />
                        <Icon type="delete" className="logistic_house_bill_icon"
                            style={{ color: 'lightcoral', marginLeft: '0px' }}
                            onClick={this.DeleteAllFee.bind(this, 16)} />
                    </div>
                );
            }
            // 17. 关税费
            let uiTaxFee = null;
            let fTaxBenefit = parseFloat(objOrder.tax_fee) - parseFloat(objOrder.tax_cost);
            if (typeof (fTaxBenefit) === 'number')
                fTaxBenefit = fTaxBenefit.toFixed(2);
            if (objOrder.tax_active === 1) {
                this.arrAllFee[17].active = 1;
                uiTaxFee = (
                    <div className="auto_order_info_row">
                        <div className="order_detail_title">{this.arrAllFee[17].name + "："}</div>
                        <Input className="logistic_fee_title_column_short" ref="tax_cost" value={objOrder.tax_cost}
                            onChange={this.AllFeeInfoChange.bind(this, objOrder, 35)} />
                        <Input className="logistic_fee_title_column_short" ref="tax_fee" value={objOrder.tax_fee}
                            onChange={this.AllFeeInfoChange.bind(this, objOrder, 34)} style={{ background: 'rgba(32, 178, 171, 0.2)' }} />
                        <Input className="logistic_fee_title_column_short" ref="tax_benefit" value={fTaxBenefit} disabled />
                        <Icon type="delete" className="logistic_house_bill_icon"
                            style={{ color: 'lightcoral', marginLeft: '0px' }}
                            onClick={this.DeleteAllFee.bind(this, 17)} />
                    </div>
                );
            }
            // 18. GST费
            let uiGSTFee = null;
            let fGSTBenefit = parseFloat(objOrder.gst_fee) - parseFloat(objOrder.gst_cost);
            if (typeof (fGSTBenefit) === 'number')
                fGSTBenefit = fGSTBenefit.toFixed(2);
            if (objOrder.gst_active === 1) {
                this.arrAllFee[18].active = 1;
                uiGSTFee = (
                    <div className="auto_order_info_row">
                        <div className="order_detail_title">{this.arrAllFee[18].name + "："}</div>
                        <Input className="logistic_fee_title_column_short" ref="gst_cost" value={objOrder.gst_cost}
                            onChange={this.AllFeeInfoChange.bind(this, objOrder, 37)} />
                        <Input className="logistic_fee_title_column_short" ref="gst_fee" value={objOrder.gst_fee}
                            onChange={this.AllFeeInfoChange.bind(this, objOrder, 36)} style={{ background: 'rgba(32, 178, 171, 0.2)' }} />
                        <Input className="logistic_fee_title_column_short" ref="gst_benefit" value={fGSTBenefit} disabled />
                        <Icon type="delete" className="logistic_house_bill_icon"
                            style={{ color: 'lightcoral', marginLeft: '0px' }}
                            onClick={this.DeleteAllFee.bind(this, 18)} />
                    </div>
                );
            }
            // 19. 超项费
            let uiOverItemFee = null;
            let fOverItemBenefit = parseFloat(objOrder.overitem_fee) - parseFloat(objOrder.overitem_cost);
            if (typeof (fOverItemBenefit) === 'number')
                fOverItemBenefit = fOverItemBenefit.toFixed(2);
            if (objOrder.overitem_active === 1) {
                this.arrAllFee[19].active = 1;
                uiOverItemFee = (
                    <div className="auto_order_info_row">
                        <div className="order_detail_title">{this.arrAllFee[19].name + "："}</div>
                        <Input className="logistic_fee_title_column_short" ref="overitem_cost" value={objOrder.overitem_cost}
                            onChange={this.AllFeeInfoChange.bind(this, objOrder, 39)} />
                        <Input className="logistic_fee_title_column_short" ref="overitem_fee" value={objOrder.overitem_fee}
                            onChange={this.AllFeeInfoChange.bind(this, objOrder, 38)} style={{ background: 'rgba(32, 178, 171, 0.2)' }} />
                        <Input className="logistic_fee_title_column_short" ref="overitem_benefit" value={fOverItemBenefit}
                            disabled />
                        <Icon type="delete" className="logistic_house_bill_icon"
                            style={{ color: 'lightcoral', marginLeft: '0px' }}
                            onClick={this.DeleteAllFee.bind(this, 19)} />
                    </div>
                );
            }
            // 20. CFIA费
            let uiCFIAFee = null;
            let fCFIABenefit = parseFloat(objOrder.cfia_fee) - parseFloat(objOrder.cfia_cost);
            if (typeof (fCFIABenefit) === 'number')
                fCFIABenefit = fCFIABenefit.toFixed(2);
            if (objOrder.cfia_active === 1) {
                this.arrAllFee[20].active = 1;
                uiCFIAFee = (
                    <div className="auto_order_info_row">
                        <div className="order_detail_title">{this.arrAllFee[20].name}</div>
                        <Input className="logistic_fee_title_column_short" ref="cfia_cost" value={objOrder.cfia_cost}
                            onChange={this.AllFeeInfoChange.bind(this, objOrder, 41)} />
                        <Input className="logistic_fee_title_column_short" ref="cfia_fee" value={objOrder.cfia_fee}
                            onChange={this.AllFeeInfoChange.bind(this, objOrder, 40)} style={{ background: 'rgba(32, 178, 171, 0.2)' }} />
                        <Input className="logistic_fee_title_column_short" ref="cfia_benefit" value={fCFIABenefit}
                            disabled />
                        <Icon type="delete" className="logistic_house_bill_icon"
                            style={{ color: 'lightcoral', marginLeft: '0px' }}
                            onClick={this.DeleteAllFee.bind(this, 20)} />
                    </div>
                );
            }
            // 21. 检查费
            let uiCheckFee = null;
            let fCheckBenefit = parseFloat(objOrder.check_fee) - parseFloat(objOrder.check_cost);
            if (typeof (fCheckBenefit) === 'number')
                fCheckBenefit = fCheckBenefit.toFixed(2);
            if (objOrder.check_active === 1) {
                this.arrAllFee[21].active = 1;
                uiCheckFee = (
                    <div className="auto_order_info_row">
                        <div className="order_detail_title">{this.arrAllFee[21].name + "："}</div>
                        <Input className="logistic_fee_title_column_short" ref="check_cost" value={objOrder.check_cost}
                            onChange={this.AllFeeInfoChange.bind(this, objOrder, 43)} />
                        <Input className="logistic_fee_title_column_short" ref="check_fee" value={objOrder.check_fee}
                            onChange={this.AllFeeInfoChange.bind(this, objOrder, 42)} style={{ background: 'rgba(32, 178, 171, 0.2)' }} />
                        <Input className="logistic_fee_title_column_short" ref="check_benefit" value={fCheckBenefit}
                            disabled />
                        <Icon type="delete" className="logistic_house_bill_icon"
                            style={{ color: 'lightcoral', marginLeft: '0px' }}
                            onClick={this.DeleteAllFee.bind(this, 21)} />
                    </div>
                );
            }
            // 22. 其他费用
            let uiOtherFee = null;
            let uiOtherFeeComments = null;
            if (objOrder.other_fee === null || objOrder.other_fee === undefined || objOrder.other_fee === "")
                objOrder.other_fee = 0.0;
            if (objOrder.other_cost === null || objOrder.other_cost === undefined || objOrder.other_cost === "")
                objOrder.other_cost = 0.0;
            let fOtherBenefit = parseFloat(objOrder.other_fee) - parseFloat(objOrder.other_cost);
            if (typeof (fOtherBenefit) === 'number')
                fOtherBenefit = fOtherBenefit.toFixed(2);
            if (objOrder.other_active === 1) {
                this.arrAllFee[22].active = 1;
                uiOtherFee = (
                    <div className="auto_order_info_row">
                        <div className="order_detail_title">{this.arrAllFee[22].name + "："}</div>
                        <Input className="logistic_fee_title_column_short" ref="other_cost" value={objOrder.other_cost}
                            onChange={this.AllFeeInfoChange.bind(this, objOrder, 45)} />
                        <Input className="logistic_fee_title_column_short" ref="other_fee" value={objOrder.other_fee}
                            onChange={this.AllFeeInfoChange.bind(this, objOrder, 44)} style={{ background: 'rgba(32, 178, 171, 0.2)' }} />
                        <Input className="logistic_fee_title_column_short" ref="other_benefit" value={fOtherBenefit}
                            disabled />
                        <Icon type="delete" className="logistic_house_bill_icon"
                            style={{ color: 'lightcoral', marginLeft: '0px' }}
                            onClick={this.DeleteAllFee.bind(this, 22)} />
                    </div>
                );
                uiOtherFeeComments = (
                    <div className="auto_order_info_row">
                        <div className="order_detail_title">说明：</div>
                        <Input className="order_detail_input_long" ref="other_fee_comments" defaultValue={objOrder.other_fee_comments} placeholder={"其他收费说明"} />
                    </div>
                );

            }

            objOrder = this.CalculateAllFee(objOrder);
            console.log("this.iCurrentAllFee : " + this.iCurrentAllFee, this.state.iCurrentAllFee);
            let uiFeeTypeMenu = null;
            let uiFeeTypeDropdown = null;
            let uiAddFeeButton = null;
            if (this.state.iCurrentAllFee >= 0 && this.state.iCurrentAllFee < this.arrAllFee.length) {
                uiFeeTypeMenu = (
                    <Menu>
                        {this.arrAllFee.map((item, index) => {
                            if (item.active === 0)
                                return (
                                    <Menu.Item key={index} onClick={this.AllFeeSwitch.bind(this, index)}>
                                        {item.name}
                                    </Menu.Item>
                                );
                            else
                                return null
                        })}
                    </Menu>
                );
                uiFeeTypeDropdown = (
                    <Dropdown className="order_detail_dropdown" trigger={['click']} overlay={uiFeeTypeMenu} placement="bottomLeft"
                        style={{ marginLeft: 'auto', marginRight: '0px' }}>
                        <Button>{this.arrAllFee[this.state.iCurrentAllFee].name}<Icon type="caret-down" /></Button>
                    </Dropdown>
                );
                uiAddFeeButton = (
                    <Button className="manage_contents_title_margin" type="primary"
                        onClick={this.AddAllFee.bind(this, this.state.iCurrentAllFee)}
                        style={{ color: 'white', marginLeft: '5px', marginRight: '5px' }}>
                        <Icon type="plus" />收费</Button>
                );
            }



            // 统一费用
            let uiAllFee = (
                <div className="auto_order_info_block">
                    <div className="auto_order_info_row">
                        <div className="order_detail_title">币种：</div>
                        <Dropdown className="logistic_fee_title_column" trigger={['click']}
                            overlay={uiCurrencyFilter} placement="bottomLeft" true>
                            <Button style={{ width: '125px' }}>{this.arrCurrency[this.state.iCurrency]}
                                <Icon type="caret-down" /></Button>
                        </Dropdown>
                        {uiFeeTypeDropdown}
                        {uiAddFeeButton}
                    </div>
                    <div className="auto_order_info_row logistic_fee_title_row">
                        <div className="logistic_fee_title_column_short">成本</div>
                        <div className="logistic_fee_title_column_short" style={{ weight: 'bold' }}>收费</div>
                        <div className="logistic_fee_title_column_short">收入</div>
                    </div>

                    {uiAgentFee}
                    {uiShipFee}
                    {uiPortFee}

                    {uiTruckFee}
                    {uiPrepickFee}
                    {uiStackFee}
                    {uiCarFrameFee}
                    {uiDangerFee}
                    {uiNullFee}

                    {uiOpenFee}
                    {uiDivideFee}
                    {uiOnPlateFee}
                    {uiTagFee}
                    {uiOversizeFee}
                    {uiStorageFee}
                    {uiExportFee}

                    {uiCustomFee}
                    {uiTaxFee}
                    {uiGSTFee}
                    {uiOverItemFee}
                    {uiCFIAFee}
                    {uiCheckFee}

                    {uiOtherFee}

                    {uiOtherFeeComments}
                    <div className="auto_order_info_row">
                        <div className="order_detail_title">小计：</div>
                        <Input className="logistic_fee_title_column_short" defaultValue={objOrder.all_total_cost}
                            value={objOrder.all_total_cost}
                            ref="all_total_cost" disabled style={{ color: 'darkcyan' }} />
                        <Input className="logistic_fee_title_column_short" defaultValue={objOrder.all_total_fee}
                            value={objOrder.all_total_fee} ref="all_total_fee" disabled
                            style={{ color: 'gray', background: 'rgba(32, 178, 171, 0.2)' }} />
                        <Input className="logistic_fee_title_column_short" defaultValue={objOrder.all_total_benefit}
                            value={objOrder.all_total_benefit} ref="all_total_benefit" disabled style={{ color: 'darkcyan' }} />
                    </div>
                </div>
            );
            if (this.state.iDlgType === 0)
                uiAllFee = null;

            // Tab0 发船 Block
            // let uiShipFee = null;
            let uiHouseBill0 = null;
            // Tab1 到港 Block
            let uiRelease = null;
            let uiPortInfo = null;
            // let uiPortFee = null;
            // Tab2 仓库 block
            // let uiStorageFee = null;
            let uiHouseBill2 = null;
            // Tab3 清关 block
            // let uiCustomFee = null;
            let uiHouseBill3 = null;
            // Tab4 换单 block
            // let uiTransitFee = null;
            let uiHouseBill45 = null;
            // Tab5 自提 block
            // let uiPickupFee = null;
            // 编辑状态才开放的内容
            if (this.state.iDlgType === 1) {
                // strCancel = "关闭";
                // strConfirm = "保存";
                /* 各部分不同信息，以及HouseBill等 */
                // let bFeeConfirm = true;
                // Tab0 发船
                if (this.state.iDlgTab === 0) {
                    // let bDefaultRelease = false;
                    // if (objOrder.ship_release === 1)
                    //     bDefaultRelease = true;
                    // let bRelease = false;
                    // if (this.state.iShipRelease === 1)
                    //     bRelease = true
                    uiAgent = (
                        <div className="auto_order_info_row">
                            <div className="order_detail_title">目的港代理</div>
                            <Input className="order_detail_input_long" ref="agent" defaultValue={objOrder.agent} disabled
                                style={{ background: 'white', color: 'rgba(0, 0, 0, 0.75)' }} />
                        </div>
                    );
                    uiRelease = null;
                    // uiRelease = (
                    //     <div className="auto_order_info_block">
                    //         <div className="auto_order_info_row">
                    //             <div className="order_detail_title" style={{ color: 'lightcoral' }}>已发船</div>
                    //             <Switch onChange={this.ShipReleaseSwitch.bind(this, 0)} style={{ marginRight: '160px' }}
                    //                 defaultChecked={bDefaultRelease} checked={bRelease} />
                    //         </div>
                    //     </div>
                    // );
                    let fAgentBenefit = parseFloat(objOrder.agent_fee) - parseFloat(objOrder.agent_cost);
                    if (typeof (fAgentBenefit) === 'number')
                        fAgentBenefit = fAgentBenefit.toFixed(2);
                    let fShipBenefit = parseFloat(objOrder.ship_fee) - parseFloat(objOrder.ship_cost);
                    if (typeof (fShipBenefit) === 'number')
                        fShipBenefit = fShipBenefit.toFixed(2);
                    let fPortBenefit = parseFloat(objOrder.port_fee) - parseFloat(objOrder.port_cost);
                    if (typeof (fPortBenefit) === 'number')
                        fPortBenefit = fPortBenefit.toFixed(2);
                    let fShipOtherBenefit = parseFloat(objOrder.ship_other_fee) - parseFloat(objOrder.ship_other_cost);
                    if (typeof (fShipOtherBenefit) === 'number')
                        fShipOtherBenefit = fShipOtherBenefit.toFixed(2);
                    objOrder = this.CalculateShipFee(objOrder);
                    // bFeeConfirm = true;
                    // if (this.state.iShipFeeConfirm === 0)
                    //     bFeeConfirm = false;

                    let uiHouseBillList = null;

                    if (this.arrHouseBill.length > 0) {
                        uiHouseBillList = (
                            <div className="storage_sku_list_area">
                                {this.arrHouseBill.map((house_bill, index) => {
                                    // console.log("render: ", house_bill);
                                    let uiEmailButton = (
                                        <Icon type="mail" className="logistic_house_bill_icon"
                                            style={{
                                                fontWeight: 'bold', marginLeft: '0px', marginRight: '0px',
                                                minWidth: '40px', maxWidth: '40px'
                                            }}
                                            onClick={this.OpenReportText.bind(this, index)} />
                                    );
                                    let uiDeleteButton = (
                                        <Icon type="delete" className="logistic_house_bill_icon" style={{ color: 'lightcoral' }} onClick={this.DeleteHouseBill.bind(this, index)} />
                                    );
                                    let uiEditButton = (
                                        <Icon type="edit" className="logistic_house_bill_icon" style={{ marginLeft: 'auto' }} onClick={this.CreateEditHouseBill.bind(this, 1, index)} />
                                    );

                                    if (this.props.iUserLevel < 5) {
                                        uiEditButton = (
                                            <Icon type="search" className="logistic_house_bill_icon" style={{ marginLeft: 'auto' }} onClick={this.CreateEditHouseBill.bind(this, 1, index)} />
                                        );
                                        uiDeleteButton = null;
                                    }
                                    let strStyle = "logistic_house_bill_row";
                                    if (this.strHBLKeyword !== undefined && this.strHBLKeyword !== null && this.strHBLKeyword !== '') {
                                        if (house_bill.mark.indexOf(this.strHBLKeyword) >= 0)
                                            strStyle += " logistic_house_bill_row_select";
                                    }

                                    return (
                                        <div className={strStyle} key={index} >
                                            <div style={{ fontWeight: 'bold', marginRight: '20px', minWidth: '125px', maxWidth: '125px' }}>
                                                {house_bill.oid + house_bill.house_bill_no}</div>
                                            <div style={{ fontWeight: 'bold', marginRight: '20px', minWidth: '135px', maxWidth: '135px' }}>
                                                {house_bill.mark}</div>
                                            <div style={{ fontWeight: 'bold', marginRight: '20px', minWidth: '150px', maxWidth: '150px' }}>
                                                {house_bill.receiver_name}</div>
                                            <div style={{ fontWeight: 'bold', marginRight: '20px', minWidth: '100px', maxWidth: '100px' }}>
                                                {this.arrPath[house_bill.path]}</div>
                                            {uiEmailButton}
                                            {uiEditButton}
                                            {uiDeleteButton}
                                        </div>
                                    );
                                })}
                            </div>
                        );
                        let uiTitle = (
                            <div className="title_row" >
                                {"预报信息"}
                                <Button className="manage_contents_title_margin" type="primary"
                                    style={{ marginLeft: "auto", marginRight: '20px' }}
                                    onClick={this.CopyPasteAction.bind(this)} >
                                    复制全部</Button>
                            </div>

                        );
                        uiReportDlg = (
                            <Modal width={900} title={uiTitle} visible={this.state.bReportDlg} closable={true}
                                maskClosable={true} footer={null} onCancel={this.CloseReportDlg.bind(this)} >
                                <TextArea size="large" autoSize={{ minRows: 10 }} allowClear={false} ref="client_notes"
                                    style={{ marginBottom: '0px' }} placeholder=""
                                    value={this.strEmailTextHaiPai} />
                            </Modal >
                        );

                    }
                    let uiCreateButton = (
                        <Button type='primary' onClick={this.CreateEditHouseBill.bind(this, 0, -1)}
                            style={{ marginLeft: 'auto', marginRight: '5px' }} > + 创建</Button>
                    );
                    uiHouseBill0 = (
                        <div className="auto_order_info_block">
                            <div className="auto_order_info_row logistic_order_row_title">
                                <div ><b>House Bill 列表</b></div>
                                {uiCreateButton}
                            </div>
                            <div className="logistic_house_bill_row" style={{ color: 'grey' }}>
                                <div style={{ fontWeight: 'bold', marginRight: '20px', minWidth: '125px', maxWidth: '125px' }}>
                                    编号</div>
                                <div style={{ fontWeight: 'bold', marginRight: '20px', minWidth: '135px', maxWidth: '135px' }}>
                                    唛头</div>
                                <div style={{ fontWeight: 'bold', marginRight: '20px', minWidth: '150px', maxWidth: '150px' }}>
                                    收货人</div>
                                <div style={{ fontWeight: 'bold', marginRight: '20px', minWidth: '100px', maxWidth: '100px' }}>
                                    路径</div>
                                <div style={{ fontWeight: 'bold', marginRight: '20px', minWidth: '110px', maxWidth: '110px' }}>
                                    预报</div>
                            </div>
                            {uiHouseBillList}
                        </div>
                    );
                    if (this.props.iUserLevel < 5) {
                        uiBasicInfo0 = (
                            <div className="auto_order_info_block">
                                {uiAgent}
                                <div className="auto_order_info_row">
                                    <div className="order_detail_title">编号前缀：</div>
                                    <Input className="order_detail_input" ref="oid_before" defaultValue={objOrder.oid_before} disabled />
                                    <div className="order_detail_title" style={{ color: 'lightcoral' }}>柜号：</div>
                                    <Input className="order_detail_input" ref="closet" defaultValue={objOrder.closet} disabled />
                                    <div className="order_detail_title" >物流单号：</div>
                                    <Input className="order_detail_input" ref="express" defaultValue={objOrder.express} disabled />
                                    <div className="order_detail_title">重量：</div>
                                    <Input className="order_detail_input" ref="weight" defaultValue={objOrder.weight} addonAfter={'KG'} disabled />
                                </div>
                                <div className="auto_order_info_row">
                                    <div className="order_detail_title">到港城市：</div>
                                    <Input className="order_detail_input" ref="port" defaultValue={objOrder.port} disabled />
                                    <div className="order_detail_title">预计到港：</div>
                                    <DatePicker className="order_date_pickup" onChange={this.ArriveDateChange} disabled
                                        value={moment(this.state.eta, dateFormat2)} placeholder="请选择日期" />
                                    <div className="order_detail_title">件数：</div>
                                    <Input className="order_detail_input" ref="num" defaultValue={objOrder.num} addonAfter={'PKG'} disabled />
                                </div>
                                <div className="auto_order_info_row">
                                    <div className="order_detail_title">目的城市：</div>
                                    <Input className="order_detail_input" ref="destination" defaultValue={objOrder.destination} disabled />
                                    <div className="order_detail_title">预计到仓：</div>
                                    <DatePicker className="order_date_pickup" onChange={this.StorageDate} disabled
                                        value={moment(this.state.etd, dateFormat2)} placeholder="请选择日期" />
                                    <div className="order_detail_title">方数：</div>
                                    <Input className="order_detail_input" ref="cbm" defaultValue={objOrder.cbm} addonAfter={'CBM'} disabled />
                                </div>
                            </div>
                        );
                        // bFeeConfirm = true;
                        // if (this.state.iShipFeeConfirm === 0)
                        //     bFeeConfirm = false;

                        uiHouseBill0 = (
                            <div className="auto_order_info_block">
                                <div className="auto_order_info_row logistic_order_row_title">
                                    <div ><b>House Bill 列表</b></div>
                                </div>
                                <div className="logistic_house_bill_row" style={{ color: 'grey' }}>
                                    <div style={{ fontWeight: 'bold', marginRight: '20px', minWidth: '125px', maxWidth: '125px' }}>
                                        编号</div>
                                    <div style={{ fontWeight: 'bold', marginRight: '20px', minWidth: '135px', maxWidth: '135px' }}>
                                        唛头</div>
                                    <div style={{ fontWeight: 'bold', marginRight: '20px', minWidth: '150px', maxWidth: '150px' }}>
                                        收货人</div>
                                    <div style={{ fontWeight: 'bold', marginRight: '20px', minWidth: '100px', maxWidth: '100px' }}>
                                        路径</div>
                                </div>
                                {uiHouseBillList}
                            </div>
                        );
                    }
                }
                // Tab1 到港
                if (this.state.iDlgTab === 1) {
                    let bDefaultRelease = [false, false];
                    if (objOrder.order_release === 1)
                        bDefaultRelease[0] = true;
                    if (objOrder.custom_release === 1)
                        bDefaultRelease[1] = true;
                    let bRelease = [false, false];
                    if (this.state.arrPortRelease[0] === 1)
                        bRelease[0] = true;
                    if (this.state.arrPortRelease[1] === 1)
                        bRelease[1] = true;
                    if (this.state.arrPortRelease[2] === 1)
                        bRelease[2] = true;
                    uiRelease = null;
                    uiPortInfo = (
                        <div className="auto_order_info_block">
                            {/* <div className="auto_order_info_row">
                                <div className="order_detail_title">码头免租期</div>
                                <DatePicker className="order_date_pickup" onChange={this.FreeUseDateChange}
                                    value={moment(this.state.free_use_date, dateFormat2)} placeholder="请选择日期" />
                                <div className="order_detail_title">箱使期限：</div>
                                <DatePicker className="order_date_pickup" onChange={this.BoxUseDateChange}
                                    value={moment(this.state.box_use_date, dateFormat2)} placeholder="请选择日期" />
                            </div> */}
                            <div className="auto_order_info_row" style={{ background: 'rgba(240, 128, 128, 0.5)', padding: '5px', borderRadius: '5px' }}>
                                <div className="order_detail_title">拖柜：</div>
                                <Input className="order_detail_input_long" ref="truck" defaultValue={objOrder.truck} disabled
                                    style={{ background: 'rgba(240, 128, 128, 0.25);', color: 'rgba(0, 0, 0, 0.75)' }} />
                                <Button type='primary' onClick={this.SelectTruck.bind(this)} style={{ marginLeft: '5px', marginRight: '5px' }} >选择</Button>
                            </div>
                        </div>
                    );
                    let fTruckBenefit = parseFloat(objOrder.truck_fee) - parseFloat(objOrder.truck_cost);
                    if (typeof (fTruckBenefit) === 'number')
                        fTruckBenefit = fTruckBenefit.toFixed(2);
                    let fPrePickBenefit = parseFloat(objOrder.prepick_fee) - parseFloat(objOrder.prepick_cost);
                    if (typeof (fPrePickBenefit) === 'number')
                        fPrePickBenefit = fPrePickBenefit.toFixed(2);
                    let fStackBenefit = parseFloat(objOrder.stack_fee) - parseFloat(objOrder.stack_cost);
                    if (typeof (fStackBenefit) === 'number')
                        fStackBenefit = fStackBenefit.toFixed(2);
                    let fCarFrameBenefit = parseFloat(objOrder.car_frame_fee) - parseFloat(objOrder.car_frame_cost);
                    if (typeof (fCarFrameBenefit) === 'number')
                        fCarFrameBenefit = fCarFrameBenefit.toFixed(2);

                    let fDangerBenefit = parseFloat(objOrder.danger_fee) - parseFloat(objOrder.danger_cost);
                    if (typeof (fDangerBenefit) === 'number')
                        fDangerBenefit = fDangerBenefit.toFixed(2);
                    let fNullBenefit = parseFloat(objOrder.null_fee) - parseFloat(objOrder.null_cost);
                    if (typeof (fNullBenefit) === 'number')
                        fNullBenefit = fNullBenefit.toFixed(2);
                    let fOtherBenefit = parseFloat(objOrder.port_other_fee) - parseFloat(objOrder.port_other_cost);
                    if (typeof (fOtherBenefit) === 'number')
                        fOtherBenefit = fOtherBenefit.toFixed(2);
                    objOrder = this.CalculatePortFee(objOrder);

                    let bFeeConfirm = true;
                    if (this.state.iPortFeeConfirm === 0)
                        bFeeConfirm = false;

                    uiPortFee = (
                        <div className="auto_order_info_block">
                            <div className="auto_order_info_row">
                                <div className="order_detail_title">币种：</div>
                                <Input className="logistic_fee_title_column" defaultValue={this.arrCurrency[objOrder.currency]}
                                    disabled style={{ background: 'white', color: 'grey' }} />
                                <div className="order_detail_title" style={{ color: 'darkcyan' }}>费用确认</div>
                                <Switch onChange={this.FeeConfirmSwitch.bind(this, 1)} style={{ marginRight: '160px' }}
                                    defaultChecked={bFeeConfirm} checked={bFeeConfirm} />
                            </div>
                            <div className="auto_order_info_row logistic_fee_title_row">
                                <div className="logistic_fee_title_column">成本</div>
                                <div className="logistic_fee_title_column">收费</div>
                                <div className="logistic_fee_title_column">收入</div>
                            </div>
                            <div className="auto_order_info_row">
                                <div className="order_detail_title">拖柜：</div>
                                <Input className="logistic_fee_title_column" ref="truck_cost" value={objOrder.truck_cost}
                                    onChange={this.PortFeeInfoChange.bind(this, objOrder, 1)} />
                                <Input className="logistic_fee_title_column" ref="truck_fee" value={objOrder.truck_fee}
                                    onChange={this.PortFeeInfoChange.bind(this, objOrder, 0)} style={{ background: 'rgba(32, 178, 171, 0.2)' }} />
                                <Input className="logistic_fee_title_column" ref="truck_benefit" value={fTruckBenefit}
                                    disabled />
                            </div>
                            <div className="auto_order_info_row">
                                <div className="order_detail_title">预提柜：</div>
                                <Input className="logistic_fee_title_column" ref="prepick_cost" value={objOrder.prepick_cost}
                                    onChange={this.PortFeeInfoChange.bind(this, objOrder, 3)} />
                                <Input className="logistic_fee_title_column" ref="prepick_fee" value={objOrder.prepick_fee}
                                    onChange={this.PortFeeInfoChange.bind(this, objOrder, 2)} style={{ background: 'rgba(32, 178, 171, 0.2)' }} />
                                <Input className="logistic_fee_title_column" ref="prepick_benefit" value={fPrePickBenefit}
                                    disabled />
                            </div>
                            <div className="auto_order_info_row">
                                <div className="order_detail_title">堆场：</div>
                                <Input className="logistic_fee_title_column" ref="stack_cost" value={objOrder.stack_cost}
                                    onChange={this.PortFeeInfoChange.bind(this, objOrder, 5)} />
                                <Input className="logistic_fee_title_column" ref="stack_fee" value={objOrder.stack_fee}
                                    onChange={this.PortFeeInfoChange.bind(this, objOrder, 4)} style={{ background: 'rgba(32, 178, 171, 0.2)' }} />
                                <Input className="logistic_fee_title_column" ref="stack_benefit" value={fStackBenefit}
                                    disabled />
                            </div>
                            <div className="auto_order_info_row">
                                <div className="order_detail_title">车架：</div>
                                <Input className="logistic_fee_title_column" ref="car_frame_cost" value={objOrder.car_frame_cost}
                                    onChange={this.PortFeeInfoChange.bind(this, objOrder, 7)} />
                                <Input className="logistic_fee_title_column" ref="car_frame_fee" value={objOrder.car_frame_fee}
                                    onChange={this.PortFeeInfoChange.bind(this, objOrder, 6)} style={{ background: 'rgba(32, 178, 171, 0.2)' }} />
                                <Input className="logistic_fee_title_column" ref="car_frame_benefit" value={fCarFrameBenefit}
                                    disabled />
                            </div>
                            <div className="auto_order_info_row">
                                <div className="order_detail_title">危险品：</div>
                                <Input className="logistic_fee_title_column" ref="danger_cost" value={objOrder.danger_cost}
                                    onChange={this.PortFeeInfoChange.bind(this, objOrder, 9)} />
                                <Input className="logistic_fee_title_column" ref="danger_fee" value={objOrder.danger_fee}
                                    onChange={this.PortFeeInfoChange.bind(this, objOrder, 8)} style={{ background: 'rgba(32, 178, 171, 0.2)' }} />
                                <Input className="logistic_fee_title_column" ref="danger_benefit" value={fDangerBenefit}
                                    disabled />
                            </div>
                            <div className="auto_order_info_row">
                                <div className="order_detail_title">空跑：</div>
                                <Input className="logistic_fee_title_column" ref="null_cost" value={objOrder.null_cost}
                                    onChange={this.PortFeeInfoChange.bind(this, objOrder, 11)} />
                                <Input className="logistic_fee_title_column" ref="null_fee" value={objOrder.null_fee}
                                    onChange={this.PortFeeInfoChange.bind(this, objOrder, 10)} style={{ background: 'rgba(32, 178, 171, 0.2)' }} />
                                <Input className="logistic_fee_title_column" ref="null_benefit" value={fNullBenefit}
                                    disabled />
                            </div>
                            <div className="auto_order_info_row">
                                <div className="order_detail_title">其他：</div>
                                <Input className="logistic_fee_title_column" ref="port_other_cost" value={objOrder.port_other_cost}
                                    onChange={this.PortFeeInfoChange.bind(this, objOrder, 13)} />
                                <Input className="logistic_fee_title_column" ref="port_other_fee" value={objOrder.port_other_fee}
                                    onChange={this.PortFeeInfoChange.bind(this, objOrder, 12)} style={{ background: 'rgba(32, 178, 171, 0.2)' }} />
                                <Input className="logistic_fee_title_column" ref="port_other_benefit" value={fOtherBenefit}
                                    disabled />
                            </div>
                            <div className="auto_order_info_row">
                                <div className="order_detail_title">说明：</div>
                                <Input className="order_detail_input_long" ref="port_other_fee_comments" defaultValue={objOrder.port_other_fee_comments}
                                    placeholder={"到港其他收费说明"} />
                            </div>
                            <div className="auto_order_info_row">
                                <div className="order_detail_title">小计：</div>
                                <Input className="logistic_fee_title_column" value={objOrder.port_total_cost}
                                    ref="port_total_cost" disabled style={{ color: 'darkcyan' }} />
                                <Input className="logistic_fee_title_column" value={objOrder.port_total_fee} ref="port_total_fee"
                                    disabled style={{ color: 'gray', background: 'rgba(32, 178, 171, 0.2)' }} />
                                <Input className="logistic_fee_title_column" value={objOrder.port_total_benefit}
                                    ref="port_total_benefit" disabled style={{ color: 'darkcyan' }} />
                            </div>
                        </div>
                    );
                    if (this.props.iUserLevel < 5) {
                        uiBasicInfo1 = (
                            <div className="auto_order_info_block">
                                {/* {uiStorage} */}
                                <div className="logistic_info_area">
                                    <div className="auto_order_info_row" style={{ margin: '0px' }}>
                                        <div style={{ fontWeight: 'bold' }}>前缀：</div>
                                        {objOrder.oid_before}
                                        <div style={{ minWidth: '15px' }} />
                                        <div style={{ fontWeight: 'bold' }}>柜号：</div>
                                        {objOrder.closet}
                                        <div style={{ minWidth: '15px' }} />
                                        <div style={{ fontWeight: 'bold' }}>物流：</div>
                                        {objOrder.express}
                                        <div style={{ minWidth: '15px' }} />
                                        <div style={{ fontWeight: 'bold' }}>目的地：</div>
                                        {objOrder.destination}
                                        <div style={{ minWidth: '15px' }} />
                                        <div style={{ fontWeight: 'bold' }}>件数：</div>
                                        {objOrder.num + " PKG"}
                                        <div style={{ minWidth: '15px' }} />
                                        <div style={{ fontWeight: 'bold' }}>重量：</div>
                                        {objOrder.weight + " KG"}
                                        <div style={{ minWidth: '15px' }} />
                                        <div style={{ fontWeight: 'bold' }}>方数：</div>
                                        {objOrder.cbm + " CBM"}
                                    </div>
                                </div>
                                <div className="auto_order_info_row">
                                    <div className="order_detail_title">预计到港：</div>
                                    <DatePicker className="order_date_pickup" onChange={this.ArriveDateChange} disabled
                                        value={moment(this.state.eta, dateFormat2)} placeholder="请选择日期" />
                                    <div className="order_detail_title">预计到仓：</div>
                                    <DatePicker className="order_date_pickup" onChange={this.StorageDate} disabled
                                        value={moment(this.state.etd, dateFormat2)} placeholder="请选择日期" />

                                </div>
                                {/* <div className="auto_order_info_row">
                                    <div className="order_detail_title" style={{ marginTop: '2.5px', marginBottom: 'auto' }}>备注：</div>
                                    <TextArea size="large" autoSize={{ minRows: 2 }} allowClear={true} ref="comments" style={{ marginBottom: '0px' }}
                                        placeholder="" defaultValue={objOrder.comments} />
                                </div>
                                <div className="auto_order_info_row">
                                    <div className="order_detail_title" style={{ marginTop: '2.5px', marginBottom: 'auto' }}>操作指示：</div>
                                    <TextArea size="large" autoSize={{ minRows: 2 }} allowClear={true} ref="client_notes" style={{ marginBottom: '0px' }}
                                        placeholder="" defaultValue={objOrder.client_notes} />
                                </div> */}
                            </div>
                        );
                        uiPortInfo = null;
                        // (
                        //     <div className="auto_order_info_block">
                        //         <div className="auto_order_info_row">
                        //             <div className="order_detail_title">码头免租期</div>
                        //             <DatePicker className="order_date_pickup" onChange={this.FreeUseDateChange} disabled
                        //                 value={moment(this.state.free_use_date, dateFormat2)} placeholder="请选择日期" />
                        //             <div className="order_detail_title">箱使期限：</div>
                        //             <DatePicker className="order_date_pickup" onChange={this.BoxUseDateChange} disabled
                        //                 value={moment(this.state.box_use_date, dateFormat2)} placeholder="请选择日期" />
                        //         </div>
                        //     </div>
                        // );
                        let bFeeConfirm = true;
                        if (this.state.iPortFeeConfirm === 0)
                            bFeeConfirm = false;
                        uiPortFee = (
                            <div className="auto_order_info_block">
                                <div className="auto_order_info_row">
                                    <div className="order_detail_title">币种：</div>
                                    <Input className="logistic_fee_title_column" defaultValue={this.arrCurrency[objOrder.currency]}
                                        disabled style={{ background: 'white', color: 'grey' }} />
                                    <div className="order_detail_title" style={{ color: 'lightcoral' }}>费用确认</div>
                                    <Switch onChange={this.FeeConfirmSwitch.bind(this, 1)} style={{ marginRight: '160px' }}
                                        defaultChecked={bFeeConfirm} checked={bFeeConfirm} />
                                </div>
                                <div className="auto_order_info_row logistic_fee_title_row">
                                    <div className="logistic_fee_title_column">收费</div>
                                </div>
                                <div className="auto_order_info_row">
                                    <div className="order_detail_title">拖柜：</div>
                                    <Input className="logistic_fee_title_column" ref="truck_fee" value={objOrder.truck_fee} disabled
                                        onChange={this.PortFeeInfoChange.bind(this, objOrder, 0)} style={{ background: 'rgba(32, 178, 171, 0.2)' }} />
                                </div>
                                <div className="auto_order_info_row">
                                    <div className="order_detail_title">预提柜：</div>
                                    <Input className="logistic_fee_title_column" ref="prepick_fee" defaultValue={objOrder.prepick_fee} disabled
                                        onChange={this.PortFeeInfoChange.bind(this, objOrder, 2)} style={{ background: 'rgba(32, 178, 171, 0.2)' }} />
                                </div>
                                <div className="auto_order_info_row">
                                    <div className="order_detail_title">堆场：</div>
                                    <Input className="logistic_fee_title_column" ref="stack_fee" defaultValue={objOrder.stack_fee} disabled
                                        onChange={this.PortFeeInfoChange.bind(this, objOrder, 4)} style={{ background: 'rgba(32, 178, 171, 0.2)' }} />
                                </div>
                                <div className="auto_order_info_row">
                                    <div className="order_detail_title">车架：</div>
                                    <Input className="logistic_fee_title_column" ref="car_frame_fee" defaultValue={objOrder.car_frame_fee} disabled
                                        onChange={this.PortFeeInfoChange.bind(this, objOrder, 6)} style={{ background: 'rgba(32, 178, 171, 0.2)' }} />
                                </div>
                                <div className="auto_order_info_row">
                                    <div className="order_detail_title">危险品：</div>
                                    <Input className="logistic_fee_title_column" ref="danger_fee" value={objOrder.danger_fee} disabled
                                        onChange={this.PortFeeInfoChange.bind(this, objOrder, 8)} style={{ background: 'rgba(32, 178, 171, 0.2)' }} />
                                </div>
                                <div className="auto_order_info_row">
                                    <div className="order_detail_title">空跑：</div>
                                    <Input className="logistic_fee_title_column" ref="null_fee" value={objOrder.null_fee} disabled
                                        onChange={this.PortFeeInfoChange.bind(this, objOrder, 10)} style={{ background: 'rgba(32, 178, 171, 0.2)' }} />
                                </div>
                                <div className="auto_order_info_row">
                                    <div className="order_detail_title">其他：</div>
                                    <Input className="logistic_fee_title_column" ref="port_other_fee" value={objOrder.port_other_fee} disabled
                                        onChange={this.PortFeeInfoChange.bind(this, objOrder, 12)} style={{ background: 'rgba(32, 178, 171, 0.2)' }} />
                                </div>
                                <div className="auto_order_info_row">
                                    <div className="order_detail_title">说明：</div>
                                    <Input className="order_detail_input_long" ref="port_other_fee_comments" defaultValue={objOrder.port_other_fee_comments}
                                        placeholder={"到港其他收费说明"} disabled />
                                </div>
                                <div className="auto_order_info_row">
                                    <div className="order_detail_title">小计：</div>
                                    <Input className="logistic_fee_title_column" value={objOrder.port_total_fee} ref="port_total_fee"
                                        disabled style={{ color: 'gray', background: 'rgba(32, 178, 171, 0.2)' }} />
                                </div>
                            </div>
                        );
                    }
                }
                // Tab2 仓库
                if (this.state.iDlgTab === 2) {
                    let bDefaultRelease = [false, false];
                    if (objOrder.open_release === 1)
                        bDefaultRelease[0] = true;
                    let bRelease = [false, false];
                    if (this.state.arrStorageRelease[0] === 1)
                        bRelease[0] = true;
                    uiRelease = null;
                    // (
                    //     <div className="auto_order_info_block">
                    //         <div className="auto_order_info_row">
                    //             <div className="order_detail_title" style={{ color: 'lightcoral' }}>拆柜完成</div>
                    //             <Switch onChange={this.StorageReleaseSwitch.bind(this, 0)} style={{ marginRight: '160px' }}
                    //                 defaultChecked={bDefaultRelease[0]} checked={bRelease[0]} />
                    //         </div>
                    //     </div>
                    // );
                    let fOpenClosetBenefit = parseFloat(objOrder.open_closet_fee) - parseFloat(objOrder.open_closet_cost);
                    if (typeof (fOpenClosetBenefit) === 'number')
                        fOpenClosetBenefit = fOpenClosetBenefit.toFixed(2);

                    let fDivideItemBenefit = parseFloat(objOrder.divide_item_fee) - parseFloat(objOrder.divide_item_cost);
                    if (typeof (fDivideItemBenefit) === 'number')
                        fDivideItemBenefit = fDivideItemBenefit.toFixed(2);
                    objOrder = this.CalculateStorageFee(objOrder);
                    this.arrStorageFee[1].active = 1;
                    let bFeeConfirm = true;
                    if (this.state.iStorageFeeConfirm === 0)
                        bFeeConfirm = false;
                    uiStorageFee = (
                        <div className="auto_order_info_block">
                            <div className="auto_order_info_row">
                                <div className="order_detail_title">币种：</div>
                                <Input className="logistic_fee_title_column" defaultValue={this.arrCurrency[objOrder.currency]}
                                    disabled style={{ background: 'white', color: 'grey' }} />
                                <div className="order_detail_title" style={{ color: 'darkcyan' }}>费用确认</div>
                                <Switch onChange={this.FeeConfirmSwitch.bind(this, 2)} style={{ marginRight: '160px' }}
                                    defaultChecked={bFeeConfirm} checked={bFeeConfirm} />
                            </div>
                            <div className="auto_order_info_row logistic_fee_title_row">
                                <div className="logistic_fee_title_column_short">成本</div>
                                <div className="logistic_fee_title_column_short">收费</div>
                                <div className="logistic_fee_title_column_short">利润</div>
                            </div>
                            <div className="auto_order_info_row">
                                <div className="order_detail_title">拆柜：</div>
                                <Input className="logistic_fee_title_column_short" ref="open_closet_cost"
                                    value={objOrder.open_closet_cost}
                                    onChange={this.StorageFeeInfoChange.bind(this, objOrder, 1)} />
                                <Input className="logistic_fee_title_column_short" ref="open_closet_fee"
                                    value={objOrder.open_closet_fee}
                                    onChange={this.StorageFeeInfoChange.bind(this, objOrder, 0)} style={{ background: 'rgba(32, 178, 171, 0.2)' }} />
                                <Input className="logistic_fee_title_column_short" ref="open_closet_benefit" value={fOpenClosetBenefit}
                                    disabled />
                            </div>
                            <div className="auto_order_info_row">
                                <div className="order_detail_title">分货：</div>
                                <Input className="logistic_fee_title_column_short" ref="divide_item_cost"
                                    value={objOrder.divide_item_cost}
                                    onChange={this.StorageFeeInfoChange.bind(this, objOrder, 3)} />
                                <Input className="logistic_fee_title_column_short" ref="divide_item_fee"
                                    value={objOrder.divide_item_fee}
                                    onChange={this.StorageFeeInfoChange.bind(this, objOrder, 2)} style={{ background: 'rgba(32, 178, 171, 0.2)' }} />
                                <Input className="logistic_fee_title_column_short" ref="divide_item_benefit"
                                    value={fDivideItemBenefit} disabled />
                            </div>
                            <div className="auto_order_info_row">
                                <div className="order_detail_title">小计：</div>
                                <Input className="logistic_fee_title_column_short" value={objOrder.storage_total_cost}
                                    ref="storage_total_cost" disabled style={{ color: 'darkcyan' }} />
                                <Input className="logistic_fee_title_column_short" value={objOrder.storage_total_fee} ref="storage_total_fee"
                                    disabled style={{ color: 'darkcyan', background: 'rgba(32, 178, 171, 0.2)' }} />
                                <Input className="logistic_fee_title_column_short" value={objOrder.storage_total_benefit}
                                    ref="storage_total_benefit" disabled style={{ color: 'darkcyan' }} />
                            </div>
                        </div>
                    );
                    let uiHouseBillList = null;
                    if (this.arrHouseBill.length > 0) {
                        let uiTitle = (
                            <div className="title_row" >
                                {"放货信息"}
                                <Button className="manage_contents_title_margin" type="primary"
                                    style={{ marginLeft: "auto", marginRight: '20px' }}
                                    onClick={this.CopyPasteAction.bind(this)} >
                                    复制全部</Button>
                            </div>

                        );
                        uiReleaseDlg = (
                            <Modal width={900} title={uiTitle} visible={this.state.bReleaseDlg} closable={true}
                                maskClosable={true} footer={null} onCancel={this.CloseReleaseDlg.bind(this)} >
                                <TextArea size="large" autoSize={{ minRows: 10 }} allowClear={false} ref="client_notes"
                                    style={{ marginBottom: '0px' }} placeholder=""
                                    value={this.strReleaseText} />
                            </Modal >
                        );
                        uiHouseBillList = (
                            <div className="storage_sku_list_area">
                                {this.arrHouseBill.map((house_bill, index) => {
                                    // 放货按钮
                                    let uiReleaseButton = (
                                        <Icon type="dropbox" className="logistic_house_bill_icon"
                                            style={{
                                                fontWeight: 'bold', marginLeft: '0px', marginRight: '0px',
                                                minWidth: '40px', maxWidth: '40px'
                                            }} onClick={this.OpenReleaseText.bind(this, index)}
                                        />
                                    );
                                    return (
                                        <div className="logistic_house_bill_row" key={index} >
                                            <div style={{ fontWeight: 'bold', marginRight: '20px', minWidth: '125px', maxWidth: '125px' }}>
                                                {house_bill.oid + house_bill.house_bill_no}</div>
                                            <div style={{ fontWeight: 'bold', marginRight: '20px', minWidth: '135px', maxWidth: '135px' }}>
                                                {house_bill.mark}</div>
                                            <div style={{ fontWeight: 'bold', marginRight: '20px', minWidth: '150px', maxWidth: '150px' }}>
                                                {house_bill.receiver_name}</div>
                                            <div style={{ fontWeight: 'bold', marginRight: '20px', minWidth: '100px', maxWidth: '100px' }}>
                                                {this.arrPath[house_bill.path]}</div>
                                            {uiReleaseButton}
                                            <Icon type="edit" className="logistic_house_bill_icon" style={{ marginLeft: 'auto' }}
                                                onClick={this.CreateEditHouseBill.bind(this, 1, index)} />
                                            {/* <Icon type="delete" className="logistic_house_bill_icon" style={{ color: 'lightcoral' }} onClick={this.DeleteHouseBill.bind(this, index)} /> */}
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    }
                    uiHouseBill2 = (
                        <div className="auto_order_info_block">
                            <div className="auto_order_info_row logistic_order_row_title">
                                <div ><b>House Bill 列表</b></div>
                            </div>
                            <div className="logistic_house_bill_row" style={{ color: 'grey' }}>
                                <div style={{ fontWeight: 'bold', marginRight: '20px', minWidth: '125px', maxWidth: '125px' }}>
                                    编号</div>
                                <div style={{ fontWeight: 'bold', marginRight: '20px', minWidth: '135px', maxWidth: '135px' }}>
                                    唛头</div>
                                <div style={{ fontWeight: 'bold', marginRight: '20px', minWidth: '150px', maxWidth: '150px' }}>
                                    收货人</div>
                                <div style={{ fontWeight: 'bold', marginRight: '20px', minWidth: '100px', maxWidth: '100px' }}>
                                    路径</div>
                                <div style={{ fontWeight: 'bold', marginRight: '20px', minWidth: '100px', maxWidth: '100px' }}>
                                    放货</div>
                            </div>
                            {uiHouseBillList}
                        </div>
                    );
                    if (this.props.iUserLevel < 5) {
                        // let uiStorage = (
                        //     <div className="auto_order_info_row">
                        //         <div className="order_detail_title">仓库：</div>
                        //         <Input className="order_detail_input_long" ref="storage" defaultValue={objOrder.storage} disabled
                        //             style={{ background: 'white', color: 'rgba(0, 0, 0, 0.75)' }} />
                        //     </div>
                        // );
                        uiBasicInfo2 = (
                            <div className="auto_order_info_block">
                                {/* {uiStorage} */}
                                <div className="logistic_info_area">
                                    <div className="auto_order_info_row" style={{ margin: '0px' }}>
                                        <div style={{ fontWeight: 'bold' }}>前缀：</div>
                                        {objOrder.oid_before}
                                        <div style={{ minWidth: '15px' }} />
                                        <div style={{ fontWeight: 'bold' }}>柜号：</div>
                                        {objOrder.closet}
                                        <div style={{ minWidth: '15px' }} />
                                        <div style={{ fontWeight: 'bold' }}>物流：</div>
                                        {objOrder.express}
                                        <div style={{ minWidth: '15px' }} />
                                        <div style={{ fontWeight: 'bold' }}>目的地：</div>
                                        {objOrder.destination}
                                        <div style={{ minWidth: '15px' }} />
                                        <div style={{ fontWeight: 'bold' }}>件数：</div>
                                        {objOrder.num + " PKG"}
                                        <div style={{ minWidth: '15px' }} />
                                        <div style={{ fontWeight: 'bold' }}>重量：</div>
                                        {objOrder.weight + " KG"}
                                        <div style={{ minWidth: '15px' }} />
                                        <div style={{ fontWeight: 'bold' }}>方数：</div>
                                        {objOrder.cbm + " CBM"}
                                    </div>
                                </div>
                                <div className="auto_order_info_row">
                                    <div className="order_detail_title">预计到仓：</div>
                                    <DatePicker className="order_date_pickup" onChange={this.StorageDate} disabled
                                        value={moment(this.state.etd, dateFormat2)} placeholder="请选择日期" />
                                    <div className="order_detail_title" style={{ color: 'lightcoral', fontWeight: 'bold' }}>免仓租日期</div>
                                    <Input className="order_detail_input" ref="mb_free_storage_date" defaultValue={objOrder.free_storage_date} disabled />
                                </div>
                                {/* <div className="auto_order_info_row">
                                    <div className="order_detail_title" style={{ marginTop: '2.5px', marginBottom: 'auto' }}>备注：</div>
                                    <TextArea size="large" autoSize={{ minRows: 2 }} allowClear={true} ref="comments" style={{ marginBottom: '0px' }}
                                        placeholder="" defaultValue={objOrder.comments} />
                                </div>
                                <div className="auto_order_info_row">
                                    <div className="order_detail_title" style={{ marginTop: '2.5px', marginBottom: 'auto' }}>操作指示：</div>
                                    <TextArea size="large" autoSize={{ minRows: 2 }} allowClear={true} ref="client_notes" style={{ marginBottom: '0px' }}
                                        placeholder="" defaultValue={objOrder.client_notes} />
                                </div> */}
                            </div>
                        );
                        let bFeeConfirm = true;
                        if (this.state.iStorageFeeConfirm === 0)
                            bFeeConfirm = false;
                        uiStorageFee = (
                            <div className="auto_order_info_block">
                                <div className="auto_order_info_row">
                                    <div className="order_detail_title">币种：</div>
                                    <Input className="logistic_fee_title_column" defaultValue={this.arrCurrency[objOrder.currency]}
                                        disabled style={{ background: 'white', color: 'grey' }} />
                                    <div className="order_detail_title" style={{ color: 'lightcoral' }}>费用确认</div>
                                    <Switch onChange={this.FeeConfirmSwitch.bind(this, 2)} style={{ marginRight: '160px' }}
                                        defaultChecked={bFeeConfirm} checked={bFeeConfirm} />
                                </div>
                                <div className="auto_order_info_row logistic_fee_title_row">
                                    <div className="logistic_fee_title_column">收费</div>
                                </div>
                                <div className="auto_order_info_row">
                                    <div className="order_detail_title">拆柜：</div>
                                    <Input className="logistic_fee_title_column_short" ref="open_closet_fee" defaultValue={objOrder.open_closet_fee} disabled
                                        onChange={this.StorageFeeInfoChange.bind(this, objOrder, 0)} style={{ background: 'rgba(32, 178, 171, 0.2)' }} />
                                </div>
                                <div className="auto_order_info_row">
                                    <div className="order_detail_title">分货：</div>
                                    <Input className="logistic_fee_title_column_short" ref="divide_item_fee" defaultValue={objOrder.divide_item_fee} disabled
                                        onChange={this.StorageFeeInfoChange.bind(this, objOrder, 2)} style={{ background: 'rgba(32, 178, 171, 0.2)' }} />
                                </div>
                                <div className="auto_order_info_row">
                                    <div className="order_detail_title">小计：</div>
                                    <Input className="logistic_fee_title_column_short" value={objOrder.storage_total_fee} ref="storage_total_fee"
                                        disabled style={{ color: 'darkcyan', background: 'rgba(32, 178, 171, 0.2)' }} />
                                </div>
                            </div>
                        );
                    }

                }
                // Tab3 清关
                if (this.state.iDlgTab === 3) {
                    let bDefaultRelease = false;
                    if (objOrder.clear_release === 1)
                        bDefaultRelease = true;
                    let bRelease = false;
                    if (this.state.iClearRelease === 1)
                        bRelease = true
                    uiRelease = (
                        <div className="auto_order_info_block">
                            <div className="auto_order_info_row">
                                <div className="order_detail_title" style={{ color: 'lightcoral' }}>清关放行</div>
                                <Switch onChange={this.ClearReleaseSwitch.bind(this)} style={{ marginRight: '160px' }}
                                    defaultChecked={bDefaultRelease} checked={bRelease} />
                            </div>
                        </div>
                    );
                    if (this.state.iStorageCustom === 0)
                        uiRelease = null;
                    // 清关费
                    let fCustomBenefit = parseFloat(objOrder.custom_fee) - parseFloat(objOrder.custom_cost);
                    if (typeof (fCustomBenefit) === 'number')
                        fCustomBenefit = fCustomBenefit.toFixed(2);
                    let fTaxBenefit = parseFloat(objOrder.tax_fee) - parseFloat(objOrder.tax_cost);
                    if (typeof (fTaxBenefit) === 'number')
                        fTaxBenefit = fTaxBenefit.toFixed(2);
                    let fGSTBenefit = parseFloat(objOrder.gst_fee) - parseFloat(objOrder.gst_cost);
                    if (typeof (fGSTBenefit) === 'number')
                        fGSTBenefit = fGSTBenefit.toFixed(2);
                    let fOverItemBenefit = parseFloat(objOrder.overitem_fee) - parseFloat(objOrder.overitem_cost);
                    if (typeof (fOverItemBenefit) === 'number')
                        fOverItemBenefit = fOverItemBenefit.toFixed(2);
                    let uiOverItemFee = null;
                    if (objOrder.overitem_active === 1) {
                        this.arrClearFee[0].active = 1;
                        uiOverItemFee = (
                            <div className="auto_order_info_row">
                                <div className="order_detail_title">超项：</div>
                                <Input className="logistic_fee_title_column_short" ref="overitem_cost" value={objOrder.overitem_cost}
                                    onChange={this.ClearFeeInfoChange.bind(this, objOrder, 7)} />
                                <Input className="logistic_fee_title_column_short" ref="overitem_fee" value={objOrder.overitem_fee}
                                    onChange={this.ClearFeeInfoChange.bind(this, objOrder, 6)} style={{ background: 'rgba(32, 178, 171, 0.2)' }} />
                                <Input className="logistic_fee_title_column_short" ref="overitem_benefit" value={fOverItemBenefit}
                                    disabled />
                                <Icon type="delete" className="logistic_house_bill_icon"
                                    style={{ color: 'lightcoral', marginLeft: '0px' }}
                                    onClick={this.DeleteClearFee.bind(this, 0)} />
                            </div>
                        );
                    }
                    let fCFIABenefit = parseFloat(objOrder.cfia_fee) - parseFloat(objOrder.cfia_cost);
                    if (typeof (fCFIABenefit) === 'number')
                        fCFIABenefit = fCFIABenefit.toFixed(2);
                    let uiCFIAFee = null;
                    if (objOrder.cfia_active === 1) {
                        this.arrClearFee[1].active = 1;
                        uiCFIAFee = (
                            <div className="auto_order_info_row">
                                <div className="order_detail_title">CFIA/OGD</div>
                                <Input className="logistic_fee_title_column_short" ref="cfia_cost" value={objOrder.cfia_cost}
                                    onChange={this.ClearFeeInfoChange.bind(this, objOrder, 9)} />
                                <Input className="logistic_fee_title_column_short" ref="cfia_fee" value={objOrder.cfia_fee}
                                    onChange={this.ClearFeeInfoChange.bind(this, objOrder, 8)} style={{ background: 'rgba(32, 178, 171, 0.2)' }} />
                                <Input className="logistic_fee_title_column_short" ref="cfia_benefit" value={fCFIABenefit}
                                    disabled />
                                <Icon type="delete" className="logistic_house_bill_icon"
                                    style={{ color: 'lightcoral', marginLeft: '0px' }}
                                    onClick={this.DeleteClearFee.bind(this, 1)} />
                            </div>
                        );
                    }
                    let fCheckBenefit = parseFloat(objOrder.check_fee) - parseFloat(objOrder.check_cost);
                    if (typeof (fCheckBenefit) === 'number')
                        fCheckBenefit = fCheckBenefit.toFixed(2);
                    let uiCheckFee = null;
                    if (objOrder.check_active === 1) {
                        this.arrClearFee[2].active = 1;
                        uiCheckFee = (
                            <div className="auto_order_info_row">
                                <div className="order_detail_title">查验：</div>
                                <Input className="logistic_fee_title_column_short" ref="check_cost" value={objOrder.check_cost}
                                    onChange={this.ClearFeeInfoChange.bind(this, objOrder, 11)} />
                                <Input className="logistic_fee_title_column_short" ref="check_fee" value={objOrder.check_fee}
                                    onChange={this.ClearFeeInfoChange.bind(this, objOrder, 10)} style={{ background: 'rgba(32, 178, 171, 0.2)' }} />
                                <Input className="logistic_fee_title_column_short" ref="check_benefit" value={fCheckBenefit}
                                    disabled />
                                <Icon type="delete" className="logistic_house_bill_icon"
                                    style={{ color: 'lightcoral', marginLeft: '0px' }}
                                    onClick={this.DeleteClearFee.bind(this, 2)} />
                            </div>
                        );
                    }
                    let fOtherBenefit = parseFloat(objOrder.custom_other_fee) - parseFloat(objOrder.custom_other_cost);
                    if (typeof (fOtherBenefit) === 'number')
                        fOtherBenefit = fOtherBenefit.toFixed(2);
                    let uiCustomOtherFee = null;
                    let uiCustomOtherComments = null;
                    if (objOrder.custom_other_active === 1) {
                        this.arrClearFee[3].active = 1;
                        uiCustomOtherFee = (
                            <div className="auto_order_info_row">
                                <div className="order_detail_title">其他：</div>
                                <Input className="logistic_fee_title_column_short" ref="custom_other_cost"
                                    value={objOrder.custom_other_cost}
                                    onChange={this.ClearFeeInfoChange.bind(this, objOrder, 13)} />
                                <Input className="logistic_fee_title_column_short" ref="custom_other_fee"
                                    value={objOrder.custom_other_fee}
                                    onChange={this.ClearFeeInfoChange.bind(this, objOrder, 12)} style={{ background: 'rgba(32, 178, 171, 0.2)' }} />
                                <Input className="logistic_fee_title_column_short" ref="custom_other_benefit" value={fOtherBenefit} disabled />
                                <Icon type="delete" className="logistic_house_bill_icon" style={{ color: 'lightcoral', marginLeft: '0px' }}
                                    onClick={this.DeleteClearFee.bind(this, 3)} />
                            </div>
                        );
                        uiCustomOtherComments = (
                            <div className="auto_order_info_row">
                                <div className="order_detail_title">说明：</div>
                                <Input className="order_detail_input_long" ref="custom_other_fee_comments" defaultValue={objOrder.custom_other_fee_comments}
                                    placeholder={"清关其他收费说明"} />
                            </div>
                        );
                    }
                    objOrder = this.CalculateClearFee(objOrder);
                    let uiFeeTypeMenu = null;
                    let uiFeeTypeDropdown = null;
                    let uiAddFeeButton = null;
                    if (this.iCurrentClearFee >= 0) {
                        uiFeeTypeMenu = (
                            <Menu>
                                {this.arrClearFee.map((item, index) => {
                                    if (item.active === 0)
                                        return (
                                            <Menu.Item key={index} onClick={this.ClearFeeSwitch.bind(this, index)}>
                                                {item.name}
                                            </Menu.Item>
                                        );
                                    else
                                        return null
                                })}
                            </Menu>
                        );
                        uiFeeTypeDropdown = (
                            <Dropdown className="order_detail_dropdown" trigger={['click']} overlay={uiFeeTypeMenu} placement="bottomLeft"
                                style={{ marginLeft: '0px', marginRight: '0px' }}>
                                <Button>{this.arrClearFee[this.iCurrentClearFee].name}<Icon type="caret-down" /></Button>
                            </Dropdown>
                        );
                        uiAddFeeButton = (
                            <Button className="manage_contents_title_margin" type="primary"
                                onClick={this.AddClearFee.bind(this, this.iCurrentClearFee)}
                                style={{ color: 'white', marginLeft: '5px', marginRight: '5px' }}>
                                <Icon type="plus" />收费</Button>
                        );
                    }
                    let bFeeConfirm = true;
                    if (this.state.iClearFeeConfirm === 0)
                        bFeeConfirm = false;
                    uiCustomFee = (
                        <div className="auto_order_info_block">
                            <div className="auto_order_info_row">
                                <div className="order_detail_title">币种：</div>
                                <Input className="logistic_fee_title_column" defaultValue={this.arrCurrency[objOrder.currency]}
                                    disabled style={{ background: 'white', color: 'grey' }} />
                                <div className="order_detail_title" style={{ color: 'darkcyan' }}>费用确认</div>
                                <Switch onChange={this.FeeConfirmSwitch.bind(this, 3)} style={{ marginRight: '60px' }}
                                    defaultChecked={bFeeConfirm} checked={bFeeConfirm} />
                                {uiFeeTypeDropdown}
                                {uiAddFeeButton}
                            </div>
                            <div className="auto_order_info_row logistic_fee_title_row">
                                <div className="logistic_fee_title_column_short">成本</div>
                                <div className="logistic_fee_title_column_short">收费</div>
                                <div className="logistic_fee_title_column_short">利润</div>
                            </div>
                            <div className="auto_order_info_row">
                                <div className="order_detail_title">报关：</div>
                                <Input className="logistic_fee_title_column_short" ref="custom_cost" value={objOrder.custom_cost}
                                    onChange={this.ClearFeeInfoChange.bind(this, objOrder, 1)} />
                                <Input className="logistic_fee_title_column_short" ref="custom_fee" value={objOrder.custom_fee}
                                    onChange={this.ClearFeeInfoChange.bind(this, objOrder, 0)} style={{ background: 'rgba(32, 178, 171, 0.2)' }} />
                                <Input className="logistic_fee_title_column_short" ref="custom_benefit" value={fCustomBenefit}
                                    disabled />
                            </div>
                            <div className="auto_order_info_row">
                                <div className="order_detail_title">关税：</div>
                                <Input className="logistic_fee_title_column_short" ref="tax_cost" value={objOrder.tax_cost}
                                    onChange={this.ClearFeeInfoChange.bind(this, objOrder, 3)} />
                                <Input className="logistic_fee_title_column_short" ref="tax_fee" value={objOrder.tax_fee}
                                    onChange={this.ClearFeeInfoChange.bind(this, objOrder, 2)} style={{ background: 'rgba(32, 178, 171, 0.2)' }} />
                                <Input className="logistic_fee_title_column_short" ref="tax_benefit" value={fTaxBenefit}
                                    disabled />
                            </div>
                            <div className="auto_order_info_row">
                                <div className="order_detail_title">GST：</div>
                                <Input className="logistic_fee_title_column_short" ref="gst_cost" value={objOrder.gst_cost}
                                    onChange={this.ClearFeeInfoChange.bind(this, objOrder, 5)} />
                                <Input className="logistic_fee_title_column_short" ref="gst_fee" value={objOrder.gst_fee}
                                    onChange={this.ClearFeeInfoChange.bind(this, objOrder, 4)} style={{ background: 'rgba(32, 178, 171, 0.2)' }} />
                                <Input className="logistic_fee_title_column_short" ref="gst_benefit" value={fGSTBenefit}
                                    disabled />
                            </div>
                            {uiOverItemFee}
                            {uiCFIAFee}
                            {uiCheckFee}
                            {uiCustomOtherFee}
                            {uiCustomOtherComments}
                            <div className="auto_order_info_row">
                                <div className="order_detail_title">小计：</div>
                                <Input className="logistic_fee_title_column_short" value={objOrder.custom_total_cost}
                                    ref="custom_total_cost" disabled style={{ color: 'darkcyan' }} />
                                <Input className="logistic_fee_title_column_short" value={objOrder.custom_total_fee} ref="custom_total_fee"
                                    disabled style={{ color: 'gray', background: 'rgba(32, 178, 171, 0.2)' }} />
                                <Input className="logistic_fee_title_column_short" value={objOrder.custom_total_benefit}
                                    ref="custom_total_benefit" disabled style={{ color: 'darkcyan' }} />
                            </div>
                        </div>
                    );
                    let uiHouseBillList = null;
                    if (this.arrHouseBill.length > 0) {
                        uiHouseBillList = (
                            <div className="storage_sku_list_area">
                                {this.arrHouseBill.map((house_bill, index) => {
                                    let uiClearCheck = <div style={{ fontWeight: 'bold', marginRight: '20px', minWidth: '50px', maxWidth: '50px' }} />
                                    if (house_bill.clear_release === 1)
                                        uiClearCheck = (
                                            <Icon type="check" style={{
                                                fontWeight: 'bold', marginRight: '20px', minWidth: '50px', maxWidth: '50px',
                                                color: 'darkcyan'
                                            }} />
                                        );
                                    if (objOrder.storage_custom >= 1)
                                        uiClearCheck = null;
                                    return (
                                        <div className="logistic_house_bill_row" key={index} >
                                            <div style={{ fontWeight: 'bold', marginRight: '20px', minWidth: '125px', maxWidth: '125px' }}>
                                                {house_bill.oid + house_bill.house_bill_no}</div>
                                            <div style={{ fontWeight: 'bold', marginRight: '20px', minWidth: '135px', maxWidth: '135px' }}>
                                                {house_bill.mark}</div>
                                            <div style={{ fontWeight: 'bold', marginRight: '20px', minWidth: '150px', maxWidth: '150px' }}>
                                                {house_bill.receiver_name}</div>
                                            {uiClearCheck}
                                            <div style={{ fontWeight: 'bold', marginRight: 'auto', minWidth: '100px', maxWidth: '100px' }}>
                                                {this.arrPath[house_bill.path]}</div>
                                            <Icon type="edit" className="logistic_house_bill_icon" style={{ marginLeft: 'auto' }} onClick={this.CreateEditHouseBill.bind(this, 1, index)} />
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    }
                    let uiClearTitle = (<div style={{ fontWeight: 'bold', marginRight: '20px', minWidth: '50px', maxWidth: '50px' }}>
                        清关</div>);
                    if (objOrder.storage_custom >= 1)
                        uiClearTitle = null;
                    uiHouseBill3 = (
                        <div className="auto_order_info_block">
                            <div className="auto_order_info_row logistic_order_row_title">
                                <div ><b>House Bill 列表</b></div>
                            </div>
                            <div className="logistic_house_bill_row" style={{ color: 'grey' }}>
                                <div style={{ fontWeight: 'bold', marginRight: '20px', minWidth: '125px', maxWidth: '125px' }}>
                                    编号</div>
                                <div style={{ fontWeight: 'bold', marginRight: '20px', minWidth: '135px', maxWidth: '135px' }}>
                                    唛头</div>
                                <div style={{ fontWeight: 'bold', marginRight: '20px', minWidth: '150px', maxWidth: '150px' }}>
                                    收货人</div>
                                {uiClearTitle}
                                <div style={{ fontWeight: 'bold', marginRight: '20px', minWidth: '100px', maxWidth: '100px' }}>
                                    路径</div>

                            </div>
                            {uiHouseBillList}
                        </div>
                    );

                    if (this.props.iUserLevel < 5) {
                        let bFeeConfirm = true;
                        if (this.state.iClearFeeConfirm === 0)
                            bFeeConfirm = false;
                        uiCustomFee = (
                            <div className="auto_order_info_block">
                                <div className="auto_order_info_row">
                                    <div className="order_detail_title">币种：</div>
                                    <Input className="logistic_fee_title_column" defaultValue={this.arrCurrency[objOrder.currency]}
                                        disabled style={{ background: 'white', color: 'grey' }} />
                                    <div className="order_detail_title" style={{ color: 'lightcoral' }}>费用确认</div>
                                    <Switch onChange={this.FeeConfirmSwitch.bind(this, 3)} style={{ marginRight: '160px' }}
                                        defaultChecked={bFeeConfirm} checked={bFeeConfirm} />
                                </div>
                                <div className="auto_order_info_row logistic_fee_title_row">
                                    <div className="logistic_fee_title_column">收费</div>
                                </div>
                                <div className="auto_order_info_row">
                                    <div className="order_detail_title">报关：</div>
                                    <Input className="logistic_fee_title_column_short" ref="custom_fee" value={objOrder.custom_fee} disabled
                                        onChange={this.ClearFeeInfoChange.bind(this, objOrder, 0)} style={{ background: 'rgba(32, 178, 171, 0.2)' }} />
                                </div>
                                <div className="auto_order_info_row">
                                    <div className="order_detail_title">关税：</div>
                                    <Input className="logistic_fee_title_column_short" ref="tax_fee" value={objOrder.tax_fee} disabled
                                        onChange={this.ClearFeeInfoChange.bind(this, objOrder, 2)} style={{ background: 'rgba(32, 178, 171, 0.2)' }} />
                                </div>
                                <div className="auto_order_info_row">
                                    <div className="order_detail_title">GST：</div>
                                    <Input className="logistic_fee_title_column_short" ref="gst_fee" value={objOrder.gst_fee} disabled
                                        onChange={this.ClearFeeInfoChange.bind(this, objOrder, 4)} style={{ background: 'rgba(32, 178, 171, 0.2)' }} />
                                </div>
                                {uiOverItemFee}
                                {uiCFIAFee}
                                {uiCheckFee}
                                {uiCustomOtherFee}
                                {uiCustomOtherComments}
                                <div className="auto_order_info_row">
                                    <div className="order_detail_title">小计：</div>
                                    <Input className="logistic_fee_title_column_short" value={objOrder.custom_total_fee} ref="custom_total_fee"
                                        disabled style={{ color: 'gray', background: 'rgba(32, 178, 171, 0.2)' }} />
                                </div>
                            </div>
                        );

                    }
                }
                // Tab4 and Tab5 HB Area
                if (this.state.iDlgTab >= 4 && this.state.iDlgTab <= 6) {
                    let uiHouseBillList = null;
                    if (this.arrHouseBill.length > 0) {
                        let uiTitle = (
                            <div className="title_row" >
                                {"放货信息"}
                                <Button className="manage_contents_title_margin" type="primary"
                                    style={{ marginLeft: "auto", marginRight: '20px' }}
                                    onClick={this.CopyPasteAction.bind(this)} >
                                    复制全部</Button>
                            </div>

                        );
                        uiReleaseDlg = (
                            <Modal width={900} title={uiTitle} visible={this.state.bReleaseDlg} closable={true}
                                maskClosable={true} footer={null} onCancel={this.CloseReleaseDlg.bind(this)} >
                                <TextArea size="large" autoSize={{ minRows: 10 }} allowClear={false} ref="client_notes"
                                    style={{ marginBottom: '0px' }} placeholder=""
                                    value={this.strReleaseText} />
                            </Modal >
                        );
                        uiHouseBillList = (
                            <div className="storage_sku_list_area">
                                {this.arrHouseBill.map((house_bill, index) => {
                                    if (house_bill.path === (this.state.iDlgTab - 4)) {
                                        // let uiPODCheck = (<div style={{ fontWeight: 'bold', marginRight: '20px', minWidth: '50px', maxWidth: '50px' }} />);
                                        // if (house_bill.pod === 1)
                                        //     uiPODCheck = (
                                        //         <Icon type="check" style={{
                                        //             fontWeight: 'bold', marginRight: '20px', minWidth: '50px', maxWidth: '50px', color: 'darkcyan'
                                        //         }} />
                                        //     );
                                        // let uiReleaseCheck = (<div style={{ fontWeight: 'bold', marginRight: '20px', minWidth: '50px', maxWidth: '50px' }} />);
                                        // if (house_bill.lk_release === 1 && house_bill.client_release === 1)
                                        //     uiReleaseCheck = (
                                        //         <Icon type="check" style={{
                                        //             fontWeight: 'bold', marginRight: '20px', minWidth: '50px', maxWidth: '50px', color: 'darkcyan'
                                        //         }} />
                                        //     );
                                        let uiEditButton = (
                                            <Icon type="edit" className="logistic_house_bill_icon" style={{ marginLeft: 'auto' }} onClick={this.CreateEditHouseBill.bind(this, 1, index)} />
                                        );
                                        if (this.props.iUserLevel < 5) {
                                            uiEditButton = (
                                                <Icon type="search" className="logistic_house_bill_icon" style={{ marginLeft: 'auto' }} onClick={this.CreateEditHouseBill.bind(this, 1, index)} />
                                            );
                                        }
                                        // 放货按钮
                                        let uiReleaseButton = (
                                            <Icon type="dropbox" className="logistic_house_bill_icon"
                                                style={{
                                                    fontWeight: 'bold', marginLeft: '0px', marginRight: '0px',
                                                    minWidth: '40px', maxWidth: '40px'
                                                }} onClick={this.OpenReleaseText.bind(this, index)}
                                            />
                                        );
                                        return (
                                            <div className="logistic_house_bill_row" key={index} >
                                                <div style={{ fontWeight: 'bold', marginRight: '20px', minWidth: '125px', maxWidth: '125px' }}>
                                                    {house_bill.oid + house_bill.house_bill_no}</div>
                                                <div style={{ fontWeight: 'bold', marginRight: '20px', minWidth: '135px', maxWidth: '135px' }}>
                                                    {house_bill.mark}</div>
                                                <div style={{ fontWeight: 'bold', marginRight: '20px', minWidth: '135px', maxWidth: '135px' }}>
                                                    {house_bill.receiver_name}</div>
                                                <div style={{ fontWeight: 'bold', marginRight: '20px', minWidth: '120px', maxWidth: '120px' }}>
                                                    {house_bill.pickup_date}</div>
                                                {uiReleaseButton}
                                                {uiEditButton}
                                            </div>
                                        );
                                    } else
                                        return null;
                                })}
                            </div>
                        );
                    }
                    let strPickupDate = "提货日期";
                    if (this.state.iDlgTab === 6)
                        strPickupDate = "放货日期";
                    uiHouseBill45 = (
                        <div className="auto_order_info_block">
                            <div className="auto_order_info_row logistic_order_row_title">
                                <div ><b>House Bill 列表</b></div>
                            </div>
                            <div className="logistic_house_bill_row" style={{ color: 'grey' }}>
                                <div style={{ fontWeight: 'bold', marginRight: '20px', minWidth: '125px', maxWidth: '125px' }}>
                                    编号</div>
                                <div style={{ fontWeight: 'bold', marginRight: '20px', minWidth: '135px', maxWidth: '135px' }}>
                                    唛头</div>
                                <div style={{ fontWeight: 'bold', marginRight: '20px', minWidth: '150px', maxWidth: '150px' }}>
                                    收货人</div>

                                <div style={{ fontWeight: 'bold', marginRight: '20px', minWidth: '120px', maxWidth: '120px' }}>
                                    {strPickupDate}</div>
                                <div style={{ fontWeight: 'bold', marginRight: '20px', minWidth: '50px', maxWidth: '50px' }}>
                                    放货</div>
                            </div>
                            {uiHouseBillList}
                        </div>
                    );
                    // let bFeeConfirm = true;
                    // if (this.state.iDlgTab === 4 && this.state.iTransitFeeConfirm === 0)
                    //     bFeeConfirm = false;
                    // if (this.state.iDlgTab === 5 && this.state.iDeliverFeeConfirm === 0)
                    //     bFeeConfirm = false;
                    // if (this.state.iDlgTab === 6 && this.state.iPickupFeeConfirm === 0)
                    //     bFeeConfirm = false;
                    // let strColor = 'darkcyan';
                    // if (this.props.iUserLevel < 5) {
                    //     strColor = 'lightcoral';
                    // }
                    // uiTransitFee = (
                    //     <div className="auto_order_info_block">
                    //         <div className="auto_order_info_row">
                    //             <div className="order_detail_title">币种：</div>
                    //             <Input className="logistic_fee_title_column" defaultValue={this.arrCurrency[objOrder.currency]}
                    //                 disabled style={{ background: 'white', color: 'grey' }} />
                    //             <div className="order_detail_title" style={{ color: strColor }}>费用确认</div>
                    //             <Switch onChange={this.FeeConfirmSwitch.bind(this, this.state.iDlgTab)} style={{ marginRight: '15px' }}
                    //                 defaultChecked={bFeeConfirm} checked={bFeeConfirm} />
                    //             <div style={{ color: 'darkcyan', minWidth: '240px' }}>* 请确认House Bill中的费用后，在点击费用确认 *</div>
                    //         </div>
                    //     </div>
                    // );
                }
            }
            let uiStorageCustomTab = (
                <div className="logistic_row">
                    <div className={arrStatus[2]} onClick={this.SwitchDlgTab.bind(this, 2)}>到仓</div>
                    {/* <div className={arrStatus[3]} onClick={this.SwitchDlgTab.bind(this, 3)}>清关</div> */}
                </div>
            );
            if (this.state.iStorageCustom >= 1) {
                uiStorageCustomTab = (
                    <div className="logistic_row">
                        {/* <div className={arrStatus[3]} onClick={this.SwitchDlgTab.bind(this, 3)}>清关</div> */}
                        <div className={arrStatus[2]} onClick={this.SwitchDlgTab.bind(this, 2)}>到仓</div>
                    </div>
                );
            } else {
                uiCustomFee = null;
                if (this.state.iDlgTab === 3) {
                    let bFeeConfirm = true;
                    if (this.state.iClearFeeConfirm === 0)
                        bFeeConfirm = false;
                    let strColor = 'darkcyan';
                    if (this.props.iUserLevel < 5)
                        strColor = 'lightcoral';
                    uiCustomFee = (
                        <div className="auto_order_info_block">
                            <div className="auto_order_info_row">
                                <div className="order_detail_title">币种：</div>
                                <Input className="logistic_fee_title_column" defaultValue={this.arrCurrency[objOrder.currency]}
                                    disabled style={{ background: 'white', color: 'grey' }} />
                                <div className="order_detail_title" style={{ color: strColor }}>费用确认</div>
                                <Switch onChange={this.FeeConfirmSwitch.bind(this, 3)} style={{ marginRight: '15px' }}
                                    defaultChecked={bFeeConfirm} checked={bFeeConfirm} />
                                <div style={{ color: 'darkcyan', minWidth: '240px' }}>* 请确认House Bill中的费用后，在点击费用确认 *</div>
                            </div>
                        </div>
                    );
                }
            }
            let uiShipTab = (<div className={arrStatus[0]} onClick={this.SwitchDlgTab.bind(this, 0)}>发船</div>);
            let uiPortTab = (<div className={arrStatus[1]} onClick={this.SwitchDlgTab.bind(this, 1)}>到港</div>);
            let uiTransitTab = (<div className={arrStatus[4]} onClick={this.SwitchDlgTab.bind(this, 4)}>换单</div>);
            let uiDeliveryTab = (<div className={arrStatus[5]} onClick={this.SwitchDlgTab.bind(this, 5)}>海派</div>);
            let uiPickupTab = (<div className={arrStatus[6]} onClick={this.SwitchDlgTab.bind(this, 6)}>自提</div>);
            // 海派专用
            let uiStorageInfo = null;
            let uiHouseBill5 = null;
            if (this.props.iType === 5) {
                uiBasicInfo456 = null;
                uiShipTab = null;
                uiPortTab = null;
                uiStorageCustomTab = null;
                // uiTransitTab = null;
                uiPickupTab = null;
                uiDeliveryTab = (<div className={"logistics_dlg_main_button"} onClick={this.SwitchDlgTab.bind(this, 5)}>海派</div>);

                // 转过来的运单才会显示仓库信息
                if (this.props.iType === 5) {
                    uiStorageInfo = (
                        <div className="auto_order_info_block">
                            <div className="auto_order_info_row">
                                <div className="order_detail_title">仓库：</div>
                                <Input className="order_detail_input_long" ref="storage" defaultValue={objOrder.storage} disabled
                                    style={{ background: 'white', color: 'rgba(0, 0, 0, 0.75)' }} />
                            </div>
                        </div>
                    );
                }
                let uiHouseBillList = null;
                if (this.arrHouseBill.length > 0) {
                    uiHouseBillList = (
                        <div className="storage_sku_list_area">
                            {this.arrHouseBill.map((house_bill, index) => {
                                // 清关标志
                                // let uiClearCheck = <div style={{ fontWeight: 'bold', marginRight: '20px', minWidth: '50px', maxWidth: '50px' }} />
                                // if (house_bill.clear_release === 1)
                                //     uiClearCheck = (
                                //         <Icon type="check" style={{
                                //             fontWeight: 'bold', marginRight: '20px', minWidth: '50px', maxWidth: '50px',
                                //             color: 'darkcyan'
                                //         }} />
                                //     );
                                // if (objOrder.storage_custom >= 1)
                                //     uiClearCheck = null;
                                // 放货标志
                                // let uiReleaseCheck = (<div style={{ fontWeight: 'bold', marginRight: '20px', minWidth: '50px', maxWidth: '50px' }} />);
                                // if (house_bill.lk_release === 1 && house_bill.client_release === 1)
                                //     uiReleaseCheck = (
                                //         <Icon type="check" style={{
                                //             fontWeight: 'bold', marginRight: '20px', minWidth: '50px', maxWidth: '50px', color: 'darkcyan'
                                //         }} />
                                //     );
                                // POD标志
                                // let uiPODCheck = (<div style={{ fontWeight: 'bold', marginRight: '20px', minWidth: '50px', maxWidth: '50px' }} />);
                                // if (house_bill.pod === 1)
                                //     uiPODCheck = (
                                //         <Icon type="check" style={{
                                //             fontWeight: 'bold', marginRight: '20px', minWidth: '50px', maxWidth: '50px', color: 'darkcyan'
                                //         }} />
                                //     );
                                if (house_bill.path <= 1)
                                    return (
                                        <div className="logistic_house_bill_row" key={index} >
                                            <div style={{ fontWeight: 'bold', marginRight: '20px', minWidth: '125px', maxWidth: '125px' }}>
                                                {house_bill.oid + house_bill.house_bill_no}</div>
                                            <div style={{ fontWeight: 'bold', marginRight: '20px', minWidth: '135px', maxWidth: '135px' }}>
                                                {house_bill.mark}</div>
                                            <div style={{ fontWeight: 'bold', marginRight: '20px', minWidth: '135px', maxWidth: '135px' }}>
                                                {house_bill.receiver_name}</div>
                                            {/* {uiClearCheck} */}
                                            {/* {uiReleaseCheck} */}
                                            <div style={{ fontWeight: 'bold', marginRight: '20px', minWidth: '100px', maxWidth: '100px' }}>
                                                {house_bill.pickup_date}</div>
                                            {/* {uiPODCheck} */}
                                            <Icon type="edit" className="logistic_house_bill_icon" style={{ marginLeft: 'auto' }}
                                                onClick={this.CreateEditHouseBill.bind(this, 1, index)} />
                                        </div>
                                    );
                                else
                                    return null;
                            })}
                        </div>
                    );
                }
                uiHouseBill0 = null;
                uiHouseBill2 = null;
                uiHouseBill3 = null;
                uiHouseBill45 = null;
                uiPortInfo = null;
                uiBasicInfo0 = null;
                uiBasicInfo1 = null;
                uiBasicInfo2 = null;
                uiBasicInfo3 = null;
                let uiClearTitle = (
                    <div style={{ fontWeight: 'bold', marginRight: '20px', minWidth: '50px', maxWidth: '50px' }}>
                        清关</div>
                );
                if (objOrder.storage_custom >= 1)
                    uiClearTitle = null;
                uiHouseBill5 = (
                    <div className="auto_order_info_block">
                        <div className="auto_order_info_row logistic_order_row_title">
                            <div ><b>House Bill 列表 5</b></div>
                        </div>
                        <div className="logistic_house_bill_row" style={{ color: 'grey' }}>
                            <div style={{ fontWeight: 'bold', marginRight: '20px', minWidth: '125px', maxWidth: '125px' }}>
                                编号</div>
                            <div style={{ fontWeight: 'bold', marginRight: '20px', minWidth: '135px', maxWidth: '135px' }}>
                                唛头</div>
                            <div style={{ fontWeight: 'bold', marginRight: '20px', minWidth: '150px', maxWidth: '150px' }}>
                                收货人</div>
                            {uiClearTitle}
                            {/* <div style={{ fontWeight: 'bold', marginRight: '20px', minWidth: '50px', maxWidth: '50px' }}>
                                放货</div> */}
                            <div style={{ fontWeight: 'bold', marginRight: '20px', minWidth: '100px', maxWidth: '100px' }}>
                                提货日期</div>
                            {/* <div style={{ fontWeight: 'bold', marginRight: '20px', minWidth: '50px', maxWidth: '50px' }}>
                                POD</div> */}
                        </div>
                        {uiHouseBillList}
                    </div>
                );
                if (this.state.iDlgType === 0) {
                    uiHouseBill5 = null;
                }
            }
            // 运单顶部Tabs
            let uiDlgTabs = (
                <div className="auto_order_info_row">
                    {uiShipTab}
                    {uiPortTab}
                    {uiStorageCustomTab}
                    {uiTransitTab}
                    {uiDeliveryTab}
                    {uiPickupTab}
                </div>
            );
            let uiFeeDetails = null;
            // 收费Tab
            if (this.state.iDlgTab === 7) {
                // uiTransitFee = null;
                // uiPickupFee = null;
                uiCustomFee = null;
                uiBasicInfo456 = null;
                uiHouseBill5 = null;
                uiStorageInfo = null;
                let uiCustomReport = null;
                let uiTax = null;
                let uiGST = null;
                if (objOrder.storage_custom >= 1) {
                    uiCustomReport = (
                        <div className="auto_order_info_row">
                            <div className="order_detail_title">报关：</div>
                            <Input className="logistic_fee_title_column_tiny" ref="custom_cost" value={objOrder.custom_cost}
                                disabled style={{ background: 'white', color: 'gray' }} />
                            <Input className="logistic_fee_title_column_tiny" ref="custom_fee" value={objOrder.custom_fee}
                                disabled style={{ background: 'rgba(32, 178, 171, 0.2)', color: 'gray' }} />
                            <Input className="logistic_fee_title_column_tiny" ref="custom_benefit" value={objOrder.custom_benefit} disabled
                                style={{ background: 'white', color: 'gray' }} />
                        </div>
                    );
                    uiTax = (
                        <div className="auto_order_info_row">
                            <div className="order_detail_title">GST：</div>
                            <Input className="logistic_fee_title_column_tiny" ref="gst_cost" value={objOrder.gst_cost}
                                disabled style={{ background: 'white', color: 'gray' }} />
                            <Input className="logistic_fee_title_column_tiny" ref="gst_fee" value={objOrder.gst_fee} disabled
                                style={{ background: 'rgba(32, 178, 171, 0.2)', color: 'gray' }} />
                            <Input className="logistic_fee_title_column_tiny" ref="gst_benefit" value={objOrder.gst_benefit} disabled
                                style={{ background: 'white', color: 'gray' }} />
                        </div>
                    );
                    uiGST = (
                        <div className="auto_order_info_row">
                            <div className="order_detail_title">关税：</div>
                            <Input className="logistic_fee_title_column_tiny" ref="tax_cost" value={objOrder.tax_cost}
                                disabled style={{ background: 'white', color: 'gray' }} />
                            <Input className="logistic_fee_title_column_tiny" ref="tax_fee" value={objOrder.tax_fee} disabled
                                style={{ background: 'rgba(32, 178, 171, 0.2)', color: 'gray' }} />
                            <Input className="logistic_fee_title_column_tiny" ref="tax_benefit" value={objOrder.tax_benefit} disabled
                                style={{ background: 'white', color: 'gray' }} />
                        </div>
                    );
                }
                let uiOverItem = null;
                if (objOrder.overitem_active === 1) {
                    uiOverItem = (
                        <div className="auto_order_info_row">
                            <div className="order_detail_title">超项：</div>
                            <Input className="logistic_fee_title_column_tiny" ref="overitem_cost" value={objOrder.overitem_cost}
                                disabled style={{ background: 'white', color: 'gray' }} />
                            <Input className="logistic_fee_title_column_tiny" ref="overitem_fee" value={objOrder.overitem_fee} disabled
                                style={{ background: 'rgba(32, 178, 171, 0.2)', color: 'gray' }} />
                            <Input className="logistic_fee_title_column_tiny" ref="overitem_benefit" value={objOrder.overitem_benefit} disabled
                                style={{ background: 'white', color: 'gray' }} />
                        </div>
                    );
                }
                let uiCFIA = null;
                if (objOrder.cfia_active === 1) {
                    uiCFIA = (
                        <div className="auto_order_info_row">
                            <div className="order_detail_title">CFIA：</div>
                            <Input className="logistic_fee_title_column_tiny" ref="cfia_cost" value={objOrder.cfia_cost}
                                disabled style={{ background: 'white', color: 'gray' }} />
                            <Input className="logistic_fee_title_column_tiny" ref="cfia_fee" value={objOrder.cfia_fee} disabled
                                style={{ background: 'rgba(32, 178, 171, 0.2)', color: 'gray' }} />
                            <Input className="logistic_fee_title_column_tiny" ref="cfia_benefit" value={objOrder.cfia_benefit} disabled
                                style={{ background: 'white', color: 'gray' }} />
                        </div>
                    );
                }
                let uiCheck = null;
                if (objOrder.check_active === 1) {
                    uiCheck = (
                        <div className="auto_order_info_row">
                            <div className="order_detail_title">查验：</div>
                            <Input className="logistic_fee_title_column_tiny" ref="check_cost" value={objOrder.check_cost}
                                disabled style={{ background: 'white', color: 'gray' }} />
                            <Input className="logistic_fee_title_column_tiny" ref="check_fee" value={objOrder.check_fee} disabled
                                style={{ background: 'rgba(32, 178, 171, 0.2)', color: 'gray' }} />
                            <Input className="logistic_fee_title_column_tiny" ref="check_benefit" value={objOrder.check_benefit} disabled
                                style={{ background: 'white', color: 'gray' }} />
                        </div>
                    );
                }
                let uiCustomOther = null;
                if (objOrder.custom_other_active === 1) {
                    uiCustomOther = (
                        <div className="auto_order_info_row">
                            <div className="order_detail_title">其他：</div>
                            <Input className="logistic_fee_title_column_tiny" ref="custom_other_cost" value={objOrder.custom_other_cost}
                                disabled style={{ background: 'white', color: 'gray' }} />
                            <Input className="logistic_fee_title_column_tiny" ref="custom_other_fee" value={objOrder.custom_other_fee} disabled
                                style={{ background: 'rgba(32, 178, 171, 0.2)', color: 'gray' }} />
                            <Input className="logistic_fee_title_column_tiny" ref="custom_other_benefit" value={objOrder.custom_other_benefit} disabled
                                style={{ background: 'white', color: 'gray' }} />
                        </div>
                    );
                }
                uiFeeDetails = (
                    <div className="auto_order_info_block">
                        <div className="auto_order_info_row">
                            <div style={{ color: 'lightcoral', weight: 'bold' }}>{"币种：" + this.arrCurrency[this.state.iCurrency]}</div>
                        </div>
                        <Collapse style={{ width: '100%' }} defaultActiveKey={['0']} onChange={this.OpenFeeDetail.bind(this, 0)}>
                            <Panel header="发船" key="1">
                                <div className="auto_order_info_block">
                                    <div className="auto_order_info_row logistic_fee_title_row">
                                        <div className="logistic_fee_title_column_tiny">成本</div>
                                        <div className="logistic_fee_title_column_tiny">收费</div>
                                        <div className="logistic_fee_title_column_tiny">利润</div>
                                    </div>
                                    <div className="auto_order_info_row">
                                        <div className="order_detail_title">代理：</div>
                                        <Input className="logistic_fee_title_column_tiny" ref="agent_cost" value={objOrder.agent_cost}
                                            disabled style={{ background: 'white', color: 'gray' }} />
                                        <Input className="logistic_fee_title_column_tiny" ref="agent_fee" value={objOrder.agent_fee}
                                            disabled style={{ background: 'rgba(32, 178, 171, 0.2)', color: 'gray' }} />
                                        <Input className="logistic_fee_title_column_tiny" ref="agent_benefit" value={objOrder.agent_benefit} disabled
                                            style={{ background: 'white', color: 'gray' }} />
                                    </div>
                                    <div className="auto_order_info_row">
                                        <div className="order_detail_title">船东：</div>
                                        <Input className="logistic_fee_title_column_tiny" ref="ship_cost" value={objOrder.ship_cost}
                                            disabled style={{ background: 'white', color: 'gray' }} />
                                        <Input className="logistic_fee_title_column_tiny" ref="ship_fee" value={objOrder.ship_fee} disabled
                                            style={{ background: 'rgba(32, 178, 171, 0.2)', color: 'gray' }} />
                                        <Input className="logistic_fee_title_column_tiny" ref="ship_benefit" value={objOrder.ship_benefit} disabled
                                            style={{ background: 'white', color: 'gray' }} />
                                    </div>
                                    <div className="auto_order_info_row">
                                        <div className="order_detail_title">港口：</div>
                                        <Input className="logistic_fee_title_column_tiny" ref="port_cost" value={objOrder.port_cost}
                                            disabled style={{ background: 'white', color: 'gray' }} />
                                        <Input className="logistic_fee_title_column_tiny" ref="port_fee" value={objOrder.port_fee} disabled
                                            style={{ background: 'rgba(32, 178, 171, 0.2)', color: 'gray' }} />
                                        <Input className="logistic_fee_title_column_tiny" ref="port_benefit" value={objOrder.port_benefit} disabled
                                            style={{ background: 'white', color: 'gray' }} />
                                    </div>
                                    <div className="auto_order_info_row">
                                        <div className="order_detail_title">其他：</div>
                                        <Input className="logistic_fee_title_column_tiny" ref="ship_other_cost" value={objOrder.ship_other_cost}
                                            disabled style={{ background: 'white', color: 'gray' }} />
                                        <Input className="logistic_fee_title_column_tiny" ref="ship_other_fee" value={objOrder.ship_other_fee} disabled
                                            style={{ background: 'rgba(32, 178, 171, 0.2)', color: 'gray' }} />
                                        <Input className="logistic_fee_title_column_tiny" ref="ship_other_benefit" value={objOrder.ship_other_benefit} disabled
                                            style={{ background: 'white', color: 'gray' }} />
                                    </div>
                                    <div className="auto_order_info_row">
                                        <div className="order_detail_title">小计：</div>
                                        <Input className="logistic_fee_title_column_tiny" value={objOrder.ship_total_cost}
                                            ref="ship_total_cost" disabled style={{ color: 'darkcyan', background: 'white', weight: 'bold' }} />
                                        <Input className="logistic_fee_title_column_tiny" value={objOrder.ship_total_fee} ref="ship_total_fee"
                                            disabled style={{ color: 'darkcyan', background: 'rgba(32, 178, 171, 0.2)', weight: 'bold' }} />
                                        <Input className="logistic_fee_title_column_tiny" value={objOrder.ship_total_benefit}
                                            ref="ship_total_benefit" disabled style={{ color: 'darkcyan', background: 'white', weight: 'bold' }} />
                                    </div>
                                </div>
                            </Panel>
                            <Panel header="到港" key="2">
                                <div className="auto_order_info_block">
                                    <div className="auto_order_info_row logistic_fee_title_row">
                                        <div className="logistic_fee_title_column_tiny">成本</div>
                                        <div className="logistic_fee_title_column_tiny">收费</div>
                                        <div className="logistic_fee_title_column_tiny">利润</div>
                                    </div>
                                    <div className="auto_order_info_row">
                                        <div className="order_detail_title">拖柜：</div>
                                        <Input className="logistic_fee_title_column_tiny" ref="truck_cost" value={objOrder.truck_cost}
                                            disabled style={{ background: 'white', color: 'gray' }} />
                                        <Input className="logistic_fee_title_column_tiny" ref="truck_fee" value={objOrder.truck_fee}
                                            disabled style={{ background: 'rgba(32, 178, 171, 0.2)', color: 'gray' }} />
                                        <Input className="logistic_fee_title_column_tiny" ref="truck_benefit" value={objOrder.truck_benefit} disabled
                                            style={{ background: 'white', color: 'gray' }} />
                                    </div>
                                    <div className="auto_order_info_row">
                                        <div className="order_detail_title">预提柜：</div>
                                        <Input className="logistic_fee_title_column_tiny" ref="prepick_fee" value={objOrder.prepick_cost}
                                            disabled style={{ background: 'white', color: 'gray' }} />
                                        <Input className="logistic_fee_title_column_tiny" ref="prepick_cost" value={objOrder.prepick_fee} disabled
                                            style={{ background: 'rgba(32, 178, 171, 0.2)', color: 'gray' }} />
                                        <Input className="logistic_fee_title_column_tiny" ref="prepick_benefit" value={objOrder.prepick_benefit} disabled
                                            style={{ background: 'white', color: 'gray' }} />
                                    </div>
                                    <div className="auto_order_info_row">
                                        <div className="order_detail_title">堆场：</div>
                                        <Input className="logistic_fee_title_column_tiny" ref="stack_cost" value={objOrder.stack_cost}
                                            disabled style={{ background: 'white', color: 'gray' }} />
                                        <Input className="logistic_fee_title_column_tiny" ref="stack_fee" value={objOrder.stack_fee} disabled
                                            style={{ background: 'rgba(32, 178, 171, 0.2)', color: 'gray' }} />
                                        <Input className="logistic_fee_title_column_tiny" ref="stack_benefit" value={objOrder.stack_benefit} disabled
                                            style={{ background: 'white', color: 'gray' }} />
                                    </div>
                                    <div className="auto_order_info_row">
                                        <div className="order_detail_title">车架：</div>
                                        <Input className="logistic_fee_title_column_tiny" ref="car_frame_cost" value={objOrder.car_frame_cost}
                                            disabled style={{ background: 'white', color: 'gray' }} />
                                        <Input className="logistic_fee_title_column_tiny" ref="car_frame_fee" value={objOrder.car_frame_fee} disabled
                                            style={{ background: 'rgba(32, 178, 171, 0.2)', color: 'gray' }} />
                                        <Input className="logistic_fee_title_column_tiny" ref="car_frame_benefit" value={objOrder.car_frame_benefit} disabled
                                            style={{ background: 'white', color: 'gray' }} />
                                    </div>
                                    <div className="auto_order_info_row">
                                        <div className="order_detail_title">危险品：</div>
                                        <Input className="logistic_fee_title_column_tiny" ref="danger_cost" value={objOrder.danger_cost}
                                            disabled style={{ background: 'white', color: 'gray' }} />
                                        <Input className="logistic_fee_title_column_tiny" ref="danger_fee" value={objOrder.danger_fee} disabled
                                            style={{ background: 'rgba(32, 178, 171, 0.2)', color: 'gray' }} />
                                        <Input className="logistic_fee_title_column_tiny" ref="danger_benefit" value={objOrder.danger_benefit} disabled
                                            style={{ background: 'white', color: 'gray' }} />
                                    </div>
                                    <div className="auto_order_info_row">
                                        <div className="order_detail_title">空跑：</div>
                                        <Input className="logistic_fee_title_column_tiny" ref="null_cost" value={objOrder.null_cost}
                                            disabled style={{ background: 'white', color: 'gray' }} />
                                        <Input className="logistic_fee_title_column_tiny" ref="null_fee" value={objOrder.null_fee} disabled
                                            style={{ background: 'rgba(32, 178, 171, 0.2)', color: 'gray' }} />
                                        <Input className="logistic_fee_title_column_tiny" ref="null_benefit" value={objOrder.null_benefit} disabled
                                            style={{ background: 'white', color: 'gray' }} />
                                    </div>
                                    <div className="auto_order_info_row">
                                        <div className="order_detail_title">其他：</div>
                                        <Input className="logistic_fee_title_column_tiny" ref="port_other_cost" value={objOrder.port_other_cost}
                                            disabled style={{ background: 'white', color: 'gray' }} />
                                        <Input className="logistic_fee_title_column_tiny" ref="port_other_fee" value={objOrder.port_other_fee} disabled
                                            style={{ background: 'rgba(32, 178, 171, 0.2)', color: 'gray' }} />
                                        <Input className="logistic_fee_title_column_tiny" ref="port_other_benefit" value={objOrder.port_other_benefit} disabled
                                            style={{ background: 'white', color: 'gray' }} />
                                    </div>
                                    <div className="auto_order_info_row">
                                        <div className="order_detail_title">小计：</div>
                                        <Input className="logistic_fee_title_column_tiny" value={objOrder.port_total_cost}
                                            ref="port_total_cost" disabled style={{ color: 'darkcyan', background: 'white', weight: 'bold' }} />
                                        <Input className="logistic_fee_title_column_tiny" value={objOrder.port_total_fee} ref="port_total_fee"
                                            disabled style={{ color: 'darkcyan', background: 'rgba(32, 178, 171, 0.2)', weight: 'bold' }} />
                                        <Input className="logistic_fee_title_column_tiny" value={objOrder.port_total_benefit}
                                            ref="port_total_benefit" disabled style={{ color: 'darkcyan', background: 'white', weight: 'bold' }} />
                                    </div>
                                </div>
                            </Panel>
                            <Panel header="到仓" key="3">
                                <div className="auto_order_info_block">
                                    <div className="auto_order_info_row logistic_fee_title_row">
                                        <div className="logistic_fee_title_column_tiny">成本</div>
                                        <div className="logistic_fee_title_column_tiny">收费</div>
                                        <div className="logistic_fee_title_column_tiny">利润</div>
                                    </div>
                                    <div className="auto_order_info_row">
                                        <div className="order_detail_title">拆柜：</div>
                                        <Input className="logistic_fee_title_column_tiny" ref="open_closet_cost" value={objOrder.open_closet_cost}
                                            disabled style={{ background: 'white', color: 'gray' }} />
                                        <Input className="logistic_fee_title_column_tiny" ref="open_closet_fee" value={objOrder.open_closet_fee}
                                            disabled style={{ background: 'rgba(32, 178, 171, 0.2)', color: 'gray' }} />
                                        <Input className="logistic_fee_title_column_tiny" ref="open_closet_benefit" value={objOrder.open_closet_benefit} disabled
                                            style={{ background: 'white', color: 'gray' }} />
                                    </div>
                                    <div className="auto_order_info_row">
                                        <div className="order_detail_title">分货：</div>
                                        <Input className="logistic_fee_title_column_tiny" ref="divide_item_cost" value={objOrder.divide_item_cost}
                                            disabled style={{ background: 'white', color: 'gray' }} />
                                        <Input className="logistic_fee_title_column_tiny" ref="divide_item_fee" value={objOrder.divide_item_fee} disabled
                                            style={{ background: 'rgba(32, 178, 171, 0.2)', color: 'gray' }} />
                                        <Input className="logistic_fee_title_column_tiny" ref="divide_item_benefit" value={objOrder.divide_item_benefit} disabled
                                            style={{ background: 'white', color: 'gray' }} />
                                    </div>
                                    <div className="auto_order_info_row">
                                        <div className="order_detail_title">HBL：</div>
                                        <Input className="logistic_fee_title_column_tiny" ref="storage_hb_total_cost" value={objOrder.storage_hb_total_cost}
                                            disabled style={{ background: 'white', color: 'gray' }} />
                                        <Input className="logistic_fee_title_column_tiny" ref="storage_hb_total_fee" value={objOrder.storage_hb_total_fee} disabled
                                            style={{ background: 'rgba(32, 178, 171, 0.2)', color: 'gray' }} />
                                        <Input className="logistic_fee_title_column_tiny" ref="storage_hb_total_benefit" value={objOrder.storage_hb_total_benefit} disabled
                                            style={{ background: 'white', color: 'gray' }} />
                                    </div>
                                    <div className="auto_order_info_row">
                                        <div className="order_detail_title">小计：</div>
                                        <Input className="logistic_fee_title_column_tiny" value={objOrder.storage_total_cost}
                                            ref="storage_total_cost" disabled style={{ color: 'darkcyan', background: 'white', weight: 'bold' }} />
                                        <Input className="logistic_fee_title_column_tiny" value={objOrder.storage_total_fee} ref="storage_total_fee"
                                            disabled style={{ color: 'darkcyan', background: 'rgba(32, 178, 171, 0.2)', weight: 'bold' }} />
                                        <Input className="logistic_fee_title_column_tiny" value={objOrder.storage_total_benefit}
                                            ref="storage_total_benefit" disabled style={{ color: 'darkcyan', background: 'white', weight: 'bold' }} />
                                    </div>
                                </div>
                            </Panel>
                            <Panel header="清关" key="4">
                                <div className="auto_order_info_block">
                                    <div className="auto_order_info_row logistic_fee_title_row">
                                        <div className="logistic_fee_title_column_tiny">成本</div>
                                        <div className="logistic_fee_title_column_tiny">收费</div>
                                        <div className="logistic_fee_title_column_tiny">利润</div>
                                    </div>
                                    {uiCustomReport}
                                    {uiTax}
                                    {uiGST}
                                    {uiOverItem}
                                    {uiCFIA}
                                    {uiCheck}
                                    {uiCustomOther}
                                    <div className="auto_order_info_row">
                                        <div className="order_detail_title">HBL：</div>
                                        <Input className="logistic_fee_title_column_tiny" ref="custom_hb_total_cost" value={objOrder.custom_hb_total_cost}
                                            disabled style={{ background: 'white', color: 'gray' }} />
                                        <Input className="logistic_fee_title_column_tiny" ref="custom_hb_total_fee" value={objOrder.custom_hb_total_fee} disabled
                                            style={{ background: 'rgba(32, 178, 171, 0.2)', color: 'gray' }} />
                                        <Input className="logistic_fee_title_column_tiny" ref="custom_hb_total_benefit" value={objOrder.custom_hb_total_benefit} disabled
                                            style={{ background: 'white', color: 'gray' }} />
                                    </div>
                                    <div className="auto_order_info_row">
                                        <div className="order_detail_title">小计：</div>
                                        <Input className="logistic_fee_title_column_tiny" value={objOrder.custom_total_cost}
                                            ref="custom_total_cost" disabled style={{ color: 'darkcyan', background: 'white', weight: 'bold' }} />
                                        <Input className="logistic_fee_title_column_tiny" value={objOrder.custom_total_fee} ref="custom_total_fee"
                                            disabled style={{ color: 'darkcyan', background: 'rgba(32, 178, 171, 0.2)', weight: 'bold' }} />
                                        <Input className="logistic_fee_title_column_tiny" value={objOrder.custom_total_benefit}
                                            ref="custom_total_benefit" disabled style={{ color: 'darkcyan', background: 'white', weight: 'bold' }} />
                                    </div>
                                </div>
                            </Panel>
                            <Panel header="换单" key="5">
                                <div className="auto_order_info_block">
                                    <div className="auto_order_info_row logistic_fee_title_row">
                                        <div className="logistic_fee_title_column_tiny">成本</div>
                                        <div className="logistic_fee_title_column_tiny">收费</div>
                                        <div className="logistic_fee_title_column_tiny">利润</div>
                                    </div>
                                    <div className="auto_order_info_row">
                                        <div className="order_detail_title">HBL：</div>
                                        <Input className="logistic_fee_title_column_tiny" ref="transit_total_cost" value={objOrder.transit_total_cost} disabled
                                            style={{ background: 'white', color: 'gray' }} />
                                        <Input className="logistic_fee_title_column_tiny" ref="transit_total_fee" value={objOrder.transit_total_fee} disabled
                                            style={{ background: 'rgba(32, 178, 171, 0.2)', color: 'gray' }} />
                                        <Input className="logistic_fee_title_column_tiny" ref="transit_total_benefit" value={objOrder.transit_total_benefit} disabled
                                            style={{ background: 'white', color: 'gray' }} />
                                    </div>
                                    <div className="auto_order_info_row">
                                        <div className="order_detail_title">小计：</div>
                                        <Input className="logistic_fee_title_column_tiny" value={objOrder.transit_total_cost}
                                            ref="transit_total_cost" disabled style={{ color: 'darkcyan', background: 'white', weight: 'bold' }} />
                                        <Input className="logistic_fee_title_column_tiny" value={objOrder.transit_total_fee} ref="transit_total_fee"
                                            disabled style={{ color: 'darkcyan', background: 'rgba(32, 178, 171, 0.2)', weight: 'bold' }} />
                                        <Input className="logistic_fee_title_column_tiny" value={objOrder.transit_total_benefit}
                                            ref="transit_total_benefit" disabled style={{ color: 'darkcyan', background: 'white', weight: 'bold' }} />
                                    </div>
                                </div>
                            </Panel>
                            <Panel header="海派" key="6">
                                <div className="auto_order_info_block">
                                    <div className="auto_order_info_row logistic_fee_title_row">
                                        <div className="logistic_fee_title_column_tiny">成本</div>
                                        <div className="logistic_fee_title_column_tiny">收费</div>
                                        <div className="logistic_fee_title_column_tiny">利润</div>
                                    </div>
                                    <div className="auto_order_info_row">
                                        <div className="order_detail_title">HBL：</div>
                                        <Input className="logistic_fee_title_column_tiny" ref="deliver_total_cost" value={objOrder.deliver_total_cost} disabled
                                            style={{ background: 'white', color: 'gray' }} />
                                        <Input className="logistic_fee_title_column_tiny" ref="deliver_total_fee" value={objOrder.deliver_total_fee} disabled
                                            style={{ background: 'rgba(32, 178, 171, 0.2)', color: 'gray' }} />
                                        <Input className="logistic_fee_title_column_tiny" ref="deliver_total_benefit" value={objOrder.deliver_total_benefit} disabled
                                            style={{ background: 'white', color: 'gray' }} />
                                    </div>
                                    <div className="auto_order_info_row">
                                        <div className="order_detail_title">小计：</div>
                                        <Input className="logistic_fee_title_column_tiny" value={objOrder.deliver_total_cost}
                                            ref="deliver_total_cost" disabled style={{ color: 'darkcyan', background: 'white', weight: 'bold' }} />
                                        <Input className="logistic_fee_title_column_tiny" value={objOrder.deliver_total_fee} ref="deliver_total_fee"
                                            disabled style={{ color: 'darkcyan', background: 'rgba(32, 178, 171, 0.2)', weight: 'bold' }} />
                                        <Input className="logistic_fee_title_column_tiny" value={objOrder.deliver_total_benefit}
                                            ref="deliver_total_benefit" disabled style={{ color: 'darkcyan', background: 'white', weight: 'bold' }} />
                                    </div>
                                </div>
                            </Panel>
                            <Panel header="自提" key="7">
                                <div className="auto_order_info_block">
                                    <div className="auto_order_info_row logistic_fee_title_row">
                                        <div className="logistic_fee_title_column_tiny">成本</div>
                                        <div className="logistic_fee_title_column_tiny">收费</div>
                                        <div className="logistic_fee_title_column_tiny">利润</div>
                                    </div>
                                    <div className="auto_order_info_row">
                                        <div className="order_detail_title">HBL：</div>
                                        <Input className="logistic_fee_title_column_tiny" ref="pickup_total_cost" value={objOrder.pickup_total_cost} disabled
                                            style={{ background: 'white', color: 'gray' }} />
                                        <Input className="logistic_fee_title_column_tiny" ref="pickup_total_fee" value={objOrder.pickup_total_fee} disabled
                                            style={{ background: 'rgba(32, 178, 171, 0.2)', color: 'gray' }} />
                                        <Input className="logistic_fee_title_column_tiny" ref="pickup_total_benefit" value={objOrder.pickup_total_benefit} disabled
                                            style={{ background: 'white', color: 'gray' }} />
                                    </div>
                                    <div className="auto_order_info_row">
                                        <div className="order_detail_title">小计：</div>
                                        <Input className="logistic_fee_title_column_tiny" value={objOrder.pickup_total_cost}
                                            ref="pickup_total_cost" disabled style={{ color: 'darkcyan', background: 'white', weight: 'bold' }} />
                                        <Input className="logistic_fee_title_column_tiny" value={objOrder.pickup_total_fee} ref="pickup_total_fee"
                                            disabled style={{ color: 'darkcyan', background: 'rgba(32, 178, 171, 0.2)', weight: 'bold' }} />
                                        <Input className="logistic_fee_title_column_tiny" value={objOrder.pickup_total_benefit}
                                            ref="pickup_total_benefit" disabled style={{ color: 'darkcyan', background: 'white', weight: 'bold' }} />
                                    </div>
                                </div>
                            </Panel>
                        </Collapse>
                        <div className="auto_order_info_block">
                            <div className="auto_order_info_row">
                                <div className="order_detail_title">总计：</div>
                                <Input className="logistic_fee_title_column_tiny" value={objOrder.mb_total_cost}
                                    ref="mb_total_cost" disabled style={{ color: 'darkcyan', background: 'white', weight: 'bold' }} />
                                <Input className="logistic_fee_title_column_tiny" value={objOrder.mb_total_fee} ref="mb_total_fee"
                                    disabled style={{ color: 'darkcyan', background: 'rgba(32, 178, 171, 0.2)', weight: 'bold' }} />
                                <Input className="logistic_fee_title_column_tiny" value={objOrder.mb_total_benefit}
                                    ref="mb_total_benefit" disabled style={{ color: 'darkcyan', background: 'white', weight: 'bold' }} />
                            </div>
                        </div>
                    </div>
                );
            }
            // 客户端看到的界面
            if (this.props.iUserLevel < 5) {
                let iCurrentTab = objOrder.status;
                let arrOrderStatus = this.arrStatus;
                if (this.state.iStorageCustom >= 1) {
                    arrOrderStatus[2] = '清关';
                    arrOrderStatus[3] = '到仓';
                    if (iCurrentTab === 2)
                        iCurrentTab = 3;
                    else {
                        if (iCurrentTab === 3)
                            iCurrentTab = 2;
                    }
                }
                uiDlgTabs = (
                    <Steps size="small" current={iCurrentTab} type="default" progressDot direction={"horizontal"} >
                        {arrOrderStatus.map((step, index) => {
                            let uiStepTitle = <div style={{ fontWeight: 'normal', color: 'grey' }}>{step}</div>
                            if (this.state.iStorageCustom >= 1) {
                                if (index === 2)
                                    index = 3
                                else
                                    if (index === 3)
                                        index = 2
                            }
                            if (index === this.state.iDlgTab)
                                uiStepTitle = <div style={{ fontWeight: 'bold', color: 'darkcyan' }}>{step}</div>
                            if (this.state.iStorageCustom === 2 && (index === 2 || index === 4 || index === 6))
                                return null;
                            else
                                return (
                                    <Step key={index} title={uiStepTitle} description={""} onClick={this.SwitchDlgTab.bind(this, index)}
                                        className="logistic_step_cell" />
                                );
                        })}
                    </Steps>
                );
                uiRelease = null;
            }

            // 顶部进度按钮区域
            let uiBottomArea = null;
            let bLastDisabled = false;
            let bNextDisabled = false;
            let bFinishDisabled = true;
            if (this.state.iDlgTab === 0) {
                bLastDisabled = true;
            }
            if (this.state.iDlgTab >= 5) {
                bNextDisabled = true;
                bFinishDisabled = false;
            }
            if (this.state.iDlgType === 0)
                bNextDisabled = true;
            let btnComplete = (
                <Button type='primary' onClick={this.ConfirmButtonClick.bind(this, 2)}
                    style={{ marginLeft: '5px', marginRight: '10px' }}
                    disabled={bFinishDisabled}>结单</Button>
            );
            if (objOrder.complete) {
                btnComplete = (
                    <Button type='danger' onClick={this.ConfirmButtonClick.bind(this, -2)}
                        style={{ marginLeft: '5px', marginRight: '10px' }}
                        disabled={bFinishDisabled}>取消结单</Button>
                );
            }
            uiBottomArea = (
                <div className="title_row" >
                    <Button type='primary' onClick={this.ConfirmButtonClick.bind(this, -1)} style={{ marginLeft: 'auto', marginRight: '10px' }} disabled={bLastDisabled}> 上一步</Button>
                    <Button type='primary' onClick={this.ConfirmButtonClick.bind(this, 1)}
                        style={{ marginLeft: '5px', marginRight: '10px' }} disabled={bNextDisabled}> 下一步</Button>
                    <Button type='primary' onClick={this.ConfirmButtonClick.bind(this, 0)}
                        style={{ marginLeft: '5px', marginRight: '10px' }} >保存</Button>
                    {btnComplete}
                </div>
            );

            if (this.state.iDlgTab >= 4)
                uiAllFee = null;

            // 运单弹窗本体
            uiOrderDlg = (
                <Modal width={915} title={uiDlgTitle} visible={this.state.bDlgShow} closable={true} maskClosable={true}
                    className={'logistic_order_dlg'} footer={uiBottomArea} onCancel={this.CancelButtonClick}
                //  onOk={this.ConfirmButtonClick} okText={strConfirm} cancelText={strCancel}
                >
                    {uiCommentsDlg}
                    {uiDlgTabs}
                    {/* 海派专用 */}
                    {uiStorageInfo}
                    {/* TAB1 */}
                    {uiRelease}
                    {/* Basic */}
                    {uiBasicInfo0}
                    {uiBasicInfo1}
                    {uiBasicInfo2}
                    {uiBasicInfo3}
                    {uiBasicInfo456}
                    {/* 操作指示 */}
                    {uiDeliverNotes}
                    {/* TAB 0 */}
                    {/* TAB 1 */}
                    {uiPortInfo}
                    {/* 费用 */}
                    {uiAllFee}
                    {uiHouseBill0}
                    {/* TAB 2 */}
                    {uiHouseBill2}
                    {/* TAB 3 */}
                    {/* {uiCustomFee} */}
                    {uiHouseBill3}
                    {/* TAB 4 */}
                    {/* {uiTransitFee} */}
                    {/* TAB 5 */}
                    {/* {uiPickupFee} */}
                    {uiHouseBill45}
                    {/* 海派专用 */}
                    {uiHouseBill5}
                    {/* 收费专栏 */}
                    {uiFeeDetails}
                </Modal>
            );
            //选择车队弹窗
            let uiCarTeamList = (
                <div className="storage_sku_list_area">
                    {global.arrCarTeamData.map((carteam, index) => {
                        return (
                            <div className="storage_sku_list_row" key={index} onClick={this.CarTeamSelectResult.bind(this, index)}>
                                {carteam.name}
                            </div>
                        );
                    })}
                </div>
            );
            uiCarTeamDlg = (
                <Modal width={300} title={"选择车队"} visible={this.state.bSelectCarTeamDlg} closable={true} maskClosable={true}
                    footer={null} onCancel={this.CarTeamSelectResult.bind(this, -1)} > {uiCarTeamList} </Modal>);
            // 派送列表 
            if (this.props.iType >= 10) {

                // 发货人信息区域
                let uiNamePhoneDeliver = (
                    <div className="auto_order_info_row">
                        <div className="order_detail_title">姓名：</div>
                        <Input className="logistic_input_mid" ref="deliver_name" defaultValue={this.objDeliverOrder.deliver_name} />
                        <div className="order_detail_title">电话：</div>
                        <Input className="logistic_input_mid" ref="deliver_phone" defaultValue={this.objDeliverOrder.deliver_phone} />
                    </div>
                );
                let uiEmailDeliver = (
                    <div className="auto_order_info_row">
                        <div className="order_detail_title">邮箱：</div>
                        <Input className="order_detail_input_long" ref="deliver_email" defaultValue={this.objDeliverOrder.deliver_email} />
                    </div>
                );
                let uiAddressDeliver = (
                    <div className="auto_order_info_row">
                        <div className="order_detail_title">地址：</div>
                        <Input className="order_detail_input_long" ref="deliver_address" defaultValue={this.objDeliverOrder.deliver_address} />
                    </div>
                );
                let uiDeliverInfoArea = (
                    <div className="auto_order_info_block">
                        <div className="auto_order_info_row logistic_order_row_title">
                            <div >发货人信息</div>
                        </div>
                        {uiNamePhoneDeliver}
                        {uiEmailDeliver}
                        {uiAddressDeliver}
                    </div>
                );
                // 收货人信息区域
                let uiNamePhoneReceiver = (
                    <div className="auto_order_info_row">
                        <div className="order_detail_title">姓名：</div>
                        <Input className="logistic_input_mid" ref="receiver_name" defaultValue={this.objDeliverOrder.receiver_name} />
                        <div className="order_detail_title">电话：</div>
                        <Input className="logistic_input_mid" ref="receiver_phone" defaultValue={this.objDeliverOrder.receiver_phone} />
                    </div>
                );
                let uiEmailReceiver = (
                    <div className="auto_order_info_row">
                        <div className="order_detail_title">邮箱：</div>
                        <Input className="order_detail_input_long" ref="receiver_email" defaultValue={this.objDeliverOrder.receiver_email} />
                    </div>
                );
                let uiAddressReceiver = (
                    <div className="auto_order_info_row">
                        <div className="order_detail_title">地址：</div>
                        <Input className="order_detail_input_long" ref="receiver_address" defaultValue={this.objDeliverOrder.receiver_address} />
                    </div>
                );
                // 费用相关
                let uiOversize = (
                    <div className="auto_order_info_row">
                        <div className="order_detail_title">规格：</div>
                        <Input className="order_detail_input_long" ref="oversize" defaultValue={this.objDeliverOrder.oversize} />
                    </div>
                );
                // let uiDeliver = (
                //     <div className="auto_order_info_row">
                //         <div className="order_detail_title">指示：</div>
                //         <Input className="order_detail_input_long" ref="deliver_comments" defaultValue={this.objDeliverOrder.deliver_comments} />
                //     </div>
                // );
                let uiCarTeam = (
                    <div className="auto_order_info_row">
                        <div className="order_detail_title">车队：</div>
                        <Input className="order_detail_input_long" ref="carteam" defaultValue={this.objDeliverOrder.carteam} disabled
                            style={{ background: 'white', color: 'rgba(0, 0, 0, 0.75)' }} />
                        <Button type='primary' onClick={this.SelectCarTeam.bind(this)} style={{ marginLeft: '5px', marginRight: '5px' }} >
                            选择</Button>
                    </div>
                );
                let uiReceiverInfoArea = (
                    <div className="auto_order_info_block">
                        <div className="auto_order_info_row logistic_order_row_title">
                            <div >收货人信息</div>
                        </div>
                        {uiNamePhoneReceiver}
                        {uiEmailReceiver}
                        {uiAddressReceiver}
                    </div>);
                // 费用区域
                // 固定收费
                let fDeliverBenefit = parseFloat(this.objDeliverOrder.deliver_fee) - parseFloat(this.objDeliverOrder.deliver_cost);
                if (typeof (fDeliverBenefit) === 'number')
                    fDeliverBenefit = fDeliverBenefit.toFixed(2);
                let fStorageExportBenefit = parseFloat(this.objDeliverOrder.storage_export_fee) - parseFloat(this.objDeliverOrder.storage_export_cost);
                if (typeof (fStorageExportBenefit) === 'number')
                    fStorageExportBenefit = fStorageExportBenefit.toFixed(2);
                let fOnPlateBenefit = parseFloat(this.objDeliverOrder.on_plate_fee) - parseFloat(this.objDeliverOrder.on_plate_cost);
                if (typeof (fOnPlateBenefit) === 'number')
                    fOnPlateBenefit = fOnPlateBenefit.toFixed(2);
                let fWaitingBenefit = parseFloat(this.objDeliverOrder.waiting_fee) - parseFloat(this.objDeliverOrder.waiting_cost);
                if (typeof (fWaitingBenefit) === 'number')
                    fWaitingBenefit = fWaitingBenefit.toFixed(2);
                let fDeliverOtherBenefit = parseFloat(this.objDeliverOrder.deliver_other_fee) - parseFloat(this.objDeliverOrder.deliver_other_cost);
                if (typeof (fDeliverOtherBenefit) === 'number')
                    fDeliverOtherBenefit = fDeliverOtherBenefit.toFixed(2);
                this.objDeliverOrder = this.CalculateDeliverFee(this.objDeliverOrder);

                // let bPODCheck = false;
                // if (this.state.iPOD === 1)
                //     bPODCheck = true;
                // let bLKCheck = false;
                // if (this.state.iLKRelease === 1)
                //     bLKCheck = true;
                // let bClientCheck = false;
                // if (this.state.iClientRelease === 1)
                //     bClientCheck = true;
                // let uiPODRelease = null;
                // (
                //     <div className="auto_order_info_block">
                //         <div className="auto_order_info_row">
                //             <div className="order_detail_title" style={{ color: 'lightcoral' }}>客户放货</div>
                //             <Switch onChange={this.PODSwitch.bind(this, 2)} style={{ marginRight: '50px' }}
                //                 defaultChecked={false} checked={bClientCheck} />

                //             <div className="order_detail_title" style={{ color: 'lightcoral' }}>LK放货</div>
                //             <Switch onChange={this.PODSwitch.bind(this, 1)} style={{ marginRight: '50px' }}
                //                 defaultChecked={false} checked={bLKCheck} />

                //             <div className="order_detail_title" style={{ color: 'lightcoral' }}>POD</div>
                //             <Switch onChange={this.PODSwitch.bind(this, 0)} style={{ marginRight: '50px' }}
                //                 defaultChecked={false} checked={bPODCheck} />
                //         </div>
                //     </div>
                // );

                /* 备注区域 */
                let uiCommentsArea = null;
                if (this.state.iDlgType === 1) {
                    let uiCommentsList = null;
                    let uiSubmitButton = (
                        <Button type='primary' onClick={this.SubmitComments.bind(this, 0, -1)} style={{ marginLeft: '5px', marginRight: '5px' }} >
                            提交</Button>
                    );
                    if (this.arrOrderComments.length > 0) {
                        uiCommentsList = this.arrOrderComments.map((comments, index) => {
                            let uiEditButton = (
                                <Button type='primary' onClick={this.EditComments.bind(this, index)} disabled={comments.edit}
                                    style={{ marginLeft: 'auto', marginRight: '5px', maxWidth: '30px', Height: '30px' }} className="logistic_comment_button">
                                    <Icon type="edit" style={{ color: 'white', fontWeight: 'bold', fontSize: '20px' }} />
                                </Button>
                            );
                            let uiConfirmButton = (
                                <Button type='primary' onClick={this.SubmitComments.bind(this, 1, index)} disabled={!comments.edit}
                                    style={{ marginLeft: '5px', marginRight: '5px', maxWidth: '30px', maxHeight: '30px' }} className="logistic_comment_button">
                                    <Icon type="check" style={{ color: 'white', fontWeight: 'bold', fontSize: '20px' }} /></Button>
                            );
                            let uiDeleteButton = (
                                <Button type='danger' onClick={this.DeleteComments.bind(this, 1, index)}
                                    style={{ marginLeft: '5px', marginRight: '5px', width: '30px', height: '30px' }} className="logistic_comment_button">
                                    <Icon type="delete" style={{ color: 'white', fontWeight: 'bold', fontSize: '20px' }} /></Button>
                            );
                            if (this.props.iUserLevel < 9 && comments.user_id !== this.props.iUserID) {
                                uiEditButton = null; uiConfirmButton = null; uiDeleteButton = null;
                            }
                            if (comments.generate_time === null)
                                comments.generate_time = comments.update_time;
                            let strTime = new Date(new Date(comments.generate_time).getTime() - 7 * 60 * 60 * 1000).toISOString();
                            strTime = strTime.split('T')[0] + " " + strTime.split('T')[1].split('.')[0];
                            return (
                                <div>
                                    <div className="auto_order_info_row">
                                        <div className="order_detail_title">{comments.user_name}</div>
                                        <div style={{ marginLeft: '5px', minWidth: '100px', color: 'grey' }}>{strTime}</div>

                                    </div>
                                    <div className="auto_order_info_row">
                                        <TextArea size="large" autoSize={{ minRows: 2 }} allowClear={true} ref={"comments" + index.toString()}
                                            style={{ marginBottom: '0px' }} placeholder="" defaultValue={comments.comments} disabled={!comments.edit} />
                                    </div>
                                    <div className="auto_order_info_row">
                                        {uiEditButton}
                                        {uiConfirmButton}
                                        {uiDeleteButton}
                                    </div>
                                </div>
                            );
                        });
                    }

                    // let bClientNotes = true;
                    // if (this.props.iUserLevel < 5) {
                    //     bClientNotes = false;
                    // }
                    // uiDeliverNotes = (
                    //     <div className="auto_order_info_block">
                    //         <div className="auto_order_info_row">
                    //             <div className="order_detail_title" style={{ marginTop: '2.5px', marginBottom: 'auto' }}>操作指示：</div>
                    //             <TextArea size="large" autoSize={{ minRows: 2 }} allowClear={true} ref="client_notes" style={{ marginBottom: '0px' }}
                    //                 placeholder="" defaultValue={objOrder.client_notes} disabled={bClientNotes} />
                    //         </div>
                    //     </div>
                    // );
                    uiCommentsArea = (
                        <div className="logistic_comments_area" >
                            <div className="auto_order_info_row" >
                                <div className="order_detail_title" >备注：</div>
                            </div>
                            <div className="auto_order_info_block logistic_comments_block" >
                                {uiCommentsList}
                            </div>
                            <div className="auto_order_info_row" style={{ marginTop: 'auto', marginBottom: '0px' }}>
                                <TextArea size="large" autoSize={{ minRows: 1 }} allowClear={true} ref="new_comment" placeholder="" defaultValue={''} />
                                {uiSubmitButton}
                            </div>
                        </div>
                    );
                    // if (this.props.iUserLevel < 5)
                    //     uiCommentsArea = (
                    //         <div className="auto_order_info_block">
                    //             <div className="auto_order_info_row">
                    //                 <div className="order_detail_title" style={{ marginTop: '2.5px', marginBottom: 'auto' }}>操作指示：</div>
                    //                 <TextArea size="large" autoSize={{ minRows: 2 }} allowClear={true} ref="client_notes" style={{ marginBottom: '0px' }}
                    //                     placeholder="" defaultValue={objOrder.client_notes} disabled={bClientNotes} />
                    //             </div>
                    //         </div>
                    //     );
                    if (this.state.bDeleteComments)
                        uiDeleteCommentsDlg = (
                            <Modal title={'删除备注'} visible={this.state.bDeleteComments} okText="确定" cancelText="取消"
                                onOk={this.ConfirmDeleteComments.bind(this, 1)} onCancel={this.ConfirmDeleteComments.bind(this, -1)} >
                                <div style={{ maxHeight: '350px' }}>
                                    {"是否删除这条备注？"}<br />
                                    {this.arrOrderComments[this.iDeleteComment].comments}
                                </div>
                            </Modal>
                        );
                }
                let uiCommentsDlg = uiCommentsArea;
                if (this.state.iDlgType === 0 || !this.state.bDlgShow || this.props.iUserLevel < 5)
                    uiCommentsDlg = null;
                // 派送列表专用dlg
                let btnComplete = (
                    <Button type='primary' onClick={this.ConfirmButtonClick.bind(this, 10)}
                        style={{ marginLeft: '5px', marginRight: '10px' }} >结单</Button>
                );
                if (objOrder.complete) {
                    btnComplete = (
                        <Button type='danger' onClick={this.ConfirmButtonClick.bind(this, -10)}
                            style={{ marginLeft: '5px', marginRight: '10px' }} >取消结单</Button>
                    );
                }
                let uiBottomArea = (
                    <div className="title_row" >
                        <Button type='primary' onClick={this.ConfirmButtonClick.bind(this, 1)}
                            style={{ marginLeft: 'auto', marginRight: '10px' }} >保存</Button>
                        {btnComplete}
                    </div>
                );
                uiOrderDlg = (
                    <Modal width={915} title={uiDlgTitle} visible={this.state.bDlgShow} closable={true} maskClosable={true}
                        className={'logistic_order_dlg'} onCancel={this.CancelButtonClick} footer={uiBottomArea}>
                        {uiCommentsDlg}
                        {/* {uiPODRelease} */}
                        <div className="auto_order_info_row">
                            <div className="order_detail_title">Reference</div>
                            <Input className="order_detail_input_long" ref="reference" defaultValue={this.objDeliverOrder.reference} />
                        </div>
                        {uiDeliverInfoArea}
                        {uiReceiverInfoArea}
                        <div className="auto_order_info_block">
                            {uiOversize}
                            {/* {uiDeliver} */}
                            {uiCarTeam}
                        </div>
                        <div className="auto_order_info_block">
                            <div className="auto_order_info_row">
                                <div className="order_detail_title" style={{ color: 'lightcoral' }}>币种：</div>
                                <Dropdown className="logistic_fee_title_column" trigger={['click']}
                                    overlay={uiCurrencyFilter} placement="bottomLeft">
                                    <Button style={{ width: '125px' }}>{this.arrCurrency[this.state.iCurrency]}
                                        <Icon type="caret-down" /></Button>
                                </Dropdown>
                            </div>
                            <div className="auto_order_info_row logistic_fee_title_row">
                                <div className="logistic_fee_title_column_short">成本</div>
                                <div className="logistic_fee_title_column_short">收费</div>
                                <div className="logistic_fee_title_column_short">利润</div>
                            </div>
                            <div className="auto_order_info_row">
                                <div className="order_detail_title">派送：</div>
                                <Input className="logistic_fee_title_column_short" ref="deliver_cost" value={this.objDeliverOrder.deliver_cost}
                                    onChange={this.DeliverFeeChange.bind(this, this.objDeliverOrder, 1)} />
                                <Input className="logistic_fee_title_column_short" ref="deliver_fee" value={this.objDeliverOrder.deliver_fee}
                                    onChange={this.DeliverFeeChange.bind(this, this.objDeliverOrder, 0)} style={{ background: 'rgba(32, 178, 171, 0.2)' }} />
                                <Input className="logistic_fee_title_column_short" ref="deliver_benefit" value={fDeliverBenefit} disabled />
                            </div>
                            <div className="auto_order_info_row">
                                <div className="order_detail_title">出仓：</div>
                                <Input className="logistic_fee_title_column_short" ref="storage_export_cost" value={this.objDeliverOrder.storage_export_cost}
                                    onChange={this.DeliverFeeChange.bind(this, this.objDeliverOrder, 3)} />
                                <Input className="logistic_fee_title_column_short" ref="storage_export_fee" value={this.objDeliverOrder.storage_export_fee}
                                    onChange={this.DeliverFeeChange.bind(this, this.objDeliverOrder, 2)} style={{ background: 'rgba(32, 178, 171, 0.2)' }} />
                                <Input className="logistic_fee_title_column_short" ref="storage_export_benefit" value={fStorageExportBenefit} disabled />
                            </div>
                            <div className="auto_order_info_row">
                                <div className="order_detail_title">托盘：</div>
                                <Input className="logistic_fee_title_column_short" ref="on_plate_cost" value={this.objDeliverOrder.on_plate_cost}
                                    onChange={this.DeliverFeeChange.bind(this, this.objDeliverOrder, 5)} />
                                <Input className="logistic_fee_title_column_short" ref="on_plate_fee" value={this.objDeliverOrder.on_plate_fee}
                                    onChange={this.DeliverFeeChange.bind(this, this.objDeliverOrder, 4)} style={{ background: 'rgba(32, 178, 171, 0.2)' }} />
                                <Input className="logistic_fee_title_column_short" ref="on_plate_benefit" value={fOnPlateBenefit} disabled />
                            </div>
                            <div className="auto_order_info_row">
                                <div className="order_detail_title">等候：</div>
                                <Input className="logistic_fee_title_column_short" ref="waiting_cost" value={this.objDeliverOrder.waiting_cost}
                                    onChange={this.DeliverFeeChange.bind(this, this.objDeliverOrder, 7)} />
                                <Input className="logistic_fee_title_column_short" ref="waiting_fee" value={this.objDeliverOrder.waiting_fee}
                                    onChange={this.DeliverFeeChange.bind(this, this.objDeliverOrder, 6)} style={{ background: 'rgba(32, 178, 171, 0.2)' }} />
                                <Input className="logistic_fee_title_column_short" ref="waiting_benefit" value={fWaitingBenefit} disabled />
                            </div>
                            <div className="auto_order_info_row">
                                <div className="order_detail_title">其他：</div>
                                <Input className="logistic_fee_title_column_short" ref="deliver_other_cost" value={this.objDeliverOrder.deliver_other_cost}
                                    onChange={this.DeliverFeeChange.bind(this, this.objDeliverOrder, 9)} />
                                <Input className="logistic_fee_title_column_short" ref="deliver_other_fee" value={this.objDeliverOrder.deliver_other_fee}
                                    onChange={this.DeliverFeeChange.bind(this, this.objDeliverOrder, 8)} style={{ background: 'rgba(32, 178, 171, 0.2)' }} />
                                <Input className="logistic_fee_title_column_short" ref="deliver_other_benefit" value={fDeliverOtherBenefit} disabled />
                            </div>
                            <div className="auto_order_info_row">
                                <div className="order_detail_title">说明：</div>
                                <Input className="order_detail_input_long" ref="deliver_other_fee_comments" defaultValue={this.objDeliverOrder.deliver_other_fee_comments}
                                    placeholder={"派送其他收费说明"} />
                            </div>
                            <div className="auto_order_info_row">
                                <div className="order_detail_title">总计：</div>
                                <Input className="logistic_fee_title_column_short" value={this.objDeliverOrder.deliver_total_cost}
                                    ref="deliver_total_cost" disabled style={{ color: 'darkcyan' }} />
                                <Input className="logistic_fee_title_column_short" value={this.objDeliverOrder.deliver_total_fee} ref="deliver_total_fee"
                                    disabled style={{ color: 'gray', background: 'rgba(32, 178, 171, 0.2)' }} />
                                <Input className="logistic_fee_title_column_short" value={this.objDeliverOrder.deliver_total_benefit}
                                    ref="deliver_total_benefit" disabled style={{ color: 'darkcyan' }} />
                            </div>
                        </div>
                        {uiCarTeamDlg}
                    </Modal>
                );
            }
            uiUserDlg = (
                <Modal width={450} title={"选择负责人"} visible={this.state.bSelectUserDlg} closable={true} maskClosable={true} footer={null}
                    onCancel={this.UserSelectResult.bind(this, -1)} > {uiUserList} </Modal>);

            uiClientDlg = (
                <Modal width={450} title={uiClientSearchTitle} visible={this.state.bSelectClientDlg} closable={true} maskClosable={true} footer={null}
                    onCancel={this.ClientSelectResult.bind(this, -1)}
                > {uiClientList} </Modal>);
            //选择代理弹窗
            let uiAgentList = (
                <div className="storage_sku_list_area">
                    {global.arrAgentData.map((agent, index) => {
                        return (
                            <div className="storage_sku_list_row" key={index} onClick={this.AgentSelectResult.bind(this, index)}>
                                {agent.name}
                            </div>
                        );
                    })}
                </div>
            );
            uiAgentFilterDlg = (
                <Modal width={450} title={"选择代理"} visible={this.state.bSelectAgentFilterDlg} closable={true} maskClosable={true} footer={null}
                    onCancel={this.AgentSelectResult.bind(this, -1)}
                > {uiAgentList} </Modal>);
            uiAgentDlg = (
                <Modal width={450} title={"选择代理"} visible={this.state.bSelectAgentDlg} closable={true} maskClosable={true} footer={null}
                    onCancel={this.AgentSelectResult.bind(this, -1)}
                > {uiAgentList} </Modal>);
            //选择仓库弹窗
            let uiStorageList = (
                <div className="storage_sku_list_area">
                    {global.arrStorageData.map((storage, index) => {
                        return (
                            <div className="storage_sku_list_row" key={index} onClick={this.StorageSelectResult.bind(this, index)}>
                                {storage.name}
                            </div>
                        );
                    })}
                </div>
            );
            uiStorageDlg = (
                <Modal width={450} title={"选择仓库"} visible={this.state.bSelectStorageDlg} closable={true} maskClosable={true} footer={null}
                    onCancel={this.StorageSelectResult.bind(this, -1)}
                > {uiStorageList} </Modal>);
            //选择拖车（拖柜）弹窗
            let uiTruckList = (
                <div className="storage_sku_list_area">
                    {global.arrTruckData.map((truck, index) => {
                        return (
                            <div className="storage_sku_list_row" key={index} onClick={this.TruckSelectResult.bind(this, index)}>
                                {truck.name}
                            </div>
                        );
                    })}
                </div>
            );
            uiTruckDlg = (
                <Modal width={450} title={"选择拖车"} visible={this.state.bSelectTruckDlg} closable={true} maskClosable={true} footer={null}
                    onCancel={this.TruckSelectResult.bind(this, -1)}
                > {uiTruckList} </Modal>);

            // let uiPODRelease = null;
            // HouseBill弹窗
            if (this.state.bHouseBillDlg && this.state.iHouseBillType < 2) {
                let objHouseBill = this.arrHouseBill[this.iCurrentHouseBill];
                let uiHBFee = null;
                // 到仓、换单、海派、自提的HB收费
                if (this.state.iDlgTab >= 2) {
                    let bDisabled = false;
                    if (this.props.iUserLevel < 5)  // 客户看到的HB Fee
                        bDisabled = true;
                    // 0. 清关费
                    let uiCustomFee = null;
                    if (objHouseBill.custom_active === 1) {
                        this.arrHBFee[0].active = 1;
                        let fCustomBenefit = parseFloat(objHouseBill.custom_fee) - parseFloat(objHouseBill.custom_cost);
                        if (typeof (fCustomBenefit) === 'number')
                            fCustomBenefit = fCustomBenefit.toFixed(2);
                        uiCustomFee = (
                            <div className="auto_order_info_row">
                                <div className="order_detail_title">报关：</div>
                                <Input className="logistic_fee_title_column_short" ref="hb_custom_cost" value={objHouseBill.custom_cost}
                                    onChange={this.HBFeeInfoChange.bind(this, objHouseBill, 1)} disabled={bDisabled} />
                                <Input className="logistic_fee_title_column_short" ref="hb_custom_fee" value={objHouseBill.custom_fee}
                                    onChange={this.HBFeeInfoChange.bind(this, objHouseBill, 0)}
                                    style={{ background: 'rgba(32, 178, 171, 0.2)' }} disabled={bDisabled} />
                                <Input className="logistic_fee_title_column_short" ref="hb_custom_benefit" value={fCustomBenefit} disabled />
                                <Icon type="delete" className="logistic_house_bill_icon" style={{ color: 'lightcoral', marginLeft: '0px' }}
                                    onClick={this.DeleteHBFee.bind(this, 0)} />
                            </div>
                        );
                    }
                    // 1. 关税费
                    let uiTaxFee = null;
                    if (objHouseBill.tax_active === 1) {
                        this.arrHBFee[1].active = 1;
                        let fTaxBenefit = parseFloat(objHouseBill.tax_fee) - parseFloat(objHouseBill.tax_cost);
                        if (typeof (fTaxBenefit) === 'number')
                            fTaxBenefit = fTaxBenefit.toFixed(2);
                        uiTaxFee = (
                            <div className="auto_order_info_row">
                                <div className="order_detail_title">关税：</div>
                                <Input className="logistic_fee_title_column_short" ref="hb_tax_cost" value={objHouseBill.tax_cost}
                                    onChange={this.HBFeeInfoChange.bind(this, objHouseBill, 3)} disabled={bDisabled} />
                                <Input className="logistic_fee_title_column_short" ref="hb_tax_fee" value={objHouseBill.tax_fee}
                                    onChange={this.HBFeeInfoChange.bind(this, objHouseBill, 2)}
                                    style={{ background: 'rgba(32, 178, 171, 0.2)' }} disabled={bDisabled} />
                                <Input className="logistic_fee_title_column_short" ref="hb_tax_benefit" value={fTaxBenefit}
                                    disabled />
                                <Icon type="delete" className="logistic_house_bill_icon" style={{ color: 'lightcoral', marginLeft: '0px' }}
                                    onClick={this.DeleteHBFee.bind(this, 1)} />
                            </div>
                        );

                    }
                    // 2. GST
                    let uiGST = null;
                    if (objHouseBill.gst_active === 1) {
                        this.arrHBFee[2].active = 1;
                        let fGSTBenefit = parseFloat(objHouseBill.gst_fee) - parseFloat(objHouseBill.gst_cost);
                        if (typeof (fGSTBenefit) === 'number')
                            fGSTBenefit = fGSTBenefit.toFixed(2);
                        uiGST = (
                            <div className="auto_order_info_row">
                                <div className="order_detail_title">GST：</div>
                                <Input className="logistic_fee_title_column_short" ref="hb_gst_cost" value={objHouseBill.gst_cost}
                                    onChange={this.HBFeeInfoChange.bind(this, objHouseBill, 5)} disabled={bDisabled} />
                                <Input className="logistic_fee_title_column_short" ref="hb_gst_fee" value={objHouseBill.gst_fee}
                                    onChange={this.HBFeeInfoChange.bind(this, objHouseBill, 4)}
                                    style={{ background: 'rgba(32, 178, 171, 0.2)' }} disabled={bDisabled} />
                                <Input className="logistic_fee_title_column_short" ref="hb_gst_benefit" value={fGSTBenefit}
                                    disabled />
                                <Icon type="delete" className="logistic_house_bill_icon" style={{ color: 'lightcoral', marginLeft: '0px' }}
                                    onClick={this.DeleteHBFee.bind(this, 2)} />
                            </div>
                        );
                    }
                    // 3. 打板
                    let uiOnPlateFee = null;
                    if (objHouseBill.on_plate_active === 1) {
                        let fOnPlateBenefit = parseFloat(objHouseBill.on_plate_fee) - parseFloat(objHouseBill.on_plate_cost);
                        if (typeof (fOnPlateBenefit) === 'number')
                            fOnPlateBenefit = fOnPlateBenefit.toFixed(2);
                        this.arrHBFee[3].active = 1;
                        uiOnPlateFee = (
                            <div className="auto_order_info_row">
                                <div className="order_detail_title">打板：</div>
                                <Input className="logistic_fee_title_column_short" ref="hb_on_plate_cost" value={objHouseBill.on_plate_cost}
                                    onChange={this.HBFeeInfoChange.bind(this, objHouseBill, 7)} />
                                <Input className="logistic_fee_title_column_short" ref="hb_on_plate_fee" value={objHouseBill.on_plate_fee}
                                    onChange={this.HBFeeInfoChange.bind(this, objHouseBill, 6)}
                                    style={{ background: 'rgba(32, 178, 171, 0.2)' }} />
                                <Input className="logistic_fee_title_column_short" ref="hb_on_plate_benefit" value={fOnPlateBenefit} disabled />
                                <Icon type="delete" className="logistic_house_bill_icon" style={{ color: 'lightcoral', marginLeft: '0px' }}
                                    onClick={this.DeleteHBFee.bind(this, 3)} />
                            </div>
                        );
                    }
                    // 4. 贴标
                    let uiTagFee = null;
                    if (objHouseBill.tag_active === 1) {
                        let fTagBenefit = parseFloat(objHouseBill.tag_fee) - parseFloat(objHouseBill.tag_cost);
                        if (typeof (fTagBenefit) === 'number')
                            fTagBenefit = fTagBenefit.toFixed(2);
                        this.arrHBFee[4].active = 1;
                        uiTagFee = (
                            <div className="auto_order_info_row">
                                <div className="order_detail_title">贴标：</div>
                                <Input className="logistic_fee_title_column_short" ref="hb_tag_cost" value={objHouseBill.tag_cost}
                                    onChange={this.HBFeeInfoChange.bind(this, objHouseBill, 9)} />
                                <Input className="logistic_fee_title_column_short" ref="hb_tag_fee" value={objHouseBill.tag_fee}
                                    onChange={this.HBFeeInfoChange.bind(this, objHouseBill, 8)}
                                    style={{ background: 'rgba(32, 178, 171, 0.2)' }} />
                                <Input className="logistic_fee_title_column_short" ref="hb_tag_benefit" value={fTagBenefit}
                                    disabled />
                                <Icon type="delete" className="logistic_house_bill_icon"
                                    style={{ color: 'lightcoral', marginLeft: '0px' }}
                                    onClick={this.DeleteHBFee.bind(this, 4)} />
                            </div>
                        );
                    }
                    // 5. 超规
                    let uiOversizeFee = null;
                    if (objHouseBill.oversize_active === 1) {
                        this.arrHBFee[5].active = 1;
                        let fOversizeBenefit = parseFloat(objHouseBill.oversize_fee) - parseFloat(objHouseBill.oversize_cost);
                        if (typeof (fOversizeBenefit) === 'number')
                            fOversizeBenefit = fOversizeBenefit.toFixed(2);
                        uiOversizeFee = (
                            <div className="auto_order_info_row">
                                <div className="order_detail_title">超规：</div>
                                <Input className="logistic_fee_title_column_short" ref="hb_oversize_cost"
                                    value={objHouseBill.oversize_cost}
                                    onChange={this.HBFeeInfoChange.bind(this, objHouseBill, 11)} />
                                <Input className="logistic_fee_title_column_short" ref="hb_oversize_fee"
                                    value={objHouseBill.oversize_fee}
                                    onChange={this.HBFeeInfoChange.bind(this, objHouseBill, 10)}
                                    style={{ background: 'rgba(32, 178, 171, 0.2)' }} />
                                <Input className="logistic_fee_title_column_short" ref="hb_oversize_benefit"
                                    value={fOversizeBenefit} disabled />
                                <Icon type="delete" className="logistic_house_bill_icon"
                                    style={{ color: 'lightcoral', marginLeft: '0px' }}
                                    onClick={this.DeleteHBFee.bind(this, 5)} />
                            </div>
                        );
                    }
                    // 6. 仓储
                    let uiStorageSaveFee = null;
                    if (objHouseBill.storage_active === 1) {
                        this.arrHBFee[6].active = 1;
                        let fStorageBenefit = parseFloat(objHouseBill.storage_fee) - parseFloat(objHouseBill.storage_cost);
                        if (typeof (fStorageBenefit) === 'number')
                            fStorageBenefit = fStorageBenefit.toFixed(2);
                        uiStorageSaveFee = (
                            <div className="auto_order_info_row">
                                <div className="order_detail_title">仓储：</div>
                                <Input className="logistic_fee_title_column_short" ref="hb_storage_cost"
                                    value={objHouseBill.storage_cost}
                                    onChange={this.HBFeeInfoChange.bind(this, objHouseBill, 13)} />
                                <Input className="logistic_fee_title_column_short" ref="hb_storage_fee"
                                    value={objHouseBill.storage_fee}
                                    onChange={this.HBFeeInfoChange.bind(this, objHouseBill, 12)}
                                    style={{ background: 'rgba(32, 178, 171, 0.2)' }} />
                                <Input className="logistic_fee_title_column_short" ref="hb_storage_benefit"
                                    value={fStorageBenefit} disabled />
                                <Icon type="delete" className="logistic_house_bill_icon"
                                    style={{ color: 'lightcoral', marginLeft: '0px' }}
                                    onClick={this.DeleteHBFee.bind(this, 6)} />
                            </div>
                        );
                    }
                    // 7. 出仓
                    let uiStorageExportFee = null;
                    if (objHouseBill.storage_export_active === 1) {
                        let fStorageExportBenefit = parseFloat(objHouseBill.storage_export_fee) - parseFloat(objHouseBill.storage_export_cost);
                        if (typeof (fStorageExportBenefit) === 'number')
                            fStorageExportBenefit = fStorageExportBenefit.toFixed(2);
                        this.arrHBFee[7].active = 1;
                        uiStorageExportFee = (
                            <div className="auto_order_info_row">
                                <div className="order_detail_title">出仓：</div>
                                <Input className="logistic_fee_title_column_short" ref="hb_storage_export_cost"
                                    value={objHouseBill.storage_export_cost}
                                    onChange={this.HBFeeInfoChange.bind(this, objHouseBill, 15)} />
                                <Input className="logistic_fee_title_column_short" ref="hb_storage_export_fee"
                                    value={objHouseBill.storage_export_fee}
                                    onChange={this.HBFeeInfoChange.bind(this, objHouseBill, 14)}
                                    style={{ background: 'rgba(32, 178, 171, 0.2)' }} />
                                <Input className="logistic_fee_title_column_short" ref="hb_storage_export_benefit"
                                    value={fStorageExportBenefit} disabled />
                                <Icon type="delete" className="logistic_house_bill_icon"
                                    style={{ color: 'lightcoral', marginLeft: '0px' }}
                                    onClick={this.DeleteHBFee.bind(this, 7)} />
                            </div>
                        );
                    }
                    // 8. 超项
                    let uiOverItemFee = null;
                    if (objHouseBill.overitem_active === 1) {
                        let fOveritemBenefit = parseFloat(objHouseBill.overitem_fee) - parseFloat(objHouseBill.overitem_cost);
                        if (typeof (fOveritemBenefit) === 'number')
                            fOveritemBenefit = fOveritemBenefit.toFixed(2);
                        this.arrHBFee[8].active = 1;
                        uiOverItemFee = (
                            <div className="auto_order_info_row">
                                <div className="order_detail_title">超项：</div>
                                <Input className="logistic_fee_title_column_short" ref="hb_overitem_cost" value={objHouseBill.overitem_cost}
                                    onChange={this.HBFeeInfoChange.bind(this, objHouseBill, 17)} />
                                <Input className="logistic_fee_title_column_short" ref="hb_overitem_fee" value={objHouseBill.overitem_fee}
                                    onChange={this.HBFeeInfoChange.bind(this, objHouseBill, 16)}
                                    style={{ background: 'rgba(32, 178, 171, 0.2)' }} />
                                <Input className="logistic_fee_title_column_short" ref="hb_overitem_benefit" value={fOveritemBenefit}
                                    disabled />
                                <Icon type="delete" className="logistic_house_bill_icon" style={{ color: 'lightcoral', marginLeft: '0px' }}
                                    onClick={this.DeleteHBFee.bind(this, 8)} />
                            </div>
                        );
                    }
                    // 9. CFIA
                    let uiCFIAFee = null;
                    if (objHouseBill.cfia_active === 1) {
                        let fCFIABenefit = parseFloat(objHouseBill.cfia_fee) - parseFloat(objHouseBill.cfia_cost);
                        if (typeof (fCFIABenefit) === 'number')
                            fCFIABenefit = fCFIABenefit.toFixed(2);
                        this.arrHBFee[9].active = 1;
                        uiCFIAFee = (
                            <div className="auto_order_info_row">
                                <div className="order_detail_title">CFIA/OGD</div>
                                <Input className="logistic_fee_title_column_short" ref="hb_cfia_cost" value={objHouseBill.cfia_cost}
                                    onChange={this.HBFeeInfoChange.bind(this, objHouseBill, 19)} />
                                <Input className="logistic_fee_title_column_short" ref="hb_cfia_fee" value={objHouseBill.cfia_fee}
                                    onChange={this.HBFeeInfoChange.bind(this, objHouseBill, 18)} style={{ background: 'rgba(32, 178, 171, 0.2)' }} />
                                <Input className="logistic_fee_title_column_short" ref="hb_cfia_benefit" value={fCFIABenefit}
                                    disabled />
                                <Icon type="delete" className="logistic_house_bill_icon" style={{ color: 'lightcoral', marginLeft: '0px' }}
                                    onClick={this.DeleteHBFee.bind(this, 9)} />
                            </div>
                        );
                    }
                    // 10. 查验
                    let uiCheckFee = null;
                    if (objHouseBill.check_active === 1) {
                        let fCheckBenefit = parseFloat(objHouseBill.check_fee) - parseFloat(objHouseBill.check_cost);
                        if (typeof (fCheckBenefit) === 'number')
                            fCheckBenefit = fCheckBenefit.toFixed(2);
                        this.arrHBFee[10].active = 1;
                        uiCheckFee = (
                            <div className="auto_order_info_row">
                                <div className="order_detail_title">查验：</div>
                                <Input className="logistic_fee_title_column_short" ref="hb_check_cost" value={objHouseBill.check_cost}
                                    onChange={this.HBFeeInfoChange.bind(this, objHouseBill, 21)} />
                                <Input className="logistic_fee_title_column_short" ref="hb_check_fee" value={objHouseBill.check_fee}
                                    onChange={this.HBFeeInfoChange.bind(this, objHouseBill, 20)}
                                    style={{ background: 'rgba(32, 178, 171, 0.2)' }} />
                                <Input className="logistic_fee_title_column_short" ref="hb_check_benefit" value={fCheckBenefit} disabled />
                                <Icon type="delete" className="logistic_house_bill_icon" style={{ color: 'lightcoral', marginLeft: '0px' }}
                                    onClick={this.DeleteHBFee.bind(this, 10)} />
                            </div>
                        );
                    }
                    // 11. 换单费
                    let uiTransferFee = null;
                    if (objHouseBill.transit_active === 1) {
                        this.arrHBFee[11].active = 1;
                        let fTransitBenefit = parseFloat(objHouseBill.transit_fee) - parseFloat(objHouseBill.transit_cost);
                        if (typeof (fTransitBenefit) === 'number')
                            fTransitBenefit = fTransitBenefit.toFixed(2);
                        uiTransferFee = (
                            <div className="auto_order_info_row">
                                <div className="order_detail_title">换单：</div>
                                <Input className="logistic_fee_title_column_short" ref="hb_transit_cost" value={objHouseBill.transit_cost}
                                    onChange={this.HBFeeInfoChange.bind(this, objHouseBill, 23)} />
                                <Input className="logistic_fee_title_column_short" ref="hb_transit_fee" value={objHouseBill.transit_fee}
                                    onChange={this.HBFeeInfoChange.bind(this, objHouseBill, 22)}
                                    style={{ background: 'rgba(32, 178, 171, 0.2)' }} />
                                <Input className="logistic_fee_title_column_short" ref="hb_transit_benefit"
                                    value={fTransitBenefit} disabled />
                                <Icon type="delete" className="logistic_house_bill_icon" style={{ color: 'lightcoral', marginLeft: '0px' }}
                                    onClick={this.DeleteHBFee.bind(this, 11)} />
                            </div>
                        );
                    }
                    // 12. 派送费
                    let uiDeliverFee = null;
                    if (objHouseBill.deliver_active === 1) {
                        this.arrHBFee[12].active = 1;
                        let fDeliverBenefit = parseFloat(objHouseBill.deliver_fee) - parseFloat(objHouseBill.deliver_cost);
                        if (typeof (fDeliverBenefit) === 'number')
                            fDeliverBenefit = fDeliverBenefit.toFixed(2);
                        uiDeliverFee = (
                            <div className="auto_order_info_row">
                                <div className="order_detail_title">派送：</div>
                                <Input className="logistic_fee_title_column_short" ref="hb_deliver_cost" value={objHouseBill.deliver_cost}
                                    onChange={this.HBFeeInfoChange.bind(this, objHouseBill, 25)} />
                                <Input className="logistic_fee_title_column_short" ref="hb_deliver_fee" value={objHouseBill.deliver_fee}
                                    onChange={this.HBFeeInfoChange.bind(this, objHouseBill, 24)}
                                    style={{ background: 'rgba(32, 178, 171, 0.2)' }} />
                                <Input className="logistic_fee_title_column_short" ref="hb_deliver_benefit"
                                    value={fDeliverBenefit} disabled />
                                <Icon type="delete" className="logistic_house_bill_icon" style={{ color: 'lightcoral', marginLeft: '0px' }}
                                    onClick={this.DeleteHBFee.bind(this, 12)} />
                            </div>
                        );
                    }
                    // 13. 托盘费
                    let uiOnPlateFee45 = null;
                    if (objHouseBill.on_plate45_active === 1) {
                        this.arrHBFee[13].active = 1;
                        let fOnPlateBenefit = parseFloat(objHouseBill.on_plate45_fee) - parseFloat(objHouseBill.on_plate45_cost);
                        if (typeof (fOnPlateBenefit) === 'number')
                            fOnPlateBenefit = fOnPlateBenefit.toFixed(2);
                        uiOnPlateFee45 = (
                            <div className="auto_order_info_row">
                                <div className="order_detail_title">托盘：</div>
                                <Input className="logistic_fee_title_column_short" ref="on_plate45_cost" value={objHouseBill.on_plate45_cost}
                                    onChange={this.HBFeeInfoChange.bind(this, objHouseBill, 27)} />
                                <Input className="logistic_fee_title_column_short" ref="on_plate45_fee" value={objHouseBill.on_plate45_fee}
                                    onChange={this.HBFeeInfoChange.bind(this, objHouseBill, 26)}
                                    style={{ background: 'rgba(32, 178, 171, 0.2)' }} />
                                <Input className="logistic_fee_title_column_short" ref="on_plate45_benefit"
                                    value={fOnPlateBenefit} disabled />
                                <Icon type="delete" className="logistic_house_bill_icon" style={{ color: 'lightcoral', marginLeft: '0px' }}
                                    onClick={this.DeleteHBFee.bind(this, 13)} />
                            </div>
                        );
                    }
                    // 14. 等候费
                    let uiWaitingFee = null;
                    if (objHouseBill.waiting_active === 1) {
                        this.arrHBFee[14].active = 1;
                        let fWaitingBenefit = parseFloat(objHouseBill.waiting_fee) - parseFloat(objHouseBill.waiting_cost);
                        if (typeof (fWaitingBenefit) === 'number')
                            fWaitingBenefit = fWaitingBenefit.toFixed(2);
                        uiWaitingFee = (
                            <div className="auto_order_info_row">
                                <div className="order_detail_title">等候：</div>
                                <Input className="logistic_fee_title_column_short" ref="hb_waiting_cost" value={objHouseBill.waiting_cost}
                                    onChange={this.HBFeeInfoChange.bind(this, objHouseBill, 29)} />
                                <Input className="logistic_fee_title_column_short" ref="hb_waiting_fee" value={objHouseBill.waiting_fee}
                                    onChange={this.HBFeeInfoChange.bind(this, objHouseBill, 28)}
                                    style={{ background: 'rgba(32, 178, 171, 0.2)' }} />
                                <Input className="logistic_fee_title_column_short" ref="hb_waiting_benefit"
                                    value={fWaitingBenefit} disabled />
                                <Icon type="delete" className="logistic_house_bill_icon" style={{ color: 'lightcoral', marginLeft: '0px' }}
                                    onClick={this.DeleteHBFee.bind(this, 14)} />
                            </div>
                        );
                    }
                    // 15. 其他费用
                    let uiHBOtherFee = null;
                    let uiHBOtherComments = null;
                    if (objHouseBill.hb_other_active === 1) {
                        let fHBOtherBenefit = parseFloat(objHouseBill.hb_other_fee) - parseFloat(objHouseBill.hb_other_cost);
                        if (typeof (fHBOtherBenefit) === 'number')
                            fHBOtherBenefit = fHBOtherBenefit.toFixed(2);
                        this.arrHBFee[15].active = 1;
                        uiHBOtherFee = (
                            <div className="auto_order_info_row">
                                <div className="order_detail_title">其他：</div>
                                <Input className="logistic_fee_title_column_short" ref="hb_other_cost" value={objHouseBill.hb_other_cost}
                                    onChange={this.HBFeeInfoChange.bind(this, objHouseBill, 31)} />
                                <Input className="logistic_fee_title_column_short" ref="hb_other_fee" value={objHouseBill.hb_other_fee}
                                    onChange={this.HBFeeInfoChange.bind(this, objHouseBill, 30)}
                                    style={{ background: 'rgba(32, 178, 171, 0.2)' }} />
                                <Input className="logistic_fee_title_column_short" ref="hb_other_benefit" value={fHBOtherBenefit} disabled />
                                <Icon type="delete" className="logistic_house_bill_icon" style={{ color: 'lightcoral', marginLeft: '0px' }}
                                    onClick={this.DeleteHBFee.bind(this, 15)} />
                            </div>
                        );
                        uiHBOtherComments = (
                            <div className="auto_order_info_row">
                                <div className="order_detail_title">说明：</div>
                                <Input className="order_detail_input_long" ref="hb_other_fee_comments" defaultValue={objHouseBill.hb_other_fee_comments}
                                    placeholder={"其他收费说明"} />
                            </div>
                        );
                    }

                    let uiFeeTypeMenu = null;
                    let uiFeeTypeDropdown = null;
                    let uiAddFeeButton = null;
                    if (this.iCurrentHBFee >= 0) {
                        uiFeeTypeMenu = (
                            <Menu>
                                {this.arrHBFee.map((item, index) => {
                                    if (item.active === undefined || item.active === null || item.active === "")
                                        item.active = 0;
                                    if (item.active === 0) {
                                        return (
                                            <Menu.Item key={index} onClick={this.HBFeeSwitch.bind(this, index)}>
                                                {item.name}
                                            </Menu.Item>
                                        );
                                    } else
                                        return null;
                                })}
                            </Menu>
                        );
                        uiFeeTypeDropdown = (
                            <Dropdown className="order_detail_dropdown" trigger={['click']} overlay={uiFeeTypeMenu} placement="bottomLeft" style={{ marginLeft: '0px', marginRight: '0px' }}>
                                <Button>{this.arrHBFee[this.iCurrentHBFee].name}<Icon type="caret-down" /></Button>
                            </Dropdown>
                        );
                        uiAddFeeButton = (
                            <Button className="manage_contents_title_margin" type="primary"
                                onClick={this.AddHBFee.bind(this, this.iCurrentHBFee)}
                                style={{ color: 'white', marginLeft: '5px', marginRight: '5px' }}>
                                <Icon type="plus" />收费</Button>
                        );
                    }
                    objHouseBill = this.CalculateHBFee(objHouseBill);
                    uiHBFee = (
                        <div className="auto_order_info_block">
                            <div className="auto_order_info_row">
                                <div className="order_detail_title">币种：</div>
                                <Input className="logistic_fee_title_column" defaultValue={this.arrCurrency[objOrder.currency]}
                                    disabled style={{ background: 'white', color: 'grey', marginRight: 'auto' }} />
                                {uiFeeTypeDropdown}
                                {uiAddFeeButton}
                            </div>
                            <div className="auto_order_info_row logistic_fee_title_row">
                                <div className="logistic_fee_title_column_short">成本</div>
                                <div className="logistic_fee_title_column_short">收费</div>
                                <div className="logistic_fee_title_column_short">利润</div>
                            </div>
                            {uiCustomFee}
                            {uiTaxFee}
                            {uiGST}
                            {uiOnPlateFee}
                            {uiTagFee}
                            {uiOversizeFee}
                            {uiStorageSaveFee}
                            {uiStorageExportFee}
                            {uiOverItemFee}
                            {uiCFIAFee}
                            {uiCheckFee}
                            {uiTransferFee}
                            {uiDeliverFee}
                            {uiOnPlateFee45}
                            {uiWaitingFee}
                            {uiHBOtherFee}
                            {uiHBOtherComments}
                            <div className="auto_order_info_row">
                                <div className="order_detail_title">小计：</div>
                                <Input className="logistic_fee_title_column_short" value={objHouseBill.hb_total_cost}
                                    ref="storage_total_cost" disabled style={{ color: 'darkcyan' }} />
                                <Input className="logistic_fee_title_column_short" value={objHouseBill.hb_total_fee}
                                    ref="storage_total_fee" disabled style={{ color: 'gray', background: 'rgba(32, 178, 171, 0.2)' }} />
                                <Input className="logistic_fee_title_column_short" value={objHouseBill.hb_total_benefit}
                                    ref="storage_total_benefit" disabled style={{ color: 'darkcyan' }} />
                            </div>
                        </div>
                    );

                }
                if (this.state.iHouseBillType === 1)
                    objHouseBill = this.arrHouseBill[this.iCurrentHouseBill];
                let uiHouseBillDlgTitle = (
                    <div className="auto_order_dlg_title">
                        {'运单号：' + objOrder.oid}
                        <div className="auto_order_dlg_title_client_info" style={{ marginLeft: '50px', marginRight: '0px' }}>编号：</div>
                        <Input className="logistics_detail_input" ref="house_bill_no" defaultValue={objHouseBill.house_bill_no} style={{ marginLeft: '5px', marginRight: '5px' }} />
                        <div className="auto_order_dlg_title_client_info" style={{ marginLeft: '50px', marginRight: '0px' }}>唛头：</div>
                        <Input className="logistics_detail_input" ref="mark" defaultValue={objHouseBill.mark} style={{ marginLeft: '5px', marginRight: '5px' }} />
                    </div >
                );
                let uiHBPathRelease = null;
                if (this.state.iDlgTab === 0) {
                    uiHBPathRelease = (
                        <div className="auto_order_info_block">
                            <div className="auto_order_info_row">
                                <Radio.Group onChange={this.SwitchPath} value={this.state.iPath}
                                    style={{ color: 'darkcyan', fontWeight: 'bold' }}>
                                    <Radio value={0}>换单</Radio>
                                    <Radio value={1}>海派</Radio>
                                    <Radio value={2}>自提</Radio>
                                </Radio.Group>
                            </div>
                        </div>
                    );
                }
                let strFreeDate = "";
                if (objOrder.free_storage_date !== undefined && objOrder.free_storage_date !== null && objOrder.free_storage_date !== "")
                    strFreeDate = objOrder.free_storage_date;
                if (this.state.iDlgTab >= 2) {
                    uiHBPathRelease = null;
                    let strPath = "路径：";
                    if (objHouseBill.path === 0)
                        strPath += "换单";
                    if (objHouseBill.path === 1)
                        strPath += "海派";
                    if (objHouseBill.path === 2)
                        strPath += "自提";

                    uiHBPathRelease = (
                        <div className="auto_order_info_block">
                            <div className="auto_order_info_row" style={{ fontWeight: 'bold' }}>
                                <div className="order_detail_title">{strPath}</div>
                                <div className="order_detail_title"
                                    style={{ width: 'auto', marginLeft: '20px', marginRight: '5px', color: 'lightcoral', weight: 'bold' }}>
                                    免仓租日期</div>
                                <Input className="order_detail_input" ref="free_storage_date" value={strFreeDate} disabled />
                            </div>
                        </div>
                    );
                    if (this.state.iDlgTab === 3 && this.state.iStorageCustom === 0) {
                        let bDefaultRelease = false;
                        if (objHouseBill.clear_release === 1)
                            bDefaultRelease = true;
                        let bRelease = false;
                        if (this.state.iClearRelease === 1)
                            bRelease = true
                        let uiClearReleaseTitle = (
                            <div className="order_detail_title" style={{ color: 'lightcoral', marginLeft: 'auto', marginRight: '5px' }}>清关放行</div>
                        );
                        let uiClearRelease = (
                            <Switch onChange={this.ClearReleaseSwitch.bind(this)} style={{ marginRight: '160px' }}
                                defaultChecked={bDefaultRelease} checked={bRelease} />
                        );
                        uiHBPathRelease = (
                            <div className="auto_order_info_block">
                                <div className="auto_order_info_row" style={{ fontWeight: 'bold' }}>
                                    <div className="order_detail_title">{strPath}</div>
                                    <div className="order_detail_title"
                                        style={{ width: 'auto', marginLeft: '20px', marginRight: '5px', color: 'lightcoral', weight: 'bold' }}>
                                        免仓租日期</div>
                                    <Input className="order_detail_input" ref="receiver_name" defaultValue={strFreeDate} disabled />
                                    {uiClearReleaseTitle}
                                    {uiClearRelease}
                                </div>
                            </div>
                        );
                    }
                }
                let uiDeliver = null;
                let uiCarTeam = null;
                let uiPickupDate = null;
                let uiNamePhone = null;
                let uiAddress = null;
                let uiEmail = null;
                let uiNum1 = null;
                let uiNum2 = null;
                // 换单、海派、自提
                if (this.state.iDlgTab >= 4) {
                    uiHBPathRelease = null;
                    // let bPODCheck = false;
                    // if (this.state.iPOD === 1)
                    //     bPODCheck = true;
                    // let bLKCheck = false;
                    // if (this.state.iLKRelease === 1)
                    //     bLKCheck = true;
                    // let bClientCheck = false;
                    // if (this.state.iClientRelease === 1)
                    //     bClientCheck = true;
                    // uiPODRelease = null;
                    // (
                    //     <div className="auto_order_info_block">
                    //         <div className="auto_order_info_row">
                    //             <div className="order_detail_title"
                    //                 style={{ width: 'auto', marginLeft: '10px', marginRight: '5px', color: 'lightcoral', weight: 'bold' }}>
                    //                 免仓租日期</div>
                    //             <Input className="order_detail_input" ref="free_storage_date" value={strFreeDate} disabled />
                    //             <div className="order_detail_title" style={{ color: 'lightcoral' }}>客户放货</div>
                    //             <Switch onChange={this.PODSwitch.bind(this, 2)} style={{ marginRight: '50px' }}
                    //                 defaultChecked={false} checked={bClientCheck} />

                    //             <div className="order_detail_title" style={{ color: 'lightcoral' }}>LK放货</div>
                    //             <Switch onChange={this.PODSwitch.bind(this, 1)} style={{ marginRight: '50px' }}
                    //                 defaultChecked={false} checked={bLKCheck} />

                    //             <div className="order_detail_title" style={{ color: 'lightcoral' }}>POD</div>
                    //             <Switch onChange={this.PODSwitch.bind(this, 0)} style={{ marginRight: '50px' }}
                    //                 defaultChecked={false} checked={bPODCheck} />
                    //         </div>
                    //     </div>
                    // );
                    uiNamePhone = (
                        <div className="auto_order_info_row">
                            {"姓名: " + objHouseBill.receiver_name + ", 电话: " + objHouseBill.receiver_phone}
                        </div>
                    );
                    if (objHouseBill.receiver_email === "" || objHouseBill.receiver_email === null || objHouseBill.receiver_email === undefined)
                        uiEmail = null;
                    else {
                        uiNamePhone = (
                            <div className="auto_order_info_row">
                                {"姓名: " + objHouseBill.receiver_name + ", 电话: " + objHouseBill.receiver_phone + ", 邮箱：" + objHouseBill.receiver_email}
                            </div>
                        );
                        uiEmail = null;
                    }
                    if (objHouseBill.receiver_address === "" || objHouseBill.receiver_address === null || objHouseBill.receiver_address === undefined)
                        objHouseBill.receiver_address = "";
                    uiAddress = (
                        <div className="auto_order_info_row">
                            {"地址: " + objHouseBill.receiver_address}
                        </div>
                    );
                    uiNum1 = (
                        <div className="auto_order_info_row">
                            {"包裹数: " + objHouseBill.num_pkg + "PKG, 箱数: " + objHouseBill.num_crate + "CRATE, 方数: " + objHouseBill.cbm +
                                "CMB, 重量: " + objHouseBill.weight + "KG, 板数: " + objHouseBill.plate}
                        </div>
                    );
                    uiNum2 = null;
                    if (this.state.iDlgTab <= 6) {
                        uiCarTeam = (
                            <div className="auto_order_info_row">
                                <div className="order_detail_title">车队：</div>
                                <Input className="order_detail_input_long" ref="carteam" defaultValue={objHouseBill.carteam} disabled
                                    style={{ background: 'white', color: 'rgba(0, 0, 0, 0.75)' }} />
                                <Button type='primary' onClick={this.SelectCarTeam.bind(this)} style={{ marginLeft: '5px', marginRight: '5px' }} >
                                    选择</Button>
                            </div>
                        );
                        let strPickupDate = "提货日期：";
                        if (this.state.iDlgTab === 6)
                            strPickupDate = "放货日期：";
                        uiPickupDate = (
                            <div className="auto_order_info_row">
                                <div className="order_detail_title">{strPickupDate}</div>
                                <Input className="order_detail_input" ref="pickup_date" defaultValue={objHouseBill.pickup_date} placeholder='YYYY-MM-DD' />
                            </div>
                        );
                    }
                }
                let bInfoDisabled = false;
                if (this.props.iUserLevel < 5) {
                    bInfoDisabled = true;
                    if (this.state.iDlgTab >= 4) {
                        // let bClientCheck = false;
                        // if (this.state.iClientRelease === 1)
                        //     bClientCheck = true;
                        // uiPODRelease = null;
                        // (
                        //     <div className="auto_order_info_block">
                        //         <div className="auto_order_info_row">
                        //             <div className="order_detail_title" style={{ color: 'lightcoral' }}>客户放货</div>
                        //             <Switch onChange={this.PODSwitch.bind(this, 2)} style={{ marginRight: '50px' }}
                        //                 defaultChecked={false} checked={bClientCheck} />
                        //         </div>
                        //     </div>
                        // );

                        let strPickupDate = "提货日期：";
                        if (this.state.iDlgTab === 6)
                            strPickupDate = "放货日期：";
                        uiPickupDate = (
                            <div className="auto_order_info_row">
                                <div className="order_detail_title">{strPickupDate}</div>
                                <Input className="order_detail_input" ref="pickup_date" defaultValue={objHouseBill.pickup_date} placeholder='YYYY-MM-DD' disabled />
                            </div>
                        );
                    }
                    uiHBPathRelease = null;
                    uiCarTeam = null;
                    uiHouseBillDlgTitle = (
                        <div className="auto_order_dlg_title">
                            <div className="auto_order_dlg_title">
                                {'运单号：' + objOrder.oid}
                                <div className="auto_order_dlg_title_client_info" style={{ marginLeft: '50px', marginRight: '0px' }}>
                                    编号：</div>
                                <Input className="logistics_detail_input" ref="house_bill_no" defaultValue={objHouseBill.house_bill_no} style={{ marginLeft: '5px', marginRight: '5px' }} />
                                <div className="auto_order_dlg_title_client_info" style={{ marginLeft: '50px', marginRight: '0px' }}>
                                    唛头：</div>
                                <Input className="logistics_detail_input" ref="mark" defaultValue={objHouseBill.mark}
                                    style={{ marginLeft: '5px', marginRight: '5px' }} />
                            </div >
                        </div >
                    );

                }
                let uiOversize = (
                    <div className="auto_order_info_row">
                        <div className="order_detail_title">超规：</div>
                        <Input className="order_detail_input_long" ref="oversize" defaultValue={objHouseBill.oversize} disabled={bInfoDisabled} />
                    </div>
                );
                // 收货人信息
                uiNamePhone = (
                    <div className="auto_order_info_row">
                        <div className="order_detail_title">姓名：</div>
                        <Input className="order_detail_input" ref="receiver_name" defaultValue={objHouseBill.receiver_name} disabled={bInfoDisabled} />
                        <div className="order_detail_title">电话：</div>
                        <Input className="order_detail_input" ref="receiver_phone" defaultValue={objHouseBill.receiver_phone} disabled={bInfoDisabled} />
                    </div>
                );
                uiEmail = (
                    <div className="auto_order_info_row">
                        <div className="order_detail_title">邮箱：</div>
                        <Input className="order_detail_input_long" ref="receiver_email" defaultValue={objHouseBill.receiver_email} disabled={bInfoDisabled} />
                    </div>
                );
                uiAddress = (
                    <div className="auto_order_info_row">
                        <div className="order_detail_title">地址：</div>
                        <Input className="order_detail_input_long" ref="receiver_address" defaultValue={objHouseBill.receiver_address} disabled={bInfoDisabled} />
                    </div>
                );
                let fNumTotal = parseFloat(objHouseBill.num_pkg) + parseFloat(objHouseBill.num_crate);
                if (typeof (fNumTotal) === "number")
                    fNumTotal = fNumTotal.toFixed(2);

                uiNum1 = (
                    <div className="auto_order_info_row">
                        <div className="order_detail_title">包裹数：</div>
                        <Input className="order_detail_input" ref="num_pkg" defaultValue={objHouseBill.num_pkg} addonAfter={'PKG'} onChange={this.HouseBillInfoChange.bind(this, 0)} disabled={bInfoDisabled} />
                        <div className="order_detail_title">箱数：</div>
                        <Input className="order_detail_input" ref="num_crate" defaultValue={objHouseBill.num_crate} addonAfter={'CRATE'} onChange={this.HouseBillInfoChange.bind(this, 1)} disabled={bInfoDisabled} />
                        <div className="order_detail_title">总数：</div>
                        <Input className="order_detail_input" ref="num_total" defaultValue={fNumTotal} disabled />
                    </div>
                );
                uiNum2 = (
                    <div className="auto_order_info_row">
                        <div className="order_detail_title">方数：</div>
                        <Input className="order_detail_input" ref="hb_cbm" defaultValue={objHouseBill.cbm} addonAfter={'CBM'} disabled={bInfoDisabled} />
                        <div className="order_detail_title">重量：</div>
                        <Input className="order_detail_input" ref="hb_weight" defaultValue={objHouseBill.weight} addonAfter={'KG'} disabled={bInfoDisabled} />
                        <div className="order_detail_title">板数：</div>
                        <Input className="order_detail_input" ref="hb_plate" defaultValue={objHouseBill.plate} addonAfter={'PLT'} disabled={bInfoDisabled} />
                    </div>
                );
                let uiHBLComments = null;
                if (this.props.iUserLevel >= 5) {
                    // if (this.state.iDlgTab >= 4)
                    //     uiDeliver = (
                    //         <div className="auto_order_info_row">
                    //             <div className="order_detail_title">操作指示：</div>
                    //             <Input className="order_detail_input_long" ref="deliver_comments" defaultValue={objHouseBill.deliver_comments} disabled />
                    //         </div>
                    //     );
                    uiHBLComments = (
                        <div className="auto_order_info_block">
                            <div className="auto_order_info_row">
                                <div className="order_detail_title" style={{ marginTop: '2.5px', marginBottom: 'auto' }}>备注：</div>
                                <TextArea size="large" autoSize={{ minRows: 2 }} allowClear={true} ref="hb_comments" style={{ marginBottom: '0px' }}
                                    placeholder="" defaultValue={objHouseBill.comments} disabled={bInfoDisabled} />
                            </div>
                            {uiDeliver}
                        </div>
                    );

                }
                else {
                    if (this.state.iDlgTab >= 4) {
                        // uiDeliver = (
                        //     <div className="auto_order_info_row">
                        //         <div className="order_detail_title">操作指示：</div>
                        //         <Input className="order_detail_input_long" ref="deliver_comments" defaultValue={objHouseBill.deliver_comments} />
                        //     </div>
                        // );
                        uiHBLComments = (
                            <div className="auto_order_info_block">
                                {uiDeliver}
                            </div>
                        );
                    }
                }
                // House Bill 编辑弹窗
                let strSaveButton = "确定"; let strCancelButton = "取消";
                if (this.state.iHouseBillType === 1) {
                    strSaveButton = "保存"; strCancelButton = "关闭";
                }
                uiHouseBillDlg = (
                    <Modal width={915} title={uiHouseBillDlgTitle} visible={this.state.bHouseBillDlg} closable={true} maskClosable={true}
                        onOk={this.ConfirmHouseBill} onCancel={this.CloseHouseBill} okText={strSaveButton} cancelText={strCancelButton} >
                        {uiHBPathRelease}
                        {/* {uiPODRelease} */}
                        {uiHBLComments}
                        <div className="auto_order_info_block">
                            <div className="auto_order_info_row logistic_order_row_title">
                                <div >收货人信息</div>
                            </div>
                            {uiNamePhone}
                            {uiEmail}
                            {uiAddress}
                            {uiNum1}{uiNum2}
                            {uiOversize}
                            {uiCarTeam}
                            {uiPickupDate}
                        </div>
                        {uiHBFee}
                        {/* 选择车队弹窗 */}
                        {uiCarTeamDlg}
                    </Modal>
                );
            }
            // 删除HouseBill弹窗
            if (this.state.iHouseBillType === 2) {
                strDlgTitle = "删除 House Bill";
                uiDeleteHouseBillDlg = (
                    <Modal title={strDlgTitle} visible={this.state.bHouseBillDlg} okText="确定" cancelText="取消" okType="danger"
                        onOk={this.ConfirmDeleteHouseBill.bind(this, 1)} onCancel={this.ConfirmDeleteHouseBill.bind(this, -1)} >
                        {"是否删除：" + this.arrHouseBill[this.iCurrentHouseBill].oid + this.arrHouseBill[this.iCurrentHouseBill].house_bill_no}
                    </Modal>
                );
            }
            // 客户确认弹窗
            if (this.props.iUserLevel < 5 && this.state.bClientConfirmDlg) {
                this.iConfirmCode = 1000 + parseInt(Math.floor(Math.random() * 8999), 10);
                strDlgTitle = "请输入验证码进行保存确认";
                uiClientConfirmDlg = (
                    <Modal title={strDlgTitle} visible={this.state.bClientConfirmDlg} okText="确定" cancelText="取消" width={450}
                        onOk={this.ClientConfirm.bind(this, 1)} onCancel={this.ClientConfirm.bind(this, -1)} >
                        <div className="auto_order_info_row">
                            <div className="order_detail_title" >验证码：</div>
                            <Input className="order_detail_input" ref="confirm_code" defaultValue={""} />
                            <div className="order_detail_title" style={{ color: 'darkcyan', fontWeight: 'bold' }}>{this.iConfirmCode}</div>
                        </div>
                    </Modal>
                );
            }
        }
        // 删除运单弹窗
        if (this.state.bDlgShow && this.state.iDlgType === 2) {
            strDlgTitle = "删除运单";
            uiOrderDlg = (
                <Modal title={uiDlgTitle} visible={this.state.bDlgShow} okText="确定" cancelText="取消"
                    onOk={this.ConfirmButtonClick} onCancel={this.CancelButtonClick} >
                    {"是否删除：" + this.strDeleteOrderID}
                </Modal>
            );
        }

        // 表格分页属性
        let uiPagination = {
            simple: false,
            showSizeChanger: true,
            showQuickJumper: false,
            showTotal: () => `共 ${this.arrTableData.length} 笔运单`,
            defaultPageSize: 10,
            defaultCurrent: this.state.iCurrentPage,
            pageSizeOptions: ['10', '20', '30', '40', '50'],
            total: this.arrTableData.length,
            position: 'bottom',
            onChange: this.TablePageChange.bind(this)
        };
        const columns = this.state.columns.map((col, index) => ({
            ...col,
            onHeaderCell: column => ({
                width: column.width,
                onResize: this.handleResize(index),
            }),
        }));

        // 表格主体
        let uiTable = (
            <Table style={{ marginLeft: '20px', marginRight: '20px', width: '96%', overflowX: 'auto' }}
                bordered components={this.components}
                pagination={uiPagination} rowSelection={rowSelection} columns={columns}
                dataSource={this.arrTableData} onRow={(record) => ({ onClick: () => { this.selectRow(record); } })}
                rowClassName={(record) => record.color.replace('#', '')}
                rowKey={record => record.key} />
        );
        if (this.state.bLoading) {
            uiTable = (
                <div className="table_loading">
                    <Spin size="large" />
                </div>
            );
        }
        // 批量编辑弹窗
        let strMultiEditTitle = "";
        let uiMultiEditDlg = null;
        if (this.state.bMultiEdit) {
            strMultiEditTitle = "批量编辑：" + this.arrSelectOrders[0].oid + " 等运单";
            uiMultiEditDlg = (
                <Modal width={350} title={strMultiEditTitle} visible={this.state.bMultiEditDlg} okText="确定" cancelText="取消"
                    onOk={this.ConfirmButtonClick} onCancel={this.CancelButtonClick} >
                    <div className="item_info_row">
                        <Checkbox onChange={this.MultiEditOption.bind(this, 0)} checked={this.state.arrMultiEditOption[0]} />
                        <div className="order_detail_title auto_order_detail_title">货运类型：</div>
                        {/* {uiTypeChannel} */}
                    </div>
                    <div className="item_info_row">
                        <Checkbox onChange={this.MultiEditOption.bind(this, 1)} checked={this.state.arrMultiEditOption[1]} />
                        <div className="order_detail_title auto_order_detail_title">预计到港：</div>
                        <DatePicker className="order_date_pickup" onChange={this.ArriveDateChange}
                            value={moment(this.state.eta, dateFormat2)}
                            defaultValue={moment(this.strToday, dateFormat2)} />
                    </div>
                    <div className="item_info_row">
                        <Checkbox onChange={this.MultiEditOption.bind(this, 2)} checked={this.state.arrMultiEditOption[2]} />
                        <div className="order_detail_title auto_order_detail_title" style={{ width: 'auto' }}>预计到港自动提醒</div>
                    </div>
                    {/* <div className="item_info_row">
                        <Checkbox onChange={this.MultiEditOption.bind(this, 3)} checked={this.state.arrMultiEditOption[3]} />
                        <div className="order_detail_title auto_order_detail_title">物流状态：</div>
                        <Dropdown className="order_detail_dropdown" trigger={['click']}
                            overlay={uiOrderTransitStatusMenu} placement="bottomLeft">
                            <Button className="order_details_dropdown_button">
                                <div className="order_details_dropdown_text">{this.arrOrderTransitStatus[this.state.iMultiEditTransitStatus]}</div>
                                <Icon type="caret-down" />
                            </Button>
                        </Dropdown>
                    </div> */}
                    {/* <div className="item_info_row">
                        <Checkbox onChange={this.MultiEditOption.bind(this, 4)} checked={this.state.arrMultiEditOption[4]} disabled />
                        <div className="order_detail_title auto_order_detail_title">状态描述：</div>
                        <Dropdown className="order_detail_dropdown" trigger={['click']} disabled={bMenuDisable}
                            overlay={uiOrderTransitDetailsMenu} placement="bottomLeft">
                            <Button className="order_details_dropdown_button">
                                <div className="order_details_dropdown_text">
                                    {this.arrOrderTransitDetails[this.state.iMultiEditTransitDetails]}</div>
                                <Icon type="caret-down" />
                            </Button>
                        </Dropdown>
                    </div> */}
                </Modal>
            );
        }

        // 顶部栏
        let strDateDesc = "预计到港 ↓";
        if (this.iArriveOrder === -1)
            strDateDesc = "预计到港 ↑";
        let strOrderDesc = "运单编号 ↓";
        if (this.iIDOrder === -1)
            strOrderDesc = "运单编号 ↑";
        let strHightlight = "normal";
        if (this.iHightLightOrder === 1)
            strHightlight = "bold"
        let strCurrentDate = this.dateCurrent;
        if (this.dateCurrent === "")
            strCurrentDate = this.strToday;
        let arrArriveDate = this.dateArrive;
        if (this.dateArrive[0] === "")
            arrArriveDate = [this.strToday, this.strToday];
        let uiNewButton = (<Button className="manage_contents_title_margin" type="primary" onClick={this.ShowDlg.bind(this, 0)} >
            <Icon type="plus" />新建</Button>);
        // 全部、结单、海派、客户账号，不显示新建按钮
        if (this.props.iType === 0 || this.props.iType === 7 || this.props.iType === 10 ||
            this.props.iType === 13 || this.props.iUserLevel < 5)
            uiNewButton = null;
        let uiDeleteButton = null;
        if (this.props.iUserLevel === 9)
            uiDeleteButton = (
                <Button className="manage_contents_title_margin logistic_comment_button" type="danger" onClick={this.ShowDlg.bind(this, 2)} style={{ width: '75px' }}
                    disabled={this.state.btnControlDisable[2]}> <Icon type="close" />删除</Button>
            );
        /* 过滤器 */
        let uiStatusFilterMenu = (
            <Menu>
                {this.arrOrderStatus.map((status, index) => {
                    return (
                        <Menu.Item key={index} onClick={this.OrderStatusFilter.bind(this, index)}>
                            {status}
                        </Menu.Item>
                    );
                })}
            </Menu>
        );
        let uiStatusFilterDropDown = (
            <Dropdown className="user_edit_info_input" trigger={['click']}
                overlay={uiStatusFilterMenu} placement="bottomLeft">
                <Button>{this.arrOrderStatus[this.iCurrentOrderStatusFilter]}<Icon type="caret-down" /></Button>
            </Dropdown>
        );
        let uiTeamFilterMenu = (
            <Menu>
                {this.arrTeamFilter.map((team, index) => {
                    return (
                        <Menu.Item key={index} onClick={this.TeamFilter.bind(this, index)}>
                            {team}
                        </Menu.Item>
                    );
                })}
            </Menu>
        );
        let uiTeamFilterDropDown = (
            <Dropdown className="user_edit_info_input" trigger={['click']}
                overlay={uiTeamFilterMenu} placement="bottomLeft">
                <Button>{this.arrTeamFilter[this.iCurrentTeamFilter]}<Icon type="caret-down" /></Button>
            </Dropdown>
        );

        let bClientDisabled = false;
        let uiThirdTitle = (
            <div className="logistics_page_title_thd">
                {uiNewButton}
                <Button className="manage_contents_title_margin logistic_comment_button" type="primary" onClick={this.ShowDlg.bind(this, 1)} disabled={this.state.btnControlDisable[1]}
                    style={{ width: '75px' }}>
                    <Icon type="edit" />编辑</Button>
                {uiDeleteButton}
                <div className="logistic_filter_title" style={{ maxWidth: '50px' }}>客户</div>
                <Input className="logistic_search_input" ref="client_filter" style={{ marginRight: '0px' }}
                    disabled={bClientDisabled} />
                <Button type='primary' onClick={this.SelectClient.bind(this)} style={{ maxWidth: '50px' }} className="logistic_comment_button">选择</Button>
                <div className="logistic_filter_title" >负责人</div>
                <Input className="logistic_search_input" ref="user_filter" style={{ marginRight: '0px' }} />
                <Button type='primary' onClick={this.SelectUser.bind(this)} style={{ maxWidth: '50px' }} className="logistic_comment_button">选择</Button>
                <div className="logistic_filter_title">关键字</div>
                <Input className="logistic_search_input" ref="order_search" defaultValue="" style={{ marginRight: '0px' }} />
                <Button type="primary" onClick={this.SearchOrder} style={{ width: '30px' }} className="logistic_comment_button">
                    <Icon type="search" /></Button>
                <div className="logistic_filter_title">HBL</div>
                <Input className="logistic_search_input logistic_comment_button" ref="hbl_search" defaultValue="" style={{ marginRight: '0px' }} />
                <Button type="primary" onClick={this.SearchHBL} style={{ width: '30px' }} className="logistic_comment_button">
                    <Icon type="search" /></Button>
            </div>
        );
        if (this.props.iUserLevel === 1) {
            bClientDisabled = true;
            uiThirdTitle = (
                <div className="logistics_page_title_thd">
                    {uiNewButton}
                    <Button className="manage_contents_title_margin logistic_comment_button" type="primary" onClick={this.ShowDlg.bind(this, 1)} disabled={this.state.btnControlDisable[1]}
                        style={{ width: '75px' }}>
                        <Icon type="edit" />编辑</Button>
                    {uiDeleteButton}

                    <div className="logistic_filter_title">关键字</div>
                    <Input className="logistic_search_input" ref="order_search" defaultValue="" style={{ marginRight: '0px' }} />
                    <Button type="primary" onClick={this.SearchOrder} style={{ width: '30px' }} className="logistic_comment_button">
                        <Icon type="search" /></Button>
                    <div className="logistic_filter_title">HBL</div>
                    <Input className="logistic_search_input logistic_comment_button" ref="hbl_search" defaultValue="" style={{ marginRight: '0px' }} />
                    <Button type="primary" onClick={this.SearchHBL} style={{ width: '30px' }} className="logistic_comment_button">
                        <Icon type="search" /></Button>
                </div>
            );
        }

        let uiTitle = (
            <div>
                <div className="logistics_page_title">
                    <div className="manage_title_left_side">
                        <Button className="manage_contents_title_margin manage_contents_title_margin_right" type="primary"
                            onClick={this.GetTargetOrders.bind(this, 0)}>{strOrderDesc}</Button>
                        <Button className="manage_contents_title_margin manage_contents_title_margin_right" type="primary"
                            onClick={this.GetTargetOrders.bind(this, 1)}>{strDateDesc}</Button>
                        <Button className="manage_contents_title_margin manage_contents_title_margin_right" type="primary"
                            onClick={this.ShowAllOrders} >显示全部</Button>
                        <Button className="manage_contents_title_margin manage_contents_title_margin_right" type="primary"
                            style={{ fontWeight: strHightlight }}
                            onClick={this.GetTargetOrders.bind(this, 2)} >显示高光</Button>
                        <Button className="manage_contents_title_margin" type="primary" onClick={this.ShowDlg.bind(this, 4)} disabled={!this.state.bHighlight}
                            style={{ marginRight: "15px", background: 'lightseagreen', color: 'black' }}>
                            <Icon type="highlight" />高光标记</Button>
                        <Button className="manage_contents_title_margin" type="primary" onClick={this.ShowDlg.bind(this, 5)} disabled={!this.state.bHighlight}
                            style={{ marginRight: "20px" }}>
                            <Icon type="eye-invisible" />取消标记</Button>
                        <Button className="manage_contents_title_margin" type="primary" onClick={this.ArriveDateFilter.bind(this, 10)}
                            style={{ marginRight: "15px", background: 'yellow', color: 'black' }}>
                            <Icon type="clock-circle" />10日提醒</Button>
                        <Button className="manage_contents_title_margin" type="primary" onClick={this.ArriveDateFilter.bind(this, 5)}
                            style={{ marginRight: "15px", background: 'lightcoral', color: 'black' }}>
                            <Icon type="clock-circle" />5日提醒</Button>
                    </div>
                </div>
                <div className="logistics_page_title_sec">
                    <div className="order_filter_title">创建日期</div>
                    <MonthPicker placeholder="选择月份" picker="month" onChange={this.MonthPickerChange}
                        defaultValue={moment(this.strToday, dateFormat1)} value={moment(strCurrentDate, dateFormat1)}
                        className="manage_contents_title_margin" style={{ maxWidth: '125px' }} />
                    <div className="order_filter_title">预计到港</div>
                    <RangePicker defaultValue={[moment(this.strToday, dateFormat2), moment(this.strToday, dateFormat2)]} format={dateFormat2}
                        value={[moment(arrArriveDate[0], dateFormat2), moment(arrArriveDate[1], dateFormat2)]}
                        onChange={this.ArriveDateRangeChange} style={{ maxWidth: '250px' }} size={"middle"} />
                    <div className="order_filter_title">物流状态</div>
                    {uiStatusFilterDropDown}
                    <div className="order_filter_title">组别</div>
                    {uiTeamFilterDropDown}
                </div>
                {uiThirdTitle}
            </div>
        );
        let strAreaStyle = "logistics_page_contents";
        return (
            <div className={strAreaStyle} style={{ paddingTop: ' 170px' }}>
                {/* 运单管理页面控制栏 */}
                {uiTitle}
                {/* 表格主体 */}
                {uiTable}
                {/* 选择负责人弹窗过滤器 */}
                {uiUserFilterDlg}
                {/* 选择客户弹窗过滤器 */}
                {uiClientFilterDlg}
                {/* 选择代理弹窗过滤器 */}
                {uiAgentFilterDlg}
                {/* 运单弹窗 */}
                {uiOrderDlg}
                {/* 选择负责人弹窗 */}
                {uiUserDlg}
                {/* 选择客户弹窗 */}
                {uiClientDlg}
                {/* 选择代理弹窗 */}
                {uiAgentDlg}
                {/* 选择仓库弹窗 */}
                {uiStorageDlg}
                {/* 选择托车弹窗 */}
                {uiTruckDlg}
                {/* 批量编辑弹窗 */}
                {uiMultiEditDlg}
                {/* HouseBill弹窗 */}
                {uiHouseBillDlg}
                {uiDeleteHouseBillDlg}
                {/* 客户确认弹窗 */}
                {uiClientConfirmDlg}
                {/* 删除备注弹窗 */}
                {uiDeleteCommentsDlg}
                {/* 显示全部备注弹窗 */}
                {/* {uiViewAllCommentsDlg} */}
                {/* 预报信息弹窗 */}
                {uiReportDlg}
                {uiReleaseDlg}
            </div>
        );
    }
}
export default LogisticOrder;