import React, { Component } from 'react';
import axios from 'axios';
// import moment from 'moment';

import Spin from 'antd/es/spin';
import Table from 'antd/es/table';
import Button from 'antd/es/button';
import Input from 'antd/es/input';
import Icon from 'antd/es/icon';
import Modal from 'antd/es/modal';
import Menu from 'antd/es/menu';
import Dropdown from 'antd/es/dropdown';
// import DatePicker from 'antd/es/date-picker';
// import Radio from 'antd/es/radio';
import message from 'antd/es/message';

import '../../../styles/Background/ego_manager.css';
import '../../../styles/Background/UI_Module/user_management.css';
import '../../../styles/Background/UI_Module/transit_price.css';

const g_strMessageKey = 'updating';

// 表格
const arrTableHeader = [
    // { title: '起运地', dataIndex: 'from_str', width: 100 },
    // { title: '目的地', dataIndex: 'to_str', width: 100 },
    // { title: '备注', dataIndex: 'comments', width: 200 },
    { title: '单价', dataIndex: 'price_str', width: 150 },
    { title: '折扣', dataIndex: 'discount_str', width: 150 },
    // { title: '成本', dataIndex: 'cost_str', width: 125 },
    { title: '类别', dataIndex: 'transit_str', width: 150 },
    // { title: '有效期', dataIndex: 'expire_date', width: 150 }
    // { title: '更新时间', dataIndex: 'update_date' }
];
// const arrTableHeader_m = [
//     { title: '起运地', dataIndex: 'from_str', width: 100, ellipsis: true, className: 'store_manage_table_m' },
//     { title: '目的地', dataIndex: 'to_str', width: 100, ellipsis: true, className: 'store_manage_table_m' },
//     { title: '备注', dataIndex: 'comments', width: 150, ellipsis: true, className: 'store_manage_table_m' },
//     { title: '原价', dataIndex: 'price_str', width: 150, ellipsis: true, className: 'store_manage_table_m' },
//     { title: '成本', dataIndex: 'cost_str', width: 125, ellipsis: true, className: 'store_manage_table_m' },
//     { title: '运输', dataIndex: 'transit_str', width: 100, ellipsis: true, className: 'store_manage_table_m' }
//     // { title: '备注', dataIndex: 'comments', width: 100, ellipsis: true, className: 'store_manage_table_m' },
//     // { title: '原价', dataIndex: 'price_str', width: 150, ellipsis: true, className: 'store_manage_table_m' },
//     // { title: '成本', dataIndex: 'cost_str', width: 125, ellipsis: true, className: 'store_manage_table_m' },
//     // { title: '类型', dataIndex: 'transit_str', width: 125, ellipsis: true, className: 'store_manage_table_m' },
// ];

class TransitPriceManager extends Component {
    constructor(props) {
        super();

        this.strToday = new Date();

        this.state = {
            btnControlDisable: [false, true, true],  // 操作按钮控制
            selectedRowKeys: [],  // Check here to configure the default column
            iDlgType: -1,
            bShowDlg: false,
            bVipDlg: false,
            bLoading: true,
            fTotalPrice: 0.0,
            iTransitType: 0,
            iTransitTypeFilter: 0,
            iUnit: 0,
            expire_date: new Date(),
            iVipPrice: 0,
            iAproxyPrice: 0,
            iFromWhere: 0,
            iToWhere: 0,
            iCityPortFilter: 0
        };
        // 成员变量
        this.arrTableData = [];  // 用于表格的数组
        this.objCurrentTransitPrice = {};
        this.tableHead = arrTableHeader;
        // if (props.bMStation)
        //     this.tableHead = arrTableHeader_m;

        // this.arrTransitTypeFilter = ["全部", "空运", "海运", "平邮(小包裹)", "卡派"];
        this.iTransitTypeFilter = 0;
        // this.arrTransitType = ["空运", "海运", "平邮(小包裹)", "卡派"];
        this.arrTransitType = ["普货", "食品", "敏感货"];
        // this.arrUnit = ["立方", "公斤", "整柜"];

        this.strYear = this.strToday.toISOString().split('-')[0];
        this.strMonth = this.strToday.toISOString().split('-')[1];
        this.strDay = this.strToday.toISOString().split('-')[2].split('T')[0];
        this.strExpireDate = this.strToday.toISOString();

        this.arrVipPrice = [];
        this.arrFromInfo = ["否", "是"];
        this.arrCityPort = [];
        this.arrCityPortFilter = [{ name: '全部' }];
        this.iCityPortFilter = 0;
    }

    // 选中某行的响应函数
    selectRow = (record) => {
        const selectedRowKeys = [...this.state.selectedRowKeys];
        if (selectedRowKeys.indexOf(record.key) >= 0) {
            selectedRowKeys.splice(selectedRowKeys.indexOf(record.key), 1);
        } else {
            selectedRowKeys.push(record.key);
        }
        this.SelectedRowStateSetting(selectedRowKeys);
    }
    // 选中的行发生变化的响应函数
    onSelectedRowKeysChange = (selectedRowKeys) => {
        this.SelectedRowStateSetting(selectedRowKeys);
    }
    // 处理选中的表行行
    SelectedRowStateSetting = (selectedRowKeys) => {
        let btnControlDisable = [];
        switch (selectedRowKeys.length) {
            case 0: {
                btnControlDisable = [false, true, true];
                this.objCurrentTransitPrice = {};
                break;
            }
            case 1: {
                btnControlDisable = [false, false, false];
                for (let i = 0; i < this.arrTableData.length; i++) {
                    if (this.arrTableData[i].key === selectedRowKeys[0]) {
                        this.objCurrentTransitPrice = this.arrTableData[i];
                        break;
                    }
                }
                break;
            }
            default: {
                btnControlDisable = [false, true, true]; break;
            }
        }
        this.setState({
            ...this.state,
            selectedRowKeys,
            btnControlDisable: btnControlDisable
        });
    }
    //弹出对话框
    ShowDlg = (iDlgType, e) => {
        if (iDlgType === 0) {
            if (this.refs.price !== undefined) {
                // this.refs.comments.state.value = "";
                this.refs.price.state.value = "";
                this.refs.discount.state.value = "";
                // this.refs.cost.state.value = "";
            } else {
                // this.objCurrentTransitPrice = { comments: '', price: '', cost: '', transit_type: 0, unit: 0 };
                this.objCurrentTransitPrice = { price: 0.00, discount: '', transit_type: 0, store_id: this.props.iStoreID };
            }
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true,
                iTransitType: 0,
                // iUnit: 0,
                // expire_date: this.strExpireDate,
                // iFromWhere: 0,
                // iToWhere: 0
            });
        }
        if (iDlgType === 1) {
            if (this.refs.price !== undefined) {
                // this.refs.comments.state.value = this.objCurrentTransitPrice.comments;
                this.refs.price.state.value = this.objCurrentTransitPrice.price;
                this.refs.discount.state.value = this.objCurrentTransitPrice.discount;
                // this.refs.cost.state.value = this.objCurrentTransitPrice.cost;
            }
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true,
                iTransitType: this.objCurrentTransitPrice.transit_type,
                // iUnit: this.objCurrentTransitPrice.unit,
                // expire_date: this.objCurrentTransitPrice.expire_date,
                // iFromWhere: this.objCurrentTransitPrice.from_where,
                // iToWhere: this.objCurrentTransitPrice.to_where
            });
        }
        if (iDlgType === 3) {
            if (this.refs.vip_price !== undefined) {
                this.refs.vip_price.state.value = this.arrVipPrice[0].price;
            }
            if (this.refs.aproxy_price !== undefined) {
                this.refs.aproxy_price.state.value = this.arrVipPrice[1].price;
            }
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bVipDlg: true,
                iVipPrice: this.arrVipPrice[0].price,
                iAproxyPrice: this.arrVipPrice[1].price
            });
        }
        if (iDlgType === 2) {
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true
            });
        }
    }
    // 弹窗确认按钮响应
    handleOk = e => {
        // VIP / 代理价格设置
        // if (this.state.iDlgType === 3) {
        //     if (this.refs.vip_price) {
        //         this.arrVipPrice[0].price = this.refs.vip_price.state.value;
        //     }
        //     if (this.refs.aproxy_price) {
        //         this.arrVipPrice[1].price = this.refs.aproxy_price.state.value;
        //     }
        //     message.loading({ content: '正在更新VIP / 代理价格信息……', key: g_strMessageKey });
        //     axios.post('/UpdateTransitPrice', {
        //         objTransitPrice: this.arrVipPrice[0]
        //     }).then(({ data }) => {
        //         axios.post('/UpdateTransitPrice', {
        //             objTransitPrice: this.arrVipPrice[1]
        //         }).then(({ data }) => {
        //             this.GetTransitPriceData();
        //         }).catch(function (error) { console.log(error); });
        //     }).catch(function (error) { console.log(error); });
        //     return;
        // }

        delete this.objCurrentTransitPrice.key;
        if (this.state.iDlgType === 0) {
            delete this.objCurrentTransitPrice.id;
            // let strComments = this.refs.comments.state.value;
            let strPrice = this.refs.price.state.value;
            // let strCost = this.refs.cost.state.value;
            // if (strComments === null || strComments.trim() === "") {
            //     strComments = "";
            // }
            if (strPrice === null || strPrice.trim() === "") {
                message.warning("单价不能为空！"); return;
            }
            // if (strCost) {
            //     if (strCost === null || strCost.trim() === "") {
            //         strCost = 0;
            //     }
            // } else
            //     strCost = 0;
            // this.objCurrentTransitPrice.comments = strComments;
            this.objCurrentTransitPrice.price = strPrice;
            this.objCurrentTransitPrice.discount = this.refs.discount.state.value;
            // this.objCurrentTransitPrice.cost = strCost;
            // this.objCurrentTransitPrice.unit = this.state.iUnit;
            this.objCurrentTransitPrice.transit_type = this.state.iTransitType;
            // this.objCurrentTransitPrice.expire_date = this.state.expire_date;
            // this.objCurrentTransitPrice.from_where = this.state.iFromWhere;
            // this.objCurrentTransitPrice.to_where = this.state.iToWhere;
            delete this.objCurrentTransitPrice.key;
            delete this.objCurrentTransitPrice.price_str;
            delete this.objCurrentTransitPrice.discount_str;
            // delete this.objCurrentTransitPrice.cost_str;
            delete this.objCurrentTransitPrice.transit_str;
            // delete this.objCurrentTransitPrice.from_str;
            // delete this.objCurrentTransitPrice.to_str;

            // this.iTransitTypeFilter = 0;
            // this.setState({
            //     ...this.state,
            //     iTransitTypeFilter: 0
            // })
            this.objCurrentTransitPrice.store_id = this.props.iStoreID;
            console.log("new price: ", this.objCurrentTransitPrice);
            message.loading({ content: '正在更新价格信息……', key: g_strMessageKey });
            axios.post('/NewTransitPrice', {
                objTransitPrice: this.objCurrentTransitPrice
            }).then(({ data }) => {
                this.GetTransitPriceData();
            }).catch(function (error) { console.log(error); });
        }
        if (this.state.iDlgType === 1) {
            // let strComments = this.refs.comments.state.value;
            let strPrice = this.refs.price.state.value;
            // let strCost = this.refs.cost.state.value;
            // if (strComments === null || strComments.trim() === "") {
            //     strComments = "";
            // }
            if (strPrice === null || strPrice.trim() === "") {
                message.warning("价格不能为空！"); return;
            }
            // if (strCost) {
            //     if (strCost === null || strCost.trim() === "") {
            //         strCost = 0;
            //     }
            // } else
            //     strCost = 0;
            // this.objCurrentTransitPrice.comments = strComments;
            this.objCurrentTransitPrice.price = strPrice;
            this.objCurrentTransitPrice.discount = this.refs.discount.state.value;
            // this.objCurrentTransitPrice.cost = strCost;
            // this.objCurrentTransitPrice.unit = this.state.iUnit;
            this.objCurrentTransitPrice.transit_type = this.state.iTransitType;
            this.objCurrentTransitPrice.expire_date = this.state.expire_date;
            // this.objCurrentTransitPrice.from_where = this.state.iFromWhere;
            // this.objCurrentTransitPrice.to_where = this.state.iToWhere;
            delete this.objCurrentTransitPrice.key;
            delete this.objCurrentTransitPrice.price_str;
            delete this.objCurrentTransitPrice.discount_str;
            // delete this.objCurrentTransitPrice.cost_str;
            delete this.objCurrentTransitPrice.transit_str;
            // delete this.objCurrentTransitPrice.from_str;
            // delete this.objCurrentTransitPrice.to_str;
            this.objCurrentTransitPrice.store_id = this.props.iStoreID;

            message.loading({ content: '正在更新价格信息……', key: g_strMessageKey });
            axios.post('/UpdateTransitPrice', {
                objTransitPrice: this.objCurrentTransitPrice
            }).then(({ data }) => {
                message.success({ content: "价格已更新！", key: g_strMessageKey, duration: 2 });
                this.GetTransitPriceData();
            }).catch(function (error) { console.log(error); });
        }
        if (this.state.iDlgType === 2) {
            message.loading({ content: '价格删除中……', key: g_strMessageKey });
            axios.post('/DeleteTransitPrice', { id: this.objCurrentTransitPrice.id }).then(
                ({ data }) => {
                    this.GetTransitPriceData();
                }).catch(function (error) { console.log(error); });
        }
    };
    // 弹窗取消按钮响应
    handleCancel = e => {
        this.objCurrentTransitPrice = {};
        this.setState({
            bShowDlg: false,
            bVipDlg: false,
            selectedRowKeys: [],
            btnControlDisable: [false, true, true]
        });
    };
    // 从数据库获取当前的数据结构
    GetTransitPriceData() {
        message.loading({ content: '数据加载中……', key: g_strMessageKey });
        // axios('/GetTransitPrice', { params: { transit_type: this.iTransitTypeFilter - 1, city_port: this.iCityPortFilter - 1 } })
        // console.log("store id : ", this.props.iStoreID);
        axios('/GetTransitPrice', { params: { store_id: this.props.iStoreID } })
            .then(({ data }) => {
                console.log("Get Transit Price : ", data);
                this.arrTableData = data;
                if (this.arrTableData.length > 0) {
                    for (let i = 0; i < this.arrTableData.length; i++) {
                        this.arrTableData[i].key = i;
                        // this.arrTableData[i].update_date = this.arrTableData[i].update_date.split('T')[0];
                        this.arrTableData[i].price_str = "￥ " + parseFloat(this.arrTableData[i].price).toFixed(2) + " / kg";// + this.arrUnit[this.arrTableData[i].unit];
                        // this.arrTableData[i].cost_str = "￥ " + parseFloat(this.arrTableData[i].cost).toFixed(2);
                        this.arrTableData[i].discount_str = this.arrTableData[i].discount + "%";
                        this.arrTableData[i].transit_str = this.arrTransitType[this.arrTableData[i].transit_type];
                        // this.arrTableData[i].from_str = this.arrCityPort[this.arrTableData[i].from_where].name;
                        // this.arrTableData[i].to_str = this.arrCityPort[this.arrTableData[i].to_where].name;
                        if (this.arrTableData[i].expire_date !== null)
                            this.arrTableData[i].expire_date = this.arrTableData[i].expire_date.split('T')[0];
                        else
                            this.arrTableData[i].expire_date = this.strExpireDate.toISOString().split('T')[0];
                    }
                }
                if (this.state.bShowDlg) {
                    if (this.state.iDlgType === 0) {
                        message.success({ content: "新增价格完成！", key: g_strMessageKey, duration: 2 });
                    }
                    if (this.state.iDlgType === 1) {
                        message.success({ content: "价格已更新！", key: g_strMessageKey, duration: 2 });
                    }
                    if (this.state.iDlgType === 2) {
                        message.success({ content: "价格已删除！", key: g_strMessageKey, duration: 2 })
                    }
                } else {
                    message.success({ content: "数据加载完成！", key: g_strMessageKey, duration: 2 });
                }
                this.objCurrentTransitPrice = {};
                this.setState({
                    ...this.state,
                    btnControlDisable: [false, true, true],
                    bLoading: false,
                    bShowDlg: false,
                    // bVipDlg: false,
                    selectedRowKeys: [],
                    // iVipPrice: this.arrVipPrice[0].price,
                    // iAproxyPrice: this.arrVipPrice[1].price
                });
                // axios('/GetVipTransitPrice')
                //     .then(({ data }) => {
                //         if (data.length > 0) {
                //             this.arrVipPrice = data;
                //             this.setState({
                //                 ...this.state,
                //                 btnControlDisable: [false, true, true],
                //                 bLoading: false,
                //                 bShowDlg: false,
                //                 bVipDlg: false,
                //                 selectedRowKeys: [],
                //                 iVipPrice: this.arrVipPrice[0].price,
                //                 iAproxyPrice: this.arrVipPrice[1].price
                //             });
                //         } else {
                //             this.setState({
                //                 ...this.state,
                //                 btnControlDisable: [false, true, true],
                //                 bLoading: false,
                //                 bShowDlg: false,
                //                 bVipDlg: false,
                //                 selectedRowKeys: [],
                //                 iVipPrice: 0,
                //                 iAproxyPrice: 0
                //             });
                //         }
                //     }).catch(function (error) { console.log(error); });
            }).catch(function (error) { console.log(error); });
    }
    // 价格查询
    PriceLookup = () => {
        let volumn = this.refs.volumn.state.value;
        if (volumn.trim() === "") {
            message.warning("请输入有效的数值！");
            return;
        }
        if (parseFloat(volumn) > 0.0) {
            let fVolumn = parseFloat(volumn);
            let fTotalPrice = fVolumn * parseFloat(this.objCurrentTransitPrice.price);
            fTotalPrice += parseFloat(this.objCurrentTransitPrice.cost);
            this.setState({
                ...this.state,
                fTotalPrice: fTotalPrice.toFixed(2)
            });
        } else {
            message.warning("请输入有效的数值！");
            return;
        }
    }
    // 运输方式过滤器切换
    SwitchTransitTypeFilter = (index) => {
        this.setState({
            ...this.state,
            iTransitTypeFilter: index
        });
        this.iTransitTypeFilter = index;
        this.GetTransitPriceData();
    }
    // 运输方式切换
    SwitchTransitType = (index) => {
        this.setState({
            ...this.state,
            iTransitType: index
        });
    }
    // 城市/港口切换
    SwitchCityPort = (index, iFromToFilter) => {
        // 起运港
        if (iFromToFilter === 0) {
            this.setState({
                ...this.state,
                iFromWhere: index
            });
        }
        // 目的港
        if (iFromToFilter === 1) {
            this.setState({
                ...this.state,
                iToWhere: index
            });
        }
        // 过滤器
        if (iFromToFilter === 2) {
            this.setState({
                ...this.state,
                iCityPortFilter: index
            });
            this.iCityPortFilter = index;
            this.GetTransitPriceData();
        }
    }
    // 单位切换
    SwitchUnit = (index) => {
        this.setState({
            ...this.state,
            iUnit: index
        });
    }
    // 获取到期日期
    GetExpireDate = () => {
        let iDay = parseInt(this.strDay, 10);
        if (iDay > 15) {
            switch (this.strMonth) {
                case '01': iDay = 31; break;
                case '02': iDay = 28; break;
                case '03': iDay = 31; break;
                case '04': iDay = 30; break;
                case '05': iDay = 31; break;
                case '06': iDay = 30; break;
                case '07': iDay = 31; break;
                case '08': iDay = 31; break;
                case '09': iDay = 30; break;
                case '10': iDay = 31; break;
                case '11': iDay = 30; break;
                case '12': iDay = 31; break;
                default: break;
            }
        } else
            iDay = 15;
        // this.strExpireDate = new Date(this.strYear + "-" + this.strMonth + "-" + iDay + " 23:59:59");
        this.strExpireDate = this.strYear + "-" + this.strMonth + "-" + iDay;
        this.setState({
            ...this.state,
            expire_date: this.strExpireDate
        });
    }
    // 到期日期更新
    ExpireDateChange = (date, dateString) => {
        this.setState({
            ...this.state,
            expire_date: dateString
        });
    }
    // 获取城市/港口信息
    GetCityPort = () => {
        axios('/GetCityPort', { params: { strOrder: 'asc' } })
            .then(({ data }) => {
                this.arrCityPort = data;
                this.arrCityPortFilter = this.arrCityPortFilter.concat(this.arrCityPort);
                this.GetTransitPriceData();
            }).catch(function (error) { console.log(error); });
        this.setState({
            ...this.state,
            iFromWhere: 0,
        });
    };

    /* React自带函数 */
    // 卸载异步操作设置状态
    componentWillUnmount() {
        clearTimeout(this.timeouter)
        this.setState = (state, callback) => {
            return;
        }
    }
    // 渲染完毕……
    componentDidMount() {
        // this.GetExpireDate();
        // this.GetCityPort();
        this.GetTransitPriceData();
    }
    // 渲染函数
    render() {

        /* 用户管理页面控制栏 */
        let strNewButtonStyle = { backgroundColor: 'lightseagreen', color: 'white' };
        let strAreaStyle = "store_manage_contents store_manage_top_no_space";
        let strLoadingStyle = "manage_empty_loading";
        let uiTitleArea = null;
        // 管理员
        // let uiSelectedUnit = null;
        // if (this.objCurrentTransitPrice.unit >= 0)
        //     uiSelectedUnit = (
        //         <div className="transit_price_lookup_text"><b>{this.arrUnit[this.objCurrentTransitPrice.unit]}</b></div>
        //     );
        // const menuTransitTypeFilter = (
        //     <Menu>
        //         {this.arrTransitTypeFilter.map((item, index) => {
        //             return (
        //                 <Menu.Item key={index} onClick={this.SwitchTransitTypeFilter.bind(this, index)}>
        //                     {item}
        //                 </Menu.Item>
        //             );
        //         })}
        //     </Menu>
        // );
        // const menuCityPortFilter = (
        //     <Menu>
        //         {this.arrCityPortFilter.map((item, index) => {
        //             return (
        //                 <Menu.Item key={index} onClick={this.SwitchCityPort.bind(this, index, 2)}>
        //                     {item.name}
        //                 </Menu.Item>
        //             );
        //         })}
        //     </Menu>
        // );
        if (this.props.iUserLevel >= 9) {
            strAreaStyle = "store_manage_contents store_manage_top_space";
            // strAreaStyle = "store_manage_contents store_manage_top_space_trible";
            // let strVipPrice = 0.0;
            // let strAproxyPrice = 0.0;
            // if (this.arrVipPrice.length > 0) {
            //     strVipPrice = this.state.fTotalPrice * parseFloat(this.arrVipPrice[0].price) / 100.0;
            //     strAproxyPrice = this.state.fTotalPrice * parseFloat(this.arrVipPrice[1].price) / 100.0;
            // }
            // strVipPrice = strVipPrice.toFixed(2);
            // strAproxyPrice = strAproxyPrice.toFixed(2);

            uiTitleArea = (
                <div>
                    {/* <div className="store_contents_title" >
                        <div className="transit_price_title">运输</div>
                        <Dropdown className="order_detail_dropdown" trigger={['click']}
                            overlay={menuTransitTypeFilter} placement="bottomLeft">
                            <Button className="transit_type_filter_dropdown_button">
                                <div className="order_details_dropdown_text">{this.arrTransitTypeFilter[this.state.iTransitTypeFilter]}</div>
                                <Icon type="caret-down" />
                            </Button>
                        </Dropdown>
                        <div className="transit_price_title">港口</div>
                        <Dropdown className="order_detail_dropdown" trigger={['click']}
                            overlay={menuCityPortFilter} placement="bottomLeft">
                            <Button className="transit_type_filter_dropdown_button">
                                <div className="order_details_dropdown_text">{this.arrCityPortFilter[this.state.iCityPortFilter].name}</div>
                                <Icon type="caret-down" />
                            </Button>
                        </Dropdown>
                    </div >
                    <div className="store_contents_title_sec" >
                        <div className="transit_price_lookup_area">
                            <div className="transit_price_lookup_text">选择一个<b>地区</b>，并输入：</div>
                            <Input className="transit_unit_input" ref="volumn" defaultValue={"0"} /> {uiSelectedUnit}
                            <Button className="transit_price_lookup_button" type="primary" onClick={this.PriceLookup} disabled={this.state.btnControlDisable[1]} > 查 询</Button>
                            <div className="transit_price_lookup_result">
                                {"预估原价 = $ " + this.state.fTotalPrice + "， VIP价格 = $ " + strVipPrice + "， 代理价格 = $ " + strAproxyPrice}
                            </div>
                        </div>
                    </div> */}
                    <div className="store_contents_title" >
                        {/* <div className="store_contents_title_thd" > */}
                        <Button className="manage_contents_title_margin" style={strNewButtonStyle}
                            onClick={this.ShowDlg.bind(this, 0)} disabled={this.state.btnControlDisable[0]}>
                            <Icon type="plus" />新建</Button>
                        <Button className="manage_contents_title_margin" type="primary" onClick={this.ShowDlg.bind(this, 1)} disabled={this.state.btnControlDisable[1]}>
                            <Icon type="edit" /> 编辑</Button>
                        <Button className="manage_contents_title_margin" type="danger" onClick={this.ShowDlg.bind(this, 2)} disabled={this.state.btnControlDisable[2]}>
                            <Icon type="close" /> 删除</Button>
                        {/* <Button className="manage_contents_title_margin" type="primary"
                            onClick={this.ShowDlg.bind(this, 3)} disabled={this.state.btnControlDisable[0]}>
                            <Icon type="star" />{"VIP & 代理价格设置"}</Button> */}
                    </div>
                </div>
            );
        }
        // 代理
        // else {
        //     strAreaStyle = "store_manage_contents store_manage_top_space";
        //     let strAproxyPrice = 0.0;
        //     if (this.arrVipPrice.length > 0) {
        //         strAproxyPrice = this.state.fTotalPrice * parseFloat(this.arrVipPrice[1].price) / 100.0;
        //     }
        //     strAproxyPrice = strAproxyPrice.toFixed(2);
        //     uiTitleArea = (
        //         <div className="store_contents_title" >
        //             <div className="transit_price_title">运输</div>
        //             <Dropdown className="order_detail_dropdown" trigger={['click']}
        //                 overlay={menuTransitTypeFilter} placement="bottomLeft">
        //                 <Button className="transit_type_filter_dropdown_button">
        //                     <div className="order_details_dropdown_text">{this.arrTransitTypeFilter[this.state.iTransitTypeFilter]}</div>
        //                     <Icon type="caret-down" />
        //                 </Button>
        //             </Dropdown>
        //             <div className="transit_price_title">港口</div>
        //             <Dropdown className="order_detail_dropdown" trigger={['click']}
        //                 overlay={menuCityPortFilter} placement="bottomLeft">
        //                 <Button className="transit_type_filter_dropdown_button">
        //                     <div className="order_details_dropdown_text">{this.arrCityPortFilter[this.state.iCityPortFilter].name}</div>
        //                     <Icon type="caret-down" />
        //                 </Button>
        //             </Dropdown>
        //             <div className="transit_price_lookup_area">
        //                 <div className="transit_price_lookup_text">选择一个<b>地区</b>，并输入：</div>
        //                 <Input className="transit_unit_input" ref="volumn" defaultValue={"0"} /> {uiSelectedUnit}
        //                 <Button className="transit_price_lookup_button" type="primary" onClick={this.PriceLookup} disabled={this.state.btnControlDisable[1]} > 查 询</Button>
        //                 <div className="transit_price_lookup_result">
        //                     {"预估原价 = $ " + this.state.fTotalPrice + "， 代理价格 = $ " + strAproxyPrice}
        //                 </div>
        //             </div>
        //         </div >
        //     );
        // }

        // M站针对加载状态时的界面处理
        if (this.props.bMStation) {
            uiTitleArea = null;
            strLoadingStyle = "manage_empty_loading_m";
        }

        // 如果界面上需要显示的内容已经从数据库获得
        if (!this.state.bLoading) {
            // 主体表格相关
            const { selectedRowKeys } = this.state;
            const rowSelection = {
                selectedRowKeys,
                onChange: this.onSelectedRowKeysChange,
            };
            // 表格分页属性
            const paginationProps = {
                showSizeChanger: true,
                showQuickJumper: false,
                showTotal: () => `共 ${this.arrTableData.length} 条 价格信息`,
                defaultPageSize: 10,
                defaultCurrent: this.state.iCurrentPage,
                pageSizeOptions: ['10', '20', '30', '40', '50'],
                total: this.arrTableData.length,
                position: 'bottom'
            };
            // 用户信息编辑弹窗
            let strTitle = "新建价格";
            if (this.state.iDlgType === 1)
                strTitle = "编辑价格";
            const menuTransitType = (
                <Menu>
                    {this.arrTransitType.map((item, index) => {
                        return (
                            <Menu.Item key={index} onClick={this.SwitchTransitType.bind(this, index)}>
                                {item}
                            </Menu.Item>
                        );
                    })}
                </Menu>
            );
            // const menuUnit = (
            //     <Menu>
            //         {this.arrUnit.map((item, index) => {
            //             return (
            //                 <Menu.Item key={index} onClick={this.SwitchUnit.bind(this, index)}>
            //                     {item}
            //                 </Menu.Item>
            //             );
            //         })}
            //     </Menu>
            // );
            // const menuCityPortFrom = (
            //     <Menu>
            //         {this.arrCityPort.map((item, index) => {
            //             return (
            //                 <Menu.Item key={index} onClick={this.SwitchCityPort.bind(this, index, 0)}>
            //                     {item.name}
            //                 </Menu.Item>
            //             );
            //         })}
            //     </Menu>
            // );
            // const menuCityPortTo = (
            //     <Menu>
            //         {this.arrCityPort.map((item, index) => {
            //             return (
            //                 <Menu.Item key={index} onClick={this.SwitchCityPort.bind(this, index, 1)}>
            //                     {item.name}
            //                 </Menu.Item>
            //             );
            //         })}
            //     </Menu>
            // );
            // const dateFormat = 'YYYY/MM/DD';
            let uiModal = (
                <Modal title={strTitle} width={335} visible={this.state.bShowDlg}
                    okText="确定" cancelText="取消" onOk={this.handleOk} onCancel={this.handleCancel} >
                    {/* <div className="user_edit_info_row">
                        <div className="transit_price_edit_title">起运：</div>
                        <Dropdown overlay={menuCityPortFrom} trigger={['click']}>
                            <Button className="transit_type_dropdown_button">
                                <div className="order_details_dropdown_text">{this.arrCityPort[this.state.iFromWhere].name}</div>
                                <Icon type="caret-down" />
                            </Button>
                        </Dropdown>
                    </div>
                    <div className="user_edit_info_row">
                        <div className="transit_price_edit_title">目的：</div>
                        <Dropdown overlay={menuCityPortTo} trigger={['click']}>
                            <Button className="transit_type_dropdown_button">
                                <div className="order_details_dropdown_text">{this.arrCityPort[this.state.iToWhere].name}</div>
                                <Icon type="caret-down" />
                            </Button>
                        </Dropdown>
                    </div>
                    <div className="user_edit_info_row">
                        <div className="transit_price_edit_title">备注：</div>
                        <Input className="user_edit_info_input" ref="comments"
                            defaultValue={this.objCurrentTransitPrice.comments} />
                    </div> */}
                    <div className="user_edit_info_row">
                        <div className="transit_price_edit_title">单价：</div>
                        <Input className="user_edit_info_input" ref="price" addonBefore="￥ " addonAfter="/ kg"
                            // addonAfter={
                            // <Dropdown overlay={menuUnit} trigger={['click']}>
                            //     <Button className="transit_unit_dropdown_button">
                            //         <div className="order_details_dropdown_text">{this.arrUnit[this.state.iUnit]}</div>
                            //         <Icon type="caret-down" />
                            //     </Button>
                            // </Dropdown>}
                            defaultValue={this.objCurrentTransitPrice.price} />
                    </div>
                    <div className="user_edit_info_row">
                        <div className="transit_price_edit_title">折扣：</div>
                        <Input className="user_edit_info_input" ref="discount" addonAfter="%"
                            // addonAfter={
                            // <Dropdown overlay={menuUnit} trigger={['click']}>
                            //     <Button className="transit_unit_dropdown_button">
                            //         <div className="order_details_dropdown_text">{this.arrUnit[this.state.iUnit]}</div>
                            //         <Icon type="caret-down" />
                            //     </Button>
                            // </Dropdown>}
                            defaultValue={this.objCurrentTransitPrice.discount} />
                    </div>
                    {/* <div className="user_edit_info_row">
                        <div className="transit_price_edit_title">成本：</div>
                        <Input className="user_edit_info_input" ref="cost" addonBefore="$ "
                            defaultValue={this.objCurrentTransitPrice.cost} />
                    </div> */}
                    <div className="user_edit_info_row">
                        <div className="transit_price_edit_title">类别：</div>
                        <Dropdown overlay={menuTransitType} trigger={['click']}>
                            <Button className="transit_type_dropdown_button">
                                <div className="order_details_dropdown_text">{this.arrTransitType[this.state.iTransitType]}</div>
                                <Icon type="caret-down" />
                            </Button>
                        </Dropdown>
                    </div>
                    {/* <div className="user_edit_info_row">
                        <div className="transit_price_edit_title">卡派：</div>
                        <Radio.Group onChange={this.OnFromOption} value={this.state.iFromWhere} defaultValue={0}>
                            <Radio value={1}>是</Radio>
                            <Radio value={0}>否</Radio>
                        </Radio.Group>
                    </div> */}
                    {/* <div className="user_edit_info_row">
                        <div className="transit_price_edit_title">有效期：</div>
                        <DatePicker className="transit_expire_date_pickup" onChange={this.ExpireDateChange}
                            value={moment(this.state.expire_date, dateFormat)}
                            defaultValue={moment(this.strExpireDate, dateFormat)} />
                    </div> */}
                </Modal>
            );
            if (this.state.iDlgType === 2) {
                uiModal = (
                    <Modal width={350} title={"删除价格"} visible={this.state.bShowDlg} okType="danger"
                        onOk={this.handleOk} okText="确定" onCancel={this.handleCancel} cancelText="取消">
                        <div className="modal_item">
                            <div>{"类别：" + this.objCurrentTransitPrice.transit_str + ", 价格：" + this.objCurrentTransitPrice.price}
                                <br /><br />确定删除选中的价格？
                            </div>
                        </div>
                    </Modal>
                );
            }
            // let uiVipModal = null;
            // if (this.state.iDlgType === 3) {
            //     uiVipModal = (
            //         <Modal width={400} title={"设置VIP&代理价格"} visible={this.state.bVipDlg}
            //             onOk={this.handleOk} okText="确定" onCancel={this.handleCancel} cancelText="取消">
            //             <div className="user_edit_info_row">
            //                 <div className="transit_vip_price_edit_title">  {"vip 价格 = 原价 × "}</div>
            //                 <Input className="user_edit_info_input" ref="vip_price" addonAfter="%"
            //                     defaultValue={this.state.iVipPrice} />
            //             </div>
            //             <div className="user_edit_info_row">
            //                 <div className="transit_vip_price_edit_title "> {"代理价格 = 原价 × "}</div>
            //                 <Input className="user_edit_info_input" ref="aproxy_price" addonAfter="%"
            //                     defaultValue={this.state.iAproxyPrice} />
            //             </div>
            //         </Modal>
            //     );
            // }
            let uiTable = (
                <Table style={{ marginLeft: '20px', marginRight: '20px', width: '50%', overflowX: 'auto' }}
                    rowSelection={rowSelection} pagination={paginationProps}
                    columns={this.tableHead} dataSource={this.arrTableData} bordered
                    onRow={(record) => ({ onClick: () => { this.selectRow(record); } })} />
            );

            // M站布局
            // if (this.props.bMStation) {
            //     strAreaStyle = "store_manage_contents_m";
            //     let uiExpireDate = null;
            //     if (this.objCurrentTransitPrice.expire_date)
            //         uiExpireDate = (
            //             <div className="store_contents_title_m" style={{ color: 'red', fontWeight: 'bold' }} >
            //                 {"有效期：" + this.objCurrentTransitPrice.expire_date}</div>
            //         );

            //     if (this.props.iUserLevel >= 9) {
            //         let strVipPrice = 0.0;
            //         let strAproxyPrice = 0.0;
            //         if (this.arrVipPrice.length > 0) {
            //             strVipPrice = this.state.fTotalPrice * parseFloat(this.arrVipPrice[0].price) / 100.0;
            //             strAproxyPrice = this.state.fTotalPrice * parseFloat(this.arrVipPrice[1].price) / 100.0;
            //         }
            //         strVipPrice = strVipPrice.toFixed(2);
            //         strAproxyPrice = strAproxyPrice.toFixed(2);
            //         uiTitleArea = (
            //             <div>
            //                 <div className="store_contents_title_m" >
            //                     <Input className="transit_price_lookup_input_m" ref="volumn" defaultValue={"0"} />
            //                     <div className="transit_price_text_m" style={{ color: 'white' }}> {uiSelectedUnit}</div>
            //                     <Button className="store_manager_control_button" type="primary" onClick={this.PriceLookup} disabled={this.state.btnControlDisable[1]} >
            //                         <Icon type="search" />
            //                     </Button>
            //                     <div className="transit_price_lookup_result_m">{"预估原价 = $ " + this.state.fTotalPrice}</div>
            //                 </div >
            //                 <div className="store_contents_title_m" >
            //                     <div className="transit_price_lookup_result_m">{"VIP价格 = $ " + strVipPrice}</div>
            //                     <div className="transit_price_lookup_result_m">{"，代理价格 = $ " + strAproxyPrice}</div>
            //                 </div >
            //                 {uiExpireDate}
            //                 <div className="store_contents_title_m" >
            //                     <div className="transit_price_text_m" style={{ color: 'white', marginRight: '1.5vw' }}>运输</div>
            //                     <Dropdown className="order_detail_dropdown" trigger={['click']}
            //                         overlay={menuTransitTypeFilter} placement="bottomLeft">
            //                         <Button className="transit_type_filter_dropdown_button">
            //                             <div className="order_details_dropdown_text">{this.arrTransitTypeFilter[this.state.iTransitTypeFilter]}</div>
            //                             <Icon type="caret-down" />
            //                         </Button>
            //                     </Dropdown>
            //                     <div className="transit_price_text_m" style={{ color: 'white', marginRight: '1.5vw' }}>城市</div>
            //                     <Dropdown className="order_detail_dropdown" trigger={['click']}
            //                         overlay={menuCityPortFilter} placement="bottomLeft">
            //                         <Button className="transit_type_filter_dropdown_button">
            //                             <div className="order_details_dropdown_text">
            //                                 {this.arrCityPortFilter[this.state.iCityPortFilter].name}
            //                             </div>
            //                             <Icon type="caret-down" />
            //                         </Button>
            //                     </Dropdown>
            //                 </div>
            //                 <div className="store_contents_title_m" >
            //                     <Button className="store_manager_control_button" style={strNewButtonStyle}
            //                         onClick={this.ShowDlg.bind(this, 0)} disabled={this.state.btnControlDisable[0]}>
            //                         <Icon type="plus" /></Button>
            //                     <Button className="store_manager_control_button" type="primary" onClick={this.ShowDlg.bind(this, 1)} disabled={this.state.btnControlDisable[1]}>
            //                         <Icon type="edit" /></Button>
            //                     <Button className="store_manager_control_button" type="danger" onClick={this.ShowDlg.bind(this, 2)} disabled={this.state.btnControlDisable[2]}>
            //                         <Icon type="close" /></Button>
            //                     <Button className="store_manager_control_button" type="primary"
            //                         onClick={this.ShowDlg.bind(this, 3)} disabled={this.state.btnControlDisable[0]}>
            //                         <Icon type="star" /></Button>
            //                 </div>
            //             </div>
            //         );
            //     } else {
            //         let strAproxyPrice = 0.0;
            //         if (this.arrVipPrice.length > 0) {
            //             strAproxyPrice = this.state.fTotalPrice * parseFloat(this.arrVipPrice[1].price) / 100.0;
            //         }
            //         strAproxyPrice = strAproxyPrice.toFixed(2);
            //         uiTitleArea = (
            //             <div>
            //                 <div className="store_contents_title_m" >
            //                     <Input className="transit_price_lookup_input_m" ref="volumn" defaultValue={"0"} />
            //                     <div className="transit_price_text_m" style={{ color: 'white' }}> {uiSelectedUnit}</div>
            //                     <Button className="store_manager_control_button" type="primary" onClick={this.PriceLookup} disabled={this.state.btnControlDisable[1]} >
            //                         <Icon type="search" />
            //                     </Button>
            //                 </div >
            //                 <div className="store_contents_title_m" >
            //                     <div className="transit_price_lookup_result_m">{"预估原价 = $ " + this.state.fTotalPrice}</div>
            //                     <div className="transit_price_lookup_result_m">{"代理价格 = $ " + strAproxyPrice}</div>
            //                 </div >
            //                 {uiExpireDate}
            //                 <div className="store_contents_title_m" >
            //                     <div className="transit_price_text_m" style={{ color: 'white', marginRight: '1.5vw' }}>运输</div>
            //                     <Dropdown className="order_detail_dropdown" trigger={['click']}
            //                         overlay={menuTransitTypeFilter} placement="bottomLeft">
            //                         <Button className="transit_type_filter_dropdown_button">
            //                             <div className="order_details_dropdown_text">{this.arrTransitTypeFilter[this.state.iTransitTypeFilter]}</div>
            //                             <Icon type="caret-down" />
            //                         </Button>
            //                     </Dropdown>
            //                     <div className="transit_price_text_m" style={{ color: 'white', marginRight: '1.5vw' }}>城市</div>
            //                     <Dropdown className="order_detail_dropdown" trigger={['click']}
            //                         overlay={menuCityPortFilter} placement="bottomLeft">
            //                         <Button className="transit_type_filter_dropdown_button">
            //                             <div className="order_details_dropdown_text">
            //                                 {this.arrCityPortFilter[this.state.iCityPortFilter].name}
            //                             </div>
            //                             <Icon type="caret-down" />
            //                         </Button>
            //                     </Dropdown>
            //                 </div>
            //             </div>
            //         );
            //     }
            //     uiTable = (
            //         <Table style={{ /*width: '100vw', height: '100vh',*/ overflowX: 'scroll', overflowY: 'scroll' }} bordered
            //             rowSelection={rowSelection} pagination={paginationProps}
            //             columns={this.tableHead} dataSource={this.arrTableData}
            //             onRow={(record) => ({ onClick: () => { this.selectRow(record); } })} />
            //     );
            // }

            return (
                <div className={strAreaStyle}>
                    {uiTitleArea}
                    {uiModal}
                    {/* {uiVipModal} */}
                    {uiTable}
                </div >
            );

        } else {
            return (
                <div className={strAreaStyle}>
                    {uiTitleArea}
                    <Spin className={strLoadingStyle} size="large" tip="加载中……" />
                </div>
            );
        }
    }
}
export default TransitPriceManager;
