import React, { Component } from 'react';
import axios from 'axios';

import Spin from 'antd/es/spin';
import Table from 'antd/es/table';
import Button from 'antd/es/button';
import Input from 'antd/es/input';
import Icon from 'antd/es/icon';
import Modal from 'antd/es/modal';

import CPExpressDlg from './CPExpressDlg.js';
import UPSExpressDlg from './UPSExpressDlg.js';

import message from 'antd/es/message';
import '../../../styles/Background/ego_manager.css';
import '../../../styles/Background/UI_Module/user_management.css';

const g_strMessageKey = 'updating';

// 表格
const arrTableHeader = [
    // { title: 'id', dataIndex: 'id', width: 35, ellipsis: true },
    { title: '订单编号', dataIndex: 'oid', width: 75, ellipsis: true },
    { title: '订单状态', dataIndex: 'status_str', width: 50, ellipsis: true },
    { title: '快递单号', dataIndex: 'tracking_no', width: 75, ellipsis: true },
    { title: '承运商 - 快递方式', dataIndex: 'express_name', width: 75, ellipsis: true },
    { title: '报价（$CAD）', dataIndex: 'final_price', width: 50, ellipsis: true },
    { title: '创建日期', dataIndex: 'create_date_str', width: 50, ellipsis: true },
    { title: '备注', dataIndex: 'comments', width: 225, ellipsis: true },
];

class JetonExpressOrder extends Component {
    constructor(props) {
        super();
        this.state = {
            btnControlDisable: [false, true, true],  // 操作按钮控制
            selectedRowKeys: [],  // Check here to configure the default column
            iDlgType: -1,
            bShowDlg: false,
            bLoading: true
        };

        this.arrStatus = ["正常", "已取消"];

        this.arrExpressServiceInfoList = [
            { icon: "canada_post_icon", name: "Canada Post Expeited Parcel", time: "", price: "" },
            { icon: "canada_post_icon", name: "Canada Post Priority", time: "", price: "" },
            { icon: "canada_post_icon", name: "Canada Post Regular Parcel", time: "", price: "" },
            { icon: "canada_post_icon", name: "Canada Post Xpresspost", time: "", price: "" },
            { icon: "ups_icon", name: "UPS Express", time: "", price: "" },
            { icon: "ups_icon", name: "UPS Express Saver", time: "", price: "" },
            { icon: "ups_icon", name: "UPS Expeditied", time: "", price: "" },
            { icon: "ups_icon", name: "UPS Standard", time: "", price: "" }

        ];
        this.arrUPSExpress = [
            { name: 'UPS Express', id: '01', category: 'Canadian domestic shipments' },
            { name: 'UPS Express Saver', id: '13', category: 'Canadian domestic shipments' },
            { name: 'UPS Expedited', id: '02', category: 'Canadian domestic shipments' },
            { name: 'UPS Standard', id: '11', category: 'Shipments originating in Canada' },
        ];


        // 成员变量
        this.arrOgeTableData = [];
        this.arrTableData = [];  // 用于表格的数组
        this.arrSelectedJetonOrderID = [];
        this.arrSelectedJetonOrderIndex = [];
        this.objCurrentJetonOrder = {};
    }

    // 选中某行的响应函数
    selectRow = (record) => {
        const selectedRowKeys = [...this.state.selectedRowKeys];
        if (selectedRowKeys.indexOf(record.key) >= 0) {
            selectedRowKeys.splice(selectedRowKeys.indexOf(record.key), 1);
        } else {
            selectedRowKeys.push(record.key);
        }
        this.SelectedRowStateSetting(selectedRowKeys);
    }
    // 选中的行发生变化的响应函数
    onSelectedRowKeysChange = (selectedRowKeys) => {
        this.SelectedRowStateSetting(selectedRowKeys);
    }
    // 处理选中的表行行
    SelectedRowStateSetting = (selectedRowKeys) => {
        let btnControlDisable = [];
        let btnCopyPasteDisable = [];
        switch (selectedRowKeys.length) {
            case 0: {
                btnControlDisable = [false, true, true];
                btnCopyPasteDisable = [true, true];
                this.objCurrentJetonOrder = {};
                break;
            }
            case 1: {
                btnControlDisable = [false, false, false];
                btnCopyPasteDisable = [false, true];
                for (let i = 0; i < this.arrTableData.length; i++) {
                    if (this.arrTableData[i].key === selectedRowKeys[0]) {
                        this.objCurrentJetonOrder = this.arrTableData[i];
                        break;
                    }
                }
                break;
            }
            default: {
                btnControlDisable = [false, true, true]; btnCopyPasteDisable = [true, true]; break;
            }
        }
        this.setState({
            ...this.state,
            selectedRowKeys,
            btnControlDisable: btnControlDisable,
            btnCopyPasteDisable: btnCopyPasteDisable
        });
    }
    // 关键词处理
    KeywordsProcess = (strKeywords) => {
        let newKeywords = "";
        for (let i = 0; i < strKeywords.length; i++) {
            if (strKeywords[i] !== " ") {
                if (i === strKeywords.length - 1)
                    newKeywords += strKeywords[i];
                else
                    newKeywords += strKeywords[i] + "%";
            }
        }
        return newKeywords;
    }
    // 搜索运单名
    JetonOrderSearch = () => {
        let strKeywords = this.refs.express_order_search.state.value;
        if (strKeywords === undefined)
            strKeywords = "";
        if (strKeywords.trim() === "") {
            this.GetJetonOrdersData();
        } else {
            this.setState({
                ...this.state,
                bLoading: true,
                selectedRowKeys: [],
                btnControlDisable: [false, true, true]
            });
            axios('/SearchJetonOrder', { params: { name: this.KeywordsProcess(strKeywords) } })
                .then(({ data }) => {
                    this.arrTableData = this.OrganizeJetonOrdersData(data);
                    this.setState({
                        ...this.state,
                        bLoading: false
                    });
                }).catch(function (error) { console.log(error); });
        }
    }
    /* 自定义成员函数 */
    //弹出对话框
    ShowDlg = (iDlgType, e) => {
        // 新建
        if (iDlgType === 0) {
            if (this.refs.express_order !== undefined) {
                this.refs.express_order.state.value = "";
                this.refs.oid.state.value = "";
                this.refs.client.state.value = "";
                this.refs.comments.state.value = "";
            }
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true
            });
        }
        // 编辑/查看
        if (iDlgType === 1) {
            console.log("查看运单 : ", this.objCurrentJetonOrder);
            if (this.objCurrentJetonOrder.express_index === 1)
                this.iSelectedOrderType = this.objCurrentJetonOrder.express_index * 4 + this.objCurrentJetonOrder.express_type;



            if (this.refs.express_order !== undefined) {
                this.refs.express_order.state.value = this.objCurrentJetonOrder.express_order;
                this.refs.oid.state.value = this.objCurrentJetonOrder.oid;
                this.refs.client.state.value = this.objCurrentJetonOrder.client;
                this.refs.comments.state.value = this.objCurrentJetonOrder.comments;
            }
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true
            });
        }
        // 删除
        if (iDlgType === 2) {
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true
            });
        }
    }
    // 弹窗确认按钮响应
    handleOk = e => {
        // 新建/编辑运单
        if (this.state.iDlgType <= 1) {

            let objJetonOrder = {
                store_id: 99999,
                express_order: this.refs.express_order.state.value,
                oid: this.refs.oid.state.value,
                client: this.refs.client.state.value,
                comments: this.refs.comments.state.value,
                create_time: new Date(),
                update_time: new Date()
            }
            if (this.state.iDlgType === 1) {
                objJetonOrder.id = this.objCurrentJetonOrder.id;
                objJetonOrder.create_time = this.objCurrentJetonOrder.create_time;
            }

            console.log("确认下单 : ", objJetonOrder);
            return;

            message.loading({ content: '正在更新运单信息……', key: g_strMessageKey });
            axios.post('/UpdateJetonOrder', {
                objJetonOrder: objJetonOrder
            }).then(({ data }) => {
                if (data.status === 1) {
                    this.GetJetonOrdersData();
                }
                else
                    message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
            }).catch(function (error) { console.log(error); });
        }
        // 删除运单
        if (this.state.iDlgType === 2) {
            message.loading({ content: '运单删除中……', key: g_strMessageKey });
            axios.post('/DeleteJetonOrder', { id: this.objCurrentJetonOrder.id }).then(
                ({ data }) => {
                    if (data.status === -1) {
                        message.warning({
                            content: "删除失败，请重新尝试！",
                            key: g_strMessageKey, duration: 2
                        });
                        this.setState({
                            ...this.state,
                            bLoading: false,
                            bShowDlg: false,
                            selectedRowKeys: []
                        });
                    } else {
                        this.GetJetonOrdersData();
                    }
                }).catch(function (error) { console.log(error); });
        }
    };
    // 弹窗取消按钮响应
    handleCancel = e => {
        this.setState({
            bShowDlg: false,
            iDlgType: -1,
            selectedRowKeys: [],
            btnControlDisable: [false, true, true],  // 操作按钮控制
        });
    };
    // 整理JetonOrders数据
    OrganizeJetonOrdersData(data) {
        let arrTableData = [];
        for (let i = 0; i < data.length; i++) {

            let strExpressInfo = this.arrUPSExpress[data[i].express_type].name;

            let objShipperInfo = null;
            if (data[i].shipper_info !== null && data[i].shipper_info !== undefined && data[i].shipper_info !== "")
                objShipperInfo = JSON.parse(data[i].shipper_info);
            let objReceiverInfo = null;
            if (data[i].receiver_info !== null && data[i].receiver_info !== undefined && data[i].receiver_info !== "")
                objReceiverInfo = JSON.parse(data[i].receiver_info);
            let objPackageInfo = null;
            if (data[i].package_info !== null && data[i].package_info !== undefined && data[i].package_info !== "")
                objPackageInfo = JSON.parse(data[i].package_info);
            let objPriceDetail = null;
            if (data[i].price_detail !== null && data[i].price_detail !== undefined && data[i].price_detail !== "")
                objPriceDetail = JSON.parse(data[i].price_detail);

            let objJetonOrderData = {
                key: i, id: data[i].id, oid: data[i].oid,
                tracking_no: data[i].tracking_no,
                express_name: strExpressInfo,
                express_index: data[i].express_index,
                express_type: data[i].express_type,
                status_str: this.arrStatus[data[i].status],
                create_date_str: data[i].create_time.split('T')[0],
                comments: data[i].comments,
                shipper_info: objShipperInfo,
                receiver_info: objReceiverInfo,
                package_info: objPackageInfo,
                price_detail: objPriceDetail,
                // store_id: this.props.iStoreID,
                // user_id: iUserID,
                // user_name: strUserName,
                // status: 0,
                // client: data[i].client,
                // total_cost: fTotalCost,  // 没有加上不同用户类别折扣率的UPS优惠报价，但是含GST
                // create_time: new Date(),
                // update_time: new Date(),
                // discount: this.objCurrentJetonOrder.discount,
                final_price: data[i].final_price  // 最终价格，加上了折扣率
            }

            arrTableData.push(objJetonOrderData);
        }
        return arrTableData;
    }
    // 从数据库获取当前的数据结构
    GetJetonOrdersData() {
        // message.loading({ content: '数据加载中……', key: g_strMessageKey });
        // axios('/GetJetonOrders')
        //     .then(({ data }) => {
        //         this.arrOgeTableData = this.OrganizeJetonOrdersData(data);
        //         this.arrTableData = this.arrOgeTableData;
        //         if (this.state.bShowDlg) {
        //             if (this.state.iDlgType === 0) {
        //                 message.success({ content: "运单创建成功！", key: g_strMessageKey, duration: 2 });
        //             }
        //             if (this.state.iDlgType === 1) {
        //                 message.success({ content: "运单信息已更新！", key: g_strMessageKey, duration: 2 });
        //             }
        //             if (this.state.iDlgType === 2) {
        //                 message.success({ content: "运单已删除！", key: g_strMessageKey, duration: 2 })
        //             }
        //         } else {
        //             message.success({ content: "数据加载完成！", key: g_strMessageKey, duration: 2 });
        //         }
        //         this.setState({
        //             ...this.state,
        //             bLoading: false,
        //             bShowDlg: false,
        //             selectedRowKeys: [],
        //             btnControlDisable: [false, true, true],  // 操作按钮控制
        //         });
        //     }).catch(function (error) { console.log(error); });
        message.loading({ content: '数据加载中……', key: g_strMessageKey });
        axios('/GetUPSOrders', { params: { store_id: this.props.iStoreID, user_id: this.props.iUserID, level: this.props.iLevel } })
            .then(({ data }) => {
                console.log("GetJetonOrdersData : ", data);
                this.arrOgeTableData = this.OrganizeJetonOrdersData(data);
                this.arrTableData = this.arrOgeTableData;
                if (this.state.bShowDlg) {
                    if (this.state.iDlgType === 0) {
                        message.success({ content: "运单创建成功！", key: g_strMessageKey, duration: 2 });
                    }
                    if (this.state.iDlgType === 1) {
                        message.success({ content: "运单信息已更新！", key: g_strMessageKey, duration: 2 });
                    }
                    if (this.state.iDlgType === 2) {
                        message.success({ content: "运单已取消！", key: g_strMessageKey, duration: 2 })
                    }
                    if (this.state.iDlgType === 3) {
                        this.setState({
                            ...this.state,
                            bLoading: false,
                            selectedRowKeys: [],
                            btnControlDisable: [false, true, true],  // 操作按钮控制
                            bExportExcel: false
                        });
                        return;
                    }
                } else {
                    message.success({ content: "数据加载完成！", key: g_strMessageKey, duration: 2 });
                }
                this.setState({
                    ...this.state,
                    bLoading: false,
                    bShowDlg: false,
                    selectedRowKeys: [],
                    btnControlDisable: [false, true, true],  // 操作按钮控制
                    bExportExcel: false
                });
            }).catch(function (error) { console.log(error); });
    }
    // 显示全部
    ShowAllOrders = () => {
        this.refs.express_order_search.state.value = "";
        this.GetJetonOrdersData();
    }
    // 从数据库获取当前的数据结构
    GetShipperData = () => {
        axios('/GetJetonShippers', { params: { store_id: this.props.iStoreID, user_id: this.props.iUserID, level: this.props.iLevel } })
            .then(({ data }) => {
                global.arrShipperData = data;
                // console.log("GetShipperData : ", data);
            }).catch(function (error) { console.log(error); });
    }
    // 从数据库获取当前的数据结构
    GetReceiverData = () => {
        axios('/GetJetonReceivers', { params: { store_id: this.props.iStoreID, user_id: this.props.iUserID, level: this.props.iLevel } })
            .then(({ data }) => {
                global.arrReceiverData = data;
                // console.log("Get Address Data : ", data);
            }).catch(function (error) { console.log(error); });
    }
    // 显示寄件人对话框
    ShowShipperDlg = (iShow, bShow) => {
        if (iShow === 1) {
            this.setState({
                ...this.state,
                iShipperDlg: iShow,
                bShipperDlg: bShow,
                iCurrentShipperProvince: this.objCurrentShipper.province
            });
            this.CalculatePrice(this.state.iCurrentUnit, this.state.iCurrentUPSExpress);
        } else
            this.setState({
                ...this.state,
                iShipperDlg: iShow,
                bShipperDlg: bShow
            });
    }
    // 选择寄件人
    SelectShipper = (index) => {
        this.objCurrentShipper = global.arrReceiverData[index];
        this.refs.shipper_name.state.value = this.objCurrentShipper.name;
        this.refs.shipper_phone.state.value = this.objCurrentShipper.phone;
        this.refs.shipper_email.state.value = this.objCurrentShipper.email;
        this.refs.shipper_address.state.value = this.objCurrentShipper.address;
        this.refs.shipper_postcode.state.value = this.objCurrentShipper.postcode;
        this.refs.shipper_company.state.value = this.objCurrentShipper.company;
        this.refs.shipper_city.state.value = this.objCurrentShipper.city;
        this.objCurrentJetonOrder.shipper_postcode = this.objCurrentShipper.postcode;
        this.ShowShipperDlg(1, false);
    }
    // 显示收件人对话框
    ShowReceiverDlg = (iShow, bShow) => {
        if (iShow === 1) {
            this.setState({
                ...this.state,
                iReceiverDlg: iShow,
                bReceiverDlg: bShow,
                iCurrentReceiverProvince: this.objCurrentReceiver.province
            });
            this.CalculatePrice(this.state.iCurrentUnit, this.state.iCurrentUPSExpress);
        }
        else
            this.setState({
                ...this.state,
                iReceiverDlg: iShow,
                bReceiverDlg: bShow
            });

    }
    // 选择收件人
    SelectReceiver = (index) => {
        this.objCurrentReceiver = global.arrReceiverData[index];
        // console.log("SelectReceiver : ", this.objCurrentReceiver);
        this.refs.receiver_name.state.value = this.objCurrentReceiver.name;
        this.refs.receiver_phone.state.value = this.objCurrentReceiver.phone;
        this.refs.receiver_email.state.value = this.objCurrentReceiver.email;
        this.refs.receiver_address.state.value = this.objCurrentReceiver.address;
        this.refs.receiver_postcode.state.value = this.objCurrentReceiver.postcode;
        this.refs.receiver_company.state.value = this.objCurrentReceiver.company;
        this.refs.receiver_city.state.value = this.objCurrentReceiver.city;
        this.objCurrentJetonOrder.receiver_postcode = this.objCurrentReceiver.postcode;
        this.ShowReceiverDlg(1, false);
    }
    // 从数据库获取当前的数据结构
    GetUsersData = () => {
        message.loading({ content: '数据加载中……', key: g_strMessageKey });
        let objParms = {
            store_id: this.props.iStoreID,
            level: this.props.iLevel
        }
        if (this.props.iLevel < 9)
            objParms = {
                store_id: this.props.iStoreID,
                user_id: this.props.iUserID
            }
        axios('/GetUsers', { params: objParms })
            .then(({ data }) => {
                // console.log(data);
                global.arrUserData = data;
            }).catch(function (error) { console.log(error); });
    }
    // 回调函数 - 确认下单按钮点击
    ConfirmOrder = () => {
        // console.log("确认下单");
        this.GetJetonOrdersData();
        this.setState({
            ...this.state,
            bShowDlg: false,
            iDlgType: -1
        })
    }
    // 关闭运单弹窗
    CloseOrderDlg = () => {
        this.setState({
            ...this.state,
            bShowDlg: false,
            iDlgType: -1
        })
    }

    /* React自带函数 */
    // 卸载异步操作设置状态
    componentWillUnmount() {
        clearTimeout(this.timeouter)
        this.setState = (state, callback) => {
            return;
        }
    }
    // 渲染完毕……
    componentDidMount() {
        // this.GetJetonOrdersData();

        this.GetShipperData();
        this.GetReceiverData();
        if (global.arrUserData.length <= 0 && this.props.iLevel >= 9)
            this.GetUsersData();

        // return;

        if (this.props.iJumpToJetonOrder === -1) {
            console.log("从估价跳转过来，需要直接弹窗, objCurrentJetonOrder = ", this.props.objCurrentJetonOrder);
            this.iSelectedOrderType = this.props.iSelectedOrderType;
            this.objCurrentJetonOrder = this.props.objCurrentJetonOrder;
            this.setState({
                ...this.state,
                iDlgType: 3,
                bShowDlg: true
            });
        } else {
            console.log("只是普通菜单切换");
            this.iSelectedOrderType = -1;
            this.objCurrentJetonOrder = null;
        }

        this.GetJetonOrdersData();

    }
    // 渲染函数
    render() {
        let uiNewButton = null;
        let uiEditButton = (
            <Button className="manage_contents_title_margin" type="primary" onClick={this.ShowDlg.bind(this, 1)} disabled={this.state.btnControlDisable[1]}>
                <Icon type="search" />查看</Button>
        );
        let uiCancelButton = null;
        let uiExportExcelButton = null;
        uiNewButton = (
            <Button className="manage_contents_title_margin" type="primary" onClick={this.ShowDlg.bind(this, 0)} disabled={this.state.btnControlDisable[0]}>
                <Icon type="edit" />新建</Button>
        );
        uiCancelButton = (
            <Button className="manage_contents_title_margin" type="danger" onClick={this.ShowDlg.bind(this, 2)} disabled={this.state.btnControlDisable[2]}>
                <Icon type="close" />取消</Button>
        );
        if (this.props.iLevel >= 9) {
            uiExportExcelButton = (<Button className="manage_contents_title_margin manage_contents_title_margin_right export_button_style" onClick={this.ExportExcelFile}
                disabled={!this.state.bExportExcel}>
                <Icon type="export" /> 导出Excel</Button>);
            uiEditButton = (
                <Button className="manage_contents_title_margin" type="primary" onClick={this.ShowDlg.bind(this, 1)} disabled={this.state.btnControlDisable[1]}>
                    <Icon type="edit" />查看</Button>
            );
        }

        /* 运单管理页面控制栏 */
        let uiTitleArea = (
            < div className="store_contents_title" >
                <div className="manage_stretch_right">
                    {/* {uiNewButton} */}
                    {uiEditButton}
                    {uiCancelButton}
                    <Input placeholder="单号/用户/收寄件人/备注" defaultValue={this.state.strKeywords} style={{ marginLeft: '20px' }} ref="order_search" />
                    <Button className="manage_contents_title_margin"
                        type="primary" onClick={this.JetonOrderSearch}>搜 索
                    </Button>
                    <Button className="manage_contents_title_margin" style={{ marginRight: '20px' }}
                        type="primary" onClick={this.ShowAllOrders}>显示全部
                    </Button>
                    {uiExportExcelButton}
                </div>
            </div >
        );

        // 如果界面上需要显示的内容已经从数据库获得
        if (!this.state.bLoading) {
            // 主体表格相关
            const { selectedRowKeys } = this.state;
            const rowSelection = {
                selectedRowKeys,
                onChange: this.onSelectedRowKeysChange,
                columnWidth: 25
            };
            // 表格分页属性
            const paginationProps = {
                showSizeChanger: true,
                showQuickJumper: false,
                showTotal: () => `共 ${this.arrTableData.length} 个 运单`,
                defaultPageSize: 10,
                defaultCurrent: this.state.iCurrentPage,
                pageSizeOptions: ['10', '20', '50', '100'],
                total: this.arrTableData.length,
                position: 'bottom'
            };
            // 运单信息编辑弹窗
            let uiDlgCenter = null;
            if (this.iSelectedOrderType >= 0 & this.iSelectedOrderType <= 3 && this.state.iDlgType >= 1)
                uiDlgCenter = (<CPExpressDlg iSelectedOrderType={this.iSelectedOrderType} objCurrentJetonOrder={this.objCurrentJetonOrder}
                    fMoney={this.props.fMoney} iJumpToJetonOrder={this.props.iJumpToJetonOrder} iStoreID={this.props.iStoreID}
                    iUserID={this.props.iUserID} strUserName={this.props.strUserName} iLevel={this.props.iLevel} iDlgType={this.state.iDlgType}
                    ConfirmOrder={this.ConfirmOrder} CloseOrderDlg={this.CloseOrderDlg} />);
            if (this.iSelectedOrderType >= 4 & this.iSelectedOrderType <= 7 && this.state.iDlgType >= 1) {
                uiDlgCenter = (<UPSExpressDlg iSelectedOrderType={this.iSelectedOrderType} objCurrentJetonOrder={this.objCurrentJetonOrder}
                    fMoney={this.props.fMoney} iJumpToJetonOrder={this.props.iJumpToJetonOrder} iStoreID={this.props.iStoreID}
                    iUserID={this.props.iUserID} strUserName={this.props.strUserName} iLevel={this.props.iLevel} iDlgType={this.state.iDlgType}
                    ConfirmOrder={this.ConfirmOrder} CloseOrderDlg={this.CloseOrderDlg} />);
                // console.log("打开UPS Express Dlg : ", this.state.iDlgType, this.iSelectedOrderType);
            }
            let uiModal = null;
            if (this.state.iDlgType >= 0)
                uiModal = (
                    <Modal title={"运单 - " + this.objCurrentJetonOrder.oid} width={1000} visible={this.state.bShowDlg} footer={null}
                        /*okText="确定下单" cancelText="取消"*/ onOk={this.handleOk} onCancel={this.handleCancel} >
                        {uiDlgCenter}
                    </Modal>
                );
            if (this.state.iDlgType === 2) {
                uiModal = (
                    <Modal width={500} title={"删除运单"} visible={this.state.bShowDlg} okType="danger"
                        onOk={this.handleOk} okText="确定"
                        onCancel={this.handleCancel} cancelText="取消">
                        <div className="modal_item">
                            <div>{"运单：" + this.objCurrentJetonOrder.express_order + ", 客户" + this.objCurrentJetonOrder.client}
                                <br /><br />确定删除选中的运单？
                            </div>
                        </div>
                    </Modal>
                );
            }

            return (
                <div className="store_manage_contents store_manage_top_space">
                    {uiTitleArea}
                    {uiModal}
                    {/* 表格主体 */}
                    <Table style={{ margin: '10px', width: '97.5%', overflowX: 'auto' }}
                        rowSelection={rowSelection} pagination={paginationProps}
                        columns={arrTableHeader} dataSource={this.arrTableData} bordered
                        onRow={(record) => ({ onClick: () => { this.selectRow(record); } })} />
                </div >
            );
        } else {
            return (
                <div className="store_manage_contents store_manage_top_no_space">
                    {uiTitleArea}
                    <Spin className="manage_empty_loading" size="large" tip="加载中……" />
                </div>
            );
        }
    }
}
export default JetonExpressOrder;
