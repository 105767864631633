import React, { Component } from "react";
import axios from 'axios';
import cookie from 'react-cookies';
import "antd/dist/antd.css";
import "../../styles/Background/storage_manager.css";

import Empty from 'antd/es/empty';
import Spin from 'antd/es/spin';
import Menu from 'antd/es/menu';
import Icon from 'antd/es/icon';
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import Form from 'antd/es/form';
import message from 'antd/es/message';

import SKUManager from "./StoragePage/SKUManager.js";
import StorageEnteringManager from "./StoragePage/StorageEnteringManager.js";

import EnteringChecking from "./StoragePage/EnteringChecking.js";
import SKUUpRecord from "./StoragePage/SKUUpRecord.js";
import SKUDownRecord from "./StoragePage/SKUDownRecord.js";
import InventorySearch from "./StoragePage/InventorySearch.js";
import InventoryPositionList from "./StoragePage/InventoryPositionList.js";
import InventoryPositionType from "./StoragePage/InventoryPositionType.js";
import InventoryAreaInfo from "./StoragePage/InventoryAreaInfo.js";

import StorageDeliveryManager from "./StoragePage/StorageDeliveryManager.js";
import DeliveryChecking from "./StoragePage/DeliveryChecking.js";
import StorageUserInfo from "./StoragePage/StorageUserInfo.js";
import StorageClientInfo from "./StoragePage/StorageClientInfo.js";

import { UserActiveUpdate } from "../../functions/DataStatistic.js";

const { SubMenu } = Menu;
const g_strMessageKey = 'updating';

function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class StorageManager extends Component {
    // 构造函数
    constructor(props) {
        super();

        let strParams = window.location.search;
        if (strParams.indexOf('?') >= 0)
            strParams = strParams.substring(strParams.indexOf('?') + 1);
        this.bClientUser = false;
        if (strParams.indexOf('client') >= 0)
            this.bClientUser = true;
        // console.log("strParams = " + strParams.split('=')[1]);

        this.state = {
            bLoading: true,  // 加载状态
            bLogin: false,  // 登录状态
            iCurrentSideBarMenu: 0,  // 主菜单索引
            // iCurrentSideBarSubIndex: 0,  // 子菜单索引
        };
        this.arrSideMenuButton = [
            {
                id: 0, name: "入库管理", icon: "shop",
                sub: [{ id: 101, name: "入库列表" }, { id: 102, name: "签收查验" }, { id: 103, name: "上架记录" }]
            },
            {
                id: 1, name: "库存管理", icon: "code-sandbox",
                sub: [{ id: 110, name: "库存查询" }, { id: 111, name: "库位列表" }, { id: 112, name: "库位类型" }, { id: 113, name: "库区信息" }]
            },
            {
                id: 2, name: "出库管理", icon: "car",
                sub: [{ id: 120, name: "出库列表" }, { id: 121, name: "发货查验" }, { id: 122, name: "下架记录" }]
            },
            { id: 3, name: "工单管理", icon: "control" },
            { id: 4, name: "统计数据", icon: "bar-chart" },
            {
                id: 5, name: "系统设置", icon: "setting",
                sub: [{ id: 150, name: "用户管理" }, { id: 151, name: "渠道管理" }]
            }

        ];
        this.arrClientSideMenuButton = [
            {
                id: 0, name: "入库管理", icon: "shop",
                sub: [{ id: 100, name: "SKU列表" }, { id: 101, name: "入库列表" }]
            },
            {
                id: 1, name: "库存管理", icon: "code-sandbox",
                sub: [{ id: 110, name: "库存查询" }]
            },
            {
                id: 2, name: "出库管理", icon: "car",
                sub: [{ id: 120, name: "出库列表" }, { id: 121, name: "用户信息" }]
            },
        ]
        this.arrCategoriesData = [];  // 用于初始处理分类数据
        this.arrLocationsData = [];  // 用于初始处理地区数据
        this.strCurrentUserName = '';
        this.strCurrentUserCode = '';
        this.iCurrentUserLevel = 0;
        this.strInviteCode = '';
        this.objStoreInfo = { store_name: '' };
        this.iUserID = 0;

    }
    // 切换侧边栏菜单
    SwitchSideMenu = (iMenuID) => {
        this.setState({
            ...this.state,
            bLoading: true
        });

        this.setState({
            ...this.state,
            bLoading: false,
            iCurrentSideBarMenu: iMenuID
        });
    }
    // 整理Categories的数据
    OrganizeCategoriesData(iParentID, strParentKey) {
        let arrCurrentLevelCategories = [];
        let iCurrentCategoryKey = 0;
        for (let i = 0; i < this.arrCategoriesData.length; i++) {
            if (this.arrCategoriesData[i].parent_id === iParentID) {
                if (this.arrCategoriesData[i].icon === '')
                    this.arrCategoriesData[i].icon = "about_us_icon";
                let objCategory = {
                    key: strParentKey + "-" + iCurrentCategoryKey.toString(),
                    id: this.arrCategoriesData[i].id, parent_id: iParentID,
                    level: this.arrCategoriesData[i].level,
                    title: this.arrCategoriesData[i].name + " - " + this.arrCategoriesData[i].en_name,
                    name: this.arrCategoriesData[i].name,
                    en_name: this.arrCategoriesData[i].en_name,
                    icon: this.arrCategoriesData[i].icon,
                    children: []
                };
                if (objCategory.en_name === null || objCategory.en_name === "null") {
                    objCategory.en_name = "";
                    objCategory.title = this.arrCategoriesData[i].name + " - " +
                        this.arrCategoriesData[i].en_name;
                }
                let arrChildren = this.OrganizeCategoriesData(objCategory.id, objCategory.key);
                objCategory.children = arrChildren;
                arrCurrentLevelCategories.push(objCategory);
                iCurrentCategoryKey++;
            }
        }
        return arrCurrentLevelCategories;
    }
    // 获取分类数据
    GetCategoriesData() {
        axios('/GetCategories', { params: { id: 0 } })
            .then(({ data }) => {
                this.arrCategoriesData = data;
                global.arrCategoriesData = this.OrganizeCategoriesData(0, "0");
                // 还未获得地区数据(未点击过分类按钮)
                if (global.arrLocationsData[0].children.length === 0) {
                    this.GetLocationData();
                } else {
                    // 有地区数据都有则直接刷新
                    this.setState({
                        ...this.state,
                        bLoading: false,
                        iCurrentInterface: this.arrSideMenuButton[1].id,
                    });
                }
            })
            .catch(function (error) { console.log(error); });
    }
    // 整理Locations数据
    OrganizeLocationsData(iParentID, strParentKey) {
        let arrCurrentLevelLocations = [];
        let iCurrentLocationKey = 0;
        for (let i = 0; i < this.arrLocationsData.length; i++) {
            if (this.arrLocationsData[i].parent_id === iParentID) {
                let objLocation = {
                    key: strParentKey + "-" + iCurrentLocationKey.toString(),
                    id: this.arrLocationsData[i].id, parent_id: iParentID,
                    level: this.arrLocationsData[i].level,
                    title: this.arrLocationsData[i].name + " - " + this.arrLocationsData[i].en_name,
                    name: this.arrLocationsData[i].name,
                    en_name: this.arrLocationsData[i].en_name,
                    latitude_x: this.arrLocationsData[i].latitude_x,
                    longitude_y: this.arrLocationsData[i].longitude_y,
                    children: []
                };
                let arrChildren = this.OrganizeLocationsData(objLocation.id, objLocation.key);
                objLocation.children = arrChildren;
                arrCurrentLevelLocations.push(objLocation);
                if (objLocation.id === 3) {
                    global.arrVancouverAreaData = [];
                    global.arrVancouverAreaData.push(objLocation);
                }
                iCurrentLocationKey++;
            }
        }
        return arrCurrentLevelLocations;
    }
    // 获取地区数据
    GetLocationData() {
        axios('/GetLocations', { params: { id: 0 } })
            .then(({ data }) => {
                this.arrLocationsData = data;
                global.arrLocationsData = this.OrganizeLocationsData(0, "0");
                this.setState({
                    ...this.state,
                    bLoading: false,
                    iCurrentInterface: this.arrSideMenuButton[1].id,
                });
            })
            .catch(function (error) { console.log(error); });
    }
    // 获取当前登录的商家的基本信息
    GetStoreInfo = () => {
        axios('/GetUserRelevantStoreID', { params: { client: this.strCurrentUserCode } })
            .then(({ data }) => {
                console.log("GetStoreInfo : ", data);
                if (data.status === -1) {
                    message.warning(data.message);
                    this.setState({
                        ...this.state,
                        bLoading: false
                    });
                } else {
                    this.iUserID = data.user_id;
                    this.strCurrentUserName = data.name;
                    this.iCurrentUserLevel = data.level;
                    this.strInviteCode = data.invite_code;
                    console.log("this.strInviteCode = " + this.strInviteCode);
                    axios('/GetStoreInfo', { params: { id: data.store_id } })
                        .then(({ data }) => {
                            this.objStoreInfo = data[0];
                            this.SwitchSideMenu(0);
                            this.setState({
                                ...this.state,
                                bLoading: false,
                                bLogin: true
                            });

                        }).catch(function (error) { console.log(error); });
                }
            }).catch(function (error) { console.log(error); });
    }
    // 登录表单提交响应
    LoginSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                // DataStatistic
                UserActiveUpdate(0, { active: "管理后台: 尝试登录", user: values.username });
                message.loading({ content: '登录中……', key: g_strMessageKey });
                this.setState({
                    ...this.state,
                    bLoading: true
                });
                this.TryLogin(values.username, values.password)
            }
        });
    };
    // 尝试登录
    TryLogin = (name, password) => {
        let objUserInfo = { name: name, password: password };
        if (this.bClientUser) {
            axios('/StorageBackEndLogin', { params: objUserInfo })
                .then(({ data }) => {
                    if (data.status === -1) {
                        message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
                        this.setState({
                            ...this.state,
                            bLoading: false
                        });
                    }
                    else {
                        message.success({ content: data.message, key: g_strMessageKey, duration: 2 });
                        this.strCurrentUserName = name;
                        this.strCurrentUserCode = data.code;
                        cookie.save('store_user_code', this.strCurrentUserCode, { path: '/' });
                        cookie.save('store_login_status', 1, { path: '/' });
                        // DataStatistic
                        UserActiveUpdate(0, { active: "仓管系统: 登录成功", user: name });
                        this.GetStoreInfo();
                    }
                }).catch(function (error) { console.log(error); });
        } else {
            axios('/StoreBackEndLogin', { params: objUserInfo })
                .then(({ data }) => {
                    if (data.status === -1) {
                        message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
                        this.setState({
                            ...this.state,
                            bLoading: false
                        });
                    }
                    else {
                        message.success({ content: data.message, key: g_strMessageKey, duration: 2 });
                        this.strCurrentUserName = name;
                        this.strCurrentUserCode = data.code;
                        cookie.save('store_user_code', this.strCurrentUserCode, { path: '/' });
                        cookie.save('store_login_status', 1, { path: '/' });
                        // DataStatistic
                        UserActiveUpdate(0, { active: "仓管系统: 登录成功", user: name });
                        this.GetStoreInfo();
                    }
                }).catch(function (error) { console.log(error); });
        }
    }
    // 退出登录
    LogoutClick = () => {
        cookie.remove('store_user_code', { path: '/' });
        cookie.save('store_login_status', -1, { path: '/' });
        this.setState({
            ...this.state,
            bLogin: false,
            bLoading: false
        });
    }
    // 用户识别码检查
    UserCodeCheck = () => {
        axios('/UserCodeExistCheck', { params: { code: this.strCurrentUserCode, level: 10 } })
            .then(({ data }) => {
                if (data.status === -1) {
                    message.warning(data.message);
                    this.setState({
                        ...this.state,
                        bLoading: false
                    });
                } else {
                    message.success(data.message);
                    this.strCurrentUserName = data.name;
                    this.setState({
                        ...this.state,
                        bLoading: false,
                        bLogin: true
                    });
                }
            }).catch(function (error) { console.log(error); });
    }
    // 渲染完毕……
    componentDidMount() {
        let strUserCode = cookie.load('store_user_code');
        let iUserLoginStatus = cookie.load('store_login_status');
        if ((iUserLoginStatus === 1 || iUserLoginStatus === '1') && strUserCode !== undefined) {
            this.strCurrentUserCode = strUserCode;
            if (this.strCurrentUserCode.indexOf('u_') >= 0)
                this.iCurrentUserLevel = 5;
            if (this.strCurrentUserCode.indexOf('s_') >= 0)
                this.iCurrentUserLevel = 9;
            // 客户端登录
            if (this.bClientUser) {
                if (this.iCurrentUserLevel >= 9)
                    this.LogoutClick();
                else
                    this.GetStoreInfo();
            }
            // 管理后台登录
            else {
                if (this.iCurrentUserLevel < 9)
                    this.LogoutClick();
                else
                    this.GetStoreInfo();
            }
        } else {
            this.setState({
                ...this.state,
                bLoading: false,
                bLogin: false
            });
        }
        document.title = "筋斗云海外仓系统EGWMS";
    }

    render() {

        const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;
        // Only show error after a field is touched.
        const usernameError = isFieldTouched('username') && getFieldError('username');
        const passwordError = isFieldTouched('password') && getFieldError('password');
        // 登录表单
        const uiLoginForm = (
            <Form className="manage_login_form" layout="inline" onSubmit={this.LoginSubmit}>
                <Form.Item validateStatus={usernameError ? 'error' : ''} help={usernameError || ''}>
                    {getFieldDecorator('username')(
                        <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="用户名" />,
                    )}
                </Form.Item>
                <Form.Item validateStatus={passwordError ? 'error' : ''} help={passwordError || ''}>
                    {getFieldDecorator('password')(
                        <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="密码" />,
                    )}
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" disabled={hasErrors(getFieldsError())}>
                        登 陆
                    </Button>
                </Form.Item>
            </Form>
        );
        // 客户登录表单
        const uiClientLoginForm = (
            <Form className="storage_login_form" onSubmit={this.LoginSubmit}>
                <Form.Item>
                    {getFieldDecorator('username', {
                        rules: [{ required: true, message: '请输入用户名!' }],
                    })(
                        <Input
                            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                            placeholder="用户名"
                        />,
                    )}
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator('password', {
                        rules: [{ required: true, message: '请输入密码!' }],
                    })(
                        <Input
                            prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                            type="password"
                            placeholder="密码"
                        />,
                    )}
                </Form.Item>
                <Form.Item>
                    <div className="storage_login_row">
                        <div className="storage_manager_register_button">注册申请</div>
                        <Button type="primary" htmlType="submit" className="login-form-button" disabled={hasErrors(getFieldsError())}>
                            登录
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        );
        // 欢迎回来
        let strTitle = '欢迎回来,  ' + this.strCurrentUserName;
        const uiWelcomeBack = (
            <div className="manage_login_form">
                <div className="welcome_back_title">{strTitle}</div>
                <Button className="logout_btn" type="primary" htmlType="submit"
                    onClick={this.LogoutClick}>退出登录</Button>
            </div>
        );
        // 顶部菜单栏
        let uiStoreInfo = (
            <div className="storage_backend_title">
                {"筋斗云海外仓系统EGWMS - " + this.objStoreInfo.store_name}
            </div>
        );
        let uiNavBar = (
            <div className="manage_top_bar">
                <div className="storage_manage_logo" >Mouthing</div>
                {uiStoreInfo}
                {uiWelcomeBack}
            </div>
        );
        // 侧边菜单栏
        let arrSideMenu = this.arrSideMenuButton;
        if (this.bClientUser)
            arrSideMenu = this.arrClientSideMenuButton;
        let uiSideBar = (
            <Menu className="storage_manage_side_bar" defaultSelectedKeys={[this.state.iCurrentSideBarMenu.toString()]}
                selectedKeys={[this.state.iCurrentSideBarMenu.toString()]}
                mode="inline" theme="dark" inlineCollapsed={this.state.collapsed}>
                {arrSideMenu.map((menu, menu_index) => {
                    let uiMenuItem = (
                        <Menu.Item key={menu.id} onClick={this.SwitchSideMenu.bind(this, menu.id)}>
                            <Icon type={menu.icon} />{menu.name}
                        </Menu.Item>
                    );
                    if (menu.sub) {
                        uiMenuItem = (
                            <SubMenu key={menu.id} title={<span><Icon type={menu.icon} />{menu.name}</span>}>
                                {menu.sub.map((sub, sub_index) => {
                                    return (
                                        <Menu.Item key={sub.id} onClick={this.SwitchSideMenu.bind(this, sub.id)}>{sub.name}</Menu.Item>
                                    );
                                })}
                            </SubMenu>
                        );
                    }
                    return (uiMenuItem);
                })}
            </Menu>
        );
        if (!this.state.bLogin) {
            uiNavBar = (
                <div className="manage_top_bar">
                    <div className="platform_logo" >筋斗云海外仓系统EGWMS</div>
                    <div className="manage_top_bar_menu" />
                    {uiLoginForm}
                </div>
            );
            if (this.bClientUser) {
                uiNavBar = (
                    <div className="manage_top_bar">
                        <div className="platform_logo" >筋斗云海外仓系统EGWMS
                        </div>
                    </div>
                );
            }
            uiSideBar = <div />;
        }

        let uiCentralArea;
        // 是否还在加载中……
        if (this.state.bLoading) {
            uiCentralArea = (<Spin className="manage_empty_loading" size="large" tip="加载中……" />);
        } else {
            if (this.state.bLogin) {
                /****** 入库管理 ******/
                // SKU列表
                let uiSKUList = null;
                if (this.bClientUser)
                    uiSKUList = (this.state.iCurrentSideBarMenu === arrSideMenu[0].sub[0].id &&
                        <SKUManager objStoreInfo={this.objStoreInfo} iUserID={this.iUserID} />);
                // 入库预报
                let uiStorageEntering = null;
                if (this.bClientUser)
                    uiStorageEntering = (this.state.iCurrentSideBarMenu === arrSideMenu[0].sub[1].id &&
                        <StorageEnteringManager objStoreInfo={this.objStoreInfo} iUserID={this.iUserID} iUserLevel={this.iCurrentUserLevel}
                            strUserName={this.strCurrentUserName} strInviteCode={this.strInviteCode} />);
                else
                    uiStorageEntering = (this.state.iCurrentSideBarMenu === arrSideMenu[0].sub[0].id &&
                        <StorageEnteringManager objStoreInfo={this.objStoreInfo} iUserID={this.iUserID} iUserLevel={this.iCurrentUserLevel}
                            strUserName={this.strCurrentUserName} strInviteCode={this.strInviteCode} />);
                // 签收查验
                let uiEnteringChecking = null;
                if (!this.bClientUser)
                    uiEnteringChecking = (this.state.iCurrentSideBarMenu === arrSideMenu[0].sub[1].id &&
                        <EnteringChecking objStoreInfo={this.objStoreInfo} iUserID={this.iUserID} iUserLevel={this.iCurrentUserLevel}
                            strUserName={this.strCurrentUserName} strInviteCode={this.strInviteCode} />);
                // 上架记录
                let uiSKUUpRecord = null;
                if (!this.bClientUser)
                    uiSKUUpRecord = (this.state.iCurrentSideBarMenu === arrSideMenu[0].sub[2].id &&
                        <SKUUpRecord objStoreInfo={this.objStoreInfo} iUserID={this.iUserID} iUserLevel={this.iCurrentUserLevel}
                            strUserName={this.strCurrentUserName} strInviteCode={this.strInviteCode} />);
                /****** 库存管理 ******/
                // 库存查询
                let uiInventorySearch = null;
                uiInventorySearch = (this.state.iCurrentSideBarMenu === arrSideMenu[1].sub[0].id &&
                    <InventorySearch objStoreInfo={this.objStoreInfo} iUserID={this.iUserID} iUserLevel={this.iCurrentUserLevel}
                        strUserName={this.strCurrentUserName} bClientUser={this.bClientUser} strInviteCode={this.strInviteCode} />);
                // 库位列表
                let uiInventoryPositionList = null;
                uiInventoryPositionList = (this.state.iCurrentSideBarMenu === this.arrSideMenuButton[1].sub[1].id &&
                    <InventoryPositionList objStoreInfo={this.objStoreInfo} />);
                // 库位类型
                let uiInventoryPositionType = null;
                uiInventoryPositionType = (this.state.iCurrentSideBarMenu === this.arrSideMenuButton[1].sub[2].id &&
                    <InventoryPositionType objStoreInfo={this.objStoreInfo} />);
                // 库区信息
                let uiInventoryAreaInfo = null;
                uiInventoryAreaInfo = (this.state.iCurrentSideBarMenu === this.arrSideMenuButton[1].sub[3].id &&
                    <InventoryAreaInfo objStoreInfo={this.objStoreInfo} />);
                /****** 出库管理 ******/
                // 出库预报
                let uiStorageDelivery = null;
                uiStorageDelivery = (this.state.iCurrentSideBarMenu === arrSideMenu[2].sub[0].id &&
                    <StorageDeliveryManager objStoreInfo={this.objStoreInfo} iUserID={this.iUserID} iUserLevel={this.iCurrentUserLevel}
                        strUserName={this.strCurrentUserName} bClientUser={this.bClientUser} strInviteCode={this.strInviteCode} />);
                // 客户的用户信息
                let uiUserInfo = null;
                if (this.bClientUser)
                    uiUserInfo = (this.state.iCurrentSideBarMenu === arrSideMenu[2].sub[1].id &&
                        <StorageUserInfo objStoreInfo={this.objStoreInfo} iUserID={this.iUserID} iUserLevel={this.iCurrentUserLevel}
                            strUserName={this.strCurrentUserName} />);
                // 出库查验
                let uiDeliveryChecking = null;
                if (!this.bClientUser)
                    uiDeliveryChecking = (this.state.iCurrentSideBarMenu === arrSideMenu[2].sub[1].id &&
                        <DeliveryChecking objStoreInfo={this.objStoreInfo} iUserID={this.iUserID} iUserLevel={this.iCurrentUserLevel}
                            strUserName={this.strCurrentUserName} strInviteCode={this.strInviteCode} />);
                // 下架记录
                let uiSKUDownRecord = null;
                if (!this.bClientUser)
                    uiSKUDownRecord = (this.state.iCurrentSideBarMenu === arrSideMenu[2].sub[2].id &&
                        <SKUDownRecord objStoreInfo={this.objStoreInfo} iUserID={this.iUserID} iUserLevel={this.iCurrentUserLevel}
                            strUserName={this.strCurrentUserName} strInviteCode={this.strInviteCode} />);
                // 客户管理
                let uiClientInfo = null;
                if (!this.bClientUser)
                    uiClientInfo = (this.state.iCurrentSideBarMenu === arrSideMenu[5].sub[0].id &&
                        <StorageClientInfo objStoreInfo={this.objStoreInfo} iUserID={this.iUserID} iUserLevel={this.iCurrentUserLevel}
                            strUserName={this.strCurrentUserName} strInviteCode={this.strInviteCode} />);
                // 渠道管理
                let uiChannel = null;
                if (!this.bClientUser)
                    uiChannel = (this.state.iCurrentSideBarMenu === arrSideMenu[5].sub[1].id &&
                        <StorageUserInfo objStoreInfo={this.objStoreInfo} iUserID={this.iUserID} iUserLevel={this.iCurrentUserLevel}
                            strUserName={this.strCurrentUserName} />);
                uiCentralArea = (
                    <div>
                        {/* 入库管理 */}
                        {uiSKUList}
                        {uiStorageEntering}
                        {uiEnteringChecking}
                        {uiSKUUpRecord}
                        {/* 库存管理 */}
                        {uiInventorySearch}
                        {uiInventoryPositionList}
                        {uiInventoryPositionType}
                        {uiInventoryAreaInfo}
                        {/* 出库管理 */}
                        {uiStorageDelivery}
                        {uiUserInfo}
                        {uiDeliveryChecking}
                        {uiSKUDownRecord}
                        {/* 系统设置 */}
                        {uiClientInfo}
                        {uiChannel}
                    </div>
                );
            } else {
                uiCentralArea = (
                    <Empty className="manage_empty_loading" description={<span>未登录，无法使用</span>} />
                );
                if (this.bClientUser) {
                    uiCentralArea = uiClientLoginForm;
                }
            }
        }

        return (
            <div className="manage_page">
                {/* 顶部菜单栏 */}
                {uiNavBar}
                {/* 侧边菜单栏 */}
                {uiSideBar}
                {/* 中心区域内容 */}
                {uiCentralArea}
            </div >
        );
    }
}
export default Form.create()(StorageManager);
