import React, { Component } from 'react';
import axios from 'axios';

import Spin from 'antd/es/spin';
import Table from 'antd/es/table';
import Button from 'antd/es/button';
import Input from 'antd/es/input';
import Icon from 'antd/es/icon';
import Modal from 'antd/es/modal';
import Dropdown from 'antd/es/dropdown';
import Menu from 'antd/es/menu';
import message from 'antd/es/message';

import '../../../styles/Background/ego_manager.css';
import '../../../styles/Background/UI_Module/user_management.css';

const g_strMessageKey = 'updating';
global.arrShipperData = [];

// 表格
const arrTableHeader = [
    // { title: 'ID', dataIndex: 'id', width: 20, ellipsis: true },
    { title: '姓名', dataIndex: 'name', width: 75, ellipsis: true },
    { title: '公司', dataIndex: 'company', width: 135, ellipsis: true },
    { title: '电话', dataIndex: 'phone', width: 95, ellipsis: true },
    // { title: '邮箱', dataIndex: 'email', width: 125, ellipsis: true },
    { title: '省份', dataIndex: 'province_str', width: 40, ellipsis: true },
    { title: '国家', dataIndex: 'country', width: 40, ellipsis: true },
    { title: '邮编', dataIndex: 'postcode', width: 70, ellipsis: true },
    { title: '地址', dataIndex: 'address', width: 250, ellipsis: true }
];

class ShipOrderShipperManager extends Component {
    constructor(props) {
        super();
        this.state = {
            btnControlDisable: [false, true, true],
            selectedRowKeys: [],
            iDlgType: -1,
            bShowDlg: false,
            bLoading: true,
            iCurrentProvince: 1,
        };
        // 成员变量
        this.arrOgeTableData = [];
        this.arrTableData = [];
        this.objCurrentShipper = {};
        this.objCAProvince = ["AB", "BC", "MB", "NB", "NL", "NS", "NT", "NU", "ON", "PE", "QC", "SK", "YT"];
    }

    // 选中某行的响应函数
    selectRow = (record) => {
        const selectedRowKeys = [...this.state.selectedRowKeys];
        if (selectedRowKeys.indexOf(record.key) >= 0) {
            selectedRowKeys.splice(selectedRowKeys.indexOf(record.key), 1);
        } else {
            selectedRowKeys.push(record.key);
        }
        this.SelectedRowStateSetting(selectedRowKeys);
    }
    // 选中的行发生变化的响应函数
    onSelectedRowKeysChange = (selectedRowKeys) => {
        this.SelectedRowStateSetting(selectedRowKeys);
    }
    // 处理选中的表行行
    SelectedRowStateSetting = (selectedRowKeys) => {
        let btnControlDisable = [];
        switch (selectedRowKeys.length) {
            case 0: {
                btnControlDisable = [false, true, true];
                this.objCurrentShipper = {};
                break;
            }
            case 1: {
                btnControlDisable = [false, false, false];
                for (let i = 0; i < this.arrTableData.length; i++) {
                    if (this.arrTableData[i].key === selectedRowKeys[0]) {
                        this.objCurrentShipper = this.arrTableData[i];
                        break;
                    }
                }
                break;
            }
            default: {
                btnControlDisable = [false, true, true]; break;
            }
        }
        this.setState({
            ...this.state,
            selectedRowKeys: selectedRowKeys,
            btnControlDisable: btnControlDisable
        });
    }
    // 关键词处理
    KeywordsProcess = (strKeywords) => {
        let newKeywords = "";
        for (let i = 0; i < strKeywords.length; i++) {
            if (strKeywords[i] !== " ") {
                if (i === strKeywords.length - 1)
                    newKeywords += strKeywords[i];
                else
                    newKeywords += strKeywords[i] + "%";
            }
        }
        return newKeywords;
    }
    // 搜索寄件人名
    ShipperNameSearch = () => {
        let strKeywords = this.refs.name_search.state.value;
        if (strKeywords === undefined || strKeywords === null)
            strKeywords = "";
        if (strKeywords.trim() === "") {
            this.GetShipperData();
        } else {
            this.setState({
                ...this.state,
                bLoading: true,
                selectedRowKeys: [],
                btnControlDisable: [false, true, true]
            });
            axios('/SearchJetonShipper', { params: { name: this.KeywordsProcess(strKeywords) } })
                .then(({ data }) => {
                    this.arrTableData = this.OrganizeShipperData(data);
                    this.setState({
                        ...this.state,
                        bLoading: false
                    });
                }).catch(function (error) { console.log(error); });
        }
    }
    //弹出对话框
    ShowDlg = (iDlgType, e) => {
        if (iDlgType === 0) {
            this.objCurrentShipper = {};
            if (this.refs.name !== undefined) {
                this.refs.name.state.value = '';
                this.refs.phone.state.value = '';
                this.refs.email.state.value = '';
                this.refs.address.state.value = '';
                this.refs.company.state.value = '';
                this.refs.postcode.state.value = '';
                this.refs.city.state.value = '';
            }
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                iCurrentProvince: 1,
                bShowDlg: true
            });
        }
        if (iDlgType === 1) {
            if (this.refs.name !== undefined) {
                this.refs.name.state.value = this.objCurrentShipper.name;
                this.refs.phone.state.value = this.objCurrentShipper.phone;
                this.refs.email.state.value = this.objCurrentShipper.email;
                this.refs.address.state.value = this.objCurrentShipper.address;
                this.refs.company.state.value = this.objCurrentShipper.company;
                this.refs.postcode.state.value = this.objCurrentShipper.postcode;
                this.refs.city.state.value = this.objCurrentShipper.city;
            }
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                iCurrentProvince: this.objCurrentShipper.province,
                bShowDlg: true
            });
        }
        if (iDlgType === 2) {
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true
            });
        }
    }
    // 弹窗确认按钮响应
    handleOk = e => {
        if (this.state.iDlgType === 0) {
            let strName = this.refs.name.state.value;
            let strPhone = this.refs.phone.state.value;
            let strEmail = this.refs.email.state.value;
            let strPostcode = this.refs.postcode.state.value;
            let strAddress = this.refs.address.state.value;
            if (strName === undefined || strName === null || strName === "") {
                message.warning("姓名不能为空！"); return;
            }
            if (strPhone === undefined || strPhone === null || strPhone === "") {
                message.warning('电话不能为空！'); return;
            }
            if (strPostcode === undefined || strPostcode === null || strPostcode === "") {
                message.warning('邮编不能为空！'); return;
            }
            if (strAddress === undefined || strAddress === null || strAddress === "") {
                message.warning('地址不能为空！'); return;
            }
            let objNewShipper = {
                store_id: this.props.iStoreID,
                user_id: this.props.iUserID,
                name: strName,
                company: this.refs.company.state.value,
                phone: strPhone,
                email: strEmail,
                postcode: strPostcode,
                address: strAddress,
                country: 'CA',
                province: this.state.iCurrentProvince,
                city: this.refs.city.state.value
            }
            console.log("new shipper : ", objNewShipper);
            message.loading({ content: '正在创建 寄件人……', key: g_strMessageKey });
            axios.post('/NewJetonShipper', objNewShipper).then(({ data }) => {
                console.log("NewShipper response : ", data);
                if (data.status === 1) {
                    this.GetShipperData();
                }
                else
                    message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
            }).catch(function (error) { console.log(error); });
        }
        if (this.state.iDlgType === 1) {
            let strName = this.refs.name.state.value;
            let strPhone = this.refs.phone.state.value;
            let strPostcode = this.refs.postcode.state.value;
            let strAddress = this.refs.address.state.value;
            if (strName === undefined || strName === null || strName === "") {
                message.warning("姓名不能为空！"); return;
            }
            if (strPhone === undefined || strPhone === null || strPhone === "") {
                message.warning('电话不能为空！'); return;
            }
            if (strPostcode === undefined || strPostcode === null || strPostcode === "") {
                message.warning('邮编不能为空！'); return;
            }
            if (strAddress === undefined || strAddress === null || strAddress === "") {
                message.warning('地址不能为空！'); return;
            }
            let objNewShipper = {
                id: this.objCurrentShipper.id,
                store_id: this.props.iStoreID,
                user_id: this.props.iUserID,
                name: strName,
                company: this.refs.company.state.value,
                phone: strPhone,
                email: this.refs.email.state.value,
                postcode: strPostcode,
                address: strAddress,
                country: 'CA',
                province: this.state.iCurrentProvince,
                city: this.refs.city.state.value
            }
            console.log("new user : ", objNewShipper);
            console.log("current user : ", this.objCurrentShipper);
            message.loading({ content: '寄件人 更新中……', key: g_strMessageKey });
            axios.post('/UpdateJetonShipper', {
                objCurrentShipper: objNewShipper
            }).then(({ data }) => {
                console.log("UpdateJetonShipper response : ", data);
                if (data.status === 1)
                    this.GetShipperData();
                else
                    message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
            }).catch(function (error) { console.log(error); });
        }
        if (this.state.iDlgType === 2) {
            message.loading({ content: '寄件人 删除中……', key: g_strMessageKey });
            axios.post('/DeleteJetonShipper', { id: this.objCurrentShipper.id })
                .then(({ data }) => {
                    if (data.status === -1) {
                        message.warning({ content: "删除失败，请重新尝试！", key: g_strMessageKey, duration: 2 });
                        this.setState({
                            ...this.state,
                            bLoading: false,
                            bShowDlg: false,
                            selectedRowKeys: []
                        });
                    } else {
                        this.GetShipperData();
                    }
                }).catch(function (error) { console.log(error); });
        }
    };
    // 弹窗取消按钮响应
    handleCancel = e => {

        this.setState({
            ...this.state,
            bShowDlg: false,
            selectedRowKeys: []
        });
    };
    // 整理Shipper数据
    OrganizeShipperData(data) {
        let arrTableData = [];
        for (let i = 0; i < data.length; i++) {
            let strCompany = data[i].company;
            if (strCompany === null || strCompany === undefined)
                strCompany = "";
            let strEmail = data[i].email;
            if (strEmail === null || strEmail === undefined)
                strEmail = "";
            if (data[i].price_off === null || data[i].price_off === "")
                data[i].price_off = 0;
            let objUserData = {
                key: i, id: data[i].id,
                store_id: data[i].store_id,
                user_id: data[i].user_id,
                name: data[i].name,
                company: strCompany,
                phone: data[i].phone,
                email: strEmail,
                address: data[i].address,
                postcode: data[i].postcode,
                country: data[i].country,
                province: data[i].province,
                province_str: this.objCAProvince[data[i].province],
                city: data[i].city
            }
            arrTableData.push(objUserData);
        }
        return arrTableData;
    }
    // 从数据库获取当前的数据结构
    GetShipperData = () => {
        message.loading({ content: '数据加载中……', key: g_strMessageKey });
        axios('/GetJetonShippers', { params: { store_id: this.props.iStoreID, user_id: this.props.iUserID, level: this.props.iLevel } })
            .then(({ data }) => {
                console.log("GetJetonShippers response : ", data);
                global.arrShipperData = data;
                this.arrOgeTableData = this.OrganizeShipperData(data);
                this.arrTableData = this.arrOgeTableData;
                if (this.state.bShowDlg) {
                    if (this.state.iDlgType === 0) {
                        message.success({ content: "新建 寄件人 成功！", key: g_strMessageKey, duration: 2 });
                    }
                    if (this.state.iDlgType === 1) {
                        message.success({ content: "寄件人 信息已更新！", key: g_strMessageKey, duration: 2 });
                    }
                    if (this.state.iDlgType === 2) {
                        message.success({ content: "寄件人 已删除！", key: g_strMessageKey, duration: 2 })
                    }
                } else {
                    message.success({ content: "数据加载完成！", key: g_strMessageKey, duration: 2 });
                }
                this.setState({
                    ...this.state,
                    bLoading: false,
                    bShowDlg: false,
                    selectedRowKeys: []
                });
            }).catch(function (error) { console.log(error); });
    }
    // 显示全部寄件人
    ShowAllShippers = () => {
        this.refs.name_search.state.value = "";
        this.ShipperNameSearch();
    }
    // 切换地区
    SwitchCAProvince = (index,) => {
        this.setState({
            ...this.state,
            iCurrentProvince: index
        });
    }

    /* React自带函数 */
    // 卸载异步操作设置状态
    componentWillUnmount() {
        clearTimeout(this.timeouter)
        this.setState = (state, callback) => {
            return;
        }
    }
    // 渲染完毕……
    componentDidMount() {
        this.GetShipperData();
    }
    // 渲染函数
    render() {

        let uiTitleArea = (
            < div className="store_contents_title" >
                <Button className="manage_contents_title_margin manage_contents_title_margin_right" type="primary" onClick={this.ShowAllShippers}>
                    显示全部<Icon type="redo" /></Button>
                <div className="manage_stretch_right">
                    <Input placeholder="搜索寄件人名/公司/电话等" ref="name_search" defaultValue={this.state.strKeywords} />
                    <Button className="manage_contents_title_margin"
                        type="primary" onClick={this.ShipperNameSearch}>搜索
                    </Button>
                </div>

                <div className="manage_title_button_right_side">
                    <Button className="manage_contents_title_margin" type="primary" onClick={this.ShowDlg.bind(this, 0)} disabled={this.state.btnControlDisable[0]}>
                        <Icon type="edit" />新建</Button>
                    <Button className="manage_contents_title_margin" type="primary" onClick={this.ShowDlg.bind(this, 1)} disabled={this.state.btnControlDisable[1]}>
                        <Icon type="edit" />编辑</Button>
                    <Button className="manage_contents_title_margin" type="danger" onClick={this.ShowDlg.bind(this, 2)} disabled={this.state.btnControlDisable[2]}>
                        <Icon type="close" />删除</Button>
                </div>
            </div >
        );

        // 如果界面上需要显示的内容已经从数据库获得
        if (!this.state.bLoading) {
            // 主体表格相关
            const { selectedRowKeys } = this.state;
            const rowSelection = {
                selectedRowKeys,
                onChange: this.onSelectedRowKeysChange,
                columnWidth: 30
            };
            // 表格分页属性
            const paginationProps = {
                showSizeChanger: true,
                showQuickJumper: false,
                showTotal: () => `共 ${this.arrTableData.length} 个 寄件人`,
                defaultPageSize: 10,
                defaultCurrent: this.state.iCurrentPage,
                pageSizeOptions: ['10', '20', '30', '40', '50'],
                total: this.arrTableData.length,
                position: 'bottom'
            };
            // 地区下拉菜单
            const menuCNAreasShip = (
                <Menu>
                    {this.objCAProvince.map((province, index) => {
                        return (
                            <Menu.Item key={index} onClick={this.SwitchCAProvince.bind(this, index)}>
                                {province}
                            </Menu.Item>
                        );
                    })}
                </Menu>
            );
            // 寄件人信息编辑弹窗
            let uiModal = (
                <Modal title="寄件人信息" width={999} visible={this.state.bShowDlg}
                    okText="确定" cancelText="取消" onOk={this.handleOk} onCancel={this.handleCancel} >
                    <div className="jeton_order_block">
                        <div className="jeton_order_row">
                            <div className="jeton_order_row_title jeton_order_row_title_require" >寄件人：</div>
                            <Input className="jeton_order_row_input" ref="name"
                                defaultValue={this.objCurrentShipper.name} />
                            <div className="jeton_order_row_title" >公司：</div>
                            <Input className="jeton_order_row_input" ref="company"
                                defaultValue={this.objCurrentShipper.company} />
                        </div>
                        <div className="jeton_order_row">
                            <div className="jeton_order_row_title jeton_order_row_title_require" >电话：</div>
                            <Input className="jeton_order_row_input" ref="phone"
                                defaultValue={this.objCurrentShipper.phone} />
                            <div className="jeton_order_row_title" >邮箱：</div>
                            <Input className="jeton_order_row_input" ref="email"
                                defaultValue={this.objCurrentShipper.email} />
                        </div>
                        <div className="jeton_order_row">
                            <div className="jeton_order_row_title jeton_order_row_title_require" >国家地区</div>
                            <Input className="jeton_order_row_input" ref="country" disabled value={"CA"}
                                defaultValue={this.objCurrentShipper.country} />
                            <div className="jeton_order_row_title jeton_order_row_title_require" >省份：</div>
                            <Dropdown overlay={menuCNAreasShip} trigger={['click']} placement="bottomLeft" className={"jeton_order_row_input"}
                                overlayStyle={{ maxHeight: '75vh', overflow: 'auto' }} style={{ maxHeight: '75vh', overflow: 'auto' }} disabled>
                                <Button className="jeton_order_row_input">
                                    {/* <div className="order_details_dropdown_text">{this.objCAProvince[this.state.iCurrentProvince]}</div> */}
                                    <div className="order_details_dropdown_text">BC</div>
                                    <Icon type="caret-down" />
                                </Button>
                            </Dropdown>
                            <div className="jeton_order_row_title jeton_order_row_title_require" >邮编：</div>
                            <Input className="jeton_order_row_input" ref="postcode" disabled
                                defaultValue={this.objCurrentShipper.postcode} value="V6X1X5" />
                        </div>
                        <div className="jeton_order_row">
                            <div className="jeton_order_row_title jeton_order_row_title_require" >详细地址</div>
                            <Input className="jeton_order_row_input_long" ref="address" disabled
                                value="7080 River Road, Unit 115-120"
                                defaultValue={this.objCurrentShipper.address} />
                            <div className="jeton_order_row_title" >城市：</div>
                            <Input className="jeton_order_row_input" ref="city" disabled
                                defaultValue={this.objCurrentShipper.city} value="Richmond" />
                        </div>
                    </div>
                </Modal>
            );
            if (this.state.iDlgType === 2) {
                uiModal = (
                    <Modal width={500} title={"删除寄件人"} visible={this.state.bShowDlg} okType="danger"
                        onOk={this.handleOk} okText="确定"
                        onCancel={this.handleCancel} cancelText="取消">
                        <div className="modal_item">
                            <div>{this.objCurrentShipper.name}
                                <br /><br />选中寄件人的所有信息将被删除……<br />确定删除选中的寄件人？
                            </div>
                        </div>
                    </Modal>
                );
            }

            return (
                <div className="store_manage_contents store_manage_top_space">
                    {uiTitleArea}
                    {uiModal}
                    {/* 表格主体 */}
                    <Table style={{ margin: '10px', marginLeft: '25px', width: '95%', overflowX: 'auto' }} bordered
                        rowSelection={rowSelection} pagination={paginationProps}
                        columns={arrTableHeader} dataSource={this.arrTableData}
                        onRow={(record) => ({ onClick: () => { this.selectRow(record); } })} />
                </div >
            );
        } else {
            return (
                <div className="store_manage_contents store_manage_top_no_space">
                    {uiTitleArea}
                    <Spin className="manage_empty_loading" size="large" tip="加载中……" />
                </div>
            );
        }
    }
}
export default ShipOrderShipperManager;
