import React, { Component } from "react";
import axios from 'axios';
import "antd/dist/antd.css";

import Input from 'antd/es/input';
import Icon from 'antd/es/icon';
import Button from 'antd/es/button';
import Switch from 'antd/es/switch';
import Modal from 'antd/es/modal';
import message from 'antd/es/message';

import "../../../styles/Background/store_manager.css";
import "../../../styles/Background/UI_Module/delivery_fee_calculator.css";

import PriceDetailDlg from "./PriceDetailDlg.js";

const g_strMessageKey = 'updating';

class DeliveryFeeCalculator extends Component {
    constructor(props) {
        super();
        this.state = {
            bLoading: false,
            arrCPFeeResult: [],
            arrUPSFeeResult: [],
            bItemValueChange: false,
            iReceiverDlg: -1,
            bReceiverDlg: false,
            iCurrentReceiverProvince: 0,
            iPriceDetailDlg: -1,
            bPriceDetailDlg: false,
        };
        this.objCurrentJetonOrder = {};
        this.objCurrentShipper = { index: -1 };
        this.objCurrentReceiver = { index: -1 };
        this.arrPackageList = [];
        this.arrExpressServiceInfoList = [
            { icon: "canada_post_icon", name: "Canada Post Expeited Parcel", time: "", price: "" },
            { icon: "canada_post_icon", name: "Canada Post Priority", time: "", price: "" },
            { icon: "canada_post_icon", name: "Canada Post Regular Parcel", time: "", price: "" },
            { icon: "canada_post_icon", name: "Canada Post Xpresspost", time: "", price: "" },
            { icon: "ups_icon", name: "UPS Express", time: "", price: "" },
            { icon: "ups_icon", name: "UPS Express Saver", time: "", price: "" },
            { icon: "ups_icon", name: "UPS Expeditied", time: "", price: "" },
            { icon: "ups_icon", name: "UPS Standard", time: "", price: "" }

        ];
        this.arrUPSExpress = [
            { name: 'UPS Express', id: '01', category: 'Canadian domestic shipments' },
            { name: 'UPS Express Saver', id: '13', category: 'Canadian domestic shipments' },
            { name: 'UPS Expedited', id: '02', category: 'Canadian domestic shipments' },
            { name: 'UPS Standard', id: '11', category: 'Shipments originating in Canada' },

            // { name: 'UPS Worldwide Express', id: '07', category: 'International shipments originating in Canada' },
            // { name: 'UPS Worldwide Expedited', id: '08', category: 'International shipments originating in Canada' },
            // { name: 'UPS Worldwide Express Saver', id: '65', category: 'International shipments originating in Canada' }
        ];
        this.arrCAProvince = ["AB", "BC", "MB", "NB", "NL", "NS", "NT", "NU", "ON", "PE", "QC", "SK", "YT"];
        this.bUnitCmKg = true;
        this.bCalculating = false;
        this.fLbToKg = 0.453592374;
        this.fInToCm = 2.54;

        this.iCPCalculateResultNum = 0;
        this.arrCPFeeResultTotal = [0.0, 0.0, 0.0, 0.0];
        this.arrCPDeliveryDays = [0, 0, 0, 0];
        this.iUPSCalculateResultNum = 0;
        this.arrUPSFeeResultTotal = [0.0, 0.0, 0.0, 0.0];
        this.arrUPSDeliveryDays = [0, 0, 0, 0];

        this.arrCPPriceDetails = [null, null, null, null];
        this.arrUPSPriceDetails = [null, null, null, null];
        this.objCurrentPriceDetail = {};
        this.iCurrentExpressType = 0;  // 0-Canada Post, 1-UPS

        this.bCPWarning = false;
        this.bUPSWarning = false;
        this.strCPWarningMessage = "";
        this.strUPSWarningMessage = "";

    }

    // 计算运费
    CalculateDeliveryFee = () => {

        if (this.bCalculating) {
            message.warning("运费计算中，请稍等……");
        }

        this.bCPWarning = false;
        this.strCPWarningMessage = "";
        this.arrCPPriceDetails = [null, null, null, null];

        this.bUPSWarning = false;
        this.strUPSWarningMessage = "";
        this.arrUPSPriceDetails = [null, null, null, null];

        let strShipperPostcode = this.refs.shipper_postcode.state.value;
        let strReceiverPostcode = this.refs.receiver_postcode.state.value;
        if (strShipperPostcode === null || strShipperPostcode === "" || strShipperPostcode === undefined) {
            message.warning("发货邮编不能为空！"); return;
        }
        if (strReceiverPostcode === null || strReceiverPostcode === "" || strReceiverPostcode === undefined) {
            message.warning("收货邮编不能为空！"); return;
        }
        this.iCPCalculateResultNum = 0;
        this.arrCPFeeResultTotal = [0.0, 0.0, 0.0, 0.0];

        let strWeight = "";
        let strLength = "";
        let strWidth = "";
        let strHeight = "";
        let strBoxNum = "";
        // 逐一检查包裹长宽高重量等数据是否正常
        for (let i = 0; i < this.arrPackageList.length; i++) {
            strWeight = this.arrPackageList[i].weight
            strLength = this.arrPackageList[i].length;
            strWidth = this.arrPackageList[i].width;
            strHeight = this.arrPackageList[i].height;
            strBoxNum = this.arrPackageList[i].box;

            if (isNaN(parseFloat(strWeight))) {
                this.strCPWarningMessage = "货物重量无效！";
                message.warning("货物重量无效！");
                this.setState({
                    ...this.state,
                    bLoading: false
                });
                return;
            }
            strWeight = strWeight.toString().replaceAll(' ', '');
            strWeight = parseFloat(strWeight).toFixed(3).toString();
            if (!this.bUnitCmKg)
                strWeight = (parseFloat(strWeight) * this.fLbToKg).toFixed(3).toString();
            else
                strWeight = parseFloat(strWeight).toFixed(3).toString();
            if (isNaN(parseFloat(strLength))) {
                message.warning("货物长度无效！"); return;
            }
            strLength = strLength.toString().replaceAll(' ', '');
            if (!this.bUnitCmKg)
                strLength = (parseFloat(strLength) * this.fInToCm).toFixed(3).toString();
            else
                strLength = parseFloat(strLength).toFixed(3).toString();

            if (isNaN(parseFloat(strWidth))) {
                message.warning("货物宽度无效！"); return;
            }
            strWidth = strWidth.toString().replaceAll(' ', '');
            if (!this.bUnitCmKg)
                strWidth = (parseFloat(strWidth) * this.fInToCm).toFixed(3).toString();
            else
                strWidth = parseFloat(strWidth).toFixed(3).toString();

            if (isNaN(parseFloat(strHeight))) {
                message.warning("货物高度无效！"); return;
            }
            strHeight = strHeight.toString().replaceAll(' ', '');
            if (!this.bUnitCmKg)
                strHeight = (parseFloat(strHeight) * this.fInToCm).toFixed(3).toString();
            else
                strHeight = parseFloat(strHeight).toFixed(3).toString();


            if (isNaN(parseFloat(strBoxNum))) {
                message.warning("包裹数量无效！"); return;
            }

        }

        // Canada Post API 调取
        for (let i = 0; i < this.arrPackageList.length; i++) {
            strWeight = this.arrPackageList[i].weight
            strLength = this.arrPackageList[i].length;
            strWidth = this.arrPackageList[i].width;
            strHeight = this.arrPackageList[i].height;
            strBoxNum = this.arrPackageList[i].box;

            strWeight = strWeight.toString().replaceAll(' ', '');
            strWeight = parseFloat(strWeight).toFixed(3).toString();
            if (!this.bUnitCmKg) {
                strWeight = (parseFloat(strWeight) * this.fLbToKg).toFixed(3).toString();
                if (strWeight > 66) {
                    this.bCPWarning = true;
                    this.strCPWarningMessage = "货物的重量不能超过66磅(30kg)";
                    // message.warning("货物超重1");
                    this.setState({
                        ...this.state,
                        bLoading: false
                    });
                    break;
                }
            } else {
                strWeight = parseFloat(strWeight).toFixed(3).toString();
                if (strWeight > 30) {
                    this.bCPWarning = true;
                    this.strCPWarningMessage = "货物的重量不能超过66磅(30kg)";
                    // message.warning("货物超重2");
                    this.setState({
                        ...this.state,
                        bLoading: false
                    });
                    break;
                }
            }

            strLength = strLength.toString().replaceAll(' ', '');
            if (!this.bUnitCmKg)
                strLength = (parseFloat(strLength) * this.fInToCm).toFixed(3).toString();
            else
                strLength = parseFloat(strLength).toFixed(3).toString();

            strWidth = strWidth.toString().replaceAll(' ', '');
            if (!this.bUnitCmKg)
                strWidth = (parseFloat(strWidth) * this.fInToCm).toFixed(3).toString();
            else
                strWidth = parseFloat(strWidth).toFixed(3).toString();

            strHeight = strHeight.toString().replaceAll(' ', '');
            if (!this.bUnitCmKg)
                strHeight = (parseFloat(strHeight) * this.fInToCm).toFixed(3).toString();
            else
                strHeight = parseFloat(strHeight).toFixed(3).toString();

            // 长宽高限制
            let fLengthTotal = parseFloat(strLength) + (parseFloat(strWidth) + parseFloat(strHeight)) * 2;
            if (!this.bUnitCmKg) {
                if (fLengthTotal > 118) {
                    this.bCPWarning = true;
                    this.strCPWarningMessage = "货物的 长度 + (宽度+高度)*2 不能超过300cm(118in)";
                    // message.warning("货物超长！");
                    this.setState({
                        ...this.state,
                        bLoading: false
                    });
                    break;
                }
            } else {
                if (fLengthTotal > 300) {
                    this.bCPWarning = true;
                    this.strCPWarningMessage = "货物的 长度 + (宽度+高度)*2 不能超过300cm(118in)";
                    // message.warning("货物超长！");
                    this.setState({
                        ...this.state,
                        bLoading: false
                    });
                    break;
                }
            }

            strBoxNum = strBoxNum.toString().replaceAll(' ', '');
            let iBox = parseInt(strBoxNum);

            // 加邮 API 调取
            let objCPData = {
                originPostalCode: strShipperPostcode,
                destPostalCode: strReceiverPostcode,
                weight: strWeight,
                length: strLength,
                width: strWidth,
                height: strHeight
            }
            // console.log("package ", i, objCPData, iBox);
            // 如果没有出发CP的警告，就调用CP的API
            if (!this.bCPWarning) {
                axios.post('/cpapi/rate', objCPData).then(({ data }) => {
                    console.log("CP Fee Result : ", i, data);

                    if (data === undefined || data === null || data === "")
                        data = [];
                    let objPriceDetail = {
                        base: 0.00,
                        gst: 0.00,
                        pst: 0.00,
                        hst: 0.00,
                        service: 0.00,
                        price: 0.00
                    }
                    // 不同的加邮运送方式（共4种）
                    for (let j = 0; j < data.length; j++) {
                        data[j].price.base *= iBox;
                        data[j].price.gst *= iBox;
                        data[j].price.pst *= iBox;
                        data[j].price.hst *= iBox;
                        data[j].price.total *= iBox;
                        objPriceDetail = data[j].price;
                        objPriceDetail.service = objPriceDetail.total - objPriceDetail.base - objPriceDetail.gst - objPriceDetail.pst - objPriceDetail.hst;
                        objPriceDetail.service = objPriceDetail.service.toFixed(2);
                        objPriceDetail.name = data[j].service.name;
                        this.arrCPFeeResultTotal[j] += data[j].price.total;
                        this.arrCPDeliveryDays[j] = data[j].serviceStandard.expectedTransitTime;
                        if (this.arrCPPriceDetails[j] === null)
                            this.arrCPPriceDetails[j] = objPriceDetail;
                        else {
                            console.log("objPriceDetail : ", objPriceDetail);
                            this.arrCPPriceDetails[j].base += objPriceDetail.base;
                            this.arrCPPriceDetails[j].gst += objPriceDetail.gst;
                            this.arrCPPriceDetails[j].pst += objPriceDetail.pst;
                            this.arrCPPriceDetails[j].hst += objPriceDetail.hst;
                            this.arrCPPriceDetails[j].service = parseFloat(this.arrCPPriceDetails[j].service) + parseFloat(objPriceDetail.service);
                            this.arrCPPriceDetails[j].total += objPriceDetail.total;
                        }
                    }


                    this.iCPCalculateResultNum++;
                    if (this.iCPCalculateResultNum >= this.arrPackageList.length) {
                        this.bCalculating = false;
                        // console.log("加邮运费计算结束,需要进行所有保所数据的累加");
                        this.setState({
                            ...this.state,
                            bLoading: false,
                            arrCPFeeResult: data
                        });
                    }

                });
            }
        }

        // UPS API 调取
        this.iUPSCalculateResultNum = 0;
        this.arrUPSFeeResultTotal = [0.0, 0.0, 0.0, 0.0];

        let arrUnit = ["CM", "KGS"];
        if (!this.bUnitCmKg)
            arrUnit = ["IN", "LBS"]
        let arrUPSPackage = [];
        // 检查包裹数据信息
        for (let i = 0; i < this.arrPackageList.length; i++) {
            strWeight = this.arrPackageList[i].weight
            strLength = this.arrPackageList[i].length;
            strWidth = this.arrPackageList[i].width;
            strHeight = this.arrPackageList[i].height;
            strWeight = strWeight.toString().replaceAll(' ', '');
            strWeight = parseFloat(strWeight).toFixed(2).toString();
            if (!this.bUnitCmKg) {
                strWeight = (parseFloat(strWeight) * this.fLbToKg).toFixed(2).toString();
                if (strWeight > 132) {
                    this.bUPSWarning = true;
                    this.strUPSWarningMessage = "货物的重量不能超过132磅(60kg)";
                    // message.warning("货物超重1");
                    this.setState({
                        ...this.state,
                        bLoading: false
                    });
                    break;
                }
            }
            else {
                strWeight = parseFloat(strWeight).toFixed(2).toString();
                if (strWeight > 60) {
                    this.bUPSWarning = true;
                    this.strUPSWarningMessage = "货物的重量不能超过132磅(60kg)";
                    // message.warning("货物超重2");
                    this.setState({
                        ...this.state,
                        bLoading: false
                    });
                    break;
                }
            }

            if (isNaN(parseFloat(strLength))) {
                message.warning("货物长度无效！"); return;
            }
            strLength = strLength.toString().replaceAll(' ', '');
            if (!this.bUnitCmKg)
                strLength = (parseFloat(strLength) * this.fInToCm).toFixed(2).toString();
            else
                strLength = parseFloat(strLength).toFixed(2).toString();

            if (isNaN(parseFloat(strWidth))) {
                message.warning("货物宽度无效！"); return;
            }
            strWidth = strWidth.toString().replaceAll(' ', '');
            if (!this.bUnitCmKg)
                strWidth = (parseFloat(strWidth) * this.fInToCm).toFixed(2).toString();
            else
                strWidth = parseFloat(strWidth).toFixed(2).toString();

            if (isNaN(parseFloat(strHeight))) {
                message.warning("货物高度无效！"); return;
            }
            strHeight = strHeight.toString().replaceAll(' ', '');
            if (!this.bUnitCmKg)
                strHeight = (parseFloat(strHeight) * this.fInToCm).toFixed(2).toString();
            else
                strHeight = parseFloat(strHeight).toFixed(2).toString();

            let objPackage = {
                "PackagingType": {
                    "Code": "02",
                    "Description": "Package"
                },
                "Dimensions": {
                    "UnitOfMeasurement": {
                        "Code": arrUnit[0]
                    },
                    "Length": strLength,
                    "Width": strWidth,
                    "Height": strHeight,
                },
                "PackageWeight": {
                    "UnitOfMeasurement": {
                        "Code": arrUnit[1]
                    },
                    "Weight": strWeight
                }
            };

            // if (this.bSignAll)
            //     objPackage = {
            //         "PackagingType": {
            //             "Code": "02",
            //             "Description": "Package"
            //         },
            //         "Dimensions": {
            //             "UnitOfMeasurement": {
            //                 "Code": arrUnit[0]
            //             },
            //             "Length": this.arrPackageInfo[i].length,
            //             "Width": this.arrPackageInfo[i].width,
            //             "Height": this.arrPackageInfo[i].height,
            //         },
            //         "PackageWeight": {
            //             "UnitOfMeasurement": {
            //                 "Code": arrUnit[1]
            //             },
            //             "Weight": this.arrPackageInfo[i].weight
            //         },
            //         "PackageServiceOptions": {
            //             "DeliveryConfirmation": {
            //                 "DCISType": "2"
            //             }
            //         }
            //     };

            let iBox = parseInt(this.arrPackageList[i].box);
            if (iBox > 1) {
                for (let i = 0; i < iBox; i++)
                    arrUPSPackage.push(objPackage);
            } else {
                arrUPSPackage.push(objPackage);
            }
        }
        // 4类UPS的服务
        let arrUPSFeeResult = [];
        this.setState({
            ...this.state,
            bLoading: true,
            arrUPSFeeResult: []
        })
        if (!this.bUPSWarning) {
            for (let i = 0; i < this.arrUPSExpress.length; i++) {
                let data = {
                    "RateRequest": {
                        "Request": {
                            "SubVersion": "1703",
                            "TransactionReference": {
                                "CustomerContext": ""
                            }
                        },
                        "Shipment": {
                            "DeliveryTimeInformation": "TRUE",
                            "ShipmentRatingOptions": {
                                "NegotiatedRatesIndicator": "TRUE"
                            },
                            "Shipper": {
                                "Name": "",//this.refs.shipper_name.state.value,
                                "ShipperNumber": "00R549",
                                "Address": {
                                    "AddressLine": this.refs.shipper_address.state.value,
                                    "City": "", //this.refs.shipper_city.state.value,
                                    "StateProvinceCode": this.arrCAProvince[1],//this.arrCAProvince[this.objCurrentShipper.province],
                                    "PostalCode": this.refs.shipper_postcode.state.value,
                                    "CountryCode": "CA"
                                }
                            },
                            "ShipFrom": {
                                "Name": "", //this.refs.shipper_name.state.value,
                                "Address": {
                                    "AddressLine": this.refs.shipper_address.state.value,
                                    "City": "", //this.refs.shipper_city.state.value,
                                    "StateProvinceCode": this.arrCAProvince[1],//this.arrCAProvince[this.objCurrentShipper.province],
                                    "PostalCode": this.refs.shipper_postcode.state.value,
                                    "CountryCode": "CA"
                                }
                            },
                            "ShipTo": {
                                "Name": "", //this.refs.receiver_name.state.value,
                                "Address": {
                                    "AddressLine": this.refs.receiver_address.state.value,
                                    "City": "", //this.refs.receiver_city.state.value,
                                    "StateProvinceCode": this.arrCAProvince[1],//this.arrCAProvince[this.objCurrentReceiver.province],
                                    "PostalCode": this.refs.receiver_postcode.state.value,
                                    "CountryCode": "CA"
                                }
                            },
                            "Service": {
                                "Code": this.arrUPSExpress[i].id,
                                "Description": "UPS Shippinig Option"
                            },
                            "Package":
                                arrUPSPackage
                        }
                    }
                }

                axios('/UPS_API_Rating', { params: { data: data } })
                    .then(({ data }) => {

                        if (data.status === -1) {
                            console.log("UPS_API_Rating : response : ", data.message);
                        }
                        else {
                            console.log("UPS_API_Rating : response : ", data);
                        }

                        if (data.RateResponse !== undefined) {
                            let objRatedShipment = data.RateResponse.RatedShipment;
                            this.arrUPSPriceDetails[i] = objRatedShipment;
                            let fUPSFee = objRatedShipment.NegotiatedRateCharges.TotalCharge.MonetaryValue * 1.05;
                            this.arrUPSFeeResultTotal[i] = fUPSFee.toFixed(2);
                            let objDeliveryDays = objRatedShipment.GuaranteedDelivery;
                            if (objDeliveryDays === null || objDeliveryDays === undefined) {
                                this.arrUPSDeliveryDays[i] = 1;
                                if (i === 3)
                                    this.arrUPSDeliveryDays[i] = 2;
                            }
                            else {
                                // console.log("objDeliveryDays = ", objDeliveryDays);
                                let fUPSDays = objDeliveryDays.BusinessDaysInTransit;
                                this.arrUPSDeliveryDays[i] = fUPSDays;
                            }
                        }

                        this.bCalculating = false;
                        arrUPSFeeResult.push(data);
                        this.setState({
                            ...this.state,
                            bLoading: false,
                            arrUPSFeeResult: arrUPSFeeResult
                        });
                    }).catch(function (error) { console.log(error); });
            }
        }

        // 进入计算状态……
        if (!this.bCalculating) {
            message.loading({ content: '价格计算中……', key: g_strMessageKey });
            this.bCalculating = true;
        }
        if (this.bCPWarning && this.bUPSWarning) {
            // message.warning("加邮和UPS都有超规，结束计算！");
            this.bCalculating = false;
        }

        // console.log("Calculte Price : package info = ", this.arrPackageList);

        this.setState({
            ...this.state,
            bLoading: true
        });


    }
    // 单位切换
    UnitSwitch = (checked) => {
        this.bUnitCmKg = checked;
        this.setState({
            ...this.state,
            bItemValueChange: true
        });
    }
    // 物品信息发生变化
    PackageInfoChange = (indexPackage, value, e) => {
        // 长
        if (value === 0)
            this.arrPackageList[indexPackage].length = e.target.value;
        // 宽
        if (value === 1)
            this.arrPackageList[indexPackage].width = e.target.value;
        // 高
        if (value === 2)
            this.arrPackageList[indexPackage].height = e.target.value;
        // 重
        if (value === 3)
            this.arrPackageList[indexPackage].weight = e.target.value;
        // 数量
        if (value === 4)
            this.arrPackageList[indexPackage].box = e.target.value;
        // 描述
        if (value === 5)
            this.arrPackageList[indexPackage].comments = e.target.value;
        this.setState({
            ...this.state,
            bItemValueChange: true
        });
    }
    // 新增一个包裹
    AddPackage = (objNewPacakge) => {
        let objPackageInfoDefault = {};
        if (objNewPacakge == null) {
            objPackageInfoDefault = {
                box: 1,
                weight: 1.0,
                length: 10.0,
                width: 10.0,
                height: 10.0,
                comments: ""
            }
        }
        else {
            objPackageInfoDefault = objNewPacakge;
        }
        this.arrPackageList.push(objPackageInfoDefault);
        this.setState({
            ...this.state,
            bItemValueChange: true
        });
    }
    // 清空包裹列表
    CleanPackage = () => {
        this.arrPackageList = [];
        this.setState({
            ...this.state,
            arrCPFeeResult: [],
            arrUPSFeeResult: [],
            bItemValueChange: true
        });
    }
    // 移除一个包裹
    RemovePackageInfo = (indexPackage) => {
        this.arrPackageList.splice(indexPackage, 1);
        this.setState({
            ...this.state,
            bItemValueChange: true
        });

    }
    // 拷贝并复制包裹
    CopyPastePackage = (indexPackage) => {
        let objPackageInfoDefault = {};
        objPackageInfoDefault = Object.assign({}, this.arrPackageList[indexPackage]);
        this.AddPackage(objPackageInfoDefault);
    }
    // 从数据库获取当前的数据结构
    GetShipperData = () => {
        axios('/GetJetonShippers', { params: { store_id: this.props.iStoreID, user_id: this.props.iUserID, level: this.props.iLevel } })
            .then(({ data }) => {
                global.arrShipperData = data;
                // console.log("GetShipperData : ", data);
            }).catch(function (error) { console.log(error); });
    }
    // 从数据库获取当前的数据结构
    GetReceiverData = () => {
        axios('/GetJetonReceivers', { params: { store_id: this.props.iStoreID, user_id: this.props.iUserID, level: this.props.iLevel } })
            .then(({ data }) => {
                global.arrReceiverData = data;
                // console.log("GetReceiverData : ", data);
            }).catch(function (error) { console.log(error); });
    }
    // 显示寄件人对话框
    ShowShipperDlg = (iShow, bShow) => {
        if (iShow === 1) {
            this.setState({
                ...this.state,
                iShipperDlg: iShow,
                bShipperDlg: bShow,
                iCurrentShipperProvince: this.objCurrentShipper.province
            });
            // this.CalculatePrice(this.state.iCurrentUnit, this.state.iCurrentUPSExpress);
        } else
            this.setState({
                ...this.state,
                iShipperDlg: iShow,
                bShipperDlg: bShow
            });
    }
    // 选择寄件人
    SelectShipper = (index) => {
        this.objCurrentShipper = global.arrReceiverData[index];
        this.objCurrentShipper.index = index;
        // console.log("SelectShipper : ", this.objCurrentShipper);
        // this.refs.shipper_name.state.value = this.objCurrentShipper.name;
        // this.refs.shipper_phone.state.value = this.objCurrentShipper.phone;
        // this.refs.shipper_email.state.value = this.objCurrentShipper.email;
        this.refs.shipper_address.state.value = this.objCurrentShipper.address;
        this.refs.shipper_postcode.state.value = this.objCurrentShipper.postcode;
        // this.refs.shipper_company.state.value = this.objCurrentShipper.company;
        // this.refs.shipper_city.state.value = this.objCurrentShipper.city;
        this.objCurrentJetonOrder.shipper_postcode = this.objCurrentShipper.postcode;
        this.ShowShipperDlg(1, false);
    }
    // 显示收件人对话框
    ShowReceiverDlg = (iShow, bShow) => {
        if (iShow === 1) {
            this.setState({
                ...this.state,
                iReceiverDlg: iShow,
                bReceiverDlg: bShow,
                iCurrentReceiverProvince: this.objCurrentReceiver.province
            });
            // this.CalculatePrice(this.state.iCurrentUnit, this.state.iCurrentUPSExpress);
        }
        else {
            // console.log("ShowReceiverDlg :", iShow, bShow);
            this.setState({
                ...this.state,
                iReceiverDlg: iShow,
                bReceiverDlg: bShow
            });
        }
    }
    // 选择收件人
    SelectReceiver = (index) => {
        this.objCurrentReceiver = global.arrReceiverData[index];
        this.objCurrentReceiver.index = index;
        // console.log("SelectReceiver : ", this.objCurrentReceiver);
        // this.refs.receiver_name.state.value = this.objCurrentReceiver.name;
        // this.refs.receiver_phone.state.value = this.objCurrentReceiver.phone;
        // this.refs.receiver_email.state.value = this.objCurrentReceiver.email;
        this.refs.receiver_address.state.value = this.objCurrentReceiver.address;
        this.refs.receiver_postcode.state.value = this.objCurrentReceiver.postcode;
        // this.refs.receiver_company.state.value = this.objCurrentReceiver.company;
        // this.refs.receiver_city.state.value = this.objCurrentReceiver.city;
        this.objCurrentJetonOrder.receiver_postcode = this.objCurrentReceiver.postcode;
        this.ShowReceiverDlg(1, false);
    }
    // 显示价格详情对话框
    ShowPriceDetailDlg = (iShow, bShow, iPriceIndex) => {

        if (bShow) {
            this.GetPriceDetail(iPriceIndex);
            // console.log("ShowPriceDetailDlg : ", this.objCurrentPriceDetail);
        }

        this.setState({
            ...this.state,
            bPriceDetailDlg: bShow,
            iPriceDetailDlg: iShow
        });
    }
    // 获取价格明细
    GetPriceDetail = (iPriceIndex) => {
        // Canada Post
        if (iPriceIndex < 4) {
            this.objCurrentPriceDetail = this.arrCPPriceDetails[iPriceIndex];
            this.objCurrentPriceDetail.name = "Canada Post " + this.objCurrentPriceDetail.name;
            this.iCurrentExpressType = 0;
        }
        // UPS
        else {
            this.objCurrentPriceDetail = this.arrUPSPriceDetails[iPriceIndex - 4];
            this.objCurrentPriceDetail.name = this.arrUPSExpress[iPriceIndex - 4].name;
            this.iCurrentExpressType = 1;
        }
    }
    // 选择了某个运营商的某项运输服务
    SelectExpressService = (iExpressIndex) => {

        if (this.objCurrentShipper.index === -1) {
            this.objCurrentShipper.postcode = this.refs.shipper_postcode.state.value;
            this.objCurrentShipper.address = this.refs.shipper_address.state.value;
        }

        if (this.objCurrentReceiver.index === -1) {
            this.objCurrentReceiver.postcode = this.refs.receiver_postcode.state.value;
            this.objCurrentReceiver.address = this.refs.receiver_address.state.value;
        }

        this.objCurrentJetonOrder.shipper_info = this.objCurrentShipper;
        this.objCurrentJetonOrder.receiver_info = this.objCurrentReceiver;
        this.objCurrentJetonOrder.package_info = this.arrPackageList;
        this.objCurrentJetonOrder.unit = 0;
        if (!this.bUnitCmKg)
            this.objCurrentJetonOrder.unit = 1;
        this.GetPriceDetail(iExpressIndex);
        this.objCurrentJetonOrder.price_detail = this.objCurrentPriceDetail;
        // console.log("SelectExpressService: this.objCurrentJetonOrder  = ", this.objCurrentJetonOrder);
        this.props.JumpToJetonOrder(iExpressIndex, this.objCurrentJetonOrder);

    }

    // 卸载异步操作设置状态
    componentWillUnmount() {
        clearTimeout(this.timeouter)
        this.setState = (state, callback) => {
            return;
        }
    }
    // 渲染完毕……
    componentDidMount() {
        this.GetShipperData();
        this.GetReceiverData();
    }

    render() {

        // 地址区域
        let uiAddressArea = (
            < div className="jeton_address_detail_area" >
                <div className="jeton_area_title">地址详情</div>
                <div className="jeton_general_row">
                    <div className="jeton_address_sub_area">
                        <div className="jeton_area_subtitle">
                            发货地
                            <Button type="primary" className="jeton_order_block_button" onClick={this.ShowShipperDlg.bind(this, 0, true)}>选择</Button>
                        </div>
                        <div className="jeton_general_row">
                            <div className="jeton_address_vblock">
                                <div className="jeton_area_small_title">地址</div>
                                <Input defaultValue="7080 River Road, Unit 117" className="jeton_input_row_address" ref="shipper_address" />
                            </div>
                            <div className="jeton_postcode_vblock">
                                <div className="jeton_area_small_title">邮编*</div>
                                <Input defaultValue="V6X1X5" className="jeton_input_row_postcode" ref="shipper_postcode" />
                            </div>
                        </div>
                    </div>
                    <div className="jeton_address_sub_area">
                        <div className="jeton_area_subtitle">
                            收货地
                            <Button type="primary" className="jeton_order_block_button" onClick={this.ShowReceiverDlg.bind(this, 0, true)}>选择</Button>
                        </div>
                        <div className="jeton_general_row">
                            <div className="jeton_address_vblock">
                                <div className="jeton_area_small_title">地址</div>
                                <Input defaultValue="6240 Yeats Cresent" className="jeton_input_row_address" ref="receiver_address" />
                            </div>
                            <div className="jeton_postcode_vblock">
                                <div className="jeton_area_small_title">邮编*</div>
                                <Input defaultValue="V7E4C9" className="jeton_input_row_postcode" ref="receiver_postcode" />
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
        // 包裹列表
        let uiPackageInfoList = null;
        let uiCalculateFeeButton = null;
        if (this.arrPackageList.length > 0) {
            let strUnitLength = "cm";
            let strUnitWeight = "kg";
            if (!this.bUnitCmKg) {
                strUnitLength = "in";
                strUnitWeight = "lb";
            }
            uiPackageInfoList = (
                <div className="jeton_package_list_area">
                    {this.arrPackageList.map((package_info, indexPackage) => {
                        return (
                            <div key={indexPackage} className="jeton_general_row">
                                <div className="jeton_package_info_wlwh_area">
                                    <div className="jeton_area_small_title">长度 *</div>
                                    <Input className="jeton_input_package_info" addonAfter={strUnitLength}
                                        ref={"package_length" + indexPackage} defaultValue={package_info.length} value={package_info.length}
                                        onChange={this.PackageInfoChange.bind(this, indexPackage, 0)} />
                                </div>
                                <div className="jeton_package_info_wlwh_area">
                                    <div className="jeton_area_small_title">宽度 *</div>
                                    <Input className="jeton_input_package_info" addonAfter={strUnitLength}
                                        ref={"package_width" + indexPackage} defaultValue={package_info.width} value={package_info.width}
                                        onChange={this.PackageInfoChange.bind(this, indexPackage, 1)} />
                                </div>
                                <div className="jeton_package_info_wlwh_area">
                                    <div className="jeton_area_small_title">高度 *</div>
                                    <Input className="jeton_input_package_info" addonAfter={strUnitLength}
                                        ref={"package_height" + indexPackage} defaultValue={package_info.height} value={package_info.height}
                                        onChange={this.PackageInfoChange.bind(this, indexPackage, 2)} />
                                </div>
                                <div className="jeton_package_info_wlwh_area">
                                    <div className="jeton_area_small_title">重量 *</div>
                                    <Input className="jeton_input_package_info" addonAfter={strUnitWeight}
                                        ref={"package_weight" + indexPackage} defaultValue={package_info.weight} value={package_info.weight}
                                        onChange={this.PackageInfoChange.bind(this, indexPackage, 3)} />
                                </div>
                                <div className="jeton_package_info_num_area">
                                    <div className="jeton_area_small_title">数量 *</div>
                                    <Input className="jeton_input_package_info" ref={"package_num" + indexPackage}
                                        defaultValue={package_info.box} value={package_info.box}
                                        onChange={this.PackageInfoChange.bind(this, indexPackage, 4)} />
                                </div>
                                <div className="jeton_package_info_comments_area">
                                    <div className="jeton_area_small_title">描述</div>
                                    <Input defaultValue="" className="jeton_input_package_info" placeholder={"物品信息描述"}
                                        ref={"package_comments" + indexPackage} value={package_info.comments}
                                        onChange={this.PackageInfoChange.bind(this, indexPackage, 5)} />
                                </div>
                                <div className="jeton_package_info_action_area">
                                    <div className="jeton_area_small_title"> </div>
                                    <Button type="primary" style={{ marginRight: '5px' }}
                                        onClick={this.CopyPastePackage.bind(this, indexPackage)}>
                                        <Icon type="copy" style={{ fontSize: '16px' }} /></Button>
                                    <Button type="danger" style={{ marginRight: '0px' }}
                                        onClick={this.RemovePackageInfo.bind(this, indexPackage)}>
                                        <Icon type="delete" style={{ fontSize: '16px' }} /></Button>
                                </div>
                            </div>
                        );
                    })}
                </div>
            );
            if (!this.bCalculating) {

                uiCalculateFeeButton = (
                    <div className="jeton_general_row">

                        <Button type="primary" className="jeton_calculate_fee_button" onClick={this.CalculateDeliveryFee.bind(this)}>
                            <Icon type="calculator" />计算费率</Button>
                    </div>
                );
            }
            else {
                uiCalculateFeeButton = (
                    <div className="jeton_general_row">
                        <Button type="primary" className="jeton_calculate_fee_button" onClick={this.CalculateDeliveryFee.bind(this)}>
                            <Icon type="loading" />计算费率</Button>
                    </div>
                );
            }
        }
        else {
            uiPackageInfoList = (
                <div className="jeton_package_list_area">
                    <div className="jeton_center_row">
                        <div className="jeton_area_subtitle">包裹列表为空</div>
                    </div>
                </div>
            );
        }
        let uiPackageArea = (
            < div className="jeton_address_detail_area" >
                <div className="jeton_general_row">
                    <div className="jeton_area_title">包裹详情</div>
                    <div className="jeton_row_alien_right">
                        <Switch defaultChecked onChange={this.UnitSwitch.bind(this)} style={{ marginRight: '5px' }} />
                        <div className="jeton_area_subtitle" style={{ marginRight: '20px' }} >{"单位（厘米&千克）"}</div>
                        <Button type="primary" style={{ marginRight: '20px' }} onClick={this.AddPackage.bind(this, null)}>
                            <Icon type="code-sandbox" />添加包裹</Button>
                        <Button type="primary" style={{ marginRight: '20px' }} onClick={this.CleanPackage}>
                            <Icon type="delete" />清空</Button>
                    </div>
                </div>
                {uiPackageInfoList}
                {uiCalculateFeeButton}

            </div >
        );
        // 运费选项
        // let uiViewPriceDetail = (
        //     <Button type="primary" onClick={this.ShowPriceDetailDlg.bind(this, 0, true)}>
        //         价格明细</Button>
        // );
        // let uiSelectServiceButton = (
        //     <Button type="primary" onClick={this.AddPackage.bind(this, null)}>
        //         选 择</Button>
        // );

        let uiExpressFeeList = null;

        // 没有处于计算中
        if (!this.bCalculating) {
            // 至少有一个运营商有数据返回
            if (this.state.arrCPFeeResult.length > 0 || this.state.arrUPSFeeResult.length > 0) {
                uiExpressFeeList = (
                    < div className="jeton_delivery_fee_detail_area" >
                        {/* <div className="jeton_area_title">请选择最适合您的快递服务</div> */}
                        <div className="jeton_general_row">
                            <div className="jeton_express_icon_col" style={{ marginRight: '40px' }}></div>
                            <div className="jeton_area_subtitle jeton_express_name_col"><b>承运商</b></div>
                            <div className="jeton_area_subtitle jeton_express_deliver_time_col"><b>运送时间</b></div>
                            <div className="jeton_area_subtitle jeton_express_price_col"><b>总价</b></div>
                            <div className="jeton_express_select_col"><b>请选择适合您的快递服务</b></div>
                        </div>
                        <div >
                            {this.arrExpressServiceInfoList.map((express_info, indexExpress) => {
                                // Canada Post
                                if (indexExpress < 4) {
                                    // 如果CP超规，只显示提示
                                    if (this.bCPWarning) {
                                        if (indexExpress === 0) {
                                            let uiWarningMessage = null;
                                            if (this.strCPWarningMessage !== "" && this.strCPWarningMessage !== null && this.strCPWarningMessage !== undefined) {
                                                uiWarningMessage = (
                                                    <div className="jeton_message_text_warning">
                                                        <Icon type="exclamation-circle" theme="filled" style={{ fontSize: "26.5px", marginRight: "10px", marginBottom: "0px" }} />
                                                        {this.strCPWarningMessage}</div>
                                                );
                                            }
                                            return (
                                                <div key={indexExpress} className="jeton_express_info_row">
                                                    <div className={"jeton_express_icon_col " + express_info.icon}></div>
                                                    {uiWarningMessage}
                                                </div>
                                            );
                                        }
                                        else
                                            return null;
                                    }
                                    // 正常返回CP价格
                                    else {
                                        let iDeliverDays = parseInt(this.arrCPDeliveryDays[indexExpress]);
                                        let strDeliverDays = iDeliverDays.toString();
                                        if (iDeliverDays === 1)
                                            strDeliverDays += "-3 Business Days";
                                        if (iDeliverDays === 2)
                                            strDeliverDays += "-10 Business Days";
                                        let strPrice = this.arrCPFeeResultTotal[indexExpress].toFixed(2);
                                        return (
                                            <div key={indexExpress} className="jeton_express_info_row">
                                                <div className={"jeton_express_icon_col " + express_info.icon}></div>
                                                <div className="jeton_area_subtitle jeton_express_name_col">{express_info.name}</div>
                                                <div className="jeton_area_subtitle jeton_express_deliver_time_col">
                                                    {strDeliverDays}
                                                </div>
                                                <div className="jeton_area_subtitle jeton_express_price_col"><b>
                                                    {"$ " + strPrice}
                                                </b></div>
                                                <div className="jeton_express_select_col" style={{ marginRight: '20px' }}>
                                                    <Button type="primary" onClick={this.ShowPriceDetailDlg.bind(this, 0, true, indexExpress)}>
                                                        价格明细</Button>
                                                </div>
                                                <div className="jeton_express_select_col">
                                                    <Button type="primary" onClick={this.SelectExpressService.bind(this, indexExpress)}>
                                                        选 择</Button>
                                                </div>
                                            </div>
                                        );
                                    }
                                }
                                // UPS
                                else {
                                    if (this.bUPSWarning) {
                                        if (indexExpress === 4) {
                                            let uiWarningMessage = null;
                                            if (this.strUPSWarningMessage !== "" && this.strUPSWarningMessage !== null && this.strUPSWarningMessage !== undefined) {
                                                uiWarningMessage = (
                                                    <div className="jeton_message_text_warning">
                                                        <Icon type="exclamation-circle" theme="filled" style={{ fontSize: "26.5px", marginRight: "10px", marginBottom: "0px" }} />
                                                        {this.strUPSWarningMessage}</div>
                                                );
                                            }
                                            return (
                                                <div key={indexExpress} className="jeton_express_info_row">
                                                    <div className={"jeton_express_icon_col " + express_info.icon}></div>
                                                    {uiWarningMessage}
                                                </div>
                                            );
                                        }
                                        else
                                            return null;
                                    } else {
                                        let iDeliverDays = parseInt(this.arrUPSDeliveryDays[indexExpress - 4]);
                                        let strDeliverDays = iDeliverDays.toString();
                                        if (iDeliverDays === 1)
                                            strDeliverDays += "-3 Business Days";
                                        if (iDeliverDays === 2)
                                            strDeliverDays += "-10 Business Days";
                                        return (
                                            <div key={indexExpress} className="jeton_express_info_row">
                                                <div className={"jeton_express_icon_col " + express_info.icon}></div>
                                                <div className="jeton_area_subtitle jeton_express_name_col">{express_info.name}</div>
                                                <div className="jeton_area_subtitle jeton_express_deliver_time_col">{strDeliverDays}</div>
                                                <div className="jeton_area_subtitle jeton_express_price_col"><b>
                                                    {"$ " + this.arrUPSFeeResultTotal[indexExpress - 4]}
                                                </b></div>
                                                <div className="jeton_express_select_col" style={{ marginRight: '20px' }}>
                                                    <Button type="primary" onClick={this.ShowPriceDetailDlg.bind(this, 0, true, indexExpress)}>
                                                        价格明细</Button>
                                                </div>
                                                <div className="jeton_express_select_col">
                                                    <Button type="primary" onClick={this.SelectExpressService.bind(this, indexExpress)}>
                                                        选 择</Button>
                                                </div>
                                            </div>
                                        );
                                    }
                                }
                            })}
                        </div>
                    </div>
                );
            }
            // 没有运营商有数据返回，并且都有超规报警时
            else {
                if (this.bCPWarning && this.bUPSWarning) {
                    uiExpressFeeList = (
                        < div className="jeton_delivery_fee_detail_area" >
                            <div className="jeton_general_row">
                                <div className="jeton_express_icon_col" style={{ marginRight: '40px' }}></div>
                                <div className="jeton_area_subtitle jeton_express_name_col"><b>承运商</b></div>
                                <div className="jeton_area_subtitle jeton_express_deliver_time_col"><b>运送时间</b></div>
                                <div className="jeton_area_subtitle jeton_express_price_col"><b>总价</b></div>
                                <div className="jeton_express_select_col"><b>请选择适合您的快递服务</b></div>
                            </div>
                            <div >
                                {this.arrExpressServiceInfoList.map((express_info, indexExpress) => {
                                    // Canada Post
                                    if (indexExpress < 4) {
                                        if (indexExpress === 0) {
                                            let uiWarningMessage = null;
                                            if (this.strCPWarningMessage !== "" && this.strCPWarningMessage !== null && this.strCPWarningMessage !== undefined) {
                                                uiWarningMessage = (
                                                    <div className="jeton_message_text_warning">
                                                        <Icon type="exclamation-circle" theme="filled" style={{ fontSize: "26.5px", marginRight: "10px", marginBottom: "0px" }} />
                                                        {this.strCPWarningMessage}</div>
                                                );
                                            }
                                            return (
                                                <div key={indexExpress} className="jeton_express_info_row">
                                                    <div className={"jeton_express_icon_col " + express_info.icon}></div>
                                                    {uiWarningMessage}
                                                </div>
                                            );
                                        }
                                        else
                                            return null;
                                    }
                                    // UPS
                                    else {
                                        if (indexExpress === 4) {
                                            let uiWarningMessage = null;
                                            if (this.strUPSWarningMessage !== "" && this.strUPSWarningMessage !== null && this.strUPSWarningMessage !== undefined) {
                                                uiWarningMessage = (
                                                    <div className="jeton_message_text_warning">
                                                        <Icon type="exclamation-circle" theme="filled" style={{ fontSize: "26.5px", marginRight: "10px", marginBottom: "0px" }} />
                                                        {this.strUPSWarningMessage}</div>
                                                );
                                            }
                                            return (
                                                <div key={indexExpress} className="jeton_express_info_row">
                                                    <div className={"jeton_express_icon_col " + express_info.icon}></div>
                                                    {uiWarningMessage}
                                                </div>
                                            );
                                        }
                                        else
                                            return null;
                                    }

                                })}
                            </div>
                        </div>
                    );
                }
            }
        }

        let uiShipperModal = null;
        if (this.state.iShipperDlg === 0) {
            uiShipperModal = (
                <Modal width={600} title={"寄件人列表"} visible={this.state.bShipperDlg} footer={null} onCancel={this.ShowShipperDlg.bind(this, -1, false)} >
                    <div className="jeton_order_list_row_disable">
                        <div className="jeton_order_list_content jeton_order_list_content_bold" >{"姓名"}</div>
                        <div className="jeton_order_list_content jeton_order_list_content_bold" >{"电话"}</div>
                        <div className="jeton_order_list_content jeton_order_list_content_bold" >{"邮编"}</div>
                    </div>
                    {global.arrReceiverData.map((shipper, index) => {
                        return (
                            <div className="jeton_order_list_row" onClick={this.SelectShipper.bind(this, index)} key={index}>
                                <div className="jeton_order_list_content" >{shipper.name}</div>
                                <div className="jeton_order_list_content" >{shipper.phone}</div>
                                <div className="jeton_order_list_content" >{shipper.postcode}</div>
                            </div>
                        );
                    })}


                </Modal>
            );
        }
        let uiReceiverModal = null;
        if (this.state.iReceiverDlg === 0) {
            uiReceiverModal = (
                <Modal width={600} title={"收件人列表"} visible={this.state.bReceiverDlg} footer={null} onCancel={this.ShowReceiverDlg.bind(this, -1, false)} >
                    <div className="jeton_order_list_row_disable">
                        <div className="jeton_order_list_content jeton_order_list_content_bold" >{"姓名"}</div>
                        <div className="jeton_order_list_content jeton_order_list_content_bold" >{"电话"}</div>
                        <div className="jeton_order_list_content jeton_order_list_content_bold" >{"邮编"}</div>
                    </div>
                    {global.arrReceiverData.map((receiver, index) => {
                        return (
                            <div className="jeton_order_list_row" onClick={this.SelectReceiver.bind(this, index)} key={index}>
                                <div className="jeton_order_list_content" >{receiver.name}</div>
                                <div className="jeton_order_list_content" >{receiver.phone}</div>
                                <div className="jeton_order_list_content" >{receiver.postcode}</div>
                            </div>
                        );
                    })}
                </Modal>
            );
        }
        let uiPriceDetailModal = null;
        if (this.state.iPriceDetailDlg === 0) {
            let uiDlgTitle = (<div className="jeton_price_detail_title" >{this.objCurrentPriceDetail.name + " - 价格明细"}</div>);
            uiPriceDetailModal = (
                <Modal width={600} title={uiDlgTitle} visible={this.state.bPriceDetailDlg} footer={null} onCancel={this.ShowPriceDetailDlg.bind(this, -1, false)} >
                    <PriceDetailDlg objPriceDetail={this.objCurrentPriceDetail} iExpressType={this.iCurrentExpressType} />
                </Modal>
            );
        }

        return (
            <div className="jeton_main_contents">
                {uiAddressArea}
                {uiPackageArea}
                {uiExpressFeeList}
                {uiShipperModal}
                {uiReceiverModal}
                {uiPriceDetailModal}
            </div>
        );
    }
}
export default DeliveryFeeCalculator;
