import React, { Component } from "react";
import axios from 'axios';
// import download from 'downloadjs';

import Collapse from 'antd/es/collapse';
import Icon from 'antd/es/icon';
import Statistic from 'antd/es/statistic';
import Modal from 'antd/es/modal';
// import Radio from 'antd/es/radio';
import Menu from 'antd/es/menu';
import Dropdown from 'antd/es/dropdown';
import Input from 'antd/es/input';
import message from 'antd/es/message';

import "../../../styles/Background/UI_Module/store_order_pickup.css";
import "../../../styles/ClientSide/user_center_page.css";

const { Panel } = Collapse;
const { Countdown } = Statistic;

const g_strMessageKey = 'updating';


class StoreOrderPickup extends Component {
    constructor(props) {
        super();
        this.state = {
            iOrderFilter: 0,
            bDataLoading: true,
            deadline: Date.now() + 1000 * 5,
            bPanelActive: false,
            bProcessOrderDlg: false,
            iProcessOrderType: -1,
            iPickupTime: 0,
            iOrderCancelReason: 0,
            bOrderStatusUpdate: false,
            bExpressOrderInput: false,
            bCommentsDlg: false,
            bNameDlg: false
        };

        // 新订单音效
        this.audioNewOrder = new Audio('https://res.cloudinary.com/jingtu-ego-test/video/upload/v1603042409/0-%E9%B2%B8%E5%9B%BEWhaleMap/NewMessage_glprkc.mp3');
        this.audioNewOrder.load();
        this.audioReminder = new Audio('https://res.cloudinary.com/jingtu-ego-test/video/upload/v1603042409/0-%E9%B2%B8%E5%9B%BEWhaleMap/Reminder_lffu3k.mp3');
        this.audioReminder.load();

        this.arrOrderFilter = ['未处理', '已接单', '已完成', '已取消'];
        this.arrOrderStatus = ["未支付", "已支付", "已接单", "已出单", "已完成", "已取消", "已退款", "售后争议"];
        this.arrOrderInfoTitle = ["订单编号", "下单时间", "支付方式", "支付金额", "收件人名", "联系电话", "配送方式", "商品信息", "物流单号"];
        this.arrOrderList = -1;
        this.arrOrderListShow = -1;
        this.arrOrderFilterNum = [0, 0, 0, 0];
        this.bFirstIn = true;
        this.bUnviewedOrder = false;
        this.arrActiveOrderPanel = [];
        this.arrPickupTime = [10, 20, 30, 40];
        this.iMinuteCount = 0;
        this.arrOrderCancelReason = ["食材不足", "即将打样", "超时作废", "其他原因"];
        if (props.objStoreInfo.type === 1) {
            this.arrOrderCancelReason = ["库存不足,No Stock", "不在配送范围,Only For Great Vancouver Delivery", "运费等支付金额有误,Payment Amount Error", "超时作废,Expired",
                "与客户沟通后退款,Refund After Contact", "其他原因,Other Reason"];
        }
        this.iCurrentOrder = -1;
        this.dataPdf = "www.baidu.com";
        // require('dotenv').config();
    }
    // 定时器倒计时
    TimeCountDown = () => {
        console.log("Order Monitor ...");
        this.iMinuteCount++;
        if (this.iMinuteCount >= 4) {
            this.iMinuteCount = 0;
            console.log("又过去了1分钟……");
            this.PickupTimeLeftCount();
        }
        this.GetStoreOrders();
        this.setState({
            ...this.state,
            deadline: Date.now() + 1000 * 60
        });
    }
    SwitchOrderFilter = (index) => {
        if (index !== this.state.iOrderFilter) {
            this.arrActiveOrderPanel = [];
            this.RefeshOrderList(index);
            this.setState({
                ...this.state,
                iOrderFilter: index
            });
        }
    }
    GetStoreOrders = () => {
        axios('/GetStoreOrders', { params: { store_id: parseInt(this.props.objStoreInfo.id, 10) } })
            .then(({ data }) => {
                this.bUnviewedOrder = false;
                if (this.bFirstIn) {
                    this.bFirstIn = false;
                    this.arrOrderList = data;
                    this.PickupTimeLeftCount();
                } else {
                    if (this.arrOrderList !== -1 && data.length > this.arrOrderList.length) {
                        this.audioNewOrder.play();
                        message.info("有新的订单来了！");
                        this.bUnviewedOrder = true;
                    }
                    this.arrOrderList = data;
                }
                this.RefeshOrderList(this.state.iOrderFilter);
                if (this.state.iOrderFilter === 0) {
                    for (let i = 0; i < this.arrOrderListShow.length; i++) {
                        if (this.arrOrderListShow[i].viewed === 0) {
                            this.bUnviewedOrder = true; break;
                        }
                    }
                }
                this.setState({
                    ...this.state,
                    bDataLoading: false
                })
            }).catch(function (error) { console.log(error); });
    }
    // 刷新订单列表
    RefeshOrderList = (index) => {
        this.arrOrderListShow = [];
        this.arrOrderFilterNum = [0, 0, 0, 0];
        for (let i = 0; i < this.arrOrderList.length; i++) {
            if (this.arrOrderList[i].status <= 1) {
                this.arrOrderFilterNum[0]++;
                if (index === 0)
                    this.arrOrderListShow.push(this.arrOrderList[i]);
            }
            if (this.arrOrderList[i].status === 2 || this.arrOrderList[i].status === 3) {
                this.arrOrderFilterNum[1]++;
                if (index === 1)
                    this.arrOrderListShow.push(this.arrOrderList[i]);
            }
            if (this.arrOrderList[i].status === 4) {
                this.arrOrderFilterNum[2]++;
                if (index === 2)
                    this.arrOrderListShow.push(this.arrOrderList[i]);
            }
            if (this.arrOrderList[i].status === 5) {
                this.arrOrderFilterNum[3]++;
                if (index === 3)
                    this.arrOrderListShow.push(this.arrOrderList[i]);
            }
        }
    }
    // 订单列表折叠区域点击响应
    OrderListCollapse = (key) => {
        if (key) {
            this.arrActiveOrderPanel = key;
            if (this.state.iOrderFilter === 0 && key.length > 0) {
                let index = parseInt(key[key.length - 1], 10);
                this.arrOrderListShow[index].viewed = 1;
                axios.post('/UpdateOrderViewed', { oid: this.arrOrderListShow[index].oid })
                    .then(({ data }) => {
                        this.GetStoreOrders();
                    });
            }
        } else {
            this.arrActiveOrderPanel = [];
        }
        this.setState({
            ...this.state,
            bPanelActive: true
        });
    }
    // 确认接单
    PickupOrder = (index) => {
        let oid = this.arrOrderListShow[index].oid;
        // 零售电商
        if (this.props.objStoreInfo.type === 1) {
            axios.post('/UpdateOrderStatus', { oid: oid, status: 2 })
                .then(({ data }) => {
                    console.log("订单状态更新", data);
                    message.info("接单成功！请在已接单列表中查看");
                    this.arrActiveOrderPanel = [];
                    this.GetStoreOrders();
                });
        }
        // 自提外卖
        else {
            axios.post('/UpdateOrderPickupTime', { oid: oid, pickup_time: this.arrPickupTime[this.state.iPickupTime] })
                .then(({ data }) => {
                    axios.post('/UpdateOrderStatus', { oid: oid, status: 2 })
                        .then(({ data }) => {
                            console.log("订单状态更新", data);
                            message.info("接单成功！请在已接单列表中查看");
                            this.arrActiveOrderPanel = [];
                            this.GetStoreOrders();
                        });
                });
        }
    }
    // 取消订单
    CancelOrder = () => {
        this.setState({
            ...this.state,
            bProcessOrderDlg: false,
            iProcessOrderType: -1
        });
        let oid = this.arrOrderListShow[this.iCurrentOrder].oid;
        let pid = this.arrOrderListShow[this.iCurrentOrder].pid;
        console.log("Cancel Order : ", this.arrOrderListShow[this.iCurrentOrder]);
        // 现金支付
        if (this.arrOrderListShow[this.iCurrentOrder].card_info === "-4") {
            console.log("现金无需退款！");
            // 订单状态更新
            axios.post('/UpdateOrderStatus', { oid: oid, status: 5, comments: this.arrOrderCancelReason[this.state.iOrderCancelReason] })
                .then(({ data }) => {
                    console.log("订单状态更新", data);
                    this.GetStoreOrders();
                });
        } else {
            if (this.arrOrderListShow[this.iCurrentOrder].card_info !== null && this.arrOrderListShow[this.iCurrentOrder].card_info !== "") {
                // Stripe 退款
                axios.post('/OrderRefund', {
                    pid: pid
                }).then(({ data }) => {
                    if (data.refund === 1) {
                        message.info(data.message);
                        // 订单状态更新
                        axios.post('/UpdateOrderStatus', { oid: oid, status: 5, comments: this.arrOrderCancelReason[this.state.iOrderCancelReason] })
                            .then(({ data }) => {
                                console.log("订单状态更新", data);
                                this.GetStoreOrders();
                            });
                    } else {
                        message.warning("退款失败，请联系客服！");
                    }
                }).catch(function (error) { console.log(error); });
            }
        }
    }
    // 处理订单弹窗
    OrderProcessDlg = (iType, iOrderIndex) => {
        let bShow = true;
        if (iType < 0)
            bShow = false;
        if (iType === 2) {
            console.log("Cancel Order Dlg : " + iOrderIndex);
            this.iCurrentOrder = iOrderIndex;
        }
        this.setState({
            ...this.state,
            bProcessOrderDlg: bShow,
            iProcessOrderType: iType
        });
    }
    // 取消订单下拉框响应
    OrderCancelReasonChange = (index) => {
        this.setState({
            ...this.state,
            iOrderCancelReason: index
        })
    }
    // 更改取餐时间
    PickupTimeChange = (e) => {
        this.setState({
            ...this.state,
            iPickupTime: e.target.value
        })
    }
    // 订单状态更改（出单出餐，取走完成）
    OrderStatusUpdate = (status, index) => {
        this.iCurrentOrder = index;
        let oid = this.arrOrderListShow[index].oid;
        let express_order = this.arrOrderListShow[index].tracking_pin;
        let iStatus = parseInt(status, 10);
        console.log("OrderStatusUpdate : ", iStatus);
        // 加邮下单/打印面单
        if (iStatus === -1 || iStatus === -2) {
            if (iStatus === -1) {
                console.log("Canada Post Place Order");
                let objShippingAddress = this.arrOrderListShow[index].deliver_address;
                objShippingAddress = JSON.parse(objShippingAddress);
                let objShippingWeight =
                {
                    weight: this.arrOrderListShow[index].package_weight,
                    length: this.arrOrderListShow[index].package_length,
                    width: this.arrOrderListShow[index].package_width,
                    height: this.arrOrderListShow[index].package_height
                }
                console.log(objShippingAddress);
                console.log(objShippingWeight);
                this.CreateShipment(objShippingAddress, objShippingWeight);
            }
            if (iStatus === -2) {
                console.log("Canada Post Print Order : ", process.env.REACT_APP_NODE_ENV);
                let strPdfLink = this.arrOrderListShow[index].pdf_link;
                if (process.env.REACT_APP_NODE_ENV === 'production') {
                    strPdfLink = "https://" + process.env.REACT_APP_CanadaPostUser + ":" +
                        process.env.REACT_APP_CanadaPostPass + "@" + strPdfLink;
                } else {
                    strPdfLink = "https://" + process.env.REACT_APP_CanadaPostUser_Dev + ":" +
                        process.env.REACT_APP_CanadaPostPass_Dev + "@" + strPdfLink;
                }
                console.log(strPdfLink);
                window.open(strPdfLink);
            }
        }
        // 其余情况
        else {
            if (iStatus === -3)
                iStatus = 2;
            if (iStatus === 3 /*&& (express_order === null || express_order === "") && this.arrOrderListShow[index].delivery_way === 2*/) {
                // this.setState({
                //     ...this.state,
                //     bExpressOrderInput: true
                // });
                let oid = this.arrOrderListShow[index].oid;
                console.log("已发货按钮相应 ： ", oid, express_order);
                axios.post('/UpdateOrderStatus', { oid: oid, status: 3, express_order: this.arrOrderListShow[index].tracking_pin/*this.refs.express_order.state.value*/ })
                    .then(({ data }) => {
                        message.info("订单状态更新，已通知顾客！");
                        this.setState({
                            ...this.state,
                            bExpressOrderInput: false
                        });
                        this.GetStoreOrders();
                    });
            } else {
                console.log("UpdateOrderStatus");
                axios.post('/UpdateOrderStatus', { oid: oid, status: iStatus })
                    .then(({ data }) => {
                        console.log(data);
                        let strReadyGo = "出餐完成，已通知顾客来取！";
                        let strCancelReadyGo = "已取消出餐！";
                        if (this.props.objStoreInfo.type === 1) {
                            strReadyGo = "订单状态更新，已通知顾客！";
                            strCancelReadyGo = "重新准备，状态已更新！";
                        }
                        if (status === -3)
                            message.info(strCancelReadyGo);
                        if (status === 3 && this.props.objStoreInfo.type === 3) {
                            message.info(strReadyGo);
                        }
                        if (status === 4) {
                            message.info("恭喜又完成了一笔订单！请在已完成列表中查看");
                            this.arrActiveOrderPanel = [];
                        }
                        this.GetStoreOrders();
                    });
            }
        }
    }
    // 输入物流单号之后
    AfterInputExpressOrder = (index) => {
        if (this.refs.express_order) {
            if (this.refs.express_order.state.value === null || this.refs.express_order.state.value === "") {
                message.warning("物流单号不能为空");
                return;
            } else {
                let oid = this.arrOrderListShow[index].oid;
                axios.post('/UpdateOrderStatus', { oid: oid, status: 3, express_order: this.refs.express_order.state.value })
                    .then(({ data }) => {
                        message.info("订单状态更新，已通知顾客！");
                        this.setState({
                            ...this.state,
                            bExpressOrderInput: false
                        });
                        this.GetStoreOrders();
                    });
            }
        }
    }
    // 取消输入物流单号
    CancelInputExpressOrder = () => {
        this.setState({
            ...this.state,
            bExpressOrderInput: false
        });
    }
    // 距离客人取餐时间的计算
    PickupTimeLeftCount = () => {
        let arrOrderUpdate = [];
        let bRemind = false;
        if (this.arrOrderList.length > 0) {
            for (let i = 0; i < this.arrOrderList.length; i++) {
                if (this.arrOrderList[i].status === 2) {
                    if (this.arrOrderList[i].order_confirm_time === null) {
                        // console.log("未记录接单时间，不做进一步处理");
                    } else {
                        let dateNow = new Date();
                        let dateOrder = new Date(this.arrOrderList[i].order_confirm_time);
                        let drr = Math.abs(dateNow.getTime() - dateOrder.getTime());
                        let day = parseInt(drr / (24 * 60 * 60 * 1000));
                        let iMinuteLeft = 0;
                        let iPickupTime = parseInt(this.arrOrderList[i].pickup_time, 10);
                        if (day <= 0) {
                            let hours = parseInt(drr % (24 * 60 * 60 * 1000) / (60 * 60 * 1000));
                            if (hours <= 0) {
                                let minutes = parseInt(drr % (60 * 60 * 1000) / (60 * 1000));
                                if (minutes >= iPickupTime)
                                    iMinuteLeft = 0;
                                else {
                                    iMinuteLeft = iPickupTime - minutes;
                                }
                            }
                        }
                        this.arrOrderList[i].pickup_time_left = iMinuteLeft;
                        arrOrderUpdate.push(this.arrOrderList[i]);
                    }
                }
                // 针对未接单进行提醒
                if (!bRemind) {
                    if (parseInt(this.arrOrderList[i].status, 10) < 2) {
                        bRemind = true;
                        this.audioReminder.play();
                        message.info("还有未处理的订单，不要忘记哦！");
                    }
                }
            }
            if (arrOrderUpdate.length > 0) {
                axios.post('/UpdateOrdersPickupTime', { arrOrders: arrOrderUpdate })
                    .then(({ data }) => {
                        console.log("order pickup time updated !", data);
                    });
            }
        }
    }
    // 显示修改姓名窗口
    ShowNameDlg = (index, iAction) => {
        if (index >= 0) {
            this.iCurrentOrder = index;
        }
        if (this.refs.order_contact !== undefined && iAction === 0)
            this.refs.order_contact.state.value = this.arrOrderListShow[this.iCurrentOrder].contact;
        let bShowDlg = false;
        if (iAction === 0)
            bShowDlg = true;
        if (iAction === 1) {
            if (this.refs.order_contact !== undefined) {
                this.arrOrderListShow[this.iCurrentOrder].contact = this.refs.order_contact.state.value;
            }
            axios.post('/UpdateOrderInfo', { order: this.arrOrderListShow[this.iCurrentOrder], store_id: this.props.objStoreInfo.id })
                .then(({ data }) => {
                    console.log(data);
                    message.success('收件人姓名修改完成！');
                    this.setState({
                        ...this.state,
                        bDataLoading: false
                    });
                });
        }
        this.setState({
            ...this.state,
            bNameDlg: bShowDlg
        });
    }
    // 显示修改备注窗口
    ShowCommentsDlg = (index, iAction) => {
        if (index >= 0) {
            this.iCurrentOrder = index;
        }
        if (this.refs.order_comments !== undefined && iAction === 0)
            this.refs.order_comments.state.value = this.arrOrderListShow[this.iCurrentOrder].comments;
        let bShowDlg = false;
        if (iAction === 0)
            bShowDlg = true;
        if (iAction === 1) {
            if (this.refs.order_comments !== undefined) {
                this.arrOrderListShow[this.iCurrentOrder].comments = this.refs.order_comments.state.value;
            }
            axios.post('/UpdateOrderComments', { order: this.arrOrderListShow[this.iCurrentOrder], store_id: this.props.objStoreInfo.id })
                .then(({ data }) => {
                    message.success('备注/优惠信息修改完成！');
                });
        }
        this.setState({
            ...this.state,
            bCommentsDlg: bShowDlg
        });
    }
    // CanadaPostLabelTest
    ViewPDF = () => {
        console.log("ViewPDF");
        console.log(this.dataPdf);
        window.open(this.dataPdf, '_blank');
    }
    CreateShipment = (objShippingAddress, objShippingWeight) => {
        this.setState({
            ...this.state,
            bDataLoading: true
        });
        message.loading({ content: 'Canada Post 订单创建中……', key: g_strMessageKey });
        // CanadaPost下单
        console.log('canada post create shipment');
        axios.post('/cpapi/createshipment', {
            objShippingAddress: objShippingAddress,
            objShippingWeight: objShippingWeight
        }).then(({ data }) => {
            console.log(data);
            message.success({ content: "下单完成！", key: g_strMessageKey, duration: 2 });
            this.dataPdf = "https://" + process.env.REACT_APP_CanadaPostUser + ":" +
                process.env.REACT_APP_CanadaPostPass + "@" +
                data.label_link.split('https://')[1];
            console.log("this.dataPdf : ", this.dataPdf);

            // 记录加邮订单信息
            this.arrOrderListShow[this.iCurrentOrder].shipment_id = data.shipment_id[0];
            this.arrOrderListShow[this.iCurrentOrder].tracking_pin = data.tracking_pin[0];
            this.arrOrderListShow[this.iCurrentOrder].pdf_link = data.label_link.split('https://')[1];
            console.log("this.arrOrderListShow[this.iCurrentOrder] : ", this.arrOrderListShow[this.iCurrentOrder]);
            axios.post('/UpdateOrderInfo', { order: this.arrOrderListShow[this.iCurrentOrder], store_id: this.props.objStoreInfo.id })
                .then(({ data }) => {
                    console.log(data, '加邮订单信息记录完毕！');
                    this.setState({
                        ...this.state,
                        bDataLoading: false
                    });
                });

        });
    }
    // 显示修改备注窗口
    GetTrackingInfo = (index) => {
        if (index >= 0) {
            this.iCurrentOrder = index;
        }
        console.log("tracking order : ", this.arrOrderListShow[this.iCurrentOrder]);
        window.open("https://www.canadapost-postescanada.ca/track-reperage/en#/details/" + this.arrOrderListShow[this.iCurrentOrder].tracking_pin);
    }
    // 渲染完毕……
    componentDidMount() {
        this.GetStoreOrders();
        console.log(process.env.REACT_APP_CanadaPostUser, process.env.REACT_APP_CanadaPostPass);
    }

    render() {

        let uiOrderList = null;
        if (!this.state.bDataLoading) {
            uiOrderList = (
                <div className="store_order_pickup_order_list_area">
                    <Collapse onChange={this.OrderListCollapse} style={{ margin: '0', padding: '0' }} activeKey={this.arrActiveOrderPanel} accordion>
                        {this.arrOrderListShow.map((order, indexOrder) => {
                            let objItems = JSON.parse(order.items);
                            let uiReminder = (<div className="store_order_new_order_reminder_null" />);
                            if (this.state.iOrderFilter === 0 && parseInt(order.viewed, 10) === 0) {
                                uiReminder = (<div className="store_order_new_order_reminder" />);
                            }
                            // 下单时间处理
                            let strDateTime = "";
                            let dateNow = new Date();
                            let dateOrder = new Date(order.client_order_time);
                            let drr = Math.abs(dateNow.getTime() - dateOrder.getTime());
                            let day = parseInt(drr / (24 * 60 * 60 * 1000));
                            if (day > 0)
                                strDateTime = day + "天前";
                            else {
                                let hours = parseInt(drr % (24 * 60 * 60 * 1000) / (60 * 60 * 1000));
                                if (hours > 0) {
                                    strDateTime = hours + "小时前";
                                } else {
                                    let minutes = parseInt(drr % (60 * 60 * 1000) / (60 * 1000));
                                    if (minutes > 0) {
                                        strDateTime = minutes + "分钟前";
                                    } else {
                                        strDateTime = "刚刚";
                                    }
                                }
                            }
                            let uiTitle = (
                                <div className="store_order_pickup_order_list_title">
                                    {uiReminder}
                                    <div className="store_order_pickup_title_left_area" style={{ marginLeft: '0.5vw' }}>
                                        <Icon type="file-text" />
                                        {" " + order.oid}
                                    </div>
                                    <div className="store_order_pickup_title_middle_area">
                                        <Icon type="code-sandbox" />
                                        {" x " + objItems.total_num}
                                    </div>
                                    <div className="store_order_pickup_order_status_text">
                                        <Icon type="clock-circle" style={{ marginRight: '1vw' }} />
                                        {strDateTime}
                                    </div>
                                </div>
                            );
                            let strName = "无";
                            let strPhone = "无";
                            let strAddress = "无";
                            if (order.contact !== null && order.contact !== "")
                                strName = order.contact;
                            if (order.phone !== null && order.phone !== "")
                                strPhone = order.phone;
                            if (order.delivery_way === 0)
                                strAddress = "到店自提";
                            else {
                                if (order.address !== null && order.address !== "") {
                                    if (order.delivery_way === 1)
                                        strAddress = "送货上门 - " + order.address;
                                    if (order.delivery_way === 2)
                                        strAddress = "快递邮寄 - " + order.address;
                                }
                            }
                            let uiItemInfo = null;
                            if (objItems.items.length > 0) {
                                uiItemInfo = (
                                    <div className="store_order_pickup_item_info_area">
                                        {objItems.items.map((item, indexItem) => {
                                            let uiOptions = null;
                                            if (item.options) {
                                                if (item.options.length > 0) {
                                                    uiOptions = (
                                                        <div className="store_order_pickup_item_info_detail">
                                                            {item.options.map((option, indexOption) => {
                                                                if (option === -1)
                                                                    return null;
                                                                else
                                                                    return (
                                                                        <div className="store_order_pickup_item_info_option_row">
                                                                            {option.title.split(',')[0] + ": " + option.name.split(',')[0]}
                                                                        </div>
                                                                    );
                                                            })}
                                                        </div>
                                                    );
                                                }
                                            }
                                            let uiComments = null;
                                            if (item.comments !== undefined)
                                                if (item.comments !== null && item.comments !== "")
                                                    uiComments = (<div className="store_order_pickup_item_info_detail">
                                                        {"备注: " + item.comments}
                                                    </div>);
                                            let strItemName = item.name;
                                            if (strItemName !== undefined)
                                                if (strItemName !== null && strItemName !== "")
                                                    if (strItemName.indexOf(',') >= 0)
                                                        strItemName = item.name.split(',')[0];
                                            return (
                                                <div className="store_order_pickup_item_block">
                                                    <div className="store_order_pickup_item_info_title store_order_pickup_details_text">
                                                        {strItemName + " x " + item.num}
                                                    </div>
                                                    {uiOptions}
                                                    {uiComments}
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            }
                            // 接单操作区域
                            let uiOrderActiveArea = null;
                            if (this.state.iOrderFilter === 0) {
                                // let uiPickupTime = null;
                                // 自提外卖显示取餐时间
                                // if (this.props.objStoreInfo.type === 3) {
                                //     uiPickupTime = (
                                //         <Radio.Group onChange={this.PickupTimeChange} value={this.state.iPickupTime}>
                                //             {this.arrPickupTime.map((item, index) => {
                                //                 return (
                                //                     <Radio.Button value={index} className="store_order_pickup_radio_button" >
                                //                         <div className="store_order_pickup_radio_text">
                                //                             {item + "分钟"}</div></Radio.Button>
                                //                 );
                                //             })}
                                //         </Radio.Group>
                                //     );
                                // }
                                uiOrderActiveArea = (
                                    <div className="store_order_pickup_active_area">
                                        <div className="store_order_pickup_active_button" style={{ backgroundColor: 'lightcoral' }}
                                            onClick={this.OrderProcessDlg.bind(this, 2, indexOrder)}>取 消</div>
                                        <div className="store_order_pickup_active_button" onClick={this.PickupOrder.bind(this, indexOrder)}>接 单</div>
                                        {/* {uiPickupTime} */}
                                    </div>
                                );
                            }
                            if (this.state.iOrderFilter === 1) {
                                let strReadyGo = "已出餐";
                                let strCancelReadyGo = "取消出餐";
                                let strPickup = "已取走";
                                let uiOrderCPOrderButton = null;
                                if (this.props.objStoreInfo.type === 1) {
                                    strReadyGo = "已备好";
                                    if (order.delivery_way >= 1) {
                                        strReadyGo = "已发货";
                                        strPickup = "已签收";
                                    }
                                    strCancelReadyGo = "重新准备";
                                    uiOrderCPOrderButton = (
                                        <div className="store_order_pickup_confirm_button" style={{ minWidth: "21.5vw" }}
                                            onClick={this.OrderStatusUpdate.bind(this, -1, indexOrder)}>
                                            {"加邮下单"}</div>);
                                    if (order.pdf_link !== undefined && order.pdf_link !== null && order.pdf_link !== '') {
                                        uiOrderCPOrderButton = (
                                            <div className="store_order_pickup_confirm_button" style={{ minWidth: "21.5vw" }}
                                                onClick={this.OrderStatusUpdate.bind(this, -2, indexOrder)}>
                                                {"打印面单"}</div>);
                                    }
                                }
                                let uiOrderReadyButton = (<div className="store_order_pickup_confirm_button" onClick={this.OrderStatusUpdate.bind(this, 3, indexOrder)}>{strReadyGo}</div>);
                                let uiOrderCompleteButton = (<div className="store_order_pickup_confirm_button" onClick={this.OrderStatusUpdate.bind(this, 4, indexOrder)}>{strPickup}</div>);
                                if (order.status === 3)
                                    uiOrderReadyButton = (<div className="store_order_pickup_confirm_button_revert" onClick={this.OrderStatusUpdate.bind(this, -3, indexOrder)}>{strCancelReadyGo}</div>);
                                // let strPickupReminder = order.pickup_time_left + "分钟后客人来取";
                                // if (parseInt(order.pickup_time_left, 10) <= 0)
                                //     strPickupReminder = "客人即将来取";
                                // if (this.props.objStoreInfo.type === 1)
                                //     strPickupReminder = null;
                                uiOrderActiveArea = (
                                    <div className="store_order_pickup_time_remind">
                                        {/* {strPickupReminder} */}
                                        <div className="store_order_pickup_active_button" style={{ backgroundColor: 'lightcoral' }}
                                            onClick={this.OrderProcessDlg.bind(this, 2, indexOrder)}>取 消</div>
                                        {uiOrderCPOrderButton}
                                        {uiOrderReadyButton}
                                        {uiOrderCompleteButton}
                                    </div>
                                );
                            }
                            let uiNotYetButton = null;
                            if (this.state.iOrderFilter === 2) {
                                uiNotYetButton = (<div className="store_order_pickup_confirm_button" onClick={this.OrderStatusUpdate.bind(this, 3, indexOrder)}>{"还未完成"}</div>);
                                uiOrderActiveArea = (
                                    <div className="store_order_pickup_time_remind">
                                        <div className="store_order_pickup_active_button" style={{ backgroundColor: 'lightcoral' }}
                                            onClick={this.OrderProcessDlg.bind(this, 2, indexOrder)}>取 消</div>
                                        {uiNotYetButton}
                                    </div>
                                );
                            }
                            let uiCancelReason = null;
                            if (this.state.iOrderFilter === 3 && order.comments !== null && order.comments !== "") {
                                let strCancelReason = "取消原因：" + order.comments;
                                if (order.comments.indexOf(',') >= 0)
                                    strCancelReason = "取消原因：" + order.comments.split(',')[0];
                                uiCancelReason = (
                                    <div className="user_center_order_details_text user_center_order_details_comments" style={{ width: '100%' }}>
                                        {strCancelReason}
                                    </div>
                                );
                            }
                            let uiExpressOrderDlg = (
                                <Modal title={null} visible={this.state.bExpressOrderInput} footer={null} centered closable={false}
                                    onCancel={this.CancelInputExpressOrder}>
                                    <div className="store_order_pickup_order_express_input_row">
                                        物流单号：
                                        <Input className="package_scan_item_info_input" ref="express_order" />
                                    </div>
                                    <div className="store_order_pickup_dlg_button_area">
                                        <div className="store_order_pickup_dlg_button" style={{ background: 'grey', marginLeft: 'auto' }}
                                            onClick={this.CancelInputExpressOrder}>取 消</div>
                                        <div className="store_order_pickup_dlg_button" onClick={this.AfterInputExpressOrder.bind(this, indexOrder)}>确 认</div>
                                    </div>
                                </Modal>
                            );
                            let uiExpressOrder = null;
                            if (this.props.objStoreInfo.type === 1 && order.delivery_way === 2) {
                                let strExpressOrder = "暂无";
                                let uiTrackingButton = null;
                                if (order.tracking_pin !== undefined && order.tracking_pin !== null && order.tracking_pin !== "") {
                                    strExpressOrder = order.tracking_pin;
                                    uiTrackingButton = (
                                        <div className="store_order_pickup_active_button" style={{ marginLeft: 'auto', marginRight: '1vw' }}
                                            onClick={this.GetTrackingInfo.bind(this, indexOrder)}>
                                            追 踪</div>
                                    );
                                }
                                uiExpressOrder = (
                                    <div className="store_order_pickup_details_text">
                                        {this.arrOrderInfoTitle[8] + "：" + strExpressOrder}
                                        {uiTrackingButton}
                                    </div>
                                );
                            }
                            let fDiscount = "";
                            let strDiscount = "无";
                            let fBenefit = order.store_benefit;
                            if (order.comments !== null && order.comments !== "" && typeof (order.comments) === "string") {
                                if (order.comments.indexOf('#$-') >= 0) {
                                    fDiscount = order.comments.split('#$-')[1];
                                    fDiscount = parseFloat(fDiscount) * -1.0;
                                }
                                if (order.comments.indexOf('#$+') >= 0) {
                                    fDiscount = order.comments.split('#$+')[1];
                                    fDiscount = parseFloat(fDiscount);
                                }
                                fDiscount = parseFloat(fDiscount);
                                strDiscount = order.comments;
                            }
                            if (!isNaN(parseInt(fDiscount, 10))) {
                                fBenefit = parseFloat(order.store_benefit) + fDiscount;
                            }

                            // 修改备注（休会/折扣信息）窗口
                            let uiCommentsDlg = (
                                <Modal title={null} visible={this.state.bCommentsDlg} footer={null} centered closable={false}
                                    onCancel={this.ShowCommentsDlg.bind(this, -1, -1)}>
                                    <div className="store_order_pickup_order_express_input_row">
                                        备注/折扣：
                                        <Input className="package_scan_item_info_input" ref="order_comments" defaultValue={order.comments} />
                                    </div>
                                    <div className="store_order_pickup_dlg_button_area">
                                        <div className="store_order_pickup_dlg_button" style={{ background: 'grey', marginLeft: 'auto' }}
                                            onClick={this.ShowCommentsDlg.bind(this, null, -1)}>取 消</div>
                                        <div className="store_order_pickup_dlg_button" onClick={this.ShowCommentsDlg.bind(this, -1, 1)}>确 认</div>
                                    </div>
                                </Modal>
                            );
                            // 修改姓名窗口
                            let uiNameDlg = (
                                <Modal title={null} visible={this.state.bNameDlg} footer={null} centered closable={false}
                                    onCancel={this.ShowNameDlg.bind(this, -1, -1)}>
                                    <div className="store_order_pickup_order_express_input_row">
                                        收件人名：
                                        <Input className="package_scan_item_info_input" ref="order_contact" defaultValue={order.contact} />
                                    </div>
                                    <div className="store_order_pickup_dlg_button_area">
                                        <div className="store_order_pickup_dlg_button" style={{ background: 'grey', marginLeft: 'auto' }}
                                            onClick={this.ShowNameDlg.bind(this, null, -1)}>取 消</div>
                                        <div className="store_order_pickup_dlg_button" onClick={this.ShowNameDlg.bind(this, -1, 1)}>确 认</div>
                                    </div>
                                </Modal>
                            );

                            return (
                                <Panel header={uiTitle} key={indexOrder}>
                                    <div className="store_order_pickup_order_details_area">
                                        {uiCancelReason}
                                        <div className="store_order_pickup_details_text">
                                            {this.arrOrderInfoTitle[4] + "：" + strName}
                                            <div className="store_order_pickup_active_button" style={{ marginLeft: 'auto', marginRight: '1vw' }}
                                                onClick={this.ShowNameDlg.bind(this, indexOrder, 0)}>
                                                修 改</div>
                                        </div>
                                        <div className="store_order_pickup_details_text">
                                            {this.arrOrderInfoTitle[5] + "：" + strPhone}
                                        </div>
                                        <div className="store_order_pickup_details_text">
                                            {this.arrOrderInfoTitle[6] + "：" + strAddress}
                                        </div>
                                        {uiExpressOrder}
                                        <div className="store_order_pickup_details_text">
                                            {"备注/折扣：" + strDiscount}
                                            <div className="store_order_pickup_active_button" style={{ marginLeft: 'auto', marginRight: '1vw' }}
                                                onClick={this.ShowCommentsDlg.bind(this, indexOrder, 0)}>
                                                修 改</div>
                                        </div>
                                        {uiCommentsDlg}
                                        {uiNameDlg}
                                        <div className="store_order_pickup_payment_details">
                                            <div className="store_order_pickup_details_text" style={{ fontWeight: 'bold' }}>
                                                账目明细：
                                            </div>
                                            <div className="store_order_pickup_details_text">
                                                {"商品(含税)：$ " + (parseFloat(order.total_price) + parseFloat(order.gst_pst)).toFixed(2)}
                                            </div>
                                            <div className="store_order_pickup_details_text">
                                                {"运费：$" + parseFloat(order.delivery_fee).toFixed(2)}
                                            </div>
                                            <div className="store_order_pickup_details_text">
                                                {"手续费：$" + (parseFloat(order.mouthing_benefit)).toFixed(2)}
                                            </div>
                                            <div className="store_order_pickup_details_text">
                                                {"实际支付：$" + parseFloat(fBenefit).toFixed(2)}
                                            </div>
                                        </div>
                                        <div className="store_order_pickup_details_text">
                                            {this.arrOrderInfoTitle[7] + "："}
                                        </div>
                                        {uiItemInfo}
                                        {uiOrderActiveArea}
                                        {uiExpressOrderDlg}

                                    </div>
                                </Panel>
                            );
                        })}
                    </Collapse>
                </div >
            );
        }
        // 订单操作确认弹窗
        let uiProcessOrderDlg = null;
        if (this.state.iProcessOrderType === 2) {
            const menuCancelReason = (
                <Menu>
                    {this.arrOrderCancelReason.map((item, index) => {
                        return (
                            <Menu.Item key={index} onClick={this.OrderCancelReasonChange.bind(this, index)}>
                                <div className="store_order_pickup_cancel_reason_dropdown_text">{item.split(',')[0]}</div>
                            </Menu.Item>
                        );
                    })}
                </Menu>
            );
            uiProcessOrderDlg = (
                <Modal title={"取消订单"} visible={this.state.bProcessOrderDlg} closable={true} footer={null} centered
                    onCancel={this.OrderProcessDlg.bind(this, -1, -1)}>
                    <Dropdown overlay={menuCancelReason} trigger={['click']}>
                        <div className="store_order_pickup_dlg_title">
                            <div className="store_order_pickup_dlg_title_text">原因 : </div>
                            <div className="store_order_pickup_cancel_reason_dropdown" >
                                {this.arrOrderCancelReason[this.state.iOrderCancelReason].split(',')[0]}<Icon type="caret-down" theme="filled" style={{ marginLeft: '1vw' }} />
                            </div>
                        </div>
                    </Dropdown>
                    <div className="store_order_pickup_dlg_button_area">
                        <div className="store_order_pickup_dlg_button" style={{ background: 'grey', marginLeft: 'auto' }}
                            onClick={this.OrderProcessDlg.bind(this, -1, -1)}>取 消</div>
                        <div className="store_order_pickup_dlg_button" onClick={this.CancelOrder}>确 认</div>
                    </div>
                </Modal>
            );
        }
        return (
            <div className="store_order_pickup_page">
                <div className="store_order_pickup_title_area">
                    {this.arrOrderFilter.map((item, index) => {
                        let strStyle = "store_order_pickup_title_order_filter";
                        if (index === this.state.iOrderFilter)
                            strStyle = "store_order_pickup_title_order_filter_select";
                        if (index < this.arrOrderFilter.length - 1)
                            strStyle += " order_filter_right_border";
                        let uiReminder = null;
                        if (index === 0 && this.bUnviewedOrder) {
                            uiReminder = (<div className="store_order_new_order_reminder" />);
                        }
                        return (
                            <div className={strStyle} key={index} onClick={this.SwitchOrderFilter.bind(this, index)}>
                                {item + " (" + this.arrOrderFilterNum[index] + ")"}
                                {uiReminder}
                            </div>
                        )
                    })}

                </div>
                {/* <div style={{ background: 'darkcyan', padding: '10px', color: 'white' }} onClick={this.ViewPDF}>
                    test</div> */}
                {uiOrderList}
                {uiProcessOrderDlg}
                <Countdown title="Countdown" value={this.state.deadline} onFinish={this.TimeCountDown} style={{ position: 'fixed', top: '-10vw', left: '0vw' }} />
            </div>
        );
    }
}
export default StoreOrderPickup;
