import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
import XLSX from 'xlsx';

import Spin from 'antd/es/spin';
import Table from 'antd/es/table';
import Button from 'antd/es/button';
import Input from 'antd/es/input';
import Icon from 'antd/es/icon';
import Modal from 'antd/es/modal';
import DatePicker from 'antd/es/date-picker';
import Menu from 'antd/es/menu';
import Dropdown from 'antd/es/dropdown';
import message from 'antd/es/message';

import '../../../styles/Background/ego_manager.css';
import '../../../styles/Background/UI_Module/user_management.css';
// import { UserActiveUpdate } from "../../../functions/DataStatistic.js"

const g_strMessageKey = 'updating';
const dateFormat2 = 'YYYY-MM-DD';

// 将一个sheet转成最终的excel文件的blob对象，然后利用URL.createObjectURL下载
function sheet2blob(sheet, sheetName) {
    sheetName = sheetName || 'sheet1';
    var workbook = {
        SheetNames: [sheetName],
        Sheets: {}
    };
    workbook.Sheets[sheetName] = sheet;
    // 生成excel的配置项
    var wopts = {
        bookType: 'xlsx', // 要生成的文件类型
        bookSST: false, // 是否生成Shared String Table，官方解释是，如果开启生成速度会下降，但在低版本IOS设备上有更好的兼容性
        type: 'binary'
    };
    var wbout = XLSX.write(workbook, wopts);
    var blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });
    // 字符串转ArrayBuffer
    function s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
    }
    return blob;
}
//  通用的打开下载对话框方法，没有测试过具体兼容性
//  @param url 下载地址，也可以是一个blob对象，必选
//  @param saveName 保存文件名，可选
function openDownloadDialog(url, saveName) {
    if (typeof url == 'object' && url instanceof Blob) {
        url = URL.createObjectURL(url); // 创建blob地址
    }
    var aLink = document.createElement('a');
    aLink.href = url;
    aLink.download = saveName || ''; // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，file:///模式下不会生效
    var event;
    if (window.MouseEvent) event = new MouseEvent('click');
    else {
        event = document.createEvent('MouseEvents');
        event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
    }
    aLink.dispatchEvent(event);
}

// 表格
const arrTableHeader1 = [
    { title: '单号', dataIndex: 'oid', width: 90, ellipsis: true },
    { title: '收件人', dataIndex: 'receiver', width: 90, ellipsis: true },
    { title: '线路', dataIndex: 'path_str', width: 80, ellipsis: true },
    { title: '实重', dataIndex: 'true_weight', width: 45, ellipsis: true },
    { title: '重量', dataIndex: 'weight', width: 45, ellipsis: true },
    { title: '单价', dataIndex: 'price_fee_str', width: 65, ellipsis: true },
    { title: '包装费', dataIndex: 'pack_fee_str', width: 65, ellipsis: true },
    { title: '代买费', dataIndex: 'agent_fee_str', width: 65, ellipsis: true },
    { title: '手续费', dataIndex: 'server_fee_str', width: 65, ellipsis: true },
    { title: '折扣', dataIndex: 'price_off_str', width: 45, ellipsis: true },
    { title: '总收费', dataIndex: 'total_fee_str', width: 70, ellipsis: true },
    { title: '总成本', dataIndex: 'total_cost_str', width: 70, ellipsis: true },
    { title: '总利润', dataIndex: 'total_benefit_str', width: 70, ellipsis: true },
    { title: '收费方式', dataIndex: 'payment_str', width: 60, ellipsis: true },
    { title: '收费日期', dataIndex: 'bill_time_str', width: 75, ellipsis: true },
    { title: '创建日期', dataIndex: 'create_time_str', width: 75, ellipsis: true },
    { title: '备注', dataIndex: 'comments', width: 385, ellipsis: true },
];
const arrTableHeader2 = [
    { title: '单号', dataIndex: 'oid', width: 90, ellipsis: true },
    { title: '收件人', dataIndex: 'receiver', width: 90, ellipsis: true },
    { title: '线路', dataIndex: 'path_str', width: 80, ellipsis: true },
    { title: '实重', dataIndex: 'true_weight', width: 45, ellipsis: true },
    { title: '重量', dataIndex: 'weight', width: 45, ellipsis: true },
    { title: '单价', dataIndex: 'price_fee_str', width: 65, ellipsis: true },
    { title: '包装费', dataIndex: 'pack_fee_str', width: 65, ellipsis: true },
    { title: '代买费', dataIndex: 'agent_fee_str', width: 65, ellipsis: true },
    { title: '手续费', dataIndex: 'server_fee_str', width: 65, ellipsis: true },
    { title: '折扣', dataIndex: 'price_off_str', width: 45, ellipsis: true },
    { title: '总收费', dataIndex: 'total_fee_str', width: 70, ellipsis: true },
    // { title: '总成本', dataIndex: 'total_cost_str', width: 70, ellipsis: true },
    // { title: '总利润', dataIndex: 'total_benefit_str', width: 70, ellipsis: true },
    { title: '收费方式', dataIndex: 'payment_str', width: 60, ellipsis: true },
    { title: '收费日期', dataIndex: 'bill_time_str', width: 75, ellipsis: true },
    { title: '创建日期', dataIndex: 'create_time_str', width: 75, ellipsis: true },
    { title: '备注', dataIndex: 'comments', width: 385, ellipsis: true },
];

class AccountingSN extends Component {
    constructor(props) {
        super();
        this.state = {
            btnControlDisable: [false, true, true, true],  // 操作按钮控制
            selectedRowKeys: [],  // Check here to configure the default column
            objFilter: {
                strKeyword: "", iCurrentUserStatus: 0, iCurrentUserType: 0
            },
            iDlgType: -1,
            bShowDlg: false,
            bLoading: true,
            strNewPassword: "",
            strConfirmPassword: "",
            pay_date: new Date(),
            iPath: 0,
            iPayment: 0,
            bFeeChange: false
        };
        // 成员变量
        this.arrOgeTableData = [];
        this.arrTableData = [];  // 用于表格的数组
        this.arrSelectAccount = [];
        this.objCurrentAccount = {};
        this.arrPath = ['北美境内', '加拿大->中国', 'VIP', '空运', '海运(CN->CA)', '海运(CA->CN)', '海运(头程)'];
        this.arrPaymentType = ['现金', 'POS机', 'EMT', '支票', '转账'];
        this.arrTableHeader = arrTableHeader1;
        if (props.iUserLevel < 9)
            this.arrTableHeader = arrTableHeader2;
    }

    // 选中某行的响应函数
    selectRow = (record) => {
        const selectedRowKeys = [...this.state.selectedRowKeys];
        if (selectedRowKeys.indexOf(record.key) >= 0) {
            selectedRowKeys.splice(selectedRowKeys.indexOf(record.key), 1);
        } else {
            selectedRowKeys.push(record.key);
        }
        this.SelectedRowStateSetting(selectedRowKeys);
    }
    // 选中的行发生变化的响应函数
    onSelectedRowKeysChange = (selectedRowKeys) => {
        this.SelectedRowStateSetting(selectedRowKeys);
    }
    // 处理选中的表行行
    SelectedRowStateSetting = (selectedRowKeys) => {
        let btnControlDisable = [];
        let btnCopyPasteDisable = [];
        switch (selectedRowKeys.length) {
            case 0: {
                btnControlDisable = [false, true, true, true];
                this.objCurrentAccount = {};
                break;
            }
            case 1: {
                this.arrSelectAccount = [];
                btnControlDisable = [false, false, false, false];
                for (let i = 0; i < this.arrTableData.length; i++) {
                    if (this.arrTableData[i].key === selectedRowKeys[0]) {
                        this.objCurrentAccount = this.arrTableData[i];
                        this.arrSelectAccount.push(this.arrTableData[i]);
                        break;
                    }
                }
                // console.log(this.arrSelectAccount);
                break;
            }
            default: {
                this.arrSelectAccount = [];
                btnControlDisable = [false, true, true, false];
                for (let j = 0; j < this.arrTableData.length; j++) {
                    for (let i = 0; i < this.arrTableData.length; i++) {
                        if (this.arrTableData[i].key === selectedRowKeys[j]) {
                            this.arrSelectAccount.push(this.arrTableData[i]);
                            break;
                        }
                    }
                }
                // console.log(this.arrSelectAccount);
                break;
            }
        }
        this.setState({
            ...this.state,
            selectedRowKeys,
            btnControlDisable: btnControlDisable,
            btnCopyPasteDisable: btnCopyPasteDisable
        });
    }
    // 关键词处理
    KeywordsProcess = (strKeywords) => {
        let newKeywords = "";
        for (let i = 0; i < strKeywords.length; i++) {
            if (strKeywords[i] !== " ") {
                if (i === strKeywords.length - 1)
                    newKeywords += strKeywords[i];
                else
                    newKeywords += strKeywords[i] + "%";
            }
        }
        return newKeywords;
    }
    // 搜索账目名
    AccountingRecordSearch = () => {
        let strKeywords = this.refs.search.state.value;
        if (strKeywords === undefined)
            strKeywords = "";
        if (strKeywords.trim() === "") {
            this.GetAccountingRecords();
        } else {
            this.setState({
                ...this.state,
                bLoading: true,
                selectedRowKeys: [],
                btnControlDisable: [false, true, true]
            });
            axios('/SearchAccountingRecord', { params: { name: this.KeywordsProcess(strKeywords), store_id: this.props.iStoreID } })
                .then(({ data }) => {
                    this.arrTableData = this.OrganizeAccountingRecords(data);
                    this.setState({
                        ...this.state,
                        bLoading: false
                    });
                }).catch(function (error) { console.log(error); });
        }
    }
    // 费用变化
    FeeChange = (index, e) => {
        let fTotalFee = this.refs.total_fee.state.value;
        let fTotalCost = this.refs.total_cost.state.value;
        if (index === 0) {
            fTotalFee = e.target.value;
            if (fTotalFee === null || fTotalFee === undefined || fTotalFee === "")
                fTotalFee = 0.0;
            if (typeof (fTotalFee) === "string")
                fTotalFee = fTotalFee.trim();
            fTotalFee = parseFloat(fTotalFee);
            if (typeof (fTotalFee) === "number")
                fTotalFee = fTotalFee.toFixed(2);
        }
        if (index === 1) {
            fTotalCost = e.target.value;
            if (fTotalCost === null || fTotalCost === undefined || fTotalCost === "")
                fTotalCost = 0.0;
            if (typeof (fTotalCost) === "string")
                fTotalCost = fTotalCost.trim();
            fTotalCost = parseFloat(fTotalCost);
            if (typeof (fTotalCost) === "number")
                fTotalCost = fTotalCost.toFixed(2);
        }
        let fTotalBenefit = fTotalFee - fTotalCost;
        if (typeof (fTotalBenefit) === "number")
            fTotalBenefit = fTotalBenefit.toFixed(2);
        this.refs.total_benefit.state.value = fTotalBenefit;

        this.setState({
            ...this.state,
            bFeeChange: true
        })

    }

    /* 自定义成员函数 */
    //弹出对话框
    ShowDlg = (iDlgType, e) => {
        if (iDlgType === 0) {
            this.objCurrentAccount = {
                oid: "",
                store_id: this.props.iStoreID,
                path: this.state.iPath,
                payment: this.state.iPayment,
                receiver: "",
                price_off: 0.0,
                bill_time: this.state.pay_date,
                true_weight: 0.00,
                weight: 0.00,
                agent_fee: 0.00,
                price_fee: 0.00,
                price_cost: 0.00,
                pack_fee: 0.00,
                pack_cost: 0.00,
                server_fee: 0.00,
                server_cost: 0.00,
                insurance: 0.00,
                total_fee: 0.00,
                total_cost: 0.00,
                total_benefit: 0.00,
                comments: "",
                update_time: new Date(),
                create_time: new Date(),
            };
            if (this.refs.oid !== undefined) {
                this.refs.oid.state.value = "";
                this.refs.receiver.state.value = "";
                this.refs.price_off.state.value = "0";
                this.refs.true_weight.state.value = "0.00";
                this.refs.weight.state.value = "0.00";
                this.refs.agent_fee.state.value = "0.00";
                this.refs.price_fee.state.value = "0.00";
                this.refs.price_cost.state.value = "0.00";
                this.refs.pack_fee.state.value = "0.00";
                this.refs.pack_cost.state.value = "0.00";
                this.refs.server_fee.state.value = "0.00";
                this.refs.server_cost.state.value = "0.00";
                this.refs.insurance.state.value = "0.00";
                this.refs.comments.state.value = "";
                this.refs.total_fee.state.value = "0.00";
                this.refs.total_cost.state.value = "0.00";
                this.refs.total_benefit.state.value = "0.00";
            }
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true,
                iPath: 0,
                iPayment: 0,
                pay_date: new Date()
            });
        }
        if (iDlgType === 1) {
            if (this.refs.oid !== undefined) {
                this.refs.oid.state.value = this.objCurrentAccount.oid;
                this.refs.receiver.state.value = this.objCurrentAccount.receiver;
                this.refs.price_off.state.value = this.objCurrentAccount.price_off;
                this.refs.true_weight.state.value = this.objCurrentAccount.true_weight;
                this.refs.weight.state.value = this.objCurrentAccount.weight;
                this.refs.agent_fee.state.value = this.objCurrentAccount.agent_fee;
                this.refs.price_fee.state.value = this.objCurrentAccount.price_fee;
                this.refs.price_cost.state.value = this.objCurrentAccount.price_cost;
                this.refs.pack_fee.state.value = this.objCurrentAccount.pack_fee;
                this.refs.pack_cost.state.value = this.objCurrentAccount.pack_cost;
                this.refs.server_fee.state.value = this.objCurrentAccount.server_fee;
                this.refs.server_cost.state.value = this.objCurrentAccount.server_cost;
                this.refs.insurance.state.value = this.objCurrentAccount.insurance;
                this.refs.comments.state.value = this.objCurrentAccount.comments;
                this.refs.total_fee.state.value = this.objCurrentAccount.total_fee;
                this.refs.total_cost.state.value = this.objCurrentAccount.total_cost;
                this.refs.total_benefit.state.value = this.objCurrentAccount.total_benefit;
            }
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true,
                iPath: this.objCurrentAccount.path,
                iPayment: this.objCurrentAccount.payment,
                pay_date: this.objCurrentAccount.bill_time
            });
        }
        if (iDlgType === 2) {
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true
            });
        }
    }
    // 弹窗确认按钮响应
    handleOk = e => {
        if (this.state.iDlgType === 0) {

            let objNewAccount = {
                oid: this.refs.oid.state.value,
                store_id: this.props.iStoreID,
                path: this.state.iPath,
                payment: this.state.iPayment,
                receiver: this.refs.receiver.state.value,
                price_off: this.refs.price_off.state.value,
                bill_time: this.state.pay_date,
                true_weight: this.refs.true_weight.state.value,
                weight: this.refs.weight.state.value,
                agent_fee: this.refs.agent_fee.state.value,
                price_fee: this.refs.price_fee.state.value,
                price_cost: this.refs.price_cost.state.value,
                pack_fee: this.refs.pack_fee.state.value,
                pack_cost: this.refs.pack_cost.state.value,
                server_fee: this.refs.server_fee.state.value,
                server_cost: this.refs.server_cost.state.value,
                insurance: this.refs.insurance.state.value,
                total_fee: this.refs.total_fee.state.value,
                total_cost: this.refs.total_cost.state.value,
                total_benefit: this.refs.total_benefit.state.value,
                comments: this.refs.comments.state.value,
                update_time: new Date(),
                create_time: new Date(),
            };
            console.log(objNewAccount);
            message.loading({ content: '正在更新账目信息……', key: g_strMessageKey });
            axios.post('/CreateNewAccountingRecord', objNewAccount).then(
                ({ data }) => {
                    if (data.status === 1) {
                        this.GetAccountingRecords();
                    } else {
                        message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
                    }
                }).catch(function (error) { console.log(error); });
        }
        if (this.state.iDlgType === 1) {
            let objNewAccount = {
                oid: this.refs.oid.state.value,
                store_id: this.props.iStoreID,
                path: this.state.iPath,
                payment: this.state.iPayment,
                receiver: this.refs.receiver.state.value,
                price_off: this.refs.price_off.state.value,
                bill_time: this.state.pay_date,
                true_weight: this.refs.true_weight.state.value,
                weight: this.refs.weight.state.value,
                agent_fee: this.refs.agent_fee.state.value,
                price_fee: this.refs.price_fee.state.value,
                price_cost: this.refs.price_cost.state.value,
                pack_fee: this.refs.pack_fee.state.value,
                pack_cost: this.refs.pack_cost.state.value,
                server_fee: this.refs.server_fee.state.value,
                server_cost: this.refs.server_cost.state.value,
                insurance: this.refs.insurance.state.value,
                total_fee: this.refs.total_fee.state.value,
                total_cost: this.refs.total_cost.state.value,
                total_benefit: this.refs.total_benefit.state.value,
                comments: this.refs.comments.state.value,
                update_time: new Date()
            };
            objNewAccount.id = this.objCurrentAccount.id;
            console.log(objNewAccount);
            message.loading({ content: '正在更新账目信息……', key: g_strMessageKey });
            axios.post('/EditAccountingRecords', {
                objNewAccount: objNewAccount
            }).then(({ data }) => {
                if (data.status === 1)
                    this.GetAccountingRecords();
                else
                    message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
            }).catch(function (error) { console.log(error); });
        }
        if (this.state.iDlgType === 2) {
            message.loading({ content: '账目删除中……', key: g_strMessageKey });
            axios.post('/DeleteAccountingRecord', { id: this.objCurrentAccount.id }).then(
                ({ data }) => {
                    if (data.status === -1) {
                        message.warning({
                            content: "删除失败，请重新尝试！",
                            key: g_strMessageKey, duration: 2
                        });
                        this.setState({
                            ...this.state,
                            bLoading: false,
                            bShowDlg: false,
                            selectedRowKeys: []
                        });
                    } else {
                        this.GetAccountingRecords();
                    }
                }).catch(function (error) { console.log(error); });
        }
    };
    // 弹窗取消按钮响应
    handleCancel = e => {
        this.setState({
            bShowDlg: false,
        });
    };
    // 整理Users数据
    OrganizeAccountingRecords(data) {
        let arrTableData = [];
        for (let i = 0; i < data.length; i++) {
            let objAccountingRecords = data[i];
            objAccountingRecords.key = i;
            objAccountingRecords.path_str = this.arrPath[data[i].path];
            objAccountingRecords.payment_str = this.arrPaymentType[data[i].payment];
            objAccountingRecords.bill_time_str = data[i].bill_time.split('T')[0];
            objAccountingRecords.create_time_str = data[i].create_time.split('T')[0];
            objAccountingRecords.price_fee_str = "$ " + data[i].price_fee;
            objAccountingRecords.price_off_str = data[i].price_off + " %";
            objAccountingRecords.pack_fee_str = "$ " + data[i].pack_fee;
            objAccountingRecords.agent_fee_str = "$ " + data[i].agent_fee;
            objAccountingRecords.server_fee_str = "$ " + data[i].server_fee;

            if (data[i].total_fee === null || data[i].total_fee === undefined || data[i].total_fee === "")
                data[i].total_fee = 0.00;
            if (data[i].total_cost === null || data[i].total_cost === undefined || data[i].total_cost === "")
                data[i].total_cost = 0.00;
            if (data[i].total_benefit === null || data[i].total_benefit === undefined || data[i].total_benefit === "")
                data[i].total_benefit = 0.00;
            objAccountingRecords.total_fee_str = "$ " + data[i].total_fee;
            objAccountingRecords.total_cost_str = "$ " + data[i].total_cost;
            objAccountingRecords.total_benefit_str = "$ " + data[i].total_benefit;
            arrTableData.push(objAccountingRecords);
        }
        return arrTableData;
    }
    // 显示全部账目
    ShowAllAccountingRecords = () => {
        this.refs.search.state.value = "";
        this.AccountingRecordSearch();
    }
    // 从数据库获取当前的数据结构
    GetAccountingRecords() {
        message.loading({ content: '数据加载中……', key: g_strMessageKey });
        axios('/GetAccountingRecords', { params: { store_id: this.props.iStoreID } })
            .then(({ data }) => {
                console.log("GetAccountingRecords:", data);
                this.arrOgeTableData = this.OrganizeAccountingRecords(data);
                this.arrTableData = this.arrOgeTableData;
                if (this.state.bShowDlg) {
                    if (this.state.iDlgType === 0) {
                        message.success({ content: "新建账目信息成功！", key: g_strMessageKey, duration: 2 });
                    }
                    if (this.state.iDlgType === 1) {
                        message.success({ content: "账目信息已更新！", key: g_strMessageKey, duration: 2 });
                    }
                    if (this.state.iDlgType === 2) {
                        message.success({ content: "账目已删除！", key: g_strMessageKey, duration: 2 })
                    }
                } else {
                    message.success({ content: "数据加载完成！", key: g_strMessageKey, duration: 2 });
                }
                this.setState({
                    ...this.state,
                    bLoading: false,
                    bShowDlg: false,
                    selectedRowKeys: []
                });
            }).catch(function (error) { console.log(error); });
    }
    // 路线变更
    PathChange = (index) => {
        this.setState({
            ...this.state,
            iPath: index
        })
    }
    // 支付方式变更
    PaymentChange = (index) => {
        this.setState({
            ...this.state,
            iPayment: index
        })
    }
    // 收费日期更改
    PayDateChange = (date, dateString) => {
        this.setState({
            ...this.state,
            pay_date: dateString
        });
    }
    // 导出Excel文档
    ExportExcelFile = () => {
        console.log("导出订单列表：", this.arrSelectAccount);
        let arrExportData = [
            ['单号', '收件人', '线路', '实重', '重量', '单价', '包装费', '代买费', '手续费', '折扣', '总费用', '总成本', '总利润', '收费方式', '收费日期', '备注']
        ];
        for (let i = 0; i < this.arrSelectAccount.length; i++) {
            let arrData = [];
            arrData.push(this.arrSelectAccount[i].oid); arrData.push(this.arrSelectAccount[i].receiver); arrData.push(this.arrSelectAccount[i].path_str);
            arrData.push(this.arrSelectAccount[i].true_weight); arrData.push(this.arrSelectAccount[i].weight); arrData.push(this.arrSelectAccount[i].price_fee_str);
            arrData.push(this.arrSelectAccount[i].pack_fee_str); arrData.push(this.arrSelectAccount[i].agent_fee_str); arrData.push(this.arrSelectAccount[i].server_fee_str);
            arrData.push(this.arrSelectAccount[i].price_off_str); arrData.push(this.arrSelectAccount[i].total_fee_str); arrData.push(this.arrSelectAccount[i].total_cost_str);
            arrData.push(this.arrSelectAccount[i].total_benefit_str); arrData.push(this.arrSelectAccount[i].payment_str); arrData.push(this.arrSelectAccount[i].bill_time_str);
            arrData.push(this.arrSelectAccount[i].comments);
            arrExportData.push(arrData);
        }
        console.log("export excel file", arrExportData);
        var sheet = XLSX.utils.aoa_to_sheet(arrExportData);
        openDownloadDialog(sheet2blob(sheet), '账目列表.xlsx');

    }


    /* React自带函数 */
    // 卸载异步操作设置状态
    componentWillUnmount() {
        clearTimeout(this.timeouter)
        this.setState = (state, callback) => {
            return;
        }
    }
    // 渲染完毕……
    componentDidMount() {
        this.GetAccountingRecords();
    }
    // 渲染函数
    render() {

        /* 账目管理页面控制栏 */
        let uiTitleArea = (
            < div className="store_contents_title" >
                <Button className="manage_contents_title_margin manage_contents_title_margin_right export_button_style"
                    style={{ border: 'none', borderRadius: '5px', background: 'darkorange' }}
                    type="primary" onClick={this.ShowAllAccountingRecords}>
                    显示全部<Icon type="redo" /></Button>
                <div className="manage_stretch_right">
                    <Input placeholder="搜索单号/收件人/备注" ref="search" defaultValue={this.state.strKeywords} />
                    <Button className="manage_contents_title_margin export_button_style" type="primary" onClick={this.AccountingRecordSearch}
                        style={{ border: 'none', borderRadius: '5px', background: 'darkorange' }}> 搜索 </Button>
                </div>
                <div className="manage_title_button_right_side">
                    <Button className="manage_contents_title_margin" type="primary" onClick={this.ShowDlg.bind(this, 0)} >
                        <Icon type="plus" />新建</Button>
                    <Button className="manage_contents_title_margin" type="primary" onClick={this.ShowDlg.bind(this, 1)} disabled={this.state.btnControlDisable[1]}>
                        <Icon type="edit" />编辑</Button>
                    <Button className="manage_contents_title_margin" type="danger" onClick={this.ShowDlg.bind(this, 2)} disabled={this.state.btnControlDisable[2]}>
                        <Icon type="close" /> 删除</Button>
                    <Button className="manage_contents_title_margin" type="primary" onClick={this.ExportExcelFile.bind(this)} disabled={this.state.btnControlDisable[3]}>
                        <Icon type="edit" />导出</Button>
                </div>
            </div >
        );

        // 如果界面上需要显示的内容已经从数据库获得
        if (!this.state.bLoading) {
            // 主体表格相关
            const { selectedRowKeys } = this.state;
            const rowSelection = {
                selectedRowKeys,
                onChange: this.onSelectedRowKeysChange,
                columnWidth: 35
            };
            // 表格分页属性
            const paginationProps = {
                showSizeChanger: true,
                showQuickJumper: false,
                showTotal: () => `共 ${this.arrTableData.length} 个 账目`,
                defaultPageSize: 10,
                defaultCurrent: this.state.iCurrentPage,
                pageSizeOptions: ['10', '20', '30', '40', '50'],
                total: this.arrTableData.length,
                position: 'bottom'
            };
            let uiPathFilter = (
                <Menu>
                    {this.arrPath.map((path, index) => {
                        return (
                            <Menu.Item key={index} onClick={this.PathChange.bind(this, index)}>
                                {path}
                            </Menu.Item>
                        );
                    })}
                </Menu>
            );
            let uiPaymentTypeFilter = (
                <Menu>
                    {this.arrPaymentType.map((type, index) => {
                        return (
                            <Menu.Item key={index} onClick={this.PaymentChange.bind(this, index)}>
                                {type}
                            </Menu.Item>
                        );
                    })}
                </Menu>
            );
            // 账目信息编辑弹窗
            let uiCost = null;
            let uiTotal = (
                <div className="user_edit_info_row">
                    <div className="user_edit_info_title" style={{ color: 'darkorange', fontWeight: 'bold' }} >总计收费：</div>
                    <Input className="user_edit_info_input" ref="total_fee" style={{ marginRight: '20px' }} addonBefore="CAD"
                        defaultValue={this.objCurrentAccount.total_fee} onChange={this.FeeChange.bind(this, 0)} />
                </div>
            );
            if (this.props.iUserLevel >= 9) {
                uiCost = (
                    <div className="user_edit_info_row">
                        <div className="user_edit_info_title" >单价成本：</div>
                        <Input className="user_edit_info_input" ref="price_cost" style={{ marginRight: '20px' }} addonBefore="CAD"
                            defaultValue={this.objCurrentAccount.price_cost} />
                        <div className="user_edit_info_title" >包装成本：</div>
                        <Input className="user_edit_info_input" ref="pack_cost" style={{ marginRight: '20px' }} addonBefore="CAD"
                            defaultValue={this.objCurrentAccount.pack_cost} />
                        <div className="user_edit_info_title" >手续成本：</div>
                        <Input className="user_edit_info_input" ref="server_cost" style={{ marginRight: '20px' }} addonBefore="CAD"
                            defaultValue={this.objCurrentAccount.server_cost} />
                    </div>
                );
                uiTotal = (
                    <div className="user_edit_info_row">
                        <div className="user_edit_info_title" style={{ color: 'darkorange', fontWeight: 'bold' }} >总计收费：</div>
                        <Input className="user_edit_info_input" ref="total_fee" style={{ marginRight: '20px' }} addonBefore="CAD"
                            defaultValue={this.objCurrentAccount.total_fee} onChange={this.FeeChange.bind(this, 0)} />
                        <div className="user_edit_info_title" style={{ color: 'darkorange', fontWeight: 'bold' }} >总计成本：</div>
                        <Input className="user_edit_info_input" ref="total_cost" style={{ marginRight: '20px' }} addonBefore="CAD"
                            defaultValue={this.objCurrentAccount.total_cost} onChange={this.FeeChange.bind(this, 1)} />
                        <div className="user_edit_info_title" style={{ color: 'darkorange', fontWeight: 'bold' }} >总计利润：</div>
                        <Input className="user_edit_info_input" ref="total_benefit" style={{ marginRight: '20px' }} addonBefore="CAD"
                            defaultValue={this.objCurrentAccount.total_benefit} disabled />
                    </div>
                );
            }

            let uiModal = (
                <Modal title="账目明细" width={925} visible={this.state.bShowDlg}
                    okText="确定" cancelText="取消" onOk={this.handleOk} onCancel={this.handleCancel} >
                    <div className="user_edit_info_row">
                        <div className="user_edit_info_title" >单号：</div>
                        <Input className="user_edit_info_input" ref="oid" style={{ marginRight: '20px' }}
                            defaultValue={this.objCurrentAccount.oid} />
                        <div className="user_edit_info_title" >线路：</div>
                        <Dropdown className="user_edit_info_input" trigger={['click']}
                            overlay={uiPathFilter} placement="bottomLeft" >
                            <Button style={{ width: '125px', marginRight: '20px' }}>{this.arrPath[this.state.iPath]}
                                <Icon type="caret-down" /></Button>
                        </Dropdown>
                        <div className="user_edit_info_title" >付款方式：</div>
                        <Dropdown className="user_edit_info_input" trigger={['click']}
                            overlay={uiPaymentTypeFilter} placement="bottomLeft">
                            <Button style={{ width: '125px' }}>{this.arrPaymentType[this.state.iPayment]}
                                <Icon type="caret-down" /></Button>
                        </Dropdown>
                    </div>
                    <div className="user_edit_info_row">
                        <div className="user_edit_info_title" >收件人</div>
                        <Input className="user_edit_info_input" ref="receiver" style={{ marginRight: '20px' }}
                            defaultValue={this.objCurrentAccount.receiver} />
                        <div className="user_edit_info_title" >折扣：</div>
                        <Input className="user_edit_info_input" ref="price_off" style={{ marginRight: '20px' }} addonAfter="%"
                            defaultValue={this.objCurrentAccount.price_off} />
                        <div className="user_edit_info_title" >收费日期：</div>
                        <DatePicker className="user_edit_info_input" onChange={this.PayDateChange}
                            value={moment(this.state.pay_date, dateFormat2)}
                            defaultValue={moment(this.state.pay_date, dateFormat2)} />
                    </div>
                    <div className="user_edit_info_row">
                        <div className="user_edit_info_title" >实重：</div>
                        <Input className="user_edit_info_input" ref="true_weight" style={{ marginRight: '20px' }}
                            defaultValue={this.objCurrentAccount.true_weight} />
                        <div className="user_edit_info_title" >重量：</div>
                        <Input className="user_edit_info_input" ref="weight" style={{ marginRight: '20px' }}
                            defaultValue={this.objCurrentAccount.weight} />
                        <div className="user_edit_info_title" >代买费：</div>
                        <Input className="user_edit_info_input" ref="agent_fee" style={{ marginRight: '20px' }} addonBefore="CAD"
                            defaultValue={this.objCurrentAccount.agent_fee} />
                    </div>
                    <div className="user_edit_info_row">
                        <div className="user_edit_info_title" >单价收费：</div>
                        <Input className="user_edit_info_input" ref="price_fee" style={{ marginRight: '20px' }} addonBefore="CAD"
                            defaultValue={this.objCurrentAccount.price_fee} />
                        <div className="user_edit_info_title" >包装收费：</div>
                        <Input className="user_edit_info_input" ref="pack_fee" style={{ marginRight: '20px' }} addonBefore="CAD"
                            defaultValue={this.objCurrentAccount.pack_fee} />
                        <div className="user_edit_info_title" >手续收费：</div>
                        <Input className="user_edit_info_input" ref="server_fee" style={{ marginRight: '20px' }} addonBefore="CAD"
                            defaultValue={this.objCurrentAccount.server_fee} />
                    </div>
                    {uiCost}
                    {uiTotal}
                    <div className="user_edit_info_row">
                        <div className="user_edit_info_title" >保险：</div>
                        <Input className="user_edit_info_input" ref="insurance" style={{ marginRight: '20px' }} addonBefore="CAD"
                            defaultValue={this.objCurrentAccount.insurance} />
                        <div className="user_edit_info_title" >备注：</div>
                        <Input className="user_edit_info_input_long" ref="comments"
                            defaultValue={this.objCurrentAccount.comments} />
                    </div>
                </Modal>
            );
            if (this.state.iDlgType === 2) {
                uiModal = (
                    <Modal width={500} title={"删除账目信息"} visible={this.state.bShowDlg} okType="danger"
                        onOk={this.handleOk} okText="确定"
                        onCancel={this.handleCancel} cancelText="取消">
                        <div className="modal_item">
                            <div>{this.objCurrentAccount.oid + ", " + this.objCurrentAccount.receiver}
                                <br /><br />确定删除选中的账目？
                            </div>
                        </div>
                    </Modal>
                );
            }

            return (
                <div className="store_manage_contents store_manage_top_space">
                    {uiTitleArea}
                    {uiModal}
                    {/* 表格主体 */}
                    <div className="sooning_accounting_table">
                        <Table style={{ margin: '20px', marginTop: '0px', width: '2500px', overflowX: 'auto' }} bordered
                            rowSelection={rowSelection} pagination={paginationProps} scroll={{ y: '70vh' }}
                            columns={this.arrTableHeader} dataSource={this.arrTableData}
                            onRow={(record) => ({ onClick: () => { this.selectRow(record); } })} />
                    </div>

                </div >
            );
        } else {
            return (
                <div className="store_manage_contents store_manage_top_no_space">
                    {uiTitleArea}
                    <Spin className="manage_empty_loading" size="large" tip="加载中……" />
                </div>
            );
        }
    }
}
export default AccountingSN;
