import React, { Component } from 'react';
import axios from 'axios';

import Spin from 'antd/es/spin';
import Table from 'antd/es/table';
import Button from 'antd/es/button';
import Input from 'antd/es/input';
import Icon from 'antd/es/icon';
import Modal from 'antd/es/modal';
import message from 'antd/es/message';
import Clipboard from 'react-clipboard.js';

// import { Spin, Table, Button, Input, Menu, Dropdown, Icon, Modal, message } from 'antd';
import '../../../styles/Background/ego_manager.css';
import '../../../styles/Background/UI_Module/user_management.css';
import { UserActiveUpdate } from "../../../functions/DataStatistic.js"

const g_strMessageKey = 'updating';

// 表格
const arrTableHeader = [
    { title: 'ID', dataIndex: 'id', width: 50, ellipsis: true },
    { title: '用户名称', dataIndex: 'name', width: 100, ellipsis: true },
    { title: '用户编号', dataIndex: 'storage_code_ui', width: 125, ellipsis: true },
    { title: '联系电话', dataIndex: 'phone', width: 100, ellipsis: true },
    { title: '联系邮箱', dataIndex: 'email', width: 125, ellipsis: true },
    { title: '微信', dataIndex: 'wechat', width: 125, ellipsis: true },
    { title: '用户状态', dataIndex: 'status', width: 100, ellipsis: true },
    { title: '注册时间', dataIndex: 'register_date', width: 100, ellipsis: true }
];

class StoreUserManagerJW extends Component {
    constructor(props) {
        super();
        this.state = {
            btnControlDisable: [false, true, true],  // 操作按钮控制
            btnCopyPasteDisable: [true, true],  // 操作按钮控制
            selectedRowKeys: [],  // Check here to configure the default column
            objFilter: {
                strKeyword: "", iCurrentUserStatus: 0, iCurrentUserType: 0
            },
            iDlgType: -1,
            bShowDlg: false,
            bLoading: true,
            strNewPassword: "",
            strConfirmPassword: ""
        };
        // 成员变量
        this.arrOgeTableData = [];
        this.arrTableData = [];  // 用于表格的数组
        this.arrFilterUserStatus = ['全部', '正常使用', '审核中', '已禁用'];
        this.arrFilterUserType = ['全部', '普通用户', '商家', '管理员', '超级管理员'];
        this.arrSortType = ['用户名A-Z', '用户名Z-A', '注册时间↓', '注册时间↑'];
        this.arrSelectedUserID = [];
        this.arrSelectedUserIndex = [];
        this.objCurrentUser = {};
        this.bCopyUserCode = false;
    }

    // 选中某行的响应函数
    selectRow = (record) => {
        const selectedRowKeys = [...this.state.selectedRowKeys];
        if (selectedRowKeys.indexOf(record.key) >= 0) {
            selectedRowKeys.splice(selectedRowKeys.indexOf(record.key), 1);
        } else {
            selectedRowKeys.push(record.key);
        }
        this.SelectedRowStateSetting(selectedRowKeys);
    }
    // 选中的行发生变化的响应函数
    onSelectedRowKeysChange = (selectedRowKeys) => {
        this.SelectedRowStateSetting(selectedRowKeys);
    }
    // 处理选中的表行行
    SelectedRowStateSetting = (selectedRowKeys) => {

        if (this.bCopyUserCode) {
            this.bCopyUserCode = false;
            return;
        }

        let btnControlDisable = [];
        let btnCopyPasteDisable = [];
        switch (selectedRowKeys.length) {
            case 0: {
                btnControlDisable = [false, true, true];
                btnCopyPasteDisable = [true, true];
                this.objCurrentUser = {};
                break;
            }
            case 1: {
                btnControlDisable = [false, false, false];
                btnCopyPasteDisable = [false, true];
                for (let i = 0; i < this.arrTableData.length; i++) {
                    if (this.arrTableData[i].key === selectedRowKeys[0]) {
                        this.objCurrentUser = this.arrTableData[i];
                        break;
                    }
                }
                break;
            }
            default: {
                btnControlDisable = [false, true, true]; btnCopyPasteDisable = [true, true]; break;
            }
        }
        this.setState({
            ...this.state,
            selectedRowKeys,
            btnControlDisable: btnControlDisable,
            btnCopyPasteDisable: btnCopyPasteDisable
        });
    }
    // 关键词处理
    KeywordsProcess = (strKeywords) => {
        let newKeywords = "";
        for (let i = 0; i < strKeywords.length; i++) {
            if (strKeywords[i] !== " ") {
                if (i === strKeywords.length - 1)
                    newKeywords += strKeywords[i];
                else
                    newKeywords += strKeywords[i] + "%";
            }
        }
        return newKeywords;
    }
    // 搜索用户名
    UserNameSearch = () => {
        let strKeywords = this.refs.user_name_search.state.value;
        if (strKeywords === undefined || strKeywords === null)
            strKeywords = "";
        if (strKeywords.trim() === "") {
            this.GetUsersData();
        } else {
            this.setState({
                ...this.state,
                bLoading: true,
                selectedRowKeys: [],
                btnControlDisable: [false, true, true]
            });
            axios('/SearchUsers', { params: { name: this.KeywordsProcess(strKeywords) } })
                .then(({ data }) => {
                    this.arrTableData = this.OrganizeUsersData(data);
                    this.setState({
                        ...this.state,
                        bLoading: false,
                        objFilter: {
                            strKeyword: strKeywords,
                            iCurrentUserStatus: 0,
                            iCurrentUserType: 0
                        }
                    });
                }).catch(function (error) { console.log(error); });
        }
    }
    // 点击了用户类别过滤器
    UserTypeFilterClicked = (iUserType) => {
        let strTargetType = this.arrFilterUserType[iUserType];
        if (iUserType !== 0) {
            this.arrTableData = [];
            for (let i = 0; i < this.arrOgeTableData.length; i++) {
                if (strTargetType === this.arrOgeTableData[i].level) {
                    this.arrTableData.push(this.arrOgeTableData[i]);
                }
            }
        } else {
            this.arrTableData = this.arrOgeTableData;
        }
        if (this.refs.user_name_search !== undefined)
            this.refs.user_name_search.state.value = "";
        this.setState({
            ...this.state,
            objFilter: {
                strKeyword: "",
                iCurrentUserStatus: this.state.objFilter.iCurrentUserStatus,
                iCurrentUserType: iUserType
            }
        });
    }
    /* 自定义成员函数 */
    //弹出对话框
    ShowDlg = (iDlgType, e) => {
        if (iDlgType === 0) {
            if (this.refs.user_name !== undefined) {
                this.refs.user_name.state.value = '';
                this.refs.phone.state.value = '';
                this.refs.email.state.value = '';
                this.refs.wechat.state.value = '';
            }
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true,
                strNewPassword: "",
                strConfirmPassword: ""
            });
        }
        if (iDlgType === 1) {
            if (this.refs.user_name !== undefined) {
                this.refs.user_name.state.value = this.objCurrentUser.name;
                this.refs.phone.state.value = this.objCurrentUser.phone;
                this.refs.email.state.value = this.objCurrentUser.email;
                this.refs.wechat.state.value = this.objCurrentUser.wechat;
            }
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true,
                strNewPassword: "",
                strConfirmPassword: ""
            });
        }
        if (iDlgType === 2) {
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true
            });
        }
    }
    // 弹窗确认按钮响应
    handleOk = e => {
        if (this.state.iDlgType === 0) {
            let strName = this.refs.user_name.state.value;
            let strPhone = this.refs.phone.state.value;
            let strEmail = this.refs.email.state.value;
            if (strName === undefined || strName === null || strName === "") {
                message.warning("用户名不能为空！"); return;
            }
            if (this.state.strConfirmPassword !== this.state.strNewPassword) {
                message.warning("两次输入密码不一致！"); return;
            }
            if ((strPhone === undefined || strPhone === null || strPhone === "") &&
                (strEmail === undefined || strEmail === null || strEmail === "")) {
                message.warning('电话和邮箱至少填写一个！'); return;
            }
            let objNewUser = {};
            if (this.state.strNewPassword === "") {
                message.warning("请输入密码！"); return;
            } else {
                objNewUser = {
                    name: strName,
                    phone: strPhone,
                    email: strEmail,
                    password: this.state.strNewPassword,
                    invite_code: 'LK39',
                    level: 1,
                    status: 1,
                    wechat: this.refs.wechat.state.value
                }
            }
            console.log("new user : ", objNewUser);
            message.loading({ content: '正在创建用户……', key: g_strMessageKey });
            axios.post('/NewUser', objNewUser).then(({ data }) => {
                console.log(data);
                if (data.status === 1) {
                    this.GetUsersData();
                }
                else
                    message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
            }).catch(function (error) { console.log(error); });
        }
        if (this.state.iDlgType === 1) {
            let strName = this.refs.user_name.state.value;
            if (strName === null || strName.trim() === "") {
                message.warning("用户名不能为空！"); return;
            }
            if (this.state.strConfirmPassword !== this.state.strNewPassword) {
                message.warning("两次输入密码不一致！"); return;
            }
            let objNewUser = {};
            if (this.state.strNewPassword === "") {
                objNewUser = {
                    name: strName,
                    phone: this.refs.phone.state.value,
                    email: this.refs.email.state.value,
                    wechat: this.refs.wechat.state.value
                }
            } else {
                objNewUser = {
                    name: strName,
                    phone: this.refs.phone.state.value,
                    email: this.refs.email.state.value,
                    password: this.state.strNewPassword,
                    wechat: this.refs.wechat.state.value
                }
            }
            console.log("new user : ", objNewUser);
            console.log("current user : ", this.objCurrentUser);
            message.loading({ content: '正在更新用户信息……', key: g_strMessageKey });
            axios.post('/UpdateJWUserKeyInfo', {
                objCurrentUser: this.objCurrentUser,
                objNewUser: objNewUser
            }).then(({ data }) => {
                console.log(data);
                if (data.status === 1) {
                    // DataStatistic
                    UserActiveUpdate(4, { active: "管理后台: 用户管理操作", action: "编辑" });
                    this.GetUsersData();
                }
                else
                    message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
            }).catch(function (error) { console.log(error); });
        }
        if (this.state.iDlgType === 2) {
            message.loading({ content: '用户删除中……', key: g_strMessageKey });
            axios.post('/DeleteUser', { id: this.objCurrentUser.id }).then(
                ({ data }) => {
                    if (data.status === -1) {
                        message.warning({
                            content: "删除失败，该账户已绑定商家，请先检查并删除商家！",
                            key: g_strMessageKey, duration: 2
                        });
                        this.setState({
                            ...this.state,
                            bLoading: false,
                            bShowDlg: false,
                            selectedRowKeys: []
                        });
                    } else {
                        // DataStatistic
                        UserActiveUpdate(4, { active: "管理后台: 用户管理操作", action: "删除" });
                        this.GetUsersData();
                    }
                }).catch(function (error) { console.log(error); });
        }
    };
    // 弹窗取消按钮响应
    handleCancel = e => {
        this.setState({
            ...this.state,
            bShowDlg: false
        });
    };
    // 整理Users数据
    OrganizeUsersData(data) {
        let arrTableData = [];
        for (let i = 0; i < data.length; i++) {
            if (data[i].invite_code === "LK39" && data[i].storage_code !== null && data[i].storage_code !== "") {
                let strRegisterDate = data[i].register_date;
                let strLoginDate = data[i].last_login;
                if (strRegisterDate !== null) {
                    if (strRegisterDate.indexOf('T') >= 0)
                        strRegisterDate = strRegisterDate.split('T')[0];
                }
                if (strLoginDate !== null) {
                    if (strLoginDate.indexOf('T') >= 0)
                        strLoginDate = strLoginDate.split('T')[0];
                }
                let strPhone = data[i].phone;
                if (strPhone === null || strPhone === "") {
                    strPhone = data[i].contact_phone;
                }
                let strEmail = data[i].email;
                if (strEmail === null) {
                    strEmail = "";
                }
                if (data[i].price_off === null || data[i].price_off === "")
                    data[i].price_off = 0;
                let objUserData = {
                    key: i, id: data[i].id,
                    name: data[i].name,
                    storage_code: data[i].storage_code,
                    storage_code_ui: (
                        <div className="copy_info_row">
                            <div className="copy_info_text">{data[i].storage_code}</div>
                            <Clipboard data-clipboard-text={data[i].storage_code} onClick={this.CopyUserCode.bind(this)}
                                className="copy_info_button export_button_style"
                                style={{ border: 'none', borderRadius: '5px' }}>
                                复制
                            </Clipboard>
                        </div>),
                    invite_code: data[i].invite_code,
                    code: data[i].code,
                    phone: strPhone,
                    email: strEmail,
                    status: this.arrFilterUserStatus[data[i].status],
                    store_id: data[i].store_id,
                    register_date: strRegisterDate,
                    last_login: strLoginDate,
                    price_off: data[i].price_off,
                    price_off_str: data[i].price_off + "%",
                    wechat: data[i].wechat
                }
                if (data[i].level >= 15)
                    objUserData.level = this.arrFilterUserType[4];
                if (data[i].level >= 10 && data[i].level < 15)
                    objUserData.level = this.arrFilterUserType[3];
                if (data[i].level >= 5 && data[i].level < 10)
                    objUserData.level = this.arrFilterUserType[2];
                if (data[i].level >= 0 && data[i].level < 5)
                    objUserData.level = this.arrFilterUserType[1];
                arrTableData.push(objUserData);
            }
        }
        return arrTableData;
    }
    // 复制用户编号
    CopyUserCode = () => {
        this.bCopyUserCode = true;
        message.info('用户编号已复制！');
    }
    // 从数据库获取当前的数据结构
    GetUsersData = () => {
        message.loading({ content: '数据加载中……', key: g_strMessageKey });
        axios('/GetUsers', { params: { store_id: 0 } })
            .then(({ data }) => {
                this.arrOgeTableData = this.OrganizeUsersData(data);
                this.arrTableData = this.arrOgeTableData;
                if (this.state.bShowDlg) {
                    if (this.state.iDlgType === 0) {
                        message.success({ content: "新建用户成功！", key: g_strMessageKey, duration: 2 });
                    }
                    if (this.state.iDlgType === 1) {
                        message.success({ content: "用户信息已更新！", key: g_strMessageKey, duration: 2 });
                    }
                    if (this.state.iDlgType === 2) {
                        message.success({ content: "用户已删除！", key: g_strMessageKey, duration: 2 })
                    }
                } else {
                    message.success({ content: "数据加载完成！", key: g_strMessageKey, duration: 2 });
                }
                this.setState({
                    ...this.state,
                    bLoading: false,
                    bShowDlg: false,
                    selectedRowKeys: []
                });
            }).catch(function (error) { console.log(error); });
    }
    // 显示全部用户
    ShowAllUsers = () => {
        this.refs.user_name_search.state.value = "";
        this.UserNameSearch();
    }
    /* React自带函数 */
    // 卸载异步操作设置状态
    componentWillUnmount() {
        clearTimeout(this.timeouter)
        this.setState = (state, callback) => {
            return;
        }
    }
    // 渲染完毕……
    componentDidMount() {
        this.GetUsersData();
    }
    // 渲染函数
    render() {

        // 顶部栏用户类别下拉菜单
        // const uiUserTypeMenu = (
        //     <Menu>
        //         {this.arrFilterUserType.map((item, index) => {
        //             return (
        //                 <Menu.Item key={index} onClick={this.UserTypeFilterClicked.bind(this, index)}>
        //                     {item}
        //                 </Menu.Item>
        //             );
        //         })}
        //     </Menu>
        // );
        // 顶部栏用户状态下拉菜单
        // const uiUserStatusMenu = (
        //     <Menu>
        //         {this.arrFilterUserStatus.map((item, index) => {
        //             return (
        //                 <Menu.Item key={index} onClick={this.UserStatusFilterClicked.bind(this, index)}>
        //                     {item}
        //                 </Menu.Item>
        //             );
        //         })}
        //     </Menu>
        // );

        /* 用户管理页面控制栏 */
        let uiTitleArea = (
            < div className="store_contents_title" >
                <Button className="manage_contents_title_margin manage_contents_title_margin_right" type="primary" onClick={this.ShowAllUsers}>
                    显示全部<Icon type="redo" /></Button>
                <div className="manage_stretch_right">
                    <Input placeholder="搜索用户名/代码" ref="user_name_search" defaultValue={this.state.strKeywords} />
                    <Button className="manage_contents_title_margin"
                        type="primary" onClick={this.UserNameSearch}>搜索
                    </Button>
                </div>

                <div className="manage_title_button_right_side">
                    <Button className="manage_contents_title_margin" type="primary" onClick={this.ShowDlg.bind(this, 0)} disabled={this.state.btnControlDisable[0]}>
                        <Icon type="edit" />新建</Button>
                    <Button className="manage_contents_title_margin" type="primary" onClick={this.ShowDlg.bind(this, 1)} disabled={this.state.btnControlDisable[1]}>
                        <Icon type="edit" />编辑</Button>
                    <Button className="manage_contents_title_margin" type="danger" onClick={this.ShowDlg.bind(this, 2)} disabled={this.state.btnControlDisable[2]}>
                        <Icon type="close" />删除</Button>
                </div>
            </div >
        );

        // 如果界面上需要显示的内容已经从数据库获得
        if (!this.state.bLoading) {
            // 主体表格相关
            const { selectedRowKeys } = this.state;
            const rowSelection = {
                selectedRowKeys,
                onChange: this.onSelectedRowKeysChange,
            };
            // 表格分页属性
            const paginationProps = {
                showSizeChanger: true,
                showQuickJumper: false,
                showTotal: () => `共 ${this.arrTableData.length} 个 用户`,
                defaultPageSize: 10,
                defaultCurrent: this.state.iCurrentPage,
                pageSizeOptions: ['10', '20', '30', '40', '50'],
                total: this.arrTableData.length,
                position: 'bottom'
            };
            // 用户信息编辑弹窗
            let uiModal = (
                <Modal title="编辑账户" width={350} visible={this.state.bShowDlg}
                    okText="确定" cancelText="取消" onOk={this.handleOk} onCancel={this.handleCancel} >
                    <div className="user_edit_info_row">
                        <div className="user_edit_info_title">用户名：</div>
                        <Input className="user_edit_info_input" ref="user_name"
                            defaultValue={this.objCurrentUser.name} />
                    </div>
                    <div className="user_edit_info_row">
                        <div className="user_edit_info_title">电话：</div>
                        <Input className="user_edit_info_input" ref="phone"
                            defaultValue={this.objCurrentUser.phone} />
                    </div>
                    <div className="user_edit_info_row">
                        <div className="user_edit_info_title">邮箱：</div>
                        <Input className="user_edit_info_input" ref="email"
                            defaultValue={this.objCurrentUser.email} />
                    </div>
                    <div className="user_edit_info_row">
                        <div className="user_edit_info_title">微信：</div>
                        <Input className="user_edit_info_input" ref="wechat"
                            defaultValue={this.objCurrentUser.wechat} />
                    </div>
                    <div className="user_edit_info_row">
                        <div className="user_edit_info_title">新的密码：</div>
                        <Input.Password className="user_edit_info_input" ref="new_password"
                            onChange={e => this.setState({ strNewPassword: e.target.value })}
                            defaultValue={""} value={this.state.strNewPassword} />
                    </div>
                    <div className="user_edit_info_row">
                        <div className="user_edit_info_title">确认密码：</div>
                        <Input.Password className="user_edit_info_input" ref="comfirm_password"
                            onChange={e => this.setState({ strConfirmPassword: e.target.value })}
                            defaultValue={""} value={this.state.strConfirmPassword} />
                    </div>
                </Modal>
            );
            if (this.state.iDlgType === 2) {
                uiModal = (
                    <Modal width={500} title={"删除用户信息"} visible={this.state.bShowDlg} okType="danger"
                        onOk={this.handleOk} okText="确定"
                        onCancel={this.handleCancel} cancelText="取消">
                        <div className="modal_item">
                            <div>{this.objCurrentUser.name}
                                <br /><br />选中用户的所有信息都将被删除……<br />确定删除选中的用户？
                            </div>
                        </div>
                    </Modal>
                );
            }

            return (
                <div className="store_manage_contents store_manage_top_space">
                    {uiTitleArea}
                    {uiModal}
                    {/* 表格主体 */}
                    <Table style={{ margin: '10px', width: '97.5%', overflowX: 'auto' }} bordered
                        rowSelection={rowSelection} pagination={paginationProps}
                        columns={arrTableHeader} dataSource={this.arrTableData}
                        onRow={(record) => ({ onClick: () => { this.selectRow(record); } })} />
                </div >
            );
        } else {
            return (
                <div className="store_manage_contents store_manage_top_no_space">
                    {uiTitleArea}
                    <Spin className="manage_empty_loading" size="large" tip="加载中……" />
                </div>
            );
        }
    }
}
export default StoreUserManagerJW;
