import React, { Component } from "react";

import Empty from 'antd/es/empty';
import Spin from 'antd/es/spin';
import Icon from 'antd/es/icon';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import message from 'antd/es/message';

import axios from 'axios';
import cookie from 'react-cookies'
import TicketCheck from "./FunctionPage/TicketCheck.js";
import PackageScanner from "./FunctionPage/PackageScanner.js";
import ScannerPackage from "./FunctionPage/ScannerPackage.js";
import HSCodeManager from "./FunctionPage/HSCodeManager.js";
import StoreOrderPickup from "./ManagerPage/StoreOrderPickup.js";

import "../../styles/Background/ego_manager.css";
import "../../styles/Background/store_manager.css";
import { UserActiveUpdate } from "../../functions/DataStatistic.js"


const g_strMessageKey = 'updating';

function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class StorePlayer extends Component {
    // 构造函数
    constructor(props) {
        super();

        let strCurrentFuntion = props.match.params.id;

        console.log("function id = " + strCurrentFuntion);

        let strParams = window.location.search;
        if (strParams.indexOf('?') >= 0)
            strParams = strParams.substring(strParams.indexOf('?') + 1);
        console.log("parms = " + strParams);

        // 卡券核销功能
        this.iStore = -1;
        this.iTicket = -1;
        this.iUser = -1;
        if (strCurrentFuntion === "scan") {
            if (strParams.indexOf('ticket') >= 0 && strParams.indexOf('user') >= 0) {
                this.iStore = parseInt(strParams.split('?')[0].split('=')[1], 10);
                this.iTicket = parseInt(strParams.split('?')[1].split('=')[1], 10);
                this.iUser = parseInt(strParams.split('?')[2].split('=')[1], 10);
                console.log("store = " + this.iStore);
                console.log("ticket = " + this.iTicket);
                console.log("user = " + this.iUser);
            }
        }
        if (strCurrentFuntion === "order") {
            console.log("当前功能：接单/订单管理");
        }
        if (strCurrentFuntion === 'package_scan') {
            console.log("包裹扫描");
        }
        if (strCurrentFuntion === 'scan_package') {
            console.log("扫描包裹");
        }
        if (strCurrentFuntion === "hscode") {
            console.log("HSCode 管理");
        }

        this.state = {
            bLoading: true,  // 加载状态
            bLogin: false,  // 登录状态
            strCurrentFuntion: strCurrentFuntion,  //当前使用的功能
        };

        this.objStoreInfo = { id: -1, store_name: '' };
        this.strCurrentUserName = '';
        this.strCurrentUserCode = '';
    }

    // 获取商品/服务类别信息
    GetItemTypeInfo = () => {
        axios('/GetStoreItemTypes', { params: { store_id: this.objStoreInfo.id } }).then(
            ({ data }) => {
                global.arrItemTypeData = data;
                // 切换到商品种类页面
                this.setState({
                    ...this.state,
                    bLoading: false,
                    bLogin: true,
                    iCurrentInterface: this.arrSideMenuButton[3].id
                });
            });
    }
    // 登录表单提交响应
    LoginSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                // DataStatistic
                UserActiveUpdate(0, { active: "商家后台: 尝试登录", user: values.username });
                message.loading({ content: '登录中……', key: g_strMessageKey });
                this.setState({
                    ...this.state,
                    bLoading: true
                });
                this.TryLogin(values.username, values.password)
            }
        });
    };
    // 尝试登录
    TryLogin = (name, password) => {
        let objUserInfo = { name: name, password: password };
        axios('/StoreBackEndLogin', { params: objUserInfo })
            .then(({ data }) => {
                if (data.status === -1) {
                    message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
                    this.setState({
                        ...this.state,
                        bLoading: false
                    });
                }
                else {
                    message.success({ content: data.message, key: g_strMessageKey, duration: 2 });
                    this.strCurrentUserName = name;
                    this.strCurrentUserCode = data.code;
                    cookie.save('store_user_code', this.strCurrentUserCode, { path: '/' });
                    cookie.save('store_login_status', 1, { path: '/' });
                    // DataStatistic
                    UserActiveUpdate(0, { active: "商家后台: 登录成功", user: name });
                    this.GetStoreInfo();
                }
            }).catch(function (error) { console.log(error); });
    }
    // 退出登录
    LogoutClick = () => {
        global.arrItemTypeData = [];
        // DataStatistic
        // UserActiveUpdate(0, { active: "商家后台: 退出登录", user: this.strCurrentUserName });
        cookie.remove('store_user_code', { path: '/' });
        cookie.save('store_login_status', -1, { path: '/' });
        this.setState({
            ...this.state,
            bLogin: false
        });
    }
    // 获取当前登录的商家的基本信息
    GetStoreInfo = () => {
        axios('/GetUserRelevantStoreID', { params: { code: this.strCurrentUserCode } })
            .then(({ data }) => {
                if (data.status === -1) {
                    message.warning(data.message);
                    this.setState({
                        ...this.state,
                        bLoading: false
                    });
                } else {
                    this.strCurrentUserName = data.name;
                    axios('/GetStoreInfo', { params: { id: data.store_id } })
                        .then(({ data }) => {
                            this.objStoreInfo = data[0];
                            this.setState({
                                ...this.state,
                                bLogin: true,
                                bLoading: false
                            });
                        }).catch(function (error) { console.log(error); });
                }
            }).catch(function (error) { console.log(error); });
    }
    // 渲染完毕……
    componentDidMount() {
        let strUserCode = cookie.load('store_user_code');
        let iUserLoginStatus = cookie.load('store_login_status');
        if ((iUserLoginStatus === 1 || iUserLoginStatus === '1') && strUserCode !== undefined) {
            this.strCurrentUserCode = strUserCode;
            this.GetStoreInfo();
        } else {
            this.setState({
                ...this.state,
                bLoading: false,
                bLogin: false
            });
        }
        document.title = "Mouthing管理后台";
    }

    render() {
        const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;
        // Only show error after a field is touched.
        const usernameError = isFieldTouched('username') && getFieldError('username');
        const passwordError = isFieldTouched('password') && getFieldError('password');
        // 登录区域
        let strTitle = this.strCurrentUserName;
        let uiLogInForm = (
            <Form className="manage_login_form_m" layout="inline" onSubmit={this.LoginSubmit}>
                <Form.Item validateStatus={usernameError ? 'error' : ''} help={usernameError || ''}>
                    {getFieldDecorator('username')(
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Icon type="user" style={{ color: 'white', marginLeft: '2.5vw', marginRight: '1vw', fontSize: '3vw' }} />
                            <Input placeholder="用户名" style={{ fontSize: '2.5vw', width: '30vw', height: '6.25vw' }} />
                        </div>
                    )}
                </Form.Item>
                <Form.Item validateStatus={passwordError ? 'error' : ''} help={passwordError || ''}>
                    {getFieldDecorator('password')(
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Icon type="lock" style={{ color: 'white', marginLeft: '0vw', marginRight: '1vw', fontSize: '3vw' }} />
                            <Input type="password" placeholder="密码"
                                style={{ fontSize: '2.5vw', width: '30vw', height: '6.25vw', marginRight: '2.5vw' }} />
                        </div>
                    )}
                </Form.Item>
                <Form.Item>
                    <Button className="logout_btn_m" htmlType="submit" disabled={hasErrors(getFieldsError())}>
                        登 陆
                    </Button>
                </Form.Item>
            </Form>
        );
        // 顶部栏
        let uiNavBar = (
            <div className="manage_top_bar_m">
                {uiLogInForm}
            </div>
        );
        if (this.state.bLogin) {
            uiLogInForm = (
                <div className="manage_login_form_m">
                    <div className="welcome_back_title_m">{"欢迎回来：" + strTitle}</div>
                    <Button className="logout_btn_m" htmlType="submit"
                        onClick={this.LogoutClick}>退 出</Button>
                </div>
            );
            uiNavBar = (
                <div className="manage_top_bar_m">
                    {uiLogInForm}
                </div>
            );
        }
        let uiCentralArea;
        // 是否还在加载中……
        if (this.state.bLoading) {
            uiCentralArea = (<Spin className="manage_empty_loading_m" size="large" tip="加载中……" />);
        } else {
            // 已登录
            if (this.state.bLogin) {
                // 卡券核销
                if (this.state.strCurrentFuntion === "scan") {
                    uiCentralArea = (
                        <div>
                            <TicketCheck iMyStore={parseInt(this.objStoreInfo.id, 10)} iTargetStore={this.iStore} iTicket={this.iTicket} iUser={this.iUser} />
                        </div>
                    );
                }
                // 接单/订单管理
                if (this.state.strCurrentFuntion === "order") {
                    uiCentralArea = (<StoreOrderPickup objStoreInfo={this.objStoreInfo} />);
                }
                // 包裹扫描（进仓出仓）
                if (this.state.strCurrentFuntion === "package_scan" || this.state.strCurrentFuntion === "scan_package") {
                    if (this.objStoreInfo.id === 30000 || this.objStoreInfo.id === 50000)
                        uiCentralArea = (<ScannerPackage objStoreInfo={this.objStoreInfo} strUserCode={this.strCurrentUserCode} />);
                    else
                        uiCentralArea = (<PackageScanner objStoreInfo={this.objStoreInfo} strUserCode={this.strCurrentUserCode} />);
                }
                // 扫描包裹（签收、捡包）
                // if (this.state.strCurrentFuntion === "scan_package") {
                //     if (this.objStoreInfo.id === 30000)
                //         uiCentralArea = (<ScannerPackage objStoreInfo={this.objStoreInfo} strUserCode={this.strCurrentUserCode} />);
                //     else
                //         uiCentralArea = (<PackageScanner objStoreInfo={this.objStoreInfo} strUserCode={this.strCurrentUserCode} />);
                // }
            }
            // 未登录
            else {
                uiCentralArea = (<Empty className="manage_empty_loading_m"
                    description={<span>请使用商家账号登录</span>} />);
            }
        }
        // HSCode 管理 无需登录
        if (this.state.strCurrentFuntion === "hscode") {
            uiNavBar = null;
            uiCentralArea = (<HSCodeManager />);
        }

        return (
            <div className="manage_page">
                {/* 顶部菜单栏 */}
                {uiNavBar}
                {/* 中心区域内容 */}
                {uiCentralArea}
            </div >
        );
    }
}
export default Form.create()(StorePlayer);
