import React, { Component } from 'react';
import axios from 'axios';

import Spin from 'antd/es/spin';
import Table from 'antd/es/table';
import Button from 'antd/es/button';
import Input from 'antd/es/input';
import Icon from 'antd/es/icon';
import Modal from 'antd/es/modal';
import message from 'antd/es/message';

import '../../../styles/Background/ego_manager.css';
import '../../../styles/Background/UI_Module/user_management.css';
import { UserActiveUpdate } from "../../../functions/DataStatistic.js"

const { TextArea } = Input;

const g_strMessageKey = 'updating';
global.arrActionData = [];

// 表格
const arrTableHeader = [
    // { title: 'id', dataIndex: 'id', width: 50, ellipsis: true },
    { title: '单号', dataIndex: 'oid', width: 100, ellipsis: true },
    { title: '唛头', dataIndex: 'mark', width: 125, ellipsis: true },
    { title: '操作人', dataIndex: 'user_name', width: 100, ellipsis: true },
    { title: '负责人', dataIndex: 'charger_name', width: 100, ellipsis: true },
    { title: '修改内容', dataIndex: 'record', width: 400, ellipsis: true },
    { title: '时间', dataIndex: 'update_time_str', width: 160, ellipsis: true }
];

class LogisticActionManager extends Component {
    constructor(props) {
        super();
        this.state = {
            btnControlDisable: [false, true, true],  // 操作按钮控制
            btnCopyPasteDisable: [true, true],  // 操作按钮控制
            selectedRowKeys: [],  // Check here to configure the default column
            objFilter: {
                strKeyword: "", iCurrentUserStatus: 0, iCurrentUserType: 0
            },
            iDlgType: -1,
            bShowDlg: false,
            bLoading: true,
            strNewPassword: "",
            strConfirmPassword: ""
        };
        // 成员变量
        this.arrOgeTableData = [];
        this.arrTableData = [];  // 用于表格的数组
        this.arrFilterUserStatus = ['全部', '正常使用', '审核中', '已禁用'];
        this.arrFilterUserType = ['全部', '普通操作记录', '商家', '管理员', '超级管理员'];
        this.arrSortType = ['操作记录名A-Z', '操作记录名Z-A', '注册时间↓', '注册时间↑'];
        this.arrSelectedUserID = [];
        this.arrSelectedUserIndex = [];
        this.objCurrentAction = {};
    }

    // 选中某行的响应函数
    selectRow = (record) => {
        const selectedRowKeys = [...this.state.selectedRowKeys];
        if (selectedRowKeys.indexOf(record.key) >= 0) {
            selectedRowKeys.splice(selectedRowKeys.indexOf(record.key), 1);
        } else {
            selectedRowKeys.push(record.key);
        }
        this.SelectedRowStateSetting(selectedRowKeys);
    }
    // 选中的行发生变化的响应函数
    onSelectedRowKeysChange = (selectedRowKeys) => {
        this.SelectedRowStateSetting(selectedRowKeys);
    }
    // 处理选中的表行行
    SelectedRowStateSetting = (selectedRowKeys) => {
        let btnControlDisable = [];
        let btnCopyPasteDisable = [];
        switch (selectedRowKeys.length) {
            case 0: {
                btnControlDisable = [false, true, true];
                btnCopyPasteDisable = [true, true];
                this.objCurrentAction = {};
                break;
            }
            case 1: {
                btnControlDisable = [false, false, false];
                btnCopyPasteDisable = [false, true];
                for (let i = 0; i < this.arrTableData.length; i++) {
                    if (this.arrTableData[i].key === selectedRowKeys[0]) {
                        this.objCurrentAction = this.arrTableData[i];
                        break;
                    }
                }
                break;
            }
            default: {
                btnControlDisable = [false, true, true]; btnCopyPasteDisable = [true, true]; break;
            }
        }
        this.setState({
            ...this.state,
            selectedRowKeys,
            btnControlDisable: btnControlDisable,
            btnCopyPasteDisable: btnCopyPasteDisable
        });
    }
    // 关键词处理
    KeywordsProcess = (strKeywords) => {
        let newKeywords = "";
        for (let i = 0; i < strKeywords.length; i++) {
            if (strKeywords[i] !== " ") {
                if (i === strKeywords.length - 1)
                    newKeywords += strKeywords[i];
                else
                    newKeywords += strKeywords[i] + "%";
            }
        }
        return newKeywords;
    }
    // 搜索操作记录名
    ActionNameSearch = () => {
        let strKeywords = this.refs.name_search.state.value;
        if (strKeywords === undefined)
            strKeywords = "";
        if (strKeywords.trim() === "") {
            this.GetActionsData();
        } else {
            this.setState({
                ...this.state,
                bLoading: true,
                selectedRowKeys: [],
                btnControlDisable: [false, true, true]
            });
            axios('/SearchActions', { params: { name: this.KeywordsProcess(strKeywords), store_id: this.props.objStoreInfo.id } })
                .then(({ data }) => {
                    this.arrTableData = this.OrganizeActionsData(data);
                    this.setState({
                        ...this.state,
                        bLoading: false,
                        objFilter: {
                            strKeyword: strKeywords,
                            iCurrentUserStatus: 0,
                            iCurrentUserType: 0
                        }
                    });
                }).catch(function (error) { console.log(error); });
        }
    }
    /* 自定义成员函数 */
    //弹出对话框
    ShowDlg = (iDlgType, e) => {
        if (iDlgType === 0) {
            if (this.refs.name !== undefined) {
                this.refs.name.state.value = "";
                this.refs.phone.state.value = "";
                this.refs.email.state.value = "";
                this.refs.address.state.value = "";
                this.refs.comments.state.value = "";
            }
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true,
                strNewPassword: "",
                strConfirmPassword: ""
            });
        }
        if (iDlgType === 1) {
            // if (this.refs.name !== undefined) {
            //     this.refs.name.state.value = this.objCurrentAction.name;
            //     this.refs.phone.state.value = this.objCurrentAction.phone;
            //     this.refs.email.state.value = this.objCurrentAction.email;
            //     this.refs.address.state.value = this.objCurrentAction.address;
            //     this.refs.comments.state.value = this.objCurrentAction.comments;
            // }
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true,
                strNewPassword: "",
                strConfirmPassword: ""
            });
        }
        if (iDlgType === 2) {
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true
            });
        }
    }
    // 弹窗确认按钮响应
    handleOk = e => {
        if (this.state.iDlgType === 0) {
            let strName = this.refs.name.state.value;
            if (strName === null || strName.trim() === "") {
                message.warning("操作记录名不能为空！"); return;
            }
            let objNewAction = {
                name: strName,
                phone: this.refs.phone.state.value,
                email: this.refs.email.state.value,
                address: this.refs.address.state.value,
                comments: this.refs.comments.state.value,
                store_id: this.props.iStoreID
            };
            message.loading({ content: '正在创建新的操作记录信息……', key: g_strMessageKey });
            axios.post('/CreateNewAction', objNewAction)
                .then(({ data }) => {
                    if (data.status === 1) {
                        this.GetActionsData();
                    } else {
                        message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
                    }
                }).catch(function (error) { console.log(error); });
        }
        if (this.state.iDlgType === 1) {
            let strName = this.refs.name.state.value;
            if (strName === null || strName.trim() === "") {
                message.warning("操作记录名不能为空！"); return;
            }
            let objNewAction = {
                name: strName,
                phone: this.refs.phone.state.value,
                email: this.refs.email.state.value,
                address: this.refs.address.state.value,
                comments: this.refs.comments.state.value,
                store_id: this.props.iStoreID
            };
            message.loading({ content: '正在更新操作记录信息……', key: g_strMessageKey });
            axios.post('/UpdateAction', {
                objCurrentAction: this.objCurrentAction,
                objNewAction: objNewAction
            }).then(({ data }) => {
                if (data.status === 1)
                    this.GetActionsData();
                else
                    message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
            }).catch(function (error) { console.log(error); });
        }
        if (this.state.iDlgType === 2) {
            message.loading({ content: '操作记录删除中……', key: g_strMessageKey });
            axios.post('/DeleteAction', { id: this.objCurrentAction.id }).then(
                ({ data }) => {
                    if (data.status === -1) {
                        message.warning({
                            content: "删除失败，该操作记录已绑定商家，请先检查并删除商家！",
                            key: g_strMessageKey, duration: 2
                        });
                        this.setState({
                            ...this.state,
                            bLoading: false,
                            bShowDlg: false,
                            selectedRowKeys: []
                        });
                    } else {
                        // DataStatistic
                        UserActiveUpdate(4, { active: "管理后台: 操作记录管理操作", action: "删除" });
                        this.GetActionsData();
                    }
                }).catch(function (error) { console.log(error); });
        }
    };
    // 弹窗取消按钮响应
    handleCancel = e => {
        this.setState({
            bShowDlg: false,
        });
    };
    // 整理Action数据
    OrganizeActionsData(data) {
        let arrTableData = [];
        for (let i = 0; i < data.length; i++) {
            let strTime = new Date(new Date(data[i].update_time).getTime() - 7 * 60 * 60 * 1000).toISOString();
            strTime = strTime.split('T')[0] + " " + strTime.split('T')[1].split('.')[0];
            let objActionData = {
                key: i,
                id: data[i].id,
                oid: data[i].oid,
                mark: data[i].mark,
                user_name: data[i].user_name,
                charger_name: data[i].charger_name,
                record: data[i].record,
                update_time_str: strTime,
            }
            arrTableData.push(objActionData);
        }
        return arrTableData;
    }
    // 从数据库获取当前的数据结构
    GetActionsData() {
        message.loading({ content: '数据加载中……', key: g_strMessageKey });
        console.log("store id : ", this.props.objStoreInfo.id);
        axios('/GetLogisticsActions', { params: { store_id: this.props.objStoreInfo.id } })
            .then(({ data }) => {
                console.log("GetLogisticsActions : ", data);
                this.arrOgeTableData = this.OrganizeActionsData(data);
                this.arrTableData = this.arrOgeTableData;
                if (this.state.bShowDlg) {
                    // if (this.state.iDlgType === 0) {
                    //     message.success({ content: "新建操作记录信息成功！", key: g_strMessageKey, duration: 2 });
                    // }
                    // if (this.state.iDlgType === 1) {
                    //     message.success({ content: "操作记录信息已更新！", key: g_strMessageKey, duration: 2 });
                    // }
                    // if (this.state.iDlgType === 2) {
                    //     message.success({ content: "操作记录已删除！", key: g_strMessageKey, duration: 2 })
                    // }
                } else {
                    message.success({ content: "数据加载完成！", key: g_strMessageKey, duration: 2 });
                }
                this.setState({
                    ...this.state,
                    bLoading: false,
                    bShowDlg: false,
                    selectedRowKeys: []
                });
            }).catch(function (error) { console.log(error); });
    }
    /* React自带函数 */
    // 卸载异步操作设置状态
    componentWillUnmount() {
        clearTimeout(this.timeouter)
        this.setState = (state, callback) => {
            return;
        }
    }
    // 渲染完毕……
    componentDidMount() {
        this.GetActionsData();
    }
    // 渲染函数
    render() {
        /* 操作记录管理页面控制栏 */
        let uiTitleArea = (
            < div className="logistics_page_title" >
                <div className="manage_stretch_right">
                    <Input placeholder="搜索单号/负责人/操作人" ref="name_search" defaultValue={this.state.strKeywords} />
                    <Button className="manage_contents_title_margin"
                        type="primary" onClick={this.ActionNameSearch}>搜索
                    </Button>
                </div>
                <div className="manage_title_button_right_side">
                    <Button className="manage_contents_title_margin" type="primary" onClick={this.ShowDlg.bind(this, 1)} disabled={this.state.btnControlDisable[1]}>
                        <Icon type="search" />查看</Button>
                </div>
            </div >
        );

        // 如果界面上需要显示的内容已经从数据库获得
        if (!this.state.bLoading) {
            // 主体表格相关
            const { selectedRowKeys } = this.state;
            const rowSelection = {
                selectedRowKeys,
                onChange: this.onSelectedRowKeysChange,
                columnWidth: 30
            };
            // 表格分页属性
            const paginationProps = {
                showSizeChanger: true,
                showQuickJumper: false,
                showTotal: () => `共 ${this.arrTableData.length} 个 操作记录`,
                defaultPageSize: 10,
                defaultCurrent: this.state.iCurrentPage,
                pageSizeOptions: ['10', '20', '30', '40', '50'],
                total: this.arrTableData.length,
                position: 'bottom'
            };
            // 操作记录信息编辑弹窗
            let uiModal = null;
            if (this.state.iDlgType === 1) {
                let strTitle = "操作记录：单号-" + this.objCurrentAction.oid + ", 唛头-" + this.objCurrentAction.mark;
                if (this.objCurrentAction.mark === null || this.objCurrentAction.mark === undefined)
                    strTitle = "操作记录：单号-" + this.objCurrentAction.oid;
                uiModal = (
                    <Modal title={strTitle} width={725} visible={this.state.bShowDlg} footer={null}
                        okText="确定" cancelText="取消" onOk={this.handleOk} onCancel={this.handleCancel} >
                        <div className="user_edit_info_row">
                            <div className="user_edit_info_title">操作人：</div>
                            <div className="user_edit_info_title" style={{ marginRight: '20px', fontWeight: 'normal' }}>
                                {this.objCurrentAction.user_name}
                            </div>
                            <div className="user_edit_info_title">负责人：</div>
                            <div className="user_edit_info_title" style={{ marginRight: '20px', fontWeight: 'normal' }}>
                                {this.objCurrentAction.charger_name}
                            </div>
                            <div className="user_edit_info_title">时间：</div>
                            <div className="user_edit_info_title" style={{ fontWeight: 'normal' }}>
                                {this.objCurrentAction.update_time_str}
                            </div>
                        </div>
                        <div className="user_edit_info_row">
                            <div className="user_edit_info_title">修改内容：</div>
                            <TextArea size="large" autoSize={{ minRows: 2 }} allowClear={true} ref="comments" style={{ marginBottom: 'auto', marginTop: '0px' }}
                                placeholder="" defaultValue={this.objCurrentAction.record} disabled />
                        </div>
                    </Modal>
                );
            }

            if (this.state.iDlgType === 2) {
                uiModal = (
                    <Modal width={500} title={"删除操作记录信息"} visible={this.state.bShowDlg} okType="danger"
                        onOk={this.handleOk} okText="确定"
                        onCancel={this.handleCancel} cancelText="取消">
                        <div className="modal_item">
                            <div>{this.objCurrentAction.name}
                                <br /><br />确定删除选中的操作记录？
                            </div>
                        </div>
                    </Modal>
                );
            }


            let strAreaStyle = "logistics_page_contents";
            return (
                <div className={strAreaStyle} style={{ paddingTop: ' 50px' }}/*"store_manage_contents store_manage_top_space"*/>
                    {uiTitleArea}
                    {uiModal}
                    {/* 表格主体 */}
                    <Table style={{ margin: '20px', width: '97.5%', overflowX: 'auto' }}
                        rowSelection={rowSelection} pagination={paginationProps}
                        columns={arrTableHeader} dataSource={this.arrTableData}
                        onRow={(record) => ({ onClick: () => { this.selectRow(record); } })} />
                </div >
            );
        } else {
            return (
                <div className="logistics_page_contents">
                    {uiTitleArea}
                    <Spin className="manage_empty_loading" size="large" tip="加载中……" />
                </div>
            );
        }
    }
}
export default LogisticActionManager;
