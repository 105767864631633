import React, { Component } from "react";
import axios from 'axios';
import "../../../styles/Background/UI_Module/store_item_management.css";
// import '../../../styles/Background/UI_Module/store_management.css';
import '../../../styles/Background/store_manager.css';
import '../../../styles/Background/ego_manager.css';
import "../../../styles/Background/UI_Module/store_item_management.css";
import "antd/dist/antd.css";

import Table from 'antd/es/table';
import Input from 'antd/es/input';
import Menu from 'antd/es/menu';
import Dropdown from 'antd/es/dropdown';
import Icon from 'antd/es/icon';
import Button from 'antd/es/button';
import Modal from 'antd/es/modal';
import message from 'antd/es/message';

import { UserActiveUpdate } from "../../../functions/DataStatistic.js"

const g_strMessageKey = 'updating';
const arrTableHeader = [
    { title: 'id', dataIndex: 'id', width: 50, ellipsis: true },
    { title: '编码', dataIndex: 'code', width: 150, ellipsis: true },
    { title: '类别', dataIndex: 'type_str', width: 100, ellipsis: true },
    { title: '折扣率', dataIndex: 'discount', width: 100, ellipsis: true },
    { title: '减免金额', dataIndex: 'price_off', width: 100, ellipsis: true }
];

class CouponCodeManager extends Component {
    constructor(props) {
        super();

        this.state = {
            btnControlDisable: [false, true, true],  // 操作按钮控制
            // btnCopyPasteDisable: [true, true],  // 赋值粘贴按钮
            selectedRowKeys: [],  // 选中的行
            bDlgShow: false,  // 是否显示弹窗
            iDlgType: 0,  // 弹窗类别
            iCouponType: 0
        };
        this.arrCouponType = ["折扣券", "代金券"];
        this.arrTableData = [];
        this.objCurrentCoupon = {};
        this.arrDeleteID = [];
        this.arrDeleteCode = [];
        this.objCopiedCoupon = {};
    }

    // 选中某行的响应函数
    selectRow = (record) => {
        const selectedRowKeys = [...this.state.selectedRowKeys];
        if (selectedRowKeys.indexOf(record.key) >= 0) {
            selectedRowKeys.splice(selectedRowKeys.indexOf(record.key), 1);
        } else {
            selectedRowKeys.push(record.key);
        }
        this.SelectedRowStateSetting(selectedRowKeys);
    }
    // 选中的行发生变化的响应函数
    onSelectedRowKeysChange = (selectedRowKeys) => {
        this.SelectedRowStateSetting(selectedRowKeys);
    }
    // 处理选中的表行行
    SelectedRowStateSetting = (selectedRowKeys) => {
        let btnControlDisable = [];
        // let btnCopyPasteDisable = [];
        let iSelectedCouponType = 0;
        switch (selectedRowKeys.length) {
            case 0: {
                btnControlDisable = [false, true, true];
                // btnCopyPasteDisable = [true, true];
                this.objCurrentCoupon = {};
                break;
            }
            case 1: {
                btnControlDisable = [false, false, false];
                // btnCopyPasteDisable = [false, true];
                this.objCurrentCoupon = this.arrTableData[selectedRowKeys];
                iSelectedCouponType = this.GetCouponType(selectedRowKeys)
                break;
            }
            default: {
                btnControlDisable = [false, true, false];
                // btnCopyPasteDisable = [true, true];
                break;
            }
        }
        this.setState({
            ...this.state,
            selectedRowKeys,
            iCouponType: iSelectedCouponType,
            btnControlDisable: btnControlDisable,
            // btnCopyPasteDisable: btnCopyPasteDisable
        });
    }
    // 复制一行数据
    // CopyItem = () => {
    //     this.objCopiedCoupon = {};
    //     this.objCopiedCoupon = Object.assign({}, this.objCurrentCoupon);
    //     this.setState({
    //         ...this.state,
    //         btnCopyPasteDisable: [true, false]
    //     });
    //     // DataStatistic
    //     UserActiveUpdate(8, { active: "商家后台: 优惠码管理操作", action: "复制" });
    //     const hide = message.success("已复制优惠码：<" + this.objCopiedCoupon.code + ">");
    //     setTimeout(hide, 2000);
    // }
    // 粘贴一行数据
    // PasteItem = () => {
    //     let objCoupon = {};
    //     objCoupon.store_id = this.props.objStoreInfo.id;
    //     objCoupon.type = this.GetCouponType(this.objCopiedCoupon.key);
    //     objCoupon.code = this.objCopiedCoupon.code;
    //     objCoupon.discount = this.objCopiedCoupon.discount;
    //     objCoupon.price_off = this.objCopiedCoupon.price_off;

    //     message.loading({ content: '拷贝中……', key: g_strMessageKey });
    //     axios.post('/NewCoupon', { objNewCoupon: objCoupon })
    //         .then(({ data }) => {
    //             // DataStatistic
    //             UserActiveUpdate(8, { active: "商家后台: 优惠码管理操作", action: "粘贴" });
    //             message.success({ content: '拷贝商品：<' + objCoupon.code + "> 成功！", key: g_strMessageKey, duration: 2 });
    //             this.RefreshCouponTable(data);
    //         });
    // }
    // 新建时的数据重置
    PresetNewDlg = () => {
        if (this.refs.code !== undefined) {
            this.refs.code.state.value = "";
            if (this.refs.discount !== undefined)
                this.refs.discount.state.value = "";
            if (this.refs.price_off !== undefined)
                this.refs.price_off.state.value = "";
        }
    }
    //显示弹窗前预置编辑的内容
    PresetEditDlg(iSelectedRow) {
        if (this.refs.code !== undefined) {
            this.refs.code.state.value = this.arrTableData[iSelectedRow].code;
            if (this.refs.discount !== undefined)
                this.refs.discount.state.value = this.arrTableData[iSelectedRow].discount;
            if (this.refs.price_off !== undefined)
                this.refs.price_off.state.value = this.arrTableData[iSelectedRow].price_off;
        }
    }
    // 获取当前选中行的优惠码类型
    GetCouponType = (iSelectedRow) => {
        let iTypeIndex = 0;
        for (let i = 0; i < this.arrCouponType.length; i++) {
            if (this.arrTableData[iSelectedRow].type === this.arrCouponType[i]) {
                iTypeIndex = i;
                break;
            }
        }
        return iTypeIndex;
    }
    //弹出对话框
    ShowDlg = (iDlgType) => {
        switch (iDlgType) {
            // 新建优惠码
            case 0: {
                this.PresetNewDlg();
                this.setState({
                    ...this.state,
                    bDlgShow: true,
                    iDlgType: iDlgType,
                    iCouponType: 0,
                });
                break;
            }
            // 编辑优惠码
            case 1: {
                let iSelectedRow = this.state.selectedRowKeys[0];
                this.PresetEditDlg(iSelectedRow);
                this.setState({
                    ...this.state,
                    bDlgShow: true,
                    iDlgType: iDlgType,
                    iCouponType: this.GetCouponType(iSelectedRow),
                });
                break;
            }
            // 删除优惠码
            case 2: {
                this.arrDeleteID = [];
                for (let i = 0; i < this.state.selectedRowKeys.length; i++) {
                    console.log(this.arrTableData[this.state.selectedRowKeys[i]]);
                    this.arrDeleteID.push(this.arrTableData[this.state.selectedRowKeys[i]].id);
                    this.arrDeleteCode.push(this.arrTableData[this.state.selectedRowKeys[i]].code);
                }
                this.setState({
                    ...this.state,
                    iDlgType: iDlgType,
                    bDlgShow: true
                });
                break;
            }
            default: break;
        }
    }
    // 获取弹窗中的更新数据
    GetNewInputData() {
        let objInputInfo = {};
        objInputInfo.type = this.state.iCouponType;
        objInputInfo.code = this.refs.code.state.value;
        objInputInfo.discount = '0';
        objInputInfo.price_off = '0';
        if (this.state.iCouponType === 0)
            objInputInfo.discount = this.refs.discount.state.value;
        if (this.state.iCouponType === 1)
            objInputInfo.price_off = this.refs.price_off.state.value;
        return objInputInfo;
    }
    // 点击了弹窗里的优惠码类别过滤器
    DlgCouponTypeClicked = (iCouponType) => {
        this.setState({
            ...this.state,
            iCouponType: iCouponType
        });
    }
    // 确认按钮响应
    ConfirmButtonClick = () => {
        switch (this.state.iDlgType) {
            case 0: {
                let objNewCoupon = this.GetNewInputData();
                if (objNewCoupon.code === undefined || objNewCoupon.code === null || objNewCoupon.code === "") {
                    message.warning('优惠码不能为空！');
                    return;
                }
                if (objNewCoupon.type === 0) {
                    if (objNewCoupon.discount === undefined || objNewCoupon.discount === null || objNewCoupon.discount === "") {
                        message.warning('折扣率不能为空！');
                        return;
                    }
                }
                if (objNewCoupon.type === 1) {
                    if (objNewCoupon.price_off === undefined || objNewCoupon.cprice_offd === null || objNewCoupon.price_off === "") {
                        message.warning('减免金额不能为空！');
                        return;
                    }
                }
                objNewCoupon.store_id = this.props.objStoreInfo.id;
                console.log("New Coupone : ", objNewCoupon);
                message.loading({ content: '正在创建新的优惠码……', key: g_strMessageKey });
                axios.post('/NewCoupon', { objNewCoupon: objNewCoupon })
                    .then(
                        ({ data }) => {
                            // DataStatistic
                            UserActiveUpdate(8, { active: "商家后台: 优惠码管理操作", action: "新建" });
                            message.success({ content: '创建完成！', key: g_strMessageKey, duration: 2 });
                            this.RefreshCouponTable(data);
                        }
                    );
                break;
            }
            case 1: {
                let objEditCoupon = this.GetNewInputData();
                if (objEditCoupon.code === "") {
                    message.warning('优惠码不能为空！');
                    return;
                }
                objEditCoupon.id = this.arrTableData[this.state.selectedRowKeys[0]].id;
                message.loading({ content: '正在更新优惠码信息……', key: g_strMessageKey });
                axios.post('/EditCoupon', { objEditCoupon: objEditCoupon })
                    .then(
                        ({ data }) => {
                            // DataStatistic
                            UserActiveUpdate(8, { active: "商家后台: 优惠码管理操作", action: "编辑" });
                            message.success({ content: '更新完成！', key: g_strMessageKey, duration: 2 });
                            this.RefreshCouponTable(data);
                        }
                    );
                break;
            }
            case 2: {
                message.loading({ content: '删除优惠码中……', key: g_strMessageKey });
                axios.post('/DeleteCoupons', {
                    arrDeleteCoupons: this.arrDeleteID, store_id: this.props.objStoreInfo.id
                }).then(({ data }) => {
                    // DataStatistic
                    UserActiveUpdate(8, { active: "商家后台: 优惠码管理操作", action: "删除" });
                    message.success({ content: '删除完成！', key: g_strMessageKey, duration: 2 });
                    this.RefreshCouponTable(data);
                });
                break;
            }
            default: break;
        }
    }
    // 取消按钮响应
    CancelButtonClick = () => {
        this.setState({
            ...this.state,
            bDlgShow: false
        });
    }
    // 刷新表格中的数据
    RefreshCouponTable = (data) => {
        this.arrTableData = [];
        for (let i = 0; i < data.length; i++) {
            let objCouponData = {
                key: i, id: data[i].id,
                type: data[i].type,
                type_str: this.arrCouponType[data[i].type],
                code: data[i].code,
                discount: data[i].discount,
                price_off: data[i].price_off,
                store_id: data[i].store_id
            }
            this.arrTableData.push(objCouponData);
        }
        if (this.state.iDlgType === 2) {
            this.setState({
                ...this.state,
                // btnCopyPasteDisable: [true, true],
                btnControlDisable: [false, true, true],
                selectedRowKeys: [],
                iDlgType: 2,
                bDlgShow: false
            });
        } else {
            this.setState({
                ...this.state,
                // btnCopyPasteDisable: [true, true],
                btnControlDisable: [false, true, true],
                selectedRowKeys: [],
                iDlgType: 0,
                bDlgShow: false
            });
        }
    }
    // 从数据库获取当前的数据结构
    GetCouponsData = () => {
        message.loading({ content: '数据加载中……', key: g_strMessageKey });
        axios('/GetCoupons', { params: { store_id: this.props.objStoreInfo.id } })
            .then(({ data }) => {
                message.success({ content: "数据加载完成！", key: g_strMessageKey, duration: 2 });
                this.RefreshCouponTable(data);
            }).catch(function (error) { console.log(error); });
    }
    // 保存统一折扣率
    SaveDiscount = () => {
        console.log("SaveDiscount : " + this.refs.all_discount.state.value, this.props.objStoreInfo.id);
        message.loading({ content: '正在保存……', key: g_strMessageKey });
        axios.post('/SaveStoreDiscount', { all_discount: this.refs.all_discount.state.value, store_id: this.props.objStoreInfo.id })
            .then(({ data }) => {
                console.log("data : ", data);
                this.props.objStoreInfo.all_discount = this.refs.all_discount.state.value;
                // DataStatistic
                UserActiveUpdate(8, { active: "商家后台: 统一优惠价格设置", action: "编辑" });
                message.success({ content: '统一优惠价格已保存！', key: g_strMessageKey, duration: 2 });
            });
    }
    /* React自带函数 */
    // 卸载异步操作设置状态
    componentWillUnmount() {
        clearTimeout(this.timeouter)
        this.setState = (state, callback) => {
            return;
        }
    }
    // 渲染完毕……
    componentDidMount() {
        console.log("store info : ", this.props.objStoreInfo);
        this.GetCouponsData();
    }

    render() {

        // 新建按钮的样式控制
        let strNewButtonStyle = { backgroundColor: 'lightseagreen', color: 'white' };
        // let strCopyButtonStyle = { backgroundColor: '#20b2aa', color: 'white', oppacity: '1' };
        // if (this.state.btnCopyPasteDisable[0])
        //     strCopyButtonStyle = { backgroundColor: 'white', color: 'gray', oppacity: '0.5' };
        // 主体表格相关
        const { selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectedRowKeysChange,
        };

        // 创建对话框
        let strDlgTitle = "新建优惠码";
        let objCoupon = {
            key: 0, id: 0, type: this.arrCouponType[0], code: "", discount: "0", price_off: "0"
        }
        if (this.state.iDlgType === 1) {
            objCoupon = this.arrTableData[this.state.selectedRowKeys[0]];
            if (objCoupon)
                strDlgTitle = "编辑优惠码 <" + objCoupon.code + ">";
            else
                strDlgTitle = "编辑优惠码";
        }
        if (objCoupon !== undefined) {
            if (objCoupon.type !== undefined) {
                objCoupon.type = this.arrCouponType[this.state.iCouponType];
            }
        }
        let uiCouponTypeMenu = (
            <Menu>
                {this.arrCouponType.map((item, index) => {
                    return (
                        <Menu.Item key={index} onClick={this.DlgCouponTypeClicked.bind(this, index)}>
                            {item}
                        </Menu.Item>
                    );
                })}
            </Menu>
        );

        let uiDlg = null
        if (objCoupon !== undefined) {
            let uiValueEdit = null;
            if (objCoupon.type === "代金券") {
                uiValueEdit = (
                    <div>
                        <div className="item_info_row">
                            <div className="coupon_edit_title">减免</div><Input className="coupon_edit_input" ref="price_off" defaultValue={objCoupon.price_off} addonBefore={'$CAD'} />
                        </div>
                    </div>
                );
            }
            if (objCoupon.type === "折扣券") {
                uiValueEdit = (
                    <div className="item_info_row">
                        <div className="coupon_edit_title">折扣</div><Input className="coupon_edit_input" ref="discount" defaultValue={objCoupon.discount} addonAfter={'%'} />
                    </div>
                );
            }
            uiDlg = (
                <Modal title={strDlgTitle} visible={this.state.bDlgShow} okText="确定" cancelText="取消" width={350}
                    onOk={this.ConfirmButtonClick} onCancel={this.CancelButtonClick} >
                    <div className="item_info_row">
                        <div className="coupon_edit_title">类别</div><Dropdown className="coupon_edit_input"
                            overlay={uiCouponTypeMenu} placement="bottomLeft">
                            <Button> {this.arrCouponType[this.state.iCouponType]} <Icon type="caret-down" /> </Button>
                        </Dropdown>
                    </div>
                    <div className="item_info_row">
                        <div className="coupon_edit_title">编码</div><Input className="coupon_edit_input" ref="code" placeholder="必填……" defaultValue={objCoupon.code} />
                    </div>
                    {uiValueEdit}
                </Modal>
            );
        }
        if (this.state.iDlgType === 2) {
            strDlgTitle = "删除优惠码";
            let strDeleteItems = "是否删除：";
            for (let i = 0; i < this.arrDeleteID.length; i++) {
                strDeleteItems += "<" + this.arrDeleteCode[i] + ">";
                if (i !== this.arrDeleteCode.length - 1)
                    strDeleteItems += ", ";
            }
            strDeleteItems += " 等优惠码";
            uiDlg = (
                <Modal title={strDlgTitle} visible={this.state.bDlgShow} okText="确定" cancelText="取消"
                    onOk={this.ConfirmButtonClick} onCancel={this.CancelButtonClick} >
                    {strDeleteItems}
                </Modal>
            );
        }

        return (
            <div className="store_manage_contents store_manage_top_space">
                {/* 优惠码管理页面控制栏 */}
                <div className="store_contents_title">
                    {/* <div className="manage_title_button_right_side"> */}
                    <Button className="manage_contents_title_margin" style={strNewButtonStyle} onClick={this.ShowDlg.bind(this, 0)} disabled={this.state.btnControlDisable[0]}>
                        <Icon type="plus" />新建</Button>
                    <Button className="manage_contents_title_margin" type="primary" onClick={this.ShowDlg.bind(this, 1)} disabled={this.state.btnControlDisable[1]}>
                        <Icon type="edit" /> 编辑</Button>
                    <Button className="manage_contents_title_margin" type="danger" onClick={this.ShowDlg.bind(this, 2)} disabled={this.state.btnControlDisable[2]}>
                        <Icon type="close" /> 删除</Button>
                    {/* </div> */}
                    {/* <div className="manage_title_button_right_side" style={{ marginLeft: '20px', marginRight: '20px' }}>
                        <Button className="manage_contents_title_margin" style={strCopyButtonStyle}
                            onClick={this.CopyItem} disabled={this.state.btnCopyPasteDisable[0]}>
                            <Icon type="copy" />复制</Button>
                        <Button className="manage_contents_title_margin" type="primary" onClick={this.PasteItem} disabled={this.state.btnCopyPasteDisable[1]}>
                            <Icon type="edit" /> 粘贴</Button>
                    </div> */}
                    <div className="manage_title_button_right_side" style={{ marginLeft: 'auto', marginRight: '10px' }}>
                        <div className="order_detail_title" style={{ color: "white", width: '175px', textAlign: 'right' }}>统一折扣设置：</div>
                        <Input className="order_detail_input" ref="all_discount" style={{ marginRight: '0px', width: '100px' }} addonAfter={"%"}
                            defaultValue={this.props.objStoreInfo.all_discount} />
                        <Button className="manage_contents_title_margin" onClick={this.SaveDiscount} type="primary" style={{ marginLeft: '10px' }}>
                            <Icon type="save" />保存</Button>
                    </div>
                </div>
                {/* 表格主体 */}
                <Table style={{ marginLeft: '20px', marginRight: '20px' }}
                    rowSelection={rowSelection} columns={arrTableHeader}
                    dataSource={this.arrTableData}
                    onRow={(record) => ({ onClick: () => { this.selectRow(record); } })} />
                {/* 弹窗 */}
                {uiDlg}
            </div>
        );
    }
}
export default CouponCodeManager;
