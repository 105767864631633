import React, { Component } from "react";
import axios from 'axios';

import Modal from "antd/es/modal";
import Input from "antd/es/input";
import Tree from "antd/es/tree";
import Icon from "antd/es/icon";
import Button from "antd/es/button";
import message from "antd/es/message";

import "../../../styles/Background/UI_Module/transit_status_management.css";

const { TreeNode } = Tree;
const g_strMessageKey = 'updating';

class TransitStatusManagement extends Component {
    constructor(props) {
        super();
        this.state = {
            btnDisable: [false, true, true],
            bDlgShow: false,
            iDlgType: -1,
            selectedKeys: ['0'],
            iStatusLevel: 0
        };
        this.arrOrderTransitStatus = ["国内收货", "已发货", "已到港", "到目的地", "派送中", "已签收"];
        if (this.strLanguage === "en")
            this.arrOrderTransitStatus = ["Goods Received", "On-board", "Port of Discharge",
                "Destination Facility", "Out For Delivery", "Delivered"];
        this.arrStatusObj = [
            { cn_name: "国内收货", en_name: "Goods Received", status_order: 0, store_id: props.objStoreInfo.id, sub_cn_name: "", sub_en_name: "" },
            { cn_name: "已发货", en_name: "On-board", status_order: 1, store_id: props.objStoreInfo.id, sub_cn_name: "", sub_en_name: "" },
            { cn_name: "已到港", en_name: "Port of Discharge", status_order: 2, store_id: props.objStoreInfo.id, sub_cn_name: "", sub_en_name: "" },
            { cn_name: "到目的地", en_name: "Destination Facility", status_order: 3, store_id: props.objStoreInfo.id, sub_cn_name: "", sub_en_name: "" },
            { cn_name: "派送中", en_name: "Out For Delivery", status_order: 4, store_id: props.objStoreInfo.id, sub_cn_name: "", sub_en_name: "" },
            { cn_name: "已签收", en_name: "Delivered", status_order: 5, store_id: props.objStoreInfo.id, sub_cn_name: "", sub_en_name: "" },
        ]
        this.arrCurrentSelectNode = [];
        this.objCurrentStatus = { cn_name: "", en_name: "", status_order: -1 };
        this.objCurrentSubStatus = { sub_cn_name: "", sub_en_name: "" };
        this.bLoading = true;
    }

    onSelect = (selectedKeys, info) => {
        console.log('selected', selectedKeys, info);
        this.arrCurrentSelectNode = selectedKeys;

        console.log(this.arrCurrentSelectNode);
        if (this.arrCurrentSelectNode.length > 0) {
            this.objCurrentStatus = info.node.props.dataRef;
            console.log(this.objCurrentStatus);
            // “物流状态”母节点
            if (this.arrCurrentSelectNode[0] === "0") {
                this.objCurrentStatus = { cn_name: "", en_name: "", status_order: -1 };
                this.setState({
                    ...this.state,
                    btnDisable: [false, true, true],
                    selectedKeys,
                    iStatusLevel: 0
                });
            }
            // 是子结点
            if (this.arrCurrentSelectNode[0].indexOf('-') >= 0) {
                // 主状态节点
                if (!this.arrCurrentSelectNode[0].split('-')[2]) {
                    this.setState({
                        ...this.state,
                        btnDisable: [false, false, false],
                        selectedKeys,
                        iStatusLevel: 1
                    });
                }
                // 状态描述节点
                else {
                    if (this.arrCurrentSelectNode[0].split('-')[2] !== null && this.arrCurrentSelectNode[0].split('-')[2] !== "") {
                        this.setState({
                            ...this.state,
                            btnDisable: [true, false, false],
                            selectedKeys,
                            iStatusLevel: 2
                        });
                    }
                }
            }
        } else {
            this.objCurrentStatus = { cn_name: "", en_name: "", status_order: -1 };
            this.setState({
                ...this.state,
                btnDisable: [true, true, true],
                selectedKeys,
                iStatusLevel: -1
            });
        }
    };
    // 状态管理弹窗
    showModal = (iDlgType) => {
        console.log("dlg type > " + iDlgType);
        console.log("level > " + this.state.iStatusLevel);
        console.log("objCurrentStatus = ", this.objCurrentStatus);
        if (this.bLoading)
            return;
        // 新建状态
        if (iDlgType === 0) {
            if (this.state.iStatusLevel === 0)
                this.objCurrentStatus = { cn_name: "", en_name: "", status_order: -1 };
            if (this.refs.status_cn) {
                this.refs.status_cn.state.value = "";
                this.refs.status_en.state.value = "";
            }
            if (this.refs.status_order) {
                this.refs.status_order.state.value = "";
            }
        }
        // 编辑状态
        if (iDlgType === 1) {
            console.log("编辑状态：");
            if (this.refs.status_cn) {
                console.log(this.objCurrentStatus);
                this.refs.status_cn.state.value = this.objCurrentStatus.cn_name;
                this.refs.status_en.state.value = this.objCurrentStatus.en_name;
                if (this.state.iStatusLevel === 2) {
                    this.refs.status_cn.state.value = this.objCurrentStatus.sub_cn_name;
                    this.refs.status_en.state.value = this.objCurrentStatus.sub_en_name;
                }
            }
            if (this.refs.status_order && this.state.iStatusLevel === 1) {
                this.refs.status_order.state.value = this.objCurrentStatus.status_order;
            }
        }
        this.setState({
            ...this.state,
            iDlgType: iDlgType,
            bDlgShow: true,
        });
    };

    handleOk = e => {
        this.bLoading = true;
        let objTransitStatus = {};

        //  主节点新建
        if (this.refs.status_cn && this.state.iStatusLevel === 0) {
            objTransitStatus.cn_name = this.refs.status_cn.state.value;
            objTransitStatus.en_name = this.refs.status_en.state.value;
            objTransitStatus.status_order = this.refs.status_order.state.value;
            objTransitStatus.store_id = this.props.objStoreInfo.id;
            // 新建
            if (this.state.iDlgType === 0) {
                message.loading({ content: '正在创建新的物流状态……', key: g_strMessageKey });
                axios.post('/NewTransitStatus', { objTransitStatus: objTransitStatus })
                    .then(({ data }) => {
                        console.log("新增物流状态完成", data);
                        this.arrStatusObj = data;
                        message.success({ content: '创建完成！', key: g_strMessageKey, duration: 2 });
                        this.bLoading = false;
                        this.setState({
                            ...this.state,
                            bDlgShow: false,
                            selectedKeys: ['0'],
                            iStatusLevel: 0
                        });
                    });
            }
        } else {
            this.bLoading = false;
            console.log("0：未能正获取新的物流状态信息！");
        }
        // 子结点新建/编辑
        if (this.refs.status_cn && this.state.iStatusLevel === 1) {
            // 新建
            if (this.state.iDlgType === 0) {
                objTransitStatus = Object.assign({}, this.objCurrentStatus);
                if (objTransitStatus.sub_cn_name !== null) {
                    if (objTransitStatus.sub_cn_name.trim() !== "") {
                        objTransitStatus.sub_cn_name += "," + this.refs.status_cn.state.value;;
                        objTransitStatus.sub_en_name += "," + this.refs.status_en.state.value;;
                    } else {
                        objTransitStatus.sub_cn_name = this.refs.status_cn.state.value;;
                        objTransitStatus.sub_en_name = this.refs.status_en.state.value;;
                    }
                } else {
                    objTransitStatus.sub_cn_name = this.refs.status_cn.state.value;;
                    objTransitStatus.sub_en_name = this.refs.status_en.state.value;;
                }
                message.loading({ content: '正在新增物流状态描述……', key: g_strMessageKey });
                axios.post('/EditTransitStatus', { objTransitStatus: objTransitStatus })
                    .then(({ data }) => {
                        console.log("新增物流描述完成", data);
                        this.arrStatusObj = data;
                        message.success({ content: '新增完成！', key: g_strMessageKey, duration: 2 });
                        this.bLoading = false;
                        this.setState({
                            ...this.state,
                            bDlgShow: false,
                            selectedKeys: ['0'],
                            iStatusLevel: 0
                        });
                    });
            }
            // 编辑
            if (this.state.iDlgType === 1) {
                objTransitStatus.cn_name = this.refs.status_cn.state.value;
                objTransitStatus.en_name = this.refs.status_en.state.value;
                objTransitStatus.status_order = this.refs.status_order.state.value;
                objTransitStatus.store_id = this.props.objStoreInfo.id;
                objTransitStatus.id = this.objCurrentStatus.id;
                message.loading({ content: '正在更新物流状态信息……', key: g_strMessageKey });
                axios.post('/EditTransitStatus', { objTransitStatus: objTransitStatus })
                    .then(({ data }) => {
                        console.log("更新物流状态完成", data);
                        this.arrStatusObj = data;
                        message.success({ content: '更新完成！', key: g_strMessageKey, duration: 2 });
                        this.bLoading = false;
                        this.setState({
                            ...this.state,
                            bDlgShow: false,
                            selectedKeys: ['0'],
                            iStatusLevel: 0
                        });
                    });
            }
        } else {
            this.bLoading = false;
            console.log("1：未能正获取新的物流状态/描述信息！")
        }
        // 状态描述编辑
        if (this.refs.status_cn && this.state.iStatusLevel === 2) {
            // 编辑
            if (this.state.iDlgType === 1) {
                objTransitStatus = Object.assign({}, this.objCurrentStatus.parent);
                let iTransitDetailID = parseInt(this.arrCurrentSelectNode[0].split('-')[2], 10);
                let arrTransitDetailsCN = objTransitStatus.sub_cn_name.split(',');
                let arrTransitDetailsEN = objTransitStatus.sub_en_name.split(',');
                arrTransitDetailsCN[iTransitDetailID] = this.refs.status_cn.state.value;
                arrTransitDetailsEN[iTransitDetailID] = this.refs.status_en.state.value;
                objTransitStatus.sub_cn_name = "";
                objTransitStatus.sub_en_name = "";
                for (let i = 0; i < arrTransitDetailsCN.length; i++) {
                    if (i === arrTransitDetailsCN.length - 1) {
                        objTransitStatus.sub_cn_name += arrTransitDetailsCN[i];
                        objTransitStatus.sub_en_name += arrTransitDetailsEN[i];
                    } else {
                        objTransitStatus.sub_cn_name += arrTransitDetailsCN[i] + ",";
                        objTransitStatus.sub_en_name += arrTransitDetailsEN[i] + ",";
                    }
                }
                message.loading({ content: '正在更新物流状态描述……', key: g_strMessageKey });
                axios.post('/EditTransitStatus', { objTransitStatus: objTransitStatus })
                    .then(({ data }) => {
                        console.log("更新物流状态描述完成", data);
                        this.arrStatusObj = data;
                        message.success({ content: '更新状态描述完成！', key: g_strMessageKey, duration: 2 });
                        this.bLoading = false;
                        this.setState({
                            ...this.state,
                            bDlgShow: false,
                            selectedKeys: ['0'],
                            iStatusLevel: 0
                        });
                    });
            }
        } else {
            this.bLoading = false;
            console.log("1：未能正获取新的物流状态/描述信息！")
        }
        // 删除
        if (this.state.iDlgType === 2) {
            // 删除物流状态
            if (this.state.iStatusLevel === 1) {
                message.loading({ content: '正在更新物流状态信息……', key: g_strMessageKey });
                axios.post('/DeleteTransitStatus', { id: this.objCurrentStatus.id, store_id: this.props.objStoreInfo.id })
                    .then(({ data }) => {
                        this.arrStatusObj = data;
                        message.success({ content: '删除完成！', key: g_strMessageKey, duration: 2 });
                        this.bLoading = false;
                        this.setState({
                            ...this.state,
                            bDlgShow: false,
                            selectedKeys: ['0'],
                            iStatusLevel: 0
                        });
                    });
            }
            // 删除物流状态描述
            if (this.state.iStatusLevel === 2) {
                objTransitStatus = Object.assign({}, this.objCurrentStatus.parent);
                let iTransitDetailID = parseInt(this.arrCurrentSelectNode[0].split('-')[2], 10);
                let arrTransitDetailsCN = objTransitStatus.sub_cn_name.split(',');
                let arrTransitDetailsEN = objTransitStatus.sub_en_name.split(',');
                let arrCN = [];
                let arrEN = [];
                for (let i = 0; i < arrTransitDetailsCN.length; i++) {
                    if (i !== iTransitDetailID) {
                        arrCN.push(arrTransitDetailsCN[i]);
                        arrEN.push(arrTransitDetailsEN[i]);
                    }
                }
                objTransitStatus.sub_cn_name = "";
                objTransitStatus.sub_en_name = "";
                for (let i = 0; i < arrCN.length; i++) {
                    if (i === arrCN.length - 1) {
                        objTransitStatus.sub_cn_name += arrCN[i];
                        objTransitStatus.sub_en_name += arrEN[i];
                    } else {
                        objTransitStatus.sub_cn_name += arrCN[i] + ",";
                        objTransitStatus.sub_en_name += arrEN[i] + ",";
                    }
                }

                message.loading({ content: '正在删除物流状态描述……', key: g_strMessageKey });
                axios.post('/EditTransitStatus', { objTransitStatus: objTransitStatus })
                    .then(({ data }) => {
                        console.log("删除物流状态描述完成", data);
                        this.arrStatusObj = data;
                        message.success({ content: '更新状态描述完成！', key: g_strMessageKey, duration: 2 });
                        this.bLoading = false;
                        this.setState({
                            ...this.state,
                            bDlgShow: false,
                            selectedKeys: ['0'],
                            iStatusLevel: 0
                        });
                    });
            }
        }
        this.objCurrentStatus = { cn_name: "", en_name: "", status_order: -1 };
        this.setState({
            ...this.state,
            btnDisable: [false, true, true],
            bDlgShow: false,
            selectedKeys: ['0'],
            iStatusLevel: 0
        });
    };

    handleCancel = e => {
        // console.log(e);
        this.objCurrentStatus = { cn_name: "", en_name: "", status_order: -1 };
        this.setState({
            ...this.state,
            btnDisable: [false, true, true],
            bDlgShow: false,
            selectedKeys: ['0']
        });
    };

    // 获取商家的物流状态
    GetTransitStatus = () => {
        message.loading({ content: '数据加载中……', key: g_strMessageKey });
        axios('/GetTransitStatus', { params: { store_id: this.props.objStoreInfo.id } })
            .then(({ data }) => {
                this.bLoading = false;
                if (data.length <= 0) {
                    console.log("Init TransitStatus", data);
                    axios.post('/InitTransitStatus', { arrStatus: this.arrStatusObj })
                        .then(({ data }) => {
                            console.log(data);

                        }).catch(function (error) { console.log(error); });
                } else {

                    this.arrStatusObj = data;
                    console.log("GetTransitStatus", this.arrStatusObj);
                }
                message.success({ content: "数据加载完成！", key: g_strMessageKey, duration: 2 });
                this.setState({
                    ...this.state,
                    bDlgShow: false
                });
            }).catch(function (error) { console.log(error); });
    }

    componentDidMount() {
        this.GetTransitStatus();
    }

    render() {

        let uiOrderInput = (
            <div className="user_edit_info_row">
                <div className="user_edit_info_title">状态排序</div>
                <Input className="user_edit_info_input" ref="status_order" defaultValue={this.objCurrentStatus.status_order} />
            </div>
        );
        let arrDefaultName = [this.objCurrentStatus.cn_name, this.objCurrentStatus.en_name];

        if (this.state.iDlgType === 0 && this.state.iStatusLevel === 1) {
            arrDefaultName = ["", ""];
            uiOrderInput = <div />;
        }
        if (this.state.iDlgType === 1 && this.state.iStatusLevel === 2) {
            arrDefaultName = [this.objCurrentStatus.sub_cn_name, this.objCurrentStatus.sub_en_name];
            uiOrderInput = <div />;
        }
        let uiDlg = (
            <Modal title="物流状态管理" visible={this.state.bDlgShow} width={350}
                onOk={this.handleOk} onCancel={this.handleCancel}
                okText="确认" cancelText="取消">
                <div className="user_edit_info_row">
                    <div className="user_edit_info_title">中文名称</div>
                    <Input className="user_edit_info_input" ref="status_cn" defaultValue={arrDefaultName[0]} />
                </div>
                <div className="user_edit_info_row">
                    <div className="user_edit_info_title">英文名称</div>
                    <Input className="user_edit_info_input" ref="status_en" defaultValue={arrDefaultName[1]} />
                </div>
                {uiOrderInput}
            </Modal>
        );
        if (this.state.iDlgType === 2) {
            if (this.state.iStatusLevel === 1) {
                uiDlg = (
                    <Modal title="删除物流状态" visible={this.state.bDlgShow} width={350}
                        onOk={this.handleOk} onCancel={this.handleCancel} okType="danger"
                        okText="确认" cancelText="取消">
                        <div className="user_edit_info_row">
                            <div className="user_edit_info_title">{"确定删除物流状态：< " + this.objCurrentStatus.cn_name + " > 吗？"}</div>
                        </div>
                    </Modal>
                );
            }
            if (this.state.iStatusLevel === 2) {
                uiDlg = (
                    <Modal title="删除物流状态描述" visible={this.state.bDlgShow} width={350}
                        onOk={this.handleOk} onCancel={this.handleCancel} okType="danger"
                        okText="确认" cancelText="取消">
                        <div className="user_edit_info_row">
                            <div className="user_edit_info_title">{"确定删除物流状态描述：< " + this.objCurrentStatus.sub_cn_name + " > 吗？"}</div>
                        </div>
                    </Modal>
                );
            }
        }

        let strPageStyle = "transit_status_page";
        if (this.props.bMStation) {
            strPageStyle = "transit_status_page_m";
        }

        return (
            <div className={strPageStyle}>
                <div className="transit_status_title">
                    <Button className="transit_status_button transit_status_new_button" disabled={this.state.btnDisable[0]} onClick={this.showModal.bind(this, 0)}>新建</Button>
                    <Button type="primary" className="transit_status_button" disabled={this.state.btnDisable[1]} onClick={this.showModal.bind(this, 1)}>编辑</Button>
                    <Button type="danger" className="transit_status_button" disabled={this.state.btnDisable[2]} onClick={this.showModal.bind(this, 2)}>删除</Button>
                </div>
                <div className="transit_status_tree">
                    <Tree showLine switcherIcon={<Icon type="down" />} selectedKeys={this.state.selectedKeys}
                        /*defaultExpandedKeys={['0']}*/ defaultExpandAll={true} onSelect={this.onSelect} >
                        <TreeNode title="物流状态" key="0">{
                            this.arrStatusObj.map((item, index) => {

                                let iNodeIndex = index;
                                let arrSubStatusCN = [];
                                let arrSubStatusEN = [];
                                let uiTreeNode = (
                                    <TreeNode /*className="manage_tree_node_h"*/ title={item.status_order + "-" + item.cn_name + " | " + item.en_name}
                                        key={"0-" + index} dataRef={item}>
                                    </TreeNode>
                                );
                                if (item.sub_cn_name !== null) {
                                    if (item.sub_cn_name.trim() !== "") {
                                        arrSubStatusCN = item.sub_cn_name.split(',');
                                        arrSubStatusEN = item.sub_en_name.split(',');
                                        // console.log("当前状态的描述列表 ： ", arrSubStatusCN, arrSubStatusEN);
                                        uiTreeNode = (
                                            <TreeNode /*className="manage_tree_node_h"*/ title={item.status_order + "-" + item.cn_name + " | " + item.en_name}
                                                key={"0-" + index} dataRef={item}>
                                                {
                                                    arrSubStatusCN.map((node, index) => {
                                                        let objDataRef = { parent: item, sub_cn_name: node, sub_en_name: arrSubStatusEN[index] };
                                                        return (
                                                            <TreeNode /*className="manage_tree_node_h"*/ title={node + " | " + arrSubStatusEN[index]}
                                                                key={"0-" + iNodeIndex + "-" + index} dataRef={objDataRef}>
                                                            </TreeNode>
                                                        );
                                                    })
                                                }
                                            </TreeNode>
                                        );
                                    }
                                }

                                return (
                                    uiTreeNode
                                );
                            })
                        }
                        </TreeNode>
                    </Tree>
                </div>
                {uiDlg}
            </div>
        );
    }
}
export default TransitStatusManagement;
