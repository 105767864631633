import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
// Mouthing客户端
// import Mouthing from "./pages/ClientSide/Mouthing.js";
// import TransitTracking from "./pages/ClientSide/TransitTracking.js";
// Mouthing管理后台
import WebAppManager from "./pages/Background/WebAppManager.js";  // 管理员后台
import StoreManager from "./pages/Background/StoreManager.js";  // 商家管理后台
import Logistics from "./pages/Background/Logistics.js";  // 物流管理后台
import Trucking from "./pages/Background/Trucking.js";  // 卡派管理后台
import Sooning from "./pages/Background/SooningManager.js";  // 商家管理后台
import StorePlayer from "./pages/Background/StorePlayer";  // 商家控制后台（小功能&特殊功能）
import DBManager from "./pages/Background/DBManager";  // 数据库管理器
import Accounting from "./pages/Background/Accounting";  // 记账系统
import StorageManager from "./pages/Background/StorageManager";  // 筋斗云海外仓系统EGWMS
import JetonManager from "./pages/Background/JetonManager";  // ZT运单管理器
import ShippingManager from "./pages/Background/ShippingManager";  // 第三方运单管理器
import JetonService from "./pages/Background/JetonService";  // 捷通服务系统

class App extends Component {
    render() {
        document.title = "服务咨询娱乐便民";

        const App = () => (
            <div>
                <Switch>
                    {/* Mouthing客户端 */}
                    {/* <Route path="/webapp" exact component={Mouthing} />
                    <Route path="/webapp" component={Mouthing} />
                    <Route path="/tracking" component={TransitTracking} /> */}
                    {/* Mouthing管理后台 */}
                    {/* <Route exact path="/" component={Sooning} /> */}
                    <Route path="/jeton-service" component={JetonService} iStoreID={30000} />
                    <Route path="/trucking" component={Trucking} />
                    <Route path="/jw-ship" component={ShippingManager} iStoreID={30000} />
                    <Route path="/sn-ship" component={ShippingManager} iStoreID={50000} />
                    <Route path="/jeton" component={JetonManager} />
                    <Route path="/webapp-manager" component={WebAppManager} />
                    <Route path="/store-manager" component={StoreManager} />
                    <Route path="/logistics" component={Logistics} />
                    <Route path="/sooning" component={Sooning} />
                    <Route path="/store-player/:id" component={StorePlayer} />
                    <Route path="/db-manager" component={DBManager} />
                    <Route path="/storage-manager" component={StorageManager} />
                    <Route path="/accounting" component={Accounting} />
                </Switch>
            </div>
        );
        return (
            <div>
                <App />
            </div>
        );
    }
}

export default App;
