import React, { Component } from "react";
import axios from 'axios';

import Spin from 'antd/es/spin';
import Tree from 'antd/es/tree';
import Modal from 'antd/es/modal';
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import Icon from 'antd/es/icon';
import message from 'antd/es/message';

// import { Spin, Tree, Modal, Input, Button, Icon, message } from 'antd';
import '../../../styles/Background/ego_manager.css';
import { UserActiveUpdate } from "../../../functions/DataStatistic.js"

// antd树形结构体
const { TreeNode } = Tree;
// 树形结构数据 (Default Data)
global.arrLocationsData = [{
    key: '0-0', id: 1, parent_id: 0, level: 0,
    title: '全部', name: '', en_name: '', latitude_x: 0, longitude_y: 0,
    children: []
}];
global.arrVancouverAreaData = [];
const g_strMessageKey = 'updating';

class LocationManagement extends Component {
    /* 构造函数 */
    constructor(props) {
        super();
        this.state = {
            // 操作按钮控制
            btnControlDisable: [false, true, true],
            // 弹窗控制
            bShowDlg: false,
            iDlgType: 0,
            // 控制树控件的状态
            selectedKeys: ['0-0'],
            expandedKeys: ['0-0'],
            // 树控件数据体
            locationsData: global.arrLocationsData
        };
        this.bDataBack = false;
        this.arrLocationsData = [];
        this.iSelectedLocationID = 0;
        this.iSelectedLevel = 0;
        this.strSelectedLocationName = "全部";
        this.iSelectedLocationIndex = 0;
        this.strSelectedLocationKey = ['0-0'];
    }
    /* 树形控件自带响应函数 */
    // 树形控件节点展开
    onExpand = expandedKeys => {
        this.setState({
            ...this.state,
            expandedKeys: expandedKeys
        });
    };
    // 树形控件节点选择
    onSelect = (selectedKeys, info) => {
        this.strSelectedLocationKey = selectedKeys;
        if (info.selectedNodes.length > 0) {
            this.iSelectedLocationID = info.selectedNodes[0].props.dataRef.id;
            this.iSelectedLocationLevel = info.selectedNodes[0].props.dataRef.level;
            this.strSelectedLocationName = info.selectedNodes[0].props.dataRef.name;
            this.GetSelectLocationIndex();
            if (this.iSelectedLocationID === 1) {
                this.setState({
                    ...this.state,
                    btnControlDisable: [false, true, true],
                    selectedKeys
                });
            } else {
                this.setState({
                    ...this.state,
                    btnControlDisable: [false, false, false],
                    selectedKeys
                });
            }
        } else {
            this.iSelectedLocationID = 0;
            this.setState({
                ...this.state,
                btnControlDisable: [true, true, true],
                selectedKeys
            });
        }
    };
    // 树形控件节点渲染
    renderTreeNodes = data =>
        data.map(item => {
            if (item.children) {
                return (
                    <TreeNode className="tree_node_v"
                        title={item.title} key={item.key} dataRef={item}>
                        {this.renderTreeNodes(item.children)}
                    </TreeNode>
                );
            }
            return <TreeNode key={item.key} {...item} />;
        });
    /* 弹窗自带响应函数 */
    // 显示弹窗
    ShowDlg = (iDlgType) => {
        if (iDlgType === 0) {
            if (this.refs.input_cn_name)  // 一开始refs.input_cn_name会是undefine，因此需要做判断
                this.refs.input_cn_name.state.value = "";
            if (this.refs.input_en_name)  // 一开始refs.input_cn_name会是undefine，因此需要做判断
                this.refs.input_en_name.state.value = "";
        }
        if (iDlgType === 1) {
            if (this.refs.input_cn_name)  // 一开始refs.input_cn_name会是undefine，因此需要做判断
                this.refs.input_cn_name.state.value = this.arrLocationsData[this.iSelectedLocationIndex].name;
            if (this.refs.input_en_name)  // 一开始refs.input_cn_name会是undefine，因此需要做判断
                this.refs.input_en_name.state.value = this.arrLocationsData[this.iSelectedLocationIndex].en_name;
        }
        this.setState({
            ...this.state,
            bShowDlg: true,
            iDlgType: iDlgType
        });
    };
    // 弹窗确定按钮响应
    OKButtonClick = e => {
        let cn_input = this.refs.input_cn_name;
        let en_input = this.refs.input_en_name;
        // 新建地区信息
        if (this.state.iDlgType === 0) {
            const objNewLocation = {
                parent_id: this.arrLocationsData[this.iSelectedLocationIndex].id,
                level: this.iSelectedLocationLevel + 1,
                name: cn_input.state.value, en_name: en_input.state.value,
                latitude_x: 0, longitude_y: 0,
            }
            this.bDataBack = false;
            axios.post('/NewLocation', { objNewLocation })
                .then(
                    ({ data }) => {
                        // DataStatistic
                        UserActiveUpdate(6, { active: "管理后台: 地区管理操作", action: "新建" });
                        this.RefreshLocationTreeStructure(data);
                    }
                );
        }
        // 编辑地区信息
        if (this.state.iDlgType === 1) {
            this.arrLocationsData[this.iSelectedLocationIndex].name = cn_input.state.value;
            this.arrLocationsData[this.iSelectedLocationIndex].en_name = en_input.state.value;
            const objEditLocation = {
                id: this.iSelectedLocationID,
                parent_id: this.arrLocationsData[this.iSelectedLocationIndex].parent_id,
                name: this.arrLocationsData[this.iSelectedLocationIndex].name,
                en_name: this.arrLocationsData[this.iSelectedLocationIndex].en_name,
                latitude_x: this.arrLocationsData[this.iSelectedLocationIndex].latitude_x,
                longitude_y: this.arrLocationsData[this.iSelectedLocationIndex].longitude_y,
            };
            // DataStatistic
            UserActiveUpdate(6, { active: "管理后台: 地区管理操作", action: "编辑" });
            axios.post('/EditLocation', { objEditLocation })
                .then((response) => { console.log(response); });
            global.arrLocationsData = this.OrganizeLocationsData(0, "0");
            this.setState({
                ...this.state,
                bShowDlg: false,
                locationsData: global.arrLocationsData
            });
        };
        // 删除地区信息
        if (this.state.iDlgType === 2) {
            let arrDeleteLocations = [this.iSelectedLocationID];
            this.GetSubNodesID(this.iSelectedLocationID, arrDeleteLocations);
            this.bDataBack = false;
            axios.post('/DeleteLocation', { arrDeleteLocations: arrDeleteLocations })
                .then(({ data }) => {
                    if (data.status === -1) {
                        message.warning("删除地区失败，请先检查并删除该地区下的所有商家！");
                        console.log(data.err);
                        this.bDataBack = true;
                        this.setState({
                            ...this.state,
                            bShowDlg: false
                        });
                    } else {
                        // DataStatistic
                        UserActiveUpdate(6, { active: "管理后台: 地区管理操作", action: "删除" });
                        message.success("地区已删除！");
                        this.RefreshLocationTreeStructure(data.data);
                    }
                });
        }
    };
    // 弹窗取消按钮响应
    CancelButtonClick = e => {
        this.setState({
            ...this.state,
            bShowDlg: false,
        });
    };
    /* 自定义成员函数 */
    // 获去当前选中节点在地区数据数组中的位置
    GetSelectLocationIndex() {
        for (let i = 0; i < this.arrLocationsData.length; i++) {
            if (this.arrLocationsData[i].id === this.iSelectedLocationID) {
                this.iSelectedLocationIndex = i;
                break;
            }
        }
    }
    // 获取某个节点下的所有子节点ID（用于删除）
    GetSubNodesID(parent_id, arrDeleteNodes) {
        for (let i = 0; i < this.arrLocationsData.length; i++) {
            if (this.arrLocationsData[i].parent_id === parent_id) {
                arrDeleteNodes.push(this.arrLocationsData[i].id);
                this.GetSubNodesID(this.arrLocationsData[i].id, arrDeleteNodes);
            }
        }
    }
    // 整理Locations数据
    OrganizeLocationsData(iParentID, strParentKey) {
        let arrCurrentLevelLocations = [];
        let iCurrentLocationKey = 0;
        for (let i = 0; i < this.arrLocationsData.length; i++) {
            if (this.arrLocationsData[i].parent_id === iParentID) {
                let objLocation = {
                    key: strParentKey + "-" + iCurrentLocationKey.toString(),
                    id: this.arrLocationsData[i].id, parent_id: iParentID,
                    level: this.arrLocationsData[i].level,
                    title: "id: " + this.arrLocationsData[i].id.toString() + " - "
                        + this.arrLocationsData[i].name + " - "
                        + this.arrLocationsData[i].en_name,
                    name: this.arrLocationsData[i].name,
                    en_name: this.arrLocationsData[i].en_name,
                    latitude_x: this.arrLocationsData[i].latitude_x,
                    longitude_y: this.arrLocationsData[i].longitude_y,
                    children: []
                };
                let arrChildren = this.OrganizeLocationsData(objLocation.id, objLocation.key);
                objLocation.children = arrChildren;
                arrCurrentLevelLocations.push(objLocation);
                if (objLocation.id === 3) {
                    global.arrVancouverAreaData = [];
                    global.arrVancouverAreaData.push(objLocation);
                }
                iCurrentLocationKey++;
            }
        }
        return arrCurrentLevelLocations;
    }
    // 刷新树形结构数据
    RefreshLocationTreeStructure(data) {
        this.bDataBack = true;
        this.arrLocationsData = data;
        global.arrLocationsData = this.OrganizeLocationsData(0, "0");
        if (this.state.iDlgType === 0) {
            this.setState({
                ...this.state,
                bShowDlg: false,
                expandedKeys: this.strSelectedLocationKey,
                locationsData: global.arrLocationsData
            });
        }
        if (this.state.iDlgType === 1) {
            this.setState({
                ...this.state,
                bShowDlg: false,
                locationsData: global.arrLocationsData
            });
        }
        if (this.state.iDlgType === 2) {
            this.strSelectedLocationKey = "";
            this.strSelectedLocationName = "";
            this.iSelectedLocationIndex = 0;
            this.iSelectedLocationID = 0;
            let selectedKeys = [];
            this.setState({
                ...this.state,
                btnControlDisable: [true, true, true],
                selectedKeys,
                bShowDlg: false,
                locationsData: global.arrLocationsData
            });
        }
    }
    // 从数据库获取当前的分类树形结构
    GetLocationsData() {
        message.loading({ content: '数据加载中……', key: g_strMessageKey });
        this.bDataBack = false;
        // 发送获取分类数据的请求
        axios('/GetLocations', { params: { id: 0 } })
            .then(({ data }) => {
                message.success({ content: "数据加载完成！", key: g_strMessageKey, duration: 2 });
                this.RefreshLocationTreeStructure(data);
            }).catch(function (error) { console.log(error); });
    }
    // 卸载异步操作设置状态
    componentWillUnmount() {
        clearTimeout(this.timeouter)
        this.setState = (state, callback) => {
            return;
        }
    }
    /* 渲染完毕…… (只执行一遍，后续setState引起的render完毕后，不会进入到该函数) */
    componentDidMount() {
        this.GetLocationsData();
    }
    /* React 渲染函数*/
    render() {
        // 如果界面上需要显示的内容已经从数据库获得
        if (this.bDataBack) {
            // 3个操作按钮的样式控制
            let strNewButtonStyle = { backgroundColor: 'lightseagreen', color: 'white' };
            if (this.state.btnControlDisable[0])
                strNewButtonStyle = {};
            // 弹窗控件
            let strDlgTitle = '在 "' + this.strSelectedLocationName + '" 下创建新区域';
            let arrInputValue = ["", ""];
            let arrPlayHolder = ["请输入地区中文名", "请输入地区英文名"];
            if (this.state.iDlgType === 1) {
                strDlgTitle = '编辑地区 "' + this.strSelectedLocationName + '"';
                arrInputValue = [
                    // iSelectedLocation是DB中的id, 用于索引需-1
                    this.arrLocationsData[this.iSelectedLocationIndex].name,
                    this.arrLocationsData[this.iSelectedLocationIndex].en_name
                ];
                arrPlayHolder = ["", ""];
            }
            let uiDialog = (
                <Modal width={300} title={strDlgTitle}
                    visible={this.state.bShowDlg} onOk={this.OKButtonClick} okText="确定"
                    onCancel={this.CancelButtonClick} cancelText="取消">
                    <div className="manage_modal_item">
                        <div className="manage_info_label">中文名</div>
                        <Input className="manage_modal_input" ref="input_cn_name"
                            defaultValue={arrInputValue[0]} placeholder={arrPlayHolder[0]} />
                    </div>
                    <div className="manage_modal_item">
                        <div className="manage_info_label">英文名</div>
                        <Input className="manage_modal_input" ref="input_en_name"
                            defaultValue={arrInputValue[1]} placeholder={arrPlayHolder[1]} />
                    </div>
                </Modal>
            );
            if (this.state.iDlgType === 2) {
                strDlgTitle = '删除地区 "' + this.strSelectedLocationName + '"';
                uiDialog = (
                    <Modal width={350} title={strDlgTitle}
                        visible={this.state.bShowDlg} okType="danger"
                        onOk={this.OKButtonClick} okText="确定"
                        onCancel={this.CancelButtonClick} cancelText="取消">
                        <div className="manage_modal_item">
                            <div>该地区下的所有分区也将一并删除……<br />确定删除该地区？</div>
                        </div>
                    </Modal>
                );
            }
            // 渲染控件主体
            return (
                <div className="manage_contents" style={{ background: 'white' }}>
                    <div className="manage_contents_title">
                        <div className="category_page_title_button">
                            <Button className="manage_contents_title_margin" style={strNewButtonStyle}
                                onClick={this.ShowDlg.bind(this, 0)}
                                disabled={this.state.btnControlDisable[0]}>
                                <Icon type="plus" />新建
                            </Button>
                            <Button className="manage_contents_title_margin" type="primary"
                                onClick={this.ShowDlg.bind(this, 1)}
                                disabled={this.state.btnControlDisable[1]}>
                                <Icon type="edit" /> 编辑
                            </Button>
                            <Button className="manage_contents_title_margin" type="danger"
                                onClick={this.ShowDlg.bind(this, 2)}
                                disabled={this.state.btnControlDisable[2]}>
                                <Icon type="close" /> 删除
                            </Button>
                            {uiDialog}
                        </div>
                    </div>
                    <Tree className="manage_tree_structure" showLine
                        onExpand={this.onExpand} defaultExpandAll={true} defaultExpandParent={true}
                        autoExpandParent={true} onSelect={this.onSelect}
                        selectedKeys={this.state.selectedKeys} >
                        {this.renderTreeNodes(this.state.locationsData)}
                    </Tree>
                </div>
            );
        } else {
            return (<Spin className="manage_empty_loading" size="large" tip="加载中……" />);
        }
    }
}
export default LocationManagement;
