import axios from 'axios';
import cookie from 'react-cookies';

import message from 'antd/es/message';

// import { message } from 'antd';

import Geocode from "react-geocode";

// 检查并获取cookie中的用户当前的位置信息
export const CheckUserLocationFromCookie = (bLocationPage, funcBack) => {
    let strUserLocation = cookie.load('wm_user_location');
    if (strUserLocation !== undefined) {
        if (strUserLocation !== null && strUserLocation.trim() !== "") {
            let position = {
                lat: strUserLocation.split(',')[1],
                lng: strUserLocation.split(',')[2],
            }
            if (bLocationPage)
                GetAddressBasedOnLatLng(position, funcBack);
            else
                funcBack(strUserLocation.split(',')[0], "", position);
        } else {
            cookie.save('wm_user_location', '', { path: '/' });
            strUserLocation = "";
            GetUserLocation(funcBack);
        }
    } else {
        cookie.save('wm_user_location', '', { path: '/' });
        strUserLocation = "";
        GetUserLocation(funcBack);
    }
}
// 获取当前用户的位置
export const GetUserLocation = (funcBack) => {
    // Try HTML5 geolocation.
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function success(pos) {
            // message.info("I get the location!");
            var position = {
                lat: pos.coords.latitude,
                lng: pos.coords.longitude
            };
            GetAddressBasedOnLatLng(position, funcBack);
        }, failure => {
            if (failure.message.startsWith("Only secure origins are allowed")) {
                // Secure Origin issue.
                // message.info("Only secure origins are allowed");
            } else {
                // message.info("Failed to get geolocation!");
            }
            axios.get("https://ipapi.co/json/")  // Another Option: https://api.ipify.org/?format=json https://ipapi.co/json/
                .then((response) => {
                    console.log("get geo :");
                    console.log(response);
                    let position = {
                        lat: response.data.latitude,
                        lng: response.data.longitude
                    };

                    GetAddressBasedOnLatLng(position, funcBack);
                }).catch(err => {
                    console.log(err);
                    let position = {
                        lat: 0,
                        lng: 0
                    };
                    GetAddressBasedOnLatLng(position, funcBack);
                });
        });
    } else {
        message.warning("当前的设备/浏览器不支持定位获取！");
    }
}
// Geocode初始化
export const GeocodeInit = () => {
    // set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
    Geocode.setApiKey("AIzaSyDAJUpZSeP9ZNYOSYakvbGGX2XbGAWF3P0");
    // set response language. Defaults to english.
    Geocode.setLanguage("en");
    // set response region. Its optional.
    // A Geocoding request with region=es (Spain) will return the Spanish city.
    Geocode.setRegion("CA");
    // Enable or disable logs. Its optional.
    Geocode.enableDebug();
}
// 根据经纬度获得地址
export const GetAddressBasedOnLatLng = (position, funcBack) => {
    // Get address from latidude & longitude.
    Geocode.fromLatLng(position.lat.toString(), position.lng.toString())
        .then(response => {
            const address = response.results[0].formatted_address;
            const city = response.results[0].formatted_address.split(',')[1].trim();
            let strLocation = city + "," + position.lat + "," + position.lng + "," + address;
            cookie.save('wm_user_location', strLocation, { path: '/' });
            funcBack(city, address, position);
        }, error => { console.error(error); });
}
// 根据地址获取经纬度信息
export const GetLatLongBasedOnAddress = (iStoreID, strAddress, funcBack) => {
    // Get latidude & longitude from address.
    console.log("GetLatLongBasedOnAddress : " + iStoreID);
    console.log("strAddress = " + strAddress);
    Geocode.fromAddress(strAddress)
        .then(response => {
            if (iStoreID > 0) {
                axios.post('/UpdateStoreGeo', {
                    id: iStoreID,
                    latitude_x: response.results[0].geometry.location.lat,
                    longitude_y: response.results[0].geometry.location.lng
                }).then(({ data }) => {
                    // console.log(data);
                }).catch(err => { console.log(err); });
            } else {
                let position = {
                    lat: response.results[0].geometry.location.lat,
                    lng: response.results[0].geometry.location.lng
                };
                funcBack("", strAddress, position);
            }
        }, error => {
            console.log("GetLatLongBasedOnAddress failed >>> ");
            console.error(error);
        });
}
// 计算两个经纬度的间距(千米)
export const GetLatLongDistance = (lat1, lng1, lat2, lng2) => {
    // 49.1353277, -123.156689  // Home
    // 49.1862473, -123.1333378  // 本宫的茶 Richmond
    var radLat1 = lat1 * Math.PI / 180.0;
    var radLat2 = lat2 * Math.PI / 180.0;
    var a = radLat1 - radLat2;
    var b = lng1 * Math.PI / 180.0 - lng2 * Math.PI / 180.0;
    var s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a / 2), 2) +
        Math.cos(radLat1) * Math.cos(radLat2) * Math.pow(Math.sin(b / 2), 2)));
    s = s * 6378.137;// EARTH_RADIUS;
    s = Math.round(s * 10000) / 10000;
    return s;
}
// 排序辅助功能函数
function sortbyKey(arr, key) {
    if (arr.length > 0) {
        return arr.sort((a, b) => {
            let x = a[key];
            let y = b[key];
            return x - y;
        })
    }
}
// 根据用户当前的位置和商家的位置距离，来排序商家
export const StoreOrderBasedOnDistance = (position, arrStoreList) => {
    // 1. 先计算用户和每个商家的位置
    for (let i = 0; i < arrStoreList.length; i++) {
        let fDistance = GetLatLongDistance(position.lat, position.lng, arrStoreList[i].latitude_x, arrStoreList[i].longitude_y);
        arrStoreList[i].distance = fDistance;
    }
    return sortbyKey(arrStoreList, 'distance')
}
// 根据城市名称获取ID
export let GetCityIDFromName = (funcBack, strCityName) => {
    axios('/GetTargetCity', {
        params: { name: strCityName }
    }).then(({ data }) => {
        funcBack(data[0].id);
    }).catch(function (error) { console.log(error); });
}