import React, { Component } from "react";
import axios from 'axios';
import "../../../styles/Background/UI_Module/store_item_management.css";
// import '../../../styles/Background/UI_Module/store_management.css';
import '../../../styles/Background/store_manager.css';
import '../../../styles/Background/ego_manager.css';
import "../../../styles/Background/UI_Module/store_item_management.css";

import Table from 'antd/es/table';
import Icon from 'antd/es/icon';
import Button from 'antd/es/button';
import Modal from 'antd/es/modal';
import message from 'antd/es/message';
import Input from 'antd/es/input';

// import { Table, Icon, Button, Modal, message } from 'antd';
import { UserActiveUpdate } from "../../../functions/DataStatistic.js"

const g_strMessageKey = 'updating';

global.arrOrdersData = [];

const arrTableHeader = [
    { title: '订单号', dataIndex: 'oid', width: 100, ellipsis: true },
    { title: 'stripe pid', dataIndex: 'pid', width: 150, ellipsis: true },
    { title: '商品', dataIndex: 'item_name' },
    { title: '拼团号/批次', dataIndex: 'cut_price' },
    { title: '备注/物流状态', dataIndex: 'comments' },
    { title: '状态', dataIndex: 'status' },
    { title: '用户编码', dataIndex: 'user_code' },
    { title: '支付金额($CAD)', dataIndex: 'final_price_str' },
    { title: '商品数', dataIndex: 'total_num' },
    { title: '地区', dataIndex: 'area' },
    { title: '下单日期', dataIndex: 'client_order_time' }
];
const arrTableHeaderShort = [
    { title: '订单号', dataIndex: 'oid', width: 70, ellipsis: true },
    { title: '状态', dataIndex: 'status', width: 70, ellipsis: true },
    { title: '用户编码', dataIndex: 'user_code', width: 105, ellipsis: true },
    { title: '联系人', dataIndex: 'contact', width: 100, ellipsis: true },
    { title: '商品', dataIndex: 'item_info', width: 200, ellipsis: true },
    { title: '商品数', dataIndex: 'total_num', width: 65, ellipsis: true },
    { title: '实付金额($CAD)', dataIndex: 'final_price_str', width: 100, ellipsis: true },
    { title: '备注/折扣', dataIndex: 'comments', width: 200, ellipsis: true },
    { title: '下单日期', dataIndex: 'client_order_time', width: 125, ellipsis: true }
];

class StoreOrderManagement extends Component {
    constructor(props) {
        super();
        this.state = {
            btnControlDisable: [true, true],  // 操作按钮控制
            selectedRowKeys: [],  // 选中的行
            bDlgShow: false,  // 是否显示弹窗
            iDlgType: 0,  // 弹窗类别
            dlgInfo: { iOrderStatus: 0 },
            iCurrentPage: 1
        };
        // this.arrOrderStatus = ["未支付", "已支付", "有争议", "已关闭", "已完成", "已退款"];
        this.arrOrderStatus = ["未支付", "已支付", "已接单", "已出单", "已完成", "已取消", "已退款", "售后争议"];
        this.arrTableData = [];
        this.arrOrderData = [];
        this.objCurrentOrder = {};
        this.strDeleteOrderID = "";
        this.arrSelectOrders = [];
    }

    // 选中某行的响应函数
    selectRow = (record) => {
        const selectedRowKeys = [...this.state.selectedRowKeys];
        if (selectedRowKeys.indexOf(record.key) >= 0) {
            selectedRowKeys.splice(selectedRowKeys.indexOf(record.key), 1);
        } else {
            selectedRowKeys.push(record.key);
        }
        this.SelectedRowStateSetting(selectedRowKeys);
    }
    // 选中的行发生变化的响应函数
    onSelectedRowKeysChange = (selectedRowKeys) => {
        this.SelectedRowStateSetting(selectedRowKeys);
    }
    // 处理选中的表行行
    SelectedRowStateSetting = (selectedRowKeys) => {
        this.arrSelectOrders = [];
        let btnControlDisable = [];
        if (selectedRowKeys.length === 1) {
            btnControlDisable = [false, false];
            this.objCurrentOrder = this.arrTableData[selectedRowKeys];
            this.arrSelectOrders.push(this.arrOrderData[selectedRowKeys]);
        } else {
            if (selectedRowKeys.length <= 0) {
                btnControlDisable = [true, true];
                this.objCurrentOrder = {};
            } else {
                btnControlDisable = [true, false];
                for (let i = 0; i < selectedRowKeys.length; i++)
                    this.arrSelectOrders.push(this.arrOrderData[selectedRowKeys[i]]);
            }
        }
        this.setState({
            ...this.state,
            selectedRowKeys,
            btnControlDisable: btnControlDisable
        });
    }
    //显示弹窗前预置编辑的内容
    PresetEditDlg(iSelectedRow) {

    }
    // 获取当前选中行的订单状态
    GetOrderStatus = (iSelectedRow) => {
        let iStatusIndex = 0;
        for (let i = 0; i < this.arrOrderStatus.length; i++) {
            if (this.arrTableData[iSelectedRow].status === this.arrOrderStatus[i]) {
                iStatusIndex = i;
                break;
            }
        }
        return iStatusIndex;
    }
    //弹出对话框
    ShowDlg = (iDlgType) => {
        switch (iDlgType) {
            // 查看订单详情
            case 1: {
                let iSelectedRow = this.state.selectedRowKeys[0];
                this.PresetEditDlg(iSelectedRow);
                this.setState({
                    ...this.state,
                    bDlgShow: true,
                    iDlgType: iDlgType,
                    dlgInfo: {
                        iOrderStatus: this.GetOrderStatus(iSelectedRow)
                    }
                });
                break;
            }
            // 批量编辑备注
            case 2: {
                this.setState({
                    ...this.state,
                    iDlgType: iDlgType,
                    bDlgShow: true,
                });
                break;
            }
            // 单独修改编辑备注
            case 3: {
                if (this.refs.order_comments !== undefined)
                    this.refs.order_comments.state.value = this.objCurrentOrder.comments;
                this.setState({
                    ...this.state,
                    iDlgType: iDlgType,
                    bDlgShow: true,
                });
                break;
            }
            default: break;
        }
    }
    // 获取弹窗中的更新数据
    GetNewInputData() {
        let objInputInfo = {};
        // 从表单中获取
        objInputInfo.status = this.state.dlgInfo.iOrderStatus;
        return objInputInfo;
    }
    // 点击了弹窗里的优惠券状态过滤器
    DlgOrderStatusClicked = (iOrderStatus) => {
        this.setState({
            ...this.state,
            dlgInfo: {
                iOrderStatus: iOrderStatus
            }
        });
    }
    // 确认按钮响应
    ConfirmButtonClick = () => {
        switch (this.state.iDlgType) {
            case 1: {
                // let objEditOrder = this.GetNewInputData();
                // objEditOrder.id = this.arrTableData[this.state.selectedRowKeys[0]].id;
                // message.loading({ content: '正在更新订单信息……', key: g_strMessageKey });
                // axios.post('/EditOrder', { objEditOrder: objEditOrder })
                //     .then(
                //         ({ data }) => {
                //             // DataStatistic
                //             UserActiveUpdate(6, { active: "商家后台: 订单管理操作", action: "编辑" });
                //             message.success({ content: '更新完成！', key: g_strMessageKey, duration: 2 });
                //             this.RefreshOrderTable(data);
                //         }
                //     );
                break;
            }
            case 2: {
                let strComments = this.refs.order_comments.state.value;
                if (strComments.trim() === "") {
                    message.warning("备注/物流信息 不能为空！");
                    return;
                }
                let arrOrders = [];
                for (let i = 0; i < this.arrSelectOrders.length; i++) {
                    arrOrders.push(this.arrSelectOrders[i].oid);
                }
                axios.post('/UpdateOrders', { arrOrders: arrOrders, comments: strComments, store_id: this.props.objStoreInfo.id })
                    .then(({ data }) => {
                        // DataStatistic
                        UserActiveUpdate(6, { active: "商家后台: 订单管理操作", action: "编辑" });
                        message.success({ content: '更新完成！', key: g_strMessageKey, duration: 2 });
                        this.RefreshOrderTable(data);
                    });
                break;
            }
            case 3: {
                if (this.refs.order_comments !== undefined) {
                    this.objCurrentOrder.comments = this.refs.order_comments.state.value;
                }
                axios.post('/UpdateOrderComments', { order: this.objCurrentOrder, store_id: this.props.objStoreInfo.id })
                    .then(({ data }) => {
                        // DataStatistic
                        UserActiveUpdate(6, { active: "商家后台: 订单管理操作", action: "编辑" });
                        message.success({ content: '修改完成！', key: g_strMessageKey, duration: 2 });
                        this.RefreshOrderTable(data);
                    });
                break;
            }
            default: break;
        }
        this.setState({
            ...this.state,
            bDlgShow: false
        });
    }
    // 取消按钮响应
    CancelButtonClick = () => {
        this.setState({
            ...this.state,
            bDlgShow: false
        });
    }
    // 刷新表格中的数据
    RefreshOrderTable(data) {
        this.arrOrderData = data;
        this.arrTableData = [];
        for (let i = 0; i < data.length; i++) {
            let strDateTime = data[i].client_order_time.split('T')[0] + " " + data[i].client_order_time.split('T')[1].split('.')[0];
            let objItemInfo = JSON.parse(data[i].items);
            let strItemInfo = objItemInfo.items[0].name;
            if (strItemInfo.indexOf(',') >= 0)
                strItemInfo = strItemInfo.split(',')[0];
            if (Array.isArray(objItemInfo.items[0].options)) {
                if (objItemInfo.items[0].options.length > 0) {
                    if (objItemInfo.items[0].options[0].name.indexOf(',') >= 0)
                        strItemInfo += "【" + objItemInfo.items[0].options[0].name.split(',')[0] + "】";
                    else
                        strItemInfo += "【" + objItemInfo.items[0].options[0].name + "】";
                }
            }
            if (objItemInfo.items.length > 0) {
                strItemInfo += " 等商品";
            }
            let fDiscount = "";
            if (data[i].comments !== null) {
                if (data[i].comments.indexOf('#$-') >= 0) {
                    fDiscount = data[i].comments.split('#$-')[1];
                    fDiscount = parseFloat(fDiscount) * -1.0;
                }
                if (data[i].comments.indexOf('#$+') >= 0) {
                    fDiscount = data[i].comments.split('#$+')[1];
                    fDiscount = parseFloat(fDiscount);
                }
                fDiscount = parseFloat(fDiscount);
            }
            if (!isNaN(parseInt(fDiscount, 10))) {
                data[i].final_price = parseFloat(data[i].final_price) + fDiscount;
            }
            let objOrderData = {
                id: data[i].id, key: i, pid: data[i].pid, oid: data[i].oid,
                status: this.arrOrderStatus[data[i].status],
                // final_price: "$ CAD " + (data[i].final_price / 100).toFixed(2),
                final_price_str: parseFloat(data[i].final_price).toFixed(2),
                total_num: JSON.parse(data[i].items).total_num,
                user_code: data[i].user_code,
                client_order_time: strDateTime,
                comments: data[i].comments,
                item_info: strItemInfo,
                contact: data[i].contact,
                cut_price: data[i].cut_price_id + "/" + data[i].cut_price_batch,
                area: data[i].area,
                discount: fDiscount
            }
            this.arrTableData.push(objOrderData);
        }
        global.arrOrdersData = this.arrTableData;

        if (this.state.iDlgType === 2) {
            this.setState({
                ...this.state,
                btnControlDisable: [true, true],
                selectedRowKeys: [],
                iDlgType: 2,
                bDlgShow: false
            });
        } else {
            this.setState({
                ...this.state,
                btnControlDisable: [true, true],
                selectedRowKeys: [],
                iDlgType: 0,
                bDlgShow: false
            });
        }
    }
    // 从数据库获取当前的数据结构
    GetOrdersData() {
        message.loading({ content: '数据加载中……', key: g_strMessageKey });
        axios('/GetStoreOrders', { params: { store_id: parseInt(this.props.objStoreInfo.id, 10) } })
            .then(({ data }) => {
                message.success({ content: "数据加载完成！", key: g_strMessageKey, duration: 2 });
                this.RefreshOrderTable(data);
            }).catch(function (error) { console.log(error); });
    }
    // 搜索订单
    OrderSearch = () => {
        let strKeywords = this.refs.store_order_input.state.value;
        if (strKeywords === undefined)
            strKeywords = "";
        if (strKeywords.trim() === "") {
            this.GetOrdersData();
        } else {
            this.setState({
                ...this.state,
                selectedRowKeys: [],
                btnControlDisable: [true, true],  // 操作按钮控制
            });
            axios('/SearchOrders', { params: { name: strKeywords, store_id: this.props.objStoreInfo.id } })
                .then(({ data }) => {
                    this.RefreshOrderTable(data);
                }).catch(function (error) { console.log(error); });
        }
    }

    /* React自带函数 */
    // 卸载异步操作设置状态
    componentWillUnmount() {
        clearTimeout(this.timeouter)
        this.setState = (state, callback) => {
            return;
        }
    }
    // 渲染完毕……
    componentDidMount() {
        this.GetOrdersData();
    }

    render() {

        // 主体表格相关
        const { selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectedRowKeysChange,
            columnWidth: 30
        };

        // 创建对话框
        let objOrder = {
            key: 0, pid: "", oid: "", status: this.arrOrderStatus[0], total_price: 0, total_num: 0, oge_price: 0,
            client_order_time: "", contact: "", phone: "", comments: ""
        }
        let strDlgTitle = "";
        let uiDlg = null;
        let uiItemInfo = null;
        let uiCutPriceInfo = null;
        if (this.state.iDlgType === 1 && this.state.bDlgShow) {
            objOrder = this.arrOrderData[this.state.selectedRowKeys[0]];
            if (objOrder !== undefined) {
                if (objOrder.contact === null || objOrder.contact === "")
                    objOrder.contact = "无";
                if (objOrder.phone === null || objOrder.phone === "")
                    objOrder.phone = "无";
                if (objOrder.comments === null || objOrder.comments === "")
                    objOrder.comments = "无";
                if (objOrder.client_order_time !== null && objOrder.client_order_time !== "" && objOrder.client_order_time.indexOf('T') !== -1)
                    objOrder.client_order_time = objOrder.client_order_time.split('T')[0] + " " + objOrder.client_order_time.split('T')[1].split('.')[0];
            }
            strDlgTitle = "订单详情";
            uiDlg = (
                <Modal title={strDlgTitle} visible={this.state.bDlgShow} footer={null} closable={true} maskClosable={true}
                    onCancel={this.CancelButtonClick}
                // okText="确定" cancelText="取消" onOk={this.CancelButtonClick} 
                >
                    <div className="item_info_row"> {"订单编号：" + objOrder.oid} </div>
                    <div className="item_info_row"> {"用户编码：" + objOrder.user_code} </div>
                    <div className="item_info_row"> {"下单时间：" + objOrder.client_order_time} </div>
                    <div className="item_info_row"> {"实付总额：$ CAD " + (parseFloat(objOrder.final_price)).toFixed(2)} </div>
                    {uiItemInfo}
                    {uiCutPriceInfo}
                    <div className="item_info_row"> {"联系人名：" + objOrder.contact} </div>
                    <div className="item_info_row"> {"联系电话：" + objOrder.phone} </div>
                    <div className="item_info_row"> {"寄送地址：" + objOrder.address} </div>
                    <div className="item_info_row">
                        {"备注信息：" + objOrder.comments}
                        <div className="item_info_modify_button" onClick={this.ShowDlg.bind(this, 3)}>修 改</div>
                    </div>
                </Modal>
            );
        }
        if (this.props.objStoreInfo.id === 10005 && this.state.bDlgShow && this.state.iDlgType === 1) {
            let objItem = JSON.parse(objOrder.items);
            uiItemInfo = (
                <div className="item_info_row">
                    {"商品信息：" + objItem.items[0].name + " x " + objItem.total_num + " 盒, 商品ID = " + objItem.items[0].id}
                </div>
            );
            uiCutPriceInfo = (
                <div className="item_info_row">
                    {"拼单信息：" + objOrder.cut_price_id + "/" + objOrder.cut_price_batch}
                </div>
            );
            uiDlg = (
                <Modal title={strDlgTitle} visible={this.state.bDlgShow} footer={null} closable={true}
                    maskClosable={true} onCancel={this.CancelButtonClick}
                // okText="确定" cancelText="取消" onOk={this.CancelButtonClick} 
                >
                    <div className="item_info_row"> {"订单编号：" + objOrder.oid} </div>
                    <div className="item_info_row">
                        {"Stipre单号：" + objOrder.pid}
                        {/* <div className="order_pid_copy_button">
                            <Icon type="copy" />
                        </div> */}
                    </div>
                    <div className="item_info_row"> {"用户编码：" + objOrder.user_code} </div>
                    <div className="item_info_row"> {"下单时间：" + objOrder.client_order_time} </div>
                    <div className="item_info_row"> {"支付总额：$ CAD " + (parseFloat(objOrder.final_price) / 100).toFixed(2)} </div>
                    {uiItemInfo}
                    {uiCutPriceInfo}
                    <div className="item_info_row"> {"联系人名：" + objOrder.contact} </div>
                    <div className="item_info_row"> {"联系电话：" + objOrder.phone} </div>
                    <div className="item_info_row"> {"寄送地址：" + objOrder.address} </div>
                    <div className="item_info_row"> {"备注信息：" + objOrder.comments} </div>
                </Modal>
            );
        }
        if (this.state.iDlgType === 2 && this.state.bDlgShow) {
            strDlgTitle = "批量编辑 备注/物流状态";
            uiDlg = (
                <Modal title={strDlgTitle} visible={this.state.bDlgShow} okText="确定" cancelText="取消"
                    onOk={this.ConfirmButtonClick} onCancel={this.CancelButtonClick} >
                    <div className="item_info_row">{"正在修改订单 " + this.arrSelectOrders[0].oid + " 的如下信息："}</div>
                    <div className="item_info_row">
                        {"备注/物流状态："}
                        <Input className="store_order_input" ref="order_comments" />
                    </div>
                </Modal>
            );
        }
        if (this.state.iDlgType === 3 && this.state.bDlgShow) {
            strDlgTitle = "编辑 备注/折扣信息";
            uiDlg = (
                <Modal title={strDlgTitle} visible={this.state.bDlgShow} okText="确定" cancelText="取消"
                    onOk={this.ConfirmButtonClick} onCancel={this.CancelButtonClick} >
                    <div className="item_info_row">{"正在修改 " + this.arrSelectOrders[0].oid + " 等订单信息："}</div>
                    <div className="item_info_row">
                        {"备注/折扣："} <Input className="store_order_input" ref="order_comments" defaultValue={this.arrSelectOrders[0].comments} />
                    </div>
                </Modal>
            );
        }

        // 表格分页属性
        const paginationProps = {
            showSizeChanger: true,
            showQuickJumper: false,
            showTotal: () => `共 ${this.arrTableData.length} 笔订单`,
            defaultPageSize: 10,
            defaultCurrent: this.state.iCurrentPage,
            pageSizeOptions: ['10', '20', '30', '40', '50'],
            total: this.arrTableData.length,
            position: 'bottom'
        };

        let arrColumn = arrTableHeader;
        if (this.props.objStoreInfo.type === 1 || this.props.objStoreInfo.type === 3)
            arrColumn = arrTableHeaderShort;

        return (
            <div className="store_manage_contents store_manage_top_space">
                {/* 订单管理页面控制栏 */}
                <div className="store_contents_title">
                    <Button className="manage_contents_title_margin" type="primary" onClick={this.ShowDlg.bind(this, 1)} disabled={this.state.btnControlDisable[0]}>
                        <Icon type="eye" />查看详情</Button>
                    <Button className="manage_contents_title_margin" type="primary" onClick={this.ShowDlg.bind(this, 2)} disabled={this.state.btnControlDisable[1]}>
                        <Icon type="edit" />批量修改</Button>
                    <Input className="store_order_input" placeholder="订单号 或 拼团号/批次" ref="store_order_input" />
                    <Button className="manage_stretch_left" type="primary"
                        onClick={this.OrderSearch}>搜索</Button>
                    <Button className="manage_stretch_left manage_refresh_button" type="primary"
                        onClick={this.GetOrdersData.bind(this)}>
                        <Icon type="redo" />
                    </Button>
                </div>
                {/* 表格主体 */}
                <Table style={{ marginLeft: '20px', marginRight: '20px', width: '95%', minWidth: '95%', overflowX: 'auto' }} pagination={paginationProps}
                    rowSelection={rowSelection} columns={arrColumn} bordered
                    dataSource={this.arrTableData}
                    onRow={(record) => ({ onClick: () => { this.selectRow(record); } })} />
                {/* 弹窗 */}
                {uiDlg}
            </div>
        );
    }
}
export default StoreOrderManagement;
