import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';

import Spin from 'antd/es/spin';
import Table from 'antd/es/table';
import Button from 'antd/es/button';
import Input from 'antd/es/input';
import Icon from 'antd/es/icon';
import Modal from 'antd/es/modal';
import DatePicker from 'antd/es/date-picker';
import message from 'antd/es/message';

import '../../../styles/Background/ego_manager.css';
import '../../../styles/Background/UI_Module/user_management.css';
import '../../../styles/Background/UI_Module/transit_price.css';

const g_strMessageKey = 'updating';

// 表格
const arrTableHeader = [
    { title: '船期', dataIndex: 'transit_date', width: 180 },
    { title: '更新时间', dataIndex: 'update_date', width: 180 },
    { title: '备注', dataIndex: 'comments', width: 180 }
];

class TransitDateManager extends Component {
    constructor(props) {
        super();

        this.strToday = new Date();

        this.state = {
            btnControlDisable: [false, true, true],  // 操作按钮控制
            selectedRowKeys: [],  // Check here to configure the default column
            iDlgType: -1,
            bShowDlg: false,
            bLoading: true,
            transit_date: new Date()
        };
        // 成员变量
        this.arrTableData = [];  // 用于表格的数组
        this.objCurrentTransitDate = {};
        this.tableHead = arrTableHeader;
        this.strTransitDate = this.strToday.toISOString();
    }

    // 选中某行的响应函数
    selectRow = (record) => {
        const selectedRowKeys = [...this.state.selectedRowKeys];
        if (selectedRowKeys.indexOf(record.key) >= 0) {
            selectedRowKeys.splice(selectedRowKeys.indexOf(record.key), 1);
        } else {
            selectedRowKeys.push(record.key);
        }
        this.SelectedRowStateSetting(selectedRowKeys);
    }
    // 选中的行发生变化的响应函数
    onSelectedRowKeysChange = (selectedRowKeys) => {
        this.SelectedRowStateSetting(selectedRowKeys);
    }
    // 处理选中的表行行
    SelectedRowStateSetting = (selectedRowKeys) => {
        let btnControlDisable = [];
        switch (selectedRowKeys.length) {
            case 0: {
                btnControlDisable = [false, true, true];
                this.objCurrentTransitDate = {};
                break;
            }
            case 1: {
                btnControlDisable = [false, false, false];
                for (let i = 0; i < this.arrTableData.length; i++) {
                    if (this.arrTableData[i].key === selectedRowKeys[0]) {
                        this.objCurrentTransitDate = this.arrTableData[i];
                        break;
                    }
                }
                break;
            }
            default: {
                btnControlDisable = [false, true, true]; break;
            }
        }
        this.setState({
            ...this.state,
            selectedRowKeys,
            btnControlDisable: btnControlDisable
        });
    }
    //弹出对话框
    ShowDlg = (iDlgType, e) => {
        if (iDlgType === 0) {
            if (this.refs.comments !== undefined)
                this.refs.comments.state.value = "";
            this.objCurrentTransitDate = { store_id: this.props.iStoreID, comments: '' };
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true,
                transit_date: this.strTransitDate
            });
        }
        if (iDlgType === 1) {
            if (this.refs.comments !== undefined)
                this.refs.comments.state.value = this.objCurrentTransitDate.comments;
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true,
                transit_date: this.objCurrentTransitDate.transit_date
            });
        }

        if (iDlgType === 2) {
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true
            });
        }
    }
    // 弹窗确认按钮响应
    handleOk = e => {
        delete this.objCurrentTransitDate.key;
        if (this.state.iDlgType === 0) {
            delete this.objCurrentTransitDate.id;
            this.objCurrentTransitDate.transit_date = this.state.transit_date;
            this.objCurrentTransitDate.update_date = new Date();
            this.objCurrentTransitDate.store_id = this.props.iStoreID;
            this.objCurrentTransitDate.comments = this.refs.comments.state.value;
            console.log("new date: ", this.objCurrentTransitDate);
            message.loading({ content: '正在更新船期信息……', key: g_strMessageKey });
            axios.post('/NewTransitDate', {
                objTransitDate: this.objCurrentTransitDate
            }).then(({ data }) => {
                this.GetTransitDate();
            }).catch(function (error) { console.log(error); });
        }
        if (this.state.iDlgType === 1) {
            delete this.objCurrentTransitDate.key;
            this.objCurrentTransitDate.transit_date = this.state.transit_date;
            this.objCurrentTransitDate.update_date = new Date();
            this.objCurrentTransitDate.store_id = this.props.iStoreID;
            this.objCurrentTransitDate.comments = this.refs.comments.state.value;
            console.log("edit date: ", this.objCurrentTransitDate);
            message.loading({ content: '正在更新船期信息……', key: g_strMessageKey });
            axios.post('/UpdateTransitDate', {
                objTransitDate: this.objCurrentTransitDate
            }).then(({ data }) => {
                message.success({ content: "船期已更新！", key: g_strMessageKey, duration: 2 });
                this.GetTransitDate();
            }).catch(function (error) { console.log(error); });
        }
        if (this.state.iDlgType === 2) {
            message.loading({ content: '船期删除中……', key: g_strMessageKey });
            axios.post('/DeleteTransitDate', { id: this.objCurrentTransitDate.id }).then(
                ({ data }) => {
                    this.GetTransitDate();
                }).catch(function (error) { console.log(error); });
        }
    };
    // 弹窗取消按钮响应
    handleCancel = e => {
        this.objCurrentTransitDate = {};
        this.setState({
            bShowDlg: false,
            selectedRowKeys: [],
            btnControlDisable: [false, true, true]
        });
    };
    // 从数据库获取当前的数据结构
    GetTransitDate() {
        message.loading({ content: '数据加载中……', key: g_strMessageKey });
        axios('/GetTransitDate', { params: { store_id: this.props.iStoreID } })
            .then(({ data }) => {
                console.log("Get Transit Date : ", data);
                this.arrTableData = data;
                if (this.arrTableData.length > 0) {
                    for (let i = 0; i < this.arrTableData.length; i++) {
                        this.arrTableData[i].key = i;
                        if (this.arrTableData[i].transit_date !== null)
                            this.arrTableData[i].transit_date = this.arrTableData[i].transit_date.split('T')[0];
                        else
                            this.arrTableData[i].transit_date = this.strTransitDate.toISOString().split('T')[0];
                        if (this.arrTableData[i].update_date !== null)
                            this.arrTableData[i].update_date = this.arrTableData[i].update_date.split('T')[0];
                        else
                            this.arrTableData[i].update_date = this.strTransitDate.toISOString().split('T')[0];
                        this.arrTableData[i].comments = this.arrTableData[i].comments;
                    }
                }
                if (this.state.bShowDlg) {
                    if (this.state.iDlgType === 0) {
                        message.success({ content: "新增船期成功！", key: g_strMessageKey, duration: 2 });
                    }
                    if (this.state.iDlgType === 1) {
                        message.success({ content: "船期已更新！", key: g_strMessageKey, duration: 2 });
                    }
                    if (this.state.iDlgType === 2) {
                        message.success({ content: "船期已删除！", key: g_strMessageKey, duration: 2 })
                    }
                } else {
                    message.success({ content: "数据加载完成！", key: g_strMessageKey, duration: 2 });
                }
                this.objCurrentTransitDate = {};
                this.setState({
                    ...this.state,
                    btnControlDisable: [false, true, true],
                    bLoading: false,
                    bShowDlg: false,
                    selectedRowKeys: [],
                });

            }).catch(function (error) { console.log(error); });
    }

    // 到期日期更新
    TransitDateChange = (date, dateString) => {
        this.setState({
            ...this.state,
            transit_date: dateString
        });
    }

    /* React自带函数 */
    // 卸载异步操作设置状态
    componentWillUnmount() {
        clearTimeout(this.timeouter)
        this.setState = (state, callback) => {
            return;
        }
    }
    // 渲染完毕……
    componentDidMount() {
        this.GetTransitDate();
    }
    // 渲染函数
    render() {

        /* 用户管理页面控制栏 */
        let strNewButtonStyle = { backgroundColor: 'lightseagreen', color: 'white' };
        let strAreaStyle = "store_manage_contents store_manage_top_no_space";
        let strLoadingStyle = "manage_empty_loading";
        let uiTitleArea = null;

        if (this.props.iUserLevel >= 9) {
            strAreaStyle = "store_manage_contents store_manage_top_space";
            uiTitleArea = (
                <div>
                    <div className="store_contents_title" >
                        <Button className="manage_contents_title_margin" style={strNewButtonStyle}
                            onClick={this.ShowDlg.bind(this, 0)} disabled={this.state.btnControlDisable[0]}>
                            <Icon type="plus" />新建</Button>
                        <Button className="manage_contents_title_margin" type="primary" onClick={this.ShowDlg.bind(this, 1)} disabled={this.state.btnControlDisable[1]}>
                            <Icon type="edit" /> 编辑</Button>
                        <Button className="manage_contents_title_margin" type="danger" onClick={this.ShowDlg.bind(this, 2)} disabled={this.state.btnControlDisable[2]}>
                            <Icon type="close" /> 删除</Button>
                    </div>
                </div>
            );
        }

        // M站针对加载状态时的界面处理
        if (this.props.bMStation) {
            uiTitleArea = null;
            strLoadingStyle = "manage_empty_loading_m";
        }

        // 如果界面上需要显示的内容已经从数据库获得
        if (!this.state.bLoading) {
            // 主体表格相关
            const { selectedRowKeys } = this.state;
            const rowSelection = {
                selectedRowKeys,
                onChange: this.onSelectedRowKeysChange,
            };
            // 表格分页属性
            const paginationProps = {
                showSizeChanger: true,
                showQuickJumper: false,
                showTotal: () => `共 ${this.arrTableData.length} 条 船期信息`,
                defaultPageSize: 10,
                defaultCurrent: this.state.iCurrentPage,
                pageSizeOptions: ['10', '20', '30', '40', '50'],
                total: this.arrTableData.length,
                position: 'bottom'
            };
            // 用户信息编辑弹窗
            let strTitle = "新建船期";
            if (this.state.iDlgType === 1)
                strTitle = "编辑船期";

            const dateFormat = 'YYYY/MM/DD';
            let uiModal = (
                <Modal title={strTitle} width={500} visible={this.state.bShowDlg}
                    okText="确定" cancelText="取消" onOk={this.handleOk} onCancel={this.handleCancel} >
                    <div className="user_edit_info_row">
                        <div className="transit_price_edit_title">船期：</div>
                        <DatePicker className="user_edit_info_input_long" onChange={this.TransitDateChange}
                            value={moment(this.state.transit_date, dateFormat)}
                            defaultValue={moment(this.strTransitDate, dateFormat)} />
                    </div>
                    <div className="user_edit_info_row">
                        <div className="transit_price_edit_title">备注：</div>
                        <Input className="user_edit_info_input_long" ref="comments" defaultValue={this.objCurrentTransitDate.comments} />
                    </div>
                </Modal>
            );
            if (this.state.iDlgType === 2) {
                uiModal = (
                    <Modal width={350} title={"删除船期"} visible={this.state.bShowDlg} okType="danger"
                        onOk={this.handleOk} okText="确定" onCancel={this.handleCancel} cancelText="取消">
                        {"确定删除选中的船期？"}
                    </Modal>
                );
            }

            let uiTable = (
                <Table style={{ marginLeft: '20px', marginRight: '20px', width: '50%', overflowX: 'auto' }}
                    rowSelection={rowSelection} pagination={paginationProps}
                    columns={this.tableHead} dataSource={this.arrTableData} bordered
                    onRow={(record) => ({ onClick: () => { this.selectRow(record); } })} />
            );



            return (
                <div className={strAreaStyle}>
                    {uiTitleArea}
                    {uiModal}
                    {uiTable}
                </div >
            );

        } else {
            return (
                <div className={strAreaStyle}>
                    {uiTitleArea}
                    <Spin className={strLoadingStyle} size="large" tip="加载中……" />
                </div>
            );
        }
    }
}
export default TransitDateManager;
