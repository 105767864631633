import React, { Component } from 'react';
import axios from 'axios';

import Spin from 'antd/es/spin';
import Table from 'antd/es/table';
import Button from 'antd/es/button';
import Input from 'antd/es/input';
import Icon from 'antd/es/icon';
import Modal from 'antd/es/modal';
import message from 'antd/es/message';
import '../../../styles/Background/ego_manager.css';
import '../../../styles/Background/UI_Module/user_management.css';

const g_strMessageKey = 'updating';

// 表格
const arrTableHeader = [
    { title: 'id', dataIndex: 'id', width: 35, ellipsis: true },
    { title: '中通单号', dataIndex: 'express_order', width: 75, ellipsis: true },
    { title: '运单号', dataIndex: 'oid', width: 75, ellipsis: true },
    { title: '客户', dataIndex: 'client', width: 75, ellipsis: true },
    { title: '创建日期', dataIndex: 'create_date_str', width: 100, ellipsis: true },
    { title: '备注', dataIndex: 'comments', width: 300, ellipsis: true },
];

class JetonOrder extends Component {
    constructor(props) {
        super();
        this.state = {
            btnControlDisable: [false, true, true],  // 操作按钮控制
            selectedRowKeys: [],  // Check here to configure the default column
            iDlgType: -1,
            bShowDlg: false,
            bLoading: true
        };
        // 成员变量
        this.arrOgeTableData = [];
        this.arrTableData = [];  // 用于表格的数组
        this.arrSelectedJetonOrderID = [];
        this.arrSelectedJetonOrderIndex = [];
        this.objCurrentJetonOrder = {};
    }

    // 选中某行的响应函数
    selectRow = (record) => {
        const selectedRowKeys = [...this.state.selectedRowKeys];
        if (selectedRowKeys.indexOf(record.key) >= 0) {
            selectedRowKeys.splice(selectedRowKeys.indexOf(record.key), 1);
        } else {
            selectedRowKeys.push(record.key);
        }
        this.SelectedRowStateSetting(selectedRowKeys);
    }
    // 选中的行发生变化的响应函数
    onSelectedRowKeysChange = (selectedRowKeys) => {
        this.SelectedRowStateSetting(selectedRowKeys);
    }
    // 处理选中的表行行
    SelectedRowStateSetting = (selectedRowKeys) => {
        let btnControlDisable = [];
        let btnCopyPasteDisable = [];
        switch (selectedRowKeys.length) {
            case 0: {
                btnControlDisable = [false, true, true];
                btnCopyPasteDisable = [true, true];
                this.objCurrentJetonOrder = {};
                break;
            }
            case 1: {
                btnControlDisable = [false, false, false];
                btnCopyPasteDisable = [false, true];
                for (let i = 0; i < this.arrTableData.length; i++) {
                    if (this.arrTableData[i].key === selectedRowKeys[0]) {
                        this.objCurrentJetonOrder = this.arrTableData[i];
                        break;
                    }
                }
                break;
            }
            default: {
                btnControlDisable = [false, true, true]; btnCopyPasteDisable = [true, true]; break;
            }
        }
        this.setState({
            ...this.state,
            selectedRowKeys,
            btnControlDisable: btnControlDisable,
            btnCopyPasteDisable: btnCopyPasteDisable
        });
    }
    // 关键词处理
    KeywordsProcess = (strKeywords) => {
        let newKeywords = "";
        for (let i = 0; i < strKeywords.length; i++) {
            if (strKeywords[i] !== " ") {
                if (i === strKeywords.length - 1)
                    newKeywords += strKeywords[i];
                else
                    newKeywords += strKeywords[i] + "%";
            }
        }
        return newKeywords;
    }
    // 搜索运单名
    JetonOrderSearch = () => {
        let strKeywords = this.refs.express_order_search.state.value;
        if (strKeywords === undefined)
            strKeywords = "";
        if (strKeywords.trim() === "") {
            this.GetJetonOrdersData();
        } else {
            this.setState({
                ...this.state,
                bLoading: true,
                selectedRowKeys: [],
                btnControlDisable: [false, true, true]
            });
            axios('/SearchJetonOrder', { params: { name: this.KeywordsProcess(strKeywords) } })
                .then(({ data }) => {
                    this.arrTableData = this.OrganizeJetonOrdersData(data);
                    this.setState({
                        ...this.state,
                        bLoading: false
                    });
                }).catch(function (error) { console.log(error); });
        }
    }
    /* 自定义成员函数 */
    //弹出对话框
    ShowDlg = (iDlgType, e) => {
        if (iDlgType === 0) {
            if (this.refs.express_order !== undefined) {
                this.refs.express_order.state.value = "";
                this.refs.oid.state.value = "";
                this.refs.client.state.value = "";
                this.refs.comments.state.value = "";
            }
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true
            });
        }
        if (iDlgType === 1) {
            if (this.refs.express_order !== undefined) {
                this.refs.express_order.state.value = this.objCurrentJetonOrder.express_order;
                this.refs.oid.state.value = this.objCurrentJetonOrder.oid;
                this.refs.client.state.value = this.objCurrentJetonOrder.client;
                this.refs.comments.state.value = this.objCurrentJetonOrder.comments;
            }
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true
            });
        }
        if (iDlgType === 2) {
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true
            });
        }
    }
    // 弹窗确认按钮响应
    handleOk = e => {
        if (this.state.iDlgType <= 1) {

            let objJetonOrder = {
                store_id: 99999,
                express_order: this.refs.express_order.state.value,
                oid: this.refs.oid.state.value,
                client: this.refs.client.state.value,
                comments: this.refs.comments.state.value,
                create_time: new Date(),
                update_time: new Date()
            }
            if (this.state.iDlgType === 1) {
                objJetonOrder.id = this.objCurrentJetonOrder.id;
                objJetonOrder.create_time = this.objCurrentJetonOrder.create_time;
            }

            message.loading({ content: '正在更新运单信息……', key: g_strMessageKey });
            axios.post('/UpdateJetonOrder', {
                objJetonOrder: objJetonOrder
            }).then(({ data }) => {
                if (data.status === 1) {
                    this.GetJetonOrdersData();
                }
                else
                    message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
            }).catch(function (error) { console.log(error); });
        }
        if (this.state.iDlgType === 2) {
            message.loading({ content: '运单删除中……', key: g_strMessageKey });
            axios.post('/DeleteJetonOrder', { id: this.objCurrentJetonOrder.id }).then(
                ({ data }) => {
                    if (data.status === -1) {
                        message.warning({
                            content: "删除失败，请重新尝试！",
                            key: g_strMessageKey, duration: 2
                        });
                        this.setState({
                            ...this.state,
                            bLoading: false,
                            bShowDlg: false,
                            selectedRowKeys: []
                        });
                    } else {
                        this.GetJetonOrdersData();
                    }
                }).catch(function (error) { console.log(error); });
        }
    };
    // 弹窗取消按钮响应
    handleCancel = e => {
        this.setState({
            bShowDlg: false,
            selectedRowKeys: [],
            btnControlDisable: [false, true, true],  // 操作按钮控制
        });
    };
    // 整理JetonOrders数据
    OrganizeJetonOrdersData(data) {
        let arrTableData = [];
        for (let i = 0; i < data.length; i++) {

            let objJetonOrderData = {
                key: i, id: data[i].id,
                express_order: data[i].express_order,
                oid: data[i].oid,
                client: data[i].client,
                create_date_str: data[i].create_time.split('T')[0],
                comments: data[i].comments
            }

            arrTableData.push(objJetonOrderData);
        }
        return arrTableData;
    }
    // 从数据库获取当前的数据结构
    GetJetonOrdersData() {
        message.loading({ content: '数据加载中……', key: g_strMessageKey });
        axios('/GetJetonOrders')
            .then(({ data }) => {
                this.arrOgeTableData = this.OrganizeJetonOrdersData(data);
                this.arrTableData = this.arrOgeTableData;
                if (this.state.bShowDlg) {
                    if (this.state.iDlgType === 0) {
                        message.success({ content: "运单创建成功！", key: g_strMessageKey, duration: 2 });
                    }
                    if (this.state.iDlgType === 1) {
                        message.success({ content: "运单信息已更新！", key: g_strMessageKey, duration: 2 });
                    }
                    if (this.state.iDlgType === 2) {
                        message.success({ content: "运单已删除！", key: g_strMessageKey, duration: 2 })
                    }
                } else {
                    message.success({ content: "数据加载完成！", key: g_strMessageKey, duration: 2 });
                }
                this.setState({
                    ...this.state,
                    bLoading: false,
                    bShowDlg: false,
                    selectedRowKeys: [],
                    btnControlDisable: [false, true, true],  // 操作按钮控制
                });
            }).catch(function (error) { console.log(error); });
    }
    // 显示全部
    ShowAllOrders = () => {
        this.refs.express_order_search.state.value = "";
        this.GetJetonOrdersData();
    }

    /* React自带函数 */
    // 卸载异步操作设置状态
    componentWillUnmount() {
        clearTimeout(this.timeouter)
        this.setState = (state, callback) => {
            return;
        }
    }
    // 渲染完毕……
    componentDidMount() {
        this.GetJetonOrdersData();
    }
    // 渲染函数
    render() {


        /* 运单管理页面控制栏 */
        let uiTitleArea = (
            < div className="db_manager_contents_title" >
                <div className="manage_stretch_right">
                    <Button className="manage_contents_title_margin" style={{ marginRight: '20px' }}
                        type="primary" onClick={this.ShowAllOrders}>显示全部
                    </Button>
                    <Input placeholder="搜索运单名/代码" ref="express_order_search" defaultValue={this.state.strKeywords} />
                    <Button className="manage_contents_title_margin"
                        type="primary" onClick={this.JetonOrderSearch}>搜索
                    </Button>
                </div>

                <div className="manage_title_button_right_side">
                    <Button className="manage_contents_title_margin" type="primary" onClick={this.ShowDlg.bind(this, 0)} disabled={this.state.btnControlDisable[0]}>
                        <Icon type="edit" /> 新建</Button>
                    <Button className="manage_contents_title_margin" type="primary" onClick={this.ShowDlg.bind(this, 1)} disabled={this.state.btnControlDisable[1]}>
                        <Icon type="edit" /> 编辑</Button>
                    <Button className="manage_contents_title_margin" type="danger" onClick={this.ShowDlg.bind(this, 2)} disabled={this.state.btnControlDisable[2]}>
                        <Icon type="close" /> 删除</Button>
                </div>
            </div >
        );

        // 如果界面上需要显示的内容已经从数据库获得
        if (!this.state.bLoading) {
            // 主体表格相关
            const { selectedRowKeys } = this.state;
            const rowSelection = {
                selectedRowKeys,
                onChange: this.onSelectedRowKeysChange,
                columnWidth: 25
            };
            // 表格分页属性
            const paginationProps = {
                showSizeChanger: true,
                showQuickJumper: false,
                showTotal: () => `共 ${this.arrTableData.length} 个 运单`,
                defaultPageSize: 10,
                defaultCurrent: this.state.iCurrentPage,
                pageSizeOptions: ['10', '20', '50', '100'],
                total: this.arrTableData.length,
                position: 'bottom'
            };
            // 运单信息编辑弹窗
            let uiModal = (
                <Modal title="编辑运单" width={925} visible={this.state.bShowDlg}
                    okText="确定" cancelText="取消" onOk={this.handleOk} onCancel={this.handleCancel} >
                    <div className="user_edit_info_row">
                        <div className="user_edit_info_title" style={{ width: '75px' }}>中通单号</div>
                        <Input className="user_edit_info_input" ref="express_order"
                            defaultValue={this.objCurrentJetonOrder.express_order} />
                        <div className="user_edit_info_title" style={{ marginLeft: '15px', width: '75px' }}>运单编号</div>
                        <Input className="user_edit_info_input" ref="oid"
                            defaultValue={this.objCurrentJetonOrder.oid} />
                        <div className="user_edit_info_title" style={{ marginLeft: '15px', width: '75px' }}>客户</div>
                        <Input className="user_edit_info_input" ref="client"
                            defaultValue={this.objCurrentJetonOrder.client} />
                    </div>
                    <div className="user_edit_info_row">
                        <div className="user_edit_info_title" style={{ width: '75px' }}>备注：</div>
                        <Input className="user_edit_info_input" ref="comments" style={{ width: '100%' }}
                            defaultValue={this.objCurrentJetonOrder.comments} />
                    </div>
                </Modal>
            );
            if (this.state.iDlgType === 2) {
                uiModal = (
                    <Modal width={500} title={"删除运单"} visible={this.state.bShowDlg} okType="danger"
                        onOk={this.handleOk} okText="确定"
                        onCancel={this.handleCancel} cancelText="取消">
                        <div className="modal_item">
                            <div>{"运单：" + this.objCurrentJetonOrder.express_order + ", 客户" + this.objCurrentJetonOrder.client}
                                <br /><br />确定删除选中的运单？
                            </div>
                        </div>
                    </Modal>
                );
            }

            return (
                <div className="store_manage_contents store_manage_top_space">
                    {uiTitleArea}
                    {uiModal}
                    {/* 表格主体 */}
                    <Table style={{ margin: '10px', width: '97.5%', overflowX: 'auto' }}
                        rowSelection={rowSelection} pagination={paginationProps}
                        columns={arrTableHeader} dataSource={this.arrTableData} bordered
                        onRow={(record) => ({ onClick: () => { this.selectRow(record); } })} />
                </div >
            );
        } else {
            return (
                <div className="store_manage_contents store_manage_top_no_space">
                    {uiTitleArea}
                    <Spin className="manage_empty_loading" size="large" tip="加载中……" />
                </div>
            );
        }
    }
}
export default JetonOrder;
