import React, { Component } from 'react';
import axios from 'axios';

import Spin from 'antd/es/spin';
import Table from 'antd/es/table';
import Button from 'antd/es/button';
import Input from 'antd/es/input';
import Menu from 'antd/es/menu';
import Dropdown from 'antd/es/dropdown';
import Icon from 'antd/es/icon';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Modal from 'antd/es/modal';
import Select from 'antd/es/select';
import message from 'antd/es/message';

import '../../../styles/Background/ego_manager.css';
// import '../../../styles/Background/UI_Module/store_management.css';
import { UserActiveUpdate } from "../../../functions/DataStatistic.js"
import { GetLatLongBasedOnAddress } from "../../../functions/PositionProcess.js"

const g_strMessageKey = 'updating';
const { Option } = Select;
const { TextArea } = Input;

// 表格
const TableColumns = [
    { title: 'id', dataIndex: 'store_id' },
    { title: '店名', dataIndex: 'store_name' },
    { title: '类别', dataIndex: 'store_type' },
    { title: '主分类', dataIndex: 'main_category' },
    { title: '子分类', dataIndex: 'sub_category' },
    { title: '地区', dataIndex: 'location' },
    { title: '联系人', dataIndex: 'contact_name' },
    { title: '代理', dataIndex: 'proxy_name' },
    { title: '入驻时间', dataIndex: 'register_date' },
    { title: '更新时间', dataIndex: 'update_date' },
    { title: '状态', dataIndex: 'status' }
];
let g_arrTableData = [];
let objEmptyStoreTableRow = {
    store_name: '', proxy_name: '', contact_name: '', address: '',
    main_category: 0, sub_category: 0, location: 0, mall: '',
    email: '', cell_phone: '', phone: '', wechat: '', qq: '',
    description: '', comment: '',
    register_date: '', update_date: '', status: ''
};

class StoreManagement extends Component {
    constructor(props) {
        super();
        this.state = {
            btnControlDisable: [false, true, true],  // 操作按钮控制
            btnCopyPasteDisable: [true, true],  // 赋值粘贴按钮
            selectedRowKeys: [],  // 选中的行
            objFilter: {
                strKeyword: "", iCurrentMainCategory: 0, iCurrentSubCategory: 0,
                iCurrentLocation: 0, iCurrentStoreStatus: 0
                // iCurrentSortHot: 0, iCurrentSortRegistTime: 0
            },
            iDlgType: -1,
            dlgInfo: { iMainCategory: 0, iSubCategory: 0, iLocation: 0, iStatus: 0, iStoreType: 0 },
            bShowDlg: false,
            input_store_name: '',
            storesData: g_arrTableData,
            arrSelectedTypes: []
        };
        // 成员变量
        this.bDataBack = false;
        this.arrStoresData = [];
        this.arrFilterStoreStatus = ['全部', "审核中", "运营中", "已打样", "下架了"];
        this.arrSortHot = ['热度 ↓', '热度 ↑'];
        this.arrSortRegistTime = ['入驻时间 ↓', '入驻时间 ↑'];
        this.arrStatus = ["审核中", "运营中", "已打样", "下架了"];
        this.arrSelectedStoreID = [];
        this.arrSelectedStoreIndex = [];
        // 根据全局变量来设置相关的列表参数
        this.arrSetMainCategory = ['全部'];
        this.arrSetSubCategory = [['全部']];
        let objAllCategories = global.arrCategoriesData[0];
        for (let i = 0; i < objAllCategories.children.length; i++) {
            let objMainCategory = objAllCategories.children[i];
            this.arrSetMainCategory.push(objMainCategory.name);
            let arrSubCategories = ['全部'];
            for (let j = 0; j < objMainCategory.children.length; j++) {
                let objSubCategory = objMainCategory.children[j];
                arrSubCategories.push(objSubCategory.name);
                this.arrSetSubCategory[0].push(objSubCategory.name);
            }
            this.arrSetSubCategory.push(arrSubCategories);
        }
        this.arrSetLocations = [global.arrVancouverAreaData[0].name];

        let objVancouverLocations = global.arrVancouverAreaData[0];
        for (let i = 0; i < objVancouverLocations.children.length; i++) {
            let objLocation = objVancouverLocations.children[i];
            this.arrSetLocations.push(objLocation.name);
        }
        this.objCopiedStore = {};
        this.arrSubCategories = [];  // 用于追踪查询标签分类的母分类
        this.arrLabelCategories = [];
        this.arrLabelCategoriesShow = [];
        this.arrStoreLabelOptions = [];
        this.bEditDlgPreset = false;  // 是否是编辑对话框的预置
        this.arrSelectedLabelCategories = [];  // 当前选中商家已绑定的标签类
        this.arrStoreType = ['黄页展示', '电商零售', '拼单团购', '自提外卖', '排队点餐', '物流仓储'];
        this.arrStoreTypeFilter = ['全部', '黄页展示', '电商零售', '拼单团购', '自提外卖', '排队点餐', '物流仓储'];
    }
    // 关键词处理
    KeywordsProcess = (strKeywords) => {
        let newKeywords = "";
        for (let i = 0; i < strKeywords.length; i++) {
            if (strKeywords[i] !== " ") {
                if (i === strKeywords.length - 1)
                    newKeywords += strKeywords[i];
                else
                    newKeywords += strKeywords[i] + "%";
            }
        }
        return newKeywords;
    }
    // 搜索商家名
    StoreNameSearch = () => {
        let strKeywords = this.refs.store_name_search.state.value;
        if (strKeywords === undefined)
            strKeywords = "";
        if (strKeywords.trim() === "") {
            this.GetStoresData();
        } else {
            this.bDataBack = false;
            this.setState({
                ...this.state,
                selectedRowKeys: [],
                btnControlDisable: [false, true, true]
            });
            axios('/SearchStores', { params: { name: this.KeywordsProcess(strKeywords) } })
                .then(({ data }) => {
                    this.bDataBack = true;
                    this.RefreshStoreTable(data);
                }).catch(function (error) { console.log(error); });
        }
    }
    // 过滤器筛选功能
    FilterFunction = (iLocation, iMainCategory, iSubCategory, iStatus) => {
        let arrTableData = [];
        // 地区过滤
        if (iLocation !== 0) {
            for (let i = 0; i < g_arrTableData.length; i++) {
                if (this.arrSetLocations[iLocation] === g_arrTableData[i].location) {
                    arrTableData.push(g_arrTableData[i]);
                }
            }
        } else {
            arrTableData = g_arrTableData;
        }
        // 主分类过滤
        let arrFilterData = [];
        if (iMainCategory !== 0) {
            for (let i = 0; i < arrTableData.length; i++) {
                if (this.arrSetMainCategory[iMainCategory] === arrTableData[i].main_category) {
                    arrFilterData.push(arrTableData[i]);
                }
            }
            arrTableData = arrFilterData;
            if (arrTableData.length <= 0)
                return arrTableData;
        }
        // 子分类过滤
        arrFilterData = [];
        if (iSubCategory !== 0) {
            for (let i = 0; i < arrTableData.length; i++) {
                if (this.arrSetSubCategory[iMainCategory][iSubCategory] === arrTableData[i].sub_category) {
                    arrFilterData.push(arrTableData[i]);
                }
            }
            arrTableData = arrFilterData;
            if (arrTableData.length <= 0)
                return arrTableData;
        }
        // 状态过滤
        arrFilterData = [];
        if (iStatus !== 0) {
            for (let i = 0; i < arrTableData.length; i++) {
                if (this.arrFilterStoreStatus[iStatus] === arrTableData[i].status) {
                    arrFilterData.push(arrTableData[i]);
                }
            }
            arrTableData = arrFilterData;
            if (arrTableData.length <= 0)
                return arrTableData;
        }
        return arrTableData;
    }
    //点击了地域过滤器
    LocationFilterClicked = (iLocationIndex) => {
        let arrTableData = this.FilterFunction(iLocationIndex, this.state.objFilter.iCurrentMainCategory,
            this.state.objFilter.iCurrentSubCategory, this.state.objFilter.iCurrentStoreStatus);
        this.setState({
            ...this.state,
            objFilter: {
                ...this.state.objFilter,
                iCurrentLocation: iLocationIndex
            },
            storesData: arrTableData
        });
    }
    // 点击了主分类过滤器
    MainCategoyFilterClicked = (iMainCategoryIndex) => {
        let arrTableData = this.FilterFunction(this.state.objFilter.iCurrentLocation, iMainCategoryIndex,
            this.state.objFilter.iCurrentSubCategory, this.state.objFilter.iCurrentStoreStatus);
        this.setState({
            ...this.state,
            objFilter: {
                strKeyword: "",
                iCurrentMainCategory: iMainCategoryIndex,
                iCurrentSubCategory: this.state.objFilter.iCurrentSubCategory,
                iCurrentLocation: this.state.objFilter.iCurrentLocation,
                iCurrentStoreStatus: this.state.objFilter.iCurrentStoreStatus,
            },
            storesData: arrTableData
        });
    }
    //点击了子分类过滤器
    SubCategoryFilterClicked = (iSubCategoryIndex) => {
        let arrTableData = this.FilterFunction(this.state.objFilter.iCurrentLocation, this.state.objFilter.iCurrentMainCategory, iSubCategoryIndex,
            this.state.objFilter.iCurrentStoreStatus);
        this.setState({
            ...this.state,
            objFilter: {
                strKeyword: "",
                iCurrentMainCategory: this.state.objFilter.iCurrentMainCategory,
                iCurrentSubCategory: iSubCategoryIndex,
                iCurrentLocation: this.state.objFilter.iCurrentLocation,
                iCurrentStoreStatus: this.state.objFilter.iCurrentStoreStatus,
            },
            storesData: arrTableData
        });
    }
    //点击了状态过滤器
    StoreStatusFilterClicked = (iStoreStatusID) => {
        let arrTableData = this.FilterFunction(this.state.objFilter.iCurrentLocation, this.state.objFilter.iCurrentMainCategory, this.state.objFilter.iCurrentSubCategory,
            iStoreStatusID);
        this.setState({
            ...this.state,
            objFilter: {
                strKeyword: "",
                iCurrentMainCategory: this.state.objFilter.iCurrentMainCategory,
                iCurrentSubCategory: this.state.objFilter.iCurrentSubCategory,
                iCurrentLocation: this.state.objFilter.iCurrentLocation,
                iCurrentStoreStatus: iStoreStatusID
            },
            storesData: arrTableData
        });
    }
    // 点击了热度排序过滤器
    HotSortClicked = (iHotID) => {
        this.setState({
            ...this.state,
            objFilter: {
                strKeyword: "",
                iCurrentMainCategory: this.state.objFilter.iCurrentMainCategory,
                iCurrentSubCategory: this.state.objFilter.iCurrentSubCategory,
                iCurrentLocation: this.state.objFilter.iCurrentLocation,
                iCurrentStoreStatus: this.state.objFilter.iCurrentStoreStatus,
                iCurrentSortHot: iHotID,
                iCurrentSortRegistTime: this.state.objFilter.iCurrentSortRegistTime
            }
        });
    }
    // 点击入驻时间排序过滤器
    RegisterTimeSortClicked = (iRegisterID) => {
        this.setState({
            ...this.state,
            objFilter: {
                strKeyword: "",
                iCurrentMainCategory: this.state.objFilter.iCurrentMainCategory,
                iCurrentSubCategory: this.state.objFilter.iCurrentSubCategory,
                iCurrentLocation: this.state.objFilter.iCurrentLocation,
                iCurrentStoreStatus: this.state.objFilter.iCurrentStoreStatus,
                iCurrentSortHot: this.state.objFilter.iCurrentSortHot,
                iCurrentSortRegistTime: iRegisterID
            }
        });
    }
    // 表格数据相关
    // 拷贝一行数据
    CopyStore = () => {
        this.objCopiedStore = {};
        this.objCopiedStore = Object.assign({}, this.arrStoresData[this.arrSelectedStoreIndex[0]]);
        this.setState({
            ...this.state,
            btnCopyPasteDisable: [true, false]
        });
        const hide = message.success("已复制商家：<" + this.objCopiedStore.store_name + ">");
        // DataStatistic
        UserActiveUpdate(2, { active: "管理后台: 商家管理操作", action: "拷贝" });
        setTimeout(hide, 2000);
    }
    // 粘贴一行数据
    PasteStore = () => {
        message.loading({ content: '拷贝中……', key: g_strMessageKey });
        let objNewStore = this.objCopiedStore;
        delete objNewStore.id;
        axios.post('/NewStore', { objNewStore })
            .then(({ data }) => {
                // DataStatistic
                UserActiveUpdate(2, { active: "管理后台: 商家管理操作", action: "粘贴" });
                message.success({ content: '拷贝商家：<' + objNewStore.store_name + "> 成功！", key: g_strMessageKey, duration: 2 });
                this.RefreshStoreTable(data);
            });
    }
    // 点击了弹窗里的主分类过滤器
    DlgMainCategoyClicked = (iMainCategoryIndex) => {
        this.arrStoreLabelOptions = [];
        this.bEditDlgPreset = false;
        this.setState({
            ...this.state,
            dlgInfo: {
                ...this.state.dlgInfo,
                iMainCategory: iMainCategoryIndex,
                iSubCategory: 0,
                // iLocation: this.state.dlgInfo.iLocation,
                // iStatus: this.state.dlgInfo.iStatus,
                // iStoreType: this.state.dlgInfo.iStoreType
            },
            arrSelectedTypes: []
        });
    }
    // 根据当前弹窗中选中的子分类来确定标签分类选择器的内容
    SetLabelCategoriesSelector = (iMainCategoryIndex, iSubCategoryIndex) => {
        let strSubCategoryName =
            this.arrSetSubCategory[iMainCategoryIndex][iSubCategoryIndex];
        let iParentID = -1;
        if (iMainCategoryIndex !== 0 && iSubCategoryIndex !== 0) {
            for (let i = 0; i < this.arrSubCategories.length; i++) {
                if (strSubCategoryName === this.arrSubCategories[i].name) {
                    iParentID = this.arrSubCategories[i].id; break;
                }
            }
        }
        this.arrStoreLabelOptions = [];
        this.arrSelectedLabelCategories = [];
        if (iParentID >= 0) {
            // 先设置好标签类别的选项内容
            let iKey = 0;
            for (let i = 0; i < this.arrLabelCategories.length; i++) {
                if (this.arrLabelCategories[i].parent_id === iParentID) {
                    this.arrStoreLabelOptions.push(
                        <Option key={iKey}>{this.arrLabelCategories[i].name}</Option>
                    );
                    iKey++;
                }
            }
            // 再设置当前商家已经打上的标签
            let iSelectedRow = this.state.selectedRowKeys[0];
            if (this.bEditDlgPreset && this.arrStoresData[iSelectedRow].labels !== null && this.arrStoresData[iSelectedRow].labels !== "") {
                let arrCurrentLabelCategories = this.arrStoresData[iSelectedRow].labels.split(',');
                for (let i = 0; i < arrCurrentLabelCategories.length; i++) {
                    for (let j = 0; j < this.arrStoreLabelOptions.length; j++) {
                        if (arrCurrentLabelCategories[i] === this.arrStoreLabelOptions[j].props.children) {
                            // 多重标签选择器（Selector的一种），是根据key来判断，当前已经选中的内容
                            this.arrSelectedLabelCategories.push(j.toString()); break;
                        }
                    }
                }
            }
        }
    }
    // 点击了弹窗里的子分类过滤器
    DlgSubCategoyClicked = (iSubCategoryIndex) => {
        this.bEditDlgPreset = false;
        this.SetLabelCategoriesSelector(this.state.dlgInfo.iMainCategory, iSubCategoryIndex);
        this.setState({
            ...this.state,
            dlgInfo: {
                ...this.state.dlgInfo,
                // iMainCategory: this.state.dlgInfo.iMainCategory,
                iSubCategory: iSubCategoryIndex,
                // iLocation: this.state.dlgInfo.iLocation,
                // iStatus: this.state.dlgInfo.iStatus,
                // iStoreType: this.state.dlgInfo.iStoreType
            },
            arrSelectedTypes: []
        });
    }
    // 点击了弹窗里的地区过滤器
    DlgLocationClicked = (iLocationID) => {
        this.setState({
            ...this.state,
            dlgInfo: {
                ...this.state.dlgInfo,
                // iMainCategory: this.state.dlgInfo.iMainCategory,
                // iSubCategory: this.state.dlgInfo.iSubCategory,
                iLocation: iLocationID,
                // iStatus: this.state.dlgInfo.iStatus
            }
        });
    }
    // 点击了弹窗里的状态过滤器
    DlgStatusClicked = (iStatus) => {
        this.setState({
            ...this.state,
            dlgInfo: {
                ...this.state.dlgInfo,
                // iMainCategory: this.state.dlgInfo.iMainCategory,
                // iSubCategory: this.state.dlgInfo.iSubCategory,
                // iLocation: this.state.dlgInfo.iLocation,
                iStatus: iStatus
            }
        });
    }
    // 点击了弹窗里的状态过滤器
    DlgStoreTypeClicked = (iStoreType) => {
        this.setState({
            ...this.state,
            dlgInfo: {
                ...this.state.dlgInfo,
                // iMainCategory: this.state.dlgInfo.iMainCategory,
                // iSubCategory: this.state.dlgInfo.iSubCategory,
                // iLocation: this.state.dlgInfo.iLocation,
                // iStatus: this.state.dlgInfo.iStatus,
                iStoreType: iStoreType
            }
        });
    }
    // Table Item Checkbox Status
    start = () => {
        this.setState({ loading: true });
        // ajax request after empty completing
        setTimeout(() => {
            this.setState({
                selectedRowKeys: [],
                loading: false
            });
        }, 1000);
    }
    // 选中某行的响应函数
    selectRow = (record) => {
        const selectedRowKeys = [...this.state.selectedRowKeys];
        if (selectedRowKeys.indexOf(record.key) >= 0) {
            selectedRowKeys.splice(selectedRowKeys.indexOf(record.key), 1);
        } else {
            selectedRowKeys.push(record.key);
        }
        this.OnSelectChange(selectedRowKeys);
    }
    // 选中的行发生变化的响应函数
    onSelectedRowKeysChange = (selectedRowKeys) => {
        this.OnSelectChange(selectedRowKeys);
    }
    // 表格选择内容发生变化
    OnSelectChange = selectedRowKeys => {
        // 一个都没有选中
        if (selectedRowKeys.length === 0) {
            this.arrSelectedStoreID = [];
            this.arrSelectedStoreIndex = [];
            this.setState({
                ...this.state,
                iDlgType: -1,
                btnControlDisable: [false, true, true],  // 操作按钮控制
                btnCopyPasteDisable: [true, true],
                selectedRowKeys
            });
        } else {
            // 只选中了一个
            if (selectedRowKeys.length === 1) {
                this.arrSelectedStoreID = [];
                if (this.arrStoresData[selectedRowKeys[0]])
                    if (this.arrStoresData[selectedRowKeys[0]].id)
                        this.arrSelectedStoreID.push(this.arrStoresData[selectedRowKeys[0]].id);
                this.arrSelectedStoreIndex = [];
                this.arrSelectedStoreIndex.push(selectedRowKeys[0]);
                let bDelete = false;
                if (this.arrSelectedStoreID[0] === 1)
                    bDelete = true;
                this.setState({
                    ...this.state,
                    btnControlDisable: [false, false, bDelete],  // 操作按钮控制
                    btnCopyPasteDisable: [false, true],
                    selectedRowKeys
                });
            } else {
                let bDelete = false;
                this.arrSelectedStoreID = [];
                this.arrSelectedStoreIndex = [];
                for (let i = 0; i < selectedRowKeys.length; i++) {
                    let id = this.arrStoresData[selectedRowKeys[i]].id;
                    if (id === 1)
                        bDelete = true;
                    this.arrSelectedStoreID.push(id);
                    this.arrSelectedStoreIndex.push(selectedRowKeys[i]);
                }
                this.setState({
                    ...this.state,
                    btnControlDisable: [false, true, bDelete],  // 操作按钮控制
                    btnCopyPasteDisable: [true, true],
                    selectedRowKeys
                });
            }
        }
    }
    //显示弹窗前预置新建的内容
    PresetNewDlg() {
        // Area 1: 必填基本信息
        if (this.refs.input_store_name)  // 一开始refs.input_store_name会是undefine，因此需要做判断
            this.refs.input_store_name.state.value = "";
        if (this.refs.input_contact_name)
            this.refs.input_contact_name.state.value = "";
        if (this.refs.input_proxy_name)
            this.refs.input_proxy_name.state.value = "";
        if (this.refs.input_address)
            this.refs.input_address.state.value = "";
        // Area 2: 商区（非必填）
        if (this.refs.input_mall)
            this.refs.input_mall.state.value = "";
        if (this.refs.input_price)
            this.refs.input_price.state.value = "";
        // Area 3: 联系信息（非必填）
        if (this.refs.input_cell_phone)
            this.refs.input_cell_phone.state.value = "";
        if (this.refs.input_phone)
            this.refs.input_phone.state.value = "";
        if (this.refs.input_email)
            this.refs.input_email.state.value = "";
        if (this.refs.input_wechat)
            this.refs.input_wechat.state.value = "";
        if (this.refs.input_qq)
            this.refs.input_qq.state.value = "";
        // Area 4: 富文本区域
        if (this.refs.input_slogan)
            this.refs.input_slogan.state.value = "";
        if (this.refs.input_description)
            this.refs.input_description.state.value = "";
        if (this.refs.input_comment)
            this.refs.input_comment.state.value = "";
    }
    //显示弹窗前预置编辑的内容
    PresetEditDlg(iSelectedRow) {
        // Area 1: 必填基本信息
        if (this.refs.input_store_name)
            this.refs.input_store_name.state.value = this.arrStoresData[iSelectedRow].store_name;
        if (this.refs.input_contact_name)
            this.refs.input_contact_name.state.value = this.arrStoresData[iSelectedRow].contact_name;
        if (this.refs.input_proxy_name)
            this.refs.input_proxy_name.state.value = this.arrStoresData[iSelectedRow].proxy_name;
        if (this.refs.input_address)
            this.refs.input_address.state.value = this.arrStoresData[iSelectedRow].address;
        // Area 2: 商区（非必填）
        if (this.refs.input_mall)
            this.refs.input_mall.state.value = this.arrStoresData[iSelectedRow].mall;
        if (this.refs.input_price)
            this.refs.input_price.state.value = this.arrStoresData[iSelectedRow].ave_price;
        // Area 3: 联系信息（非必填）
        if (this.refs.input_cell_phone)
            this.refs.input_cell_phone.state.value = this.arrStoresData[iSelectedRow].cell_phone;
        if (this.refs.input_phone)
            this.refs.input_phone.state.value = this.arrStoresData[iSelectedRow].phone;
        if (this.refs.input_email)
            this.refs.input_email.state.value = this.arrStoresData[iSelectedRow].email;
        if (this.refs.input_wechat)
            this.refs.input_wechat.state.value = this.arrStoresData[iSelectedRow].wechat;
        if (this.refs.input_qq)
            this.refs.input_qq.state.value = this.arrStoresData[iSelectedRow].qq;
        // Area 4: 富文本区域
        if (this.refs.input_description)
            this.refs.input_description.state.value = this.arrStoresData[iSelectedRow].description;
        if (this.refs.input_slogan)
            this.refs.input_slogan.state.value = this.arrStoresData[iSelectedRow].slogan;
        if (this.refs.input_comment)
            this.refs.input_comment.state.value = this.arrStoresData[iSelectedRow].comment;
    }
    //弹出对话框
    ShowDlg = (iDlgType) => {
        // 新建
        if (iDlgType === 0) {
            this.PresetNewDlg();
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                dlgInfo: { iMainCategory: 0, iSubCategory: 0, iLocation: 0, iStatus: 0, iStoreType: 0 },
                bShowDlg: true
            });
        }
        // 编辑
        if (iDlgType === 1) {
            let iSelectedRow = this.state.selectedRowKeys[0];
            this.PresetEditDlg(iSelectedRow);
            let iLocation = this.LocationIDToIndex(this.arrStoresData[iSelectedRow].location);
            let arrCategoryIndex = this.CategoryIDToIndex(this.arrStoresData[iSelectedRow].main_category,
                this.arrStoresData[iSelectedRow].sub_category);
            this.bEditDlgPreset = true;
            this.SetLabelCategoriesSelector(arrCategoryIndex[0], arrCategoryIndex[1]);
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                dlgInfo: {
                    iMainCategory: arrCategoryIndex[0],
                    iSubCategory: arrCategoryIndex[1],
                    iLocation: iLocation,
                    iStatus: this.arrStoresData[iSelectedRow].status,
                    iStoreType: this.arrStoresData[iSelectedRow].type
                },
                bShowDlg: true,
                arrSelectedTypes: this.arrSelectedLabelCategories
            });
        }
        // 删除
        if (iDlgType === 2) {
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true
            });
        }
    }
    // 点击确定按钮
    DlgOkButtonClick = e => {
        // 新建商家
        if (this.state.iDlgType === 0) {
            let objNewStore = this.GetNewInputData();
            switch (objNewStore) {
                case -1: {
                    message.warning("商家名称不能为空！"); return;
                }
                case -2: {
                    message.warning("请选择一个主分类！"); return;
                }
                case -3: {
                    message.warning("请选择一个子分类"); return;
                }
                case -4: {
                    message.warning("请选择一个城市！"); return;
                }
                default: break;
            }
            this.bDataBack = false;
            message.loading({ content: '新的商家创建中……', key: g_strMessageKey });
            console.log("新建商家：");
            console.log(objNewStore);
            axios.post('/NewStore', { objNewStore })
                .then(({ data }) => {
                    console.log(data);
                    if (data.status === -1) {
                        message.warning({ content: data.message, key: g_strMessageKey, duration: 2 })
                        console.log(data.error);
                    }
                    if (data.status === 1) {
                        // DataStatistic
                        UserActiveUpdate(2, { active: "管理后台: 商家管理操作", action: "新建" });
                        // 如果有填写地址，则需要获取经纬度信息并存储
                        if (objNewStore.address !== undefined && objNewStore.address !== null &&
                            objNewStore.address.trim() !== "") {
                            GetLatLongBasedOnAddress(data.iNewStoreID, objNewStore.address);
                        }
                        message.success({
                            content: '创建商家：<' + objNewStore.store_name + "> 成功！",
                            key: g_strMessageKey, duration: 2
                        });
                    }
                    this.RefreshStoreTable(data.arrNewStoreList);
                });
        }
        // 编辑商家
        if (this.state.iDlgType === 1) {
            let objEditStore = this.GetNewInputData();
            switch (objEditStore) {
                case -1: {
                    message.warning("商家名称不能为空！"); return;
                }
                case -2: {
                    message.warning("请选择一个主分类！"); return;
                }
                case -3: {
                    message.warning("请选择一个子分类"); return;
                }
                case -4: {
                    message.warning("请选择一个城市！"); return;
                }
                default: break;
            }
            this.bDataBack = false;
            message.loading({ content: '正在更新商家信息……', key: g_strMessageKey });
            axios.post('/EditStore', { id: this.arrSelectedStoreID[0], objEditStore: objEditStore })
                .then((response) => {
                    // DataStatistic
                    UserActiveUpdate(2, { active: "管理后台: 商家管理操作", action: "编辑" });
                    this.bDataBack = true;
                    // 如果有填写地址，则需要获取经纬度信息并存储
                    if (objEditStore.address !== undefined && objEditStore.address !== null &&
                        objEditStore.address.trim() !== "") {
                        GetLatLongBasedOnAddress(this.arrSelectedStoreID[0], objEditStore.address);
                    }
                    message.success({ content: '商家信息更新成功！', key: g_strMessageKey, duration: 2 });
                    g_arrTableData = this.OrganizeStoresData();
                    this.setState({
                        ...this.state,
                        bShowDlg: false,
                        btnControlDisable: [false, true, true],
                        btnCopyPasteDisable: [true, true],
                        selectedRowKeys: [],
                        storesData: g_arrTableData,
                        objFilter: {
                            strKeyword: "", iCurrentMainCategory: 0, iCurrentSubCategory: 0,
                            iCurrentLocation: 0, iCurrentStoreStatus: 0
                        }
                    });
                });
        }
        // 删除商家
        if (this.state.iDlgType === 2) {
            this.bDataBack = false;
            message.loading({ content: '正在删除商家……', key: g_strMessageKey });
            axios.post('/DeleteStore', { arrDeleteStores: this.arrSelectedStoreID })
                .then(
                    ({ data }) => {
                        // DataStatistic
                        UserActiveUpdate(2, { active: "管理后台: 商家管理操作", action: "删除" });
                        message.success({
                            content: '商家已删除！',
                            key: g_strMessageKey,
                            duration: 2
                        });
                        this.RefreshStoreTable(data);
                    });
        }
    };
    // 点击取消按钮
    DlgCancelButtonClick = e => {
        this.setState({
            ...this.state,
            // iDlgType: -1,
            bShowDlg: false
        });
    };
    /* 自定义成员函数 */
    // 刷新表格中的数据
    RefreshStoreTable = (data) => {
        if (this.state.bShowDlg)
            this.bDataBack = true;
        this.arrStoresData = data;
        g_arrTableData = this.OrganizeStoresData();
        this.setState({
            ...this.state,
            bShowDlg: false,
            btnControlDisable: [false, true, true],
            btnCopyPasteDisable: [true, true],
            selectedRowKeys: [],
            storesData: g_arrTableData,
            objFilter: {
                strKeyword: "", iCurrentMainCategory: 0, iCurrentSubCategory: 0,
                iCurrentLocation: 0, iCurrentStoreStatus: 0
            }
        });
    }
    // 获取弹窗中的更新数据
    GetNewInputData = () => {
        let objInputInfo = {};
        let strStoreName = this.refs.input_store_name.state.value.trim();
        if (strStoreName === null || strStoreName === "" || strStoreName === " ") {
            return -1;
        }
        let strContactName = this.refs.input_contact_name.state.value;
        let strProxyName = this.refs.input_proxy_name.state.value;
        let strAddress = this.refs.input_address.state.value;
        if (this.state.dlgInfo.iMainCategory < 1) {
            return -2;
        }
        let iMainCategory = global.arrCategoriesData[0].children[this.state.dlgInfo.iMainCategory - 1].id;
        if (this.state.dlgInfo.iSubCategory < 1) {
            return -3;
        }
        let iSubCategory = global.arrCategoriesData[0].children[this.state.dlgInfo.iMainCategory - 1]
            .children[this.state.dlgInfo.iSubCategory - 1].id;
        if (this.state.dlgInfo.iLocation < 1) {
            return -4;
        }
        let iLocation = global.arrVancouverAreaData[0].children[this.state.dlgInfo.iLocation - 1].id;
        let strMall = this.refs.input_mall.state.value;
        let strPrice = this.refs.input_price.state.value;
        let strCellPhone = this.refs.input_cell_phone.state.value;
        let strPhone = this.refs.input_phone.state.value;
        let strEmail = this.refs.input_email.state.value;
        let strWeChat = this.refs.input_wechat.state.value;
        let strQQ = this.refs.input_qq.state.value;
        let strSlogan = this.refs.input_slogan.state.value;
        let strDescription = this.refs.input_description.state.value;
        let strComment = this.refs.input_comment.state.value;
        let iStatus = this.state.dlgInfo.iStatus;
        let iStoreType = this.state.dlgInfo.iStoreType;
        let strPickupLocations = "";
        if (this.refs.input_pickup_locations)
            strPickupLocations = this.refs.input_pickup_locations.state.value;
        // 生成编辑好的数据结构体
        objInputInfo.main_category = iMainCategory;
        objInputInfo.sub_category = iSubCategory;
        objInputInfo.store_name = strStoreName;
        objInputInfo.contact_name = strContactName;
        objInputInfo.proxy_name = strProxyName;
        objInputInfo.address = strAddress;
        objInputInfo.location = iLocation;
        objInputInfo.mall = strMall;
        objInputInfo.ave_price = strPrice;
        objInputInfo.cell_phone = strCellPhone;
        objInputInfo.phone = strPhone;
        objInputInfo.email = strEmail;
        objInputInfo.wechat = strWeChat;
        objInputInfo.qq = strQQ;
        objInputInfo.description = strDescription;
        objInputInfo.slogan = strSlogan;
        objInputInfo.comment = strComment;
        objInputInfo.status = iStatus;
        objInputInfo.type = iStoreType;
        objInputInfo.pickup_locations = strPickupLocations;
        objInputInfo.labels = "";
        if (this.state.arrSelectedTypes.length > 0) {
            for (let i = 0; i < this.state.arrSelectedTypes.length; i++) {
                objInputInfo.labels += this.arrStoreLabelOptions[i].props.children;
                if (i !== this.state.arrSelectedTypes.length - 1)
                    objInputInfo.labels += ",";
            }
        }
        // 新建商家
        if (this.state.iDlgType === 0) {
            iStatus = 0;  // 审核中
            objInputInfo.register_date = new Date().toISOString().split('T')[0];
        }
        // 编辑商家
        if (this.state.iDlgType === 1) {
            objInputInfo.register_date = this.arrStoresData[this.arrSelectedStoreIndex].register_date;
        }
        objInputInfo.update_date = new Date().toISOString().split('T')[0];
        // 同步到成员变量的商家数据结构体数组
        if (this.state.iDlgType === 1) {
            let id = this.arrStoresData[this.arrSelectedStoreIndex].id;
            this.arrStoresData[this.arrSelectedStoreIndex] = Object.assign({}, objInputInfo);
            this.arrStoresData[this.arrSelectedStoreIndex].id = id;
        }
        return objInputInfo;
    }
    // 地区ID转换为Index
    LocationIDToIndex = (iLocationID) => {
        let iIndex = 0;
        if (iLocationID !== global.arrVancouverAreaData[0].id) {
            for (let i = 0; i < global.arrVancouverAreaData[0].children.length; i++) {
                if (iLocationID === global.arrVancouverAreaData[0].children[i].id) {
                    iIndex = i + 1; break;
                }
            }
        }
        return iIndex;
    }
    // 主分类、子分类ID转换为Index
    CategoryIDToIndex = (iMainCategoryID, iSubCategoryID) => {
        let arrCategoryIndex = [0, 0];
        let arrMainCategory = global.arrCategoriesData[0].children;
        for (let i = 0; i < arrMainCategory.length; i++) {
            if (arrMainCategory[i].id === iMainCategoryID) {
                arrCategoryIndex[0] = i + 1;
                for (let j = 0; j < arrMainCategory[i].children.length; j++) {
                    if (arrMainCategory[i].children[j].id === iSubCategoryID) {
                        arrCategoryIndex[1] = j + 1;
                        break;
                    }
                }
                break;
            }
        }
        return arrCategoryIndex;
    }
    // 整理Stores数据
    OrganizeStoresData = () => {
        let arrTableData = [];
        if (this.arrStoresData.length > 0) {
            for (let i = 0; i < this.arrStoresData.length; i++) {
                let iLocation = this.LocationIDToIndex(this.arrStoresData[i].location);
                let arrCategoryIndex = this.CategoryIDToIndex(this.arrStoresData[i].main_category, this.arrStoresData[i].sub_category);
                let strRegisterDate = this.arrStoresData[i].register_date;
                let strUpdateDate = this.arrStoresData[i].update_date;
                if (strRegisterDate !== null) {
                    if (strRegisterDate.indexOf('T') >= 0)
                        strRegisterDate = strRegisterDate.split('T')[0];
                }
                if (strUpdateDate !== null) {
                    if (strUpdateDate.indexOf('T') >= 0)
                        strUpdateDate = strUpdateDate.split('T')[0];
                }
                let objStoreData = {
                    key: i, store_id: this.arrStoresData[i].id,
                    store_name: this.arrStoresData[i].store_name,
                    contact_name: this.arrStoresData[i].contact_name,
                    proxy_name: this.arrStoresData[i].proxy_name,
                    main_category: this.arrSetMainCategory[arrCategoryIndex[0]],
                    sub_category: this.arrSetSubCategory[arrCategoryIndex[0]][arrCategoryIndex[1]],
                    location: this.arrSetLocations[iLocation],
                    register_date: strRegisterDate,
                    update_date: strUpdateDate,
                    status: this.arrStatus[this.arrStoresData[i].status],
                    store_type: this.arrStoreType[this.arrStoresData[i].type]
                }
                arrTableData.push(objStoreData);
            }
        }
        return arrTableData;
    }
    // 从数据库获取所有商家的数据
    GetStoresData() {
        message.loading({ content: '数据加载中……', key: g_strMessageKey });
        axios('/GetAllStores').then(({ data }) => {
            message.success({ content: "数据加载完成！", key: g_strMessageKey, duration: 2 });
            this.RefreshStoreTable(data);
        }).catch(function (error) { console.log(error); });
    }
    // 类别选择监控
    TagSelectChange = (arrTagIndex) => {
        //  多重标签选择器（Selector的一种）在发生变化时，传来的参数是整型数组，选中标签的index
        this.setState({
            ...this.state,
            arrSelectedTypes: arrTagIndex
        });
    }
    // 获取所有的标签分类
    GetLabelCategories = () => {
        axios('/GetAllLabelCategories').then(({ data }) => {
            this.arrSubCategories = data.arrSubCategories;
            this.arrLabelCategories = data.arrLabelCategories;
            this.arrStoreLabelOptions = [];
            this.bDataBack = true;
            this.setState({
                ...this.state,
                bShowDlg: false,
                btnControlDisable: [false, true, true],
                btnCopyPasteDisable: [true, true],
                selectedRowKeys: [],
                storesData: g_arrTableData
            });
        }).catch(function (error) { console.log(error); });
    }
    // 卸载异步操作设置状态
    componentWillUnmount() {
        clearTimeout(this.timeouter)
        this.setState = (state, callback) => {
            return;
        }
    }
    // 渲染完毕……
    componentDidMount() {
        this.GetStoresData();
        this.GetLabelCategories();
    }
    // 渲染函数
    render() {

        let uiStoreSearch = (
            <div className="manage_stretch_right">
                <Input placeholder="搜索商家名" ref="store_name_search" />
                <Button className="manage_contents_title_margin" type="primary"
                    onClick={this.StoreNameSearch}>搜索</Button>
            </div>
        );
        // 如果界面上需要显示的内容已经从数据库获得
        if (this.bDataBack) {
            // 顶部栏主分类下拉菜单
            const uiMainCategoryMenu = (
                <Menu>
                    {this.arrSetMainCategory.map((item, index) => {
                        return (
                            <Menu.Item key={index} onClick={this.MainCategoyFilterClicked.bind(this, index)} >
                                {item}
                            </Menu.Item>
                        );
                    })}
                </Menu>
            );
            // 对话框中主分类下拉菜单
            const uiModalMainCategoryMenu = (
                <Menu>
                    {this.arrSetMainCategory.map((item, index) => {
                        return (
                            <Menu.Item key={index} onClick={this.DlgMainCategoyClicked.bind(this, index)}>
                                {item}
                            </Menu.Item>
                        );
                    })}
                </Menu>
            );
            // 顶部栏子分类下拉菜单
            const uiSubCategoryMenu = (
                <Menu>
                    {this.arrSetSubCategory[this.state.objFilter.iCurrentMainCategory].map((item, index) => {
                        return (
                            <Menu.Item key={index}
                                onClick={this.SubCategoryFilterClicked.bind(this, index)}>
                                {item}
                            </Menu.Item>
                        );
                    })}
                </Menu>
            );
            // 对话框中子分类下拉菜单
            const uiModalSubCategoryMenu = (
                <Menu>
                    {this.arrSetSubCategory[this.state.dlgInfo.iMainCategory].map((item, index) => {
                        return (
                            <Menu.Item key={index} onClick={this.DlgSubCategoyClicked.bind(this, index)}>
                                {item}
                            </Menu.Item>
                        );
                    })}
                </Menu>
            );
            // 顶部栏地区下拉菜单
            const uiLocationMenu = (
                <Menu>
                    {this.arrSetLocations.map((button, index) => {
                        return (
                            <Menu.Item key={index} onClick={this.LocationFilterClicked.bind(this, index)}>
                                {this.arrSetLocations[index]}
                            </Menu.Item>
                        );
                    })}
                </Menu>
            );
            // 对话框中的地区下拉菜单
            const uiModalLocationMenu = (
                <Menu>
                    {this.arrSetLocations.map((item, index) => {
                        return (
                            <Menu.Item key={index} onClick={this.DlgLocationClicked.bind(this, index)}>
                                {item}
                            </Menu.Item>
                        );
                    })}
                </Menu>
            );
            //状态下拉菜单
            const uiStatusMenu = (
                <Menu>
                    {this.arrFilterStoreStatus.map((item, index) => {
                        return (
                            <Menu.Item key={index} onClick={this.StoreStatusFilterClicked.bind(this, index)}>
                                {item}
                            </Menu.Item>
                        );
                    })}
                </Menu>
            );
            // 对话框中的商家类别下拉菜单
            const uiStoreTypeMenu = (
                <Menu>
                    {this.arrStoreType.map((item, index) => {
                        return (
                            <Menu.Item key={index} onClick={this.DlgStoreTypeClicked.bind(this, index)}>
                                {item}
                            </Menu.Item>
                        );
                    })}
                </Menu>
            );
            //火热度排序下拉菜单
            // const uiHotMenu = (
            //     <Menu>
            //         <Menu.Item onClick={this.HotSortClicked.bind(this, 0)}>
            //             {this.arrSortHot[0]}
            //         </Menu.Item>
            //         <Menu.Item onClick={this.HotSortClicked.bind(this, 1)}>
            //             {this.arrSortHot[1]}
            //         </Menu.Item>
            //     </Menu>
            // );
            //入驻时间排序下拉菜单//
            // const uiRegistTimeMenu = (
            //     <Menu>
            //         <Menu.Item onClick={this.RegisterTimeSortClicked.bind(this, 0)} >
            //             {this.arrSortRegistTime[0]}
            //         </Menu.Item>
            //         <Menu.Item onClick={this.RegisterTimeSortClicked.bind(this, 1)} >
            //             {this.arrSortRegistTime[1]}
            //         </Menu.Item>
            //     </Menu>
            // );

            //对话框
            let objSelectedRow = Object.assign({}, objEmptyStoreTableRow);
            let strDlgTitle = "新建商家";
            let uiAddressStatusColumn = (
                <Row className="manage_modal_row" type="flex" justify="start">
                    <Col className='manage_modal_col' span={4}>
                        <span className="manage_info_label">
                            <div className="must_input_star"> </div>地址
                        </span>
                        <Input className='manage_modal_input manage_long_input'
                            size="default" placeholder="" ref="input_address"
                            defaultValue={objSelectedRow.address} />
                    </Col>
                </Row>
            );
            if (this.state.iDlgType === 1 && this.state.bShowDlg) {
                strDlgTitle = "编辑商家: " + objSelectedRow.store_name;
                objSelectedRow = this.arrStoresData[this.state.selectedRowKeys[0]];
                const uiStatusMenu = (
                    <Menu>
                        {this.arrStatus.map((item, index) => {
                            return (
                                <Menu.Item key={index} onClick={this.DlgStatusClicked.bind(this, index)}>
                                    {item}
                                </Menu.Item>
                            );
                        })}
                    </Menu>
                );
                uiAddressStatusColumn = (
                    <Row className="manage_modal_row" type="flex" justify="start">
                        <Col className="manage_modal_col" span={4}>
                            <div className="manage_info_label">
                                <div className="must_input_star"> </div>状态</div>
                            <Dropdown className="manage_modal_dropdown"
                                overlay={uiStatusMenu} placement="bottomLeft">
                                <Button>
                                    {this.arrStatus[this.state.dlgInfo.iStatus]} <Icon type="caret-down" />
                                </Button>
                            </Dropdown>
                        </Col>
                        <Col className='manage_modal_col' span={4}>
                            <span className="manage_info_label">
                                <div className="must_input_star"> </div>地址
                            </span>
                            <Input className='manage_modal_input manage_mid_input'
                                size="default" placeholder="" ref="input_address"
                                defaultValue={objSelectedRow.address} />
                        </Col>
                    </Row>
                );
            }
            let uiPickUpLocations = null;
            if (this.state.bShowDlg && this.state.dlgInfo.iStoreType === 3) {
                uiPickUpLocations = (
                    <Row className="manage_modal_row" type="flex" justify="start">
                        <Col className='manage_modal_col' span={4}>
                            <span className="manage_info_label_top">
                                <div className="must_input_star"> </div>自提点
                            </span>
                            <TextArea className='manage_modal_input_area' rows={2} size="default"
                                placeholder="" ref="input_pickup_locations" defaultValue={objSelectedRow.pickup_locations} />
                        </Col>
                    </Row>
                );
            }
            let uiModal = (
                <Modal width={775} title={strDlgTitle} visible={this.state.bShowDlg}
                    onOk={this.DlgOkButtonClick} onCancel={this.DlgCancelButtonClick} okText={'提交'} cancelText={'取消'} >
                    {/* 名称、联系人、代理 */}
                    <div className="manage_model_box">
                        <Row className="manage_modal_row" type="flex" justify="start">
                            <Col className='manage_modal_col' span={4}>
                                <div className="manage_info_label">
                                    <div className="must_input_star">*</div>名称
                                </div>
                                <Input className='manage_modal_input' size="default"
                                    placeholder="" ref="input_store_name"
                                    defaultValue={objSelectedRow.store_name} />
                            </Col>
                            <Col className="manage_modal_col" span={4}>
                                <div className="manage_info_label">
                                    <div className="must_input_star"> </div>代理
                                </div>
                                <Input className="manage_modal_input" size="default" placeholder="" ref="input_proxy_name" defaultValue={objSelectedRow.proxy_name} />
                            </Col>
                            <Col className="manage_modal_col" span={4}>
                                <div className="manage_info_label">
                                    <div className="must_input_star"> </div>类别</div>
                                <Dropdown className="manage_modal_dropdown"
                                    overlay={uiStoreTypeMenu} placement="bottomLeft">
                                    <Button>
                                        {this.arrStoreType[this.state.dlgInfo.iStoreType]} <Icon type="caret-down" />
                                    </Button>
                                </Dropdown>
                            </Col>
                        </Row>
                        {uiAddressStatusColumn}
                        {uiPickUpLocations}
                    </div>
                    {/* 主分类、子分类、标签、地区、商区 */}
                    <div className="manage_model_box">
                        <Row className="manage_modal_row" type="flex" justify="start">
                            <Col className="manage_modal_col" span={4}>
                                <div className="manage_info_label">
                                    <div className="must_input_star">*</div>主分类
                                </div>
                                <Dropdown className="manage_modal_dropdown" trigger={['click']}
                                    overlay={uiModalMainCategoryMenu} placement="bottomLeft">
                                    <Button>
                                        {this.arrSetMainCategory[this.state.dlgInfo.iMainCategory]}
                                        <Icon type="caret-down" />
                                    </Button>
                                </Dropdown>
                            </Col>
                            <Col className="manage_modal_col" span={4}>
                                <span className="manage_info_label">
                                    <div className="must_input_star">*</div>子分类
                                </span>
                                <Dropdown className="manage_modal_dropdown" trigger={['click']}
                                    overlay={uiModalSubCategoryMenu} placement="bottomLeft">
                                    <Button>
                                        {this.arrSetSubCategory[this.state.dlgInfo.iMainCategory][this.state.dlgInfo.iSubCategory]}
                                        <Icon type="caret-down" />
                                    </Button>
                                </Dropdown>
                            </Col>
                            <Col className="manage_modal_col" span={4}>
                                <span className="manage_info_label">
                                    <div className="must_input_star">*</div>地区
                                </span>
                                <Dropdown className="manage_modal_dropdown" trigger={['click']}
                                    overlay={uiModalLocationMenu} placement="bottomLeft">
                                    <Button>
                                        {this.arrSetLocations[this.state.dlgInfo.iLocation]}
                                        <Icon type="caret-down" />
                                    </Button>
                                </Dropdown>
                            </Col>
                        </Row>
                        <Row className="manage_modal_row" type="flex" justify="start">
                            <Col className="manage_modal_col" span={4}>
                                <div className="manage_info_label">
                                    <div className="must_input_star"></div>标签类
                                </div>
                                <Select mode="multiple" style={{ width: '625px' }}
                                    className="item_info_input_long item_info_input_nopadding"
                                    placeholder="可多选" labelInValue={false}
                                    onChange={this.TagSelectChange}
                                    value={this.state.arrSelectedTypes} >
                                    {this.arrStoreLabelOptions}</Select>
                            </Col>
                        </Row>
                        <Row className="manage_modal_row" type="flex" justify="start">
                            <Col className="manage_modal_col" span={4}>
                                <span className="manage_info_label">
                                    <div className="must_input_star"> </div>商区
                                </span>
                                <Input className="manage_modal_input" size="default" placeholder=""
                                    ref="input_mall" defaultValue={objSelectedRow.mall} />
                            </Col>
                            <Col className="manage_modal_col" span={4}>
                                <span className="manage_info_label">
                                    <div className="must_input_star"> </div>均价
                                </span>
                                <Input className="manage_modal_input" size="default" placeholder=""
                                    ref="input_price" defaultValue={objSelectedRow.ave_price} />
                            </Col>
                        </Row>
                    </div>
                    {/* 商家联系方式 */}
                    <div className="manage_model_box">
                        <Row className="manage_modal_row" type="flex" justify="start">
                            <Col className='manage_modal_col' span={4}>
                                <div className="manage_info_label">
                                    <div className="must_input_star"> </div>联系人
                                </div>
                                <Input className='manage_modal_input' size="default" placeholder="" ref="input_contact_name" defaultValue={objSelectedRow.contact_name} />
                            </Col>
                            <Col className="manage_modal_col" span={4}>
                                <span className="manage_info_label">
                                    <div className="must_input_star"></div>座机
                                </span>
                                <Input className="manage_modal_input" size="default" placeholder=""
                                    ref="input_phone" defaultValue={objSelectedRow.phone} />
                            </Col>
                            <Col className="manage_modal_col" span={4}>
                                <span className="manage_info_label">
                                    <div className="must_input_star"></div>手机
                                </span>
                                <Input className="manage_modal_input" size="default" placeholder=""
                                    ref="input_cell_phone" defaultValue={objSelectedRow.cell_phone} />
                            </Col>
                        </Row>
                        <Row className="manage_modal_row" type="flex" justify="start">
                            <Col className="manage_modal_col" span={4}>
                                <span className="manage_info_label">
                                    <div className="must_input_star"></div>邮箱
                                </span>
                                <Input className="manage_modal_input" size="default" placeholder=""
                                    ref="input_email" defaultValue={objSelectedRow.email} />
                            </Col>
                            <Col className="manage_modal_col" span={4}>
                                <span className="manage_info_label">
                                    <div className="must_input_star"></div>微信
                                </span>
                                <Input className="manage_modal_input" size="default" placeholder=""
                                    ref="input_wechat" defaultValue={objSelectedRow.wechat} />
                            </Col>
                            <Col className="manage_modal_col" span={4}>
                                <span className="manage_info_label">
                                    <div className="must_input_star"></div>QQ
                                </span>
                                <Input className="manage_modal_input" size="default" placeholder=""
                                    ref="input_qq" defaultValue={objSelectedRow.qq} />
                            </Col>
                        </Row>
                    </div>
                    {/* 商家描述和备注 */}
                    <div className="manage_model_box">
                        <Row className="manage_modal_row" type="flex" justify="start">
                            <Col className="manage_modal_col" span={4}>
                                <span className="manage_info_label" style={{ marginBottom: 'auto', marginTop: "5px" }}><div className="must_input_star"></div>标语</span>
                                <Input className="manage_modal_input manage_long_input"
                                    size="default" placeholder="" ref="input_slogan"
                                    defaultValue={objSelectedRow.slogan} />
                            </Col>
                        </Row>
                        <Row className="manage_modal_row" type="flex" justify="start">
                            <Col className="manage_modal_col" span={4}>
                                <span className="manage_info_label" style={{ marginBottom: 'auto', marginTop: "5px" }}><div className="must_input_star"></div>描述</span>
                                {/* <Input className="manage_modal_input manage_long_input"
                                    size="default" placeholder="" ref="input_description"
                                    defaultValue={objSelectedRow.description} /> */}
                                <TextArea className='manage_modal_input_area' rows={2} size="default"
                                    placeholder="" ref="input_description" defaultValue={objSelectedRow.description} />
                            </Col>
                        </Row>
                        <Row className="manage_modal_row" type="flex" justify="start">
                            <Col className="manage_modal_col" span={4}>
                                <span className="manage_info_label" style={{ marginBottom: 'auto', marginTop: "5px" }}><div className="must_input_star"></div>备注</span>
                                <Input className="manage_modal_input manage_long_input"
                                    size="default" placeholder="" ref="input_comment"
                                    defaultValue={objSelectedRow.comment} />
                            </Col>
                        </Row>
                    </div>
                </Modal>
            );
            if (this.state.iDlgType === 2) {
                strDlgTitle = "删除商家";
                let strStoresName = "";
                if (this.state.bShowDlg) {
                    for (let i = 0; i < this.arrSelectedStoreIndex.length; i++) {
                        strStoresName += '<' + this.arrStoresData[this.arrSelectedStoreIndex[i]].store_name
                            + '> ';
                    }
                }
                uiModal = (
                    <Modal width={500} title={strDlgTitle} visible={this.state.bShowDlg} okType="danger"
                        onOk={this.DlgOkButtonClick} okText="确定"
                        onCancel={this.DlgCancelButtonClick} cancelText="取消">
                        <div className="modal_item">
                            <div>{strStoresName}<br /><br />选中商家的所有信息都将被删除……<br />确定删除选中的商家？</div>
                        </div>
                    </Modal>
                );
            }
            // 表格勾选框
            const { selectedRowKeys } = this.state;
            const rowSelection = {
                selectedRowKeys,
                onChange: this.OnSelectChange
            };
            // 新建按钮的样式控制
            let strNewButtonStyle = { backgroundColor: 'lightseagreen', color: 'white' };
            if (this.state.btnControlDisable[0])
                strNewButtonStyle = {};
            // let strCopyButtonStyle = { backgroundColor: '#20b2aa', color: 'white', oppacity: '1' };
            // if (this.state.btnCopyPasteDisable[0])
            //     strCopyButtonStyle = { backgroundColor: 'white', color: 'gray', oppacity: '0.5' };
            // 表格分页属性
            const paginationProps = {
                showSizeChanger: true,
                showQuickJumper: false,
                showTotal: () => `共 ${this.state.storesData.length} 个商家`,
                defaultPageSize: 10,
                defaultCurrent: 0,
                pageSizeOptions: ['10', '20', '30', '40', '50'],
                total: this.state.storesData.length,
                position: 'bottom'
            };

            return (
                <div className="manage_contents">
                    {/* 商店管理页面控制栏 */}
                    <div className="manage_contents_title">
                        {uiStoreSearch}
                        <div className="manage_title_filter_area">
                            <span className="manage_stretch_right_tiny">地区: </span>
                            <Dropdown className="manage_contents_title_margin manage_stretch_right"
                                overlay={uiLocationMenu} placement="bottomLeft" trigger={['click']}>
                                <Button>
                                    {this.arrSetLocations[this.state.objFilter.iCurrentLocation]}
                                    <Icon type="caret-down" />
                                </Button>
                            </Dropdown>
                            <span className="manage_stretch_right_tiny">主分类: </span>
                            <Dropdown className="manage_contents_title_margin manage_stretch_right"
                                overlay={uiMainCategoryMenu} placement="bottomLeft" trigger={['click']}>
                                <Button>
                                    {this.arrSetMainCategory[this.state.objFilter.iCurrentMainCategory]}
                                    <Icon type="caret-down" />
                                </Button>
                            </Dropdown>
                            <span className="manage_stretch_right_tiny">子分类: </span>
                            <Dropdown className="manage_contents_title_margin manage_stretch_right"
                                overlay={uiSubCategoryMenu} placement="bottomLeft" trigger={['click']}>
                                <Button>
                                    {this.arrSetSubCategory[this.state.objFilter.iCurrentMainCategory][this.state.objFilter.iCurrentSubCategory]}
                                    <Icon type="caret-down" />
                                </Button>
                            </Dropdown>
                            <span className="manage_stretch_right_tiny">状态: </span>
                            <Dropdown className="manage_contents_title_margin manage_stretch_right"
                                overlay={uiStatusMenu} placement="bottomLeft" trigger={['click']}>
                                <Button>
                                    {this.arrFilterStoreStatus[this.state.objFilter.iCurrentStoreStatus]}
                                    <Icon type="caret-down" />
                                </Button>
                            </Dropdown>
                            {/* <span className="manage_stretch_right_tiny">排序</span>
                            <Dropdown className="manage_contents_title_margin manage_stretch_right_tiny"
                                overlay={uiHotMenu} placement="bottomLeft">
                                <Button> {this.arrSortHot[this.state.objFilter.iCurrentSortHot]} </Button>
                            </Dropdown>
                            <Dropdown className="manage_contents_title_margin manage_stretch_right"
                                overlay={uiRegistTimeMenu} placement="bottomLeft">
                                <Button>{this.arrSortRegistTime[this.state.objFilter.iCurrentSortRegistTime]}</Button>
                            </Dropdown> */}

                        </div>
                        <div className="manage_title_button_right_side">
                            <Button className="manage_contents_title_margin" style={strNewButtonStyle} onClick={this.ShowDlg.bind(this, 0)} disabled={this.state.btnControlDisable[0]}>
                                <Icon type="plus" />新建</Button>
                            <Button className="manage_contents_title_margin" type="primary" onClick={this.ShowDlg.bind(this, 1)} disabled={this.state.btnControlDisable[1]}>
                                <Icon type="edit" /> 编辑</Button>
                            <Button className="manage_contents_title_margin" type="danger" onClick={this.ShowDlg.bind(this, 2)} disabled={this.state.btnControlDisable[2]}>
                                <Icon type="close" /> 删除</Button>
                        </div>
                        {/* <div className="manage_title_button_right_side" style={{ marginLeft: '20px', marginRight: '20px' }}>
                            <Button className="manage_contents_title_margin" style={strCopyButtonStyle}
                                onClick={this.CopyStore} disabled={this.state.btnCopyPasteDisable[0]}>
                                <Icon type="copy" />复制</Button>
                            <Button className="manage_contents_title_margin" type="primary" onClick={this.PasteStore} disabled={this.state.btnCopyPasteDisable[1]}>
                                <Icon type="edit" /> 粘贴</Button>
                        </div> */}
                    </div>
                    {uiModal}
                    {/* 表格主体 */}
                    <Table className="manage_table_contents" rowSelection={rowSelection} pagination={paginationProps}
                        columns={TableColumns} dataSource={this.state.storesData}
                        onRow={(record) => ({
                            onClick: () => {
                                this.selectRow(record);
                            },
                        })} />
                </div>
            );
        } else {
            return (
                <div className="manage_contents">
                    <div className="manage_contents_title">
                        {uiStoreSearch}
                    </div>
                    <Spin className="manage_empty_loading" size="large" tip="加载中……" />
                </div>
            );
        }
    }
}
export default StoreManagement;
