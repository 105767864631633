import React, { Component } from 'react';
import ReactToPrint from "react-to-print";
import Barcode from 'react-barcode';

import axios from 'axios';
import XLSX from 'xlsx';

import Spin from 'antd/es/spin';
import Table from 'antd/es/table';
import Button from 'antd/es/button';
import Input from 'antd/es/input';
import Icon from 'antd/es/icon';
import Modal from 'antd/es/modal';
import Menu from 'antd/es/menu';
import Dropdown from 'antd/es/dropdown';
import Checkbox from 'antd/es/checkbox';
import Timeline from 'antd/es/timeline';
import message from 'antd/es/message';

import "antd/dist/antd.css";
import '../../../styles/Background/ego_manager.css';
import '../../../styles/Background/UI_Module/user_management.css';
import '../../../styles/Background/UI_Module/express_enter.css';
import '../../../styles/Background/UI_Module/ca_cn_order.css';
import { UserActiveUpdate } from "../../../functions/DataStatistic.js";
import { GetCNProvince } from "../../../functions/CNCity";
import { GetCNCity } from "../../../functions/CNCity";
import { GetCNArea } from "../../../functions/CNCity";

const g_strMessageKey = 'updating';

// 表格
const arrTableHeader = [
    { title: '运单号', dataIndex: 'order_no', width: 125, ellipsis: true },
    { title: '状态', dataIndex: 'status_str', width: 65, ellipsis: true },
    { title: '线路', dataIndex: 'transit_type_str', width: 100, ellipsis: true },
    { title: '用户信息', dataIndex: 'user_info', width: 125, ellipsis: true },
    { title: '发件人', dataIndex: 'shipper_name', width: 90, ellipsis: true },
    { title: '收件人', dataIndex: 'receiver_name', width: 90, ellipsis: true },
    { title: '物品信息', dataIndex: 'item_info_str', width: 125, ellipsis: true },
    { title: '重量(lb)', dataIndex: 'weight', width: 70, ellipsis: true },
    { title: '身份证', dataIndex: 'id_status', width: 75, ellipsis: true },
    { title: '创建日期', dataIndex: 'order_date_str', width: 90, ellipsis: true },
    { title: '编辑', dataIndex: 'edit_btn', width: 55, ellipsis: true }
];
const arrTableHeader_m = [
    { title: '运单编号', dataIndex: 'order_no', width: 125, ellipsis: true },
    { title: '用户信息', dataIndex: 'receiver', width: 125, ellipsis: true },
    { title: '物流单号', dataIndex: 'express_order', width: 175, ellipsis: true },
    { title: '物品信息', dataIndex: 'item_info', width: 200, ellipsis: true },
    { title: '运单状态', dataIndex: 'status_check', width: 65, ellipsis: true },
    { title: '备注信息', dataIndex: 'comments', width: 200, ellipsis: true }
];

// 将一个sheet转成最终的excel文件的blob对象，然后利用URL.createObjectURL下载
function sheet2blob(sheet, sheetName) {
    sheetName = sheetName || 'sheet1';
    var workbook = {
        SheetNames: [sheetName],
        Sheets: {}
    };
    workbook.Sheets[sheetName] = sheet;
    // 生成excel的配置项
    var wopts = {
        bookType: 'xlsx', // 要生成的文件类型
        bookSST: false, // 是否生成Shared String Table，官方解释是，如果开启生成速度会下降，但在低版本IOS设备上有更好的兼容性
        type: 'binary'
    };
    var wbout = XLSX.write(workbook, wopts);
    var blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });
    // 字符串转ArrayBuffer
    function s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
    }
    return blob;
}
//  通用的打开下载对话框方法，没有测试过具体兼容性
//  @param url 下载地址，也可以是一个blob对象，必选
//  @param saveName 保存文件名，可选
function openDownloadDialog(url, saveName) {
    if (typeof url == 'object' && url instanceof Blob) {
        url = URL.createObjectURL(url); // 创建blob地址
    }
    var aLink = document.createElement('a');
    aLink.href = url;
    aLink.download = saveName || ''; // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，file:///模式下不会生效
    var event;
    if (window.MouseEvent) event = new MouseEvent('click');
    else {
        event = document.createEvent('MouseEvents');
        event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
    }
    aLink.dispatchEvent(event);
}

class CACNOrder extends Component {
    constructor(props) {
        super();
        this.strToday = new Date();
        this.state = {
            btnControlDisable: [false, true, true, true, true, true, true],  // 操作按钮控制
            btnCopyPasteDisable: [true, true],  // 操作按钮控制
            selectedRowKeys: [],  // Check here to configure the default column
            iDlgType: -1,
            bShowDlg: false,
            bLoading: true,
            iExpressType: 1,
            iExpressTypeFilter: 0,
            iPackage: 0,
            iTransitType: 0,
            bItemValueChange: false,
            iStatusFilter: 0,
            iCurrentStatus: 0,
            bMultiEditDlg: false,
            bDeliveryStatus: true,
            selectedFile1: null,
            selectedFile2: null,
            bFileDelete: false,
            bSelectShipperDlg: false,
            bSelectReceiverDlg: false,
            iCurrentStorage: 0,
            arrMultiEditCheck: [false, false, false],
            iCurrentProvince: 0,
            iCurrentCity: 0,
            iCurrentArea: 0,
            bShipperReceiverFilter: false,
            bViewTrackingInfo: false,
            bEditTrackingInfo: false,
            bPrintDlg: false
        };
        // 成员变量
        this.arrTableDataOge = [];  // 原始运单
        this.arrTableData = [];  // 过滤后的运单
        this.objCurrentCACNOrder = {};
        this.objCurrentCACNOrder.item_info = [{ name: '', type: 0, sub_type: 0, num: 1, brand: '', price: '' }];
        this.arrSelectedCACNOrder = [];
        this.tableHead = arrTableHeader;
        if (props.bMStation)
            this.tableHead = arrTableHeader_m;
        // 运输线路
        // this.arrExpressTypeFilter = ["全部", "奶粉", "保健品、食品", "大货", "其他"];
        // this.iExpressTypeFilter = 0;
        // this.arrExpressType = ["奶粉", "保健品、食品", "大货", "其他"];
        this.strYear = this.strToday.toISOString().split('-')[0];
        this.strMonth = this.strToday.toISOString().split('-')[1];
        this.strDay = this.strToday.toISOString().split('-')[2].split('T')[0];

        this.strOrderNoKeyWord = "";
        this.strUserKeyWord = "";
        this.strItemKeyWord = "";
        this.arrTransitTypeFilter = ["全部", "奶粉", "保健品、食品", "其他"];
        this.arrTransitType = ["奶粉", "保健品、食品", "其他"];
        this.arrStatusFilter = ['全部', '待处理', '待发货', '已发货'];
        this.arrStatusChange = ['待处理', '待发货', '已发货'];
        this.arrStorage = ['Richmond仓库'];

        this.arrOrderStatusUpdate = [];
        this.arrCurrentOrderExpressDetail = [];
        this.iCurrentTrackingInfo = 0;

        // this.arrItemType = [
        //     { name: '奶粉', sub_type: ['奶粉', '奶粉-快线'] },
        //     { name: '保健品', sub_type: ['普通保健品', '保健品-快线'] },
        //     { name: '电子产品', sub_type: ['电子产品', '电子产品-快线'] },
        //     { name: '食品', sub_type: ['食品', '海参', '食品-快线'] },
        //     { name: '家居用品', sub_type: ['普通-家居用品', '家居用品-快线', '普货线', '特货线', '轻奢 家用品'] },
        //     { name: '洗护用品', sub_type: ['洗护用品-普通品牌', '洗护用品-普通用品-快线', '轻奢 洗护用品'] },
        //     { name: '护肤品', sub_type: ['护肤品-高端品牌', '护肤品-高端品牌-快线'] },
        //     { name: '彩妆', sub_type: ['彩妆'] },
        //     { name: '普通品牌服装', sub_type: ['普通 短袖/内衣裤', '衣服-快线', '外套风衣裤子'] },
        //     { name: '首饰', sub_type: ['首饰'] },
        //     { name: '鞋/包/箱包/穿戴配饰', sub_type: ['鞋', '包', '鞋-快线', '包-快线', '围巾/帽子/皮带/领带', '旅行箱'] },
        //     { name: '数码', sub_type: ['数码'] },
        //     { name: '个人行李', sub_type: ['回国行李'] },
        //     { name: '酒', sub_type: ['酒'] },
        //     { name: '加拿大鹅/奢侈品服装', sub_type: ['奢侈品外套', '奢侈品外套-快线'] },
        //     { name: '手表', sub_type: ['手表', '手表-快线'] },
        //     { name: '护肤品-高端品牌', sub_type: ['香水'] }
        // ];
        this.arrItemType = [
            { name: '奶粉', sub_type: ['奶粉', '奶粉-快线'] },
            { name: '普通 短袖/内衣裤', sub_type: ['普通保健品', '保健品-快线'] },
            { name: '普通保健品', sub_type: ['普通保健品', '保健品-快线'] },
            { name: '食品', sub_type: ['食品', '海参', '食品-快线'] },
            { name: '鞋', sub_type: ['鞋', '包', '鞋-快线', '包-快线', '围巾/帽子/皮带/领带', '旅行箱'] },
            { name: '洗护用品-普通品牌', sub_type: ['洗护用品-普通品牌', '洗护用品-普通用品-快线', '轻奢 洗护用品'] },
            { name: '护肤品-高端品牌', sub_type: ['护肤品-高端品牌', '护肤品-高端品牌-快线'] },
            { name: '普通-家居用品', sub_type: ['普通-家居用品', '家居用品-快线', '普货线', '特货线', '轻奢 家用品'] },
            { name: '包', sub_type: ['鞋', '包', '鞋-快线', '包-快线', '围巾/帽子/皮带/领带', '旅行箱'] },
            { name: '奢侈品外套', sub_type: ['奢侈品外套', '奢侈品外套-快线'] },
            { name: '电子产品', sub_type: ['电子产品', '电子产品-快线'] },
            { name: '手表', sub_type: ['手表', '手表-快线'] },
            { name: '海参', sub_type: ['首饰'] },
            { name: '直销产品', sub_type: ['首饰'] },
            { name: '外套风衣裤子', sub_type: ['首饰'] },
            { name: '围巾/帽子/皮带/领带', sub_type: ['首饰'] },
            { name: '轻奢 家用品', sub_type: ['首饰'] },
            { name: '香水', sub_type: ['首饰'] },
            { name: '首饰', sub_type: ['首饰'] },
            { name: '旅行箱', sub_type: ['回国行李'] },
            { name: '轻奢 洗护用品', sub_type: ['香水'] },
            { name: '回国行李', sub_type: ['彩妆'] }
        ];

        this.bSubmitting = false;

        this.arrFileWaitList = [];
        this.bDeletingFile = false;

        this.iCurrentPage = 1;
        this.iTotalOrderNum = 0;
        this.iTotalPage = 0;
        this.iNumPerPage = 10;
        this.objCurrentShipper = {};
        this.objCurrentReceiver = {};

        this.bEditCACNOrderButtonClick = false;
        this.bDoubleClick = false;

        this.objChinaCity = {};

        this.arrShipperShow = [];
        this.arrReceiverShow = [];

    }

    // 选中某行的响应函数
    selectRow = (record) => {
        const selectedRowKeys = [...this.state.selectedRowKeys];
        if (selectedRowKeys.indexOf(record.key) >= 0) {
            selectedRowKeys.splice(selectedRowKeys.indexOf(record.key), 1);
        } else {
            selectedRowKeys.push(record.key);
        }
        this.SelectedRowStateSetting(selectedRowKeys, false);
    }
    // 双击某行的响应函数
    // DoubleClickRow = (record) => {
    //     // console.log("Double Click Row : ", record);
    //     // const selectedRowKeys = [...this.state.selectedRowKeys];
    //     // if (selectedRowKeys.indexOf(record.key) >= 0) {
    //     //     selectedRowKeys.splice(selectedRowKeys.indexOf(record.key), 1);
    //     // } else {
    //     //     selectedRowKeys.push(record.key);
    //     // }
    //     const selectedRowKeys = [record.key];
    //     this.SelectedRowStateSetting(selectedRowKeys, true);
    // }
    // 选中的行发生变化的响应函数
    onSelectedRowKeysChange = (selectedRowKeys) => {
        this.SelectedRowStateSetting(selectedRowKeys, false);
    }
    // 处理选中的行
    SelectedRowStateSetting = (selectedRowKeys, bDoubleClick) => {
        let btnControlDisable = [];
        let btnCopyPasteDisable = [];

        switch (selectedRowKeys.length) {
            case 0: {
                if (this.bEditCACNOrderButtonClick)
                    break;
                this.arrSelectedCACNOrder = [];
                btnControlDisable = [false, true, true, true, true, true, true];
                btnCopyPasteDisable = [true, true];
                this.objCurrentCACNOrder = {};
                break;
            }
            case 1: {
                btnControlDisable = [false, false, false, false, true, false, true];
                btnCopyPasteDisable = [false, true];
                this.arrSelectedCACNOrder = [];
                for (let i = 0; i < this.arrTableData.length; i++) {
                    if (this.arrTableData[i].key === selectedRowKeys[0]) {
                        let objCACNOrder = JSON.stringify(this.arrTableData[i]);
                        this.objCurrentCACNOrder = JSON.parse(objCACNOrder);
                        this.arrSelectedCACNOrder.push(this.arrTableData[i]);
                        break;
                    }
                }
                break;
            }
            default: {
                btnControlDisable = [false, true, false, true, true, false, false];
                btnCopyPasteDisable = [true, true];
                this.arrSelectedCACNOrder = [];
                for (let j = 0; j < selectedRowKeys.length; j++) {
                    for (let i = 0; i < this.arrTableData.length; i++) {
                        if (this.arrTableData[i].key === selectedRowKeys[j]) {
                            this.arrSelectedCACNOrder.push(this.arrTableData[i]);
                            break;
                        }
                    }
                }
                break;
            }
        }
        this.setState({
            ...this.state,
            selectedRowKeys,
            btnControlDisable: btnControlDisable,
            btnCopyPasteDisable: btnCopyPasteDisable
        });
        // if (bDoubleClick)
        //     this.ShowDlg(1);
        if (this.bEditCACNOrderButtonClick) {
            console.log("Fast Edit Order : ", this.objCurrentCACNOrder, this.arrSelectedCACNOrder);
            this.ShowDlg(1);
        }

    }
    //弹出对话框
    ShowDlg = (iDlgType, e) => {
        this.bEditCACNOrderButtonClick = false;
        if (this.refs.file_upload1 !== undefined) {
            document.getElementById("file_upload1").value = "";

        }
        if (this.refs.file_upload2 !== undefined) {
            document.getElementById("file_upload2").value = "";

        }
        if (iDlgType === 0) {
            if (this.refs.order_no) {
                this.refs.order_no.state.value = "";
                this.refs.user_code.state.value = "";
                this.refs.user_name.state.value = "";
                this.refs.tracking_no.state.value = "";
                this.refs.batch_no.state.value = "";

                this.refs.shipper_name.state.value = "";
                this.refs.shipper_phone.state.value = "";
                this.refs.shipper_address.state.value = "";

                this.refs.receiver_name.state.value = "";
                this.refs.receiver_phone.state.value = "";
                this.refs.receiver_address.state.value = "";
                this.refs.receiver_poscode.state.value = "";
                this.refs.id_number.value = "";


                this.refs.weight.state.value = "";
                this.refs.insurance.state.value = "";
                this.refs.other_fee.state.value = "";
                this.refs.comments.state.value = "";

                // this.refs.express_detail.state.value = "";

            }
            this.objCurrentReceiver.id_s3_link1 = null;
            this.objCurrentReceiver.id_s3_link2 = null;
            this.objCurrentCACNOrder = {};
            this.objCurrentCACNOrder.item_info = [{ name: '', type: 0, sub_type: 0, num: 1, brand: '', price: '' }];
            this.GetCNAreaInfo(0, 0);
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true,
                iCurrentStatus: 0,
                iTransitType: 0,
                iCurrentStorage: 0,
                selectedFile1: null,
                selectedFile2: null,
                iCurrentProvince: 0,
                iCurrentCity: 0,
                iCurrentArea: 0
            });
        }
        if (iDlgType === 1) {
            if (this.objCurrentCACNOrder.express_detail !== undefined && this.objCurrentCACNOrder.express_detail !== null &&
                this.objCurrentCACNOrder.express_detail !== '')
                this.arrCurrentOrderExpressDetail = JSON.parse(this.objCurrentCACNOrder.express_detail);
            if (this.refs.comments !== undefined) {

                this.refs.order_no.state.value = this.objCurrentCACNOrder.order_no;
                this.refs.user_code.state.value = this.objCurrentCACNOrder.user_code;
                this.refs.user_name.state.value = this.objCurrentCACNOrder.user_name;
                this.refs.tracking_no.state.value = this.objCurrentCACNOrder.tracking_no;
                this.refs.batch_no.state.value = this.objCurrentCACNOrder.batch_no;

                this.refs.shipper_name.state.value = this.objCurrentCACNOrder.shipper_name;
                this.refs.shipper_phone.state.value = this.objCurrentCACNOrder.shipper_phone;
                this.refs.shipper_address.state.value = this.objCurrentCACNOrder.shipper_address;

                this.refs.receiver_name.state.value = this.objCurrentCACNOrder.receiver_name;
                this.refs.receiver_phone.state.value = this.objCurrentCACNOrder.receiver_phone;
                this.refs.receiver_address.state.value = this.objCurrentCACNOrder.receiver_address;
                this.refs.receiver_postcode.state.value = this.objCurrentCACNOrder.receiver_postcode;

                this.refs.weight.state.value = this.objCurrentCACNOrder.weight;
                this.refs.insurance.state.value = this.objCurrentCACNOrder.insurance;
                this.refs.other_fee.state.value = this.objCurrentCACNOrder.other_fee;
                this.refs.comments.state.value = this.objCurrentCACNOrder.comments;

                // this.refs.express_detail.state.value = this.objCurrentCACNOrder.express_detail;

            }
            this.GetUserShipperReceiverInfo();
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true,
                iCurrentStatus: this.objCurrentCACNOrder.status,
                iTransitType: this.objCurrentCACNOrder.transit_type,
                iCurrentStorage: this.objCurrentCACNOrder.storage,
                selectedFile1: null,
                selectedFile2: null,
                // iCurrentProvince: this.objCurrentCACNOrder.province,
                // iCurrentCity: 0,
                // iCurrentArea: 0
            });
        }
        if (iDlgType === 2) {
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true
            });
        }
        if (iDlgType === 3) {
            this.setState({
                ...this.state,
                bMultiEditDlg: true
            });
        }
        if (iDlgType === 4) {
            let bStatusCheck = true;
            for (let i = 0; i < this.arrSelectedCACNOrder.length; i++) {
                console.log(this.arrSelectedCACNOrder[i].express_info);
                if (this.arrSelectedCACNOrder[i].status !== 1) {
                    bStatusCheck = false; break;
                }
                if (this.arrSelectedCACNOrder[i].express_info === null || this.arrSelectedCACNOrder[i].express_info === "") {
                    message.warning('运单的物品信息不能为空，请检查！');
                    bStatusCheck = false; return;
                } else {
                    if (this.arrSelectedCACNOrder[i].express_info.item_info.length <= 0) {
                        message.warning('运单的物品信息不能为空，请检查！');
                        bStatusCheck = false; return;
                    }
                }
            }
            if (bStatusCheck) {
                if (this.arrSelectedCACNOrder.length > 1) {
                    // 是否来自同一个用户的判断
                    let bSameUser = true;
                    let strUserInfo = this.arrSelectedCACNOrder[0].user_info;
                    for (let i = 1; i < this.arrSelectedCACNOrder.length; i++) {
                        if (this.arrSelectedCACNOrder[i].user_info !== strUserInfo) {
                            bSameUser = false; break;
                        }
                    }
                    if (bSameUser) {
                        this.props.CreatePackageCombine(this.arrSelectedCACNOrder);
                    } else
                        message.warning("请选择同一个用户的运单！");
                } else
                    this.props.CreatePackageCombine(this.arrSelectedCACNOrder);
            } else
                message.warning('请选择已到库的运单！');
        }
    }
    // 获取新输入的信息
    GetNewInputInfo = () => {
        let strUserCode = this.refs.user_code.state.value;
        let strUserName = this.refs.user_name.state.value;
        if (this.state.iDlgType === 1) {
            strUserCode = this.objCurrentCACNOrder.user_code;
            strUserName = this.objCurrentCACNOrder.user_name;
        }


        let strTrackingNo = this.refs.tracking_no.state.value;
        let strBatchNo = this.refs.batch_no.state.value;
        let strWeight = this.refs.item_weight.state.value;
        let strInsurance = this.refs.insurance.state.value;
        let strOtherFee = this.refs.other_fee.state.value;
        let strComments = this.refs.comments.state.value;

        if (strUserCode === undefined || strUserCode === null || strUserCode === "") {
            message.warning("用户编码不能为空！"); this.bSubmitting = false; return -1;
        }

        let strShipperName = this.refs.shipper_name.state.value;
        let strShipperPhone = this.refs.shipper_phone.state.value;
        let strShipperAddress = this.refs.shipper_address.state.value;
        let strReceiverName = this.refs.receiver_name.state.value;
        let strReceiverPhone = this.refs.receiver_phone.state.value;
        let strReceiverAddress = this.refs.receiver_address.state.value;
        let strReceiverPostcode = this.refs.receiver_postcode.state.value;
        if (strShipperName === undefined || strShipperName === null || strShipperName === "") {
            message.warning("发件人姓名不能为空！"); this.bSubmitting = false; return -1;
        }
        if (strShipperPhone === undefined || strShipperPhone === null || strShipperPhone === "") {
            message.warning("发件人电话不能为空！"); this.bSubmitting = false; return -1;
        }
        if (strReceiverName === undefined || strReceiverName === null || strReceiverName === "") {
            message.warning("收件人姓名不能为空！"); this.bSubmitting = false; return -1;
        }
        if (strReceiverPhone === undefined || strReceiverPhone === null || strReceiverPhone === "") {
            message.warning("收件人电话不能为空！"); this.bSubmitting = false; return -1;
        }
        if (strReceiverAddress === undefined || strReceiverAddress === null || strReceiverAddress === "") {
            message.warning("收件人地址不能为空！"); this.bSubmitting = false; return -1;
        }
        if (strReceiverPostcode === undefined || strReceiverPostcode === null || strReceiverPostcode === "") {
            // message.warning("收件人邮编不能为空！"); this.bSubmitting = false; return -1;
            strReceiverPostcode = "";
        }
        let iItemCheck = this.ItemInfoCheck();
        if (iItemCheck === 1) {
            this.objCurrentCACNOrder.item_info = JSON.stringify(this.objCurrentCACNOrder.item_info);
        }
        else
            return -1;


        if (this.state.iDlgType === 0)
            this.objCurrentCACNOrder.order_no = "";
        this.objCurrentCACNOrder.tracking_no = strTrackingNo;
        this.objCurrentCACNOrder.batch_no = strBatchNo;
        this.objCurrentCACNOrder.user_code = strUserCode;
        this.objCurrentCACNOrder.user_name = strUserName;
        this.objCurrentCACNOrder.transit_type = this.state.iTransitType;
        this.objCurrentCACNOrder.status = this.state.iCurrentStatus;
        this.objCurrentCACNOrder.storage = this.state.iCurrentStorage;

        if (this.objCurrentShipper !== undefined && this.objCurrentShipper !== null) {
            if (this.objCurrentShipper.id !== undefined && this.objCurrentShipper.id !== null && this.objCurrentShipper.id !== 0 && this.objCurrentShipper.id !== '')
                this.objCurrentCACNOrder.shipper_id = this.objCurrentShipper.id;
            else
                console.log("当前发件人的id为空！", this.objCurrentShipper);
            this.objCurrentShipper.name = strShipperName;
            this.objCurrentShipper.phone = strShipperPhone;
            this.objCurrentShipper.address = strShipperAddress;
            // this.SaveShipperReceiver(0);
        }
        // else {
        //     let iResult = this.SaveShipperReceiver(0);
        //     if (iResult === -1)
        //         return -1;
        // }
        this.objCurrentCACNOrder.shipper_name = this.objCurrentShipper.id;

        if (this.refs.id_number !== undefined) {
            if (this.refs.id_number.state.value !== null && this.refs.id_number.state.value !== "" &&
                this.objCurrentReceiver.id_s3_link1 !== null && this.objCurrentReceiver.id_s3_link2 !== null) {
                this.objCurrentCACNOrder.photo_id = 1;
            }
            this.objCurrentCACNOrder.id_number = this.objCurrentReceiver.id_number;
        }
        if (this.objCurrentReceiver !== undefined && this.objCurrentReceiver !== null) {
            if (this.objCurrentReceiver.id !== undefined && this.objCurrentReceiver.id !== null && this.objCurrentReceiver.id !== 0 && this.objCurrentReceiver.id !== '')
                this.objCurrentCACNOrder.receiver_id = this.objCurrentReceiver.id;
            else
                console.log("当前收件人的id为空！", this.objCurrentReceiver);
            this.objCurrentReceiver.name = strReceiverName;
            this.objCurrentReceiver.phone = strReceiverPhone;
            this.objCurrentReceiver.address = strReceiverAddress;
            this.objCurrentReceiver.postcode = strReceiverPostcode;
            this.objCurrentReceiver.province = this.state.iCurrentProvince;
            this.objCurrentReceiver.city = this.state.iCurrentCity;
            this.objCurrentReceiver.area = this.state.iCurrentArea;
            // this.SaveShipperReceiver(1);
        }
        // else {
        //     let iResult = this.SaveShipperReceiver(1);
        //     if (iResult === -1)
        //         return -1;
        // }
        // this.objCurrentCACNOrder.shipper_name = strShipperName;
        // this.objCurrentCACNOrder.shipper_phone = strShipperPhone;
        // this.objCurrentCACNOrder.shipper_address = strShipperAddress;
        // this.objCurrentCACNOrder.receiver_id = this.objCurrentReceiver.id;
        // this.objCurrentCACNOrder.receiver_name = strReceiverName;
        // this.objCurrentCACNOrder.receiver_phone = strReceiverPhone;
        // this.objCurrentCACNOrder.receiver_address = strReceiverAddress;
        // this.objCurrentCACNOrder.receiver_postcode = strReceiverPostcode;
        // this.objCurrentCACNOrder.province = this.state.iCurrentProvince;
        // this.objCurrentCACNOrder.city = this.state.iCurrentCity;
        // this.objCurrentCACNOrder.area = this.state.iCurrentArea;
        // this.objCurrentCACNOrder.photo_id = this.objCurrentReceiver.photo_id;

        this.objCurrentCACNOrder.weight = strWeight;
        this.objCurrentCACNOrder.insurance = strInsurance;
        this.objCurrentCACNOrder.other_fee = strOtherFee;
        this.objCurrentCACNOrder.comments = strComments;
        // this.objCurrentCACNOrder.express_detail = "";//this.refs.express_detail.state.value;

        return 1;
    }
    // 新建/更新订单
    CreateUpdateOrder = (iAction) => {
        if (iAction === 0) {
            this.iExpressTypeFilter = 0;
            delete this.objCurrentCACNOrder.id;
            this.objCurrentCACNOrder.store_id = this.props.iStoreID;
            delete this.objCurrentCACNOrder.key;
            delete this.objCurrentCACNOrder.rowKey;
            delete this.objCurrentCACNOrder.status_check;
            delete this.objCurrentCACNOrder.package_check;
            delete this.objCurrentCACNOrder.delivery_status_str;
            delete this.objCurrentCACNOrder.order_date_str;
            delete this.objCurrentCACNOrder.user_info;
            delete this.objCurrentCACNOrder.item_info_str;
            delete this.objCurrentCACNOrder.transit_type_str;
            delete this.objCurrentCACNOrder.status_str;
            delete this.objCurrentCACNOrder.weight_str;
            delete this.objCurrentCACNOrder.id_status;
            delete this.objCurrentCACNOrder.edit_btn;
            message.loading({ content: '新的运单创建中……', key: g_strMessageKey });
            axios.post('/NewCACNOrder', {
                objCACNOrder: this.objCurrentCACNOrder
            }).then(({ data }) => {
                if (data.status === 1) {
                    this.setState({
                        ...this.state,
                        iExpressTypeFilter: 0,
                        bShowDlg: false
                    });
                    this.GetCACNOrderData();
                } else {
                    message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
                    this.bSubmitting = false;
                    if (typeof (this.objCurrentCACNOrder.item_info) === 'string')
                        this.objCurrentCACNOrder.item_info = JSON.parse(this.objCurrentCACNOrder.item_info);
                    this.setState({
                        ...this.state,
                        iExpressTypeFilter: 0
                    });
                }
            }).catch(function (error) { console.log(error); });
        }
        if (iAction === 1) {
            this.iExpressTypeFilter = 0;
            this.objCurrentCACNOrder.store_id = this.props.iStoreID;
            delete this.objCurrentCACNOrder.key;
            delete this.objCurrentCACNOrder.rowKey;
            delete this.objCurrentCACNOrder.status_check;
            delete this.objCurrentCACNOrder.package_check;
            delete this.objCurrentCACNOrder.delivery_status_str;
            delete this.objCurrentCACNOrder.order_date_str;
            delete this.objCurrentCACNOrder.user_info;
            delete this.objCurrentCACNOrder.item_info_str;
            delete this.objCurrentCACNOrder.transit_type_str;
            delete this.objCurrentCACNOrder.status_str;
            delete this.objCurrentCACNOrder.weight_str;
            delete this.objCurrentCACNOrder.id_status;
            delete this.objCurrentCACNOrder.edit_btn;
            message.loading({ content: '正在更新运单信息……', key: g_strMessageKey });
            axios.post('/UpdateCACNOrder', {
                objCACNOrder: this.objCurrentCACNOrder
            }).then(({ data }) => {
                this.setState({
                    ...this.state,
                    bShowDlg: false
                })
                // DataStatistic
                UserActiveUpdate(4, { active: "物流后台:运单管理操作", action: "编辑" });
                this.GetCACNOrderData();
            }).catch(function (error) { console.log(error); });
        }
    }
    // 弹窗确认按钮响应
    handleOk = e => {
        if (this.bSubmitting)
            return;
        this.bSubmitting = true;
        // 新建运单
        if (this.state.iDlgType === 0) {
            if (this.GetNewInputInfo() === -1) {
                this.bSubmitting = false; return;
            }
            if (this.CheckShipperReceiver(0) === -1) {
                this.bSubmitting = false; return;
            }
            if (this.CheckShipperReceiver(1) === -1) {
                this.bSubmitting = false; return;
            }
            this.SaveShipperReceiver();
            // this.CreateUpdateOrder(0);
        }
        // 编辑运单
        if (this.state.iDlgType === 1) {
            if (this.GetNewInputInfo() === -1) {
                this.bSubmitting = false; return;
            }
            if (this.CheckShipperReceiver(0) === -1) {
                this.bSubmitting = false; return;
            }
            if (this.CheckShipperReceiver(1) === -1) {
                this.bSubmitting = false; return;
            }
            this.SaveShipperReceiver();
            // this.CreateUpdateOrder(1);
        }
        // 删除运单
        if (this.state.iDlgType === 2) {
            let arrDeleteID = [];
            for (let i = 0; i < this.arrSelectedCACNOrder.length; i++)
                arrDeleteID.push(this.arrSelectedCACNOrder[i].id);
            message.loading({ content: '运单删除中……', key: g_strMessageKey });
            axios.post('/DeleteCACNOrder', { arrDeleteID: arrDeleteID }).then(
                ({ data }) => {
                    // DataStatistic
                    UserActiveUpdate(4, { active: "管理后台: 用户管理操作", action: "删除" });
                    this.GetCACNOrderData();
                }).catch(function (error) { console.log(error); });
        }
    };
    // 弹窗取消按钮响应
    handleCancel = e => {
        this.arrCurrentOrderExpressDetail = [];
        this.objCurrentCACNOrder = {};
        if (this.refs.file_upload1 !== undefined) {
            document.getElementById("file_upload1").value = "";
        }
        if (this.refs.file_upload2 !== undefined) {
            document.getElementById("file_upload2").value = "";
        }
        this.setState({
            bShowDlg: false,
            bViewTrackingInfo: false,
            selectedRowKeys: [],
            btnControlDisable: [false, true, true, true, true, true, true]
        });
    };
    // 获取用户、收件人、寄件人等信息
    GetUserShipperReceiverInfo = () => {
        let objParms = {
            user_code: this.objCurrentCACNOrder.user_code,
            shipper_id: this.objCurrentCACNOrder.shipper_id,
            receiver_id: this.objCurrentCACNOrder.receiver_id
        };
        axios('/GetUserShipperReceiverInfo', {
            params: objParms
        }).then(({ data }) => {
            console.log("GetUserShipperReceiverInfo : ", data);
            if (data.user_name !== "")
                this.objCurrentCACNOrder.user_name = data.user_name;
            if (data.receiver_info !== null) {
                this.objCurrentReceiver = data.receiver_info;
                this.objCurrentCACNOrder.receiver_name = this.objCurrentReceiver.name;
                this.objCurrentCACNOrder.receiver_phone = this.objCurrentReceiver.phone;
                this.objCurrentCACNOrder.receiver_address = this.objCurrentReceiver.address;
                this.objCurrentCACNOrder.receiver_postcode = this.objCurrentReceiver.postcode;
                if (this.refs.receiver_name !== undefined) {
                    this.refs.receiver_name.state.value = this.objCurrentCACNOrder.receiver_name;
                    this.refs.receiver_phone.state.value = this.objCurrentCACNOrder.receiver_phone;
                    this.refs.receiver_address.state.value = this.objCurrentCACNOrder.receiver_address;
                    this.refs.receiver_postcode.state.value = this.objCurrentCACNOrder.receiver_postcode;
                }
            }
            if (data.shipper_info !== null) {
                this.objCurrentShipper = data.shipper_info;
                this.objCurrentCACNOrder.shipper_name = this.objCurrentShipper.name;
                this.objCurrentCACNOrder.shipper_phone = this.objCurrentShipper.phone;
                this.objCurrentCACNOrder.shipper_address = this.objCurrentShipper.address;
                if (this.refs.shipper_name !== undefined) {
                    this.refs.shipper_name.state.value = this.objCurrentCACNOrder.shipper_name;
                    this.refs.shipper_phone.state.value = this.objCurrentCACNOrder.shipper_phone;
                    this.refs.shipper_address.state.value = this.objCurrentCACNOrder.shipper_address;
                }
            }
            if (this.objCurrentReceiver !== null && this.refs.id_number !== undefined)
                this.refs.id_number.state.value = this.objCurrentReceiver.id_number
            this.GetCNAreaInfo(this.objCurrentReceiver.province, this.objCurrentReceiver.city);
            this.objCurrentCACNOrder.province = this.objCurrentReceiver.province;
            this.objCurrentCACNOrder.city = this.objCurrentReceiver.city;
            this.objCurrentCACNOrder.area = this.objCurrentReceiver.area;
            this.objCurrentCACNOrder.id_number = this.objCurrentReceiver.id_number;
            console.log("current receiver : ", this.objCurrentReceiver);
            this.setState({
                ...this.state,
                bLoading: false,
                iCurrentProvince: this.objCurrentReceiver.province,
                iCurrentCity: this.objCurrentReceiver.city,
                iCurrentArea: this.objCurrentReceiver.area
            });
        }).catch(function (error) {
            console.log(error);
        });
    }
    // 从数据库获取当前的数据结构
    GetCACNOrderData() {
        message.loading({ content: '数据加载中……', key: g_strMessageKey });
        let objParms = {
            order_no_search: this.strOrderNoKeyWord,
            user_search: this.strUserKeyWord,
            item_search: this.strItemKeyWord,
            store_id: this.props.iStoreID,
            user_name: this.props.strUserName,
            page: this.iCurrentPage,
            num: this.iNumPerPage
        };
        axios('/GetCACNOrder', {
            params: objParms
        }).then(({ data }) => {
            console.log("GetCACNOrder : ", data);
            this.bSubmitting = false;
            this.iTotalOrderNum = data.total_num;
            this.iTotalPage = data.total_page;
            this.arrTableData = [];
            this.arrTableData = data.orders;
            if (this.arrTableData.length > 0) {
                for (let i = 0; i < this.arrTableData.length; i++) {
                    this.arrTableData[i].key = i;
                    this.arrTableData[i].rowKey = i;
                    this.arrTableData[i].user_info = this.arrTableData[i].user_name + ' / ' + this.arrTableData[i].user_code;
                    this.arrTableData[i].transit_type_str = this.arrTransitType[this.arrTableData[i].transit_type];
                    if (this.arrTableData[i].order_date !== null)
                        if (this.arrTableData[i].order_date.indexOf('T') >= 0)
                            this.arrTableData[i].order_date_str = this.arrTableData[i].order_date.split('T')[0];
                    if (this.arrTableData[i].item_info === null || this.arrTableData[i].item_info === "")
                        this.arrTableData[i].item_info = [];
                    else {
                        this.arrTableData[i].item_info = JSON.parse(this.arrTableData[i].item_info);
                        if (typeof (this.arrTableData[i].item_info) === 'string') {
                            this.arrTableData[i].item_info = JSON.parse(this.arrTableData[i].item_info);
                        }
                    }
                    if (this.arrTableData[i].item_info.length > 0) {
                        let iItemNum = 0;
                        for (let j = 0; j < this.arrTableData[i].item_info.length; j++)
                            iItemNum += parseInt(this.arrTableData[i].item_info[j].num);
                        this.arrTableData[i].item_info_str = this.arrTableData[i].item_info[0].name +
                            " 等 " + iItemNum + " 件";
                    }
                    this.arrTableData[i].status_str = this.arrStatusChange[this.arrTableData[i].status];
                    if (this.arrTableData[i].photo_id === 1)
                        this.arrTableData[i].id_status = (<div style={{ color: 'darkcyan' }}>已上传 √</div>);
                    else
                        this.arrTableData[i].id_status = (<div style={{ color: 'lightcoral' }}>未上传</div>);
                    this.arrTableData[i].edit_btn = (
                        <Button type={'primary'} onClick={this.EditCACNOrder.bind(this, i)} className="fast_edit_button"
                            style={{ width: '30x', maxWidth: '30px' }}>
                            <Icon type="edit" />
                        </Button>
                    );
                }
                this.arrTableDataOge = this.arrTableData;
            }
            if (this.state.bShowDlg) {
                if (this.state.iDlgType === 0) {
                    message.success({ content: "下单完成！", key: g_strMessageKey, duration: 2 });
                }
                if (this.state.iDlgType === 1) {
                    message.success({ content: "运单信息已更新！", key: g_strMessageKey, duration: 2 });
                    if (this.state.iCurrentStatus !== 0) {
                        this.SwitchStatusFilter(this.state.iStatusFilter);
                    }
                }
                if (this.state.iDlgType === 2) {
                    message.success({ content: "运单信息已删除！", key: g_strMessageKey, duration: 2 })
                }
            } else {
                message.success({ content: "数据加载完成！", key: g_strMessageKey, duration: 2 });
            }
            this.objCurrentCACNOrder = {};
            this.objCurrentShipper = {};
            this.objCurrentReceiver = {};

            this.setState({
                ...this.state,
                bLoading: false,
                bShowDlg: false,
                bMultiEditDlg: false,
                btnControlDisable: [false, true, true, true, true, true, true],
                btnCopyPasteDisable: [true, true],
                selectedRowKeys: []
            });
        }).catch(function (error) {
            console.log(error);
        });
    }
    // 运单信息查询
    ExpressOrderLookup = (iType) => {
        let strKeyWord = "";
        strKeyWord = this.refs.order_no_search.state.value;
        this.strOrderNoKeyWord = strKeyWord;
        strKeyWord = this.refs.user_search.state.value;
        this.strUserKeyWord = strKeyWord;
        strKeyWord = this.refs.item_search.state.value;
        this.strItemKeyWord = strKeyWord;
        this.GetCACNOrderData();
    }
    // 快递公司过滤器切换
    SwitchExpressTypeFilter = (index) => {
        this.iExpressTypeFilter = index;
        if (index === 0) {
            this.arrTableData = this.arrTableDataOge;
        } else {

            let iExpressType = index - 1;
            let arrNewTableData = [];
            for (let i = 0; i < this.arrTableData.length; i++) {
                if (iExpressType === this.arrTableData[i].express_type)
                    arrNewTableData.push(this.arrTableData[i]);
            }
            this.arrTableData = arrNewTableData;
        }
        this.setState({
            ...this.state,
            iExpressTypeFilter: index
        });
    }
    // 快递公司切换
    SwitchExpressType = (index) => {
        this.setState({
            ...this.state,
            iExpressType: index
        });
    }
    // 运单状态过滤器响应
    SwitchStatusFilter = (index) => {
        this.arrTableData = [];
        if (index > 0) {
            let iStatus = index - 1;
            for (let i = 0; i < this.arrTableDataOge.length; i++) {
                if (iStatus === 2 && iStatus <= this.arrTableDataOge[i].status)
                    this.arrTableData.push(this.arrTableDataOge[i]);
                else {
                    if (iStatus === this.arrTableDataOge[i].status)
                        this.arrTableData.push(this.arrTableDataOge[i]);
                }
            }
        } else {
            this.arrTableData = this.arrTableDataOge;
        }
        this.setState({
            ...this.state,
            iStatusFilter: index
        });
    }
    // 运单状态变更
    SwitchExpressStatus = (index) => {
        this.setState({
            ...this.state,
            iCurrentStatus: index
        });
    }
    // 状态更新
    StatusChange = (e) => {
        if (e.target.checked)
            this.setState({
                ...this.state,
                iCurrentStatus: 1
            });
        else
            this.setState({
                ...this.state,
                iCurrentStatus: 0,
                iPackage: 0
            });
    }
    // 合箱更新
    PackageChange = (e) => {
        if (e.target.checked)
            this.setState({
                ...this.state,
                iPackage: 1,
                iCurrentStatus: 1
            });
        else
            this.setState({
                ...this.state,
                iPackage: 0
            });
    }
    // 发货选项更新
    DeliveryStatusChange = (e) => {
        this.setState({
            ...this.state,
            bDeliveryStatus: e.target.checked
        });
    }
    // 复制粘贴
    CopyPasteAction = (action) => {
        // 复制
        if (action === 0) {
            this.setState({
                ...this.state,
                btnControlDisable: [false, false, false, false, false, false, true]
            });
        }
        // 粘贴
        if (action === 1) {
            delete this.objCurrentCACNOrder.key;
            delete this.objCurrentCACNOrder.rowKey;
            delete this.objCurrentCACNOrder.status_check;
            delete this.objCurrentCACNOrder.package_check;
            delete this.objCurrentCACNOrder.item_info;
            delete this.objCurrentCACNOrder.id;
            delete this.objCurrentCACNOrder.edit_btn;

            console.log("paste order : ", this.objCurrentCACNOrder);

            this.iExpressTypeFilter = 0;
            this.setState({
                ...this.state,
                iExpressTypeFilter: 0
            })
            message.loading({ content: '正在新建运单信息……', key: g_strMessageKey });
            axios.post('/NewExpressEnter', {
                objExpressEnter: this.objCurrentCACNOrder
            }).then(({ data }) => {
                if (data.status === 1) {
                    // DataStatistic
                    UserActiveUpdate(4, { active: "商家后台: 运单新信息管理操作", action: "粘贴" });
                    this.GetCACNOrderData();
                } else {
                    message.warning(data.message);
                }
            }).catch(function (error) { console.log(error); });
        }
    }
    // 导出Excel文档
    ExportExcelFile = () => {
        console.log("导出订单列表：", this.arrSelectedCACNOrder);
        let arrExportData = [
            ['订单类型', '母单号', '承运公司', '是否推送', '线路名称', '清关公司', '保价金额', '保费', '预缴关税', '体积', '包裹总重', '计费重量', '运费', '总费用', '代收件人', '发件人姓名', '发件人手机', '发件人联系电话', '发件人地址', '收件人姓名', '收件人手机', '收件人联系电话', '收件人省份', '收件人城市', '收件人区', '收件人详细地址', '收件人身份证号', '备注', '货物价值', '货物1产品', '货物1名称', '货物1英文品牌', '货物1规格', '货物1件数', '货物2产品', '货物2名称', '货物2英文品牌', '货物2规格', '货物2件数', '货物3产品', '货物3名称', '货物3英文品牌', '货物3规格', '货物3件数', '货物4产品', '货物4名称', '货物4英文品牌', '货物4规格', '货物4件数', '货物5产品', '货物5名称', '货物5英文品牌', '货物5规格', '货物5件数']
        ];
        for (let i = 0; i < this.arrSelectedCACNOrder.length; i++) {
            let arrData = [];
            // 运单信息
            arrData.push("普通订单"); arrData.push(""); arrData.push("Deppon"); arrData.push("否"); arrData.push("普货线"); arrData.push("未确认关口");
            arrData.push(""); arrData.push(""); arrData.push(""); arrData.push("");
            arrData.push(this.arrSelectedCACNOrder[i].weight); arrData.push(this.arrSelectedCACNOrder[i].weight);
            arrData.push("1"); arrData.push("1"); arrData.push("否");  // 代收件人
            arrData.push(this.arrSelectedCACNOrder[i].shipper_name); arrData.push(this.arrSelectedCACNOrder[i].shipper_phone); arrData.push(""); arrData.push("Canada");
            arrData.push(this.arrSelectedCACNOrder[i].receiver_name); arrData.push(this.arrSelectedCACNOrder[i].receiver_phone); arrData.push("");
            let iProvince = this.arrSelectedCACNOrder[i].province, iCity = this.arrSelectedCACNOrder[i].city, iArea = this.arrSelectedCACNOrder[i].area;
            this.GetCNAreaInfo(iProvince, iCity);
            arrData.push(this.objChinaCity.provinces[iProvince]); arrData.push(this.objChinaCity.cities[iCity]); arrData.push(this.objChinaCity.areas[iArea]);
            arrData.push(this.arrSelectedCACNOrder[i].receiver_address); arrData.push(this.arrSelectedCACNOrder[i].id_number);
            arrData.push(this.arrSelectedCACNOrder[i].order_no);
            // 货物信息
            let objItemInfo = this.arrSelectedCACNOrder[i].item_info;
            arrData.push("");
            if (objItemInfo.length > 5) {
                for (let j = 5; j < objItemInfo.length; j++) {
                    objItemInfo[j - 5].num = parseInt(objItemInfo[j - 5].num, 10) + parseInt(objItemInfo[j].num, 10);
                }
            }
            let iItemNum = objItemInfo.length;
            if (objItemInfo.length > 5)
                iItemNum = 5;
            for (let j = 0; j < iItemNum; j++) {
                arrData.push(this.arrItemType[objItemInfo[j].type].name); arrData.push(objItemInfo[j].name); arrData.push(objItemInfo[j].brand);
                arrData.push(objItemInfo[j].style); arrData.push(objItemInfo[j].num);
            }
            arrExportData.push(arrData);
        }
        console.log("export excel file", arrExportData);
        var sheet = XLSX.utils.aoa_to_sheet(arrExportData);
        openDownloadDialog(sheet2blob(sheet), '德邦运单.xlsx');

    }
    // 显示全部运单信息
    ShowAllExpressEnter = () => {
        this.iExpressTypeFilter = 0;
        this.strOrderNoKeyWord = "";
        this.refs.order_no_search.state.value = "";
        this.strUserKeyWord = "";
        this.refs.user_search.state.value = "";
        this.strItemKeyWord = "";
        this.refs.item_search.state.value = "";
        this.setState({
            ...this.state,
            iExpressTypeFilter: 0,
            iCurrentStatus: 0
        });
        this.GetCACNOrderData();
    }
    // 新增运单信息
    AddNewItemInfo = () => {
        let objItemInfo = { name: '', type: 0, sub_type: 0, num: 1, brand: '', price: '' };
        if (this.objCurrentCACNOrder.item_info === undefined)
            this.objCurrentCACNOrder.item_info = [];
        if (this.objCurrentCACNOrder.item_info.length >= 10) {
            message.warning("每单物品不能超过10种！");
            return;
        }
        this.objCurrentCACNOrder.item_info.splice(0, 0, objItemInfo);
        console.log("AddNewItemInfo : ", this.objCurrentCACNOrder.item_info);
        this.setState({
            ...this.state,
            bItemValueChange: true
        });
    }
    // 线路切换
    SwitchTransitType = (index) => {
        if (this.props.iUserLevel <= 5) {
            message.warning("权限不够，只能查看，无法编辑！");
            return;
        }
        this.setState({
            ...this.state,
            iTransitType: index
        });
    }
    // 物品信息发生变化
    ItemInfoChange = (indexItem, value, e) => {
        // 名称
        if (value === 0) {
            this.objCurrentCACNOrder.item_info[indexItem].name = e.target.value;
        }
        // 品牌
        if (value === 1) {
            this.objCurrentCACNOrder.item_info[indexItem].brand = e.target.value;
        }
        // 规格
        if (value === 2) {
            this.objCurrentCACNOrder.item_info[indexItem].style = e.target.value;
        }
        // 数量
        if (value === 3) {
            this.objCurrentCACNOrder.item_info[indexItem].num = e.target.value;
        }
        // 价格
        if (value === 4) {
            this.objCurrentCACNOrder.item_info[indexItem].price = e.target.value;
        }
        this.setState({
            ...this.state,
            bItemValueChange: true
        });
    }
    // 移除一个物品信息
    RemoveItemInfo = (indexItem) => {

        if (this.objCurrentCACNOrder.item_info.length <= 1) {
            message.warning("每单运单至少包含一条物品信息！");
            return;
        }

        this.objCurrentCACNOrder.item_info.splice(indexItem, 1);
        this.setState({
            ...this.state,
            bItemValueChange: true
        });

    }
    // 清空物品信息
    ClearItemInfo = () => {
        this.objCurrentCACNOrder.item_info = [{ name: '', type: 0, sub_type: 0, num: 1, brand: '', price: '' }];
        this.setState({
            ...this.state,
            bLoading: false
        })
    }
    // 批量编辑选项
    MultiEditCheck = (index, e) => {
        let arrMultiEditCheck = this.state.arrMultiEditCheck;
        arrMultiEditCheck[index] = e.target.checked;
        this.setState({
            ...this.state,
            arrMultiEditCheck: arrMultiEditCheck
        })
    }
    // 批量编辑确认按钮响应
    MultiEditConfirm = () => {
        if (!this.state.arrMultiEditCheck[0] && !this.state.arrMultiEditCheck[1] && !this.state.arrMultiEditCheck[2]) {
            message.warning("请至少选中一个需要更新的内容!"); return;
        }
        let strBatchNo = this.refs.multi_batch_no.state.value;
        // let strExpressDetail = this.refs.multi_express_detail.state.value;
        let iStatus = 0;
        let arrOrderID = [];
        for (let i = 0; i < this.arrSelectedCACNOrder.length; i++) {
            arrOrderID.push(this.arrSelectedCACNOrder[i].id);
            if (strBatchNo !== "")
                this.arrSelectedCACNOrder[i].batch_no = strBatchNo;
            // if (strExpressDetail !== "")
            //     this.arrSelectedCACNOrder[i].express_detail = strExpressDetail;
            this.arrSelectedCACNOrder[i].status = this.state.iCurrentStatus;
            iStatus = this.arrSelectedCACNOrder[i].status;
        }
        // console.log(iStatus, strBatchNo, strExpressDetail, this.state.arrMultiEditCheck);
        let arrMultiEditCheck = [0, 0, 0];
        for (let i = 0; i < this.state.arrMultiEditCheck.length; i++) {
            if (this.state.arrMultiEditCheck[i])
                arrMultiEditCheck[i] = 1;
        }
        this.setState({
            ...this.state,
            bLoading: true
        });
        message.loading({ content: '正在更新订单信息……', key: g_strMessageKey });
        axios.post('/MultiEditCACNOrder', {
            arrOrderID: arrOrderID,
            arrMultiEditCheck: arrMultiEditCheck,
            batch_no: strBatchNo,
            // express_detail: strExpressDetail,
            status: iStatus
        }).then(({ data }) => {
            console.log(data);
            if (data.status === 1) {
                message.success({ content: '更新完成！', key: g_strMessageKey, duration: 2 });
                console.log("Multi Edit completed!");
            } else {
                message.warning("更新失败，请重新尝试!");
            }
            this.GetCACNOrderData();
        });
    }
    // 取消按钮响应
    MultiEditCancel = () => {
        this.setState({
            ...this.state,
            bMultiEditDlg: false,
            selectedRowKeys: [],
            btnControlDisable: [false, true, true, true, true, true, true]
        });
    }
    // 切换表格页码
    TablePageChange = (page, pageSize) => {
        this.iCurrentPage = page;
        this.GetCACNOrderData();
    }
    // 切换每页显示的订单数量
    TableNumPerPageChange = (current, size) => {
        this.iNumPerPage = size;
        this.iCurrentPage = 1;
        this.GetCACNOrderData();
    }
    // 检查收发件人信息
    CheckShipperReceiver = (iAction) => {
        if (iAction === 0) {
            let strShipperName = this.refs.shipper_name.state.value;
            let strShipperPhone = this.refs.shipper_phone.state.value;
            // let strShipperAddress = this.refs.shipper_address.state.value;
            if (strShipperName === null || strShipperName === "" || strShipperName === undefined) {
                message.warning("发件人名不能为空！"); return -1;
            }
            if (strShipperName.trim() === "") {
                message.warning("发件人名不能为空！"); return -1;
            }
            if (strShipperPhone === null || strShipperPhone === "" || strShipperPhone === undefined) {
                message.warning("联系电话不能为空！"); return -1;
            }
            if (strShipperPhone.trim() === "") {
                message.warning("联系电话不能为空！"); return -1;
            }
        }
        if (iAction === 1) {
            let strReceiverName = this.refs.receiver_name.state.value;
            let strReceiverPhone = this.refs.receiver_phone.state.value;
            let strReceiverAddress = this.refs.receiver_address.state.value;
            let strReceiverPostcode = this.refs.receiver_postcode.state.value;
            // let strReceiverID = this.refs.id_number.state.value;
            if (strReceiverName === null || strReceiverName === "" || strReceiverName === undefined) {
                message.warning("收件人名不能为空！"); return -1;
            }
            if (strReceiverName.trim() === "") {
                message.warning("收件人名不能为空！"); return -1;
            }
            if (strReceiverPhone === null || strReceiverPhone === "" || strReceiverPhone === undefined) {
                message.warning("联系电话不能为空！"); return -1;
            }
            if (strReceiverPhone.trim() === "") {
                message.warning("联系电话不能为空！"); return -1;
            }
            if (strReceiverPostcode === null || strReceiverPostcode === "" || strReceiverPostcode === undefined) {
                // message.warning("邮政编码不能为空！"); return;
                strReceiverPostcode = "";
            }
            if (strReceiverPostcode.trim() === "") {
                // message.warning("邮政编码不能为空！"); return;
                strReceiverPostcode = "";
            }
            if (strReceiverAddress === null || strReceiverAddress === "" || strReceiverAddress === undefined) {
                message.warning("收货地址不能为空"); return -1;
            }
            if (strReceiverAddress.trim() === "") {
                message.warning("收货地址不能为空"); return -1;
            }
        }
        return 1;
    }
    // 保存发件人/收件人
    SaveShipperReceiver = () => {

        // 发件人
        // if (iAction === 0) {
        let strShipperName = this.refs.shipper_name.state.value;
        let strShipperPhone = this.refs.shipper_phone.state.value;
        let strShipperAddress = this.refs.shipper_address.state.value;
        // this.CheckShipperReceiver(0);
        let strUserCode = this.objCurrentCACNOrder.user_code;
        let strUserName = this.objCurrentCACNOrder.user_name;
        if (strUserCode === undefined || strUserCode === null || strUserCode === "")
            strUserCode = "";
        if (strUserName === undefined || strUserName === null || strUserName === "")
            strUserName = "";
        let objNewShipper = {
            name: strShipperName,
            phone: strShipperPhone,
            address: strShipperAddress,
            store_id: this.props.iStoreID,
            user_id: 0,
            user_name: strUserName,
            user_code: strUserCode,
            email: '',
            postcode: ''
        };
        let iUpdate = 0; let objCurrentShipper = null;
        if (this.objCurrentShipper !== undefined && this.objCurrentShipper !== null) {
            if (this.objCurrentShipper.id !== undefined && this.objCurrentShipper.id !== null) {
                objCurrentShipper = this.objCurrentShipper;
                objNewShipper.id = objCurrentShipper.id;
                objNewShipper.email = objCurrentShipper.email;
                objNewShipper.postcode = objCurrentShipper.postcode;
                objNewShipper.user_id = objCurrentShipper.user_id;
                objNewShipper.user_name = objCurrentShipper.user_name;
                objNewShipper.user_code = objCurrentShipper.user_code;
                iUpdate = 1;
            }
        }
        axios.post('/UpdateShipper', {
            objCurrentShipper: objCurrentShipper,
            objNewShipper: objNewShipper,
            action: iUpdate
        }).then(({ data }) => {
            if (data.status === 1) {
                message.success({ content: "发件人已保存！", key: g_strMessageKey, duration: 2 });
                this.objCurrentShipper = objNewShipper;

                // 收件人
                // if (iAction === 1) {
                let strReceiverName = this.refs.receiver_name.state.value;
                let strReceiverPhone = this.refs.receiver_phone.state.value;
                let strReceiverAddress = this.refs.receiver_address.state.value;
                let strReceiverPostcode = this.refs.receiver_postcode.state.value;
                let strReceiverID = this.refs.id_number.state.value;
                // this.CheckShipperReceiver(1);

                // let strUserCode = this.objCurrentCACNOrder.user_code;
                // let strUserName = this.objCurrentCACNOrder.user_name;
                // if (strUserCode === undefined || strUserCode === null || strUserCode === "")
                //     strUserCode = "";
                // if (strUserName === undefined || strUserName === null || strUserName === "")
                //     strUserName = "";

                let objNewReceiver = {
                    name: strReceiverName,
                    phone: strReceiverPhone,
                    address: strReceiverAddress,
                    postcode: strReceiverPostcode,
                    store_id: this.props.iStoreID,
                    user_id: 0,
                    user_name: strUserName,
                    user_code: strUserCode,
                    id_number: strReceiverID,
                    id_s3_link1: this.objCurrentReceiver.id_s3_link1,
                    id_s3_link2: this.objCurrentReceiver.id_s3_link2,
                    province: this.state.iCurrentProvince,
                    city: this.state.iCurrentCity,
                    area: this.state.iCurrentArea
                };
                let iUpdate = 0; let objCurrentReceiver = null;
                if (this.objCurrentReceiver !== undefined && this.objCurrentReceiver !== null) {
                    if (this.objCurrentReceiver.id !== undefined && this.objCurrentReceiver.id !== null) {
                        objCurrentReceiver = this.objCurrentReceiver;
                        objNewReceiver.id = this.objCurrentReceiver.id;
                        objNewReceiver.user_id = objCurrentReceiver.user_id;
                        objNewReceiver.user_name = objCurrentReceiver.user_name;
                        objNewReceiver.user_code = objCurrentReceiver.user_code;
                        iUpdate = 1;
                    }
                    this.objCurrentReceiver.province = this.state.iCurrentProvince;
                    this.objCurrentReceiver.city = this.state.iCurrentCity;
                    this.objCurrentReceiver.area = this.state.iCurrentArea;
                }
                message.loading({ content: '正在保存收件人信息……', key: g_strMessageKey });
                // 无需更新身份证照片
                if (this.state.selectedFile1 === null && this.state.selectedFile2 === null) {
                    this.UpdateReceiverSubmitOrder(objCurrentReceiver, objNewReceiver, iUpdate);
                    // axios.post('/UpdateReceiver', {
                    //     objCurrentReceiver: objCurrentReceiver,
                    //     objNewReceiver: objNewReceiver,
                    //     action: iUpdate
                    // }).then(({ data }) => {
                    //     if (data.status === 1) {
                    //         message.success({ content: "收件人已保存！", key: g_strMessageKey, duration: 2 });
                    //         this.objCurrentReceiver = objNewReceiver;
                    //         this.objCurrentCACNOrder.shipper_name = strShipperName;
                    //         this.objCurrentCACNOrder.shipper_phone = strShipperPhone;
                    //         this.objCurrentCACNOrder.shipper_address = strShipperAddress;
                    //         this.objCurrentCACNOrder.receiver_id = this.objCurrentReceiver.id;
                    //         this.objCurrentCACNOrder.receiver_name = strReceiverName;
                    //         this.objCurrentCACNOrder.receiver_phone = strReceiverPhone;
                    //         this.objCurrentCACNOrder.receiver_address = strReceiverAddress;
                    //         this.objCurrentCACNOrder.receiver_postcode = strReceiverPostcode;
                    //         this.objCurrentCACNOrder.province = this.state.iCurrentProvince;
                    //         this.objCurrentCACNOrder.city = this.state.iCurrentCity;
                    //         this.objCurrentCACNOrder.area = this.state.iCurrentArea;
                    //         this.objCurrentCACNOrder.photo_id = this.objCurrentReceiver.photo_id;
                    //     } else {
                    //         this.bSubmitting = false;
                    //         message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
                    //     }
                    // }).catch(function (error) { console.log(error); });
                }
                // 身份证图片需要更新时
                if (this.state.selectedFile1 !== null || this.state.selectedFile2 !== null) {
                    console.log("需更新身份证照片！");
                    this.singleFileUploadHandler(objNewReceiver, objCurrentReceiver, iUpdate);
                }
                // }

            } else {
                this.bSubmitting = false;
                message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
            }
        }).catch(function (error) { console.log(error); });
        // }

    }
    // * 提交订单时保存收件人到数据库以及之后的更新订单流程 *
    UpdateReceiverSubmitOrder = (objCurrentReceiver, objNewReceiver, iUpdate) => {
        axios.post('/UpdateReceiver', {
            objCurrentReceiver: objCurrentReceiver,
            objNewReceiver: objNewReceiver,
            action: iUpdate
        }).then(({ data }) => {
            if (data.status === 1) {
                message.success({ content: "收件人已保存！", key: g_strMessageKey, duration: 2 });
                this.objCurrentReceiver = objNewReceiver;

                this.objCurrentCACNOrder.shipper_id = this.objCurrentShipper.id;
                this.objCurrentCACNOrder.shipper_name = this.objCurrentShipper.name;
                this.objCurrentCACNOrder.shipper_phone = this.objCurrentShipper.phone;
                this.objCurrentCACNOrder.shipper_address = this.objCurrentShipper.address;

                this.objCurrentCACNOrder.receiver_id = this.objCurrentReceiver.id;
                this.objCurrentCACNOrder.receiver_name = this.objCurrentReceiver.name;
                this.objCurrentCACNOrder.receiver_phone = this.objCurrentReceiver.phone;;
                this.objCurrentCACNOrder.receiver_address = this.objCurrentReceiver.address;
                this.objCurrentCACNOrder.receiver_postcode = this.objCurrentReceiver.postcode;

                this.objCurrentCACNOrder.province = this.state.iCurrentProvince;
                this.objCurrentCACNOrder.city = this.state.iCurrentCity;
                this.objCurrentCACNOrder.area = this.state.iCurrentArea;
                this.objCurrentCACNOrder.photo_id = 0;
                if (this.objCurrentReceiver.id_s3_link1 !== null && this.objCurrentReceiver.id_s3_link1 !== null &&
                    this.objCurrentReceiver.id_s3_link1 !== '' && this.objCurrentReceiver.id_s3_link1 !== '')
                    this.objCurrentCACNOrder.photo_id = 1;
                console.log("Finally Create / Update Order : ", this.objCurrentCACNOrder);
                this.CreateUpdateOrder(this.state.iDlgType);
            } else {
                this.bSubmitting = false;
                message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
            }
        }).catch(function (error) { console.log(error); });
    }
    // 获取寄件人信息
    GetShippersData() {
        message.loading({ content: '数据加载中……', key: g_strMessageKey });
        axios('/GetShippers', { params: { store_id: this.props.iStoreID } })
            .then(({ data }) => {
                console.log("GetShippers : ", data);
                global.arrShipperData = data;
            }).catch(function (error) { console.log(error); });
    }
    // 获取收件人信息
    GetReceiversData() {
        message.loading({ content: '数据加载中……', key: g_strMessageKey });
        axios('/GetReceivers', { params: { store_id: this.props.iStoreID } })
            .then(({ data }) => {
                console.log("GetReceivers : ", data);
                global.arrReceiverData = data;
            }).catch(function (error) { console.log(error); });
    }
    // 选择发件人
    SelectShipper = () => {
        this.arrShipperShow = global.arrShipperData;
        this.setState({
            ...this.state,
            bSelectReceiverDlg: false,
            bSelectShipperDlg: true,
            bShipperReceiverFilter: false
        });
    }
    // 确认发件人
    SelectShipperConfirm = (index) => {
        this.objCurrentShipper = this.arrShipperShow[index];
        this.refs.shipper_name.state.value = this.objCurrentShipper.name;
        this.refs.shipper_phone.state.value = this.objCurrentShipper.phone;
        this.refs.shipper_address.state.value = this.objCurrentShipper.address;
        this.setState({
            ...this.state,
            bSelectReceiverDlg: false,
            bSelectShipperDlg: false
        });
    }
    // 选择收件人
    SelectReceiver = () => {
        this.arrReceiverShow = global.arrReceiverData;
        this.setState({
            ...this.state,
            bSelectReceiverDlg: true,
            bSelectShipperDlg: false,
            bShipperReceiverFilter: false
        });
    }
    // 确认发件人
    SelectReceiverConfirm = (index) => {
        this.objCurrentReceiver = this.arrReceiverShow[index];
        this.refs.receiver_name.state.value = this.objCurrentReceiver.name;
        this.refs.receiver_phone.state.value = this.objCurrentReceiver.phone;
        this.refs.receiver_postcode.state.value = this.objCurrentReceiver.postcode;
        this.refs.receiver_address.state.value = this.objCurrentReceiver.address;
        this.refs.id_number.state.value = this.objCurrentReceiver.id_number;
        this.GetCNAreaInfo(this.objCurrentReceiver.province, this.objCurrentReceiver.city);
        this.setState({
            ...this.state,
            bSelectReceiverDlg: false,
            bSelectShipperDlg: false,
            iCurrentProvince: this.objCurrentReceiver.province,
            iCurrentCity: this.objCurrentReceiver.city,
            iCurrentArea: this.objCurrentReceiver.area
        });
    }
    // SelectCancel
    SelectCancel = () => {
        this.setState({
            ...this.state,
            bSelectReceiverDlg: false,
            bSelectShipperDlg: false
        });
    }
    // 切换物品大类
    SwitchItemType = (type, item) => {
        this.objCurrentCACNOrder.item_info[item].type = type;
        this.objCurrentCACNOrder.item_info[item].sub_type = 0;
        this.setState({
            ...this.state,
            bItemValueChange: true
        })
    }
    // 切换物品小
    SwitchItemSubType = (sub_type, item) => {
        this.objCurrentCACNOrder.item_info[item].sub_type = sub_type;
        this.setState({
            ...this.state,
            bItemValueChange: true
        })
    }
    // 物品信息检查
    ItemInfoCheck = () => {
        if (this.objCurrentCACNOrder.item_info === undefined || this.objCurrentCACNOrder.item_info === null || this.objCurrentCACNOrder.item_info === "") {
            message.warning("请至少添加一个物品！"); this.bSubmitting = false;
            return -1;
        }
        if (this.objCurrentCACNOrder.item_info.length <= 0) {
            message.warning("请至少添加一个物品！"); this.bSubmitting = false;
            return -1;
        }
        for (let i = 0; i < this.objCurrentCACNOrder.item_info.length; i++) {
            if (this.objCurrentCACNOrder.item_info[i].name === undefined || this.objCurrentCACNOrder.item_info[i].name === null ||
                this.objCurrentCACNOrder.item_info[i].name === '') {
                message.warning("物品名称不能为空！"); this.bSubmitting = false;
                return -1;
            }
            if (this.objCurrentCACNOrder.item_info[i].brand === undefined || this.objCurrentCACNOrder.item_info[i].brand === null ||
                this.objCurrentCACNOrder.item_info[i].brand === '') {
                message.warning("品牌不能为空！"); this.bSubmitting = false;
                return -1;
            }
            if (this.objCurrentCACNOrder.item_info[i].style === undefined || this.objCurrentCACNOrder.item_info[i].style === null ||
                this.objCurrentCACNOrder.item_info[i].style === '') {
                this.objCurrentCACNOrder.item_info[i].style = '';
                // message.warning("物品规格不能为空！"); this.bSubmitting = false;
                // return -1;
            }
            if (this.objCurrentCACNOrder.item_info[i].num === undefined || this.objCurrentCACNOrder.item_info[i].num === null ||
                this.objCurrentCACNOrder.item_info[i].num === '') {
                message.warning("物品数量不能为空！"); this.bSubmitting = false;
                return -1;
            }
            if (parseInt(this.objCurrentCACNOrder.item_info[i].num) <= 0) {
                message.warning("请填写正确的物品数量！"); this.bSubmitting = false;
                return -1;
            }
            if (this.objCurrentCACNOrder.item_info[i].price === undefined || this.objCurrentCACNOrder.item_info[i].price === null ||
                this.objCurrentCACNOrder.item_info[i].price === '') {
                // message.warning("物品价格不能为空！"); this.bSubmitting = false;
                // return -1;
            }

        }
        return 1;
    }
    // 选择单个文件按钮响应
    SelectSingleFile = (index, event) => {
        if (index === 0) {
            this.setState({
                ...this.state,
                selectedFile1: event.target.files[0]
            });
        }
        else {
            this.setState({
                ...this.state,
                selectedFile2: event.target.files[0]
            });
        }
    }
    // 单个文件上传按钮响应
    singleFileUploadHandler = (objNewReceiver, objCurrentReceiver, iAction) => {
        let data = new FormData();
        // 正反面都更新
        if (this.state.selectedFile1 !== null && this.state.selectedFile2 !== null) {
            data.append('profileImage', this.state.selectedFile1, this.state.selectedFile1.name);
            axios.post('/aws-profile-upload', data, {
                headers: {
                    'accept': 'application/json',
                    'Accept-Language': 'en-US,en;q=0.8',
                    'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
                    store_id: this.props.iStoreID,
                }
            }).then((response) => {
                if (200 === response.status) {
                    // If file size is larger than expected.
                    if (response.data.error) {
                        if ('LIMIT_FILE_SIZE' === response.data.error.code) {
                            message.warning("文件大小不能超过2Mb!");
                        } else {
                            console.log(response.data);
                            message.error("文件类型不正确！");
                        }
                    } else {
                        // Success
                        let fileName = response.data;
                        console.log('fileName 1: ', fileName);
                        objNewReceiver.id_s3_link1 = fileName;
                        if (objCurrentReceiver !== null && objCurrentReceiver !== undefined)
                            objCurrentReceiver.id_s3_link1 = fileName;
                        data = new FormData();
                        data.append('profileImage', this.state.selectedFile2, this.state.selectedFile2.name);
                        axios.post('/aws-profile-upload', data, {
                            headers: {
                                'accept': 'application/json',
                                'Accept-Language': 'en-US,en;q=0.8',
                                'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
                                store_id: this.props.iStoreID,
                            }
                        }).then((response) => {
                            if (200 === response.status) {
                                // If file size is larger than expected.
                                if (response.data.error) {
                                    if ('LIMIT_FILE_SIZE' === response.data.error.code) {
                                        message.warning("文件大小不能超过2Mb!");
                                    } else {
                                        console.log(response.data);
                                        message.error("文件类型不正确！");
                                    }
                                } else {
                                    // Success
                                    let fileName = response.data;
                                    objNewReceiver.id_s3_link2 = fileName;
                                    if (objCurrentReceiver !== null && objCurrentReceiver !== undefined)
                                        objCurrentReceiver.id_s3_link2 = fileName;
                                    this.UpdateReceiverSubmitOrder(objCurrentReceiver, objNewReceiver, iAction);
                                    // axios.post('/UpdateReceiver', {
                                    //     objCurrentReceiver: objCurrentReceiver,
                                    //     objNewReceiver: objNewReceiver,
                                    //     action: iAction
                                    // }).then(({ data }) => {
                                    //     if (data.status === 1) {
                                    //         message.success({ content: "收件人已保存！", key: g_strMessageKey, duration: 2 });
                                    //         this.objCurrentReceiver = objNewReceiver;
                                    //         console.log("Update CurrentReceiver : ", this.objCurrentReceiver);
                                    //         this.setState({
                                    //             ...this.state,
                                    //             bItemValueChange: true
                                    //         });
                                    //     } else
                                    //         message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
                                    // }).catch(function (error) { console.log(error); });
                                }
                            }
                        }).catch((error) => {
                            // If another error
                            console.log(error);
                            message.warning(error);
                        });
                    }
                }
            }).catch((error) => {
                // If another error
                console.log(error);
                message.warning(error);
            });
        }
        // 只更新正面
        if (this.state.selectedFile1 !== null && this.state.selectedFile2 === null) {
            data.append('profileImage', this.state.selectedFile1, this.state.selectedFile1.name);
            axios.post('/aws-profile-upload', data, {
                headers: {
                    'accept': 'application/json',
                    'Accept-Language': 'en-US,en;q=0.8',
                    'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
                    store_id: this.props.iStoreID,
                }
            }).then((response) => {
                if (200 === response.status) {
                    // If file size is larger than expected.
                    if (response.data.error) {
                        if ('LIMIT_FILE_SIZE' === response.data.error.code) {
                            message.warning("文件大小不能超过2Mb!");
                        } else {
                            console.log(response.data);
                            message.error("文件类型不正确！");
                        }
                    } else {
                        // Success
                        let fileName = response.data;
                        console.log('fileName 1: ', fileName);
                        objNewReceiver.id_s3_link1 = fileName;
                        if (objCurrentReceiver !== null && objCurrentReceiver !== undefined)
                            objCurrentReceiver.id_s3_link1 = fileName;
                        this.UpdateReceiverSubmitOrder(objCurrentReceiver, objNewReceiver, iAction);
                        // axios.post('/UpdateReceiver', {
                        //     objCurrentReceiver: objCurrentReceiver,
                        //     objNewReceiver: objNewReceiver,
                        //     action: iAction
                        // }).then(({ data }) => {
                        //     if (data.status === 1) {
                        //         message.success({ content: "收件人已保存！", key: g_strMessageKey, duration: 2 });
                        //         this.objCurrentReceiver = objNewReceiver;
                        //         this.setState({
                        //             ...this.state,
                        //             bItemValueChange: true
                        //         });
                        //     } else
                        //         message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
                        // }).catch(function (error) { console.log(error); });
                    }
                }
            }).catch((error) => {
                // If another error
                console.log(error);
                message.warning(error);
            });
        }
        // 只更新反面
        if (this.state.selectedFile1 === null && this.state.selectedFile2 !== null) {
            data.append('profileImage', this.state.selectedFile2, this.state.selectedFile2.name);
            axios.post('/aws-profile-upload', data, {
                headers: {
                    'accept': 'application/json',
                    'Accept-Language': 'en-US,en;q=0.8',
                    'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
                    store_id: this.props.iStoreID,
                }
            }).then((response) => {
                if (200 === response.status) {
                    // If file size is larger than expected.
                    if (response.data.error) {
                        if ('LIMIT_FILE_SIZE' === response.data.error.code) {
                            message.warning("文件大小不能超过2Mb!");
                        } else {
                            console.log(response.data);
                            message.error("文件类型不正确！");
                        }
                    } else {
                        // Success
                        let fileName = response.data;
                        console.log('fileName 2: ', fileName);
                        objNewReceiver.id_s3_link2 = fileName;
                        if (objCurrentReceiver !== null && objCurrentReceiver !== undefined)
                            objCurrentReceiver.id_s3_link2 = fileName;
                        this.UpdateReceiverSubmitOrder(objCurrentReceiver, objNewReceiver, iAction);
                        // axios.post('/UpdateReceiver', {
                        //     objCurrentReceiver: objCurrentReceiver,
                        //     objNewReceiver: objNewReceiver,
                        //     action: iAction
                        // }).then(({ data }) => {
                        //     if (data.status === 1) {
                        //         message.success({ content: "收件人已保存！", key: g_strMessageKey, duration: 2 });
                        //         this.objCurrentReceiver = objNewReceiver;
                        //         this.setState({
                        //             ...this.state,
                        //             bItemValueChange: true
                        //         });
                        //     } else
                        //         message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
                        // }).catch(function (error) { console.log(error); });
                    }
                }
            }).catch((error) => {
                // If another error
                console.log(error);
                message.warning(error);
            });
        }
    };
    // 仓库变更
    SwitchStorage = (index) => {
        this.setState({
            ...this.state,
            iCurrentStorage: index
        });
    }
    // 拷贝一行数据
    CopyCACNOrder = () => {
        this.objCopiedCACNOrder = {};
        this.objCopiedCACNOrder = Object.assign({}, this.objCurrentCACNOrder);
        this.setState({
            ...this.state,
            btnCopyPasteDisable: [true, false]
        });
        const hide = message.success("已复制订单：<" + this.objCurrentCACNOrder.order_no + ">");
        setTimeout(hide, 2000);
    }
    // 粘贴一行数据
    PasteCACNOrder = () => {
        delete this.objCopiedCACNOrder.id;
        delete this.objCopiedCACNOrder.key;
        delete this.objCopiedCACNOrder.rowKey;
        delete this.objCopiedCACNOrder.status_check;
        delete this.objCopiedCACNOrder.package_check;
        delete this.objCopiedCACNOrder.delivery_status_str;
        delete this.objCopiedCACNOrder.order_date_str;
        delete this.objCopiedCACNOrder.user_info;
        delete this.objCopiedCACNOrder.item_info_str;
        delete this.objCopiedCACNOrder.transit_type_str;
        delete this.objCopiedCACNOrder.status_str;
        delete this.objCopiedCACNOrder.weight_str;
        delete this.objCopiedCACNOrder.id_status;
        delete this.objCopiedCACNOrder.edit_btn;

        this.objCopiedCACNOrder.order_no = "";
        this.objCopiedCACNOrder.tracking_no = "";//this.objCurrentCACNOrder.tracking_no;
        this.objCopiedCACNOrder.batch_no = this.objCurrentCACNOrder.batch_no;
        this.objCopiedCACNOrder.user_code = this.objCurrentCACNOrder.user_code;
        this.objCopiedCACNOrder.user_name = this.objCurrentCACNOrder.user_name;
        this.objCopiedCACNOrder.transit_type = this.objCurrentCACNOrder.transit_type;
        this.objCopiedCACNOrder.status = this.objCurrentCACNOrder.status;
        this.objCopiedCACNOrder.storage = this.objCurrentCACNOrder.storage;

        this.objCopiedCACNOrder.shipper_name = this.objCurrentCACNOrder.shipper_name;
        this.objCopiedCACNOrder.shipper_phone = this.objCurrentCACNOrder.shipper_phone;
        this.objCopiedCACNOrder.shipper_address = this.objCurrentCACNOrder.shipper_address;

        this.objCopiedCACNOrder.receiver_name = this.objCurrentCACNOrder.receiver_name;
        this.objCopiedCACNOrder.receiver_phone = this.objCurrentCACNOrder.receiver_phone;
        this.objCopiedCACNOrder.receiver_address = this.objCurrentCACNOrder.receiver_address;
        this.objCopiedCACNOrder.receiver_postcode = this.objCurrentCACNOrder.receiver_postcode;
        this.objCopiedCACNOrder.photo_id = this.objCurrentCACNOrder.photo_id;

        this.objCopiedCACNOrder.weight = this.objCurrentCACNOrder.weight;
        this.objCopiedCACNOrder.insurance = this.objCurrentCACNOrder.insurance;
        this.objCopiedCACNOrder.other_fee = this.objCurrentCACNOrder.other_fee;
        this.objCopiedCACNOrder.comments = this.objCurrentCACNOrder.comments;
        this.objCopiedCACNOrder.express_detail = ""; //this.objCurrentCACNOrder.express_detail;
        this.objCopiedCACNOrder.item_info = JSON.stringify(this.objCurrentCACNOrder.item_info);
        console.log("复制的订单", this.objCopiedCACNOrder);

        message.loading({ content: '新的运单创建中……', key: g_strMessageKey });
        axios.post('/NewCACNOrder', {
            objCACNOrder: this.objCopiedCACNOrder
        }).then(({ data }) => {
            if (data.status === 1) {
                this.setState({
                    ...this.state,
                    iExpressTypeFilter: 0,
                    bShowDlg: false
                });
                this.GetCACNOrderData();
            } else {
                message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
                this.bSubmitting = false;
                this.setState({
                    ...this.state,
                    iExpressTypeFilter: 0
                });
            }
        }).catch(function (error) { console.log(error); });
    }
    // 快捷编辑
    EditCACNOrder = (index) => {
        this.bEditCACNOrderButtonClick = true;
        let objCACNOrder = JSON.stringify(this.arrTableData[index]);
        this.objCurrentCACNOrder = JSON.parse(objCACNOrder);
        this.arrSelectedCACNOrder = [];
        this.arrSelectedCACNOrder.push(this.arrTableData[index]);
    }
    // 获取某个省份的城市信息
    GetCNCityInfo = (province) => {
        this.objChinaCity.cities = GetCNCity(this.objChinaCity.provinces[province]);
        this.objChinaCity.areas = GetCNArea(this.objChinaCity.provinces[province], this.objChinaCity.cities[0]);
    }
    // 获取某个省份的城市信息
    GetCNAreaInfo = (province, city) => {
        this.objChinaCity.cities = GetCNCity(this.objChinaCity.provinces[province]);
        this.objChinaCity.areas = GetCNArea(this.objChinaCity.provinces[province], this.objChinaCity.cities[city]);
    }
    // 切换省份
    SwitchCNProvince = (index) => {
        this.GetCNAreaInfo(index, 0);
        this.setState({
            ...this.state,
            iCurrentProvince: index
        })
    }
    // 切换城市
    SwitchCNCity = (index) => {
        this.GetCNAreaInfo(this.state.iCurrentProvince, index);
        this.setState({
            ...this.state,
            iCurrentCity: index
        })
    }
    // 切换地区
    SwitchCNArea = (index) => {
        this.setState({
            ...this.state,
            iCurrentArea: index
        })
    }
    // 搜索收发件人
    SearchShipperReceiver = () => {
        console.log("SearchShipperReceiver");
        if (this.state.bSelectShipperDlg) {
            let strShipperSearch = this.refs.search_shipper.state.value;
            if (strShipperSearch !== undefined && strShipperSearch !== null && strShipperSearch !== '') {
                if (strShipperSearch.trim() !== '') {
                    this.arrShipperShow = [];
                    for (let i = 0; i < global.arrShipperData.length; i++) {
                        let strShipperInfo = JSON.stringify(global.arrShipperData[i]);
                        if (strShipperInfo.indexOf(strShipperSearch) >= 0) {
                            this.arrShipperShow.push(global.arrShipperData[i]);
                        }
                    }
                    console.log(this.arrShipperShow);
                }
            }
        }
        if (this.state.bSelectReceiverDlg) {
            let strReceiverSearch = this.refs.search_receiver.state.value;
            if (strReceiverSearch !== undefined && strReceiverSearch !== null && strReceiverSearch !== '') {
                if (strReceiverSearch.trim() !== '') {
                    this.arrReceiverShow = [];
                    for (let i = 0; i < global.arrReceiverData.length; i++) {
                        let strReceiverInfo = JSON.stringify(global.arrReceiverData[i]);
                        if (strReceiverInfo.indexOf(strReceiverSearch) >= 0) {
                            this.arrReceiverShow.push(global.arrReceiverData[i]);
                        }
                    }
                    console.log(this.arrReceiverShow);
                }
            }
        }

        this.setState({
            ...this.state,
            bShipperReceiverFilter: true
        });
    }
    // 时间格式转换
    formatDate = (numb, format) => {
        let time = new Date((numb - 1) * 24 * 3600000 + 1)
        time.setYear(time.getFullYear() - 70)
        let year = time.getFullYear() + ''
        let month = time.getMonth() + 1 + ''
        let date = time.getDate() + ''
        if (format && format.length === 1) {
            return year + format + month + format + date
        }
        return year + (month < 10 ? '0' + month : month) + (date < 10 ? '0' + date : date)
    }
    // 上传文件响应
    onImportExcel = file => {
        // 获取上传的文件对象
        const { files } = file.target;
        // 通过FileReader对象读取文件
        const fileReader = new FileReader();
        fileReader.onload = event => {
            try {
                const { result } = event.target;
                // 以二进制流方式读取得到整份excel表格对象
                const workbook = XLSX.read(result, { type: 'binary' });
                // 存储获取到的数据
                let data = [];
                // 遍历每张工作表进行读取（这里默认只读取第一张表）
                for (const sheet in workbook.Sheets) {
                    // esline-disable-next-line
                    if (workbook.Sheets.hasOwnProperty(sheet)) {
                        // 利用 sheet_to_json 方法将 excel 转成 json 数据
                        data = data.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
                        // break; // 如果只取第一张表，就取消注释这行
                    }
                }
                // 最终获取到并且格式化后的 json 数据
                message.success('上传成功！');
                this.arrOrderStatusUpdate = [];
                if (data.length > 0) {
                    console.log("Upload File : ", data);
                    for (let i = 0; i < data.length; i++) {
                        if (data[i]['运单号*'].indexOf('填写说明') >= 0) {
                            break;
                        } else {
                            let objOrderStatusUpdate = {
                                order: data[i]['运单号*'],
                                express_order: data[i]['国内快递单号'],
                                express_name: data[i]['国内快递名称'],
                                status: data[i]['运单状态名称'],
                                update_time: this.formatDate(data[i]['更新时间'], '/')
                            }
                            this.arrOrderStatusUpdate.push(objOrderStatusUpdate);
                        }
                    }
                    console.log("Oge Data : ", this.arrOrderStatusUpdate);
                    if (this.refs.file_upload) {
                        document.getElementById("file_upload").value = "";
                    }
                    this.UpdateOrderExpressDetail();
                } else {
                    message.warning("文件中的数据为空，请重新选择！")
                }
            } catch (e) {
                // 这里可以抛出文件类型错误不正确的相关提示
                message.error('文件类型不正确！');
            }
        };
        // 以二进制方式打开文件
        fileReader.readAsBinaryString(files[0]);
    }
    // 批量更新运单状态
    UpdateOrderExpressDetail = () => {
        this.setState({
            ...this.state,
            bLoading: true
        });
        let arrOrderID = [];
        for (let i = 0; i < this.arrOrderStatusUpdate.length; i++) {
            arrOrderID.push(this.arrOrderStatusUpdate[i].order);
            delete this.arrOrderStatusUpdate[i].order;
        }
        message.loading({ content: '正在更新物流状态……', key: g_strMessageKey });
        axios.post('/UpdateCACNOrderExpressDetail', {
            arrOrderID: arrOrderID,
            arrOrderStatusUpdate: this.arrOrderStatusUpdate
        }).then(({ data }) => {
            console.log(data);
            if (data.status === 1) {
                message.success({ content: '更新完成！', key: g_strMessageKey, duration: 2 });
            } else {
                message.warning("未找到相应的运单号，更新失败，请重新尝试!");
            }
            this.GetCACNOrderData();
        });
    }
    // 查看物流状态
    ViewExpressDetail = () => {
        this.setState({
            ...this.state,
            bViewTrackingInfo: true
        });
    }
    // 关闭物流状态
    CloseExpressDetail = () => {
        this.objCurrentCACNOrder.express_detail = JSON.stringify(this.arrCurrentOrderExpressDetail);
        this.setState({
            ...this.state,
            bViewTrackingInfo: false
        });
    }
    // 更新指定运单的物流状态
    UpdateCurrentOrderExpressDetail = () => {

        message.loading({ content: '正在更新物流状态……', key: g_strMessageKey });
        axios.post('/UpdateCurrentCACNOrderExpressDetail', {
            order: this.objCurrentCACNOrder.id,
            express_detail: this.objCurrentCACNOrder.express_detail
        }).then(({ data }) => {
            if (data.status === 1) {
                this.arrCurrentOrderExpressDetail = JSON.parse(this.objCurrentCACNOrder.express_detail);
                message.success({ content: '更新完成！', key: g_strMessageKey, duration: 2 });
            } else {
                message.warning("更新失败，请重新尝试!");
            }
            this.setState({
                ...this.state,
                bViewTrackingInfo: false
            });
        });
    }
    // 下载模板
    DownloadTemplate = () => {
        console.log("Download Template");
        window.open("https://mouthingmanager.s3.us-west-2.amazonaws.com/stores/30000/OrderChange.xls");
    }
    // 新建物流状态
    CreateNewTrackingInfo = () => {
        console.log("CreateNewTrackingInfo : ", this.arrCurrentOrderExpressDetail, this.objCurrentCACNOrder.express_detail);
        this.iCurrentTrackingInfo = -1;
        this.setState({
            ...this.state,
            bEditTrackingInfo: true
        });
    }
    // 新建物流状态
    EditTrackingInfo = (index) => {
        console.log("EditTrackingInfo : ", this.arrCurrentOrderExpressDetail, this.objCurrentCACNOrder.express_detail);
        this.iCurrentTrackingInfo = index;
        this.setState({
            ...this.state,
            bEditTrackingInfo: true
        });
    }
    // 显示物流状态编辑框
    ConfirmTrackingInfo = (index) => {
        if (index === 1) {
            let arrExpressDetail = [];
            if (this.objCurrentCACNOrder.express_detail !== undefined && this.objCurrentCACNOrder.express_detail !== null &&
                this.objCurrentCACNOrder.express_detail !== "" && typeof (this.objCurrentCACNOrder.express_detail) === 'string')
                arrExpressDetail = JSON.parse(this.objCurrentCACNOrder.express_detail);
            if (this.iCurrentTrackingInfo >= 0) {
                arrExpressDetail[this.iCurrentTrackingInfo].status = this.refs.tracking_info.state.value;
                arrExpressDetail[this.iCurrentTrackingInfo].update_date = this.refs.tracking_info_update_date.state.value;
            }
            else {
                let objTrackingInfo = {
                    express_order: this.objCurrentCACNOrder.express_order,
                    express_name: this.refs.tracking_info_express_name.state.value,
                    status: this.refs.tracking_info.state.value,
                    update_time: this.refs.tracking_info_update_date.state.value
                }
                if (arrExpressDetail === undefined || arrExpressDetail === null || arrExpressDetail === "")
                    arrExpressDetail = [];
                arrExpressDetail.push(objTrackingInfo);
            }
            this.objCurrentCACNOrder.express_detail = JSON.stringify(arrExpressDetail);
            console.log("new tracking info : ", this.objCurrentCACNOrder.express_detail);
        }
        this.setState({
            ...this.state,
            bEditTrackingInfo: false
        });
    }
    // 删除物流信息
    DeleteOrderExpressDetail = (index) => {
        let arrExpressDetail = JSON.parse(this.objCurrentCACNOrder.express_detail);
        arrExpressDetail.splice(index, 1);
        this.objCurrentCACNOrder.express_detail = JSON.stringify(arrExpressDetail);
        this.setState({
            ...this.state,
            bViewTrackingInfo: true
        });
    }
    // 打印面单
    PrintOrder = () => {
        console.log("PrintOrder : ", this.arrSelectedCACNOrder);
        this.setState({
            ...this.state,
            bPrintDlg: true
        })
    }
    // 确定打印
    PrintOK = () => {
        this.setState({
            ...this.state,
            bPrintDlg: false
        })
    }
    // 取消打印
    PrintCancel = () => {
        this.setState({
            ...this.state,
            bPrintDlg: false
        })
    }
    // 点击条形码时的默认屏蔽
    // BarcodeClick = (event) => {
    //     event.preventDefault();
    // }

    /* React自带函数 */
    // 卸载异步操作设置状态
    componentWillUnmount() {
        clearTimeout(this.timeouter)
        this.setState = (state, callback) => {
            return;
        }
    }
    // 渲染完毕……
    componentDidMount() {
        this.GetCACNOrderData();
        this.GetShippersData();
        this.GetReceiversData();
        this.objChinaCity.provinces = GetCNProvince();
        this.GetCNCityInfo(0, 0);
    }

    // 渲染函数
    render() {

        /* 用户管理页面控制栏 */
        let strAreaStyle = "store_manage_contents store_manage_top_no_space";
        let strLoadingStyle = "manage_empty_loading";
        let uiTitleArea = null;
        // 物流公司过滤器
        // const menuExpressTypeFilter = (
        //     <Menu>
        //         {this.arrExpressTypeFilter.map((item, index) => {
        //             return (
        //                 <Menu.Item key={index} onClick={this.SwitchExpressTypeFilter.bind(this, index)}>
        //                     {item}
        //                 </Menu.Item>
        //             );
        //         })}
        //     </Menu>
        // );
        // 物流公司下拉框
        // const menuExpressType = (
        //     <Menu>
        //         {this.arrExpressType.map((item, index) => {
        //             return (
        //                 <Menu.Item key={index} onClick={this.SwitchExpressType.bind(this, index)}>
        //                     {item}
        //                 </Menu.Item>
        //             );
        //         })}
        //     </Menu>
        // );
        // 运输方式下拉菜单
        const menuTransitType = (
            <Menu>
                {this.arrTransitType.map((item, index) => {
                    return (
                        <Menu.Item key={index} onClick={this.SwitchTransitType.bind(this, index)}>
                            {item}
                        </Menu.Item>
                    );
                })}
            </Menu>
        );
        // 运单状态过滤器
        const menuStatusFilter = (
            <Menu>
                {this.arrStatusFilter.map((item, index) => {
                    return (
                        <Menu.Item key={index} onClick={this.SwitchStatusFilter.bind(this, index)}>
                            {item}
                        </Menu.Item>
                    );
                })}
            </Menu>
        );
        // 运单状态变更
        const menuStatusChange = (
            <Menu>
                {this.arrStatusChange.map((item, index) => {
                    return (
                        <Menu.Item key={index} onClick={this.SwitchExpressStatus.bind(this, index)}>
                            {item}
                        </Menu.Item>
                    );
                })}
            </Menu>
        );
        // 仓库下拉菜单
        const menuStorage = (
            <Menu>
                {this.arrStorage.map((storage, index) => {
                    return (
                        <Menu.Item key={index} onClick={this.SwitchStorage.bind(this, index)}>
                            {storage}
                        </Menu.Item>
                    );
                })}
            </Menu>
        );

        strAreaStyle = "store_manage_contents store_manage_top_space_double";
        let strCopyButtonStyle = { backgroundColor: '#20b2aa', color: 'white', oppacity: '1' };
        if (this.state.btnCopyPasteDisable[0])
            strCopyButtonStyle = { backgroundColor: 'white', color: 'gray', oppacity: '0.5' };
        uiTitleArea = (
            <div>
                <div className="store_contents_title">
                    <Button className="manage_contents_title_margin manage_contents_title_margin_right" type="primary" onClick={this.ShowAllExpressEnter}>
                        显示全部<Icon type="redo" /></Button>
                    <div className="transit_price_title" >{"运单状态"}</div>
                    <Dropdown className="order_detail_dropdown order_filter_dropdown" trigger={['click']} overlay={menuStatusFilter} placement="bottomLeft">
                        <Button className="transit_type_filter_dropdown_button" style={{ maxWidth: '100px' }}>
                            <div className="order_details_dropdown_text">{this.arrStatusFilter[this.state.iStatusFilter]}</div>
                            <Icon type="caret-down" />
                        </Button>
                    </Dropdown>
                    <div className="order_filter_title">用户信息</div>
                    <Input className="order_search_input" ref="user_search" defaultValue="" />
                    <div className="order_filter_title">物流单号</div>
                    <Input className="order_search_input" ref="order_no_search" defaultValue="" />
                    <div className="order_filter_title">物品/备注信息</div>
                    <Input className="order_search_input" ref="item_search" defaultValue="" />
                    <Button className="manage_contents_title_margin" type="primary" onClick={this.ExpressOrderLookup} >
                        <Icon type="search" /></Button>
                </div >
                <div className="store_contents_title_sec">
                    <Button className="manage_contents_title_margin new_button_general_style"
                        onClick={this.ShowDlg.bind(this, 0)} disabled={this.state.btnControlDisable[0]}>
                        <Icon type="plus" />新建</Button>
                    <Button className="manage_contents_title_margin" type="primary" onClick={this.ShowDlg.bind(this, 1)} disabled={this.state.btnControlDisable[1]}>
                        <Icon type="edit" />编辑</Button>
                    <Button className="manage_contents_title_margin" type="primary" onClick={this.ShowDlg.bind(this, 3)} disabled={this.state.btnControlDisable[6]}>
                        <Icon type="database" />批量编辑</Button>
                    <Button className="manage_contents_title_margin" type="danger" onClick={this.ShowDlg.bind(this, 2)} disabled={this.state.btnControlDisable[2]}>
                        <Icon type="close" />删除</Button>
                    <Button className="manage_contents_title_margin" style={strCopyButtonStyle}
                        onClick={this.CopyCACNOrder} disabled={this.state.btnCopyPasteDisable[0]}>
                        <Icon type="copy" />复制</Button>
                    <Button className="manage_contents_title_margin" type="primary" onClick={this.PasteCACNOrder} disabled={this.state.btnCopyPasteDisable[1]}>
                        <Icon type="edit" /> 粘贴</Button>
                    <Button className="manage_contents_title_margin" type='primary' onClick={this.PrintOrder} disabled={this.state.btnControlDisable[2]}>
                        <Icon type="print" />打印面单</Button>
                    <Button className="manage_contents_title_margin manage_contents_title_margin_right export_button_style" onClick={this.ExportExcelFile}
                        disabled={this.state.btnControlDisable[5]}>
                        <Icon type="download" />导出订单列表</Button>
                    <Button className="manage_contents_title_margin manage_contents_title_margin_right export_button_style" onClick={this.DownloadTemplate}
                        style={{ marginLeft: '10px', marginRight: '5px' }}>
                        <Icon type="download" />下载模板</Button>
                    <div className="order_filter_title" style={{ marginLeft: '5px', marginRight: '5px' }}>导入物流状态</div>
                    <input type='file' accept='.xlsx, .xls' onChange={this.onImportExcel} ref="file_upload" id="file_upload" />
                </div>
            </div>
        );

        // 代理
        if (this.props.iUserLevel <= 5) {
            uiTitleArea = (
                <div className="store_contents_title" >
                    <Button className="manage_contents_title_margin manage_contents_title_margin_right" type="primary" onClick={this.ShowAllExpressEnter}>
                        显示全部<Icon type="redo" /></Button>
                    <div className="transit_price_title" style={{ minWidth: '80px' }}>{"运单状态"}</div>
                    <Dropdown className="order_detail_dropdown" trigger={['click']}
                        overlay={menuStatusFilter} placement="bottomLeft">
                        <Button className="transit_type_filter_dropdown_button">
                            <div className="order_details_dropdown_text">{this.arrStatusFilter[this.state.iStatusFilter]}</div>
                            <Icon type="caret-down" />
                        </Button>
                    </Dropdown>
                    <div className="order_filter_title">运单/合箱编码</div>
                    <Input className="order_search_input" ref="order_no_search" defaultValue="" />
                    {/* <Button className="manage_contents_title_margin" type="primary" onClick={this.ExpressOrderLookup.bind(this, 0)} >
                        <Icon type="search" /></Button> */}
                    <div className="order_filter_title">收件人/用户编码</div>
                    <Input className="order_search_input" ref="user_search" defaultValue="" />
                    <Button className="manage_contents_title_margin" type="primary" onClick={this.ExpressOrderLookup} >
                        <Icon type="search" /></Button>
                    <Button className="manage_title_button_right_side" type="primary" onClick={this.ShowDlg.bind(this, 1)} disabled={this.state.btnControlDisable[1]}>
                        <Icon type="edit" />
                        查 看</Button>
                    <Button className="manage_contents_title_margin export_button_style" onClick={this.ExportExcelFile} disabled={this.state.btnControlDisable[5]}>
                        <Icon type="export" /> 导出Excel</Button>
                </div >
            );
        }

        // M站针对加载状态时的界面处理
        // if (this.props.bMStation) {
        //     uiTitleArea = null;
        //     strLoadingStyle = "manage_empty_loading_m";
        // }

        // 如果界面上需要显示的内容已经从数据库获得
        if (!this.state.bLoading) {
            // 主体表格相关
            const { selectedRowKeys } = this.state;
            const rowSelection = {
                selectedRowKeys,
                onChange: this.onSelectedRowKeysChange,
                columnWidth: 30
            };
            // 表格分页属性
            // let uiPagination = (
            //     <Pagination showQuickJumper={false} position={'bottom'}
            //         defaultPageSize={10} pageSizeOptions={['10', '20', '50', '100']}
            //         total={this.iTotalOrderNum}
            //         showTotal={total => `Total ${total} 条 运单记录`}
            //         showSizeChanger onShowSizeChange={this.TableNumPerPageChange()}
            //         defaultCurrent={1}
            //     />
            // );
            let paginationProps = {
                showSizeChanger: true,
                showQuickJumper: false,
                showTotal: () => `共 ${this.iTotalOrderNum} 条 运单记录`,
                defaultPageSize: 10,
                defaultCurrent: 1,
                pageSizeOptions: ['10', '20', '50', '100'],
                total: this.iTotalOrderNum,
                position: 'bottom',
                onChange: this.TablePageChange.bind(this),
                onShowSizeChange: this.TableNumPerPageChange.bind(this)
            };
            // 用户信息编辑弹窗
            let strTitle = "新建运单";
            if (this.state.iDlgType === 1)
                strTitle = "编辑运单：";

            let bDefaultChecked = [false, false];
            if (this.state.iCurrentStatus === 1)
                bDefaultChecked[0] = true;
            if (this.state.iPackage)
                bDefaultChecked[1] = true;

            let uiModal = null;
            if (this.state.bShowDlg) {
                // 运单编号中的物品信息列表
                let uiItemInfoList = null;
                let iItemTotalNum = 1;
                if (this.objCurrentCACNOrder.item_info !== undefined)
                    if (this.objCurrentCACNOrder.item_info !== null) {
                        if (this.objCurrentCACNOrder.item_info.length > 0) {
                            if (typeof (this.objCurrentCACNOrder.item_info) === 'string')
                                this.objCurrentCACNOrder.item_info = JSON.parse(this.objCurrentCACNOrder.item_info);
                            iItemTotalNum = this.objCurrentCACNOrder.item_info.length;
                            uiItemInfoList = (
                                this.objCurrentCACNOrder.item_info.map((item, indexItem) => {
                                    // 物品大类菜单
                                    let menuItemType = (
                                        <Menu key={indexItem}>
                                            {this.arrItemType.map((type, indexType) => {
                                                return (
                                                    <Menu.Item key={indexItem * 100 + indexType} onClick={this.SwitchItemType.bind(this, indexType, indexItem)}>
                                                        {type.name}
                                                    </Menu.Item>
                                                );
                                            })}
                                        </Menu>
                                    );
                                    // 物品小类菜单
                                    // let menuItemSubType = (
                                    //     <Menu key={indexItem}>
                                    //         {this.arrItemType[item.type].sub_type.map((sub_type, indexSubType) => {
                                    //             return (
                                    //                 <Menu.Item key={indexItem * 100 + indexSubType}
                                    //                     onClick={this.SwitchItemSubType.bind(this, indexSubType, indexItem)}>
                                    //                     {sub_type}
                                    //                 </Menu.Item>
                                    //             );
                                    //         })}
                                    //     </Menu>
                                    // );

                                    let strItemBlockStyle = "express_item_info_block";
                                    if (indexItem % 2 === 0)
                                        strItemBlockStyle = "express_item_info_block_light";
                                    return (
                                        <div key={indexItem} className={strItemBlockStyle}>
                                            <div className="express_item_info_row">
                                                <div className="express_enter_edit_title_short">名称</div>
                                                <Input style={{ width: '200px' }} ref={"item_name_" + indexItem}
                                                    defaultValue={item.name} value={item.name}
                                                    onChange={this.ItemInfoChange.bind(this, indexItem, 0)} />
                                                <div className="express_enter_edit_title_short">品牌</div>
                                                <Input style={{ width: '200px' }} ref={"item_name_" + indexItem} value={item.brand}
                                                    defaultValue={item.brand} onChange={this.ItemInfoChange.bind(this, indexItem, 1)} />
                                                <div className="express_enter_edit_title_short">大类</div>
                                                <Dropdown overlay={menuItemType} trigger={['click']} placement="bottomLeft" style={{ width: '200px' }}>
                                                    <Button className="ca_cn_order_dropdown_button">
                                                        <div className="order_details_dropdown_text">{this.arrItemType[item.type].name}</div>
                                                        <Icon type="caret-down" />
                                                    </Button>
                                                </Dropdown>
                                                {/* <div className="express_enter_edit_title_short">小类</div>
                                                <Dropdown overlay={menuItemSubType} trigger={['click']} placement="bottomLeft" style={{ width: '200px' }}>
                                                    <Button className="ca_cn_order_dropdown_button">
                                                        <div className="order_details_dropdown_text">{this.arrItemType[item.type].sub_type[item.sub_type]}</div>
                                                        <Icon type="caret-down" />
                                                    </Button>
                                                </Dropdown> */}
                                                <div className="express_item_delete_button" style={{ marginLeft: 'auto', marginRight: '0px' }}
                                                    onClick={this.RemoveItemInfo.bind(this, indexItem)}>
                                                    <Icon type="delete" theme="filled" />
                                                </div>
                                            </div>
                                            <div className="express_item_info_row">
                                                <div className="express_enter_edit_title_short">规格</div>
                                                <Input style={{ width: '200px' }} ref={"item_style_" + indexItem} value={item.style}
                                                    defaultValue={item.style} onChange={this.ItemInfoChange.bind(this, indexItem, 2)} />
                                                <div className="express_enter_edit_title_short">数量</div>
                                                <Input className="express_enter_item_info_input_short" ref={"item_num_" + indexItem} value={item.num}
                                                    defaultValue={item.num} style={{ width: '200px' }} onChange={this.ItemInfoChange.bind(this, indexItem, 3)} />
                                                <div className="express_enter_edit_title_short">单价</div>
                                                <Input style={{ width: '200px' }} ref={"item_name_" + indexItem} value={item.price}
                                                    defaultValue={item.price} addonBefore={'$'} onChange={this.ItemInfoChange.bind(this, indexItem, 4)} />
                                            </div>
                                        </div>
                                    );
                                })
                            );
                        }
                    }

                let uiPhotoID1 = null; let uiPhotoID2 = null;
                if (this.state.bShowDlg /*&& this.state.iDlgType === 1*/) {
                    if (this.objCurrentReceiver.id_s3_link1 !== undefined && this.objCurrentReceiver.id_s3_link1 !== null && this.objCurrentReceiver.id_s3_link1 !== '') {
                        let objFile1 = this.objCurrentReceiver.id_s3_link1;
                        if (typeof (objFile1) === 'string')
                            objFile1 = JSON.parse(objFile1);
                        uiPhotoID1 = (
                            <img src={objFile1.location} alt='' style={{ marginLeft: '20px', minWidth: '40%', maxWidth: '40%', marginRight: '12%' }} />
                        );
                    }
                    if (this.objCurrentReceiver.id_s3_link2 !== undefined && this.objCurrentReceiver.id_s3_link2 !== null && this.objCurrentReceiver.id_s3_link2 !== '') {
                        let objFile2 = this.objCurrentReceiver.id_s3_link2;
                        if (typeof (objFile2) === 'string')
                            objFile2 = JSON.parse(objFile2);
                        uiPhotoID2 = (
                            <img src={objFile2.location} alt='' style={{ minWidth: '40%', maxWidth: '40%' }} />
                        );
                    }
                }

                let bEditDisable = false;
                let uiTitle = null;
                if (this.state.iDlgType === 0) {
                    bEditDisable = true;
                }
                uiTitle = (
                    <div className="ca_cn_order_dlg_title">
                        {strTitle}<div style={{ width: '15px' }} />
                        {this.objCurrentCACNOrder.order_no}
                    </div>
                );

                // 省份下拉菜单
                const menuCNProvinces = (
                    <Menu>
                        {this.objChinaCity.provinces.map((province, index) => {
                            return (
                                <Menu.Item key={index} onClick={this.SwitchCNProvince.bind(this, index)}>
                                    {province}
                                </Menu.Item>
                            );
                        })}
                    </Menu>
                );
                // 城市下拉菜单
                const menuCNCities = (
                    <Menu>
                        {this.objChinaCity.cities.map((city, index) => {
                            return (
                                <Menu.Item key={index} onClick={this.SwitchCNCity.bind(this, index)}>
                                    {city}
                                </Menu.Item>
                            );
                        })}
                    </Menu>
                );
                // 地区下拉菜单
                const menuCNAreas = (
                    <Menu>
                        {this.objChinaCity.areas.map((area, index) => {
                            return (
                                <Menu.Item key={index} onClick={this.SwitchCNArea.bind(this, index)}>
                                    {area}
                                </Menu.Item>
                            );
                        })}
                    </Menu>
                );
                let uiDlgTitle = null;
                // 选择发件人和收件人对话框
                let uiSelectShipperDlg = null;
                if (this.state.bSelectShipperDlg) {
                    uiDlgTitle = (
                        <div className="user_edit_info_row">
                            {"选择发件人"}
                            <Input className="order_search_input" ref="search_shipper" defaultValue="" style={{ marginLeft: '20px' }} />
                            <Button className="manage_contents_title_margin" type="primary" onClick={this.SearchShipperReceiver}>
                                <Icon type="search" />搜索</Button>
                        </div>
                    )
                    uiSelectShipperDlg = (
                        <Modal width={950} title={uiDlgTitle} visible={this.state.bSelectShipperDlg} footer={null} centered
                            onOk={this.SelectShipperConfirm} onCancel={this.SelectCancel} >
                            {
                                this.arrShipperShow.map((shipper, indexShipper) => {
                                    return (
                                        <div key={indexShipper} className='shipper_receiver_select_block' onClick={this.SelectShipperConfirm.bind(this, indexShipper)}>
                                            <div className="user_edit_info_row">
                                                <div className="express_enter_edit_title">姓名：</div>
                                                <Input className="user_edit_info_input" value={shipper.name} disabled />
                                                <div className="express_enter_edit_title">电话：</div>
                                                <Input className="user_edit_info_input" value={shipper.phone} disabled />
                                                <div className="express_enter_edit_title">邮箱：</div>
                                                <Input className="user_edit_info_input" value={shipper.email} disabled />
                                            </div>
                                            <div className="user_edit_info_row">
                                                <div className="express_enter_edit_title">地址：</div>
                                                <Input className="express_enter_item_info_input_long" value={shipper.address} disabled />
                                                <div className="express_enter_edit_title">邮编：</div>
                                                <Input className="user_edit_info_input" value={shipper.postcode} disabled />
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </Modal>
                    );
                }

                let uiSelectReceiverDlg = null;
                if (this.state.bSelectReceiverDlg) {
                    uiDlgTitle = (
                        <div className="user_edit_info_row">
                            {"选择收件人"}
                            <Input className="order_search_input" ref="search_receiver" defaultValue="" style={{ marginLeft: '20px' }} />
                            <Button className="manage_contents_title_margin" type="primary" onClick={this.SearchShipperReceiver}>
                                <Icon type="search" />搜索</Button>
                        </div>
                    )
                    uiSelectReceiverDlg = (
                        <Modal width={960} title={uiDlgTitle} visible={this.state.bSelectReceiverDlg} footer={null} centered
                            onCancel={this.SelectCancel} >
                            {
                                this.arrReceiverShow.map((receiver, indexReceiver) => {
                                    return (
                                        <div key={indexReceiver} className='shipper_receiver_select_block' onClick={this.SelectReceiverConfirm.bind(this, indexReceiver)}>
                                            <div className="user_edit_info_row">
                                                <div className="express_enter_edit_title">姓名：</div>
                                                <Input className="user_edit_info_input" value={receiver.name} disabled />
                                                <div className="express_enter_edit_title">电话：</div>
                                                <Input className="user_edit_info_input" value={receiver.phone} disabled />
                                                <div className="express_enter_edit_title">邮箱：</div>
                                                <Input className="user_edit_info_input" value={receiver.email} disabled />
                                            </div>
                                            <div className="user_edit_info_row">
                                                <div className="express_enter_edit_title">地址：</div>
                                                <Input className="express_enter_item_info_input_long" value={receiver.address} disabled />
                                                <div className="express_enter_edit_title">邮编：</div>
                                                <Input className="user_edit_info_input" value={receiver.postcode} disabled />
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </Modal>
                    );
                }
                if (this.state.bViewTrackingInfo) {
                    uiDlgTitle = (
                        <div className="ca_cn_order_dlg_title">
                            {strTitle}<div style={{ width: '15px' }} />
                            {this.objCurrentCACNOrder.order_no}
                            <div className="ca_cn_order_button" style={{ marginLeft: "20px" }}
                                onClick={this.CreateNewTrackingInfo}>新建物流状态</div>
                        </div>
                    );
                }
                let uiTimeLineInfo = (
                    <Timeline>
                        <Timeline.Item>暂无物流信息</Timeline.Item>
                    </Timeline>

                );
                let arrExpressDetails = [];
                let uiViewTrckingInfoButton = (
                    <div className="ca_cn_order_edit_row">
                        {"暂无"}
                        <div className="ca_cn_order_button" style={{ marginLeft: "10px" }} onClick={this.ViewExpressDetail}>查看/编辑</div>
                    </div>
                );
                if (this.objCurrentCACNOrder.express_detail !== undefined && this.objCurrentCACNOrder.express_detail !== null &&
                    this.objCurrentCACNOrder.express_detail !== '') {
                    arrExpressDetails = JSON.parse(this.objCurrentCACNOrder.express_detail);
                    if (arrExpressDetails.length > 0)
                        uiViewTrckingInfoButton = (
                            <div className="ca_cn_order_edit_row">
                                {arrExpressDetails[arrExpressDetails.length - 1].express_name} -
                                {arrExpressDetails[arrExpressDetails.length - 1].status}
                                <div className="ca_cn_order_button" style={{ marginLeft: "10px" }}
                                    onClick={this.ViewExpressDetail}>查看/编辑</div>
                            </div>
                        );
                }
                if (arrExpressDetails.length > 0) {
                    uiTimeLineInfo = (
                        <Timeline style={{ marginLeft: '15px' }}>
                            { arrExpressDetails.map((item, index) => {
                                return (
                                    <Timeline.Item key={index} >
                                        <div className="user_edit_info_row" style={{ minWidth: '50%', marginLeft: '15px', marginTop: '15px', marginBottom: '10px' }}>
                                            <div style={{ minWidth: '50%', marginLeft: '5px', fontSize: '15px' }}>
                                                {item.status + " - " + item.update_time}</div>
                                            <Icon type="edit" theme="filled" style={{ marginLeft: '50px', fontSize: '17.5px', color: 'darkcyan' }}
                                                onClick={this.EditTrackingInfo.bind(this, index)} />
                                            <Icon type="delete" theme="filled" style={{ marginLeft: '10px', fontSize: '17.5px', color: 'lightcoral' }}
                                                onClick={this.DeleteOrderExpressDetail.bind(this, index)} />
                                        </div>
                                    </Timeline.Item>
                                );
                            })}
                        </Timeline>
                    );
                }
                let uiEditTrackingInfoDlg = null;
                if (this.state.bEditTrackingInfo) {
                    let strTrackingInfo = "";
                    let strUpdateDate = "";
                    let strExpressName = "德邦物流";
                    let uiExpressOrder = null;
                    if (this.iCurrentTrackingInfo >= 0 && arrExpressDetails.length > 0) {
                        strTrackingInfo = arrExpressDetails[this.iCurrentTrackingInfo].status;
                        strUpdateDate = arrExpressDetails[this.iCurrentTrackingInfo].update_time;
                        strExpressName = arrExpressDetails[this.iCurrentTrackingInfo].express_name;
                        uiExpressOrder = (
                            <div className="user_edit_info_row">
                                <div className="express_enter_edit_title" style={{ fontWeight: 'bold' }}>物流名称：</div>
                                <Input className="user_edit_info_input" ref="tracking_info_express_name"
                                    defaultValue={strExpressName} />
                            </div>
                        );

                    } else {
                        if (arrExpressDetails.length > 0)
                            strExpressName = arrExpressDetails[arrExpressDetails.length - 1].express_name;
                        uiExpressOrder = (
                            <div className="user_edit_info_row">
                                <div className="express_enter_edit_title" style={{ fontWeight: 'bold' }}>物流名称：</div>
                                <Input className="user_edit_info_input" ref="tracking_info_express_name"
                                    defaultValue={strExpressName} />
                            </div>
                        );
                    }
                    uiEditTrackingInfoDlg = (
                        <Modal title={"新建/编辑物流状态"} width={450} visible={this.state.bEditTrackingInfo} maskClosable={false} centered
                            okText="确定" cancelText="取消" onOk={this.ConfirmTrackingInfo.bind(this, 1)} onCancel={this.ConfirmTrackingInfo.bind(this, 0)} >
                            {uiExpressOrder}
                            <div className="user_edit_info_row">
                                <div className="express_enter_edit_title" style={{ fontWeight: 'bold' }}>物流状态：</div>
                                <Input className="user_edit_info_input" ref="tracking_info"
                                    defaultValue={strTrackingInfo} />
                            </div>
                            <div className="user_edit_info_row">
                                <div className="express_enter_edit_title" style={{ fontWeight: 'bold' }}>更新日期：</div>
                                <Input className="user_edit_info_input" ref="tracking_info_update_date" placeholder='YYYY/MM/DD'
                                    defaultValue={strUpdateDate} />
                            </div>
                        </Modal>
                    );
                }

                let uiViewTrackingInfoDlg = null;
                if (this.state.bViewTrackingInfo)
                    uiViewTrackingInfoDlg = (
                        <Modal title={uiDlgTitle} width={600} visible={this.state.bViewTrackingInfo} maskClosable={false} centered
                            okText="确定" cancelText="取消" onOk={this.UpdateCurrentOrderExpressDetail} onCancel={this.CloseExpressDetail} >
                            {uiTimeLineInfo}
                            {uiEditTrackingInfoDlg}
                        </Modal>
                    );

                uiModal = (
                    <Modal title={uiTitle} width={950} visible={this.state.bShowDlg} maskClosable={false}
                        okText="确定" cancelText="取消" onOk={this.handleOk} onCancel={this.handleCancel} >
                        <div className="no_mouse_select">
                            <div className="ca_cn_order_title" style={{ marginTop: '0px' }}>
                                运单信息
                            </div>
                            <div className="user_edit_info_row">
                                <div className="express_enter_edit_title" style={{ color: 'lightcoral', fontWeight: 'bold' }}>用户编码：</div>
                                <Input className="user_edit_info_input" ref="user_code" disabled
                                    defaultValue={'XYJ123'} />
                                <div className="express_enter_edit_title" style={{ color: 'lightcoral', fontWeight: 'bold' }}>用户名称：</div>
                                <Input className="user_edit_info_input" ref="user_name" disabled
                                    defaultValue={'xiyouji'} />
                                <div className="express_enter_edit_title" style={{ color: 'lightcoral', fontWeight: 'bold' }}>运单状态：</div>
                                <Dropdown overlay={menuStatusChange} trigger={['click']} placement="bottomLeft" disabled={bEditDisable}>
                                    <Button className="transit_type_dropdown_button">
                                        <div className="order_details_dropdown_text">{this.arrStatusChange[this.state.iCurrentStatus]}</div>
                                        <Icon type="caret-down" />
                                    </Button>
                                </Dropdown>
                            </div>
                            <div className="user_edit_info_row ca_cn_order_row_bottom">
                                <div className="express_enter_edit_title" style={{ color: 'lightcoral', fontWeight: 'bold' }}>送达仓库：</div>
                                <Dropdown overlay={menuStorage} trigger={['click']} placement="bottomLeft" >
                                    <Button className="transit_type_dropdown_button">
                                        <div className="order_details_dropdown_text">{this.arrStorage[this.state.iCurrentStorage]}</div>
                                        <Icon type="caret-down" />
                                    </Button>
                                </Dropdown>
                                <div className="express_enter_edit_title" style={{ color: 'lightcoral', fontWeight: 'bold' }}>线路名称：</div>
                                <Dropdown overlay={menuTransitType} trigger={['click']} >
                                    <Button className="transit_type_dropdown_button">
                                        <div className="order_details_dropdown_text">{this.arrTransitType[this.state.iTransitType]}</div>
                                        <Icon type="caret-down" />
                                    </Button>
                                </Dropdown>
                                <div className="express_enter_edit_title" style={{ fontWeight: 'bold' }}>发货批次：</div>
                                <Input className="user_edit_info_input" ref="batch_no"
                                    defaultValue={this.objCurrentCACNOrder.batch_no} />
                            </div>
                            <div className="user_edit_info_row ca_cn_order_row_bottom">
                                <div className="express_enter_edit_title" style={{ fontWeight: 'bold' }}>物流单号：</div>
                                <Input className="user_edit_info_input" ref="tracking_no"
                                    defaultValue={this.objCurrentCACNOrder.tracking_no} />
                                <div className="express_enter_edit_title" style={{ fontWeight: 'bold' }}>物流状态：</div>
                                {uiViewTrckingInfoButton}
                                {/* <Input className="ca_cn_order_input_mid" ref="express_detail"
                                    defaultValue={this.objCurrentCACNOrder.express_detail} /> */}
                            </div>
                            <div className="ca_cn_order_title">
                                发件人
                                 <div className="ca_cn_order_button ca_cn_order_button_right_layout"
                                    onClick={this.SelectShipper}>选择</div>
                                {/* <div className="ca_cn_order_button"
                                    onClick={this.SaveShipperReceiver.bind(this, 0)}>保存</div> */}
                            </div>
                            <div className="user_edit_info_row">
                                <div className="express_enter_edit_title" style={{ color: 'lightcoral', fontWeight: 'bold' }} >姓名：</div>
                                <Input className="user_edit_info_input" ref="shipper_name" defaultValue={this.objCurrentCACNOrder.shipper_name} />
                                <div className="express_enter_edit_title" style={{ color: 'lightcoral', fontWeight: 'bold' }}>电话：</div>
                                <Input className="user_edit_info_input" ref="shipper_phone" defaultValue={this.objCurrentCACNOrder.shipper_phone} />
                            </div>
                            <div className="user_edit_info_row ca_cn_order_row_bottom">
                                <div className="express_enter_edit_title">地址：</div>
                                <Input className="express_enter_item_info_input_full" ref="shipper_address"
                                    defaultValue={this.objCurrentCACNOrder.shipper_address} />
                            </div>
                            <div className="ca_cn_order_title">
                                收件人
                                 <div className="ca_cn_order_button ca_cn_order_button_right_layout"
                                    onClick={this.SelectReceiver}>选择</div>
                                {/* <div className="ca_cn_order_button"
                                    onClick={this.SaveShipperReceiver.bind(this, 1)}>保存</div> */}
                            </div>
                            <div className="user_edit_info_row">
                                <div className="express_enter_edit_title" style={{ color: 'lightcoral', fontWeight: 'bold' }}>姓名：</div>
                                <Input className="user_edit_info_input" ref="receiver_name" defaultValue={this.objCurrentCACNOrder.receiver_name} />
                                <div className="express_enter_edit_title" style={{ color: 'lightcoral', fontWeight: 'bold' }}>电话：</div>
                                <Input className="user_edit_info_input" ref="receiver_phone" defaultValue={this.objCurrentCACNOrder.receiver_phone} />
                                <div className="express_enter_edit_title" /*style={{ color: 'lightcoral', fontWeight: 'bold' }}*/>邮编：</div>
                                <Input className="user_edit_info_input" ref="receiver_postcode" defaultValue={this.objCurrentCACNOrder.receiver_postcode} />
                            </div>
                            <div className="user_edit_info_row">
                                <div className="express_enter_edit_title" style={{ color: 'lightcoral', fontWeight: 'bold' }}>省份：</div>
                                <Dropdown overlay={menuCNProvinces} trigger={['click']} placement="bottomLeft"
                                    overlayStyle={{ maxHeight: '75vh', overflow: 'auto' }} style={{ maxHeight: '75vh', overflow: 'auto' }}>
                                    <Button className="transit_type_dropdown_button">
                                        <div className="order_details_dropdown_text">{this.objChinaCity.provinces[this.state.iCurrentProvince]}</div>
                                        <Icon type="caret-down" />
                                    </Button>
                                </Dropdown>
                                <div className="express_enter_edit_title" style={{ color: 'lightcoral', fontWeight: 'bold' }}>城市：</div>
                                <Dropdown overlay={menuCNCities} trigger={['click']} placement="bottomLeft"
                                    overlayStyle={{ maxHeight: '75vh', overflow: 'auto' }} style={{ maxHeight: '75vh', overflow: 'auto' }}>
                                    <Button className="transit_type_dropdown_button">
                                        <div className="order_details_dropdown_text">{this.objChinaCity.cities[this.state.iCurrentCity]}</div>
                                        <Icon type="caret-down" />
                                    </Button>
                                </Dropdown>
                                <div className="express_enter_edit_title" style={{ color: 'lightcoral', fontWeight: 'bold' }}>地区：</div>
                                <Dropdown overlay={menuCNAreas} trigger={['click']} placement="bottomLeft"
                                    overlayStyle={{ maxHeight: '75vh', overflow: 'auto' }} style={{ maxHeight: '75vh', overflow: 'auto' }}>
                                    <Button className="transit_type_dropdown_button">
                                        <div className="order_details_dropdown_text">{this.objChinaCity.areas[this.state.iCurrentArea]}</div>
                                        <Icon type="caret-down" />
                                    </Button>
                                </Dropdown>
                            </div>
                            <div className="user_edit_info_row">
                                <div className="express_enter_edit_title" style={{ color: 'lightcoral', fontWeight: 'bold' }}>地址：</div>
                                <Input className="express_enter_item_info_input_full" ref="receiver_address" defaultValue={this.objCurrentCACNOrder.receiver_address} />
                            </div>
                            <div className="user_edit_info_row">
                                <div className="express_enter_edit_title" >身份证：</div>
                                <Input className="express_enter_item_info_input_full" ref="id_number"
                                    defaultValue={this.objCurrentCACNOrder.id_number} />
                            </div>
                            <div className="user_edit_info_row">
                                <div className="express_enter_edit_title" >正面：</div>
                                <input className="auto_order_select_file_button" type='file' accept='.jpg,.jpeg,.png,.gif,.pdf'
                                    ref='file_upload1' id="file_upload1" onChange={this.SelectSingleFile.bind(this, 0)}
                                    style={{ marginRight: '27.5px' }} />
                                <div className="express_enter_edit_title" >反面：</div>
                                <input className="auto_order_select_file_button" type='file' accept='.jpg,.jpeg,.png,.gif,.pdf'
                                    ref='file_upload2' id="file_upload2" onChange={this.SelectSingleFile.bind(this, 1)} />
                            </div>
                            <div className="user_edit_info_row">
                                {uiPhotoID1} {uiPhotoID2}
                            </div>
                            <div className="ca_cn_order_title">
                                物品信息
                            </div>
                            <div className="user_edit_info_row">
                                <div className="express_enter_edit_title" >包裹实重：</div>
                                <Input className="user_edit_info_input" ref="item_weight" addonAfter={"lb"}
                                    defaultValue={this.objCurrentCACNOrder.weight} />
                                <div className="express_enter_edit_title" >保额：</div>
                                <Input className="user_edit_info_input" ref="insurance" addonBefore={"$"}
                                    defaultValue={this.objCurrentCACNOrder.insurance} />
                                <div className="express_enter_edit_title" >其他费用：</div>
                                <Input className="user_edit_info_input" ref="other_fee" addonBefore={"$"}
                                    defaultValue={this.objCurrentCACNOrder.other_fee} />
                            </div>
                            <div className="user_edit_info_row">
                                <div className="express_enter_edit_title">备注信息：</div>
                                <Input className="express_enter_item_info_input_full" ref="comments"
                                    defaultValue={this.objCurrentCACNOrder.comments} />
                            </div>
                        </div>
                        <div className="user_edit_info_row">
                            <div className="express_enter_express_info_add_button express_enter_express_info_add_button_left"
                                onClick={this.AddNewItemInfo}>+ 物品信息</div>
                            {"共 " + iItemTotalNum + " 种物品"}
                            <div className="express_enter_express_info_clean_button" onClick={this.ClearItemInfo}>
                                <Icon type="delete" theme="filled" style={{ marginRight: '10px' }} />清空</div>
                        </div>
                        {uiItemInfoList}
                        {uiSelectShipperDlg}
                        {uiSelectReceiverDlg}
                        {uiViewTrackingInfoDlg}
                    </Modal>
                );

            }
            if (this.state.iDlgType === 2) {
                if (this.arrSelectedCACNOrder.length === 1) {
                    uiModal = (
                        <Modal width={350} title={"删除运单信息"} visible={this.state.bShowDlg} okType="danger"
                            onOk={this.handleOk} okText="确定" onCancel={this.handleCancel} cancelText="取消">
                            <div className="modal_item">
                                <div>
                                    {"用户信息：" + this.objCurrentCACNOrder.user_name + " / " + this.objCurrentCACNOrder.user_code}
                                    <br /><br />
                                    {"运单号：" + this.objCurrentCACNOrder.order_no}
                                    <br /><br />
                                确定删除选中的运单信息？
                            </div>
                            </div>
                        </Modal>
                    );
                } else {
                    uiModal = (
                        <Modal width={350} title={"删除运单信息"} visible={this.state.bShowDlg} okType="danger"
                            onOk={this.handleOk} okText="确定" onCancel={this.handleCancel} cancelText="取消">
                            <div className="modal_item">
                                <div>
                                    {"运单号：" + this.arrSelectedCACNOrder[0].order_no + " 等 "
                                        + this.arrSelectedCACNOrder.length + " 个运单"}
                                    <br /><br />
                                确定删除选中的运单信息？
                            </div>
                            </div>
                        </Modal>
                    );
                }
            }
            let uiTable = (
                <Table style={{ marginLeft: '30px', marginRight: '30px', marginTop: '10px', width: '95%', overflowX: 'auto', userSelect: 'text' }}
                    rowSelection={rowSelection} pagination={paginationProps}
                    columns={this.tableHead} dataSource={this.arrTableData} bordered
                    onRow={(record) => ({
                        onClick: () => { this.selectRow(record); },
                        // onDoubleClick: () => { this.DoubleClickRow(record); }
                    })} />
            );

            // 批量编辑对话框
            let uiMultiEditDlg = null;
            if (this.state.bMultiEditDlg) {
                uiMultiEditDlg = (
                    <Modal width={500} title={"批量编辑"} visible={this.state.bMultiEditDlg} okText="确定" cancelText="取消"
                        onOk={this.MultiEditConfirm} onCancel={this.MultiEditCancel} >
                        <div className="user_edit_info_row">
                            <Checkbox className="express_enter_checkbox" onChange={this.MultiEditCheck.bind(this, 0)} checked={this.state.arrMultiEditCheck[0]} />
                            <div className="express_enter_edit_title">运单状态：</div>
                            <Dropdown overlay={menuStatusChange} trigger={['click']} placement="bottomLeft" >
                                <Button className="transit_type_dropdown_button">
                                    <div className="order_details_dropdown_text">{this.arrStatusChange[this.state.iCurrentStatus]}</div>
                                    <Icon type="caret-down" />
                                </Button>
                            </Dropdown>
                        </div>
                        <div className="user_edit_info_row">
                            <Checkbox className="express_enter_checkbox" onChange={this.MultiEditCheck.bind(this, 1)} checked={this.state.arrMultiEditCheck[1]} />
                            <div className="express_enter_edit_title">发货批次：</div>
                            <Input className="user_edit_info_input" ref="multi_batch_no" defaultValue={""} />
                        </div>
                        {/* <div className="user_edit_info_row">
                            <Checkbox className="express_enter_checkbox" onChange={this.MultiEditCheck.bind(this, 2)} checked={this.state.arrMultiEditCheck[2]} />
                            <div className="express_enter_edit_title">物流状态：</div>
                            <Input className="user_edit_info_input" ref="multi_express_detail" defaultValue={""} />
                        </div> */}
                    </Modal>
                );
            }
            // 打印弹窗
            let uiPrintDlg = null;
            if (this.state.bPrintDlg) {

                let uiPrintButton = (
                    <ReactToPrint trigger={() => <div> 打印 </div>} content={() => this.componentRef} />
                );

                let uiOrderList = (
                    this.arrSelectedCACNOrder.map((order, index) => {
                        let uiItemList = null;
                        if (order.item_info !== undefined && order.item_info !== null &&
                            order.item_info !== "") {
                            if (order.item_info.length > 0) {
                                uiItemList = "";
                                for (let i = 0; i < order.item_info.length; i++) {
                                    uiItemList += order.item_info[i].name + " x " + order.item_info[i].num;
                                    if (i !== order.item_info.length - 1)
                                        uiItemList += "; ";
                                }
                            }
                        }
                        let strShipperInfo1 = order.shipper_name + ", " + order.shipper_phone;
                        let strShipperInfo2 = order.shipper_address;
                        let strReceiverInfo1 = order.receiver_name + ", " + order.receiver_phone;
                        let strReceiverInfo2 = order.receiver_address;
                        let strBlockStyle = "ca_cn_order_print_block";
                        if (index === this.arrSelectedCACNOrder.length - 1) {
                            strBlockStyle += " ca_cn_order_print_block_no_space";
                        }
                        return (
                            <div className={strBlockStyle}>
                                <div className="ca_cn_order_print_row ca_cn_order_print_layout_center">
                                    <Barcode value={order.order_no} renderer={'img'} displayValue={true} width={2.4} height={55} fontSize={15}
                                        background={'rgba(0, 0, 0, 0)'} ref={(el) => (this.componentRef = el)} style={{ marginLeft: 'auto', marginRight: 'auto' }} />
                                </div>
                                <div className="ca_cn_order_print_row ca_cn_order_print_fixed_height1">
                                    <div className="ca_cn_order_print_title">
                                        寄件： </div>
                                    <div>
                                        <div className="ca_cn_order_print_contents">
                                            {strShipperInfo1}
                                        </div>
                                        <div className="ca_cn_order_print_contents">
                                            {strShipperInfo2}
                                        </div>
                                    </div>
                                </div>
                                <div className="ca_cn_order_print_row ca_cn_order_print_fixed_height1">
                                    <div className="ca_cn_order_print_title">
                                        收件：
                                </div>
                                    <div>
                                        <div className="ca_cn_order_print_contents">
                                            {strReceiverInfo1}
                                        </div>
                                        <div className="ca_cn_order_print_contents">
                                            {strReceiverInfo2}
                                        </div>
                                    </div>
                                </div>
                                <div className="ca_cn_order_print_row ca_cn_order_print_fixed_height2">
                                    <div className="ca_cn_order_print_title" >
                                        货物： </div>
                                    <div className="ca_cn_order_print_contents">
                                        {uiItemList}
                                    </div>
                                </div>
                                <div className="ca_cn_order_print_row" style={{ border: 'none' }}>
                                    <div className="ca_cn_order_print_title">
                                        网址： </div>
                                    <div className="ca_cn_order_print_contents">
                                        {"www.xiyouji.ca"}
                                    </div>
                                </div>
                            </div>
                        );
                    })
                );

                uiPrintDlg = (
                    <Modal title={"运单信息打印"} width={'12.5cm'} visible={this.state.bPrintDlg} maskClosable={true} centered
                        okText={uiPrintButton} cancelText="取消" onOk={this.PrintOK} onCancel={this.PrintCancel} >
                        <div className="ca_cn_order_print_area " ref={(el) => (this.componentRef = el)}>
                            {uiOrderList}
                        </div>
                    </Modal >
                );
            }

            // M站布局
            if (this.props.bMStation) {
                // strAreaStyle = "store_manage_contents_m";
                // paginationProps = {
                //     showSizeChanger: true,
                //     showQuickJumper: false,
                //     showTotal: () => `共 ${this.arrTableData.length} 条 入仓信息`,
                //     defaultPageSize: 10,
                //     defaultCurrent: this.state.iCurrentPage,
                //     pageSizeOptions: ['10', '20', '30', '40', '50'],
                //     total: this.arrTableData.length,
                //     position: 'bottom'
                // };
                // let strNewButtonStyle = { backgroundColor: 'lightseagreen', color: 'white' };
                // uiTitleArea = (
                //     <div>
                //         <div className="store_contents_title_m" >
                //             <div className="order_filter_title">关键字</div>
                //             <Input className="order_search_input" ref="search" defaultValue="" />
                //             <Button className="store_manager_control_button" type="primary" onClick={this.ExpressOrderLookup} >
                //                 <Icon type="search" />
                //             </Button>
                //         </div >
                //         <div className="store_contents_title_sec_m" >
                //             <Button className="manage_contents_title_margin manage_contents_title_margin_right" type="primary" onClick={this.ShowAllExpressEnter} >
                //                 显示全部</Button>
                //             <div className="transit_price_title" style={{ minWidth: '80px' }}>{"运单状态"}</div>
                //             <Dropdown className="order_detail_dropdown" trigger={['click']}
                //                 overlay={menuStatusFilter} placement="bottomLeft">
                //                 <Button className="transit_type_filter_dropdown_button">
                //                     <div className="order_details_dropdown_text">{this.arrStatusFilter[this.state.iStatusFilter]}</div>
                //                     <Icon type="caret-down" />
                //                 </Button>
                //             </Dropdown>
                //         </div>
                //         <div className="store_contents_title_thd_m" >
                //             <Button className="store_manager_control_button" style={strNewButtonStyle} onClick={this.ShowDlg.bind(this, 0)}
                //                 disabled={this.state.btnControlDisable[0]}>
                //                 <Icon type="plus" /></Button>
                //             <Button className="store_manager_control_button" type="primary" onClick={this.ShowDlg.bind(this, 1)} disabled={this.state.btnControlDisable[1]}>
                //                 <Icon type="edit" /></Button>
                //             <Button className="store_manager_control_button" type="danger" onClick={this.ShowDlg.bind(this, 2)} disabled={this.state.btnControlDisable[2]}>
                //                 <Icon type="close" /></Button>
                //         </div>
                //     </div>
                // );
                // uiTable = (
                //     <div className="express_enter_table_area_m">
                //         <Table style={{ width: '100%', height: '100%', overflowX: 'scroll', overflowY: 'scroll' }} bordered
                //             rowSelection={rowSelection} pagination={paginationProps}
                //             columns={this.tableHead} dataSource={this.arrTableData}
                //             onRow={(record) => ({ onClick: () => { this.selectRow(record); } })} />
                //     </div>

                // );
            }




            return (
                <div className={strAreaStyle}>
                    {uiTitleArea}
                    {uiModal}
                    {uiTable}
                    {uiMultiEditDlg}
                    {uiPrintDlg}
                </div >
            );

        } else {
            return (
                <div className={strAreaStyle}>
                    {uiTitleArea}
                    <Spin className={strLoadingStyle} size="large" tip="加载中……" />
                </div>
            );
        }
    }
}
export default CACNOrder;
