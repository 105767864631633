import React, { Component } from "react";
import axios from 'axios';
import moment from 'moment';

import Statistic from 'antd/es/statistic';
import Icon from 'antd/es/icon';
import DatePicker from 'antd/es/date-picker';
import message from 'antd/es/message';
// import ReactHighCharts from "react-highcharts";
// import Radio from "antd/es/radio";
// import Button from "antd/es/button";
// import Modal from "antd/es/modal";
// import Input from "antd/es/input";

import '../../../styles/icon_image.css';
import '../../../styles/Background/ego_manager.css';
import '../../../styles/Background/store_manager.css';
import '../../../styles/Background/UI_Module/general_data_show.css';

const g_strMessageKey = 'updating';

const { /*MonthPicker,*/ RangePicker } = DatePicker;
const dateFormat = 'YYYY/MM/DD';
// const monthFormat = 'YYYY/MM';
// const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];


class TruckingDataPanel extends Component {
    constructor(props) {
        super();
        this.state = {
            bLoading: true,
            iCharContent: 0,
            bSelectUserDlg: false
        };

        this.dateDefault = moment();
        this.dateDefault = this.dateDefault.subtract(1, 'days').format('YYYY-MM-DD');
        this.dateDefault = moment(this.dateDefault);
        this.dateCurrent = new Date(this.dateDefault).toISOString().split('T')[0];

        // 管理后台统计数据
        this.objCalculateData = -1;  // 直接获取的统计数据
        this.arrDAU = [];  // 活跃用户
        this.arrDAV = [];  // 活跃访客
        this.arrActiveStores = [];  // 活跃商家
        this.arrNewUsers = [];  // 新注册用户
        this.arrNewStores = [];  // 新入驻商家
        this.arrPageVisit = [];  // 页面访问量

        this.iTotalVisit = 0;  // 总访问量
        this.iStartVisit = 0; // 启动页访问量
        this.iHomeVisit = 0;  // 首页访问量
        this.iCategoryVisit = 0;  // 分类页访问量
        this.iSearchVisit = 0;  // 搜索页访问量
        this.iLocationVisit = 0;  // 定位页访问量
        this.iStoreVisit = 0;  // 商家页访问量
        this.iItemVisit = 0;  // 商品页访问量
        this.iUserCenterVisit = 0;  // 用户中心访问量
        this.iLoginVisit = 0;  // 登录页访问量
        this.iRegisterVisit = 0;  // 注册页访问量

        this.iBudgetCartVisit = 0;  // 打开购物车次数
        this.iOrderDetailVisit = 0;  // 查看订单详情次数
        this.iPlaceOrderNum = 0;  // 下单量
        this.iPlaceOrderSucceedNum = 0;  // 下单成功量

        this.iTicketPublish = 0;
        this.iTicketTotalSold = 0;
        this.iTicketTotalUse = 0;
        this.iTicketDailySold = 0;
        this.iTicketDailyUse = 0;

        // 商家后台统计数据
        this.arrStoreDailyVisitData = [];  // 商家访问数据
        this.iStoreVisitorNum = 0;  // 某个商家来过的访客数量
        this.iStoreDailyTotalVisit = 0;  // 某个商家被访问次数
        this.iStoreStarredTime = 0;  // 商家被收藏/关注次数
        this.iItemAddedTime = 0;  // 商品被添加到购物车
        this.iTodayOrder = 0;  // 当日单量

        this.iAllTruckingOrder = 0;
        this.iAllFTLOrder = 0;
        this.iAllLTLOrder = 0;
        this.iAllCompleteOrder = 0;
        this.iTruckingOrder = 0;
        this.iFTLOrder = 0;
        this.iLTLOrder = 0;
        this.iCompleteOrder = 0;

        this.iTodayExpense = 0;  // 当日支出
        this.fMonthExpense = 0;  // 支出
        this.fTotalExpense = 0;  // 总支出
        this.iTodayRevenue = 0;  // 当日收入
        this.fMonthRevenue = 0;  // 收入
        this.fTotalRevenue = 0;  // 总收入
        this.iTodayBenefit = 0;  // 当日利润
        this.fMonthBenefit = 0;  // 利润
        this.iTotalBenefit = 0;  // 总利润

        this.iMonthGST = 0; // GST
        this.iTotalGST = 0; // 总GST

        this.iHotScore = 0;

        this.arrTeamOrderMonth = [0, 0, 0, 0, 0, 0];  // 每组单量
        // this.arrTeamOrderTotal = [];  // 每组总单量

        this.arrTeamBenefitMonth = [];  // 每组利润
        // this.arrTeamBenefitTotal = [];  // 每组总利润

        this.dateEnd = new Date();
        this.dateEnd = moment(this.dateEnd).format('YYYY/MM/DD');
        // console.log(this.dateEnd, this.dateEnd.toString());
        this.dateStart = new Date(this.dateEnd.toString().split('/')[0] + "/" + this.dateEnd.toString().split('/')[1] + "/01");
        this.dateStart = moment(this.dateStart).format('YYYY/MM/DD');

        this.dateStart = this.dateStart.toString();
        this.dateEnd = this.dateEnd.toString();

        this.iChargeUserID = -1;
        this.iChargeUserTeam = -1;
        this.strUserName = "";
    }

    // 定制商家数据获取
    GetTruckingOrderData = () => {
        console.log("GetTruckingOrderData: ", this.dateCurrent, this.dateStart, this.dateEnd);
        message.loading({ content: '数据加载中……', key: g_strMessageKey });
        axios('/GetTruckingStatisticData', {
            params: {
                date: this.dateCurrent,
                store_id: this.props.iStoreID,
                start_date: this.dateStart,
                end_date: this.dateEnd
            }
        }).then(({ data }) => {

            console.log("GetLogisticData : ", data);

            // 单量
            this.iTruckingOrder = data.orders.length;
            this.iFTLOrder = data.ftl_orders.length;
            this.iLTLOrder = data.ltl_orders.length;
            this.iCompleteOrder = data.complete_orders.length;
            this.iAllTruckingOrder = data.total_orders.length;
            this.iAllFTLOrder = data.total_ftl_orders.length;
            this.iAllLTLOrder = data.total_ltl_orders.length;
            this.iAllCompleteOrder = data.total_complete_orders.length;

            // 金额
            this.fMonthExpense = 0.0;
            this.fMonthRevenue = 0.0;
            this.fMonthBenefit = 0.0;
            this.fTotalExpense = 0.0;
            this.fTotalRevenue = 0.0;
            this.fTotalBenefit = 0.0;
            for (let i = 0; i < data.orders.length; i++) {
                // fMonthExpense += data.orders[i].calculate_fee;
                // fMonthExpense += data.orders[i].out_storage_fee;
                // fMonthExpense += data.orders[i].wait_fee;
                // fMonthExpense += data.orders[i].other_fee;
                // fMonthExpense += data.orders[i].calculate_fee;
                // fMonthExpense += data.orders[i].calculate_fee;
                let fCurrency = 1.00;
                if (data.orders[i].currency === 1)
                    fCurrency = 1.25;
                this.fMonthExpense += parseFloat(data.orders[i].total_cost) * fCurrency;
                this.fMonthRevenue += parseFloat(data.orders[i].total_fee) * fCurrency;
                this.fMonthBenefit = this.fMonthRevenue - this.fMonthExpense;
            }
            for (let i = 0; i < data.total_orders.length; i++) {
                // fMonthExpense += data.orders[i].calculate_fee;
                // fMonthExpense += data.orders[i].out_storage_fee;
                // fMonthExpense += data.orders[i].wait_fee;
                // fMonthExpense += data.orders[i].other_fee;
                // fMonthExpense += data.orders[i].calculate_fee;
                // fMonthExpense += data.orders[i].calculate_fee;
                let fCurrency = 1.00;
                if (data.total_orders[i].currency === 1)
                    fCurrency = 1.25;
                this.fTotalExpense += parseFloat(data.total_orders[i].total_cost) * fCurrency;
                this.fTotalRevenue += parseFloat(data.total_orders[i].total_fee) * fCurrency;
                this.fTotalBenefit = this.fTotalRevenue - this.fTotalExpense;
            }

            message.success({ content: "数据加载完成！", key: g_strMessageKey, duration: 2 });
            this.setState({
                ...this.state,
                bLoading: false
            });
        });
    }
    // 收益统计
    CalculateBenefit = (objOrder, arrHBL) => {
        if (objOrder.type < 10) {
            // 发船
            if (objOrder.agent_fee === null || objOrder.agent_fee === undefined || objOrder.agent_fee === "" || isNaN(objOrder.agent_fee))
                objOrder.agent_fee = 0.0;
            if (objOrder.agent_cost === null || objOrder.agent_cost === undefined || objOrder.agent_cost === "" || isNaN(objOrder.agent_cost))
                objOrder.agent_cost = 0.0;
            if (objOrder.ship_fee === null || objOrder.ship_fee === undefined || objOrder.ship_fee === "" || isNaN(objOrder.ship_fee))
                objOrder.ship_fee = 0.0;
            if (objOrder.ship_cost === null || objOrder.ship_cost === undefined || objOrder.ship_cost === "" || isNaN(objOrder.ship_cost))
                objOrder.ship_cost = 0.0;
            if (objOrder.port_fee === null || objOrder.port_fee === undefined || objOrder.port_fee === "" || isNaN(objOrder.port_fee))
                objOrder.port_fee = 0.0;
            if (objOrder.port_cost === null || objOrder.port_cost === undefined || objOrder.port_cost === "" || isNaN(objOrder.port_cost))
                objOrder.port_cost = 0.0;
            if (objOrder.ship_other_fee === null || objOrder.ship_other_fee === undefined || objOrder.ship_other_fee === "" || isNaN(objOrder.ship_other_fee))
                objOrder.ship_other_fee = 0.0;
            if (objOrder.ship_other_cost === null || objOrder.ship_other_cost === undefined || objOrder.ship_other_cost === "" || isNaN(objOrder.ship_other_cost))
                objOrder.ship_other_cost = 0.0;
            objOrder.agent_benefit = objOrder.agent_fee - objOrder.agent_cost;
            objOrder.ship_benefit = objOrder.ship_fee - objOrder.ship_cost;
            objOrder.port_benefit = objOrder.port_fee - objOrder.port_cost;
            objOrder.ship_other_benefit = objOrder.ship_other_fee - objOrder.ship_other_cost;
            objOrder.ship_total_fee = parseFloat(objOrder.agent_fee) + parseFloat(objOrder.ship_fee) + parseFloat(objOrder.port_fee) + parseFloat(objOrder.ship_other_fee);
            objOrder.ship_total_cost = parseFloat(objOrder.agent_cost) + parseFloat(objOrder.ship_cost) + parseFloat(objOrder.port_cost) + parseFloat(objOrder.ship_other_cost);
            objOrder.ship_total_benefit = objOrder.ship_total_fee - objOrder.ship_total_cost;
            // 到港
            if (objOrder.truck_fee === null || objOrder.truck_fee === undefined || objOrder.truck_fee === "" || isNaN(objOrder.truck_fee))
                objOrder.truck_fee = 0.0;
            if (objOrder.truck_cost === null || objOrder.truck_cost === undefined || objOrder.truck_cost === "" || isNaN(objOrder.truck_cost))
                objOrder.truck_cost = 0.0;
            if (objOrder.prepick_fee === null || objOrder.prepick_fee === undefined || objOrder.prepick_fee === "" || isNaN(objOrder.prepick_fee))
                objOrder.prepick_fee = 0.0;
            if (objOrder.prepick_cost === null || objOrder.prepick_cost === undefined || objOrder.prepick_cost === "" || isNaN(objOrder.prepick_cost))
                objOrder.prepick_cost = 0.0;
            if (objOrder.car_frame_fee === null || objOrder.car_frame_fee === undefined || objOrder.car_frame_fee === "" || isNaN(objOrder.car_frame_fee))
                objOrder.car_frame_fee = 0.0;
            if (objOrder.car_frame_cost === null || objOrder.car_frame_cost === undefined || objOrder.car_frame_cost === "" || isNaN(objOrder.car_frame_cost))
                objOrder.car_frame_cost = 0.0;
            if (objOrder.stack_fee === null || objOrder.stack_fee === undefined || objOrder.stack_fee === "" || isNaN(objOrder.stack_fee))
                objOrder.stack_fee = 0.0;
            if (objOrder.stack_cost === null || objOrder.stack_cost === undefined || objOrder.stack_cost === "" || isNaN(objOrder.stack_cost))
                objOrder.stack_cost = 0.0;
            if (objOrder.danger_fee === null || objOrder.danger_fee === undefined || objOrder.danger_fee === "" || isNaN(objOrder.danger_fee))
                objOrder.danger_fee = 0.0;
            if (objOrder.danger_cost === null || objOrder.danger_cost === undefined || objOrder.danger_cost === "" || isNaN(objOrder.danger_cost))
                objOrder.danger_cost = 0.0;
            if (objOrder.null_fee === null || objOrder.null_fee === undefined || objOrder.null_fee === "" || isNaN(objOrder.null_fee))
                objOrder.null_fee = 0.0;
            if (objOrder.null_cost === null || objOrder.null_cost === undefined || objOrder.null_cost === "" || isNaN(objOrder.null_cost))
                objOrder.null_cost = 0.0;
            if (objOrder.port_other_fee === null || objOrder.port_other_fee === undefined || objOrder.port_other_fee === "" || isNaN(objOrder.port_other_fee))
                objOrder.port_other_fee = 0.0;
            if (objOrder.port_other_cost === null || objOrder.port_other_cost === undefined || objOrder.port_other_cost === "" || isNaN(objOrder.port_other_cost))
                objOrder.port_other_cost = 0.0;
            objOrder.truck_benefit = objOrder.truck_fee - objOrder.truck_cost;
            objOrder.prepick_benefit = objOrder.prepick_fee - objOrder.prepick_cost;
            objOrder.car_frame_benefit = objOrder.car_frame_fee - objOrder.car_frame_cost;
            objOrder.stack_benefit = objOrder.stack_fee - objOrder.stack_cost;
            objOrder.danger_benefit = objOrder.danger_fee - objOrder.danger_cost;
            objOrder.null_benefit = objOrder.null_fee - objOrder.null_cost;
            objOrder.port_other_benefit = objOrder.port_other_fee - objOrder.port_other_cost;
            objOrder.port_total_fee = parseFloat(objOrder.truck_fee) + parseFloat(objOrder.prepick_fee) + parseFloat(objOrder.car_frame_fee) + parseFloat(objOrder.stack_fee) + parseFloat(objOrder.danger_fee) + parseFloat(objOrder.null_fee) + parseFloat(objOrder.port_other_fee);
            objOrder.port_total_cost = parseFloat(objOrder.truck_cost) + parseFloat(objOrder.prepick_cost) + parseFloat(objOrder.car_frame_cost) +
                parseFloat(objOrder.stack_cost) + parseFloat(objOrder.danger_cost) + parseFloat(objOrder.null_cost) + parseFloat(objOrder.port_other_cost);
            objOrder.port_total_benefit = objOrder.port_total_fee - objOrder.port_total_cost;
            // 仓库
            if (objOrder.open_closet_fee === null || objOrder.open_closet_fee === undefined || objOrder.open_closet_fee === "" || isNaN(objOrder.open_closet_fee))
                objOrder.open_closet_fee = 0.0;
            if (objOrder.open_closet_cost === null || objOrder.open_closet_cost === undefined || objOrder.open_closet_cost === "" || isNaN(objOrder.open_closet_cost))
                objOrder.open_closet_cost = 0.0;
            if (objOrder.divide_item_fee === null || objOrder.divide_item_fee === undefined || objOrder.divide_item_fee === "" || isNaN(objOrder.divide_item_fee))
                objOrder.divide_item_fee = 0.0;
            if (objOrder.divide_item_cost === null || objOrder.divide_item_cost === undefined || objOrder.divide_item_cost === "" || isNaN(objOrder.divide_item_cost))
                objOrder.divide_item_cost = 0.0;
            objOrder.open_closet_benefit = objOrder.open_closet_fee - objOrder.open_closet_cost;
            objOrder.divide_item_benefit = objOrder.divide_item_fee - objOrder.divide_item_cost;
            objOrder.storage_total_fee = parseFloat(objOrder.open_closet_fee) + parseFloat(objOrder.divide_item_fee);
            objOrder.storage_total_cost = parseFloat(objOrder.open_closet_cost) + parseFloat(objOrder.divide_item_cost);
            objOrder.storage_total_benefit = objOrder.storage_total_fee - objOrder.storage_total_cost;
            //清关
            if (objOrder.custom_fee === null || objOrder.custom_fee === undefined || objOrder.custom_fee === "" || isNaN(objOrder.custom_fee))
                objOrder.custom_fee = 0.0;
            if (objOrder.custom_cost === null || objOrder.custom_cost === undefined || objOrder.custom_cost === "" || isNaN(objOrder.custom_cost))
                objOrder.custom_cost = 0.0;
            if (objOrder.tax_fee === null || objOrder.tax_fee === undefined || objOrder.tax_fee === "" || isNaN(objOrder.tax_fee))
                objOrder.tax_fee = 0.0;
            if (objOrder.tax_cost === null || objOrder.tax_cost === undefined || objOrder.tax_cost === "" || isNaN(objOrder.tax_cost))
                objOrder.tax_cost = 0.0;
            if (objOrder.gst_fee === null || objOrder.gst_fee === undefined || objOrder.gst_fee === "" || isNaN(objOrder.gst_fee))
                objOrder.gst_fee = 0.0;
            if (objOrder.gst_cost === null || objOrder.gst_cost === undefined || objOrder.gst_cost === "" || isNaN(objOrder.gst_cost))
                objOrder.gst_cost = 0.0;
            objOrder.custom_benefit = objOrder.custom_fee - objOrder.custom_cost;
            objOrder.tax_benefit = objOrder.tax_fee - objOrder.tax_cost;
            objOrder.gst_benefit = objOrder.gst_fee - objOrder.gst_cost;
            let fCustomOtherFee = 0.0; let fCustomOtherCost = 0.0;
            if (objOrder.overitem_active === 1) {
                if (objOrder.overitem_fee === null || objOrder.overitem_fee === undefined || objOrder.overitem_fee === "" || isNaN(objOrder.overitem_fee))
                    objOrder.overitem_fee = 0.0;
                if (objOrder.overitem_cost === null || objOrder.overitem_cost === undefined || objOrder.overitem_cost === "" || isNaN(objOrder.overitem_cost))
                    objOrder.overitem_cost = 0.0;
                objOrder.overitem_benefit = objOrder.overitem_fee - objOrder.overitem_cost;
                fCustomOtherFee += parseFloat(objOrder.overitem_fee);
                fCustomOtherCost += parseFloat(objOrder.overitem_cost);
            }
            if (objOrder.cfia_active === 1) {
                if (objOrder.cfia_fee === null || objOrder.cfia_fee === undefined || objOrder.cfia_fee === "" || isNaN(objOrder.cfia_fee))
                    objOrder.cfia_fee = 0.0;
                if (objOrder.cfia_cost === null || objOrder.cfia_cost === undefined || objOrder.cfia_cost === "" || isNaN(objOrder.cfia_cost))
                    objOrder.cfia_cost = 0.0;
                objOrder.cfia_benefit = objOrder.cfia_fee - objOrder.cfia_cost;
                fCustomOtherFee += parseFloat(objOrder.cfia_fee);
                fCustomOtherCost += parseFloat(objOrder.cfia_cost);
            }
            if (objOrder.check_active === 1) {
                if (objOrder.check_fee === null || objOrder.check_fee === undefined || objOrder.check_fee === "" || isNaN(objOrder.check_fee))
                    objOrder.check_fee = 0.0;
                if (objOrder.check_cost === null || objOrder.check_cost === undefined || objOrder.check_cost === "" || isNaN(objOrder.check_cost))
                    objOrder.check_cost = 0.0;
                objOrder.check_benefit = objOrder.check_fee - objOrder.check_cost;
                fCustomOtherFee += parseFloat(objOrder.check_fee);
                fCustomOtherCost += parseFloat(objOrder.check_cost);
            }
            if (objOrder.custom_other_active === 1) {
                if (objOrder.custom_other_fee === null || objOrder.custom_other_fee === undefined || objOrder.custom_other_fee === "" || isNaN(objOrder.custom_other_fee))
                    objOrder.custom_other_fee = 0.0;
                if (objOrder.custom_other_cost === null || objOrder.custom_other_cost === undefined || objOrder.custom_other_cost === "" || isNaN(objOrder.custom_other_cost))
                    objOrder.custom_other_cost = 0.0;
                objOrder.custom_other_benefit = objOrder.custom_other_fee - objOrder.custom_other_cost;
                fCustomOtherFee += parseFloat(objOrder.custom_other_fee);
                fCustomOtherCost += parseFloat(objOrder.custom_other_cost);
            }
            objOrder.custom_total_fee = parseFloat(objOrder.custom_fee) + parseFloat(objOrder.tax_fee) + parseFloat(objOrder.gst_fee);
            objOrder.custom_total_cost = parseFloat(objOrder.custom_cost) + parseFloat(objOrder.tax_cost) + parseFloat(objOrder.gst_cost);
            if (fCustomOtherFee > 0.0)
                objOrder.custom_total_fee += fCustomOtherFee;
            if (fCustomOtherCost > 0.0)
                objOrder.custom_total_cost += fCustomOtherCost;
            // HB费用
            let fStorageHBFee = 0.0; let fStorageHBCost = 0.0; let fStorageHBBenefit = 0.0;
            let fCustomHBFee = 0.0; let fCustomHBCost = 0.0; let fCustomHBBenefit = 0.0;
            let fTransitHBFee = 0.0; let fTransitHBCost = 0.0; let fTransitHBBenefit = 0.0;
            let fDeliverHBFee = 0.0; let fDeliverHBCost = 0.0; let fDeliverHBBenefit = 0.0;
            let fPickupHBFee = 0.0; let fPickupHBCost = 0.0; let fPickupHBBenefit = 0.0;
            for (let i = 0; i < arrHBL.length; i++) {
                // 仓库
                if (arrHBL[i].on_plate_active === 1) {
                    if (arrHBL[i].on_plate_fee === null || arrHBL[i].on_plate_fee === undefined || arrHBL[i].on_plate_fee === "" || isNaN(objOrder.on_plate_fee))
                        arrHBL[i].on_plate_fee = 0.0;
                    if (arrHBL[i].on_plate_cost === null || arrHBL[i].on_plate_cost === undefined || arrHBL[i].on_plate_cost === "" || isNaN(objOrder.on_plate_cost))
                        arrHBL[i].on_plate_cost = 0.0;
                    fStorageHBFee += parseFloat(arrHBL[i].on_plate_fee);
                    fStorageHBCost += parseFloat(arrHBL[i].on_plate_cost);
                }
                if (arrHBL[i].tag_active === 1) {
                    if (arrHBL[i].tag_fee === null || arrHBL[i].tag_fee === undefined || arrHBL[i].tag_fee === "" || isNaN(objOrder.tag_fee))
                        arrHBL[i].tag_fee = 0.0;
                    if (arrHBL[i].tag_cost === null || arrHBL[i].tag_cost === undefined || arrHBL[i].tag_cost === "" || isNaN(objOrder.tag_cost))
                        arrHBL[i].tag_cost = 0.0;
                    fStorageHBFee += parseFloat(arrHBL[i].tag_fee);
                    fStorageHBCost += parseFloat(arrHBL[i].tag_cost);
                }
                if (arrHBL[i].oversize_active === 1) {
                    if (arrHBL[i].oversize_fee === null || arrHBL[i].oversize_fee === undefined || arrHBL[i].oversize_fee === "" || isNaN(objOrder.oversize_fee))
                        arrHBL[i].oversize_fee = 0.0;
                    if (arrHBL[i].oversize_cost === null || arrHBL[i].oversize_cost === undefined || arrHBL[i].oversize_cost === "" || isNaN(objOrder.oversize_cost))
                        arrHBL[i].oversize_cost = 0.0;
                    fStorageHBFee += parseFloat(arrHBL[i].oversize_fee);
                    fStorageHBCost += parseFloat(arrHBL[i].oversize_cost);
                }
                if (arrHBL[i].storage_export_active === 1) {
                    if (arrHBL[i].storage_export_fee === null || arrHBL[i].storage_export_fee === undefined || arrHBL[i].storage_export_fee === "" ||
                        isNaN(objOrder.storage_export_fee))
                        arrHBL[i].storage_export_fee = 0.0;
                    if (arrHBL[i].storage_export_cost === null || arrHBL[i].storage_export_cost === undefined || arrHBL[i].storage_export_cost === "" ||
                        isNaN(objOrder.storage_export_cost))
                        arrHBL[i].storage_export_cost = 0.0;
                    fStorageHBFee += parseFloat(arrHBL[i].storage_export_fee);
                    fStorageHBCost += parseFloat(arrHBL[i].storage_export_cost);
                }
                if (arrHBL[i].storage_active === 1) {
                    if (arrHBL[i].storage_fee === null || arrHBL[i].storage_fee === undefined || arrHBL[i].storage_fee === "" || isNaN(objOrder.storage_export_fee))
                        arrHBL[i].storage_fee = 0.0;
                    if (arrHBL[i].storage_cost === null || arrHBL[i].storage_cost === undefined || arrHBL[i].storage_cost === "" || isNaN(objOrder.storage_export_fee))
                        arrHBL[i].storage_cost = 0.0;
                    fStorageHBFee += parseFloat(arrHBL[i].storage_fee);
                    fStorageHBCost += parseFloat(arrHBL[i].storage_cost);
                }
                if (arrHBL[i].storage_other_active === 1) {
                    if (arrHBL[i].storage_other_fee === null || arrHBL[i].storage_other_fee === undefined || arrHBL[i].storage_other_fee === "" ||
                        isNaN(objOrder.storage_other_fee))
                        arrHBL[i].storage_other_fee = 0.0;
                    if (arrHBL[i].storage_other_cost === null || arrHBL[i].storage_other_cost === undefined || arrHBL[i].storage_other_cost === "" ||
                        isNaN(objOrder.storage_other_cost))
                        arrHBL[i].storage_other_cost = 0.0;
                    fStorageHBFee += parseFloat(arrHBL[i].storage_other_fee);
                    fStorageHBCost += parseFloat(arrHBL[i].storage_other_cost);
                }
                // 清关
                if (arrHBL[i].custom_fee === null || arrHBL[i].custom_fee === undefined || arrHBL[i].custom_fee === "" || isNaN(objOrder.custom_fee))
                    arrHBL[i].custom_fee = 0.0;
                if (arrHBL[i].custom_cost === null || arrHBL[i].custom_cost === undefined || arrHBL[i].custom_cost === "" || isNaN(objOrder.custom_cost))
                    arrHBL[i].custom_cost = 0.0;
                if (arrHBL[i].tax_fee === null || arrHBL[i].tax_fee === undefined || arrHBL[i].tax_fee === "" || isNaN(objOrder.tax_fee))
                    arrHBL[i].tax_fee = 0.0;
                if (arrHBL[i].tax_cost === null || arrHBL[i].tax_cost === undefined || arrHBL[i].tax_cost === "" || isNaN(objOrder.tax_cost))
                    arrHBL[i].tax_cost = 0.0;
                if (arrHBL[i].gst_fee === null || arrHBL[i].gst_fee === undefined || arrHBL[i].gst_fee === "" || isNaN(objOrder.gst_fee))
                    arrHBL[i].gst_fee = 0.0;
                if (arrHBL[i].gst_cost === null || arrHBL[i].gst_cost === undefined || arrHBL[i].gst_cost === "" || isNaN(objOrder.gst_cost))
                    arrHBL[i].gst_cost = 0.0;
                fCustomHBFee += parseFloat(arrHBL[i].custom_fee);
                fCustomHBCost += parseFloat(arrHBL[i].custom_cost);
                fCustomHBFee += parseFloat(arrHBL[i].tax_fee);
                fCustomHBCost += parseFloat(arrHBL[i].tax_cost);
                fCustomHBFee += parseFloat(arrHBL[i].gst_fee);
                fCustomHBCost += parseFloat(arrHBL[i].gst_cost);
                if (arrHBL[i].overitem_active === 1) {
                    if (arrHBL[i].overitem_fee === null || arrHBL[i].overitem_fee === undefined || arrHBL[i].overitem_fee === "" || isNaN(objOrder.overitem_fee))
                        arrHBL[i].overitem_fee = 0.0;
                    if (arrHBL[i].overitem_cost === null || arrHBL[i].overitem_cost === undefined || arrHBL[i].overitem_cost === "" || isNaN(objOrder.overitem_cost))
                        arrHBL[i].overitem_cost = 0.0;
                    fCustomHBFee += parseFloat(arrHBL[i].overitem_fee);
                    fCustomHBCost += parseFloat(arrHBL[i].overitem_cost);
                }
                if (arrHBL[i].cfia_active === 1) {
                    if (arrHBL[i].cfia_fee === null || arrHBL[i].cfia_fee === undefined || arrHBL[i].cfia_fee === "" || isNaN(objOrder.cfia_fee))
                        arrHBL[i].cfia_fee = 0.0;
                    if (arrHBL[i].cfia_cost === null || arrHBL[i].cfia_cost === undefined || arrHBL[i].cfia_cost === "" || isNaN(objOrder.cfia_cost))
                        arrHBL[i].cfia_cost = 0.0;
                    fCustomHBFee += parseFloat(arrHBL[i].cfia_fee);
                    fCustomHBCost += parseFloat(arrHBL[i].cfia_cost);
                }
                if (arrHBL[i].check_active === 1) {
                    if (arrHBL[i].check_fee === null || arrHBL[i].check_fee === undefined || arrHBL[i].check_fee === "" || isNaN(objOrder.check_fee))
                        arrHBL[i].check_fee = 0.0;
                    if (arrHBL[i].check_cost === null || arrHBL[i].check_cost === undefined || arrHBL[i].check_cost === "" || isNaN(objOrder.check_cost))
                        arrHBL[i].check_cost = 0.0;
                    fCustomHBFee += parseFloat(arrHBL[i].check_fee);
                    fCustomHBCost += parseFloat(arrHBL[i].check_cost);
                }
                if (arrHBL[i].hb_other_active === 1) {
                    if (arrHBL[i].storage_fee === null || arrHBL[i].storage_fee === undefined || arrHBL[i].storage_fee === "" || isNaN(objOrder.storage_fee))
                        arrHBL[i].storage_fee = 0.0;
                    if (arrHBL[i].storage_cost === null || arrHBL[i].storage_cost === undefined || arrHBL[i].storage_cost === "" || isNaN(objOrder.storage_cost))
                        arrHBL[i].storage_cost = 0.0;
                    fCustomHBFee += parseFloat(arrHBL[i].hb_other_fee);
                    fCustomHBCost += parseFloat(arrHBL[i].hb_other_cost);
                }
                if (arrHBL[i].transit_fee === null || arrHBL[i].transit_fee === undefined || arrHBL[i].transit_fee === "" || isNaN(objOrder.transit_fee))
                    arrHBL[i].transit_fee = 0.0;
                if (arrHBL[i].transit_cost === null || arrHBL[i].transit_cost === undefined || arrHBL[i].transit_cost === "" || isNaN(objOrder.transit_cost))
                    arrHBL[i].transit_cost = 0.0;
                if (arrHBL[i].deliver_fee === null || arrHBL[i].deliver_fee === undefined || arrHBL[i].deliver_fee === "" || isNaN(objOrder.deliver_fee))
                    arrHBL[i].deliver_fee = 0.0;
                if (arrHBL[i].deliver_cost === null || arrHBL[i].deliver_cost === undefined || arrHBL[i].deliver_cost === "" || isNaN(objOrder.deliver_cost))
                    arrHBL[i].deliver_cost = 0.0;
                if (arrHBL[i].storage_export45_fee === null || arrHBL[i].storage_export45_fee === undefined ||
                    arrHBL[i].storage_export45_fee === "" || isNaN(objOrder.storage_export45_fee))
                    arrHBL[i].storage_export45_fee = 0.0;
                if (arrHBL[i].storage_export45_cost === null || arrHBL[i].storage_export45_cost === undefined ||
                    arrHBL[i].storage_export45_cost === "" || isNaN(objOrder.storage_export45_cost))
                    arrHBL[i].storage_export45_cost = 0.0;
                // 转运
                if (arrHBL[i].path === 0) {
                    fTransitHBFee += parseFloat(arrHBL[i].transit_fee);
                    fTransitHBCost += parseFloat(arrHBL[i].transit_cost);
                    fTransitHBFee += parseFloat(arrHBL[i].deliver_fee);
                    fTransitHBCost += parseFloat(arrHBL[i].deliver_cost);
                    fTransitHBFee += parseFloat(arrHBL[i].storage_export45_fee);
                    fTransitHBCost += parseFloat(arrHBL[i].storage_export45_cost);
                    if (arrHBL[i].on_plate45_active === 1) {
                        if (arrHBL[i].on_plate45_fee === null || arrHBL[i].on_plate45_fee === undefined || arrHBL[i].on_plate45_fee === "" || isNaN(objOrder.on_plate45_fee))
                            arrHBL[i].on_plate45_fee = 0.0;
                        if (arrHBL[i].on_plate45_cost === null || arrHBL[i].on_plate45_cost === undefined || arrHBL[i].on_plate45_cost === "" || isNaN(objOrder.on_plate45_cost))
                            arrHBL[i].on_plate45_cost = 0.0;
                        fTransitHBFee += parseFloat(arrHBL[i].on_plate45_fee);
                        fTransitHBCost += parseFloat(arrHBL[i].on_plate45_cost);
                    }
                    if (arrHBL[i].waiting_active === 1) {
                        if (arrHBL[i].waiting_fee === null || arrHBL[i].waiting_fee === undefined || arrHBL[i].waiting_fee === "" || isNaN(objOrder.waiting_fee))
                            arrHBL[i].waiting_fee = 0.0;
                        if (arrHBL[i].waiting_cost === null || arrHBL[i].waiting_cost === undefined || arrHBL[i].waiting_cost === "" || isNaN(objOrder.waiting_cost))
                            arrHBL[i].waiting_cost = 0.0;
                        fTransitHBFee += parseFloat(arrHBL[i].waiting_fee);
                        fTransitHBCost += parseFloat(arrHBL[i].waiting_cost);
                    }
                    if (arrHBL[i].hb_other45_active === 1) {
                        if (arrHBL[i].hb_other45_fee === null || arrHBL[i].hb_other45_fee === undefined || arrHBL[i].hb_other45_fee === "" || isNaN(objOrder.hb_other45_fee))
                            arrHBL[i].hb_other45_fee = 0.0;
                        if (arrHBL[i].hb_other45_cost === null || arrHBL[i].hb_other45_cost === undefined || arrHBL[i].hb_other45_cost === "" || isNaN(objOrder.hb_other45_cost))
                            arrHBL[i].hb_other45_cost = 0.0;
                        fTransitHBFee += parseFloat(arrHBL[i].hb_other45_fee);
                        fTransitHBCost += parseFloat(arrHBL[i].hb_other45_cost);
                    }
                }
                // 派送
                if (arrHBL[i].path === 1) {
                    fDeliverHBFee += parseFloat(arrHBL[i].deliver_fee);
                    fDeliverHBCost += parseFloat(arrHBL[i].deliver_cost);
                    fDeliverHBFee += parseFloat(arrHBL[i].storage_export45_fee);
                    fDeliverHBCost += parseFloat(arrHBL[i].storage_export45_cost);
                    if (arrHBL[i].on_plate45_active === 1) {
                        if (arrHBL[i].on_plate45_fee === null || arrHBL[i].on_plate45_fee === undefined || arrHBL[i].on_plate45_fee === "" || isNaN(objOrder.on_plate45_fee))
                            arrHBL[i].on_plate45_fee = 0.0;
                        if (arrHBL[i].on_plate45_cost === null || arrHBL[i].on_plate45_cost === undefined || arrHBL[i].on_plate45_cost === "" || isNaN(objOrder.on_plate45_cost))
                            arrHBL[i].on_plate45_cost = 0.0;
                        fTransitHBFee += parseFloat(arrHBL[i].on_plate45_fee);
                        fTransitHBCost += parseFloat(arrHBL[i].on_plate45_cost);
                    }
                    if (arrHBL[i].waiting_active === 1) {
                        if (arrHBL[i].waiting_fee === null || arrHBL[i].waiting_fee === undefined || arrHBL[i].waiting_fee === "" || isNaN(objOrder.waiting_fee))
                            arrHBL[i].waiting_fee = 0.0;
                        if (arrHBL[i].waiting_cost === null || arrHBL[i].waiting_cost === undefined || arrHBL[i].waiting_cost === "" || isNaN(objOrder.waiting_cost))
                            arrHBL[i].waiting_cost = 0.0;
                        fTransitHBFee += parseFloat(arrHBL[i].waiting_fee);
                        fTransitHBCost += parseFloat(arrHBL[i].waiting_cost);
                    }
                    if (arrHBL[i].hb_other45_active === 1) {
                        if (arrHBL[i].hb_other45_fee === null || arrHBL[i].hb_other45_fee === undefined || arrHBL[i].hb_other45_fee === "" || isNaN(objOrder.hb_other45_fee))
                            arrHBL[i].hb_other45_fee = 0.0;
                        if (arrHBL[i].hb_other45_cost === null || arrHBL[i].hb_other45_cost === undefined || arrHBL[i].hb_other45_cost === "" || isNaN(objOrder.hb_other45_cost))
                            arrHBL[i].hb_other45_cost = 0.0;
                        fTransitHBFee += parseFloat(arrHBL[i].hb_other45_fee);
                        fTransitHBCost += parseFloat(arrHBL[i].hb_other45_cost);
                    }
                }
                // 自提
                if (arrHBL[i].path === 2) {
                    fPickupHBFee += parseFloat(arrHBL[i].storage_export45_fee);
                    fPickupHBCost += parseFloat(arrHBL[i].storage_export45_cost);
                    if (arrHBL[i].on_plate45_active === 1) {
                        if (arrHBL[i].on_plate45_fee === null || arrHBL[i].on_plate45_fee === undefined || arrHBL[i].on_plate45_fee === "" || isNaN(objOrder.on_plate45_fee))
                            arrHBL[i].on_plate45_fee = 0.0;
                        if (arrHBL[i].on_plate45_cost === null || arrHBL[i].on_plate45_cost === undefined || arrHBL[i].on_plate45_cost === "" || isNaN(objOrder.on_plate45_cost))
                            arrHBL[i].on_plate45_cost = 0.0;
                        fTransitHBFee += parseFloat(arrHBL[i].on_plate45_fee);
                        fTransitHBCost += parseFloat(arrHBL[i].on_plate45_cost);
                    }
                    if (arrHBL[i].waiting_active === 1) {
                        if (arrHBL[i].waiting_fee === null || arrHBL[i].waiting_fee === undefined || arrHBL[i].waiting_fee === "" || isNaN(objOrder.waiting_fee))
                            arrHBL[i].waiting_fee = 0.0;
                        if (arrHBL[i].waiting_cost === null || arrHBL[i].waiting_cost === undefined || arrHBL[i].waiting_cost === "" || isNaN(objOrder.waiting_cost))
                            arrHBL[i].waiting_cost = 0.0;
                        fTransitHBFee += parseFloat(arrHBL[i].waiting_fee);
                        fTransitHBCost += parseFloat(arrHBL[i].waiting_cost);
                    }
                    if (arrHBL[i].hb_other45_active === 1) {
                        if (arrHBL[i].hb_other45_fee === null || arrHBL[i].hb_other45_fee === undefined || arrHBL[i].hb_other45_fee === "" || isNaN(objOrder.hb_other45_fee))
                            arrHBL[i].hb_other45_fee = 0.0;
                        if (arrHBL[i].hb_other45_cost === null || arrHBL[i].hb_other45_cost === undefined || arrHBL[i].hb_other45_cost === "" || isNaN(objOrder.hb_other45_cost))
                            arrHBL[i].hb_other45_cost = 0.0;
                        fTransitHBFee += parseFloat(arrHBL[i].hb_other45_fee);
                        fTransitHBCost += parseFloat(arrHBL[i].hb_other45_cost);
                    }
                }
            }
            // 仓库
            fStorageHBBenefit = fStorageHBFee - fStorageHBCost;
            if (typeof (fStorageHBFee) === 'number')
                fStorageHBFee = fStorageHBFee.toFixed(2);
            if (typeof (fStorageHBCost) === 'number')
                fStorageHBCost = fStorageHBCost.toFixed(2);
            if (typeof (fStorageHBBenefit) === 'number')
                fStorageHBBenefit = fStorageHBBenefit.toFixed(2);
            objOrder.storage_hb_total_fee = fStorageHBFee;
            objOrder.storage_hb_total_cost = fStorageHBCost;
            objOrder.storage_hb_total_benefit = fStorageHBBenefit;
            objOrder.storage_total_fee += parseFloat(fStorageHBFee);
            objOrder.storage_total_cost += parseFloat(fStorageHBCost);
            objOrder.storage_total_benefit = objOrder.storage_total_fee - objOrder.storage_total_cost;
            if (typeof (objOrder.storage_total_fee) === 'number')
                objOrder.storage_total_fee = objOrder.storage_total_fee.toFixed(2);
            if (typeof (objOrder.storage_total_cost) === 'number')
                objOrder.storage_total_cost = objOrder.storage_total_cost.toFixed(2);
            if (typeof (objOrder.storage_total_benefit) === 'number')
                objOrder.storage_total_benefit = objOrder.storage_total_benefit.toFixed(2);
            // 清关
            fCustomHBBenefit = fCustomHBFee - fCustomHBCost;
            if (typeof (fCustomHBFee) === 'number')
                fCustomHBFee = fCustomHBFee.toFixed(2);
            if (typeof (fCustomHBCost) === 'number')
                fCustomHBCost = fCustomHBCost.toFixed(2);
            if (typeof (fCustomHBBenefit) === 'number')
                fCustomHBBenefit = fCustomHBBenefit.toFixed(2);
            objOrder.custom_hb_total_fee = fCustomHBFee;
            objOrder.custom_hb_total_cost = fCustomHBCost;
            objOrder.custom_hb_total_benefit = fCustomHBBenefit;
            objOrder.custom_total_fee = parseFloat(objOrder.custom_total_fee) + parseFloat(fCustomHBFee);
            objOrder.custom_total_cost = parseFloat(objOrder.custom_total_cost) + parseFloat(fCustomHBCost);
            objOrder.custom_total_benefit = objOrder.custom_total_fee - objOrder.custom_total_cost;
            if (typeof (objOrder.custom_total_fee) === 'number')
                objOrder.custom_total_fee = objOrder.custom_total_fee.toFixed(2);
            if (typeof (objOrder.custom_total_cost) === 'number')
                objOrder.custom_total_cost = objOrder.custom_total_cost.toFixed(2);
            if (typeof (objOrder.custom_total_benefit) === 'number')
                objOrder.custom_total_benefit = objOrder.custom_total_benefit.toFixed(2);
            // 转运
            fTransitHBBenefit = fTransitHBFee - fTransitHBCost;
            if (typeof (fTransitHBFee) === 'number')
                fTransitHBFee = fTransitHBFee.toFixed(2);
            if (typeof (fTransitHBCost) === 'number')
                fTransitHBCost = fTransitHBCost.toFixed(2);
            if (typeof (fTransitHBBenefit) === 'number')
                fTransitHBBenefit = fTransitHBBenefit.toFixed(2);
            objOrder.transit_total_fee = fTransitHBFee;
            objOrder.transit_total_cost = fTransitHBCost;
            objOrder.transit_total_benefit = fTransitHBBenefit;
            if (typeof (objOrder.transit_total_fee) === 'number')
                objOrder.transit_total_fee = objOrder.transit_total_fee.toFixed(2);
            if (typeof (objOrder.transit_total_cost) === 'number')
                objOrder.transit_total_cost = objOrder.transit_total_cost.toFixed(2);
            if (typeof (objOrder.transit_total_benefit) === 'number')
                objOrder.transit_total_benefit = objOrder.transit_total_benefit.toFixed(2);
            // 派送
            fDeliverHBBenefit = fDeliverHBFee - fDeliverHBCost;
            if (typeof (fDeliverHBFee) === 'number')
                fDeliverHBFee = fDeliverHBFee.toFixed(2);
            if (typeof (fDeliverHBCost) === 'number')
                fDeliverHBCost = fDeliverHBCost.toFixed(2);
            if (typeof (fDeliverHBBenefit) === 'number')
                fDeliverHBBenefit = fDeliverHBBenefit.toFixed(2);
            objOrder.deliver_total_fee = fDeliverHBFee;
            objOrder.deliver_total_cost = fDeliverHBCost;
            objOrder.deliver_total_benefit = fDeliverHBBenefit;
            if (typeof (objOrder.deliver_total_fee) === 'number')
                objOrder.deliver_total_fee = objOrder.deliver_total_fee.toFixed(2);
            if (typeof (objOrder.deliver_total_cost) === 'number')
                objOrder.deliver_total_cost = objOrder.deliver_total_cost.toFixed(2);
            if (typeof (objOrder.deliver_total_benefit) === 'number')
                objOrder.deliver_total_benefit = objOrder.deliver_total_benefit.toFixed(2);
            // 自提
            fPickupHBBenefit = fPickupHBFee - fPickupHBCost;
            if (typeof (fPickupHBFee) === 'number')
                fPickupHBFee = fPickupHBFee.toFixed(2);
            if (typeof (fPickupHBCost) === 'number')
                fPickupHBCost = fPickupHBCost.toFixed(2);
            if (typeof (fPickupHBBenefit) === 'number')
                fPickupHBBenefit = fPickupHBBenefit.toFixed(2);
            objOrder.pickup_total_fee = fPickupHBFee;
            objOrder.pickup_total_cost = fPickupHBCost;
            objOrder.pickup_total_benefit = fPickupHBBenefit;
            if (typeof (objOrder.pickup_total_fee) === 'number')
                objOrder.pickup_total_fee = objOrder.pickup_total_fee.toFixed(2);
            if (typeof (objOrder.pickup_total_cost) === 'number')
                objOrder.pickup_total_cost = objOrder.pickup_total_cost.toFixed(2);
            if (typeof (objOrder.pickup_total_benefit) === 'number')
                objOrder.pickup_total_benefit = objOrder.pickup_total_benefit.toFixed(2);

            // 费用总计
            objOrder.mb_total_fee = 0.0;
            objOrder.mb_total_fee += parseFloat(objOrder.ship_total_fee) + parseFloat(objOrder.port_total_fee) + parseFloat(objOrder.storage_total_fee) +
                parseFloat(objOrder.custom_total_fee) + parseFloat(objOrder.transit_total_fee) + parseFloat(objOrder.deliver_total_fee) +
                parseFloat(objOrder.pickup_total_fee);
            objOrder.mb_total_cost = 0.0;
            objOrder.mb_total_cost += parseFloat(objOrder.ship_total_cost) + parseFloat(objOrder.port_total_cost) + parseFloat(objOrder.storage_total_cost) +
                parseFloat(objOrder.custom_total_cost) + parseFloat(objOrder.transit_total_cost) + parseFloat(objOrder.deliver_total_cost) +
                parseFloat(objOrder.pickup_total_cost);
            objOrder.mb_total_benefit = objOrder.mb_total_fee - objOrder.mb_total_cost;
            if (typeof (objOrder.mb_total_fee) === 'number')
                objOrder.mb_total_fee = objOrder.mb_total_fee.toFixed(2);
            if (typeof (objOrder.mb_total_cost) === 'number')
                objOrder.mb_total_cost = objOrder.mb_total_cost.toFixed(2);
            if (typeof (objOrder.mb_total_benefit) === 'number')
                objOrder.mb_total_benefit = objOrder.mb_total_benefit.toFixed(2);
        }
        // 派送列表
        else {
            // 发船
            if (objOrder.deliver_fee === null || objOrder.deliver_fee === undefined || objOrder.deliver_fee === "" || isNaN(objOrder.deliver_fee))
                objOrder.deliver_fee = 0.0;
            if (objOrder.deliver_cost === null || objOrder.deliver_cost === undefined || objOrder.deliver_cost === "" || isNaN(objOrder.deliver_cost))
                objOrder.deliver_cost = 0.0;
            if (objOrder.storage_export_fee === null || objOrder.storage_export_fee === undefined || objOrder.storage_export_fee === "" || isNaN(objOrder.storage_export_fee))
                objOrder.storage_export_fee = 0.0;
            if (objOrder.storage_export_cost === null || objOrder.storage_export_cost === undefined || objOrder.storage_export_cost === "" || isNaN(objOrder.storage_export_cost))
                objOrder.storage_export_cost = 0.0;
            if (objOrder.on_plate_fee === null || objOrder.on_plate_fee === undefined || objOrder.on_plate_fee === "" || isNaN(objOrder.on_plate_fee))
                objOrder.on_plate_fee = 0.0;
            if (objOrder.on_plate_cost === null || objOrder.on_plate_cost === undefined || objOrder.on_plate_cost === "" || isNaN(objOrder.on_plate_cost))
                objOrder.on_plate_cost = 0.0;
            if (objOrder.waiting_fee === null || objOrder.waiting_fee === undefined || objOrder.waiting_fee === "" || isNaN(objOrder.waiting_fee))
                objOrder.waiting_fee = 0.0;
            if (objOrder.waiting_cost === null || objOrder.waiting_cost === undefined || objOrder.waiting_cost === "" || isNaN(objOrder.waiting_cost))
                objOrder.waiting_cost = 0.0;
            if (objOrder.deliver_other_fee === null || objOrder.deliver_other_fee === undefined || objOrder.deliver_other_fee === "" || isNaN(objOrder.deliver_other_fee))
                objOrder.deliver_other_fee = 0.0;
            if (objOrder.deliver_other_cost === null || objOrder.deliver_other_cost === undefined || objOrder.deliver_other_cost === "" || isNaN(objOrder.deliver_other_cost))
                objOrder.deliver_other_cost = 0.0;
            objOrder.mb_total_fee = parseFloat(objOrder.deliver_fee) + parseFloat(objOrder.storage_export_fee) + parseFloat(objOrder.on_plate_fee) + parseFloat(objOrder.waiting_fee) + parseFloat(objOrder.deliver_other_fee);
            objOrder.mb_total_cost = parseFloat(objOrder.deliver_cost) + parseFloat(objOrder.storage_export_cost) + parseFloat(objOrder.on_plate_cost) + parseFloat(objOrder.waiting_cost) + parseFloat(objOrder.deliver_other_cost);
            // objOrder.mb_total_fee = 0.0;
            // objOrder.mb_total_cost = 0.0;
            objOrder.mb_total_benefit = objOrder.mb_total_fee - objOrder.mb_total_cost;
            if (typeof (objOrder.mb_total_fee) === 'number')
                objOrder.mb_total_fee = objOrder.mb_total_fee.toFixed(2);
            if (typeof (objOrder.mb_total_cost) === 'number')
                objOrder.mb_total_cost = objOrder.mb_total_cost.toFixed(2);
            if (typeof (objOrder.mb_total_benefit) === 'number')
                objOrder.mb_total_benefit = objOrder.mb_total_benefit.toFixed(2);
        }

        return objOrder;
    }
    // 月份变化
    MonthPickerChange = (date, dateString) => {
        this.dateCurrent = dateString + "-01";
        this.GetTruckingOrderData();
    }
    // 查看图标内容变化
    ChartContentChange = e => {
        console.log('ChartContentChange : ', e.target.value);
        this.setState({
            iCharContent: e.target.value,
        });
    };
    // 选择时间端
    RangePickerChange = (dates, dateStrings) => {
        console.log("RangePickerChange : ", dates, dateStrings);
        this.dateStart = dateStrings[0]; this.dateEnd = dateStrings[1];
        this.GetTruckingOrderData();
        // this.dateStart = new Date(dateStrings[0]);
        // this.dateEnd = new Date(dateStrings[1]);
        // this.dateStart = moment(this.dateStart).format('YYYY/MM/DD');
        // this.dateEnd = moment(this.dateEnd).format('YYYY/MM/DD');
    }
    // 查询数据
    SearchData = () => {
        this.GetTruckingOrderData();
    }
    // 获取负责人数据
    GetUsersData() {
        axios('/GetUsers', { params: { store_id: this.props.iStoreID } })
            .then(({ data }) => {
                global.arrUserData = data;
                // console.log("GetUsers:", data);
            }).catch(function (error) { console.log(error); });
    }
    // 选择负责人
    SelectUser = () => {
        this.setState({
            ...this.state,
            bSelectUserDlg: true
        });
    }
    // 选择负责人弹窗结果
    UserSelectResult = (index) => {
        if (index >= 0) {
            this.refs.user.state.value = global.arrUserData[index].name;
            this.iChargeUserID = global.arrUserData[index].id;
            this.iChargeUserTeam = global.arrUserData[index].team;
            this.strUserName = global.arrUserData[index].name;
        }
        this.GetTruckingOrderData();
        this.setState({
            ...this.state,
            bSelectUserDlg: false
        })
    }
    // 重置搜索条件
    Reset = () => {
        // this.dateEnd = new Date();
        // this.dateEnd = moment(this.dateEnd).format('YYYY/MM/DD');
        // console.log(this.dateEnd, this.dateEnd.toString());
        // this.dateStart = new Date(this.dateEnd.toString().split('/')[0] + "/" + this.dateEnd.toString().split('/')[1] + "/01");
        // this.dateStart = moment(this.dateStart).format('YYYY/MM/DD');

        // this.dateStart = this.dateStart.toString();
        // this.dateEnd = this.dateEnd.toString();

        this.iChargeUserID = -1;
        this.iChargeUserTeam = -1;
        this.strUserName = "";

        this.refs.user.state.value = "";
        this.GetTruckingOrderData();
    }

    // 卸载异步操作设置状态
    componentWillUnmount() {
        clearTimeout(this.timeouter)
        this.setState = (state, callback) => {
            return;
        }
    }
    // 渲染完毕……
    componentDidMount() {
        this.GetTruckingOrderData();
        if (global.arrUserData.length <= 0)
            this.GetUsersData();
    }

    render() {
        // 商家管理后台数据展示
        let uiTitle = (
            <div className="logistics_page_title">
                {/* <MonthPicker placeholder="选择月份" picker="month" onChange={this.MonthPickerChange} defaultValue={this.dateDefault} /> */}
                <div style={{ color: "white", fontWeight: 'bold', marginLeft: '15px' }}>{'选择时间段：'}</div>
                <RangePicker defaultValue={[moment(this.dateStart, dateFormat), moment(this.dateEnd, dateFormat)]} format={dateFormat}
                    onChange={this.RangePickerChange} />

            </div>
        );
        let uiStoreData = (
            <div className="general_data_show general_data_show_padding_top">
                <div className="general_data_show_title">运单数量</div>
                <div className="general_data_show_row">
                    <div className="general_data_show_cell">
                        <Statistic title="全部" value={this.iTruckingOrder} prefix={<Icon type="form" />} />
                    </div>
                    <div className="general_data_show_cell">
                        <Statistic title="FTL" value={this.iFTLOrder} prefix={<Icon type="form" />} />
                    </div>
                    <div className="general_data_show_cell">
                        <Statistic title="LTL" value={this.iLTLOrder} prefix={<Icon type="form" />} />
                    </div>
                    <div className="general_data_show_cell">
                        <Statistic title="已结单" value={this.iCompleteOrder} prefix={<Icon type="form" />} />
                    </div>
                </div>
                <div className="general_data_show_row">
                    <div className="general_data_show_cell">
                        <Statistic title="全部总量" value={this.iAllTruckingOrder} prefix={<Icon type="form" />} />
                    </div>
                    <div className="general_data_show_cell">
                        <Statistic title="FTL总量" value={this.iAllFTLOrder} prefix={<Icon type="form" />} />
                    </div>
                    <div className="general_data_show_cell">
                        <Statistic title="LTL总量" value={this.iAllLTLOrder} prefix={<Icon type="form" />} />
                    </div>
                    <div className="general_data_show_cell">
                        <Statistic title="结单总量" value={this.iAllCompleteOrder} prefix={<Icon type="form" />} />
                    </div>
                </div>
                <div className="general_data_show_title">营业额</div>
                <div className="general_data_show_row">
                    <div className="general_data_show_cell">
                        <Statistic title="收入(CAD)" value={this.fMonthRevenue} precision={2} prefix={<Icon type="dollar" />} />
                    </div>
                    <div className="general_data_show_cell">
                        <Statistic title="支出(CAD)" value={this.fMonthExpense} precision={2} prefix={<Icon type="dollar" />} />
                    </div>
                    <div className="general_data_show_cell">
                        <Statistic title="利润(CAD)" value={this.fMonthBenefit} precision={2} prefix={<Icon type="dollar" />} />
                    </div>
                </div>
                <div className="general_data_show_row">
                    <div className="general_data_show_cell">
                        <Statistic title="总收入(CAD)" value={this.fTotalRevenue} precision={2} prefix={<Icon type="dollar" />} />
                    </div>
                    <div className="general_data_show_cell">
                        <Statistic title="总支出(CAD)" value={this.fTotalExpense} precision={2} prefix={<Icon type="dollar" />} />
                    </div>
                    <div className="general_data_show_cell">
                        <Statistic title="总利润(CAD)" value={this.fTotalBenefit} precision={2} prefix={<Icon type="dollar" />} />
                    </div>
                </div>
            </div>
        );

        let uiContents = uiStoreData;
        let strAreaStyle = "logistics_page_contents";
        // let config = null;
        // let seriesData1 = [
        //     { name: '全部', data: [this.arrTeamOrderMonth[0]] },
        //     { name: 'A组', data: [this.arrTeamOrderMonth[1]] },
        //     { name: 'B组', data: [this.arrTeamOrderMonth[2]] },
        //     { name: 'C组', data: [this.arrTeamOrderMonth[3]] },
        //     { name: 'D组', data: [this.arrTeamOrderMonth[4]] },
        //     { name: 'E组', data: [this.arrTeamOrderMonth[5]] }
        // ];
        // let seriesData2 = [
        //     { name: '全部', data: [parseFloat(this.arrTeamBenefitMonth[0])] },
        //     { name: 'A组', data: [parseFloat(this.arrTeamBenefitMonth[1])] },
        //     { name: 'B组', data: [parseFloat(this.arrTeamBenefitMonth[2])] },
        //     { name: 'C组', data: [parseFloat(this.arrTeamBenefitMonth[3])] },
        //     { name: 'D组', data: [parseFloat(this.arrTeamBenefitMonth[4])] },
        //     { name: 'E组', data: [parseFloat(this.arrTeamBenefitMonth[5])] }
        // ];
        // if (this.iChargeUserID >= 0) {
        //     seriesData1 = [
        //         { name: this.strUserName, data: [this.arrTeamOrderMonth[0]] }
        //     ];
        //     seriesData2 = [
        //         { name: this.strUserName, data: [this.arrTeamBenefitMonth[0]] },
        //     ];
        // }

        // let config1 = {
        //     chart: {
        //         type: "column", style: { fontFamily: "Arial" },
        //     },
        //     plotOptions: {
        //         column: {
        //             pointWidth: 40,
        //             borderWidth: 1,
        //         }
        //     },
        //     title: { text: '单量' },
        //     // subtitle: { text: '营业额' },
        //     xAxis: {
        //         categories: [this.dateStart + ' ~ ' + this.dateEnd],
        //         // categories: [this.dateCurrent],
        //         crosshair: true
        //     },
        //     yAxis: {
        //         // min: -1000000,
        //         title: { text: '单量' }
        //     },
        //     series: seriesData1
        // };
        // let config2 = {
        //     chart: { type: "column", style: { fontFamily: "Arial" } },
        //     plotOptions: {
        //         column: {
        //             pointWidth: 40,
        //             borderWidth: 1,
        //         }
        //     },
        //     title: { text: '营业额（利润）' },
        //     xAxis: {
        //         categories: [this.dateStart + ' ~ ' + this.dateEnd],
        //         crosshair: true
        //     },
        //     yAxis: {
        //         // min: 0,
        //         title: { text: '营业额' }
        //     },
        //     series: seriesData2
        // };
        // if (this.state.iCharContent === 0)
        //     config = config1;
        // if (this.state.iCharContent === 1)
        //     config = config2;

        // let uiChart = (
        //     <div className="logistics_chart_area">
        //         <Radio.Group defaultValue={0} buttonStyle="solid" onChange={this.ChartContentChange} value={this.state.iCharContent}>
        //             <Radio.Button value={0}>单量</Radio.Button>
        //             <Radio.Button value={1}>营业额</Radio.Button>
        //         </Radio.Group>
        //         <ReactHighCharts config={config} className="highcharts-figure" />
        //     </div>
        // );
        // 选择负责人弹窗
        // let uiUserList = (
        //     <div className="storage_sku_list_area">
        //         {global.arrUserData.map((user, index) => {
        //             return (
        //                 <div className="storage_sku_list_row" key={index} onClick={this.UserSelectResult.bind(this, index)}>
        //                     {user.name}
        //                 </div>
        //             );
        //         })}
        //     </div>
        // );
        // let uiUserDlg = (
        //     <Modal width={300} title={"选择负责人"} visible={this.state.bSelectUserDlg} closable={true} maskClosable={true} footer={null}
        //         onCancel={this.UserSelectResult.bind(this, -1)} > {uiUserList} </Modal>);


        return (
            <div className={strAreaStyle}>
                {uiTitle}
                {uiContents}
                {/* {uiChart} */}
                {/* {uiUserDlg} */}
            </div >
        );
    }
}
export default TruckingDataPanel;
