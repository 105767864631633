import React, { Component } from "react";

import Input from 'antd/es/input';

import '../../../styles/Background/UI_Module/customs_file.css';

const { TextArea } = Input;

class PDFA8AB extends Component {
    constructor(props) {
        super();
        this.state = {
            bEditing: false,
        };

        // this.objA8ABEmpty = {
        //     store_id: -1,
        //     order_no: '',
        //     us_port_of_exit: '',
        //     in_transit: '',
        //     manifest_from: '',
        //     manifest_to: '',
        //     consignee_name_address: '',
        //     shipper_name_address: '',
        //     no_of_pkgs: '',
        //     description_and_marks: '',
        //     arrival_vessel: '',
        //     type_of_cargo: '',
        //     container_no: '',
        //     seal_no: '',
        //     bl_no: '',
        //     eta: '',
        //     foreign_point_of_lading: '',
        //     name_of_carrier: '',
        //     acquittal_no: '',
        //     carrier_code: '',
        //     cargo_control_no: '',
        //     precious_cargo_control_no: '',
        //     weight: '',
        //     volume: '',
        //     on_board_date: '',
        //     location_of_goods: '',
        //     conveyance_id: ''
        // };
        // this.props.objCurrent_a8ab = Object.assign({}, this.objA8ABEmpty);
        this.strCurrentEditKey = "";
    }

    // 编辑文件信息
    EditCFInfo = (strKey) => {
        let bEditing = true;

        if (strKey === -1) {
            this.props.objCurrent_a8ab[this.strCurrentEditKey] = this.refs[this.strCurrentEditKey].state.value;
            bEditing = false;
            this.strCurrentEditKey = "";
        } else {
            this.strCurrentEditKey = strKey;
        }

        this.setState({
            ...this.state,
            bEditing: bEditing
        });
    }

    // 渲染完毕……
    componentDidMount() {

    }

    render() {

        // us_port_of_exit
        let uiUSPortExit = (
            <div className="a8a-b_template_01_block_content_align_left_blank"
                onDoubleClick={this.EditCFInfo.bind(this, "us_port_of_exit")}></div>
        );
        if (this.props.objCurrent_a8ab.us_port_of_exit !== "")
            uiUSPortExit = (
                <div className="a8a-b_template_01_block_content_align_left"
                    onDoubleClick={this.EditCFInfo.bind(this, "us_port_of_exit")}>
                    {this.props.objCurrent_a8ab.us_port_of_exit}</div>
            );
        if (this.strCurrentEditKey === "us_port_of_exit") {
            uiUSPortExit = (
                <div className="a8a-b_template_01_block_content_align_left">
                    <Input size="large" defaultValue={this.props.objCurrent_a8ab.us_port_of_exit} autoFocus ref="us_port_of_exit"
                        style={{ width: '90%', fontSize: '5mm' }} onBlur={this.EditCFInfo.bind(this, -1)} onPressEnter={this.EditCFInfo.bind(this, -1)} />
                </div>);
        }
        // in_transit
        let uiInTransit = (
            <div className="a8a-b_template_01_block_content_align_left_blank"
                onDoubleClick={this.EditCFInfo.bind(this, "in_transit")}></div>
        );
        if (this.props.objCurrent_a8ab.in_transit !== "")
            uiInTransit = (
                <div className="a8a-b_template_01_block_content_align_left"
                    onDoubleClick={this.EditCFInfo.bind(this, "in_transit")}>
                    {this.props.objCurrent_a8ab.in_transit}</div>
            );
        if (this.strCurrentEditKey === "in_transit") {
            uiInTransit = (
                <div className="a8a-b_template_01_block_content_align_left">
                    <Input size="large" defaultValue={this.props.objCurrent_a8ab.in_transit} autoFocus ref="in_transit"
                        style={{ width: '90%', fontSize: '5mm' }} onBlur={this.EditCFInfo.bind(this, -1)} onPressEnter={this.EditCFInfo.bind(this, -1)} />
                </div>);
        }
        // mainfest_from
        let uiMainfestFromContent = (
            <div className="a8a-b_template_01_block_content_align_left_blank"
                onDoubleClick={this.EditCFInfo.bind(this, "manifest_from")}></div>
        );
        if (this.props.objCurrent_a8ab.manifest_from !== "")
            uiMainfestFromContent = (
                <div className="a8a-b_template_01_block_content_align_left"
                    onDoubleClick={this.EditCFInfo.bind(this, "manifest_from")}>
                    {this.props.objCurrent_a8ab.manifest_from}</div>
            );
        if (this.strCurrentEditKey === "manifest_from") {
            uiMainfestFromContent = (
                <div className="a8a-b_template_01_block_content_align_left">
                    <Input size="large" defaultValue={this.props.objCurrent_a8ab.manifest_from} autoFocus ref="manifest_from"
                        style={{ width: '90%', fontSize: '5mm' }} onBlur={this.EditCFInfo.bind(this, -1)} onPressEnter={this.EditCFInfo.bind(this, -1)} />
                </div>);
        }
        // mainfest_to
        let uiMainfestToContent = (
            <div className="a8a-b_template_01_block_content_align_left_blank"
                onDoubleClick={this.EditCFInfo.bind(this, "manifest_to")}></div>
        );
        if (this.props.objCurrent_a8ab.manifest_to !== "")
            uiMainfestToContent = (
                <div className="a8a-b_template_01_block_content_align_left"
                    onDoubleClick={this.EditCFInfo.bind(this, "manifest_to")}>
                    {this.props.objCurrent_a8ab.manifest_to}</div>
            );
        if (this.strCurrentEditKey === "manifest_to") {
            uiMainfestToContent = (
                <div className="a8a-b_template_01_block_content_align_left">
                    <Input size="large" defaultValue={this.props.objCurrent_a8ab.manifest_to} autoFocus ref="manifest_to"
                        style={{ width: '90%', fontSize: '5mm' }} onBlur={this.EditCFInfo.bind(this, -1)} onPressEnter={this.EditCFInfo.bind(this, -1)} />
                </div>);
        }
        // consignee_name_address
        let uiConsigneeNameAddress = (
            <div className="a8a-b_template_01_block_content_align_left_blank"
                onDoubleClick={this.EditCFInfo.bind(this, "consignee_name_address")}></div>
        );
        if (this.props.objCurrent_a8ab.consignee_name_address !== null && this.props.objCurrent_a8ab.consignee_name_address !== "") {
            uiConsigneeNameAddress = (<div className="a8a-b_template_01_block_content_align_left"
                onDoubleClick={this.EditCFInfo.bind(this, "consignee_name_address")}>{this.props.objCurrent_a8ab.consignee_name_address}</div>);
            if (this.props.objCurrent_a8ab.consignee_name_address !== "" && this.props.objCurrent_a8ab.consignee_name_address.indexOf('\n')) {
                let arrConsigneeNameAddress = this.props.objCurrent_a8ab.consignee_name_address.split('\n');
                uiConsigneeNameAddress = (
                    arrConsigneeNameAddress.map((item, index) => {
                        return (
                            <div className="a8a-b_template_01_block_content_align_left" key={index}
                                onDoubleClick={this.EditCFInfo.bind(this, "consignee_name_address")}>
                                {item}
                            </div>
                        );
                    })
                );
            }
        }
        if (this.strCurrentEditKey === "consignee_name_address") {
            uiConsigneeNameAddress = (
                <div className="a8a-b_template_01_block_content_align_left">
                    <TextArea size="large" autoSize={{ minRows: 3 }} ref="consignee_name_address" style={{ width: '90%', fontSize: '5mm' }} autoFocus
                        onBlur={this.EditCFInfo.bind(this, -1)} defaultValue={this.props.objCurrent_a8ab.consignee_name_address} />
                </div>);
        }
        // shipper_name_address
        let uiShipperNameAddress = (
            <div className="a8a-b_template_01_block_content_align_left_blank"
                onDoubleClick={this.EditCFInfo.bind(this, "shipper_name_address")}></div>
        );
        if (this.props.objCurrent_a8ab.shipper_name_address !== null && this.props.objCurrent_a8ab.shipper_name_address !== "") {
            uiShipperNameAddress = (<div className="a8a-b_template_01_block_content_align_left"
                onDoubleClick={this.EditCFInfo.bind(this, "shipper_name_address")}>{this.props.objCurrent_a8ab.shipper_name_address}</div>);
            if (this.props.objCurrent_a8ab.shipper_name_address !== "" && this.props.objCurrent_a8ab.shipper_name_address.indexOf('\n')) {
                let arrShipperNameAddress = this.props.objCurrent_a8ab.shipper_name_address.split('\n');
                uiShipperNameAddress = (
                    arrShipperNameAddress.map((item, index) => {
                        return (
                            <div className="a8a-b_template_01_block_content_align_left" key={index}
                                onDoubleClick={this.EditCFInfo.bind(this, "shipper_name_address")}>
                                {item}
                            </div>
                        );
                    })
                );
            }
        }
        if (this.strCurrentEditKey === "shipper_name_address") {
            uiShipperNameAddress = (
                <div className="a8a-b_template_01_block_content_align_left">
                    <TextArea size="large" autoSize={{ minRows: 3 }} ref="shipper_name_address" style={{ width: '90%', fontSize: '5mm' }} autoFocus
                        onBlur={this.EditCFInfo.bind(this, -1)} defaultValue={this.props.objCurrent_a8ab.shipper_name_address} />
                </div>);
        }
        // no_of_pkgs
        let uiPkgs = (
            <div className="a8a-b_template_01_block_content_align_left_blank"
                onDoubleClick={this.EditCFInfo.bind(this, "no_of_pkgs")}></div>
        );
        if (this.props.objCurrent_a8ab.no_of_pkgs !== null && this.props.objCurrent_a8ab.no_of_pkgs !== "") {
            uiPkgs = (<div className="a8a-b_template_01_block_content_align_center"
                onDoubleClick={this.EditCFInfo.bind(this, "no_of_pkgs")}>{this.props.objCurrent_a8ab.no_of_pkgs}</div>);
            if (this.props.objCurrent_a8ab.no_of_pkgs.indexOf('\n')) {
                let arrDescription = this.props.objCurrent_a8ab.no_of_pkgs.split('\n');
                uiPkgs = (
                    arrDescription.map((item, index) => {
                        return (
                            <div className="a8a-b_template_01_block_content_align_center" key={index}
                                onDoubleClick={this.EditCFInfo.bind(this, "no_of_pkgs")}>
                                {item}
                            </div>
                        );
                    })
                );
            }
        }
        if (this.strCurrentEditKey === "no_of_pkgs") {
            uiPkgs = (
                <div className="a8a-b_template_01_block_content_align_center">
                    <TextArea size="large" autoSize={{ minRows: 3 }} ref="no_of_pkgs" style={{ width: '90%', fontSize: '5mm' }} autoFocus
                        onBlur={this.EditCFInfo.bind(this, -1)} defaultValue={this.props.objCurrent_a8ab.no_of_pkgs} />
                </div>);
        }
        // description_and_marks
        let uiDescription = (
            <div className="a8a-b_template_01_block_content_align_left_blank"
                onDoubleClick={this.EditCFInfo.bind(this, "description_and_marks")}></div>
        );
        if (this.props.objCurrent_a8ab.description_and_marks !== null && this.props.objCurrent_a8ab.description_and_marks !== "") {
            uiDescription = (<div className="a8a-b_template_01_block_content_align_center"
                onDoubleClick={this.EditCFInfo.bind(this, "description_and_marks")}>{this.props.objCurrent_a8ab.description_and_marks}</div>);
            if (this.props.objCurrent_a8ab.description_and_marks.indexOf('\n')) {
                let arrDescription = this.props.objCurrent_a8ab.description_and_marks.split('\n');
                uiDescription = (
                    arrDescription.map((item, index) => {
                        return (
                            <div className="a8a-b_template_01_block_content_align_center" key={index}
                                onDoubleClick={this.EditCFInfo.bind(this, "description_and_marks")}>
                                {item}
                            </div>
                        );
                    })
                );
            }
        }
        if (this.strCurrentEditKey === "description_and_marks") {
            uiDescription = (
                <div className="a8a-b_template_01_block_content_align_center">
                    <TextArea size="large" autoSize={{ minRows: 3 }} ref="description_and_marks" style={{ width: '90%', fontSize: '5mm' }} autoFocus
                        onBlur={this.EditCFInfo.bind(this, -1)} defaultValue={this.props.objCurrent_a8ab.description_and_marks} />
                </div>);
        }
        // foreign_point_of_lading
        let uiForeignPointLading = (
            <div className="a8a-b_template_01_block_content_align_left_blank"
                onDoubleClick={this.EditCFInfo.bind(this, "foreign_point_of_lading")}></div>
        );
        if (this.props.objCurrent_a8ab.foreign_point_of_lading !== "")
            uiForeignPointLading = (
                <div className="a8a-b_template_01_block_content_align_left"
                    onDoubleClick={this.EditCFInfo.bind(this, "foreign_point_of_lading")}>
                    {this.props.objCurrent_a8ab.foreign_point_of_lading}</div>
            );
        if (this.strCurrentEditKey === "foreign_point_of_lading") {
            uiForeignPointLading = (
                <div className="a8a-b_template_01_block_content_align_left">
                    <Input size="large" defaultValue={this.props.objCurrent_a8ab.foreign_point_of_lading} autoFocus ref="foreign_point_of_lading"
                        style={{ width: '90%', fontSize: '5mm' }} onBlur={this.EditCFInfo.bind(this, -1)} onPressEnter={this.EditCFInfo.bind(this, -1)} />
                </div>);
        }
        // name_of_carrier
        let uiNameCarrier = (
            <div className="a8a-b_template_01_block_content_align_left_blank"
                onDoubleClick={this.EditCFInfo.bind(this, "name_of_carrier")}></div>
        );
        if (this.props.objCurrent_a8ab.name_of_carrier !== "")
            uiNameCarrier = (
                <div className="a8a-b_template_01_block_content_align_left"
                    onDoubleClick={this.EditCFInfo.bind(this, "name_of_carrier")}>
                    {this.props.objCurrent_a8ab.name_of_carrier}</div>
            );
        if (this.strCurrentEditKey === "name_of_carrier") {
            uiNameCarrier = (
                <div className="a8a-b_template_01_block_content_align_left">
                    <Input size="large" defaultValue={this.props.objCurrent_a8ab.name_of_carrier} autoFocus ref="name_of_carrier"
                        style={{ width: '90%', fontSize: '5mm' }} onBlur={this.EditCFInfo.bind(this, -1)} onPressEnter={this.EditCFInfo.bind(this, -1)} />
                </div>);
        }
        // carrier_code
        let uiCarreirCode = (
            <div className="a8a-b_template_01_block_content_align_left_blank"
                onDoubleClick={this.EditCFInfo.bind(this, "carrier_code")}></div>
        );
        if (this.props.objCurrent_a8ab.carrier_code !== "")
            uiCarreirCode = (
                <div className="a8a-b_template_01_block_content_align_left"
                    onDoubleClick={this.EditCFInfo.bind(this, "carrier_code")}>
                    {this.props.objCurrent_a8ab.carrier_code}</div>
            );
        if (this.strCurrentEditKey === "carrier_code") {
            uiCarreirCode = (
                <div className="a8a-b_template_01_block_content_align_left">
                    <Input size="large" defaultValue={this.props.objCurrent_a8ab.carrier_code} autoFocus ref="carrier_code"
                        style={{ width: '90%', fontSize: '5mm' }} onBlur={this.EditCFInfo.bind(this, -1)} onPressEnter={this.EditCFInfo.bind(this, -1)} />
                </div>);
        }
        // cargo_control_no
        let uiCargoControlNo = (
            <div className="a8a-b_template_01_block_content_align_left_blank"
                onDoubleClick={this.EditCFInfo.bind(this, "cargo_control_no")}></div>
        );
        if (this.props.objCurrent_a8ab.cargo_control_no !== "")
            uiCargoControlNo = (
                <div className="a8a-b_template_01_block_content_align_left"
                    onDoubleClick={this.EditCFInfo.bind(this, "cargo_control_no")}>
                    {this.props.objCurrent_a8ab.cargo_control_no}</div>
            );
        if (this.strCurrentEditKey === "cargo_control_no") {
            uiCargoControlNo = (
                <div className="a8a-b_template_01_block_content_align_left">
                    <Input size="large" defaultValue={this.props.objCurrent_a8ab.cargo_control_no} autoFocus ref="cargo_control_no"
                        style={{ width: '90%', fontSize: '5mm' }} onBlur={this.EditCFInfo.bind(this, -1)} onPressEnter={this.EditCFInfo.bind(this, -1)} />
                </div>);
        }
        // precious_cargo_control_no
        let uiPreciousCargoControlNo = (
            <div className="a8a-b_template_01_block_content_align_left_blank"
                onDoubleClick={this.EditCFInfo.bind(this, "precious_cargo_control_no")}></div>
        );
        if (this.props.objCurrent_a8ab.precious_cargo_control_no !== "")
            uiPreciousCargoControlNo = (
                <div className="a8a-b_template_01_block_content_align_left"
                    onDoubleClick={this.EditCFInfo.bind(this, "precious_cargo_control_no")}>
                    {this.props.objCurrent_a8ab.precious_cargo_control_no}</div>
            );
        if (this.strCurrentEditKey === "precious_cargo_control_no") {
            uiPreciousCargoControlNo = (
                <div className="a8a-b_template_01_block_content_align_left">
                    <Input size="large" defaultValue={this.props.objCurrent_a8ab.precious_cargo_control_no} autoFocus ref="precious_cargo_control_no"
                        style={{ width: '90%', fontSize: '5mm' }} onBlur={this.EditCFInfo.bind(this, -1)} onPressEnter={this.EditCFInfo.bind(this, -1)} />
                </div>);
        }
        // weight
        let uiWeight = (
            <div className="a8a-b_template_01_block_content_align_left_blank"
                onDoubleClick={this.EditCFInfo.bind(this, "weight")}></div>
        );
        if (this.props.objCurrent_a8ab.weight !== null && this.props.objCurrent_a8ab.weight !== "") {
            uiWeight = (<div className="a8a-b_template_01_block_content_align_left"
                onDoubleClick={this.EditCFInfo.bind(this, "weight")}>{this.props.objCurrent_a8ab.weight}</div>);
            if (this.props.objCurrent_a8ab.weight !== "" && this.props.objCurrent_a8ab.weight.indexOf('\n')) {
                let arrLocationGoods = this.props.objCurrent_a8ab.weight.split('\n');
                uiWeight = (
                    arrLocationGoods.map((item, index) => {
                        if (item.trim() === "")
                            return null;
                        else
                            return (
                                <div className="a8a-b_template_01_block_content_align_center" key={index}
                                    onDoubleClick={this.EditCFInfo.bind(this, "weight")}>
                                    {item + " KGS"}
                                </div>
                            );
                    })
                );
            }
        }
        if (this.strCurrentEditKey === "weight") {
            uiWeight = (
                <div className="a8a-b_template_01_block_content_align_left">
                    <TextArea size="large" autoSize={{ minRows: 3 }} ref="weight" style={{ width: '90%', fontSize: '5mm' }} autoFocus
                        onBlur={this.EditCFInfo.bind(this, -1)} defaultValue={this.props.objCurrent_a8ab.weight} />
                </div>);
        }
        // volume
        let uiVolume = (
            <div className="a8a-b_template_01_block_content_align_left_blank"
                onDoubleClick={this.EditCFInfo.bind(this, "volume")}></div>
        );
        if (this.props.objCurrent_a8ab.volume !== null && this.props.objCurrent_a8ab.volume !== "") {
            uiVolume = (<div className="a8a-b_template_01_block_content_align_left"
                onDoubleClick={this.EditCFInfo.bind(this, "volume")}>{this.props.objCurrent_a8ab.volume}</div>);
            if (this.props.objCurrent_a8ab.volume !== "" && this.props.objCurrent_a8ab.volume.indexOf('\n')) {
                let arrLocationGoods = this.props.objCurrent_a8ab.volume.split('\n');
                uiVolume = (
                    arrLocationGoods.map((item, index) => {
                        if (item.trim() === "")
                            return null;
                        else
                            return (
                                <div className="a8a-b_template_01_block_content_align_center" key={index}
                                    onDoubleClick={this.EditCFInfo.bind(this, "volume")}>
                                    {item + " CBM"}
                                </div>
                            );
                    })
                );
            }
        }
        if (this.strCurrentEditKey === "volume") {
            uiVolume = (
                <div className="a8a-b_template_01_block_content_align_left">
                    <TextArea size="large" autoSize={{ minRows: 3 }} ref="volume" style={{ width: '90%', fontSize: '5mm' }} autoFocus
                        onBlur={this.EditCFInfo.bind(this, -1)} defaultValue={this.props.objCurrent_a8ab.volume} />
                </div>);
        }
        // arrival_vessel
        let uiArrivalVessel = (
            <div className="a8a-b_template_01_block_content_align_left_blank"
                onDoubleClick={this.EditCFInfo.bind(this, "arrival_vessel")}></div>
        );
        if (this.props.objCurrent_a8ab.arrival_vessel !== "")
            uiArrivalVessel = (
                <div>
                    <div className="a8a-b_template_01_block_content_align_left"
                        onDoubleClick={this.EditCFInfo.bind(this, "arrival_vessel")}>
                        {this.props.objCurrent_a8ab.arrival_vessel}</div>
                </div>
            );
        if (this.strCurrentEditKey === "arrival_vessel") {
            uiArrivalVessel = (
                <div>
                    <div className="a8a-b_template_01_block_content_align_left">
                        <Input size="large" defaultValue={this.props.objCurrent_a8ab.arrival_vessel} autoFocus ref="arrival_vessel"
                            style={{ width: '90%', fontSize: '5mm' }} onBlur={this.EditCFInfo.bind(this, -1)} onPressEnter={this.EditCFInfo.bind(this, -1)} />
                    </div>
                </div>
            );
        }
        // type_of_cargo
        let uiTypeOfCargo = (
            <div className="a8a-b_template_01_block_content_align_left_blank"
                onDoubleClick={this.EditCFInfo.bind(this, "type_of_cargo")}></div>
        );
        if (this.props.objCurrent_a8ab.type_of_cargo !== "")
            uiTypeOfCargo = (
                <div>
                    <div className="a8a-b_template_01_block_content_align_left"
                        onDoubleClick={this.EditCFInfo.bind(this, "type_of_cargo")}>
                        {this.props.objCurrent_a8ab.type_of_cargo}</div>
                </div>
            );
        if (this.strCurrentEditKey === "type_of_cargo") {
            uiTypeOfCargo = (
                <div>
                    <div className="a8a-b_template_01_block_content_align_left">
                        <Input size="large" defaultValue={this.props.objCurrent_a8ab.type_of_cargo} autoFocus ref="type_of_cargo"
                            style={{ width: '90%', fontSize: '5mm' }} onBlur={this.EditCFInfo.bind(this, -1)} onPressEnter={this.EditCFInfo.bind(this, -1)} />
                    </div>
                </div>
            );
        }
        // container_no
        let uiContainerNo = (
            <div className="a8a-b_template_01_block_content_align_left_blank"
                onDoubleClick={this.EditCFInfo.bind(this, "container_no")}></div>
        );
        if (this.props.objCurrent_a8ab.container_no !== "")
            uiContainerNo = (
                <div>
                    <div className="a8a-b_template_01_block_content_align_left"
                        onDoubleClick={this.EditCFInfo.bind(this, "container_no")}>
                        {this.props.objCurrent_a8ab.container_no}</div>
                </div>
            );
        if (this.strCurrentEditKey === "container_no") {
            uiContainerNo = (
                <div>
                    <div className="a8a-b_template_01_block_content_align_left">
                        <Input size="large" defaultValue={this.props.objCurrent_a8ab.container_no} autoFocus ref="container_no"
                            style={{ width: '90%', fontSize: '5mm' }} onBlur={this.EditCFInfo.bind(this, -1)} onPressEnter={this.EditCFInfo.bind(this, -1)} />
                    </div>
                </div>
            );
        }
        // seal_no
        let uiSealNo = (
            <div className="a8a-b_template_01_block_content_align_left_blank"
                onDoubleClick={this.EditCFInfo.bind(this, "seal_no")}></div>
        );
        if (this.props.objCurrent_a8ab.seal_no !== "")
            uiSealNo = (
                <div>
                    <div className="a8a-b_template_01_block_content_align_left"
                        onDoubleClick={this.EditCFInfo.bind(this, "seal_no")}>
                        {this.props.objCurrent_a8ab.seal_no}</div>
                </div>
            );
        if (this.strCurrentEditKey === "seal_no") {
            uiSealNo = (
                <div>
                    <div className="a8a-b_template_01_block_content_align_left">
                        <Input size="large" defaultValue={this.props.objCurrent_a8ab.seal_no} autoFocus ref="seal_no"
                            style={{ width: '90%', fontSize: '5mm' }} onBlur={this.EditCFInfo.bind(this, -1)} onPressEnter={this.EditCFInfo.bind(this, -1)} />
                    </div>
                </div>
            );
        }
        // bl_no
        let uiBLNo = (
            <div className="a8a-b_template_01_block_content_align_left_blank"
                onDoubleClick={this.EditCFInfo.bind(this, "bl_no")}></div>
        );
        if (this.props.objCurrent_a8ab.bl_no !== "")
            uiBLNo = (
                <div>
                    <div className="a8a-b_template_01_block_content_align_left"
                        onDoubleClick={this.EditCFInfo.bind(this, "bl_no")}>
                        {this.props.objCurrent_a8ab.bl_no}</div>
                </div>
            );
        if (this.strCurrentEditKey === "bl_no") {
            uiBLNo = (
                <div>
                    <div className="a8a-b_template_01_block_content_align_left">
                        <Input size="large" defaultValue={this.props.objCurrent_a8ab.bl_no} autoFocus ref="bl_no"
                            style={{ width: '90%', fontSize: '5mm' }} onBlur={this.EditCFInfo.bind(this, -1)} onPressEnter={this.EditCFInfo.bind(this, -1)} />
                    </div>
                </div>
            );
        }
        // eta
        let uiETA = (
            <div className="a8a-b_template_01_block_content_align_left_blank"
                onDoubleClick={this.EditCFInfo.bind(this, "eta")}></div>
        );
        if (this.props.objCurrent_a8ab.eta !== "")
            uiETA = (
                <div>
                    <div className="a8a-b_template_01_block_content_align_left"
                        onDoubleClick={this.EditCFInfo.bind(this, "eta")}>
                        {this.props.objCurrent_a8ab.eta}</div>
                </div>
            );
        if (this.strCurrentEditKey === "eta") {
            uiETA = (
                <div>
                    <div className="a8a-b_template_01_block_content_align_left">
                        <Input size="large" defaultValue={this.props.objCurrent_a8ab.eta} autoFocus ref="eta"
                            style={{ width: '90%', fontSize: '5mm' }} onBlur={this.EditCFInfo.bind(this, -1)} onPressEnter={this.EditCFInfo.bind(this, -1)} />
                    </div>
                </div>
            );
        }
        // on_board_date
        let uiOnBoardDate = (
            <div className="a8a-b_template_01_block_content_align_left_blank"
                onDoubleClick={this.EditCFInfo.bind(this, "on_board_date")}></div>
        );
        if (this.props.objCurrent_a8ab.on_board_date !== "")
            uiOnBoardDate = (
                <div className="a8a-b_template_01_block_content_align_left"
                    onDoubleClick={this.EditCFInfo.bind(this, "on_board_date")}>
                    {this.props.objCurrent_a8ab.on_board_date}</div>
            );
        if (this.strCurrentEditKey === "on_board_date") {
            uiOnBoardDate = (
                <div className="a8a-b_template_01_block_content_align_left">
                    <Input size="large" defaultValue={this.props.objCurrent_a8ab.on_board_date} autoFocus ref="on_board_date"
                        style={{ width: '90%', fontSize: '5mm' }} onBlur={this.EditCFInfo.bind(this, -1)} onPressEnter={this.EditCFInfo.bind(this, -1)} />
                </div>);
        }
        // location_of_goods
        let uiLocationGoods = (
            <div className="a8a-b_template_01_block_content_align_left_blank"
                onDoubleClick={this.EditCFInfo.bind(this, "location_of_goods")}></div>
        );
        if (this.props.objCurrent_a8ab.location_of_goods !== null && this.props.objCurrent_a8ab.location_of_goods !== "") {
            uiLocationGoods = (<div className="a8a-b_template_01_block_content_align_left"
                onDoubleClick={this.EditCFInfo.bind(this, "location_of_goods")}>{this.props.objCurrent_a8ab.location_of_goods}</div>);
            if (this.props.objCurrent_a8ab.location_of_goods !== "" && this.props.objCurrent_a8ab.location_of_goods.indexOf('\n')) {
                let arrLocationGoods = this.props.objCurrent_a8ab.location_of_goods.split('\n');
                uiLocationGoods = (
                    arrLocationGoods.map((item, index) => {
                        return (
                            <div className="a8a-b_template_01_block_content_align_left" key={index}
                                onDoubleClick={this.EditCFInfo.bind(this, "location_of_goods")}>
                                {item}
                            </div>
                        );
                    })
                );
            }
        }
        if (this.strCurrentEditKey === "location_of_goods") {
            uiLocationGoods = (
                <div className="a8a-b_template_01_block_content_align_left">
                    <TextArea size="large" autoSize={{ minRows: 3 }} ref="location_of_goods" style={{ width: '90%', fontSize: '5mm' }} autoFocus
                        onBlur={this.EditCFInfo.bind(this, -1)} defaultValue={this.props.objCurrent_a8ab.location_of_goods} />
                </div>);
        }
        // conveyance_id
        let uiConveyanceID = (
            <div className="a8a-b_template_01_block_content_align_left_blank"
                onDoubleClick={this.EditCFInfo.bind(this, "conveyance_id")}></div>
        );
        if (this.props.objCurrent_a8ab.conveyance_id !== "")
            uiConveyanceID = (
                <div className="a8a-b_template_01_block_content_align_left"
                    onDoubleClick={this.EditCFInfo.bind(this, "conveyance_id")}>
                    {this.props.objCurrent_a8ab.conveyance_id}</div>
            );
        if (this.strCurrentEditKey === "conveyance_id") {
            uiConveyanceID = (
                <div className="a8a-b_template_01_block_content_align_left">
                    <Input size="large" defaultValue={this.props.objCurrent_a8ab.conveyance_id} autoFocus ref="conveyance_id"
                        style={{ width: '90%', fontSize: '5mm' }} onBlur={this.EditCFInfo.bind(this, -1)} onPressEnter={this.EditCFInfo.bind(this, -1)} />
                </div>);
        }
        // Company Info Title
        let uiSpace1 = (
            <div style={{ width: '0.5%' }}></div>
        );
        let uiSpace2 = (
            <div style={{ width: '1.5%' }}></div>
        );
        let uiCompanyInfoTitle = (
            <div className="custom_file_company_info_title">
                <b>Address: </b>
                {uiSpace1}
                {this.props.objStoreInfo.address}
                {uiSpace2}
                <b> Tel: </b>
                {uiSpace1}
                {this.props.objStoreInfo.phone}
                {uiSpace2}
                <b> Email: </b>
                {uiSpace1}
                {this.props.objStoreInfo.email}
            </div>
        );
        // a8a-b pdf body
        let uiPdfA8AB = (
            <div className="pdf_area" id={"a8a-b"} >
                {uiCompanyInfoTitle}
                <img className="a8a-b_template_left_01" alt=''
                    src={"https://res.cloudinary.com/jingtu-ego-test/image/upload/v1609297122/0-%E9%B2%B8%E5%9B%BEWhaleMap/Title_sz3pyx.jpg"} />
                <div className="pdf_central_area">
                    {/* 左侧 */}
                    <div className="a8a-b_template_left_side">
                        <div className="a8a-b_template_left_02">
                            <div className="a8a-b_template_left_02_01">
                                <div className="a8a-b_template_01_block_title_align_left">{"U.S. port of exit"/* + " - Bureau de sortie des E.-U."*/}</div>
                                {uiUSPortExit}
                            </div>
                            <div className="a8a-b_template_left_02_01">
                                <div className="a8a-b_template_01_block_title_align_left">{"In transit"/* + " - En transit"*/}</div>
                                {uiInTransit}
                            </div>
                        </div>
                        <div className="a8a-b_template_left_02">
                            <div className="a8a-b_template_left_02_01">
                                <div className="a8a-b_template_01_block_title_align_left">{"Manifest from"/* + " - Manifeste de"*/}</div>
                                {uiMainfestFromContent}
                            </div>
                            <div className="a8a-b_template_left_02_01">
                                <div className="a8a-b_template_01_block_title_align_left">{"Manifest To"/* + " - À"*/}</div>
                                {uiMainfestToContent}
                            </div>
                        </div>
                        <div className="a8a-b_template_left_03">
                            <div className="a8a-b_template_01_block_title_align_left">{"Consignee name and address"/* + " - Nom et adresse du destinataire"*/}</div>
                            {uiConsigneeNameAddress}
                        </div>
                        <div className="a8a-b_template_left_03">
                            <div className="a8a-b_template_01_block_title_align_left">{"Shipper name and address"/* + "  Nom et adresse de I'expediteur"*/}</div>
                            {uiShipperNameAddress}
                        </div>
                        <div className="a8a-b_template_left_04">
                            <div className="a8a-b_template_left_04_01">
                                <div className="a8a-b_template_01_block_title_align_center">{"No. of pkgs."}</div>
                            </div>
                            <div className="a8a-b_template_left_04_02">
                                <div className="a8a-b_template_01_block_title_align_center">{"Description and marks"}</div>
                            </div>
                        </div>
                        <div className="a8a-b_template_left_05">
                            <div className="a8a-b_template_left_05_01">
                                {uiPkgs}
                            </div>
                            <div className="a8a-b_template_left_05_02">
                                {uiDescription}
                            </div>
                        </div>
                        <div className="a8a-b_template_left_06">
                            <div className="a8a-b_template_01_block_title_align_left">
                                {"Onboard date"/* - À bord date"*/}
                            </div>
                            {uiOnBoardDate}
                        </div>
                        <div className="a8a-b_template_left_06">
                            <div className="a8a-b_template_01_block_title_align_left">
                                {"Foreign point of lading"/* + "   - Port de chargement etranger"*/}
                            </div>
                            {uiForeignPointLading}
                        </div>
                        <div className="a8a-b_template_left_06">
                            <div className="a8a-b_template_01_block_title_align_left">
                                {"Name of carrier"/* + "   - Nom du transporteur"*/}
                            </div>
                            {uiNameCarrier}
                        </div>
                    </div>
                    {/* 右侧 */}
                    <div className="a8a-b_template_right_side">
                        <div className="a8a-b_template_right_01">
                            <div className="a8a-b_template_01_block_title_align_left">{"Acquittal No."/* + "   - N° de l'acquittement"*/}</div>
                        </div>
                        <div className="a8a-b_template_right_02">
                            <div className="a8a-b_template_right_02_01">
                                <div className="a8a-b_template_01_block_title_align_left">{"Carrier code"/* + "   - Code du transporteur"*/}</div>
                                {uiCarreirCode}
                            </div>
                            <div className="a8a-b_template_right_02_01">
                                <div className="a8a-b_template_01_block_title_align_left">
                                    {"Cargo control No."/* -  N° de controle du fret"*/}
                                </div>
                                {uiCargoControlNo}
                            </div>
                        </div>
                        <div className="a8a-b_template_right_03">
                            <div className="a8a-b_template_01_block_title_align_left">
                                {"Previous cargo control No."/* + "   -  N° de controle du fret anterieur"*/}
                            </div>
                            {uiPreciousCargoControlNo}
                        </div>
                        <div className="a8a-b_template_right_04">
                            <div className="a8a-b_template_right_04_01">
                                <div className="a8a-b_template_right_05_01">
                                    <div className="a8a-b_template_01_block_title_align_center">{"Weight"}</div>
                                </div>
                                <div className="a8a-b_template_right_05_02">
                                    {uiWeight}
                                </div>
                                <div className="a8a-b_template_right_05_01">
                                    <div className="a8a-b_template_01_block_title_align_center">{"Volume"}</div>
                                </div>
                                <div className="a8a-b_template_right_05_02">
                                    {uiVolume}
                                </div>
                            </div>
                            <div className="a8a-b_template_right_04_02">
                                <div className="a8a-b_template_right_05_03">
                                    <div className="a8a-b_template_01_block_title_align_left">{"B/L No."}</div>
                                    {uiBLNo}
                                </div> <div className="a8a-b_template_right_05_03">
                                    <div className="a8a-b_template_01_block_title_align_left">{"ETA"}</div>
                                    {uiETA}
                                </div>
                                <div className="a8a-b_template_right_05_03">
                                    <div className="a8a-b_template_01_block_title_align_left">{"Arrial vessel"}</div>
                                    {uiArrivalVessel}
                                </div>
                                <div className="a8a-b_template_right_05_03">
                                    <div className="a8a-b_template_01_block_title_align_left">{"Type of cargo"}</div>
                                    {uiTypeOfCargo}
                                </div>
                                <div className="a8a-b_template_right_05_03">
                                    <div className="a8a-b_template_01_block_title_align_left">{"Container No."}</div>
                                    {uiContainerNo}
                                </div>
                                <div className="a8a-b_template_right_05_03">
                                    <div className="a8a-b_template_01_block_title_align_left">{"Seal No."}</div>
                                    {uiSealNo}
                                </div>
                            </div>
                        </div>
                        <div className="a8a-b_template_right_06_01">
                            <div className="a8a-b_template_01_block_title_align_left">
                                {"Location of goods"/* - Emplacement des marchandises"*/}
                            </div>
                            {uiLocationGoods}
                        </div>
                        <div className="a8a-b_template_right_06_02">
                            <div className="a8a-b_template_01_block_title_align_left">
                                {"Conveyance identification"/* - Identification du moyen de transport"*/}
                            </div>
                            {uiConveyanceID}
                        </div>
                    </div>
                </div>
                {/* <div className="a8a-b_template_right_07" /> */}
                <img className="a8a-b_template_right_07" alt=''
                    src={"https://res.cloudinary.com/jingtu-ego-test/image/upload/v1605037809/0-%E9%B2%B8%E5%9B%BEWhaleMap/Bottom_t0odfq.jpg"} />
            </div>
        );


        return (
            <div className="">
                {uiPdfA8AB}
            </div>
        );
    }
}
export default PDFA8AB;
