import React, { Component } from "react";
import axios from 'axios';
import moment from 'moment';

import Statistic from 'antd/es/statistic';
import Icon from 'antd/es/icon';
import Calendar from 'antd/es/calendar';
import Button from 'antd/es/button';
import DatePicker from 'antd/es/date-picker';
import message from 'antd/es/message';

// import { Statistic, Icon, Calendar, Button, message } from 'antd';
import '../../../styles/icon_image.css';
import '../../../styles/Background/ego_manager.css';
import '../../../styles/Background/store_manager.css';
import '../../../styles/Background/UI_Module/general_data_show.css';

const g_strMessageKey = 'updating';

const { MonthPicker } = DatePicker;

class GeneralDataShow extends Component {
    constructor(props) {
        super();
        this.state = {
            bLoading: true
        };

        this.dateDefault = moment();
        this.dateDefault = this.dateDefault.subtract(1, 'days').format('YYYY-MM-DD');
        this.dateDefault = moment(this.dateDefault);
        this.dateCurrent = new Date(this.dateDefault).toISOString().split('T')[0];

        // 管理后台统计数据
        this.objCalculateData = -1;  // 直接获取的统计数据
        this.arrDAU = [];  // 活跃用户
        this.arrDAV = [];  // 活跃访客
        this.arrActiveStores = [];  // 活跃商家
        this.arrNewUsers = [];  // 新注册用户
        this.arrNewStores = [];  // 新入驻商家
        this.arrPageVisit = [];  // 页面访问量

        this.iTotalVisit = 0;  // 总访问量
        this.iStartVisit = 0; // 启动页访问量
        this.iHomeVisit = 0;  // 首页访问量
        this.iCategoryVisit = 0;  // 分类页访问量
        this.iSearchVisit = 0;  // 搜索页访问量
        this.iLocationVisit = 0;  // 定位页访问量
        this.iStoreVisit = 0;  // 商家页访问量
        this.iItemVisit = 0;  // 商品页访问量
        this.iUserCenterVisit = 0;  // 用户中心访问量
        this.iLoginVisit = 0;  // 登录页访问量
        this.iRegisterVisit = 0;  // 注册页访问量

        this.iBudgetCartVisit = 0;  // 打开购物车次数
        this.iOrderDetailVisit = 0;  // 查看订单详情次数
        this.iPlaceOrderNum = 0;  // 下单量
        this.iPlaceOrderSucceedNum = 0;  // 下单成功量

        this.iTicketPublish = 0;
        this.iTicketTotalSold = 0;
        this.iTicketTotalUse = 0;
        this.iTicketDailySold = 0;
        this.iTicketDailyUse = 0;

        // 商家后台统计数据
        this.arrStoreDailyVisitData = [];  // 商家访问数据
        this.iStoreVisitorNum = 0;  // 某个商家来过的访客数量
        this.iStoreDailyTotalVisit = 0;  // 某个商家被访问次数
        this.iStoreStarredTime = 0;  // 商家被收藏/关注次数
        this.iItemAddedTime = 0;  // 商品被添加到购物车
        this.iTodayOrder = 0;  // 当日单量
        this.iMonthOrder = 0;  // 当月单量
        this.iTotalOrder = 0;  // 总单量
        this.iTodayExpense = 0;  // 当日支出
        this.iMonthExpense = 0;  // 当月支出
        this.iTotalExpense = 0;  // 总支出
        this.iTodayRevenue = 0;  // 当日收入
        this.iMonthRevenue = 0;  // 当月收入
        this.iTotalRevenue = 0;  // 总收入
        this.iTodayBenefit = 0;  // 当日利润
        this.iMonthBenefit = 0;  // 当月利润
        this.iTotalBenefit = 0;  // 总利润

        this.iMonthGST = 0; // 当月GST
        this.iTotalGST = 0; // 总GST

        this.iHotScore = 0;
    }

    // 日期选择器
    DateChange = (value) => {
        let dateTarget = new Date(value._d);
        this.dateCurrent = dateTarget.toISOString().split('T')[0];
    }
    // 调用数据统计
    PlatformDataCalculate = () => {
        this.objCalculateData = -1;
        message.loading({ content: '数据加载中……', key: g_strMessageKey });
        axios('/GetCalculateResults', { params: { date: this.dateCurrent } })
            .then(({ data }) => {
                if (data.length > 0) {
                    console.log("直接获取到了统计数据");
                    message.success({ content: "数据加载完成！", key: g_strMessageKey, duration: 2 });
                    this.objCalculateData = data[0];
                    this.iStartVisit = this.objCalculateData.pv_start;
                    this.iHomeVisit = this.objCalculateData.pv_home;
                    this.iCategoryVisit = this.objCalculateData.pv_category;
                    this.iSearchVisit = this.objCalculateData.pv_search;
                    this.iLocationVisit = this.objCalculateData.pv_location;
                    this.iStoreVisit = this.objCalculateData.pv_store;
                    this.iItemVisit = this.objCalculateData.pv_item;
                    this.iUserCenterVisit = this.objCalculateData.pv_user_center;
                    this.iLoginVisit = this.objCalculateData.pv_login;
                    this.iRegisterVisit = this.objCalculateData.pv_register;
                    this.iSideBarVisit = this.objCalculateData.pv_sidebar;
                    this.iBudgetCartVisit = this.objCalculateData.pv_cart;
                    this.iOrderDetailVisit = this.objCalculateData.pv_order;
                    this.iPlaceOrderNum = this.objCalculateData.cal_place_order;
                    this.iPlaceOrderSucceedNum = this.objCalculateData.cal_place_order_succeed;
                    if (this.iBudgetCartVisit == null)
                        this.iBudgetCartVisit = 0;
                    if (this.iOrderDetailVisit == null)
                        this.iOrderDetailVisit = 0;
                    if (this.iPlaceOrderNum == null)
                        this.iPlaceOrderNum = 0;
                    if (this.iPlaceOrderSucceedNum == null)
                        this.iPlaceOrderSucceedNum = 0;
                    this.iTotalVisit = this.iStartVisit + this.iHomeVisit + this.iCategoryVisit + this.iSearchVisit +
                        this.iLocationVisit + this.iStoreVisit + this.iItemVisit + this.iUserCenterVisit +
                        this.iLoginVisit + this.iRegisterVisit + this.iSideBarVisit + this.iBudgetCartVisit + this.iOrderDetailVisit;
                    this.setState({
                        ...this.state,
                        bLoading: false
                    });
                } else {
                    // 如果没有，则从data_monitor表中获取数据并进行存储
                    axios('/GetDailyGeneralData', { params: { date: this.dateCurrent } })
                        .then(({ data }) => {
                            console.log("数据库中无记载，需要现统计……");
                            this.arrDAU = data.active_users;
                            this.arrDAV = data.active_visitors;
                            this.arrNewUsers = data.new_users;
                            this.arrActiveStores = data.active_stores;
                            this.arrNewStores = data.new_stores;
                            this.objCalculateData = {
                                when_record: "", when_dt: "",
                                dav: 0, dau: 0, new_users: 0, das: 0, new_stores: 0,
                                pv_start: 0, pv_home: 0, pv_category: 0, pv_search: 0,
                                pv_location: 0, pv_store: 0, pv_item: 0, pv_user_center: 0,
                                pv_login: 0, pv_register: 0, pv_sidebar: 0, pv_cart: 0, pv_order: 0,
                                cal_place_order: 0, cal_place_order_succeed: 0
                            };
                            this.objCalculateData.dau = this.arrDAU.length;
                            this.objCalculateData.dav = this.arrDAV.length;
                            this.objCalculateData.new_users = this.arrNewUsers.length;
                            this.objCalculateData.das = this.arrActiveStores.length;
                            this.objCalculateData.new_stores = this.arrNewStores.length;
                            axios('/GetDailyVisitData', { params: { date: this.dateCurrent } })
                                .then(({ data }) => {
                                    this.arrPageVisit = data.page_visit;
                                    this.iSideBarVisit = data.sidebar_visit.length;
                                    this.iBudgetCartVisit = data.cart_visit.length;
                                    this.iOrderDetailVisit = data.order_visit.length;
                                    this.iPlaceOrderNum = data.place_order.length;
                                    this.iPlaceOrderSucceedNum = data.place_order_succeed.length;
                                    if (this.iSideBarVisit == null)
                                        this.iSideBarVisit = 0;
                                    if (this.iBudgetCartVisit == null)
                                        this.iBudgetCartVisit = 0;
                                    if (this.iOrderDetailVisit == null)
                                        this.iOrderDetailVisit = 0;
                                    if (this.iPlaceOrderNum == null)
                                        this.iPlaceOrderNum = 0;
                                    if (this.iPlaceOrderSucceedNum == null)
                                        this.iPlaceOrderSucceedNum = 0;
                                    this.VisitCalculation();
                                    message.success({
                                        content: "数据加载完成！",
                                        key: g_strMessageKey, duration: 2
                                    });
                                    this.objCalculateData.pv_start = this.iStartVisit;
                                    this.objCalculateData.pv_home = this.iHomeVisit;
                                    this.objCalculateData.pv_category = this.iCategoryVisit;
                                    this.objCalculateData.pv_search = this.iSearchVisit;
                                    this.objCalculateData.pv_location = this.iLocationVisit;
                                    this.objCalculateData.pv_store = this.iStoreVisit;
                                    this.objCalculateData.pv_item = this.iItemVisit;
                                    this.objCalculateData.pv_user_center = this.iUserCenterVisit;
                                    this.objCalculateData.pv_login = this.iLoginVisit;
                                    this.objCalculateData.pv_register = this.iRegisterVisit;
                                    this.objCalculateData.pv_sidebar = this.iSideBarVisit;
                                    this.objCalculateData.when_dt = this.dateCurrent;
                                    this.objCalculateData.pv_cart = this.iBudgetCartVisit;
                                    this.objCalculateData.pv_order = this.iOrderDetailVisit;
                                    this.objCalculateData.cal_place_order = this.iPlaceOrderNum;
                                    this.objCalculateData.cal_place_order_succeed = this.iPlaceOrderSucceedNum;
                                    // axios.post('/UpdateCalculateData',
                                    //     { objCalculateData: this.objCalculateData })
                                    //     .then(({ data }) => {
                                    //         console.log(data);
                                    //     });
                                    this.setState({
                                        ...this.state,
                                        bLoading: false
                                    });
                                });
                        });
                }
            });
    }
    // 总的单量/流水统计数据
    PlatformOrderRevenueCalculate = () => {
        axios('/GetAllStoreOrders')
            .then(({ data }) => {
                this.iTotalOrder = data.length;
                this.iTotalRevenue = 0;
                if (data.length > 0) {
                    for (let i = 0; i < data.length; i++) {
                        this.iTotalRevenue += data[i].total_price;
                    }
                    // this.iTotalRevenue /= 100;
                }
                axios('/GetDailyAllStoreOrders', { params: { day: this.dateCurrent } })
                    .then(({ data }) => {
                        this.iTodayOrder = data.length;
                        this.iTodayRevenue = 0;
                        if (data.length > 0) {
                            for (let i = 0; i < data.length; i++) {
                                this.iTodayRevenue += data[i].total_price;
                            }
                            // this.iTodayRevenue /= 100;
                        }
                        this.setState({
                            ...this.state,
                            bLoading: false
                        });
                    }).catch(function (error) { console.log(error); });
            }).catch(function (error) { console.log(error); });
    }
    // 优惠券统计数据
    TicketDataCalculate = () => {
        let iStoreID = -1;
        if (this.props.bStore)
            iStoreID = this.props.iStoreID;
        axios('/GetAllTicketUserRecord', { params: { store: iStoreID } })
            .then(({ data }) => {
                this.iTicketPublish = 0;
                this.iTicketTotalSold = 0;
                this.iTicketTotalUse = 0;
                if (this.props.bStore) {
                    for (let i = 0; i < data.length; i++) {
                        this.iTicketPublish += data[i].total_num;
                        this.iTicketTotalSold += data[i].sold_num;
                        this.iTicketTotalUse += data[i].use_num;
                    }
                } else {
                    this.iTicketTotalSold = data.length;
                    this.iTicketTotalUse = 0;
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].status === 1)
                            this.iTicketTotalUse++;
                    }
                }
                if (this.props.bStore) {
                    this.setState({
                        ...this.state,
                        bLoading: false
                    });
                } else {
                    axios('/GetDailyTicketUserRecord', { params: { day: this.dateCurrent, store: iStoreID } })
                        .then(({ data }) => {
                            this.iTicketDailySold = data.arrTicketSold.length;
                            this.iTicketDailyUse = data.arrTicketUse.length;
                            this.setState({
                                ...this.state,
                                bLoading: false
                            });
                        }).catch(function (error) { console.log(error); });
                }
            }).catch(function (error) { console.log(error); });
    }
    // 访问量计算
    VisitCalculation = () => {
        this.iTotalVisit = 0;
        this.iStartVisit = 0; this.iHomeVisit = 0; this.iCategoryVisit = 0;
        this.iSearchVisit = 0; this.iLocationVisit = 0; this.iStoreVisit = 0; this.iItemVisit = 0;
        this.iUserCenterVisit = 0; this.iLoginVisit = 0; this.iRegisterVisit = 0;
        for (let i = 0; i < this.arrPageVisit.length; i++) {

            // if(this.arrPageVisit[i].where_url.indexOf('home') == -1 &&
            //     this.arrPageVisit[i].where_url.indexOf('category') == -1 &&
            //     this.arrPageVisit[i].where_url.indexOf('search') == -1 &&
            //     this.arrPageVisit[i].where_url.indexOf('location') == -1 &&
            //     this.arrPageVisit[i].where_url.indexOf('store') == -1 &&
            //     this.arrPageVisit[i].where_url.indexOf('item') == -1 &&
            //     this.arrPageVisit[i].where_url.indexOf('user') == -1 &&
            //     this.arrPageVisit[i].where_url.indexOf('login') == -1 &&
            //     this.arrPageVisit[i].where_url.indexOf('register') == -1)
            if (this.arrPageVisit[i].where_url.split('/webapp')[1] === '/' || this.arrPageVisit[i].where_url.split('/webapp')[1].trim() === "") {
                this.iStartVisit += parseInt(this.arrPageVisit[i].visit, 10);
            }


            if (this.arrPageVisit[i].where_url.indexOf('/home') !== -1)
                this.iHomeVisit += parseInt(this.arrPageVisit[i].visit, 10);
            if (this.arrPageVisit[i].where_url.indexOf('/category') !== -1)
                this.iCategoryVisit += parseInt(this.arrPageVisit[i].visit, 10);

            if (this.arrPageVisit[i].where_url.indexOf('/search') !== -1)
                this.iSearchVisit += parseInt(this.arrPageVisit[i].visit, 10);
            if (this.arrPageVisit[i].where_url.indexOf('/location') !== -1)
                this.iLocationVisit += parseInt(this.arrPageVisit[i].visit, 10);
            if (this.arrPageVisit[i].where_url.indexOf('/store/') !== -1 &&
                this.arrPageVisit[i].where_url.indexOf('item') === -1)
                this.iStoreVisit += parseInt(this.arrPageVisit[i].visit, 10);
            if (this.arrPageVisit[i].where_url.indexOf('/item/') !== -1)
                this.iItemVisit += parseInt(this.arrPageVisit[i].visit, 10);

            if (this.arrPageVisit[i].where_url.indexOf('/user') !== -1)
                this.iUserCenterVisit += parseInt(this.arrPageVisit[i].visit, 10);
            if (this.arrPageVisit[i].where_url.indexOf('/login') !== -1)
                this.iLoginVisit += parseInt(this.arrPageVisit[i].visit, 10);
            if (this.arrPageVisit[i].where_url.indexOf('/register') !== -1)
                this.iRegisterVisit += parseInt(this.arrPageVisit[i].visit, 10);
        }
        this.iTotalVisit += this.iStartVisit + this.iHomeVisit + this.iCategoryVisit + this.iSearchVisit + this.iLocationVisit + this.iStoreVisit +
            this.iItemVisit + this.iUserCenterVisit + this.iLoginVisit + this.iRegisterVisit;
    }
    // 商家后台数据统计展示
    StoreDataCalculate = () => {
        message.loading({ content: '数据加载中……', key: g_strMessageKey });
        axios('/GetStoreCalculateResults', { params: { date: this.dateCurrent, store_id: this.props.iStoreID } })
            .then(({ data }) => {
                if (data.length > 0) {
                    console.log("直接获取到了商家统计数据");
                    message.success({ content: "数据加载完成！", key: g_strMessageKey, duration: 2 });
                    this.iStoreDailyTotalVisit = data[0].total_visit;
                    this.iStoreVisitorNum = data[0].visitor_num;
                    this.iStoreStarredTime = data[0].starred_time;
                    this.iItemAddedTime = data[0].item_in_cart_time;
                    this.setState({
                        ...this.state,
                        bLoading: false
                    });
                } else {
                    axios('/GetStoreDailyData',
                        { params: { date: this.dateCurrent, store_id: this.props.iStoreID } })
                        .then(({ data }) => {
                            console.log("GetStoreDailyData : ", data);
                            this.arrStoreDailyVisitData = data.store_visit;
                            this.iStoreVisitorNum = this.arrStoreDailyVisitData.length;
                            message.success({ content: "数据加载完成！", key: g_strMessageKey, duration: 2 });
                            this.iStoreDailyTotalVisit = 0;
                            for (let i = 0; i < data.store_visit.length; i++) {
                                this.iStoreDailyTotalVisit += parseInt(data.store_visit[i].visit, 10);
                            }
                            this.iHotScore = 0;
                            this.iHotScore += this.arrStoreDailyVisitData.length * 4;
                            this.iStoreStarredTime = data.store_starred.length;
                            this.iHotScore += this.iStoreStarredTime * 4;
                            this.iItemAddedTime = data.item_added.length;
                            this.iHotScore += this.iItemAddedTime * 2;
                            this.iHotScore += this.arrStoreDailyVisitData.length * 2;
                            this.objStoreCalculateData = {
                                when_record: "", when_dt: this.dateCurrent, store_id: this.props.iStoreID,
                                total_visit: this.iStoreDailyTotalVisit,
                                visitor_num: this.arrStoreDailyVisitData.length,
                                starred_time: this.iStoreStarredTime,
                                item_in_cart_time: this.iItemAddedTime,
                                place_order_time: 0,
                                total_revenue: 0
                            };
                            console.log("UpdateStoreCalculateData ... ", this.objStoreCalculateData);
                            axios.post('/UpdateStoreCalculateData',
                                { objStoreCalculateData: this.objStoreCalculateData })
                                .then(({ data }) => { console.log(data); });
                            this.setState({
                                ...this.state,
                                bLoading: false
                            });
                        });
                }
            });
    }
    // 商家单量/流水等统计
    StoreOrderRevenueCalculate = () => {
        axios('/GetStoreOrders', { params: { store_id: this.props.iStoreID } })
            .then(({ data }) => {
                this.iTotalOrder = data.length;
                this.iTotalRevenue = 0;
                if (data.length > 0) {
                    for (let i = 0; i < data.length; i++) {
                        this.iTotalRevenue += data[i].total_price - data[i].stripe_fee;
                    }
                    // this.iTotalRevenue /= 100;
                }
                axios('/GetDailyStoreOrders', { params: { store_id: this.props.iStoreID, day: this.dateCurrent } })
                    .then(({ data }) => {
                        this.iTodayOrder = data.length;
                        this.iTodayRevenue = 0;
                        if (data.length > 0) {
                            for (let i = 0; i < data.length; i++) {
                                this.iTodayRevenue += data[i].total_price - data[i].stripe_fee;
                            }
                            // this.iTodayRevenue /= 100;
                        }
                        this.setState({
                            ...this.state,
                            bLoading: false
                        });
                    }).catch(function (error) { console.log(error); });
            }).catch(function (error) { console.log(error); });
    }
    // 更新商家统计数据
    UpdateStoreData = () => {
        if (this.props.iStoreID === 10000 || this.props.iStoreID === 20000 || this.props.iStoreID === 30000) {
            this.UpdateSpecialStoreData();
            return;
        }
        this.StoreDataCalculate();
        this.StoreOrderRevenueCalculate();
        this.TicketDataCalculate();
    }
    // 更新平台统计数据
    UpdatePlatformData = () => {
        this.PlatformDataCalculate();
        this.PlatformOrderRevenueCalculate();
        this.TicketDataCalculate();
    }
    // 定制商家数据获取
    UpdateSpecialStoreData = () => {
        message.loading({ content: '数据加载中……', key: g_strMessageKey });
        axios('/GetSpecialStoreDailyData', { params: { date: this.dateCurrent, store_id: this.props.iStoreID } })
            .then(({ data }) => {

                console.log("GetSpecialStoreDailyData : ", data);

                this.iTodayOrder = data.daily.length;
                this.iTodayExpense = 0;
                this.iTodayRevenue = 0;
                this.iTodayBenefit = 0;
                for (let i = 0; i < data.daily.length; i++) {
                    if (isNaN(data.daily[i]).expense || data.daily[i].expense === 'NaN')
                        data.daily[i].expense = 0.0;
                    if (isNaN(data.daily[i]).revenue || data.daily[i].revenue === 'NaN')
                        data.daily[i].revenue = 0.0;
                    if (isNaN(data.daily[i]).benefit || data.daily[i].benefit === 'NaN')
                        data.daily[i].benefit = 0.0;
                    this.iTodayExpense += parseFloat(data.daily[i].expense);
                    this.iTodayRevenue += parseFloat(data.daily[i].revenue);
                    this.iTodayBenefit += parseFloat(data.daily[i].benefit);
                }
                this.iTodayExpense /= 100;
                this.iTodayExpense = this.iTodayExpense.toFixed(2);
                this.iTodayRevenue /= 100;
                this.iTodayRevenue = this.iTodayRevenue.toFixed(2);
                this.iTodayBenefit /= 100;
                this.iTodayBenefit = this.iTodayBenefit.toFixed(2);

                this.iMonthOrder = data.month.length;
                this.iMonthExpense = 0;
                this.iMonthRevenue = 0;
                this.iMonthBenefit = 0;
                this.iMonthGST = 0;
                for (let i = 0; i < data.month.length; i++) {
                    if (isNaN(data.month[i]).expense || data.month[i].expense === 'NaN')
                        data.month[i].expense = 0.0;
                    if (isNaN(data.month[i]).revenue || data.month[i].revenue === 'NaN')
                        data.month[i].revenue = 0.0;
                    if (isNaN(data.month[i]).benefit || data.month[i].benefit === 'NaN')
                        data.month[i].benefit = 0.0;
                    if (isNaN(data.month[i]).gst || data.month[i].gst === 'NaN')
                        data.month[i].gst = 0;
                    this.iMonthExpense += parseFloat(data.month[i].expense);
                    this.iMonthRevenue += parseFloat(data.month[i].revenue);
                    this.iMonthBenefit += parseFloat(data.month[i].benefit);
                    this.iMonthGST += parseFloat(data.month[i].gst);
                }
                this.iMonthExpense /= 100;
                this.iMonthExpense = this.iMonthExpense.toFixed(2);
                this.iMonthRevenue /= 100;
                this.iMonthRevenue = this.iMonthRevenue.toFixed(2);
                this.iMonthBenefit /= 100;
                this.iMonthBenefit = this.iMonthBenefit.toFixed(2);
                this.iMonthGST /= 100;
                this.iMonthGST = this.iMonthGST.toFixed(2);

                this.iTotalOrder = data.total.length;
                this.iTotalExpense = 0;
                this.iTotalRevenue = 0;
                this.iTotalBenefit = 0;
                this.iTotalGST = 0;
                for (let i = 0; i < data.total.length; i++) {
                    if (isNaN(data.total[i]).expense || data.total[i].expense === 'NaN')
                        data.total[i].expense = 0;
                    if (isNaN(data.total[i]).revenue || data.total[i].revenue === 'NaN')
                        data.total[i].revenue = 0;
                    if (isNaN(data.total[i]).benefit || data.total[i].benefit === 'NaN')
                        data.total[i].benefit = 0;
                    if (isNaN(data.total[i]).gst || data.total[i].gst === 'NaN')
                        data.total[i].gst = 0;
                    this.iTotalExpense += parseFloat(data.total[i].expense);
                    this.iTotalRevenue += parseFloat(data.total[i].revenue);
                    this.iTotalBenefit += parseFloat(data.total[i].benefit);
                    this.iTotalGST += parseFloat(data.total[i].gst);
                }
                this.iTotalExpense /= 100;
                this.iTotalExpense = this.iTotalExpense.toFixed(2);
                this.iTotalRevenue /= 100;
                this.iTotalRevenue = this.iTotalRevenue.toFixed(2);
                this.iTotalBenefit /= 100;
                this.iTotalBenefit = this.iTotalBenefit.toFixed(2);
                this.iTotalGST /= 100;
                this.iTotalGST = this.iTotalGST.toFixed(2);

                message.success({ content: "数据加载完成！", key: g_strMessageKey, duration: 2 });
                this.setState({
                    ...this.state,
                    bLoading: false
                });
            });
    }
    // 月份变化
    MonthPickerChange = (date, dateString) => {
        this.dateCurrent = dateString + "-01";
        this.UpdateSpecialStoreData();
    }

    // 卸载异步操作设置状态
    componentWillUnmount() {
        clearTimeout(this.timeouter)
        this.setState = (state, callback) => {
            return;
        }
    }
    // 渲染完毕……
    componentDidMount() {
        if (this.props.bStore) {
            this.UpdateStoreData();
        } else {
            this.UpdatePlatformData();
        }
    }

    render() {
        // 管理后台数据展示
        let iDAU = this.arrDAU.length;
        let iDAV = this.arrDAV.length;
        let iActiveStores = this.arrActiveStores.length;
        let iNewUsers = this.arrNewUsers.length;
        let iNewStores = this.arrNewStores.length;
        if (this.objCalculateData !== -1) {
            iDAU = this.objCalculateData.dau;
            iDAV = this.objCalculateData.dav;
            iActiveStores = this.objCalculateData.das;
            iNewUsers = this.objCalculateData.new_users;
            iNewStores = this.objCalculateData.s;
        }
        let uiEgoData = (
            <div className="general_data_show">
                <div className="general_data_site-calendar-demo-card">
                    <Calendar fullscreen={false} defaultValue={this.dateDefault}
                        onChange={this.DateChange} />
                    <Button className="general_data_submit_button" type="primary"
                        onClick={this.UpdatePlatformData}>刷新数据</Button>
                </div>
                <div className="general_data_show_title">用户量</div>
                <div className="general_data_show_row">
                    <div className="general_data_show_cell">
                        <Statistic title="DAU (用户+访客)"
                            value={(iDAU + iDAV).toString() + " (" + iDAU + "+" + iDAV + ")"}
                            prefix={<Icon type="smile" theme="twoTone" />} />
                    </div>
                    <div className="general_data_show_cell">
                        <Statistic title="新注册用户" value={iNewUsers}
                            prefix={<Icon type="smile" theme="twoTone" />} />
                    </div>
                    <div className="general_data_show_cell">
                        <Statistic title="活跃商家" value={iActiveStores} prefix={<Icon type="shop" theme="twoTone" />} />
                    </div>
                    <div className="general_data_show_cell">
                        <Statistic title="新入住商家" value={iNewStores} prefix={<Icon type="shop" theme="twoTone" />} />
                    </div>
                </div>
                <div className="general_data_show_title">访问量</div>
                <div className="general_data_show_row">
                    <div className="general_data_show_cell general_data_show_cell_red">
                        <Statistic title="总访问量" value={this.iTotalVisit} suffix="次"
                            prefix={<Icon type="fund" theme="twoTone" />} />
                    </div>
                    <div className="general_data_show_cell">
                        <Statistic title="启动页访问量" value={this.iStartVisit} suffix="次"
                            prefix={<Icon type="fund" theme="twoTone" />} />
                    </div>
                    <div className="general_data_show_cell">
                        <Statistic title="首页访问量" value={this.iHomeVisit} suffix="次"
                            prefix={<Icon type="fund" theme="twoTone" />} />
                    </div>
                    <div className="general_data_show_cell">
                        <Statistic title="分类页访问量" value={this.iCategoryVisit} suffix="次"
                            prefix={<Icon type="fund" theme="twoTone" />} />
                    </div>
                </div>
                <div className="general_data_show_row">
                    <div className="general_data_show_cell">
                        <Statistic title="搜索页问量" value={this.iSearchVisit} suffix="次"
                            prefix={<Icon type="fund" theme="twoTone" />} />
                    </div>
                    <div className="general_data_show_cell">
                        <Statistic title="定位页访问量" value={this.iLocationVisit} suffix="次"
                            prefix={<Icon type="fund" theme="twoTone" />} />
                    </div>
                    <div className="general_data_show_cell">
                        <Statistic title="商家页访问量" value={this.iStoreVisit} suffix="次"
                            prefix={<Icon type="fund" theme="twoTone" />} />
                    </div>
                    <div className="general_data_show_cell">
                        <Statistic title="商品页访问量" value={this.iItemVisit} suffix="次"
                            prefix={<Icon type="fund" theme="twoTone" />} />
                    </div>
                </div>
                <div className="general_data_show_row">
                    <div className="general_data_show_cell">
                        <Statistic title="用户中心访问量" value={this.iUserCenterVisit} suffix="次"
                            prefix={<Icon type="fund" theme="twoTone" />} />
                    </div>
                    <div className="general_data_show_cell">
                        <Statistic title="登录页访问量" value={this.iLoginVisit} suffix="次"
                            prefix={<Icon type="fund" theme="twoTone" />} />
                    </div>
                    <div className="general_data_show_cell">
                        <Statistic title="注册页访问量" value={this.iRegisterVisit} suffix="次"
                            prefix={<Icon type="fund" theme="twoTone" />} />
                    </div>
                    <div className="general_data_show_cell general_data_show_cell_green">
                        <Statistic title="打开侧边栏" value={this.iSideBarVisit} suffix="次"
                            prefix={<Icon type="fund" theme="twoTone" />} />
                    </div>
                </div>
                <div className="general_data_show_row">
                    <div className="general_data_show_cell general_data_show_cell_green">
                        <Statistic title="打开购物车" value={this.iBudgetCartVisit} suffix="次"
                            prefix={<Icon type="fund" theme="twoTone" />} />
                    </div>
                    <div className="general_data_show_cell general_data_show_cell_green">
                        <Statistic title="查看订单详情" value={this.iOrderDetailVisit} suffix="次"
                            prefix={<Icon type="fund" theme="twoTone" />} />
                    </div>
                    <div className="general_data_show_cell general_data_show_cell_green">
                        <Statistic title="下单次数" value={this.iPlaceOrderNum} suffix="次"
                            prefix={<Icon type="fund" theme="twoTone" />} />
                    </div>
                    <div className="general_data_show_cell general_data_show_cell_green">
                        <Statistic title="下单成功" value={this.iPlaceOrderSucceedNum} suffix="次"
                            prefix={<Icon type="fund" theme="twoTone" />} />
                    </div>
                </div>
                <div className="general_data_show_title">收益</div>
                <div className="general_data_show_row">
                    <div className="general_data_show_cell">
                        <Statistic title="当日单量" value={this.iTodayOrder} suffix="次"
                            prefix={<Icon type="fund" theme="twoTone" />} />
                    </div>
                    <div className="general_data_show_cell">
                        <Statistic title="总下单量" value={this.iTotalOrder} suffix="次"
                            prefix={<Icon type="fund" theme="twoTone" />} />
                    </div>
                    <div className="general_data_show_cell">
                        <Statistic title="当日流水 (CAD)" value={this.iTodayRevenue} precision={2}
                            prefix={<Icon type="dollar" theme="twoTone" />} />
                    </div>
                    <div className="general_data_show_cell">
                        <Statistic title="总流水 (CAD)" value={this.iTotalRevenue} precision={2}
                            prefix={<Icon type="dollar" theme="twoTone" />} />
                    </div>
                </div>
                <div className="general_data_show_title">优惠券</div>
                <div className="general_data_show_row">
                    <div className="general_data_show_cell">
                        <Statistic title="当日领取" value={this.iTicketDailySold} suffix="次"
                            prefix={<Icon type="fund" theme="twoTone" />} />
                    </div>
                    <div className="general_data_show_cell">
                        <Statistic title="总领取量" value={this.iTicketTotalSold} suffix="次"
                            prefix={<Icon type="fund" theme="twoTone" />} />
                    </div>
                    <div className="general_data_show_cell">
                        <Statistic title="当日使用" value={this.iTicketDailyUse} suffix="次"
                            prefix={<Icon type="dollar" theme="twoTone" />} />
                    </div>
                    <div className="general_data_show_cell">
                        <Statistic title="总使用量" value={this.iTicketTotalUse} suffix="次"
                            prefix={<Icon type="dollar" theme="twoTone" />} />
                    </div>
                </div>
            </div>
        );
        // 商家管理后台数据展示
        let uiStoreData = (
            <div className="general_data_show">
                <div className="general_data_site-calendar-demo-card">
                    <Calendar fullscreen={false} defaultValue={this.dateDefault}
                        onChange={this.DateChange} />
                    <Button className="general_data_submit_button" type="primary"
                        onClick={this.UpdateStoreData}>刷新数据</Button>
                </div>
                <div className="general_data_show_title">访问 / 收藏量</div>
                <div className="general_data_show_row">
                    <div className="general_data_show_cell">
                        <Statistic title="总访问量" value={this.iStoreDailyTotalVisit}
                            suffix="次" prefix={<Icon type="area-chart" />} />
                    </div>
                    <div className="general_data_show_cell">
                        <Statistic title="访客数" value={this.iStoreVisitorNum}
                            suffix="人" prefix={<Icon type="user" />} />
                    </div>
                    <div className="general_data_show_cell">
                        <Statistic title="被收藏" value={this.iStoreStarredTime}
                            suffix="次" prefix={<Icon type="star" />} />
                    </div>
                    <div className="general_data_show_cell">
                        <Statistic title="商品被放到购物车" value={this.iItemAddedTime}
                            suffix="次" prefix={<Icon type="shopping-cart" />} />
                    </div>
                </div>
                <div className="general_data_show_title">单量 / 营业额</div>
                <div className="general_data_show_row">
                    <div className="general_data_show_cell">
                        <Statistic title="当日单量" value={this.iTodayOrder} prefix={<Icon type="form" />} />
                    </div>
                    <div className="general_data_show_cell">
                        <Statistic title="总下单量" value={this.iTotalOrder} prefix={<Icon type="form" />} />
                    </div>
                    <div className="general_data_show_cell">
                        <Statistic title="当日营业额(CAD)" value={this.iTodayRevenue} precision={2} prefix={<Icon type="dollar" />} />
                    </div>
                    <div className="general_data_show_cell">
                        <Statistic title="总营业额(CAD)" value={this.iTotalRevenue} precision={2} prefix={<Icon type="dollar" />} />
                    </div>
                </div>
                <div className="general_data_show_title">优惠券</div>
                <div className="general_data_show_row">
                    <div className="general_data_show_cell">
                        <Statistic title="总发行量" value={this.iTicketPublish} suffix="次"
                            prefix={<Icon type="fund" theme="twoTone" />} />
                    </div>
                    {/* <div className="general_data_show_cell">
                        <Statistic title="当日领取" value={this.iTicketDailySold} suffix="次"
                            prefix={<Icon type="fund" theme="twoTone" />} />
                    </div> */}
                    <div className="general_data_show_cell">
                        <Statistic title="总领取量" value={this.iTicketTotalSold} suffix="次"
                            prefix={<Icon type="fund" theme="twoTone" />} />
                    </div>
                    {/* <div className="general_data_show_cell">
                        <Statistic title="当日使用" value={this.iTicketDailyUse} suffix="次"
                            prefix={<Icon type="dollar" theme="twoTone" />} />
                    </div> */}
                    <div className="general_data_show_cell">
                        <Statistic title="总使用量" value={this.iTicketTotalUse} suffix="次"
                            prefix={<Icon type="dollar" theme="twoTone" />} />
                    </div>
                </div>
            </div>
        );
        let uiTitle = null;
        // 特殊商家管理后台数据展示
        if (this.props.iStoreID === 10000 || this.props.iStoreID === 20000) {
            uiTitle = (
                <div className="store_contents_title">
                    <MonthPicker placeholder="选择月份" picker="month" onChange={this.MonthPickerChange} defaultValue={this.dateDefault} />
                    {/* <Button type="primary" className="general_data_refresh_button"
                        onClick={this.UpdateStoreData}>刷新数据</Button> */}
                </div>
            );
            uiStoreData = (
                <div className="general_data_show general_data_show_padding_top">
                    <div className="general_data_show_title">单量</div>
                    <div className="general_data_show_row">
                        {/* <div className="general_data_show_cell">
                            <Statistic title="当日单量" value={this.iTodayOrder} prefix={<Icon type="form" />} />
                        </div> */}
                        <div className="general_data_show_cell">
                            <Statistic title="当月单量" value={this.iMonthOrder} prefix={<Icon type="form" />} />
                        </div>
                        <div className="general_data_show_cell">
                            <Statistic title="总单量" value={this.iTotalOrder} prefix={<Icon type="form" />} />
                        </div>
                    </div>
                    <div className="general_data_show_title">GST</div>
                    <div className="general_data_show_row">
                        <div className="general_data_show_cell">
                            <Statistic title="当月GST" value={this.iMonthGST} prefix={<Icon type="form" />} />
                        </div>
                        <div className="general_data_show_cell">
                            <Statistic title="总GST" value={this.iTotalGST} prefix={<Icon type="form" />} />
                        </div>
                    </div>
                    <div className="general_data_show_title">营业额</div>
                    <div className="general_data_show_row">
                        {/* <div className="general_data_show_cell">
                            <Statistic title="当日支出(CAD)" value={this.iTodayExpense} precision={2} prefix={<Icon type="dollar" />} />
                        </div> */}
                        {/* <div className="general_data_show_cell">
                            <Statistic title="当日收入(CAD)" value={this.iTodayRevenue} precision={2} prefix={<Icon type="dollar" />} />
                        </div> */}
                        {/* <div className="general_data_show_cell">
                            <Statistic title="当日利润(CAD)" value={this.iTodayBenefit} precision={2} prefix={<Icon type="dollar" />} />
                        </div> */}
                        <div className="general_data_show_cell">
                            <Statistic title="当月支出(CAD)" value={this.iMonthExpense} precision={2} prefix={<Icon type="dollar" />} />
                        </div>
                        <div className="general_data_show_cell">
                            <Statistic title="当月收入(CAD)" value={this.iMonthRevenue} precision={2} prefix={<Icon type="dollar" />} />
                        </div>
                        <div className="general_data_show_cell">
                            <Statistic title="当月利润(CAD)" value={this.iMonthBenefit} precision={2} prefix={<Icon type="dollar" />} />
                        </div>
                    </div>
                    <div className="general_data_show_row">
                        <div className="general_data_show_cell">
                            <Statistic title="总收入(CAD)" value={this.iTotalRevenue} precision={2} prefix={<Icon type="dollar" />} />
                        </div>
                        <div className="general_data_show_cell">
                            <Statistic title="总支出(CAD)" value={this.iTotalExpense} precision={2} prefix={<Icon type="dollar" />} />
                        </div>
                        <div className="general_data_show_cell">
                            <Statistic title="总利润(CAD)" value={this.iTotalBenefit} precision={2} prefix={<Icon type="dollar" />} />
                        </div>
                    </div>
                </div>
            );


            // M站布局
            if (this.props.bMStation) {
                uiStoreData = (
                    <div className="general_data_show_m">
                        {/* <div className="general_data_site-calendar-demo-card">
                            <Calendar fullscreen={false} defaultValue={this.dateDefault}
                                onChange={this.DateChange} />
                            <Button className="general_data_submit_button" type="primary"
                                onClick={this.UpdateStoreData}>刷新数据</Button>
                        </div>
                        <div className="general_data_show_title">单量</div>
                        <div className="general_data_show_row">
                            <div className="general_data_show_cell">
                                <Statistic title="当日单量" value={this.iTodayOrder} prefix={<Icon type="form" />} />
                            </div>
                            <div className="general_data_show_cell">
                                <Statistic title="当月单量" value={this.iMonthOrder} prefix={<Icon type="form" />} />
                            </div>
                            <div className="general_data_show_cell">
                                <Statistic title="总单量" value={this.iTotalOrder} prefix={<Icon type="form" />} />
                            </div>
                        </div>
                        <div className="general_data_show_title">营业额</div>
                        <div className="general_data_show_row">
                            <div className="general_data_show_cell">
                                <Statistic title="当日支出(CAD)" value={this.iTodayExpense} precision={2} prefix={<Icon type="dollar" />} />
                            </div>
                            <div className="general_data_show_cell">
                                <Statistic title="当月支出(CAD)" value={this.iMonthExpense} precision={2} prefix={<Icon type="dollar" />} />
                            </div>
                            <div className="general_data_show_cell">
                                <Statistic title="总支出(CAD)" value={this.iTotalExpense} precision={2} prefix={<Icon type="dollar" />} />
                            </div>
                        </div>
                        <div className="general_data_show_row">
                            <div className="general_data_show_cell">
                                <Statistic title="当日收入(CAD)" value={this.iTodayRevenue} precision={2} prefix={<Icon type="dollar" />} />
                            </div>
                            <div className="general_data_show_cell">
                                <Statistic title="当月收入(CAD)" value={this.iMonthRevenue} precision={2} prefix={<Icon type="dollar" />} />
                            </div>
                            <div className="general_data_show_cell">
                                <Statistic title="总收入(CAD)" value={this.iTotalRevenue} precision={2} prefix={<Icon type="dollar" />} />
                            </div>
                        </div>
                        <div className="general_data_show_row">
                            <div className="general_data_show_cell">
                                <Statistic title="当日利润(CAD)" value={this.iTodayBenefit} precision={2} prefix={<Icon type="dollar" />} />
                            </div>
                            <div className="general_data_show_cell">
                                <Statistic title="当月利润(CAD)" value={this.iMonthBenefit} precision={2} prefix={<Icon type="dollar" />} />
                            </div>
                            <div className="general_data_show_cell">
                                <Statistic title="总利润(CAD)" value={this.iTotalBenefit} precision={2} prefix={<Icon type="dollar" />} />
                            </div>
                        </div> */}
                    </div>
                );
            }

        }

        let uiContents;
        let strAreaStyle;
        if (this.props.bStore) {
            uiContents = uiStoreData;
            strAreaStyle = "store_manage_contents store_manage_top_no_space"
            if (this.props.bMStation) {
                strAreaStyle = "store_manage_contents_m"
            }
        } else {
            uiContents = uiEgoData;
            strAreaStyle = "manage_contents manage_contents_less_top"
        }

        return (
            <div className={strAreaStyle}>
                {uiTitle}
                {uiContents}
            </div >
        );
    }
}
export default GeneralDataShow;
