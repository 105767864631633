import React, { Component } from "react";
import axios from 'axios';
import QrReader from 'react-qr-reader';

import Spin from 'antd/es/spin';
import Progress from 'antd/es/progress';
import message from 'antd/es/message';

import "../../../styles/Background/UI_Module/ticket_check.css"

class TicketCheck extends Component {
    constructor(props) {
        super();
        this.state = {
            bLoading: true,
            bChcked: false,
            result: ''
        };

        this.objUserInfo = {};
        this.objTicketInfo = {};
        this.arrTicketType = ["折扣券", "代金券", "兑换券"];
    }

    handleScan = data => {
        if (data) {
            if (data.indexOf('store-player/scan/?store=') >= 0) {
                let iTargetStore = parseInt(data.split('?')[1].split('store=')[1], 10);
                if (this.props.iMyStore === iTargetStore) {
                    message.success("确认过眼神，是本店的券！");
                    window.location.href = data;
                } else {
                    if (data !== this.state.result)
                        message.warning("确认过眼神，不是本店的券！" + iTargetStore.toString());
                }
            } else {
                message.warning("该二维码和与本店优惠券无关，请重新确认！");
            }

            this.setState({
                ...this.state,
                result: data
            })
        }
    }

    handleError = err => {
        console.error(err)
    }

    TicketStatusCheck = () => {
        axios('/GetTicketUserInfo', { params: { ticket_id: this.props.iTicket, user_id: this.props.iUser } })
            .then(({ data }) => {
                if (data[0].status === 1) {
                    message.info("该卡券已经被核销过！");
                    this.setState({
                        ...this.state,
                        bChcked: true
                    });
                }
                this.GetUserInfo();
            }).catch(function (error) { console.log(error); });
    }

    GetUserInfo = () => {
        axios('/GetUserInfo', { params: { user_id: this.props.iUser } })
            .then(({ data }) => {
                this.objUserInfo = data[0];
                this.GetTicketInfo();
            }).catch(function (error) { console.log(error); });
    }

    GetTicketInfo = () => {
        axios('/GetTicketInfo', { params: { ticket_id: this.props.iTicket } })
            .then(({ data }) => {
                this.objTicketInfo = data[0];
                this.setState({
                    ...this.state,
                    bLoading: false
                });
            }).catch(function (error) { console.log(error); });
    }

    TicketUseConfirm = () => {
        if (this.state.bChcked)
            return;
        message.info("核销中……");
        axios.post('/TicketUseRecord', { ticket_id: this.props.iTicket, user_id: this.props.iUser })
            .then(({ data }) => {
                message.success("卡券已核销！");
                this.setState({
                    ...this.state,
                    bChcked: true
                });
            }).catch(function (error) { console.log(error); });
    }

    componentDidMount() {

        if (this.props.iUser > 0 && this.props.iTicket > 0 && this.props.iTargetStore > 0)
            this.TicketStatusCheck();
        console.log("Ticket Check : ticket = " + this.props.iTicket + ", user = " + this.props.iUser + ", my store = " + this.props.iMyStore + ", target store = " + this.props.iTargetStore);
    }

    render() {

        let uiCenterArea = (
            <div className="ticket_check_loading">
                <Spin size="large" />
            </div>
        );

        if (!this.state.bLoading) {
            let strTitle = this.objTicketInfo.title;
            if (strTitle.indexOf('_') !== -1)
                strTitle = strTitle.split('_')[1];
            let dateExpire = this.objTicketInfo.expire_date;
            if (dateExpire === null)
                dateExpire = "";
            else
                dateExpire = dateExpire.split('T')[0] + " 到期";
            let fSoldPersentage = (this.objTicketInfo.sold_num * 100) / this.objTicketInfo.total_num;
            if (fSoldPersentage <= 10.0)
                fSoldPersentage = 10.0;
            fSoldPersentage = parseInt(fSoldPersentage, 10);
            let strStasus = "待核销";
            if (this.state.bChcked)
                strStasus = "已核销";
            let uiTicket = (
                <div className="ticket_bgp ticket_bgp_image" >
                    <div className="ticket_store_info">
                        <div className="ticket_store_name">{this.objTicketInfo.store_name}</div>
                        <div className="ticket_type">{this.arrTicketType[this.objTicketInfo.type]}</div>
                    </div>
                    <div className="ticket_details_area">
                        <img className="ticket_item_icon" src={this.objTicketInfo.store_logo} alt='' />
                        <div className="ticket_title_area" >
                            <div className="ticket_title">{strTitle}</div>
                            <div className="ticket_subtitle">{this.objTicketInfo.subtitle}</div>
                            <div className="ticket_expire_date">{dateExpire}</div>
                        </div>
                        <div className="ticket_type_get_area">
                            <div className="ticket_get_btn_used">{strStasus}</div>
                            <div className="ticket_get_info">已被领取</div>
                            <div className="ticket_get_info">
                                <Progress percent={fSoldPersentage} size="small" />
                            </div>
                        </div>
                    </div>
                </div>
            );

            let strButton = "确 认";
            let strConfirmButtonStyle = "ticket_check_confirm_button";
            if (this.state.bChcked) {
                strButton = "已核销";
                strConfirmButtonStyle = "ticket_check_confirm_button_disable";
            }

            uiCenterArea = (
                <div>
                    <div className="ticket_check_title">
                        该用户希望在您的店铺使用优惠券，请确认：
                    </div>
                    <div className="ticket_check_user_info_area">
                        <img className="ticket_check_user_avatar" src={this.objUserInfo.avatar} alt='' />
                        <div className="ticket_check_user_name" >{this.objUserInfo.name}</div>
                    </div>
                    <div className="ticket_check_ticket_info_area">
                        {uiTicket}
                    </div>
                    <div className={strConfirmButtonStyle} onClick={this.TicketUseConfirm}>
                        {strButton}
                    </div>
                </div>
            );
        }

        if (this.props.iTicket === -1 || this.props.iUser === -1 || this.props.iTargetStore === -1) {
            uiCenterArea = (
                <div className="ticket_check_scan_page">
                    <div className="ticket_check_scan_tip">请对准用户提供的二维码</div>
                    <div className="ticket_check_scan_area">
                        <QrReader delay={300} onError={this.handleError} onScan={this.handleScan}
                            style={{ width: '100%' }} />
                    </div>
                    <div className="ticket_check_scan_result">{this.state.result}</div>
                </div>
            );
        }

        return (
            <div className="ticket_check_page bgc_brown_super_light">
                {uiCenterArea}
            </div>
        );
    }
}
export default TicketCheck;
