import React, { Component } from "react";
import axios from 'axios';
// import cookie from 'react-cookies';
// import QrReader from 'react-qr-reader';
import BarcodeScannerComponent from "react-webcam-barcode-scanner";
// import Camera from 'react-camera';
import Webcam from 'react-webcam';

import Modal from 'antd/es/modal';
import Input from 'antd/es/input';
import Icon from 'antd/es/icon';
import Menu from 'antd/es/menu';
import Dropdown from 'antd/es/dropdown';
import Button from 'antd/es/button';
// import Radio from 'antd/es/radio';
import message from 'antd/es/message';

import "../../../styles/Background/UI_Module/package_scanner.css";

const g_strMessageKey = 'updating';

class PackageScanner extends Component {
    constructor(props) {
        super();
        this.state = {
            iScanMethod: 0,
            strQRCodeResult: 'No Result',
            strBarCodeResult: 'No Result',
            strOgeScanResult: 'No Result',
            bScanResultDlg: false,
            iExpressStatus: 0,
            bSubmitResultDlg: false,
            iSubmitResult: 0,
            bItemInfoChange: false,
            iShipDate: 0,
            iItemType: 0,
            photo: null,
            iScanPurpose: 0,
            iShipMethod: 0,
            iEditExpresssInfo: -1,
            bAutoFill: false
        };
        this.audio = new Audio('https://res.cloudinary.com/jingtu-ego-test/video/upload/v1602208394/0-%E9%B2%B8%E5%9B%BEWhaleMap/Scan_zm6ajd.mp3');
        this.audio.load();
        this.bScanResultDlg = false;
        // 当前的快递进仓信息
        this.objExpressEnter = { receiver: "", express_info: { item_info: [] } };
        this.strCurrentEnterNo = "";
        this.strCurrentReceiver = "";
        this.strCurrentStorageCode = "";
        this.strCurrentPackageCode = "";
        // 当前的合箱信息
        this.objPackageCombine = {};

        // 货物信息
        this.fItemWeight = '';
        this.fItemLength = '';
        this.fItemWidth = '';
        this.fItemHeight = '';
        this.fItemValueWeight = '';
        this.fItemPriceWeight = '';
        this.arrShipDate = ["月中(15号)", "月底(30号)"];
        this.arrShipDateShot = ["15", "30"];
        this.arrItemType = ["普货", "食品", "敏感"];
        this.arrItemTypeShort = ["G", "F", "S"];
        this.strCurrentMonth = new Date().getMonth() + 1;
        this.strCurrentMonth = this.strCurrentMonth.toString();
        if (parseInt(this.strCurrentMonth, 10) >= 10)
            console.log("current month = " + this.strCurrentMonth);
        else {
            if (this.strCurrentMonth.toString().indexOf('0') < 0) {
                this.strCurrentMonth = '0' + this.strCurrentMonth;
            }
        }

        this.refs = undefined;
    }

    // 切换扫描方式
    SwitchScanMethod = index => {
        this.setState({
            ...this.state,
            iScanMethod: index
        });
    };
    // 二维码扫描（扫码枪扫描结果）
    QRCodeScanUpdate = result => {
        let strScanInput = this.refs.order_input.state.value;
        console.log("QRCodeScanUpdate : ", result, strScanInput);
        if (result) {
            let strResult = JSON.stringify(result);
            strResult = strResult.split("'k5':")[1].split(",'k6'")[0].split("'")[1];
            this.setState({
                ...this.state,
                bScanResultDlg: true,
                strQRCodeResult: strResult,
                strOgeScanResult: result
            });
            this.audio.play();
            this.bScanResultDlg = true;
            // 根据扫描到的单号进行搜索
            message.loading({ content: '数据扫描中……', key: g_strMessageKey });
            axios('/GetExpressEnter', { params: { express_order: strResult, store_id: this.props.objStoreInfo.id } })
                .then(({ data }) => {
                    let iExpressStatus = 0;
                    if (data.length <= 0) {
                        this.objExpressEnter = { receiver: "", express_info: { item_info: [] } };
                        iExpressStatus = 1;  // 数据库中无记录，当前步骤为签收入仓
                    } else {
                        iExpressStatus = 2;
                        this.objExpressEnter = data[0];
                        if (this.objExpressEnter.express_info !== null)
                            this.objExpressEnter.express_info = JSON.parse(this.objExpressEnter.express_info);
                        else {
                            this.objExpressEnter.express_info = {
                                item_info: []
                            }
                        }
                        if (this.objExpressEnter.status === 1) {
                            iExpressStatus = 3;
                            if (this.objExpressEnter.express_info !== null) {
                                if (this.objExpressEnter.express_info.item_info) {
                                    if (this.objExpressEnter.express_info.item_info.length > 0 && this.objExpressEnter.price_weight !== null && this.objExpressEnter.price_weight !== "")
                                        iExpressStatus = 4;
                                }
                            }
                        }
                        if (this.objExpressEnter.status === 2) {
                            iExpressStatus = 5;
                        }
                        if (this.objExpressEnter.status === 3) {
                            iExpressStatus = 6;
                        }
                        this.strCurrentEnterNo = this.objExpressEnter.enter_no;
                        this.strCurrentReceiver = this.objExpressEnter.receiver;
                        this.strCurrentStorageCode = this.objExpressEnter.storage_code;
                    }
                    message.success({ content: "数据扫描完成！", key: g_strMessageKey, duration: 2 });
                    this.setState({
                        ...this.state,
                        iExpressStatus: iExpressStatus
                    });
                }).catch(function (error) { console.log(error); });
        }
        // 扫码枪输入
        else {
            console.log("扫码枪扫描结果 : ", strScanInput);
            if (strScanInput !== null && strScanInput !== undefined && strScanInput !== "") {
                let strResult = strScanInput;
                this.setState({
                    ...this.state,
                    bScanResultDlg: true,
                    strQRCodeResult: strResult,
                    strOgeScanResult: result
                });
                this.audio.play();
                this.bScanResultDlg = true;
                // 根据扫描到的单号进行搜索
                message.loading({ content: '数据扫描中……', key: g_strMessageKey });
                axios('/GetExpressEnter', { params: { express_order: strResult, store_id: this.props.objStoreInfo.id } })
                    .then(({ data }) => {
                        let iExpressStatus = 0;
                        if (data.length <= 0) {
                            console.log("还未入库，当前为签收状态");
                            this.objExpressEnter = { receiver: "", express_info: { item_info: [] } };
                            iExpressStatus = 1;  // 数据库中无记录，当前步骤为签收入仓
                        } else {
                            console.log("已入库，当前为编辑物品信息状态");
                            iExpressStatus = 2;
                            this.objExpressEnter = data[0];
                            if (this.objExpressEnter.express_info !== null)
                                this.objExpressEnter.express_info = JSON.parse(this.objExpressEnter.express_info);
                            else {
                                this.objExpressEnter.express_info = {
                                    item_info: []
                                }
                            }
                            if (this.objExpressEnter.status === 1) {
                                iExpressStatus = 3;
                                if (this.objExpressEnter.express_info !== null) {
                                    if (this.objExpressEnter.express_info.item_info) {
                                        if (this.objExpressEnter.express_info.item_info.length > 0 && this.objExpressEnter.price_weight !== null && this.objExpressEnter.price_weight !== "")
                                            iExpressStatus = 4;
                                    }
                                }
                            }
                            if (this.objExpressEnter.status === 2) {
                                iExpressStatus = 5;
                            }
                            if (this.objExpressEnter.status === 3) {
                                iExpressStatus = 6;
                            }
                            this.strCurrentEnterNo = this.objExpressEnter.enter_no;
                            this.strCurrentReceiver = this.objExpressEnter.receiver;
                            this.strCurrentStorageCode = this.objExpressEnter.storage_code;
                        }
                        message.success({ content: "数据扫描完成！", key: g_strMessageKey, duration: 2 });
                        this.setState({
                            ...this.state,
                            iExpressStatus: iExpressStatus
                        });
                    }).catch(function (error) { console.log(error); });
            } else {
                message.warning("请扫描或手动输入单号！");
            }
        }
    }
    QRCodeScanError = err => {
        console.error("QR Code Scanner Error : ", err);
        // this.setState({
        //     ...this.state,
        //     strOgeScanResult: err
        // });
    }
    // 条形码扫描
    BarCodeScanUpdate = (err, result) => {
        if (this.state.iExpressStatus > 0 || this.state.strBarCodeResult !== "No Result" || this.state.bScanResultDlg || this.state.bSubmitResultDlg)
            return;
        if (result) {

            let strResult = result.text;
            if (strResult.indexOf('MMM') < 0 && strResult.indexOf('k5') >= 0) {
                let objResult = JSON.parse(strResult);
                strResult = objResult.k5;
            }
            if (strResult.indexOf('MMM') >= 0 && strResult.indexOf('k5') >= 0) {
                let objResult = JSON.parse(strResult.split('MMM=')[1]);
                strResult = objResult.k5;
            }

            this.setState({
                ...this.state,
                bScanResultDlg: true,
                strBarCodeResult: strResult,
                strOgeScanResult: result.text
            });
            this.audio.play();
            this.bScanResultDlg = true;
            // 根据扫描到的单号进行搜索
            message.loading({ content: '数据扫描中……', key: g_strMessageKey });
            axios('/GetExpressEnter', { params: { express_order: result.text, store_id: this.props.objStoreInfo.id } })
                .then(({ data }) => {
                    let iExpressStatus = 0;
                    if (data.length <= 0) {
                        this.objExpressEnter = { receiver: "", express_info: { item_info: [] } };
                        iExpressStatus = 1;  // 数据库中无记录，当前步骤为签收入仓
                    } else {
                        iExpressStatus = 2;
                        this.objExpressEnter = data[0];
                        if (this.objExpressEnter.express_info !== null) {
                            this.objExpressEnter.express_info = JSON.parse(this.objExpressEnter.express_info);
                            this.fItemWeight = this.objExpressEnter.express_info.item_weight;
                            this.fItemLength = this.objExpressEnter.express_info.item_length;
                            this.fItemWidth = this.objExpressEnter.express_info.item_width;
                            this.fItemHeight = this.objExpressEnter.express_info.item_height;
                            this.fItemValueWeight = this.objExpressEnter.express_info.value_weight;
                            this.fItemPriceWeight = this.objExpressEnter.express_info.price_weight;
                            if (this.objExpressEnter.package_no === null || this.objExpressEnter.package_no === "")
                                this.strCurrentPackageCode = this.strCurrentPackageCode;
                            else
                                this.strCurrentPackageCode = this.objExpressEnter.package_no;
                        } else {
                            this.objExpressEnter.express_info = {
                                item_info: []
                            }
                        }
                        if (this.objExpressEnter.status >= 1) {
                            iExpressStatus = 3;
                            // if (this.objExpressEnter.express_info !== null) {
                            //     if (this.objExpressEnter.express_info.item_info) {
                            //         if (this.objExpressEnter.express_info.item_info.length > 0 && this.objExpressEnter.price_weight !== null && this.objExpressEnter.price_weight !== "")
                            //             iExpressStatus = 4;
                            //     }
                            // }
                        }
                        // if (this.objExpressEnter.status === 2) {
                        //     iExpressStatus = 5;
                        // }
                        // if (this.objExpressEnter.status === 3) {
                        //     iExpressStatus = 6;
                        // }
                        this.strCurrentEnterNo = this.objExpressEnter.enter_no;
                        this.strCurrentReceiver = this.objExpressEnter.receiver;
                        this.strCurrentStorageCode = this.objExpressEnter.storage_code;
                    }
                    message.success({ content: "数据扫描完成！", key: g_strMessageKey, duration: 2 });
                    this.setState({
                        ...this.state,
                        iExpressStatus: iExpressStatus
                    });
                }).catch(function (error) { console.log(error); });
        }
    }
    // 签收进仓/合箱出仓
    ScanResultProcess = (bConfirmButton) => {
        if (this.state.iExpressStatus === 0)
            return;

        // 每当进行确认时，都会对用户码进行记录更新
        if (this.refs.receiver !== undefined && this.refs.receiver !== null) {
            this.strCurrentReceiver = this.refs.receiver.state.value;
            // cookie.save('wm_scan_user_code', this.strCurrentUserCode, { path: '/' });
        }
        if (this.refs.enter_no !== undefined && this.refs.enter_no !== null) {
            this.strCurrentEnterNo = this.refs.enter_no.state.value;
        }
        if (this.refs.package_no !== undefined && this.refs.package_no !== null) {
            this.strCurrentPackageCode = this.refs.package_no.state.value;
            this.objExpressEnter.package_no = this.refs.package_no.state.value;
            this.objPackageCombine.package_no = this.refs.package_no.state.value;
        }
        if (this.refs.storage_code !== undefined && this.refs.storage_code !== null) {
            this.strCurrentStorageCode = this.refs.storage_code.state.value;
        }
        // if (this.refs.receiver || this.refs.enter_no || this.refs.package_no) {
        axios.post('/UpdateScanRecord', {
            code: this.props.strUserCode, scan_record: { user_code: this.strCurrentReceiver, enter_no: this.strCurrentEnterNo, package_no: this.strCurrentPackageCode }
        }).then(({ data }) => {
            console.log("UpdateScanRecord Complete", data);
        }).catch(function (error) { console.log(error); });
        // }

        // 还未录入单号
        if (this.state.iExpressStatus === 1) {
            this.objExpressEnter.enter_no = this.strCurrentEnterNo;
            this.objExpressEnter.receiver = this.strCurrentReceiver;
            if (this.state.iScanMethod === 0)
                this.objExpressEnter.express_order = this.state.strQRCodeResult;
            else
                this.objExpressEnter.express_order = this.state.strBarCodeResult;
            this.objExpressEnter.status = 1;
            this.objExpressEnter.store_id = this.props.objStoreInfo.id;
            message.loading({ content: '快递进仓中……', key: g_strMessageKey });
            axios.post('/NewExpressEnter', {
                objExpressEnter: this.objExpressEnter
            }).then(({ data }) => {
                if (data.status === 1) {
                    message.success({ content: "快递进仓并签收完成!", key: g_strMessageKey, duration: 2 });
                    this.bScanResultDlg = false;
                    this.setState({
                        ...this.state,
                        bScanResultDlg: false,
                        strQRCodeResult: 'No Result',
                        strBarCodeResult: 'No Result',
                        bSubmitResultDlg: true,
                        iSubmitResult: 1
                    });
                } else {
                    message.warning(data.message);
                }
            }).catch(function (error) {
                console.log(error);
                this.setState({
                    ...this.state,
                    bScanResultDlg: false,
                    strQRCodeResult: 'No Result',
                    strBarCodeResult: 'No Result',
                    bSubmitResultDlg: true,
                    iSubmitResult: -1
                });
            });
        }
        // 已录入，还未签收
        if (this.state.iExpressStatus === 2) {
            this.objExpressEnter.status = 1;
            this.objExpressEnter.enter_no = this.strCurrentEnterNo;
            this.objExpressEnter.receiver = this.strCurrentReceiver;
            message.loading({ content: '快递签收中……', key: g_strMessageKey });
            axios.post('/UpdateExpressEnter', {
                objExpressEnter: this.objExpressEnter
            }).then(({ data }) => {
                message.success({ content: "快递签收完成!", key: g_strMessageKey, duration: 2 });
                this.bScanResultDlg = false;
                this.setState({
                    ...this.state,
                    bScanResultDlg: false,
                    strQRCodeResult: 'No Result',
                    strBarCodeResult: 'No Result',
                    bSubmitResultDlg: true,
                    iSubmitResult: 2
                });
            }).catch(function (error) {
                console.log(error);
                this.setState({
                    ...this.state,
                    bScanResultDlg: false,
                    strQRCodeResult: 'No Result',
                    strBarCodeResult: 'No Result',
                    bSubmitResultDlg: true,
                    iSubmitResult: -1
                });
            });
        }
        // 已签收，还未录入明细
        if (this.state.iExpressStatus >= 3) {
            this.objExpressEnter.enter_no = this.strCurrentEnterNo;
            this.objExpressEnter.receiver = this.strCurrentReceiver;
            this.objExpressEnter.storage_code = this.strCurrentStorageCode;
            this.objExpressEnter.express_info.item_weight = this.fItemWeight;
            this.objExpressEnter.express_info.item_length = this.fItemLength;
            this.objExpressEnter.express_info.item_width = this.fItemWidth;
            this.objExpressEnter.express_info.item_height = this.fItemHeight;
            this.objExpressEnter.express_info.item_value = parseFloat(this.fItemLength) * parseFloat(this.fItemWidth) * parseFloat(this.fItemHeight);
            this.objExpressEnter.express_info.value_weight = this.fItemValueWeight;
            this.objExpressEnter.express_info.price_weight = this.fItemPriceWeight;
            this.objExpressEnter.express_info = JSON.stringify(this.objExpressEnter.express_info);
            if (!bConfirmButton) {
                this.objExpressEnter.status = 1;
                message.loading({ content: '快递信息更新中……', key: g_strMessageKey });
            } else {
                this.objExpressEnter.status = 2;
                if (this.refs.package_no)
                    if (this.refs.package_no.state.value === null || this.refs.package_no.state.value === "") {
                        message.warning('合箱编码不能为空！');
                        return;
                    }
                message.loading({ content: '快递合箱/出仓中……', key: g_strMessageKey });
            }
            axios.post('/UpdateExpressEnter', {
                objExpressEnter: this.objExpressEnter
            }).then(({ data }) => {
                if (!bConfirmButton) {
                    message.success({ content: "快递信息更新完成!", key: g_strMessageKey, duration: 2 });
                    this.bScanResultDlg = false;
                    this.setState({
                        ...this.state,
                        bScanResultDlg: false,
                        strQRCodeResult: 'No Result',
                        strBarCodeResult: 'No Result',
                        bSubmitResultDlg: true,
                        iSubmitResult: 3
                    });
                } else {
                    this.objPackageCombine.store_id = this.props.objStoreInfo.id;
                    this.objPackageCombine.status = 0;
                    this.objPackageCombine.transit_way = 0;
                    this.objPackageCombine.ship_date = this.state.iShipDate;
                    this.objPackageCombine.package_type = this.state.iItemType;
                    this.objPackageCombine.express_info = [];
                    this.objPackageCombine.express_info.push({ express_order: this.objExpressEnter.express_order, receiver: this.objExpressEnter.receiver });
                    this.objPackageCombine.express_info = JSON.stringify(this.objPackageCombine.express_info);
                    axios.post('/ScanPackageCombine', {
                        objPackageCombine: this.objPackageCombine
                    }).then(({ data }) => {
                        message.success({ content: "快递合箱/出仓完成!", key: g_strMessageKey, duration: 2 });
                        this.bScanResultDlg = false;
                        this.setState({
                            ...this.state,
                            bScanResultDlg: false,
                            strQRCodeResult: 'No Result',
                            strBarCodeResult: 'No Result',
                            bSubmitResultDlg: true,
                            iSubmitResult: 4
                        });
                    }).catch(function (error) {
                        console.log(error);
                        this.bScanResultDlg = false;
                        this.setState({
                            ...this.state,
                            bScanResultDlg: false,
                            strQRCodeResult: 'No Result',
                            strBarCodeResult: 'No Result',
                            bSubmitResultDlg: true,
                            iSubmitResult: -2
                        });
                    });
                }
            }).catch(function (error) {
                console.log(error);
                this.setState({
                    ...this.state,
                    bScanResultDlg: false,
                    strQRCodeResult: 'No Result',
                    strBarCodeResult: 'No Result',
                    bSubmitResultDlg: true,
                    iSubmitResult: -1
                });
            });
        }

    }
    // 重新扫描
    RestartScan = () => {
        if (this.state.iExpressStatus === 0)
            return;
        this.bScanResultDlg = false;
        this.setState({
            ...this.state,
            bScanResultDlg: false,
            bSubmitResultDlg: false,
            iSubmitResult: 0,
            strQRCodeResult: 'No Result',
            strBarCodeResult: 'No Result',
            iExpressStatus: 0
        });
    }
    // 获取上一次扫描的收件人名
    GetLastScanCode = () => {
        // 存储在cookie
        // let strScanUserCode = cookie.load('wm_scan_user_code');
        // if (strScanUserCode) {
        //     this.strCurrentUserCode = strScanUserCode;
        // } else {
        //     cookie.save('wm_scan_user_code', '', { path: '/' });
        // }
        // 存储在数据库
        axios('/GetLastScanRecord', { params: { code: this.props.strUserCode } })
            .then(({ data }) => {
                if (data[0].scan_record !== null && data[0].scan_record !== "") {
                    let objScanRecord = JSON.parse(data[0].scan_record);
                    this.strCurrentReceiver = objScanRecord.user_code;
                    this.strCurrentEnterNo = objScanRecord.enter_no;
                    this.strCurrentPackageCode = objScanRecord.package_no;
                }
                // this.setState({
                //     ...this.state,
                //     bScanResultDlg: true,
                //     iExpressStatus: 1
                // });
            }).catch(function (error) { console.log(error); });
    }
    // 签收/进仓成功/失败的提示框
    SubmitResultDlg = (bShow) => {
        this.setState({
            ...this.state,
            bSubmitResultDlg: bShow
        })
    }
    // 货物信息发生变化
    ItemInfoChange = (index, e) => {
        // console.log(index, e.target.value);
        let fItemValueWeight = 0.0;
        let fItemPriceWeight = 0.0;
        // 净重
        if (index === 0) {
            let fItemWeight = e.target.value;


            if (this.fItemValueWeight === "")
                fItemPriceWeight = fItemWeight;
            else {
                if (parseFloat(fItemWeight) >= parseFloat(this.fItemValueWeight))
                    fItemPriceWeight = fItemWeight;
                else
                    fItemPriceWeight = this.fItemValueWeight;
            }


            this.fItemWeight = fItemWeight;
            this.fItemPriceWeight = fItemPriceWeight;
        }
        // 长
        if (index === 1) {
            let fItemLength = e.target.value;
            fItemValueWeight = parseFloat(fItemLength) * parseFloat(this.fItemWidth) * parseFloat(this.fItemHeight) / 6000;
            if (fItemLength === "" || this.fItemWidth === "" || this.fItemHeight === "")
                fItemPriceWeight = this.fItemWeight;
            if (parseFloat(this.fItemWeight) >= parseFloat(fItemValueWeight))
                fItemPriceWeight = this.fItemWeight;
            else
                fItemPriceWeight = fItemValueWeight;
            this.fItemLength = fItemLength;
            this.fItemValueWeight = fItemValueWeight;
            this.fItemPriceWeight = fItemPriceWeight;
        }
        // 宽
        if (index === 2) {
            let fItemWidth = e.target.value;
            fItemValueWeight = parseFloat(this.fItemLength) * parseFloat(fItemWidth) * parseFloat(this.fItemHeight) / 6000;
            if (this.fItemLength === "" || fItemWidth === "" || this.fItemHeight === "")
                fItemPriceWeight = this.fItemWeight;
            if (parseFloat(this.fItemWeight) >= parseFloat(fItemValueWeight))
                fItemPriceWeight = this.fItemWeight;
            else
                fItemPriceWeight = fItemValueWeight;
            this.fItemWidth = fItemWidth;
            this.fItemValueWeight = fItemValueWeight;
            this.fItemPriceWeight = fItemPriceWeight;
        }
        // 高
        if (index === 3) {
            let fItemHeight = e.target.value;
            fItemValueWeight = parseFloat(fItemHeight) * parseFloat(this.fItemWidth) * parseFloat(this.fItemLength) / 6000;
            if (this.fItemLength === "" || this.fItemWidth === "" || fItemHeight === "")
                fItemPriceWeight = this.fItemWeight;
            if (parseFloat(this.fItemWeight) >= parseFloat(fItemValueWeight))
                fItemPriceWeight = this.fItemWeight;
            else
                fItemPriceWeight = fItemValueWeight;
            this.fItemHeight = fItemHeight;
            this.fItemValueWeight = fItemValueWeight;
            this.fItemPriceWeight = fItemPriceWeight;
        }
        this.setState({
            ...this.state,
            bItemInfoChange: true
        });

    }
    // 新增货物明细
    AddNewItemInfo = () => {
        console.log("Add New Item Info:", this.objExpressEnter);
        let objItemInfo = {
            name: '', num: 1
        };
        this.objExpressEnter.express_info.item_info.push(objItemInfo);
        this.setState({
            ...this.state,
            bItemInfoChange: true
        });
    }
    // 物品信息发生变化
    ItemDetailsChange = (indexItem, value, e) => {
        if (value === 0) {
            this.objExpressEnter.express_info.item_info[indexItem].name = e.target.value;
        }
        if (value === 1) {
            this.objExpressEnter.express_info.item_info[indexItem].num = e.target.value;
        }
        this.setState({
            ...this.state,
            bItemInfoChange: true
        });
    }
    // 移除一个物品信息
    RemoveItemInfo = (indexItem) => {
        this.objExpressEnter.express_info.item_info.splice(indexItem, 1);

        this.setState({
            ...this.state,
            bItemInfoChange: true
        });

    }
    // 切换船期
    SwitchShipDate = (index) => {
        if (this.refs.package_no) {
            if (this.refs.enter_no.state.value !== "" && this.refs.receiver.state.value !== "")
                this.refs.package_no.state.value = this.refs.enter_no.state.value + "/" +
                    this.refs.receiver.state.value + "/" + this.strCurrentMonth +
                    this.arrShipDateShot[index] + this.arrItemTypeShort[this.state.iItemType];

        }
        this.setState({
            ...this.state,
            iShipDate: index
        });
    }
    // 切换货物品类
    SwitchItemType = (index) => {
        if (this.refs.package_no) {
            if (this.refs.enter_no.state.value !== "" && this.refs.receiver.state.value !== "")
                this.refs.package_no.state.value = this.refs.enter_no.state.value + "/" +
                    this.refs.receiver.state.value + "/" + this.strCurrentMonth +
                    this.arrShipDateShot[this.state.iShipDate] + this.arrItemTypeShort[index];
        }
        this.setState({
            ...this.state,
            iItemType: index
        });
    }
    // 清空查询输入框
    ClearOrderInput = (index) => {
        if (index === 1) {
            this.refs.express_input.state.value = '';
            this.setState({
                ...this.state,
                iScanPurpose: this.state.iScanPurpose
            });
        } else {
            this.refs.order_input.state.value = '';
            this.objCurrentExpress = -1;
            this.objCurrentPackage = -1;
            this.arrExpressInfo = [];
            this.setState({
                ...this.state,
                iExpressPackageStatus: 0,
                iScanPurpose: this.state.iScanPurpose
            });
        }
    }

    // 渲染完毕……
    componentDidMount() {
        this.GetLastScanCode();
        console.log("PackageScan : zhihe");
    }

    /******** 拍照相关 ********/
    handleWebcamDidMount = (elem) => {
        this.refs = elem;
    }
    TakePicture = () => {
        message.info("已拍照！");
        this.setState({
            ...this.state,
            photo: this.refs.getScreenshot()
        });
    }
    UploadPicture = () => {
        if (this.state.photo === null)
            return;
        message.loading({ content: '照片提交中……', key: g_strMessageKey });
        axios.post('/UploadImageToCloudinary',
            {
                storeName: this.props.objStoreInfo.id.toString() + "-" + this.props.objStoreInfo.store_name,
                imgName: 'EEP' + new Date().toISOString(),
                imgUrl: this.state.photo
            }).then((response) => {
                console.log(response.data);
                if (response.data === -1) {
                    message.success({ content: "上传失败，请重新尝试！", key: g_strMessageKey, duration: 2 });
                } else {
                    message.success({ content: "照片已提交到云端！", key: g_strMessageKey, duration: 2 });
                }
                this.setState({
                    ...this.state,
                    photo: null
                });
            }).catch(function (error) {
                console.log(error);
                message.success({ content: "上传失败，请重新尝试！", key: g_strMessageKey, duration: 2 });
                this.setState({
                    ...this.state,
                    photo: null
                });
            });
    }

    render() {
        let strQRButtonStyle = "code_scanner_method_switch_button_select";
        let strBARButtonStyle = "code_scanner_method_switch_button";
        let strPhotoButtonStyle = "code_scanner_method_switch_button";
        let strResultOrder = this.state.strQRCodeResult;
        // let uiScanArea = (
        //     <div>
        //         <QrReader delay={300} onError={this.QRCodeScanError} onScan={this.QRCodeScanUpdate}
        //             style={{ width: '100%' }} />
        //     </div>
        // );
        // 默认扫码枪模式
        let strInputTip = "点击输入框后，扫描快递包裹";
        // let strInputButotn = "签 收";
        if (this.state.iScanPurpose === 1) {
            strInputTip = "点击输入框后，扫描运单编号";
            // strInputButotn = "保 存";
        }
        // let strInputButtonStyle = "code_scanner_input_confirm_button code_scanner_input_confirm_button_enable";
        let uiOrderInfoArea = null;
        if (this.state.iScanPurpose === 0 && this.objCurrentExpress !== 1 && typeof (this.objCurrentExpress) === 'object') {
            // strInputButtonStyle = "code_scanner_input_confirm_button";
            let uiItemList = null;
            if (this.objCurrentExpress.express_info !== undefined && this.objCurrentExpress.express_info !== null &&
                this.objCurrentExpress.express_info !== "") {
                let arrItemInfo = JSON.parse(this.objCurrentExpress.express_info);
                arrItemInfo = arrItemInfo.item_info;
                uiItemList = (
                    arrItemInfo.map((item, indexItem) => {
                        return (
                            <div key={indexItem} className="code_scanner_oder_info_row">
                                {item.cn + " x " + item.num + " 件"}
                            </div>
                        );
                    })
                );
            } else {
                uiItemList = (
                    <div className="code_scanner_oder_info_row">
                        无
                    </div>
                );
            }
            uiOrderInfoArea = (
                <div className="code_scanner_order_info_area">
                    <div className="code_scanner_oder_info_row">
                        <b>{"物流单号："}</b>
                        {this.objCurrentExpress.express_order}
                        <div className="code_scanner_edit_button" onClick={this.EditUserInfo.bind(this.setState, 0)}>编辑</div>
                    </div>
                    <div className="code_scanner_oder_info_row">
                        <b>{"货运方式："}</b>
                        {this.arrShipMethod[this.objCurrentExpress.transit_type]}
                    </div>
                    <div className="code_scanner_oder_info_row">
                        <b>{"合箱编码："}</b>
                        {this.objCurrentExpress.auto_order_no}
                    </div>
                    <div className="code_scanner_oder_info_row">
                        <b>{"用户信息："}</b>
                        {this.objCurrentExpress.user_name + " ( " + this.objCurrentExpress.storage_code + " )"}

                    </div>
                    <div className="code_scanner_oder_info_row">
                        <b>{"包裹状态："}</b>
                        {this.arrExpressStatus[this.objCurrentExpress.status]}
                    </div>
                    <div className="code_scanner_oder_info_row">
                        <b>{"物品信息："}</b>
                    </div>
                    {uiItemList}
                </div>
            );
        }
        if (this.state.iScanPurpose === 1 && this.objCurrentPackage !== 1 && typeof (this.objCurrentPackage) === 'object') {
            // strInputButtonStyle = "code_scanner_input_confirm_button";
            let uiExpressList = null;
            let iExpressNum = 0;
            if (this.objCurrentPackage.express_info !== undefined) {
                uiExpressList = (
                    this.arrExpressInfo.map((express, indexExpress) => {
                        let uiCheck = null;
                        if (express.checked === 1) {
                            uiCheck = (
                                <Icon type="check-circle" style={{ marginLeft: "2.5vw", color: 'darkcyan' }} />
                            );
                        }
                        return (
                            <div key={indexExpress} className="code_scanner_oder_info_row">
                                {express.express_order}
                                {uiCheck}
                            </div>
                        );
                    })
                );
                iExpressNum = this.arrExpressInfo.length;
            }
            uiOrderInfoArea = (
                <div className="code_scanner_order_info_area">
                    <div className="code_scanner_oder_info_row">
                        <b>{"分拣："}</b>
                        <Input className="code_scanner_input" style={{ width: '40vw', marginLeft: '0px', marginRight: '0px' }}
                            placeholder={'扫描快递单号'} ref="express_input" />
                        <Icon type="close-circle" className="code_scanner_search_button" onClick={this.ClearOrderInput.bind(this, 1)}
                            style={{ background: 'grey', marginLeft: '0px', marginRight: '6vw', borderRadius: '0px', fontWeight: 'bold', fontSize: '5vw' }} />
                        <div className="code_scanner_search_button" onClick={this.InputConfirmButtonClicked.bind(this, 2)}>
                            <Icon type="check" />
                        </div>
                    </div>
                    <div className="code_scanner_oder_info_row" style={{ marginTop: '15px' }}>
                        <b>{"单号："}</b>
                        {this.objCurrentPackage.package_no}
                        <div className="code_scanner_edit_button" onClick={this.EditUserInfo.bind(this.setState, 1)}>编辑</div>
                    </div>
                    <div className="code_scanner_oder_info_row" >
                        <b>{"合箱编码："}</b>
                        {this.objCurrentPackage.auto_order_no}
                    </div>
                    <div className="code_scanner_oder_info_row">
                        <b>{"用户："}</b>
                        {this.arrExpressInfo[0].user_name + " ( " + this.arrExpressInfo[0].storage_code + " )"}
                    </div>
                    <div className="code_scanner_oder_info_row">
                        <b>{"状态："}</b>
                        {this.arrPackageStatus[this.objCurrentPackage.status]}
                    </div>
                    <div className="code_scanner_oder_info_row">
                        <b>{"包裹："}</b>{"共 " + iExpressNum + " 个"}
                    </div>
                    {uiExpressList}
                    <div style={{ marginBottom: '20px' }} />
                </div>
            );
        }
        if (this.state.iScanPurpose === -1) {
            // 货运方式
            let uiShipMethod = (
                <Menu>
                    {this.arrShipMethod.map((item, index) => {
                        return (
                            <Menu.Item key={index} onClick={this.SwitchShipMethod.bind(this, index)}>
                                {item}
                            </Menu.Item>
                        );
                    })}
                </Menu>
            );
            uiOrderInfoArea = (
                <div className="code_scanner_order_info_area">
                    <div className="code_scanner_oder_info_row">
                        {"该包裹未预报，无法获取物品信息"}
                    </div>
                    <div className="code_scanner_oder_info_row">
                        {"需要直接签收入库码？"}
                    </div>
                    <div className="code_scanner_oder_info_row">
                        <div className="code_scanner_confirm_button" onClick={this.ConfirmExpressEnter.bind(this, 1)}>
                            签收入库
                        </div>
                        <div className="code_scanner_confirm_button" style={{ marginLeft: 'auto', marginRight: '5vw' }}
                            onClick={this.ConfirmExpressEnter.bind(this, 0)}>
                            算了
                        </div>
                    </div>
                    <div className="code_scanner_oder_info_row" style={{ marginTop: '5vw', marginBottom: '2.5vw' }}>
                        {"用户编码"}
                        <Input className="code_scanner_input" style={{ width: '50vw', marginLeft: '6.95vw' }}
                            placeholder={'输入包裹上的用户编码'} ref="user_code" defaultValue={this.strCurrentEnterNo} />
                    </div>
                    <div className="code_scanner_oder_info_row" style={{ marginTop: '2.5vw', marginBottom: '5vw' }}>
                        {"货运方式"}
                        <Dropdown className="code_scanner_input" trigger={['click']}
                            overlay={uiShipMethod} placement="bottomLeft"
                            style={{ width: '50vw', maxWidth: '50vw' }}>
                            <Button style={{ width: '50vw', maxWidth: '50vw' }}
                            >{this.arrShipMethod[this.state.iShipMethod]}<Icon type="caret-down" /></Button>
                        </Dropdown>
                    </div>
                </div>
            );
        }
        let iScanPurpose = this.state.iScanPurpose;
        if (iScanPurpose < 0)
            iScanPurpose = 0;
        let uiHistoryButton = null;
        if (iScanPurpose === 1) {
            uiHistoryButton = (
                <div className="code_scanner_search_button" onClick={this.AutoFillPackageNo.bind(this, 1)}>
                    <Icon type="history" />
                </div>
            );
        }
        let uiScanArea = (
            <div>
                <div className="code_scanner_title_sub" >
                    {/* <Radio.Group onChange={this.ScanPurposeChange.bind(this)} value={iScanPurpose} className="code_scanner_input_row"  >
                        <Radio value={0} className="code_scanner_input_row" style={{ fontSize: '4vw' }}>扫码签收</Radio>
                        <Radio value={1} className="code_scanner_input_row" style={{ fontSize: '4vw' }}>合箱拣包</Radio>
                    </Radio.Group>
                    <div className={strInputButtonStyle} onClick={this.InputConfirmButtonClicked.bind(this, 1)}>{strInputButotn}</div> */}
                </div>
                <div className="code_scanner_input_row">
                    <Input className="code_scanner_input" placeholder={strInputTip} ref="order_input" style={{ width: '60vw', marginRight: '0px' }} />
                    <Icon type="close-circle" className="code_scanner_search_button" onClick={this.ClearOrderInput.bind(this, 0)}
                        style={{ background: 'grey', marginLeft: '0px', marginRight: '2.5vw', borderRadius: '0px', fontWeight: 'bold', fontSize: '5vw' }} />
                    <div className="code_scanner_search_button" onClick={this.QRCodeScanUpdate.bind(this, null)} style={{ marginRight: '2.5vw' }} >
                        <Icon type="search" />
                    </div>
                    {uiHistoryButton}
                </div>
                {uiOrderInfoArea}
            </div>
        );
        if (this.state.iScanMethod === 1) {
            strQRButtonStyle = "code_scanner_method_switch_button";
            strBARButtonStyle = "code_scanner_method_switch_button_select";
            strPhotoButtonStyle = "code_scanner_method_switch_button";
            strResultOrder = this.state.strBarCodeResult;
            uiScanArea = (
                <div>
                    {/* <div>{"Bar Result : " + this.state.strBarCodeResult}</div> */}
                    <BarcodeScannerComponent
                        // width={450} height={350}
                        style={{ width: '100%' }}
                        onUpdate={this.BarCodeScanUpdate} />
                </div>
            );
        }
        if (this.state.iScanMethod === 2) {
            strQRButtonStyle = "code_scanner_method_switch_button";
            strBARButtonStyle = "code_scanner_method_switch_button";
            strPhotoButtonStyle = "code_scanner_method_switch_button_select";
            let uiTakePicture = null;
            let strUploadStyle = "code_scanner_upload_photo_button code_scanner_upload_photo_button_disable";
            if (this.state.photo !== null) {
                strUploadStyle = "code_scanner_upload_photo_button";
                uiTakePicture = (
                    <img src={this.state.photo} className="code_scanner_photo_shot" alt='' />
                );
            }
            uiScanArea = (
                <div className="code_scanner_photo_area">
                    <Webcam audio={false} ref={this.handleWebcamDidMount} screenshotFormat={'image'}
                        videoConstraints={{ width: 1280, height: 720, facingMode: "environment" }}
                        minScreenshotWidth={1280} minScreenshotHeight={720} className="code_scanner_photo_shot" />
                    {uiTakePicture}
                    <div className="code_scanner_take_photo_button" onClick={this.TakePicture.bind(this)} >
                        <Icon type="camera" style={{ color: 'white', fontWeight: 'bold', fontSize: '25px' }} />
                    </div>
                    <div className={strUploadStyle} onClick={this.UploadPicture.bind(this)}>上传图片</div>
                </div>
            );

        }

        let strOkButtonText = "处理中……";
        let strCancelButton = "扫错了，重来";
        let uiReceiver = null;
        let uiEnterNo = null;  // 来自哪个代理
        let uiStorageCode = null;  // 自动分配的用户仓库编码
        let uiPackageCombine = null;
        let uiComplete = null;
        let uiShipDate = null;
        let uiItemType = null;
        let uiItemInfo = null;
        let uiItemInfoList = null;
        if (this.state.iExpressStatus === 1) {
            strOkButtonText = "快递进仓&签收";
            uiEnterNo = (
                <div className="package_scan_item_info_row" style={{ marginTop: '5vw' }}>
                    <div className="package_scan_item_info_title">进仓编码：</div>
                    <Input className="package_scan_item_info_input" ref="enter_no" defaultValue={this.strCurrentEnterNo} />
                </div>
            );
            uiReceiver = (
                <div className="package_scan_item_info_row" style={{ marginTop: '5vw' }}>
                    <div className="package_scan_item_info_title">收件人名：</div>
                    <Input className="package_scan_item_info_input" ref="receiver" defaultValue={this.strCurrentReceiver} />
                </div>
            );
            if (this.objExpressEnter.express_info !== null) {
                if (this.objExpressEnter.express_info.item_info)
                    if (this.objExpressEnter.express_info.item_info !== null) {
                        if (this.objExpressEnter.express_info.item_info.length > 0) {
                            uiItemInfoList = (
                                this.objExpressEnter.express_info.item_info.map((item, indexItem) => {
                                    let strItemBlockStyle = "express_item_info_block";
                                    if (indexItem % 2 === 0)
                                        strItemBlockStyle = "express_item_info_block_light";
                                    return (
                                        <div key={indexItem} className={strItemBlockStyle}>
                                            <div className="express_item_info_row">
                                                <div className="express_enter_edit_title_m">明细</div>
                                                <Input style={{ width: '30%' }} ref={"item_name_" + indexItem}
                                                    defaultValue={item.name} value={item.name}
                                                    onChange={this.ItemDetailsChange.bind(this, indexItem, 0)} />
                                                <div className="express_enter_edit_title_m">件数</div>
                                                <Input style={{ width: '20%' }} ref={"item_num_" + indexItem}
                                                    defaultValue={item.num} value={item.num}
                                                    onChange={this.ItemDetailsChange.bind(this, indexItem, 1)} />
                                                <div className="express_item_delete_button" style={{ marginLeft: 'auto', marginRight: '0px' }}
                                                    onClick={this.RemoveItemInfo.bind(this, indexItem)}>
                                                    <Icon type="delete" theme="filled" />
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            );
                        }
                    }
            }
            // 包括详细信息
            uiItemInfo = (
                <div>
                    <div className="package_scan_item_info_add_button" onClick={this.AddNewItemInfo}>+ 物品信息</div>
                    {uiItemInfoList}
                </div>
            );
        }
        if (this.state.iExpressStatus === 2) {
            strOkButtonText = "快递签收";
            uiReceiver = (
                <div className="package_scan_item_info_row" style={{ marginTop: '5vw' }}>
                    <div className="package_scan_item_info_title">{"收件人名：" + this.objExpressEnter.receiver}</div>
                </div>
            );
            uiEnterNo = (
                <div className="package_scan_item_info_row" style={{ marginTop: '5vw' }}>
                    <div className="package_scan_item_info_title">进仓编码：</div>
                    <Input className="package_scan_item_info_input" ref="enter_no" defaultValue={this.strCurrentEnterNo} />
                </div>
            );
        }

        if (this.state.iExpressStatus >= 3) {
            strOkButtonText = "合箱/出仓";
            uiPackageCombine = (
                <div className="package_scan_item_info_row" style={{ marginTop: '5vw' }}>
                    <div className="package_scan_item_info_title">合箱编码：</div>
                    <Input className="package_scan_item_info_input" ref="package_no" defaultValue={this.strCurrentPackageCode} />
                </div>
            );
            uiEnterNo = (
                <div className="package_scan_item_info_row" style={{ marginTop: '5vw' }}>
                    <div className="package_scan_item_info_title">进仓编码：</div>
                    <Input className="package_scan_item_info_input" ref="enter_no" defaultValue={this.strCurrentEnterNo} />
                </div>
            );
            uiReceiver = (
                <div className="package_scan_item_info_row" style={{ marginTop: '5vw' }}>
                    <div className="package_scan_item_info_title">收件人名：</div>
                    <Input className="package_scan_item_info_input" ref="receiver" defaultValue={this.strCurrentReceiver} />
                </div>
            );
            uiStorageCode = (
                <div className="package_scan_item_info_row" style={{ marginTop: '5vw' }}>
                    <div className="package_scan_item_info_title">用户编码：</div>
                    <Input className="package_scan_item_info_input" ref="storage_code" defaultValue={this.strCurrentStorageCode} />
                </div>
            );

            if (this.objExpressEnter.express_info !== null) {
                if (this.objExpressEnter.express_info.item_info)
                    if (this.objExpressEnter.express_info.item_info !== null) {
                        if (this.objExpressEnter.express_info.item_info.length > 0) {
                            uiItemInfoList = (
                                this.objExpressEnter.express_info.item_info.map((item, indexItem) => {
                                    let strItemBlockStyle = "express_item_info_block";
                                    if (indexItem % 2 === 0)
                                        strItemBlockStyle = "express_item_info_block_light";
                                    return (
                                        <div key={indexItem} className={strItemBlockStyle}>
                                            <div className="express_item_info_row">
                                                <div className="express_enter_edit_title_m">明细</div>
                                                <Input style={{ width: '30%' }} ref={"item_name_" + indexItem}
                                                    defaultValue={item.name} value={item.name}
                                                    onChange={this.ItemDetailsChange.bind(this, indexItem, 0)} />
                                                <div className="express_enter_edit_title_m">件数</div>
                                                <Input style={{ width: '20%' }} ref={"item_num_" + indexItem}
                                                    defaultValue={item.num} value={item.num}
                                                    onChange={this.ItemDetailsChange.bind(this, indexItem, 1)} />
                                                <div className="express_item_delete_button" style={{ marginLeft: 'auto', marginRight: '0px' }}
                                                    onClick={this.RemoveItemInfo.bind(this, indexItem)}>
                                                    <Icon type="delete" theme="filled" />
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            );
                        }
                    }
            }
            // 包括详细信息
            uiItemInfo = (
                <div>
                    <div className="package_scan_item_info_row" style={{ marginTop: '5vw' }}>
                        <div className="package_scan_item_info_title">货物净重：</div>
                        <Input className="package_scan_item_info_input package_scan_item_info_input_addon" ref="item_weight"
                            defaultValue={''} value={this.fItemWeight} onChange={this.ItemInfoChange.bind(this, 0)} />
                        <div className="package_scan_item_info_after">kg</div>
                    </div>
                    <div className="package_scan_item_info_row" style={{ marginTop: '5vw' }}>
                        <div className="package_scan_item_info_title">货物长度：</div>
                        <Input className="package_scan_item_info_input package_scan_item_info_input_addon" ref="item_length"
                            defaultValue={''} value={this.fItemLength} onChange={this.ItemInfoChange.bind(this, 1)} />
                        <div className="package_scan_item_info_after ">cm</div>
                    </div>
                    <div className="package_scan_item_info_row" style={{ marginTop: '5vw' }}>
                        <div className="package_scan_item_info_title ">货物宽度：</div>
                        <Input className="package_scan_item_info_input package_scan_item_info_input_addon" ref="item_width"
                            defaultValue={''} value={this.fItemWidth} onChange={this.ItemInfoChange.bind(this, 2)} />
                        <div className="package_scan_item_info_after">cm</div>
                    </div>
                    <div className="package_scan_item_info_row" style={{ marginTop: '5vw' }}>
                        <div className="package_scan_item_info_title ">货物高度：</div>
                        <Input className="package_scan_item_info_input package_scan_item_info_input_addon" ref="item_height"
                            defaultValue={''} value={this.fItemHeight} onChange={this.ItemInfoChange.bind(this, 3)} />
                        <div className="package_scan_item_info_after">cm</div>
                    </div>
                    <div className="package_scan_item_info_row" style={{ marginTop: '5vw' }}>
                        <div className="package_scan_item_info_title">{"计费重量：" + parseFloat(this.fItemPriceWeight).toFixed(2) + " kg"}</div>
                    </div>
                    <div className="package_scan_item_info_add_button" onClick={this.AddNewItemInfo}>+ 物品信息</div>
                    {uiItemInfoList}
                </div>
            );
            // 船期
            let uiShipDateMenu = (
                <Menu>
                    {this.arrShipDate.map((item, index) => {
                        return (
                            <Menu.Item key={index} onClick={this.SwitchShipDate.bind(this, index)}>
                                {item}
                            </Menu.Item>
                        );
                    })}
                </Menu>
            );
            uiShipDate = (
                <div className="package_scan_item_info_row" style={{ marginTop: '5vw' }}>
                    <div className="package_scan_item_info_title">本月船期：</div>
                    <Dropdown className="order_detail_dropdown_m" trigger={['click']}
                        overlay={uiShipDateMenu} placement="bottomLeft">
                        <Button>{this.arrShipDate[this.state.iShipDate]}<Icon type="caret-down" /></Button>
                    </Dropdown>
                </div>
            );
            // 货物品类
            let uiItemTypeMenu = (
                <Menu>
                    {this.arrItemType.map((item, index) => {
                        return (
                            <Menu.Item key={index} onClick={this.SwitchItemType.bind(this, index)}>
                                {item}
                            </Menu.Item>
                        );
                    })}
                </Menu>
            );
            uiItemType = (
                <div className="package_scan_item_info_row" style={{ marginTop: '5vw' }}>
                    <div className="package_scan_item_info_title">货物品类：</div>
                    <Dropdown className="order_detail_dropdown_m" trigger={['click']}
                        overlay={uiItemTypeMenu} placement="bottomLeft">
                        <Button>{this.arrItemType[this.state.iItemType]}<Icon type="caret-down" /></Button>
                    </Dropdown>
                </div>
            );
        }

        if (this.state.iExpressStatus >= 5) {
            uiEnterNo = (
                <div className="package_scan_item_info_row" style={{ marginTop: '5vw' }}>
                    <div className="package_scan_item_info_title">{"进仓编码：" + this.objExpressEnter.enter_no}</div>
                </div>
            );
            uiReceiver = (
                <div className="package_scan_item_info_row" style={{ marginTop: '5vw' }}>
                    <div className="package_scan_item_info_title">{"收件人名：" + this.objExpressEnter.receiver}</div>
                </div>
            );
            uiStorageCode = (
                <div className="package_scan_item_info_row" style={{ marginTop: '5vw' }}>
                    <div className="package_scan_item_info_title">{"用户编码：" + this.objExpressEnter.storage_code}</div>
                </div>
            );
            uiPackageCombine = (
                <div className="package_scan_item_info_row" style={{ marginTop: '5vw' }}>
                    <div className="package_scan_item_info_title">{"合箱编码：" + this.objExpressEnter.package_no}</div>
                </div>
            );
        }
        let uiOnCancel = this.RestartScan;
        if (this.state.iExpressStatus >= 3 && this.state.iExpressStatus <= 4) {
            strCancelButton = "保存信息";
            uiOnCancel = this.ScanResultProcess.bind(this, false);
        }
        let uiResultDlg = null;
        if (this.state.bScanResultDlg) {
            let uiTitle = (
                <div className="package_scan_result_dlg_title">
                    扫描结果
                    <div className="package_scan_result_dlg_close_button" onClick={this.RestartScan}>x</div>
                </div>
            );
            uiResultDlg = (
                <Modal title={uiTitle} visible={this.state.bScanResultDlg} centered={true}
                    cancelText={strCancelButton} okText={strOkButtonText}
                    closable={false} maskClosable={false} width={'95vw'}
                    onOk={this.ScanResultProcess.bind(this, true)} onCancel={uiOnCancel} >
                    <div className="package_scan_item_info_title">{"快递单号：" + strResultOrder}</div>
                    {uiPackageCombine}
                    {uiShipDate}
                    {uiItemType}
                    {uiEnterNo}
                    {uiReceiver}
                    {uiStorageCode}
                    {uiComplete}
                    {uiItemInfo}
                </Modal>
            );
            uiScanArea = null;
        }
        let uiSubmitDlg = null;
        if (this.state.bSubmitResultDlg) {
            let strSubmitResult = "";
            let strOkButtonText = "好的";
            if (this.state.iSubmitResult === 1) {
                strSubmitResult = "快递 " + this.objExpressEnter.express_order + " 签收完成！";
            }
            if (this.state.iSubmitResult === 2) {
                strSubmitResult = "快递 " + this.objExpressEnter.express_order + " 签收完成！";
            }
            if (this.state.iSubmitResult === 3) {
                strSubmitResult = "快递 " + this.objExpressEnter.express_order + " 信息更新完成！";
            }
            if (this.state.iSubmitResult === 4) {
                strSubmitResult = "快递 " + this.objExpressEnter.express_order + " 合箱完成！";
            }
            if (this.state.iSubmitResult === -1) {
                strSubmitResult = "快递 " + this.objExpressEnter.express_order + " 签收失败，请重新扫描！";
                strOkButtonText = "再试一次";
            }
            if (this.state.iSubmitResult === -2) {
                strSubmitResult = "快递 " + this.objExpressEnter.express_order + " 合箱完成，请重新扫描！";
                strOkButtonText = "再试一次";
            }
            uiSubmitDlg = (
                <Modal title="提交结果" visible={this.state.bSubmitResultDlg} centered={true} okText={strOkButtonText} cancelText={"取消"} closable={false} maskClosable={false}
                    onOk={this.RestartScan} onCancel={this.RestartScan} >
                    {strSubmitResult}
                </Modal>
            );
            uiScanArea = null;
        }
        let uiOgeScanResult = (
            <div className="code_scanner_oge_result">{this.state.strOgeScanResult}</div>
        );

        if (this.state.iScanMethod === 0)
            uiOgeScanResult = null;

        return (
            <div className="code_scanner_page">
                <div className="code_scanner_title">
                    <div className={strQRButtonStyle} onClick={this.SwitchScanMethod.bind(this, 0)}>扫码枪</div>
                    <div className={strBARButtonStyle} onClick={this.SwitchScanMethod.bind(this, 1)}>条形码</div>
                    {/* <div className={strQRButtonStyle} onClick={this.SwitchScanMethod.bind(this, 0)}>二维码</div> */}
                    <div className={strPhotoButtonStyle} onClick={this.SwitchScanMethod.bind(this, 2)}>拍照</div>
                </div>
                {uiScanArea}
                {uiOgeScanResult}
                {uiResultDlg}
                {uiSubmitDlg}
            </div>
        );

    }
}
export default PackageScanner;
