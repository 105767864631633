import React, { Component } from "react";
import axios from 'axios';

// import Statistic from 'antd/es/statistic';
import Icon from 'antd/es/icon';
import Spin from 'antd/es/spin';
import Table from 'antd/es/table';
import Button from 'antd/es/button';
import Input from 'antd/es/input';
import Modal from 'antd/es/modal';
import Dropdown from 'antd/es/dropdown';
import Menu from 'antd/es/menu';
import message from 'antd/es/message';

import '../../../styles/Background/ego_manager.css';
import '../../../styles/Background/UI_Module/user_management.css';

import '../../../styles/icon_image.css';
import '../../../styles/Background/ego_manager.css';
import '../../../styles/Background/store_manager.css';
import '../../../styles/Background/UI_Module/general_data_show.css';

const g_strMessageKey = 'updating';
global.arrUserData = [];

// const { MonthPicker } = DatePicker;


// 表格
const arrUserTableHeader = [
    // { title: 'ID', dataIndex: 'id', width: 20, ellipsis: true },
    { title: '邮箱', dataIndex: 'email', width: 150, ellipsis: true },
    { title: '编码', dataIndex: 'code', width: 70, ellipsis: true },
    { title: '等级', dataIndex: 'level_str', width: 90, ellipsis: true },
    // { title: '积分', dataIndex: 'credit', width: 75, ellipsis: true },
    { title: '余额', dataIndex: 'money', width: 75, ellipsis: true },
    { title: '折扣', dataIndex: 'discount_str', width: 50, ellipsis: true },
    { title: '姓名', dataIndex: 'name', width: 75, ellipsis: true },
    { title: '电话', dataIndex: 'phone', width: 75, ellipsis: true }
    // { title: '备注', dataIndex: 'comments', width: 150, ellipsis: true }
    // { title: 'UPS单量', dataIndex: 'ups_order', width: 50, ellipsis: true },
    // { title: '加邮单量', dataIndex: 'cp_order', width: 50, ellipsis: true },
];
const arrClientTableHeader = [
    { title: '邮箱', dataIndex: 'email', width: 150, ellipsis: true },
    { title: '姓名', dataIndex: 'name', width: 75, ellipsis: true },
    { title: '电话', dataIndex: 'phone', width: 75, ellipsis: true }
];

class ShipOrderStatistic extends Component {
    constructor(props) {
        super();
        this.state = {
            bLoading: true,
            btnControlDisable: [false, true, true],
            selectedRowKeys: [],
            iDlgType: -1,
            bShowDlg: false,
            iCurrentLevel: 0
        };

        // 商家后台统计数据
        this.iUPSOrder = 0;  // 当月单量
        this.iCPOrder = 0;  // 总单量

        // 成员变量
        this.arrOgeTableData = [];
        this.arrTableData = [];
        this.objCurrentUser = {
            credit: 0,
            money: "0.0"

        };
        this.arrLevel = ['Personal', 'Small Business', 'Logistic Partner', 'Agent', 'Manager', 'Super Manager'];
        this.arrVIP = ['MB', 'VIP', 'VVIP', 'SVIP', 'SSVIP', 'CRVIP', 'SCRVIP', 'FLAT'];
    }

    // 商家单量/流水等统计
    OrderCalculate = () => {
        axios('/GetUPSCPOrders', { params: { store_id: this.props.iStoreID, user_id: this.props.iUserID, level: this.props.iLevel } })
            .then(({ data }) => {
                this.iUPSOrder = data.ups;
                this.iCPOrder = data.cp;
                this.setState({
                    ...this.state,
                    bLoading: false
                });
            }).catch(function (error) { console.log(error); });
    }
    // 选中某行的响应函数
    selectRow = (record) => {
        const selectedRowKeys = [...this.state.selectedRowKeys];
        if (selectedRowKeys.indexOf(record.key) >= 0) {
            selectedRowKeys.splice(selectedRowKeys.indexOf(record.key), 1);
        } else {
            selectedRowKeys.push(record.key);
        }
        this.SelectedRowStateSetting(selectedRowKeys);
    }
    // 选中的行发生变化的响应函数
    onSelectedRowKeysChange = (selectedRowKeys) => {
        this.SelectedRowStateSetting(selectedRowKeys);
    }
    // 处理选中的表行行
    SelectedRowStateSetting = (selectedRowKeys) => {
        let btnControlDisable = [];
        switch (selectedRowKeys.length) {
            case 0: {
                btnControlDisable = [false, true, true];
                this.objCurrentUser = {};
                break;
            }
            case 1: {
                btnControlDisable = [false, false, false];
                for (let i = 0; i < this.arrTableData.length; i++) {
                    if (this.arrTableData[i].key === selectedRowKeys[0]) {
                        this.objCurrentUser = this.arrTableData[i];
                        break;
                    }
                }
                break;
            }
            default: {
                btnControlDisable = [false, true, true]; break;
            }
        }
        this.setState({
            ...this.state,
            selectedRowKeys: selectedRowKeys,
            btnControlDisable: btnControlDisable
        });
    }
    // 关键词处理
    KeywordsProcess = (strKeywords) => {
        let newKeywords = "";
        for (let i = 0; i < strKeywords.length; i++) {
            if (strKeywords[i] !== " ") {
                if (i === strKeywords.length - 1)
                    newKeywords += strKeywords[i];
                else
                    newKeywords += strKeywords[i] + "%";
            }
        }
        return newKeywords;
    }
    // 搜索用户名
    UserNameSearch = () => {
        let strKeywords = this.refs.name_search.state.value;
        if (strKeywords === undefined || strKeywords === null)
            strKeywords = "";
        if (strKeywords.trim() === "") {
            this.GetUsersData();
        } else {
            this.setState({
                ...this.state,
                bLoading: true,
                selectedRowKeys: [],
                btnControlDisable: [false, true, true]
            });
            axios('/SearchJetonUser', { params: { name: this.KeywordsProcess(strKeywords) } })
                .then(({ data }) => {
                    this.arrTableData = this.OrganizeUsersData(data);
                    this.setState({
                        ...this.state,
                        bLoading: false
                    });
                }).catch(function (error) { console.log(error); });
        }
    }
    //弹出对话框
    ShowDlg = (iDlgType, e) => {
        if (iDlgType === 0) {
            this.objCurrentUser = { credit: 0, money: "0.0" };
            if (this.refs.name !== undefined) {
                this.refs.name.state.value = '';
                this.refs.phone.state.value = '';
                this.refs.email.state.value = '';
                // this.refs.credit.state.value = 0;
                this.refs.money.state.value = '0.0';
                this.refs.discount.state.value = '0.0';
                this.refs.comments.state.value = '';
            }
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                iCurrentLevel: 0,
                bShowDlg: true
            });
        }
        if (iDlgType === 1) {
            if (this.refs.name !== undefined) {
                this.refs.name.state.value = this.objCurrentUser.name;
                this.refs.phone.state.value = this.objCurrentUser.phone;
                this.refs.email.state.value = this.objCurrentUser.email;
                // this.refs.credit.state.value = this.objCurrentUser.credit;
                this.refs.money.state.value = this.objCurrentUser.money;
                this.refs.discount.state.value = this.objCurrentUser.discount;
                this.refs.comments.state.value = this.objCurrentUser.comments;
            }
            console.log("current user : ", this.objCurrentUser);
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                iCurrentLevel: this.objCurrentUser.level,
                bShowDlg: true
            });
        }
        if (iDlgType === 2) {
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true
            });
        }
    }
    // 弹窗确认按钮响应
    handleOk = e => {
        if (this.state.iDlgType === 0) {
            let strEmail = this.refs.email.state.value;
            let strName = this.refs.name.state.value;
            let strPhone = this.refs.phone.state.value;

            let strCompanyName = this.refs.company_name.state.value;
            let strCompanyAddress = this.refs.company_address.state.value;

            let strMoney = this.refs.money.state.value;
            // let strCredit = this.refs.credit.state.value;
            let strDiscount = this.refs.discount.state.value;
            let strComments = this.refs.comments.state.value;

            if (strEmail === undefined || strEmail === null || strEmail === "") {
                message.warning('邮箱不能为空！'); return;
            }
            if (strName === undefined || strName === null || strName === "") {
                message.warning("姓名不能为空！"); return;
            }
            if (strPhone === undefined || strPhone === null || strPhone === "") {
                strPhone = "";
                // message.warning('电话不能为空！'); return;
            }
            // if (strCredit === undefined || strCredit === null || strCredit === "") {
            //     strCredit = 0;
            // }
            if (strMoney === undefined || strMoney === null || strMoney === "") {
                strMoney = "0.0";
            }
            if (strDiscount === undefined || strDiscount === null || strDiscount === "") {
                strDiscount = "0.0";
            }
            if (strComments === undefined || strComments === null) {
                strComments = "";
            }

            let objNewUser = {
                name: strName,
                phone: strPhone,
                email: strEmail,
                company_name: strCompanyName,
                company_address: strCompanyAddress,
                code: "",
                password: '123456',
                level: this.state.iCurrentLevel,
                discount: strDiscount,
                credit: 0,
                money: strMoney,
                comments: strComments
            }
            console.log("new user : ", objNewUser);
            // return;
            message.loading({ content: '正在创建用户……', key: g_strMessageKey });
            axios.post('/NewJetonUser', objNewUser).then(({ data }) => {
                console.log(data);
                if (data.status === 1) {
                    this.GetUsersData();
                }
                else
                    message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
            }).catch(function (error) { console.log(error); });
        }
        if (this.state.iDlgType === 1) {
            let strEmail = this.refs.email.state.value;
            let strName = this.refs.name.state.value;
            let strPhone = this.refs.phone.state.value;

            let strCompanyName = this.refs.company_name.state.value;
            let strCompanyAddress = this.refs.company_address.state.value;

            // let strCredit = this.refs.credit.state.value;
            let strMoney = this.refs.money.state.value;
            let strDiscount = this.refs.discount.state.value;
            let strComments = this.refs.comments.state.value;

            if (strEmail === undefined || strEmail === null || strEmail === "") {
                message.warning('邮箱不能为空！'); return;
            }
            if (strName === undefined || strName === null || strName === "") {
                message.warning("姓名不能为空！"); return;
            }
            if (strPhone === undefined || strPhone === null || strPhone === "") {
                strPhone = "";
                // message.warning('电话不能为空！'); return;
            }
            // if (strCredit === undefined || strCredit === null || strCredit === "") {
            //     strCredit = 0;
            // }
            if (strMoney === undefined || strMoney === null || strMoney === "") {
                strMoney = "0.0";
            }
            if (strDiscount === undefined || strDiscount === null || strDiscount === "") {
                strDiscount = "0.0";
            }
            if (strComments === undefined || strComments === null) {
                strComments = "";
            }

            let objNewUser = {
                id: this.objCurrentUser.id,
                name: strName,
                phone: strPhone,
                email: strEmail,
                company_name: strCompanyName,
                company_address: strCompanyAddress,
                code: this.objCurrentUser.code,
                password: '123456',
                level: this.state.iCurrentLevel,
                discount: strDiscount,
                credit: 0,
                money: strMoney,
                comments: strComments
            }
            console.log("new user : ", objNewUser);
            console.log("current user : ", this.objCurrentUser);
            // return;
            message.loading({ content: '用户 更新中……', key: g_strMessageKey });
            axios.post('/UpdateJetonUser', {
                objCurrentUser: this.objCurrentUser, objNewUser: objNewUser
            }).then(({ data }) => {
                console.log("UpdateJetonUser response : ", data);
                if (data.status === 1) {
                    this.props.UserNameUpdate(objNewUser.name, this.objCurrentUser.name);  // 更新主页菜单栏右上角的登录用户名
                    this.GetUsersData();
                }
                else
                    message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
            }).catch(function (error) { console.log(error); });
        }
        if (this.state.iDlgType === 2) {
            message.loading({ content: '用户 删除中……', key: g_strMessageKey });
            axios.post('/DeleteJetonUser', { id: this.objCurrentUser.id })
                .then(({ data }) => {
                    if (data.status === -1) {
                        message.warning({ content: "删除失败，请重新尝试！", key: g_strMessageKey, duration: 2 });
                        this.setState({
                            ...this.state,
                            bLoading: false,
                            bShowDlg: false,
                            selectedRowKeys: []
                        });
                    } else {
                        this.GetUsersData();
                    }
                }).catch(function (error) { console.log(error); });
        }
    };
    // 弹窗取消按钮响应
    handleCancel = e => {

        this.setState({
            ...this.state,
            bShowDlg: false,
            selectedRowKeys: []
        });
    };
    // 整理User数据
    OrganizeUsersData(data) {
        let arrTableData = [];
        for (let i = 0; i < data.length; i++) {
            let strCredit = data[i].credit;
            if (strCredit === null || strCredit === undefined || strCredit === '')
                strCredit = 0;

            let strMoney = data[i].money;
            if (strMoney === null || strMoney === undefined || strMoney === '')
                strMoney = 0;

            let strComments = data[i].comments;
            if (strComments === null || strComments === undefined)
                strComments = "";

            let strLevel = "";
            let iLevel = parseInt(data[i].level);
            if (data[i].level < 4)
                strLevel = this.arrLevel[iLevel];
            else {
                if (iLevel >= 9) {
                    strLevel = this.arrLevel[iLevel - 5];
                    iLevel -= 5;
                }
                else
                    strLevel = this.arrLevel[iLevel];
            }


            let strDiscount = "无";
            if (data[i].discount !== undefined && data[i].discount !== null && data[i].discount !== "")
                strDiscount = data[i].discount + " %";

            let objUserData = {
                key: i, id: data[i].id,
                store_id: data[i].store_id,
                name: data[i].name,
                phone: data[i].phone,
                email: data[i].email,
                level: iLevel,
                level_str: strLevel,
                code: data[i].code,
                credit: strCredit,
                money: strMoney,
                discount: data[i].discount,
                discount_str: strDiscount,
                comments: strComments
            }
            arrTableData.push(objUserData);
        }
        return arrTableData;
    }
    // 从数据库获取当前的数据结构
    GetUsersData = () => {
        message.loading({ content: '数据加载中……', key: g_strMessageKey });
        let iClient = 0;
        if (this.props.bClient)
            iClient = 1;
        let objParms = {
            store_id: this.props.iStoreID,
            level: this.props.iLevel,
            code: this.props.strUserCode,
            client: iClient
        }
        console.log("Get Jeton Users Data : ", objParms);
        axios('/GetJetonUsers', { params: objParms })
            .then(({ data }) => {
                console.log("GetJetonUsers : ", data);
                global.arrUserData = data;
                this.arrOgeTableData = this.OrganizeUsersData(data);
                this.arrTableData = this.arrOgeTableData;
                // if (this.state.bShowDlg) {
                //     if (this.state.iDlgType === 0) {
                //         message.success({ content: "新建用户成功！", key: g_strMessageKey, duration: 2 });
                //     }
                //     if (this.state.iDlgType === 1) {
                //         message.success({ content: "用户信息已更新！", key: g_strMessageKey, duration: 2 });
                //     }
                //     if (this.state.iDlgType === 2) {
                //         message.success({ content: "用户已删除！", key: g_strMessageKey, duration: 2 })
                //     }
                // } else {
                //     message.success({ content: "数据加载完成！", key: g_strMessageKey, duration: 2 });
                // }
                this.setState({
                    ...this.state,
                    bLoading: false,
                    bShowDlg: false,
                    selectedRowKeys: []
                });
            }).catch(function (error) { console.log(error); });
    }
    // 显示全部用户
    ShowAllUsers = () => {
        this.refs.name_search.state.value = "";
        this.UserNameSearch();
    }
    // 切换VIP 等级
    SwitchVIPLevel = (index) => {
        this.setState({
            ...this.state,
            iCurrentLevel: index
        });
    }
    // 切换用户 等级
    SwitchUserLevel = (index) => {
        this.setState({
            ...this.state,
            iCurrentLevel: index
        });
    }
    // 从数据库获取当前的数据结构
    GetVipPriceData = () => {
        message.loading({ content: '数据加载中……', key: g_strMessageKey });
        axios('/GetVipPrice', { params: { store_id: this.props.iStoreID } })
            .then(({ data }) => {
                console.log(data);
                global.arrVipData = data;
                this.arrVIP = [];
                if (global.arrVipData.length >= 1) {
                    for (let i = global.arrVipData.length - 1; i >= 0; i--)
                        this.arrVIP.push(global.arrVipData[i].name);
                }
            }).catch(function (error) { console.log(error); });
    }
    // 卸载异步操作设置状态
    componentWillUnmount() {
        clearTimeout(this.timeouter)
        this.setState = (state, callback) => {
            return;
        }
    }
    // 渲染完毕……
    componentDidMount() {

        this.GetUsersData();
        // if (global.arrVipData.length <= 0)
        //     this.GetVipPriceData();
        // else {
        //     this.arrLevel = [];
        //     this.arrVip = [];
        //     if (global.arrVipData.length >= 1) {
        //         for (let i = global.arrVipData.length - 1; i >= 0; i--) {
        //             this.arrLevel.push(global.arrVipData[i].name);
        //         }
        //     }
        // }
    }

    render() {


        let uiContents = null;
        let uiModal = null;
        let uiNewButton = null;

        if (!this.state.bLoading) {
            let bDisabled = true;
            if (this.props.iLevel >= 9) {
                bDisabled = false;
                uiNewButton = (
                    <Button className="manage_contents_title_margin" type="primary" onClick={this.ShowDlg.bind(this, 0)} disabled={this.state.btnControlDisable[0]}>
                        <Icon type="edit" />新建</Button>
                );
            }

            // 主体表格相关
            const { selectedRowKeys } = this.state;
            const rowSelection = {
                selectedRowKeys,
                onChange: this.onSelectedRowKeysChange,
                columnWidth: 30
            };
            // 表格分页属性
            const paginationProps = {
                showSizeChanger: true,
                showQuickJumper: false,
                showTotal: () => `共 ${this.arrTableData.length} 个 用户`,
                defaultPageSize: 10,
                defaultCurrent: this.state.iCurrentPage,
                pageSizeOptions: ['10', '20', '30', '40', '50'],
                total: this.arrTableData.length,
                position: 'bottom'
            };
            // VIP等级下拉菜单
            // const menuVIPLevel = (
            //     <Menu>
            //         {this.arrVIP.map((level, index) => {
            //             return (
            //                 <Menu.Item key={index} onClick={this.SwitchVIPLevel.bind(this, index)}>
            //                     {level}
            //                 </Menu.Item>
            //             );
            //         })}
            //     </Menu>
            // );
            // 用户等级下拉菜单
            const menuUserLevel = (
                <Menu>
                    {this.arrLevel.map((level, index) => {
                        return (
                            <Menu.Item key={index} onClick={this.SwitchUserLevel.bind(this, index)}>
                                {level}
                            </Menu.Item>
                        );
                    })}
                </Menu>
            );

            let uiDlgTitle = (
                <div className="jeton_area_title" >用户信息</div>
            );
            if (this.state.iDlgType === 1) {
                uiDlgTitle = (
                    <div className="jeton_area_title" >{"用户信息 - 编码：" + this.objCurrentUser.code}</div>
                );

            }

            // 用户信息编辑弹窗
            uiModal = (
                <Modal title={uiDlgTitle} width={999} visible={this.state.bShowDlg}
                    okText="确定" cancelText="取消" onOk={this.handleOk} onCancel={this.handleCancel} >
                    <div className="jeton_order_block">
                        <div className="jeton_order_row">
                            <div className="jeton_order_row_title jeton_order_row_title_require" >邮箱：</div>
                            <Input className="jeton_order_row_input" ref="email"
                                defaultValue={this.objCurrentUser.email} />
                            <div className="jeton_order_row_title jeton_order_row_title_require" >姓名：</div>
                            <Input className="jeton_order_row_input" ref="name"
                                defaultValue={this.objCurrentUser.name} />
                            <div className="jeton_order_row_title" >电话：</div>
                            <Input className="jeton_order_row_input" ref="phone"
                                defaultValue={this.objCurrentUser.phone} />
                        </div>
                        <div className="jeton_order_row">
                            <div className="jeton_order_row_title" >等级：</div>
                            <Dropdown overlay={menuUserLevel} trigger={['click']} placement="bottomLeft"
                                className={"jeton_order_row_input"} disabled={bDisabled}
                                overlayStyle={{ maxHeight: '75vh', overflow: 'auto' }} style={{ maxHeight: '75vh', overflow: 'auto' }}>
                                <Button className="jeton_order_row_input">
                                    <div className="order_details_dropdown_text">{this.arrLevel[this.state.iCurrentLevel]}</div>
                                    <Icon type="caret-down" />
                                </Button>
                            </Dropdown>
                            {/* <div className="jeton_order_row_title" >积分：</div>
                            <Input className="jeton_order_row_input" ref="credit" disabled={bDisabled}
                                defaultValue={this.objCurrentUser.credit} /> */}
                            <div className="jeton_order_row_title" >余额：</div>
                            <Input className="jeton_order_row_input" ref="money" disabled={bDisabled}
                                defaultValue={this.objCurrentUser.money} addonBefore="CAD" />
                            <div className="jeton_order_row_title" >折扣率</div>
                            <Input className="jeton_order_row_input" ref="discount" disabled={bDisabled}
                                defaultValue={this.objCurrentUser.discount} addonAfter={"%"} />
                        </div>
                        <div className="jeton_order_row">
                            <div className="jeton_order_row_title" >公司：</div>
                            <Input className="jeton_order_row_input" ref="company_name"
                                defaultValue={this.objCurrentUser.company_name} />
                            <div className="jeton_order_row_title" >地址：</div>
                            <Input className="jeton_order_row_input_long" ref="company_address"
                                defaultValue={this.objCurrentUser.company_address} />
                        </div>
                        <div className="jeton_order_row">
                            <div className="jeton_order_row_title" >备注：</div>
                            <Input className="jeton_order_row_input_full" ref="comments"
                                defaultValue={this.objCurrentUser.comments} />
                        </div>
                    </div>
                </Modal>
            );
            if (this.props.bClient) {
                uiModal = (
                    <Modal title={uiDlgTitle} width={999} visible={this.state.bShowDlg} footer={null} onCancel={this.handleCancel} >
                        <div className="jeton_order_block">
                            <div className="jeton_order_row">
                                <div className="jeton_order_row_title jeton_order_row_title_require" >邮箱：</div>
                                <Input className="jeton_order_row_input" ref="email" disabled={true}
                                    defaultValue={this.objCurrentUser.email} />
                                <div className="jeton_order_row_title jeton_order_row_title_require" >姓名：</div>
                                <Input className="jeton_order_row_input" ref="name" disabled={true}
                                    defaultValue={this.objCurrentUser.name} />
                                <div className="jeton_order_row_title" >电话：</div>
                                <Input className="jeton_order_row_input" ref="phone" disabled={true}
                                    defaultValue={this.objCurrentUser.phone} />
                            </div>
                            <div className="jeton_order_row">
                                <div className="jeton_order_row_title" >等级：</div>
                                <Dropdown overlay={menuUserLevel} trigger={['click']} placement="bottomLeft"
                                    className={"jeton_order_row_input"} disabled={true}
                                    overlayStyle={{ maxHeight: '75vh', overflow: 'auto' }} style={{ maxHeight: '75vh', overflow: 'auto' }}>
                                    <Button className="jeton_order_row_input">
                                        <div className="order_details_dropdown_text">{this.arrLevel[this.state.iCurrentLevel]}</div>
                                        <Icon type="caret-down" />
                                    </Button>
                                </Dropdown>
                                {/* <div className="jeton_order_row_title" >积分：</div>
                                <Input className="jeton_order_row_input" ref="credit" disabled={true}
                                    defaultValue={this.objCurrentUser.credit} /> */}
                                <div className="jeton_order_row_title" >余额：</div>
                                <Input className="jeton_order_row_input" ref="money" disabled={bDisabled}
                                    defaultValue={this.objCurrentUser.money} addonBefore="CAD" />
                                <div className="jeton_order_row_title" >折扣率</div>
                                <Input className="jeton_order_row_input" ref="discount" disabled={true}
                                    defaultValue={this.objCurrentUser.discount} addonAfter={"%"} />
                            </div>
                            <div className="jeton_order_row">
                                <div className="jeton_order_row_title" >公司：</div>
                                <Input className="jeton_order_row_input" ref="company_name" disabled={true}
                                    defaultValue={this.objCurrentUser.company_name} />
                                <div className="jeton_order_row_title" >地址：</div>
                                <Input className="jeton_order_row_input_long" ref="company_address" disabled={true}
                                    defaultValue={this.objCurrentUser.company_address} />
                            </div>
                            <div className="jeton_order_row">
                                <div className="jeton_order_row_title" >备注：</div>
                                <Input className="jeton_order_row_input_full" ref="comments" disabled={true}
                                    defaultValue={this.objCurrentUser.comments} />
                            </div>
                        </div>
                    </Modal>
                );
            }
            if (this.state.iDlgType === 2) {
                uiModal = (
                    <Modal width={500} title={"删除用户"} visible={this.state.bShowDlg} okType="danger"
                        onOk={this.handleOk} okText="确定"
                        onCancel={this.handleCancel} cancelText="取消">
                        <div className="modal_item">
                            <div>{this.objCurrentUser.name}
                                <br /><br />选中用户的所有信息将被删除……<br />确定删除选中的用户？
                            </div>
                        </div>
                    </Modal>
                );
            }

            let arrTableHeader = arrUserTableHeader;
            if (this.props.bClient)
                arrTableHeader = arrClientTableHeader;
            uiContents = (
                <Table style={{ margin: '10px', marginLeft: '25px', width: '95%', overflowX: 'auto' }} bordered
                    rowSelection={rowSelection} pagination={paginationProps}
                    columns={arrTableHeader} dataSource={this.arrTableData}
                    onRow={(record) => ({ onClick: () => { this.selectRow(record); } })} />
            );
        } else {
            uiContents = (<Spin className="manage_empty_loading" size="large" tip="加载中……" />);
        }
        let uiSearch = (<div className="manage_stretch_right">
            <Input placeholder="搜索姓名/电话/邮箱" ref="name_search" defaultValue={this.state.strKeywords} />
            <Button className="manage_contents_title_margin"
                type="primary" onClick={this.UserNameSearch}>搜索
            </Button>
        </div>);
        let uiShowAllButton = (
            <Button className="manage_contents_title_margin manage_contents_title_margin_right" type="primary" onClick={this.ShowAllUsers}>
                显示全部<Icon type="redo" /></Button>
        );
        let uiEditButton = (<Button className="manage_contents_title_margin" type="primary" onClick={this.ShowDlg.bind(this, 1)}
            disabled={this.state.btnControlDisable[1]}> <Icon type="edit" />编辑</Button>);
        let uiDeleteButton = (<Button className="manage_contents_title_margin" type="danger" onClick={this.ShowDlg.bind(this, 2)}
            disabled={this.state.btnControlDisable[2]}> <Icon type="close" />删除</Button>);
        if (this.props.bClient) {
            uiShowAllButton = null;
            uiSearch = null;
            uiEditButton = (<Button className="manage_contents_title_margin" type="primary" onClick={this.ShowDlg.bind(this, 1)}
                disabled={this.state.btnControlDisable[1]}>
                <Icon type="edit" />查看</Button>);
            uiDeleteButton = null;
        }
        else {
            if (this.props.iLevel < 9) {
                uiShowAllButton = null;
                uiSearch = null;
                // uiEditButton = (<Button className="manage_contents_title_margin" type="primary" onClick={this.ShowDlg.bind(this, 1)}
                //     disabled={this.state.btnControlDisable[1]}>
                //     <Icon type="edit" />编辑</Button>);
                uiDeleteButton = null;
            }
        }


        let uiTitleArea = (
            < div className="store_contents_title" >
                {uiShowAllButton}
                {uiSearch}
                <div className="manage_title_button_right_side">
                    {uiNewButton}
                    {uiEditButton}
                    {uiDeleteButton}
                </div>
            </div >
        );

        return (
            <div className="store_manage_contents store_manage_top_space" /*style={{ height: '100%' }}*/>
                {uiTitleArea}
                {uiModal}
                {uiContents}
            </div >
        );
    }
}
export default ShipOrderStatistic;
