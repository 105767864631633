import React, { Component } from "react";
import axios from 'axios';
import cookie from 'react-cookies';
import "antd/dist/antd.css";
import "../../styles/Background/db_manager.css";
import "../../styles/Background/jeton_services.css";

// import Empty from 'antd/es/empty';
import Spin from 'antd/es/spin';
import Menu from 'antd/es/menu';
import Icon from 'antd/es/icon';
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import Form from 'antd/es/form';
import message from 'antd/es/message';

import JetonExpressOrder from "./ManagerPage/JetonExpressOrder.js";
import CPShippingOrder from "./ManagerPage/CPShippingOrder.js";
import UPSShippingOrder from "./ManagerPage/UPSShippingOrder.js";
import ShipOrderStatistic from "./ManagerPage/ShipOrderStatistic.js";
import DeliveryFeeCalculator from "./ManagerPage/DeliveryFeeCalculator.js";
import AddressBookManager from "./ManagerPage/AddressBookManager.js";

const g_strMessageKey = 'updating';

function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class JetonService extends Component {
    // 构造函数
    constructor(props) {
        super();

        this.iUserID = 0;
        this.strUserCode = "";
        this.iStoreID = 99999;
        let strDomain = window.location.href;
        console.log("strDomain = " + strDomain);
        this.strInviteCode = "";
        let bLoginPage = true;
        if (strDomain.indexOf("?invite=") >= 0) {
            this.strInviteCode = strDomain.split('?invite=')[1];
            console.log("this.strInviteCode = " + this.strInviteCode);
            bLoginPage = false;
        }

        this.state = {
            bLoading: true,  // 加载状态
            bLoginStatus: false,  // 登录状态
            bLoginPage: bLoginPage,
            iCurrentSideBarIndex: 0,  // 当前显示的中心区域内容索引
        };
        this.arrSideMenuButton = [
            { id: 0, name: "快速估价", icon: "calculator" },
            { id: 1, name: "正式下单", icon: "snippets" },
            { id: 2, name: "UPS运单", icon: "code-sandbox" },
            { id: 3, name: "加邮运单", icon: "code-sandbox" },
            { id: 4, name: "地址信息", icon: "profile" },
            { id: 5, name: '用户信息', icon: 'account-book' },
            { id: 6, name: '关联用户', icon: 'link' }
        ];
        this.arrSideMenuButtonManager = [
            { id: 0, name: "快速估价", icon: "calculator" },
            { id: 1, name: "正式下单", icon: "snippets" },
            { id: 2, name: "UPS运单", icon: "code-sandbox" },
            { id: 3, name: "加邮运单", icon: "code-sandbox" },
            { id: 4, name: "地址信息", icon: "profile" },
            { id: 5, name: '用户信息', icon: 'account-book' }
        ]
        this.strCurrentUserName = '';
        this.strCurrentUserCode = '';
        this.iLevel = 0;
        this.fMoney = 0.00;
        this.iVIP = 0;
        this.iCurrentSideBarIndex = 0;
        this.iSelectedOrderType = 0;
    }

    // 点击注册按钮
    SwitchLoginRegistButtonClick = (bLogin) => {
        // console.log("SwitchLoginRegistButtonClick: " + bLogin);
        this.setState({
            ...this.state,
            bLoginPage: bLogin
        });
    }
    // 切换侧边栏菜单
    SwitchSideMenu = (iMenuID) => {
        this.iCurrentSideBarIndex = iMenuID;
        this.setState({
            ...this.state,
            bLoading: false,
            iCurrentSideBarIndex: iMenuID
        });
    }
    // 登录表单提交响应
    LoginSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                message.loading({ content: '登录中……', key: g_strMessageKey });
                this.setState({
                    ...this.state,
                    bLoading: true
                });
                this.TryLogin(values.name, values.password)
            }
        });
    };
    // 尝试登录
    TryLogin = (name, password) => {
        let objUserInfo = { name: name, password: password };
        if (name === "" || password === "" ||
            name === null || password === null ||
            name === undefined || password === undefined) {
            message.warning({ content: "登录邮箱/密码不能为空！", key: g_strMessageKey, duration: 2 });
            this.setState({
                ...this.state,
                bLoginStatus: false,
                bLoading: false
            });
            return;
        }

        axios('/JetonServiceUserLogin', { params: objUserInfo })
            .then(({ data }) => {
                console.log("JetonServiceUserLogin : ", data);
                if (data.status === -1) {
                    message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
                    this.setState({
                        ...this.state,
                        bLoginStatus: false,
                        bLoading: false
                    });
                }
                else {
                    message.success({ content: data.message, key: g_strMessageKey, duration: 2 });
                    this.strCurrentUserName = data.name;
                    this.iUserID = data.id;
                    this.strUserCode = data.code;
                    this.iLevel = data.level;
                    if (this.iLevel >= 4)
                        this.iLevel += 5;
                    // this.iVIP = data.vip;
                    if (data.money !== undefined && data.money !== null && data.money !== "" && !isNaN(data.money)) {
                        this.fMoney = parseFloat(data.money);
                        this.fMoney = (this.fMoney).toFixed(2);
                    }
                    console.log("该用户余额 = " + this.fMoney);
                    cookie.save('jeton_user_code', data.code, { path: '/' });
                    cookie.save('jeton_login_status', 1, { path: '/' });
                    this.setState({
                        ...this.state,
                        bLoading: false,
                        bLoginStatus: true
                    });
                    this.SwitchSideMenu(this.state.iCurrentSideBarIndex);
                }
            }).catch(function (error) { console.log(error); });
    }
    // 退出登录
    LogoutClick = () => {
        cookie.remove('jeton_user_code', { path: '/' });
        cookie.save('jeton_login_status', -1, { path: '/' });
        this.setState({
            ...this.state,
            bLoginStatus: false,
            bLoginPage: true
        });
    }
    // 用户识别码检查
    UserCodeCheck = () => {
        console.log("JetonUserCodeCheck user code : ", this.strCurrentUserCode);
        axios('/JetonUserCodeCheck', { params: { code: this.strCurrentUserCode } })
            .then(({ data }) => {
                console.log("ShipUserCodeCheck response : ", data);
                if (data.status < 0) {
                    message.warning(data.message);
                    this.setState({
                        ...this.state,
                        bLoading: false
                    });
                } else {
                    message.success(data.message);
                    this.strCurrentUserName = data.user.name;
                    this.strUserCode = data.user.code;
                    this.iUserID = data.user.id;
                    this.iLevel = data.user.level;
                    if (this.iLevel >= 4)
                        this.iLevel += 5;
                    if (data.user.money !== undefined && data.user.money !== null && data.user.money !== "" && !isNaN(data.user.money)) {
                        this.fMoney = parseFloat(data.user.money);
                        this.fMoney = (this.fMoney).toFixed(2);

                    }
                    console.log("该用户余额 = " + this.fMoney);
                    this.setState({
                        ...this.state,
                        bLoading: false,
                        bLoginStatus: true
                    });
                }
            }).catch(function (error) { console.log(error); });
    }
    // 注册提交按钮响应
    handleRegisterSubmit = e => {
        console.log("RegisterButtonClicked");
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                // 邮箱
                if (values.email === undefined || values.email === null || values.email === '') {
                    message.warning("请填写邮箱！")
                    return;
                }
                if (values.email.trim() === "") {
                    message.warning("请填写邮箱！")
                    return;
                }
                // 用户名
                if (values.name === undefined || values.name === null || values.name === '') {
                    message.warning("请填写用户名！")
                    return;
                }
                if (values.name.trim() === "") {
                    message.warning("请填写用户名！")
                    return;
                }
                // 密码
                if (values.password === undefined) {
                    message.warning("请设置密码！")
                    return;
                } else {
                    if (values.password.trim() === "") {
                        message.warning("请设置密码！")
                        return;
                    }
                }
                if (values.password !== values.confirm) {
                    message.warning("两次输入密码不一致！")
                    return;
                }
                if (values.password.length < 6) {
                    message.warning("密码太短，请大于6位！")
                    return;
                }

                let objNewUser = {};
                objNewUser.email = values.email;
                objNewUser.name = values.name;
                objNewUser.password = values.password;
                objNewUser.level = 0;
                objNewUser.credit = 0;
                objNewUser.discount = "0.0";
                objNewUser.invite_code = this.strInviteCode;
                console.log(objNewUser);
                axios('/JetonUserRegistCheck', { params: { email: objNewUser.email, invite_code: this.strInviteCode } })
                    .then(({ data }) => {
                        console.log("JetonUserRegistCheck response : ");
                        console.log(data);
                        if (data.status === 1) {
                            console.log("JetonUserRegistCheck Pass");
                            axios.post('/NewJetonUser', objNewUser)
                                .then(({ data }) => {
                                    console.log("new user : ", data);
                                    if (data.status === 1) {
                                        message.success(data.message);
                                        cookie.save('jeton_user_code', data.code, { path: '/' });
                                        cookie.save('jeton_login_status', 1, { path: '/' });
                                        this.strCurrentUserName = objNewUser.name;
                                        this.strUserCode = data.code;
                                        this.iUserID = data.id;
                                        this.iLevel = objNewUser.level;
                                        this.setState({
                                            ...this.state,
                                            bLoading: false,
                                            bLoginStatus: true
                                        });
                                    } else {
                                        message.warning(data.message);
                                    }
                                }).catch(function (error) { console.log(error); });
                        } else {
                            message.warning(data.message);
                        }
                    }).catch(function (error) { console.log(error); });
            }
        });
    };
    /******** Callback Functions ********/
    // 右上角用户名更新
    UserNameUpdate = (strNewName, strOldName) => {
        console.log("UserNameUpdate : ", strNewName, strOldName, this.strCurrentUserName);
        if (strOldName === this.strCurrentUserName) {
            this.strCurrentUserName = strNewName;
            this.setState({
                ...this.state,
                bLoading: false
            })
        }

    }
    // 跳转正式下单页面
    JumpToJetonOrder = (iOrderType, objJetonOrder) => {
        // console.log("JumpToJetonOrder : ", iOrderType, objJetonOrder);
        this.iCurrentSideBarIndex = -1;
        this.iSelectedOrderType = iOrderType;
        this.objCurrentJetonOrder = objJetonOrder;

        this.setState({
            ...this.state,
            iCurrentSideBarIndex: 1
        })
    }

    // 渲染完毕……
    componentDidMount() {
        let strUserCode = cookie.load('jeton_user_code');
        let iUserLoginStatus = cookie.load('jeton_login_status');
        console.log("strUserCode: " + strUserCode);
        console.log("iUserLoginStatus: " + iUserLoginStatus);
        if ((iUserLoginStatus === 1 || iUserLoginStatus === '1') && strUserCode !== undefined) {
            this.strCurrentUserCode = strUserCode;
            this.UserCodeCheck();
        } else {
            this.setState({ ...this.state, bLoading: false, bLoginStatus: false });
        }
        document.title = "Jeton Express Services";
    }

    render() {

        const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;
        // Only show error after a field is touched.
        const usernameError = isFieldTouched('username') && getFieldError('username');
        const passwordError = isFieldTouched('password') && getFieldError('password');
        // 登录表单
        // const uiLogInForm = (
        //     <Form className="manage_login_form" layout="inline" onSubmit={this.LoginSubmit}>
        //         <Form.Item validateStatus={usernameError ? 'error' : ''} help={usernameError || ''}>
        //             {getFieldDecorator('username')(
        //                 <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="用户名" />,
        //             )}
        //         </Form.Item>
        //         <Form.Item validateStatus={passwordError ? 'error' : ''} help={passwordError || ''}>
        //             {getFieldDecorator('password')(
        //                 <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="密码" />,
        //             )}
        //         </Form.Item>
        //         <Form.Item>
        //             <Button type="primary" htmlType="submit" disabled={hasErrors(getFieldsError())}>
        //                 登 陆
        //             </Button>
        //         </Form.Item>
        //     </Form>
        // );
        // 欢迎回来
        let strTitle = '欢迎回来,  ' + this.strCurrentUserName;
        const uiWelcomeBack = (
            <div className="manage_login_form">
                <div className="welcome_back_title">{strTitle}</div>
                <Button className="logout_btn" type="primary" htmlType="submit"
                    onClick={this.LogoutClick}>退出登录</Button>
            </div>
        );
        // 顶部菜单栏
        let uiNavBar = (
            <div className="manage_top_bar">
                <div className="jeton_services_logo" style={{ marginLeft: '0px' }} >捷通快递服务</div>
                {uiWelcomeBack}
            </div>
        );
        // 侧边菜单栏
        let arrMenu = this.arrSideMenuButton;
        if (this.iLevel >= 9)
            arrMenu = this.arrSideMenuButtonManager;
        let uiSideBar = null;

        // 未登录时
        if (!this.state.bLoginStatus) {
            uiNavBar = (
                <div className="manage_top_bar">
                    <div className="platform_logo" style={{ marginLeft: '50px' }} >Jeton Service</div>
                    <div className="manage_top_bar_menu" />
                    {/* {uiLogInForm} */}
                </div>
            );

        }
        // 登陆后
        else {
            uiSideBar = (
                <Menu className="store_manage_side_bar" defaultSelectedKeys={[this.state.iCurrentSideBarIndex.toString()]}
                    selectedKeys={[this.state.iCurrentSideBarIndex.toString()]}
                    mode="inline" theme="dark" inlineCollapsed={this.state.collapsed}>
                    {arrMenu.map((item, index) => {
                        return (
                            <Menu.Item key={index} onClick={this.SwitchSideMenu.bind(this, index)}>
                                <Icon type={item.icon} />{item.name}
                            </Menu.Item>
                        );
                    })}
                </Menu>
            );
        }


        let uiCentralArea;
        // 是否还在加载中……
        if (this.state.bLoading) {
            uiCentralArea = (<Spin className="manage_empty_loading" size="large" tip="加载中……" />);
        } else {
            // 登陆后
            if (this.state.bLoginStatus) {
                let uiFeeCalculator = (this.state.iCurrentSideBarIndex === arrMenu[0].id &&
                    <DeliveryFeeCalculator iStoreID={this.iStoreID} iUserID={this.iUserID} strUserName={this.strCurrentUserName}
                        iLevel={this.iLevel} fMoney={this.fMoney} JumpToJetonOrder={this.JumpToJetonOrder} />);
                let uiJetonOrderManager = ((this.state.iCurrentSideBarIndex === arrMenu[1].id) &&
                    <JetonExpressOrder iStoreID={this.iStoreID} iUserID={this.iUserID} strUserName={this.strCurrentUserName}
                        iLevel={this.iLevel} fMoney={this.fMoney} iVIP={this.iVIP} iJumpToJetonOrder={this.iCurrentSideBarIndex}
                        iSelectedOrderType={this.iSelectedOrderType} objCurrentJetonOrder={this.objCurrentJetonOrder}
                    />);
                let uiUPSOrderManager = (this.state.iCurrentSideBarIndex === arrMenu[2].id &&
                    <UPSShippingOrder iStoreID={this.iStoreID} iUserID={this.iUserID} strUserName={this.strCurrentUserName}
                        iLevel={this.iLevel} fMoney={this.fMoney} iVIP={this.iVIP} />);
                let uiCPOrderManager = (this.state.iCurrentSideBarIndex === arrMenu[3].id &&
                    <CPShippingOrder iStoreID={this.iStoreID} iUserID={this.iUserID} strUserName={this.strCurrentUserName}
                        iLevel={this.iLevel} fMoney={this.fMoney} iVIP={this.iVIP} />);
                let uiAddressManager = (
                    this.state.iCurrentSideBarIndex === arrMenu[4].id && <AddressBookManager
                        iStoreID={this.iStoreID} iLevel={this.iLevel} iUserID={this.iUserID} />
                );
                let uiUserInfo = (this.state.iCurrentSideBarIndex === arrMenu[5].id &&
                    <ShipOrderStatistic iStoreID={this.iStoreID} iUserID={this.iUserID} strUserName={this.strCurrentUserName}
                        strUserCode={this.strUserCode} iLevel={this.iLevel} fMoney={this.fMoney} bClient={false}
                        UserNameUpdate={this.UserNameUpdate} />);
                let uiClientInfo = null;
                if (this.iLevel < 9) {
                    uiClientInfo = (this.state.iCurrentSideBarIndex === arrMenu[6].id &&
                        <ShipOrderStatistic iStoreID={this.iStoreID} iUserID={this.iUserID} strUserName={this.strCurrentUserName}
                            strUserCode={this.strUserCode} iLevel={this.iLevel} fMoney={this.fMoney} bClient={true} />);
                }

                uiCentralArea = (
                    <div>
                        {uiFeeCalculator}
                        {uiJetonOrderManager}
                        {uiUPSOrderManager}
                        {uiCPOrderManager}
                        {uiAddressManager}
                        {uiUserInfo}
                        {uiClientInfo}
                    </div>
                );
            }
            // 未登录时
            else {
                let uiLoginForm = null;
                let uiRegisterForm = null;
                if (this.state.bLoginPage) {
                    uiLoginForm = (
                        <Form onSubmit={this.LoginSubmit}>
                            <Form.Item>
                                {getFieldDecorator('name', {
                                    rules: [{ required: false }],
                                })(<Input style={{ width: '80%', height: '10%', marginLeft: '10%', fontSize: "20px" }}
                                    placeholder={"邮箱"} allowClear={true} size="large" />
                                )}
                            </Form.Item>
                            <Form.Item>
                                {getFieldDecorator('password', {
                                    rules: [{ required: false }],
                                })(<Input style={{ width: '80%', height: '10%', marginLeft: '10%', fontSize: "20px", borderRadius: '0px' }}
                                    type="password" placeholder="密码" allowClear={true} size="large" />,
                                )}
                            </Form.Item>
                            <Button htmlType="submit" className="login_page_web_login_button"
                                style={{ background: 'skyblue', color: 'black', fontSize: '17.5px', fontWeight: 'bold', height: '45px', border: 'none' }}>
                                登　录 </Button>
                            <div className="login_page_web_regist_now" style={{ color: 'skyblue' }}
                                onClick={this.SwitchLoginRegistButtonClick.bind(this, false)}>没有账号？现在注册</div>
                        </Form>
                    );
                }
                else {
                    uiRegisterForm = (
                        <Form onSubmit={this.handleRegisterSubmit}>
                            <Form.Item style={{ margin: '0', padding: '0', marginTop: '5%' }}>
                                {getFieldDecorator('email', {
                                    rules: [{ required: false/*, type: 'email'message: '请输入有效的邮箱'*/ }]
                                })(
                                    <div className="login_page_web_regist_form_row">
                                        <div style={{ width: '20%', height: '10%', marginRight: '2.5%', fontSize: "17.5px" }}>登录邮箱</div>
                                        < Input style={{ width: '60%', height: '10%', fontSize: "20px" }}
                                            placeholder="" allowClear={true} size="large" />
                                    </div>
                                )}
                            </Form.Item>
                            <Form.Item style={{ margin: '0', padding: '0', marginTop: '5%' }}>
                                {getFieldDecorator('name', { rules: [{ required: true }] })(
                                    <div className="login_page_web_regist_form_row">
                                        <div style={{ width: '20%', height: '10%', marginRight: '2.5%', fontSize: "17.5px" }}>用户名称</div>
                                        < Input style={{ width: '60%', height: '10%', fontSize: "20px" }}
                                            placeholder="" allowClear={true} size="large" />
                                    </div>
                                )}
                            </Form.Item>
                            {/* <Form.Item style={{ margin: '0', padding: '0', marginTop: '5%' }} >
                            {getFieldDecorator('phone', { rules: [{ required: false }] })(
                                <div className="login_page_web_regist_form_row">
                                    <div style={{ width: '20%', height: '10%', marginRight: '2.5%', fontSize: "17.5px" }}>手机号码</div>
                                    <Input style={{ width: '60%', height: '10%', fontSize: "20px" }}
                                        addonBefore={prefixSelector} allowClear={true} size="large" />
                                </div>)}
                        </Form.Item> */}
                            <Form.Item style={{ margin: '0', padding: '0', marginTop: '5%' }}>
                                {getFieldDecorator('password', {
                                    rules: [{ required: false }, { validator: this.validateToNextPassword, }],
                                })(<div className="login_page_web_regist_form_row">
                                    <div style={{ width: '20%', height: '10%', marginRight: '2.5%', fontSize: "17.5px" }}>设置密码</div>
                                    <Input.Password style={{ width: '60%', height: '10%', fontSize: "20px" }}
                                        placeholder="" allowClear={true} size="large" />
                                </div>)}
                            </Form.Item>
                            <Form.Item style={{ margin: '0', padding: '0', marginTop: '5%', marginBottom: '5%' }}>
                                {getFieldDecorator('confirm', {
                                    rules: [{ required: false }, { validator: this.compareToFirstPassword }],
                                })(<div className="login_page_web_regist_form_row">
                                    <div style={{ width: '20%', height: '10%', marginRight: '2.5%', fontSize: "17.5px" }}>确认密码</div>
                                    <Input.Password style={{ width: '60%', height: '10%', fontSize: "20px" }} onBlur={this.handleConfirmBlur} allowClear={true} size="large" />
                                </div>)}
                            </Form.Item>
                            {/* <Form.Item style={{ margin: '0', padding: '0', marginTop: '5%', marginBottom: '5%' }}>
                                {getFieldDecorator('invite_code', { rules: [{ required: false }] })(
                                    <div className="login_page_web_regist_form_row">
                                        <div style={{ width: '20%', height: '10%', marginRight: '2.5%', fontSize: "17.5px" }}>邀请码</div>
                                        < Input style={{ width: '60%', height: '10%', fontSize: "20px" }}
                                            placeholder="" allowClear={true} size="large" />
                                    </div>
                                )}
                            </Form.Item> */}
                            {/* <Form.Item style={{ margin: '0', padding: '0', marginTop: '5%', marginBottom: '5%' }} >
                            {getFieldDecorator('agreement', { valuePropName: 'checked' })(
                                <div className="login_page_web_regist_form_row">
                                    <Checkbox style={{ fontWeight: 'bold', fontSize: '15px', marginLeft: '9%' }}>我已阅读</Checkbox>
                                    <Tooltip title={"即将呈现，敬请期待！"} trigger="click" onClick={this.AgreementClicked}>
                                        <div style={{
                                            fontWeight: 'bold', fontSize: '15px', color: strMainColorStyle,
                                            marginLeft: 'auto', marginRight: '10%', textDecoration: 'underline'
                                        }}> {strAgreement} </div>
                                    </Tooltip>
                                </div>
                            )}
                        </Form.Item> */}
                            <div className="login_page_web_regist_form_row">
                                <Button htmlType="submit" className="login_page_web_login_button"
                                    style={{
                                        width: '82.5%', height: '45px', marginBottom: '2.5%', marginLeft: '0%', background: 'skyblue',
                                        color: 'black', fontSize: '17.5px', fontWeight: 'bold', border: 'none'
                                    }}>注 册</Button>
                            </div>
                            <div className="login_page_web_regist_now" style={{ margin: '0', marginLeft: '9%', color: 'skyblue' }}
                                onClick={this.SwitchLoginRegistButtonClick.bind(this, true)}>已有账号？立刻登录</div>
                        </Form>
                    );
                }

                uiCentralArea = (
                    <div className="jeton_title_page_center_area">
                        <div className={"jeton_title_page_login_form"}>
                            <div className="jeton_title_page_login_title">
                                登录 | 注册
                            </div>
                            {uiLoginForm}
                            {uiRegisterForm}
                        </div>
                    </div>
                );
                // uiCentralArea = (<Empty className="manage_empty_loading" description={<span>未登录，无法使用</span>} />);
            }
        }

        return (
            <div className="manage_page">
                {/* 顶部菜单栏 */}
                {uiNavBar}
                {/* 侧边菜单栏 */}
                {uiSideBar}
                {/* 中心区域内容 */}
                {uiCentralArea}
            </div >
        );
    }
}
export default Form.create()(JetonService);
