import React, { Component } from 'react';
import axios from 'axios';
import XLSX from 'xlsx';
import Spin from 'antd/es/spin';
import Table from 'antd/es/table';
import Button from 'antd/es/button';
import Input from 'antd/es/input';
import Menu from 'antd/es/menu';
import Dropdown from 'antd/es/dropdown';
import Icon from 'antd/es/icon';
import Modal from 'antd/es/modal';
import Radio from 'antd/es/radio';
import Checkbox from 'antd/es/checkbox';
import Progress from 'antd/es/progress';
import message from 'antd/es/message';
import Barcode from 'react-barcode';
import ReactToPrint from "react-to-print";
import '../../../styles/Background/ego_manager.css';
import '../../../styles/Background/storage_manager.css';
import '../../../styles/Background/UI_Module/user_management.css';

const g_strMessageKey = 'updating';
global.arrPositionList = [];

// 表格
const arrTableHeader = [
    { title: '库位编码', dataIndex: 'code', width: 50, ellipsis: true },
    { title: '所在库区', dataIndex: 'area_str', width: 50, ellipsis: true },
    { title: '库位类型', dataIndex: 'position_type_str', width: 50, ellipsis: true },
    { title: '库位层级', dataIndex: 'level_str', width: 50, ellipsis: true },
    { title: '低库位捡货序号', dataIndex: 'low_level_order', width: 50, ellipsis: true },
    { title: '高库位捡货序号', dataIndex: 'high_level_order', width: 50, ellipsis: true },
    { title: '库位使用', dataIndex: 'use_type_str', width: 50, ellipsis: true },
    { title: '库位状态', dataIndex: 'status_str', width: 50, ellipsis: true },
    { title: '混放物品', dataIndex: 'mix_item_str', width: 50, ellipsis: true },
    { title: '货架类型', dataIndex: 'shelf_type_str', width: 50, ellipsis: true },
    { title: '备注', dataIndex: 'comments', width: 75, ellipsis: true }
];

class InventoryPositionList extends Component {
    constructor(props) {
        super();
        this.state = {
            btnControlDisable: [false, true, true],  // 操作按钮控制
            // btnCopyPasteDisable: [true, true],  // 操作按钮控制
            selectedRowKeys: [],  // Check here to configure the default column
            iDlgType: -1,
            bShowDlg: false,
            bLoading: true,
            iInventoryAreaInfo: 0,
            iInventoryPositionType: 0,
            iUseType: 0,
            iShelfType: 0,
            iInventoryPositionStatus: 0,
            iMixItem: 0,
            iLevel: 1,
            iProgress: 0
        };
        // 成员变量
        this.arrOgeTableData = [];
        this.arrTableData = [];  // 用于表格的数组

        this.arrUseType = ['固定库位', '非固定库位'];
        this.arrUseTypeCode = ['GDKW', 'FGDKW'];
        this.arrShelfType = ['钢制大货架', '钢制小货架', '塑料小货架', '水泥地板', '其它类型'];
        this.arrShelfTypeCode = ['GZDHJ', 'GZXHJ', 'SLXHJ', 'SNDM', 'QTLXHJ'];
        this.arrInventoryPositionStatus = ['正常', '封存', '管控', '禁入'];

        this.arrInventoryAreaInfo = [];
        this.arrInventoryPositionType = [];

        this.objCurrentInventoryPosition = {};

        this.iCurrenProgress = 0;
    }

    // 选中某行的响应函数
    selectRow = (record) => {
        const selectedRowKeys = [...this.state.selectedRowKeys];
        if (selectedRowKeys.indexOf(record.key) >= 0) {
            selectedRowKeys.splice(selectedRowKeys.indexOf(record.key), 1);
        } else {
            selectedRowKeys.push(record.key);
        }
        this.SelectedRowStateSetting(selectedRowKeys);
    }
    // 选中的行发生变化的响应函数
    onSelectedRowKeysChange = (selectedRowKeys) => {
        this.SelectedRowStateSetting(selectedRowKeys);
    }
    // 处理选中的表行行
    SelectedRowStateSetting = (selectedRowKeys) => {
        let btnControlDisable = [];
        switch (selectedRowKeys.length) {
            case 0: {
                btnControlDisable = [false, true, true];
                this.InitCurrentIventoryPosition();
                break;
            }
            case 1: {
                btnControlDisable = [false, false, false];
                // btnCopyPasteDisable = [false, true];
                for (let i = 0; i < this.arrTableData.length; i++) {
                    if (this.arrTableData[i].key === selectedRowKeys[0]) {
                        this.objCurrentInventoryPosition = this.arrTableData[i];
                        break;
                    }
                }
                break;
            }
            default: {
                btnControlDisable = [false, true, true];
                // btnCopyPasteDisable = [true, true];
                break;
            }
        }
        this.setState({
            ...this.state,
            selectedRowKeys,
            btnControlDisable: btnControlDisable
            // btnCopyPasteDisable: btnCopyPasteDisable
        });
    }
    // 关键词处理
    KeywordsProcess = (strKeywords) => {
        let newKeywords = "";
        for (let i = 0; i < strKeywords.length; i++) {
            if (strKeywords[i] !== " ") {
                if (i === strKeywords.length - 1)
                    newKeywords += strKeywords[i];
                else
                    newKeywords += strKeywords[i] + "%";
            }
        }
        return newKeywords;
    }
    // 搜索库位名
    // UserNameSearch = () => {
    //     let strKeywords = this.refs.user_name_search.state.value;
    //     if (strKeywords === undefined)
    //         strKeywords = "";
    //     if (strKeywords.trim() === "") {
    //         this.GetInventoryPosition();
    //     } else {
    //         this.setState({
    //             ...this.state,
    //             bLoading: true,
    //             selectedRowKeys: [],
    //             btnControlDisable: [false, true, true]
    //         });
    //         axios('/SearchUsers', { params: { name: this.KeywordsProcess(strKeywords) } })
    //             .then(({ data }) => {
    //                 this.arrTableData = this.OrganizeInventoryPositionData(data);
    //                 this.setState({
    //                     ...this.state,
    //                     bLoading: false,
    //                     objFilter: {
    //                         strKeyword: strKeywords,
    //                         iCurrentUserStatus: 0,
    //                         iCurrentUserType: 0
    //                     }
    //                 });
    //             }).catch(function (error) { console.log(error); });
    //     }
    // }
    // 点击了库位过滤器
    UserTypeFilterClicked = (iUserType) => {
        let strTargetType = this.arrFilterUserType[iUserType];
        if (iUserType !== 0) {
            this.arrTableData = [];
            for (let i = 0; i < this.arrOgeTableData.length; i++) {
                if (strTargetType === this.arrOgeTableData[i].level) {
                    this.arrTableData.push(this.arrOgeTableData[i]);
                }
            }
        } else {
            this.arrTableData = this.arrOgeTableData;
        }
        if (this.refs.user_name_search !== undefined)
            this.refs.user_name_search.state.value = "";
        this.setState({
            ...this.state,
            objFilter: {
                strKeyword: "",
                iCurrentUserStatus: this.state.objFilter.iCurrentUserStatus,
                iCurrentUserType: iUserType
            }
        });
    }
    // 点击了库区信息过滤器
    InventoryAreaInfoDropDownClicked = (index) => {
        this.setState({
            ...this.state,
            iInventoryAreaInfo: index
        });
    }
    // 点击了库位类型过滤器
    InventoryPositionTypeDropDownClicked = (index) => {
        this.setState({
            ...this.state,
            iInventoryPositionType: index
        });
    }
    // 点击了货架使用类型过滤器
    UseTypeDropDownClicked = (index) => {
        this.setState({
            ...this.state,
            iUseType: index
        });
    }
    // 点击了货架类型过滤器
    ShelfTypeDropDownClicked = (index) => {
        this.setState({
            ...this.state,
            iShelfType: index
        });
    }
    // 点击了库位状态过滤器
    InventoryPositionStatusDropDownClicked = (index) => {
        this.setState({
            ...this.state,
            iInventoryPositionStatus: index
        });
    }
    // 允许货物混放
    MixItemOption = (e) => {
        this.setState({
            ...this.state,
            iMixItem: e.target.checked
        })
    }
    // 切换库位类型
    InventoryPositionTypeChange = e => {
        this.setState({
            ...this.state,
            iLevel: e.target.value,
        });
    };
    //弹出对话框
    ShowDlg = (iDlgType) => {
        if (iDlgType === 2) {
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true
            });
        } else {
            if (this.arrInventoryPositionType.length <= 0) {
                message.warning("请先创建至少一个库位类型！");
                return;
            }
            if (this.arrInventoryAreaInfo.length <= 0) {
                message.warning("请先创建至少一个库区信息!");
                return;
            }
            if (iDlgType === 0) {
                if (this.refs.code) {
                    this.refs.code.state.value = "";
                    this.refs.comments.state.value = "";
                    this.refs.up_priority.state.value = 1;
                    this.refs.down_priority.state.value = 1;
                    this.refs.low_level_order.state.value = 1;
                    this.refs.high_level_order.state.value = 1;
                    this.refs.channel.state.value = "";
                    this.refs.pos_x.state.value = "";
                    this.refs.pos_y.state.value = "";
                    this.refs.pos_z.state.value = "";
                }
                this.InitCurrentIventoryPosition();
                this.setState({
                    ...this.state,
                    iDlgType: iDlgType,
                    bShowDlg: true,
                    iInventoryAreaInfo: 0,
                    iInventoryPositionType: 0,
                    iUserType: 0,
                    iShelfType: 0,
                    iInventoryPositionStatus: 0,
                    iMixItem: 1,
                    iLevel: 1
                });
            }
            if (iDlgType === 1) {
                if (this.refs.code) {
                    this.refs.code.state.value = this.objCurrentInventoryPosition.code;
                    this.refs.comments.state.value = this.objCurrentInventoryPosition.comments;
                    this.refs.up_priority.state.value = this.objCurrentInventoryPosition.up_priority;
                    this.refs.down_priority.state.value = this.objCurrentInventoryPosition.down_priority;
                    this.refs.low_level_order.state.value = this.objCurrentInventoryPosition.low_level_order;
                    this.refs.high_level_order.state.value = this.objCurrentInventoryPosition.high_level_order;
                    this.refs.code.channel.value = this.objCurrentInventoryPosition.channel;
                    this.refs.code.pos_x.value = this.objCurrentInventoryPosition.pos_x;
                    this.refs.code.pos_y.value = this.objCurrentInventoryPosition.pos_y;
                    this.refs.code.pos_z.value = this.objCurrentInventoryPosition.pos_z;
                }
                this.setState({
                    ...this.state,
                    iDlgType: iDlgType,
                    bShowDlg: true,
                    iInventoryAreaInfo: this.objCurrentInventoryPosition.area_index,
                    iInventoryPositionType: this.objCurrentInventoryPosition.position_type_index,
                    iUseType: this.objCurrentInventoryPosition.use_type,
                    iShelfType: this.objCurrentInventoryPosition.shelf_type,
                    iInventoryPositionStatus: this.objCurrentInventoryPosition.status,
                    iMixItem: this.objCurrentInventoryPosition.mix_item,
                    iLevel: this.objCurrentInventoryPosition.level
                });
            }
        }
    }
    // 弹窗确认按钮响应
    handleOk = e => {
        if (this.state.iDlgType === 0 || this.state.iDlgType === 1) {
            let strCode = this.refs.code.state.value;
            if (strCode === null || strCode.trim() === "") {
                message.warning("库位代码不能为空！"); return;
            }
            let iMixItem = 0;
            if (this.state.iMixItem)
                iMixItem = 1;
            let objNewInventoryPosition = {
                code: this.refs.code.state.value,
                area: this.arrInventoryAreaInfo[this.state.iInventoryAreaInfo].id,
                position_type: this.arrInventoryPositionType[this.state.iInventoryPositionType].id,
                use_type: this.state.iUseType,
                shelf_type: this.state.iShelfType,
                status: this.state.iInventoryPositionStatus,
                up_priority: this.refs.up_priority.state.value,
                down_priority: this.refs.down_priority.state.value,
                comments: this.refs.comments.state.value,
                mix_item: iMixItem,
                level: this.state.iLevel,
                low_level_order: this.refs.low_level_order.state.value,
                high_level_order: this.refs.high_level_order.state.value,
                channel: this.refs.channel.state.value,
                pos_x: this.refs.pos_x.state.value,
                pos_y: this.refs.pos_y.state.value,
                pos_z: this.refs.pos_z.state.value,
                store_id: this.props.objStoreInfo.id
            }
            message.loading({ content: '正在更新库位……', key: g_strMessageKey });
            axios.post('/UpdateInventoryPosition', {
                objCurrentInventoryPosition: this.objCurrentInventoryPosition,
                objNewInventoryPosition: objNewInventoryPosition,
                action: this.state.iDlgType
            }).then(({ data }) => {
                if (data.status === 1) {
                    this.GetInventoryPosition();
                }
                if (data.status === -1) {
                    message.warning({ content: "新建/更新库位信息失败！", key: g_strMessageKey, duration: 2 });
                }
                if (data.status === -2) {
                    message.warning({ content: "库位编码已存在，请检查！", key: g_strMessageKey, duration: 2 });
                }
            }).catch(function (error) { console.log(error); });
        }
        if (this.state.iDlgType === 2) {
            message.loading({ content: '库位删除中……', key: g_strMessageKey });
            axios.post('/DeleteInventoryPosition', { id: this.objCurrentInventoryPosition.id }).then(
                ({ data }) => {
                    if (data.status === -1) {
                        message.warning({
                            content: "删除失败，数据库中未找到指定库位！",
                            key: g_strMessageKey, duration: 2
                        });
                        this.setState({
                            ...this.state,
                            btnControlDisable: [false, true, true],
                            bLoading: false,
                            bShowDlg: false,
                            selectedRowKeys: []
                        });
                    } else {
                        this.GetInventoryPosition();
                    }
                }).catch(function (error) { console.log(error); });
        }
    };
    // 弹窗取消按钮响应
    handleCancel = e => {
        this.InitCurrentIventoryPosition();
        this.setState({
            bShowDlg: false,
            selectedRowKeys: [],
            btnControlDisable: [false, true, true]
        });
    };
    // 整理Users数据
    OrganizeInventoryPositionData(data) {
        let arrTableData = [];
        for (let i = 0; i < data.length; i++) {
            let objInventoryData = data[i];
            objInventoryData.key = data[i].id;

            for (let j = 0; j < this.arrInventoryPositionType.length; j++) {
                if (data[i].position_type === this.arrInventoryPositionType[j].id) {
                    objInventoryData.position_type_str = this.arrInventoryPositionType[j].name;
                    objInventoryData.position_type_index = j;
                    break;
                }
            }
            for (let j = 0; j < this.arrInventoryAreaInfo.length; j++) {
                if (data[i].area === this.arrInventoryAreaInfo[j].id) {
                    objInventoryData.area_str = this.arrInventoryAreaInfo[j].name;
                    objInventoryData.area_index = j;
                    break;
                }
            }
            objInventoryData.level_str = "高";
            if (data[i].level === 0)
                objInventoryData.level_str = "低";
            objInventoryData.use_type_str = this.arrUseType[data[i].use_type];
            objInventoryData.status_str = this.arrInventoryPositionStatus[data[i].status];
            objInventoryData.mix_item_str = "允许";
            if (data[i].mix_item === 0)
                objInventoryData.mix_item_str = "不允许";
            objInventoryData.shelf_type_str = this.arrShelfType[data[i].shelf_type];
            arrTableData.push(objInventoryData);
        }
        return arrTableData;
    }
    // 初始化当前选中的记录信息
    InitCurrentIventoryPosition = () => {
        this.objCurrentInventoryPosition = {
            id: -1,
            code: "",
            area: 0,
            position_type: 0,
            use_type: 0,
            shelf_type: 0,
            status: 0,
            up_priority: 1,
            down_priority: 1,
            comments: "",
            mix_item: 1,
            level: 1,
            low_level_order: 1,
            high_level_order: 1,
            channel: '',
            pos_x: '',
            pos_y: '',
            pos_z: '',
            store_id: this.props.objStoreInfo.id
        }
    }
    // 从数据库获取当前的数据结构
    GetInventoryPosition = () => {
        this.InitCurrentIventoryPosition();
        message.loading({ content: '数据加载中……', key: g_strMessageKey });
        axios('/GetInventoryAreaInfo', { params: { store_id: this.props.objStoreInfo.id } })
            .then(({ data }) => {
                this.arrInventoryAreaInfo = data;
                axios('/GetInventoryPositionType', { params: { store_id: this.props.objStoreInfo.id } })
                    .then(({ data }) => {
                        this.arrInventoryPositionType = data;
                        axios('/GetInventoryPosition', { params: { store_id: this.props.objStoreInfo.id } })
                            .then(({ data }) => {
                                global.arrPositionList = data;
                                this.arrOgeTableData = this.OrganizeInventoryPositionData(data);
                                this.arrTableData = this.arrOgeTableData;
                                if (this.state.bShowDlg) {
                                    if (this.state.iDlgType === 0) {
                                        message.success({ content: "新建库位完成！", key: g_strMessageKey, duration: 2 });
                                    }
                                    if (this.state.iDlgType === 1) {
                                        message.success({ content: "库位已更新！", key: g_strMessageKey, duration: 2 });
                                    }
                                    if (this.state.iDlgType === 2) {
                                        message.success({ content: "库位已删除！", key: g_strMessageKey, duration: 2 })
                                    }
                                } else {
                                    message.success({ content: "库位加载完成！", key: g_strMessageKey, duration: 2 });
                                }
                                this.setState({
                                    ...this.state,
                                    bLoading: false,
                                    bShowDlg: false,
                                    selectedRowKeys: []
                                });
                            }).catch(function (error) { console.log(error); });
                    }).catch(function (error) { console.log(error); });
            }).catch(function (error) { console.log(error); });
    }
    // 上传文件响应
    onImportExcel = file => {
        // 获取上传的文件对象
        const { files } = file.target;
        console.log("upload file : ", files);
        // 通过FileReader对象读取文件
        const fileReader = new FileReader();
        fileReader.onload = event => {
            try {
                const { result } = event.target;
                // 以二进制流方式读取得到整份excel表格对象
                const workbook = XLSX.read(result, { type: 'binary' });
                // 存储获取到的数据
                let data = [];
                // 遍历每张工作表进行读取（这里默认只读取第一张表）
                for (const sheet in workbook.Sheets) {
                    // esline-disable-next-line
                    if (workbook.Sheets.hasOwnProperty(sheet)) {
                        // 利用 sheet_to_json 方法将 excel 转成 json 数据
                        data = data.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
                        // break; // 如果只取第一张表，就取消注释这行
                    }
                }
                // 最终获取到并且格式化后的 json 数据
                message.success('上传成功！');
                if (data.length > 0) {
                    this.ProcessInventoryPositionData(data);
                } else {
                    message.warning("文件中的数据为空，请重新选择！")
                }
            } catch (e) {
                // 这里可以抛出文件类型错误不正确的相关提示
                message.error('文件类型不正确！');
            }
        };
        // 以二进制方式打开文件
        fileReader.readAsBinaryString(files[0]);
    }
    // 逐一处理HSCode数据
    ProcessInventoryPositionData = (dataExcel) => {
        this.iCurrenProgress = 0;
        this.setState({
            ...this.state,
            bLoading: true,
            iProgress: parseFloat((this.iCurrenProgress * 100) / dataExcel.length).toFixed(0)
        });
        this.InitCurrentIventoryPosition();
        console.log("ProcessInventoryPositionData : ");
        console.log(dataExcel);

        for (let i = 0; i < dataExcel.length; i++) {
            let iType = -1;
            for (let j = 0; j < this.arrInventoryPositionType.length; j++) {
                if (dataExcel[i]["*库位类型"] === this.arrInventoryPositionType[j].name) {
                    iType = this.arrInventoryPositionType[j].id;
                }
            }
            let iArea = -1;
            for (let j = 0; j < this.arrInventoryAreaInfo.length; j++) {
                if (dataExcel[i]["*库区"] === this.arrInventoryAreaInfo[j].code) {
                    iArea = this.arrInventoryAreaInfo[j].id;
                }
            }
            if (iType === -1 || iArea === -1) {
                console.log(i + "未找到对应的库区/库位类别信息，跳过");
                this.iCurrenProgress++;
                console.log("current progress : " + this.iCurrenProgress);
                if (this.iCurrenProgress >= dataExcel.length)
                    this.iCurrenProgress = dataExcel.length;
                this.setState({
                    ...this.state,
                    iProgress: parseFloat((this.iCurrenProgress * 100) / dataExcel.length).toFixed(0)
                });
                continue;
            }
            let iUseType = 0;
            for (let j = 0; j < this.arrUseTypeCode.length; j++) {
                if (dataExcel[i]['*库位使用(是否固定库位，默认非固定库位“FGDKW”)'] === this.arrUseTypeCode[j]) {
                    iUseType = j;
                }
            }
            let iShelfType = 0;
            for (let j = 0; j < this.arrShelfTypeCode.length; j++) {
                if (dataExcel[i]['货架类型'] === this.arrShelfTypeCode[j]) {
                    iShelfType = j;
                }
            }
            let iLevel = 0;
            if (dataExcel[i]['是否高库位'])
                iLevel = 1;
            let iMixItem = 0;
            if (dataExcel[i]['是否混放产品'])
                iMixItem = 1;
            let objNewInventoryPosition = {
                code: dataExcel[i]['*库位编码'],
                area: iArea,
                position_type: iType,
                use_type: iUseType,
                shelf_type: iShelfType,
                status: 0,
                up_priority: dataExcel[i]['*上架优先级(默认99999)'],
                down_priority: dataExcel[i]['*下架优先级(默认999)'],
                comments: dataExcel[i]['备注'],
                mix_item: iMixItem,
                level: iLevel,
                low_level_order: dataExcel[i]['*低库位拣货序号(默认999999)'],
                high_level_order: dataExcel[i]['高库位拣货序号(默认999999)'],
                channel: dataExcel[i]['通道'],
                pos_x: dataExcel[i]['X'],
                pos_y: dataExcel[i]['Y'],
                pos_z: dataExcel[i]['Z'],
                store_id: this.props.objStoreInfo.id
            }
            this.objCurrentInventoryPosition.code = dataExcel[i]['*库位编码'];
            console.log("data " + i + " : ", objNewInventoryPosition);
            axios.post('/UpdateInventoryPosition', {
                objCurrentInventoryPosition: this.objCurrentInventoryPosition,
                objNewInventoryPosition: objNewInventoryPosition
            }).then(({ data }) => {
                console.log("UpdateInventoryPosition : ", data);
                if (data.status === 1) {
                    this.iCurrenProgress++;
                    console.log("current progress = " + this.iCurrenProgress);
                    if (this.iCurrenProgress >= dataExcel.length)
                        this.iCurrenProgress = dataExcel.length;
                    this.setState({
                        ...this.state,
                        iProgress: parseFloat((this.iCurrenProgress * 100) / dataExcel.length).toFixed(0)
                    });
                    if (this.iCurrenProgress >= dataExcel.length) {
                        console.log("加载完毕！");
                        this.iCurrenProgress = 0;
                        document.getElementById("file_upload").value = "";
                        this.GetInventoryPosition();
                    }
                } else
                    message.warning({ content: "新建/更新库位失败！", key: g_strMessageKey, duration: 2 });
            }).catch(function (error) { console.log(error); });

        }
    }
    // 点击条形码时的默认屏蔽
    BarcodeClick = (event) => {
        event.preventDefault();
    }
    /* React自带函数 */
    // 卸载异步操作设置状态
    componentWillUnmount() {
        clearTimeout(this.timeouter)
        this.setState = (state, callback) => {
            return;
        }
    }
    // 渲染完毕……
    componentDidMount() {
        this.GetInventoryPosition();
    }
    // 渲染函数
    render() {
        /* 库位管理页面控制栏 */
        let uiUploadProgress = null;
        if (this.state.iProgress > 0)
            uiUploadProgress = (<Progress percent={parseInt(this.state.iProgress, 10)} style={{ maxWidth: '500px' }} />);
        let uiTitleArea = (
            < div className="storage_contents_title" >
                {/* <div className="manage_stretch_right">
                    <Input placeholder="搜索库位名/代码" ref="user_name_search" defaultValue={this.state.strKeywords} />
                    <Button className="manage_contents_title_margin"
                        type="primary" onClick={this.UserNameSearch}>搜索
                    </Button>
                </div> */}
                {/* <div className="manage_title_filter_area">
                    <span className="manage_stretch_right_tiny">库位</span>
                    <Dropdown className="manage_contents_title_margin manage_stretch_right"
                        overlay={uiUserTypeMenu} placement="bottomLeft">
                        <Button>
                            {this.arrFilterUserType[this.state.objFilter.iCurrentUserType]}
                            <Icon className="dropdown_icon" type="down" />
                        </Button>
                    </Dropdown>
                </div> */}
                {/* <div className="manage_title_button_right_side"> */}
                <Button className="manage_contents_title_margin" style={{ background: 'darkcyan', color: 'white', marginLeft: '5px' }}
                    onClick={this.ShowDlg.bind(this, 0)} disabled={this.state.btnControlDisable[0]}>
                    <Icon type="plus" />新建</Button>
                <Button className="manage_contents_title_margin" type="primary" onClick={this.ShowDlg.bind(this, 1)} disabled={this.state.btnControlDisable[1]}>
                    <Icon type="edit" /> 编辑</Button>
                <Button
                    className="manage_contents_title_margin" type="danger" onClick={this.ShowDlg.bind(this, 2)} disabled={this.state.btnControlDisable[2]}>
                    <Icon type="close" /> 删除</Button>
                <div className="manage_contents_title_margin" style={{ color: 'white', marginLeft: '20px' }} >
                    批量导入:
                </div>
                <input className="manage_contents_title_margin" type='file' accept='.xlsx, .xls' onChange={this.onImportExcel} ref="file_upload" id="file_upload" />
                {uiUploadProgress}
                {/* </div> */}
            </div >
        );

        // 如果界面上需要显示的内容已经从数据库获得
        if (!this.state.bLoading) {
            // 主体表格相关
            const { selectedRowKeys } = this.state;
            const rowSelection = {
                selectedRowKeys,
                onChange: this.onSelectedRowKeysChange,
                columnWidth: 30
            };
            // 表格分页属性
            const paginationProps = {
                showSizeChanger: true,
                showQuickJumper: false,
                showTotal: () => `共 ${this.arrTableData.length} 个 库位`,
                defaultPageSize: 10,
                defaultCurrent: this.state.iCurrentPage,
                pageSizeOptions: ['10', '20', '30', '40', '50'],
                total: this.arrTableData.length,
                position: 'bottom'
            };
            let uiModal = null;
            if (this.state.bShowDlg) {
                if (this.state.iDlgType <= 1) {
                    // 库位编辑弹窗
                    let strTitle = "新建库位信息";
                    if (this.state.iDlgType === 1)
                        strTitle = "编辑库位信息";
                    // 货架使用类型下拉菜单
                    const uiUseTypeMenu = (
                        <Menu>
                            {this.arrUseType.map((item, index) => {
                                return (
                                    <Menu.Item key={index} onClick={this.UseTypeDropDownClicked.bind(this, index)}>
                                        {item}
                                    </Menu.Item>
                                );
                            })}
                        </Menu>
                    );
                    // 货架类型下拉菜单
                    const uiShelfTypeMenu = (
                        <Menu>
                            {this.arrShelfType.map((item, index) => {
                                return (
                                    <Menu.Item key={index} onClick={this.ShelfTypeDropDownClicked.bind(this, index)}>
                                        {item}
                                    </Menu.Item>
                                );
                            })}
                        </Menu>
                    );
                    // 库位状态下拉菜单
                    const uiInventoryPositionStatusMenu = (
                        <Menu>
                            {this.arrInventoryPositionStatus.map((item, index) => {
                                return (
                                    <Menu.Item key={index} onClick={this.InventoryPositionStatusDropDownClicked.bind(this, index)}>
                                        {item}
                                    </Menu.Item>
                                );
                            })}
                        </Menu>
                    );
                    // 库区列表下拉菜单
                    const uiInventoryAreaInfo = (
                        <Menu>
                            {this.arrInventoryAreaInfo.map((item, index) => {
                                return (
                                    <Menu.Item key={index} onClick={this.InventoryAreaInfoDropDownClicked.bind(this, index)}>
                                        {item.name}
                                    </Menu.Item>
                                );
                            })}
                        </Menu>
                    );
                    // 库位类型下拉菜单
                    const uiInventoryPositionType = (
                        <Menu>
                            {this.arrInventoryPositionType.map((item, index) => {
                                return (
                                    <Menu.Item key={index} onClick={this.InventoryPositionTypeDropDownClicked.bind(this, index)}>
                                        {item.name}
                                    </Menu.Item>
                                );
                            })}
                        </Menu>
                    );
                    let bMixItem = false;
                    if (this.state.iMixItem === 1)
                        bMixItem = true;
                    uiModal = (
                        <Modal title={strTitle} width={1000} visible={this.state.bShowDlg}
                            okText="确定" cancelText="取消" onOk={this.handleOk} onCancel={this.handleCancel} >
                            <div className="storage_info_edit_block">
                                <div className="user_edit_info_row">
                                    <div className="storage_edit_info_title_long storage_edit_info_title_first_col">库位编码:</div>
                                    <Input className="storage_edit_info_input" ref="code"
                                        defaultValue={this.objCurrentInventoryPosition.code} />
                                    <div className="storage_edit_info_title_long">所在库区:</div>
                                    <Dropdown className="storage_edit_info_input" overlay={uiInventoryAreaInfo} placement="bottomLeft">
                                        <Button className="storage_edit_info_input">
                                            {this.arrInventoryAreaInfo[this.state.iInventoryAreaInfo].name}
                                            <Icon type="caret-down" style={{ marginLeft: '10px' }} />
                                        </Button>
                                    </Dropdown>
                                    <div className="storage_edit_info_title_long">库位类型:</div>
                                    <Dropdown className="storage_edit_info_input" overlay={uiInventoryPositionType} placement="bottomLeft">
                                        <Button className="storage_edit_info_input" >
                                            {this.arrInventoryPositionType[this.state.iInventoryPositionType].name}
                                            <Icon type="caret-down" style={{ marginLeft: '10px' }} />
                                        </Button>
                                    </Dropdown>
                                </div>
                                <div className="user_edit_info_row">
                                    <div className="storage_edit_info_title_long storage_edit_info_title_first_col">库位使用:</div>
                                    <Dropdown className="storage_edit_info_input" overlay={uiUseTypeMenu} placement="bottomLeft">
                                        <Button className="storage_edit_info_input" >
                                            {this.arrUseType[this.state.iUseType]}
                                            <Icon type="caret-down" style={{ marginLeft: '10px' }} />
                                        </Button>
                                    </Dropdown>
                                    <div className="storage_edit_info_title_long">货架类型:</div>
                                    <Dropdown className="storage_edit_info_input" overlay={uiShelfTypeMenu} placement="bottomLeft">
                                        <Button className="storage_edit_info_input" >
                                            {this.arrShelfType[this.state.iShelfType]}
                                            <Icon type="caret-down" style={{ marginLeft: '10px' }} />
                                        </Button>
                                    </Dropdown>
                                    <div className="storage_edit_info_title_long">库位状态:</div>
                                    <Dropdown className="storage_edit_info_input" overlay={uiInventoryPositionStatusMenu} placement="bottomLeft">
                                        <Button className="storage_edit_info_input" >
                                            {this.arrInventoryPositionStatus[this.state.iInventoryPositionStatus]}
                                            <Icon type="caret-down" style={{ marginLeft: '10px' }} />
                                        </Button>
                                    </Dropdown>
                                </div>
                                <div className="user_edit_info_row">
                                    <div className="storage_edit_info_title_long storage_edit_info_title_first_col">上架优先级:</div>
                                    <Input className="storage_edit_info_input" ref="up_priority"
                                        defaultValue={this.objCurrentInventoryPosition.up_priority} />
                                    <div className="storage_edit_info_title_long">下架优先级:</div>
                                    <Input className="storage_edit_info_input" ref="down_priority"
                                        defaultValue={this.objCurrentInventoryPosition.down_priority} />
                                    <div className="storage_edit_info_title_long">库位备注:</div>
                                    <Input className="storage_edit_info_input" ref="comments"
                                        defaultValue={this.objCurrentInventoryPosition.comments} />
                                </div>
                                <div className="user_edit_info_row" style={{ marginTop: '30px' }}>
                                    <div className="storage_edit_info_title_long storage_edit_info_title_first_col">混放产品:</div>
                                    <Checkbox onChange={this.MixItemOption} style={{ minWidth: '50px' }} checked={bMixItem}>允许</Checkbox>
                                    <div className="storage_edit_info_title_long">库位类型:</div>
                                    <Radio.Group onChange={this.InventoryPositionTypeChange} value={this.state.iLevel} className="storage_edit_info_option" >
                                        <Radio value={1}>高</Radio>
                                        <Radio value={0}>低</Radio>
                                    </Radio.Group>
                                    <div className="storage_edit_info_title_long" style={{ minWidth: '110px' }}>低库位捡货序号:</div>
                                    <Input className="storage_edit_info_input_short" ref="low_level_order"
                                        defaultValue={this.objCurrentInventoryPosition.low_level_order} />
                                    <div className="storage_edit_info_title_long" style={{ minWidth: '110px' }}>高库位捡货序号:</div>
                                    <Input className="storage_edit_info_input_short" ref="high_level_order"
                                        defaultValue={this.objCurrentInventoryPosition.high_level_order} />
                                </div>
                            </div>
                            <div className="storage_info_edit_block">
                                <div className="user_edit_info_row">
                                    <div className="storage_edit_info_title_short" >通道:</div>
                                    <Input className="storage_edit_info_input_mid" ref="channel"
                                        defaultValue={this.objCurrentInventoryPosition.channel} />
                                    <div className="storage_edit_info_title_short" >X坐标:</div>
                                    <Input className="storage_edit_info_input_mid" ref="pos_x"
                                        defaultValue={this.objCurrentInventoryPosition.pos_x} />
                                    <div className="storage_edit_info_title_short" >Y坐标:</div>
                                    <Input className="storage_edit_info_input_mid" ref="pos_y"
                                        defaultValue={this.objCurrentInventoryPosition.pos_y} />
                                    <div className="storage_edit_info_title_short" >Z坐标:</div>
                                    <Input className="storage_edit_info_input_mid" ref="pos_z"
                                        defaultValue={this.objCurrentInventoryPosition.pos_z} />
                                </div>
                            </div>
                            <div className="storage_info_edit_block">
                                <div className="user_edit_info_row">
                                    <div className="storage_edit_info_title_short" >鼠标右键点击 下载/打印 库位条形码:</div>
                                    <a href="请右键点击并保存该条形码" download={this.objCurrentInventoryPosition.code + ".png"} style={{ marginLeft: '10px' }}
                                        onClick={e => this.BarcodeClick(e)}>
                                        <Barcode value={this.objCurrentInventoryPosition.code} renderer={'img'} displayValue={true}
                                            width={3.5} height={75} fontSize={20} background={'rgba(0, 0, 0, 0)'} ref={(el) => (this.componentRef = el)} />
                                    </a>
                                    <ReactToPrint trigger={() =>
                                        <Button type="primary" className="storage_print_button" >
                                            <Icon type="printer" style={{ color: 'white', fontWeight: 'bold', fontSize: '15px' }} />
                                        </Button>} content={() => this.componentRef} />
                                </div>
                            </div>
                        </Modal>
                    );
                }
                if (this.state.iDlgType === 2) {
                    uiModal = (
                        <Modal width={500} title={"删除库位"} visible={this.state.bShowDlg} okType="danger"
                            onOk={this.handleOk} okText="确定"
                            onCancel={this.handleCancel} cancelText="取消">
                            <div className="modal_item">
                                <div>
                                    {this.objCurrentInventoryPosition.code}
                                    <br /><br />确定删除选中的库位？
                                </div>
                            </div>
                        </Modal>
                    );
                }
            }
            return (
                <div className="storage_contents_area">
                    {uiTitleArea}
                    {uiModal}
                    {/* 表格主体 */}
                    <Table style={{ margin: '20px', width: '97.5%', overflowX: 'auto' }}
                        rowSelection={rowSelection} pagination={paginationProps}
                        columns={arrTableHeader} dataSource={this.arrTableData} bordered
                        onRow={(record) => ({ onClick: () => { this.selectRow(record); } })} />
                </div >
            );
        } else {
            return (
                <div className="storage_contents_area">
                    {uiTitleArea}
                    <Spin className="manage_empty_loading" size="large" tip="加载中……" />
                </div>
            );
        }
    }
}
export default InventoryPositionList;
