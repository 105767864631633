import React, { Component } from 'react';
import axios from 'axios';

import Spin from 'antd/es/spin';
import Table from 'antd/es/table';
import Button from 'antd/es/button';
import Input from 'antd/es/input';
import Icon from 'antd/es/icon';
import Modal from 'antd/es/modal';
import message from 'antd/es/message';
// import Clipboard from 'react-clipboard.js';

import '../../../styles/Background/ego_manager.css';
import '../../../styles/Background/UI_Module/user_management.css';
import { UserActiveUpdate } from "../../../functions/DataStatistic.js"

const g_strMessageKey = 'updating';
global.arrShipperData = [];

// 表格
const arrTableHeader = [
    { title: 'id', dataIndex: 'id', width: 50, ellipsis: true },
    { title: '用户信息', dataIndex: 'user_info', width: 100, ellipsis: true },
    { title: '发件人', dataIndex: 'name', width: 150, ellipsis: true },
    { title: '电话', dataIndex: 'phone', width: 125, ellipsis: true },
    { title: '邮箱', dataIndex: 'email', width: 125, ellipsis: true },
    { title: '地址', dataIndex: 'address', width: 300, ellipsis: true },
    { title: '邮编', dataIndex: 'postcode', width: 125, ellipsis: true },
];

class StoreShipperManager extends Component {
    constructor(props) {
        super();
        this.state = {
            btnControlDisable: [false, true, true],  // 操作按钮控制
            btnCopyPasteDisable: [true, true],  // 操作按钮控制
            selectedRowKeys: [],  // Check here to configure the default column
            objFilter: {
                strKeyword: "", iCurrentUserStatus: 0, iCurrentUserType: 0
            },
            iDlgType: -1,
            bShowDlg: false,
            bLoading: true,
            strNewPassword: "",
            strConfirmPassword: ""
        };
        // 成员变量
        this.arrOgeTableData = [];
        this.arrTableData = [];  // 用于表格的数组
        this.arrFilterUserStatus = ['全部', '正常使用', '审核中', '已禁用'];
        this.arrFilterUserType = ['全部', '普通发件人', '商家', '管理员', '超级管理员'];
        this.arrSortType = ['发件人名A-Z', '发件人名Z-A', '注册时间↓', '注册时间↑'];
        this.arrSelectedUserID = [];
        this.arrSelectedUserIndex = [];
        this.objCurrentShipper = {};
        this.bCopyUserName = false;
    }

    // 选中某行的响应函数
    selectRow = (record) => {
        const selectedRowKeys = [...this.state.selectedRowKeys];
        if (selectedRowKeys.indexOf(record.key) >= 0) {
            selectedRowKeys.splice(selectedRowKeys.indexOf(record.key), 1);
        } else {
            selectedRowKeys.push(record.key);
        }
        this.SelectedRowStateSetting(selectedRowKeys);
    }
    // 选中的行发生变化的响应函数
    onSelectedRowKeysChange = (selectedRowKeys) => {
        this.SelectedRowStateSetting(selectedRowKeys);
    }
    // 处理选中的表行行
    SelectedRowStateSetting = (selectedRowKeys) => {
        let btnControlDisable = [];
        let btnCopyPasteDisable = [];
        this.arrSelectedUserID = [];
        switch (selectedRowKeys.length) {
            case 0: {
                btnControlDisable = [false, true, true];
                btnCopyPasteDisable = [true, true];
                this.objCurrentShipper = {};
                break;
            }
            case 1: {
                btnControlDisable = [false, false, false];
                btnCopyPasteDisable = [false, true];
                for (let i = 0; i < this.arrTableData.length; i++) {
                    if (this.arrTableData[i].key === selectedRowKeys[0]) {
                        this.objCurrentShipper = this.arrTableData[i];
                        this.arrSelectedUserID.push(this.arrTableData[i].id);
                        break;
                    }
                }
                break;
            }
            default: {
                for (let j = 0; j < selectedRowKeys.length; j++) {
                    for (let i = 0; i < this.arrTableData.length; i++) {
                        if (this.arrTableData[i].key === selectedRowKeys[j]) {
                            this.arrSelectedUserID.push(this.arrTableData[i].id);
                            break;
                        }
                    }
                }
                btnControlDisable = [false, true, false]; btnCopyPasteDisable = [true, true]; break;
            }
        }
        this.setState({
            ...this.state,
            selectedRowKeys,
            btnControlDisable: btnControlDisable,
            btnCopyPasteDisable: btnCopyPasteDisable
        });
    }
    // 关键词处理
    KeywordsProcess = (strKeywords) => {
        let newKeywords = "";
        for (let i = 0; i < strKeywords.length; i++) {
            if (strKeywords[i] !== " ") {
                if (i === strKeywords.length - 1)
                    newKeywords += strKeywords[i];
                else
                    newKeywords += strKeywords[i] + "%";
            }
        }
        return newKeywords;
    }
    // 搜索发件人名
    ShipperNameSearch = () => {
        let strKeywords = this.refs.name_search.state.value;
        if (strKeywords === undefined)
            strKeywords = "";
        if (strKeywords.trim() === "") {
            this.GetShippersData();
        } else {
            this.setState({
                ...this.state,
                bLoading: true,
                selectedRowKeys: [],
                btnControlDisable: [false, true, true]
            });
            axios('/SearchShippers', { params: { name: this.KeywordsProcess(strKeywords) } })
                .then(({ data }) => {
                    this.arrTableData = this.OrganizeShippersData(data);
                    this.setState({
                        ...this.state,
                        bLoading: false,
                        objFilter: {
                            strKeyword: strKeywords,
                            iCurrentUserStatus: 0,
                            iCurrentUserType: 0
                        }
                    });
                }).catch(function (error) { console.log(error); });
        }
    }
    // 点击了发件人类别过滤器
    // UserTypeFilterClicked = (iUserType) => {
    //     let strTargetType = this.arrFilterUserType[iUserType];
    //     if (iUserType !== 0) {
    //         this.arrTableData = [];
    //         for (let i = 0; i < this.arrOgeTableData.length; i++) {
    //             if (strTargetType === this.arrOgeTableData[i].level) {
    //                 this.arrTableData.push(this.arrOgeTableData[i]);
    //             }
    //         }
    //     } else {
    //         this.arrTableData = this.arrOgeTableData;
    //     }
    //     if (this.refs.name_search !== undefined)
    //         this.refs.name_search.state.value = "";
    //     this.setState({
    //         ...this.state,
    //         objFilter: {
    //             strKeyword: "",
    //             iCurrentUserStatus: this.state.objFilter.iCurrentUserStatus,
    //             iCurrentUserType: iUserType
    //         }
    //     });
    // }
    // 点击了发件人状态过滤器
    // UserStatusFilterClicked = (iStatus) => {
    //     this.setState({
    //         ...this.state,
    //         objFilter: {
    //             strKeyword: "", iCurrentUserStatus: iStatus, iCurrentUserType: this.state.objFilter.iCurrentUserType
    //         }
    //     });
    // }
    /* 自定义成员函数 */
    //弹出对话框
    ShowDlg = (iDlgType, e) => {
        if (iDlgType === 0) {
            if (this.refs.name !== undefined) {
                this.refs.name.state.value = "";
                this.refs.phone.state.value = "";
                this.refs.email.state.value = "";
                this.refs.postcode.state.value = "";
                this.refs.address.state.value = "";
            }
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true,
                strNewPassword: "",
                strConfirmPassword: ""
            });
        }
        if (iDlgType === 1) {
            if (this.refs.name !== undefined) {
                this.refs.name.state.value = this.objCurrentShipper.name;
                this.refs.phone.state.value = this.objCurrentShipper.phone;
                this.refs.email.state.value = this.objCurrentShipper.email;
                this.refs.postcode.state.value = this.objCurrentShipper.postcode;
                this.refs.address.state.value = this.objCurrentShipper.address;
            }
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true,
                strNewPassword: "",
                strConfirmPassword: ""
            });
        }
        if (iDlgType === 2) {
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true
            });
        }
    }
    // 弹窗确认按钮响应
    handleOk = e => {
        // 新建
        if (this.state.iDlgType === 0) {
            let strName = this.refs.name.state.value;
            if (strName === null || strName === "" || strName === undefined) {
                message.warning("发件人名不能为空！"); return;
            }
            if (strName.trim() === "") {
                message.warning("发件人名不能为空！"); return;
            }
            let strPhone = this.refs.phone.state.value;
            if (strPhone === null || strPhone === "" || strPhone === undefined) {
                message.warning("联系电话不能为空！"); return;
            }
            if (strPhone.trim() === "") {
                message.warning("联系电话不能为空！"); return;
            }
            let strPostcode = this.refs.postcode.state.value;
            if (strPostcode === null || strPostcode === "" || strPostcode === undefined) {
                // message.warning("邮政编码不能为空！"); return;
                strPostcode = "";
            }
            if (strPostcode.trim() === "") {
                // message.warning("邮政编码不能为空！"); return;
            }
            let strAddress = this.refs.address.state.value;
            if (strAddress === null || strAddress === "" || strAddress === undefined) {
                // message.warning("收货地址不能为空"); return;
                strAddress = "";
            }
            if (strAddress.trim() === "") {
                // message.warning("收货地址不能为空"); return;
            }
            let objNewShipper = {
                name: strName,
                phone: strPhone,
                email: this.refs.email.state.value,
                address: strAddress,
                postcode: strPostcode,
                store_id: this.props.iStoreID,
                user_id: 0,
                user_name: 'xiyouji',
                user_code: ''
            };
            message.loading({ content: '正在创建新的发件人……', key: g_strMessageKey });
            axios.post('/CreateNewShipper', objNewShipper).then(
                ({ data }) => {
                    if (data.status === 1) {
                        this.GetShippersData();
                    } else {
                        message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
                    }
                }).catch(function (error) { console.log(error); });
        }
        // 编辑
        if (this.state.iDlgType === 1) {
            let strName = this.refs.name.state.value;
            if (strName === null || strName === "" || strName === undefined) {
                message.warning("发件人名不能为空！"); return;
            }
            if (strName.trim() === "") {
                message.warning("发件人名不能为空！"); return;
            }
            let strPhone = this.refs.phone.state.value;
            if (strPhone === null || strPhone === "" || strPhone === undefined) {
                message.warning("联系电话不能为空！"); return;
            }
            if (strPhone.trim() === "") {
                message.warning("联系电话不能为空！"); return;
            }
            let strPostcode = this.refs.postcode.state.value;
            if (strPostcode === null || strPostcode === "" || strPostcode === undefined) {
                strPostcode = "";
                // message.warning("邮政编码不能为空！"); return;
            }
            if (strPostcode.trim() === "") {
                // message.warning("邮政编码不能为空！"); return;
            }
            let strAddress = this.refs.address.state.value;
            if (strAddress === null || strAddress === "" || strAddress === undefined) {
                strAddress = "";
                // message.warning("收货地址不能为空"); return;
            }
            if (strAddress.trim() === "") {
                // message.warning("收货地址不能为空"); return;
            }
            let objNewShipper = {
                name: strName,
                phone: strPhone,
                email: this.refs.email.state.value,
                address: strAddress,
                postcode: strPostcode,
                store_id: this.props.iStoreID,
                user_id: this.objCurrentShipper.user_id,
                user_name: this.objCurrentShipper.user_name,
                user_code: this.objCurrentShipper.user_code
            };
            message.loading({ content: '正在更新发件人信息……', key: g_strMessageKey });
            axios.post('/UpdateShipper', {
                objCurrentShipper: this.objCurrentShipper,
                objNewShipper: objNewShipper
            }).then(({ data }) => {
                if (data.status === 1)
                    this.GetShippersData();
                else
                    message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
            }).catch(function (error) { console.log(error); });
        }
        // 删除
        if (this.state.iDlgType === 2) {
            console.log("Delete Shipper: ", this.arrSelectedUserID);
            message.loading({ content: '发件人删除中……', key: g_strMessageKey });
            axios.post('/DeleteShipper', { arrShipperID: this.arrSelectedUserID })
                .then(({ data }) => {
                    if (data.status === -1) {
                        message.warning({ content: "删除失败，请重新尝试！", key: g_strMessageKey, duration: 2 });
                        this.setState({
                            ...this.state,
                            bLoading: false,
                            bShowDlg: false,
                            selectedRowKeys: []
                        });
                    } else {
                        // DataStatistic
                        UserActiveUpdate(4, { active: "管理后台: 发件人管理操作", action: "删除" });
                        this.GetShippersData();
                    }
                }).catch(function (error) { console.log(error); });
        }
    };
    // 弹窗取消按钮响应
    handleCancel = e => {
        this.setState({
            bLoading: false,
            bShowDlg: false,
            btnControlDisable: [false, true, true],
            btnCopyPasteDisable: [true, true],
            selectedRowKeys: []
        });
    };
    // 整理Users数据
    OrganizeShippersData(data) {
        let arrTableData = [];
        for (let i = 0; i < data.length; i++) {
            let strUserInfo = data[i].user_name + " / " + data[i].user_code;
            if (data[i].user_name === 'xiyouji')
                strUserInfo = data[i].user_name;
            let objShipperData = {
                key: i, id: data[i].id,
                name: data[i].name,
                // name_ui: (
                //     <div className="copy_info_row">
                //         <div className="copy_info_text">{data[i].name}</div>
                //         <Clipboard data-clipboard-text={data[i].name} onClick={this.CopyUserName.bind(this)}
                //             className="copy_info_button export_button_style"
                //             style={{ border: 'none', borderRadius: '5px' }}>
                //             复制
                //             </Clipboard>
                //     </div>),
                phone: data[i].phone,
                email: data[i].email,
                postcode: data[i].postcode,
                address: data[i].address,
                user_info: strUserInfo
            }
            arrTableData.push(objShipperData);
        }
        return arrTableData;
    }
    // 复制发件人名
    CopyUserName = () => {
        this.bCopyUserName = true;
        message.info('发件人名已复制！');
    }
    // 显示全部发件人
    ShowAllUsers = () => {
        this.refs.name_search.state.value = "";
        this.ShipperNameSearch();
    }
    // 从数据库获取当前的数据结构
    GetShippersData() {
        message.loading({ content: '数据加载中……', key: g_strMessageKey });
        axios('/GetShippers', { params: { store_id: this.props.iStoreID } })
            .then(({ data }) => {
                console.log("GetShippers : ", data);
                global.arrShipperData = data;
                this.arrOgeTableData = this.OrganizeShippersData(data);
                this.arrTableData = this.arrOgeTableData;
                if (this.state.bShowDlg) {
                    if (this.state.iDlgType === 0) {
                        message.success({ content: "新建发件人信息成功！", key: g_strMessageKey, duration: 2 });
                    }
                    if (this.state.iDlgType === 1) {
                        message.success({ content: "发件人信息已更新！", key: g_strMessageKey, duration: 2 });
                    }
                    if (this.state.iDlgType === 2) {
                        message.success({ content: "发件人已删除！", key: g_strMessageKey, duration: 2 })
                    }
                } else {
                    message.success({ content: "数据加载完成！", key: g_strMessageKey, duration: 2 });
                }
                this.setState({
                    ...this.state,
                    bLoading: false,
                    bShowDlg: false,
                    btnControlDisable: [false, true, true],
                    btnCopyPasteDisable: [true, true],
                    selectedRowKeys: []
                });
            }).catch(function (error) { console.log(error); });
    }
    // 拷贝一行数据
    CopyShipper = () => {
        this.objCopiedShipper = {};
        this.objCopiedShipper = Object.assign({}, this.objCurrentShipper);
        this.setState({
            ...this.state,
            btnCopyPasteDisable: [true, false]
        });
        const hide = message.success("已复制发件人：<" + this.objCopiedShipper.name + ">");
        setTimeout(hide, 2000);
    }
    // 粘贴一行数据
    PasteShipper = () => {
        let objNewShipper = {
            name: this.objCopiedShipper.name,
            phone: this.objCopiedShipper.phone,
            email: this.objCopiedShipper.email,
            address: this.objCopiedShipper.address,
            postcode: this.objCopiedShipper.postcode,
            store_id: this.props.iStoreID,
            user_id: 0,
            user_name: this.objCopiedShipper.user_name,
            user_code: this.objCopiedShipper.user_code
        };
        message.loading({ content: '正在创建新的发件人……', key: g_strMessageKey });
        axios.post('/CreateNewShipper', objNewShipper).then(
            ({ data }) => {
                if (data.status === 1) {
                    this.GetShippersData();
                } else {
                    message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
                }
            }).catch(function (error) { console.log(error); });
    }
    /* React自带函数 */
    // 卸载异步操作设置状态
    componentWillUnmount() {
        clearTimeout(this.timeouter)
        this.setState = (state, callback) => {
            return;
        }
    }
    // 渲染完毕……
    componentDidMount() {
        console.log("Xiyouji CA to CN: Shipper Manager");
        this.GetShippersData();
    }
    // 渲染函数
    render() {

        let strCopyButtonStyle = { backgroundColor: '#20b2aa', color: 'white', oppacity: '1' };
        if (this.state.btnCopyPasteDisable[0])
            strCopyButtonStyle = { backgroundColor: 'white', color: 'gray', oppacity: '0.5' };
        /* 发件人管理页面控制栏 */
        let uiTitleArea = (
            < div className="store_contents_title" >
                <Button className="manage_contents_title_margin manage_contents_title_margin_right" type="primary" onClick={this.ShowAllUsers}>
                    显示全部<Icon type="redo" /></Button>
                <div className="manage_stretch_right">
                    <Input placeholder="搜索发件人名/代码" ref="name_search" defaultValue={this.state.strKeywords} />
                    <Button className="manage_contents_title_margin"
                        type="primary" onClick={this.ShipperNameSearch}>搜索
                    </Button>
                </div>
                <div className="manage_title_button_right_side">
                    <Button className="manage_contents_title_margin" type="primary" onClick={this.ShowDlg.bind(this, 0)} >
                        <Icon type="plus" />新建</Button>
                    <Button className="manage_contents_title_margin" type="primary" onClick={this.ShowDlg.bind(this, 1)} disabled={this.state.btnControlDisable[1]}>
                        <Icon type="edit" />编辑</Button>
                    <Button className="manage_contents_title_margin" type="danger" onClick={this.ShowDlg.bind(this, 2)} disabled={this.state.btnControlDisable[2]}>
                        <Icon type="close" /> 删除</Button>
                    <Button className="manage_contents_title_margin" style={strCopyButtonStyle}
                        onClick={this.CopyShipper} disabled={this.state.btnCopyPasteDisable[0]}>
                        <Icon type="copy" />复制</Button>
                    <Button className="manage_contents_title_margin" type="primary" onClick={this.PasteShipper} disabled={this.state.btnCopyPasteDisable[1]}>
                        <Icon type="edit" /> 粘贴</Button>
                </div>
            </div >
        );

        // 如果界面上需要显示的内容已经从数据库获得
        if (!this.state.bLoading) {
            // 主体表格相关
            const { selectedRowKeys } = this.state;
            const rowSelection = {
                selectedRowKeys,
                onChange: this.onSelectedRowKeysChange,
                columnWidth: 35
            };
            // 表格分页属性
            const paginationProps = {
                showSizeChanger: true,
                showQuickJumper: false,
                showTotal: () => `共 ${this.arrTableData.length} 个 发件人`,
                defaultPageSize: 10,
                defaultCurrent: this.state.iCurrentPage,
                pageSizeOptions: ['10', '20', '30', '40', '50'],
                total: this.arrTableData.length,
                position: 'bottom'
            };
            // 发件人信息编辑弹窗
            let uiModal = (
                <Modal title="编辑发件人" width={725} visible={this.state.bShowDlg}
                    okText="确定" cancelText="取消" onOk={this.handleOk} onCancel={this.handleCancel} >
                    <div className="user_edit_info_row">
                        <div className="user_edit_info_title" style={{ color: 'lightcoral' }}>姓名：</div>
                        <Input className="user_edit_info_input_long" ref="name" style={{ marginRight: '25px' }}
                            defaultValue={this.objCurrentShipper.name} />
                        <div className="user_edit_info_title" style={{ color: 'lightcoral' }}>电话：</div>
                        <Input className="user_edit_info_input_long" ref="phone"
                            defaultValue={this.objCurrentShipper.phone} />
                    </div>
                    <div className="user_edit_info_row">
                        <div className="user_edit_info_title">邮箱：</div>
                        <Input className="user_edit_info_input_long" ref="email" style={{ marginRight: '25px' }}
                            defaultValue={this.objCurrentShipper.email} />
                        <div className="user_edit_info_title">邮编：</div>
                        <Input className="user_edit_info_input_long" ref="postcode"
                            defaultValue={this.objCurrentShipper.postcode} />
                    </div>
                    <div className="user_edit_info_row">
                        <div className="user_edit_info_title" >地址：</div>
                        <Input className="user_edit_info_input_full" ref="address"
                            defaultValue={this.objCurrentShipper.address} />
                    </div>
                </Modal>
            );
            if (this.state.iDlgType === 2) {
                let uiTips = null;
                if (this.arrSelectedUserID.length > 0) {
                    uiTips = '确定删除选中的发件人？';
                } else {
                    uiTips = (
                        <div>{this.objCurrentShipper.name}
                            <br /><br />确定删除选中的发件人？
                        </div>
                    );
                }
                uiModal = (
                    <Modal width={500} title={"删除发件人信息"} visible={this.state.bShowDlg} okType="danger"
                        onOk={this.handleOk} okText="确定"
                        onCancel={this.handleCancel} cancelText="取消">
                        <div className="modal_item">
                            {uiTips}
                        </div>
                    </Modal>
                );

            }

            return (
                <div className="store_manage_contents store_manage_top_space">
                    {uiTitleArea}
                    {uiModal}
                    {/* 表格主体 */}
                    <Table style={{ margin: '20px', width: '97.5%', overflowX: 'auto' }} bordered
                        rowSelection={rowSelection} pagination={paginationProps}
                        columns={arrTableHeader} dataSource={this.arrTableData}
                        onRow={(record) => ({ onClick: () => { this.selectRow(record); } })} />
                </div >
            );
        } else {
            return (
                <div className="store_manage_contents store_manage_top_no_space">
                    {uiTitleArea}
                    <Spin className="manage_empty_loading" size="large" tip="加载中……" />
                </div>
            );
        }
    }
}
export default StoreShipperManager;
