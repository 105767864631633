import React, { Component } from "react";
import axios from 'axios';

import Radio from 'antd/es/radio';
import Input from 'antd/es/input';
// import Upload from 'antd/es/upload';
import Icon from 'antd/es/icon';
import message from 'antd/es/message';

// import { Radio, Input, Upload, Icon, message } from 'antd';
import "../../../styles/Background/UI_Module/store_image_management.css";
// import { UserActiveUpdate } from "../../../functions/DataStatistic.js"

const g_strMessageKey = 'updating';

// 获取图片的base64编码
function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}
// 从本地选择文件上传之前的检测判断（大小不能超过2M等）
// function beforeUpload(file) {
//     const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
//     if (!isJpgOrPng) {
//         message.error('请选择jpg或png格式的图片文件上传');
//     }
//     const isLt2M = file.size / 1024 / 1024 < 2;
//     if (!isLt2M) {
//         message.error('图片不能大于2Ｍ');
//     }
//     return isJpgOrPng && isLt2M;
// }

class StoreImageManagement extends Component {
    constructor(props) {
        super();
        this.state = {
            bSubmit: [false, false, false, false, false, false, false, false, false, false, false, false],  // 1+10+1个图片位置的提交状态
            bThumbSubmit: false,  // 缩略图位置的提交状态
            arrImgUrl: ['', '', '', '', '', '', '', '', '', '', '', ''],  // 1+10+1个图片位置的链接
            arrSelectedFile: [null, null, null, null, null, null, null, null, null, null, null, null],
            strThumbUrl: '',  // 缩略图的链接
            fileThumbUrl: null,
            arrImgLoading: [false, false, false, false, false, false, false, false, false, false, false, false],   // 1+10+1张头图的上传位置的加载状态
            bThumbLoading: false,  // 缩略图上传位置的家在状态
            arrFileType: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],  // 上传文件的类型
            iThumbFileType: 0,  // 缩略图文件类型
        };
        this.iCurrentUpload = -1;  // 当前刚上传完的位置编号(仅用于本地上传完成后的位置追踪)
        this.bLocalUpload = [false, false, false, false, false, false, false, false, false, false, false, false];  // 1+10+1个图片位置的上传状态
        this.bLinkChange = [false, false, false, false, false, false, false, false, false, false, false, false];  // 1+10+1个链接输入框的变化状态
        this.bThumbLocalUpload = false;
        this.bThumbLinkChange = false;
        this.bDeletingFile = false;
        this.strOldS3Link = "";
    }
    // 图片链接输入框有变化时
    LinkInputChange = (iInputID, e) => {
        // 缩略图
        if (iInputID === -1) {
            if (!this.bThumbLinkChange) {
                this.bThumbLinkChange = true;
                this.setState({
                    ...this.state,
                    bThumbSubmit: true
                });
            }
        } else {  // 其余图片位置
            if (!this.bLinkChange[iInputID]) {
                this.bLinkChange[iInputID] = true;
                let bSubmit = this.state.bSubmit;
                bSubmit[iInputID] = true;
                this.setState({
                    ...this.state,
                    bSubmit: bSubmit
                });
            }
        }
    }
    // 本地上传完毕后的显示（只在上传按钮处显示本地图片）
    AfterLocalUpload = (imgUrl) => {
        // 此时的imgUrl只是base64格式的字符串，不是网络地址
        message.success({ content: "本地图片加载完毕！", key: g_strMessageKey, duration: 2 });
        // 商家缩略图上传位置
        if (this.iCurrentUpload === -1) {
            this.bThumbLocalUpload = true;
            this.setState({
                ...this.state,
                bThumbSubmit: true,
                strThumbUrl: imgUrl,
                bThumbLoading: false
            });
        } else {  // 其余上传位置
            this.bLocalUpload[this.iCurrentUpload] = true;
            let arrImgUrl = this.state.arrImgUrl;
            arrImgUrl[this.iCurrentUpload] = imgUrl;
            let arrImgLoading = this.state.arrImgLoading;
            arrImgLoading[this.iCurrentUpload] = false;
            let bSubmit = this.state.bSubmit;
            bSubmit[this.iCurrentUpload] = true;
            this.setState({
                ...this.state,
                bSubmit: bSubmit,
                arrImgUrl: arrImgUrl,
                arrImgLoading: arrImgLoading
            });
        }
    }
    // 从本地上传图片，转化为Base64格式的状态监控
    UploadToBase64 = (iImageID, info) => {
        // 商家缩略图上传位置
        if (iImageID === -1) {
            this.iCurrentUpload = iImageID;
            if (info.file.status === 'uploading') {
                message.loading({ content: '本地图片加载中……', key: g_strMessageKey });
                this.setState({
                    ...this.state,
                    bThumbLoading: true
                });
                return;
            }
            if (info.file.status === 'done') {
                // 获取Base64格式的链接（字符串极长，无法直接存储到DB）
                getBase64(info.file.originFileObj, this.AfterLocalUpload.bind(this));
            }
        } else {  // 其余上传位置
            this.iCurrentUpload = iImageID;
            let arrImgLoading = this.state.arrImgLoading;
            arrImgLoading[iImageID] = true;
            if (info.file.status === 'uploading') {
                message.loading({ content: '本地图片加载中……', key: g_strMessageKey });
                this.setState({
                    ...this.state,
                    arrImgLoading: arrImgLoading
                });
                return;
            }
            if (info.file.status === 'done') {
                // 获取Base64格式的链接（字符串极长，无法直接存储到DB）
                getBase64(info.file.originFileObj, this.AfterLocalUpload.bind(this));
            }
        }
    };
    // 提交并保存最新的图片链接
    // SubmitImageURL = (index) => {
    //     // 缩略图位置
    //     if (index === -1) {
    //         if (!this.state.bThumbSubmit)
    //             return;
    //         // 如果是有本地新上传的数据
    //         if (this.bThumbLocalUpload) {
    //             this.bThumbLocalUpload = false;
    //             this.bThumbLinkChange = false;
    //             let strImgName = "Thumb";
    //             // 上传到云端
    //             message.loading({ content: '图片提交中……', key: g_strMessageKey });
    //             axios.post('/UploadImageToCloudinary', {
    //                 storeName: this.props.objStoreInfo.id.toString() + "-" + this.props.objStoreInfo.store_name,
    //                 imgName: strImgName,
    //                 imgUrl: this.state.strThumbUrl
    //             }).then((response) => {
    //                 message.success({ content: "图片已提交到云端！", key: g_strMessageKey, duration: 2 });
    //                 this.refs.input_thumb.state.value = response.data;
    //                 this.UpdateStoreThumb();
    //             });
    //         } else {
    //             // 如果只是输入框的链接有变化
    //             if (this.bThumbLinkChange) {
    //                 this.bThumbLinkChange = false;
    //                 this.UpdateStoreThumb();
    //             }
    //         }
    //     } else {  // 其余图片位置
    //         if (!this.state.bSubmit[index])
    //             return;
    //         // 如果是有本地新上传的数据
    //         if (this.bLocalUpload[index]) {
    //             this.bLocalUpload[index] = false;
    //             this.bLinkChange[index] = false;
    //             let strImgName = "Ads" + index.toString();
    //             if (index === 0)
    //                 strImgName = "Logo";
    //             // 上传到云端
    //             message.loading({ content: '图片提交中……', key: g_strMessageKey });
    //             axios.post('/UploadImageToCloudinary',
    //                 {
    //                     storeName: this.props.objStoreInfo.id.toString() + "-" + this.props.objStoreInfo.store_name,
    //                     imgName: strImgName,
    //                     imgUrl: this.state.arrImgUrl[index]
    //                 }).then((response) => {
    //                     message.success({ content: "图片已提交到云端！", key: g_strMessageKey, duration: 2 });
    //                     // 如果是商家Logo图片
    //                     if (index === 0) {
    //                         this.refs.input_logo.state.value = response.data;
    //                         this.UpdateStoreLogo();
    //                     } else {
    //                         let strKey = "input_image_link" + index.toString();
    //                         this.refs[strKey].state.value = response.data;
    //                         this.UpdateAdsImages(index);
    //                     }
    //                 });
    //         } else {
    //             // 如果只是输入框的链接有变化
    //             if (this.bLinkChange[index]) {
    //                 this.bLinkChange[index] = false;
    //                 if (index === 0)
    //                     this.UpdateStoreLogo();
    //                 else {
    //                     this.UpdateAdsImages(index);
    //                 }
    //             }
    //         }
    //     }
    // }
    // 更新商家表中的Logo
    UpdateStoreLogo() {

        if (this.refs.file_logo !== undefined) {
            document.getElementById("file_logo").value = "";
        }

        let strLogoUrl = this.refs.input_logo.state.value;
        message.loading({ content: '商家图标更新中……', key: g_strMessageKey });
        axios.post('/UpdateStoreLogo', { id: this.props.objStoreInfo.id, logo: strLogoUrl })
            .then((response) => {
                if (response.status === 200) {
                    message.success({ content: "商家图标已更新！", key: g_strMessageKey, duration: 2 });
                    let arrImgUrl = this.state.arrImgUrl;
                    let strOldUrl = arrImgUrl[0];
                    strOldUrl = decodeURI(strOldUrl);
                    arrImgUrl[0] = strLogoUrl;
                    let bSubmit = this.state.bSubmit;
                    bSubmit[0] = false;
                    this.setState({
                        ...this.state,
                        bSubmit: bSubmit,
                        arrImgUrl: arrImgUrl
                    });
                    if (strOldUrl.indexOf('amazonaws.com/stores/') >= 0)
                        this.DeleteFile(strOldUrl);
                } else {
                    message.success({ content: "图标更新失败，请重试！", key: g_strMessageKey, duration: 2 });
                    let bSubmit = this.state.bSubmit;
                    bSubmit[0] = false;
                    this.setState({
                        ...this.state,
                        bSubmit: bSubmit
                    });
                }

            });
    }
    // 更新商家表中的缩略图
    UpdateStoreThumb() {
        if (this.refs.file_thumb !== undefined) {
            document.getElementById("file_thumb").value = "";
        }
        let strThumbUrl = this.refs.input_thumb.state.value;
        message.loading({ content: '商家缩略图更新中……', key: g_strMessageKey });
        axios.post('/UpdateStoreThumb', { id: this.props.objStoreInfo.id, thumb: strThumbUrl })
            .then((response) => {
                message.success({ content: "商家缩略图已更新！", key: g_strMessageKey, duration: 2 });
                let strOldUrl = this.state.strThumbUrl;
                strOldUrl = decodeURI(strOldUrl);
                if (strOldUrl.indexOf('amazonaws.com/stores/') >= 0)
                    this.DeleteFile(strOldUrl);
                this.setState({
                    ...this.state,
                    bThumbSubmit: false,
                    strThumbUrl: strThumbUrl
                });
            });
    }
    // 更新商家头图图片
    UpdateAdsImages(iImageID) {

        // let strKey = "file_image_link" + iImageID.toString();
        // if (this.refs[strKey] !== undefined) {
        //     document.getElementById(strKey).value = "";
        // }

        let strKey = "input_image_link" + iImageID.toString();
        let strImageUrl = this.refs[strKey].state.value;
        message.loading({ content: '商家头图/视频更新中……', key: g_strMessageKey });
        axios.post('/UpdateAdsImages', {
            store_id: this.props.objStoreInfo.id, ads_order: iImageID, image: strImageUrl,
            type: this.state.arrFileType[iImageID]
        }).then((response) => {

            if (response.status === 200) {
                message.success({ content: "商家头图/视频已更新！", key: g_strMessageKey, duration: 2 });
                let arrImgUrl = this.state.arrImgUrl;
                let strOldUrl = arrImgUrl[iImageID];
                strOldUrl = decodeURI(strOldUrl);
                arrImgUrl[iImageID] = strImageUrl;
                let bSubmit = this.state.bSubmit;
                bSubmit[iImageID] = false;
                this.setState({
                    ...this.state,
                    bSubmit: bSubmit,
                    arrImgUrl: arrImgUrl
                });
                console.log("old url = ", strOldUrl);
                if (strOldUrl.indexOf('amazonaws.com/stores/') >= 0)
                    this.DeleteFile(strOldUrl);
            } else {
                message.success({ content: "图标更新失败，请重试！", key: g_strMessageKey, duration: 2 });
                let bSubmit = this.state.bSubmit;
                bSubmit[iImageID] = false;
                this.setState({
                    ...this.state,
                    bSubmit: bSubmit
                });
            }

        });
    }
    // 文件类型选择切换
    FileTypeSwitch = (iFileID, e) => {
        let arrFileType = this.state.arrFileType;
        arrFileType[iFileID] = e.target.value;
        this.setState({
            arrFileType: arrFileType,
        });
    };
    // 从数据库获取当前商家图片相关的数据
    GetStoreData() {
        message.loading({ content: '数据加载中……', key: g_strMessageKey });
        // 1. 发送获取Logo数据的请求
        axios('/GetStoreLogo', { params: { id: this.props.objStoreInfo.id } })
            .then(({ data }) => {
                let arrImageUrl = this.state.arrImgUrl;
                let arrFileType = this.state.arrFileType;
                if (data !== null && data !== undefined && data !== "") {
                    arrImageUrl[0] = data;
                    if (this.refs.input_logo !== undefined)
                        this.refs.input_logo.state.value = data;
                }
                // 2. 发送获取缩略图的请求
                axios('/GetStoreThumb', { params: { id: [this.props.objStoreInfo.id] } })
                    .then(({ data }) => {
                        data = data[0].thumb;
                        let strThumbUrl = this.state.strThumbUrl;
                        if (data !== null && data !== undefined && data !== "") {
                            strThumbUrl = data;
                            if (this.refs.input_thumb !== undefined)
                                this.refs.input_thumb.state.value = data;
                        }
                        // 3. 发送获取头图数据的请求
                        axios('/GetAdsImages', {
                            params: { store_id: this.props.objStoreInfo.id, ads_order: -1 }
                        }).then(({ data }) => {
                            if (data.length > 0) {
                                for (let i = 0; i < data.length; i++) {
                                    let iAdsIndex = data[i].ads_order;
                                    arrFileType[iAdsIndex] = data[i].type;
                                    let strImageUrl = data[i].image;
                                    if (strImageUrl !== null && strImageUrl !== undefined && strImageUrl !== "") {
                                        arrImageUrl[iAdsIndex] = strImageUrl;
                                        if (strImageUrl.indexOf('.jpg') === -1 && strImageUrl.indexOf('.jpeg') === -1 && strImageUrl.indexOf('.png') === -1)
                                            arrImageUrl[iAdsIndex] = "";
                                        let strKey = "input_image_link" + iAdsIndex.toString();
                                        if (this.refs[strKey] !== undefined)
                                            this.refs[strKey].state.value = strImageUrl;
                                    } else {
                                        arrImageUrl[iAdsIndex] = "";
                                    }
                                }
                            }
                            message.success({
                                content: "数据加载完成！",
                                key: g_strMessageKey,
                                duration: 2
                            });
                            this.setState({
                                ...this.state,
                                strThumbUrl: strThumbUrl,
                                arrImgUrl: arrImageUrl,
                                iThumbFileType: 0,
                                arrFileType: arrFileType,
                            });
                        }).catch(function (error) { console.log(error); });
                    }).catch(function (error) { console.log(error); });
            }).catch(function (error) { console.log(error); });
    }
    // 选择单个文件按钮响应
    SelectSingleFile = (index, event) => {
        console.log("SelectSinsleFile: ", index, event.target.files[0]);

        if (index === -1) {
            this.bThumbLocalUpload = true;
            this.setState({
                ...this.state,
                bThumbSubmit: true,
                bThumbLoading: false,
                fileThumbUrl: event.target.files[0]
            });
        }
        else {
            let arrImageUrl = this.state.arrSelectedFile;
            arrImageUrl[index] = event.target.files[0];
            this.bLocalUpload[index] = true;
            let arrImgLoading = this.state.arrImgLoading;
            arrImgLoading[index] = false;
            let bSubmit = this.state.bSubmit;
            bSubmit[index] = true;
            this.setState({
                ...this.state,
                bSubmit: bSubmit,
                arrSelectedFile: arrImageUrl,
                arrImgLoading: arrImgLoading
            });
        }
    }
    // 单个文件上传按钮响应
    singleFileUploadHandler = (index) => {
        const data = new FormData();
        if (index === -1) {
            // If file selected
            if (this.state.fileThumbUrl) {
                data.append('profileImage', this.state.fileThumbUrl, this.state.fileThumbUrl.name);
                console.log("缩略图文件数据整理完成 : ", data);
                axios.post('/aws-profile-upload', data, {
                    headers: {
                        'accept': 'application/json',
                        'Accept-Language': 'en-US,en;q=0.8',
                        'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
                        store_id: this.props.objStoreInfo.id
                    }
                }).then((response) => {
                    console.log("response : ", response);
                    if (200 === response.status) {
                        // If file size is larger than expected.
                        if (response.data.error) {
                            if ('LIMIT_FILE_SIZE' === response.data.error.code) {
                                message.warning("文件大小不能超过2Mb!");
                            } else {
                                console.log(response.data);
                                message.error("文件类型不正确！");
                            }
                        } else {
                            // Success
                            let fileName = response.data;
                            console.log('fileName', fileName.location);
                            message.success({ content: "图片已提交！", key: g_strMessageKey, duration: 2 });
                            this.refs.input_thumb.state.value = fileName.location;
                            this.UpdateStoreThumb();
                        }
                    }
                }).catch((error) => {
                    // If another error
                    console.log(error);
                    message.warning(error);
                });
            } else {
                // if file not selected throw error
                message.warning("请选择需要上传的文件！");
            }
        } else {
            // If file selected
            if (this.state.arrSelectedFile[index]) {
                data.append('profileImage', this.state.arrSelectedFile[index], this.state.arrSelectedFile[index].name);
                console.log("文件数据整理完成 : ", data);
                axios.post('/aws-profile-upload', data, {
                    headers: {
                        'accept': 'application/json',
                        'Accept-Language': 'en-US,en;q=0.8',
                        'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
                        store_id: this.props.objStoreInfo.id
                    }
                }).then((response) => {
                    console.log("response : ", response);
                    if (200 === response.status) {
                        // If file size is larger than expected.
                        if (response.data.error) {
                            if ('LIMIT_FILE_SIZE' === response.data.error.code) {
                                message.warning("文件大小不能超过2Mb!");
                            } else {
                                console.log(response.data);
                                message.error("文件类型不正确！");
                            }
                        } else {
                            // Success
                            let fileName = response.data;
                            console.log('fileName', fileName.location);
                            message.success({ content: "图片已提交！", key: g_strMessageKey, duration: 2 });
                            // 如果是商家Logo图片
                            if (index === 0) {
                                this.refs.input_logo.state.value = fileName.location;
                                this.UpdateStoreLogo();
                            } else {
                                let strKey = "input_image_link" + index.toString();
                                this.refs[strKey].state.value = fileName.location;
                                this.UpdateAdsImages(index);
                            }
                        }
                    }
                }).catch((error) => {
                    // If another error
                    console.log(error);
                    message.warning(error);
                });
            } else {
                // if file not selected throw error
                message.warning("请选择需要上传的文件！");
            }
        }

    };
    // 提交并保存最新的图片链接
    SubmitImageURL = (index) => {
        console.log("SubmitImageURL : ", index);
        // 缩略图位置
        if (index === -1) {
            if (!this.state.bThumbSubmit)
                return;
            // 如果是有本地新上传的数据
            if (this.bThumbLocalUpload) {
                this.bThumbLocalUpload = false;
                this.bThumbLinkChange = false;
                console.log("上传新的缩略图");
                this.singleFileUploadHandler(index);
            } else {
                // 如果只是输入框的链接有变化
                if (this.bThumbLinkChange) {
                    this.bThumbLinkChange = false;
                    console.log("缩略图的输入框有变化");
                    this.UpdateStoreThumb();
                }
            }
        } else {  // 其余图片位置
            if (!this.state.bSubmit[index])
                return;
            // 如果是有本地新上传的数据
            if (this.bLocalUpload[index]) {
                this.bLocalUpload[index] = false;
                this.bLinkChange[index] = false;
                this.singleFileUploadHandler(index);
            } else {
                // 如果只是输入框的链接有变化
                if (this.bLinkChange[index]) {
                    this.bLinkChange[index] = false;
                    if (index === 0)
                        this.UpdateStoreLogo();
                    else {
                        this.UpdateAdsImages(index);
                    }
                }
            }
        }
    }
    // 删除文件
    DeleteFile = (strUrl) => {
        if (this.bDeletingFile)
            return;
        this.bDeletingFile = true;
        strUrl = "stores/" + strUrl.split("stores/")[1];
        console.log("DeleteFile : ", strUrl);

        axios.post('/aws-profile-delete', {
            store_id: this.props.objStoreInfo.id,
            action: -1,
            oid: null,
            s3_link: strUrl,
            index: 0
        }).then(({ data }) => {
            console.log("delete response: ", data);
            this.bDeletingFile = false;
            this.setState({
                ...this.state,
                bFileDelete: false
            })
        });
    }

    // 卸载异步操作设置状态
    componentWillUnmount() {
        clearTimeout(this.timeouter)
        this.setState = (state, callback) => {
            return;
        }
    }
    // 渲染完毕……
    componentDidMount() {
        this.GetStoreData();
    }

    render() {

        let strSubmitBtnStyle = "store_image_submit_btn";
        let strSubmitBtnDisableStyle = "store_image_submit_disable";
        let arrSubmitBtnStyle = [strSubmitBtnStyle];
        let strThumbSubmitBtnStyle = strSubmitBtnStyle;
        if (!this.state.bSubmit[0])
            arrSubmitBtnStyle[0] = strSubmitBtnDisableStyle;
        if (!this.state.bThumbSubmit)
            strThumbSubmitBtnStyle = strSubmitBtnDisableStyle;
        let uiUploadButton = [];
        uiUploadButton.push(
            <div>
                <Icon type={this.state.arrImgLoading[0] ? 'loading' : 'plus'} />
                <div className="store_image_upload_text">上传</div>
            </div>
        );
        // let uiThumbUploadButton = (
        //     <div>
        //         <Icon type={this.state.bThumbLoading ? 'loading' : 'plus'} />
        //         <div className="store_image_upload_text">上传</div>
        //     </div>
        // );
        let uiAdsImageList = [];
        for (let i = 1; i < this.state.bSubmit.length; i++) {
            arrSubmitBtnStyle.push(strSubmitBtnStyle);
            if (!this.state.bSubmit[i]) {
                arrSubmitBtnStyle[i] = strSubmitBtnDisableStyle;
            }
            uiUploadButton.push(
                <div>
                    <Icon type={this.state.arrImgLoading[i] ? 'loading' : 'plus'} />
                    <div className="store_image_upload_text">上传</div>
                </div>
            );
            // let uiImageUpload = (
            //     <Upload disable={true} className="store_image_upload"
            //         style={{ width: '320px', height: 'auto' }}
            //         listType="picture-card" showUploadList={false} ref={"image_upload_" + i.toString()}
            //         action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            //         beforeUpload={beforeUpload} onChange={this.UploadToBase64.bind(this, i)} >
            //         {this.state.arrImgUrl[i] ? <img src={this.state.arrImgUrl[i]} alt=''
            //             style={{ width: '320px', height: 'auto' }} /> : uiUploadButton[i]}
            //     </Upload>
            // );
            let uiImageUpload = (
                <div className="store_image_upload_block">
                    <img src={this.state.arrImgUrl[i]} alt="" style={{ width: '500px', height: '281px' }} />
                </div>
            );
            if (this.state.arrImgUrl[i] === null || this.state.arrImgUrl[i] === '' || this.state.arrImgUrl[i] === undefined) {
                uiImageUpload = (
                    <div className="store_image_upload_block">
                        {null}
                    </div>
                );
            }

            if (this.state.arrFileType[i] === 1) {
                uiImageUpload = (
                    <div className="store_image_upload_disable">
                        <Icon type="play-circle" theme="twoTone" />
                    </div>
                );
            }
            let uiAdsImageTitle = (
                <div className="store_image_ads_order">
                    轮播位 {i} :
                    <Radio.Group className="store_image_file_type" onChange={this.FileTypeSwitch.bind(this, i)}
                        value={this.state.arrFileType[i]}>
                        <Radio value={0}>图片</Radio>
                        <Radio value={1}>视频</Radio>
                    </Radio.Group>
                </div>
            );
            if (i === 10) {
                uiAdsImageTitle = (
                    <div className="store_image_management_title">商家、商品默认头图　推荐尺寸：960 x 540（16 : 9）</div>
                );
            }
            if (i === 11) {
                uiAdsImageTitle = (
                    <div className="store_image_management_title">启动广告图　推荐尺寸：540 x 960（9 : 16）</div>
                );
            }
            uiAdsImageList.push(
                <div className="store_image_area" key={i}>
                    {uiAdsImageTitle}
                    {/* <div className="store_image_submit_area">
                        <Input className="store_image_url_input" placeholder="图片/视频的链接" allowClear={true} style={{ marginRight: '15px' }}
                            ref={"input_image_link" + i} defaultValue={this.state.arrImgUrl[i]}
                            onChange={this.LinkInputChange.bind(this, i)} />
                        <div className={arrSubmitBtnStyle[i]} onClick={this.SubmitImageURL.bind(this, i)} >提　交</div>
                    </div> */}
                    <div className="store_image_submit_area">
                        <input className="auto_order_select_file_button" type='file' accept='.jpg,.jpeg,.png,.gif,.pdf'
                            ref={"file_image_link" + i} id={"file_image_link" + i} onChange={this.SelectSingleFile.bind(this, i)}
                            style={{ marginLeft: '30px', marginRight: '0px', maxWidth: '200px' }} />
                        <Input className="store_image_url_input" placeholder="图片/视频的链接" allowClear={true}
                            style={{ marginLeft: '0px', marginRight: '15px' }}
                            ref={"input_image_link" + i} defaultValue={this.state.arrImgUrl[i]} onChange={this.LinkInputChange.bind(this, i)} />
                        <div className={arrSubmitBtnStyle[i]}
                            onClick={this.SubmitImageURL.bind(this, i)}>提　交</div>
                    </div>
                    {uiImageUpload}
                </div>
            );
        }


        let uiLogoImage = (
            <div className="store_image_upload_block" style={{ width: '256px', height: '256px' }}>
                暂无图片
            </div>
        );
        let uiMainImage = (
            <div className="store_image_upload_block">
                暂无图片
            </div>
        );

        if (this.state.arrImgUrl[0] !== null && this.state.arrImgUrl[0] !== undefined && this.state.arrImgUrl[0] !== "") {
            uiLogoImage = (
                <div className="store_image_upload_block">
                    <img src={this.state.arrImgUrl[0]} alt="logo" style={{ width: '256px', height: '256px' }} />
                </div>
            );
        }
        if (this.state.strThumbUrl !== null && this.state.strThumbUrl !== undefined && this.state.strThumbUrl !== "") {
            uiMainImage = (
                <div className="store_image_upload_block">
                    <img src={this.state.strThumbUrl} alt="thumb" style={{ width: '500px', height: '281px' }} />
                </div>
            );
        }

        return (
            <div className="store_manage_contents" style={{ height: '100vh' }}>
                {/* <div className={strSubmitAllBtnStyle} onClick={this.SubmitImageURL.bind(this, -1)} >全部提交</div> */}
                <div className="store_image_management_title">商标（Logo）推荐尺寸：128 x 128（1 : 1）</div>
                <div className="store_image_submit_area">
                    <input className="auto_order_select_file_button" type='file' accept='.jpg,.jpeg,.png,.gif,.pdf'
                        ref='file_logo' id="file_logo" onChange={this.SelectSingleFile.bind(this, 0)}
                        style={{ marginLeft: '30px', marginRight: '0px', maxWidth: '200px' }} />
                    <Input className="store_image_url_input" placeholder="图片的链接" allowClear={true} style={{ marginLeft: '0px', marginRight: '15px' }}
                        ref="input_logo" defaultValue={this.state.arrImgUrl[0]} onChange={this.LinkInputChange.bind(this, 0)} />
                    <div className={arrSubmitBtnStyle[0]} onClick={this.SubmitImageURL.bind(this, 0)}>提　交</div>
                </div>
                {uiLogoImage}
                {/* <Upload className="store_image_upload"
                    name="logo" listType="picture-card" showUploadList={false}
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    beforeUpload={beforeUpload} onChange={this.UploadToBase64.bind(this, 0)} >
                    {this.state.arrImgUrl[0] ? <img src={this.state.arrImgUrl[0]} alt="logo" style={{ width: '80px', height: 'auto' }} /> : uiUploadButton[0]}
                </Upload> */}
                <div className="store_image_management_title">缩略图    推荐尺寸：960 x 540</div>
                <div className="store_image_submit_area">
                    <input className="auto_order_select_file_button" type='file' accept='.jpg,.jpeg,.png,.gif,.pdf'
                        ref='file_thumb' id="file_thumb" onChange={this.SelectSingleFile.bind(this, -1)}
                        style={{ marginLeft: '30px', marginRight: '0px', maxWidth: '200px' }} />
                    <Input className="store_image_url_input" placeholder="图片的链接" allowClear={true} style={{ marginLeft: '0px', marginRight: '15px' }}
                        ref="input_thumb" defaultValue={this.state.strThumbUrl} onChange={this.LinkInputChange.bind(this, -1)} />
                    <div className={strThumbSubmitBtnStyle} onClick={this.SubmitImageURL.bind(this, -1)} >提　交</div>
                </div>
                {uiMainImage}
                {/* <Upload className="store_image_upload"
                    name="thumb" listType="picture-card" showUploadList={false}
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    beforeUpload={beforeUpload} onChange={this.UploadToBase64.bind(this, -1)} >
                    {this.state.strThumbUrl ? <img src={this.state.strThumbUrl} alt="thumb" style={{ width: '250px', height: 'auto' }} /> : uiThumbUploadButton}
                </Upload> */}
                <div className="store_image_management_title">宣传头图/视频（共9个轮播位）推荐尺寸：960 x 540（16 : 9）</div>
                {uiAdsImageList}
            </div>
        );
    }
}
export default StoreImageManagement;
