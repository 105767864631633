import React, { Component } from "react";
import axios from 'axios';
import cookie from 'react-cookies';
import "antd/dist/antd.css";
import "../../styles/Background/db_manager.css";

import Empty from 'antd/es/empty';
import Spin from 'antd/es/spin';
import Menu from 'antd/es/menu';
import Icon from 'antd/es/icon';
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import Form from 'antd/es/form';
import message from 'antd/es/message';

import XiYouJiUserManager from "./DBManagerPage/XiYouJiUserManager.js";
import DBUserManager from "./DBManagerPage/DBUserManager.js";
import AccountingRecord from "./DBManagerPage/AccountingRecord.js";
import AccountingTarget from "./DBManagerPage/AccountingTarget.js";
import { UserActiveUpdate } from "../../functions/DataStatistic.js";

const g_strMessageKey = 'updating';

function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class Accounting extends Component {
    // 构造函数
    constructor(props) {
        super();
        this.state = {
            bLoading: true,  // 加载状态
            bLogin: false,  // 登录状态
            iCurrentTopBarIndex: 0,  // 顶部栏菜单索引
            iCurrentSideBarIndex: 0,  // 当前显示的中心区域内容索引
        };
        this.arrTopMenuButton = [
            { id: 0, name: "记账管理", icon: "account-book" },
            // { id: 1, name: "数据库管理", icon: "database" },
            // { id: 2, name: "西邮寄数据", icon: "api" }
        ];
        this.arrSideMenuButton0 = [
            { id: 0, name: "账目", icon: "unordered-list" },
            { id: 1, name: "统计", icon: "bar-chart" },
            { id: 2, name: "账户", icon: "user" }
        ];
        this.arrSideMenuButton1 = [
            { id: 0, name: "账户", icon: "smile" }
        ];
        this.arrSideMenuButton2 = [
            { id: 0, name: "用户", icon: "user" },
            { id: 1, name: "测试", icon: "alert" }
        ];
        this.arrCategoriesData = [];  // 用于初始处理分类数据
        this.arrLocationsData = [];  // 用于初始处理地区数据
        this.strCurrentUserName = '';
        this.strCurrentUserCode = '';

    }
    // 切换侧边栏菜单
    SwitchSideMenu = (iMenuID) => {
        this.setState({
            ...this.state,
            bLoading: true
        });
        let iCurrentSideBarIndex = 0;
        if (this.state.iCurrentTopBarIndex === 0)
            iCurrentSideBarIndex = this.arrSideMenuButton0[iMenuID].id;
        if (this.state.iCurrentTopBarIndex === 1)
            iCurrentSideBarIndex = this.arrSideMenuButton1[iMenuID].id;
        if (this.state.iCurrentTopBarIndex === 2)
            iCurrentSideBarIndex = this.arrSideMenuButton2[iMenuID].id;
        switch (iMenuID) {
            default: {
                this.setState({
                    ...this.state,
                    bLoading: false,
                    iCurrentSideBarIndex: iCurrentSideBarIndex
                });
                break;
            }
        }
    }
    // 切换顶部栏菜单
    SwitchTopBarMenu = (iMenuID) => {
        this.setState({
            ...this.state,
            bLoading: true
        });
        let iCurrentSideBarIndex = 0;
        if (iMenuID === 0)
            iCurrentSideBarIndex = this.arrSideMenuButton0[0].id;
        if (iMenuID === 1)
            iCurrentSideBarIndex = this.arrSideMenuButton1[0].id;
        if (iMenuID === 2)
            iCurrentSideBarIndex = this.arrSideMenuButton2[0].id;
        this.setState({
            ...this.state,
            bLoading: false,
            iCurrentTopBarIndex: iMenuID,
            iCurrentSideBarIndex: iCurrentSideBarIndex
        });
    }
    // 整理Categories的数据
    OrganizeCategoriesData(iParentID, strParentKey) {
        let arrCurrentLevelCategories = [];
        let iCurrentCategoryKey = 0;
        for (let i = 0; i < this.arrCategoriesData.length; i++) {
            if (this.arrCategoriesData[i].parent_id === iParentID) {
                if (this.arrCategoriesData[i].icon === '')
                    this.arrCategoriesData[i].icon = "about_us_icon";
                let objCategory = {
                    key: strParentKey + "-" + iCurrentCategoryKey.toString(),
                    id: this.arrCategoriesData[i].id, parent_id: iParentID,
                    level: this.arrCategoriesData[i].level,
                    title: this.arrCategoriesData[i].name + " - " + this.arrCategoriesData[i].en_name,
                    name: this.arrCategoriesData[i].name,
                    en_name: this.arrCategoriesData[i].en_name,
                    icon: this.arrCategoriesData[i].icon,
                    children: []
                };
                if (objCategory.en_name === null || objCategory.en_name === "null") {
                    objCategory.en_name = "";
                    objCategory.title = this.arrCategoriesData[i].name + " - " +
                        this.arrCategoriesData[i].en_name;
                }
                let arrChildren = this.OrganizeCategoriesData(objCategory.id, objCategory.key);
                objCategory.children = arrChildren;
                arrCurrentLevelCategories.push(objCategory);
                iCurrentCategoryKey++;
            }
        }
        return arrCurrentLevelCategories;
    }
    // 获取分类数据
    GetCategoriesData() {
        axios('/GetCategories', { params: { id: 0 } })
            .then(({ data }) => {
                this.arrCategoriesData = data;
                global.arrCategoriesData = this.OrganizeCategoriesData(0, "0");
                // 还未获得地区数据(未点击过分类按钮)
                if (global.arrLocationsData[0].children.length === 0) {
                    this.GetLocationData();
                } else {
                    // 有地区数据都有则直接刷新
                    this.setState({
                        ...this.state,
                        bLoading: false,
                        iCurrentInterface: this.arrSideMenuButton0[1].id,
                    });
                }
            }).catch(function (error) { console.log(error); });
    }
    // 整理Locations数据
    OrganizeLocationsData(iParentID, strParentKey) {
        let arrCurrentLevelLocations = [];
        let iCurrentLocationKey = 0;
        for (let i = 0; i < this.arrLocationsData.length; i++) {
            if (this.arrLocationsData[i].parent_id === iParentID) {
                let objLocation = {
                    key: strParentKey + "-" + iCurrentLocationKey.toString(),
                    id: this.arrLocationsData[i].id, parent_id: iParentID,
                    level: this.arrLocationsData[i].level,
                    title: this.arrLocationsData[i].name + " - " + this.arrLocationsData[i].en_name,
                    name: this.arrLocationsData[i].name,
                    en_name: this.arrLocationsData[i].en_name,
                    latitude_x: this.arrLocationsData[i].latitude_x,
                    longitude_y: this.arrLocationsData[i].longitude_y,
                    children: []
                };
                let arrChildren = this.OrganizeLocationsData(objLocation.id, objLocation.key);
                objLocation.children = arrChildren;
                arrCurrentLevelLocations.push(objLocation);
                if (objLocation.id === 3) {
                    global.arrVancouverAreaData = [];
                    global.arrVancouverAreaData.push(objLocation);
                }
                iCurrentLocationKey++;
            }
        }
        return arrCurrentLevelLocations;
    }
    // 获取地区数据
    GetLocationData() {
        axios('/GetLocations', { params: { id: 0 } })
            .then(({ data }) => {
                this.arrLocationsData = data;
                global.arrLocationsData = this.OrganizeLocationsData(0, "0");
                this.setState({
                    ...this.state,
                    bLoading: false,
                    iCurrentInterface: this.arrSideMenuButton0[1].id,
                });
            })
            .catch(function (error) { console.log(error); });
    }
    // 登录表单提交响应
    LoginSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                // DataStatistic
                UserActiveUpdate(0, { active: "管理后台: 尝试登录", user: values.username });
                message.loading({ content: '登录中……', key: g_strMessageKey });
                this.setState({
                    ...this.state,
                    bLoading: true
                });
                this.TryLogin(values.username, values.password)
            }
        });
    };
    // 尝试登录
    TryLogin = (name, password) => {
        let objUserInfo = { name: name, password: password };
        axios('/MouthingBackEndLogin', { params: objUserInfo })
            .then(({ data }) => {
                if (data.status === -1) {
                    message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
                    this.setState({
                        ...this.state,
                        bLoading: false
                    });
                }
                else {
                    // DataStatistic
                    UserActiveUpdate(0, { active: "管理后台: 登录成功", user: name });
                    message.success({ content: data.message, key: g_strMessageKey, duration: 2 });
                    this.strCurrentUserName = name;
                    cookie.save('ego_user_code', data.code, { path: '/' });
                    cookie.save('ego_login_status', 1, { path: '/' });
                    this.setState({
                        ...this.state,
                        bLoading: false,
                        bLogin: true
                    });
                    this.SwitchSideMenu(this.state.iCurrentSideBarIndex);
                }
            }).catch(function (error) { console.log(error); });
    }
    // 退出登录
    LogoutClick = () => {
        // DataStatistic
        UserActiveUpdate(0, { active: "管理后台: 退出成功", user: this.strCurrentUserName });
        cookie.remove('ego_user_code', { path: '/' });
        cookie.save('ego_login_status', -1, { path: '/' });
        this.setState({
            ...this.state,
            bLogin: false
        });
    }
    // 用户识别码检查
    UserCodeCheck = () => {
        axios('/UserCodeExistCheck', { params: { code: this.strCurrentUserCode, level: 10 } })
            .then(({ data }) => {
                if (data.status === -1) {
                    message.warning(data.message);
                    this.setState({
                        ...this.state,
                        bLoading: false
                    });
                } else {
                    message.success(data.message);
                    this.strCurrentUserName = data.name;
                    this.setState({
                        ...this.state,
                        bLoading: false,
                        bLogin: true
                    });
                }
            }).catch(function (error) { console.log(error); });
    }
    // 从数据库获取当前的数据结构
    GetUsersData() {
        // console.log("GetUsersData = ", this.strCurrentUserCode);

        // console.log('my api request >>>');
        // fetch("https://api.ipify.org/?format=json", {
        // fetch("https://bk.mouthing.info/api/test", {
        //     // fetch("http://cn.jwestlog.com/API/ZY_Depot.aspx", {
        //     method: 'GET',
        //     mode: 'cors',
        //     headers: new Headers({
        //         'Content-Type': 'text/plain'
        //     })
        //     // headers: {
        //     //     'Content-Type': 'application/json'
        //     // },
        // }).then(res => res.json())
        //     .then(res => {
        //         console.log("result1 : ", res);
        //     }).catch(err => {
        //         console.log(err);
        //     });


        // fetch("http://cn.jwestlog.com/API/ZY_Depot.aspx", {
        //     method: 'GET',
        //     mode: 'no-cors',
        //     // headers: new Headers({
        //     //     'Content-Type': 'text/plain',
        //     //     'Access-Control-Allow-Origin': '*'
        //     // }),
        //     headers: {
        //         'Content-Type': 'application/json',
        //         'Access-Control-Allow-Origin': '*'
        //     },
        //     // body: data
        // }).then(res => { res.text(); console.log(res); })
        //     .then(res => {
        //         console.log("result2 : ", res);
        //     }).catch(err => {
        //         console.log(err);
        //     });

        // var proxyUrl = 'https://cors-anywhere.herokuapp.com/',
        //     targetUrl = 'http://cn.jwestlog.com/API/ZY_Depot.aspx';
        // fetch(proxyUrl + targetUrl)
        //     .then(blob => blob.json())
        //     .then(data => {
        //         console.log(data);
        //     }).catch(err => {
        //         console.log(err);
        //     });

        // var proxyUrl = 'https://cors-anywhere.herokuapp.com/',
        //     targetUrl = 'http://cn.jwestlog.com/API/UserListAPI.aspx';
        // let data = { "PageIndex": "1", "OpenID": "XiYouJi" };
        // fetch(proxyUrl + targetUrl, {
        //     method: 'POST',
        //     mode: 'cors',
        //     //     // headers: new Headers({
        //     //     //     'Content-Type': 'text/plain',
        //     //     //     'Access-Control-Allow-Origin': '*'
        //     //     // }),
        //     headers: {
        //         'Content-Type': 'application/json',
        //         'Access-Control-Allow-Origin': '*'
        //     },
        //     body: JSON.stringify(data)
        // }).then(blob => blob.json())
        //     .then(data => {
        //         console.log("data:", data);
        //     }).catch(err => {
        //         console.log("err : ", err);
        //     });
    }
    // 渲染完毕……
    componentDidMount() {
        let strUserCode = cookie.load('ego_user_code');
        let iUserLoginStatus = cookie.load('ego_login_status');
        if ((iUserLoginStatus === 1 || iUserLoginStatus === '1') && strUserCode !== undefined) {
            this.strCurrentUserCode = strUserCode;
            this.UserCodeCheck();
        } else {
            this.setState({ ...this.state, bLoading: false, bLogin: false });
        }
        document.title = "*MDBM数据库管理后台*";
    }

    render() {

        const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;
        // Only show error after a field is touched.
        const usernameError = isFieldTouched('username') && getFieldError('username');
        const passwordError = isFieldTouched('password') && getFieldError('password');
        // 登录表单
        const uiLogInForm = (
            <Form className="manage_login_form" layout="inline" onSubmit={this.LoginSubmit}>
                <Form.Item validateStatus={usernameError ? 'error' : ''} help={usernameError || ''}>
                    {getFieldDecorator('username')(
                        <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="用户名" />,
                    )}
                </Form.Item>
                <Form.Item validateStatus={passwordError ? 'error' : ''} help={passwordError || ''}>
                    {getFieldDecorator('password')(
                        <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="密码" />,
                    )}
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" disabled={hasErrors(getFieldsError())}>
                        登 陆
                    </Button>
                </Form.Item>
            </Form>
        );
        // 欢迎回来
        let strTitle = '欢迎回来,  ' + this.strCurrentUserName;
        const uiWelcomeBack = (
            <div className="manage_login_form">
                <div className="welcome_back_title">{strTitle}</div>
                <Button className="logout_btn" type="primary" htmlType="submit"
                    onClick={this.LogoutClick}>退出登录</Button>
            </div>
        );
        // 顶部菜单栏
        let uiNavBar = (
            <div className="manage_top_bar">
                <div className="platform_logo" >Mouthing</div>
                <Menu className="manage_top_bar_menu" defaultSelectedKeys={["0"]} mode="horizontal"
                    theme="dark" onClick={this.handleClick} >
                    {this.arrTopMenuButton.map((item, index) => {
                        return (
                            <Menu.Item key={index} onClick={this.SwitchTopBarMenu.bind(this, index)}>
                                <Icon type={item.icon} />{item.name}
                            </Menu.Item>
                        );
                    })}
                </Menu>
                {uiWelcomeBack}
            </div>
        );
        // 侧边菜单栏
        let arrSideMenuButton = null;
        if (this.state.iCurrentTopBarIndex === 0)
            arrSideMenuButton = this.arrSideMenuButton0;
        if (this.state.iCurrentTopBarIndex === 1)
            arrSideMenuButton = this.arrSideMenuButton1;
        if (this.state.iCurrentTopBarIndex === 2)
            arrSideMenuButton = this.arrSideMenuButton2;
        let uiSideBar = (
            <Menu className="manage_side_bar" defaultSelectedKeys={[this.state.iCurrentSideBarIndex.toString()]}
                selectedKeys={[this.state.iCurrentSideBarIndex.toString()]}
                mode="inline" theme="dark" inlineCollapsed={this.state.collapsed}>
                {arrSideMenuButton.map((item, index) => {
                    return (
                        <Menu.Item key={index} onClick={this.SwitchSideMenu.bind(this, index)}>
                            <Icon type={item.icon} />{item.name}
                        </Menu.Item>
                    );
                })}
            </Menu>
        );
        if (!this.state.bLogin) {
            uiNavBar = (
                <div className="manage_top_bar">
                    <div className="platform_logo" >Mouthing</div>
                    <div className="manage_top_bar_menu" />
                    {uiLogInForm}
                </div>
            );
            uiSideBar = <div />;
        }

        let uiCentralArea;
        // 是否还在加载中……
        if (this.state.bLoading) {
            uiCentralArea = (<Spin className="manage_empty_loading" size="large" tip="加载中……" />);
        } else {
            if (this.state.bLogin) {
                let uiAccountingRecord = null;
                let uiAccountingTarget = null;
                let uiUserManager = null;
                let uiOutsideAPI = null;
                if (this.state.iCurrentTopBarIndex === 0) {
                    uiAccountingRecord = (this.state.iCurrentSideBarIndex === this.arrSideMenuButton0[0].id && <AccountingRecord />);
                    uiAccountingTarget = (this.state.iCurrentSideBarIndex === this.arrSideMenuButton0[2].id && <AccountingTarget />);
                }
                if (this.state.iCurrentTopBarIndex === 1) {
                    uiUserManager = (this.state.iCurrentSideBarIndex === this.arrSideMenuButton0[0].id && <DBUserManager />);
                }
                if (this.state.iCurrentTopBarIndex === 2) {
                    uiUserManager = (this.state.iCurrentSideBarIndex === this.arrSideMenuButton1[0].id && <XiYouJiUserManager />);
                }
                uiCentralArea = (
                    <div>
                        {uiAccountingRecord}
                        {uiAccountingTarget}
                        {uiUserManager}
                        {uiOutsideAPI}
                    </div>
                );
            } else {
                uiCentralArea = (<Empty className="manage_empty_loading" description={<span>未登录，无法使用</span>} />);
            }
        }

        return (
            <div className="manage_page">
                {/* 顶部菜单栏 */}
                {uiNavBar}
                {/* 侧边菜单栏 */}
                {uiSideBar}
                {/* 中心区域内容 */}
                {uiCentralArea}
            </div >
        );
    }
}
export default Form.create()(Accounting);
