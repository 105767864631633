import React, { Component } from 'react';
import axios from 'axios';

import Spin from 'antd/es/spin';
import Table from 'antd/es/table';
import Button from 'antd/es/button';
import Input from 'antd/es/input';
import Menu from 'antd/es/menu';
import Dropdown from 'antd/es/dropdown';
import Icon from 'antd/es/icon';
import Modal from 'antd/es/modal';
import Checkbox from 'antd/es/checkbox';
import message from 'antd/es/message';
import Barcode from 'react-barcode';
import ReactToPrint from "react-to-print";

import '../../../styles/Background/ego_manager.css';
import '../../../styles/Background/storage_manager.css';
import '../../../styles/Background/UI_Module/user_management.css';
// import { UserActiveUpdate } from "../../../functions/DataStatistic.js"

const g_strMessageKey = 'updating';

// 表格
const arrClientTableHeader = [
    { title: '入库单号', dataIndex: 'code', width: 125, ellipsis: true },
    { title: '类型', dataIndex: 'type_str', width: 75, ellipsis: true },
    { title: '状态', dataIndex: 'status_str', width: 75, ellipsis: true },
    { title: '物流', dataIndex: 'express_no', width: 100, ellipsis: true },
    { title: '箱数', dataIndex: 'box_num', width: 50, ellipsis: true },
    { title: '费用', dataIndex: 'service_fee', width: 50, ellipsis: true },
    { title: '备注', dataIndex: 'comments', width: 125, ellipsis: true },
    { title: '更新日期', dataIndex: 'update_date_str', width: 100, ellipsis: true }
];

const arrManagerTableHeader = [
    { title: '入库单号', dataIndex: 'code', width: 125, ellipsis: true },
    { title: '客户', dataIndex: 'user_name', width: 75, ellipsis: true },
    { title: '类型', dataIndex: 'type_str', width: 75, ellipsis: true },
    { title: '状态', dataIndex: 'status_str', width: 75, ellipsis: true },
    { title: '物流', dataIndex: 'express_no', width: 125, ellipsis: true },
    { title: '箱数', dataIndex: 'box_num', width: 50, ellipsis: true },
    { title: '费用', dataIndex: 'service_fee', width: 50, ellipsis: true },
    { title: '备注', dataIndex: 'comments', width: 125, ellipsis: true },
    { title: '更新日期', dataIndex: 'update_date_str', width: 100, ellipsis: true }
];

class StorageEnteringManager extends Component {
    constructor(props) {
        super();
        this.state = {
            btnControlDisable: [false, true, true],  // 操作按钮控制
            selectedRowKeys: [],  // Check here to configure the default column
            iDlgType: -1,
            bShowDlg: false,
            bLoading: true,
            bValueWeightChange: false,
            iStatus: 0,
            iType: 0,
            bAddNew: false,
            bSelectSKU: false
        };
        // 成员变量
        this.arrOgeTableData = [];
        this.arrTableData = [];  // 用于表格的数组
        this.objCurrentEnteringOrder = {};
        this.fValue = 0.0;
        this.fValueWeight = 0.0;
        this.fPriceWeight = 0.0;
        this.arrStatus = ['已预报', '运输中', '已签收', '已入库', '已上架', '作废'];
        this.arrType = ['自发预报', '物流预报', '退货预报'];
        this.arrBoxInfo = [
            [{ box: 0, sku: '', name: '', num: 0, num_check: 0, status: 0 }]
        ];
        this.iTotalSKUNum = 0;
        this.iTotalItemNum = 0;

        this.strScanInput = "";
        this.timeLast = new Date();
        this.timeCurrent = new Date();
        this.strLastScanCode = "";
        this.strCurrentScanCode = "";
        this.iBoxIndex = 0;
        this.iItemIndex = 0;
        this.iSKUIndex = 0;
    }

    // 选中某行的响应函数
    selectRow = (record) => {
        const selectedRowKeys = [...this.state.selectedRowKeys];
        if (selectedRowKeys.indexOf(record.key) >= 0) {
            selectedRowKeys.splice(selectedRowKeys.indexOf(record.key), 1);
        } else {
            selectedRowKeys.push(record.key);
        }
        this.SelectedRowStateSetting(selectedRowKeys);
    }
    // 选中的行发生变化的响应函数
    onSelectedRowKeysChange = (selectedRowKeys) => {
        this.SelectedRowStateSetting(selectedRowKeys);
    }
    // 处理选中的表行行
    SelectedRowStateSetting = (selectedRowKeys) => {
        let btnControlDisable = [];
        // let btnCopyPasteDisable = [];
        switch (selectedRowKeys.length) {
            case 0: {
                btnControlDisable = [false, true, true];
                // btnCopyPasteDisable = [true, true];
                this.CurrentEnteringOrderInit();
                break;
            }
            case 1: {
                btnControlDisable = [false, false, false];
                // btnCopyPasteDisable = [false, true];
                for (let i = 0; i < this.arrTableData.length; i++) {
                    if (this.arrTableData[i].key === selectedRowKeys[0]) {
                        this.objCurrentEnteringOrder = this.arrTableData[i];
                        break;
                    }
                }
                break;
            }
            default: {
                btnControlDisable = [false, true, true];
                // btnCopyPasteDisable = [true, true];
                break;
            }
        }
        this.setState({
            ...this.state,
            selectedRowKeys,
            btnControlDisable: btnControlDisable
            // btnCopyPasteDisable: btnCopyPasteDisable
        });
    }
    // 关键词处理
    KeywordsProcess = (strKeywords) => {
        let newKeywords = "";
        for (let i = 0; i < strKeywords.length; i++) {
            if (strKeywords[i] !== " ") {
                if (i === strKeywords.length - 1)
                    newKeywords += strKeywords[i];
                else
                    newKeywords += strKeywords[i] + "%";
            }
        }
        return newKeywords;
    }
    // 搜索EnteringOrder名
    // UserNameSearch = () => {
    //     let strKeywords = this.refs.user_name_search.state.value;
    //     if (strKeywords === undefined)
    //         strKeywords = "";
    //     if (strKeywords.trim() === "") {
    //         this.GetEnteringOrder();
    //     } else {
    //         this.setState({
    //             ...this.state,
    //             bLoading: true,
    //             selectedRowKeys: [],
    //             btnControlDisable: [false, true, true]
    //         });
    //         axios('/SearchUsers', { params: { name: this.KeywordsProcess(strKeywords) } })
    //             .then(({ data }) => {
    //                 this.arrTableData = this.OrganizeEnteringOrderData(data);
    //                 this.setState({
    //                     ...this.state,
    //                     bLoading: false,
    //                     objFilter: {
    //                         strKeyword: strKeywords,
    //                         iCurrentUserStatus: 0,
    //                         iCurrentUserType: 0
    //                     }
    //                 });
    //             }).catch(function (error) { console.log(error); });
    //     }
    // }
    // 点击了EnteringOrder过滤器
    // UserTypeFilterClicked = (iUserType) => {
    //     let strTargetType = this.arrFilterUserType[iUserType];
    //     if (iUserType !== 0) {
    //         this.arrTableData = [];
    //         for (let i = 0; i < this.arrOgeTableData.length; i++) {
    //             if (strTargetType === this.arrOgeTableData[i].level) {
    //                 this.arrTableData.push(this.arrOgeTableData[i]);
    //             }
    //         }
    //     } else {
    //         this.arrTableData = this.arrOgeTableData;
    //     }
    //     if (this.refs.user_name_search !== undefined)
    //         this.refs.user_name_search.state.value = "";
    //     this.setState({
    //         ...this.state,
    //         objFilter: {
    //             strKeyword: "",
    //             iCurrentUserStatus: this.state.objFilter.iCurrentUserStatus,
    //             iCurrentUserType: iUserType
    //         }
    //     });
    // }
    // 点击了EnteringOrder状态过滤器
    // UserStatusFilterClicked = (iStatus) => {
    //     this.setState({
    //         ...this.state,
    //         objFilter: {
    //             strKeyword: "", iCurrentUserStatus: iStatus, iCurrentUserType: this.state.objFilter.iCurrentUserType
    //         }
    //     });
    // }
    /* 自定义成员函数 */
    //弹出对话框
    ShowDlg = (iDlgType, e) => {
        if (iDlgType === 0) {
            if (this.refs.code !== undefined) {
                this.refs.code.state.value = "";
                this.refs.length.state.value = 0.0;
                this.refs.width.state.value = 0.0;
                this.refs.height.state.value = 0.0;
                // this.refs.value.state.value = 0.0;
                this.refs.weight.state.value = 0.0;
                this.refs.express_no.state.value = "";
                this.refs.service_fee.state.value = "";
                this.refs.comments.state.value = "";
            }
            this.fValue = 0.0;
            this.fValueWeight = 0.0;
            this.fPriceWeight = 0.0;
            this.CurrentEnteringOrderInit();
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true,
                iStatus: 0,
                iType: 0
            });
        }
        if (iDlgType === 1) {
            if (this.refs.code !== undefined) {
                this.refs.code.state.value = this.objCurrentEnteringOrder.code;
                this.refs.length.state.value = this.objCurrentEnteringOrder.length;
                this.refs.width.state.value = this.objCurrentEnteringOrder.width;
                this.refs.height.state.value = this.objCurrentEnteringOrder.height;
                // this.refs.value.state.value = this.objCurrentEnteringOrder.value;
                this.refs.weight.state.value = this.objCurrentEnteringOrder.weight;
                this.refs.value_weight.state.value = this.objCurrentEnteringOrder.value_weight;
                this.refs.service_fee.state.value = this.objCurrentEnteringOrder.service_fee;
                this.refs.express_no.state.value = this.objCurrentEnteringOrder.express_no;
                this.refs.comments.state.value = this.objCurrentEnteringOrder.comments;
            }
            this.fValue = parseFloat(this.objCurrentEnteringOrder.value);
            this.fValueWeight = parseFloat(this.objCurrentEnteringOrder.value_weight);
            this.fPriceWeight = parseFloat(this.objCurrentEnteringOrder.price_weight);
            message.loading({ content: '数据加载中……', key: g_strMessageKey });
            axios('/GetEnteringOrderItemInfo', { params: { entering: this.objCurrentEnteringOrder.code } })
                .then(({ data }) => {
                    message.success({ content: "货物信息加载完成！", key: g_strMessageKey, duration: 2 });
                    if (data.length > 0) {
                        this.arrBoxInfo = [];
                        let arrItemInfo = [];
                        let iBox = data[0].box;
                        for (let i = 0; i < data.length; i++) {
                            if (data[i].box === iBox) {
                                let objItemInfo = {
                                    box: data[i].box,
                                    sku: data[i].sku,
                                    name: data[i].name,
                                    num: data[i].num,
                                    num_check: data[i].num_check,
                                    status: data[i].status
                                }
                                arrItemInfo.push(objItemInfo);
                                if (i === data.length - 1)
                                    this.arrBoxInfo.push(arrItemInfo);
                            } else {
                                this.arrBoxInfo.push(arrItemInfo);
                                arrItemInfo = [];
                                iBox = data[i].box;
                                let objItemInfo = {
                                    box: data[i].box,
                                    sku: data[i].sku,
                                    name: data[i].name,
                                    num: data[i].num,
                                    num_check: data[i].num_check,
                                    status: data[i].status
                                }
                                arrItemInfo.push(objItemInfo);
                                if (i === data.length - 1)
                                    this.arrBoxInfo.push(arrItemInfo);
                            }
                        }
                    }
                    this.setState({
                        ...this.state,
                        iDlgType: iDlgType,
                        bShowDlg: true,
                        iStatus: this.objCurrentEnteringOrder.status,
                        iType: this.objCurrentEnteringOrder.type
                    });
                }).catch(function (error) { console.log(error); });
        }
        if (iDlgType === 2) {
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true
            });
        }
    }
    // 弹窗确认按钮响应
    handleOk = e => {
        if (this.state.iDlgType === 0 || this.state.iDlgType === 1) {

            if (!this.BoxInfoCheck())
                return;

            let objNewEnteringOrder = {
                type: this.state.iType,
                status: this.state.iStatus,
                length: this.refs.length.state.value,
                width: this.refs.width.state.value,
                height: this.refs.height.state.value,
                // value: this.refs.value.state.value,
                value: this.objCurrentEnteringOrder.value,
                value_weight: this.refs.value_weight.state.value,
                weight: this.refs.weight.state.value,
                price_weight: this.fPriceWeight,
                express_no: this.refs.express_no.state.value,
                box_num: this.arrBoxInfo.length,
                service_fee: this.refs.service_fee.state.value,
                comments: this.refs.comments.state.value,
                user_name: this.props.strUserName,
                user_id: this.props.iUserID,
                store_id: this.props.objStoreInfo.id
            }
            if (this.state.iDlgType === 1) {
                objNewEnteringOrder.code = this.objCurrentEnteringOrder.code;
                objNewEnteringOrder.user_id = this.objCurrentEnteringOrder.user_id;
                objNewEnteringOrder.user_name = this.objCurrentEnteringOrder.user_name;
            }
            message.loading({ content: '正在更新入库记录……', key: g_strMessageKey });
            axios.post('/UpdateEnteringOrder', {
                objCurrentEnteringOrder: this.objCurrentEnteringOrder,
                objNewEnteringOrder: objNewEnteringOrder,
                arrBoxInfo: this.arrBoxInfo,
                action: this.state.iDlgType,
                invite_code: this.props.strInviteCode
            }).then(({ data }) => {
                if (data.status === 1) {
                    this.GetEnteringOrder();
                } else {
                    if (data.message)
                        message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
                    else
                        message.warning({ content: "新建/更新入库记录失败！", key: g_strMessageKey, duration: 2 });
                }
            }).catch(function (error) { console.log(error); });
        }
        if (this.state.iDlgType === 2) {
            message.loading({ content: '入库记录删除中……', key: g_strMessageKey });
            axios.post('/DeleteEnteringOrder', { id: this.objCurrentEnteringOrder.id, code: this.objCurrentEnteringOrder.code }).then(
                ({ data }) => {
                    if (data.status === -1) {
                        message.warning({
                            content: "删除失败，数据库中未找到指定入库单！",
                            key: g_strMessageKey, duration: 2
                        });
                        this.setState({
                            ...this.state,
                            btnControlDisable: [false, true, true],
                            bLoading: false,
                            bShowDlg: false,
                            selectedRowKeys: []
                        });
                    } else {
                        this.GetEnteringOrder();
                    }
                }).catch(function (error) { console.log(error); });
        }
    };
    // 弹窗取消按钮响应
    handleCancel = e => {
        this.arrBoxInfo = [
            [{ box: '', sku: '', name: '', num: 0, num_check: 0, status: 0 }]
        ];
        this.setState({
            bShowDlg: false,
            selectedRowKeys: [],
            btnControlDisable: [false, true, true]
        });
    };
    // 整理Users数据
    OrganizeEnteringOrderData(data) {
        let arrTableData = [];
        for (let i = 0; i < data.length; i++) {
            let objEnteringOrderData = data[i];
            objEnteringOrderData.key = objEnteringOrderData.id;
            objEnteringOrderData.type_str = this.arrType[data[i].type];
            objEnteringOrderData.status_str = this.arrStatus[data[i].status];
            if (objEnteringOrderData.update_date)
                if (objEnteringOrderData.update_date !== null)
                    if (objEnteringOrderData.update_date.indexOf('T') >= 0)
                        objEnteringOrderData.update_date_str = data[i].update_date.split('T')[0];
            arrTableData.push(objEnteringOrderData);
        }
        return arrTableData;
    }
    // 从数据库获取当前的数据结构
    GetEnteringOrder = () => {
        this.CurrentEnteringOrderInit();
        message.loading({ content: '数据加载中……', key: g_strMessageKey });
        axios('/GetEnteringOrder', {
            params: {
                store_id: this.props.objStoreInfo.id,
                user_id: this.props.iUserID,
                user_level: this.props.iUserLevel,
                invite_code: this.props.strInviteCode
            }
        }).then(({ data }) => {
            this.arrOgeTableData = this.OrganizeEnteringOrderData(data);
            this.arrTableData = this.arrOgeTableData;
            if (this.state.bShowDlg) {
                if (this.state.iDlgType === 0) {
                    message.success({ content: "新建入库预报完成！", key: g_strMessageKey, duration: 2 });
                }
                if (this.state.iDlgType === 1) {
                    message.success({ content: "入库记录信息已更新！", key: g_strMessageKey, duration: 2 });
                }
                if (this.state.iDlgType === 2) {
                    message.success({ content: "入库记录已删除！", key: g_strMessageKey, duration: 2 })
                }
            } else {
                message.success({ content: "入库信息加载完成！", key: g_strMessageKey, duration: 2 });
            }
            this.arrBoxInfo = [
                [{ box: '', sku: '', name: '', num: 0, num_check: 0, status: 0 }]
            ];
            this.setState({
                ...this.state,
                bLoading: false,
                bShowDlg: false,
                selectedRowKeys: []
            });
        }).catch(function (error) { console.log(error); });
    }
    // 新建入库单的初始化
    CurrentEnteringOrderInit = () => {
        this.objCurrentEnteringOrder = {
            code: '',
            type: 0,
            status: 0,
            length: 0.0,
            width: 0.0,
            height: 0.0,
            weight: 0.0,
            value: 0.0,
            value_weight: 0.0,
            price_weight: 0.0,
            comments: '',
            box_num: 0,
            express_no: '',
            service_fee: 0.0,
            store_id: this.props.objStoreInfo.id,
            user_id: this.props.iUserID
        };
    }
    // 体积重量信息变化
    OnValueWeightChange = (index, e) => {
        // console.log(index, e.target.value);
        let fLength = parseFloat(this.refs.length.state.value);
        let fWidth = parseFloat(this.refs.width.state.value);
        let fHeight = parseFloat(this.refs.height.state.value);
        let fWeight = parseFloat(this.refs.weight.state.value);
        switch (index) {
            // 长度变化
            case 0: {
                fLength = parseFloat(e.target.value);
                break;
            }
            // 宽度变化
            case 1: {
                fWidth = parseFloat(e.target.value);
                break;
            }
            // 高度变化
            case 2: {
                fHeight = parseFloat(e.target.value);
                break;
            }
            // 重量变化
            case 3: {
                fWeight = parseFloat(e.target.value);
                break;
            }
            default: break;
        }
        this.fValue = fLength * fWidth * fHeight;
        this.fValueWeight = fLength * fWidth * fHeight / 5000.0;
        if (fWeight >= this.fValueWeight)
            this.fPriceWeight = fWeight;
        else
            this.fPriceWeight = this.fValueWeight;
        this.setState({
            ...this.state,
            bValueWeightChange: true
        })
    }
    // 关键词处理
    KeywordsProcess = (strKeywords) => {
        let newKeywords = "";
        for (let i = 0; i < strKeywords.length; i++) {
            if (strKeywords[i] !== " ") {
                if (i === strKeywords.length - 1)
                    newKeywords += strKeywords[i];
                else
                    newKeywords += strKeywords[i] + "%";
            }
        }
        return newKeywords;
    }
    // 搜索库区信息名
    StorageEnteringSearch = () => {
        let strKeywords = this.refs.search.state.value;
        if (strKeywords === undefined)
            strKeywords = "";
        if (strKeywords.trim() === "") {
            this.GetEnteringOrder();
        } else {
            this.setState({
                ...this.state,
                bLoading: true,
                selectedRowKeys: [],
                btnControlDisable: [false, true, true]
            });
            axios('/SearchEnteringOrder', { params: { name: this.KeywordsProcess(strKeywords), user_id: this.props.iUserID } })
                .then(({ data }) => {
                    this.arrTableData = this.OrganizeEnteringOrderData(data);
                    this.setState({
                        ...this.state,
                        bLoading: false,
                        bShowDlg: false,
                        selectedRowKeys: []
                    });
                }).catch(function (error) { console.log(error); });
        }
    }
    // 点击了库区信息过滤器
    EnteringTypeDropDownClicked = (index) => {
        this.setState({
            ...this.state,
            iType: index
        });
    }
    // 点击了库位类型过滤器
    EnteringStatusDropDownClicked = (index) => {
        this.setState({
            ...this.state,
            iStatus: index
        });
    }
    // 箱子ID检查
    BoxIDCheck = (iBoxKey) => {
        for (let i = 0; i < this.arrBoxInfo.length; i++) {
            for (let j = 0; j < this.arrBoxInfo[i].length; j++) {
                if (iBoxKey === this.arrBoxInfo[i][j].box)
                    return false;
            }
            return true;
        }
    }
    // 新增一个货箱
    AddBox = () => {
        let iBoxKey = Math.floor(Math.random() * 9999);
        while (!this.BoxIDCheck(iBoxKey)) {
            iBoxKey = Math.floor(Math.random() * 9999);
        }
        let newBox = [{ box: iBoxKey, sku: '', name: '', num: 0, num_check: 0, status: 0 }];
        this.arrBoxInfo.splice(0, 0, newBox);
        // this.arrBoxInfo.push(newBox);
        this.setState({
            ...this.state,
            bAddNew: true
        })
    }
    // 新增一个SKU
    AddSKU = (box_index) => {
        let newSKU = { box: this.arrBoxInfo[box_index][0].box, sku: '', name: '', num: 0, num_check: 0, status: 0 };
        this.arrBoxInfo[box_index].splice(0, 0, newSKU);
        // this.arrBoxInfo[box_index].push(newSKU);
        this.setState({
            ...this.state,
            bAddNew: true
        })
    }
    // 物品信息编辑
    SKUInfoChange = (box_index, item_index, index, e) => {
        if (index === 0) {
            this.arrBoxInfo[box_index][item_index].sku = e.target.value;
            this.arrBoxInfo[box_index][item_index].name = "";
            for (let k = 0; k < global.arrSKUData.length; k++) {
                if (this.arrBoxInfo[box_index][item_index].sku === global.arrSKUData[k].sku) {
                    this.arrBoxInfo[box_index][item_index].name = global.arrSKUData[k].name
                    break;
                }
            }
        }
        if (index === 1)
            this.arrBoxInfo[box_index][item_index].num = e.target.value;
        this.setState({
            ...this.state,
            bAddNew: true
        })
    }
    // 删除指定箱子信息
    RemoveBox = (box_index) => {
        if (this.arrBoxInfo.length <= 1) {
            message.warning("每单预报至少一箱货物！");
            return;
        }
        this.arrBoxInfo.splice(box_index, 1);
        this.setState({
            ...this.state,
            bAddNew: true
        })
    }
    // 删除指定物品信息
    RemoveItem = (box_index, item_index) => {
        if (this.arrBoxInfo[box_index].length <= 1) {
            message.warning("每箱至少包含一种货物！");
            return;
        }
        this.arrBoxInfo[box_index].splice(item_index, 1);
        this.setState({
            ...this.state,
            bAddNew: true
        })
    }
    // 货物总量统计
    TotalItemNumCalculate = () => {
        this.iTotalSKUNum = 0;
        this.iTotalItemNum = 0;
        for (let i = 0; i < this.arrBoxInfo.length; i++) {
            this.iTotalSKUNum += this.arrBoxInfo[i].length;
            for (let j = 0; j < this.arrBoxInfo[i].length; j++) {
                this.iTotalItemNum += parseInt(this.arrBoxInfo[i][j].num, 10);
            }
        }
    }
    // 每箱货物统计
    BoxItemNumCalculate = (box_index) => {
        let iItemNum = 0;
        for (let j = 0; j < this.arrBoxInfo[box_index].length; j++) {
            iItemNum += parseInt(this.arrBoxInfo[box_index][j].num, 10);
        }
        return iItemNum;
    }
    // 提交数据前检查货物信息
    BoxInfoCheck = () => {
        for (let i = 0; i < this.arrBoxInfo.length; i++) {
            for (let j = 0; j < this.arrBoxInfo[i].length; j++) {
                if (this.arrBoxInfo[i][j].num <= 0) {
                    message.warning('有货物的数量为0，请检查！');
                    return false;
                }
                let bSKUFound = false;
                for (let k = 0; k < global.arrSKUData.length; k++) {
                    if (this.arrBoxInfo[i][j].sku === global.arrSKUData[k].sku) {
                        bSKUFound = true;
                        break;
                    }
                }
                if (!bSKUFound) {
                    message.warning('有货物的SKU不存在，请检查！');
                    return false;
                }
            }
        }
        return true;
    }
    // 点击箱子条形码时的默认屏蔽
    BoxBarcodeClick = (event) => {
        event.preventDefault();
    }
    // 搜索SKU
    FindSKU = (box_index, item_index) => {
        if (this.props.iUserLevel >= 9) {
            message.warning("无法修改入库单信息！");
            return;
        }
        if (this.objCurrentEnteringOrder.status > 1) {
            message.warning("入库单已签收，无法更改！");
            return;
        }
        this.iBoxIndex = box_index;
        this.iItemIndex = item_index;
        this.ShowSKUSelection(true, 0);
    }
    // 扫码/键盘输入监听
    ScanInputMonitor = (e) => {
        // this.nextCode = e.which;
        // this.nextTime = new Date().getTime();
        // if (this.lastCode != null && this.lastTime != null && this.nextTime - this.lastTime <= 30) {
        //     this.strScanInput += String.fromCharCode(this.lastCode);
        // } else if (this.lastCode != null && this.lastTime != null && this.nextTime - this.lastTime > 100) {
        //     this.strScanInput = "";
        // }
        // this.lastCode = this.nextCode;
        // this.lastTime = this.nextTime;
        // if (e.which === 13) {
        //     console.log(this.strScanInput);
        //     this.strScanInput = "";
        // }
        // console.log("ScanInputMonitor : " + this.strScanInput);
        // console.log("Next Code : " + String.fromCharCode(this.nextCode));

        // 回车确认
        // if (e.which == 13) {
        //     console.log("e.which = 13, strScanInput = " + this.strScanInput);
        //     this.strScanInput = "";
        // }

        this.strCurrentScanCode = e.which;
        this.timeCurrent = new Date().getTime();
        if (this.strCurrentScanCode != null && this.timeLast != null && this.timeCurrent - this.timeLast <= 30) {
            // this.strScanInput += String.fromCharCode(this.lastCode);
        } else if (this.strLastScanCode != null && this.timeLast != null && this.timeCurrent - this.timeLast > 100) {
            this.strScanInput = "";
        }
        this.strLastScanCode = this.strCurrentScanCode;
        this.timeLast = this.timeCurrent;
        this.strScanInput += String.fromCharCode(this.strCurrentScanCode);

        // 检查是否扫描完成（入库单号）
        if (this.strScanInput.indexOf('-') >= 0 && this.strScanInput[this.strScanInput.length - 1] === "B")
            console.log("StorageEnteringManager : Scane Complete, Bar Code = " + this.strScanInput);
    }
    // 选择SKU
    ShowSKUSelection = (bShow, sku_index) => {
        this.iSKUIndex = sku_index;
        if (!bShow && sku_index >= 0) {
            this.arrBoxInfo[this.iBoxIndex][this.iItemIndex].sku = global.arrSKUData[this.iSKUIndex].sku;
            this.arrBoxInfo[this.iBoxIndex][this.iItemIndex].name = global.arrSKUData[this.iSKUIndex].name;
        }
        this.setState({
            ...this.state,
            bSelectSKU: bShow
        });
    }
    // Box上架状态
    BoxStatusCheck = (index) => {
        let bCheck = true;
        let bUp = true;
        for (let i = 0; i < this.arrBoxInfo[index].length; i++) {
            if (this.arrBoxInfo[index][i].status <= 1) {
                bCheck = false; bUp = false;
            } else {
                if (this.arrBoxInfo[index][i].status <= 2)
                    bUp = false;
            }
        }
        return { check: bCheck, up: bUp };
    }

    /* React自带函数 */
    // 卸载异步操作设置状态
    componentWillUnmount() {
        clearTimeout(this.timeouter);
        this.setState = (state, callback) => {
            return;
        };
        window.removeEventListener('keypress', this.ScanInputMonitor);
    }
    // 渲染完毕……
    componentDidMount() {

        window.addEventListener('keypress', this.ScanInputMonitor);

        this.GetEnteringOrder();
        if (global.arrSKUData.length <= 0) {
            axios('/GetSKU', { params: { store_id: this.props.objStoreInfo.id, user_id: this.props.iUserID, level: this.props.iUserLevel } })
                .then(({ data }) => {
                    global.arrSKUData = data;
                }).catch(function (error) { console.log(error); });
        }
    }
    // 渲染函数
    render() {

        let uiNewButton = (
            <Button className="manage_contents_title_margin" style={{ background: 'darkcyan', color: 'white', marginLeft: '5px' }}
                onClick={this.ShowDlg.bind(this, 0)} disabled={this.state.btnControlDisable[0]}>
                <Icon type="plus" />预报</Button>
        );
        let strEditButton = "编辑";
        let strEditIcon = "edit";
        if (this.props.iUserLevel >= 9) {
            uiNewButton = null;
            strEditButton = "查看";
            strEditIcon = "search";
        }
        let uiTitleArea = (
            < div className="storage_contents_title" >
                {uiNewButton}
                <Button className="manage_contents_title_margin" type="primary" onClick={this.ShowDlg.bind(this, 1)} disabled={this.state.btnControlDisable[1]}>
                    <Icon type={strEditIcon} />{strEditButton}</Button>
                {/* <Button className="manage_contents_title_margin" type="danger" onClick={this.ShowDlg.bind(this, 2)} disabled={this.state.btnControlDisable[2]}>
                    <Icon type="close" /> 删除</Button> */}
                <Input placeholder="搜索 单号/客户/备注" ref="search" defaultValue={this.state.strKeywords} style={{ width: '200px', marginLeft: '20px' }} />
                <Button className="manage_contents_title_margin"
                    type="primary" onClick={this.StorageEnteringSearch}>搜索
                    </Button>
            </div >
        );

        // 如果界面上需要显示的内容已经从数据库获得
        if (!this.state.bLoading) {
            // 主体表格相关
            const { selectedRowKeys } = this.state;
            const rowSelection = {
                selectedRowKeys,
                onChange: this.onSelectedRowKeysChange,
                columnWidth: 30
            };
            // 表格分页属性
            const paginationProps = {
                showSizeChanger: true,
                showQuickJumper: false,
                showTotal: () => `共 ${this.arrTableData.length} 个 入库单`,
                defaultPageSize: 10,
                defaultCurrent: this.state.iCurrentPage,
                pageSizeOptions: ['10', '20', '30', '40', '50'],
                total: this.arrTableData.length,
                position: 'bottom'
            };
            // 入库单类别下拉菜单
            const uiEnteringType = (
                <Menu>
                    {this.arrType.map((item, index) => {
                        return (
                            <Menu.Item key={index} onClick={this.EnteringTypeDropDownClicked.bind(this, index)}>
                                {item}
                            </Menu.Item>
                        );
                    })}
                </Menu>
            );
            // 库位类型下拉菜单
            const uiEnteringStatus = (
                <Menu>
                    {this.arrStatus.map((item, index) => {
                        return (
                            <Menu.Item key={index} onClick={this.EnteringStatusDropDownClicked.bind(this, index)}>
                                {item}
                            </Menu.Item>
                        );
                    })}
                </Menu>
            );
            this.TotalItemNumCalculate();
            // 入库单编辑弹窗
            let strTitle = "新建入库预报";
            if (this.state.iDlgType === 1) {
                strTitle = "编辑入库单";
                if (this.props.iUserLevel >= 9)
                    strTitle = "查看入库单";
            }
            // let strPrintTip = "提交预报后，请右键点击保存每个货箱的条形码，打印并贴到货箱上";
            // if (this.objCurrentEnteringOrder.code !== null && this.objCurrentEnteringOrder.code !== "")
            //     strPrintTip = "请右键点击并保存每个货箱的条形码，打印并贴到货箱上";
            // if (this.props.iUserLevel >= 9)
            //     strPrintTip = "";
            let bDisable = false;
            // 管理后台
            if (this.props.iUserLevel >= 9)
                bDisable = true;
            // 客户端
            else {
                if (this.objCurrentEnteringOrder.status > 1)
                    bDisable = true;
                else
                    bDisable = false;
            }
            let uiPrintOrder = null;
            if (this.objCurrentEnteringOrder.code !== "" && this.objCurrentEnteringOrder.code !== null) {
                uiPrintOrder = (
                    <ReactToPrint trigger={() =>
                        <Button className="storage_edit_info_add_button" style={{ marginLeft: '20px', paddingLeft: '10px', paddingRight: '10px', maxWidth: '200px' }}>
                            <Icon type="printer" style={{ color: 'white', fontWeight: 'bold', fontSize: '15px' }} /> 打印入库单
                    </Button>} content={() => this.componentRef} />
                    // <Button className="storage_edit_info_add_button" style={{ marginLeft: '20px', paddingLeft: '10px', paddingRight: '10px', maxWidth: '200px' }}
                    //     onClick={this.AddBox} disabled={bDisable}>
                    //     <Icon type="printer" style={{ color: 'white', fontWeight: 'bold', fontSize: '15px' }} /> 打印入库单
                    // </Button>
                );
            }

            let uiModal = (
                <Modal title={strTitle} width={910} visible={this.state.bShowDlg}
                    okText="确定" cancelText="取消" onOk={this.handleOk} onCancel={this.handleCancel} >
                    <div ref={(el) => (this.componentRef = el)}>
                        <div style={{ width: '100%', padding: '20px' }}>
                            <div className="user_edit_info_row" >
                                <div className="storage_edit_info_title">单号:</div>
                                <Input className="storage_edit_info_input_print" ref="code" disabled
                                    defaultValue={this.objCurrentEnteringOrder.code} />
                                <div className="storage_edit_info_title">类别:</div>
                                <Dropdown className="storage_edit_info_input_print" overlay={uiEnteringType} placement="bottomLeft">
                                    <Button className="storage_edit_info_input">
                                        {this.arrType[this.state.iType]}
                                        <Icon type="caret-down" style={{ marginLeft: '10px' }} />
                                    </Button>
                                </Dropdown>
                                <div className="storage_edit_info_title">状态:</div>
                                <Dropdown className="storage_edit_info_input_print" overlay={uiEnteringStatus} placement="bottomLeft">
                                    <Button className="storage_edit_info_input" >
                                        {this.arrStatus[this.state.iStatus]}
                                        <Icon type="caret-down" style={{ marginLeft: '10px' }} />
                                    </Button>
                                </Dropdown>
                            </div>
                            <div className="user_edit_info_row">
                                <div className="storage_edit_info_title">长：</div>
                                <Input className="storage_edit_info_input_print" ref="length" addonAfter="cm" onChange={this.OnValueWeightChange.bind(this, 0)}
                                    defaultValue={this.objCurrentEnteringOrder.length} />
                                <div className="storage_edit_info_title">宽：</div>
                                <Input className="storage_edit_info_input_print" ref="width" addonAfter="cm" onChange={this.OnValueWeightChange.bind(this, 1)}
                                    defaultValue={this.objCurrentEnteringOrder.width} />
                                <div className="storage_edit_info_title">高：</div>
                                <Input className="storage_edit_info_input_print" ref="height" addonAfter="cm" onChange={this.OnValueWeightChange.bind(this, 2)}
                                    defaultValue={this.objCurrentEnteringOrder.height} />
                            </div>
                            <div className="user_edit_info_row">
                                {/* <div className="storage_edit_info_title">体积：</div>
                        <Input className="storage_edit_info_input" ref="value" disabled addonAfter="cm3" value={this.fValue}
                            defaultValue={this.objCurrentEnteringOrder.value} /> */}
                                <div className="storage_edit_info_title">体积重：</div>
                                <Input className="storage_edit_info_input_print" ref="value_weight" disabled addonAfter="kg" value={this.fValueWeight}
                                    defaultValue={this.objCurrentEnteringOrder.value_weight} />
                                <div className="storage_edit_info_title">称重：</div>
                                <Input className="storage_edit_info_input_print" ref="weight" addonAfter="kg" onChange={this.OnValueWeightChange.bind(this, 3)}
                                    defaultValue={this.objCurrentEnteringOrder.weight} />
                                <div className="storage_edit_info_title">计费重：</div>
                                <Input className="storage_edit_info_input_print" ref="price_weight" disabled addonAfter="kg" value={this.fPriceWeight}
                                    defaultValue={this.objCurrentEnteringOrder.price_weight} />
                            </div>
                            <div className="user_edit_info_row">
                                <div className="storage_edit_info_title">物流：</div>
                                <Input className="storage_edit_info_input_print" ref="express_no" placeholder={'快递/空运/海运货柜号'}
                                    defaultValue={this.objCurrentEnteringOrder.express_no} />
                                <div className="storage_edit_info_title">费用：</div>
                                <Input className="storage_edit_info_input_print" ref="service_fee" addonBefore="$"
                                    defaultValue={this.objCurrentEnteringOrder.service_fee} />
                                <div className="storage_edit_info_title">备注：</div>
                                <Input className="storage_edit_info_input_print" ref="comments"
                                    defaultValue={this.objCurrentEnteringOrder.comments} />
                            </div>
                        </div>
                        <div className="storage_edit_info_block">
                            <div className="user_edit_info_row storage_edit_info_black_title">
                                <b>货物信息</b>：共 {this.arrBoxInfo.length} 箱, {this.iTotalSKUNum} 种, {this.iTotalItemNum} 件 货物
                                {uiPrintOrder}
                                <Button className="storage_edit_info_add_button" onClick={this.AddBox} disabled={bDisable}>
                                    + 货箱
                            </Button>
                            </div>
                            {this.arrBoxInfo.map((box, box_index) => {
                                let strBlockStyle = "storage_edit_info_sub_block";
                                if (box_index % 2 !== 0)
                                    strBlockStyle += " storage_edit_info_sub_block_dark";
                                if (this.arrBoxInfo.length % 2 === 0) {
                                    strBlockStyle = "storage_edit_info_sub_block storage_edit_info_sub_block_dark";
                                    if (box_index % 2 !== 0)
                                        strBlockStyle = "storage_edit_info_sub_block";
                                }
                                let iItemNum = this.BoxItemNumCalculate(box_index);
                                let uiBarCode = null;
                                let uiChecked = null;
                                if (this.objCurrentEnteringOrder.code !== null && this.objCurrentEnteringOrder.code !== "") {
                                    let strBoxBarcode = this.objCurrentEnteringOrder.code + "-" + box[0].box + "B";
                                    uiBarCode = (
                                        <div className="user_edit_info_row storage_edit_info_black_title">
                                            <a href="请右键点击并保存该条形码" onClick={e => this.BoxBarcodeClick(e)} download={strBoxBarcode} >
                                                <Barcode value={strBoxBarcode} renderer={'img'} displayValue={true} width={3} height={75} fontSize={15}
                                                    background={'rgba(0, 0, 0, 0)'}
                                                /*ref={(el) => (box.componentRef = el)}*/ />
                                            </a>
                                            {/* <ReactToPrint trigger={() =>
                                            <Button type="primary" className="storage_print_button" >
                                                <Icon type="printer" style={{ color: 'white', fontWeight: 'bold', fontSize: '15px' }} />
                                            </Button>}
                                            content={() => box.componentRef} /> */}
                                        </div>
                                    );

                                    if (this.BoxStatusCheck(box_index).up)
                                        uiChecked = (
                                            <Checkbox checked={true} style={{ marginLeft: '5px' }}>已上架</Checkbox>
                                        );
                                    else {
                                        if (parseInt(box[0].status, 10) === 0 || box[0].status === null)
                                            uiChecked = (
                                                <Checkbox checked={false} disabled style={{ marginLeft: '5px' }}>未签收</Checkbox>
                                            );
                                        if (parseInt(box[0].status, 10) === 1)
                                            uiChecked = (
                                                <Checkbox checked={true} style={{ marginLeft: '5px' }}>已签收</Checkbox>
                                            );
                                        if (parseInt(box[0].status, 10) === 2)
                                            uiChecked = (
                                                <Checkbox checked={true} style={{ marginLeft: '5px' }}>已入库</Checkbox>
                                            );
                                    }

                                }
                                let uiSKUList = (
                                    <div className="storage_sku_list_area">
                                        {global.arrSKUData.map((sku, sku_index) => {
                                            return (
                                                <div className="storage_sku_list_row" key={sku_index} onClick={this.ShowSKUSelection.bind(this, false, sku_index)}>
                                                    {sku.sku + "：" + sku.name}
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                                let uiSKUSelectDlg = (
                                    <Modal title="选择SKU" width={500} visible={this.state.bSelectSKU}
                                        onCancel={this.ShowSKUSelection.bind(this, false, -1)} footer={null} >
                                        {uiSKUList}
                                    </Modal>
                                );
                                return (
                                    <div className={strBlockStyle} key={box_index}>
                                        <div className="user_edit_info_row storage_edit_info_black_title">
                                            共 {box.length} 种, {iItemNum} 件 货物
                                        <div style={{ width: '5px' }} />
                                            {uiChecked}
                                            <Button className="storage_edit_info_add_button" onClick={this.AddSKU.bind(this, box_index)} disabled={bDisable}>
                                                + SKU
                                        </Button>
                                            <Button className="storage_edit_info_delete_box_button" onClick={this.RemoveBox.bind(this, box_index)} disabled={bDisable}>
                                                删除货箱
                                        </Button>
                                        </div>
                                        {uiBarCode}
                                        {box.map((item, item_index) => {
                                            let uiSKUSearch = (
                                                <div className="storage_sku_input_search" onClick={this.FindSKU.bind(this, box_index, item_index)}>
                                                    <Icon type="search" style={{ color: 'black', fontWeight: 'bold' }} />
                                                </div>
                                            );
                                            let uiStatusIcon = null;
                                            if (item.status === 2)
                                                uiStatusIcon = (
                                                    <div className="storage_edit_info_delete_button" /*onClick={this.RemoveItem.bind(this, box_index, item_index)}*/>
                                                        <Icon type="check-circle" style={{ color: 'darkcyan', fontWeight: 'bold', fontSize: '17.5px' }} /></div>
                                                );
                                            if (item.status === 3)
                                                uiStatusIcon = (
                                                    <div className="storage_edit_info_delete_button" /*onClick={this.RemoveItem.bind(this, box_index, item_index)}*/>
                                                        <Icon type="vertical-align-top" style={{ color: 'darkcyan', fontWeight: 'bold', fontSize: '17.5px' }} /></div>
                                                );
                                            let uiDeleteButton = null;
                                            if (this.props.iUserLevel < 9) {
                                                if (this.objCurrentEnteringOrder.status !== undefined)
                                                    if (parseInt(this.objCurrentEnteringOrder.status, 10) < 1) {
                                                        uiDeleteButton = (
                                                            <div className="storage_edit_info_delete_button" onClick={this.RemoveItem.bind(this, box_index, item_index)}>
                                                                <Icon type="delete" style={{ marginLeft: '5px', color: 'lightcoral', fontWeight: 'bold', fontSize: '17.5px' }} /></div>
                                                        );
                                                    }

                                            }
                                            if (this.state.iDlgType === 0) {
                                                uiDeleteButton = (
                                                    <div className="storage_edit_info_delete_button" onClick={this.RemoveItem.bind(this, box_index, item_index)}>
                                                        <Icon type="delete" style={{ marginLeft: '5px', color: 'lightcoral', fontWeight: 'bold', fontSize: '17.5px' }} /></div>
                                                );
                                            }
                                            let uiSKUBarode = null;
                                            if (item.sku !== null && item.sku !== "") {
                                                uiSKUBarode = (
                                                    <a href="请右键点击并保存该条形码" onClick={e => this.BoxBarcodeClick(e)} download={item.sku} >
                                                        <Barcode value={item.sku} renderer={'img'} displayValue={false} width={2.5} height={65} fontSize={0}
                                                            background={'rgba(0, 0, 0, 0)'} />
                                                    </a>
                                                );
                                            }
                                            return (
                                                <div key={box_index * 100 + item_index} className="storage_entering_sku_list_row">
                                                    {uiSKUBarode}
                                                    <div className="user_edit_info_row" >
                                                        <div className="storage_edit_box_info_title">SKU：</div>
                                                        <Input className="storage_edit_box_info_input_long" ref={"box_" + box_index + "sku_" + item_index} disabled={bDisable}
                                                            defaultValue={item.sku} value={item.sku} onChange={this.SKUInfoChange.bind(this, box_index, item_index, 0)}
                                                            addonAfter={uiSKUSearch} />
                                                        {uiStatusIcon}
                                                        {uiDeleteButton}
                                                    </div>
                                                    <div className="user_edit_info_row sku_row_bottom_line" >
                                                        <div className="storage_edit_box_info_title">名称：</div>
                                                        <Input className="storage_edit_box_info_input_mid2" ref={"box_" + box_index + "name_" + item_index} disabled
                                                            defaultValue={item.name} value={item.name} />
                                                        <div className="storage_edit_box_info_title">数量：</div>
                                                        <Input className="storage_edit_box_info_input_short2" ref={"box_" + box_index + "num_" + item_index}
                                                            defaultValue={item.num} value={item.num} disabled={bDisable}
                                                            onChange={this.SKUInfoChange.bind(this, box_index, item_index, 1)} />
                                                    </div>
                                                </div>
                                            );
                                        })}
                                        {uiSKUSelectDlg}
                                    </div>
                                );
                            })
                            }
                        </div>

                    </div>
                </Modal >
            );
            if (this.state.iDlgType === 2) {
                uiModal = (
                    <Modal width={500} title={"删除入库单"} visible={this.state.bShowDlg} okType="danger"
                        onOk={this.handleOk} okText="确定"
                        onCancel={this.handleCancel} cancelText="取消">
                        <div className="modal_item">
                            <div>{this.objCurrentEnteringOrder.code}
                                <br /><br />确定删除选中的入库单？
                            </div>
                        </div>
                    </Modal>
                );
            }

            let uiTableHeader = arrClientTableHeader;
            if (this.props.iUserLevel >= 9)
                uiTableHeader = arrManagerTableHeader;
            return (
                <div className="storage_contents_area">
                    {uiTitleArea}
                    {uiModal}
                    {/* 表格主体 */}
                    <Table style={{ margin: '20px', width: '96%', overflowX: 'auto' }}
                        rowSelection={rowSelection} pagination={paginationProps}
                        columns={uiTableHeader} dataSource={this.arrTableData} bordered
                        onRow={(record) => ({ onClick: () => { this.selectRow(record); } })} />
                </div >
            );
        } else {
            return (
                <div className="storage_contents_area">
                    {uiTitleArea}
                    <Spin className="manage_empty_loading" size="large" tip="加载中……" />
                </div>
            );
        }
    }

}
export default StorageEnteringManager;
