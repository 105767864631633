import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';

import Spin from 'antd/es/spin';
import Table from 'antd/es/table';
import Button from 'antd/es/button';
import Input from 'antd/es/input';
import Icon from 'antd/es/icon';
import Modal from 'antd/es/modal';
import DatePicker from 'antd/es/date-picker';
import message from 'antd/es/message';

import '../../../styles/Background/ego_manager.css';
import '../../../styles/Background/storage_manager.css';
import '../../../styles/Background/UI_Module/user_management.css';
import { UserActiveUpdate } from "../../../functions/DataStatistic.js"

const { TextArea } = Input;

const g_strMessageKey = 'updating';
global.arrStorageInData = [];


// 表格
const arrTableHeader1 = [
    // { title: 'ID', dataIndex: 'id', width: 35, ellipsis: true },
    { title: '客户', dataIndex: 'client_name', width: 100, ellipsis: true },
    { title: '柜号', dataIndex: 'closet', width: 100, ellipsis: true },
    { title: '货物信息', dataIndex: 'item_info_str', width: 135, ellipsis: true },
    { title: '库位/货架', dataIndex: 'location', width: 80, ellipsis: true },
    { title: '操作人', dataIndex: 'user_name', width: 75, ellipsis: true },
    { title: '入库日期', dataIndex: 'storage_in_date_str', width: 75, ellipsis: true },
    { title: '备注', dataIndex: 'comments', width: 200, ellipsis: true }
];
const arrTableHeader2 = [
    // { title: 'ID', dataIndex: 'id', width: 35, ellipsis: true },
    // { title: '客户', dataIndex: 'client_name', width: 100, ellipsis: true },
    { title: '柜号', dataIndex: 'closet', width: 100, ellipsis: true },
    { title: '货物信息', dataIndex: 'item_info_str', width: 135, ellipsis: true },
    { title: '库位/货架', dataIndex: 'location', width: 80, ellipsis: true },
    { title: '操作人', dataIndex: 'user_name', width: 75, ellipsis: true },
    { title: '入库日期', dataIndex: 'storage_in_date_str', width: 75, ellipsis: true },
    { title: '备注', dataIndex: 'comments', width: 200, ellipsis: true }
];

class LogisticStorageIn extends Component {
    constructor(props) {
        super();
        this.state = {
            btnControlDisable: [false, true, true],  // 操作按钮控制
            btnCopyPasteDisable: [true, true],  // 操作按钮控制
            selectedRowKeys: [],  // Check here to configure the default column
            iDlgType: -1,
            bShowDlg: false,
            bLoading: true,
            bSelectClientDlg: false,
            dateStorageIn: new Date()
        };
        // 成员变量
        this.arrOgeTableData = [];
        this.arrTableData = [];  // 用于表格的数组
        this.objCurrentStorageIn = {};
        this.arrItemList = [];
        this.objItemInit = {
            name: "", notes: "", reference: '', num: 0, weight: '0.0', length: '0.0', width: '0.0', height: '0.0'
        };
        this.iCurrentClient = 0;
    }

    // 选中某行的响应函数
    selectRow = (record) => {
        const selectedRowKeys = [...this.state.selectedRowKeys];
        if (selectedRowKeys.indexOf(record.key) >= 0) {
            selectedRowKeys.splice(selectedRowKeys.indexOf(record.key), 1);
        } else {
            selectedRowKeys.push(record.key);
        }
        this.SelectedRowStateSetting(selectedRowKeys);
    }
    // 选中的行发生变化的响应函数
    onSelectedRowKeysChange = (selectedRowKeys) => {
        this.SelectedRowStateSetting(selectedRowKeys);
    }
    // 处理选中的表行行
    SelectedRowStateSetting = (selectedRowKeys) => {
        let btnControlDisable = [];
        let btnCopyPasteDisable = [];
        switch (selectedRowKeys.length) {
            case 0: {
                btnControlDisable = [false, true, true];
                btnCopyPasteDisable = [true, true];
                this.objCurrentStorageIn = {};
                break;
            }
            case 1: {
                btnControlDisable = [false, false, false];
                btnCopyPasteDisable = [false, true];
                for (let i = 0; i < this.arrTableData.length; i++) {
                    if (this.arrTableData[i].key === selectedRowKeys[0]) {
                        this.objCurrentStorageIn = this.arrTableData[i];
                        break;
                    }
                }
                break;
            }
            default: {
                btnControlDisable = [false, true, true]; btnCopyPasteDisable = [true, true]; break;
            }
        }
        this.setState({
            ...this.state,
            selectedRowKeys,
            btnControlDisable: btnControlDisable,
            btnCopyPasteDisable: btnCopyPasteDisable
        });
    }
    // 关键词处理
    KeywordsProcess = (strKeywords) => {
        let newKeywords = "";
        for (let i = 0; i < strKeywords.length; i++) {
            if (strKeywords[i] !== " ") {
                if (i === strKeywords.length - 1)
                    newKeywords += strKeywords[i];
                else
                    newKeywords += strKeywords[i] + "%";
            }
        }
        return newKeywords;
    }
    // 搜索入库记录
    StorageInSearch = (bShowAll) => {
        if (this.props.iUserLevel === 1) {
            if (bShowAll) {
                this.refs.closet_search.state.value = "";
                this.refs.name_search.state.value = "";
                this.GetStorageInData();
            } else {
                let strClient = "";
                let strCloset = this.refs.closet_search.state.value;
                let strKeywords = this.refs.name_search.state.value;
                if (strClient === undefined || strClient === null)
                    strClient = "";
                if (strCloset === undefined || strCloset === null)
                    strCloset = "";
                if (strKeywords === undefined || strKeywords === null)
                    strKeywords = "";
                this.setState({
                    ...this.state,
                    bLoading: true,
                    selectedRowKeys: [],
                    btnControlDisable: [false, true, true]
                });
                axios('/SearchStorageIn', {
                    params: {
                        client: this.KeywordsProcess(strClient),
                        closet: this.KeywordsProcess(strCloset),
                        key: this.KeywordsProcess(strKeywords),
                        store_id: this.props.iStoreID
                    }
                }).then(({ data }) => {
                    // console.log("search results : ", data);
                    this.arrTableData = this.OrganizeStorageInData(data);
                    this.setState({
                        ...this.state,
                        bLoading: false,
                        objFilter: {
                            strKeyword: strKeywords,
                            iCurrentUserStatus: 0,
                            iCurrentUserType: 0
                        }
                    });
                }).catch(function (error) { console.log(error); });
            }
        } else {
            if (bShowAll) {
                this.refs.client_filter.state.value = "";
                this.refs.closet_search.state.value = "";
                this.refs.name_search.state.value = "";
                this.GetStorageInData();
            } else {
                let strClient = this.refs.client_filter.state.value;
                let strCloset = this.refs.closet_search.state.value;
                let strKeywords = this.refs.name_search.state.value;
                if (strClient === undefined || strClient === null)
                    strClient = "";
                if (strCloset === undefined || strCloset === null)
                    strCloset = "";
                if (strKeywords === undefined || strKeywords === null)
                    strKeywords = "";
                this.setState({
                    ...this.state,
                    bLoading: true,
                    selectedRowKeys: [],
                    btnControlDisable: [false, true, true]
                });
                axios('/SearchStorageIn', {
                    params: {
                        client: this.KeywordsProcess(strClient),
                        closet: this.KeywordsProcess(strCloset),
                        key: this.KeywordsProcess(strKeywords),
                        store_id: this.props.iStoreID
                    }
                }).then(({ data }) => {
                    // console.log("search results : ", data);
                    this.arrTableData = this.OrganizeStorageInData(data);
                    this.setState({
                        ...this.state,
                        bLoading: false,
                        objFilter: {
                            strKeyword: strKeywords,
                            iCurrentUserStatus: 0,
                            iCurrentUserType: 0
                        }
                    });
                }).catch(function (error) { console.log(error); });
            }
        }


    }

    /* 自定义成员函数 */
    //弹出对话框
    ShowDlg = (iDlgType, e) => {
        if (iDlgType === 0) {
            if (this.refs.closet !== undefined) {
                this.refs.closet.state.value = "";
                this.refs.location.state.value = "";
                this.refs.comments.state.value = "";
                this.refs.client_name.state.value = "";
            }
            this.arrItemList = [];
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true,
                strNewPassword: "",
                strConfirmPassword: "",
                dateStorageIn: new Date()
            });
        }
        if (iDlgType === 1) {
            if (this.refs.closet !== undefined) {
                this.refs.closet.state.value = this.objCurrentStorageIn.closet;
                this.refs.location.state.value = this.objCurrentStorageIn.location;
                this.refs.comments.state.value = this.objCurrentStorageIn.comments;
                this.refs.client_name.state.value = this.objCurrentStorageIn.client_name;
            }
            this.arrItemList = JSON.parse(this.objCurrentStorageIn.item_info);
            let dateStorageIn = new Date();
            if (this.objCurrentStorageIn.storage_in_date !== "" && this.objCurrentStorageIn.storage_in_date !== null &&
                this.objCurrentStorageIn.storage_in_date !== undefined)
                dateStorageIn = this.objCurrentStorageIn.storage_in_date;
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true,
                strNewPassword: "",
                dateStorageIn: dateStorageIn
            });
        }
        if (iDlgType === 2) {
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true
            });
        }
    }
    // 弹窗确认按钮响应
    handleOk = e => {
        if (this.state.iDlgType <= 1) {
            let strCloset = this.refs.closet.state.value;
            if (strCloset === null || strCloset === "" || strCloset === undefined) {
                message.warning("柜号不能为空！"); return;
            }
            let strClient = this.refs.client_name.state.value;
            if (strClient === null || strClient === "" || strClient === undefined) {
                message.warning("客户不能为空"); return;
            }
            let iClient = this.objCurrentStorageIn.client_id;
            if (this.iCurrentClient > 0)
                iClient = this.iCurrentClient;
            let bItemCheck = true;
            if (this.arrItemList.length >= 1) {
                for (let i = 0; i < this.arrItemList.length; i++) {
                    if (this.arrItemList[i].name === "" || this.arrItemList[i].name === undefined || this.arrItemList[i].name === null) {
                        message.warning("货物名称不能为空！");
                        bItemCheck = false;
                        break;
                    }
                    if (this.arrItemList[i].num === "" || this.arrItemList[i].num === undefined || this.arrItemList[i].num === null || this.arrItemList[i].num <= 0) {
                        message.warning("货物数量不能为空！");
                        bItemCheck = false;
                        break;
                    }
                    if (this.arrItemList[i].weight === "" || this.arrItemList[i].weight === undefined || this.arrItemList[i].weight === null) {
                        message.warning("货物重量不能为空！");
                        bItemCheck = false;
                        break;
                    }
                }
            } else {
                message.warning("货物不能为空！");
                bItemCheck = false;
            }
            if (!bItemCheck)
                return;
            let objNewStorageIn = {
                store_id: this.props.iStoreID,
                user_id: this.props.iUserID,
                user_name: this.props.strUserName,
                team: this.props.iUserTeam,
                client_id: iClient,
                client_name: strClient,
                closet: strCloset,
                location: this.refs.location.state.value,
                comments: this.refs.comments.state.value,
                item_info: JSON.stringify(this.arrItemList),
                storage_in_date: this.state.dateStorageIn,
                // create_time: new Date(),
                update_time: new Date()
            };
            if (this.state.iDlgType === 0) {
                objNewStorageIn = {
                    store_id: this.props.iStoreID,
                    user_id: this.props.iUserID,
                    user_name: this.props.strUserName,
                    team: this.props.iUserTeam,
                    client_id: this.iCurrentClient,
                    client_name: strClient,
                    closet: strCloset,
                    location: this.refs.location.state.value,
                    comments: this.refs.comments.state.value,
                    item_info: JSON.stringify(this.arrItemList),
                    storage_in_date: this.state.dateStorageIn,
                    create_time: new Date(),
                    update_time: new Date()
                };
                message.loading({ content: '正在创建新的入库记录……', key: g_strMessageKey });
                axios.post('/CreateNewStorageIn', objNewStorageIn)
                    .then(({ data }) => {
                        if (data.status === 1) {
                            this.GetStorageInData();
                        } else {
                            message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
                        }
                    }).catch(function (error) { console.log(error); });
            }
            if (this.state.iDlgType === 1) {
                console.log("objNewStorageIn : ", objNewStorageIn);
                message.loading({ content: '正在更新入库记录……', key: g_strMessageKey });
                axios.post('/UpdateStorageIn', {
                    objCurrentStorageIn: this.objCurrentStorageIn,
                    objNewStorageIn: objNewStorageIn
                }).then(({ data }) => {
                    if (data.status === 1)
                        this.GetStorageInData();
                    else
                        message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
                }).catch(function (error) { console.log(error); });
            }
        }
        // 删除入库记录
        if (this.state.iDlgType === 2) {
            message.loading({ content: '入库删除中……', key: g_strMessageKey });
            axios.post('/DeleteStorageIn', { id: this.objCurrentStorageIn.id })
                .then(({ data }) => {
                    if (data.status === -1) {
                        message.warning({
                            content: "删除失败，该入库已绑定商家，请先检查并删除商家！",
                            key: g_strMessageKey, duration: 2
                        });
                        this.setState({
                            ...this.state,
                            bLoading: false,
                            bShowDlg: false,
                            selectedRowKeys: []
                        });
                    } else {
                        // DataStatistic
                        UserActiveUpdate(4, { active: "管理后台: 入库管理操作", action: "删除" });
                        this.GetStorageInData();
                    }
                }).catch(function (error) { console.log(error); });
        }
    };
    // 弹窗取消按钮响应
    handleCancel = e => {
        this.setState({
            bShowDlg: false,
            selectedRowKeys: []
        });
    };
    // 整理StorageIn数据
    OrganizeStorageInData(data) {
        let arrTableData = [];
        for (let i = 0; i < data.length; i++) {
            let strItemInfo = "";
            let arrItemList = [];
            if (data[i].item_info !== null && data[i].item_info !== undefined && data[i].item_info !== "")
                arrItemList = JSON.parse(data[i].item_info);
            if (arrItemList.length <= 0)
                strItemInfo = "无";
            else {
                strItemInfo = "共 " + arrItemList.length + " 种， ";
                let iItemNum = 0;
                for (let i = 0; i < arrItemList.length; i++)
                    iItemNum += parseInt(arrItemList[i].num);
                strItemInfo += iItemNum.toString() + " 件 货物";
            }
            let strDate = data[i].storage_in_date;
            if (strDate.indexOf('T') >= 0) {
                strDate = strDate.split('T')[0];
            }
            let objStorageInData = {
                key: i, id: data[i].id,
                client_id: data[i].client_id,
                client_name: data[i].client_name,
                user_id: data[i].user_id,
                user_name: data[i].user_name,
                team: data[i].team,
                closet: data[i].closet,
                item_info: data[i].item_info,
                item_info_str: strItemInfo,
                location: data[i].location,
                comments: data[i].comments,
                storage_in_date: data[i].storage_in_date,
                storage_in_date_str: strDate
                // create_time_str: data[i].create_time.split('T')[0],
            }
            arrTableData.push(objStorageInData);
        }
        return arrTableData;
    }
    // 从数据库获取当前的数据结构
    GetStorageInData() {
        message.loading({ content: '数据加载中……', key: g_strMessageKey });
        axios('/GetStorageIn', {
            params: {
                store_id: this.props.iStoreID, level: this.props.iUserLevel,
                client_id: this.props.iClientID
            }
        }).then(({ data }) => {
            global.arrStorageInData = data;
            this.arrOgeTableData = this.OrganizeStorageInData(data);
            this.arrTableData = this.arrOgeTableData;
            if (this.state.bShowDlg) {
                if (this.state.iDlgType === 0) {
                    message.success({ content: "新建入库记录成功！", key: g_strMessageKey, duration: 2 });
                }
                if (this.state.iDlgType === 1) {
                    message.success({ content: "入库记录已更新！", key: g_strMessageKey, duration: 2 });
                }
                if (this.state.iDlgType === 2) {
                    message.success({ content: "入库已删除！", key: g_strMessageKey, duration: 2 })
                }
            } else {
                message.success({ content: "数据加载完成！", key: g_strMessageKey, duration: 2 });
            }
            this.setState({
                ...this.state,
                bLoading: false,
                bShowDlg: false,
                selectedRowKeys: []
            });
        }).catch(function (error) { console.log(error); });
    }
    // 获取客户数据
    GetClientsData() {
        axios('/GetClients', { params: { store_id: this.props.objStoreInfo.id } })
            .then(({ data }) => {
                global.arrClientData = data;
            }).catch(function (error) { console.log(error); });
    }
    // 选择客户
    SelectClient = () => {
        if (this.state.bShowDlg) {
            this.setState({
                ...this.state,
                bSelectClientDlg: true
            });
        } else {
            this.setState({
                ...this.state,
                bSelectClientFilterDlg: true
            });
        }

    }
    // 选择客户弹窗结果
    ClientSelectResult = (index) => {
        if (this.state.bShowDlg) {
            if (index >= 0) {
                this.refs.client_name.state.value = global.arrClientData[index].name;
                this.iCurrentClient = global.arrClientData[index].id;
            }
        } else {
            if (index >= 0) {
                this.refs.client_filter.state.value = global.arrClientData[index].name;
            }
        }

        this.setState({
            ...this.state,
            bSelectClientDlg: false,
            bSelectClientFilterDlg: false
        })
    }
    // 搜索/筛选客户
    SearchClient = () => {
        this.setState({
            ...this.state,
            bLoading: false
        })
    }
    // 新增货物
    AddItem = () => {
        let objItemInit = {
            name: "", notes: "", reference: '', num: 0, weight: '0.0', length: '0.0', width: '0.0', height: '0.0'
        };
        this.arrItemList.push(objItemInit);
        this.setState({
            ...this.state,
            bLoading: false
        })
    }
    // 更新货物信息
    ItemValueChange = (index, type, e) => {
        if (type === 0) {
            this.arrItemList[index].name = e.target.value;
        }
        if (type === 1) {
            this.arrItemList[index].notes = e.target.value;
        }
        if (type === 2) {
            this.arrItemList[index].reference = e.target.value;
        }
        if (type === 3) {
            this.arrItemList[index].num = e.target.value;
        }
        if (type === 4) {
            this.arrItemList[index].weight = e.target.value;
        }
        if (type === 5) {
            this.arrItemList[index].length = e.target.value;
        }
        if (type === 6) {
            this.arrItemList[index].width = e.target.value;
        }
        if (type === 7) {
            this.arrItemList[index].height = e.target.value;
        }

        this.setState({
            ...this.state,
            bLoading: false
        })
    }
    // 新增货物
    DeleteItem = (index) => {
        if (this.arrItemList.length <= 1) {
            message.warning("至少保留一样货物！");
            return;
        }
        this.arrItemList.splice(index, 1);
        this.setState({
            ...this.state,
            bLoading: false
        })
    }
    // 预计到达日期更改
    StorageInDateChange = (date, dateString) => {
        this.setState({
            ...this.state,
            dateStorageIn: dateString
        });
    }

    /* React自带函数 */
    // 卸载异步操作设置状态
    componentWillUnmount() {
        clearTimeout(this.timeouter)
        this.setState = (state, callback) => {
            return;
        }
    }
    // 渲染完毕……
    componentDidMount() {
        this.GetStorageInData();
        if (global.arrClientData.length <= 0)
            this.GetClientsData();
    }
    // 渲染函数
    render() {

        const dateFormat = 'YYYY-MM-DD';

        /* 入库管理页面控制栏 */
        let uiNewButton = (
            <Button className="manage_contents_title_margin" type="primary" onClick={this.ShowDlg.bind(this, 0)} >
                <Icon type="plus" />新建</Button>
        );
        let uiEditButton = (
            <Button className="manage_contents_title_margin" type="primary" onClick={this.ShowDlg.bind(this, 1)} disabled={this.state.btnControlDisable[1]}>
                <Icon type="edit" />编辑</Button>
        );
        let uiDeleteButton = (
            <Button className="manage_contents_title_margin" type="danger" onClick={this.ShowDlg.bind(this, 2)} disabled={this.state.btnControlDisable[2]}>
                <Icon type="close" /> 删除</Button>
        );
        if (this.props.iUserLevel === 1) {
            uiEditButton = (
                <Button className="manage_contents_title_margin" type="primary" onClick={this.ShowDlg.bind(this, 1)} disabled={this.state.btnControlDisable[1]}>
                    <Icon type="search" />查看</Button>
            );
            uiDeleteButton = null;
            uiNewButton = null;
        }

        let uiTitleArea = (
            < div className="logistics_page_title" >
                <div className="manage_stretch_right">
                    <div className="logistic_filter_title" style={{ minWidth: '36px' }}>客户</div>
                    <Input className="logistic_search_input" ref="client_filter" style={{ marginRight: '0px' }} />
                    <Button type='primary' className="manage_contents_title_margin" onClick={this.SelectClient.bind(this)}>选 择</Button>
                    <div className="logistic_filter_title" style={{ minWidth: '36px', marginLeft: '20px' }}>柜号</div>
                    <Input ref="closet_search" defaultValue={this.state.strKeywords} />
                    <div className="logistic_filter_title" style={{ minWidth: '50px', marginLeft: '20px' }}>关键字</div>
                    <Input placeholder="货物名称/Ref#/备注/操作人" ref="name_search" defaultValue={this.state.strKeywords} />
                    <Button className="manage_contents_title_margin" type="primary" onClick={this.StorageInSearch.bind(this, false)}>搜 索
                    </Button>
                    <Button type='primary' className="manage_contents_title_margin" onClick={this.StorageInSearch.bind(this, true)}>显示全部</Button>
                </div>
                <div className="manage_title_button_right_side">
                    {uiNewButton}
                    {uiEditButton}
                    {uiDeleteButton}
                </div>
            </div >
        );
        if (this.props.iUserLevel === 1) {
            uiTitleArea = (
                < div className="logistics_page_title" >
                    <div className="manage_stretch_right">

                        <div className="logistic_filter_title" style={{ minWidth: '36px', marginLeft: '20px' }}>柜号</div>
                        <Input ref="closet_search" defaultValue={this.state.strKeywords} />
                        <div className="logistic_filter_title" style={{ minWidth: '50px', marginLeft: '20px' }}>关键字</div>
                        <Input placeholder="货物名称/Ref#/备注/操作人" ref="name_search" defaultValue={this.state.strKeywords} />
                        <Button className="manage_contents_title_margin" type="primary" onClick={this.StorageInSearch.bind(this, false)}>搜 索
                    </Button>
                        <Button type='primary' className="manage_contents_title_margin" onClick={this.StorageInSearch.bind(this, true)}>显示全部</Button>
                    </div>
                    <div className="manage_title_button_right_side">
                        {uiNewButton}
                        {uiEditButton}
                        {uiDeleteButton}
                    </div>
                </div >
            );
        }

        // 如果界面上需要显示的内容已经从数据库获得
        if (!this.state.bLoading) {
            // 主体表格相关
            const { selectedRowKeys } = this.state;
            const rowSelection = {
                selectedRowKeys,
                onChange: this.onSelectedRowKeysChange,
                columnWidth: 30
            };
            // 表格分页属性
            const paginationProps = {
                showSizeChanger: true,
                showQuickJumper: false,
                showTotal: () => `共 ${this.arrTableData.length} 条 入库记录`,
                defaultPageSize: 10,
                defaultCurrent: this.state.iCurrentPage,
                pageSizeOptions: ['10', '20', '30', '40', '50'],
                total: this.arrTableData.length,
                position: 'bottom'
            };

            let uiItemList = null;
            if (this.arrItemList.length <= 0) {
                let objItemInit = {
                    name: "", notes: "", reference: '', num: 0, weight: '0.0', length: '0.0', width: '0.0', height: '0.0'
                };
                this.arrItemList.push(objItemInit);
            }
            let iItemTotalNum = 0;
            uiItemList = this.arrItemList.map((item, index) => {
                let strStyle = "logistics_item_list_area1 ";
                if (index % 2 !== 0)
                    strStyle += "logistics_item_list_area2";
                iItemTotalNum += parseInt(item.num);
                let bDiabled = false;
                let uiSelectButton = (
                    <Button className="logistics_small_button" type="danger" onClick={this.DeleteItem.bind(this, index)}>X</Button>
                );
                if (this.props.iUserLevel === 1) {
                    uiSelectButton = null;
                    bDiabled = true;
                }

                return (
                    <div className={strStyle} key={index}>
                        <div className="user_edit_info_row">
                            <div className="user_edit_info_title" style={{ color: 'lightcoral' }}>名称：</div>
                            <Input className="user_edit_info_input" ref={"name" + index}
                                style={{ marginRight: '30px', background: 'white', color: 'black' }}
                                value={item.name} onChange={this.ItemValueChange.bind(this, index, 0)} disabled={bDiabled} />
                            <div className="user_edit_info_title">描述：</div>
                            <Input className="user_edit_info_input_long" ref={"notes" + index}
                                style={{ marginRight: '30px', background: 'white', color: 'black' }}
                                value={item.notes} onChange={this.ItemValueChange.bind(this, index, 1)} disabled={bDiabled} />
                            {uiSelectButton}
                        </div>
                        <div className="user_edit_info_row">
                            <div className="user_edit_info_title" style={{ color: 'lightcoral' }}>数量：</div>
                            <Input className="user_edit_info_input" ref={"num" + index}
                                style={{ marginRight: '30px', background: 'white', color: 'black' }}
                                disabled={bDiabled} value={item.num} onChange={this.ItemValueChange.bind(this, index, 3)} />
                            <div className="user_edit_info_title" style={{ color: 'lightcoral' }}>重量：</div>
                            <Input className="user_edit_info_input" ref={"weight" + index}
                                style={{ marginRight: '30px', background: 'white', color: 'black' }} addonAfter={"KG"}
                                value={item.weight} onChange={this.ItemValueChange.bind(this, index, 4)} />
                            <div className="user_edit_info_title">Reference</div>
                            <Input className="user_edit_info_input" ref={"reference" + index}
                                style={{ marginRight: '30px', background: 'white', color: 'black' }}
                                value={item.reference} onChange={this.ItemValueChange.bind(this, index, 2)} disabled={bDiabled} />
                        </div>
                        <div className="user_edit_info_row" >
                            <div className="user_edit_info_title">长度：</div>
                            <Input className="user_edit_info_input" ref={"length" + index}
                                style={{ marginRight: '30px', background: 'white', color: 'black' }} addonAfter={"CM"}
                                value={item.length} onChange={this.ItemValueChange.bind(this, index, 5)} />
                            <div className="user_edit_info_title">宽度：</div>
                            <Input className="user_edit_info_input" ref={"width" + index}
                                style={{ marginRight: '30px', background: 'white', color: 'black' }} addonAfter={"CM"}
                                value={item.width} onChange={this.ItemValueChange.bind(this, index, 6)} />
                            <div className="user_edit_info_title">高度：</div>
                            <Input className="user_edit_info_input" ref={"height" + index}
                                style={{ marginRight: '30px', background: 'white', color: 'black' }} addonAfter={"CM"}
                                value={item.height} onChange={this.ItemValueChange.bind(this, index, 7)} />
                        </div>
                    </div>);
            });

            // 入库记录编辑弹窗
            let uiModal = (
                <Modal title="入库记录" width={1000} visible={this.state.bShowDlg}
                    okText="提交" cancelText="取消" onOk={this.handleOk} onCancel={this.handleCancel} >
                    <div className="user_edit_info_row" >
                        <div className="user_edit_info_title" style={{ color: 'lightcoral' }}>柜号：</div>
                        <Input className="user_edit_info_input" ref="closet" style={{ marginRight: '30px' }}
                            defaultValue={this.objCurrentStorageIn.closet} />
                        <div className="user_edit_info_title" style={{ color: 'lightcoral' }}>客户：</div>
                        <Input className="user_edit_info_input" ref="client_name" style={{ marginRight: '30px' }}
                            defaultValue={this.objCurrentStorageIn.client_name} disabled />
                        <Button className="manage_contents_title_margin" type="primary" onClick={this.SelectClient.bind(this)}> 选择</Button>
                    </div>
                    <div className="user_edit_info_row" >
                        <div className="user_edit_info_title" style={{ color: 'lightcoral' }}>入库日期</div>
                        <DatePicker className="user_edit_info_input" onChange={this.StorageInDateChange} style={{ marginRight: '30px' }}
                            value={moment(this.state.dateStorageIn, dateFormat)}
                            defaultValue={moment(this.state.dateStorageIn, dateFormat)} />
                        <div className="user_edit_info_title" >库位/货架</div>
                        <Input className="user_edit_info_long" ref="location" style={{ marginRight: '0px' }}
                            defaultValue={this.objCurrentStorageIn.location} />
                    </div>
                    <div className="user_edit_info_row" style={{ marginTop: '20px' }}>
                        <div className="user_edit_info_title" style={{ marginTop: '10px', marginBottom: 'auto' }}>备注：</div>
                        <TextArea size="large" autoSize={{ minRows: 2 }} allowClear={true} ref="comments" style={{ marginBottom: '15px' }}
                            placeholder="" defaultValue={this.objCurrentStorageIn.comments} />
                    </div>
                    <div className="logistics_block_title" >
                        货物列表
                        <div className="user_edit_info_input" style={{ marginLeft: 'auto', marginRight: '0px' }}>{"种类：" + this.arrItemList.length}</div>
                        <div className="user_edit_info_input" >{"总数：" + iItemTotalNum}</div>
                        <Button type="primary" className="jeton_order_block_button" style={{ marginLefr: 'auto', marginRight: '0px' }}
                            onClick={this.AddItem}>新增 +</Button>
                    </div>
                    {uiItemList}
                </Modal>
            );
            if (this.props.iUserLevel === 1) {
                uiModal = (<Modal title="入库记录" width={1000} visible={this.state.bShowDlg} footer={null} onCancel={this.handleCancel} >
                    <div className="user_edit_info_row" >
                        <div className="user_edit_info_title" style={{ color: 'lightcoral' }}>柜号：</div>
                        <Input className="user_edit_info_input" ref="closet"
                            style={{ marginRight: '30px', background: 'white', color: 'black' }}
                            defaultValue={this.objCurrentStorageIn.closet} disabled />
                        <div className="user_edit_info_title" style={{ color: 'lightcoral' }}>客户：</div>
                        <Input className="user_edit_info_input" ref="client_name"
                            style={{ marginRight: '30px', background: 'white', color: 'black' }}
                            defaultValue={this.objCurrentStorageIn.client_name} disabled />
                    </div>
                    <div className="user_edit_info_row" >
                        <div className="user_edit_info_title" style={{ color: 'lightcoral' }}>入库日期</div>
                        <DatePicker className="user_edit_info_input" onChange={this.StorageInDateChange}
                            style={{ marginRight: '30px', background: 'white', color: 'black' }}
                            value={moment(this.state.dateStorageIn, dateFormat)}
                            defaultValue={moment(this.state.dateStorageIn, dateFormat)} />
                        <div className="user_edit_info_title" >库位/货架</div>
                        <Input className="user_edit_info_long" ref="location"
                            style={{ marginRight: '0px', background: 'white', color: 'black' }} disabled
                            defaultValue={this.objCurrentStorageIn.location} />
                    </div>
                    <div className="user_edit_info_row" style={{ marginTop: '20px' }}>
                        <div className="user_edit_info_title" style={{ marginTop: '10px', marginBottom: 'auto' }}>备注：</div>
                        <TextArea size="large" autoSize={{ minRows: 2 }} allowClear={true} ref="comments"
                            style={{ marginRight: '15px', background: 'white', color: 'black' }}
                            placeholder="" defaultValue={this.objCurrentStorageIn.comments} />
                    </div>
                    <div className="logistics_block_title" >
                        货物列表
                        <div className="user_edit_info_input" style={{ marginLeft: 'auto', marginRight: '0px' }}>{"种类：" + this.arrItemList.length}</div>
                        <div className="user_edit_info_input" >{"总数：" + iItemTotalNum}</div>
                    </div>
                    {uiItemList}
                </Modal>);

            }
            if (this.state.iDlgType === 2) {
                uiModal = (
                    <Modal width={500} title={"删除入库记录"} visible={this.state.bShowDlg} okType="danger"
                        onOk={this.handleOk} okText="确定"
                        onCancel={this.handleCancel} cancelText="取消">
                        <div className="modal_item">
                            <div>
                                {/* {this.objCurrentStorageIn.id} */}
                                确定删除选中的入库？
                            </div>
                        </div>
                    </Modal>
                );
            }
            // 选择客户弹窗
            let uiClientSearchTitle = (
                <div className="logistic_row">
                    <div >选择客户</div>
                    <Input className="logistic_search_input" ref="client_search" defaultValue="" style={{ marginRight: '0px', marginLeft: '10px' }} allowClear />
                    <Button type="primary" onClick={this.SearchClient} style={{ width: '30px', marginRight: 'auto' }} className="logistic_row">
                        <Icon type="search" /></Button>
                </div>);
            let uiClientList = (
                <div className="storage_sku_list_area">
                    {global.arrClientData.map((client, index) => {
                        if (this.refs.client_search !== undefined) {
                            let strClientSearch = this.refs.client_search.state.value;
                            if (strClientSearch !== "" && strClientSearch !== null) {
                                if (client.name.toLowerCase().indexOf(strClientSearch.toLowerCase()) >= 0)
                                    return (
                                        <div className="storage_sku_list_row" key={index} onClick={this.ClientSelectResult.bind(this, index)}>
                                            {client.name}
                                        </div>
                                    );
                                else return null
                            } else {
                                return (
                                    <div className="storage_sku_list_row" key={index} onClick={this.ClientSelectResult.bind(this, index)}>
                                        {client.name}
                                    </div>
                                );
                            }
                        } else {
                            return (
                                <div className="storage_sku_list_row" key={index} onClick={this.ClientSelectResult.bind(this, index)}>
                                    {client.name}
                                </div>
                            );
                        }
                    })}
                </div>
            );
            let uiClientFilterDlg = (
                <Modal width={450} title={uiClientSearchTitle} visible={this.state.bSelectClientFilterDlg} closable={true} maskClosable={true} footer={null}
                    onCancel={this.ClientSelectResult.bind(this, -1)}
                > {uiClientList} </Modal>);
            let uiSelectClientDlg = (
                <Modal width={450} title={uiClientSearchTitle} visible={this.state.bSelectClientDlg} closable={true} maskClosable={true} footer={null}
                    onCancel={this.ClientSelectResult.bind(this, -1)}
                > {uiClientList} </Modal>);


            let strAreaStyle = "logistics_page_contents";
            let arrTableHeader = arrTableHeader1;
            if (this.props.iUserLevel === 1)
                arrTableHeader = arrTableHeader2;
            return (
                <div className={strAreaStyle} style={{ paddingTop: ' 50px' }}/*"store_manage_contents store_manage_top_space"*/>
                    {uiTitleArea}
                    {uiModal}
                    {uiSelectClientDlg}
                    {uiClientFilterDlg}
                    {/* 表格主体 */}
                    <Table style={{ margin: '20px', width: '97.5%', overflowX: 'auto' }} bordered
                        rowSelection={rowSelection} pagination={paginationProps}
                        columns={arrTableHeader} dataSource={this.arrTableData}
                        onRow={(record) => ({ onClick: () => { this.selectRow(record); } })} />
                </div >
            );
        } else {
            return (
                <div className="logistics_page_contents">
                    {uiTitleArea}
                    <Spin className="manage_empty_loading" size="large" tip="加载中……" />
                </div>
            );
        }
    }
}
export default LogisticStorageIn;
