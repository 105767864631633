import React, { Component } from 'react';
import axios from 'axios';

import Spin from 'antd/es/spin';
import Table from 'antd/es/table';
import Button from 'antd/es/button';
import Input from 'antd/es/input';
// import Menu from 'antd/es/menu';
// import Dropdown from 'antd/es/dropdown';
import Icon from 'antd/es/icon';
import Modal from 'antd/es/modal';
import message from 'antd/es/message';

import '../../../styles/Background/ego_manager.css';
import '../../../styles/Background/storage_manager.css';
import '../../../styles/Background/UI_Module/user_management.css';
// import { UserActiveUpdate } from "../../../functions/DataStatistic.js"

const g_strMessageKey = 'updating';

// 表格
const arrTableHeader = [
    // { title: 'id', dataIndex: 'id', width: 50, ellipsis: true },
    { title: '代码', dataIndex: 'code_str', width: 75, ellipsis: true },
    { title: '名称', dataIndex: 'name', width: 100, ellipsis: true },
    { title: '备注', dataIndex: 'comments', width: 150, ellipsis: true }
];

class InventoryPositionType extends Component {
    constructor(props) {
        super();
        this.state = {
            btnControlDisable: [false, true, true],  // 操作按钮控制
            // btnCopyPasteDisable: [true, true],  // 操作按钮控制
            selectedRowKeys: [],  // Check here to configure the default column
            iDlgType: -1,
            bShowDlg: false,
            bLoading: true,
        };
        // 成员变量
        this.arrOgeTableData = [];
        this.arrTableData = [];  // 用于表格的数组
        this.arrFilterUserStatus = ['全部', '正常使用', '审核中', '已禁用'];
        this.arrFilterUserType = ['全部', '普通库位类型', '商家', '管理员', '超级管理员'];
        this.arrSortType = ['库位类型名A-Z', '库位类型名Z-A', '注册时间↓', '注册时间↑'];
        this.arrSelectedUserID = [];
        this.arrSelectedUserIndex = [];
        this.objCurrentInventoryPositionType = {};
    }

    // 选中某行的响应函数
    selectRow = (record) => {
        const selectedRowKeys = [...this.state.selectedRowKeys];
        if (selectedRowKeys.indexOf(record.key) >= 0) {
            selectedRowKeys.splice(selectedRowKeys.indexOf(record.key), 1);
        } else {
            selectedRowKeys.push(record.key);
        }
        this.SelectedRowStateSetting(selectedRowKeys);
    }
    // 选中的行发生变化的响应函数
    onSelectedRowKeysChange = (selectedRowKeys) => {
        this.SelectedRowStateSetting(selectedRowKeys);
    }
    // 处理选中的表行行
    SelectedRowStateSetting = (selectedRowKeys) => {
        let btnControlDisable = [];
        // let btnCopyPasteDisable = [];
        switch (selectedRowKeys.length) {
            case 0: {
                btnControlDisable = [false, true, true];
                // btnCopyPasteDisable = [true, true];
                this.objCurrentInventoryPositionType = { code: "", name: "", comments: "", store_id: this.props.objStoreInfo.id };
                break;
            }
            case 1: {
                btnControlDisable = [false, false, false];
                // btnCopyPasteDisable = [false, true];
                for (let i = 0; i < this.arrTableData.length; i++) {
                    if (this.arrTableData[i].key === selectedRowKeys[0]) {
                        this.objCurrentInventoryPositionType = this.arrTableData[i];
                        break;
                    }
                }
                break;
            }
            default: {
                btnControlDisable = [false, true, true];
                // btnCopyPasteDisable = [true, true];
                break;
            }
        }
        this.setState({
            ...this.state,
            selectedRowKeys,
            btnControlDisable: btnControlDisable
            // btnCopyPasteDisable: btnCopyPasteDisable
        });
    }
    // 关键词处理
    KeywordsProcess = (strKeywords) => {
        let newKeywords = "";
        for (let i = 0; i < strKeywords.length; i++) {
            if (strKeywords[i] !== " ") {
                if (i === strKeywords.length - 1)
                    newKeywords += strKeywords[i];
                else
                    newKeywords += strKeywords[i] + "%";
            }
        }
        return newKeywords;
    }
    // 搜索库位类型名
    // UserNameSearch = () => {
    //     let strKeywords = this.refs.user_name_search.state.value;
    //     if (strKeywords === undefined)
    //         strKeywords = "";
    //     if (strKeywords.trim() === "") {
    //         this.GetInventoryPositionType();
    //     } else {
    //         this.setState({
    //             ...this.state,
    //             bLoading: true,
    //             selectedRowKeys: [],
    //             btnControlDisable: [false, true, true]
    //         });
    //         axios('/SearchUsers', { params: { name: this.KeywordsProcess(strKeywords) } })
    //             .then(({ data }) => {
    //                 this.arrTableData = this.OrganizeInventoryPositionTypeData(data);
    //                 this.setState({
    //                     ...this.state,
    //                     bLoading: false,
    //                     objFilter: {
    //                         strKeyword: strKeywords,
    //                         iCurrentUserStatus: 0,
    //                         iCurrentUserType: 0
    //                     }
    //                 });
    //             }).catch(function (error) { console.log(error); });
    //     }
    // }
    // 点击了库位类型过滤器
    UserTypeFilterClicked = (iUserType) => {
        let strTargetType = this.arrFilterUserType[iUserType];
        if (iUserType !== 0) {
            this.arrTableData = [];
            for (let i = 0; i < this.arrOgeTableData.length; i++) {
                if (strTargetType === this.arrOgeTableData[i].level) {
                    this.arrTableData.push(this.arrOgeTableData[i]);
                }
            }
        } else {
            this.arrTableData = this.arrOgeTableData;
        }
        if (this.refs.user_name_search !== undefined)
            this.refs.user_name_search.state.value = "";
        this.setState({
            ...this.state,
            objFilter: {
                strKeyword: "",
                iCurrentUserStatus: this.state.objFilter.iCurrentUserStatus,
                iCurrentUserType: iUserType
            }
        });
    }
    // 点击了库位类型状态过滤器
    // UserStatusFilterClicked = (iStatus) => {
    //     this.setState({
    //         ...this.state,
    //         objFilter: {
    //             strKeyword: "", iCurrentUserStatus: iStatus, iCurrentUserType: this.state.objFilter.iCurrentUserType
    //         }
    //     });
    // }
    /* 自定义成员函数 */
    //弹出对话框
    ShowDlg = (iDlgType, e) => {
        if (iDlgType === 0) {
            if (this.refs.name !== undefined) {
                this.refs.name.state.value = "";
                this.refs.code.state.value = "";
                this.refs.comments.state.value = "";
            }
            this.objCurrentInventoryPositionType = { code: "", name: "", comments: "", store_id: this.props.objStoreInfo.id };
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true
            });
        }
        if (iDlgType === 1) {
            if (this.refs.name !== undefined) {
                this.refs.name.state.value = this.objCurrentInventoryPositionType.name;
                this.refs.code.state.value = this.objCurrentInventoryPositionType.code;
                this.refs.comments.state.value = this.objCurrentInventoryPositionType.comments;
            }

            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true,
            });
        }
        if (iDlgType === 2) {
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true
            });
        }
    }
    // 弹窗确认按钮响应
    handleOk = e => {
        if (this.state.iDlgType === 0 || this.state.iDlgType === 1) {
            let strCode = this.refs.code.state.value;
            if (strCode === null || strCode.trim() === "") {
                message.warning("库位类型代码不能为空！"); return;
            }
            let strName = this.refs.name.state.value;
            if (strName === null || strName.trim() === "") {
                message.warning("库位类型名称不能为空！"); return;
            }
            let objNewInventoryPositionType = {
                name: strName,
                code: this.refs.code.state.value,
                comments: this.refs.comments.state.value,
                store_id: this.props.objStoreInfo.id
            }
            message.loading({ content: '正在更新库位类型……', key: g_strMessageKey });
            axios.post('/UpdateInventoryPositionType', {
                objCurrentInventoryPositionType: this.objCurrentInventoryPositionType,
                objNewInventoryPositionType: objNewInventoryPositionType
            }).then(
                ({ data }) => {
                    if (data.status === 1) {
                        this.GetInventoryPositionType();
                    } else
                        message.warning({ content: "新建/更新库位类型失败！", key: g_strMessageKey, duration: 2 });
                }).catch(function (error) { console.log(error); });
        }
        if (this.state.iDlgType === 2) {
            message.loading({ content: '库位类型删除中……', key: g_strMessageKey });
            axios.post('/DeleteInventoryPositionType', { id: this.objCurrentInventoryPositionType.id }).then(
                ({ data }) => {
                    if (data.status === -1) {
                        message.warning({
                            content: "删除失败，数据库中未找到指定库位类型！",
                            key: g_strMessageKey, duration: 2
                        });
                        this.setState({
                            ...this.state,
                            btnControlDisable: [false, true, true],
                            bLoading: false,
                            bShowDlg: false,
                            selectedRowKeys: []
                        });
                    } else {
                        this.GetInventoryPositionType();
                    }
                }).catch(function (error) { console.log(error); });
        }
    };
    // 弹窗取消按钮响应
    handleCancel = e => {
        this.setState({
            bShowDlg: false,
            selectedRowKeys: [],
            btnControlDisable: [false, true, true]
        });
    };
    // 整理Users数据
    OrganizeInventoryPositionTypeData(data) {
        let arrTableData = [];
        for (let i = 0; i < data.length; i++) {
            let objInventoryData = {
                key: i, id: data[i].id,
                name: data[i].name,
                code: data[i].code,
                code_str: <div style={{ color: 'darkcyan', fontWeight: 'bold' }}>{data[i].code}</div>,
                comments: data[i].comments,
                store_id: data[i].store_id
            }
            arrTableData.push(objInventoryData);
        }
        return arrTableData;
    }
    // 从数据库获取当前的数据结构
    GetInventoryPositionType = () => {
        this.objCurrentInventoryPositionType = { code: "", name: "", comments: "", store_id: this.props.objStoreInfo.id };
        message.loading({ content: '数据加载中……', key: g_strMessageKey });
        axios('/GetInventoryPositionType', { params: { store_id: this.props.objStoreInfo.id } })
            .then(({ data }) => {
                this.arrOgeTableData = this.OrganizeInventoryPositionTypeData(data);
                this.arrTableData = this.arrOgeTableData;
                if (this.state.bShowDlg) {
                    if (this.state.iDlgType === 0) {
                        message.success({ content: "新建库位类型完成！", key: g_strMessageKey, duration: 2 });
                    }
                    if (this.state.iDlgType === 1) {
                        message.success({ content: "库位类型已更新！", key: g_strMessageKey, duration: 2 });
                    }
                    if (this.state.iDlgType === 2) {
                        message.success({ content: "库位类型已删除！", key: g_strMessageKey, duration: 2 })
                    }
                } else {
                    message.success({ content: "库位类型加载完成！", key: g_strMessageKey, duration: 2 });
                }
                this.setState({
                    ...this.state,
                    bLoading: false,
                    bShowDlg: false,
                    selectedRowKeys: []
                });
            }).catch(function (error) { console.log(error); });
    }
    /* React自带函数 */
    // 卸载异步操作设置状态
    componentWillUnmount() {
        clearTimeout(this.timeouter)
        this.setState = (state, callback) => {
            return;
        }
    }
    // 渲染完毕……
    componentDidMount() {
        this.GetInventoryPositionType();
    }
    // 渲染函数
    render() {

        // 顶部栏库位类型下拉菜单
        // const uiUserTypeMenu = (
        //     <Menu>
        //         {this.arrFilterUserType.map((item, index) => {
        //             return (
        //                 <Menu.Item key={index} onClick={this.UserTypeFilterClicked.bind(this, index)}>
        //                     {item}
        //                 </Menu.Item>
        //             );
        //         })}
        //     </Menu>
        // );

        /* 库位类型管理页面控制栏 */
        let uiTitleArea = (
            < div className="storage_contents_title" >
                {/* <div className="manage_stretch_right">
                    <Input placeholder="搜索库位类型名/代码" ref="user_name_search" defaultValue={this.state.strKeywords} />
                    <Button className="manage_contents_title_margin"
                        type="primary" onClick={this.UserNameSearch}>搜索
                    </Button>
                </div> */}
                {/* <div className="manage_title_filter_area">
                    <span className="manage_stretch_right_tiny">库位类型</span>
                    <Dropdown className="manage_contents_title_margin manage_stretch_right"
                        overlay={uiUserTypeMenu} placement="bottomLeft">
                        <Button>
                            {this.arrFilterUserType[this.state.objFilter.iCurrentUserType]}
                            <Icon className="dropdown_icon" type="down" />
                        </Button>
                    </Dropdown>
                </div> */}
                {/* <div className="manage_title_button_right_side"> */}
                <Button className="manage_contents_title_margin" style={{ background: 'darkcyan', color: 'white', marginLeft: '5px' }}
                    onClick={this.ShowDlg.bind(this, 0)} disabled={this.state.btnControlDisable[0]}>
                    <Icon type="plus" />新建</Button>
                <Button className="manage_contents_title_margin" type="primary" onClick={this.ShowDlg.bind(this, 1)} disabled={this.state.btnControlDisable[1]}>
                    <Icon type="edit" /> 编辑</Button>
                <Button className="manage_contents_title_margin" type="danger" onClick={this.ShowDlg.bind(this, 2)} disabled={this.state.btnControlDisable[2]}>
                    <Icon type="close" /> 删除</Button>
                {/* </div> */}
            </div >
        );

        // 如果界面上需要显示的内容已经从数据库获得
        if (!this.state.bLoading) {
            // 主体表格相关
            const { selectedRowKeys } = this.state;
            const rowSelection = {
                selectedRowKeys,
                onChange: this.onSelectedRowKeysChange,
                columnWidth: 30
            };
            // 表格分页属性
            const paginationProps = {
                showSizeChanger: true,
                showQuickJumper: false,
                showTotal: () => `共 ${this.arrTableData.length} 个 库位类型`,
                defaultPageSize: 10,
                defaultCurrent: this.state.iCurrentPage,
                pageSizeOptions: ['10', '20', '30', '40', '50'],
                total: this.arrTableData.length,
                position: 'bottom'
            };
            // 库位类型编辑弹窗
            let uiModal = (
                <Modal title="库位类型" width={350} visible={this.state.bShowDlg}
                    okText="确定" cancelText="取消" onOk={this.handleOk} onCancel={this.handleCancel} >
                    <div className="user_edit_info_row">
                        <div className="user_edit_info_title">代码：</div>
                        <Input className="user_edit_info_input" ref="code"
                            defaultValue={this.objCurrentInventoryPositionType.code} />
                    </div>
                    <div className="user_edit_info_row">
                        <div className="user_edit_info_title">名称：</div>
                        <Input className="user_edit_info_input" ref="name"
                            defaultValue={this.objCurrentInventoryPositionType.name} />
                    </div>
                    <div className="user_edit_info_row">
                        <div className="user_edit_info_title">备注：</div>
                        <Input className="user_edit_info_input" ref="comments"
                            defaultValue={this.objCurrentInventoryPositionType.comments} />
                    </div>
                </Modal>
            );
            if (this.state.iDlgType === 2) {
                uiModal = (
                    <Modal width={500} title={"删除库位类型"} visible={this.state.bShowDlg} okType="danger"
                        onOk={this.handleOk} okText="确定"
                        onCancel={this.handleCancel} cancelText="取消">
                        <div className="modal_item">
                            <div>{this.objCurrentInventoryPositionType.name}
                                <br /><br />确定删除选中的库位类型？
                            </div>
                        </div>
                    </Modal>
                );
            }

            return (
                <div className="storage_contents_area">
                    {uiTitleArea}
                    {uiModal}
                    {/* 表格主体 */}
                    <Table style={{ margin: '20px', width: '96%', overflowX: 'auto' }}
                        rowSelection={rowSelection} pagination={paginationProps}
                        columns={arrTableHeader} dataSource={this.arrTableData} bordered
                        onRow={(record) => ({ onClick: () => { this.selectRow(record); } })} />
                </div >
            );
        } else {
            return (
                <div className="storage_contents_area">
                    {uiTitleArea}
                    <Spin className="manage_empty_loading" size="large" tip="加载中……" />
                </div>
            );
        }
    }
}
export default InventoryPositionType;
