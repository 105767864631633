import React, { Component } from "react";
import axios from 'axios';

import Tag from 'antd/es/tag';
import Input from 'antd/es/input';
import Tooltip from 'antd/es/tooltip';
import Icon from 'antd/es/icon';
import Button from 'antd/es/button';
import message from 'antd/es/message';

// import { Tag, Input, Tooltip, Icon, Button, message } from 'antd';
import "../../../styles/Background/store_manager.css";
import "../../../styles/Background/UI_Module/item_type_management.css";
import { UserActiveUpdate } from "../../../functions/DataStatistic.js"

const g_strMessageKey = 'updating';

class SearchManagement extends Component {
    constructor(props) {
        super();
        this.state = {
            arrCustomTags: [],
            inputVisible: false,
            inputValue: '',
            arrInputVisible: [],
            bSubmitDisable: true
        };
        this.arrTagObj = [];
    }

    RemoveTag = (iTag) => {
        let tags = this.state.arrCustomTags;
        tags.splice(iTag, 1);

        let arrInputVisible = this.state.arrInputVisible;
        arrInputVisible.slice(0, 1);

        this.setState({
            ...this.state,
            arrCustomTags: tags,
            arrInputVisible: arrInputVisible,
            bSubmitDisable: false
        });
    };

    showInput = () => {
        this.setState({
            inputVisible: true
        }, () => this.input.focus());
    };

    handleInputChange = (e) => {
        this.setState({ inputValue: e.target.value });
    };

    TagEditChange = (iTag, e) => {
        this.setState({ inputValue: e.target.value });
    };

    handleInputConfirm = () => {
        const { inputValue } = this.state;
        if (inputValue.trim() === "") {
            message.warning("标签不能为空！");
            return;
        }
        let arrTagInfo = inputValue.split('-');
        if (arrTagInfo.length !== 4) {
            message.warning("请严格按照'序号-名称-地区id-主分类id'来填写！");
            return;
        }
        let { arrCustomTags: tags } = this.state;
        if (inputValue && tags.indexOf(inputValue) === -1) {
            tags = [...tags, inputValue];
        }
        let arrInputVisible = this.state.arrInputVisible;
        arrInputVisible.push(false);
        this.setState({
            ...this.state,
            arrCustomTags: tags,
            inputVisible: false,
            inputValue: '',
            arrInputVisible: arrInputVisible,
            bSubmitDisable: false
        });
    };

    TagEditConfirm = (iTag) => {
        const { inputValue } = this.state;
        let { arrCustomTags: tags } = this.state;
        if (inputValue && tags.indexOf(inputValue) === -1) {
            tags[iTag] = inputValue;
        }
        let arrInputVisible = this.state.arrInputVisible;
        arrInputVisible[iTag] = false;
        this.setState({
            ...this.state,
            arrCustomTags: tags,
            inputValue: '',
            arrInputVisible: arrInputVisible,
            bSubmitDisable: false
        });
    };

    saveInputRef = input => (this.input = input);

    onTagDoubleClick = (iTag) => {
        let arrInputVisible = this.state.arrInputVisible;
        arrInputVisible[iTag] = true;
        this.setState({
            ...this.state,
            inputValue: this.state.arrCustomTags[iTag],
            arrInputVisible: arrInputVisible
        }, () => this.input.focus());
    }
    // 提交新的热搜标签
    SubmitTags = () => {
        let iOgeTagNum = this.arrTagObj.length;
        let iCurrentTagNum = this.state.arrCustomTags.length;
        for (let i = 0; i < iCurrentTagNum; i++) {
            let objTag = { id: -1, name: '', tag_order: 0, location: '0', category: '0' };
            let arrTagInfo = this.state.arrCustomTags[i].split('-');
            objTag.tag_order = arrTagInfo[0];
            objTag.name = arrTagInfo[1];
            objTag.location = arrTagInfo[2];
            objTag.category = arrTagInfo[3];
            if (i < iOgeTagNum) {
                objTag.id = this.arrTagObj[i].id;
                this.arrTagObj[i] = objTag;
            }
            else
                this.arrTagObj.push(objTag);
        }
        if (iCurrentTagNum < iOgeTagNum) {
            this.arrTagObj = this.arrTagObj.slice(0, iCurrentTagNum);
        }
        message.loading({ content: '热门标签更新中……', key: g_strMessageKey });
        axios.post('/UpdateHotSearch', { tags: this.arrTagObj })
            .then((response) => {
                // DataStatistic
                UserActiveUpdate(7, { active: "管理后台: 热门标签更新" });
                message.success({
                    content: "热门标签更新完成！！",
                    key: g_strMessageKey, duration: 2
                });
                this.setState({
                    ...this.state,
                    bSubmitDisable: true
                });
                this.GetHotSearchTags();
            });
    }
    // 从数据库获得商家类别/标签信息
    GetHotSearchTags = () => {
        message.loading({ content: '数据加载中……', key: g_strMessageKey });
        axios('/GetHotSearch').then(
            ({ data }) => {
                message.success({ content: "数据加载完成！", key: g_strMessageKey, duration: 2 });
                this.arrTagObj = data;
                let arrCustomTags = [];
                for (let i = 0; i < this.arrTagObj.length; i++) {
                    arrCustomTags.push(
                        this.arrTagObj[i].tag_order.toString() + "-" +
                        this.arrTagObj[i].name + "-" +
                        this.arrTagObj[i].location + "-" +
                        this.arrTagObj[i].category);
                }
                this.setState({
                    ...this.state,
                    arrCustomTags: arrCustomTags
                });
            });
    }
    // 卸载异步操作设置状态
    componentWillUnmount() {
        clearTimeout(this.timeouter)
        this.setState = (state, callback) => {
            return;
        }
    }
    // 渲染完毕……
    componentDidMount() {
        this.GetHotSearchTags();
    }

    render() {

        const { arrCustomTags: tags, inputVisible, inputValue } = this.state;

        return (
            <div className="search_manage_contents">
                <div className="item_type_title">
                    热门搜索标签设置：序号-名称-地区id-分类id
                    <Button className="item_type_submit_btn" type="primary" disabled={this.state.bSubmitDisable}
                        onClick={this.SubmitTags}>提　交</Button>
                </div>
                <div className="item_type_edit_area">
                    {tags.map((tag, index) => {
                        const isLongTag = tag.length > 20;
                        const strTagValue = isLongTag ? `${tag.slice(0, 20)}...` : tag;
                        const uiTagItem = (
                            <Tag key={tag} color="volcano" closable={true}
                                onClose={() => this.RemoveTag(index)}
                                onDoubleClick={this.onTagDoubleClick.bind(this, index)}>
                                {strTagValue} </Tag>
                        );
                        let uiTagArea = isLongTag ? (<Tooltip title={tag} key={index}> {uiTagItem} </Tooltip>) : (uiTagItem);
                        if (this.state.arrInputVisible[index])
                            uiTagArea = <Input ref={this.saveInputRef} type="text" size="small" style={{ width: 80 }}
                                value={inputValue} onChange={this.TagEditChange.bind(this, index)} key={index}
                                onBlur={this.TagEditConfirm.bind(this, index)} onPressEnter={this.TagEditConfirm.bind(this, index)} />
                        return uiTagArea;
                    })}
                    {inputVisible && (
                        <Input ref={this.saveInputRef} type="text" size="small" style={{ width: 80 }}
                            value={inputValue} onChange={this.handleInputChange}
                            onBlur={this.handleInputConfirm} onPressEnter={this.handleInputConfirm} />
                    )}
                    {!inputVisible && (
                        <Tag className="item_type_tag" onClick={this.showInput} style={{ background: '#fff', borderStyle: 'dashed' }}>
                            <Icon type="plus" /> 新的标签
                        </Tag>)}
                </div>
            </div>
        );
    }
}
export default SearchManagement;
