import React, { Component } from "react";
import axios from 'axios';
import moment from 'moment';

import Dropdown from 'antd/es/dropdown';
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import Icon from 'antd/es/icon';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import TimePicker from 'antd/es/time-picker';
import message from 'antd/es/message';

// import { Dropdown, Input, Button, Icon, Row, Col, TimePicker, message } from 'antd';
import '../../../styles/icon_image.css';
import '../../../styles/Background/ego_manager.css';
import '../../../styles/Background/store_manager.css';
import "../../../styles/Background/UI_Module/store_info_edit.css";
import { UserActiveUpdate } from "../../../functions/DataStatistic.js"

const g_strMessageKey = 'updating';

class StoreInfoEdit extends Component {
    constructor(props) {
        super();
        this.state = {
            bOpenTimeGet: false,
            strMainCategory: '',
            strSubCategory: '',
            strLocation: ''
        };
        this.arrStartTime = ["09:00 am", "09:00 am", "09:00 am", "09:00 am", "09:00 am", "09:00 am", "09:00 am"];
        this.arrEndTime = ["09:00 pm", "09:00 pm", "09:00 pm", "09:00 pm", "09:00 pm", "09:00 pm", "09:00 pm"];
    }

    onChange = (iDate, bStart, time, strTime) => {
        // console.log(iDate);
        // console.log(bStart);
        // console.log(time);
        // console.log(strTime);
        if (bStart)
            this.arrStartTime[iDate] = strTime;
        else
            this.arrEndTime[iDate] = strTime;
    }

    DateIntToStr = (iDate) => {
        let strDate = "一";
        switch (iDate) {
            case 1: strDate = "一"; break;
            case 2: strDate = "二"; break;
            case 3: strDate = "三"; break;
            case 4: strDate = "四"; break;
            case 5: strDate = "五"; break;
            case 6: strDate = "六"; break;
            case 7: strDate = "日"; break;
            default: strDate = "一"; break;
        }
        return strDate;
    }
    // 提交营业时间
    TimeSubmit = () => {
        let strOpenTime = "";
        for (let i = 0; i < 7; i++) {
            strOpenTime += "周" + this.DateIntToStr(i + 1) + " " + this.arrStartTime[i] + " - " + this.arrEndTime[i];
            if (i < 6)
                strOpenTime += ",";
        }
        message.loading({ content: '营业时间更新中……', key: g_strMessageKey });
        axios.post('/UpdateStoreOpenTime', { id: this.props.objStoreInfo.id, open_time: strOpenTime })
            .then((response) => {
                message.success({
                    content: "营业时间已更新！",
                    key: g_strMessageKey, duration: 2
                });
                // DataStatistic
                UserActiveUpdate(2, { active: "商家后台: 营业信息更新" });
            });
    }
    // 获取营业时间
    GetOpenTime = () => {
        // 发送获取营业时间数据的请求
        axios('/GetStoreOpenTime', {
            params: { id: this.props.objStoreInfo.id }
        }).then(
            ({ data }) => {
                if (data !== "" && data !== null && data !== undefined) {
                    let arrTime = data.split(',');
                    this.arrStartTime = [];
                    this.arrEndTime = [];
                    for (let i = 0; i < arrTime.length; i++) {
                        let strStart = arrTime[i].split(' ')[1] + " am";
                        let strEnd = arrTime[i].split(' ')[4] + " pm";
                        this.arrStartTime.push(strStart);
                        this.arrEndTime.push(strEnd);
                    }
                }
                this.setState({
                    ...this.state,
                    bOpenTimeGet: true,
                })
            }
        ).catch(function (error) { console.log(error); });
    }
    // 获取主分类信息
    GetMainCategoriesData = () => {
        axios('/GetTargetCategory', {
            params: { id: this.props.objStoreInfo.main_category }
        }).then(
            ({ data }) => {
                let strMainCategory = "";
                if (data.length > 0)
                    strMainCategory = data[0].name
                this.setState({
                    ...this.state,
                    strMainCategory: strMainCategory
                });
            }
        ).catch(function (error) { console.log(error); });
    }
    // 获取子分类信息
    GetSubCategoriesData = () => {
        // 1. 发送获取分类数据的请求
        axios('/GetTargetCategory', {
            params: { id: this.props.objStoreInfo.sub_category }
        }).then(
            ({ data }) => {
                let strSubCategory = "";
                if (data.length > 0)
                    strSubCategory = data[0].name
                this.setState({
                    ...this.state,
                    strSubCategory: strSubCategory
                });
            }
        ).catch(function (error) { console.log(error); });
    }
    // 从数据库获取指定地区
    GetLocationData = () => {
        axios('/GetTargetLocation', { params: { id: this.props.objStoreInfo.location } })
            .then(({ data }) => {
                message.success({ content: "数据加载完成！", key: g_strMessageKey, duration: 2 });
                this.setState({
                    ...this.state,
                    strLocation: data[0].name
                });
            }).catch(function (error) { console.log(error); });
    }
    // 卸载异步操作设置状态
    componentWillUnmount() {
        clearTimeout(this.timeouter)
        this.setState = (state, callback) => {
            return;
        }
    }
    // 渲染完毕……
    componentDidMount() {
        message.loading({ content: '数据加载中……', key: g_strMessageKey });
        this.GetOpenTime();
        this.GetMainCategoriesData();
        this.GetSubCategoriesData();
        this.GetLocationData();
    }

    render() {
        const format = "h:mm a";
        let uiTimeManage = [];
        if (this.state.bOpenTimeGet) {
            for (let i = 1; i <= 7; i++) {
                let strDate = this.DateIntToStr(i);
                let strStart = this.arrStartTime[i - 1];
                let strEnd = this.arrEndTime[i - 1];
                uiTimeManage.push(
                    <Row className="manage_modal_row" type="flex" justify="start" key={i}>
                        周{strDate}：从 <TimePicker className="store_time_picker" defaultValue={moment(strStart, format)}
                            use12Hours format={format} onChange={this.onChange.bind(this, i - 1, true)} />
                        到 <TimePicker className="store_time_picker" defaultValue={moment(strEnd, format)}
                            use12Hours format={format} onChange={this.onChange.bind(this, i - 1, false)} />
                    </Row>
                );
            }
        }

        return (
            <div className="store_manage_contents">
                <div className="store_info_form">
                    {/* 名称、联系人、代理 */}
                    <div className="manage_model_box">
                        <Row className="manage_modal_row" type="flex" justify="start">
                            <Col className='manage_modal_col' span={4}>
                                <div className="manage_info_label">
                                    <div className="must_input_star"></div>名称
                                </div>
                                <Input className='manage_modal_input' size="default"
                                    placeholder="" ref="input_store_name"
                                    defaultValue={this.props.objStoreInfo.store_name} disabled={true} />
                            </Col>
                            <Col className='manage_modal_col' span={4}>
                                <div className="manage_info_label">
                                    <div className="must_input_star"></div>联系人
                                </div>
                                <Input className='manage_modal_input' size="default" placeholder="" ref="input_contact_name"
                                    defaultValue={this.props.objStoreInfo.contact_name} disabled={true} />
                            </Col>
                            <Col className="manage_modal_col" span={4}>
                                <div className="manage_info_label">
                                    <div className="must_input_star"></div>代理
                                </div>
                                <Input className="manage_modal_input" size="default" placeholder="" ref="input_proxy_name"
                                    defaultValue={this.props.objStoreInfo.proxy_name} disabled={true} />
                            </Col>
                        </Row>
                        <Row className="manage_modal_row" type="flex" justify="start">
                            <Col className='manage_modal_col' span={4}>
                                <span className="manage_info_label">
                                    <div className="must_input_star"></div>地址
                                </span>
                                <Input className='manage_modal_input manage_long_input'
                                    size="default" placeholder="" ref="input_address" disabled={true}
                                    defaultValue={this.props.objStoreInfo.address} />
                            </Col>
                        </Row>
                    </div>
                    {/* 主分类、子分类、地区 */}
                    <div className="manage_model_box">
                        <Row className="manage_modal_row" type="flex" justify="start">
                            <Col className="manage_modal_col" span={4}>
                                <div className="manage_info_label">
                                    <div className="must_input_star"></div>主分类
                                </div>
                                <Dropdown className="manage_modal_dropdown"
                                    overlay={null} placement="bottomLeft" disabled={true}>
                                    <Button> {this.state.strMainCategory} <Icon type="caret-down" />
                                    </Button>
                                </Dropdown>
                            </Col>
                            <Col className="manage_modal_col" span={4}>
                                <span className="manage_info_label">
                                    <div className="must_input_star"></div>子分类
                                </span>
                                <Dropdown className="manage_modal_dropdown"
                                    overlay={null} placement="bottomLeft" disabled={true}>
                                    <Button>{this.state.strSubCategory}<Icon type="caret-down" /></Button>
                                </Dropdown>
                            </Col>
                            <Col className="manage_modal_col" span={4}>
                                <span className="manage_info_label">
                                    <div className="must_input_star"></div>地区
                                </span>
                                <Dropdown className="manage_modal_dropdown"
                                    overlay={null} placement="bottomLeft" disabled={true}>
                                    <Button>{this.state.strLocation}<Icon type="caret-down" /></Button>
                                </Dropdown>
                            </Col>
                        </Row>
                    </div>
                    {/* 商家联系方式 */}
                    <div className="manage_model_box">
                        <Row className="manage_modal_row" type="flex" justify="start">
                            <Col className="manage_modal_col" span={4}>
                                <span className="manage_info_label">
                                    <div className="must_input_star"></div>手机
                                </span>
                                <Input className="manage_modal_input" size="default" placeholder=""
                                    ref="input_cell_phone" defaultValue={this.props.objStoreInfo.cell_phone} disabled={true} />
                            </Col>
                            <Col className="manage_modal_col" span={4}>
                                <span className="manage_info_label">
                                    <div className="must_input_star"></div>座机
                                </span>
                                <Input className="manage_modal_input" size="default" placeholder=""
                                    ref="input_phone" defaultValue={this.props.objStoreInfo.phone} disabled={true} />
                            </Col>
                            <Col className="manage_modal_col" span={4}>
                                <span className="manage_info_label">
                                    <div className="must_input_star"></div>邮箱
                                </span>
                                <Input className="manage_modal_input" size="default" placeholder=""
                                    ref="input_email" defaultValue={this.props.objStoreInfo.email} disabled={true} />
                            </Col>
                        </Row>
                        <Row className="manage_modal_row" type="flex" justify="start">
                            <Col className="manage_modal_col" span={4}>
                                <span className="manage_info_label">
                                    <div className="must_input_star"></div>微信
                                </span>
                                <Input className="manage_modal_input" size="default" placeholder=""
                                    ref="input_wechat" defaultValue={this.props.objStoreInfo.wechat} disabled={true} />
                            </Col>
                            <Col className="manage_modal_col" span={4}>
                                <span className="manage_info_label">
                                    <div className="must_input_star"></div>QQ
                                </span>
                                <Input className="manage_modal_input" size="default" placeholder=""
                                    ref="input_qq" defaultValue={this.props.objStoreInfo.qq} disabled={true} />
                            </Col>
                        </Row>
                    </div>
                    {/* 商家描述和备注 */}
                    <div className="manage_model_box">
                        <Row className="manage_modal_row" type="flex" justify="start">
                            <Col className="manage_modal_col" span={4}>
                                <span className="manage_info_label"><div className="must_input_star"></div>描述</span>
                                <Input className="manage_modal_input manage_long_input"
                                    size="default" placeholder="" ref="input_description"
                                    defaultValue={this.props.objStoreInfo.description} disabled={true} />
                            </Col>
                        </Row>
                        <Row className="manage_modal_row" type="flex" justify="start">
                            <Col className="manage_modal_col" span={4}>
                                <span className="manage_info_label" ><div className="must_input_star"></div>备注</span>
                                <Input className="manage_modal_input manage_long_input"
                                    size="default" placeholder="" ref="input_comment"
                                    defaultValue={this.props.objStoreInfo.comment} disabled={true} />
                            </Col>
                        </Row>
                    </div>
                    {/* 营业时间 */}
                    <div className="manage_model_box">
                        <div className="store_time_manage_area">
                            <div className="store_time_manage_title">
                                <Row className="manage_modal_row" type="flex" justify="start">
                                    营业时间<br />(*可编辑)
                                </Row>
                            </div>
                            <div>
                                {uiTimeManage}
                            </div>
                            <Button className="store_info_submit_btn" type="primary" onClick={this.TimeSubmit}>
                                提　交 </Button>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}
export default StoreInfoEdit;
