import React, { Component } from "react";
import axios from 'axios';
import cookie from 'react-cookies'

import Empty from 'antd/es/empty';
import Spin from 'antd/es/spin';
import Menu from 'antd/es/menu';
import Icon from 'antd/es/icon';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import Button from 'antd/es/button';
// import Dropdown from 'antd/es/dropdown';
import message from 'antd/es/message';

import LogisticDataPanel from "./ManagerPage/LogisticDataPanel.js";
// import StoreInfoEdit from "./ManagerPage/StoreInfoEdit.js";
// import StoreImageManagement from "./ManagerPage/StoreImageManagement.js";
// import StoreItemManagement from "./ManagerPage/StoreItemManagement.js";
// import ItemTypeManagement from "./ManagerPage/ItemTypeManagement.js";
// import DeliveryFeeCalculator from "./ManagerPage/DeliveryFeeCalculator.js";
// import StoreTicketManagement from "./ManagerPage/StoreTicketManagement.js";
// import CouponCodeManager from "./ManagerPage/CouponCodeManager.js";
// import StoreOrderManagement from "./ManagerPage/StoreOrderManagement.js";
import LogisticOrder from "./ManagerPage/LogisticOrder.js";
// import TransitStatusManagement from "./ManagerPage/TransitStatusManagement.js";
// import TransitPriceManager from "./ManagerPage/TransitPriceManager.js";
// import ExpressEnter from "./ManagerPage/ExpressEnter.js";
// import ExpressEnterJW from "./ManagerPage/ExpressEnterJW.js";
// import CACNOrder from "./ManagerPage/CACNOrder.js";
// import PackageCombine from "./ManagerPage/PackageCombine.js";
// import PackageCombineJW from "./ManagerPage/PackageCombineJW.js";
// import CommentsManager from "./ManagerPage/CommentsManager.js";
// import CityPortManager from "./ManagerPage/CityPortManager.js";
// import CusomsFileManager from "./ManagerPage/CustomsFileManager.js";
// import InvoiceFileManager from "./ManagerPage/InvoiceFileManager.js";
// import StoreUserManager from "./ManagerPage/StoreUserManager.js";
// import StoreUserManagerJW from "./ManagerPage/StoreUserManagerJW.js";
// import StoreClientManagerJW from "./ManagerPage/StoreClientManagerJW.js";

import LogisticUserManager from "./ManagerPage/LogisticUserManager.js";
import LogisticClientManager from "./ManagerPage/LogisticClientManager.js";
import LogisticAgentManager from "./ManagerPage/LogisticAgentManager.js";
import LogisticTruckManager from "./ManagerPage/LogisticTruckManager.js";
import LogisticStorageManager from "./ManagerPage/LogisticStorageManager.js";
import LogisticCarTeamManager from "./ManagerPage/LogisticCarTeamManager.js";
import LogistisPredictionOrder from "./ManagerPage/LogistisPredictionOrder.js";
// import StoreShipperManager from "./ManagerPage/StoreShipperManager.js";
// import StoreReceiverManager from "./ManagerPage/StoreReceiverManager.js";
import LogisticCustomsFile from "./ManagerPage/LogisticCustomsFile.js";
import LogisticActionManager from "./ManagerPage/LogisticActionManager.js";
import LogisticStorageIn from "./ManagerPage/LogisticStorageIn.js";
import LogisticStorageInventory from "./ManagerPage/LogisticStorageInventory.js";
import LogisticStorageOut from "./ManagerPage/LogisticStorageOut.js";
// import LogisticStorageItem from "./ManagerPage/LogisticStorageItem.js";

import { deviceType } from "react-device-detect";
// import { UserActiveUpdate } from "../../functions/DataStatistic.js"

import "../../styles/Background/ego_manager.css";
import "../../styles/Background/store_manager.css";

const { SubMenu } = Menu;
const g_strMessageKey = 'updating';

function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class Logistics extends Component {
    // 构造函数
    constructor(props) {
        super();
        this.state = {
            bLoading: true,  // 加载状态
            bLogin: false,  // 登录状态
            iCurrentInterface: 0,  //当前显示的中心区域内容ID
            iCurrentTopBarIndex: 0,
            arrExpressEnter: []
        };
        this.arrSideMenuButton = [
            { id: 0, name: "仪表盘" },
            {
                id: 1, name: "进口预报",
                sub: [{ id: 11, name: "全部" }, { id: 12, name: "整柜" }, { id: 13, name: "拼箱" }, { id: 14, name: "海派" }, { id: 15, name: "结单" }]
            },
            // {
            //     id: 2, name: "出口预报",
            //     sub: [{ id: 21, name: "全部" }, { id: 22, name: "整柜" }, { id: 23, name: "拼箱" }]
            // },
            // {
            //     id: 3, name: "仓库列表",
            //     sub: [{ id: 31, name: "全部" }]
            // },
            // {
            //     id: 4, name: "海派列表",
            //     sub: [{ id: 41, name: "全部" }, { id: 42, name: "整柜" }, { id: 43, name: "拼箱" }]
            // },
            {
                id: 5, name: "联系信息",
                sub: [{ id: 50, name: "员工" }, { id: 51, name: "客户" }, { id: 52, name: "目的港代理" }, { id: 53, name: "拖车" }, { id: 54, name: "仓库" }, { id: 55, name: "车队" }]
            },
        ];
        this.arrTopMenuButton = [
            // { id: 0, name: "中国 → 加拿大", icon: "car" },
            // { id: 1, name: "加拿大 → 中国", icon: "rocket" }
        ];
        this.iCurrentUserID = 0;
        this.iCurrentUserTeam = -1;
        this.strCurrentUserName = '';
        this.strCurrentUserCode = '';
        this.iCurrentUserLevel = 0;
        this.objStoreInfo = { store_name: '' };

        let strDevice = deviceType;
        if (strDevice === "mobile")
            this.bMStation = true;
        else
            this.bMStation = false;

        this.strTargetOrderNo = "";
        this.strCurrentOid = "";

        this.iAccountSwitch = -1;
        this.iOrderType = 0;  // 海关文件返回专用

        this.iCurrentClientID = -1;
    }
    // 切换顶部栏菜单
    SwitchTopBarMenu = (iMenuID) => {
        this.setState({
            ...this.state,
            bLoading: true
        });
        let iCurrentInterface = 0;
        if (iMenuID === 0) {
            iCurrentInterface = this.arrSideMenuButton[0].id;
            this.arrSideMenuButton = [
                { id: 0, name: "包裹管理", icon: "code-sandbox" },
                { id: 1, name: "运单管理", icon: "snippets" },
                { id: 2, name: "用户管理", icon: "user" },
                { id: 3, name: "收件人", icon: "usergroup-add" }
            ];
        }
        if (iMenuID === 1) {
            iCurrentInterface = this.arrSideMenuButton[0].id;
            this.arrSideMenuButton = [
                { id: 0, name: "订单管理", icon: "snippets" },
                { id: 1, name: "用户管理", icon: "user" },
                { id: 2, name: "发件人", icon: "usergroup-add" },
                { id: 3, name: "收件人", icon: "usergroup-add" }
            ];

        }
        this.setState({
            ...this.state,
            bLoading: false,
            iCurrentTopBarIndex: iMenuID,
            iCurrentInterface: iCurrentInterface
        });
    }
    // 切换侧边栏菜单
    SwitchSideMenu = (iMenuID) => {
        this.setState({
            ...this.state,
            bLoading: false,
            bLogin: true,
            iCurrentInterface: iMenuID
        });
    }
    // 登录表单提交响应
    LoginSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                message.loading({ content: '登录中……', key: g_strMessageKey });
                this.setState({
                    ...this.state,
                    bLoading: true
                });
                this.TryLogin(values.username, values.password)
            }
        });
    };
    // 尝试登录
    TryLogin = (name, password) => {
        let objUserInfo = { name: name, password: password, type: 1 };
        if (name === 'lekon' || name === "Lekon") {
            message.error({ content: "不是有效账号，请重新登录！", key: g_strMessageKey, duration: 2 });
            this.setState({
                ...this.state,
                bLoading: false
            });
            return;
        }
        // console.log("try login : ", objUserInfo);
        axios('/StoreBackEndLogin', { params: objUserInfo })
            .then(({ data }) => {
                if (data.status === -1) {
                    message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
                    this.setState({
                        ...this.state,
                        bLoading: false
                    });
                } else {
                    message.success({ content: data.message, key: g_strMessageKey, duration: 2 });
                    console.log("user info : ", data);
                    this.iCurrentUserID = data.id;
                    this.strCurrentUserName = name;
                    this.strCurrentUserCode = data.code;
                    this.iCurrentUserTeam = data.team;
                    this.iCurrentUserLevel = parseInt(data.level, 10);
                    cookie.save('store_user_code', this.strCurrentUserCode, { path: '/' });
                    cookie.save('store_login_status', 1, { path: '/' });
                    this.GetStoreInfo();
                }
            }).catch(function (error) { console.log(error); });
    }
    // 退出登录
    LogoutClick = () => {
        global.arrItemTypeData = [];
        // DataStatistic
        // UserActiveUpdate(0, { active: "物流系统后台: 退出登录", user: this.strCurrentUserName });
        cookie.remove('store_user_code', { path: '/' });
        cookie.save('store_login_status', -1, { path: '/' });
        this.setState({
            ...this.state,
            bLogin: false
        });
    }
    // 获取当前登录的商家的基本信息
    GetStoreInfo = () => {
        axios('/GetUserRelevantStoreID', { params: { code: this.strCurrentUserCode, store_id: 10000 } })
            .then(({ data }) => {
                if (data.status === -1) {
                    message.warning(data.message);
                    this.setState({
                        ...this.state,
                        bLoading: false
                    });
                } else {
                    this.iCurrentUserID = data.user_id;
                    this.strCurrentUserName = data.user_name;
                    this.iCurrentUserLevel = data.user_level;
                    this.iCurrentUserTeam = data.team;
                    this.iCurrentClientID = data.client_id;

                    axios('/GetStoreInfo', { params: { id: data.store_id } })
                        .then(({ data }) => {
                            this.objStoreInfo = data[0];
                            let iNewInterface = 0;
                            if (this.iCurrentUserLevel < 9)
                                iNewInterface = 11;
                            if (this.iCurrentUserLevel >= 0) {
                                this.arrSideMenuButton = [
                                    { id: 0, name: "仪表盘" },
                                    {
                                        id: 1, name: "进口预报",
                                        sub: [{ id: 11, name: "全部" }, { id: 12, name: "整柜" }, { id: 13, name: "拼箱" }, { id: 14, name: "海派" }, { id: 15, name: "结单" }]
                                    },
                                    {
                                        id: 2, name: "派送列表",
                                        sub: [{ id: 21, name: "全部" }, { id: 22, name: "FTL" }, { id: 23, name: "LTL" }, { id: 24, name: "结单" }]
                                    },
                                    {
                                        id: 3, name: "联系信息",
                                        sub: [{ id: 30, name: "员工" }, { id: 31, name: "客户" }, { id: 32, name: "目的港代理" }, { id: 33, name: "拖车" }, { id: 34, name: "仓库" }, { id: 35, name: "车队" }]
                                    },
                                    { id: 4, name: "海关文件", icon: "audit" },
                                    {
                                        id: 7, name: "仓库管理",
                                        sub: [{ id: 70, name: "入库记录" }, { id: 71, name: "库存记录" }, { id: 72, name: "出库记录" }]
                                    },
                                ];
                                if (this.iCurrentUserLevel >= 9)
                                    this.arrSideMenuButton = [
                                        { id: 0, name: "仪表盘" },
                                        {
                                            id: 1, name: "进口预报",
                                            sub: [{ id: 11, name: "全部" }, { id: 12, name: "整柜" }, { id: 13, name: "拼箱" }, { id: 14, name: "海派" }, { id: 15, name: "结单" }]
                                        },
                                        {
                                            id: 2, name: "派送列表",
                                            sub: [{ id: 21, name: "全部" }, { id: 22, name: "FTL" }, { id: 23, name: "LTL" }, { id: 24, name: "结单" }]
                                        },
                                        {
                                            id: 3, name: "联系信息",
                                            sub: [{ id: 30, name: "员工" }, { id: 31, name: "客户" }, { id: 32, name: "目的港代理" }, { id: 33, name: "拖车" }, { id: 34, name: "仓库" }, { id: 35, name: "车队" }]
                                        },
                                        { id: 4, name: "海关文件", icon: "audit" },
                                        { id: 5, name: "预报单", icon: "notification" },
                                        { id: 6, name: "操作记录", icon: "email" },
                                        {
                                            id: 7, name: "仓库管理",
                                            sub: [{ id: 70, name: "入库记录" }, { id: 71, name: "库存记录" }, { id: 72, name: "出库记录" }/*, { id: 73, name: "货物管理" }*/]
                                        },
                                    ];
                                if (this.iCurrentUserLevel === 7) {
                                    this.arrSideMenuButton = [
                                        {
                                            id: 0, name: "仓库管理",
                                            sub: [{ id: 1, name: "入库记录" }, { id: 2, name: "库存记录" }, { id: 3, name: "出库记录" }/*, { id: 73, name: "货物管理" }*/]
                                        }
                                    ];
                                    iNewInterface = 1;
                                }

                            }
                            this.SwitchSideMenu(iNewInterface);
                        }).catch(function (error) { console.log(error); });
                }
            }).catch(function (error) { console.log(error); });
    }
    // 出单系统打开海关文件的回调函数
    AutoOrderToCustomFile = (iType, strOrderNo, iOrderType) => {
        console.log("AutoOrderToCustomFile : ", iType, strOrderNo, iOrderType);
        this.strTargetOrderNo = strOrderNo;
        this.iOrderType = iOrderType;
        this.SwitchSideMenu(4);
    }
    // 出单系统打开Invoice文件的回调函数
    AutoOrderToInvoiceFile = (iType, strOrderNo) => {
        this.strTargetOrderNo = strOrderNo;
        if (this.iCurrentUserLevel === 7)
            this.SwitchSideMenu(5);
        else
            this.SwitchSideMenu(10);
    }
    // 重置目标海关文件
    TargetOrderNoReset = () => {
        this.strTargetOrderNo = "";
        this.setState({
            ...this.state,
            bLoading: false
        })
    }
    // 海关文件返回出单系统
    CustomFileToAutoOrder = (strOrderNo, iOrderType) => {
        console.log("CustomFileToAutoOrder : ", iOrderType);
        switch (iOrderType) {
            case 0: this.SwitchSideMenu(11); break;
            case 2: this.SwitchSideMenu(12); break;
            case 3: this.SwitchSideMenu(13); break;
            case 7: this.SwitchSideMenu(15); break;
            default: break;
        }

    }
    // 海关文件返回出单系统
    InvoiceFileToAutoOrder = (strOrderNo) => {
        this.SwitchSideMenu(2);
    }
    //提交运单
    CreatePackageCombine = (arrExpressEnter) => {
        // console.log("CreatePackageCombine : ", arrExpressEnter);
        this.setState({
            ...this.state,
            iCurrentInterface: 1,
            arrExpressEnter: arrExpressEnter
        });
    }
    // 重置选中包裹信息
    ResetExpressOrderInfo = () => {
        this.setState({
            ...this.state,
            arrExpressEnter: []
        })
    }
    // 预报单和正式出单的跳转
    AutoOrderAndPrediction = (index, strOid) => {
        this.strTargetOrderNo = strOid;
        // 预报单跳转正式单
        if (index === 0) {
            this.SwitchSideMenu(2);
        }
        // 正式单跳转预报单
        if (index === 1) {
            this.SwitchSideMenu(111);
        }
    }
    // 目标单号重置
    TargetOrderNoReset = () => {
        this.strTargetOrderNo = "";
        this.setState({
            ...this.state,
            bLogin: this.state.bLogin
        })
    }
    // 切换账号
    // SwitchUserAccount = () => {
    //     console.log(' this.strCurrentUserName = ', this.strCurrentUserName, this.iCurrentUserLevel);
    //     if (this.strCurrentUserName.indexOf('zhihe') >= 0) {
    //         this.strCurrentUserName = 'xiyouji';
    //         this.iCurrentUserLevel = 10;
    //         this.RefreshStoreInfo(30000);
    //     } else {
    //         if (this.strCurrentUserName.indexOf('xiyouji') >= 0) {
    //             this.strCurrentUserName = 'zhihecn';
    //             this.iCurrentUserLevel = 6;
    //             this.RefreshStoreInfo(20000);
    //         }
    //     }
    // }
    // 刷新商家信息
    // RefreshStoreInfo = (iStoreID) => {
    //     axios('/GetStoreInfo', { params: { id: iStoreID } })
    //         .then(({ data }) => {
    //             this.objStoreInfo = data[0];
    //             let iNewInterface = this.state.iCurrentInterface;
    //             if (this.objStoreInfo.type === 5) {
    //                 iNewInterface = 0;
    //                 if (this.iCurrentUserLevel >= 9) {
    //                     this.arrSideMenuButton = [
    //                         { id: 0, name: "快递进仓", icon: "code-sandbox" },
    //                         { id: 1, name: "合箱管理", icon: "cluster" },
    //                         { id: 2, name: "出单系统", icon: "profile" },
    //                         { id: 3, name: '货运价格', icon: "dollar" },
    //                         { id: 4, name: "用户管理", icon: "user" },
    //                         { id: 5, name: '城市港口', icon: "global" },
    //                         { id: 6, name: "物流状态", icon: "car" },
    //                         { id: 7, name: "数据统计", icon: "rise" },
    //                         { id: 8, name: "备忘记录", icon: "snippets" },
    //                         { id: 9, name: "海关文件", icon: "audit" },
    //                         { id: 10, name: "发票文件", icon: "file-done" },
    //                         {
    //                             id: 11, name: "预报", icon: "notification",
    //                             sub: [{ id: 110, name: "客户" }, { id: 111, name: "预报单" }]
    //                         },
    //                     ];
    //                 } else {
    //                     if (this.iCurrentUserLevel >= 6) {
    //                         this.arrSideMenuButton = [
    //                             { id: 0, name: "快递进仓", icon: "code-sandbox" },
    //                             { id: 1, name: "合箱管理", icon: "cluster" },
    //                             { id: 2, name: "出单系统", icon: "profile" },
    //                             { id: 3, name: '货运价格', icon: "dollar" }
    //                         ];
    //                         if (this.iCurrentUserLevel >= 7) {
    //                             this.arrSideMenuButton = [
    //                                 { id: 0, name: "快递进仓", icon: "code-sandbox" },
    //                                 { id: 1, name: "合箱管理", icon: "cluster" },
    //                                 { id: 2, name: "出单系统", icon: "profile" },
    //                                 { id: 3, name: '货运价格', icon: "dollar" },
    //                                 { id: 4, name: "海关文件", icon: "audit" },
    //                                 { id: 5, name: "发票文件", icon: "file-done" },
    //                                 {
    //                                     id: 11, name: "预报", icon: "notification",
    //                                     sub: [{ id: 110, name: "客户" }, { id: 111, name: "预报单" }]
    //                                 }
    //                             ];
    //                         }
    //                         if (this.objStoreInfo.id === 20000)
    //                             this.arrSideMenuButton = [
    //                                 { id: 0, name: "快递进仓", icon: "code-sandbox" },
    //                                 { id: 1, name: "合箱管理", icon: "cluster" },
    //                                 { id: 2, name: "出单系统", icon: "profile" },
    //                                 { id: 3, name: '货运价格', icon: "dollar" },
    //                                 { id: 4, name: "用户管理", icon: "user" },
    //                                 {
    //                                     id: 11, name: "预报", icon: "notification",
    //                                     sub: [{ id: 110, name: "客户" }, { id: 111, name: "预报单" }]
    //                                 }
    //                             ];
    //                     } else {
    //                         this.arrSideMenuButton = [
    //                             { id: 0, name: "快递进仓", icon: "code-sandbox" },
    //                             { id: 1, name: "海运预报", icon: "profile" },
    //                         ];
    //                     }
    //                 }
    //             } else {
    //                 this.arrSideMenuButton = [];
    //                 if (this.strCurrentUserName.indexOf('_a') >= 0) {
    //                     this.arrSideMenuButton = [
    //                         { id: 0, name: "商品管理", icon: "gift" }
    //                     ];
    //                 } else {
    //                     this.arrSideMenuButton = [
    //                         { id: 0, name: "数据统计", icon: "rise" },
    //                         { id: 1, name: "商家信息", icon: "shop" },
    //                         { id: 2, name: "宣传图片", icon: "picture" },
    //                         { id: 3, name: "商品管理", icon: "gift" },
    //                         { id: 4, name: "类别标签", icon: "appstore" },
    //                         { id: 5, name: "优惠设置", icon: "tag" },
    //                         { id: 6, name: "订单列表", icon: "profile" },
    //                         { id: 7, name: "运费计算", icon: "dollar" }
    //                     ];
    //                 }
    //                 if (this.state.iCurrentInterface > 6 || this.state.iCurrentInterface >= this.arrSideMenuButton.length)
    //                     iNewInterface = 0;
    //             }
    //             // 西邮寄账号
    //             if (this.objStoreInfo.id === 30000) {
    //                 this.arrSideMenuButton = [
    //                     { id: 0, name: "包裹管理", icon: "code-sandbox" },
    //                     { id: 1, name: "运单管理", icon: "snippets" },
    //                     { id: 2, name: "用户管理", icon: "user" },
    //                     { id: 3, name: "收件人", icon: "usergroup-add" }
    //                 ];
    //                 if (this.state.iCurrentTopBarIndex === 1)
    //                     this.arrSideMenuButton = [
    //                         { id: 0, name: "订单管理", icon: "snippets" },
    //                         { id: 1, name: "用户管理", icon: "user" },
    //                         { id: 2, name: "发件人", icon: "usergroup-add" },
    //                         { id: 3, name: "收件人", icon: "usergroup-add" }
    //                     ];
    //                 document.title = "西邮寄物流";
    //             }
    //             this.SwitchSideMenu(iNewInterface);
    //         }).catch(function (error) { console.log(error); });
    // }
    // 跳转到主页
    JumpToWebsite = () => {
        console.log("jump to lekon");
        window.location.href = 'https://www.mouthing.info/lekon';
    }
    // 渲染完毕……
    componentDidMount() {
        let strUserCode = cookie.load('store_user_code');
        let iUserLoginStatus = cookie.load('store_login_status');
        if ((iUserLoginStatus === 1 || iUserLoginStatus === '1') && strUserCode !== undefined) {
            this.strCurrentUserCode = strUserCode;
            this.GetStoreInfo();
        } else {
            this.setState({
                ...this.state,
                bLoading: false,
                bLogin: false
            });
        }
        document.title = "Logistics System";
    }

    render() {
        const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;
        // Only show error after a field is touched.
        const usernameError = isFieldTouched('username') && getFieldError('username');
        const passwordError = isFieldTouched('password') && getFieldError('password');
        // 登录区域
        let strTitle = 'Welcome, ' + this.strCurrentUserName;
        let uiLogInForm = (
            <Form className="manage_login_form" layout="inline" onSubmit={this.LoginSubmit}>
                <Form.Item validateStatus={usernameError ? 'error' : ''} help={usernameError || ''}>
                    {getFieldDecorator('username')(
                        <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="用户名" />,
                    )}
                </Form.Item>
                <Form.Item validateStatus={passwordError ? 'error' : ''} help={passwordError || ''}>
                    {getFieldDecorator('password')(
                        <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                            type="password" placeholder="密码" />,
                    )}
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" disabled={hasErrors(getFieldsError())}>
                        登 陆
                    </Button>
                </Form.Item>
            </Form>
        );
        let uiStoreInfo = <div className="store_backend_title" />
        // 侧边栏
        let uiSideBar = <div />;
        // 顶部栏
        let uiLogo = <div className="store_manage_logo" >物流系统</div>;
        if (this.bMStation)
            uiLogo = null;
        let uiNavBar = (
            <div className="manage_top_bar">
                {uiLogo}
                <div className="manage_top_bar_menu">

                </div>
                {uiLogInForm}
            </div>
        );
        if (this.state.bLogin) {
            uiStoreInfo = (
                <div className="store_backend_title">
                    Lekon Logistics
                </div>
            );
            uiLogInForm = (
                <div className="manage_login_form">
                    <div className="welcome_back_title">{strTitle}</div>
                    <Button className="logout_btn" type="primary" htmlType="submit"
                        onClick={this.LogoutClick}>退出登录</Button>
                </div>
            );
            let arrDefaultOpenMenu = ['0'];
            if (this.iCurrentUserLevel < 9) {
                arrDefaultOpenMenu = ['1'];
                if (this.iCurrentUserLevel === 7)
                    arrDefaultOpenMenu = ['0'];
                if (this.iCurrentUserLevel === 1)
                    arrDefaultOpenMenu = ['1', '7'];
            }

            uiSideBar = (
                <Menu className="logistics_side_bar" defaultSelectedKeys={[this.state.iCurrentInterface.toString()]}
                    selectedKeys={[this.state.iCurrentInterface.toString()]} defaultOpenKeys={arrDefaultOpenMenu}
                    mode="inline" theme="dark" inlineCollapsed={this.state.collapsed}  >
                    {this.arrSideMenuButton.map((menu, index) => {
                        let uiMenuItem = (
                            <Menu.Item key={menu.id} onClick={this.SwitchSideMenu.bind(this, menu.id)} >
                                {/* <Icon type={menu.icon} /> */}{menu.name}
                            </Menu.Item>
                        );
                        if (this.iCurrentUserLevel === 7) {
                            uiMenuItem = (
                                <SubMenu key={menu.id} title={<span>{menu.name}</span>} >
                                    {menu.sub.map((sub, sub_index) => {
                                        return (
                                            <Menu.Item key={sub.id} onClick={this.SwitchSideMenu.bind(this, sub.id)}>
                                                {sub.name}
                                            </Menu.Item>
                                        );
                                    })}
                                </SubMenu>
                            );
                            return (uiMenuItem);
                        }
                        else {
                            if ((this.iCurrentUserLevel < 5 && (menu.id >= 2) && (menu.id !== 7)) || (this.iCurrentUserLevel < 9 && (index === 0) && (menu.id !== 7)))
                                return null;
                            else {
                                if (menu.sub) {
                                    uiMenuItem = (
                                        <SubMenu key={menu.id} title={<span>{menu.name}</span>} >
                                            {menu.sub.map((sub, sub_index) => {
                                                switch (this.iCurrentUserLevel) {
                                                    case 1: {
                                                        if ((index === 1 && sub_index === 3))
                                                            return null;
                                                        else
                                                            return (
                                                                <Menu.Item key={sub.id} onClick={this.SwitchSideMenu.bind(this, sub.id)}>
                                                                    {sub.name}
                                                                </Menu.Item>
                                                            );
                                                    }
                                                    case 5: {
                                                        if ((index === 1 && sub_index === 4) || (index === 2 && sub_index === 3) || (index === 3 && sub_index === 0))
                                                            return null;
                                                        else
                                                            return (
                                                                <Menu.Item key={sub.id} onClick={this.SwitchSideMenu.bind(this, sub.id)}>
                                                                    {sub.name}
                                                                </Menu.Item>
                                                            );
                                                    }
                                                    case 6: {
                                                        if ((index === 3 && sub_index === 0)) {
                                                            return null;
                                                        }
                                                        else
                                                            return (
                                                                <Menu.Item key={sub.id} onClick={this.SwitchSideMenu.bind(this, sub.id)}>
                                                                    {sub.name}
                                                                </Menu.Item>
                                                            );
                                                    }
                                                    case 9: {
                                                        return (
                                                            <Menu.Item key={sub.id} onClick={this.SwitchSideMenu.bind(this, sub.id)}>
                                                                {sub.name}
                                                            </Menu.Item>
                                                        );
                                                    }
                                                    default: {
                                                        break;
                                                    }
                                                }
                                                return null;
                                            })}
                                        </SubMenu>
                                    );

                                }
                                return (uiMenuItem);
                            }
                        }

                    })}
                </Menu>
            );
            let uiMainTitle = (
                <div className="logistics_logo" onClick={this.JumpToWebsite}>
                    <img className="lekon_logo" alt=''
                        src={"https://res.cloudinary.com/jingtu-ego-test/image/upload/v1609136102/0-%E9%B2%B8%E5%9B%BEWhaleMap/LekonLogo_t6own9.png"} />
                </div>
            );
            uiNavBar = (
                <div className="manage_top_bar">
                    {uiMainTitle}
                    {/* {uiNavBarMenu} */}
                    {uiStoreInfo}
                    {uiLogInForm}
                </div>
            );
        }
        let uiCentralArea;
        // 是否还在加载中……
        if (this.state.bLoading) {
            uiCentralArea = (<Spin className="manage_empty_loading" size="large" tip="加载中……" />);
        } else {
            if (this.state.bLogin) {
                let uiStatisticData = null;
                // 进口预报
                let uiAutoOrderSub0 = null;  // 全部
                let uiAutoOrderSub1 = null;  // 整柜
                let uiAutoOrderSub2 = null;  // 拼箱
                let uiAutoOrderSub3 = null;  // 海派
                let uiAutoOrderSub4 = null;  // 结单
                // 派送列表
                let uiDeliverSub0 = null;  // 全部
                let uiDeliverSub1 = null;  // FTL
                let uiDeliverSub2 = null;  // LTL
                let uiDeliverSub3 = null;  // 结单
                // 联系信息
                let uiUserManager = null;
                let uiClientManager = null;
                let uiAgentManager = null;
                let uiTruckManager = null;
                let uiStorageManager = null;
                let uiCarTeamManager = null;
                // 海关文件
                let uiCustomsFile = null;
                // 操作记录
                let uiActionRecord = null;
                // 预报单
                let uiPredictOrder = null;
                // 仓库管理
                let uiStorageIn = null;
                let uiStorageInventory = null;
                let uiStorageOut = null;
                // let uiStorageItem = null;


                // 仓库管理专员
                if (this.iCurrentUserLevel === 7) {
                    uiStatisticData = null;
                    // 进口预报
                    uiAutoOrderSub0 = null;  // 全部
                    uiAutoOrderSub1 = null;  // 整柜
                    uiAutoOrderSub2 = null;  // 拼箱
                    uiAutoOrderSub3 = null;  // 海派
                    uiAutoOrderSub4 = null;  // 结单
                    // 派送列表
                    uiDeliverSub0 = null;  // 全部
                    uiDeliverSub1 = null;  // FTL
                    uiDeliverSub2 = null;  // LTL
                    uiDeliverSub3 = null;  // 结单
                    // 联系信息
                    uiUserManager = null;
                    uiClientManager = null;
                    uiAgentManager = null;
                    uiTruckManager = null;
                    uiStorageManager = null;
                    uiCarTeamManager = null;
                    // 海关文件
                    uiCustomsFile = null;
                    // 操作记录
                    uiActionRecord = null;
                    // 预报单
                    uiPredictOrder = null;
                    // 仓库管理
                    uiStorageIn = (
                        this.state.iCurrentInterface === this.arrSideMenuButton[0].sub[0].id && <LogisticStorageIn objStoreInfo={this.objStoreInfo}
                            iStoreID={this.objStoreInfo.id} iUserLevel={this.iCurrentUserLevel} strUserName={this.strCurrentUserName} iUserID={this.iCurrentUserID} iUserTeam={this.iCurrentUserTeam} iClientID={this.iCurrentClientID} />
                    );
                    uiStorageInventory = (
                        this.state.iCurrentInterface === this.arrSideMenuButton[0].sub[1].id && <LogisticStorageInventory objStoreInfo={this.objStoreInfo}
                            iStoreID={this.objStoreInfo.id} iUserLevel={this.iCurrentUserLevel} strUserName={this.strCurrentUserName} iUserID={this.iCurrentUserID} iUserTeam={this.iCurrentUserTeam} iClientID={this.iCurrentClientID} />
                    );
                    uiStorageOut = (
                        this.state.iCurrentInterface === this.arrSideMenuButton[0].sub[2].id && <LogisticStorageOut objStoreInfo={this.objStoreInfo}
                            iStoreID={this.objStoreInfo.id} iUserLevel={this.iCurrentUserLevel} strUserName={this.strCurrentUserName} iUserID={this.iCurrentUserID} iUserTeam={this.iCurrentUserTeam} iClientID={this.iCurrentClientID} />
                    );
                }
                // 其余情况
                else {
                    uiStatisticData = (
                        this.state.iCurrentInterface === this.arrSideMenuButton[0].id &&
                        <LogisticDataPanel bStore={true} iStoreID={this.objStoreInfo.id} bMStation={this.bMStation} />
                    );
                    // 全部
                    uiAutoOrderSub0 = (
                        this.state.iCurrentInterface === this.arrSideMenuButton[1].sub[0].id && <LogisticOrder objStoreInfo={this.objStoreInfo}
                            iUserLevel={this.iCurrentUserLevel} bMStation={this.bMStation} AutoOrderAndPrediction={this.AutoOrderAndPrediction}
                            AutoOrderToCustomFile={this.AutoOrderToCustomFile} AutoOrderToInvoiceFile={this.AutoOrderToInvoiceFile}
                            strTargetOrderNo={this.strTargetOrderNo} TargetOrderNoReset={this.TargetOrderNoReset} iType={0} bImport={true}
                            strUserName={this.strCurrentUserName} iUserID={this.iCurrentUserID} iUserTeam={this.iCurrentUserTeam}
                            iClientID={this.iCurrentClientID} />
                    );
                    // 整柜
                    uiAutoOrderSub1 = (
                        this.state.iCurrentInterface === this.arrSideMenuButton[1].sub[1].id && <LogisticOrder objStoreInfo={this.objStoreInfo}
                            iUserLevel={this.iCurrentUserLevel} bMStation={this.bMStation} AutoOrderAndPrediction={this.AutoOrderAndPrediction}
                            AutoOrderToCustomFile={this.AutoOrderToCustomFile} AutoOrderToInvoiceFile={this.AutoOrderToInvoiceFile}
                            strTargetOrderNo={this.strTargetOrderNo} TargetOrderNoReset={this.TargetOrderNoReset} iType={2} bImport={true}
                            strUserName={this.strCurrentUserName} iUserID={this.iCurrentUserID} iUserTeam={this.iCurrentUserTeam}
                            iClientID={this.iCurrentClientID} />
                    );
                    // 拼箱
                    uiAutoOrderSub2 = (
                        this.state.iCurrentInterface === this.arrSideMenuButton[1].sub[2].id && <LogisticOrder objStoreInfo={this.objStoreInfo}
                            iUserLevel={this.iCurrentUserLevel} bMStation={this.bMStation} AutoOrderAndPrediction={this.AutoOrderAndPrediction}
                            AutoOrderToCustomFile={this.AutoOrderToCustomFile} AutoOrderToInvoiceFile={this.AutoOrderToInvoiceFile}
                            strTargetOrderNo={this.strTargetOrderNo} TargetOrderNoReset={this.TargetOrderNoReset} iType={3} bImport={true}
                            strUserName={this.strCurrentUserName} iUserID={this.iCurrentUserID} iUserTeam={this.iCurrentUserTeam}
                            iClientID={this.iCurrentClientID} />
                    );
                    // 海派
                    uiAutoOrderSub3 = (
                        this.state.iCurrentInterface === this.arrSideMenuButton[1].sub[3].id && <LogisticOrder objStoreInfo={this.objStoreInfo}
                            iUserLevel={this.iCurrentUserLevel} bMStation={this.bMStation} AutoOrderAndPrediction={this.AutoOrderAndPrediction}
                            AutoOrderToCustomFile={this.AutoOrderToCustomFile} AutoOrderToInvoiceFile={this.AutoOrderToInvoiceFile}
                            strTargetOrderNo={this.strTargetOrderNo} TargetOrderNoReset={this.TargetOrderNoReset} iType={5} bImport={true}
                            strUserName={this.strCurrentUserName} iUserID={this.iCurrentUserID} iUserTeam={this.iCurrentUserTeam} iClientID={this.iCurrentClientID} />
                    );
                    // 已结单
                    if (this.iCurrentUserLevel !== 5)
                        uiAutoOrderSub4 = (
                            this.state.iCurrentInterface === this.arrSideMenuButton[1].sub[4].id && <LogisticOrder objStoreInfo={this.objStoreInfo}
                                iUserLevel={this.iCurrentUserLevel} bMStation={this.bMStation} AutoOrderAndPrediction={this.AutoOrderAndPrediction}
                                AutoOrderToCustomFile={this.AutoOrderToCustomFile} AutoOrderToInvoiceFile={this.AutoOrderToInvoiceFile}
                                strTargetOrderNo={this.strTargetOrderNo} TargetOrderNoReset={this.TargetOrderNoReset} iType={7} bImport={true}
                                strUserName={this.strCurrentUserName} iUserID={this.iCurrentUserID} iUserTeam={this.iCurrentUserTeam} iClientID={this.iCurrentClientID} />
                        );
                    // 全部
                    uiDeliverSub0 = (
                        this.state.iCurrentInterface === this.arrSideMenuButton[2].sub[0].id && <LogisticOrder objStoreInfo={this.objStoreInfo}
                            iUserLevel={this.iCurrentUserLevel} bMStation={this.bMStation} AutoOrderAndPrediction={this.AutoOrderAndPrediction}
                            AutoOrderToCustomFile={this.AutoOrderToCustomFile} AutoOrderToInvoiceFile={this.AutoOrderToInvoiceFile}
                            strTargetOrderNo={this.strTargetOrderNo} TargetOrderNoReset={this.TargetOrderNoReset} iType={10} bImport={true}
                            strUserName={this.strCurrentUserName} iUserID={this.iCurrentUserID} iUserTeam={this.iCurrentUserTeam} iClientID={this.iCurrentClientID} />
                    );
                    // FTL
                    uiDeliverSub1 = (
                        this.state.iCurrentInterface === this.arrSideMenuButton[2].sub[1].id && <LogisticOrder objStoreInfo={this.objStoreInfo}
                            iUserLevel={this.iCurrentUserLevel} bMStation={this.bMStation} AutoOrderAndPrediction={this.AutoOrderAndPrediction}
                            AutoOrderToCustomFile={this.AutoOrderToCustomFile} AutoOrderToInvoiceFile={this.AutoOrderToInvoiceFile}
                            strTargetOrderNo={this.strTargetOrderNo} TargetOrderNoReset={this.TargetOrderNoReset} iType={11} bImport={true}
                            strUserName={this.strCurrentUserName} iUserID={this.iCurrentUserID} iUserTeam={this.iCurrentUserTeam} iClientID={this.iCurrentClientID} />
                    );
                    // LTL
                    uiDeliverSub2 = (
                        this.state.iCurrentInterface === this.arrSideMenuButton[2].sub[2].id && <LogisticOrder objStoreInfo={this.objStoreInfo}
                            iUserLevel={this.iCurrentUserLevel} bMStation={this.bMStation} AutoOrderAndPrediction={this.AutoOrderAndPrediction}
                            AutoOrderToCustomFile={this.AutoOrderToCustomFile} AutoOrderToInvoiceFile={this.AutoOrderToInvoiceFile}
                            strTargetOrderNo={this.strTargetOrderNo} TargetOrderNoReset={this.TargetOrderNoReset} iType={12} bImport={true}
                            strUserName={this.strCurrentUserName} iUserID={this.iCurrentUserID} iUserTeam={this.iCurrentUserTeam} iClientID={this.iCurrentClientID} />
                    );
                    // 结单
                    if (this.iCurrentUserLevel !== 5)
                        uiDeliverSub3 = (
                            this.state.iCurrentInterface === this.arrSideMenuButton[2].sub[3].id && <LogisticOrder objStoreInfo={this.objStoreInfo}
                                iUserLevel={this.iCurrentUserLevel} bMStation={this.bMStation} AutoOrderAndPrediction={this.AutoOrderAndPrediction}
                                AutoOrderToCustomFile={this.AutoOrderToCustomFile} AutoOrderToInvoiceFile={this.AutoOrderToInvoiceFile}
                                strTargetOrderNo={this.strTargetOrderNo} TargetOrderNoReset={this.TargetOrderNoReset} iType={13} bImport={true}
                                strUserName={this.strCurrentUserName} iUserID={this.iCurrentUserID} iUserTeam={this.iCurrentUserTeam} iClientID={this.iCurrentClientID} />
                        );
                    // 用户
                    if (this.iCurrentUserLevel >= 9)
                        uiUserManager = (
                            this.state.iCurrentInterface === this.arrSideMenuButton[3].sub[0].id && <LogisticUserManager iStoreID={this.objStoreInfo.id}
                                iUserLevel={this.iCurrentUserLevel} bMStation={this.bMStation} />
                        );
                    uiClientManager = (
                        this.state.iCurrentInterface === this.arrSideMenuButton[3].sub[1].id && <LogisticClientManager iStoreID={this.objStoreInfo.id}
                            iUserLevel={this.iCurrentUserLevel} bMStation={this.bMStation} />
                    );
                    uiAgentManager = (
                        this.state.iCurrentInterface === this.arrSideMenuButton[3].sub[2].id && <LogisticAgentManager iStoreID={this.objStoreInfo.id}
                            iUserLevel={this.iCurrentUserLevel} bMStation={this.bMStation} />
                    );
                    uiTruckManager = (
                        this.state.iCurrentInterface === this.arrSideMenuButton[3].sub[3].id && <LogisticTruckManager iStoreID={this.objStoreInfo.id}
                            iUserLevel={this.iCurrentUserLevel} bMStation={this.bMStation} />
                    );
                    uiStorageManager = (
                        this.state.iCurrentInterface === this.arrSideMenuButton[3].sub[4].id && <LogisticStorageManager iStoreID={this.objStoreInfo.id}
                            iUserLevel={this.iCurrentUserLevel} bMStation={this.bMStation} />
                    );
                    uiCarTeamManager = (
                        this.state.iCurrentInterface === this.arrSideMenuButton[3].sub[5].id && <LogisticCarTeamManager iStoreID={this.objStoreInfo.id}
                            iUserLevel={this.iCurrentUserLevel} bMStation={this.bMStation} />
                    );
                    // 海关文件
                    uiCustomsFile = (
                        this.state.iCurrentInterface === this.arrSideMenuButton[4].id && <LogisticCustomsFile objStoreInfo={this.objStoreInfo}
                            iUserLevel={this.iCurrentUserLevel} bMStation={this.bMStation} strTargetOrderNo={this.strTargetOrderNo} iOrderType={this.iOrderType}
                            TargetOrderNoReset={this.TargetOrderNoReset} CustomFileToAutoOrder={this.CustomFileToAutoOrder} />
                    );
                    // 预报单
                    uiPredictOrder = (
                        this.state.iCurrentInterface === this.arrSideMenuButton[5].id && <LogistisPredictionOrder objStoreInfo={this.objStoreInfo}
                            iUserLevel={this.iCurrentUserLevel} bMStation={this.bMStation} strTargetOrderNo={this.strTargetOrderNo} iOrderType={this.iOrderType}
                            TargetOrderNoReset={this.TargetOrderNoReset} CustomFileToAutoOrder={this.CustomFileToAutoOrder} />
                    );


                    if (this.iCurrentUserLevel >= 9) {
                        // 操作记录
                        uiActionRecord = (
                            this.state.iCurrentInterface === this.arrSideMenuButton[6].id && <LogisticActionManager objStoreInfo={this.objStoreInfo}
                                iUserLevel={this.iCurrentUserLevel} bMStation={this.bMStation} strTargetOrderNo={this.strTargetOrderNo} iOrderType={this.iOrderType}
                                TargetOrderNoReset={this.TargetOrderNoReset} CustomFileToAutoOrder={this.CustomFileToAutoOrder} />
                        );

                        // 仓库管理
                        uiStorageIn = (
                            this.state.iCurrentInterface === this.arrSideMenuButton[7].sub[0].id && <LogisticStorageIn objStoreInfo={this.objStoreInfo} iClientID={this.iCurrentClientID}
                                iStoreID={this.objStoreInfo.id} iUserLevel={this.iCurrentUserLevel} strUserName={this.strCurrentUserName} iUserID={this.iCurrentUserID} iUserTeam={this.iCurrentUserTeam} />
                        );
                        uiStorageInventory = (
                            this.state.iCurrentInterface === this.arrSideMenuButton[7].sub[1].id && <LogisticStorageInventory objStoreInfo={this.objStoreInfo} iClientID={this.iCurrentClientID}
                                iStoreID={this.objStoreInfo.id} iUserLevel={this.iCurrentUserLevel} strUserName={this.strCurrentUserName} iUserID={this.iCurrentUserID} iUserTeam={this.iCurrentUserTeam} />
                        );
                        uiStorageOut = (
                            this.state.iCurrentInterface === this.arrSideMenuButton[7].sub[2].id && <LogisticStorageOut objStoreInfo={this.objStoreInfo} iClientID={this.iCurrentClientID}
                                iStoreID={this.objStoreInfo.id} iUserLevel={this.iCurrentUserLevel} strUserName={this.strCurrentUserName} iUserID={this.iCurrentUserID} iUserTeam={this.iCurrentUserTeam} />
                        );
                        // uiStorageItem = (
                        //     this.state.iCurrentInterface === this.arrSideMenuButton[7].sub[3].id && <LogisticStorageItem objStoreInfo={this.objStoreInfo}
                        //         iStoreID={this.objStoreInfo.id} iUserLevel={this.iCurrentUserLevel} strUserName={this.strCurrentUserName} iUserID={this.iCurrentUserID} iUserTeam={this.iCurrentUserTeam} />
                        // );
                    } else {
                        // 仓库管理
                        uiStorageIn = (
                            this.state.iCurrentInterface === this.arrSideMenuButton[5].sub[0].id && <LogisticStorageIn objStoreInfo={this.objStoreInfo} iClientID={this.iCurrentClientID}
                                iStoreID={this.objStoreInfo.id} iUserLevel={this.iCurrentUserLevel} strUserName={this.strCurrentUserName} iUserID={this.iCurrentUserID} iUserTeam={this.iCurrentUserTeam} />
                        );
                        uiStorageInventory = (
                            this.state.iCurrentInterface === this.arrSideMenuButton[5].sub[1].id && <LogisticStorageInventory objStoreInfo={this.objStoreInfo} iClientID={this.iCurrentClientID}
                                iStoreID={this.objStoreInfo.id} iUserLevel={this.iCurrentUserLevel} strUserName={this.strCurrentUserName} iUserID={this.iCurrentUserID} iUserTeam={this.iCurrentUserTeam} />
                        );
                        uiStorageOut = (
                            this.state.iCurrentInterface === this.arrSideMenuButton[5].sub[2].id && <LogisticStorageOut objStoreInfo={this.objStoreInfo} iClientID={this.iCurrentClientID}
                                iStoreID={this.objStoreInfo.id} iUserLevel={this.iCurrentUserLevel} strUserName={this.strCurrentUserName} iUserID={this.iCurrentUserID} iUserTeam={this.iCurrentUserTeam} />
                        );
                    }
                }

                uiCentralArea = (
                    <div>
                        {/* 0. 商家数据概况 */}
                        {uiStatisticData}
                        {/* 1 进口预报 */}
                        {uiAutoOrderSub0}
                        {uiAutoOrderSub1}
                        {uiAutoOrderSub2}
                        {uiAutoOrderSub3}
                        {uiAutoOrderSub4}
                        {/* 2 派送列表 */}
                        {uiDeliverSub0}
                        {uiDeliverSub1}
                        {uiDeliverSub2}
                        {uiDeliverSub3}
                        {/* 3 联系信息 */}
                        {uiUserManager}
                        {uiClientManager}
                        {uiAgentManager}
                        {uiTruckManager}
                        {uiStorageManager}
                        {uiCarTeamManager}
                        {/* 4. 海关文件 */}
                        {uiCustomsFile}
                        {/* 5. 操作记录 */}
                        {uiActionRecord}
                        {/* 6. 预报单 */}
                        {uiPredictOrder}
                        {/* 7. 仓库管理 */}
                        {uiStorageIn}
                        {uiStorageInventory}
                        {uiStorageOut}
                        {/* {uiStorageItem} */}
                    </div>
                );

            } else {
                uiCentralArea = (<Empty className="manage_empty_loading"
                    description={<span>未登录，无法使用</span>} />);
            }
        }

        return (
            <div className="manage_page">
                {/* 顶部菜单栏 */}
                {uiNavBar}
                {/* 侧边菜单栏 */}
                {uiSideBar}
                {/* 中心区域内容 */}
                {uiCentralArea}
            </div >
        );
    }
}
export default Form.create()(Logistics);
