import React, { Component } from "react";
import axios from 'axios';
import cookie from 'react-cookies'

import Empty from 'antd/es/empty';
import Spin from 'antd/es/spin';
import Menu from 'antd/es/menu';
import Icon from 'antd/es/icon';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import message from 'antd/es/message';

import TruckingDataPanel from "./ManagerPage/TruckingDataPanel.js";
import JetonTruckingOrder from "./ManagerPage/JetonTruckingOrder.js";
import LogisticClientManager from "./ManagerPage/LogisticClientManager.js";

import LogisticCarTeamManager from "./ManagerPage/LogisticCarTeamManager.js";
import AddressBookManager from "./ManagerPage/AddressBookManager.js";

import { deviceType } from "react-device-detect";

import "../../styles/Background/ego_manager.css";
import "../../styles/Background/store_manager.css";

const { SubMenu } = Menu;
const g_strMessageKey = 'updating';

function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class Trucking extends Component {
    // 构造函数
    constructor(props) {
        super();
        this.state = {
            bLoading: true,  // 加载状态
            bLogin: false,  // 登录状态
            iCurrentInterface: 0,  //当前显示的中心区域内容ID
            iCurrentTopBarIndex: 0,
            arrExpressEnter: []
        };
        this.arrSideMenuButton = [
            { id: 0, name: "仪表盘" },
            {
                id: 1, name: "派送列表",
                sub: [{ id: 10, name: "全部" }, { id: 11, name: "FTL" }, { id: 12, name: "LTL" }, { id: 13, name: "结单" }]
            },
            {
                id: 2, name: "联系信息",
                sub: [{ id: 20, name: "司机" }, { id: 21, name: "客户" }, { id: 22, name: "地址簿" }]
            }
        ];
        this.iCurrentUserID = 0;
        this.iCurrentUserTeam = -1;
        this.strCurrentUserName = '';
        this.strCurrentUserCode = '';
        this.iCurrentUserLevel = 0;
        this.objStoreInfo = { store_name: '' };

        let strDevice = deviceType;
        if (strDevice === "mobile")
            this.bMStation = true;
        else
            this.bMStation = false;

        this.strTargetOrderNo = "";
        this.strCurrentOid = "";

        this.iAccountSwitch = -1;
        this.iOrderType = 0;  // 海关文件返回专用

        this.iCurrentClientID = -1;
    }

    // 切换侧边栏菜单
    SwitchSideMenu = (iMenuID) => {
        // console.log("SwitchSideMenu: " + iMenuID);
        this.setState({
            ...this.state,
            bLoading: false,
            bLogin: true,
            iCurrentInterface: iMenuID
        });
    }
    // 登录表单提交响应
    LoginSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                message.loading({ content: '登录中……', key: g_strMessageKey });
                this.setState({
                    ...this.state,
                    bLoading: true
                });
                this.TryLogin(values.username, values.password)
            }
        });
    };
    // 尝试登录
    TryLogin = (name, password) => {
        let objUserInfo = { name: name, password: password, type: 1 };
        if (name === 'Trucking' || name === "trucking") {
            axios('/StoreBackEndLogin', { params: objUserInfo })
                .then(({ data }) => {
                    if (data.status === -1) {
                        message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
                        this.setState({
                            ...this.state,
                            bLoading: false
                        });
                    } else {
                        message.success({ content: data.message, key: g_strMessageKey, duration: 2 });
                        console.log("user info : ", data);
                        this.iCurrentUserID = data.id;
                        this.strCurrentUserName = name;
                        this.strCurrentUserCode = data.code;
                        this.iCurrentUserTeam = data.team;
                        this.iCurrentUserLevel = parseInt(data.level, 10);
                        cookie.save('store_user_code', this.strCurrentUserCode, { path: '/' });
                        cookie.save('store_login_status', 1, { path: '/' });
                        this.GetStoreInfo();
                    }
                }).catch(function (error) { console.log(error); });
        }
        else {
            message.error({ content: "不是有效账号，请重新登录！", key: g_strMessageKey, duration: 2 });
            this.setState({
                ...this.state,
                bLoading: false
            });
        }

    }
    // 退出登录
    LogoutClick = () => {
        global.arrItemTypeData = [];
        // DataStatistic
        // UserActiveUpdate(0, { active: "物流系统后台: 退出登录", user: this.strCurrentUserName });
        cookie.remove('store_user_code', { path: '/' });
        cookie.save('store_login_status', -1, { path: '/' });
        this.setState({
            ...this.state,
            bLogin: false
        });
    }
    // 获取当前登录的商家的基本信息
    GetStoreInfo = () => {
        axios('/GetUserRelevantStoreID', { params: { code: this.strCurrentUserCode, store_id: 999 } })
            .then(({ data }) => {
                if (data.status === -1) {
                    message.warning(data.message);
                    this.setState({
                        ...this.state,
                        bLoading: false
                    });
                } else {
                    this.iCurrentUserID = data.user_id;
                    this.strCurrentUserName = data.user_name;
                    this.iCurrentUserLevel = data.user_level;
                    this.iCurrentUserTeam = data.team;
                    this.iCurrentClientID = data.client_id;

                    axios('/GetStoreInfo', { params: { id: data.store_id } })
                        .then(({ data }) => {
                            // console.log("store info : ", data[0]);
                            this.objStoreInfo = data[0];
                            let iNewInterface = 0;
                            this.SwitchSideMenu(iNewInterface);
                        }).catch(function (error) { console.log(error); });
                }
            }).catch(function (error) { console.log(error); });
    }
    // 出单系统打开海关文件的回调函数
    AutoOrderToCustomFile = (iType, strOrderNo, iOrderType) => {
        console.log("AutoOrderToCustomFile : ", iType, strOrderNo, iOrderType);
        this.strTargetOrderNo = strOrderNo;
        this.iOrderType = iOrderType;
        this.SwitchSideMenu(4);
    }
    // 出单系统打开Invoice文件的回调函数
    AutoOrderToInvoiceFile = (iType, strOrderNo) => {
        this.strTargetOrderNo = strOrderNo;
        if (this.iCurrentUserLevel === 7)
            this.SwitchSideMenu(5);
        else
            this.SwitchSideMenu(10);
    }
    // 重置目标海关文件
    TargetOrderNoReset = () => {
        this.strTargetOrderNo = "";
        this.setState({
            ...this.state,
            bLoading: false
        })
    }
    // 海关文件返回出单系统
    CustomFileToAutoOrder = (strOrderNo, iOrderType) => {
        console.log("CustomFileToAutoOrder : ", iOrderType);
        switch (iOrderType) {
            case 0: this.SwitchSideMenu(11); break;
            case 2: this.SwitchSideMenu(12); break;
            case 3: this.SwitchSideMenu(13); break;
            case 7: this.SwitchSideMenu(15); break;
            default: break;
        }

    }
    // 海关文件返回出单系统
    InvoiceFileToAutoOrder = (strOrderNo) => {
        this.SwitchSideMenu(2);
    }
    //提交运单
    CreatePackageCombine = (arrExpressEnter) => {
        // console.log("CreatePackageCombine : ", arrExpressEnter);
        this.setState({
            ...this.state,
            iCurrentInterface: 1,
            arrExpressEnter: arrExpressEnter
        });
    }
    // 重置选中包裹信息
    ResetExpressOrderInfo = () => {
        this.setState({
            ...this.state,
            arrExpressEnter: []
        })
    }
    // 预报单和正式出单的跳转
    AutoOrderAndPrediction = (index, strOid) => {
        this.strTargetOrderNo = strOid;
        // 预报单跳转正式单
        if (index === 0) {
            this.SwitchSideMenu(2);
        }
        // 正式单跳转预报单
        if (index === 1) {
            this.SwitchSideMenu(111);
        }
    }
    // 目标单号重置
    TargetOrderNoReset = () => {
        this.strTargetOrderNo = "";
        this.setState({
            ...this.state,
            bLogin: this.state.bLogin
        })
    }
    // 切换账号
    // SwitchUserAccount = () => {
    //     console.log(' this.strCurrentUserName = ', this.strCurrentUserName, this.iCurrentUserLevel);
    //     if (this.strCurrentUserName.indexOf('zhihe') >= 0) {
    //         this.strCurrentUserName = 'xiyouji';
    //         this.iCurrentUserLevel = 10;
    //         this.RefreshStoreInfo(30000);
    //     } else {
    //         if (this.strCurrentUserName.indexOf('xiyouji') >= 0) {
    //             this.strCurrentUserName = 'zhihecn';
    //             this.iCurrentUserLevel = 6;
    //             this.RefreshStoreInfo(20000);
    //         }
    //     }
    // }
    // 刷新商家信息
    // RefreshStoreInfo = (iStoreID) => {
    //     axios('/GetStoreInfo', { params: { id: iStoreID } })
    //         .then(({ data }) => {
    //             this.objStoreInfo = data[0];
    //             let iNewInterface = this.state.iCurrentInterface;
    //             if (this.objStoreInfo.type === 5) {
    //                 iNewInterface = 0;
    //                 if (this.iCurrentUserLevel >= 9) {
    //                     this.arrSideMenuButton = [
    //                         { id: 0, name: "快递进仓", icon: "code-sandbox" },
    //                         { id: 1, name: "合箱管理", icon: "cluster" },
    //                         { id: 2, name: "出单系统", icon: "profile" },
    //                         { id: 3, name: '货运价格', icon: "dollar" },
    //                         { id: 4, name: "用户管理", icon: "user" },
    //                         { id: 5, name: '城市港口', icon: "global" },
    //                         { id: 6, name: "物流状态", icon: "car" },
    //                         { id: 7, name: "数据统计", icon: "rise" },
    //                         { id: 8, name: "备忘记录", icon: "snippets" },
    //                         { id: 9, name: "海关文件", icon: "audit" },
    //                         { id: 10, name: "发票文件", icon: "file-done" },
    //                         {
    //                             id: 11, name: "预报", icon: "notification",
    //                             sub: [{ id: 110, name: "客户" }, { id: 111, name: "预报单" }]
    //                         },
    //                     ];
    //                 } else {
    //                     if (this.iCurrentUserLevel >= 6) {
    //                         this.arrSideMenuButton = [
    //                             { id: 0, name: "快递进仓", icon: "code-sandbox" },
    //                             { id: 1, name: "合箱管理", icon: "cluster" },
    //                             { id: 2, name: "出单系统", icon: "profile" },
    //                             { id: 3, name: '货运价格', icon: "dollar" }
    //                         ];
    //                         if (this.iCurrentUserLevel >= 7) {
    //                             this.arrSideMenuButton = [
    //                                 { id: 0, name: "快递进仓", icon: "code-sandbox" },
    //                                 { id: 1, name: "合箱管理", icon: "cluster" },
    //                                 { id: 2, name: "出单系统", icon: "profile" },
    //                                 { id: 3, name: '货运价格', icon: "dollar" },
    //                                 { id: 4, name: "海关文件", icon: "audit" },
    //                                 { id: 5, name: "发票文件", icon: "file-done" },
    //                                 {
    //                                     id: 11, name: "预报", icon: "notification",
    //                                     sub: [{ id: 110, name: "客户" }, { id: 111, name: "预报单" }]
    //                                 }
    //                             ];
    //                         }
    //                         if (this.objStoreInfo.id === 20000)
    //                             this.arrSideMenuButton = [
    //                                 { id: 0, name: "快递进仓", icon: "code-sandbox" },
    //                                 { id: 1, name: "合箱管理", icon: "cluster" },
    //                                 { id: 2, name: "出单系统", icon: "profile" },
    //                                 { id: 3, name: '货运价格', icon: "dollar" },
    //                                 { id: 4, name: "用户管理", icon: "user" },
    //                                 {
    //                                     id: 11, name: "预报", icon: "notification",
    //                                     sub: [{ id: 110, name: "客户" }, { id: 111, name: "预报单" }]
    //                                 }
    //                             ];
    //                     } else {
    //                         this.arrSideMenuButton = [
    //                             { id: 0, name: "快递进仓", icon: "code-sandbox" },
    //                             { id: 1, name: "海运预报", icon: "profile" },
    //                         ];
    //                     }
    //                 }
    //             } else {
    //                 this.arrSideMenuButton = [];
    //                 if (this.strCurrentUserName.indexOf('_a') >= 0) {
    //                     this.arrSideMenuButton = [
    //                         { id: 0, name: "商品管理", icon: "gift" }
    //                     ];
    //                 } else {
    //                     this.arrSideMenuButton = [
    //                         { id: 0, name: "数据统计", icon: "rise" },
    //                         { id: 1, name: "商家信息", icon: "shop" },
    //                         { id: 2, name: "宣传图片", icon: "picture" },
    //                         { id: 3, name: "商品管理", icon: "gift" },
    //                         { id: 4, name: "类别标签", icon: "appstore" },
    //                         { id: 5, name: "优惠设置", icon: "tag" },
    //                         { id: 6, name: "订单列表", icon: "profile" },
    //                         { id: 7, name: "运费计算", icon: "dollar" }
    //                     ];
    //                 }
    //                 if (this.state.iCurrentInterface > 6 || this.state.iCurrentInterface >= this.arrSideMenuButton.length)
    //                     iNewInterface = 0;
    //             }
    //             // 西邮寄账号
    //             if (this.objStoreInfo.id === 30000) {
    //                 this.arrSideMenuButton = [
    //                     { id: 0, name: "包裹管理", icon: "code-sandbox" },
    //                     { id: 1, name: "运单管理", icon: "snippets" },
    //                     { id: 2, name: "用户管理", icon: "user" },
    //                     { id: 3, name: "收件人", icon: "usergroup-add" }
    //                 ];
    //                 if (this.state.iCurrentTopBarIndex === 1)
    //                     this.arrSideMenuButton = [
    //                         { id: 0, name: "订单管理", icon: "snippets" },
    //                         { id: 1, name: "用户管理", icon: "user" },
    //                         { id: 2, name: "发件人", icon: "usergroup-add" },
    //                         { id: 3, name: "收件人", icon: "usergroup-add" }
    //                     ];
    //                 document.title = "西邮寄物流";
    //             }
    //             this.SwitchSideMenu(iNewInterface);
    //         }).catch(function (error) { console.log(error); });
    // }
    // 跳转到主页
    JumpToWebsite = () => {
        console.log("jump to lekon");
        window.location.href = 'https://www.mouthing.info/lekon';
    }
    // 渲染完毕……
    componentDidMount() {
        let strUserCode = cookie.load('store_user_code');
        let iUserLoginStatus = cookie.load('store_login_status');
        if ((iUserLoginStatus === 1 || iUserLoginStatus === '1') && strUserCode !== undefined) {
            this.strCurrentUserCode = strUserCode;
            this.GetStoreInfo();

        } else {
            this.setState({
                ...this.state,
                bLoading: false,
                bLogin: false
            });
        }
        document.title = "Trucking System";
    }

    render() {
        const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;
        // Only show error after a field is touched.
        const usernameError = isFieldTouched('username') && getFieldError('username');
        const passwordError = isFieldTouched('password') && getFieldError('password');
        // 登录区域
        let strTitle = 'Welcome, ' + this.strCurrentUserName;
        let uiLogInForm = (
            <Form className="manage_login_form" layout="inline" onSubmit={this.LoginSubmit}>
                <Form.Item validateStatus={usernameError ? 'error' : ''} help={usernameError || ''}>
                    {getFieldDecorator('username')(
                        <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="用户名" />,
                    )}
                </Form.Item>
                <Form.Item validateStatus={passwordError ? 'error' : ''} help={passwordError || ''}>
                    {getFieldDecorator('password')(
                        <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                            type="password" placeholder="密码" />,
                    )}
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" disabled={hasErrors(getFieldsError())}>
                        登 陆
                    </Button>
                </Form.Item>
            </Form>
        );
        let uiStoreInfo = <div className="store_backend_title" />
        // 侧边栏
        let uiSideBar = <div />;
        // 顶部栏
        // let uiLogo = <div className="store_manage_logo" >物流系统</div>;
        // if (this.bMStation)
        //     uiLogo = null;
        let uiNavBar = (
            <div className="manage_top_bar">
                {/* {uiLogo} */}
                <div className="manage_top_bar_menu">

                </div>
                {uiLogInForm}
            </div>
        );
        if (this.state.bLogin) {
            uiStoreInfo = (
                <div className="store_backend_title " style={{ marginLeft: '25px' }}>
                    {/* TransCity Transportation Inc. */}
                    <img className="transcity_logo" alt=''
                        src={"https://res.cloudinary.com/jingtu-ego-test/image/upload/v1702558918/999-TransCity/TransCityLogo_dxucdu.png"} />
                </div>
            );
            uiLogInForm = (
                <div className="manage_login_form">
                    <div className="welcome_back_title">{strTitle}</div>
                    <Button className="logout_btn" type="primary" htmlType="submit"
                        onClick={this.LogoutClick}>退出登录</Button>
                </div>
            );
            let arrDefaultOpenMenu = ['0'];
            if (this.iCurrentUserLevel < 9) {
                arrDefaultOpenMenu = ['1'];
                if (this.iCurrentUserLevel === 7)
                    arrDefaultOpenMenu = ['0'];
                if (this.iCurrentUserLevel === 1)
                    arrDefaultOpenMenu = ['1', '7'];
            }

            uiSideBar = (
                <Menu className="logistics_side_bar" defaultSelectedKeys={[this.state.iCurrentInterface.toString()]}
                    selectedKeys={[this.state.iCurrentInterface.toString()]} defaultOpenKeys={arrDefaultOpenMenu}
                    mode="inline" theme="dark" inlineCollapsed={this.state.collapsed}  >
                    {this.arrSideMenuButton.map((menu, index) => {
                        let uiMenuItem = (
                            <Menu.Item key={menu.id} onClick={this.SwitchSideMenu.bind(this, menu.id)} >
                                {/* <Icon type={menu.icon} /> */}{menu.name}
                            </Menu.Item>
                        );
                        if (menu.sub) {
                            uiMenuItem = (
                                <SubMenu key={menu.id} title={<span>{menu.name}</span>} >
                                    {menu.sub.map((sub, sub_index) => {
                                        return (
                                            <Menu.Item key={sub.id} onClick={this.SwitchSideMenu.bind(this, sub.id)}>
                                                {sub.name}
                                            </Menu.Item>
                                        );

                                    })}
                                </SubMenu>
                            );

                        }
                        return (uiMenuItem);

                    })}
                </Menu>
            );
            // let uiMainTitle = (
            //     <div className="logistics_logo" onClick={this.JumpToWebsite}>
            //         <img className="lekon_logo" alt=''
            //             src={"https://res.cloudinary.com/jingtu-ego-test/image/upload/v1609136102/0-%E9%B2%B8%E5%9B%BEWhaleMap/LekonLogo_t6own9.png"} />
            //     </div>
            // );
            uiNavBar = (
                <div className="manage_top_bar">
                    {/* {uiMainTitle} */}
                    {/* {uiNavBarMenu} */}
                    {uiStoreInfo}
                    {uiLogInForm}
                </div>
            );
        }
        let uiCentralArea;
        // 是否还在加载中……
        if (this.state.bLoading) {
            uiCentralArea = (<Spin className="manage_empty_loading" size="large" tip="加载中……" />);
        } else {
            if (this.state.bLogin) {
                let uiStatisticData = null;
                // 派送列表
                let uiDeliverSub0 = null;  // 全部
                let uiDeliverSub1 = null;  // FTL
                let uiDeliverSub2 = null;  // LTL
                let uiDeliverSub3 = null;  // 结单
                // 联系信息
                let uiClientManager = null;
                let uiCarTeamManager = null;
                let uiAddressManager = null;
                uiStatisticData = (
                    this.state.iCurrentInterface === this.arrSideMenuButton[0].id &&
                    <TruckingDataPanel bStore={true} iStoreID={this.objStoreInfo.id} bMStation={this.bMStation} />
                );
                // 全部
                uiDeliverSub0 = (
                    this.state.iCurrentInterface === this.arrSideMenuButton[1].sub[0].id && <JetonTruckingOrder objStoreInfo={this.objStoreInfo}
                        iUserLevel={this.iCurrentUserLevel} bMStation={this.bMStation} AutoOrderAndPrediction={this.AutoOrderAndPrediction}
                        AutoOrderToCustomFile={this.AutoOrderToCustomFile} AutoOrderToInvoiceFile={this.AutoOrderToInvoiceFile}
                        strTargetOrderNo={this.strTargetOrderNo} TargetOrderNoReset={this.TargetOrderNoReset} iType={10} bImport={true}
                        strUserName={this.strCurrentUserName} iUserID={this.iCurrentUserID} iUserTeam={this.iCurrentUserTeam} iClientID={this.iCurrentClientID} />
                );
                // FTL
                uiDeliverSub1 = (
                    this.state.iCurrentInterface === this.arrSideMenuButton[1].sub[1].id && <JetonTruckingOrder objStoreInfo={this.objStoreInfo}
                        iUserLevel={this.iCurrentUserLevel} bMStation={this.bMStation} AutoOrderAndPrediction={this.AutoOrderAndPrediction}
                        AutoOrderToCustomFile={this.AutoOrderToCustomFile} AutoOrderToInvoiceFile={this.AutoOrderToInvoiceFile}
                        strTargetOrderNo={this.strTargetOrderNo} TargetOrderNoReset={this.TargetOrderNoReset} iType={11} bImport={true}
                        strUserName={this.strCurrentUserName} iUserID={this.iCurrentUserID} iUserTeam={this.iCurrentUserTeam} iClientID={this.iCurrentClientID} />
                );
                // LTL
                uiDeliverSub2 = (
                    this.state.iCurrentInterface === this.arrSideMenuButton[1].sub[2].id && <JetonTruckingOrder objStoreInfo={this.objStoreInfo}
                        iUserLevel={this.iCurrentUserLevel} bMStation={this.bMStation} AutoOrderAndPrediction={this.AutoOrderAndPrediction}
                        AutoOrderToCustomFile={this.AutoOrderToCustomFile} AutoOrderToInvoiceFile={this.AutoOrderToInvoiceFile}
                        strTargetOrderNo={this.strTargetOrderNo} TargetOrderNoReset={this.TargetOrderNoReset} iType={12} bImport={true}
                        strUserName={this.strCurrentUserName} iUserID={this.iCurrentUserID} iUserTeam={this.iCurrentUserTeam} iClientID={this.iCurrentClientID} />
                );
                // 结单
                uiDeliverSub3 = (
                    this.state.iCurrentInterface === this.arrSideMenuButton[1].sub[3].id && <JetonTruckingOrder objStoreInfo={this.objStoreInfo}
                        iUserLevel={this.iCurrentUserLevel} bMStation={this.bMStation} AutoOrderAndPrediction={this.AutoOrderAndPrediction}
                        AutoOrderToCustomFile={this.AutoOrderToCustomFile} AutoOrderToInvoiceFile={this.AutoOrderToInvoiceFile}
                        strTargetOrderNo={this.strTargetOrderNo} TargetOrderNoReset={this.TargetOrderNoReset} iType={13} bImport={true}
                        strUserName={this.strCurrentUserName} iUserID={this.iCurrentUserID} iUserTeam={this.iCurrentUserTeam} iClientID={this.iCurrentClientID} />
                );
                // 用户
                uiCarTeamManager = (
                    this.state.iCurrentInterface === this.arrSideMenuButton[2].sub[0].id && <LogisticCarTeamManager
                        iStoreID={this.objStoreInfo.id} iUserLevel={this.iCurrentUserLevel} bMStation={this.bMStation} />
                );
                uiClientManager = (
                    this.state.iCurrentInterface === this.arrSideMenuButton[2].sub[1].id && <LogisticClientManager
                        iStoreID={this.objStoreInfo.id} iUserLevel={this.iCurrentUserLevel} bMStation={this.bMStation} />
                );
                uiAddressManager = (
                    this.state.iCurrentInterface === this.arrSideMenuButton[2].sub[2].id && <AddressBookManager
                        iStoreID={this.objStoreInfo.id} iLevel={this.iCurrentUserLevel} iUserID={this.iCurrentUserID} />
                );

                uiCentralArea = (
                    <div>
                        {/* 0. 商家数据概况 */}
                        {uiStatisticData}
                        {/* 1 派送列表 */}
                        {uiDeliverSub0}
                        {uiDeliverSub1}
                        {uiDeliverSub2}
                        {uiDeliverSub3}
                        {/* 3 联系信息 */}
                        {uiCarTeamManager}
                        {uiClientManager}
                        {uiAddressManager}
                    </div>
                );

            } else {
                uiCentralArea = (<Empty className="manage_empty_loading"
                    description={<span>未登录，无法使用</span>} />);
            }
        }

        return (
            <div className="manage_page">
                {/* 顶部菜单栏 */}
                {uiNavBar}
                {/* 侧边菜单栏 */}
                {uiSideBar}
                {/* 中心区域内容 */}
                {uiCentralArea}
            </div >
        );
    }
}
export default Form.create()(Trucking);
