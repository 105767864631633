import React, { Component } from 'react';
import axios from 'axios';

import Spin from 'antd/es/spin';
import Table from 'antd/es/table';
import Button from 'antd/es/button';
import Input from 'antd/es/input';
import Menu from 'antd/es/menu';
import Dropdown from 'antd/es/dropdown';
import Icon from 'antd/es/icon';
import Modal from 'antd/es/modal';
import message from 'antd/es/message';

import '../../../styles/Background/ego_manager.css';
import '../../../styles/Background/UI_Module/accounting_manager.css';

const g_strMessageKey = 'updating';

// 表格
const arrTableHeader = [
    { title: 'id', dataIndex: 'id', width: 50, ellipsis: true },
    { title: '名称', dataIndex: 'name', width: 100, ellipsis: true },
    { title: '类别', dataIndex: 'type', width: 100, ellipsis: true },
    { title: '备注', dataIndex: 'comments', width: 200, ellipsis: true }
];

class AccountingTarget extends Component {
    constructor(props) {
        super();
        this.state = {
            btnControlDisable: [false, true, true],  // 操作按钮控制
            btnCopyPasteDisable: [true, true],  // 操作按钮控制
            selectedRowKeys: [],
            iAccountingType: 0,
            iAccountingTypeFilter: 0,
            iPaymentType: 0,
            iPaymentTypeFilter: 0,
            iDlgType: -1,
            bShowDlg: false,
            bLoading: false,
            strNewPassword: "",
            strConfirmPassword: ""
        };
        // 成员变量
        this.arrOgeTableData = [];
        this.arrTableData = [];  // 用于表格的数组

        this.arrFilterAccountingType = ['入账', '出账'];
        this.arrFilterAccountingTypeFilter = ['全部', '入账', '出账'];
        this.arrFilterPaymentType = ['银行卡', '现金', '支票', '电邮', '微信', '支付宝'];
        this.arrFilterPaymentTypeFilter = ['全部', '银行卡', '现金', '支票', '电邮', '微信', '支付宝'];

        this.arrSelectedAccountingID = [];
        this.arrSelectedAccountingIndex = [];
        this.objCurrentAccountingRecord = {};
    }

    // 选中某行的响应函数
    selectRow = (record) => {
        const selectedRowKeys = [...this.state.selectedRowKeys];
        if (selectedRowKeys.indexOf(record.key) >= 0) {
            selectedRowKeys.splice(selectedRowKeys.indexOf(record.key), 1);
        } else {
            selectedRowKeys.push(record.key);
        }
        this.SelectedRowStateSetting(selectedRowKeys);
    }
    // 选中的行发生变化的响应函数
    onSelectedRowKeysChange = (selectedRowKeys) => {
        this.SelectedRowStateSetting(selectedRowKeys);
    }
    // 处理选中的表行行
    SelectedRowStateSetting = (selectedRowKeys) => {
        let btnControlDisable = [];
        let btnCopyPasteDisable = [];
        switch (selectedRowKeys.length) {
            case 0: {
                btnControlDisable = [false, true, true];
                btnCopyPasteDisable = [true, true];
                this.objCurrentAccountingRecord = {};
                break;
            }
            case 1: {
                btnControlDisable = [false, false, false];
                btnCopyPasteDisable = [false, true];
                for (let i = 0; i < this.arrTableData.length; i++) {
                    if (this.arrTableData[i].key === selectedRowKeys[0]) {
                        this.objCurrentAccountingRecord = this.arrTableData[i];
                        break;
                    }
                }
                break;
            }
            default: {
                btnControlDisable = [false, true, true]; btnCopyPasteDisable = [true, true]; break;
            }
        }
        this.setState({
            ...this.state,
            selectedRowKeys,
            btnControlDisable: btnControlDisable,
            btnCopyPasteDisable: btnCopyPasteDisable
        });
    }
    // 关键词处理
    KeywordsProcess = (strKeywords) => {
        let newKeywords = "";
        for (let i = 0; i < strKeywords.length; i++) {
            if (strKeywords[i] !== " ") {
                if (i === strKeywords.length - 1)
                    newKeywords += strKeywords[i];
                else
                    newKeywords += strKeywords[i] + "%";
            }
        }
        return newKeywords;
    }
    // 搜索记账名
    AccountingSearch = () => {
        let strKeywords = this.refs.Accounting_name_search.state.value;
        if (strKeywords === undefined)
            strKeywords = "";
        if (strKeywords.trim() === "") {
            this.GetAccountingData();
        } else {
            this.setState({
                ...this.state,
                bLoading: true,
                selectedRowKeys: [],
                btnControlDisable: [false, true, true]
            });
            axios('/SearchAccounting', { params: { name: this.KeywordsProcess(strKeywords) } })
                .then(({ data }) => {
                    this.arrTableData = this.OrganizeAccountingData(data);
                    this.setState({
                        ...this.state,
                        bLoading: false,
                        iAccountingTypeFilter: 0
                    });
                }).catch(function (error) { console.log(error); });
        }
    }
    // 点击了记账类别下拉菜单
    AccountingTypeClicked = (iAccountingType) => {
        this.setState({
            ...this.state,
            iAccountingType: iAccountingType
        });
    }
    // 点击了记账类别过滤器
    AccountingTypeFilterClicked = (iAccountingType) => {
        // let strTargetType = this.arrFilterAccountingTypeFilter[iAccountingType];
        // if (iAccountingType !== 0) {
        //     this.arrTableData = [];
        //     for (let i = 0; i < this.arrOgeTableData.length; i++) {
        //         if (strTargetType === this.arrOgeTableData[i].level) {
        //             this.arrTableData.push(this.arrOgeTableData[i]);
        //         }
        //     }
        // } else {
        //     this.arrTableData = this.arrOgeTableData;
        // }
        this.setState({
            ...this.state,
            iAccountingTypeFilter: iAccountingType
        });
    }
    // 点击了支付类别下拉菜单
    PaymentTypeClicked = (iPaymentType) => {
        this.setState({
            ...this.state,
            iPaymentType: iPaymentType
        });
    }
    // 点击了记账类别过滤器
    PaymentTypeFilterClicked = (iPaymentType) => {
        this.setState({
            ...this.state,
            iPaymentTypeFilter: iPaymentType
        });
    }
    // 点击了记账状态过滤器
    // AccountingtatusFilterClicked = (iStatus) => {
    //     this.setState({
    //         ...this.state,
    //         objFilter: {
    //             strKeyword: "", iCurrentAccountingtatus: iStatus, iCurrentAccountingType: this.state.objFilter.iCurrentAccountingType
    //         }
    //     });
    // }
    /* 自定义成员函数 */
    //弹出对话框
    ShowDlg = (iDlgType, e) => {
        if (iDlgType === 0) {
            // this.PresetNewDlg();
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                dlgInfo: { iMainCategory: 0, iSubCategory: 0, iLocation: 0, iStatus: 0 },
                bShowDlg: true
            });
        }
        if (iDlgType === 1) {
            if (this.refs.Accounting_name !== undefined) {
                this.refs.Accounting_name.state.value = this.objCurrentAccountingRecord.name;
                this.refs.invite_code.state.value = this.objCurrentAccountingRecord.invite_code;
                this.refs.price_off.state.value = this.objCurrentAccountingRecord.price_off;
            }
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true,
                strNewPassword: "",
                strConfirmPassword: ""
            });
        }
        if (iDlgType === 2) {
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true
            });
        }
    }
    // 弹窗确认按钮响应
    handleOk = e => {
        if (this.state.iDlgType === 1) {
            let strName = this.refs.Accounting_name.state.value;
            let strPriceOff = this.refs.price_off.state.value;
            if (strName === null || strName.trim() === "") {
                message.warning("记账名不能为空！"); return;
            }
            if (this.state.strConfirmPassword !== this.state.strNewPassword) {
                message.warning("两次输入密码不一致！"); return;
            }
            let objNewAccounting = {};
            if (this.state.strNewPassword === "") {
                objNewAccounting = {
                    name: strName,
                    price_off: strPriceOff,
                    invite_code: this.refs.invite_code.state.value
                }
            } else {
                objNewAccounting = {
                    name: strName,
                    price_off: strPriceOff,
                    invite_code: this.refs.invite_code.state.value,
                    password: this.state.strNewPassword
                }
            }
            console.log("Update Accounting Info: ");
            console.log(objNewAccounting);
            message.loading({ content: '正在更新记账信息……', key: g_strMessageKey });
            axios.post('/UpdateAccountingKeyInfo', {
                objCurrentAccounting: this.objCurrentAccountingRecord,
                objNewAccounting: objNewAccounting
            }).then(
                ({ data }) => {
                    if (data.status === 1) {
                        this.GetAccountingData();
                    }
                    else
                        message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
                }).catch(function (error) { console.log(error); });
        }
        if (this.state.iDlgType === 2) {
            message.loading({ content: '记账删除中……', key: g_strMessageKey });
            axios.post('/DeleteAccounting', { id: this.objCurrentAccountingRecord.id }).then(
                ({ data }) => {
                    if (data.status === -1) {
                        message.warning({
                            content: "删除失败，该账目已绑定商家，请先检查并删除商家！",
                            key: g_strMessageKey, duration: 2
                        });
                        this.setState({
                            ...this.state,
                            bLoading: false,
                            bShowDlg: false,
                            selectedRowKeys: []
                        });
                    } else {
                        this.GetAccountingData();
                    }
                }).catch(function (error) { console.log(error); });
        }
    };
    // 弹窗取消按钮响应
    handleCancel = e => {
        this.setState({
            bShowDlg: false,
        });
    };
    // 整理Accounting数据
    OrganizeAccountingData(data) {
        let arrTableData = [];
        for (let i = 0; i < data.length; i++) {
            let strRegisterDate = data[i].register_date;
            let strLoginDate = data[i].last_login;
            if (strRegisterDate !== null) {
                if (strRegisterDate.indexOf('T') >= 0)
                    strRegisterDate = strRegisterDate.split('T')[0];
            }
            if (strLoginDate !== null) {
                if (strLoginDate.indexOf('T') >= 0)
                    strLoginDate = strLoginDate.split('T')[0];
            }
            let strPhone = data[i].phone;
            if (strPhone === null || strPhone === "") {
                strPhone = data[i].contact_phone;
            }
            if (data[i].price_off === null || data[i].price_off === "")
                data[i].price_off = 0;
            let objAccountingData = {
                key: i, id: data[i].id,
                name: data[i].name,
                storage_code: data[i].storage_code,
                invite_code: data[i].invite_code,
                code: data[i].code,
                phone: strPhone,
                status: this.arrFilterAccountingtatus[data[i].status],
                store_id: data[i].store_id,
                register_date: strRegisterDate,
                last_login: strLoginDate,
                price_off: data[i].price_off,
                price_off_str: data[i].price_off + "%"
            }
            if (data[i].level >= 15)
                objAccountingData.level = this.arrFilterAccountingTypeFilter[4];
            if (data[i].level >= 10 && data[i].level < 15)
                objAccountingData.level = this.arrFilterAccountingTypeFilter[3];
            if (data[i].level >= 5 && data[i].level < 10)
                objAccountingData.level = this.arrFilterAccountingTypeFilter[2];
            if (data[i].level >= 0 && data[i].level < 5)
                objAccountingData.level = this.arrFilterAccountingTypeFilter[1];
            arrTableData.push(objAccountingData);
        }
        return arrTableData;
    }
    // 从数据库获取当前的数据结构
    GetAccountingData() {
        message.loading({ content: '数据加载中……', key: g_strMessageKey });
        axios('/DB/GetAllAccounting')
            .then(({ data }) => {
                // console.log("GetAllAccounting", data);
                this.arrOgeTableData = this.OrganizeAccountingData(data);
                this.arrTableData = this.arrOgeTableData;
                if (this.state.bShowDlg) {
                    if (this.state.iDlgType === 1) {
                        message.success({ content: "记账信息已更新！", key: g_strMessageKey, duration: 2 });
                    }
                    if (this.state.iDlgType === 2) {
                        message.success({ content: "记账已删除！", key: g_strMessageKey, duration: 2 })
                    }
                } else {
                    message.success({ content: "数据加载完成！", key: g_strMessageKey, duration: 2 });
                }
                this.setState({
                    ...this.state,
                    bLoading: false,
                    bShowDlg: false,
                    selectedRowKeys: []
                });
            }).catch(function (error) { console.log(error); });
    }
    /* React自带函数 */
    // 卸载异步操作设置状态
    componentWillUnmount() {
        clearTimeout(this.timeouter)
        this.setState = (state, callback) => {
            return;
        }
    }
    // 渲染完毕……
    componentDidMount() {
        // this.GetAccountingData();
    }
    // 渲染函数
    render() {
        // 编辑弹窗账目类别下拉菜单
        const uiAccountingTypeMenu = (
            <Menu>
                {this.arrFilterAccountingType.map((item, index) => {
                    return (
                        <Menu.Item key={index} onClick={this.AccountingTypeClicked.bind(this, index)}>
                            {item}
                        </Menu.Item>
                    );
                })}
            </Menu>
        );
        const uiPaymentTypeMenu = (
            <Menu>
                {this.arrFilterPaymentType.map((item, index) => {
                    return (
                        <Menu.Item key={index} onClick={this.PaymentTypeClicked.bind(this, index)}>
                            {item}
                        </Menu.Item>
                    );
                })}
            </Menu>
        );
        // 顶部栏账目类别下拉菜单
        const uiAccountingTypeFilterMenu = (
            <Menu>
                {this.arrFilterAccountingTypeFilter.map((item, index) => {
                    return (
                        <Menu.Item key={index} onClick={this.AccountingTypeFilterClicked.bind(this, index)}>
                            {item}
                        </Menu.Item>
                    );
                })}
            </Menu>
        );
        const uiPaymentTypeFilterMenu = (
            <Menu>
                {this.arrFilterPaymentTypeFilter.map((item, index) => {
                    return (
                        <Menu.Item key={index} onClick={this.PaymentTypeFilterClicked.bind(this, index)}>
                            {item}
                        </Menu.Item>
                    );
                })}
            </Menu>
        );

        /* 记账管理页面控制栏 */
        let uiTitleArea = (
            < div className="db_manager_contents_title" >
                <div className="manage_title_filter_area">
                    <div style={{ marginLeft: '20px', marginRight: '10px', color: 'white', fontWeight: 'bold' }}>账目类型</div>
                    <Dropdown className="manage_contents_title_margin manage_stretch_right"
                        overlay={uiAccountingTypeFilterMenu} placement="bottomLeft">
                        <Button>
                            {this.arrFilterAccountingTypeFilter[this.state.iAccountingTypeFilter]}
                            <Icon className="dropdown_icon" type="caret-down" />
                        </Button>
                    </Dropdown>
                    <div style={{ marginLeft: '20px', marginRight: '10px', color: 'white', fontWeight: 'bold' }}>支付类型</div>
                    <Dropdown className="manage_contents_title_margin manage_stretch_right"
                        overlay={uiPaymentTypeFilterMenu} placement="bottomLeft">
                        <Button>
                            {this.arrFilterPaymentTypeFilter[this.state.iPaymentTypeFilter]}
                            <Icon className="dropdown_icon" type="caret-down" />
                        </Button>
                    </Dropdown>
                </div>
                <div className="manage_title_button_right_side">
                    <Button className="manage_contents_title_margin" type="primary" onClick={this.ShowDlg.bind(this, 0)}
                        disabled={this.state.btnControlDisable[0]}><Icon type="edit" />新建</Button>
                    <Button className="manage_contents_title_margin" type="primary" onClick={this.ShowDlg.bind(this, 1)}
                        disabled={this.state.btnControlDisable[1]}><Icon type="edit" />编辑</Button>
                    <Button className="manage_contents_title_margin" type="danger" onClick={this.ShowDlg.bind(this, 2)}
                        disabled={this.state.btnControlDisable[2]}><Icon type="close" />删除</Button>
                </div>
            </div >
        );

        // 如果界面上需要显示的内容已经从数据库获得
        if (!this.state.bLoading) {
            // 主体表格相关
            const { selectedRowKeys } = this.state;
            const rowSelection = {
                selectedRowKeys,
                onChange: this.onSelectedRowKeysChange,
                columnWidth: 30
            };
            // 表格分页属性
            const paginationProps = {
                showSizeChanger: true,
                showQuickJumper: false,
                showTotal: () => `共 ${this.arrTableData.length} 条 账目`,
                defaultPageSize: 10,
                defaultCurrent: this.state.iCurrentPage,
                pageSizeOptions: ['10', '20', '50', '100'],
                total: this.arrTableData.length,
                position: 'bottom'
            };
            // 记账信息编辑弹窗
            let uiModal = (
                <Modal title="编辑账目" width={666} visible={this.state.bShowDlg} okText="确定" cancelText="取消"
                    onOk={this.handleOk} onCancel={this.handleCancel} >
                    <div className="user_edit_info_row">
                        <div className="user_edit_info_row">
                            <div className="user_edit_info_title">账目类别：</div>
                            <Dropdown className="accounting_edit_info_input"
                                overlay={uiAccountingTypeMenu} placement="bottomLeft">
                                <Button>
                                    {this.arrFilterAccountingType[this.state.iAccountingType]}
                                    <Icon className="dropdown_icon" type="caret-down" />
                                </Button>
                            </Dropdown>
                        </div>
                        <div className="user_edit_info_row">
                            <div className="user_edit_info_title">负责人：</div>
                            <Input className="accounting_edit_info_input" ref="charge"
                                defaultValue={this.objCurrentAccountingRecord.charge} />
                        </div>
                    </div>
                    <div className="user_edit_info_row">
                        <div className="user_edit_info_row">
                            <div className="user_edit_info_title">来源：</div>
                            <Input className="accounting_edit_info_input" ref="target_from"
                                defaultValue={this.objCurrentAccountingRecord.target_from} />
                        </div>
                        <div className="user_edit_info_row">
                            <div className="user_edit_info_title">去处：</div>
                            <Input className="accounting_edit_info_input" ref="target_to"
                                defaultValue={this.objCurrentAccountingRecord.target_to} />
                        </div>
                    </div>
                    <div className="user_edit_info_row">
                        <div className="user_edit_info_row">
                            <div className="user_edit_info_title">支付方式：</div>
                            <Dropdown className="accounting_edit_info_input"
                                overlay={uiPaymentTypeMenu} placement="bottomLeft">
                                <Button>
                                    {this.arrFilterPaymentType[this.state.iPaymentType]}
                                    <Icon className="dropdown_icon" type="caret-down" />
                                </Button>
                            </Dropdown>
                        </div>
                        <div className="user_edit_info_row">
                            <div className="user_edit_info_title">数额：</div>
                            <Input className="accounting_edit_info_input" ref="amount"
                                defaultValue={this.objCurrentAccountingRecord.amount} />
                        </div>
                    </div>
                </Modal>
            );
            if (this.state.iDlgType === 2) {
                uiModal = (
                    <Modal width={500} title={"删除记账信息"} visible={this.state.bShowDlg} okType="danger"
                        onOk={this.handleOk} okText="确定"
                        onCancel={this.handleCancel} cancelText="取消">
                        <div className="modal_item">
                            <div>{this.objCurrentAccountingRecord.name}
                                <br /><br />选中记账的所有信息都将被删除……<br />确定删除选中的记账？
                            </div>
                        </div>
                    </Modal>
                );
            }

            return (
                <div className="store_manage_contents store_manage_top_space">
                    {uiTitleArea}
                    {uiModal}
                    {/* 表格主体 */}
                    <Table style={{ margin: '10px', width: '97.5%', overflowX: 'auto' }}
                        rowSelection={rowSelection} pagination={paginationProps}
                        columns={arrTableHeader} dataSource={this.arrTableData} bordered
                        onRow={(record) => ({ onClick: () => { this.selectRow(record); } })} />
                </div >
            );
        } else {
            return (
                <div className="store_manage_contents store_manage_top_no_space">
                    {uiTitleArea}
                    <Spin className="manage_empty_loading" size="large" tip="加载中……" />
                </div>
            );
        }
    }
}
export default AccountingTarget;
