import React, { Component } from 'react';
import axios from 'axios';

import Spin from 'antd/es/spin';
import Input from 'antd/es/input';
import Modal from 'antd/es/modal';
import message from 'antd/es/message';
import Radio from 'antd/es/radio';
import Icon from 'antd/es/icon';
import Barcode from 'react-barcode';

import '../../../styles/Background/ego_manager.css';
import '../../../styles/Background/storage_manager.css';
import '../../../styles/Background/UI_Module/user_management.css';

const g_strMessageKey = 'updating';

// 表格
// const arrClientTableHeader = [
//     { title: '出库单号', dataIndex: 'code', width: 100, ellipsis: true },
//     { title: '类型', dataIndex: 'type_str', width: 75, ellipsis: true },
//     { title: '状态', dataIndex: 'status_str', width: 75, ellipsis: true },
//     { title: '物流', dataIndex: 'express_no', width: 100, ellipsis: true },
//     { title: '箱数', dataIndex: 'box_num', width: 50, ellipsis: true },
//     { title: '费用', dataIndex: 'service_fee', width: 50, ellipsis: true },
//     { title: '备注', dataIndex: 'comments', width: 150, ellipsis: true },
// ];

// const arrManagerTableHeader = [
//     { title: '出库单号', dataIndex: 'code', width: 100, ellipsis: true },
//     { title: '客户', dataIndex: 'user_name', width: 75, ellipsis: true },
//     { title: '类型', dataIndex: 'type_str', width: 75, ellipsis: true },
//     { title: '状态', dataIndex: 'status_str', width: 75, ellipsis: true },
//     { title: '物流', dataIndex: 'express_no', width: 125, ellipsis: true },
//     { title: '箱数', dataIndex: 'box_num', width: 50, ellipsis: true },
//     { title: '费用', dataIndex: 'service_fee', width: 50, ellipsis: true },
//     { title: '备注', dataIndex: 'comments', width: 150, ellipsis: true },
// ];

class DeliveryChecking extends Component {
    constructor(props) {
        super();
        this.state = {
            btnControlDisable: [false, true, true],  // 操作按钮控制
            selectedRowKeys: [],  // Check here to configure the default column
            iDlgType: -1,
            bShowDlg: false,
            bLoading: true,
            bValueWeightChange: false,
            iStatus: 0,
            iType: 0,
            bCheckUpdate: false,
            iCheckType: 0,
            iEditType: 0,
            bDeliveryBox: false
        };
        // 成员变量
        this.arrOgeTableData = [];
        this.arrTableData = [];  // 用于表格的数组
        this.objCurrentDeliveryOrder = {};
        this.fValue = 0.0;
        this.fValueWeight = 0.0;
        this.fPriceWeight = 0.0;
        this.arrStatus = ['已预报', '已出单', '已打包', '已出库', '运输中', '已送达', '作废'];
        this.arrType = ['自发预报', '物流预报', '退货预报'];
        this.arrItemInfo = [{ sku: '', name: '', num: 0, num_check: 0, status: 0 }];
        this.iTotalSKUNum = 0;
        this.iTotalItemNum = 0;

        this.strScanInput = "";
        this.timeLast = new Date();
        this.timeCurrent = new Date();
        this.strLastScanCode = "";
        this.strCurrentScanCode = "";

        this.iScanMonitor = 0;
        this.iSKUInventoryIndex = -1;
        this.iSKUItemIndex = -1;
        this.bSKUFound = false;

        this.bDownAble = false;
        this.iBoxStatus = 0;
        this.strCurrentScanPosition = "";
        this.iCurrentScanPositionID = 0;
        this.iCurrentScanPositionIndex = 0;
        this.arrCurrentInventoryRecord = [];
    }

    // 选中某行的响应函数
    selectRow = (record) => {
        const selectedRowKeys = [...this.state.selectedRowKeys];
        if (selectedRowKeys.indexOf(record.key) >= 0) {
            selectedRowKeys.splice(selectedRowKeys.indexOf(record.key), 1);
        } else {
            selectedRowKeys.push(record.key);
        }
        this.SelectedRowStateSetting(selectedRowKeys);
    }
    // 选中的行发生变化的响应函数
    onSelectedRowKeysChange = (selectedRowKeys) => {
        this.SelectedRowStateSetting(selectedRowKeys);
    }
    // 处理选中的表行行
    SelectedRowStateSetting = (selectedRowKeys) => {
        let btnControlDisable = [];
        // let btnCopyPasteDisable = [];
        switch (selectedRowKeys.length) {
            case 0: {
                btnControlDisable = [false, true, true];
                // btnCopyPasteDisable = [true, true];
                this.CurrentDeliveryOrderInit();
                break;
            }
            case 1: {
                btnControlDisable = [false, false, false];
                // btnCopyPasteDisable = [false, true];
                for (let i = 0; i < this.arrTableData.length; i++) {
                    if (this.arrTableData[i].key === selectedRowKeys[0]) {
                        this.objCurrentDeliveryOrder = this.arrTableData[i];
                        break;
                    }
                }
                break;
            }
            default: {
                btnControlDisable = [false, true, true];
                // btnCopyPasteDisable = [true, true];
                break;
            }
        }
        this.setState({
            ...this.state,
            selectedRowKeys,
            btnControlDisable: btnControlDisable
            // btnCopyPasteDisable: btnCopyPasteDisable
        });
    }
    // 关键词处理
    KeywordsProcess = (strKeywords) => {
        let newKeywords = "";
        for (let i = 0; i < strKeywords.length; i++) {
            if (strKeywords[i] !== " ") {
                if (i === strKeywords.length - 1)
                    newKeywords += strKeywords[i];
                else
                    newKeywords += strKeywords[i] + "%";
            }
        }
        return newKeywords;
    }

    /* 自定义成员函数 */
    //弹出对话框
    ShowDlg = (iDlgType, e) => {
        if (iDlgType === 0) {
            if (this.refs.code) {
                this.refs.code.state.value = "";
                this.refs.length.state.value = 0.0;
                this.refs.width.state.value = 0.0;
                this.refs.height.state.value = 0.0;
                this.refs.value.state.value = 0.0;
                this.refs.weight.state.value = 0.0;
                this.refs.express_no.state.value = "";
                this.refs.service_fee.state.value = "";
                this.refs.comments.state.value = "";
            }
            this.fValue = 0.0;
            this.fValueWeight = 0.0;
            this.fPriceWeight = 0.0;
            this.CurrentDeliveryOrderInit();
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true,
                iStatus: 0,
                iType: 0
            });
        }
        if (iDlgType === 1) {
            if (this.refs.code) {
                this.refs.code.state.value = this.objCurrentDeliveryOrder.code;
            }
            if (this.refs.length) {
                this.refs.length.state.value = this.objCurrentDeliveryOrder.length;
                this.refs.width.state.value = this.objCurrentDeliveryOrder.width;
                this.refs.height.state.value = this.objCurrentDeliveryOrder.height;
                this.refs.value.state.value = this.objCurrentDeliveryOrder.value;
                this.refs.weight.state.value = this.objCurrentDeliveryOrder.weight;
                this.refs.value_weight.state.value = this.objCurrentDeliveryOrder.value_weight;
                this.refs.service_fee.state.value = this.objCurrentDeliveryOrder.service_fee;
                this.refs.express_no.state.value = this.objCurrentDeliveryOrder.express_no;
                this.refs.comments.state.value = this.objCurrentDeliveryOrder.comments;
            }
            this.fValue = parseFloat(this.objCurrentDeliveryOrder.value);
            this.fValueWeight = parseFloat(this.objCurrentDeliveryOrder.value_weight);
            this.fPriceWeight = parseFloat(this.objCurrentDeliveryOrder.price_weight);
            message.loading({ content: '数据加载中……', key: g_strMessageKey });
            axios('/GetDeliveryOrderItemInfo', { params: { delivery: this.objCurrentDeliveryOrder.code } })
                .then(({ data }) => {
                    message.success({ content: "货物信息加载完成！", key: g_strMessageKey, duration: 2 });
                    if (data.length > 0) {
                        let arrBoxInfo = [];
                        this.arrItemInfo = [];
                        let arrItemInfo = [];
                        let iBox = data[0].box;
                        for (let i = 0; i < data.length; i++) {
                            if (data[i].box === iBox) {
                                if (data[i].box.num_check === null || data[i].box.num_check === "")
                                    data[i].box.num_check = 0;
                                arrItemInfo.push(data[i]);
                                if (i === data.length - 1) {
                                    this.arrItemInfo.push(arrItemInfo);
                                }
                            } else {
                                this.arrItemInfo.push(arrItemInfo);
                                arrBoxInfo.push(arrItemInfo);
                                iBox = data[i].box;
                                arrItemInfo = [];
                                if (data[i].box.num_check === null || data[i].box.num_check === "")
                                    data[i].box.num_check = 0;
                                arrItemInfo.push(data[i]);
                                if (i === data.length - 1) {
                                    this.arrItemInfo.push(arrItemInfo);
                                }
                            }
                        }
                    }
                    this.setState({
                        ...this.state,
                        iDlgType: iDlgType,
                        bShowDlg: true,
                        iStatus: this.objCurrentDeliveryOrder.status,
                        iType: this.objCurrentDeliveryOrder.type
                    });
                }).catch(function (error) { console.log(error); });
        }
        if (iDlgType === 2) {
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true
            });
        }
    }
    // 弹窗确认按钮响应
    handleOk = e => {
        if (this.state.iDlgType === 0 || this.state.iDlgType === 1) {

            if (!this.BoxInfoCheck())
                return;

            let objNewDeliveryOrder = {
                type: this.state.iType,
                status: this.state.iStatus,
                length: this.refs.length.state.value,
                width: this.refs.width.state.value,
                height: this.refs.height.state.value,
                value: this.refs.value.state.value,
                value_weight: this.refs.value_weight.state.value,
                weight: this.refs.weight.state.value,
                price_weight: this.fPriceWeight,
                express_no: this.refs.express_no.state.value,
                box_num: this.arrItemInfo.length,
                service_fee: this.refs.service_fee.state.value,
                comments: this.refs.comments.state.value,
                user_name: this.props.strUserName,
                user_id: this.props.iUserID,
                store_id: this.props.objStoreInfo.id
            }
            if (this.state.iDlgType === 1) {
                objNewDeliveryOrder.code = this.objCurrentDeliveryOrder.code;
                objNewDeliveryOrder.user_id = this.objCurrentDeliveryOrder.user_id;
                objNewDeliveryOrder.user_name = this.objCurrentDeliveryOrder.user_name;
            }

            message.loading({ content: '正在更新出库记录……', key: g_strMessageKey });
            axios.post('/UpdateDeliveryOrder', {
                objCurrentDeliveryOrder: this.objCurrentDeliveryOrder,
                objNewDeliveryOrder: objNewDeliveryOrder,
                arrBoxInfo: this.arrItemInfo,
                action: this.state.iDlgType
            }).then(
                ({ data }) => {
                    if (data.status === 1) {
                        this.GetDeliveryOrder();
                    } else {
                        if (data.message)
                            message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
                        else
                            message.warning({ content: "新建/更新出库记录失败！", key: g_strMessageKey, duration: 2 });
                    }
                }).catch(function (error) { console.log(error); });
        }
    };
    // 弹窗取消按钮响应
    handleCancel = e => {
        if (this.state.iCheckType === 1 && this.bDownAble) {
            message.warning("查验数据还未保存！");
            return;
        }
        this.arrItemInfo = [{ sku: '', name: '', num: 0, num_check: 0, status: 0 }];
        this.bDownAble = false;
        this.strCurrentScanInput = "";
        this.strCurrentScanCode = "";
        this.iSKUInventoryIndex = -1;
        this.iSKUItemIndex = -1;
        this.strCurrentScanPosition = "";
        this.iCurrentScanPositionID = 0;
        this.iCurrentScanPositionIndex = 0;
        this.arrCurrentInventoryRecord = [];
        this.CurrentDeliveryOrderInit();

        if (this.refs.position)
            this.refs.position.state.value = "";
        this.setState({
            bShowDlg: false,
            bDeliveryBox: false,
            iEditType: 0
        });
    };
    // 整理订单数据
    OrganizeDeliveryOrderData(data) {
        let arrTableData = [];
        for (let i = 0; i < data.length; i++) {
            let objDeliveryOrderData = data[i];
            objDeliveryOrderData.key = objDeliveryOrderData.id;
            objDeliveryOrderData.type_str = this.arrType[data[i].type];
            objDeliveryOrderData.status_str = this.arrStatus[data[i].status]
            arrTableData.push(objDeliveryOrderData);
        }
        return arrTableData;
    }
    // 从数据库获取当前的数据结构
    GetDeliveryOrder = () => {
        this.CurrentDeliveryOrderInit();
        message.loading({ content: '数据加载中……', key: g_strMessageKey });
        axios('/GetDeliveryOrder', {
            params: {
                store_id: this.props.objStoreInfo.id,
                user_id: this.props.iUserID,
                user_level: this.props.iUserLevel,
                invite_code: this.props.strInviteCode
            }
        })
            .then(({ data }) => {
                this.arrOgeTableData = this.OrganizeDeliveryOrderData(data);
                this.arrTableData = this.arrOgeTableData;
                if (this.state.bShowDlg) {
                    if (this.state.iDlgType === 0) {
                        message.success({ content: "新建出库预报完成！", key: g_strMessageKey, duration: 2 });
                    }
                    if (this.state.iDlgType === 1) {
                        message.success({ content: "出库记录信息已更新！", key: g_strMessageKey, duration: 2 });
                    }
                    if (this.state.iDlgType === 2) {
                        message.success({ content: "出库记录已删除！", key: g_strMessageKey, duration: 2 })
                    }
                } else {
                    message.success({ content: "出库信息加载完成！", key: g_strMessageKey, duration: 2 });
                }
                this.arrItemInfo = [
                    [{ sku: '', name: '', num: 0, num_check: 0, status: 0 }]
                ];
                this.setState({
                    ...this.state,
                    bLoading: false,
                    bShowDlg: false,
                    selectedRowKeys: [],
                    iEditType: 0
                });
            }).catch(function (error) { console.log(error); });
    }
    // 新建出库单的初始化
    CurrentDeliveryOrderInit = () => {
        this.objCurrentDeliveryOrder = {
            code: '',
            channel: 0,
            status: 0,
            length: 0.0,
            width: 0.0,
            height: 0.0,
            weight: 0.0,
            value: 0.0,
            value_weight: 0.0,
            price_weight: 0.0,
            comments: '',
            express_no: '',
            service_fee: 0.0,
            receiver: "",
            receiver_code: "",
            store_id: this.props.objStoreInfo.id,
            user_id: this.props.iUserID
        };
    }
    // 体积重量信息变化
    OnValueWeightChange = (index, e) => {
        // console.log(index, e.target.value);
        let fLength = parseFloat(this.refs.length.state.value);
        let fWidth = parseFloat(this.refs.width.state.value);
        let fHeight = parseFloat(this.refs.height.state.value);
        let fWeight = parseFloat(this.refs.weight.state.value);
        switch (index) {
            // 长度变化
            case 0: {
                fLength = parseFloat(e.target.value);
                break;
            }
            // 宽度变化
            case 1: {
                fWidth = parseFloat(e.target.value);
                break;
            }
            // 高度变化
            case 2: {
                fHeight = parseFloat(e.target.value);
                break;
            }
            // 重量变化
            case 3: {
                fWeight = parseFloat(e.target.value);
                break;
            }
            default: break;
        }
        this.fValue = fLength * fWidth * fHeight;
        this.fValueWeight = fLength * fWidth * fHeight / 5000.0;
        if (fWeight >= this.fValueWeight)
            this.fPriceWeight = fWeight;
        else
            this.fPriceWeight = this.fValueWeight;
        this.setState({
            ...this.state,
            bValueWeightChange: true
        })
    }
    // 关键词处理
    KeywordsProcess = (strKeywords) => {
        let newKeywords = "";
        for (let i = 0; i < strKeywords.length; i++) {
            if (strKeywords[i] !== " ") {
                if (i === strKeywords.length - 1)
                    newKeywords += strKeywords[i];
                else
                    newKeywords += strKeywords[i] + "%";
            }
        }
        return newKeywords;
    }
    // 搜索库区信息名
    StorageDeliverySearch = () => {
        let strKeywords = this.refs.search.state.value;
        if (strKeywords === undefined)
            strKeywords = "";
        if (strKeywords.trim() === "") {
            this.GetDeliveryOrder();
        } else {
            this.setState({
                ...this.state,
                bLoading: true,
                selectedRowKeys: [],
                btnControlDisable: [false, true, true]
            });
            axios('/SearchDeliveryOrder', { params: { name: this.KeywordsProcess(strKeywords) } })
                .then(({ data }) => {
                    this.arrTableData = this.OrganizeDeliveryOrderData(data);
                    this.setState({
                        ...this.state,
                        bLoading: false,
                        bShowDlg: false,
                        selectedRowKeys: []
                    });
                }).catch(function (error) { console.log(error); });
        }
    }
    // 点击了库区信息过滤器
    DeliveryTypeDropDownClicked = (index) => {
        this.setState({
            ...this.state,
            iType: index
        });
    }
    // 点击了库位类型过滤器
    DeliveryStatusDropDownClicked = (index) => {
        this.setState({
            ...this.state,
            iStatus: index
        });
    }
    // 箱子ID检查
    BoxIDCheck = (iBoxKey) => {
        for (let i = 0; i < this.arrItemInfo.length; i++) {
            if (iBoxKey === this.arrItemInfo[i].key)
                return false;
        }
        return true;
    }
    // 新增一个货箱
    AddBox = () => {
        let iBoxKey = Math.floor(Math.random() * 9999);
        while (!this.BoxIDCheck(iBoxKey)) {
            iBoxKey = Math.floor(Math.random() * 9999);
        }
        let newBox = [{ box: iBoxKey, sku: '', name: '', num: 0, num_check: 0, status: 0 }];
        this.arrItemInfo.splice(0, 0, newBox);
        // this.arrBoxInfo.push(newBox);
        this.setState({
            ...this.state,
            bAddNew: true
        })
    }
    // 新增一个SKU
    AddSKU = (box_index) => {
        let newSKU = { box: this.arrItemInfo[box_index][0].box, sku: '', name: '', num: 0 };

        this.arrItemInfo[box_index].splice(0, 0, newSKU);
        // this.arrBoxInfo[box_index].push(newSKU);
        this.setState({
            ...this.state,
            bAddNew: true
        })
    }
    // 物品信息编辑
    SKUCheckNumChange = (e) => {
        let iCheckNum = parseInt(e.target.value, 10);
        if (isNaN(iCheckNum)) {
            this.arrItemInfo[this.iSKUItemIndex].num_check = e.target.value;
        } else {
            this.arrItemInfo[this.iSKUItemIndex].num_check = iCheckNum;
            if (iCheckNum === this.arrItemInfo[this.iSKUItemIndex].num)
                this.bDownAble = true;
        }
        this.setState({
            ...this.state,
            bCheckUpdate: true
        });
    }
    // 删除指定箱子信息
    RemoveBox = (box_index) => {
        if (this.arrItemInfo.length <= 1) {
            message.warning("每单预报至少一箱货物！");
            return;
        }
        this.arrItemInfo.splice(box_index, 1);
        this.setState({
            ...this.state,
            bAddNew: true
        })
    }
    // 删除指定物品信息
    RemoveItem = (box_index, item_index) => {
        if (this.arrItemInfo[box_index].length <= 1) {
            message.warning("每箱至少包含一种货物！");
            return;
        }
        this.arrItemInfo[box_index].splice(item_index, 1);
        this.setState({
            ...this.state,
            bAddNew: true
        })
    }
    // 货物总量统计
    TotalItemNumCalculate = () => {
        this.iTotalSKUNum = 0;
        this.iTotalItemNum = 0;
        for (let i = 0; i < this.arrItemInfo.length; i++) {
            this.iTotalSKUNum += this.arrItemInfo[i].length;
            for (let j = 0; j < this.arrItemInfo[i].length; j++) {
                this.iTotalItemNum += parseInt(this.arrItemInfo[i][j].num, 10);
            }
        }
    }
    // 每向货物统计
    BoxItemNumCalculate = (box_index) => {
        let iItemNum = 0;
        for (let j = 0; j < this.arrItemInfo[box_index].length; j++) {
            iItemNum += parseInt(this.arrItemInfo[box_index][j].num, 10);
        }
        return iItemNum;
    }
    // 提交数据前检查货物信息
    BoxInfoCheck = () => {
        for (let i = 0; i < this.arrItemInfo.length; i++) {
            for (let j = 0; j < this.arrItemInfo[i].length; j++) {
                if (this.arrItemInfo[i][j].num <= 0) {
                    message.warning('有货物的数量为0，请检查！');
                    return false;
                }
                let bSKUFound = false;
                for (let k = 0; k < global.arrSKUData.length; k++) {
                    if (this.arrItemInfo[i][j].sku === global.arrSKUData[k].sku) {
                        bSKUFound = true;
                        break;
                    }
                }
                if (!bSKUFound) {
                    message.warning('有货物的SKU不存在，请检查！');
                    return false;
                }
            }
        }
        return true;
    }
    // 点击箱子条形码时的默认屏蔽
    BoxBarcodeClick = (event) => {
        event.preventDefault();
    }
    // 对比/查验当前扫描SKU编码
    ScanSKUCheck = () => {
        if (this.strScanInput.length <= 5)
            return;
        let bFoundInventory = false;
        for (let i = 0; i < this.arrCurrentInventoryRecord.length; i++) {
            if (this.arrCurrentInventoryRecord[i].sku === this.strScanInput) {
                bFoundInventory = true;
                this.iSKUInventoryIndex = i;
                break;
            }
        }
        let bFoundItem = false;
        for (let i = 0; i < this.arrItemInfo.length; i++) {
            if (this.arrItemInfo[i].sku === this.strScanInput) {
                bFoundItem = true;
                this.iSKUItemIndex = i;
                break;
            }
        }
        // 库存记录和预报单中都找到了该SKU的情况下
        if (bFoundInventory && bFoundItem) {
            let iCurrentCheckNum = parseInt(this.arrItemInfo[this.iSKUItemIndex].num_check, 10);
            if (isNaN(iCurrentCheckNum))
                iCurrentCheckNum = 0;
            iCurrentCheckNum++;
            if (iCurrentCheckNum >= this.arrItemInfo[this.iSKUItemIndex].num) {
                iCurrentCheckNum = this.arrItemInfo[this.iSKUItemIndex].num;
                this.bDownAble = true;
            }
            this.arrItemInfo[this.iSKUItemIndex].num_check = iCurrentCheckNum;
            this.strScanInput = "";
            this.setState({
                ...this.state,
                bCheckUpdate: true
            })
        }
        if (!bFoundInventory && !bFoundItem && this.strScanInput.length > 20) {
            this.strScanInput = "";
        }
    }
    // 某个SKU数量的查验结果
    SKUCheckResult = (i, j) => {
        if (parseInt(this.arrItemInfo[i][j].num_check, 10) === parseInt(this.arrItemInfo[i][j].num, 10)) {
            return 1;
        } else {
            if (parseInt(this.arrItemInfo[i][j].num_check, 10) > parseInt(this.arrItemInfo[i][j].num, 10))
                return -1;
            else
                return 0;
        }
    }
    // 点击了某个SKU数量查验框
    SKUInputClick = (i, j) => {
        this.strScanInput = "";
        this.iBoxCheckIndex = parseInt(i, 10);
        this.iSKUInventoryIndex = parseInt(j, 10);
        this.setState({
            ...this.state,
            bCheckUpdate: true
        })
    }
    // 所有SKU查验结果检查
    BoxCheckResult = (box) => {
        let bCheckComplete = true;
        for (let i = 0; i < box.length; i++) {
            if (parseInt(box[i].num_check, 10) !== parseInt(box[i].num, 10)) {
                bCheckComplete = false;
            } else {
                if (box[i].status < 2)
                    box[i].status = 2;
            }
        }
        return bCheckComplete;
    }
    // 保存SKU查验结果/下架指定的SKU
    SaveCheckResult = () => {
        if (!this.bDownAble)
            return;
        // 保存SKU查验结果
        if (this.state.iCheckType === 1) {
            let bAllChecked = true;
            for (let i = 0; i < this.arrItemInfo.length; i++) {
                for (let j = 0; j < this.arrItemInfo[i].length; j++) {
                    if (parseInt(this.arrItemInfo[i][j].num, 10) !== parseInt(this.arrItemInfo[i][j].num_check, 10)) {
                        bAllChecked = false;
                        break;
                    }
                }
            }
            message.loading({ content: '正在保存记录……', key: g_strMessageKey });
            if (bAllChecked) {
                this.objCurrentDeliveryOrder.status = 3;
                axios.post('/UpdateDeliveryOrderStatus', { delivery: this.objCurrentDeliveryOrder.code, status: this.objCurrentDeliveryOrder.status })
                    .then(({ data }) => {
                        this.setState({
                            ...this.state,
                            iStatus: this.objCurrentDeliveryOrder.status
                        });
                        axios.post('/UpdateDeliveryBoxInfo', { delivery: this.objCurrentDeliveryOrder.code, box: this.arrItemInfo[this.iBoxCheckIndex], sku: -1 })
                            .then(({ data }) => {
                                message.info({ content: '信息已保存！', key: g_strMessageKey, duration: 2 });
                                this.bDownAble = false;
                                this.setState({
                                    ...this.state,
                                    bCheckUpdate: true
                                })
                            }).catch(function (error) { console.log(error); });
                    }).catch(function (error) { console.log(error); });
            } else {
                axios.post('/UpdateDeliveryBoxInfo', { delivery: this.objCurrentDeliveryOrder.code, box: this.arrItemInfo[this.iBoxCheckIndex], sku: -1 })
                    .then(({ data }) => {
                        message.info({ content: '信息已保存！', key: g_strMessageKey, duration: 2 });
                        this.bDownAble = false;
                        this.setState({
                            ...this.state,
                            bCheckUpdate: true
                        })
                    }).catch(function (error) { console.log(error); });
            }
        }
        // 下架指定SKU
        if (this.state.iCheckType === 2) {
            let objSKUUPRecord = {
                store_id: this.props.objStoreInfo.id,
                user_id: this.objCurrentDeliveryOrder.user_id,
                user_name: this.objCurrentDeliveryOrder.user_name,
                delivery: this.objCurrentDeliveryOrder.code,
                sku: this.arrItemInfo[this.iBoxCheckIndex][this.iSKUInventoryIndex].sku,
                name: this.arrItemInfo[this.iBoxCheckIndex][this.iSKUInventoryIndex].name,
                num: this.arrItemInfo[this.iBoxCheckIndex][this.iSKUInventoryIndex].num,
                box: this.arrItemInfo[this.iBoxCheckIndex][this.iSKUInventoryIndex].box,
                position_code: this.strCurrentScanPosition,
                position_id: this.iCurrentScanPositionID
            }
            message.loading({ content: '正在下架货物……', key: g_strMessageKey });
            axios.post('/UpdateSKUUpRecord', { objSKUUPRecord: objSKUUPRecord })
                .then(({ data }) => {
                    if (data.status === 1) {
                        message.info({ content: '下架完成！', key: g_strMessageKey, duration: 2 });
                        this.strCurrentScanPosition = "";
                        this.iCurrentScanPositionID = 0;
                        this.bDownAble = false;
                        this.setState({
                            ...this.state,
                            bShowDlg: false
                        })
                        // 更新该出库单响应货箱和SKU的状态为下架
                        this.arrItemInfo[this.iBoxCheckIndex][this.iSKUInventoryIndex].status = 3;

                        let bAllUp = true;
                        for (let i = 0; i < this.arrItemInfo.length; i++) {
                            for (let j = 0; j < this.arrItemInfo[i].length; j++) {
                                if (parseInt(this.arrItemInfo[i][j].status, 10) < 3) {
                                    bAllUp = false;
                                    break;
                                }
                            }
                        }
                        if (bAllUp) {
                            console.log("出库单所有货物均以下架，更新出库单状态为已下架！");
                            this.objCurrentDeliveryOrder.status = 4;
                            axios.post('/UpdateDeliveryOrderStatus', { delivery: this.objCurrentDeliveryOrder.code, status: this.objCurrentDeliveryOrder.status })
                                .then(({ data }) => {
                                }).catch(function (error) { console.log(error); });
                        }
                        axios.post('/UpdateDeliveryBoxInfo', { delivery: this.objCurrentDeliveryOrder.code, box: this.arrItemInfo[this.iBoxCheckIndex], sku: this.iSKUInventoryIndex })
                            .then(({ data }) => {
                            }).catch(function (error) { console.log(error); });
                    } else {
                        if (data.status === -2)
                            message.warning({ content: '已有相应的下架记录，请检查！', key: g_strMessageKey, duration: 2 });
                        else
                            message.warning({ content: '下架失败！', key: g_strMessageKey, duration: 2 });
                    }
                }).catch(function (error) { console.log(error); });
        }
    }
    // 下架指定的SKU
    DownSKUConfirm = () => {
        if (!this.bDownAble)
            return;
        // 下架指定SKU
        if (this.state.iCheckType === 0) {
            let objSKUDownRecord = {
                store_id: this.props.objStoreInfo.id,
                user_id: this.objCurrentDeliveryOrder.user_id,
                user_name: this.objCurrentDeliveryOrder.user_name,
                delivery: this.objCurrentDeliveryOrder.code,
                sku: this.arrItemInfo[this.iSKUItemIndex].sku,
                name: this.arrItemInfo[this.iSKUItemIndex].name,
                num: this.arrItemInfo[this.iSKUItemIndex].num,
                position_code: this.strCurrentScanPosition,
                position_id: this.iCurrentScanPositionID
            }
            message.loading({ content: '正在下架货物……', key: g_strMessageKey });
            axios.post('/UpdateSKUDownRecord', { objSKUDownRecord: objSKUDownRecord })
                .then(({ data }) => {
                    console.log('UpdateSKUDownRecord : ', data);
                    if (data.status === 1) {
                        message.info({ content: '下架完成！', key: g_strMessageKey, duration: 2 });
                        this.strCurrentScanPosition = "";
                        this.iCurrentScanPositionID = 0;
                        this.bDownAble = false;
                        this.setState({
                            ...this.state,
                            bShowDlg: false
                        })
                        // 更新该出库单对应货箱和SKU的状态为下架
                        this.arrItemInfo[this.iSKUItemIndex].status = 1;
                        axios.post('/UpdateDeliveryItemStatus', { objItemInfo: this.arrItemInfo[this.iSKUItemIndex] })
                            .then(({ data }) => {
                                console.log("UpdateDeliveryItemStatus : ", data);
                            }).catch(function (error) { console.log(error); });

                        // 全部下架完成
                        let bAllDown = true;
                        for (let i = 0; i < this.arrItemInfo.length; i++) {
                            if (parseInt(this.arrItemInfo[i].status, 10) < 1) {
                                bAllDown = false;
                                break;
                            }
                        }
                        if (bAllDown) {
                            console.log("出库单所有货物均已下架，更新出库单状态为已下架！");
                            this.objCurrentDeliveryOrder.status = 2;
                            axios.post('/UpdateDeliveryOrderStatus', { delivery: this.objCurrentDeliveryOrder.code, status: this.objCurrentDeliveryOrder.status })
                                .then(({ data }) => {
                                    console.log("出库单状态更新！", data);
                                }).catch(function (error) { console.log(error); });
                        } else {
                            console.log("还未完全下架，状态更新为已接单！");
                            this.objCurrentDeliveryOrder.status = 1;
                            axios.post('/UpdateDeliveryOrderStatus', { delivery: this.objCurrentDeliveryOrder.code, status: this.objCurrentDeliveryOrder.status })
                                .then(({ data }) => {
                                    console.log("出库单状态更新！", data);
                                }).catch(function (error) { console.log(error); });
                        }
                    } else {
                        if (data.status === -2)
                            message.warning({ content: '已有相应的下架记录，请检查！', key: g_strMessageKey, duration: 2 });
                        else {
                            if (data.status === -3)
                                message.warning({ content: '未找到库存记录！', key: g_strMessageKey, duration: 2 });
                            else
                                message.warning({ content: '下架失败！', key: g_strMessageKey, duration: 2 });
                        }
                    }
                }).catch(function (error) { console.log(error); });
        }
        // 保存SKU查验结果
        // if (this.state.iCheckType === 0) {
        //     let bAllChecked = true;
        //     for (let i = 0; i < this.arrItemInfo.length; i++) {
        //         for (let j = 0; j < this.arrItemInfo[i].length; j++) {
        //             if (parseInt(this.arrItemInfo[i][j].num, 10) !== parseInt(this.arrItemInfo[i][j].num_check, 10)) {
        //                 bAllChecked = false;
        //                 break;
        //             }
        //         }
        //     }
        //     message.loading({ content: '正在保存记录……', key: g_strMessageKey });
        //     if (bAllChecked) {
        //         this.objCurrentDeliveryOrder.status = 3;
        //         axios.post('/UpdateDeliveryOrderStatus', { delivery: this.objCurrentDeliveryOrder.code, status: this.objCurrentDeliveryOrder.status })
        //             .then(({ data }) => {
        //                 this.setState({
        //                     ...this.state,
        //                     iStatus: this.objCurrentDeliveryOrder.status
        //                 });
        //                 axios.post('/UpdateDeliveryBoxInfo', { delivery: this.objCurrentDeliveryOrder.code, box: this.arrItemInfo[this.iBoxCheckIndex], sku: -1 })
        //                     .then(({ data }) => {
        //                         message.info({ content: '信息已保存！', key: g_strMessageKey, duration: 2 });
        //                         this.bDownAble = false;
        //                         this.setState({
        //                             ...this.state,
        //                             bCheckUpdate: true
        //                         })
        //                     }).catch(function (error) { console.log(error); });
        //             }).catch(function (error) { console.log(error); });
        //     } else {
        //         axios.post('/UpdateDeliveryBoxInfo', { delivery: this.objCurrentDeliveryOrder.code, box: this.arrItemInfo[this.iBoxCheckIndex], sku: -1 })
        //             .then(({ data }) => {
        //                 message.info({ content: '信息已保存！', key: g_strMessageKey, duration: 2 });
        //                 this.bDownAble = false;
        //                 this.setState({
        //                     ...this.state,
        //                     bCheckUpdate: true
        //                 })
        //             }).catch(function (error) { console.log(error); });
        //     }
        // }
    }
    // 出库确认
    DeliveryConfirm = () => {
        this.arrItemInfo = [{ sku: '', name: '', num: 0, num_check: 0, status: 0 }];
        this.bDownAble = false;
        this.strCurrentScanInput = "";
        this.strCurrentScanCode = "";
        this.iSKUInventoryIndex = -1;
        this.iSKUItemIndex = -1;
        this.strCurrentScanPosition = "";
        this.iCurrentScanPositionID = 0;
        this.iCurrentScanPositionIndex = 0;
        this.arrCurrentInventoryRecord = [];
        if (this.objCurrentDeliveryOrder.status >= 3) {
            message.warning("此单号已出库，不能重复执行此操作，请重新核对！");
            this.CurrentDeliveryOrderInit();
            this.setState({
                ...this.state,
                bShowDlg: false
            })
        } else {
            this.objCurrentDeliveryOrder.status = 3;
            axios.post('/UpdateDeliveryOrderStatus', { delivery: this.objCurrentDeliveryOrder.code, status: this.objCurrentDeliveryOrder.status })
                .then(({ data }) => {
                    message.info("出库完成！");
                    console.log("出库单状态更新！", data);
                    this.CurrentDeliveryOrderInit();
                    this.setState({
                        ...this.state,
                        bShowDlg: false
                    })
                }).catch(function (error) { console.log(error); });
        }
    }
    // 对比当前的箱子
    ScanBoxCheck = () => {
        if (this.strScanInput.length > 19 && this.strScanInput.indexOf('XYJ') >= 0 && this.strScanInput.indexOf('-') >= 0 && this.strScanInput[this.strScanInput.length - 1] === "B") {
            this.strScanInput = this.strScanInput.substring(this.strScanInput.length - 19, this.strScanInput.length);
        }
        // 下架扫码
        if (this.state.iCheckType === 0) {
            // 检查是否扫描完成（出库单号）
            if (this.strScanInput.indexOf('-') >= 0 && this.strScanInput[this.strScanInput.length - 1] === "B") {
                let strDeliveryOrder = this.strScanInput.split('-')[0];
                this.CurrentDeliveryOrderInit();
                this.strScanInput = "";
                this.iScanMonitor = 0;
                let bFound = false;
                for (let i = 0; i < this.arrTableData.length; i++) {
                    if (this.arrTableData[i].code === strDeliveryOrder) {
                        this.objCurrentDeliveryOrder = this.arrTableData[i];
                        bFound = true;
                        break;
                    }
                }
                if (!bFound) {
                    message.warning("未找到响应的出库记录和货箱号，请重新扫描有效的条形码！");
                    return;
                }
                if (!this.state.bShowDlg)
                    this.bDownAble = false;
                // if (this.objCurrentDeliveryOrder.status > 1) {
                //     this.ShowDlg(1);
                // } else {
                message.loading({ content: '出库单核对中……', key: g_strMessageKey });
                axios('/GetDeliveryOrderItemInfo', { params: { delivery: strDeliveryOrder } })
                    .then(({ data }) => {
                        this.arrItemInfo = [];
                        if (data.length > 0) {
                            this.arrItemInfo = data;
                            for (let i = 0; i < data.length; i++) {
                                for (let j = 0; j < this.arrCurrentInventoryRecord.length; j++) {
                                    if (this.arrCurrentInventoryRecord[j].sku === data[i].sku) {
                                        this.arrCurrentInventoryRecord[j].num_need = data[i].num;
                                    }
                                }
                            }
                        }
                        message.info({ content: "出库单信息核对完毕！", key: g_strMessageKey, duration: 2 });
                        this.setState({
                            ...this.state,
                            bLoading: false,
                            bDeliveryBox: true
                        });
                    }).catch(function (error) { console.log(error); });
                // }
            }
        }
        // 出库扫码
        if (this.state.iCheckType === 1) {
            // 检查是否扫描完成（出库单号）
            if (this.strScanInput.indexOf('-') >= 0 && this.strScanInput[this.strScanInput.length - 1] === "B") {
                let strDeliveryOrder = this.strScanInput.split('-')[0];
                this.CurrentDeliveryOrderInit();
                this.strScanInput = "";
                this.iScanMonitor = 0;
                let bFound = false;
                for (let i = 0; i < this.arrTableData.length; i++) {
                    if (this.arrTableData[i].code === strDeliveryOrder) {
                        this.objCurrentDeliveryOrder = this.arrTableData[i];
                        bFound = true;
                        break;
                    }
                }
                if (!bFound) {
                    message.warning("未找到响应的出库记录和货箱号，请重新扫描有效的条形码！");
                    return;
                }
                if (!this.state.bShowDlg)
                    this.bDownAble = false;

                message.loading({ content: '出库单核对中……', key: g_strMessageKey });
                axios('/GetDeliveryOrderItemInfo', { params: { delivery: strDeliveryOrder } })
                    .then(({ data }) => {
                        this.arrItemInfo = [];
                        if (data.length > 0) {
                            this.arrItemInfo = data;
                        }
                        message.info({ content: "出库单信息核对完毕！", key: g_strMessageKey, duration: 2 });
                        this.setState({
                            ...this.state,
                            bLoading: false,
                            bShowDlg: true,
                            iDlgType: 1
                        });
                    }).catch(function (error) { console.log(error); });
            }
        }
    }
    // 对比当前的货架
    ScanPositionCheck = () => {
        if (/*this.strScanInput.indexOf('B') >= 0 ||*/ this.strScanInput.length >= 20) {
            this.strScanInput = "";
            return;
        }
        if (this.strScanInput.length > 5 && this.strScanInput.indexOf('.p') >= 0 && this.strScanInput[this.strScanInput.length - 1] === "p") {
            // 检查货位是否存在
            let bPosition = false;
            for (let i = 0; i < global.arrPositionList.length; i++) {
                if (this.strScanInput === global.arrPositionList[i].code) {
                    console.log("找到了匹配的库位！");
                    this.iCurrentScanPositionID = global.arrPositionList[i].id;
                    bPosition = true;
                    break;
                }
            }
            if (bPosition) {
                this.strCurrentScanPosition = this.strScanInput;
                this.strScanInput = "";
                this.setState({
                    ...this.state,
                    bCheckUpdate: true
                });
            } else {
                this.strScanInput = "";
                return;
            }
            console.log("this.strCurrentScanPosition : " + this.strCurrentScanPosition);
            console.log("this.iCurrentScanPositionID : " + this.iCurrentScanPositionID);

            // 检查当前库位的库存信息
            this.setState({
                ...this.state,
                bLoading: true,
                bCheckUpdate: true
            });
            axios('/GetInventoryRecord', { params: { store_id: this.props.objStoreInfo.id, position_code: this.strCurrentScanPosition, sku: "", user_name: "" } })
                .then(({ data }) => {
                    console.log("GetInventoryRecord : ", data);
                    this.arrCurrentInventoryRecord = [];
                    if (data.length > 0) {
                        // 锁定库存数处理
                        for (let i = 0; i < data.length; i++) {
                            if (data[i].num_lock === null || data[i].num_lock === "")
                                data[i].num_lock = 0;
                        }
                        this.arrCurrentInventoryRecord = data;
                    }
                    message.success({ content: "库存信息加载完成！", key: g_strMessageKey, duration: 2 });
                    this.setState({
                        ...this.state,
                        bLoading: false,
                        bShowDlg: true
                    });
                }).catch(function (error) { console.log(error); });
        }
    }
    // 切换扫描操作方式
    CheckTypeChange = (e) => {
        this.strCurrentScanPosition = "";
        this.strScanInput = "";
        this.setState({
            ...this.state,
            iCheckType: e.target.value
        })
    }
    // 切换查验操作方式
    EditTypeChange = (e) => {
        if (this.objCurrentDeliveryOrder.code === null || this.objCurrentDeliveryOrder.code === "") {
            message.warning("请先扫描出库单编码！");
            return;
        }
        if (this.iSKUInventoryIndex < 0) {
            message.warning("请先扫描需要下架的SKU条形码！");
            return;
        }
        this.setState({
            ...this.state,
            iEditType: e.target.value
        })
    }
    // 扫码/键盘输入监听
    ScanInputMonitor = (e) => {
        // 查验数量时，处于编辑状态中，扫码无效
        // if (this.state.iCheckType === 1 && this.state.iEditType === 1 && this.state.bShowDlg)
        //     return;
        this.strCurrentScanCode = e.which;
        this.timeCurrent = new Date().getTime();
        if (this.strCurrentScanCode != null && this.timeLast != null && this.timeCurrent - this.timeLast <= 30) {
            // if (this.iScanMonitor == 1) {
            //     console.log("扫描2");
            //     this.iScanMonitor = 2;
            //     // if (this.strCurrentScanCode.length === 1)
            //     //     this.strCurrentScanCode = "X";
            // }
        } else if (this.strLastScanCode != null && this.timeLast != null && this.timeCurrent - this.timeLast > 100) {
            // if (this.iScanMonitor == 0) {
            //     console.log("扫描1");
            //     this.iScanMonitor = 1;
            //     this.strScanInput = String.fromCharCode(this.strCurrentScanCode);
            //     return;
            // }
        }
        this.strLastScanCode = this.strCurrentScanCode;
        this.timeLast = this.timeCurrent;
        this.strScanInput += String.fromCharCode(this.strCurrentScanCode);

        // 扫描库位，下架SKU Step 1
        if (!this.state.bShowDlg && this.state.iCheckType === 0) {
            let bPosition = false;
            const input = document.getElementById("position");
            if (input) {
                if (input.value !== null && input.value !== "") {
                    if (input.value.indexOf('.p') > 0) {
                        bPosition = true;
                    }
                }
            }
            if (!bPosition)
                this.ScanPositionCheck();
        }
        // 扫描库位，下架SKU Step 2
        if (this.state.bShowDlg && this.state.iCheckType === 0) {
            // 已经扫过打包合箱码
            if (this.state.bDeliveryBox) {
                this.ScanSKUCheck();
            }
            // 还没扫描打包合箱码
            else {
                if (this.strCurrentScanPosition !== null && this.strCurrentScanPosition !== "" && this.strScanInput.length > 10) {
                    this.ScanBoxCheck();
                }
            }
        }

        // 扫码出库
        if (!this.state.bShowDlg && this.state.iCheckType === 1) {
            if (this.strScanInput.length >= 10) {
                this.ScanBoxCheck();
            }
        }


    }

    /* React自带函数 */
    // 卸载异步操作设置状态
    componentWillUnmount() {
        clearTimeout(this.timeouter)
        this.setState = (state, callback) => {
            return;
        };
        window.removeEventListener('keypress', this.ScanInputMonitor);
    }
    // 渲染完毕……
    componentDidMount() {

        window.addEventListener('keypress', this.ScanInputMonitor);

        this.GetDeliveryOrder();
        if (global.arrSKUData.length <= 0) {
            axios('/GetSKU', { params: { store_id: this.props.objStoreInfo.id, user_id: this.props.iUserID, level: this.props.iUserLevel } })
                .then(({ data }) => {
                    global.arrSKUData = data;
                }).catch(function (error) { console.log(error); });
        }
        if (global.arrPositionList.length <= 0) {
            axios('/GetInventoryPosition', { params: { store_id: this.props.objStoreInfo.id } })
                .then(({ data }) => {
                    global.arrPositionList = data;
                }).catch(function (error) { console.log(error); });
        }
        if (global.arrPositionList.length <= 0) {
            axios('/GetInventoryPosition', { params: { store_id: this.props.objStoreInfo.id } })
                .then(({ data }) => {
                    global.arrPositionList = data;
                }).catch(function (error) { console.log(error); });
        }
    }
    // 渲染函数
    render() {

        let uiPosition = null;
        if (this.state.iCheckType === 0) {
            uiPosition = (
                <div style={{ color: 'white', marginLeft: '10px' }}>
                    {"库位："}
                    <Input placeholder="不要手动输入，请扫描" id="position" ref="position" style={{ width: '200px', marginLeft: '10px' }} disabled={true}
                        value={this.strCurrentScanPosition} />
                </div>
            );
        }
        let uiTitleArea = (
            < div className="storage_contents_title" >
                <Radio.Group className="store_image_file_type" onChange={this.CheckTypeChange}
                    value={this.state.iCheckType}>
                    <Radio value={0} style={{ color: 'white' }}>货物下架</Radio>
                    <Radio value={1} style={{ color: 'white' }}>打包出库</Radio>
                </Radio.Group>
                {uiPosition}
            </div >
        );

        // 如果界面上需要显示的内容已经从数据库获得
        if (!this.state.bLoading) {
            let uiModal = null;
            if (this.state.bShowDlg) {
                this.TotalItemNumCalculate();
                // 出库单编辑弹窗
                let strTitle = "下架SKU";
                if (this.state.iDlgType === 1)
                    strTitle = "打包出库";
                let strButtonStyle = "storage_edit_info_add_button";
                if (!this.bDownAble)
                    strButtonStyle = "storage_edit_info_add_button_disable";
                let uiDownButton = null;
                if (this.state.iCheckType === 0)
                    uiDownButton = (
                        <div disabled={this.bDownAble} style={{ marinLeft: 'auto', marginRight: '10px', marginTop: '10px' }}
                            className={strButtonStyle} onClick={this.DownSKUConfirm.bind(this)}>下 架</div>
                    );
                let uiEditType = (
                    <Radio.Group className="store_image_file_type" onChange={this.EditTypeChange}
                        value={this.state.iEditType}>
                        <Radio value={0} style={{ color: 'grey' }}>扫码下架</Radio>
                        <Radio value={1} style={{ color: 'grey' }}>手动编辑</Radio>
                    </Radio.Group>
                );

                let uiBarCode = (
                    <div style={{ color: "darkcyan" }}>*请扫描出单号(打包合箱码)*</div>
                );
                if (this.state.bShowDlg && this.objCurrentDeliveryOrder.code !== null && this.objCurrentDeliveryOrder.code !== "") {
                    let strBoxBarcode = this.objCurrentDeliveryOrder.code + "-B";
                    uiBarCode = (
                        <a href="请右键点击并保存该条形码" onClick={e => this.OrderBarcodeClick(e)} download={strBoxBarcode} >
                            <Barcode value={strBoxBarcode} renderer={'img'} displayValue={true} width={2} height={60} fontSize={15} background={'rgba(0, 0, 0, 0)'} />
                        </a>
                    );
                }

                if (this.state.iCheckType === 0) {
                    uiModal = (
                        <Modal title={strTitle} width={900} visible={this.state.bShowDlg} footer={null} onCancel={this.handleCancel} >
                            <div className="user_edit_info_row storage_edit_info_black_title">
                                {uiEditType}{uiBarCode}</div>
                            {this.arrCurrentInventoryRecord.map((sku, sku_index) => {
                                let uiDownIcon = null;
                                if (this.iSKUInventoryIndex === sku_index)
                                    uiDownIcon = (
                                        <Icon type="vertical-align-bottom" style={{ color: 'darkcyan' }} />
                                    );
                                let bEditDisable = true;
                                if (this.state.iEditType === 1)
                                    bEditDisable = false;
                                let iCheckNum = 0;
                                if (this.iSKUItemIndex >= 0 && sku.sku === this.arrItemInfo[this.iSKUItemIndex].sku) {
                                    iCheckNum = this.arrItemInfo[this.iSKUItemIndex].num_check;
                                }
                                if (this.iSKUItemIndex >= 0 && sku.sku !== this.arrItemInfo[this.iSKUItemIndex].sku)
                                    bEditDisable = true;
                                return (
                                    <div className="user_edit_info_row" key={sku_index} >
                                        <div className="storage_edit_box_info_title">SKU：</div>
                                        <Input className="storage_edit_box_info_input" ref={"sku_" + sku_index} disabled
                                            defaultValue={sku.sku} value={sku.sku} />
                                        <div className="storage_edit_box_info_title">名称：</div>
                                        <Input className="storage_edit_box_info_input" ref={"name_" + sku_index}
                                            defaultValue={sku.name} value={sku.name} disabled />
                                        <div className="storage_edit_box_info_title">需要出库：</div>
                                        <Input className="storage_edit_box_info_input_short" ref={"num_" + sku_index} disabled
                                            defaultValue={sku.num_need} value={sku.num_need} />
                                        <div className="storage_edit_box_info_title">已下架：</div>
                                        <Input className="storage_edit_box_info_input_short" ref={"down_" + sku_index} disabled={bEditDisable}
                                            defaultValue={iCheckNum} value={iCheckNum} onChange={this.SKUCheckNumChange.bind(this)} />
                                        {uiDownIcon}
                                    </div>
                                )
                            })}
                            {uiDownButton}
                        </Modal >
                    );
                }
                if (this.state.iCheckType === 1) {
                    strButtonStyle = "storage_edit_info_add_button";
                    uiModal = (
                        <Modal title={strTitle} width={900} visible={this.state.bShowDlg} footer={null} onCancel={this.handleCancel} >
                            <div className="user_edit_info_row storage_edit_info_black_title">
                                {uiBarCode}</div>
                            {this.arrItemInfo.map((sku, sku_index) => {
                                return (
                                    <div className="user_edit_info_row" key={sku_index} >
                                        <div className="storage_edit_box_info_title">SKU：</div>
                                        <Input className="storage_edit_box_info_input" ref={"sku_" + sku_index} disabled
                                            defaultValue={sku.sku} value={sku.sku} />
                                        <div className="storage_edit_box_info_title">名称：</div>
                                        <Input className="storage_edit_box_info_input" ref={"name_" + sku_index}
                                            defaultValue={sku.name} value={sku.name} disabled />
                                        <div className="storage_edit_box_info_title">出库数量：</div>
                                        <Input className="storage_edit_box_info_input_short" ref={"num_" + sku_index} disabled
                                            defaultValue={sku.num} value={sku.num} />
                                    </div>
                                )
                            })}
                            <div style={{ marinLeft: 'auto', marginRight: '10px', marginTop: '10px' }}
                                className={strButtonStyle} onClick={this.DeliveryConfirm.bind(this)}>出 库</div>
                        </Modal >
                    );
                }
            }
            let strScanTips = "下架流程：1. 扫描库位上的条形编码；2.逐个扫描需要下架SKU的条形编码";
            if (this.state.iCheckType === 1)
                strScanTips = "出库流程：扫描货箱的条形编码";
            return (
                <div className="storage_contents_area">
                    {uiTitleArea}
                    {uiModal}
                    <div style={{ margin: '20px', marginLeft: '45px', color: 'darkcyan' }}>{'* 扫码前，请先用鼠标点击本页面的灰色区域 *'}</div>
                    <div className="storage_scan_tips">{strScanTips}</div>
                </div >
            );
        } else {
            return (
                <div className="storage_contents_area">
                    {uiTitleArea}
                    <Spin className="manage_empty_loading" size="large" tip="加载中……" />
                </div>
            );
        }
    }

}
export default DeliveryChecking;
