import React, { Component } from 'react';
import axios from 'axios';

import Spin from 'antd/es/spin';
import Button from 'antd/es/button';
import Input from 'antd/es/input';
import Menu from 'antd/es/menu';
import Dropdown from 'antd/es/dropdown';
import Icon from 'antd/es/icon';
import Modal from 'antd/es/modal';
import Checkbox from 'antd/es/checkbox';
import message from 'antd/es/message';
import Radio from 'antd/es/radio';

import '../../../styles/Background/ego_manager.css';
import '../../../styles/Background/storage_manager.css';
import '../../../styles/Background/UI_Module/user_management.css';

const g_strMessageKey = 'updating';

// 表格
// const arrClientTableHeader = [
//     { title: '入库单号', dataIndex: 'code', width: 100, ellipsis: true },
//     { title: '类型', dataIndex: 'type_str', width: 75, ellipsis: true },
//     { title: '状态', dataIndex: 'status_str', width: 75, ellipsis: true },
//     { title: '物流', dataIndex: 'express_no', width: 100, ellipsis: true },
//     { title: '箱数', dataIndex: 'box_num', width: 50, ellipsis: true },
//     { title: '费用', dataIndex: 'service_fee', width: 50, ellipsis: true },
//     { title: '备注', dataIndex: 'comments', width: 150, ellipsis: true },
// ];

// const arrManagerTableHeader = [
//     { title: '入库单号', dataIndex: 'code', width: 100, ellipsis: true },
//     { title: '客户', dataIndex: 'user_name', width: 75, ellipsis: true },
//     { title: '类型', dataIndex: 'type_str', width: 75, ellipsis: true },
//     { title: '状态', dataIndex: 'status_str', width: 75, ellipsis: true },
//     { title: '物流', dataIndex: 'express_no', width: 125, ellipsis: true },
//     { title: '箱数', dataIndex: 'box_num', width: 50, ellipsis: true },
//     { title: '费用', dataIndex: 'service_fee', width: 50, ellipsis: true },
//     { title: '备注', dataIndex: 'comments', width: 150, ellipsis: true },
// ];

class EnteringChecking extends Component {
    constructor(props) {
        super();
        this.state = {
            btnControlDisable: [false, true, true],  // 操作按钮控制
            selectedRowKeys: [],  // Check here to configure the default column
            iDlgType: -1,
            bShowDlg: false,
            bLoading: true,
            bValueWeightChange: false,
            iStatus: 0,
            iType: 0,
            bCheckUpdate: false,
            iCheckType: 0,
            iEditType: 0
        };
        // 成员变量
        this.arrOgeTableData = [];
        this.arrTableData = [];  // 用于表格的数组
        this.objCurrentEnteringOrder = {};
        this.fValue = 0.0;
        this.fValueWeight = 0.0;
        this.fPriceWeight = 0.0;
        this.arrStatus = ['已预报', '运输中', '已签收', '已入库', '已上架', '作废'];
        this.arrType = ['自发预报', '物流预报', '退货预报'];
        this.arrBoxInfo = [
            [{ box: 0, sku: '', name: '', num: 0, num_check: 0, status: 0 }]
        ];
        this.iTotalSKUNum = 0;
        this.iTotalItemNum = 0;

        this.strScanInput = "";
        this.timeLast = new Date();
        this.timeCurrent = new Date();
        this.strLastScanCode = "";
        this.strCurrentScanCode = "";

        this.iScanMonitor = 0;
        this.iBoxID = 0;
        this.iBoxCheckIndex = 0;
        this.iSKUCheckIndex = 0;
        this.bSKUFound = false;

        this.bSaveAble = false;
        this.iBoxStatus = 0;
        this.strCurrentScanPosition = "";
        this.iCurrentScanPositionID = 0;
    }

    // 选中某行的响应函数
    selectRow = (record) => {
        const selectedRowKeys = [...this.state.selectedRowKeys];
        if (selectedRowKeys.indexOf(record.key) >= 0) {
            selectedRowKeys.splice(selectedRowKeys.indexOf(record.key), 1);
        } else {
            selectedRowKeys.push(record.key);
        }
        this.SelectedRowStateSetting(selectedRowKeys);
    }
    // 选中的行发生变化的响应函数
    onSelectedRowKeysChange = (selectedRowKeys) => {
        this.SelectedRowStateSetting(selectedRowKeys);
    }
    // 处理选中的表行行
    SelectedRowStateSetting = (selectedRowKeys) => {
        let btnControlDisable = [];
        // let btnCopyPasteDisable = [];
        switch (selectedRowKeys.length) {
            case 0: {
                btnControlDisable = [false, true, true];
                // btnCopyPasteDisable = [true, true];
                this.CurrentEnteringOrderInit();
                break;
            }
            case 1: {
                btnControlDisable = [false, false, false];
                // btnCopyPasteDisable = [false, true];
                for (let i = 0; i < this.arrTableData.length; i++) {
                    if (this.arrTableData[i].key === selectedRowKeys[0]) {
                        this.objCurrentEnteringOrder = this.arrTableData[i];
                        break;
                    }
                }
                break;
            }
            default: {
                btnControlDisable = [false, true, true];
                // btnCopyPasteDisable = [true, true];
                break;
            }
        }
        this.setState({
            ...this.state,
            selectedRowKeys,
            btnControlDisable: btnControlDisable
            // btnCopyPasteDisable: btnCopyPasteDisable
        });
    }
    // 关键词处理
    KeywordsProcess = (strKeywords) => {
        let newKeywords = "";
        for (let i = 0; i < strKeywords.length; i++) {
            if (strKeywords[i] !== " ") {
                if (i === strKeywords.length - 1)
                    newKeywords += strKeywords[i];
                else
                    newKeywords += strKeywords[i] + "%";
            }
        }
        return newKeywords;
    }
    // 搜索EnteringOrder名
    // UserNameSearch = () => {
    //     let strKeywords = this.refs.user_name_search.state.value;
    //     if (strKeywords === undefined)
    //         strKeywords = "";
    //     if (strKeywords.trim() === "") {
    //         this.GetEnteringOrder();
    //     } else {
    //         this.setState({
    //             ...this.state,
    //             bLoading: true,
    //             selectedRowKeys: [],
    //             btnControlDisable: [false, true, true]
    //         });
    //         axios('/SearchUsers', { params: { name: this.KeywordsProcess(strKeywords) } })
    //             .then(({ data }) => {
    //                 this.arrTableData = this.OrganizeEnteringOrderData(data);
    //                 this.setState({
    //                     ...this.state,
    //                     bLoading: false,
    //                     objFilter: {
    //                         strKeyword: strKeywords,
    //                         iCurrentUserStatus: 0,
    //                         iCurrentUserType: 0
    //                     }
    //                 });
    //             }).catch(function (error) { console.log(error); });
    //     }
    // }
    // 点击了EnteringOrder过滤器
    // UserTypeFilterClicked = (iUserType) => {
    //     let strTargetType = this.arrFilterUserType[iUserType];
    //     if (iUserType !== 0) {
    //         this.arrTableData = [];
    //         for (let i = 0; i < this.arrOgeTableData.length; i++) {
    //             if (strTargetType === this.arrOgeTableData[i].level) {
    //                 this.arrTableData.push(this.arrOgeTableData[i]);
    //             }
    //         }
    //     } else {
    //         this.arrTableData = this.arrOgeTableData;
    //     }
    //     if (this.refs.user_name_search !== undefined)
    //         this.refs.user_name_search.state.value = "";
    //     this.setState({
    //         ...this.state,
    //         objFilter: {
    //             strKeyword: "",
    //             iCurrentUserStatus: this.state.objFilter.iCurrentUserStatus,
    //             iCurrentUserType: iUserType
    //         }
    //     });
    // }
    // 点击了EnteringOrder状态过滤器
    // UserStatusFilterClicked = (iStatus) => {
    //     this.setState({
    //         ...this.state,
    //         objFilter: {
    //             strKeyword: "", iCurrentUserStatus: iStatus, iCurrentUserType: this.state.objFilter.iCurrentUserType
    //         }
    //     });
    // }
    /* 自定义成员函数 */
    //弹出对话框
    ShowDlg = (iDlgType, e) => {
        if (iDlgType === 0) {
            if (this.refs.code) {
                this.refs.code.state.value = "";
                this.refs.length.state.value = 0.0;
                this.refs.width.state.value = 0.0;
                this.refs.height.state.value = 0.0;
                this.refs.value.state.value = 0.0;
                this.refs.weight.state.value = 0.0;
                this.refs.express_no.state.value = "";
                this.refs.service_fee.state.value = "";
                this.refs.comments.state.value = "";
            }
            this.fValue = 0.0;
            this.fValueWeight = 0.0;
            this.fPriceWeight = 0.0;
            this.CurrentEnteringOrderInit();
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true,
                iStatus: 0,
                iType: 0
            });
        }
        if (iDlgType === 1) {
            if (this.refs.code) {
                this.refs.code.state.value = this.objCurrentEnteringOrder.code;
            }
            if (this.refs.length) {
                this.refs.length.state.value = this.objCurrentEnteringOrder.length;
                this.refs.width.state.value = this.objCurrentEnteringOrder.width;
                this.refs.height.state.value = this.objCurrentEnteringOrder.height;
                this.refs.value.state.value = this.objCurrentEnteringOrder.value;
                this.refs.weight.state.value = this.objCurrentEnteringOrder.weight;
                this.refs.value_weight.state.value = this.objCurrentEnteringOrder.value_weight;
                this.refs.service_fee.state.value = this.objCurrentEnteringOrder.service_fee;
                this.refs.express_no.state.value = this.objCurrentEnteringOrder.express_no;
                this.refs.comments.state.value = this.objCurrentEnteringOrder.comments;
            }
            this.fValue = parseFloat(this.objCurrentEnteringOrder.value);
            this.fValueWeight = parseFloat(this.objCurrentEnteringOrder.value_weight);
            this.fPriceWeight = parseFloat(this.objCurrentEnteringOrder.price_weight);
            message.loading({ content: '数据加载中……', key: g_strMessageKey });
            axios('/GetEnteringOrderItemInfo', { params: { entering: this.objCurrentEnteringOrder.code } })
                .then(({ data }) => {
                    message.success({ content: "货物信息加载完成！", key: g_strMessageKey, duration: 2 });
                    if (data.length > 0) {
                        let arrBoxInfo = [];
                        this.arrBoxInfo = [];
                        let arrItemInfo = [];
                        let iBox = data[0].box;
                        for (let i = 0; i < data.length; i++) {
                            if (data[i].box === iBox) {
                                if (data[i].box.num_check === null || data[i].box.num_check === "")
                                    data[i].box.num_check = 0;
                                arrItemInfo.push(data[i]);
                                if (i === data.length - 1) {
                                    this.arrBoxInfo.push(arrItemInfo);
                                }
                            } else {
                                this.arrBoxInfo.push(arrItemInfo);
                                arrBoxInfo.push(arrItemInfo);
                                iBox = data[i].box;
                                arrItemInfo = [];
                                if (data[i].box.num_check === null || data[i].box.num_check === "")
                                    data[i].box.num_check = 0;
                                arrItemInfo.push(data[i]);
                                if (i === data.length - 1) {
                                    this.arrBoxInfo.push(arrItemInfo);
                                }
                            }
                        }
                    }
                    this.setState({
                        ...this.state,
                        iDlgType: iDlgType,
                        bShowDlg: true,
                        iStatus: this.objCurrentEnteringOrder.status,
                        iType: this.objCurrentEnteringOrder.type
                    });
                }).catch(function (error) { console.log(error); });
        }
        if (iDlgType === 2) {
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true
            });
        }
    }
    // 弹窗确认按钮响应
    handleOk = e => {
        if (this.state.iDlgType === 0 || this.state.iDlgType === 1) {

            if (!this.BoxInfoCheck())
                return;

            let objNewEnteringOrder = {
                type: this.state.iType,
                status: this.state.iStatus,
                length: this.refs.length.state.value,
                width: this.refs.width.state.value,
                height: this.refs.height.state.value,
                value: this.refs.value.state.value,
                value_weight: this.refs.value_weight.state.value,
                weight: this.refs.weight.state.value,
                price_weight: this.fPriceWeight,
                express_no: this.refs.express_no.state.value,
                box_num: this.arrBoxInfo.length,
                service_fee: this.refs.service_fee.state.value,
                comments: this.refs.comments.state.value,
                user_name: this.props.strUserName,
                user_id: this.props.iUserID,
                store_id: this.props.objStoreInfo.id
            }
            if (this.state.iDlgType === 1) {
                objNewEnteringOrder.code = this.objCurrentEnteringOrder.code;
                objNewEnteringOrder.user_id = this.objCurrentEnteringOrder.user_id;
                objNewEnteringOrder.user_name = this.objCurrentEnteringOrder.user_name;
            }

            message.loading({ content: '正在更新入库记录……', key: g_strMessageKey });
            axios.post('/UpdateEnteringOrder', {
                objCurrentEnteringOrder: this.objCurrentEnteringOrder,
                objNewEnteringOrder: objNewEnteringOrder,
                arrBoxInfo: this.arrBoxInfo,
                action: this.state.iDlgType
            }).then(
                ({ data }) => {
                    if (data.status === 1) {
                        this.GetEnteringOrder();
                    } else {
                        if (data.message)
                            message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
                        else
                            message.warning({ content: "新建/更新入库记录失败！", key: g_strMessageKey, duration: 2 });
                    }
                }).catch(function (error) { console.log(error); });
        }
        if (this.state.iDlgType === 2) {
            if (this.refs.position)
                this.refs.position.state.value = "";
            message.loading({ content: '入库记录删除中……', key: g_strMessageKey });
            axios.post('/DeleteEnteringOrder', { id: this.objCurrentEnteringOrder.id, code: this.objCurrentEnteringOrder.code }).then(
                ({ data }) => {
                    if (data.status === -1) {
                        message.warning({
                            content: "删除失败，数据库中未找到指定EnteringOrder！",
                            key: g_strMessageKey, duration: 2
                        });
                        this.setState({
                            ...this.state,
                            btnControlDisable: [false, true, true],
                            bLoading: false,
                            bShowDlg: false,
                            selectedRowKeys: []
                        });
                    } else {
                        this.GetEnteringOrder();
                    }
                }).catch(function (error) { console.log(error); });
        }
    };
    // 弹窗取消按钮响应
    handleCancel = e => {
        if (this.state.iCheckType === 1 && this.bSaveAble) {
            message.warning("查验数据还未保存！");
            return;
        }
        this.arrBoxInfo = [
            [{ sku: '', name: '', num: 0, num_check: 0, status: 0 }]
        ];
        if (this.refs.position)
            this.refs.position.state.value = "";
        this.setState({
            bShowDlg: false,
            selectedRowKeys: [],
            btnControlDisable: [false, true, true]
        });
    };
    // 整理订单数据
    OrganizeEnteringOrderData(data) {
        let arrTableData = [];
        for (let i = 0; i < data.length; i++) {
            let objEnteringOrderData = data[i];
            objEnteringOrderData.key = objEnteringOrderData.id;
            objEnteringOrderData.type_str = this.arrType[data[i].type];
            objEnteringOrderData.status_str = this.arrStatus[data[i].status]
            arrTableData.push(objEnteringOrderData);
        }
        return arrTableData;
    }
    // 从数据库获取当前的数据结构
    GetEnteringOrder = () => {
        this.CurrentEnteringOrderInit();
        message.loading({ content: '数据加载中……', key: g_strMessageKey });
        axios('/GetEnteringOrder', {
            params: {
                store_id: this.props.objStoreInfo.id,
                user_id: this.props.iUserID,
                user_level: this.props.iUserLevel,
                invite_code: this.props.strInviteCode
            }
        }).then(({ data }) => {

            this.arrOgeTableData = this.OrganizeEnteringOrderData(data);
            this.arrTableData = this.arrOgeTableData;
            console.log("GetEnteringOrder : ", this.arrTableData);
            if (this.state.bShowDlg) {
                if (this.state.iDlgType === 0) {
                    message.success({ content: "新建入库预报完成！", key: g_strMessageKey, duration: 2 });
                }
                if (this.state.iDlgType === 1) {
                    message.success({ content: "入库记录信息已更新！", key: g_strMessageKey, duration: 2 });
                }
                if (this.state.iDlgType === 2) {
                    message.success({ content: "入库记录已删除！", key: g_strMessageKey, duration: 2 })
                }
            } else {
                message.success({ content: "入库信息加载完成！", key: g_strMessageKey, duration: 2 });
            }
            this.arrBoxInfo = [
                [{ sku: '', name: '', num: 0, num_check: 0, status: 0 }]
            ];
            this.setState({
                ...this.state,
                bLoading: false,
                bShowDlg: false,
                selectedRowKeys: []
            });
        }).catch(function (error) { console.log(error); });
    }
    // 新建入库单的初始化
    CurrentEnteringOrderInit = () => {
        this.objCurrentEnteringOrder = {
            code: '',
            type: 0,
            status: 0,
            length: 0.0,
            width: 0.0,
            height: 0.0,
            weight: 0.0,
            value: 0.0,
            value_weight: 0.0,
            price_weight: 0.0,
            comments: '',
            box_num: 0,
            express_no: '',
            service_fee: 0.0,
            store_id: this.props.objStoreInfo.id,
            user_id: this.props.iUserID
        };
    }
    // 体积重量信息变化
    OnValueWeightChange = (index, e) => {
        // console.log(index, e.target.value);
        let fLength = parseFloat(this.refs.length.state.value);
        let fWidth = parseFloat(this.refs.width.state.value);
        let fHeight = parseFloat(this.refs.height.state.value);
        let fWeight = parseFloat(this.refs.weight.state.value);
        switch (index) {
            // 长度变化
            case 0: {
                fLength = parseFloat(e.target.value);
                break;
            }
            // 宽度变化
            case 1: {
                fWidth = parseFloat(e.target.value);
                break;
            }
            // 高度变化
            case 2: {
                fHeight = parseFloat(e.target.value);
                break;
            }
            // 重量变化
            case 3: {
                fWeight = parseFloat(e.target.value);
                break;
            }
            default: break;
        }
        this.fValue = fLength * fWidth * fHeight;
        this.fValueWeight = fLength * fWidth * fHeight / 5000.0;
        if (fWeight >= this.fValueWeight)
            this.fPriceWeight = fWeight;
        else
            this.fPriceWeight = this.fValueWeight;
        this.setState({
            ...this.state,
            bValueWeightChange: true
        })
    }
    // 关键词处理
    KeywordsProcess = (strKeywords) => {
        let newKeywords = "";
        for (let i = 0; i < strKeywords.length; i++) {
            if (strKeywords[i] !== " ") {
                if (i === strKeywords.length - 1)
                    newKeywords += strKeywords[i];
                else
                    newKeywords += strKeywords[i] + "%";
            }
        }
        return newKeywords;
    }
    // 搜索库区信息名
    StorageEnteringSearch = () => {
        let strKeywords = this.refs.search.state.value;
        if (strKeywords === undefined)
            strKeywords = "";
        if (strKeywords.trim() === "") {
            this.GetEnteringOrder();
        } else {
            this.setState({
                ...this.state,
                bLoading: true,
                selectedRowKeys: [],
                btnControlDisable: [false, true, true]
            });
            axios('/SearchEnteringOrder', { params: { name: this.KeywordsProcess(strKeywords) } })
                .then(({ data }) => {
                    this.arrTableData = this.OrganizeEnteringOrderData(data);
                    this.setState({
                        ...this.state,
                        bLoading: false,
                        bShowDlg: false,
                        selectedRowKeys: []
                    });
                }).catch(function (error) { console.log(error); });
        }
    }
    // 点击了库区信息过滤器
    EnteringTypeDropDownClicked = (index) => {
        this.setState({
            ...this.state,
            iType: index
        });
    }
    // 点击了库位类型过滤器
    EnteringStatusDropDownClicked = (index) => {
        this.setState({
            ...this.state,
            iStatus: index
        });
    }
    // 箱子ID检查
    BoxIDCheck = (iBoxKey) => {
        for (let i = 0; i < this.arrBoxInfo.length; i++) {
            if (iBoxKey === this.arrBoxInfo[i].key)
                return false;
        }
        return true;
    }
    // 新增一个货箱
    AddBox = () => {
        let iBoxKey = Math.floor(Math.random() * 9999);
        while (!this.BoxIDCheck(iBoxKey)) {
            iBoxKey = Math.floor(Math.random() * 9999);
        }
        let newBox = [{ box: iBoxKey, sku: '', name: '', num: 0, num_check: 0, status: 0 }];
        this.arrBoxInfo.splice(0, 0, newBox);
        // this.arrBoxInfo.push(newBox);
        this.setState({
            ...this.state,
            bAddNew: true
        })
    }
    // 新增一个SKU
    AddSKU = (box_index) => {
        let newSKU = { box: this.arrBoxInfo[box_index][0].box, sku: '', name: '', num: 0 };

        this.arrBoxInfo[box_index].splice(0, 0, newSKU);
        // this.arrBoxInfo[box_index].push(newSKU);
        this.setState({
            ...this.state,
            bAddNew: true
        })
    }
    // 物品信息编辑
    SKUInfoChange = (box_index, item_index, index, e) => {
        this.bSaveAble = true;
        if (index === 0) {
            this.arrBoxInfo[box_index][item_index].sku = e.target.value;
            this.arrBoxInfo[box_index][item_index].name = "";
            for (let k = 0; k < global.arrSKUData.length; k++) {
                if (this.arrBoxInfo[box_index][item_index].sku === global.arrSKUData[k].sku) {
                    this.arrBoxInfo[box_index][item_index].name = global.arrSKUData[k].name
                    break;
                }
            }
        }
        if (index === 1)
            this.arrBoxInfo[box_index][item_index].num_check = e.target.value;
        this.setState({
            ...this.state,
            bCheckUpdate: true
        })
    }
    // 删除指定箱子信息
    RemoveBox = (box_index) => {
        if (this.arrBoxInfo.length <= 1) {
            message.warning("每单预报至少一箱货物！");
            return;
        }
        this.arrBoxInfo.splice(box_index, 1);
        this.setState({
            ...this.state,
            bAddNew: true
        })
    }
    // 删除指定物品信息
    RemoveItem = (box_index, item_index) => {
        if (this.arrBoxInfo[box_index].length <= 1) {
            message.warning("每箱至少包含一种货物！");
            return;
        }
        this.arrBoxInfo[box_index].splice(item_index, 1);
        this.setState({
            ...this.state,
            bAddNew: true
        })
    }
    // 货物总量统计
    TotalItemNumCalculate = () => {
        this.iTotalSKUNum = 0;
        this.iTotalItemNum = 0;
        for (let i = 0; i < this.arrBoxInfo.length; i++) {
            this.iTotalSKUNum += this.arrBoxInfo[i].length;
            for (let j = 0; j < this.arrBoxInfo[i].length; j++) {
                this.iTotalItemNum += parseInt(this.arrBoxInfo[i][j].num, 10);
            }
        }
    }
    // 每向货物统计
    BoxItemNumCalculate = (box_index) => {
        let iItemNum = 0;
        for (let j = 0; j < this.arrBoxInfo[box_index].length; j++) {
            iItemNum += parseInt(this.arrBoxInfo[box_index][j].num, 10);
        }
        return iItemNum;
    }
    // 提交数据前检查货物信息
    BoxInfoCheck = () => {
        for (let i = 0; i < this.arrBoxInfo.length; i++) {
            for (let j = 0; j < this.arrBoxInfo[i].length; j++) {
                if (this.arrBoxInfo[i][j].num <= 0) {
                    message.warning('有货物的数量为0，请检查！');
                    return false;
                }
                let bSKUFound = false;
                for (let k = 0; k < global.arrSKUData.length; k++) {
                    if (this.arrBoxInfo[i][j].sku === global.arrSKUData[k].sku) {
                        bSKUFound = true;
                        break;
                    }
                }
                if (!bSKUFound) {
                    message.warning('有货物的SKU不存在，请检查！');
                    return false;
                }
            }
        }
        return true;
    }
    // 点击箱子条形码时的默认屏蔽
    BoxBarcodeClick = (event) => {
        event.preventDefault();
    }
    // 对比/查验当前扫描SKU编码
    ScanSKUCheck = () => {
        let bFound = false;
        for (let i = 0; i < this.arrBoxInfo.length; i++) {
            if (this.arrBoxInfo[i][0].box === parseInt(this.iBoxID, 10)) {
                for (let j = 0; j < this.arrBoxInfo[i].length; j++) {
                    if (this.arrBoxInfo[i][j].sku === this.strScanInput) {
                        bFound = true;
                        this.iBoxCheckIndex = i;
                        this.iSKUCheckIndex = j;
                        this.strScanInput = "";
                        const input = document.getElementById("box" + i + "_numcheck" + j);
                        if (this.state.iCheckType === 1) {
                            let iInputValue = parseInt(input.value, 10);
                            if (isNaN(iInputValue))
                                iInputValue = 0;
                            iInputValue++;
                            this.arrBoxInfo[i][j].num_check = iInputValue;
                            input.value = iInputValue;
                        }
                        if (this.state.iCheckType === 2) {
                            this.iBoxCheckIndex = i; this.iSKUCheckIndex = j;
                        }
                        this.bSaveAble = true;
                        this.setState({
                            ...this.state,
                            bCheckUpdate: true
                        })
                        break;
                    }
                }
                break;
            }
        }
        if (!bFound && this.strScanInput.length > 20) {
            this.strScanInput = "";
        }
    }
    // 某个SKU数量的查验结果
    SKUCheckResult = (i, j) => {
        if (parseInt(this.arrBoxInfo[i][j].num_check, 10) === parseInt(this.arrBoxInfo[i][j].num, 10)) {
            return 1;
        } else {
            if (parseInt(this.arrBoxInfo[i][j].num_check, 10) > parseInt(this.arrBoxInfo[i][j].num, 10))
                return -1;
            else
                return 0;
        }
    }
    // 点击了某个SKU数量查验框
    SKUInputClick = (i, j) => {
        this.strScanInput = "";
        this.iBoxCheckIndex = parseInt(i, 10);
        this.iSKUCheckIndex = parseInt(j, 10);
        this.setState({
            ...this.state,
            bCheckUpdate: true
        })
    }
    // 所有SKU查验结果检查
    BoxCheckResult = (box) => {
        let bCheckComplete = true;
        for (let i = 0; i < box.length; i++) {
            if (parseInt(box[i].num_check, 10) !== parseInt(box[i].num, 10)) {
                bCheckComplete = false;
            } else {
                if (box[i].status < 2)
                    box[i].status = 2;
            }
        }
        return bCheckComplete;
    }
    // 保存SKU查验结果/上架指定的SKU
    SaveCheckResult = () => {
        if (!this.bSaveAble)
            return;
        // 保存SKU查验结果
        if (this.state.iCheckType === 1) {
            let bAllChecked = true;
            for (let i = 0; i < this.arrBoxInfo.length; i++) {
                for (let j = 0; j < this.arrBoxInfo[i].length; j++) {
                    if (parseInt(this.arrBoxInfo[i][j].num, 10) !== parseInt(this.arrBoxInfo[i][j].num_check, 10)) {
                        bAllChecked = false;
                        break;
                    }
                }
            }
            message.loading({ content: '正在保存记录……', key: g_strMessageKey });
            if (bAllChecked) {
                this.objCurrentEnteringOrder.status = 3;
                axios.post('/UpdateEnteringOrderStatus', { entering: this.objCurrentEnteringOrder.code, status: this.objCurrentEnteringOrder.status })
                    .then(({ data }) => {
                        this.setState({
                            ...this.state,
                            iStatus: this.objCurrentEnteringOrder.status
                        });
                        axios.post('/UpdateEnteringBoxInfo', { entering: this.objCurrentEnteringOrder.code, box: this.arrBoxInfo[this.iBoxCheckIndex], sku: -1 })
                            .then(({ data }) => {
                                message.info({ content: '信息已保存！', key: g_strMessageKey, duration: 2 });
                                this.bSaveAble = false;
                                this.setState({
                                    ...this.state,
                                    bCheckUpdate: true
                                })
                            }).catch(function (error) { console.log(error); });
                    }).catch(function (error) { console.log(error); });
            } else {
                axios.post('/UpdateEnteringBoxInfo', { entering: this.objCurrentEnteringOrder.code, box: this.arrBoxInfo[this.iBoxCheckIndex], sku: -1 })
                    .then(({ data }) => {
                        message.info({ content: '信息已保存！', key: g_strMessageKey, duration: 2 });
                        this.bSaveAble = false;
                        this.setState({
                            ...this.state,
                            bCheckUpdate: true
                        })
                    }).catch(function (error) { console.log(error); });
            }
        }
        // 上架指定SKU
        if (this.state.iCheckType === 2) {
            let objSKUUPRecord = {
                store_id: this.props.objStoreInfo.id,
                user_id: this.objCurrentEnteringOrder.user_id,
                user_name: this.objCurrentEnteringOrder.user_name,
                entering: this.objCurrentEnteringOrder.code,
                sku: this.arrBoxInfo[this.iBoxCheckIndex][this.iSKUCheckIndex].sku,
                name: this.arrBoxInfo[this.iBoxCheckIndex][this.iSKUCheckIndex].name,
                num: this.arrBoxInfo[this.iBoxCheckIndex][this.iSKUCheckIndex].num,
                box: this.arrBoxInfo[this.iBoxCheckIndex][this.iSKUCheckIndex].box,
                position_code: this.strCurrentScanPosition,
                position_id: this.iCurrentScanPositionID
            }
            message.loading({ content: '正在上架货物……', key: g_strMessageKey });
            axios.post('/UpdateSKUUpRecord', { objSKUUPRecord: objSKUUPRecord })
                .then(({ data }) => {
                    if (data.status === 1) {
                        message.info({ content: '上架完成！', key: g_strMessageKey, duration: 2 });
                        this.strCurrentScanPosition = "";
                        this.iCurrentScanPositionID = 0;
                        this.bSaveAble = false;
                        this.setState({
                            ...this.state,
                            bShowDlg: false
                        })
                        // 更新该入库单响应货箱和SKU的状态为上架
                        this.arrBoxInfo[this.iBoxCheckIndex][this.iSKUCheckIndex].status = 3;

                        let bAllUp = true;
                        for (let i = 0; i < this.arrBoxInfo.length; i++) {
                            for (let j = 0; j < this.arrBoxInfo[i].length; j++) {
                                if (parseInt(this.arrBoxInfo[i][j].status, 10) < 3) {
                                    bAllUp = false;
                                    break;
                                }
                            }
                        }
                        if (bAllUp) {
                            console.log("入库单所有货物均以上架，更新入库单状态为已上架！");
                            this.objCurrentEnteringOrder.status = 4;
                            axios.post('/UpdateEnteringOrderStatus', { entering: this.objCurrentEnteringOrder.code, status: this.objCurrentEnteringOrder.status })
                                .then(({ data }) => {
                                }).catch(function (error) { console.log(error); });
                        }
                        axios.post('/UpdateEnteringBoxInfo', { entering: this.objCurrentEnteringOrder.code, box: this.arrBoxInfo[this.iBoxCheckIndex], sku: this.iSKUCheckIndex })
                            .then(({ data }) => {
                            }).catch(function (error) { console.log(error); });
                    } else {
                        if (data.status === -2)
                            message.warning({ content: '已有相应的上架记录，请检查！', key: g_strMessageKey, duration: 2 });
                        else
                            message.warning({ content: '上架失败！', key: g_strMessageKey, duration: 2 });
                    }
                }).catch(function (error) { console.log(error); });
        }
    }
    // 对比当前的箱子
    ScanBoxCheck = () => {
        if (this.strScanInput.length > 19 && this.strScanInput.indexOf('XYJ') >= 0 && this.strScanInput.indexOf('-') >= 0 && this.strScanInput[this.strScanInput.length - 1] === "B") {
            this.strScanInput = this.strScanInput.substring(this.strScanInput.length - 19, this.strScanInput.length);
        }
        console.log("ScanInput: " + this.strScanInput);
        // 检查是否扫描完成（入库单号）
        if (this.strScanInput.indexOf('-') >= 0 && this.strScanInput[this.strScanInput.length - 1] === "B") {
            let strBoxBarCode = this.strScanInput;
            let strEnteringOrder = this.strScanInput.split('-')[0];
            console.log(strBoxBarCode, strEnteringOrder);
            let strBoxID = this.strScanInput.split('-')[1].split('B')[0];
            this.iBoxID = strBoxID;
            this.CurrentEnteringOrderInit();
            this.strScanInput = "";
            this.iScanMonitor = 0;
            let bFound = false;
            console.log(this.arrTableData);
            for (let i = 0; i < this.arrTableData.length; i++) {
                if (this.arrTableData[i].code === strEnteringOrder) {
                    this.objCurrentEnteringOrder = this.arrTableData[i];
                    bFound = true;
                    break;
                }
            }
            console.log("货箱匹配找寻结果 ： ", bFound);
            if (!bFound) {
                console.log(this.arrTableData);
                console.log(strEnteringOrder);
                message.warning("未找到相应的入库记录和货箱号，请重新扫描有效的条形码！");
                return;
            }
            if (!this.state.bShowDlg)
                this.bSaveAble = false;
            if (this.objCurrentEnteringOrder.status > 1) {
                this.ShowDlg(1);
            } else {
                message.loading({ content: '入库记录核对中……', key: g_strMessageKey });
                axios.post('/UpdateEnteringBoxStatus', { entering: strEnteringOrder, box: strBoxID })
                    .then(({ data }) => {
                        if (data.status === -1) {
                            message.warning({ content: "未找到响应的入库记录和货箱号，请重新扫描有效的条形码！", key: g_strMessageKey, duration: 2 });
                        } else {
                            message.loading({ content: '核对完成，货箱' + strBoxBarCode + '已签收……', key: g_strMessageKey });
                            // 只要订单是未签收状态，无论签收/查验扫码，都要将状态变为已签收
                            if (this.objCurrentEnteringOrder.status < 2)
                                axios.post('/UpdateEnteringOrderStatus', { entering: strEnteringOrder, status: 2 })
                                    .then(({ data }) => {
                                        this.objCurrentEnteringOrder.status = data.status;
                                        this.setState({
                                            ...this.state,
                                            iStatus: data.status
                                        });
                                        this.ShowDlg(1);
                                    }).catch(function (error) { console.log(error); });
                            else {
                                this.ShowDlg(1);
                            }
                        }
                    }).catch(function (error) { console.log(error); });
            }
        }
    }
    // 对比当前的货架
    ScanPositionCheck = () => {
        if (/*this.strScanInput.indexOf('B') >= 0 ||*/ this.strScanInput.length >= 20) {
            this.strScanInput = "";
            return;
        }
        if (this.strScanInput.length > 5 && this.strScanInput.indexOf('.p') >= 0 && this.strScanInput[this.strScanInput.length - 1] === "p") {
            // const input = document.getElementById("position");
            // input.value = this.strScanInput;

            // 检查货位是否存在
            let bPosition = false;
            for (let i = 0; i < global.arrPositionList.length; i++) {
                if (this.strScanInput === global.arrPositionList[i].code) {
                    console.log("找到了匹配的库位！");
                    this.iCurrentScanPositionID = global.arrPositionList[i].id;
                    bPosition = true;
                    break;
                }
            }
            if (bPosition) {
                this.strCurrentScanPosition = this.strScanInput;
                this.strScanInput = "";
                this.setState({
                    ...this.state,
                    bCheckUpdate: true
                });
            } else {
                this.strScanInput = "";
            }
        }
    }
    // 切换扫描操作方式
    CheckTypeChange = (e) => {
        this.strCurrentScanPosition = "";
        this.strScanInput = "";
        this.setState({
            ...this.state,
            iCheckType: e.target.value
        })
    }
    // 切换查验操作方式
    EditTypeChange = (e) => {
        this.setState({
            ...this.state,
            iEditType: e.target.value
        })
    }
    // 扫码/键盘输入监听
    ScanInputMonitor = (e) => {
        // 查验数量时，处于编辑状态中，扫码无效
        if (this.state.iCheckType === 1 && this.state.iEditType === 1 && this.state.bShowDlg)
            return;
        this.strCurrentScanCode = e.which;
        this.timeCurrent = new Date().getTime();
        if (this.strCurrentScanCode != null && this.timeLast != null && this.timeCurrent - this.timeLast <= 30) {
            // if (this.iScanMonitor == 1) {
            //     console.log("扫描2");
            //     this.iScanMonitor = 2;
            //     // if (this.strCurrentScanCode.length === 1)
            //     //     this.strCurrentScanCode = "X";
            // }
        } else if (this.strLastScanCode != null && this.timeLast != null && this.timeCurrent - this.timeLast > 100) {
            // if (this.iScanMonitor == 0) {
            //     console.log("扫描1");
            //     this.iScanMonitor = 1;
            //     this.strScanInput = String.fromCharCode(this.strCurrentScanCode);
            //     return;
            // }
        }
        this.strLastScanCode = this.strCurrentScanCode;
        this.timeLast = this.timeCurrent;
        this.strScanInput += String.fromCharCode(this.strCurrentScanCode);

        // SKU数量查验中
        if (this.state.bShowDlg && this.state.iCheckType === 1) {
            if (this.strScanInput.length >= 5) {
                this.ScanSKUCheck();
                return;
            }
        }
        // 签收箱子
        if (/*!this.state.bShowDlg &&*/ this.state.iCheckType <= 1) {
            this.ScanBoxCheck();
        }
        // 上架操作（未获得库位时）
        if (!this.state.bShowDlg && this.state.iCheckType === 2) {
            let bPosition = false;
            const input = document.getElementById("position");
            if (input) {
                if (input.value !== null && input.value !== "") {
                    if (input.value.indexOf('.p') > 0) {
                        bPosition = true;
                        this.ScanBoxCheck();
                    }
                }
            }
            if (!bPosition)
                this.ScanPositionCheck();
        }
        // 上架操作（获得库位后）
        if (this.state.bShowDlg && this.state.iCheckType === 2 && this.strCurrentScanPosition !== "") {
            this.ScanSKUCheck();
        }
    }

    /* React自带函数 */
    // 卸载异步操作设置状态
    componentWillUnmount() {
        clearTimeout(this.timeouter)
        this.setState = (state, callback) => {
            return;
        };
        window.removeEventListener('keypress', this.ScanInputMonitor);
    }
    // 渲染完毕……
    componentDidMount() {

        window.addEventListener('keypress', this.ScanInputMonitor);

        this.GetEnteringOrder();
        if (global.arrSKUData.length <= 0) {
            axios('/GetSKU', { params: { store_id: this.props.objStoreInfo.id, user_id: this.props.iUserID, level: this.props.iUserLevel } })
                .then(({ data }) => {
                    global.arrSKUData = data;
                }).catch(function (error) { console.log(error); });
        }
        if (global.arrSKUData.length <= 0) {
            axios('/GetSKU', { params: { store_id: this.props.objStoreInfo.id, user_id: this.props.iUserID, level: this.props.iUserLevel } })
                .then(({ data }) => {
                    global.arrSKUData = data;
                }).catch(function (error) { console.log(error); });
        }
        if (global.arrPositionList.length <= 0) {
            axios('/GetInventoryPosition', { params: { store_id: this.props.objStoreInfo.id } })
                .then(({ data }) => {
                    global.arrPositionList = data;
                }).catch(function (error) { console.log(error); });
        }
    }
    // 渲染函数
    render() {

        let uiPosition = null;
        if (this.state.iCheckType === 2) {
            uiPosition = (
                <div style={{ color: 'white', marginLeft: '10px' }}>
                    {"货位："}
                    <Input placeholder="不要手动输入，请扫描" id="position" ref="position" style={{ width: '200px', marginLeft: '10px' }} disabled={true}
                        value={this.strCurrentScanPosition} />
                </div>
            );
        }
        let uiTitleArea = (
            < div className="storage_contents_title" >
                <Radio.Group className="store_image_file_type" onChange={this.CheckTypeChange}
                    value={this.state.iCheckType}>
                    <Radio value={0} style={{ color: 'white' }}>签收货箱</Radio>
                    <Radio value={1} style={{ color: 'white' }}>查验SKU数量</Radio>
                    <Radio value={2} style={{ color: 'white' }}>货物上架</Radio>
                </Radio.Group>
                {uiPosition}
            </div >
        );

        // 如果界面上需要显示的内容已经从数据库获得
        if (!this.state.bLoading) {
            // 主体表格相关
            // const { selectedRowKeys } = this.state;
            // const rowSelection = {
            //     selectedRowKeys,
            //     onChange: this.onSelectedRowKeysChange,
            //     columnWidth: 30
            // };
            // // 表格分页属性
            // const paginationProps = {
            //     showSizeChanger: true,
            //     showQuickJumper: false,
            //     showTotal: () => `共 ${this.arrTableData.length} 个 入库单`,
            //     defaultPageSize: 10,
            //     defaultCurrent: this.state.iCurrentPage,
            //     pageSizeOptions: ['10', '20', '30', '40', '50'],
            //     total: this.arrTableData.length,
            //     position: 'bottom'
            // };
            // 入库单类别下拉菜单
            const uiEnteringType = (
                <Menu>
                    {this.arrType.map((item, index) => {
                        return (
                            <Menu.Item key={index} onClick={this.EnteringTypeDropDownClicked.bind(this, index)}>
                                {item}
                            </Menu.Item>
                        );
                    })}
                </Menu>
            );
            // 库位类型下拉菜单
            const uiEnteringStatus = (
                <Menu>
                    {this.arrStatus.map((item, index) => {
                        return (
                            <Menu.Item key={index} onClick={this.EnteringStatusDropDownClicked.bind(this, index)}>
                                {item}
                            </Menu.Item>
                        );
                    })}
                </Menu>
            );
            let uiModal = null;
            if (this.state.bShowDlg) {
                this.TotalItemNumCalculate();
                // 入库单编辑弹窗
                let strTitle = "新建入库预报";
                if (this.state.iDlgType === 1)
                    strTitle = "入库签收/查验";
                let strButtonStyle = "storage_edit_info_add_button";
                if (!this.bSaveAble)
                    strButtonStyle = "storage_edit_info_add_button_disable";
                let uiSaveButton = null;
                if (this.state.iCheckType === 1)
                    uiSaveButton = (
                        <div disabled={this.bSaveAble} style={{ marginRight: '5px' }}
                            className={strButtonStyle} onClick={this.SaveCheckResult.bind(this)}>保 存</div>
                    );
                if (this.state.iCheckType === 2)
                    uiSaveButton = (
                        <div disabled={this.bSaveAble} style={{ marginRight: '5px' }}
                            className={strButtonStyle} onClick={this.SaveCheckResult.bind(this)}>上 架</div>
                    );
                uiModal = (
                    <Modal title={strTitle} width={910} visible={this.state.bShowDlg} footer={null}
                        /*okText="确定" cancelText="取消" onOk={this.handleOk}*/ onCancel={this.handleCancel} >
                        <div className="user_edit_info_row">
                            <div className="storage_edit_info_title" style={{ minWidth: '50px' }}>单号:</div>
                            <Input className="storage_edit_info_input" ref="code" disabled
                                defaultValue={this.objCurrentEnteringOrder.code} />
                            <div className="storage_edit_info_title" style={{ minWidth: '50px' }}>类别:</div>
                            <Dropdown className="storage_edit_info_input" overlay={uiEnteringType} placement="bottomLeft" disabled>
                                <Button className="storage_edit_info_input">
                                    {this.arrType[this.state.iType]}
                                    <Icon type="caret-down" style={{ marginLeft: '10px' }} />
                                </Button>
                            </Dropdown>
                            <div className="storage_edit_info_title" style={{ minWidth: '50px' }}>状态:</div>
                            <Dropdown className="storage_edit_info_input" overlay={uiEnteringStatus} placement="bottomLeft" disabled>
                                <Button className="storage_edit_info_input" >
                                    {this.arrStatus[this.state.iStatus]}
                                    <Icon type="caret-down" style={{ marginLeft: '10px' }} />
                                </Button>
                            </Dropdown>
                        </div>
                        <div className="storage_edit_info_block">
                            <div className="user_edit_info_row storage_edit_info_black_title">
                                <b>货物信息</b>：共 {this.arrBoxInfo.length} 箱, {this.iTotalSKUNum} 种, {this.iTotalItemNum} 件 货物
                                {uiSaveButton}
                            </div>
                            {/* 货箱列表 */}
                            {this.arrBoxInfo.map((box, box_index) => {

                                if (this.state.iCheckType >= 1) {
                                    if (parseInt(this.iBoxID, 10) !== parseInt(box[0].box, 10))
                                        return null;
                                    this.iBoxCheckIndex = box_index;
                                }
                                let strBlockStyle = "storage_edit_info_sub_block";
                                if (box_index % 2 !== 0)
                                    strBlockStyle += " storage_edit_info_sub_block_dark";
                                if (this.arrBoxInfo.length % 2 === 0) {
                                    strBlockStyle = "storage_edit_info_sub_block storage_edit_info_sub_block_dark";
                                    if (box_index % 2 !== 0)
                                        strBlockStyle = "storage_edit_info_sub_block";
                                }
                                let iItemNum = this.BoxItemNumCalculate(box_index);
                                let uiBarCode = null;
                                let uiChecked = null;
                                let uiEditType = null;
                                if (this.objCurrentEnteringOrder.code !== null && this.objCurrentEnteringOrder.code !== "") {
                                    let strBoxBarcode = this.objCurrentEnteringOrder.code + "-" + box[0].box + "B";
                                    uiBarCode = (<div style={{ color: 'darkgrey', fontWeight: 'bold', marginRight: '15px' }}>{strBoxBarcode}</div>);
                                    if (this.BoxCheckResult(box)) {
                                        uiChecked = (
                                            <Checkbox checked={true} style={{ marginLeft: '15px' }}>已入库,等待上架</Checkbox>
                                        );
                                        if (box[0].status === 3)
                                            uiChecked = (
                                                <Checkbox checked={true} style={{ marginLeft: '15px' }}>已上架</Checkbox>
                                            );
                                    } else {
                                        if (box[0].status === 0)
                                            uiChecked = (
                                                <Checkbox checked={false} style={{ marginLeft: '15px' }}>还未签收</Checkbox>
                                            );
                                        if (box[0].status === 1)
                                            uiChecked = (
                                                <Checkbox checked={true} style={{ marginLeft: '15px' }}>已签收，等待查验</Checkbox>
                                            );
                                        if (box[0].status === 2)
                                            uiChecked = (
                                                <Checkbox checked={true} style={{ marginLeft: '15px' }}>已入库,等待上架</Checkbox>
                                            );
                                        if (box[0].status === 3)
                                            uiChecked = (
                                                <Checkbox checked={true} style={{ marginLeft: '15px' }}>已上架</Checkbox>
                                            );
                                    }
                                }
                                // SKU列表
                                let uiItemList = null;
                                if (this.state.iCheckType >= 1) {
                                    let bEditDisable = true;
                                    if (this.state.iEditType === 1)
                                        bEditDisable = false;
                                    uiItemList = (
                                        box.map((item, item_index) => {
                                            let strRowStyle = "user_edit_info_row";
                                            let uiCheckSKU = null;
                                            if (this.state.iCheckType === 1) {
                                                let iCheckComplete = this.SKUCheckResult(box_index, item_index);
                                                if (iCheckComplete === 1) {
                                                    if (parseInt(item.status, 10) >= 3) {
                                                        bEditDisable = true;
                                                        uiCheckSKU = (
                                                            <Icon type="vertical-align-top" style={{ color: 'darkcyan', fontWeight: 'bold', fontSize: '17.5px' }} />
                                                        );
                                                    } else
                                                        uiCheckSKU = (
                                                            <Icon type="check-circle" style={{ color: 'darkcyan', fontWeight: 'bold', fontSize: '17.5px' }} />
                                                        );
                                                }
                                                if (iCheckComplete === -1) {
                                                    uiCheckSKU = (
                                                        <Icon type="exclamation-circle" style={{ color: 'lighcoral', fontWeight: 'bold', fontSize: '17.5px' }} />
                                                    );
                                                }
                                            }
                                            if (box_index === this.iBoxCheckIndex && item_index === this.iSKUCheckIndex) {
                                                strRowStyle += " storage_sku_check_row";
                                                if (this.state.iCheckType === 2 && this.bSaveAble) {
                                                    uiCheckSKU = (
                                                        <Icon type="vertical-align-top" style={{ color: 'darkcyan', fontWeight: 'bold', fontSize: '17.5px' }} />
                                                    );
                                                }
                                            }
                                            return (
                                                <div className={strRowStyle} key={box_index * 100 + item_index}>
                                                    <div className="storage_edit_box_info_title">SKU：</div>
                                                    <Input className="storage_edit_box_info_input" ref={"box_" + box_index + "sku_" + item_index} disabled
                                                        defaultValue={item.sku} value={item.sku} onChange={this.SKUInfoChange.bind(this, box_index, item_index, 0)} />
                                                    <div className="storage_edit_box_info_title">名称：</div>
                                                    <Input className="storage_edit_box_info_input_mid" ref={"box_" + box_index + "name_" + item_index}
                                                        defaultValue={item.name} value={item.name} disabled />
                                                    <div className="storage_edit_box_info_title">数量：</div>
                                                    <Input className="storage_edit_box_info_input_short"
                                                        defaultValue={item.num} value={item.num} disabled
                                                        onChange={this.SKUInfoChange.bind(this, box_index, item_index, 1)} />
                                                    <div className="storage_edit_box_info_title">已查验：</div>
                                                    <Input className="storage_edit_box_info_input_short" ref={"box" + box_index + "_numcheck" + item_index}
                                                        id={"box" + box_index + "_numcheck" + item_index} onClick={this.SKUInputClick.bind(this, box_index, item_index)} disabled={bEditDisable}
                                                        defaultValue={item.num_check} value={item.num_check} onChange={this.SKUInfoChange.bind(this, box_index, item_index, 1)} />
                                                    {uiCheckSKU}
                                                </div>
                                            )
                                        })
                                    );
                                }
                                // 编辑模式
                                if (this.state.iCheckType === 1) {
                                    uiEditType = (
                                        <Radio.Group className="store_image_file_type" onChange={this.EditTypeChange}
                                            value={this.state.iEditType}>
                                            <Radio value={0} style={{ color: 'grey' }}>扫码查验</Radio>
                                            <Radio value={1} style={{ color: 'grey' }}>手动编辑</Radio>
                                        </Radio.Group>
                                    );
                                }

                                return (
                                    <div className={strBlockStyle} key={box_index}>
                                        <div className="user_edit_info_row storage_edit_info_black_title">
                                            {uiBarCode}
                                            共 {box.length} 种, {iItemNum} 件 货物
                                            <div style={{ width: '15px' }} />
                                            {uiEditType}
                                            {uiChecked}
                                            {/* <div className="storage_edit_info_add_button" onClick={this.AddSKU.bind(this, box_index)}>
                                                + SKU
                                            </div>
                                            <div className="storage_edit_info_delete_box_button" onClick={this.RemoveBox.bind(this, box_index)}>
                                                删除货箱
                                            </div> */}
                                        </div>
                                        {uiItemList}

                                    </div>
                                );
                            })}
                        </div>
                    </Modal >
                );
                if (this.state.iDlgType === 2) {
                    uiModal = (
                        <Modal width={500} title={"删除入库单"} visible={this.state.bShowDlg} okType="danger"
                            onOk={this.handleOk} okText="确定"
                            onCancel={this.handleCancel} cancelText="取消">
                            <div className="modal_item">
                                <div>{this.objCurrentEnteringOrder.code}
                                    <br /><br />确定删除选中的入库单？
                                </div>
                            </div>
                        </Modal>
                    );
                }

            }

            // let uiTableHeader = arrClientTableHeader;
            // if (this.props.iUserLevel >= 9)
            //     uiTableHeader = arrManagerTableHeader;

            let strScanTips = "签收流程：扫描货箱的条形编码";
            if (this.state.iCheckType === 1)
                strScanTips = "查验流程：1. 扫描货箱的条形编码；2.逐个扫描货箱中货物的SKU条形编码";
            if (this.state.iCheckType === 2)
                strScanTips = "上架流程：1. 扫描货架的条形编码；2. 扫描货箱的条形编码；3.扫描货箱中货物的SKU条形编码";

            return (
                <div className="storage_contents_area">
                    {uiTitleArea}
                    {uiModal}
                    <div style={{ margin: '20px', marginLeft: '45px', color: 'darkcyan' }}>{'* 扫码前，请先用鼠标点击本页面的灰色区域 *'}</div>
                    <div className="storage_scan_tips">{strScanTips}</div>

                    {/* 表格主体 */}
                    {/* <Table style={{ margin: '20px', width: '80%', overflowX: 'auto' }}
                        rowSelection={rowSelection} pagination={paginationProps}
                        columns={uiTableHeader} dataSource={this.arrTableData} bordered
                        onRow={(record) => ({ onClick: () => { this.selectRow(record); } })} /> */}
                </div >
            );
        } else {
            return (
                <div className="storage_contents_area">
                    {uiTitleArea}
                    <Spin className="manage_empty_loading" size="large" tip="加载中……" />
                </div>
            );
        }
    }

}
export default EnteringChecking;
