import React, { Component } from "react";
import axios from 'axios';

import Spin from 'antd/es/spin';
import Tree from 'antd/es/tree';
import Modal from 'antd/es/modal';
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import Icon from 'antd/es/icon';
import Popover from 'antd/es/popover';
import message from 'antd/es/message';

// import { Spin, Tree, Modal, Input, Button, Icon, Popover, message } from 'antd';
import '../../../styles/icon_image.css';
import '../../../styles/Background/ego_manager.css';
import '../../../styles/Background/UI_Module/category_management.css';
import { UserActiveUpdate } from "../../../functions/DataStatistic.js"

const g_strMessageKey = 'updating';

// antd树形结构体
const { TreeNode } = Tree;
// 树形结构数据 (Default Data)
global.arrCategoriesData = [
    {
        key: '0-0', id: 1, parent_id: 0, level: 0, sort: -1,
        title: '全部', name: '', en_name: '', icon: '',
        children: []
    }
];

class CategoryManagement extends Component {
    /* 构造函数 */
    constructor(props) {
        super();
        this.state = {
            // 操作按钮控制
            btnControlDisable: [false, true, true],
            // 弹窗控制
            bShowDlg: false,
            iDlgType: 0,
            // 控制树控件的状态
            selectedKeys: ['0-0'],
            expandedKeys: ['0-0'],
            // 树控件数据体
            categoriesData: global.arrCategoriesData,
            // 图标选择器
            bShowIconSelector: false,
            strSelectedCategoryIcon: ""
        };
        this.bDataBack = false;
        this.arrCategoriesData = [];
        this.iSelectedCategoryOrder = -1;
        this.iSelectedCategoryID = 0;
        this.iSelectedCategoryLevel = 0;
        this.strSelectedCategoryName = "全部";
        this.strSelectedCategoryIcon = "about_us_icon";
        this.iSelectedCategoryIndex = 0;
        this.strSelectedCategoryKey = ['0-0'];
        this.arrIconList = [
            [
                "category_life_icon", "category_play_icon", "category_shopping_icon", "category_food_icon", "cart_icon"
            ],
            [
                "category_car_icon", "category_haircut_icon", "category_lecture_icon", "category_carrepair_icon",
                "category_musical_icon", "category_investement_icon", "category_cleaning_icon", "category_dating_icon", "category_health_care_icon", "category_hr_icon"
            ],
            [
                "category_dentist_icon", "category_beauty_icon", "category_baby_education_icon", "campass_icon",
                "broadcast_icon", "lineup_icon", "category_moving_icon", "category_sport_icon", "category_study_abroad_icon", "category_pets_icon"
            ],
            [
                "category_piping_icon", "category_room_modify_icon", "category_jobs_icon", "category_house_renting_icon", "category_umbrella_icon", "category_sunny_icon", "category_van_icon", "category_nail1_icon", "category_nail2_icon", "category_baby_product_icon"
            ],
            [
                "category_art_icon", "category_carseatravel_icon", "category_onfire_icon", "category_rewards_icon", "category_truck_icon", "category_carstorage_icon"
            ],
            [
                "category_children_icon", "category_medical_icon", "category_tax_icon",
                "category_diy_clothes_icon", "category_photography_icon", "category_furniture_icon",
                "category_computer_icon", "category_global_icon", "category_giftshopl_icon", "category_frigerator_icon"
            ],
            [
                "category_transform_icon", "category_keyboards_icon", "category_onsale_icon"
            ],
            [
                "category_deskgame_icon", "category_travel_icon", "category_game_icon", "category_plane_icon", "category_denglong_icon", "category_ballgames_icon", "category_park_icon", "category_card_game_icon",
                "category_footbal_icon", "category_boating_icon"
            ],
            [
                "category_gift_icon", "category_fish_icon", "category_magic_icon", "category_basketball_icon", "category_fan_icon", "category_candy_icon", "category_bag_icon", "category_guitar_icon"
            ],
            [
                "category_milktea_icon", "category_ktvbar_icon", "category_fruite_icon", "category_cafe_icon",
                "category_beer_icon", "category_cheese_icon", "category_japanwoman_icon", "category_indiacup_icon", "category_bread_icon", "category_pizza_icon"
            ],
            [
                "category_mushroom_icon", "category_bowl_icon", "category_burger_icon", "category_lemon_icon"
            ]
        ];

    }
    /* 树形控件自带响应函数 */
    // 树形控件节点展开
    onExpand = expandedKeys => {
        this.setState({
            ...this.state,
            expandedKeys: expandedKeys
        });
    };
    // 树形控件节点选择
    onSelect = (selectedKeys, info) => {
        this.strSelectedCategoryKey = selectedKeys;
        if (info.selectedNodes.length > 0) {
            this.iSelectedCategoryOrder = info.selectedNodes[0].props.dataRef.sort;
            this.iSelectedCategoryID = info.selectedNodes[0].props.dataRef.id;
            this.iSelectedCategoryLevel = info.selectedNodes[0].props.dataRef.level;
            this.strSelectedCategoryName = info.selectedNodes[0].props.dataRef.name;
            this.strSelectedCategoryIcon = info.selectedNodes[0].props.dataRef.icon;
            this.strSelectedCategoryIcon = info.selectedNodes[0].props.dataRef.locations;
            this.GetSelectCategoryIndex();
            if (this.iSelectedCategoryID === 1) {
                this.setState({
                    ...this.state,
                    btnControlDisable: [false, true, true],
                    selectedKeys
                });
            } else {
                this.setState({
                    ...this.state,
                    btnControlDisable: [false, false, false],
                    selectedKeys
                });
            }
        } else {
            this.iSelectedCategoryID = 0;
            this.setState({
                ...this.state,
                btnControlDisable: [true, true, true],
                selectedKeys
            });
        }

    };
    // 树形控件节点渲染
    renderTreeNodes = data =>
        data.map(item => {
            if (item.children) {
                return (
                    <TreeNode icon={<div className={"category_tree_item_icon " + item.icon} />}
                        className="manage_tree_node_h" title={item.title} key={item.key} dataRef={item}>
                        {this.renderTreeNodes(item.children)}
                    </TreeNode>
                );
            }
            return <TreeNode key={item.key} {...item} />;
        });
    /* 弹窗自带响应函数 */
    // 显示弹窗
    ShowDlg = (iDlgType) => {
        if (iDlgType === 0) {
            if (this.refs.input_sort)  // 一开始refs.input_cn_name会是undefine，因此需要做判断
                this.refs.input_sort.state.value = "";
            if (this.refs.input_cn_name)  // 一开始refs.input_cn_name会是undefine，因此需要做判断
                this.refs.input_cn_name.state.value = "";
            if (this.refs.input_en_name)  // 一开始refs.input_cn_name会是undefine，因此需要做判断
                this.refs.input_en_name.state.value = "";
            if (this.refs.input_locations)  // 一开始refs.input_cn_name会是undefine，因此需要做判断
                this.refs.input_locations.state.value = "";
            this.strSelectedCategoryIcon = "about_us_icon";
        }
        if (iDlgType === 1) {
            if (this.refs.input_locations)  // 一开始refs.input_cn_name会是undefine，因此需要做判断
                this.refs.input_locations.state.value =
                    this.arrCategoriesData[this.iSelectedCategoryIndex].locations;
            if (this.refs.input_sort)  // 一开始refs.input_cn_name会是undefine，因此需要做判断
                this.refs.input_sort.state.value = this.arrCategoriesData[this.iSelectedCategoryIndex].sort;
            if (this.refs.input_cn_name)  // 一开始refs.input_cn_name会是undefine，因此需要做判断
                this.refs.input_cn_name.state.value = this.arrCategoriesData[this.iSelectedCategoryIndex].name;
            if (this.refs.input_en_name)  // 一开始refs.input_cn_name会是undefine，因此需要做判断
                this.refs.input_en_name.state.value = this.arrCategoriesData[this.iSelectedCategoryIndex].en_name;
            this.strSelectedCategoryIcon = this.arrCategoriesData[this.iSelectedCategoryIndex].icon;
        }
        this.setState({
            ...this.state,
            bShowDlg: true,
            iDlgType: iDlgType
        });
    };
    // 弹窗确定按钮响应
    OKButtonClick = e => {
        let cn_input = this.refs.input_cn_name;
        let en_input = this.refs.input_en_name;
        let locations_input = this.refs.input_locations;
        if (cn_input) {
            if (cn_input.state.value.trim() === "") {
                message.warning("分类的中文名不能为空！");
                return;
            }
        }
        // 新建地区信息
        if (this.state.iDlgType === 0) {
            let strLocations = "";
            if (this.iSelectedCategoryLevel <= 1)
                strLocations = locations_input.state.value;
            const objNewCategory = {
                parent_id: this.arrCategoriesData[this.iSelectedCategoryIndex].id,
                level: this.iSelectedCategoryLevel + 1, sort: -1,
                name: cn_input.state.value, en_name: en_input.state.value,
                locations: strLocations,
                icon: this.strSelectedCategoryIcon
            }
            this.bDataBack = false;
            message.loading({ content: '分类创建中……', key: g_strMessageKey });
            axios.post('/NewCategory', { objNewCategory })
                .then(
                    ({ data }) => {
                        // DataStatistic
                        UserActiveUpdate(5, { active: "管理后台: 分类管理操作", action: "新建" });
                        this.RefreshCategoriesTreeStructure(data);
                    }
                );
        }
        // 编辑地区信息
        if (this.state.iDlgType === 1) {
            this.arrCategoriesData[this.iSelectedCategoryIndex].sort = -1;
            if (this.iSelectedCategoryLevel <= 2) {
                if (this.refs.input_sort.state.value === undefined) {
                    message.warning("分类的排序不能为空，默认请填-1 ！");
                    return;
                } else {

                    if (this.refs.input_sort.state.value.toString().trim() === "") {
                        message.warning("分类的排序不能为空，默认请填-1 ！");
                        return;
                    }
                }
                console.log(this.refs.input_sort.state.value.toString());
                this.arrCategoriesData[this.iSelectedCategoryIndex].sort = parseInt(this.refs.input_sort.state.value.toString().trim(), 10);
                this.arrCategoriesData[this.iSelectedCategoryIndex].locations = this.refs.input_locations.state.value;
            }
            this.arrCategoriesData[this.iSelectedCategoryIndex].name = cn_input.state.value;
            this.arrCategoriesData[this.iSelectedCategoryIndex].en_name = en_input.state.value;
            this.arrCategoriesData[this.iSelectedCategoryIndex].icon = this.strSelectedCategoryIcon;
            const objEditCategory = {
                id: this.iSelectedCategoryID,
                parent_id: this.arrCategoriesData[this.iSelectedCategoryIndex].parent_id,
                name: this.arrCategoriesData[this.iSelectedCategoryIndex].name,
                en_name: this.arrCategoriesData[this.iSelectedCategoryIndex].en_name,
                icon: this.arrCategoriesData[this.iSelectedCategoryIndex].icon,
                sort: this.arrCategoriesData[this.iSelectedCategoryIndex].sort,
                locations: this.arrCategoriesData[this.iSelectedCategoryIndex].locations
            };
            // DataStatistic
            UserActiveUpdate(5, { active: "管理后台: 分类管理操作", action: "编辑" });
            axios.post('/EditCategory', { objEditCategory })
                .then((response) => { console.log(response); });
            global.arrCategoriesData = [];
            global.arrCategoriesData = this.OrganizeCategoriesData(0, "0");
            this.setState({
                ...this.state,
                bShowDlg: false,
                categoriesData: global.arrCategoriesData
            });
        };
        // 删除地区信息
        if (this.state.iDlgType === 2) {
            let arrDeleteCategories = [this.iSelectedCategoryID];
            this.GetSubNodesID(this.iSelectedCategoryID, arrDeleteCategories);
            this.bDataBack = false;
            message.loading({ content: '分类删除中……', key: g_strMessageKey });
            axios.post('/DeleteCategory', { arrDeleteCategories: arrDeleteCategories })
                .then(({ data }) => {
                    if (data.status === -1) {
                        message.success({ content: "删除分类失败，请先检查并删除该分类下的所有商家！", key: g_strMessageKey, duration: 2 });
                        console.log(data.err);
                        this.bDataBack = true;
                        this.setState({
                            ...this.state,
                            bShowDlg: false
                        });
                    } else {
                        // DataStatistic
                        UserActiveUpdate(5, { active: "管理后台: 分类管理操作", action: "删除" });
                        this.RefreshCategoriesTreeStructure(data.data);
                    }
                });
        }
    };
    // 弹窗取消按钮响应
    CancelButtonClick = e => {
        this.strSelectedCategoryIcon = this.arrCategoriesData[this.iSelectedCategoryIndex].icon;
        this.setState({
            ...this.state,
            bShowDlg: false,
            bShowIconSelector: false
        });
    };
    // 显示图标选择器
    ShowIconSelector = () => {
        this.setState({
            ...this.state,
            bShowIconSelector: true
        });
    }
    // 关闭图标选择器
    CloseIconSelector = (iIconRow, iIconCol) => {
        if (iIconRow >= 0 && iIconCol >= 0)
            this.strSelectedCategoryIcon = this.arrIconList[iIconRow][iIconCol];
        this.setState({
            ...this.state,
            bShowIconSelector: false
        });
    }
    /* 自定义成员函数 */
    // 获去当前选中节点在地区数据数组中的位置
    GetSelectCategoryIndex() {
        for (let i = 0; i < this.arrCategoriesData.length; i++) {
            if (this.arrCategoriesData[i].id === this.iSelectedCategoryID) {
                this.iSelectedCategoryIndex = i;
                break;
            }
        }
    }
    // 获取某个节点下的所有子节点ID（用于删除）
    GetSubNodesID(parent_id, arrDeleteNodes) {
        for (let i = 0; i < this.arrCategoriesData.length; i++) {
            if (this.arrCategoriesData[i].parent_id === parent_id) {
                arrDeleteNodes.push(this.arrCategoriesData[i].id);
                this.GetSubNodesID(this.arrCategoriesData[i].id, arrDeleteNodes);
            }
        }
    }
    // 整理Categories的数据
    OrganizeCategoriesData(iParentID, strParentKey) {
        let arrCurrentLevelCategories = [];
        let iCurrentCategoryKey = 0;
        for (let i = 0; i < this.arrCategoriesData.length; i++) {
            if (this.arrCategoriesData[i].parent_id === iParentID) {
                if (this.arrCategoriesData[i].icon === '')
                    this.arrCategoriesData[i].icon = "about_us_icon";
                let objCategory = {
                    key: strParentKey + "-" + iCurrentCategoryKey.toString(),
                    id: this.arrCategoriesData[i].id, parent_id: iParentID,
                    level: this.arrCategoriesData[i].level,
                    title: this.arrCategoriesData[i].name,
                    name: this.arrCategoriesData[i].name,
                    en_name: this.arrCategoriesData[i].en_name,
                    icon: this.arrCategoriesData[i].icon,
                    sort: this.arrCategoriesData[i].sort,
                    locations: this.arrCategoriesData[i].locations,
                    children: []
                };
                if (objCategory.en_name !== null && objCategory.en_name !== undefined &&
                    objCategory.en_name !== "null" && objCategory.en_name !== "") {
                    objCategory.title += " - " + objCategory.en_name;
                }
                if (objCategory.sort !== -1) {
                    objCategory.title = objCategory.sort.toString() + " - " + objCategory.title;
                }
                let arrChildren = this.OrganizeCategoriesData(objCategory.id, objCategory.key);
                objCategory.children = arrChildren;
                arrCurrentLevelCategories.push(objCategory);
                iCurrentCategoryKey++;
            }
        }
        return arrCurrentLevelCategories;
    }
    // 刷新树形结构数据
    RefreshCategoriesTreeStructure(data) {
        this.bDataBack = true;
        this.arrCategoriesData = data;
        global.arrCategoriesData = this.OrganizeCategoriesData(0, "0");
        if (this.state.iDlgType === 0) {
            if (this.state.bShowDlg)
                message.success({ content: "新的分类创建成功！", key: g_strMessageKey, duration: 2 });
            this.setState({
                ...this.state,
                bShowDlg: false,
                expandedKeys: this.strSelectedCategoryKey,
                categoriesData: global.arrCategoriesData
            });
        }
        if (this.state.iDlgType === 1) {
            this.setState({
                ...this.state,
                bShowDlg: false,
                categoriesData: global.arrCategoriesData
            });
        }
        if (this.state.iDlgType === 2) {
            this.strSelectedCategoryKey = "";
            this.strSelectedCategoryName = "";
            this.iSelectedCategoryOrder = -1;
            this.iSelectedCategoryIndex = 0;
            this.iSelectedCategoryID = 0;
            let selectedKeys = [];
            if (this.state.bShowDlg)
                message.success({ content: "分类已删除！", key: g_strMessageKey, duration: 2 });
            this.setState({
                ...this.state,
                btnControlDisable: [true, true, true],
                selectedKeys,
                bShowDlg: false,
                categoriesData: global.arrCategoriesData
            });
        }
    }
    // 从数据库获取当前的分类树形结构
    GetCategoriesData() {
        message.loading({ content: '数据加载中……', key: g_strMessageKey });
        this.bDataBack = false;
        // 1. 发送获取分类数据的请求
        axios('/GetCategories', { params: { id: 0 } })
            .then(({ data }) => {
                message.success({ content: "数据加载完成！", key: g_strMessageKey, duration: 2 });
                this.RefreshCategoriesTreeStructure(data);
            }).catch(function (error) { console.log(error); });
    }
    // 卸载异步操作设置状态
    componentWillUnmount() {
        clearTimeout(this.timeouter)
        this.setState = (state, callback) => {
            return;
        }
    }
    /* 渲染完毕…… (只执行一遍，后续setState引起的render完毕后，不会进入到该函数) */
    componentDidMount() {
        this.GetCategoriesData();
    }
    /* React 渲染函数*/
    render() {
        // 如果界面上需要显示的内容已经从数据库获得
        if (this.bDataBack) {
            // 3个操作按钮的样式控制
            let strNewButtonStyle = { backgroundColor: 'lightseagreen', color: 'white' };
            if (this.state.btnControlDisable[0])
                strNewButtonStyle = {};
            // 弹窗控件
            let strDlgTitle = '在 "' + this.strSelectedCategoryName + '" 下创建新分类';
            let arrInputValue = ["", "", "", ""];
            let arrPlayHolder = ["分类的中文名", "分类的英文名", "分类的排序", "分类所属地区"];
            if (this.state.iDlgType === 1) {
                strDlgTitle = '编辑分类 "' + this.strSelectedCategoryName + '"';
                arrInputValue = [
                    // iSelectedLocation是DB中的id, 用于索引需-1
                    this.arrCategoriesData[this.iSelectedCategoryIndex].name,
                    this.arrCategoriesData[this.iSelectedCategoryIndex].en_name,
                    this.arrCategoriesData[this.iSelectedCategoryIndex].sort,
                    this.arrCategoriesData[this.iSelectedCategoryIndex].locations,
                ];
                arrPlayHolder = ["", "", "", ""];
            }
            // 图标选择器
            const uiIconList = (
                <div>
                    <div className="category_icon_list">
                        {this.arrIconList[0].map((item, index) => {
                            return (
                                <div className={"category_icon_list_item " + item} key={index}
                                    onClick={this.CloseIconSelector.bind(this, 0, index)} />
                            );
                        })}
                    </div>
                    <div className="category_icon_list">
                        {this.arrIconList[1].map((item, index) => {
                            return (
                                <div className={"category_icon_list_item " + item} key={index}
                                    onClick={this.CloseIconSelector.bind(this, 1, index)} />
                            );
                        })}
                    </div>
                    <div className="category_icon_list">
                        {this.arrIconList[2].map((item, index) => {
                            return (
                                <div className={"category_icon_list_item " + item} key={index}
                                    onClick={this.CloseIconSelector.bind(this, 2, index)} />
                            );
                        })}
                    </div>
                    <div className="category_icon_list">
                        {this.arrIconList[3].map((item, index) => {
                            return (
                                <div className={"category_icon_list_item " + item} key={index}
                                    onClick={this.CloseIconSelector.bind(this, 3, index)} />
                            );
                        })}
                    </div>
                    <div className="category_icon_list">
                        {this.arrIconList[4].map((item, index) => {
                            return (
                                <div className={"category_icon_list_item " + item} key={index}
                                    onClick={this.CloseIconSelector.bind(this, 4, index)} />
                            );
                        })}
                    </div>
                    <div className="category_icon_list">
                        {this.arrIconList[5].map((item, index) => {
                            return (
                                <div className={"category_icon_list_item " + item} key={index}
                                    onClick={this.CloseIconSelector.bind(this, 5, index)} />
                            );
                        })}
                    </div>
                    <div className="category_icon_list">
                        {this.arrIconList[6].map((item, index) => {
                            return (
                                <div className={"category_icon_list_item " + item} key={index}
                                    onClick={this.CloseIconSelector.bind(this, 6, index)} />
                            );
                        })}
                    </div>
                    <div className="category_icon_list">
                        {this.arrIconList[7].map((item, index) => {
                            return (
                                <div className={"category_icon_list_item " + item} key={index}
                                    onClick={this.CloseIconSelector.bind(this, 7, index)} />
                            );
                        })}
                    </div>
                    <div className="category_icon_list">
                        {this.arrIconList[8].map((item, index) => {
                            return (
                                <div className={"category_icon_list_item " + item} key={index}
                                    onClick={this.CloseIconSelector.bind(this, 8, index)} />
                            );
                        })}
                    </div>
                    <div className="category_icon_list">
                        {this.arrIconList[9].map((item, index) => {
                            return (
                                <div className={"category_icon_list_item " + item} key={index}
                                    onClick={this.CloseIconSelector.bind(this, 9, index)} />
                            );
                        })}
                    </div>
                    <div className="category_icon_list">
                        {this.arrIconList[10].map((item, index) => {
                            return (
                                <div className={"category_icon_list_item " + item} key={index}
                                    onClick={this.CloseIconSelector.bind(this, 10, index)} />
                            );
                        })}
                    </div>
                </div>
            );
            // 排序输入框
            let uiOrderInput = <div />;
            if (this.state.iDlgType === 1 && this.iSelectedCategoryLevel <= 2) {
                uiOrderInput = (
                    <div className="manage_modal_item">
                        <div className="manage_info_label">排序</div>
                        <Input className="manage_modal_input" ref="input_sort"
                            defaultValue={arrInputValue[2]} placeholder={arrPlayHolder[2]} />
                    </div>
                );
            }
            // 地区选择框
            let uiLocationsSelector = <div />;
            let iLevel = this.iSelectedCategoryLevel;
            if (this.state.iDlgType === 0)
                iLevel++;
            if (iLevel === 1 || iLevel === 2) {
                uiLocationsSelector = (
                    <div className="manage_modal_item">
                        <div className="manage_info_label">地区</div>
                        <Input className="manage_modal_input" ref="input_locations"
                            defaultValue={arrInputValue[3]} placeholder={arrPlayHolder[3]} />
                    </div>
                );
            }
            // 新建/编辑弹窗
            let uiIconSelctorTitle = (
                <div className="icon_selector_title">
                    选择一个新的图标
                    <div className="icon_selector_close_btn"
                        onClick={this.CloseIconSelector.bind(this, -1, -1)}>x</div>
                </div>
            );
            let uiDialog = (
                <Modal width={300} title={strDlgTitle}
                    visible={this.state.bShowDlg} onOk={this.OKButtonClick} okText="确定"
                    onCancel={this.CancelButtonClick} cancelText="取消">
                    <div className="manage_modal_item">
                        <div className="manage_info_label">中文名</div>
                        <Input className="manage_modal_input" ref="input_cn_name"
                            defaultValue={arrInputValue[0]} placeholder={arrPlayHolder[0]} />
                    </div>
                    <div className="manage_modal_item">
                        <div className="manage_info_label">英文名</div>
                        <Input className="manage_modal_input" ref="input_en_name"
                            defaultValue={arrInputValue[1]} placeholder={arrPlayHolder[1]} />
                    </div>
                    {uiLocationsSelector}
                    {uiOrderInput}
                    <div className="manage_modal_item">
                        <div className="manage_info_label">图标</div>
                        <Popover className="category_icon_selector_popover " placement="right"
                            content={uiIconList} title={uiIconSelctorTitle} trigger="click"
                            visible={this.state.bShowIconSelector} >
                            <div className={"category_icon_current " + this.strSelectedCategoryIcon} />
                            <Button ghost type="primary" onClick={this.ShowIconSelector}>换一个</Button>
                        </Popover>
                    </div>
                </Modal>
            );
            if (this.state.iDlgType === 2) {
                strDlgTitle = '删除分类 "' + this.strSelectedCategoryName + '"';
                uiDialog = (
                    <Modal width={350} title={strDlgTitle}
                        visible={this.state.bShowDlg} okType="danger"
                        onOk={this.OKButtonClick} okText="确定"
                        onCancel={this.CancelButtonClick} cancelText="取消">
                        <div className="modal_item">
                            <div>该分类下的所有子分类也将一并删除……<br />确定删除该分类？</div>
                        </div>
                    </Modal>
                );
            }
            // 渲染控件主体
            return (
                <div className="manage_contents manage_contents_less_top" style={{ background: 'white' }}>
                    <div className="manage_contents_title">
                        <div className="category_page_title_button">
                            <Button className="manage_contents_title_margin" style={strNewButtonStyle}
                                onClick={this.ShowDlg.bind(this, 0)}
                                disabled={this.state.btnControlDisable[0]}>
                                <Icon type="plus" />新建
                            </Button>
                            {uiDialog}
                            <Button className="manage_contents_title_margin" type="primary"
                                onClick={this.ShowDlg.bind(this, 1)}
                                disabled={this.state.btnControlDisable[1]}>
                                <Icon type="edit" /> 编辑
                            </Button>
                            {uiDialog}
                            <Button className="manage_contents_title_margin" type="danger"
                                onClick={this.ShowDlg.bind(this, 2)}
                                disabled={this.state.btnControlDisable[2]}>
                                <Icon type="close" /> 删除
                            </Button>
                            {uiDialog}
                        </div>
                    </div>
                    <Tree className="manage_tree_structure" showLine showIcon
                        onExpand={this.onExpand} defaultExpandAll={true} defaultExpandParent={true}
                        autoExpandParent={true} onSelect={this.onSelect}
                        selectedKeys={this.state.selectedKeys} >
                        {this.renderTreeNodes(this.state.categoriesData)}
                    </Tree>
                </div>
            );
        } else {
            return (<Spin className="manage_empty_loading" size="large" tip="加载中……" />);
        }
    }
}
export default CategoryManagement;
