import React, { Component } from "react";

import "antd/dist/antd.css";
import "../../../styles/Background/store_manager.css";
import "../../../styles/Background/UI_Module/delivery_fee_calculator.css";

class PriceDetailDlg extends Component {
    constructor(props) {
        super();
        this.state = {
            bUpdatePrice: false
        };

        this.fUPSDiscount = 0.0;
        // this.fJetonDiscount = 1.5;
        this.strAdditionalHandling = "";
        this.fClientFinalPrice = 0.0;
        this.fClientGST = 0.0;
        // Canada Post
        this.objCurrentPriceDetail = {
            fBase: 0.00,
            fService: 0.00,
            fGST: 0.00,
            fPST: 0.00,
            fHST: 0.00,
            fTotal: 0.00
        }
        // UPS
        if (props.iExpressType === 1) {
            this.objCurrentPriceDetail = {
                fUPSStandard: 0.00,
                fFuelSurcharge: 0.00,
                fPeakAndDemandSurcharge: 0.00,
                fResidentialAddressSurchage: 0.00,
                fShippingFees: 0.00,
                fAddtionalHandling: 0.00
            }

        }


    }

    // 渲染完毕……
    componentDidMount() {
        console.log("Price Detail Dlg Show: ", this.props.objPriceDetail, this.props.iExpressType);
        // Canada Post
        if (this.props.iExpressType === 0) {

            this.objCurrentPriceDetail = {
                fBase: 0.00,
                fService: 0.00,
                fGST: 0.00,
                fPST: 0.00,
                fHST: 0.00,
                fTotal: 0.00
            }

            this.objCurrentPriceDetail.fBase = parseFloat(this.props.objPriceDetail.base);
            this.objCurrentPriceDetail.fBase = this.objCurrentPriceDetail.fBase.toFixed(2);
            this.objCurrentPriceDetail.fService = parseFloat(this.props.objPriceDetail.service);
            this.objCurrentPriceDetail.fService = this.objCurrentPriceDetail.fService.toFixed(2);
            this.objCurrentPriceDetail.fGST = parseFloat(this.props.objPriceDetail.gst);
            this.objCurrentPriceDetail.fGST = this.objCurrentPriceDetail.fGST.toFixed(2);
            this.objCurrentPriceDetail.fPST = parseFloat(this.props.objPriceDetail.pst);
            this.objCurrentPriceDetail.fPST = this.objCurrentPriceDetail.fPST.toFixed(2);
            this.objCurrentPriceDetail.fHST = parseFloat(this.props.objPriceDetail.hst);
            this.objCurrentPriceDetail.fHST = this.objCurrentPriceDetail.fHST.toFixed(2);
            this.objCurrentPriceDetail.fTotal = parseFloat(this.props.objPriceDetail.total);
            this.objCurrentPriceDetail.fTotal = this.objCurrentPriceDetail.fTotal.toFixed(2);
        }

        // UPS
        if (this.props.iExpressType === 1) {
            this.objCurrentPriceDetail = {
                fUPSStandard: 0.00,
                fFuelSurcharge: 0.00,
                fPeakAndDemandSurcharge: 0.00,
                fResidentialAddressSurchage: 0.00,
                fShippingFees: 0.00,
                fAddtionalHandling: 0.00
            }
            this.fUPSDiscount = parseFloat(this.props.objPriceDetail.NegotiatedRateCharges.TotalCharge.MonetaryValue) /
                parseFloat(this.props.objPriceDetail.TotalCharges.MonetaryValue);
            console.log("fUPSDiscount: ", this.fUPSDiscount);
            this.fClientGST = parseFloat(this.props.objPriceDetail.NegotiatedRateCharges.TotalCharge.MonetaryValue) * 0.05;
            this.fClientFinalPrice = parseFloat(this.props.objPriceDetail.NegotiatedRateCharges.TotalCharge.MonetaryValue) + this.fClientGST;

            this.fClientGST = this.fClientGST.toFixed(2);
            this.fClientFinalPrice = this.fClientFinalPrice.toFixed(2);

            // 加入Jeton折扣
            this.objCurrentPriceDetail.fUPSStandard = parseFloat(this.props.objPriceDetail.BaseServiceCharge.MonetaryValue) * this.fUPSDiscount;
            if (this.props.objPriceDetail.ItemizedCharges[0] !== undefined)
                this.objCurrentPriceDetail.fFuelSurcharge = parseFloat(this.props.objPriceDetail.ItemizedCharges[0].MonetaryValue) * this.fUPSDiscount;
            else
                this.objCurrentPriceDetail.fFuelSurcharge = parseFloat(this.props.objPriceDetail.ItemizedCharges.MonetaryValue) * this.fUPSDiscount;
            if (this.props.objPriceDetail.ItemizedCharges.length > 1)
                this.objCurrentPriceDetail.fPeakAndDemandSurcharge = parseFloat(this.props.objPriceDetail.ItemizedCharges[1].MonetaryValue) * this.fUPSDiscount;
            if (this.props.objPriceDetail.ItemizedCharges.length > 2)
                this.objCurrentPriceDetail.fResidentialAddressSurchage = parseFloat(this.props.objPriceDetail.ItemizedCharges[2].MonetaryValue) * this.fUPSDiscount;
            this.objCurrentPriceDetail.fShippingFees = parseFloat(this.props.objPriceDetail.TransportationCharges.MonetaryValue) * this.fUPSDiscount;
            if (this.props.objPriceDetail.RatedPackage.ItemizedCharges !== null && this.props.objPriceDetail.RatedPackage.ItemizedCharges !== undefined) {
                this.objCurrentPriceDetail.fAddtionalHandling = parseFloat(this.props.objPriceDetail.RatedPackage.ItemizedCharges.MonetaryValue) * this.fUPSDiscount;
                if (this.props.objPriceDetail.RatedPackage.ItemizedCharges.SubType !== null && this.props.objPriceDetail.RatedPackage.ItemizedCharges.SubType !== undefined)
                    this.strAdditionalHandling = " (" + this.props.objPriceDetail.RatedPackage.ItemizedCharges.SubType + ")";
            }

            this.objCurrentPriceDetail.fUPSStandard = this.objCurrentPriceDetail.fUPSStandard.toFixed(2);
            this.objCurrentPriceDetail.fFuelSurcharge = this.objCurrentPriceDetail.fFuelSurcharge.toFixed(2);
            this.objCurrentPriceDetail.fPeakAndDemandSurcharge = this.objCurrentPriceDetail.fPeakAndDemandSurcharge.toFixed(2);
            this.objCurrentPriceDetail.fResidentialAddressSurchage = this.objCurrentPriceDetail.fResidentialAddressSurchage.toFixed(2);
            this.objCurrentPriceDetail.fShippingFees = this.objCurrentPriceDetail.fShippingFees.toFixed(2);
            this.objCurrentPriceDetail.fAddtionalHandling = this.objCurrentPriceDetail.fAddtionalHandling.toFixed(2);

            // this.props.objPriceDetail = this.objCurrentPriceDetail;
        }

        this.setState({
            bUpdatePrice: true
        })
    }

    render() {
        // Canada Post
        if (this.props.iExpressType === 0) {
            return (
                <div className="jeton_price_detail_dlg_area">
                    <div className="jeton_price_detail_row" style={{ border: 'none' }}>
                        <div className="jeton_price_detail_col1 jeton_price_detail_title" >{"Item"}</div>
                        <div className="jeton_price_detail_col2 jeton_price_detail_title" >{"Price ($CAD)"}</div>
                    </div>
                    <div className="jeton_price_detail_row">
                        <div className="jeton_price_detail_col1" >{"Base"}</div>
                        <div className="jeton_price_detail_col2" >{this.objCurrentPriceDetail.fBase}</div>
                    </div>
                    <div className="jeton_price_detail_row">
                        <div className="jeton_price_detail_col1" >{"Service"}</div>
                        <div className="jeton_price_detail_col2" >{this.objCurrentPriceDetail.fService}</div>
                    </div>
                    <div className="jeton_price_detail_row">
                        <div className="jeton_price_detail_col1" >{"GST"}</div>
                        <div className="jeton_price_detail_col2" >{this.objCurrentPriceDetail.fGST}</div>
                    </div>
                    <div className="jeton_price_detail_row">
                        <div className="jeton_price_detail_col1" >{"PST"}</div>
                        <div className="jeton_price_detail_col2" >{this.objCurrentPriceDetail.fPST}</div>
                    </div>
                    <div className="jeton_price_detail_row">
                        <div className="jeton_price_detail_col1" >{"HST"}</div>
                        <div className="jeton_price_detail_col2" >{this.objCurrentPriceDetail.fHST}</div>
                    </div>
                    <div className="jeton_price_detail_row jeton_price_detail_row_subtotal">
                        <div className="jeton_price_detail_col1" >{"Total (with taxes)"}</div>
                        <div className="jeton_price_detail_col2" >{this.objCurrentPriceDetail.fTotal}</div>
                    </div>
                </div>
            );
        }
        // UPS
        if (this.props.iExpressType === 1) {
            let uiUPSPriceDetail = (
                <div className="jeton_price_detail_dlg_area">
                    <div className="jeton_price_detail_row" style={{ border: 'none' }}>
                        <div className="jeton_price_detail_col1 jeton_price_detail_title" >{"Item"}</div>
                        <div className="jeton_price_detail_col2 jeton_price_detail_title" >{"Price ($CAD)"}</div>
                    </div>
                    <div className="jeton_price_detail_row">
                        <div className="jeton_price_detail_col1" >{"UPS Standard"}</div>
                        <div className="jeton_price_detail_col2" >{this.objCurrentPriceDetail.fUPSStandard}</div>
                    </div>
                    <div className="jeton_price_detail_row">
                        <div className="jeton_price_detail_col1" >{"Fuel Surcharge"}</div>
                        <div className="jeton_price_detail_col2" >{this.objCurrentPriceDetail.fFuelSurcharge}</div>
                    </div>
                    <div className="jeton_price_detail_row">
                        <div className="jeton_price_detail_col1" >{"Peak and Demand Surcharge Residential"}</div>
                        <div className="jeton_price_detail_col2" >{this.objCurrentPriceDetail.fPeakAndDemandSurcharge}</div>
                    </div>
                    <div className="jeton_price_detail_row">
                        <div className="jeton_price_detail_col1" >{"Residential AddressSurchage"}</div>
                        <div className="jeton_price_detail_col2" >{this.objCurrentPriceDetail.fResidentialAddressSurchage}</div>
                    </div>
                    <div className="jeton_price_detail_row jeton_price_detail_row_subtotal">
                        <div className="jeton_price_detail_col1" >{"Shipping Fees (Subtotal)"}</div>
                        <div className="jeton_price_detail_col2" >{this.objCurrentPriceDetail.fShippingFees}</div>
                    </div>
                    <div className="jeton_price_detail_row">
                        <div className="jeton_price_detail_col1" >{"Additional Option Fees" + this.strAdditionalHandling}</div>
                        <div className="jeton_price_detail_col2" >{this.objCurrentPriceDetail.fAddtionalHandling}</div>
                    </div>
                    <div className="jeton_price_detail_row">
                        <div className="jeton_price_detail_col1" >{"GST"}</div>
                        <div className="jeton_price_detail_col2" >{this.fClientGST}</div>
                    </div>
                    <div className="jeton_price_detail_row jeton_price_detail_row_subtotal">
                        <div className="jeton_price_detail_col1" >{"Total (with taxes)"}</div>
                        <div className="jeton_price_detail_col2" >{this.fClientFinalPrice}</div>
                    </div>
                </div>
            );
            return uiUPSPriceDetail;
        }

    }
}
export default PriceDetailDlg;
