import React, { Component } from "react";
import axios from 'axios';
import cookie from 'react-cookies';
import "antd/dist/antd.css";
import "../../styles/Background/db_manager.css";

import Empty from 'antd/es/empty';
import Spin from 'antd/es/spin';
import Menu from 'antd/es/menu';
import Icon from 'antd/es/icon';
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import Form from 'antd/es/form';
import message from 'antd/es/message';

import CPShippingOrder from "./ManagerPage/CPShippingOrder.js";
import UPSShippingOrder from "./ManagerPage/UPSShippingOrder.js";
import ShipOrderShipperManager from "./ManagerPage/ShipOrderShipperManager.js";
import ShipOrderReceiverManager from "./ManagerPage/ShipOrderReceiverManager.js";
import ShipOrderStatistic from "./ManagerPage/ShipOrderStatistic.js";
import ShipOrderVipSetting from "./ManagerPage/ShipOrderVipSetting.js";

const g_strMessageKey = 'updating';

function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class ShippingManager extends Component {
    // 构造函数
    constructor(props) {
        super();

        this.iUserID = 0;
        this.iStoreID = 0;
        let strDomain = window.location.href;
        console.log("strDomain = " + strDomain);
        if (strDomain.indexOf('jw-ship') >= 0) {
            this.iStoreID = 30000;
        }
        if (strDomain.indexOf('sn-ship') >= 0) {
            this.iStoreID = 50000;
        }

        this.state = {
            bLoading: true,  // 加载状态
            bLogin: false,  // 登录状态
            iCurrentSideBarIndex: 0,  // 当前显示的中心区域内容索引
        };
        this.arrSideMenuButton = [
            { id: 0, name: "UPS运单", icon: "code-sandbox" },
            { id: 1, name: "加邮运单", icon: "code-sandbox" },
            { id: 2, name: "寄件人", icon: "profile" },
            { id: 3, name: "收件人", icon: "user" },
            { id: 4, name: '用户信息', icon: 'account-book' }
        ];
        this.arrSideMenuButtonManager = [
            { id: 0, name: "UPS运单", icon: "code-sandbox" },
            { id: 1, name: "加邮运单", icon: "code-sandbox" },
            { id: 2, name: "寄件人", icon: "profile" },
            { id: 3, name: "收件人", icon: "user" },
            { id: 4, name: '用户管理', icon: 'account-book' },
            { id: 5, name: 'VIP设置', icon: 'setting' }
        ]
        this.strCurrentUserName = '';
        this.strCurrentUserCode = '';
        this.iLevel = 0;
        this.fMoney = 0.00;
        this.iVIP = 0;
    }
    // 切换侧边栏菜单
    SwitchSideMenu = (iMenuID) => {
        this.setState({
            ...this.state,
            bLoading: false,
            iCurrentSideBarIndex: iMenuID
        });
    }
    // 登录表单提交响应
    LoginSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                message.loading({ content: '登录中……', key: g_strMessageKey });
                this.setState({
                    ...this.state,
                    bLoading: true
                });
                this.TryLogin(values.username, values.password)
            }
        });
    };
    // 尝试登录
    TryLogin = (name, password) => {
        let objUserInfo = { name: name, password: password };
        axios('/ShippingBackEndLogin', { params: objUserInfo })
            .then(({ data }) => {
                console.log("ShippingBackEndLogin : ", data);
                if (data.status === -1) {
                    message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
                    this.setState({
                        ...this.state,
                        bLoading: false
                    });
                }
                else {
                    message.success({ content: data.message, key: g_strMessageKey, duration: 2 });
                    this.strCurrentUserName = data.name;
                    this.iUserID = data.id;
                    this.iLevel = data.level;
                    this.iVIP = data.vip;
                    if (data.money !== undefined && data.money !== null && data.money !== "" && !isNaN(data.money)) {
                        this.fMoney = parseFloat(data.money);
                        this.fMoney = (this.fMoney).toFixed(2);
                    }
                    cookie.save('ego_user_code', data.code, { path: '/' });
                    cookie.save('ego_login_status', 1, { path: '/' });
                    this.setState({
                        ...this.state,
                        bLoading: false,
                        bLogin: true
                    });
                    this.SwitchSideMenu(this.state.iCurrentSideBarIndex);
                }
            }).catch(function (error) { console.log(error); });
    }
    // 退出登录
    LogoutClick = () => {
        cookie.remove('ego_user_code', { path: '/' });
        cookie.save('ego_login_status', -1, { path: '/' });
        this.setState({
            ...this.state,
            bLogin: false
        });
    }
    // 用户识别码检查
    UserCodeCheck = () => {
        // console.log("ShipUserCodeCheck user code : ", this.strCurrentUserCode);
        axios('/ShipUserCodeCheck', { params: { code: this.strCurrentUserCode } })
            .then(({ data }) => {
                // console.log("ShipUserCodeCheck response : ", data);
                if (data.status < 0) {
                    message.warning(data.message);
                    this.setState({
                        ...this.state,
                        bLoading: false
                    });
                } else {
                    message.success(data.message);
                    this.strCurrentUserName = data.name;
                    this.iUserID = data.id;
                    this.iLevel = data.level;
                    this.iVIP = data.vip;
                    if (data.money !== undefined && data.money !== null && data.money !== "" && !isNaN(data.money)) {
                        this.fMoney = parseFloat(data.money);
                        this.fMoney = (this.fMoney).toFixed(2);
                    }

                    this.setState({
                        ...this.state,
                        bLoading: false,
                        bLogin: true
                    });
                }
            }).catch(function (error) { console.log(error); });
    }

    // 渲染完毕……
    componentDidMount() {
        let strUserCode = cookie.load('ego_user_code');
        let iUserLoginStatus = cookie.load('ego_login_status');
        if ((iUserLoginStatus === 1 || iUserLoginStatus === '1') && strUserCode !== undefined) {
            this.strCurrentUserCode = strUserCode;
            this.UserCodeCheck();
        } else {
            this.setState({ ...this.state, bLoading: false, bLogin: false });
        }
        document.title = "Jeton Express Services 捷通快递服务";
    }

    render() {

        const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;
        // Only show error after a field is touched.
        const usernameError = isFieldTouched('username') && getFieldError('username');
        const passwordError = isFieldTouched('password') && getFieldError('password');
        // 登录表单
        const uiLogInForm = (
            <Form className="manage_login_form" layout="inline" onSubmit={this.LoginSubmit}>
                <Form.Item validateStatus={usernameError ? 'error' : ''} help={usernameError || ''}>
                    {getFieldDecorator('username')(
                        <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="用户名" />,
                    )}
                </Form.Item>
                <Form.Item validateStatus={passwordError ? 'error' : ''} help={passwordError || ''}>
                    {getFieldDecorator('password')(
                        <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="密码" />,
                    )}
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" disabled={hasErrors(getFieldsError())}>
                        登 陆
                    </Button>
                </Form.Item>
            </Form>
        );
        // 欢迎回来
        let strTitle = '欢迎回来,  ' + this.strCurrentUserName;
        const uiWelcomeBack = (
            <div className="manage_login_form">
                <div className="welcome_back_title">{strTitle}</div>
                <Button className="logout_btn" type="primary" htmlType="submit"
                    onClick={this.LogoutClick}>退出登录</Button>
            </div>
        );
        // 顶部菜单栏
        let uiNavBar = (
            <div className="manage_top_bar">
                <div className="platform_logo" style={{ marginLeft: '0px' }} >JW Ship</div>
                {uiWelcomeBack}
            </div>
        );
        // 侧边菜单栏
        let arrMenu = this.arrSideMenuButton;
        if (this.iLevel >= 9)
            arrMenu = this.arrSideMenuButtonManager;
        let uiSideBar = (
            <Menu className="store_manage_side_bar" defaultSelectedKeys={[this.state.iCurrentSideBarIndex.toString()]}
                selectedKeys={[this.state.iCurrentSideBarIndex.toString()]}
                mode="inline" theme="dark" inlineCollapsed={this.state.collapsed}>
                {arrMenu.map((item, index) => {
                    return (
                        <Menu.Item key={index} onClick={this.SwitchSideMenu.bind(this, index)}>
                            <Icon type={item.icon} />{item.name}
                        </Menu.Item>
                    );
                })}
            </Menu>
        );
        if (!this.state.bLogin) {
            uiNavBar = (
                <div className="manage_top_bar">
                    <div className="platform_logo" style={{ marginLeft: '0px' }} >Shipping</div>
                    <div className="manage_top_bar_menu" />
                    {uiLogInForm}
                </div>
            );
            uiSideBar = <div />;
        }

        let uiCentralArea;
        // 是否还在加载中……
        if (this.state.bLoading) {
            uiCentralArea = (<Spin className="manage_empty_loading" size="large" tip="加载中……" />);
        } else {
            if (this.state.bLogin) {
                let uiUPSOrderManager = (this.state.iCurrentSideBarIndex === arrMenu[0].id &&
                    <UPSShippingOrder iStoreID={this.iStoreID} iUserID={this.iUserID} strUserName={this.strCurrentUserName}
                        iLevel={this.iLevel} fMoney={this.fMoney} iVIP={this.iVIP} />);
                let uiCPOrderManager = (this.state.iCurrentSideBarIndex === arrMenu[1].id &&
                    <CPShippingOrder iStoreID={this.iStoreID} iUserID={this.iUserID} strUserName={this.strCurrentUserName}
                        iLevel={this.iLevel} fMoney={this.fMoney} iVIP={this.iVIP} />);
                let uiShiperManager = (this.state.iCurrentSideBarIndex === arrMenu[2].id &&
                    <ShipOrderShipperManager iStoreID={this.iStoreID} iUserID={this.iUserID} iLevel={this.iLevel} />);
                let uiReceiverManager = (this.state.iCurrentSideBarIndex === arrMenu[3].id &&
                    <ShipOrderReceiverManager iStoreID={this.iStoreID} iUserID={this.iUserID} iLevel={this.iLevel} />);
                let uiUserInfo = (this.state.iCurrentSideBarIndex === arrMenu[4].id &&
                    <ShipOrderStatistic iStoreID={this.iStoreID} iUserID={this.iUserID} strUserName={this.strCurrentUserName}
                        iLevel={this.iLevel} fMoney={this.fMoney} />);
                let uiVIPSetting = null;
                if (this.iLevel >= 9)
                    uiVIPSetting = (this.state.iCurrentSideBarIndex === arrMenu[5].id &&
                        <ShipOrderVipSetting iStoreID={this.iStoreID} iUserID={this.iUserID} strUserName={this.strCurrentUserName}
                            iLevel={this.iLevel} fMoney={this.fMoney} />);
                uiCentralArea = (
                    <div>
                        {uiUPSOrderManager}
                        {uiCPOrderManager}
                        {uiShiperManager}
                        {uiReceiverManager}
                        {uiUserInfo}
                        {uiVIPSetting}
                    </div>
                );
            } else {
                uiCentralArea = (<Empty className="manage_empty_loading" description={<span>未登录，无法使用</span>} />);
            }
        }

        return (
            <div className="manage_page">
                {/* 顶部菜单栏 */}
                {uiNavBar}
                {/* 侧边菜单栏 */}
                {uiSideBar}
                {/* 中心区域内容 */}
                {uiCentralArea}
            </div >
        );
    }
}
export default Form.create()(ShippingManager);
