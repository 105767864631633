import React, { Component } from 'react';
import axios from 'axios';

import Spin from 'antd/es/spin';
import Table from 'antd/es/table';
import Button from 'antd/es/button';
import Input from 'antd/es/input';
import Icon from 'antd/es/icon';
import Modal from 'antd/es/modal';
import message from 'antd/es/message';

import '../../../styles/Background/ego_manager.css';
import '../../../styles/Background/UI_Module/user_management.css';
import '../../../styles/Background/UI_Module/transit_price.css';
import { UserActiveUpdate } from "../../../functions/DataStatistic.js"

const g_strMessageKey = 'updating';

// 表格
const arrTableHeader = [
    { title: '账号', dataIndex: 'account', width: 250, ellipsis: true },
    { title: '密码', dataIndex: 'password', width: 100, ellipsis: true },
    { title: '备注', dataIndex: 'comments', width: 300, ellipsis: true },
    { title: '更新时间', dataIndex: 'update_date', width: 125, ellipsis: true }
];
const arrTableHeader_m = [
    { title: '账号', dataIndex: 'account', className: 'store_manage_table_m', width: 100, ellipsis: false },
    { title: '密码', dataIndex: 'password', className: 'store_manage_table_m', width: 100, ellipsis: false },
    { title: '备注', dataIndex: 'comments', className: 'store_manage_table_m', width: 100, ellipsis: false }
];

class CommentsManager extends Component {
    constructor(props) {
        super();
        this.state = {
            btnControlDisable: [false, true, true],  // 操作按钮控制
            selectedRowKeys: [],  // Check here to configure the default column
            iDlgType: -1,
            bShowDlg: false,
            bLoading: true
        };
        // 成员变量
        this.arrTableData = [];  // 用于表格的数组
        this.objCurrentComments = { id: 0, account: "", password: "", comments: "", update_date: "" };
        this.tableHead = arrTableHeader;
        if (props.bMStation)
            this.tableHead = arrTableHeader_m;
    }

    // 选中某行的响应函数
    selectRow = (record) => {
        const selectedRowKeys = [...this.state.selectedRowKeys];
        if (selectedRowKeys.indexOf(record.key) >= 0) {
            selectedRowKeys.splice(selectedRowKeys.indexOf(record.key), 1);
        } else {
            selectedRowKeys.push(record.key);
        }
        this.SelectedRowStateSetting(selectedRowKeys);
    }
    // 选中的行发生变化的响应函数
    onSelectedRowKeysChange = (selectedRowKeys) => {
        this.SelectedRowStateSetting(selectedRowKeys);
    }
    // 处理选中的表行行
    SelectedRowStateSetting = (selectedRowKeys) => {
        let btnControlDisable = [];
        switch (selectedRowKeys.length) {
            case 0: {
                btnControlDisable = [false, true, true];
                this.objCurrentComments = { id: 0, account: "", password: "", comments: "", update_date: "" };
                break;
            }
            case 1: {
                btnControlDisable = [false, false, false];
                for (let i = 0; i < this.arrTableData.length; i++) {
                    if (this.arrTableData[i].key === selectedRowKeys[0]) {
                        this.objCurrentComments = this.arrTableData[i];
                        break;
                    }
                }
                break;
            }
            default: {
                btnControlDisable = [false, true, true]; break;
            }
        }
        this.setState({
            ...this.state,
            selectedRowKeys,
            btnControlDisable: btnControlDisable
        });
    }
    //弹出对话框
    ShowDlg = (iDlgType, e) => {
        if (iDlgType === 0) {
            if (this.refs.account !== undefined) {
                this.refs.account.state.value = "";
                this.refs.password.state.value = "";
                this.refs.comments.state.value = "";
            }
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true
            });
        }
        if (iDlgType === 1) {
            if (this.refs.account !== undefined) {
                this.refs.account.state.value = this.objCurrentComments.account;
                this.refs.password.state.value = this.objCurrentComments.password;
                this.refs.comments.state.value = this.objCurrentComments.comments;
            }
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true
            });
        }
        if (iDlgType === 2) {
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true
            });
        }
    }
    // 弹窗确认按钮响应
    handleOk = e => {
        delete this.objCurrentComments.key;
        if (this.state.iDlgType === 0) {
            delete this.objCurrentComments.id;
            let strAccount = this.refs.account.state.value;
            let strPassword = this.refs.password.state.value;
            let strComments = this.refs.comments.state.value;
            if (strAccount === null || strAccount.trim() === "") {
                strAccount = "";
                // message.warning("账号不能为空！"); return;
            }
            if (strPassword === null || strPassword.trim() === "") {
                strPassword = "";
                // message.warning("密码不能为空！"); return;
            }
            if (strComments === null || strComments.trim() === "") {
                message.warning("这是哪里的账号？备注不能为空！"); return;
            }
            this.objCurrentComments.account = strAccount;
            this.objCurrentComments.password = strPassword;
            this.objCurrentComments.comments = strComments;
            message.loading({ content: '正在更新备忘录信息……', key: g_strMessageKey });
            axios.post('/NewAccountComments', {
                objAccountComments: this.objCurrentComments
            }).then(({ data }) => {
                // DataStatistic
                UserActiveUpdate(4, { active: "商家后台: 备忘录管理操作", action: "新建" });
                this.GetAccountCommentsData();
            }).catch(function (error) { console.log(error); });
        }
        if (this.state.iDlgType === 1) {
            let strAccount = this.refs.account.state.value;
            let strPassword = this.refs.password.state.value;
            let strComments = this.refs.comments.state.value;
            if (strAccount === null || strAccount.trim() === "") {
                strAccount = "";
                // message.warning("账号不能为空！"); return;
            }
            if (strPassword === null || strPassword.trim() === "") {
                strPassword = "";
                // message.warning("密码不能为空！"); return;
            }
            if (strComments === null || strComments.trim() === "") {
                message.warning("这是哪里的账号？备注不能为空！"); return;
            }
            this.objCurrentComments.account = strAccount;
            this.objCurrentComments.password = strPassword;
            this.objCurrentComments.comments = strComments;
            message.loading({ content: '正在更新备忘录信息……', key: g_strMessageKey });
            axios.post('/UpdateAccountComments', {
                objAccountComments: this.objCurrentComments
            }).then(({ data }) => {
                // DataStatistic
                UserActiveUpdate(4, { active: "商家后台: 备忘录管理操作", action: "编辑" });
                this.GetAccountCommentsData();
            }).catch(function (error) { console.log(error); });
        }
        if (this.state.iDlgType === 2) {
            message.loading({ content: '备忘录信息删除中……', key: g_strMessageKey });
            axios.post('/DeleteAccountComments', { id: this.objCurrentComments.id }).then(
                ({ data }) => {
                    console.log("DeleteAccountComments : ", data);
                    // DataStatistic
                    UserActiveUpdate(4, { active: "管理后台: 备忘录管理操作", action: "删除" });
                    this.GetAccountCommentsData();
                }).catch(function (error) { console.log(error); });
        }
    };
    // 弹窗取消按钮响应
    handleCancel = e => {
        this.setState({
            bShowDlg: false,
        });
    };
    // 从数据库获取当前的数据结构
    GetAccountCommentsData() {
        message.loading({ content: '数据加载中……', key: g_strMessageKey });
        axios('/GetAccountComments', { params: { id: 0 } })
            .then(({ data }) => {
                this.arrTableData = data;
                if (this.arrTableData.length > 0) {
                    for (let i = 0; i < this.arrTableData.length; i++) {
                        this.arrTableData[i].key = i;
                        this.arrTableData[i].update_date = this.arrTableData[i].update_date.split('T')[0];
                    }
                }
                if (this.state.bShowDlg) {
                    if (this.state.iDlgType === 0) {
                        message.success({ content: "新增一条备忘信息！", key: g_strMessageKey, duration: 2 });
                    }
                    if (this.state.iDlgType === 1) {
                        message.success({ content: "备忘信息已更新！", key: g_strMessageKey, duration: 2 });
                    }
                    if (this.state.iDlgType === 2) {
                        message.success({ content: "备忘信息已删除！", key: g_strMessageKey, duration: 2 })
                    }
                } else {
                    message.success({ content: "数据加载完成！", key: g_strMessageKey, duration: 2 });
                }
                this.setState({
                    ...this.state,
                    bLoading: false,
                    bShowDlg: false,
                    selectedRowKeys: []
                });
            }).catch(function (error) { console.log(error); });
    }

    /* React自带函数 */
    // 卸载异步操作设置状态
    componentWillUnmount() {
        clearTimeout(this.timeouter)
        this.setState = (state, callback) => {
            return;
        }
    }
    // 渲染完毕……
    componentDidMount() {
        this.GetAccountCommentsData();
    }
    // 渲染函数
    render() {

        /* 用户管理页面控制栏 */
        // let strNewButtonStyle = { backgroundColor: 'lightseagreen', color: 'white' };
        let strNewButtonStyle = { backgroundColor: 'lightseagreen', color: 'white' };
        let strAreaStyle = "store_manage_contents store_manage_top_no_space";
        let uiTitleArea = null;
        // 管理员
        if (this.props.iUserLevel >= 9) {
            strAreaStyle = "store_manage_contents store_manage_top_space";
            uiTitleArea = (
                <div className="store_contents_title" >
                    <Button className="manage_contents_title_margin" style={strNewButtonStyle}
                        onClick={this.ShowDlg.bind(this, 0)} disabled={this.state.btnControlDisable[0]}>
                        <Icon type="plus" />新建</Button>
                    <Button className="manage_contents_title_margin" type="primary" onClick={this.ShowDlg.bind(this, 1)} disabled={this.state.btnControlDisable[1]}>
                        <Icon type="edit" /> 编辑</Button>
                    <Button className="manage_contents_title_margin" type="danger" onClick={this.ShowDlg.bind(this, 2)} disabled={this.state.btnControlDisable[2]}>
                        <Icon type="close" /> 删除</Button>
                </div >
            );
        }
        // 代理
        else {
            strAreaStyle = "store_manage_contents store_manage_top_space";
            uiTitleArea = (
                < div className="store_contents_title" >

                </div >
            );
        }

        // 如果界面上需要显示的内容已经从数据库获得
        if (!this.state.bLoading) {
            // 主体表格相关
            const { selectedRowKeys } = this.state;
            const rowSelection = {
                selectedRowKeys,
                onChange: this.onSelectedRowKeysChange,
            };
            // 表格分页属性
            const paginationProps = {
                showSizeChanger: true,
                showQuickJumper: false,
                showTotal: () => `共 ${this.arrTableData.length} 条 备忘信息`,
                defaultPageSize: 10,
                defaultCurrent: this.state.iCurrentPage,
                pageSizeOptions: ['10', '20', '30', '40', '50'],
                total: this.arrTableData.length,
                position: 'bottom'
            };
            // 用户信息编辑弹窗
            let strTitle = "新建备忘信息";
            if (this.state.iDlgType === 1)
                strTitle = "编辑备忘信息";
            let uiModal = (
                <Modal title={strTitle} width={'50%'} visible={this.state.bShowDlg}
                    okText="确定" cancelText="取消" onOk={this.handleOk} onCancel={this.handleCancel} >
                    <div className="user_edit_info_row">
                        <div className="user_edit_info_title">账户：</div>
                        <Input className="user_edit_info_input_long" ref="account"
                            defaultValue={this.objCurrentComments.account} />
                    </div>
                    <div className="user_edit_info_row">
                        <div className="user_edit_info_title">密码：</div>
                        <Input className="user_edit_info_input_long" ref="password"
                            defaultValue={this.objCurrentComments.password} />
                    </div>
                    <div className="user_edit_info_row">
                        <div className="user_edit_info_title">备注：</div>
                        <Input className="user_edit_info_input_long" ref="comments"
                            defaultValue={this.objCurrentComments.comments} />
                    </div>
                </Modal>
            );
            if (this.state.iDlgType === 2) {
                uiModal = (
                    <Modal width={350} title={"删除备忘信息"} visible={this.state.bShowDlg} okType="danger"
                        onOk={this.handleOk} okText="确定" onCancel={this.handleCancel} cancelText="取消">
                        <div className="modal_item">
                            <div>{"账号：" + this.objCurrentComments.account + ", 备注：" + this.objCurrentComments.comments}
                                <br /><br />确定删除选中的备忘信息？
                            </div>
                        </div>
                    </Modal>
                );
            }
            let uiTable = (
                <Table style={{ marginLeft: '20px', marginRight: '20px', width: '95%', overflowX: 'auto' }}
                    rowSelection={rowSelection} pagination={paginationProps}
                    columns={this.tableHead} dataSource={this.arrTableData} bordered
                    onRow={(record) => ({ onClick: () => { this.selectRow(record); } })} />
            );

            // M站布局
            if (this.props.bMStation) {
                strAreaStyle = "store_manage_contents_m";
                if (this.props.iUserLevel >= 9) {
                    uiTitleArea = (
                        <div>
                            <div className="store_contents_title_m" >
                                <Button className="transit_price_button_m" style={strNewButtonStyle}
                                    onClick={this.ShowDlg.bind(this, 0)} disabled={this.state.btnControlDisable[0]}>
                                    <Icon type="plus" />新建</Button>
                                <Button className="transit_price_button_m" type="primary" onClick={this.ShowDlg.bind(this, 1)} disabled={this.state.btnControlDisable[1]}>
                                    <Icon type="edit" /> 编辑</Button>
                                <Button className="transit_price_button_m" type="danger" onClick={this.ShowDlg.bind(this, 2)} disabled={this.state.btnControlDisable[2]}>
                                    <Icon type="close" /> 删除</Button>
                            </div>
                        </div>
                    );
                } else {
                    uiTitleArea = (
                        <div className="store_contents_title_m" >

                        </div >
                    );
                }

                uiModal = (
                    <Modal title={strTitle} width={'90vw'} visible={this.state.bShowDlg}
                        okText="确定" cancelText="取消" onOk={this.handleOk} onCancel={this.handleCancel} >
                        <div className="item_info_row_m">
                            <div className="transit_price_title_m">账号</div>
                            <Input className="order_detail_input_m" ref="account" size="large"
                                defaultValue={this.objCurrentComments.account} />
                        </div>
                        <div className="item_info_row_m">
                            <div className="transit_price_title_m">密码</div>
                            <Input className="order_detail_input_m" ref="password"
                                defaultValue={this.objCurrentComments.password} />
                        </div>
                        <div className="item_info_row_m">
                            <div className="transit_price_title_m">备注</div>
                            <Input className="order_detail_input_m" ref="comments"
                                defaultValue={this.objCurrentComments.comments} />
                        </div>
                    </Modal>
                );
                if (this.state.iDlgType === 2) {
                    uiModal = (
                        <Modal width={350} title={"删除备忘信息"} visible={this.state.bShowDlg} okType="danger"
                            onOk={this.handleOk} okText="确定" onCancel={this.handleCancel} cancelText="取消">
                            <div className="modal_item">
                                <div>{"账号：" + this.objCurrentComments.area + ", 备注：" + this.objCurrentComments.price}
                                    <br /><br />确定删除选中的备忘信息？
                                </div>
                            </div>
                        </Modal>
                    );
                }
                uiTable = (
                    <Table style={{ width: '100vw', height: '100%', overflow: 'auto' }} bordered
                        rowSelection={rowSelection} pagination={paginationProps}
                        columns={this.tableHead} dataSource={this.arrTableData}
                        onRow={(record) => ({ onClick: () => { this.selectRow(record); } })} />
                );
            }

            return (
                <div className={strAreaStyle}>
                    {uiTitleArea}
                    {uiModal}
                    {uiTable}
                </div >
            );
        } else {
            return (
                <div className={strAreaStyle}>
                    {uiTitleArea}
                    <Spin className="manage_empty_loading" size="large" tip="加载中……" />
                </div>
            );
        }
    }
}
export default CommentsManager;
