import React, { Component } from 'react';
import jsPDF from "jspdf";
import axios from 'axios';

import Spin from 'antd/es/spin';
import Table from 'antd/es/table';
import Button from 'antd/es/button';
import Input from 'antd/es/input';
import Icon from 'antd/es/icon';
import Modal from 'antd/es/modal';
import message from 'antd/es/message';

import '../../../styles/Background/ego_manager.css';
import '../../../styles/Background/UI_Module/user_management.css';
import '../../../styles/Background/UI_Module/transit_price.css';
import '../../../styles/Background/UI_Module/customs_file.css';
import { UserActiveUpdate } from "../../../functions/DataStatistic.js"
import PDFA8AB from "./PDFA8AB.js"

const g_strMessageKey = 'updating';

// 表格
const arrTableHeader = [
    { title: '对应单号', dataIndex: 'order_no', width: 200, ellipsis: false },
    { title: '货运控制编码', dataIndex: 'cargo_control_no', width: 300, ellipsis: false },
    { title: '更新时间', dataIndex: 'update_date', width: 150, ellipsis: false }
];
const arrTableHeader_m = [
    { title: '账号', dataIndex: 'account', className: 'store_manage_table_m', width: 100, ellipsis: false },
    { title: '密码', dataIndex: 'password', className: 'store_manage_table_m', width: 100, ellipsis: false },
    { title: '备注', dataIndex: 'comments', className: 'store_manage_table_m', width: 100, ellipsis: false }
];

class LogisticCustomsFile extends Component {
    constructor(props) {
        super();
        this.state = {
            btnControlDisable: [false, true, true],  // 操作按钮控制
            selectedRowKeys: [],  // Check here to configure the default column
            iDlgType: -1,
            bShowDlg: false,
            bLoading: true,
            // bEditing: false,
        };
        // 成员变量
        this.arrTableData = [];  // 用于表格的数组
        this.tableHead = arrTableHeader;
        if (props.bMStation)
            this.tableHead = arrTableHeader_m;

        this.objA8ABEmpty = {
            store_id: -1,
            order_no: '',
            us_port_of_exit: '',
            in_transit: '',
            manifest_from: '',
            manifest_to: '',
            consignee_name_address: '',
            shipper_name_address: '',
            no_of_pkgs: '',
            description_and_marks: '',
            arrival_vessel: '',
            type_of_cargo: '',
            container_no: '',
            seal_no: '',
            bl_no: '',
            eta: '',
            foreign_point_of_lading: '',
            name_of_carrier: '',
            acquittal_no: '',
            carrier_code: '',
            cargo_control_no: '',
            precious_cargo_control_no: '',
            weight: '',
            volume: '',
            on_board_date: '',
            location_of_goods: '',
            conveyance_id: ''
        };
        this.objCurrent_a8ab = Object.assign({}, this.objA8ABEmpty);
        this.strTargetOrderNo = "";
    }

    // 选中某行的响应函数
    selectRow = (record) => {
        const selectedRowKeys = [...this.state.selectedRowKeys];
        if (selectedRowKeys.indexOf(record.key) >= 0) {
            selectedRowKeys.splice(selectedRowKeys.indexOf(record.key), 1);
        } else {
            selectedRowKeys.push(record.key);
        }
        this.SelectedRowStateSetting(selectedRowKeys);
    }
    // 选中的行发生变化的响应函数
    onSelectedRowKeysChange = (selectedRowKeys) => {
        this.SelectedRowStateSetting(selectedRowKeys);
    }
    // 处理选中的表行行
    SelectedRowStateSetting = (selectedRowKeys) => {
        let btnControlDisable = [];
        switch (selectedRowKeys.length) {
            case 0: {
                btnControlDisable = [false, true, true];
                this.objCurrent_a8ab = Object.assign({}, this.objA8ABEmpty);
                break;
            }
            case 1: {
                btnControlDisable = [false, false, false];
                for (let i = 0; i < this.arrTableData.length; i++) {
                    if (this.arrTableData[i].key === selectedRowKeys[0]) {
                        this.objCurrent_a8ab = this.arrTableData[i];
                        break;
                    }
                    if (this.arrTableData[i].children) {
                        for (let j = 0; j < this.arrTableData[i].children.length; j++) {
                            if (this.arrTableData[i].children[j].key === selectedRowKeys[0]) {
                                this.objCurrent_a8ab = this.arrTableData[i].children[j];
                                break;
                            }
                        }
                    }
                }
                break;
            }
            default: {
                btnControlDisable = [false, true, true]; break;
            }
        }
        this.setState({
            ...this.state,
            selectedRowKeys,
            btnControlDisable: btnControlDisable
        });
    }
    //弹出对话框
    ShowDlg = (iDlgType, e) => {
        // 新建
        if (iDlgType === 0) {
            this.objCurrent_a8ab = Object.assign({}, this.objA8ABEmpty);
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true,
                btnControlDisable: [true, true, true]
            });
        }
        // 查看编辑
        if (iDlgType === 1) {
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true,
                btnControlDisable: [true, true, true]
            });
        }
        // 删除
        if (iDlgType === 2) {
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true,
                btnControlDisable: [true, true, true]
            });
        }
    }
    // 弹窗确认按钮响应
    handleOk = (iSaveAs, e) => {
        this.objCurrent_a8ab.store_id = this.props.objStoreInfo.id;
        if (this.state.iDlgType !== 2) {
            this.objCurrent_a8ab.order_no = this.refs.order_no.state.value;
            if (this.refs.order_no.state.value === "" || this.refs.order_no.state.value === null) {
                message.warning("对应单号 不能为空！");
                return;
            }
            this.objCurrent_a8ab.cargo_control_no = this.refs.cargo_control_no.state.value;
            if (this.objCurrent_a8ab.cargo_control_no === "" || this.objCurrent_a8ab.cargo_control_no === null) {
                message.warning("货运编码 不能为空！");
                return;
            }
        }
        // 新建确认
        if (this.state.iDlgType === 0) {
            if (this.objCurrent_a8ab.key)
                delete this.objCurrent_a8ab.key;
            if (this.objCurrent_a8ab.children)
                delete this.objCurrent_a8ab.children;
            message.loading({ content: '正在更新海关文件……', key: g_strMessageKey });
            axios.post('/SaveCustomsFile', { type: 1, objCurrent_a8ab: this.objCurrent_a8ab, save_as: parseInt(iSaveAs, 10) })
                .then(({ data }) => {
                    console.log(data);
                    this.objCurrent_a8ab.id = data[0].id;
                    message.success({ content: "海关文件已保存！", key: g_strMessageKey, duration: 2 });
                    // DataStatistic
                    UserActiveUpdate(4, { active: "商家后台: 海关文件管理操作", action: "新建" });
                }).catch(function (error) { console.log(error); });
        }
        // 编辑确认
        if (this.state.iDlgType === 1) {
            delete this.objCurrent_a8ab.key;
            if (this.objCurrent_a8ab.children)
                delete this.objCurrent_a8ab.children;
            message.loading({ content: '正在更新海关文件……', key: g_strMessageKey });
            axios.post('/SaveCustomsFile', { type: 1, objCurrent_a8ab: this.objCurrent_a8ab, save_as: parseInt(iSaveAs, 10) })
                .then(({ data }) => {
                    message.success({ content: "海关文件已保存！", key: g_strMessageKey, duration: 2 });
                    // DataStatistic
                    UserActiveUpdate(4, { active: "商家后台: 海关文件管理操作", action: "编辑" });
                }).catch(function (error) { console.log(error); });
        }
        // 删除确认
        if (this.state.iDlgType === 2) {
            message.loading({ content: '海关文件删除中……', key: g_strMessageKey });
            axios.post('/DeleteCustomsFile', { id: this.objCurrent_a8ab.id }).then(
                ({ data }) => {
                    // DataStatistic
                    UserActiveUpdate(4, { active: "管理后台: 海关文件管理操作", action: "删除" });
                    this.GetCustomsFileData();
                }).catch(function (error) { console.log(error); });
        }
    };
    // 弹窗取消按钮响应
    handleCancel = e => {
        this.setState({
            bShowDlg: false,
            btnControlDisable: [false, true, true]
        });
        if (this.strTargetOrderNo !== "")
            this.props.CustomFileToAutoOrder(this.strTargetOrderNo, this.props.iOrderType);

        this.strTargetOrderNo = "";
        this.GetCustomsFileData();
    };
    // 从数据库获取当前的数据结构
    GetCustomsFileData = () => {
        message.loading({ content: '数据加载中……', key: g_strMessageKey });
        axios('/GetCustomsFiles', { params: { store_id: this.props.objStoreInfo.id } })
            .then(({ data }) => {
                this.OrganizeCustomFiles(data);
            }).catch(function (error) {
                console.log(error);
                this.setState({
                    ...this.state,
                    bLoading: false,
                    bShowDlg: false,
                    btnControlDisable: [false, true, true],
                    selectedRowKeys: []
                });
            });
    }
    // 关键词处理
    KeywordsProcess = (strKeywords) => {
        let newKeywords = "";
        for (let i = 0; i < strKeywords.length; i++) {
            if (strKeywords[i] !== " ") {
                if (i === strKeywords.length - 1)
                    newKeywords += strKeywords[i];
                else
                    newKeywords += strKeywords[i] + "%";
            }
        }
        return newKeywords;
    }
    // 搜索单号
    CustomFileSearch = () => {
        let strKeywords = this.refs.order_search.state.value;
        if (strKeywords === undefined)
            strKeywords = "";
        if (strKeywords.trim() === "") {
            this.GetCustomsFileData();
        } else {
            this.setState({
                ...this.state,
                bLoading: true,
                selectedRowKeys: [],
                btnControlDisable: [false, true, true]
            });
            axios('/SearchCustomFile', { params: { search: this.KeywordsProcess(strKeywords), store_id: this.props.objStoreInfo.id } })
                .then(({ data }) => {
                    this.OrganizeCustomFiles(data);
                }).catch(function (error) { console.log(error); });
        }
    }
    // 整理Invoice File数据
    OrganizeCustomFiles = (data) => {
        let iDlgType = -1;
        let bDlgShow = false;
        if (this.props.strTargetOrderNo !== "") {
            this.strTargetOrderNo = this.props.strTargetOrderNo;
            iDlgType = 0;
            this.objCurrent_a8ab = Object.assign({}, this.objA8ABEmpty);
            this.objCurrent_a8ab.order_no = this.strTargetOrderNo;
            bDlgShow = true;
            this.props.TargetOrderNoReset();
        }
        this.arrTableData = [];
        let iTableIndex = 0;
        let arrInTableOrders = [];
        if (data.length > 0) {
            for (let i = 0; i < data.length; i++) {
                if (data[i].order_no.indexOf('-') >= 0) {
                    continue;
                }
                arrInTableOrders.push(i);
                this.arrTableData.push(data[i]);
                this.arrTableData[iTableIndex].key = iTableIndex * 100;
                this.arrTableData[iTableIndex].update_date = this.arrTableData[iTableIndex].update_date.split('T')[0];
                if (this.arrTableData[iTableIndex].order_no === this.strTargetOrderNo && bDlgShow) {
                    iDlgType = 1;
                    this.objCurrent_a8ab = this.arrTableData[iTableIndex];
                }
                // 找寻子文件
                this.arrTableData[iTableIndex].children = [];
                for (let j = 0; j < data.length; j++) {
                    if (j !== i) {
                        if (data[j].order_no.indexOf('-') >= 0 && data[j].order_no.indexOf(data[i].order_no) >= 0) {
                            this.arrTableData[iTableIndex].children.push(data[j]);
                            arrInTableOrders.push(j);
                        }
                    }
                }
                if (this.arrTableData[iTableIndex].children.length <= 0)
                    delete this.arrTableData[iTableIndex].children;
                else {
                    for (let i = 0; i < this.arrTableData[iTableIndex].children.length; i++) {
                        this.arrTableData[iTableIndex].children[i].key = iTableIndex * 100 + i + 1;
                        this.arrTableData[iTableIndex].children[i].update_date = this.arrTableData[iTableIndex].update_date.split('T')[0];
                        if (this.arrTableData[iTableIndex].children[i].order_no === this.strTargetOrderNo && bDlgShow) {
                            iDlgType = 1;
                            this.objCurrent_a8ab = this.arrTableData[iTableIndex].children[i];
                        }
                    }
                }
                iTableIndex++;
            }
            for (let i = 0; i < data.length; i++) {
                if (arrInTableOrders.indexOf(i) < 0) {
                    this.arrTableData.push(data[i]);
                    this.arrTableData[iTableIndex].key = iTableIndex * 100;
                    this.arrTableData[iTableIndex].update_date = this.arrTableData[iTableIndex].update_date.split('T')[0];
                    if (this.arrTableData[iTableIndex].order_no === this.strTargetOrderNo && bDlgShow) {
                        iDlgType = 1;
                        this.objCurrent_a8ab = this.arrTableData[iTableIndex];
                    }
                    iTableIndex++;
                }
            }
        }
        message.success({ content: "数据加载完成！", key: g_strMessageKey, duration: 2 });
        this.setState({
            ...this.state,
            bLoading: false,
            iDlgType: iDlgType,
            bShowDlg: bDlgShow,
            btnControlDisable: [false, true, true],
            selectedRowKeys: []
        });
    }
    // 导出PDF文件
    OutpuPdfFile = () => {
        let input = document.getElementById("a8a-b");
        let pdf = new jsPDF('p', 'mm', [1640.84, 2123.44]);
        // let pdf = new jsPDF('p', 'mm', 'letter');
        pdf.html(input, {
            callback: function (pdf) {
                pdf.save("a8a-b.pdf");
            },
        })
    }

    /* React自带函数 */
    // 卸载异步操作设置状态
    componentWillUnmount() {
        clearTimeout(this.timeouter)
        this.setState = (state, callback) => {
            return;
        }
    }
    // 渲染完毕……
    componentDidMount() {
        this.GetCustomsFileData();
        console.log("props, order type = ", this.props.iOrderTYpe);
    }
    // 渲染函数
    render() {

        /* pdf显示区域 */
        // a0a-b
        let uiPdfA8AB = null;
        if (this.state.bShowDlg && this.state.iDlgType !== 2) {
            uiPdfA8AB = (<PDFA8AB objCurrent_a8ab={this.objCurrent_a8ab} objStoreInfo={this.props.objStoreInfo} />);
        }

        /* 用户管理页面控制栏 */
        let strNewButtonStyle = { backgroundColor: 'lightseagreen', color: 'white' };
        let uiTitleArea = null;
        let uiOrderNoTitle = null;
        let uiCargoNoTitle = null;
        let uiOrderNoInput = null;
        let uiCargoNoInput = null;
        let uiBackButton = null;
        let uiSaveButton = null;
        let uiSaveAsButton = null;
        let uiExportPdf = null;
        let uiSearch = (
            <div className="manage_stretch_right" style={{ marginLeft: '50px' }}>
                <Input placeholder="搜索单号" ref="order_search" defaultValue={""} />
                <Button className="manage_contents_title_margin" type="primary" onClick={this.CustomFileSearch}>
                    <Icon type="search" />
                </Button>
            </div>
        );
        if (this.state.bShowDlg && this.state.iDlgType !== 2) {
            uiOrderNoTitle = <div className="order_no_input_title">对应单号：</div>;
            uiOrderNoInput = <Input ref="order_no" defaultValue={this.objCurrent_a8ab.order_no} style={{ width: '150px', fontSize: '4mm', marginRight: '10px' }} />;
            uiCargoNoTitle = <div className="order_no_input_title" style={{ marginLeft: '10px' }}>货运编码：</div>;
            uiCargoNoInput = <Input ref="cargo_control_no" defaultValue={this.objCurrent_a8ab.cargo_control_no} style={{ width: '150px', fontSize: '4mm', marginRight: '10px' }} />;
            strNewButtonStyle = { backgroundColor: 'white', color: 'grey' };
            uiSaveButton = (
                <Button className="manage_contents_title_margin" type="primary" onClick={this.handleOk.bind(this, 0)}>
                    <Icon type="save" />保存</Button>
            );
            uiSaveAsButton = (
                <Button className="manage_contents_title_margin" type="primary" onClick={this.handleOk.bind(this, 1)}>
                    <Icon type="save" />另存为</Button>
            );
            uiBackButton = (
                <Button className="manage_contents_title_margin" type="primary" onClick={this.handleCancel}><Icon type="rollback" />返回</Button>
            );
            uiExportPdf = (
                <Button className="manage_contents_title_margin" type="primary" onClick={this.OutpuPdfFile}><Icon type="file-pdf" />导出</Button>
            );
            uiSearch = null;
        }
        uiTitleArea = (
            <div className="logistics_page_title" >
                <Button className="manage_contents_title_margin" style={strNewButtonStyle}
                    onClick={this.ShowDlg.bind(this, 0)} disabled={this.state.btnControlDisable[0]}>
                    <Icon type="plus" />新建</Button>
                <Button className="manage_contents_title_margin" type="primary" onClick={this.ShowDlg.bind(this, 1)} disabled={this.state.btnControlDisable[1]}>
                    <Icon type="edit" />编辑</Button>
                <Button className="manage_contents_title_margin" type="danger" onClick={this.ShowDlg.bind(this, 2)} disabled={this.state.btnControlDisable[2]}>
                    <Icon type="close" /> 删除</Button>
                {uiSearch}
                {uiOrderNoTitle}
                {uiOrderNoInput}
                {uiCargoNoTitle}
                {uiCargoNoInput}
                {uiSaveButton}
                {uiSaveAsButton}
                {uiExportPdf}
                {uiBackButton}
            </div >
        );
        // }
        // // 代理
        // else {
        //     strAreaStyle = "store_manage_contents store_manage_top_space";
        //     uiTitleArea = (
        //         < div className="store_contents_title" >

        //         </div >
        //     );
        // }

        // 如果界面上需要显示的内容已经从数据库获得
        if (!this.state.bLoading) {
            // 主体表格相关
            const { selectedRowKeys } = this.state;
            const rowSelection = {
                selectedRowKeys,
                onChange: this.onSelectedRowKeysChange,
                columnWidth: 30
            };
            // 表格分页属性
            const paginationProps = {
                showSizeChanger: true,
                showQuickJumper: false,
                showTotal: () => `共 ${this.arrTableData.length} 条 海关文件记录`,
                defaultPageSize: 10,
                defaultCurrent: this.state.iCurrentPage,
                pageSizeOptions: ['10', '20', '30', '40', '50'],
                total: this.arrTableData.length,
                position: 'bottom'
            };
            // 用户信息编辑弹窗
            let uiModal = null
            if (this.state.iDlgType === 2) {
                uiModal = (
                    <Modal width={350} title={"删除海关报表"} visible={this.state.bShowDlg} okType="danger"
                        onOk={this.handleOk} okText="确定" onCancel={this.handleCancel} cancelText="取消">
                        <div className="modal_item">
                            确定删除选中的海关文件信息？
                        </div>
                    </Modal>
                );
            }
            let uiTable = (
                <Table style={{ margin: '20px', width: '75%', overflowX: 'auto' }}
                    rowSelection={rowSelection} pagination={paginationProps}
                    columns={this.tableHead} dataSource={this.arrTableData} bordered
                    onRow={(record) => ({ onClick: () => { this.selectRow(record); } })} />
            );
            if (this.state.bShowDlg && this.state.iDlgType !== 2)
                uiTable = null;

            // M站布局
            if (this.props.bMStation) {

            }

            return (
                <div className="logistics_page_contents" style={{ paddingTop: ' 50px' }}>
                    {uiTitleArea}
                    {uiPdfA8AB}
                    {uiTable}
                    {uiModal}
                </div >
            );
        } else {
            return (
                <div className="logistics_page_contents">
                    {uiTitleArea}
                    <Spin className="manage_empty_loading" size="large" tip="加载中……" />
                </div>
            );
        }
    }
}
export default LogisticCustomsFile;
