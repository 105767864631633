import React, { Component } from "react";
import axios from 'axios';
import cookie from 'react-cookies';
import "antd/dist/antd.css";
import "../../styles/Background/db_manager.css";

import Empty from 'antd/es/empty';
import Spin from 'antd/es/spin';
import Menu from 'antd/es/menu';
import Icon from 'antd/es/icon';
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import Form from 'antd/es/form';
import message from 'antd/es/message';

import JetonOrder from "./ManagerPage/JetonOrder.js";

const g_strMessageKey = 'updating';

function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class JetonManager extends Component {
    // 构造函数
    constructor(props) {
        super();
        this.state = {
            bLoading: true,  // 加载状态
            bLogin: false,  // 登录状态
            iCurrentSideBarIndex: 0,  // 当前显示的中心区域内容索引
        };
        this.arrSideMenuButton = [
            { id: 0, name: "运单", icon: "file" }
        ];
        this.strCurrentUserName = '';
        this.strCurrentUserCode = '';

    }
    // 切换侧边栏菜单
    SwitchSideMenu = (iMenuID) => {
        this.setState({
            ...this.state,
            bLoading: false,
            iCurrentSideBarIndex: iMenuID
        });
    }
    // 登录表单提交响应
    LoginSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                message.loading({ content: '登录中……', key: g_strMessageKey });
                this.setState({
                    ...this.state,
                    bLoading: true
                });
                this.TryLogin(values.username, values.password)
            }
        });
    };
    // 尝试登录
    TryLogin = (name, password) => {
        let objUserInfo = { name: name, password: password };
        axios('/MouthingBackEndLogin', { params: objUserInfo })
            .then(({ data }) => {
                if (data.status === -1) {
                    message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
                    this.setState({
                        ...this.state,
                        bLoading: false
                    });
                }
                else {
                    message.success({ content: data.message, key: g_strMessageKey, duration: 2 });
                    this.strCurrentUserName = name;
                    cookie.save('ego_user_code', data.code, { path: '/' });
                    cookie.save('ego_login_status', 1, { path: '/' });
                    this.setState({
                        ...this.state,
                        bLoading: false,
                        bLogin: true
                    });
                    this.SwitchSideMenu(this.state.iCurrentSideBarIndex);
                }
            }).catch(function (error) { console.log(error); });
    }
    // 退出登录
    LogoutClick = () => {
        cookie.remove('ego_user_code', { path: '/' });
        cookie.save('ego_login_status', -1, { path: '/' });
        this.setState({
            ...this.state,
            bLogin: false
        });
    }
    // 用户识别码检查
    UserCodeCheck = () => {
        axios('/UserCodeExistCheck', { params: { code: this.strCurrentUserCode, level: 9 } })
            .then(({ data }) => {
                if (data.status === -1) {
                    message.warning(data.message);
                    this.setState({
                        ...this.state,
                        bLoading: false
                    });
                } else {
                    message.success(data.message);
                    this.strCurrentUserName = data.name;
                    this.setState({
                        ...this.state,
                        bLoading: false,
                        bLogin: true
                    });
                }
            }).catch(function (error) { console.log(error); });
    }

    // 渲染完毕……
    componentDidMount() {
        let strUserCode = cookie.load('ego_user_code');
        let iUserLoginStatus = cookie.load('ego_login_status');
        if ((iUserLoginStatus === 1 || iUserLoginStatus === '1') && strUserCode !== undefined) {
            this.strCurrentUserCode = strUserCode;
            this.UserCodeCheck();
        } else {
            this.setState({ ...this.state, bLoading: false, bLogin: false });
        }
        document.title = "中通物流代理";
    }

    render() {

        const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;
        // Only show error after a field is touched.
        const usernameError = isFieldTouched('username') && getFieldError('username');
        const passwordError = isFieldTouched('password') && getFieldError('password');
        // 登录表单
        const uiLogInForm = (
            <Form className="manage_login_form" layout="inline" onSubmit={this.LoginSubmit}>
                <Form.Item validateStatus={usernameError ? 'error' : ''} help={usernameError || ''}>
                    {getFieldDecorator('username')(
                        <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="用户名" />,
                    )}
                </Form.Item>
                <Form.Item validateStatus={passwordError ? 'error' : ''} help={passwordError || ''}>
                    {getFieldDecorator('password')(
                        <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="密码" />,
                    )}
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" disabled={hasErrors(getFieldsError())}>
                        登 陆
                    </Button>
                </Form.Item>
            </Form>
        );
        // 欢迎回来
        let strTitle = '欢迎回来,  ' + this.strCurrentUserName;
        const uiWelcomeBack = (
            <div className="manage_login_form">
                <div className="welcome_back_title">{strTitle}</div>
                <Button className="logout_btn" type="primary" htmlType="submit"
                    onClick={this.LogoutClick}>退出登录</Button>
            </div>
        );
        // 顶部菜单栏
        let uiNavBar = (
            <div className="manage_top_bar">
                <div className="platform_logo" style={{ marginLeft: '0px' }} >Jeton</div>
                {uiWelcomeBack}
            </div>
        );
        // 侧边菜单栏
        let uiSideBar = (
            <Menu className="manage_side_bar" defaultSelectedKeys={[this.state.iCurrentSideBarIndex.toString()]}
                selectedKeys={[this.state.iCurrentSideBarIndex.toString()]}
                mode="inline" theme="dark" inlineCollapsed={this.state.collapsed}>
                {this.arrSideMenuButton.map((item, index) => {
                    return (
                        <Menu.Item key={index} onClick={this.SwitchSideMenu.bind(this, index)}>
                            <Icon type={item.icon} />{item.name}
                        </Menu.Item>
                    );
                })}
            </Menu>
        );
        if (!this.state.bLogin) {
            uiNavBar = (
                <div className="manage_top_bar">
                    <div className="platform_logo" style={{ marginLeft: '0px' }} >Jeton</div>
                    <div className="manage_top_bar_menu" />
                    {uiLogInForm}
                </div>
            );
            uiSideBar = <div />;
        }

        let uiCentralArea;
        // 是否还在加载中……
        if (this.state.bLoading) {
            uiCentralArea = (<Spin className="manage_empty_loading" size="large" tip="加载中……" />);
        } else {
            if (this.state.bLogin) {
                let uiUserManager = (this.state.iCurrentSideBarIndex === this.arrSideMenuButton[0].id && <JetonOrder />);
                uiCentralArea = (
                    <div>
                        {uiUserManager}
                    </div>
                );
            } else {
                uiCentralArea = (<Empty className="manage_empty_loading" description={<span>未登录，无法使用</span>} />);
            }
        }

        return (
            <div className="manage_page">
                {/* 顶部菜单栏 */}
                {uiNavBar}
                {/* 侧边菜单栏 */}
                {uiSideBar}
                {/* 中心区域内容 */}
                {uiCentralArea}
            </div >
        );
    }
}
export default Form.create()(JetonManager);
