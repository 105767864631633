import React, { Component } from "react";
import axios from 'axios';
import "../../../styles/Background/UI_Module/store_item_management.css";
// import '../../../styles/Background/UI_Module/store_management.css';
import '../../../styles/Background/store_manager.css';
import '../../../styles/Background/ego_manager.css';
import "../../../styles/Background/UI_Module/store_item_management.css";

import Table from 'antd/es/table';
import Icon from 'antd/es/icon';
import Button from 'antd/es/button';
import Modal from 'antd/es/modal';
import message from 'antd/es/message';

// import { Table, Icon, Button, Modal, message } from 'antd';
import { UserActiveUpdate } from "../../../functions/DataStatistic.js"

const g_strMessageKey = 'updating';

global.arrOrdersData = [];

const arrTableHeader = [
    { title: '订单号', dataIndex: 'oid' },
    { title: 'stripe pid', dataIndex: 'pid' },
    { title: '状态', dataIndex: 'status' },
    { title: '商家', dataIndex: 'store_name' },
    { title: '用户码', dataIndex: 'user_code' },
    { title: '支付金额', dataIndex: 'total_price' },
    { title: '商品数', dataIndex: 'total_num' },
    { title: '下单日期', dataIndex: 'client_order_time' },
];

class OrderManagement extends Component {
    constructor(props) {
        super();
        this.state = {
            btnControlDisable: [true, true],  // 操作按钮控制
            selectedRowKeys: [],  // 选中的行
            bDlgShow: false,  // 是否显示弹窗
            iDlgType: 0,  // 弹窗类别
            dlgInfo: { iOrderStatus: 0 },
            iCurrentPage: 1
        };
        this.arrOrderStatus = ["未支付", "已支付", "有争议", "已关闭", "已完成", "已退款"];
        this.arrTableData = [];
        this.arrOrderData = [];
        this.objCurrentOrder = {};
        this.strDeleteOrderID = "";
    }

    // 选中某行的响应函数
    selectRow = (record) => {
        const selectedRowKeys = [...this.state.selectedRowKeys];
        if (selectedRowKeys.indexOf(record.key) >= 0) {
            selectedRowKeys.splice(selectedRowKeys.indexOf(record.key), 1);
        } else {
            selectedRowKeys.push(record.key);
        }
        this.SelectedRowStateSetting(selectedRowKeys);
    }
    // 选中的行发生变化的响应函数
    onSelectedRowKeysChange = (selectedRowKeys) => {
        this.SelectedRowStateSetting(selectedRowKeys);
    }
    // 处理选中的表行行
    SelectedRowStateSetting = (selectedRowKeys) => {
        let btnControlDisable = [];
        if (selectedRowKeys.length === 1) {
            btnControlDisable = [false, false];
            this.objCurrentOrder = this.arrTableData[selectedRowKeys];
        } else {
            btnControlDisable = [true, true];
            this.objCurrentOrder = {};
        }
        this.setState({
            ...this.state,
            selectedRowKeys,
            btnControlDisable: btnControlDisable
        });
    }
    //显示弹窗前预置编辑的内容
    PresetEditDlg(iSelectedRow) {

    }
    // 获取当前选中行的订单状态
    GetOrderStatus = (iSelectedRow) => {
        let iStatusIndex = 0;
        for (let i = 0; i < this.arrOrderStatus.length; i++) {
            if (this.arrTableData[iSelectedRow].status === this.arrOrderStatus[i]) {
                iStatusIndex = i;
                break;
            }
        }
        return iStatusIndex;
    }
    //弹出对话框
    ShowDlg = (iDlgType) => {
        switch (iDlgType) {
            // 查看订单详情
            case 1: {
                let iSelectedRow = this.state.selectedRowKeys[0];
                this.PresetEditDlg(iSelectedRow);
                this.setState({
                    ...this.state,
                    bDlgShow: true, iDlgType: iDlgType,
                    dlgInfo: {
                        iOrderStatus: this.GetOrderStatus(iSelectedRow)
                    }
                });
                break;
            }
            // 删除订单
            case 2: {
                this.strDeleteOrderID = this.arrTableData[this.state.selectedRowKeys[0]].oid;
                this.setState({
                    ...this.state,
                    iDlgType: iDlgType,
                    bDlgShow: true,
                });
                break;
            }
            default: break;
        }
    }
    // 获取弹窗中的更新数据
    GetNewInputData() {
        let objInputInfo = {};
        // 从表单中获取
        objInputInfo.status = this.state.dlgInfo.iOrderStatus;
        return objInputInfo;
    }
    // 点击了弹窗里的优惠券状态过滤器
    DlgOrderStatusClicked = (iOrderStatus) => {
        this.setState({
            ...this.state,
            dlgInfo: {
                iOrderStatus: iOrderStatus
            }
        });
    }
    // 确认按钮响应
    ConfirmButtonClick = () => {
        switch (this.state.iDlgType) {
            case 1: {
                let objEditOrder = this.GetNewInputData();
                objEditOrder.id = this.arrTableData[this.state.selectedRowKeys[0]].id;
                message.loading({ content: '正在更新订单信息……', key: g_strMessageKey });
                axios.post('/EditOrder', { objEditOrder: objEditOrder })
                    .then(
                        ({ data }) => {
                            // DataStatistic
                            UserActiveUpdate(6, { active: "管理后台: 订单管理操作", action: "编辑" });
                            message.success({ content: '更新完成！', key: g_strMessageKey, duration: 2 });
                            this.RefreshOrderTable(data);
                        }
                    );
                break;
            }
            case 2: {
                message.loading({ content: '删除订单中……', key: g_strMessageKey });
                axios.post('/DeleteOrder', {
                    pid: this.arrTableData[this.state.selectedRowKeys[0]].pid,
                    id: this.arrTableData[this.state.selectedRowKeys[0]].id,
                    user_code: this.arrTableData[this.state.selectedRowKeys[0]].user_code,
                }).then(({ data }) => {
                    console.log(data);
                    // DataStatistic
                    UserActiveUpdate(6, { active: "管理后台: 订单管理操作", action: "删除" });
                    message.success({ content: '删除完成！', key: g_strMessageKey, duration: 2 });
                    this.arrTableData.splice(this.state.selectedRowKeys[0], 1);
                    console.log(this.arrTableData);
                    this.RefreshOrderTable(this.arrTableData);
                });
                break;
            }
            default: break;
        }
    }
    // 取消按钮响应
    CancelButtonClick = () => {
        this.setState({
            ...this.state,
            iDlgType: 0,
            bDlgShow: false
        });
    }
    // 刷新表格中的数据
    RefreshOrderTable(data) {
        if (this.state.iDlgType !== 2) {
            this.arrOrderData = data;
            this.arrTableData = [];
            for (let i = 0; i < data.length; i++) {
                let strDateTime = data[i].client_order_time.split('T')[0] + " " + data[i].client_order_time.split('T')[1].split('.')[0];
                let objOrderData = {
                    key: i, id: data[i].id, pid: data[i].pid, oid: data[i].oid,
                    status: this.arrOrderStatus[data[i].status],
                    // total_price: "$ CAD " + (data[i].total_price / 100).toFixed(2),
                    total_price: "$ CAD " + data[i].total_price.toFixed(2),
                    total_num: JSON.parse(data[i].items).total_num,
                    store_name: data[i].store_name,
                    user_code: data[i].user_code,
                    client_order_time: strDateTime
                }
                console.log("RefreshOrderTable : " + i + ", " + objOrderData.total_price);
                this.arrTableData.push(objOrderData);
            }
        }

        global.arrOrdersData = this.arrTableData;

        if (this.state.iDlgType === 2) {
            this.setState({
                ...this.state,
                btnControlDisable: [true, true],
                selectedRowKeys: [],
                iDlgType: 2,
                bDlgShow: false
            });
        } else {
            this.setState({
                ...this.state,
                btnControlDisable: [true, true],
                selectedRowKeys: [],
                iDlgType: 0,
                bDlgShow: false
            });
        }
    }
    // 从数据库获取当前的数据结构
    GetOrdersData() {
        message.loading({ content: '数据加载中……', key: g_strMessageKey });
        axios('/GetAllStoreOrders')
            .then(({ data }) => {
                console.log(data);
                message.success({ content: "数据加载完成！", key: g_strMessageKey, duration: 2 });
                this.RefreshOrderTable(data);
            }).catch(function (error) { console.log(error); });
    }
    /* React自带函数 */
    // 卸载异步操作设置状态
    componentWillUnmount() {
        clearTimeout(this.timeouter)
        this.setState = (state, callback) => {
            return;
        }
    }
    // 渲染完毕……
    componentDidMount() {
        this.GetOrdersData();
    }

    render() {

        // 主体表格相关
        const { selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectedRowKeysChange,
        };

        // 创建对话框
        let objOrder = {
            key: 0, pid: "", oid: "", status: this.arrOrderStatus[0], total_price: 0, total_num: 0, oge_price: 0,
            client_order_time: "", contact: "", phone: "", comments: ""
        }
        let strDlgTitle = "";
        if (this.state.iDlgType === 1) {
            objOrder = this.arrOrderData[this.state.selectedRowKeys[0]];
            if (objOrder.contact === null || objOrder.contact === "")
                objOrder.contact = "无";
            if (objOrder.phone === null || objOrder.phone === "")
                objOrder.phone = "无";
            if (objOrder.comments === null || objOrder.comments === "")
                objOrder.comments = "无";
            if (objOrder.client_order_time !== null && objOrder.client_order_time !== "" && objOrder.client_order_time.indexOf('T') !== -1)
                objOrder.client_order_time = objOrder.client_order_time.split('T')[0] + " " + objOrder.client_order_time.split('T')[1].split('.')[0];
            strDlgTitle = "订单详情";
        }
        // let uiOrderStatusMenu = (
        //     <Menu>
        //         {this.arrOrderStatus.map((item, index) => {
        //             return (
        //                 <Menu.Item key={index} onClick={this.DlgOrderStatusClicked.bind(this, index)}>
        //                     {item}
        //                 </Menu.Item>
        //             );
        //         })}
        //     </Menu>
        // );
        let uiDlg = (
            <Modal title={strDlgTitle} visible={this.state.bDlgShow} footer={null} closable={true}
                maskClosable={true} onCancel={this.CancelButtonClick}
            // okText="确定" cancelText="取消" onOk={this.CancelButtonClick} 
            >
                <div className="item_info_row"> {"订单编号：" + objOrder.oid} </div>
                <div className="item_info_row"> {"Stripe单号：" + objOrder.pid} </div>
                <div className="item_info_row"> {"下单时间：" + objOrder.client_order_time} </div>
                <div className="item_info_row"> {"支付总额：$ CAD " + (parseFloat(objOrder.total_price) / 100).toFixed(2)} </div>
                <div className="item_info_row"> {"联系人名：" + objOrder.contact} </div>
                <div className="item_info_row"> {"联系电话：" + objOrder.phone} </div>
                <div className="item_info_row"> {"备注信息：" + objOrder.comments} </div>
            </Modal>
        );
        if (this.state.iDlgType === 2) {
            strDlgTitle = "删除订单";
            uiDlg = (
                <Modal title={strDlgTitle} visible={this.state.bDlgShow} okText="确定" cancelText="取消"
                    onOk={this.ConfirmButtonClick} onCancel={this.CancelButtonClick} >
                    <div>订单删除后将无法找回，请确认：</div>
                    <div>{"是否删除订单：" + this.strDeleteOrderID}</div>
                </Modal>
            );
        }
        // 表格分页属性
        const paginationProps = {
            showSizeChanger: true,
            showQuickJumper: false,
            showTotal: () => `共 ${this.arrTableData.length} 笔订单`,
            defaultPageSize: 10,
            defaultCurrent: this.state.iCurrentPage,
            pageSizeOptions: ['10', '20', '30', '40', '50'],
            total: this.arrTableData.length,
            position: 'bottom'
        };

        return (
            <div className="manage_contents">
                {/* 订单管理页面控制栏 */}
                <div className="manage_contents_title">
                    <div className="manage_title_button_right_side">
                        <Button className="manage_contents_title_margin" type="primary" onClick={this.ShowDlg.bind(this, 1)} disabled={this.state.btnControlDisable[0]}>
                            <Icon type="edit" />查看详情</Button>
                        <Button className="manage_contents_title_margin" type="danger" onClick={this.ShowDlg.bind(this, 2)} disabled={this.state.btnControlDisable[1]}>
                            <Icon type="close" /> 删除</Button>
                    </div>
                </div>
                {/* 表格主体 */}
                <Table style={{ marginLeft: '20px', marginRight: '20px' }} pagination={paginationProps}
                    rowSelection={rowSelection} columns={arrTableHeader}
                    dataSource={this.arrTableData}
                    onRow={(record) => ({ onClick: () => { this.selectRow(record); } })} />
                {/* 弹窗 */}
                {uiDlg}
            </div>
        );
    }
}
export default OrderManagement;
