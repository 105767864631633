import React, { Component } from 'react';
import axios from 'axios';

import Spin from 'antd/es/spin';
import Table from 'antd/es/table';
import Button from 'antd/es/button';
import Input from 'antd/es/input';
import Icon from 'antd/es/icon';
import Modal from 'antd/es/modal';
import Dropdown from 'antd/es/dropdown';
import Menu from 'antd/es/menu';
import message from 'antd/es/message';

import '../../../styles/Background/ego_manager.css';
import '../../../styles/Background/storage_manager.css';
import '../../../styles/Background/UI_Module/user_management.css';

const g_strMessageKey = 'updating';
global.arrClientData = [];

// 表格
const arrTableHeader = [
    { title: '客户', dataIndex: 'name', width: 100, ellipsis: true },
    { title: '类型', dataIndex: 'type', width: 75, ellipsis: true },
    { title: 'VIP等级', dataIndex: 'storage_level_str', width: 75, ellipsis: true },
    { title: '代理', dataIndex: 'invite_code', width: 100, ellipsis: true },
    { title: '状态', dataIndex: 'status_str', width: 100, ellipsis: true }
];

class StorageClientInfo extends Component {
    constructor(props) {
        super();
        this.state = {
            btnControlDisable: [false, true, true],  // 操作按钮控制
            selectedRowKeys: [],
            iDlgType: -1,
            bShowDlg: false,
            bLoading: true,
            iStorageLevel: 0,
            iClientStatus: 1
        };
        this.arrOgeTableData = [];
        this.arrTableData = [];  // 用于表格的数组
        this.objCurrentClient = {};
        this.arrStorageLevel = ['vip', 'vvip', 'svip'];
        this.arrClientStatus = ['未启用', '正常使用', '已停用'];
    }

    // 选中某行的响应函数
    selectRow = (record) => {
        const selectedRowKeys = [...this.state.selectedRowKeys];
        if (selectedRowKeys.indexOf(record.key) >= 0) {
            selectedRowKeys.splice(selectedRowKeys.indexOf(record.key), 1);
        } else {
            selectedRowKeys.push(record.key);
        }
        this.SelectedRowStateSetting(selectedRowKeys);
    }
    // 选中的行发生变化的响应函数
    onSelectedRowKeysChange = (selectedRowKeys) => {
        this.SelectedRowStateSetting(selectedRowKeys);
    }
    // 处理选中的表行
    SelectedRowStateSetting = (selectedRowKeys) => {
        let btnControlDisable = [];
        switch (selectedRowKeys.length) {
            case 0: {
                btnControlDisable = [false, true, true];
                this.CurrentClientInit();
                break;
            }
            case 1: {
                btnControlDisable = [false, false, false];
                for (let i = 0; i < this.arrTableData.length; i++) {
                    if (this.arrTableData[i].key === selectedRowKeys[0]) {
                        this.objCurrentClient = this.arrTableData[i];
                        break;
                    }
                }
                break;
            }
            default: {
                btnControlDisable = [false, true, true];
                break;
            }
        }
        this.setState({
            ...this.state,
            selectedRowKeys,
            btnControlDisable: btnControlDisable
            // btnCopyPasteDisable: btnCopyPasteDisable
        });
    }
    // 关键词处理
    KeywordsProcess = (strKeywords) => {
        let newKeywords = "";
        for (let i = 0; i < strKeywords.length; i++) {
            if (strKeywords[i] !== " ") {
                if (i === strKeywords.length - 1)
                    newKeywords += strKeywords[i];
                else
                    newKeywords += strKeywords[i] + "%";
            }
        }
        return newKeywords;
    }
    // 搜索Client名
    ClientNameSearch = () => {
        let strKeywords = this.refs.user_name_search.state.value;
        if (strKeywords === undefined)
            strKeywords = "";
        if (strKeywords.trim() === "") {
            this.GetStorageClient();
        } else {
            this.setState({
                ...this.state,
                bLoading: true,
                selectedRowKeys: [],
                btnControlDisable: [false, true, true]
            });
            axios('/SearchStorageClients', { params: { name: this.KeywordsProcess(strKeywords) } })
                .then(({ data }) => {
                    this.arrTableData = this.OrganizeClientData(data);
                    this.setState({
                        ...this.state,
                        bLoading: false,
                        objFilter: {
                            strKeyword: strKeywords,
                            iCurrentClientStatus: 0,
                            iCurrentClientType: 0
                        }
                    });
                }).catch(function (error) { console.log(error); });
        }
    }
    // 点击了Client过滤器
    ClientTypeFilterClicked = (iClientType) => {
        let strTargetType = this.arrFilterClientType[iClientType];
        if (iClientType !== 0) {
            this.arrTableData = [];
            for (let i = 0; i < this.arrOgeTableData.length; i++) {
                if (strTargetType === this.arrOgeTableData[i].level) {
                    this.arrTableData.push(this.arrOgeTableData[i]);
                }
            }
        } else {
            this.arrTableData = this.arrOgeTableData;
        }
        if (this.refs.user_name_search !== undefined)
            this.refs.user_name_search.state.value = "";
        this.setState({
            ...this.state,
            objFilter: {
                strKeyword: "",
                iCurrentClientStatus: this.state.objFilter.iCurrentClientStatus,
                iCurrentClientType: iClientType
            }
        });
    }
    // 点击了Client状态过滤器
    // ClientStatusFilterClicked = (iStatus) => {
    //     this.setState({
    //         ...this.state,
    //         objFilter: {
    //             strKeyword: "", iCurrentClientStatus: iStatus, iCurrentClientType: this.state.objFilter.iCurrentClientType
    //         }
    //     });
    // }
    /* 自定义成员函数 */
    //弹出对话框
    ShowDlg = (iDlgType, e) => {
        if (iDlgType === 0) {
            if (this.refs.name !== undefined) {
                this.refs.name.state.value = "";
                // this.refs.storage_level.state.value = this.arrStorageLevel[0];
                // this.refs.status.state.value = this.arrClientStatus[0];
            }
            this.CurrentClientInit();
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true,
                iStorageLevel: 0,
                iClientStatus: 1
            });
        }
        if (iDlgType === 1) {
            if (this.refs.name !== undefined) {
                this.refs.name.state.value = this.objCurrentClient.name;
                // this.refs.storage_level.state.value = this.arrStorageLevel[this.objCurrentClient.storage_level];
                // this.refs.status.state.value = this.arrClientStatus[this.objCurrentClient.status];
            }
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true,
                iStorageLevel: this.objCurrentClient.storage_level,
                iClientStatus: this.objCurrentClient.status
            });
        }
        if (iDlgType === 2) {
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true
            });
        }
    }
    // 弹窗确认按钮响应
    handleOk = e => {
        if (this.state.iDlgType === 0 || this.state.iDlgType === 1) {
            let strName = this.refs.name.state.value;
            if (strName === null || strName.trim() === "") {
                message.warning("客户名不能为空！"); return;
            }
            let objNewClient = {
                store_id: this.props.objStoreInfo.id,
                name: strName,
                code: "",
                invite_code: this.props.strInviteCode,
                level: 4,
                storage_level: this.state.iStorageLevel,
                status: this.state.iClientStatus
            }
            if (this.state.iDlgType === 1) {
                objNewClient.invite_code = this.objCurrentClient.invite_code;
                objNewClient.id = this.objCurrentClient.id;
                objNewClient.level = this.objCurrentClient.level;
            }
            message.loading({ content: '正在创建/更新客户信息……', key: g_strMessageKey });
            axios.post('/UpdateStorageClient', {
                objNewClient: objNewClient,
                action: this.state.iDlgType
            }).then(({ data }) => {
                if (data.status === 1) {
                    this.GetStorageClient();
                } else {
                    if (data.message)
                        message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
                    else
                        message.warning({ content: "新建/更新客户信息失败！", key: g_strMessageKey, duration: 2 });
                }
            }).catch(function (error) { console.log(error); });
        }
        if (this.state.iDlgType === 2) {
            message.loading({ content: '客户信息删除中……', key: g_strMessageKey });
            axios.post('/DeleteStorageClient', { id: this.objCurrentClient.id })
                .then(({ data }) => {
                    if (data.status === -1) {
                        message.warning({
                            content: "删除失败，数据库中未找到该客户信息！",
                            key: g_strMessageKey, duration: 2
                        });
                        this.setState({
                            ...this.state,
                            btnControlDisable: [false, true, true],
                            bLoading: false,
                            bShowDlg: false,
                            selectedRowKeys: []
                        });
                    } else {
                        this.GetStorageClient();
                    }
                }).catch(function (error) { console.log(error); });
        }
    };
    // 弹窗取消按钮响应
    handleCancel = e => {
        this.setState({
            bShowDlg: false,
            selectedRowKeys: [],
            btnControlDisable: [false, true, true]
        });
    };
    // 整理Client数据
    OrganizeClientData(data) {
        let arrTableData = [];
        for (let i = 0; i < data.length; i++) {
            let objClientData = data[i];
            objClientData.key = objClientData.id;
            objClientData.type = '客户';
            if (objClientData.level >= 9)
                objClientData.type = '代理';
            objClientData.storage_level_str = this.arrStorageLevel[objClientData.storage_level];
            objClientData.status_str = this.arrClientStatus[objClientData.status];
            arrTableData.push(objClientData);
        }
        return arrTableData;
    }
    // 从数据库获取当前的数据结构
    GetStorageClient = () => {
        this.CurrentClientInit();
        message.loading({ content: '数据加载中……', key: g_strMessageKey });
        axios('/GetStorageClient', { params: { store_id: this.props.objStoreInfo.id, invite_code: this.props.strInviteCode, level: this.props.iUserLevel } })
            .then(({ data }) => {
                console.log(data);
                this.arrOgeTableData = this.OrganizeClientData(data);
                this.arrTableData = this.arrOgeTableData;
                global.arrClientData = this.arrOgeTableData;
                if (this.state.bShowDlg) {
                    if (this.state.iDlgType === 0) {
                        message.success({ content: "新的客户已保存！", key: g_strMessageKey, duration: 2 });
                    }
                    if (this.state.iDlgType === 1) {
                        message.success({ content: "客户信息已更新！", key: g_strMessageKey, duration: 2 });
                    }
                    if (this.state.iDlgType === 2) {
                        message.success({ content: "客户已删除！", key: g_strMessageKey, duration: 2 })
                    }
                } else {
                    message.success({ content: "客户信息加载完成！", key: g_strMessageKey, duration: 2 });
                }
                this.setState({
                    ...this.state,
                    bLoading: false,
                    bShowDlg: false,
                    selectedRowKeys: []
                });
            }).catch(function (error) { console.log(error); });
    }
    // 新建Client信息的初始化
    CurrentClientInit = () => {
        this.objCurrentClient = {
            name: "",
            storage_level: 0,
            status: 1
        };
    }
    // 关键词处理
    KeywordsProcess = (strKeywords) => {
        let newKeywords = "";
        for (let i = 0; i < strKeywords.length; i++) {
            if (strKeywords[i] !== " ") {
                if (i === strKeywords.length - 1)
                    newKeywords += strKeywords[i];
                else
                    newKeywords += strKeywords[i] + "%";
            }
        }
        return newKeywords;
    }
    // 搜索库区信息名
    ClientSearch = () => {
        let strKeywords = this.refs.search.state.value;
        if (strKeywords === undefined)
            strKeywords = "";
        if (strKeywords.trim() === "") {
            this.GetStorageClient();
        } else {
            this.setState({
                ...this.state,
                bLoading: true,
                selectedRowKeys: [],
                btnControlDisable: [false, true, true]
            });
            axios('/SearchStorageClient', { params: { name: this.KeywordsProcess(strKeywords), store_id: this.props.objStoreInfo.id } })
                .then(({ data }) => {
                    this.arrTableData = this.OrganizeClientData(data);
                    this.setState({
                        ...this.state,
                        bLoading: false,
                        bShowDlg: false,
                        selectedRowKeys: []
                    });
                }).catch(function (error) { console.log(error); });
        }
    }
    // 点击条形码时的默认屏蔽
    BarcodeClick = (event) => {
        event.preventDefault();
    }
    // VIP等级下拉菜单相应
    StorageLevelDropDownClicked = (index) => {
        this.setState({
            ...this.state,
            iStorageLevel: index
        });
    }
    // 客户状态下拉菜单相应
    ClientStatusDropDownClicked = (index) => {
        this.setState({
            ...this.state,
            iClientStatus: index
        });
    }
    /* React自带函数 */
    // 卸载异步操作设置状态
    componentWillUnmount() {
        clearTimeout(this.timeouter)
        this.setState = (state, callback) => {
            return;
        }
    }
    // 渲染完毕……
    componentDidMount() {
        this.GetStorageClient();
    }
    // 渲染函数
    render() {

        /* Client管理页面控制栏 */
        let uiTitleArea = (
            < div className="storage_contents_title" >
                <Button className="manage_contents_title_margin" style={{ background: 'darkcyan', color: 'white', marginLeft: '5px' }}
                    onClick={this.ShowDlg.bind(this, 0)} disabled={this.state.btnControlDisable[0]}>
                    <Icon type="plus" />新建</Button>
                <Button className="manage_contents_title_margin" type="primary" onClick={this.ShowDlg.bind(this, 1)} disabled={this.state.btnControlDisable[1]}>
                    <Icon type="edit" /> 编辑</Button>
                <Button className="manage_contents_title_margin" type="danger" onClick={this.ShowDlg.bind(this, 2)} disabled={this.state.btnControlDisable[2]}>
                    <Icon type="close" /> 删除</Button>
                <Input placeholder="搜索Client/名称/备注" ref="search" defaultValue={this.state.strKeywords} style={{ width: '200px', marginLeft: '20px' }} />
                <Button className="manage_contents_title_margin"
                    type="primary" onClick={this.ClientSearch}>搜索
                </Button>
            </div >
        );

        // 如果界面上需要显示的内容已经从数据库获得
        if (!this.state.bLoading) {
            // 主体表格相关
            const { selectedRowKeys } = this.state;
            const rowSelection = {
                selectedRowKeys,
                onChange: this.onSelectedRowKeysChange,
                columnWidth: 30
            };
            // 表格分页属性
            const paginationProps = {
                showSizeChanger: true,
                showQuickJumper: false,
                showTotal: () => `共 ${this.arrTableData.length} 个 Client`,
                defaultPageSize: 10,
                defaultCurrent: this.state.iCurrentPage,
                pageSizeOptions: ['10', '20', '30', '40', '50'],
                total: this.arrTableData.length,
                position: 'bottom'
            };
            // 客户等级下拉菜单
            const uiStorageLevel = (
                <Menu>
                    {this.arrStorageLevel.map((item, index) => {
                        return (
                            <Menu.Item key={index} onClick={this.StorageLevelDropDownClicked.bind(this, index)}>
                                {item}
                            </Menu.Item>
                        );
                    })}
                </Menu>
            );
            // 客户状态下拉菜单
            const uiClientStatus = (
                <Menu>
                    {this.arrClientStatus.map((item, index) => {
                        return (
                            <Menu.Item key={index} onClick={this.ClientStatusDropDownClicked.bind(this, index)}>
                                {item}
                            </Menu.Item>
                        );
                    })}
                </Menu>
            );

            // Client编辑弹窗
            let uiModal = (
                <Modal title="客户信息(*必填)" width={925} visible={this.state.bShowDlg}
                    okText="确定" cancelText="取消" onOk={this.handleOk} onCancel={this.handleCancel} >
                    <div className="user_edit_info_row">
                        <div className="user_edit_info_row">
                            <div className="storage_edit_info_title"><div className="storage_edit_info_required">*</div>姓名：</div>
                            <Input className="storage_edit_info_input" ref="name" defaultValue={this.objCurrentClient.name} />
                        </div>
                        <div className="user_edit_info_row">
                            <div className="storage_edit_info_title"><div className="storage_edit_info_required">*</div>等级：</div>
                            {/* <Input className="storage_edit_info_input" ref="storage_level" defaultValue={this.arrStorageLevel[this.objCurrentClient.storage_level]} /> */}
                            <Dropdown className="storage_edit_info_input" overlay={uiStorageLevel} placement="bottomLeft">
                                <Button className="storage_edit_info_input">
                                    {this.arrStorageLevel[this.state.iStorageLevel]}
                                    <Icon type="caret-down" style={{ marginLeft: '10px' }} />
                                </Button>
                            </Dropdown>
                        </div>
                        <div className="user_edit_info_row">
                            <div className="storage_edit_info_title"><div className="storage_edit_info_required"></div>状态：</div>
                            {/* <Input className="storage_edit_info_input" ref="status" defaultValue={this.arrClientStatus[this.objCurrentClient.status]} /> */}
                            <Dropdown className="storage_edit_info_input" overlay={uiClientStatus} placement="bottomLeft">
                                <Button className="storage_edit_info_input">
                                    {this.arrClientStatus[this.state.iClientStatus]}
                                    <Icon type="caret-down" style={{ marginLeft: '10px' }} />
                                </Button>
                            </Dropdown>
                        </div>
                    </div>
                </Modal>
            );
            if (this.state.iDlgType === 2) {
                uiModal = (
                    <Modal width={500} title={"删除客户"} visible={this.state.bShowDlg} okType="danger"
                        onOk={this.handleOk} okText="确定"
                        onCancel={this.handleCancel} cancelText="取消">
                        <div className="modal_item">
                            <div>{this.objCurrentClient.name}
                                <br /><br />确定删除该客户信息？
                            </div>
                        </div>
                    </Modal>
                );
            }
            return (
                <div className="storage_contents_area">
                    {uiTitleArea}
                    {uiModal}
                    {/* 表格主体 */}
                    <Table style={{ margin: '20px', width: '96%', overflowX: 'auto' }}
                        rowSelection={rowSelection} pagination={paginationProps}
                        columns={arrTableHeader} dataSource={this.arrTableData} bordered
                        onRow={(record) => ({ onClick: () => { this.selectRow(record); } })} />
                </div >
            );
        } else {
            return (
                <div className="storage_contents_area">
                    {uiTitleArea}
                    <Spin className="manage_empty_loading" size="large" tip="加载中……" />
                </div>
            );
        }
    }
}
export default StorageClientInfo;
