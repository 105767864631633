import React, { Component } from 'react';
import axios from 'axios';

import Spin from 'antd/es/spin';
import Table from 'antd/es/table';
import Button from 'antd/es/button';
import Input from 'antd/es/input';
import Modal from 'antd/es/modal';
import message from 'antd/es/message';

import Barcode from 'react-barcode';

import '../../../styles/Background/ego_manager.css';
import '../../../styles/Background/storage_manager.css';
import '../../../styles/Background/UI_Module/user_management.css';

const g_strMessageKey = 'updating';

// 表格
const arrTableHeader = [
    { title: '库位', dataIndex: 'position_code', width: 100, ellipsis: true },
    { title: 'SKU', dataIndex: 'sku', width: 100, ellipsis: true },
    { title: '名称', dataIndex: 'name', width: 100, ellipsis: true },
    { title: '数量', dataIndex: 'num', width: 100, ellipsis: true },
    { title: '客户', dataIndex: 'user_name', width: 100, ellipsis: true },
    { title: '上架日期', dataIndex: 'update_date_str', width: 100, ellipsis: true },
];
const arrTableClientHeader = [
    { title: 'SKU', dataIndex: 'sku', width: 100, ellipsis: true },
    { title: '名称', dataIndex: 'name', width: 100, ellipsis: true },
    { title: '数量', dataIndex: 'num', width: 100, ellipsis: true },
    { title: '上架日期', dataIndex: 'update_date_str', width: 100, ellipsis: true },
];

class InventorySearch extends Component {
    constructor(props) {
        super();
        this.state = {
            btnControlDisable: [false, true, true],  // 操作按钮控制
            selectedRowKeys: [],  // Check here to configure the default column
            iDlgType: -1,
            bShowDlg: false,
            bLoading: true,
            bSearchChange: false
        };
        // 成员变量
        this.arrOgeTableData = [];
        this.arrTableData = [];  // 用于表格的数组
        this.objCurrentSKU = {};

        this.strSKUSearch = "";
        this.strPositionSearch = "";
        this.strClientSearch = "";
    }

    // 选中某行的响应函数
    selectRow = (record) => {
        const selectedRowKeys = [...this.state.selectedRowKeys];
        if (selectedRowKeys.indexOf(record.key) >= 0) {
            selectedRowKeys.splice(selectedRowKeys.indexOf(record.key), 1);
        } else {
            selectedRowKeys.push(record.key);
        }
        this.SelectedRowStateSetting(selectedRowKeys);
    }
    // 选中的行发生变化的响应函数
    onSelectedRowKeysChange = (selectedRowKeys) => {
        this.SelectedRowStateSetting(selectedRowKeys);
    }
    // 处理选中的表行行
    SelectedRowStateSetting = (selectedRowKeys) => {
        let btnControlDisable = [];
        // let btnCopyPasteDisable = [];
        switch (selectedRowKeys.length) {
            case 0: {
                btnControlDisable = [false, true, true];
                // btnCopyPasteDisable = [true, true];
                this.CurrentSKUInit();
                break;
            }
            case 1: {
                btnControlDisable = [false, false, false];
                // btnCopyPasteDisable = [false, true];
                for (let i = 0; i < this.arrTableData.length; i++) {
                    if (this.arrTableData[i].key === selectedRowKeys[0]) {
                        this.objCurrentSKU = this.arrTableData[i];
                        break;
                    }
                }
                break;
            }
            default: {
                btnControlDisable = [false, true, true];
                // btnCopyPasteDisable = [true, true];
                break;
            }
        }
        this.setState({
            ...this.state,
            selectedRowKeys,
            btnControlDisable: btnControlDisable
            // btnCopyPasteDisable: btnCopyPasteDisable
        });
    }
    // 关键词处理
    KeywordsProcess = (strKeywords) => {
        let newKeywords = "";
        for (let i = 0; i < strKeywords.length; i++) {
            if (strKeywords[i] !== " ") {
                if (i === strKeywords.length - 1)
                    newKeywords += strKeywords[i];
                else
                    newKeywords += strKeywords[i] + "%";
            }
        }
        return newKeywords;
    }
    // 搜索SKU名
    // UserNameSearch = () => {
    //     let strKeywords = this.refs.user_name_search.state.value;
    //     if (strKeywords === undefined)
    //         strKeywords = "";
    //     if (strKeywords.trim() === "") {
    //         this.GetSKU();
    //     } else {
    //         this.setState({
    //             ...this.state,
    //             bLoading: true,
    //             selectedRowKeys: [],
    //             btnControlDisable: [false, true, true]
    //         });
    //         axios('/SearchUsers', { params: { name: this.KeywordsProcess(strKeywords) } })
    //             .then(({ data }) => {
    //                 this.arrTableData = this.OrganizeSKUData(data);
    //                 this.setState({
    //                     ...this.state,
    //                     bLoading: false,
    //                     objFilter: {
    //                         strKeyword: strKeywords,
    //                         iCurrentUserStatus: 0,
    //                         iCurrentUserType: 0
    //                     }
    //                 });
    //             }).catch(function (error) { console.log(error); });
    //     }
    // }
    // 点击了SKU过滤器
    UserTypeFilterClicked = (iUserType) => {
        let strTargetType = this.arrFilterUserType[iUserType];
        if (iUserType !== 0) {
            this.arrTableData = [];
            for (let i = 0; i < this.arrOgeTableData.length; i++) {
                if (strTargetType === this.arrOgeTableData[i].level) {
                    this.arrTableData.push(this.arrOgeTableData[i]);
                }
            }
        } else {
            this.arrTableData = this.arrOgeTableData;
        }
        if (this.refs.user_name_search !== undefined)
            this.refs.user_name_search.state.value = "";
        this.setState({
            ...this.state,
            objFilter: {
                strKeyword: "",
                iCurrentUserStatus: this.state.objFilter.iCurrentUserStatus,
                iCurrentUserType: iUserType
            }
        });
    }
    // 点击了SKU状态过滤器
    // UserStatusFilterClicked = (iStatus) => {
    //     this.setState({
    //         ...this.state,
    //         objFilter: {
    //             strKeyword: "", iCurrentUserStatus: iStatus, iCurrentUserType: this.state.objFilter.iCurrentUserType
    //         }
    //     });
    // }
    /* 自定义成员函数 */
    //弹出对话框
    ShowDlg = (iDlgType, e) => {
        if (iDlgType === 0) {
            if (this.refs.name !== undefined) {
                this.refs.sku.state.value = "";
                this.refs.name.state.value = "";
                this.refs.en_name.state.value = "";
                this.refs.bar_code.state.value = "";
                this.refs.type.state.value = "";
                this.refs.style.state.value = "";
                // this.refs.length.state.value = 0.0;
                // this.refs.width.state.value = 0.0;
                // this.refs.height.state.value = 0.0;
                this.refs.value.state.value = 0.0;
                this.refs.weight.state.value = 0.0;
                // this.refs.value_weight.state.value = 0.0;
                // this.refs.unit.state.value = "";
                // this.refs.sale_price.state.value = "";
                // this.refs.cost.state.value = "";
                this.refs.comments.state.value = "";
            }
            this.fValue = 0.0;
            this.fValueWeight = 0.0;
            this.fPriceWeight = 0.0;
            this.CurrentSKUInit();
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true
            });
        }
        if (iDlgType === 1) {
            if (this.refs.name !== undefined) {
                this.refs.sku.state.value = this.objCurrentSKU.sku;
                this.refs.name.state.value = this.objCurrentSKU.name;
                this.refs.en_name.state.value = this.objCurrentSKU.en_name;
                this.refs.bar_code.state.value = this.objCurrentSKU.bar_code;
                this.refs.type.state.value = this.objCurrentSKU.type;
                this.refs.style.state.value = this.objCurrentSKU.style;
                // this.refs.length.state.value = this.objCurrentSKU.length;
                // this.refs.width.state.value = this.objCurrentSKU.width;
                // this.refs.height.state.value = this.objCurrentSKU.height;
                this.refs.value.state.value = this.objCurrentSKU.value;
                this.refs.weight.state.value = this.objCurrentSKU.weight;
                // this.refs.value_weight.state.value = this.objCurrentSKU.value_weight;
                // this.refs.unit.state.value = this.objCurrentSKU.unit;
                // this.refs.sale_price.state.value = this.objCurrentSKU.sale_price;
                // this.refs.cost.state.value = this.objCurrentSKU.cost;
                this.refs.comments.state.value = this.objCurrentSKU.comments;
            }
            this.fValue = parseFloat(this.objCurrentSKU.value);
            this.fValueWeight = parseFloat(this.objCurrentSKU.value_weight);
            this.fPriceWeight = parseFloat(this.objCurrentSKU.price_weight);
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true,
            });
        }
        if (iDlgType === 2) {
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true
            });
        }
    }
    // 弹窗确认按钮响应
    handleOk = e => {
        if (this.state.iDlgType === 0 || this.state.iDlgType === 1) {
            let strSKU = this.refs.sku.state.value;
            if (strSKU === null || strSKU.trim() === "") {
                message.warning("SKU代码不能为空！"); return;
            }
            let strName = this.refs.name.state.value;
            if (strName === null || strName.trim() === "") {
                message.warning("名称不能为空！"); return;
            }
            let strEnName = this.refs.en_name.state.value;
            if (strEnName === null || strEnName.trim() === "") {
                message.warning("英文名称不能为空！"); return;
            }
            let strBarCode = this.refs.bar_code.state.value;
            if (strBarCode === null || strBarCode.trim() === "") {
                message.warning("货物编码不能为空！"); return;
            }
            let strType = this.refs.type.state.value;
            if (strType === null || strType.trim() === "") {
                message.warning("货物种类不能为空！"); return;
            }
            let strStyle = this.refs.style.state.value;
            if (strStyle === null || strStyle.trim() === "") {
                message.warning("货物规格不能为空！"); return;
            }
            let objNewSKU = {
                sku: strSKU,
                name: strName,
                en_name: strEnName,
                bar_code: strBarCode,
                type: strType,
                style: strStyle,
                length: 0.0,
                width: 0.0,
                height: 0.0,
                value: this.refs.value.state.value,
                weight: this.refs.weight.state.value,
                value_weight: 0.0,
                price_weight: 0.0,
                sale_price: 0.0,
                cost: 0.0,
                unit: 0.0,
                // length: this.refs.length.state.value,
                // width: this.refs.width.state.value,
                // height: this.refs.height.state.value,
                // value_weight: this.refs.value_weight.state.value,
                // price_weight: this.fPriceWeight,
                // sale_price: this.refs.sale_price.state.value,
                // cost: this.refs.cost.state.value,
                // unit: this.refs.unit.state.value,
                comments: this.refs.comments.state.value,

                user_id: this.props.iUserID,
                store_id: this.props.objStoreInfo.id
            }
            message.loading({ content: '正在更新SKU……', key: g_strMessageKey });
            axios.post('/UpdateSKU', {
                objCurrentSKU: this.objCurrentSKU,
                objNewSKU: objNewSKU,
                action: this.state.iDlgType
            }).then(
                ({ data }) => {
                    if (data.status === 1) {
                        this.GetSKUUpRecord();
                    } else {
                        if (data.message)
                            message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
                        else
                            message.warning({ content: "新建/更新SKU失败！", key: g_strMessageKey, duration: 2 });
                    }
                }).catch(function (error) { console.log(error); });
        }
        if (this.state.iDlgType === 2) {
            message.loading({ content: 'SKU删除中……', key: g_strMessageKey });
            axios.post('/DeleteSKU', { id: this.objCurrentSKU.id }).then(
                ({ data }) => {
                    if (data.status === -1) {
                        message.warning({
                            content: "删除失败，数据库中未找到指定SKU！",
                            key: g_strMessageKey, duration: 2
                        });
                        this.setState({
                            ...this.state,
                            btnControlDisable: [false, true, true],
                            bLoading: false,
                            bShowDlg: false,
                            selectedRowKeys: []
                        });
                    } else {
                        this.GetSKUUpRecord();
                    }
                }).catch(function (error) { console.log(error); });
        }
    };
    // 弹窗取消按钮响应
    handleCancel = e => {
        this.setState({
            bShowDlg: false,
            selectedRowKeys: [],
            btnControlDisable: [false, true, true]
        });
    };
    // 整理SKU数据
    OrganizeInventoryPositionData(data) {
        let arrTableData = [];
        for (let i = 0; i < data.length; i++) {
            let objSKUUpRecordData = data[i];
            objSKUUpRecordData.key = objSKUUpRecordData.id;
            objSKUUpRecordData.update_date_str = data[i].update_date.split('T')[0];
            arrTableData.push(objSKUUpRecordData);
        }
        return arrTableData;
    }
    // 从数据库获取当前的数据结构
    GetSKUUpRecord = () => {
        this.CurrentSKUInit();
        message.loading({ content: '数据加载中……', key: g_strMessageKey });
        axios('/GetSKUUpRecord', { params: { store_id: this.props.objStoreInfo.id } })
            .then(({ data }) => {
                this.arrOgeTableData = this.OrganizeSKUUpRecordData(data);
                this.arrTableData = this.arrOgeTableData;
                message.success({ content: "上架记录加载完成！", key: g_strMessageKey, duration: 2 });
                this.setState({
                    ...this.state,
                    bLoading: false,
                    bShowDlg: false,
                    selectedRowKeys: []
                });
            }).catch(function (error) { console.log(error); });
    }
    // 从数据库获取当前的数据结构
    GetInventoryPosition = () => {
        message.loading({ content: '数据加载中……', key: g_strMessageKey });
        if (this.strPositionSearch.trim() === "" && this.strSKUSearch.trim() === "" && this.strClientSearch.trim() === "") {
            let objParams = {
                store_id: this.props.objStoreInfo.id,
                position_code: "", sku: "", user_name: "",
                invite_code: this.props.strInviteCode
            };
            if (this.props.bClientUser)
                objParams = {
                    store_id: this.props.objStoreInfo.id,
                    position_code: "", sku: "", user_id: this.props.iUserID,
                    invite_code: this.props.strInviteCode
                };
            axios('/GetInventoryRecord', {
                params: objParams
            }).then(({ data }) => {
                this.arrOgeTableData = this.OrganizeInventoryPositionData(data);
                this.arrTableData = this.arrOgeTableData;
                message.success({ content: "库位加载完成！", key: g_strMessageKey, duration: 2 });
                this.setState({
                    ...this.state,
                    bLoading: false,
                    bShowDlg: false,
                    selectedRowKeys: []
                });
            }).catch(function (error) { console.log(error); });
            return;
        }
        // 客户端，只能看到自己SKU的库存信息
        if (this.props.bClientUser) {
            axios('/GetInventoryRecord', {
                params: {
                    store_id: this.props.objStoreInfo.id, position_code: this.strPositionSearch,
                    sku: this.strSKUSearch, user_id: this.props.iUserID,
                    invite_code: this.props.strInviteCode
                }
            }).then(({ data }) => {
                this.arrOgeTableData = this.OrganizeInventoryPositionData(data);
                this.arrTableData = this.arrOgeTableData;
                message.success({ content: "库位加载完成！", key: g_strMessageKey, duration: 2 });
                this.setState({
                    ...this.state,
                    bLoading: false,
                    bShowDlg: false,
                    selectedRowKeys: []
                });
            }).catch(function (error) { console.log(error); });
        }
        // 管理员后台，可以看到所有客户SKU的库存
        else {
            axios('/GetInventoryRecord', {
                params: {
                    store_id: this.props.objStoreInfo.id, position_code: this.strPositionSearch, sku: this.strSKUSearch,
                    user_name: this.strClientSearch, invite_code: this.props.strInviteCode
                }
            }).then(({ data }) => {
                this.arrOgeTableData = this.OrganizeInventoryPositionData(data);
                this.arrTableData = this.arrOgeTableData;
                message.success({ content: "库位加载完成！", key: g_strMessageKey, duration: 2 });
                this.setState({
                    ...this.state,
                    bLoading: false,
                    bShowDlg: false,
                    selectedRowKeys: []
                });
            }).catch(function (error) { console.log(error); });
        }

    }
    // 新建SKU信息的初始化
    CurrentSKUInit = () => {
        this.objCurrentSKU = {
            sku: "",
            name: "",
            en_name: "",
            bar_code: "",
            type: "",
            style: "",
            length: 0.0,
            width: 0.0,
            height: 0.0,
            weight: 0.0,
            value: 0.0,
            value_weight: 0.0,
            price_weight: 0.0,
            comments: "",
            unit: "",
            sale_price: 0.0,
            cost: 0.0,
            store_id: this.props.objStoreInfo.id,
            user_id: this.props.iUserID
        };
    }
    // 体积重量信息变化
    OnValueWeightChange = (index, e) => {
        let fLength = parseFloat(this.refs.length.state.value);
        let fWidth = parseFloat(this.refs.width.state.value);
        let fHeight = parseFloat(this.refs.height.state.value);
        let fWeight = parseFloat(this.refs.weight.state.value);
        switch (index) {
            // 长度变化
            case 0: {
                fLength = parseFloat(e.target.value);
                break;
            }
            // 宽度变化
            case 1: {
                fWidth = parseFloat(e.target.value);
                break;
            }
            // 高度变化
            case 2: {
                fHeight = parseFloat(e.target.value);
                break;
            }
            // 重量变化
            case 3: {
                fWeight = parseFloat(e.target.value);
                break;
            }
            default: break;
        }
        this.fValue = fLength * fWidth * fHeight;
        this.fValueWeight = fLength * fWidth * fHeight / 5000.0;
        if (fWeight >= this.fValueWeight)
            this.fPriceWeight = fWeight;
        else
            this.fPriceWeight = this.fValueWeight;
        this.setState({
            ...this.state,
            bValueWeightChange: true
        })
    }
    // 关键词处理
    KeywordsProcess = (strKeywords) => {
        let newKeywords = "";
        for (let i = 0; i < strKeywords.length; i++) {
            if (strKeywords[i] !== " ") {
                if (i === strKeywords.length - 1)
                    newKeywords += strKeywords[i];
                else
                    newKeywords += strKeywords[i] + "%";
            }
        }
        return newKeywords;
    }
    // 搜索库区信息名
    PositionSearch = () => {
        this.strSKUSearch = this.KeywordsProcess(this.refs.search_sku.state.value);
        if (!this.props.bClientUser) {
            this.strPositionSearch = this.KeywordsProcess(this.refs.search_position.state.value);
            if (this.props.iUserLevel > 9)
                this.strClientSearch = this.KeywordsProcess(this.refs.search_client.state.value);
        }
        this.GetInventoryPosition();
    }
    // 点击条形码时的默认屏蔽
    BarcodeClick = (event) => {
        event.preventDefault();
    }
    /* React自带函数 */
    // 卸载异步操作设置状态
    componentWillUnmount() {
        clearTimeout(this.timeouter)
        this.setState = (state, callback) => {
            return;
        }
    }
    // 渲染完毕……
    componentDidMount() {
        this.GetInventoryPosition();
    }
    // 渲染函数
    render() {

        /* 库存查询页面控制栏 */
        let uiClientSearch = null;
        let uiPositionSearch = null;
        if (!this.props.bClientUser) {
            uiPositionSearch = (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div className="storage_contents_title_text">库位：</div>
                    <Input placeholder="库位编码" ref="search_position" defaultValue="" style={{ width: '125px', marginLeft: '5px', marginRight: '15px' }} />
                </div>
            );
            uiClientSearch = (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div className="storage_contents_title_text">客户：</div>
                    <Input placeholder="" ref="search_client" defaultValue="" style={{ width: '100px', marginLeft: '5px', marginRight: '15px' }} />
                </div>
            );
        }
        if (this.props.iUserLevel === 9)
            uiClientSearch = null;

        let uiTitleArea = (
            < div className="storage_contents_title" >
                {uiPositionSearch}
                <div className="storage_contents_title_text">SKU：</div>
                <Input placeholder="SKU编码" ref="search_sku" defaultValue="" style={{ width: '150px', marginLeft: '5px', marginRight: '15px' }} />
                {uiClientSearch}
                <Button className="manage_contents_title_margin"
                    type="primary" onClick={this.PositionSearch}>搜索
                    </Button>
            </div >
        );

        // 如果界面上需要显示的内容已经从数据库获得
        if (!this.state.bLoading) {
            // 主体表格相关
            const { selectedRowKeys } = this.state;
            const rowSelection = {
                selectedRowKeys,
                onChange: this.onSelectedRowKeysChange,
                columnWidth: 30
            };
            // 表格分页属性
            const paginationProps = {
                showSizeChanger: true,
                showQuickJumper: false,
                showTotal: () => `共 ${this.arrTableData.length} 个 SKU`,
                defaultPageSize: 10,
                defaultCurrent: this.state.iCurrentPage,
                pageSizeOptions: ['10', '20', '30', '40', '50'],
                total: this.arrTableData.length,
                position: 'bottom'
            };
            let strBarcodeValue = "";
            if (this.objCurrentSKU.sku !== undefined && this.objCurrentSKU.sku !== null)
                strBarcodeValue = this.objCurrentSKU.sku;
            // SKU编辑弹窗
            let uiModal = (
                <Modal title="货物SKU信息(*必填)" width={910} visible={this.state.bShowDlg}
                    okText="确定" cancelText="取消" onOk={this.handleOk} onCancel={this.handleCancel} >
                    <div className="user_edit_info_row">
                        <div className="user_edit_info_row">
                            <div className="storage_edit_info_title"><div className="storage_edit_info_required">*</div>SKU：</div>
                            <Input className="storage_edit_info_input" ref="sku"
                                defaultValue={this.objCurrentSKU.sku} />
                        </div>
                        <div className="user_edit_info_row">
                            <div className="storage_edit_info_title"><div className="storage_edit_info_required">*</div>中文名：</div>
                            <Input className="storage_edit_info_input" ref="name"
                                defaultValue={this.objCurrentSKU.name} />
                        </div>
                        <div className="user_edit_info_row">
                            <div className="storage_edit_info_title"><div className="storage_edit_info_required">*</div>英文名：</div>
                            <Input className="storage_edit_info_input" ref="en_name"
                                defaultValue={this.objCurrentSKU.en_name} />
                        </div>
                    </div>
                    <div className="user_edit_info_row">
                        <div className="user_edit_info_row">
                            <div className="storage_edit_info_title"><div className="storage_edit_info_required">*</div>编码：</div>
                            <Input className="storage_edit_info_input" ref="bar_code"
                                defaultValue={this.objCurrentSKU.bar_code} />
                        </div>
                        <div className="user_edit_info_row">
                            <div className="storage_edit_info_title"><div className="storage_edit_info_required">*</div>分类：</div>
                            <Input className="storage_edit_info_input" ref="type" placeholder="服装/食品/日用品 等等"
                                defaultValue={this.objCurrentSKU.type} />
                        </div>
                        <div className="user_edit_info_row">
                            <div className="storage_edit_info_title"><div className="storage_edit_info_required">*</div>规格：</div>
                            <Input className="storage_edit_info_input" ref="style"
                                defaultValue={this.objCurrentSKU.style} />
                        </div>
                    </div>
                    <div className="user_edit_info_row">
                        <div className="user_edit_info_row">
                            <div className="storage_edit_info_title"><div className="storage_edit_info_required"> </div>体积：</div>
                            <Input className="storage_edit_info_input" ref="value" /*disabled addonAfter="cm3" value={this.fValue} */
                                defaultValue={this.objCurrentSKU.value} />
                        </div>
                        <div className="user_edit_info_row">
                            <div className="storage_edit_info_title"><div className="storage_edit_info_required"> </div>重量：</div>
                            <Input className="storage_edit_info_input" ref="weight" addonAfter="kg" /*onChange={this.OnValueWeightChange.bind(this, 3)}*/
                                defaultValue={this.objCurrentSKU.weight} />
                        </div>
                        <div className="user_edit_info_row" style={{ marginLeft: '20px' }}>
                            <a href="请右键点击并保存该条形码" download={this.objCurrentSKU.sku} onClick={e => this.BarcodeClick(e)}>
                                <Barcode value={strBarcodeValue} renderer={'img'} displayValue={false} width={2} height={50} fontSize={15} background={'rgba(0, 0, 0, 0)'} />
                            </a>
                        </div>
                    </div>
                    <div className="user_edit_info_row">
                        <div className="user_edit_info_row">
                            <div className="storage_edit_info_title"><div className="storage_edit_info_required"> </div>备注：</div>
                            <Input className="storage_edit_info_input_long" ref="comments"
                                defaultValue={this.objCurrentSKU.comments} />
                        </div>
                    </div>
                </Modal>
            );
            if (this.state.iDlgType === 2) {
                uiModal = (
                    <Modal width={500} title={"删除SKU"} visible={this.state.bShowDlg} okType="danger"
                        onOk={this.handleOk} okText="确定"
                        onCancel={this.handleCancel} cancelText="取消">
                        <div className="modal_item">
                            <div>{this.objCurrentSKU.sku}
                                <br /><br />确定删除选中的SKU？
                            </div>
                        </div>
                    </Modal>
                );
            }
            let uiTable = null;
            let arrHeader = arrTableHeader;
            if (this.props.bClientUser)
                arrHeader = arrTableClientHeader;
            uiTable = (
                <Table style={{ margin: '20px', width: '96%', overflowX: 'auto' }}
                    rowSelection={rowSelection} pagination={paginationProps}
                    columns={arrHeader} dataSource={this.arrTableData} bordered
                    onRow={(record) => ({ onClick: () => { this.selectRow(record); } })} />
            );
            return (
                <div className="storage_contents_area">
                    {uiTitleArea}
                    {uiModal}
                    {/* 表格主体 */}
                    {uiTable}
                </div >
            );
        } else {
            return (
                <div className="storage_contents_area">
                    {uiTitleArea}
                    <Spin className="manage_empty_loading" size="large" tip="加载中……" />
                </div>
            );
        }
    }
}
export default InventorySearch;
