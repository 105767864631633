import React, { Component } from "react";
import { Resizable } from 'react-resizable';
import axios from 'axios';
import moment from 'moment';

import "../../../styles/Background/UI_Module/store_item_management.css";
// import '../../../styles/Background/UI_Module/store_management.css';
import '../../../styles/Background/store_manager.css';
import '../../../styles/Background/ego_manager.css';
import "../../../styles/Background/UI_Module/order_management.css";
import "../../../styles/Background/UI_Module/auto_order_management.css";

import Table from 'antd/es/table';
import Icon from 'antd/es/icon';
import Button from 'antd/es/button';
import Modal from 'antd/es/modal';
import Menu from 'antd/es/menu';
import Dropdown from 'antd/es/dropdown';
import Input from 'antd/es/input';
import message from 'antd/es/message';
import Spin from 'antd/es/spin';
import DatePicker from 'antd/es/date-picker';
import Pagination from 'antd/es/pagination';

// import { Table, Icon, Button, Modal, message } from 'antd';
import { UserActiveUpdate } from "../../../functions/DataStatistic.js"

const g_strMessageKey = 'updating';
// const { MonthPicker } = DatePicker;
// const { MonthPicker, RangePicker } = DatePicker;
const { TextArea } = Input;

const ResizeableTitle = props => {
    const { onResize, width, ...restProps } = props;

    if (!width) {
        return <th {...restProps} />;
    }

    return (
        <Resizable
            width={width}
            height={0}
            onResize={onResize}
            draggableOpts={{ enableUserSelectHack: false }}
        >
            <th {...restProps} />
        </Resizable>
    );
};
// 通用
const arrTableHeader1 = [
    { title: '预报单号', dataIndex: 'prediction_code', width: 150, ellipsis: true },
    { title: '正式单号', dataIndex: 'oid_str', width: 150, ellipsis: true },
    // { title: '预报编码', dataIndex: 'client', width: 100, ellipsis: true },
    // { title: '预计到达', dataIndex: 'arrive_date', width: 125, ellipsis: true },
    { title: '货运类型', dataIndex: 'type_str', width: 100, ellipsis: true },
    { title: '服务类型', dataIndex: 'service_type', width: 140, ellipsis: true },
    { title: '客户名称', dataIndex: 'client', width: 100, ellipsis: true },
    { title: '柜号', dataIndex: 'closet_no', width: 160, ellipsis: true },
    // { title: '物流状态', dataIndex: 'transit_status', width: 100, ellipsis: true },
    // { title: '状态描述', dataIndex: 'transit_details', width: 100, ellipsis: true },
    { title: '目的地', dataIndex: 'to_where', width: 100, ellipsis: true },
    { title: '条款', dataIndex: 'terms', width: 150, ellipsis: true },
    { title: '备注', dataIndex: 'item_info', width: 350, ellipsis: true },
    // { title: '入账', dataIndex: 'payment_status', width: 100, ellipsis: true },
    // { title: '海关文件', dataIndex: 'cf_button', width: 90, ellipsis: true },
    // { title: '发票文件', dataIndex: 'invoice_button', width: 90, ellipsis: true },
    // { title: '文件上传', dataIndex: 's3_link_sign', width: 90, ellipsis: true },
    // { title: '负责人', dataIndex: 'charge', width: 100, ellipsis: true }
];
const arrTableHeader1_Agent = [
    { title: '预报单号', dataIndex: 'prediction_code', width: 150, ellipsis: true },
    { title: '正式单号', dataIndex: 'oid_str', width: 150, ellipsis: true },
    { title: '物流单号', dataIndex: 'closet_no', width: 175, ellipsis: true },
    { title: '预计到达', dataIndex: 'arrive_date', width: 125, ellipsis: true },
    { title: '类型', dataIndex: 'type_str', width: 100, ellipsis: true },
    { title: '物流状态', dataIndex: 'transit_status', width: 150, ellipsis: true },
    // { title: '状态描述', dataIndex: 'transit_details', width: 150, ellipsis: true },
    // { title: '发货地', dataIndex: 'from_where', width: 100, ellipsis: true },
    { title: '目的地', dataIndex: 'to_where', width: 100, ellipsis: true },
    { title: '条款', dataIndex: 'item_info', width: 150, ellipsis: true },
    // { title: '负责人', dataIndex: 'charge', width: 100, ellipsis: true }
];

const arrTableHeader1_m = [
    { title: '预报单号', dataIndex: 'prediction_code', width: 100, ellipsis: true, className: 'store_manage_table_m' },
    { title: '物流单号', dataIndex: 'closet_no', width: 150, ellipsis: true, className: 'store_manage_table_m' },
];
// 至禾国际
// const arrTableHeader2 = [
//     { title: '预报单号', dataIndex: 'prediction_code', width: 125, ellipsis: true },
//     { title: '预报编码', dataIndex: 'client', width: 100, ellipsis: true },
//     { title: '装柜日期', dataIndex: 'package_date', width: 125, ellipsis: true },
//     { title: '预计到达', dataIndex: 'arrive_date', width: 125, ellipsis: true },
//     { title: '渠道', dataIndex: 'channel', width: 100, ellipsis: true },
//     { title: '物流单号', dataIndex: 'closet_no', width: 175, ellipsis: true },
//     { title: '物流状态', dataIndex: 'transit_status', width: 100, ellipsis: true },
//     { title: '状态描述', dataIndex: 'transit_details', width: 150, ellipsis: true },
//     { title: '发货地', dataIndex: 'from_where', width: 100, ellipsis: true },
//     { title: '目的地', dataIndex: 'to_where', width: 100, ellipsis: true },
//     { title: '条款', dataIndex: 'item_info', width: 150, ellipsis: true },
//     { title: '负责人', dataIndex: 'charge', width: 100, ellipsis: true }
// ];

class PredictionOrderList extends Component {
    constructor(props) {
        super();
        this.strToday = new Date();
        this.strToday = this.strToday.toISOString().split('T')[0];
        this.tableHead = arrTableHeader1;
        if (props.bMStation)
            this.tableHead = arrTableHeader1_m;
        this.strOrderTitle = "";
        // if (props.objStoreInfo.id === 10000) {
        this.strOrderTitle = "LK";
        if (props.objStoreInfo.level < 9)
            this.tableHead = arrTableHeader1_Agent;
        // }

        // if (props.objStoreInfo.id === 20000) {
        //     this.strOrderTitle = "ZH";
        //     this.tableHead = arrTableHeader2;
        // }
        this.state = {
            btnControlDisable: [false, true, true],  // 操作按钮控制
            selectedRowKeys: [],  // 选中的行
            bDlgShow: false,  // 是否显示弹窗
            iDlgType: 0,  // 弹窗类别
            dlgInfo: { iOrderType: 0, iOrderPaymentStatus: 0, iOrderTransitStatus: 0, iOrderTransitDetails: 0 },
            filterInfo: { iOrderType: 0, iOrderPaymentStatus: 0 },
            iCurrentPage: 1,
            bLoading: true,
            columns: this.tableHead,
            arrive_date: this.strToday,
            package_date: this.strToday,
            bMultiEdit: false,
            bMultiEditDlg: false,
            iMultiEditTransitStatus: 0,
            iMultiEditTransitDetails: 0,
            iDateDesc: 1,
            iOrderDesc: 1,
            iOrderOrDate: 0,
            bHighlight: false,
            iHighlightFilter: 0,
            selectedFile: null,
            selectedFiles: null,
            bFileDelete: false,
            bFilterStatusChange: false,
            bSelectClient: false,
            iServiceType: 0,
            iServiceTypeFilter: 0
        };
        this.arrOrderTypeShort = ["F", "D", "O", "O", "A", "T", "S"];
        this.arrOrderType = ["Fedex", "DHL", "Ocean-整柜", "Ocean-拼柜", "Air", "Truking", "Sales"];
        // this.arrOrderPaymentStatus = ["未支付", "已支付", "已发货", "已完成", "有争议", "已作废", "已退款"];
        this.arrOrderPaymentStatus = ["未入账", "入账中", "已入账", "已作废"];
        this.arrOrderTransitStatus = ["未发货", "国内收货", "已发货", "已到港", "到目的地", "派送中", "已签收"];
        this.arrOrderTransitDetails = [];
        this.arrFilterOrderType = ["全部", "Fedex", "DHL", "Ocean-整柜", "Ocean-拼柜", "Air", "Truking", "Sales"];
        // this.arrFilterPaymentStatus = ["全部", "未支付", "已支付", "已发货", "已完成", "有争议", "已作废", "已退款"];
        this.arrFilterPaymentStatus = ["全部", "未入账", "入账中", "已入账", "已作废"];
        this.arrFilterTransitStatus = ["全部", "未发货", "国内收货", "已发货", "已到港", "到目的地", "派送中", "已签收"];
        this.arrTableData = [];
        this.arrOrderData = [];
        this.objCurrentOrder = {};
        this.arrSelectOrders = [];
        this.arrDeleteOrders = [];
        this.arrTransitList = [];

        this.dateDefault = moment();
        // this.dateDefault = this.dateDefault.subtract(1, 'days').format('YYYY-MM-DD');
        // this.dateDefault = moment(this.dateDefault);
        // this.dateCurrent = new Date(this.dateDefault).toISOString().split('T')[0];

        this.strPackageInfo = "";
        this.arrCustomFile = [];
        this.arrFileWaitList = [];
        this.bDeletingFile = false;

        this.dateCurrent = "";
        this.dateArrive = ["", ""];
        this.iOrderType = 0;
        this.iServiceType = 0;
        this.iPaymentStatus = 0;
        this.strKeyWord = "";
        this.strClientSearch = "";
        this.iIDOrder = 0;
        this.iArriveOrder = 1;
        this.iHightLightOrder = -1;

        this.arrServiceTypeFilter = ['全部', '清关', '派送', '换单', '清关派送', '清关自提', '拆柜转运', '未确定'];
        this.arrServiceType = ['清关', '派送', '换单', '清关派送', '清关自提', '拆柜转运', '未确定'];
    }

    // 预计到达日期更改
    ArriveDateChange = (date, dateString) => {
        this.setState({
            ...this.state,
            arrive_date: dateString
        });
    }
    // 装柜日期更改
    PackageDateChange = (date, dateString) => {
        this.setState({
            ...this.state,
            package_date: dateString
        });
    }
    // 表格列款调整
    components = {
        header: {
            cell: ResizeableTitle,
        },
    };
    handleResize = index => (e, { size }) => {
        this.setState(({ columns }) => {
            const nextColumns = [...columns];
            nextColumns[index] = {
                ...nextColumns[index],
                width: size.width,
            };
            return { columns: nextColumns };
        });
    };
    ResetTableLayout = () => {
        this.setState({
            ...this.state,
            columns: arrTableHeader1
        })
    }
    // 选中某行的响应函数
    selectRow = (record) => {
        const selectedRowKeys = [...this.state.selectedRowKeys];
        if (selectedRowKeys.indexOf(record.key) >= 0) {
            selectedRowKeys.splice(selectedRowKeys.indexOf(record.key), 1);
        } else {
            selectedRowKeys.push(record.key);
        }
        this.SelectedRowStateSetting(selectedRowKeys);
    }
    // 选中的行发生变化的响应函数
    onSelectedRowKeysChange = (selectedRowKeys) => {
        this.SelectedRowStateSetting(selectedRowKeys);
    }
    // 处理选中的表行行
    SelectedRowStateSetting = (selectedRowKeys) => {
        let btnControlDisable = [];
        if (selectedRowKeys.length === 1) {
            btnControlDisable = [false, false, false, true];
            this.objCurrentOrder = this.arrTableData[selectedRowKeys];
        } else {
            btnControlDisable = [false, true, true, true];
            this.objCurrentOrder = {};
        }
        // 选中多行，为统一编辑作准备
        this.arrSelectOrders = [];
        this.arrDeleteOrders = [];
        for (let i = 0; i < selectedRowKeys.length; i++) {
            this.arrSelectOrders.push(this.arrTableData[selectedRowKeys[i]]);
        }
        let bMultiEdit = false;
        if (this.arrSelectOrders.length > 1) {
            bMultiEdit = true;
            btnControlDisable = [false, true, false, false];
        }
        let bHighlight = false;
        if (this.arrSelectOrders.length > 0) {
            bHighlight = true;
        }
        this.setState({
            ...this.state,
            selectedRowKeys,
            btnControlDisable: btnControlDisable,
            bMultiEdit: bMultiEdit,
            bHighlight: bHighlight
        });
    }
    //显示弹窗前预置编辑的内容
    PresetNewDlg() {
        if (this.refs.order_charge) {
            this.refs.order_charge.state.value = "";
            this.refs.order_expense.state.value = "";
            this.refs.order_revenue.state.value = "";
            this.refs.order_gst.state.value = "";
            this.refs.order_payee.state.value = "";
        }
        if (this.refs.order_from_where) {
            this.refs.prediction_code.state.value = "";
            this.refs.order_from_where.state.value = "";
            this.refs.order_to_where.state.value = "";
            this.refs.order_item_info.state.value = "";
            this.refs.order_terms.state.value = "";
            this.refs.order_cbm.state.value = "";
            this.refs.order_closet_no.state.value = "";
            // if (this.props.objStoreInfo.id === 20000)
            //     this.refs.order_channel.state.value = "";
            // this.refs.package_info_input.state.value = "";
            this.refs.client.state.value = "";
            this.refs.weight.state.value = "";
            this.refs.item_num.state.value = "";

            this.refs.first_value_fee.state.value = "";
            this.refs.second_value_fee.state.value = "";
            this.refs.departure_fee.state.value = "";
            this.refs.arrive_fee.state.value = "";
            this.refs.delivery_fee.state.value = "";
            this.refs.waiting_fee.state.value = "";

            this.refs.import_company.state.value = "";
            this.refs.import_no.state.value = "";
            this.refs.import_relevant.state.value = "";

            this.refs.receiver.state.value = "";
            this.refs.phone.state.value = "";
            this.refs.email.state.value = "";
            this.refs.shipping_address.state.value = "";

        }
    }
    //显示弹窗前预置编辑的内容
    PresetEditDlg(iSelectedRow) {
        if (iSelectedRow < 0)
            return;
        this.strPackageInfo = "";
        if (this.arrOrderData[iSelectedRow].package_info !== null && this.arrOrderData[iSelectedRow].package_info !== "") {
            let arrPackageInfo = JSON.parse(this.arrOrderData[iSelectedRow].package_info);
            if (arrPackageInfo.length > 0) {
                for (let i = 0; i < arrPackageInfo.length; i++) {
                    if (i === arrPackageInfo[i].length - 1)
                        this.strPackageInfo += arrPackageInfo[i];
                    else
                        this.strPackageInfo += arrPackageInfo[i] + "\n";
                }
            }
        }
        if (this.refs.order_charge) {
            if (isNaN(this.arrOrderData[iSelectedRow].expense) || this.arrOrderData[iSelectedRow].expense === "NaN")
                this.arrOrderData[iSelectedRow].expense = "0.00";
            if (isNaN(this.arrOrderData[iSelectedRow].revenue) || this.arrOrderData[iSelectedRow].revenue === "NaN")
                this.arrOrderData[iSelectedRow].revenue = "0.00";
            if (isNaN(this.arrOrderData[iSelectedRow].gst) || this.arrOrderData[iSelectedRow].gst === "NaN")
                this.arrOrderData[iSelectedRow].gst = "0.00";

            this.refs.order_charge.state.value = this.arrOrderData[iSelectedRow].charge;
            this.refs.order_expense.state.value = this.arrOrderData[iSelectedRow].expense;
            this.refs.order_revenue.state.value = this.arrOrderData[iSelectedRow].revenue;
            this.refs.order_gst.state.value = this.arrOrderData[iSelectedRow].gst;
            this.refs.order_cbm.state.value = this.arrOrderData[iSelectedRow].cbm;
            // if (this.props.objStoreInfo.id === 20000)
            //     this.refs.order_channel.state.value = this.arrOrderData[iSelectedRow].channel;
            this.refs.order_payee.state.value = this.arrOrderData[iSelectedRow].payee;
        }
        if (this.refs.order_from_where) {
            this.refs.prediction_code.state.value = this.arrOrderData[iSelectedRow].prediction_code;
            this.refs.order_from_where.state.value = this.arrOrderData[iSelectedRow].from_where;
            this.refs.order_to_where.state.value = this.arrOrderData[iSelectedRow].to_where;
            this.refs.order_item_info.state.value = this.arrOrderData[iSelectedRow].item_info;
            this.refs.order_terms.state.value = this.arrOrderData[iSelectedRow].terms;
            // this.refs.package_info_input.state.value = this.strPackageInfo;
            this.refs.order_closet_no.state.value = this.arrOrderData[iSelectedRow].closet_no;
            this.refs.client.state.value = this.arrOrderData[iSelectedRow].client;
            this.refs.weight.state.value = this.arrOrderData[iSelectedRow].weight;
            this.refs.item_num.state.value = this.arrOrderData[iSelectedRow].item_num;

            this.refs.first_value_fee.state.value = this.arrOrderData[iSelectedRow].first_value_fee;
            this.refs.second_value_fee.state.value = this.arrOrderData[iSelectedRow].second_value_fee;
            this.refs.departure_fee.state.value = this.arrOrderData[iSelectedRow].departure_fee;
            this.refs.arrive_fee.state.value = this.arrOrderData[iSelectedRow].arrive_fee;
            this.refs.delivery_fee.state.value = this.arrOrderData[iSelectedRow].delivery_fee;
            this.refs.waiting_fee.state.value = this.arrOrderData[iSelectedRow].waiting_fee;

            this.refs.import_company.state.value = this.arrOrderData[iSelectedRow].import_company;
            this.refs.import_no.state.value = this.arrOrderData[iSelectedRow].import_no;
            this.refs.import_relevant.state.value = this.arrOrderData[iSelectedRow].import_relevant;

            this.refs.receiver.state.value = this.arrOrderData[iSelectedRow].receiver;
            this.refs.phone.state.value = this.arrOrderData[iSelectedRow].phone;
            this.refs.email.state.value = this.arrOrderData[iSelectedRow].email;
            this.refs.shipping_address.state.value = this.arrOrderData[iSelectedRow].shipping_address;
        }
    }
    //弹出对话框
    ShowDlg = (iDlgType) => {
        switch (iDlgType) {
            // 新建预报单
            case 0: {
                this.PresetNewDlg();
                this.arrOrderTransitDetails = [];
                this.setState({
                    ...this.state,
                    bDlgShow: true,
                    iDlgType: iDlgType,
                    dlgInfo: { iOrderType: 0, iOrderPaymentStatus: 0, iOrderTransitStatus: 0, iOrderTransitDetails: 0 },
                    arrive_date: this.strToday,
                    package_date: this.strToday,
                    iServiceType: 0
                });
                break;
            }
            // 编辑预报单
            case 1: {
                let iSelectedRow = this.state.selectedRowKeys[0];
                this.PresetEditDlg(iSelectedRow);
                let strArriveDate = this.arrOrderData[iSelectedRow].arrive_date;
                if (strArriveDate === null || strArriveDate.isNaN || strArriveDate === "")
                    strArriveDate = this.strToday;
                let strPackageDate = this.arrOrderData[iSelectedRow].package_date;
                if (strPackageDate === null || strPackageDate.isNaN || strPackageDate === "")
                    strPackageDate = this.strToday;
                if (this.arrOrderData[iSelectedRow].transit_status >= 1 && this.arrTransitList[this.arrOrderData[iSelectedRow].transit_status - 1]) {
                    this.arrOrderTransitDetails = [];
                    let arrTransitDetailsCN = this.arrTransitList[this.arrOrderData[iSelectedRow].transit_status - 1].sub_cn_name.split(',');
                    let arrTransitDetailsEN = this.arrTransitList[this.arrOrderData[iSelectedRow].transit_status - 1].sub_en_name.split(',');
                    if (arrTransitDetailsCN.length > 0 && this.arrTransitList[this.arrOrderData[iSelectedRow].transit_status - 1].sub_cn_name !== "")
                        for (let i = 0; i < arrTransitDetailsCN.length; i++) {
                            this.arrOrderTransitDetails.push(arrTransitDetailsCN[i] + " | " + arrTransitDetailsEN[i]);
                        }
                }
                this.setState({
                    ...this.state,
                    bDlgShow: true,
                    iDlgType: iDlgType,
                    dlgInfo: {
                        iOrderType: this.arrOrderData[iSelectedRow].type,
                        iOrderPaymentStatus: this.arrOrderData[iSelectedRow].payment_status,
                        iOrderTransitStatus: this.arrOrderData[iSelectedRow].transit_status,
                        iOrderTransitDetails: this.arrOrderData[iSelectedRow].transit_details
                    },
                    arrive_date: strArriveDate,
                    package_date: strPackageDate,
                    iServiceType: this.arrOrderData[iSelectedRow].service_type
                });
                break;
            }
            // 删除预报单
            case 2: {
                this.setState({
                    ...this.state,
                    iDlgType: iDlgType,
                    bDlgShow: true,
                });
                break;
            }
            // 批量处理
            case 3: {
                this.TransitDetailsUpdate(0);
                this.setState({
                    ...this.state,
                    bMultiEditDlg: true,
                    iMultiEditTransitStatus: 0,
                    iMultiEditTransitDetails: 0
                });
                break;
            }
            // 高光标记
            case 4: {
                let arrOrderID = [];
                for (let i = 0; i < this.arrSelectOrders.length; i++) {
                    arrOrderID.push(this.arrSelectOrders[i].prediction_code);
                }
                axios.post('/HighlightAutoOrder', {
                    arrOrderID: arrOrderID,
                    highlight: '#table_row_highlight',
                    store_id: this.props.objStoreInfo.id
                }).then(({ data }) => {
                    this.RefreshOrderTable(data);
                });
                break;
            }
            // 取消高光标记
            case 5: {
                let arrOrderID = [];
                for (let i = 0; i < this.arrSelectOrders.length; i++) {
                    arrOrderID.push(this.arrSelectOrders[i].prediction_code);
                }
                axios.post('/HighlightAutoOrder', {
                    arrOrderID: arrOrderID,
                    highlight: '#table_row_white',
                    store_id: this.props.objStoreInfo.id
                }).then(({ data }) => {
                    this.RefreshOrderTable(data);
                });
                break;
            }
            default: break;
        }
    }
    // 获取弹窗中的更新数据
    GetNewInputData() {
        let objInputInfo = {};
        objInputInfo.type = this.state.dlgInfo.iOrderType;
        objInputInfo.service_type = this.state.iServiceType;
        objInputInfo.payment_status = this.state.dlgInfo.iOrderPaymentStatus;
        // objInputInfo.transit_status = this.state.dlgInfo.iOrderTransitStatus;
        // objInputInfo.transit_details = this.state.dlgInfo.iOrderTransitDetails;
        objInputInfo.arrive_date = this.state.arrive_date;
        objInputInfo.package_date = this.state.package_date;
        objInputInfo.weight = this.refs.weight.state.value;
        objInputInfo.item_num = this.refs.item_num.state.value;
        if (objInputInfo.weight === undefined || objInputInfo.weight === null || objInputInfo.weight === "")
            objInputInfo.weight = "";
        if (objInputInfo.item_num === undefined || objInputInfo.item_num === null || objInputInfo.item_num === "")
            objInputInfo.item_num = "";

        if (objInputInfo.arrive_date.indexOf('T'))
            objInputInfo.arrive_date = objInputInfo.arrive_date.split('T')[0];
        if (objInputInfo.package_date.indexOf('T'))
            objInputInfo.package_date = objInputInfo.package_date.split('T')[0];
        let strPredictionCode = "HYLK";

        if (this.refs.client !== undefined) {
            objInputInfo.client = this.refs.client.state.value;
            if (objInputInfo.client === undefined || objInputInfo.client === null || objInputInfo.client === "") {
                // message.warning("客户预报编码不能为空！");
                // return -1;
                objInputInfo.client = "";
            }
        }

        if (this.refs.order_charge) {
            objInputInfo.charge = this.refs.order_charge.state.value;
            objInputInfo.expense = this.refs.order_expense.state.value;
            objInputInfo.revenue = this.refs.order_revenue.state.value;
            objInputInfo.gst = this.refs.order_gst.state.value;

            if (objInputInfo.expense === null || objInputInfo.expense === "")
                objInputInfo.expense = 0.0;
            else {
                if (isNaN(objInputInfo.expense)) {
                    message.warning("请输入有效的支出数值！");
                    return -1;
                }
            }
            if (objInputInfo.revenue === null || objInputInfo.revenue === "")
                objInputInfo.revenue = 0.0;
            else {
                if (isNaN(objInputInfo.revenue)) {
                    message.warning("请输入有效的收入数值！");
                    return -1;
                }
            }
            objInputInfo.benefit = parseFloat(objInputInfo.revenue) - parseFloat(objInputInfo.expense);
            if (objInputInfo.gst === null || objInputInfo.gst === "")
                objInputInfo.gst = 0.0;
            else {
                if (isNaN(objInputInfo.gst)) {
                    message.warning("请输入有效的GST数值！");
                    return -1;
                }
            }
            objInputInfo.payee = this.refs.order_payee.state.value;
        }
        if (this.refs.order_from_where) {
            objInputInfo.from_where = this.refs.order_from_where.state.value;
            objInputInfo.to_where = this.refs.order_to_where.state.value;
            objInputInfo.item_info = this.refs.order_item_info.state.value;
            objInputInfo.terms = this.refs.order_terms.state.value;
            objInputInfo.closet_no = this.refs.order_closet_no.state.value;
            objInputInfo.cbm = this.refs.order_cbm.state.value;
            // if (this.props.objStoreInfo.id === 20000)
            //     objInputInfo.channel = this.refs.order_channel.state.value;
            // else
            objInputInfo.channel = "";

            objInputInfo.first_value_fee = this.refs.first_value_fee.state.value;
            objInputInfo.second_value_fee = this.refs.second_value_fee.state.value;
            objInputInfo.departure_fee = this.refs.departure_fee.state.value;
            objInputInfo.arrive_fee = this.refs.arrive_fee.state.value;
            objInputInfo.delivery_fee = this.refs.delivery_fee.state.value;
            objInputInfo.waiting_fee = this.refs.waiting_fee.state.value;

            objInputInfo.import_company = this.refs.import_company.state.value;
            objInputInfo.import_no = this.refs.import_no.state.value;
            objInputInfo.import_relevant = this.refs.import_relevant.state.value;

            objInputInfo.receiver = this.refs.receiver.state.value;
            objInputInfo.phone = this.refs.phone.state.value;
            objInputInfo.email = this.refs.email.state.value;
            objInputInfo.shipping_address = this.refs.shipping_address.state.value;

        }

        objInputInfo.prediction_code = strPredictionCode;
        if (this.state.iDlgType === 0) {
            objInputInfo.oid = objInputInfo.prediction_code;
            objInputInfo.highlight = "#table_row_white";
        }

        // 操作信息
        let strPackageInfo = "";
        // let strPackageInfo = this.refs.package_info_input.state.value;
        // if (!strPackageInfo || strPackageInfo === "") {
        //     strPackageInfo = "";
        // }
        let arrPackageInfo = [];
        if (strPackageInfo.indexOf('\n') >= 0) {
            let arrPackage = strPackageInfo.split('\n');
            for (let i = 0; i < arrPackage.length; i++) {
                if (arrPackage[i] !== "" && arrPackage[i] !== "")
                    arrPackageInfo.push(arrPackage[i]);
            }
        } else
            arrPackageInfo.push(strPackageInfo);
        if (arrPackageInfo.length <= 0)
            arrPackageInfo = "";
        objInputInfo.package_info = JSON.stringify(arrPackageInfo);

        return objInputInfo;
    }
    // 确认按钮响应
    ConfirmButtonClick = () => {
        this.setState({
            ...this.state,
            bLoading: true
        });
        if (this.state.bMultiEditDlg) {
            let arrOrderID = [];
            // let arrNewOrderID = [];
            for (let i = 0; i < this.arrSelectOrders.length; i++) {
                arrOrderID.push(this.arrSelectOrders[i].prediction_code);
            }
            let iStoreID = this.props.objStoreInfo.id;
            if (this.props.objStoreInfo.id === 10000 || this.props.objStoreInfo.id === 20000) {
                iStoreID = 10000;
            }
            message.loading({ content: '正在更新预报单信息……', key: g_strMessageKey });
            axios.post('/MultiEditAutoOrder', {
                arrOrderID: arrOrderID,
                arrNewOrderID: arrOrderID,
                type: this.state.dlgInfo.iOrderType,
                transit_status: this.state.iMultiEditTransitStatus,
                transit_details: this.state.iMultiEditTransitDetails,
                arrive_date: this.state.arrive_date,
                store_id: iStoreID
            }).then(({ data }) => {
                message.success({ content: '更新完成！', key: g_strMessageKey, duration: 2 });
                this.GetTargetOrders(-1);
            });
            return;
        }
        switch (this.state.iDlgType) {
            case 0: {
                let objNewOrder = this.GetNewInputData();
                if (objNewOrder === -1) {
                    this.setState({
                        ...this.state,
                        bLoading: false
                    });
                    return;
                }
                objNewOrder.store_id = 10000;
                objNewOrder.cf_status = 0;
                message.loading({ content: '正在生成新的预报单……', key: g_strMessageKey });
                axios.post('/AutoGenerateOrder', { objNewOrder: objNewOrder })
                    .then(({ data }) => {
                        if (data.status === 1) {
                            message.success({ content: '创建完成！', key: g_strMessageKey, duration: 2 });
                            this.GetTargetOrders(-1);
                        } else {
                            message.warning({ content: '创建失败！', key: g_strMessageKey, duration: 2 });
                        }
                    });
                break;
            }
            case 1: {
                // let strSplitType = this.strOrderTitle + "YB_" + this.arrOrderTypeShort[this.arrOrderData[this.state.selectedRowKeys[0]].type];
                // let strOgePrediction_code = this.arrOrderData[this.state.selectedRowKeys[0]].prediction_code.split(strSplitType)[1];
                // if (this.props.objStoreInfo.id === 20000)
                //     strOgePrediction_code = this.arrOrderData[this.state.selectedRowKeys[0]].prediction_code.split(this.strOrderTitle)[1];
                let objEditOrder = this.GetNewInputData();
                if (objEditOrder === -1) {
                    this.setState({
                        ...this.state,
                        bLoading: false
                    });
                    return;
                }
                // objEditOrder.store_id = this.props.objStoreInfo.id;
                objEditOrder.store_id = 10000;
                objEditOrder.cf_status = this.arrOrderData[this.state.selectedRowKeys[0]].cf_status;
                objEditOrder.id = this.arrOrderData[this.state.selectedRowKeys[0]].id;
                // objEditOrder.prediction_code += strOgePrediction_code;
                objEditOrder.prediction_code = this.refs.prediction_code.state.value;
                objEditOrder.prediction_code = this.refs.prediction_code.state.value;
                if (objEditOrder.cf_status !== 0 && objEditOrder.cf_status !== 1)
                    objEditOrder.cf_status = 0;

                message.loading({ content: '正在更新预报单信息……', key: g_strMessageKey });
                axios.post('/EditAutoOrder', { objEditOrder: objEditOrder/*, strOgePrediction_code: strOgePrediction_code*/ })
                    .then(({ data }) => {
                        if (data === -1) {
                            message.warning("更新失败，物流单号可能已经存在/输入合箱编码太多（不要超过100个），请检查！");
                            this.setState({
                                ...this.state,
                                bLoading: false
                            })
                            return;
                        }
                        message.success({ content: '更新完成！', key: g_strMessageKey, duration: 2 });
                        this.GetTargetOrders(-1);
                    });
                break;
            }
            case 2: {
                this.setState({
                    ...this.state,
                    bLoading: false,
                    bDlgShow: false
                    // iDlgType: 0
                });
                for (let i = 0; i < this.arrSelectOrders.length; i++) {
                    this.arrDeleteOrders.push(this.arrSelectOrders[i].id);
                }
                console.log("需要删除的预报单：", this.arrDeleteOrders);
                message.loading({ content: '删除预报单中……', key: g_strMessageKey });
                axios.post('/DeleteAutoOrder', {
                    arrDeleteOrders: this.arrDeleteOrders
                }).then(({ data }) => {
                    console.log(data);
                    message.success({ content: '删除完成！', key: g_strMessageKey, duration: 2 });
                    this.GetTargetOrders(-1);
                });
                break;
            }
            default: break;
        }
    }
    // 取消按钮响应
    CancelButtonClick = () => {
        this.bMultiEdit = false;
        this.setState({
            ...this.state,
            iDlgType: 0,
            bDlgShow: false,
            bMultiEditDlg: false,
            btnControlDisable: [false, true, true, true],
            selectedRowKeys: []
        });
    }
    // 打开关联的海关文件
    OpenCustomFile = (iType, strOrderNo) => {
        if (iType === 0) {
        }
        if (iType === 1) {

        }
        this.props.AutoOrderToCustomFile(iType, strOrderNo);
    }
    // 打开关联的发票文件
    OpenInvoiceFile = (iType, strOrderNo) => {
        if (iType === 0) {
        }
        if (iType === 1) {

        }
        this.props.AutoOrderToInvoiceFile(iType, strOrderNo);
    }
    // 刷新表格中的数据
    RefreshOrderTable(data) {
        this.arrTableData = [];
        for (let i = 0; i < data.length; i++) {
            if (data[i].expense === null)
                data[i].expense = 0;
            else
                data[i].expense = (parseFloat(data[i].expense) / 100).toFixed(2);
            if (data[i].revenue === null)
                data[i].revenue = 0;
            else
                data[i].revenue = (parseFloat(data[i].revenue) / 100).toFixed(2);
            if (data[i].benefit === null)
                data[i].benefit = 0;
            else
                data[i].benefit = (parseFloat(data[i].benefit) / 100).toFixed(2);
            if (data[i].gst === null)
                data[i].gst = 0;
            else
                data[i].gst = (parseFloat(data[i].gst) / 100).toFixed(2);
            let uiCFStatus = null;
            if (data[i].cf_status === null || data[i].cf_status === 0)
                uiCFStatus = (
                    <div className="custom_file_link" onClick={this.OpenCustomFile.bind(this, 0, data[i].prediction_code)}>
                        创建</div>
                );
            else
                uiCFStatus = (
                    <div className="custom_file_link" onClick={this.OpenCustomFile.bind(this, 1, data[i].prediction_code)}>
                        查看</div>
                );
            let uiInvoiceStatus = null;
            if (data[i].invoice_status === null || data[i].invoice_status === 0)
                uiInvoiceStatus = (
                    <div className="custom_file_link" onClick={this.OpenInvoiceFile.bind(this, 0, data[i].prediction_code)}>
                        创建</div>
                );
            else
                uiInvoiceStatus = (
                    <div className="custom_file_link" onClick={this.OpenInvoiceFile.bind(this, 1, data[i].prediction_code)}>
                        查看</div>
                );
            let strArriveDate = data[i].arrive_date;
            if (strArriveDate === "" || strArriveDate === null || strArriveDate.isNaN) {
                strArriveDate = "";
            } else {
                if (strArriveDate.indexOf('T'))
                    strArriveDate = strArriveDate.split('T')[0];
            }
            let strPackageDate = data[i].package_date;
            if (strPackageDate === "" || strPackageDate === null || strPackageDate.isNaN) {
                strPackageDate = "";
            } else {
                if (strPackageDate.indexOf('T'))
                    strPackageDate = strPackageDate.split('T')[0];
            }
            let strTransitDetails = "";
            let iTransitStatusIndex = data[i].transit_status - 1;
            if (data[i].transit_status !== null && iTransitStatusIndex >= 0) {
                if (this.arrTransitList[iTransitStatusIndex]) {
                    if (this.arrTransitList[iTransitStatusIndex].sub_cn_name !== null) {
                        if (this.arrTransitList[iTransitStatusIndex].sub_cn_name.trim() !== "") {
                            let arrTransitDetails = this.arrTransitList[iTransitStatusIndex].sub_cn_name.split(',');
                            strTransitDetails = arrTransitDetails[data[i].transit_details];
                        }
                    }
                }
            }
            let strColor = data[i].highlight;
            if (data[i].highlight === "" || data[i].highlight === null)
                strColor = "#table_row_white";
            let iFileNum = 0;
            if (data[i].s3_link !== null && data[i].s3_link !== '') {
                iFileNum = 1;
                if (data[i].s3_link.indexOf(',') >= 0)
                    iFileNum = data[i].s3_link.split(',').length;
            }
            let strOID = "暂无";
            if (data[i].oid.indexOf('HYLK') < 0) {
                strOID = data[i].oid;
            }
            let objOrderData = {
                key: i, id: data[i].id, store_id: data[i].store_id,
                oid: data[i].oid,
                oid_str: strOID,
                prediction_code: data[i].prediction_code,
                client: data[i].client,
                color: strColor,
                type: data[i].type,
                type_str: this.arrOrderType[data[i].type],
                service_type: this.arrServiceType[data[i].service_type],
                channel: data[i].channel,
                payment_status: this.arrOrderPaymentStatus[data[i].payment_status],
                transit_status: this.arrOrderTransitStatus[data[i].transit_status],
                transit_details: strTransitDetails,
                arrive_date: strArriveDate,
                package_date: strPackageDate,
                expense: data[i].expense,
                revenue: data[i].revenue,
                benefit: data[i].benefit,
                gst: data[i].gst,
                closet_no: data[i].closet_no,
                charge: data[i].charge,
                from_where: data[i].from_where,
                to_where: data[i].to_where,
                item_info: data[i].item_info,
                terms: data[i].terms,
                cf_status: data[i].cf_status,
                cf_button: uiCFStatus,
                invoice_status: data[i].invoice_status,
                invoice_button: uiInvoiceStatus,
                s3_link: data[i].expense.s3_link,
                s3_link_sign: iFileNum + "个文件",
                generate_time: data[i].generate_time.split('T')[0],

                first_value_fee: data[i].first_value_fee,
                second_value_fee: data[i].second_value_fee,
                departure_fee: data[i].departure_fee,
                arrive_fee: data[i].arrive_fee,
                delivery_fee: data[i].delivery_fee,
                waiting_fee: data[i].waiting_fee,

                import_company: data[i].import_company,
                import_no: data[i].import_no,
                import_relevant: data[i].import_relevant,

                receiver: data[i].receiver,
                phone: data[i].phone,
                email: data[i].email,
                shipping_address: data[i].shipping_address

            }
            if (objOrderData.prediction_code !== undefined && objOrderData.prediction_code !== null && objOrderData.prediction_code !== "") {
                if (objOrderData.prediction_code.indexOf('HYLK') >= 0)
                    this.arrTableData.push(objOrderData);
            }

        }
        this.arrOrderData = data;
        if (this.state.iDlgType === 2) {
            this.setState({
                ...this.state,
                btnControlDisable: [false, true, true, true],
                selectedRowKeys: [],
                iDlgType: 2,
                bDlgShow: false,
                bLoading: false,
                filterInfo: { iOrderType: 0, iOrderPaymentStatus: 0 },
                bMultiEditDlg: false
            });
        } else {
            this.setState({
                ...this.state,
                btnControlDisable: [false, true, true, true],
                selectedRowKeys: [],
                iDlgType: 0,
                bDlgShow: false,
                bLoading: false,
                filterInfo: { iOrderType: 0, iOrderPaymentStatus: 0 },
                bMultiEditDlg: false
            });
        }
    }
    // 从数据库获取当前的数据结构
    GetOrdersData = () => {
        message.loading({ content: '加载数据中……', key: g_strMessageKey });
        axios('/GetTransitStatus', { params: { store_id: this.props.objStoreInfo.id } }).then(({ data }) => {
            if (data.length > 0) {
                this.arrTransitList = data;
                this.arrOrderTransitStatus = ["未发货"];
                for (let i = 0; i < data.length; i++) {
                    this.arrOrderTransitStatus.push(data[i].cn_name + " | " + data[i].en_name);
                }
            }
            // 初始化显示全部预报单
            this.iIDOrder = 0;
            this.iArriveOrder = 1;
            this.GetTargetOrders(-1);
        }).catch(function (error) { console.log(error); });
    }
    // 显示全部预报单
    ShowAllOrders = () => {
        this.dateCurrent = "";
        this.dateArrive = ["", ""];
        this.iOrderType = 0;
        this.iServiceType = 0;
        this.iPaymentStatus = 0;
        this.strKeyWord = "";
        this.strClientSearch = ""
        this.refs.order_search.state.value = "";
        this.refs.client_filter.state.value = "";
        this.setState({
            ...this.state,
            bFilterStatusChange: true
        });
        this.GetTargetOrders(-1);
    }
    // 获取指定范围预报单
    GetTargetOrders = (iOrder) => {
        // let iIDOrder = 0;
        // let iArriveOrder = 0;
        // if (iOrder === -1) {
        //     this.iHightLightOrder = -1;
        //     iIDOrder = this.iIDOrder;
        //     if (this.iIDOrder === 0)
        //         iArriveOrder = this.iArriveOrder;
        // }
        // if (iOrder === 0) {
        //     if (this.iIDOrder === 0)
        //         this.iIDOrder = 1;
        //     this.iIDOrder *= -1;
        //     iIDOrder = this.iIDOrder;
        // }
        // if (iOrder === 1) {
        //     this.iArriveOrder *= -1;
        //     iArriveOrder = this.iArriveOrder;
        // }
        // if (iOrder === 2) {
        //     this.iHightLightOrder *= -1;
        //     iIDOrder = this.iIDOrder;
        // }

        if (this.strClientSearch === undefined || this.strClientSearch === null)
            this.strClientSearch = "";

        if (this.props.strTargetOrderNo !== null && this.props.strTargetOrderNo !== "") {
            this.strKeyWord = this.props.strTargetOrderNo;
            this.refs.order_search.state.value = this.strKeyWord;
            this.props.TargetOrderNoReset();
        }

        this.setState({
            ...this.state,
            bFilterStatusChange: true
        });
        let iStoreID = this.props.objStoreInfo.id;
        if (this.props.objStoreInfo.id === 10000 || this.props.objStoreInfo.id === 20000)
            iStoreID = 10000;
        axios('/GetPredictionOrders', {
            params: {
                store_id: parseInt(iStoreID, 10),

                // id_order: iIDOrder,
                // arrive_order: iArriveOrder,
                // highlight: this.iHightLightOrder,

                // order_date: this.dateCurrent,
                // arrive_date: this.dateArrive,
                order_type: this.iOrderType - 1,
                service_type: this.iServiceType - 1,
                // payment_status: this.iPaymentStatus - 1,

                strKeyWord: this.strKeyWord,
                strClientSearch: this.strClientSearch
            }
        }).then(({ data }) => {
            message.success({ content: "数据加载完成！", key: g_strMessageKey, duration: 2 });
            this.RefreshOrderTable(data);
        }).catch(function (error) { console.log(error); });
    }
    // 更新筛选出来的预报单信息
    UpdateOrderData = (i) => {
        let strDate = this.arrOrderData[i].arrive_date;
        if (strDate === "" || strDate === null || strDate.isNaN) {
            strDate = "";
        } else {
            if (strDate.indexOf('T'))
                strDate = strDate.split('T')[0];
        }
        let strTransitDetails = "";
        let iTransitStatusIndex = this.arrOrderData[i].transit_status - 1;
        if (this.arrOrderData[i].transit_status !== null && iTransitStatusIndex >= 0) {
            if (this.arrTransitList[iTransitStatusIndex]) {
                if (this.arrTransitList[iTransitStatusIndex].sub_cn_name !== null) {
                    if (this.arrTransitList[iTransitStatusIndex].sub_cn_name.trim() !== "") {
                        let arrTransitDetails = this.arrTransitList[iTransitStatusIndex].sub_cn_name.split(',');
                        strTransitDetails = arrTransitDetails[this.arrOrderData[i].transit_details];
                    }
                }
            }
        }
        let strColor = this.arrOrderData[i].highlight;
        if (strColor === "" || strColor === null)
            strColor = "#table_row_white";
        let objOrderData = {
            key: i, prediction_code: this.arrOrderData[i].prediction_code,
            color: strColor,
            type: this.arrOrderType[this.arrOrderData[i].type],
            channel: this.arrOrderData[i].channel,
            payment_status: this.arrOrderPaymentStatus[this.arrOrderData[i].payment_status],
            transit_status: this.arrOrderTransitStatus[this.arrOrderData[i].transit_status],
            transit_details: strTransitDetails,
            arrive_date: this.arrOrderData[i].arrive_date,
            package_date: this.arrOrderData[i].package_date,
            expense: this.arrOrderData[i].expense,
            revenue: this.arrOrderData[i].revenue,
            benefit: this.arrOrderData[i].benefit,
            gst: this.arrOrderData[i].gst,
            closet_no: this.arrOrderData[i].closet_no,
            charge: this.arrOrderData[i].charge,
            terms: this.arrOrderData[i].terms,
            google_drive: "",
            generate_time: this.arrOrderData[i].generate_time.split('T')[0],
            client: this.arrOrderData[i].client
        }
        return objOrderData;
    }
    // 货运类型下拉菜单点击
    OrderTypeClicked = (index) => {
        // 对话框过滤器
        if (this.state.bDlgShow || this.state.bMultiEditDlg) {
            this.setState({
                ...this.state,
                dlgInfo: {
                    iOrderPaymentStatus: this.state.dlgInfo.iOrderPaymentStatus,
                    iOrderTransitStatus: this.state.dlgInfo.iOrderTransitStatus,
                    iOrderType: index
                }
            });
        }
        // 标题栏过滤器
        else {
            // this.arrTableData = [];
            // if (index === 0) {
            //     for (let i = 0; i < this.arrOrderData.length; i++) {
            //         if ((this.state.filterInfo.iOrderPaymentStatus !== 0 && this.arrOrderData[i].payment_status === this.state.filterInfo.iOrderPaymentStatus - 1) ||
            //             this.state.filterInfo.iOrderPaymentStatus === 0) {
            //             let objOrderData = this.UpdateOrderData(i);
            //             this.arrTableData.push(objOrderData);
            //         }
            //     }
            // }
            // else {
            //     index--;
            //     for (let i = 0; i < this.arrOrderData.length; i++) {
            //         if (this.arrOrderData[i].type === index) {
            //             if ((this.state.filterInfo.iOrderPaymentStatus !== 0 && this.arrOrderData[i].payment_status === this.state.filterInfo.iOrderPaymentStatus - 1) ||
            //                 this.state.filterInfo.iOrderPaymentStatus === 0) {
            //                 let objOrderData = this.UpdateOrderData(i);
            //                 this.arrTableData.push(objOrderData);
            //             }
            //         }
            //     }
            //     index++;
            // }
            // this.setState({
            //     ...this.state,
            //     filterInfo: {
            //         iOrderPaymentStatus: this.state.filterInfo.iOrderPaymentStatus,
            //         iOrderType: index
            //     }
            // });
            this.iOrderType = index;
            this.FilterConditionChanged();
            this.setState({
                ...this.state,
                bFilterStatusChange: true
            });
        }
    }
    // 服务类型下拉菜单点击
    ServiceTypeClicked = (index) => {
        // 对话框过滤器
        if (this.state.bDlgShow || this.state.bMultiEditDlg) {
            this.setState({
                ...this.state,
                iServiceType: index
            });
        }
        // 标题栏过滤器
        else {
            this.iServiceType = index;
            this.FilterConditionChanged();
            this.setState({
                ...this.state,
                iServiceTypeFilter: index
            });
        }
    }
    // 支付状态下拉菜单点击
    OrderPaymentStatusClicked = (index) => {
        // 对话框
        if (this.state.bDlgShow) {
            this.setState({
                ...this.state,
                dlgInfo: {
                    iOrderPaymentStatus: index,
                    iOrderTransitStatus: this.state.dlgInfo.iOrderTransitStatus,
                    iOrderTransitDetails: this.state.dlgInfo.iOrderTransitDetails,
                    iOrderType: this.state.dlgInfo.iOrderType
                }
            });
        }
        // 标题栏
        else {
            // this.arrTableData = [];
            // if (index === 0) {
            //     for (let i = 0; i < this.arrOrderData.length; i++) {
            //         if ((this.state.filterInfo.iOrderType !== 0 && this.arrOrderData[i].type === this.state.filterInfo.iOrderType - 1) ||
            //             this.state.filterInfo.iOrderType === 0) {
            //             let objOrderData = this.UpdateOrderData(i);
            //             this.arrTableData.push(objOrderData);
            //         }
            //     }
            // }
            // else {
            //     index--;
            //     for (let i = 0; i < this.arrOrderData.length; i++) {
            //         if (this.arrOrderData[i].payment_status === index) {
            //             if ((this.state.filterInfo.iOrderType !== 0 && this.arrOrderData[i].type === this.state.filterInfo.iOrderType - 1) ||
            //                 this.state.filterInfo.iOrderType === 0) {
            //                 let objOrderData = this.UpdateOrderData(i);
            //                 this.arrTableData.push(objOrderData);
            //             }
            //         }
            //     }
            //     index++;
            // }
            // this.setState({
            //     ...this.state,
            //     filterInfo: {
            //         iOrderPaymentStatus: index,
            //         iOrderType: this.state.filterInfo.iOrderType
            //     }
            // });
            this.iPaymentStatus = index;
            this.FilterConditionChanged();
            this.setState({
                ...this.state,
                bFilterStatusChange: true
            });
        }
    }
    // 物流状态下拉菜单点击
    OrderTransitStatusClicked = (index) => {
        if (this.state.bDlgShow) {
            this.TransitDetailsUpdate(index);
            this.setState({
                ...this.state,
                dlgInfo: {
                    iOrderPaymentStatus: this.state.dlgInfo.iOrderPaymentStatus,
                    iOrderTransitStatus: index,
                    iOrderTransitDetails: 0,
                    iOrderType: this.state.dlgInfo.iOrderType
                }
            });
        }
        if (this.state.bMultiEditDlg) {
            this.TransitDetailsUpdate(index);
            this.setState({
                ...this.state,
                iMultiEditTransitStatus: index,
                iMultiEditTransitDetails: 0
            });
        }
    }
    // 切换物流状态时，物流状态描述的更新
    TransitDetailsUpdate = (index) => {
        this.arrOrderTransitDetails = [];
        if (index >= 1) {
            if (this.arrTransitList[index - 1].sub_cn_name !== null) {
                if (this.arrTransitList[index - 1].sub_cn_name.trim() !== "") {
                    let arrTransitCN = this.arrTransitList[index - 1].sub_cn_name.split(',');
                    let arrTransitEN = this.arrTransitList[index - 1].sub_en_name.split(',');
                    for (let i = 0; i < arrTransitCN.length; i++) {
                        this.arrOrderTransitDetails.push(arrTransitCN[i] + " | " + arrTransitEN[i]);
                    }
                }
            }
        }
    }
    // 状态描述下拉菜单点击
    OrderTransitDetailsClicked = (index) => {
        if (this.state.bDlgShow) {
            this.setState({
                ...this.state,
                dlgInfo: {
                    iOrderPaymentStatus: this.state.dlgInfo.iOrderPaymentStatus,
                    iOrderTransitStatus: this.state.dlgInfo.iOrderTransitStatus,
                    iOrderTransitDetails: index,
                    iOrderType: this.state.dlgInfo.iOrderType
                }
            });
        }
        if (this.state.bMultiEditDlg) {
            this.setState({
                ...this.state,
                iMultiEditTransitDetails: index
            });
        }
    }
    // 搜索预报单
    SearchOrder = () => {
        let strKeyWord = this.refs.order_search.state.value;
        if (strKeyWord.trim() === "") {
            message.warning('搜索内容不能为空！');
            return;
        }
        this.strKeyWord = strKeyWord;
        this.strClientSearch = this.refs.client_filter.state.value;
        if (this.strClientSearch === undefined || this.strClientSearch === null) {
            this.strClientSearch = "";
        }
        this.FilterConditionChanged();
    }
    // 去除重复预报单
    RemoveRepeatOrder = (data) => {
        let arrOrders = [];
        for (let i = 0; i < data.length; i++) {
            if (arrOrders.length > 0) {
                let bRepeat = false;
                for (let j = 0; j < arrOrders.length; j++) {
                    if (arrOrders[j].id === data[i].id) {
                        bRepeat = true;
                        break;
                    }
                }
                if (!bRepeat)
                    arrOrders.push(data[i]);
            } else {
                arrOrders.push(data[i]);
            }
        }
        console.log(arrOrders);
        return arrOrders;
    }
    // 月份变化
    MonthPickerChange = (date, dateString) => {
        this.dateCurrent = dateString;
        this.FilterConditionChanged();
        this.setState({
            ...this.state,
            bFilterStatusChange: true
        });
        // this.GetOrderList(this.state.iDateDesc, 0, 0, this.state.iHighlightFilter);
    }
    // 预计到达日期变化
    ArriveDateRangeChange = (dates, dateStrings) => {
        this.dateArrive = dateStrings;
        this.FilterConditionChanged();
        this.setState({
            ...this.state,
            bFilterStatusChange: true
        });
    }
    // 表格翻页
    TablePageChange = (page, pageSize) => {
        // console.log("TablePageChange : ", page, pageSize);
        this.setState({
            ...this.state,
            iCurrentPage: page
        });
    }
    // 选择单个文件按钮响应
    SelectSingleFile = event => {
        this.setState({
            ...this.state,
            selectedFile: event.target.files[0]
        });
        console.log(event.target.files[0]);
        // 获取上传的文件对象
        // const { files } = file.target;
        // console.log("files : ", files);
        // console.log("file = ", file);
    }
    // 选择多个文件按钮响应
    SelectMultipleFile = event => {
        this.arrFileWaitList = [];
        for (let i = 0; i < event.target.files.length; i++)
            this.arrFileWaitList.push(event.target.files[i].name);
        if (event.target.files.length > 20) {
            message.warning("一次最多上传20个文件!请重新选择");
            return;
        }
        this.setState({
            ...this.state,
            selectedFiles: event.target.files
        });
    }
    // 单个文件上传按钮响应
    singleFileUploadHandler = () => {
        const data = new FormData();
        // If file selected
        if (this.state.selectedFile) {
            data.append('profileImage', this.state.selectedFile, this.state.selectedFile.name);
            console.log("文件数据整理完成 : ", data);
            axios.post('/aws-profile-upload', data, {
                headers: {
                    'accept': 'application/json',
                    'Accept-Language': 'en-US,en;q=0.8',
                    'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
                    store_id: this.props.iStoreID
                }
            }).then((response) => {
                if (200 === response.status) {
                    // If file size is larger than expected.
                    if (response.data.error) {
                        if ('LIMIT_FILE_SIZE' === response.data.error.code) {
                            message.warning("文件大小不能超过2Mb!");
                        } else {
                            console.log(response.data);
                            message.error("文件类型不正确！");
                        }
                    } else {
                        // Success
                        let fileName = response.data;
                        console.log('fileName', fileName);
                    }
                }
            }).catch((error) => {
                // If another error
                console.log(error);
                message.warning(error);
            });
        } else {
            // if file not selected throw error
            message.warning("请选择需要上传的文件！");
        }
    };
    // 多个文件上传按钮响应
    multipleFileUploadHandler = () => {
        let data = new FormData();
        let selectedFiles = this.state.selectedFiles;
        // If file selected
        if (selectedFiles) {
            for (let i = 0; i < selectedFiles.length; i++) {
                data.append('galleryImage', selectedFiles[i], selectedFiles[i].name);
            }
            message.loading({ content: '文件上传中……', key: g_strMessageKey });
            axios.post('/aws-multiple-file-upload', data, {
                headers: {
                    'accept': 'application/json',
                    'Accept-Language': 'en-US,en;q=0.8',
                    'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
                    action: 1,
                    store_id: this.props.objStoreInfo.id,
                    prediction_code: this.arrOrderData[this.state.selectedRowKeys[0]].prediction_code
                }
            }).then((response) => {
                // console.log('res', response);
                if (200 === response.status) {
                    // If file size is larger than expected.
                    if (response.data.error) {
                        if ('LIMIT_FILE_SIZE' === response.data.error.code) {
                            message.warning("单个文件大小不能超过2Mb!");
                        } else if ('LIMIT_UNEXPECTED_FILE' === response.data.error.code) {
                            message.warning("最多同时选择20个文件!");
                        } else {
                            // If not the given file type
                            console.log(response.data.error);
                            message.error("文件类型不正确！");
                        }
                    } else {
                        // Success
                        let fileName = response.data;
                        message.success({ content: "文件上传成功！", key: g_strMessageKey, duration: 2 });
                        let strNewFileList = "";
                        for (let i = 0; i < fileName.locationArray.length; i++) {
                            strNewFileList += fileName.locationArray[i]
                            if (i !== fileName.locationArray.length - 1)
                                strNewFileList += ",";
                        }
                        if (this.arrOrderData[this.state.selectedRowKeys[0]].s3_link !== null && this.arrOrderData[this.state.selectedRowKeys[0]].s3_link !== "")
                            this.arrOrderData[this.state.selectedRowKeys[0]].s3_link = strNewFileList + "," + this.arrOrderData[this.state.selectedRowKeys[0]].s3_link;
                        else
                            this.arrOrderData[this.state.selectedRowKeys[0]].s3_link = strNewFileList;
                        this.arrFileWaitList = [];
                        if (this.refs.file_upload) {
                            document.getElementById("file_upload").value = "";
                        }
                        this.setState({
                            ...this.state,
                            selectedFile: null,
                            selectedFiles: null
                        })
                    }
                }
            }).catch((error) => {
                // If another error
                console.log(error);
                message.warning(error);
            });
        } else {
            // if file not selected throw error
            message.warning("请选择需要上传的文件！");
        }
    };
    // 下载文件
    DownloadFile = (index) => {
        window.open("https://mouthingmanager.s3.amazonaws.com/" + this.arrOrderData[this.state.selectedRowKeys[0]].s3_link.split(',')[index]);
    }
    // 删除文件
    DeleteFile = (index) => {
        if (this.bDeletingFile)
            return;
        this.bDeletingFile = true;
        this.setState({
            ...this.state,
            bFileDelete: true
        })
        let strTargetS3Link = this.arrOrderData[this.state.selectedRowKeys[0]].s3_link;
        if (this.arrOrderData[this.state.selectedRowKeys[0]].s3_link.indexOf(',') >= 0) {
            strTargetS3Link = this.arrOrderData[this.state.selectedRowKeys[0]].s3_link.split(',')[index];
        }
        axios.post('/aws-profile-delete', {
            store_id: this.props.objStoreInfo.id,
            action: 1,
            prediction_code: this.arrOrderData[this.state.selectedRowKeys[0]].prediction_code,
            s3_link: strTargetS3Link,
            index: index
        }).then(({ data }) => {
            // DataStatistic
            UserActiveUpdate(6, { active: "商家后台: 预报单管理操作", action: "删除发票文件" });
            message.success({ content: '文件删除完成！', key: g_strMessageKey, duration: 2 });
            this.arrOrderData[this.state.selectedRowKeys[0]].s3_link = data;
            this.bDeletingFile = false;
            this.setState({
                ...this.state,
                bFileDelete: false
            })
        });
    }
    // 筛选条件变化
    FilterConditionChanged = () => {
        this.GetTargetOrders(-1);
    }
    // 获取用户数据
    GetClientsData() {
        console.log("GetClients : ", this.props.objStoreInfo);
        axios('/GetClients', { params: { store_id: this.props.objStoreInfo.id } })
            .then(({ data }) => {
                console.log("GetClients", data);
                global.arrClientData = data;
            }).catch(function (error) { console.log(error); });
    }
    // 选择用户
    SelectClient = () => {
        if (this.state.bDlgShow)
            this.setState({
                ...this.state,
                bSelectClientDlg: true
            });
        else {
            console.log("标题客户过滤器")
            this.setState({
                ...this.state,
                bSelectClientFilterDlg: true
            });
        }
    }
    // 选择用户弹窗结果
    ClientSelectResult = (index) => {
        if (this.state.bDlgShow) {
            if (index >= 0) {
                this.refs.client.state.value = global.arrClientData[index].name;
            }
        } else {
            if (index >= 0) {
                this.refs.client_filter.state.value = global.arrClientData[index].name;
            }
        }
        this.setState({
            ...this.state,
            bSelectClientDlg: false,
            bSelectClientFilterDlg: false
        })
    }
    // 检查预报单是否符合要求
    PredictionOrderCheck = () => {
        let bCheck = true;
        for (let i = 0; i < this.arrSelectOrders.length; i++) {
            if (this.arrSelectOrders[i].oid !== undefined && this.arrSelectOrders[i].oid !== null && this.arrSelectOrders[i].oid !== "") {
                // 存在已经正式出单的预报单
                if (this.arrSelectOrders[i].oid.indexOf('HYLK') < 0) {
                    bCheck = false; break;
                }
            }
        }
        return bCheck;
    }
    // 正式出单
    TurnToAutoOrder = () => {
        console.log("正式出单");
        console.log(this.arrSelectOrders);
        if (this.PredictionOrderCheck()) {
            message.loading({ content: '正在更新预报单信息……', key: g_strMessageKey });
            this.PostAutoOrder(this.arrSelectOrders.length - 1);
        } else {
            message.warning("选中的预报单中有的已经正式出单，无法重复出单，请检查！");
        }
    }
    // 提交出单请求
    PostAutoOrder = (index) => {
        // 已经出单
        if (this.arrSelectOrders[index].oid.indexOf('HYLK') < 0) {
            index--;
            if (index < 0) {
                console.log("出单完成！");
                this.GetTargetOrders(-1);
            } else {
                this.PostAutoOrder(index);
            }
        }
        // 还未出单
        else {
            let strOID = this.strOrderTitle + this.arrOrderTypeShort[this.arrSelectOrders[index].type];
            let objOrder = { id: this.arrSelectOrders[index].id, store_id: this.arrSelectOrders[index].store_id, oid: strOID }
            axios.post('/PredictionToAutoOrder', { objOrder: objOrder })
                .then(({ data }) => {
                    if (data === -1) {
                        message.warning({ content: '出单失败，请重新尝试！', key: g_strMessageKey, duration: 2 });
                    } else {
                        message.success({ content: '出单成功，可在出单系统查看！', key: g_strMessageKey, duration: 2 });
                    }
                    index--;
                    if (index < 0) {
                        console.log("出单完成！");
                        this.GetTargetOrders(-1);
                    } else {
                        this.PostAutoOrder(index);
                    }
                });
        }
    }
    // 跳转到绑定的正式单
    JumpToAutoOrder = () => {
        this.props.AutoOrderAndPrediction(0, this.objCurrentOrder.oid);
    }

    /* React自带函数 */
    // 卸载异步操作设置状态
    componentWillUnmount() {
        clearTimeout(this.timeouter)
        this.setState = (state, callback) => {
            return;
        }
    }
    // 渲染完毕……
    componentDidMount() {
        document.title = "物流管理系统";
        this.GetOrdersData();
        this.FilterConditionChanged();
        if (global.arrClientData.length <= 0)
            this.GetClientsData();
    }

    render() {

        // 主体表格相关
        const { selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectedRowKeysChange,
            columnWidth: 50
        };
        // 创建对话框
        let objOrder = {
            key: 0, oid: "", prediction_code: "", type: 0, channel: "", status: 0, charge: "", item_info: "", terms: "", from_where: "", to_where: "",
            expense: 0, revenue: 0, benefit: 0, gst: 0, closet_no: "", tracking_details: "", arrive_date: "",
            google_drive: "", generate_time: "", payee: "", cbm: 0
            // , update_time: ""
        }
        let strDlgTitle = "";
        let bPrediction_codeDisable = false;
        if (this.state.iDlgType === 0) {
            strDlgTitle = "预报单号";
            bPrediction_codeDisable = true;
        }
        if (this.state.iDlgType === 1) {
            objOrder = this.arrOrderData[this.state.selectedRowKeys[0]];
            strDlgTitle = "预报单号";
        }
        if (this.state.iDlgType === 2) {
            objOrder = this.arrOrderData[this.state.selectedRowKeys[0]];
            strDlgTitle = "删除预报单";
        } else {
            if (objOrder.client === null)
                objOrder.client = "";
            if (objOrder.service_type === null)
                objOrder.service_type = 0;
        }

        let uiDlgTitle = null;
        if (this.state.bDlgShow) {
            let strTip = "提交后自动生成";
            let bDisable = true;
            if (this.state.iDlgType === 1) {
                bDisable = false;
                strTip = "";
            }
            uiDlgTitle = (
                <div className="auto_order_dlg_title">
                    {strDlgTitle}
                    <Input className="order_detail_input" ref="prediction_code" defaultValue={objOrder.prediction_code} style={{ marginLeft: '10px' }}
                        placeholder={strTip} disabled={bPrediction_codeDisable} />
                    <div className="auto_order_dlg_title_client_info">客户</div>
                    <Input className="order_detail_input" ref="client" defaultValue={objOrder.client} style={{ marginRight: '10px' }} />
                    <Button type='primary' onClick={this.SelectClient.bind(this)}>选择</Button>
                    <Button type='primary' onClick={this.TurnToAutoOrder.bind(this)} style={{ marginLeft: 'auto', marginRight: '50px' }} disabled={bDisable}>
                        正式出单</Button>
                </div >
            );
        }
        let arrOrderType = this.arrOrderType;
        let arrOrderPaymentStatus = this.arrOrderPaymentStatus;
        let arrOrderTransitStatus = this.arrOrderTransitStatus;
        let arrOrderTransitDetails = this.arrOrderTransitDetails;
        if (!this.state.bDlgShow && !this.state.bMultiEditDlg) {
            arrOrderType = this.arrFilterOrderType;
            arrOrderPaymentStatus = this.arrFilterPaymentStatus;
            arrOrderTransitStatus = this.arrFilterTransitStatus;
        }
        let uiOrderTypeMenu = (
            <Menu>
                {arrOrderType.map((item, index) => {
                    return (
                        <Menu.Item key={index} onClick={this.OrderTypeClicked.bind(this, index)}>
                            {item}
                        </Menu.Item>
                    );
                })}
            </Menu>
        );
        let uiServiceTypeMenu = (
            <Menu>
                {this.arrServiceType.map((service, index) => {
                    return (
                        <Menu.Item key={index} onClick={this.ServiceTypeClicked.bind(this, index)}>
                            {service}
                        </Menu.Item>
                    );
                })}
            </Menu>
        );
        let uiServiceTypeFilterMenu = (
            <Menu>
                {this.arrServiceTypeFilter.map((service, index) => {
                    return (
                        <Menu.Item key={index} onClick={this.ServiceTypeClicked.bind(this, index)}>
                            {service}
                        </Menu.Item>
                    );
                })}
            </Menu>
        );
        let uiOrderPaymentStatusMenu = (
            <Menu>
                {arrOrderPaymentStatus.map((item, index) => {
                    return (
                        <Menu.Item key={index} onClick={this.OrderPaymentStatusClicked.bind(this, index)}>
                            {item}
                        </Menu.Item>
                    );
                })}
            </Menu>
        );
        let uiOrderTransitStatusMenu = (
            <Menu>
                {arrOrderTransitStatus.map((item, index) => {
                    return (
                        <Menu.Item key={index} onClick={this.OrderTransitStatusClicked.bind(this, index)}>
                            {item}
                        </Menu.Item>
                    );
                })}
            </Menu>
        );
        let uiOrderTransitDetailsMenu = (<div />);
        let bMenuDisable = true;
        if (arrOrderTransitDetails.length > 0) {
            bMenuDisable = false;
            uiOrderTransitDetailsMenu = (
                <Menu>
                    {arrOrderTransitDetails.map((item, index) => {
                        return (
                            <Menu.Item key={index} onClick={this.OrderTransitDetailsClicked.bind(this, index)}>
                                {item}
                            </Menu.Item>
                        );
                    })}
                </Menu>
            );
        }
        let uiTypeChannelTitle = (
            <div className="order_detail_title">类型：</div>
        );
        let uiTypeChannel = (
            <Dropdown className="order_detail_dropdown" trigger={['click']}
                overlay={uiOrderTypeMenu} placement="bottomLeft">
                <Button>{this.arrOrderType[this.state.dlgInfo.iOrderType]}<Icon type="caret-down" /></Button>
            </Dropdown>
        );
        // const dateFormat1 = 'YYYY-MM';
        const dateFormat2 = 'YYYY-MM-DD';
        // let uiAccounting = null;
        if (this.props.iUserLevel >= 7) {
            // let uiUploadFileArea = null;
            // let uiUploadedFiles = null;  // 已上传的文件列表
            // if (this.state.iDlgType === 1) {
            //     let uiFileWaitList = null;
            //     if (this.arrFileWaitList.length >= 2) {
            //         uiFileWaitList = (
            //             <div className="auto_order_file_wait_list">
            //                 { this.arrFileWaitList.map((file, index) => {
            //                     return (
            //                         <div className="auto_order_file_wait_row" key={index}>{file}</div>
            //                     );
            //                 })}
            //             </div>
            //         );
            //     }
            //     let arrUploadedFiles = [];
            //     if (objOrder.s3_link !== null && objOrder.s3_link !== '') {
            //         if (objOrder.s3_link.indexOf(',') >= 0) {
            //             arrUploadedFiles = objOrder.s3_link.split(',');
            //         } else {
            //             arrUploadedFiles.push(objOrder.s3_link);
            //         }
            //     }
            //     if (arrUploadedFiles.length > 0) {
            //         uiUploadedFiles = (
            //             <div className="auto_order_upload_file_area" style={{ borderTop: 'none' }}>
            //                 <div className="auto_order_file_wait_row" style={{ fontWeight: 'bold' }}>已上传的文件</div>
            //                 { arrUploadedFiles.map((file, index) => {
            //                     return (
            //                         <div className="auto_order_file_wait_row" key={index}>
            //                             {file}
            //                             <Icon type="download" style={{ marginLeft: '15px', color: 'darkcyan', fontWeight: 'bold' }}
            //                                 className="auto_order_file_operate_button" onClick={this.DownloadFile.bind(this, index)} />
            //                             <Icon type="delete" style={{ marginLeft: '15px', color: 'lightcoral', fontWeight: 'bold' }}
            //                                 className="auto_order_file_operate_button" onClick={this.DeleteFile.bind(this, index)} />
            //                         </div>
            //                     );
            //                 })}
            //             </div>
            //         );
            //     }
            //     uiUploadFileArea = (
            //         <div className="auto_order_upload_file_area" style={{ marginBottom: '0px' }}>
            //             {/* <div className="auto_order_info_row" style={{ marginBottom: '0px' }}>
            //                 <input className="auto_order_select_file_button" type='file' accept='.jpg,.jpeg,.png,.gif,.pdf'
            //                     onChange={this.SelectSingleFile} />
            //                 <div className="auto_order_upload_file_button" onClick={this.singleFileUploadHandler}>上传发票</div>
            //             </div> */}
            //             <div className="auto_order_info_row" style={{ marginBottom: '0px' }}>
            //                 <input className="auto_order_select_file_button" type='file' accept='.jpg,.jpeg,.png,.gif,.pdf' multiple="multiple" ref="file_upload"
            //                     onChange={this.SelectMultipleFile} id="file_upload" />
            //                 <div className="auto_order_upload_file_button" onClick={this.multipleFileUploadHandler}>上传文件</div>
            //             </div>
            //             {uiFileWaitList}
            //         </div>
            //     );
            // }

            // uiAccounting = (
            //     <div className="auto_order_info_block">
            //         <div className="auto_order_info_row">
            //             <div className="order_detail_title">入账状态：</div>
            //             <Dropdown className="order_detail_dropdown" trigger={['click']}
            //                 overlay={uiOrderPaymentStatusMenu} placement="bottomLeft">
            //                 <Button>{this.arrOrderPaymentStatus[this.state.dlgInfo.iOrderPaymentStatus]}<Icon type="caret-down" /></Button>
            //             </Dropdown>
            //             <div className="order_detail_title">负责人：</div>
            //             <Input className="order_detail_input" ref="order_charge" defaultValue={objOrder.charge} />
            //             <div className="order_detail_title">收款方：</div>
            //             <Input className="order_detail_input" ref="order_payee" defaultValue={objOrder.payee} />
            //         </div>
            //         <div className="auto_order_info_row">
            //             <div className="order_detail_title">支出：</div>
            //             <Input className="order_detail_input" ref="order_expense" defaultValue={objOrder.expense} />
            //             <div className="order_detail_title">收入：</div>
            //             <Input className="order_detail_input" ref="order_revenue" defaultValue={objOrder.revenue} />
            //             <div className="order_detail_title">GST：</div>
            //             <Input className="order_detail_input" ref="order_gst" defaultValue={objOrder.gst} />
            //         </div>
            //         {uiUploadFileArea}
            //         {uiUploadedFiles}
            //     </div>
            // );
        }
        let uiDlg = null;
        let uiClientList = null;
        let uiClientFilterDlg = null;
        let uiClientDlg = null;
        if (this.state.bDlgShow) {
            let strOid = objOrder.oid;
            let uiOid = (
                <div className="order_detail_input order_auto_order_button" onClick={this.JumpToAutoOrder.bind(this)}>
                    {strOid}</div>
            );
            if (strOid !== undefined && strOid !== null && typeof (strOid) === 'string') {
                if (strOid.indexOf('HYLK') >= 0) {
                    strOid = "还未出单";
                    uiOid = (
                        <div className="order_detail_input" > {strOid}</div>
                    );
                }
            }
            if (this.props.objStoreInfo.id === 20000) {
                uiOid = (
                    <div className="order_detail_input"> {strOid}</div>
                );
            }

            uiDlg = (
                <Modal width={925} title={uiDlgTitle} visible={this.state.bDlgShow} closable={true} maskClosable={true}
                    onCancel={this.CancelButtonClick} onOk={this.ConfirmButtonClick} okText="确定" cancelText="取消" >
                    <div className="auto_order_info_block">
                        <div className="auto_order_info_row">
                            {uiTypeChannelTitle}
                            {uiTypeChannel}
                            <div className="order_detail_title">服务类型：</div>
                            <Dropdown className="order_detail_dropdown" trigger={['click']}
                                overlay={uiServiceTypeMenu} placement="bottomLeft">
                                <Button>{this.arrServiceType[this.state.iServiceType]}<Icon type="caret-down" /></Button>
                            </Dropdown>
                            <div className="order_detail_title">正式单号：</div>
                            {uiOid}
                        </div>
                        <div className="auto_order_info_row">
                            <div className="order_detail_title">发货地：</div>
                            <Input className="order_detail_input" ref="order_from_where" defaultValue={objOrder.from_where} />
                            <div className="order_detail_title">目的地：</div>
                            <Input className="order_detail_input" ref="order_to_where" defaultValue={objOrder.to_where} />
                            <div className="order_detail_title">CBM：</div>
                            <Input className="order_detail_input" ref="order_cbm" defaultValue={objOrder.cbm} />
                            {/* <div className="order_detail_title">预计到达：</div>
                            <DatePicker className="order_date_package" onChange={this.ArriveDateChange}
                                value={moment(this.state.arrive_date, dateFormat2)} placeholder="请选择日期" /> */}
                        </div>
                        <div className="auto_order_info_row">
                            <div className="order_detail_title">重量：</div>
                            <Input className="order_detail_input" ref="weight" defaultValue={objOrder.weight} addonAfter={"kg"} />
                            <div className="order_detail_title">货物件数：</div>
                            <Input className="order_detail_input" ref="item_num" defaultValue={objOrder.item_num} />
                            <div className="order_detail_title">装柜日期：</div>
                            <DatePicker className="order_date_package order_detail_input" onChange={this.PackageDateChange}
                                value={moment(this.state.package_date, dateFormat2)} placeholder="请选择日期" />
                        </div>
                        {/* <div className="auto_order_info_row">
                            <div className="order_detail_title">物流单号：</div>
                            <Input className="order_detail_input" ref="order_closet_no" defaultValue={objOrder.closet_no} />
                            <div className="order_detail_title">物流状态：</div>
                            <Dropdown className="order_detail_dropdown" trigger={['click']}
                                overlay={uiOrderTransitStatusMenu} placement="bottomLeft">
                                <Button>{this.arrOrderTransitStatus[this.state.dlgInfo.iOrderTransitStatus]}<Icon type="caret-down" />
                                </Button>
                            </Dropdown>
                            <div className="order_detail_title">状态描述：</div>
                            <Dropdown className="order_detail_dropdown" trigger={['click']} disabled={bMenuDisable}
                                overlay={uiOrderTransitDetailsMenu} placement="bottomLeft">
                                <Button className="order_details_dropdown_button">
                                    <div className="order_details_dropdown_text">
                                        {this.arrOrderTransitDetails[this.state.dlgInfo.iOrderTransitDetails]}</div>
                                    <Icon type="caret-down" />
                                </Button>
                            </Dropdown>
                        </div> */}
                        <div className="auto_order_info_row">
                            <div className="order_detail_title">条款：</div>
                            <Input className="auto_order_input_long" ref="order_terms" defaultValue={objOrder.terms} />
                            <div className="order_detail_title">柜号：</div>
                            <Input className="order_detail_input" ref="order_closet_no" defaultValue={objOrder.closet_no} />
                        </div>
                        <div className="auto_order_info_row">
                            <div className="order_detail_title" style={{ marginTop: '0px', marginBottom: 'auto' }}>货物信息：</div>
                            <TextArea size="large" autoSize={{ minRows: 2 }} allowClear={true} ref="order_item_info" style={{ marginBottom: '15px', maxWidth: '750px' }}
                                placeholder="" defaultValue={objOrder.item_info} />
                        </div>
                    </div>
                    <div className="auto_order_info_block">
                        <div className="auto_order_info_row">
                            <div className="order_detail_title">首方计费：</div>
                            <Input className="order_detail_input" ref="first_value_fee" defaultValue={objOrder.first_value_fee} addonBefore={"$"} />
                            <div className="order_detail_title">出港费用：</div>
                            <Input className="order_detail_input" ref="departure_fee" defaultValue={objOrder.departure_fee} addonBefore={"$"} />
                            <div className="order_detail_title">到港费用：</div>
                            <Input className="order_detail_input" ref="arrive_fee" defaultValue={objOrder.arrive_fee} addonBefore={"$"} />
                        </div>
                        <div className="auto_order_info_row">
                            <div className="order_detail_title">续方计费：</div>
                            <Input className="order_detail_input" ref="second_value_fee" defaultValue={objOrder.second_value_fee} addonBefore={"$"} />
                            <div className="order_detail_title">派送计费：</div>
                            <Input className="order_detail_input" ref="delivery_fee" defaultValue={objOrder.delivery_fee} addonBefore={"$"} />
                            <div className="order_detail_title">等待计费：</div>
                            <Input className="order_detail_input" ref="waiting_fee" defaultValue={objOrder.waiting_fee} addonBefore={"$"} />
                        </div>
                    </div>
                    <div className="auto_order_info_block">
                        <div className="auto_order_info_row">
                            <div className="order_detail_title">进口商：</div>
                            <Input className="auto_order_input_full" ref="import_company" defaultValue={objOrder.import_company} />
                        </div>
                        <div className="auto_order_info_row">
                            <div className="order_detail_title">进口号码：</div>
                            <Input className="auto_order_input_long" ref="import_no" defaultValue={objOrder.import_no} />
                            <div className="order_detail_title">进口涉及：</div>
                            <Input className="order_detail_input" ref="import_relevant" defaultValue={objOrder.import_relevant} />
                        </div>
                    </div>
                    <div className="auto_order_info_block">
                        <div className="auto_order_info_row">
                            <div className="order_detail_title">收件人：</div>
                            <Input className="order_detail_input" ref="receiver" defaultValue={objOrder.receiver} />
                            <div className="order_detail_title">联系电话：</div>
                            <Input className="order_detail_input" ref="phone" defaultValue={objOrder.phone} />
                            <div className="order_detail_title">邮箱：</div>
                            <Input className="order_detail_input" ref="email" defaultValue={objOrder.email} />
                        </div>
                        <div className="auto_order_info_row">
                            <div className="order_detail_title">派送地址：</div>
                            <Input className="auto_order_input_full" ref="shipping_address" defaultValue={objOrder.shipping_address} />
                        </div>
                    </div>
                    {/* <div className="auto_order_info_block">
                        <div className="auto_order_info_row">
                            <div className="order_detail_title">合箱信息：</div>
                        </div>
                        <TextArea size="large" autoSize={{ minRows: 2 }} allowClear={true} ref="package_info_input" style={{ marginBottom: '15px' }}
                            placeholder="用回车分隔，可输入多个合箱编码" defaultValue={this.strPackageInfo} />
                    </div> */}
                    {/* {uiAccounting} */}
                </Modal>
            );
        }

        uiClientList = (
            <div className="storage_sku_list_area">
                {global.arrClientData.map((client, index) => {
                    return (
                        <div className="storage_sku_list_row" key={index} onClick={this.ClientSelectResult.bind(this, index)}>
                            {client.name}
                        </div>
                    );
                })}
            </div>
        );
        uiClientFilterDlg = (
            <Modal width={500} title={"选择客户"} visible={this.state.bSelectClientFilterDlg} closable={true} maskClosable={true} footer={null}
                onCancel={this.ClientSelectResult.bind(this, -1)}
            > {uiClientList} </Modal>);
        uiClientDlg = (
            <Modal width={500} title={"选择客户"} visible={this.state.bSelectClientDlg} closable={true} maskClosable={true} footer={null}
                onCancel={this.ClientSelectResult.bind(this, -1)}
            > {uiClientList} </Modal>);

        if (this.state.iDlgType === 2) {
            strDlgTitle = "删除预报单";
            uiDlg = (
                <Modal title={strDlgTitle} visible={this.state.bDlgShow} okText="确定" cancelText="取消"
                    onOk={this.ConfirmButtonClick} onCancel={this.CancelButtonClick} >
                    {"是否删除：" + this.arrSelectOrders[0].prediction_code + "等预报单？"}
                </Modal>
            );
        }
        // 表格分页属性
        let uiPagination = {
            simple: false,
            showSizeChanger: true,
            showQuickJumper: false,
            showTotal: () => `共 ${this.arrTableData.length} 笔预报单`,
            defaultPageSize: 10,
            defaultCurrent: this.state.iCurrentPage,
            pageSizeOptions: ['10', '20', '30', '40', '50'],
            total: this.arrTableData.length,
            position: 'bottom',
            onChange: this.TablePageChange.bind(this)

        };
        // 新建按钮的样式控制
        let strNewButtonStyle = { backgroundColor: 'lightseagreen', color: 'white' };
        if (this.state.btnControlDisable[0])
            strNewButtonStyle = {};

        const columns = this.state.columns.map((col, index) => ({
            ...col,
            onHeaderCell: column => ({
                width: column.width,
                onResize: this.handleResize(index),
            }),
        }));

        // 表格主体
        let uiTable = (
            <Table style={{ margin: '20px', width: '97.5%', overflowX: 'auto' }}
                bordered components={this.components}
                pagination={uiPagination} rowSelection={rowSelection} columns={columns}
                dataSource={this.arrTableData} onRow={(record) => ({ onClick: () => { this.selectRow(record); } })}
                rowClassName={(record) => record.color.replace('#', '')}
                rowKey={record => record.key} />
        );
        if (this.state.bLoading) {
            uiTable = (
                <div className="table_loading">
                    <Spin size="large" />
                </div>
            );
        }

        // 批量编辑弹窗
        let strMultiEditTitle = "";
        let uiMultiEditDlg = null;
        if (this.state.bMultiEdit) {
            strMultiEditTitle = "批量编辑：" + this.arrSelectOrders[0].prediction_code + " 等预报单";
            uiMultiEditDlg = (
                <Modal width={700} title={strMultiEditTitle} visible={this.state.bMultiEditDlg} okText="确定" cancelText="取消"
                    onOk={this.ConfirmButtonClick} onCancel={this.CancelButtonClick} >
                    <div className="item_info_row">
                        <div className="order_detail_title">货运类型：</div>
                        {uiTypeChannel}
                        <div className="order_detail_title">预计到达：</div>
                        <DatePicker className="order_date_package" onChange={this.ArriveDateChange}
                            value={moment(this.state.arrive_date, dateFormat2)}
                            defaultValue={moment(this.strToday, dateFormat2)} />
                    </div>
                    <div className="item_info_row">
                        <div className="order_detail_title">物流状态：</div>
                        <Dropdown className="order_detail_dropdown" trigger={['click']}
                            overlay={uiOrderTransitStatusMenu} placement="bottomLeft">
                            <Button className="order_details_dropdown_button">
                                <div className="order_details_dropdown_text">{this.arrOrderTransitStatus[this.state.iMultiEditTransitStatus]}</div>
                                <Icon type="caret-down" />
                            </Button>
                        </Dropdown>
                        <div className="order_detail_title">状态描述：</div>
                        <Dropdown className="order_detail_dropdown" trigger={['click']} disabled={bMenuDisable}
                            overlay={uiOrderTransitDetailsMenu} placement="bottomLeft">
                            <Button className="order_details_dropdown_button">
                                <div className="order_details_dropdown_text">
                                    {this.arrOrderTransitDetails[this.state.iMultiEditTransitDetails]}</div>
                                <Icon type="caret-down" />
                            </Button>
                        </Dropdown>
                    </div>
                </Modal>
            );
        }

        let strAreaStyle = "store_manage_contents store_manage_top_space_double";
        // let strDateDesc = "预计到达 ↓";
        // if (this.iArriveOrder === -1)
        //     strDateDesc = "预计到达 ↑";
        // let strOrderDesc = "预报单编号 ↓";
        // if (this.iIDOrder === -1)
        //     strOrderDesc = "预报单编号 ↑";
        // let strHightlight = "normal";
        // if (this.iHightLightOrder === 1)
        //     strHightlight = "bold"
        // let strCurrentDate = this.dateCurrent;
        // if (this.dateCurrent === "")
        //     strCurrentDate = this.strToday;
        // let arrArriveDate = this.dateArrive;
        // if (this.dateArrive[0] === "")
        //     arrArriveDate = [this.strToday, this.strToday];
        let uiTitle = (
            <div>
                <div className="store_contents_title">
                    <div className="manage_title_left_side">
                        <div className="order_filter_title">货运类型</div>
                        <Dropdown className="order_filter_dropdown" trigger={['click']} overlay={uiOrderTypeMenu} placement="bottomLeft">
                            <Button style={{ width: '125px' }}>{this.arrFilterOrderType[this.iOrderType]}<Icon type="caret-down" /></Button>
                        </Dropdown>
                        <div className="order_filter_title">服务类型</div>
                        <Dropdown className="order_filter_dropdown" trigger={['click']}
                            overlay={uiServiceTypeFilterMenu} placement="bottomLeft">
                            <Button style={{ width: '125px' }}>{this.arrServiceTypeFilter[this.iServiceType]}<Icon type="caret-down" /></Button>
                        </Dropdown>
                        <div className="order_filter_title" >客户</div>
                        <Input className="order_detail_input" ref="client_filter" style={{ marginRight: '10px' }} />
                        <Button type='primary' onClick={this.SelectClient.bind(this)}>选择</Button>
                        <div className="order_filter_title">关键字</div>
                        <Input className="order_search_input" ref="order_search" defaultValue="" />
                        <Button className="manage_contents_title_margin" type="primary" onClick={this.SearchOrder} >
                            <Icon type="search" />搜索</Button>
                        {/* <Button className="manage_contents_title_margin manage_contents_title_margin_right" type="primary"
                            onClick={this.GetTargetOrders.bind(this, 0)}>{strOrderDesc}</Button>
                        <Button className="manage_contents_title_margin manage_contents_title_margin_right" type="primary"
                            onClick={this.GetTargetOrders.bind(this, 1)}>{strDateDesc}</Button> */}

                        {/* <Button className="manage_contents_title_margin manage_contents_title_margin_right" type="primary"
                            style={{ fontWeight: strHightlight }}
                            onClick={this.GetTargetOrders.bind(this, 2)} >显示高光</Button>
                        <Button className="manage_contents_title_margin" type="primary" onClick={this.ShowDlg.bind(this, 4)} disabled={!this.state.bHighlight}
                            style={{ marginRight: "15px" }}>
                            <Icon type="highlight" />高光标记</Button>
                        <Button className="manage_contents_title_margin" type="primary" onClick={this.ShowDlg.bind(this, 5)} disabled={!this.state.bHighlight}
                            style={{ marginRight: "20px" }}>
                            <Icon type="eye-invisible" />取消标记</Button>
                        <Button className="manage_title_button_right_side" type="primary" onClick={this.ResetTableLayout}>
                            <Icon type="layout" theme="twoTone" />重置表格</Button> */}
                    </div>
                </div>
                <div className="store_contents_title_sec">
                    <Button className="manage_contents_title_margin manage_contents_title_margin_right" type="primary"
                        onClick={this.ShowAllOrders} >显示全部</Button>
                    <Button className="manage_contents_title_margin" style={strNewButtonStyle} onClick={this.ShowDlg.bind(this, 0)} disabled={this.state.btnControlDisable[0]}>
                        <Icon type="plus" />新建</Button>
                    <Button className="manage_contents_title_margin" type="primary" onClick={this.ShowDlg.bind(this, 1)} disabled={this.state.btnControlDisable[1]}>
                        <Icon type="edit" />编辑</Button>
                    <Button className="manage_contents_title_margin" type="primary" onClick={this.ShowDlg.bind(this, 3)} disabled={this.state.btnControlDisable[3]}>
                        <Icon type="database" />批量处理</Button>
                    <Button className="manage_contents_title_margin" type="danger" onClick={this.ShowDlg.bind(this, 2)} disabled={this.state.btnControlDisable[2]}>
                        <Icon type="close" />删除</Button>
                    <Button className="manage_contents_title_margin" type="primary" onClick={this.TurnToAutoOrder.bind(this)} disabled={this.state.btnControlDisable[2]}>
                        <Icon type="profile" />正式出单</Button>
                    {/* <div className="order_filter_title">创建日期</div>
                    <MonthPicker placeholder="选择月份" picker="month" onChange={this.MonthPickerChange}
                        defaultValue={moment(this.strToday, dateFormat1)} value={moment(strCurrentDate, dateFormat1)}
                        className="manage_contents_title_margin" style={{ maxWidth: '125px' }} />
                    <div className="order_filter_title">预计到达</div>
                    <RangePicker defaultValue={[moment(this.strToday, dateFormat2), moment(this.strToday, dateFormat2)]} format={dateFormat2}
                        value={[moment(arrArriveDate[0], dateFormat2), moment(arrArriveDate[1], dateFormat2)]}
                        onChange={this.ArriveDateRangeChange} style={{ maxWidth: '250px' }} size={"middle"} /> */}

                    {/* <div className="order_filter_title">入账</div>
                    <Dropdown className="order_filter_dropdown" trigger={['click']}
                        overlay={uiOrderPaymentStatusMenu} placement="bottomLeft">
                        <Button>{this.arrFilterPaymentStatus[this.iPaymentStatus]}<Icon type="caret-down" /></Button>
                    </Dropdown> */}
                </div>
                {/* <div className="store_contents_title_thd">
                </div> */}
            </div>
        );

        // M站布局
        if (this.props.bMStation) {
            strAreaStyle = "store_manage_contents_m";
            let uiDesc = <Icon type="arrow-down" />;
            if (!this.state.iDateDesc)
                uiDesc = <Icon type="arrow-up" />;
            uiTitle = (
                <div className="store_contents_title_m">
                    <Button className="store_manager_control_button" style={strNewButtonStyle} onClick={this.ShowDlg.bind(this, 0)} disabled={this.state.btnControlDisable[0]}>
                        <Icon type="plus" /></Button>
                    <Button className="store_manager_control_button" type="primary" onClick={this.ShowDlg.bind(this, 1)} disabled={this.state.btnControlDisable[1]}>
                        <Icon type="edit" /></Button>
                    <Button className="store_manager_control_button" type="primary" onClick={this.ShowDlg.bind(this, 3)} disabled={!this.state.bMultiEdit}>
                        <Icon type="database" /></Button>
                    <Input className="order_search_input_m" ref="order_search" defaultValue="" />
                    <Button className="store_manager_control_button" type="primary" onClick={this.SearchOrder} >
                        <Icon type="search" /></Button>
                    <Button className="store_manager_control_button" type="primary" onClick={this.OrderByArriveTime} >
                        {uiDesc}</Button>
                </div>
            );
            uiPagination = (
                <Pagination total={this.arrTableData.length} showSizeChanger={false} showQuickJumper={false}
                    defaultPageSize={10} defaultCurrent={this.state.iCurrentPage} />
            );
            uiTable = (
                <Table style={{ width: '100vw', height: '100%' }}
                    // rowClassName={(record) => record.color.replace('#', '')}
                    // rowKey={record => record.key}
                    bordered components={this.components}
                    pagination={uiPagination} rowSelection={rowSelection} columns={columns}
                    dataSource={this.arrTableData} onRow={(record) => ({ onClick: () => { this.selectRow(record); } })} />
            );
            uiMultiEditDlg = (
                <Modal width={'90vw'} title={strMultiEditTitle} visible={this.state.bMultiEditDlg} okText="确定" cancelText="取消"
                    onOk={this.ConfirmButtonClick} onCancel={this.CancelButtonClick} >
                    <div className="item_info_row_m">
                        <div className="order_detail_title_m">物流状态</div>
                        <Dropdown className="order_detail_dropdown_m" trigger={['click']}
                            overlay={uiOrderTransitStatusMenu} placement="bottomLeft">
                            <Button className="order_details_dropdown_button">
                                <div className="order_details_dropdown_text">{this.arrOrderTransitStatus[this.state.iMultiEditTransitStatus]}</div>
                                <Icon type="caret-down" />
                            </Button>
                        </Dropdown>
                    </div>
                    <div className="item_info_row_m">
                        <div className="order_detail_title_m">状态描述</div>
                        <Dropdown className="order_detail_dropdown_m" trigger={['click']} disabled={bMenuDisable}
                            overlay={uiOrderTransitDetailsMenu} placement="bottomLeft">
                            <Button className="order_details_dropdown_button">
                                <div className="order_details_dropdown_text">
                                    {this.arrOrderTransitDetails[this.state.iMultiEditTransitDetails]}</div>
                                <Icon type="caret-down" />
                            </Button>
                        </Dropdown>
                    </div>
                    <div className="item_info_row_m">
                        <div className="order_detail_title_m">预计到达</div>
                        <DatePicker className="order_date_package_m" onChange={this.ArriveDateChange} size="large"
                            value={moment(this.state.arrive_date, dateFormat2)}
                            defaultValue={moment(this.strToday, dateFormat2)} />
                    </div>
                </Modal>
            );
            uiTypeChannelTitle = (
                <div className="order_detail_title_m">货运类型</div>
            );
            uiTypeChannel = (
                <Dropdown className="order_detail_dropdown_m" trigger={['click']}
                    overlay={uiOrderTypeMenu} placement="bottomLeft">
                    <Button>{this.arrOrderType[this.state.dlgInfo.iOrderType]}<Icon type="caret-down" /></Button>
                </Dropdown>
            );
            // if (this.props.objStoreInfo.id === 20000) {
            //     uiTypeChannelTitle = (
            //         <div className="order_detail_title_m">货运渠道</div>

            //     );
            //     uiTypeChannel = (
            //         <Input className="order_detail_input_m" ref="order_channel" defaultValue={objOrder.channel} />
            //     );
            // }
            uiDlg = (
                <Modal width={'90vw'} title={strDlgTitle} visible={this.state.bDlgShow} closable={true} maskClosable={true} centered={false}
                    onCancel={this.CancelButtonClick} onOk={this.ConfirmButtonClick} okText="确定" cancelText="取消"
                    bodyStyle={{ margin: '0', padding: '0' }}>
                    <div className="order_detail_edit_area">
                        <div className="item_info_row_m">
                            {uiTypeChannelTitle}
                            {uiTypeChannel}
                        </div>
                        <div className="item_info_row_m">
                            <div className="order_detail_title_m">支付状态</div>
                            <Dropdown className="order_detail_dropdown_m" trigger={['click']}
                                overlay={uiOrderPaymentStatusMenu} placement="bottomLeft">
                                <Button>{this.arrOrderPaymentStatus[this.state.dlgInfo.iOrderPaymentStatus]}<Icon type="caret-down" /></Button>
                            </Dropdown>
                        </div>
                        <div className="item_info_row_m">
                            <div className="order_detail_title_m">预计到达</div>
                            <DatePicker className="order_date_package_m" onChange={this.ArriveDateChange} size="large"
                                value={moment(this.state.arrive_date, dateFormat2)}
                                defaultValue={moment(this.strToday, dateFormat2)} />
                        </div>
                        <div className="item_info_row_m">
                            <div className="order_detail_title_m">负责人</div>
                            <Input className="order_detail_input_m" ref="order_charge" defaultValue={objOrder.charge} />
                        </div>
                        <div className="item_info_row_m">
                            <div className="order_detail_title_m">发货地</div>
                            <Input className="order_detail_input_m" ref="order_from_where" defaultValue={objOrder.from_where} />
                        </div>
                        <div className="item_info_row_m">
                            <div className="order_detail_title_m">目的地</div>
                            <Input className="order_detail_input_m" ref="order_to_where" defaultValue={objOrder.to_where} />
                        </div>
                        <div className="item_info_row_m">
                            <div className="order_detail_title_m">物流单号</div>
                            <Input className="order_detail_input_m" ref="order_closet_no" defaultValue={objOrder.closet_no} />
                        </div>
                        <div className="item_info_row_m">
                            <div className="order_detail_title_m">物流状态</div>
                            <Dropdown className="order_detail_dropdown_m" trigger={['click']}
                                overlay={uiOrderTransitStatusMenu} placement="bottomLeft">
                                <Button className="order_details_dropdown_button">
                                    <div className="order_details_dropdown_text">{this.arrOrderTransitStatus[this.state.dlgInfo.iOrderTransitStatus]}</div>
                                    <Icon type="caret-down" />
                                </Button>
                            </Dropdown>
                        </div>
                        <div className="item_info_row_m">
                            <div className="order_detail_title_m">状态描述</div>
                            <Dropdown className="order_detail_dropdown_m" trigger={['click']} disabled={bMenuDisable}
                                overlay={uiOrderTransitDetailsMenu} placement="bottomLeft">
                                <Button className="order_details_dropdown_button">
                                    <div className="order_details_dropdown_text">
                                        {this.arrOrderTransitDetails[this.state.dlgInfo.iOrderTransitDetails]}</div>
                                    <Icon type="caret-down" />
                                </Button>
                            </Dropdown>
                        </div>
                        <div className="item_info_row_m">
                            <div className="order_detail_title_m">支出</div>
                            <Input className="order_detail_input_m" ref="order_expense" defaultValue={objOrder.expense} />
                        </div>
                        <div className="item_info_row_m">
                            <div className="order_detail_title_m">收入</div>
                            <Input className="order_detail_input_m" ref="order_revenue" defaultValue={objOrder.revenue} />
                        </div>
                        <div className="item_info_row_m">
                            <div className="order_detail_title_m">GST</div>
                            <Input className="order_detail_input_m" ref="order_gst" defaultValue={objOrder.gst} />
                        </div>
                        <div className="item_info_row_m">
                            <div className="order_detail_title_m">收款方</div>
                            <Input className="order_detail_input_m" ref="order_payee" defaultValue={objOrder.payee} />
                        </div>
                        <div className="item_info_row_m">
                            <div className="order_detail_title_m">条款</div>
                            <Input className="order_detail_input_m" ref="order_item_info" defaultValue={objOrder.item_info} />
                        </div>
                        {/* <div className="item_info_row_m">
                            <div className="order_detail_title_m">链接</div>
                            <Input className="order_detail_input_m" ref="order_google_drive" defaultValue={objOrder.google_drive} />
                        </div> */}
                    </div>

                </Modal>
            );
        }

        return (
            <div className={strAreaStyle}>
                {/* 预报单管理页面控制栏 */}
                {uiTitle}
                {/* 表格主体 */}
                {uiTable}
                {/* 选择客户弹窗过滤器 */}
                {uiClientFilterDlg}
                {/* 弹窗 */}
                {uiDlg}
                {/* 选择客户弹窗 */}
                {uiClientDlg}
                {/* 批量编辑弹窗 */}
                {uiMultiEditDlg}
            </div>
        );
    }
}
export default PredictionOrderList;
