import React, { Component } from "react";
import axios from 'axios';
import BarcodeScannerComponent from "react-webcam-barcode-scanner";
import Webcam from 'react-webcam';
import Radio from 'antd/es/radio';
import Modal from 'antd/es/modal';
import Input from 'antd/es/input';
import Icon from 'antd/es/icon';
import Menu from 'antd/es/menu';
import Dropdown from 'antd/es/dropdown';
import Button from 'antd/es/button';
import message from 'antd/es/message';

import "../../../styles/Background/UI_Module/package_scanner.css";

const g_strMessageKey = 'updating';

class ScannerPackage extends Component {
    constructor(props) {
        super();
        this.state = {
            iScanMethod: 0,
            strQRCodeResult: 'No Result',
            strBarCodeResult: 'No Result',
            strOgeScanResult: 'No Result',
            bScanResultDlg: false,
            iExpressPackageStatus: 0,
            bSubmitResultDlg: false,
            iSubmitResult: 0,
            bItemInfoChange: false,
            iShipDate: 0,
            iItemType: 0,
            photo: null,
            iScanPurpose: 0,
            iShipMethod: 0,
            iEditExpresssInfo: -1,
            bAutoFill: false
        };
        this.audio = new Audio('https://res.cloudinary.com/jingtu-ego-test/video/upload/v1602208394/0-%E9%B2%B8%E5%9B%BEWhaleMap/Scan_zm6ajd.mp3');
        this.audio.load();
        this.bScanResultDlg = false;
        // 当前的快递进仓信息
        this.objExpressEnter = { receiver: "", express_info: { item_info: [] } };
        this.strCurrentEnterNo = "";
        this.strCurrentReceiver = "";
        this.strCurrentStorageCode = "";
        this.strCurrentPackageCode = "";
        // 当前的合箱信息
        this.objPackageCombine = {};

        // 货物信息
        this.fItemWeight = '';
        this.fItemLength = '';
        this.fItemWidth = '';
        this.fItemHeight = '';
        this.fItemValueWeight = '';
        this.fItemPriceWeight = '';
        this.arrShipDate = ["月中(15号)", "月底(30号)"];
        this.arrShipDateShot = ["15", "30"];
        this.arrItemType = ["普货", "食品", "敏感"];
        this.arrItemTypeShort = ["G", "F", "S"];
        this.strCurrentMonth = new Date().getMonth() + 1;
        this.strCurrentMonth = this.strCurrentMonth.toString();
        if (parseInt(this.strCurrentMonth, 10) >= 10)
            console.log("current month = " + this.strCurrentMonth);
        else {
            if (this.strCurrentMonth.toString().indexOf('0') < 0) {
                this.strCurrentMonth = '0' + this.strCurrentMonth;
            }
        }

        this.arrExpressStatus = ['未到库', '已到库', '待合箱', '合箱中', '已合箱', '已发货', '无头件', '退货中', '已退货'];
        this.arrPackageStatus = ["待合箱", "合箱中", "已合箱", "已发货"];
        this.objCurrentExpress = -1;
        this.objCurrentPackage = -1;
        this.arrExpressInfo = [];
        this.arrShipMethod = ["海运", "空运"];
        this.arrItemInfo = [];
    }

    // 切换扫描方式
    SwitchScanMethod = index => {
        this.setState({
            ...this.state,
            iScanMethod: index
        });
    };
    // 二维码扫描
    QRCodeScanUpdate = result => {
        if (this.state.iExpressPackageStatus > 0 || this.state.strQRCodeResult !== "No Result")
            return;
        if (result) {

            let strResult = JSON.stringify(result);
            strResult = strResult.split("'k5':")[1].split(",'k6'")[0].split("'")[1];
            this.setState({
                ...this.state,
                bScanResultDlg: true,
                strQRCodeResult: strResult,
                strOgeScanResult: result
            });
            this.audio.play();
            this.bScanResultDlg = true;
            // 根据扫描到的单号进行搜索
            message.loading({ content: '数据扫描中……', key: g_strMessageKey });
            axios('/GetExpressEnter', { params: { express_order: strResult, store_id: this.props.objStoreInfo.id } })
                .then(({ data }) => {
                    let iExpressPackageStatus = 0;
                    if (data.length <= 0) {
                        this.objExpressEnter = { receiver: "", express_info: { item_info: [] } };
                        iExpressPackageStatus = 1;  // 数据库中无记录，当前步骤为签收入仓
                    } else {
                        iExpressPackageStatus = 2;
                        this.objExpressEnter = data[0];
                        if (this.objExpressEnter.express_info !== null)
                            this.objExpressEnter.express_info = JSON.parse(this.objExpressEnter.express_info);
                        else {
                            this.objExpressEnter.express_info = {
                                item_info: []
                            }
                        }
                        if (this.objExpressEnter.status === 1) {
                            iExpressPackageStatus = 3;
                            if (this.objExpressEnter.express_info !== null) {
                                if (this.objExpressEnter.express_info.item_info) {
                                    if (this.objExpressEnter.express_info.item_info.length > 0 && this.objExpressEnter.price_weight !== null && this.objExpressEnter.price_weight !== "")
                                        iExpressPackageStatus = 4;
                                }
                            }
                        }
                        if (this.objExpressEnter.status === 2) {
                            iExpressPackageStatus = 5;
                        }
                        if (this.objExpressEnter.status === 3) {
                            iExpressPackageStatus = 6;
                        }
                        this.strCurrentEnterNo = this.objExpressEnter.enter_no;
                        this.strCurrentReceiver = this.objExpressEnter.receiver;
                        this.strCurrentStorageCode = this.objExpressEnter.storage_code;
                    }
                    message.success({ content: "数据扫描完成！", key: g_strMessageKey, duration: 2 });
                    this.setState({
                        ...this.state,
                        iExpressPackageStatus: iExpressPackageStatus
                    });
                }).catch(function (error) { console.log(error); });
        }
    }
    QRCodeScanError = err => {
        console.error("QR Code Scanner Error : ", err);
        // this.setState({
        //     ...this.state,
        //     strOgeScanResult: err
        // });
    }
    // 条形码扫描
    BarCodeScanUpdate = (err, result) => {
        if (this.state.iExpressPackageStatus > 0 || this.state.strBarCodeResult !== "No Result" || this.state.bScanResultDlg || this.state.bSubmitResultDlg)
            return;
        if (result) {

            let strResult = result.text;
            if (strResult.indexOf('MMM') < 0 && strResult.indexOf('k5') >= 0) {
                let objResult = JSON.parse(strResult);
                strResult = objResult.k5;
            }
            if (strResult.indexOf('MMM') >= 0 && strResult.indexOf('k5') >= 0) {
                let objResult = JSON.parse(strResult.split('MMM=')[1]);
                strResult = objResult.k5;
            }

            this.setState({
                ...this.state,
                bScanResultDlg: true,
                strBarCodeResult: strResult,
                strOgeScanResult: result.text
            });
            this.audio.play();
            this.bScanResultDlg = true;
            // 根据扫描到的单号进行搜索
            message.loading({ content: '数据扫描中……', key: g_strMessageKey });
            axios('/GetExpressEnter', { params: { express_order: result.text, store_id: this.props.objStoreInfo.id } })
                .then(({ data }) => {
                    let iExpressPackageStatus = 0;
                    if (data.length <= 0) {
                        this.objExpressEnter = { receiver: "", express_info: { item_info: [] } };
                        iExpressPackageStatus = 1;  // 数据库中无记录，当前步骤为签收入仓
                    } else {
                        iExpressPackageStatus = 2;
                        this.objExpressEnter = data[0];
                        if (this.objExpressEnter.express_info !== null) {
                            this.objExpressEnter.express_info = JSON.parse(this.objExpressEnter.express_info);
                            this.fItemWeight = this.objExpressEnter.express_info.item_weight;
                            this.fItemLength = this.objExpressEnter.express_info.item_length;
                            this.fItemWidth = this.objExpressEnter.express_info.item_width;
                            this.fItemHeight = this.objExpressEnter.express_info.item_height;
                            this.fItemValueWeight = this.objExpressEnter.express_info.value_weight;
                            this.fItemPriceWeight = this.objExpressEnter.express_info.price_weight;
                            if (this.objExpressEnter.package_no === null || this.objExpressEnter.package_no === "")
                                this.strCurrentPackageCode = this.strCurrentPackageCode;
                            else
                                this.strCurrentPackageCode = this.objExpressEnter.package_no;
                        } else {
                            this.objExpressEnter.express_info = {
                                item_info: []
                            }
                        }
                        if (this.objExpressEnter.status >= 1) {
                            iExpressPackageStatus = 3;
                            // if (this.objExpressEnter.express_info !== null) {
                            //     if (this.objExpressEnter.express_info.item_info) {
                            //         if (this.objExpressEnter.express_info.item_info.length > 0 && this.objExpressEnter.price_weight !== null && this.objExpressEnter.price_weight !== "")
                            //             iExpressPackageStatus = 4;
                            //     }
                            // }
                        }
                        // if (this.objExpressEnter.status === 2) {
                        //     iExpressPackageStatus = 5;
                        // }
                        // if (this.objExpressEnter.status === 3) {
                        //     iExpressPackageStatus = 6;
                        // }
                        this.strCurrentEnterNo = this.objExpressEnter.enter_no;
                        this.strCurrentReceiver = this.objExpressEnter.receiver;
                        this.strCurrentStorageCode = this.objExpressEnter.storage_code;
                    }
                    message.success({ content: "数据扫描完成！", key: g_strMessageKey, duration: 2 });
                    this.setState({
                        ...this.state,
                        iExpressPackageStatus: iExpressPackageStatus
                    });
                }).catch(function (error) { console.log(error); });
        }
    }
    // 签收进仓/合箱出仓
    ScanResultProcess = (bConfirmButton) => {
        if (this.state.iExpressPackageStatus === 0)
            return;

        // 每当进行确认时，都会对用户码进行记录更新
        if (this.refs.receiver) {
            this.strCurrentReceiver = this.refs.receiver.state.value;
            // cookie.save('wm_scan_user_code', this.strCurrentUserCode, { path: '/' });
        }
        if (this.refs.enter_no) {
            this.strCurrentEnterNo = this.refs.enter_no.state.value;
        }
        if (this.refs.package_no) {
            this.strCurrentPackageCode = this.refs.package_no.state.value;
            this.objExpressEnter.package_no = this.refs.package_no.state.value;
            this.objPackageCombine.package_no = this.refs.package_no.state.value;
        }
        // if (this.refs.receiver || this.refs.enter_no || this.refs.package_no) {
        axios.post('/UpdateScanRecord', {
            code: this.props.strUserCode, scan_record: { user_code: this.strCurrentReceiver, enter_no: this.strCurrentEnterNo, package_no: this.strCurrentPackageCode }
        }).then(({ data }) => {
            console.log("UpdateScanRecord Complete", data);
        }).catch(function (error) { console.log(error); });
        // }

        // 还未录入单号
        if (this.state.iExpressPackageStatus === 1) {
            this.objExpressEnter.enter_no = this.strCurrentEnterNo;
            this.objExpressEnter.receiver = this.strCurrentReceiver;
            if (this.state.iScanMethod === 0)
                this.objExpressEnter.express_order = this.state.strQRCodeResult;
            else
                this.objExpressEnter.express_order = this.state.strBarCodeResult;
            this.objExpressEnter.status = 1;
            this.objExpressEnter.store_id = this.props.objStoreInfo.id;
            message.loading({ content: '快递进仓中……', key: g_strMessageKey });
            axios.post('/NewExpressEnter', {
                objExpressEnter: this.objExpressEnter
            }).then(({ data }) => {
                if (data.status === 1) {
                    message.success({ content: "快递进仓并签收完成!", key: g_strMessageKey, duration: 2 });
                    this.bScanResultDlg = false;
                    this.setState({
                        ...this.state,
                        bScanResultDlg: false,
                        strQRCodeResult: 'No Result',
                        strBarCodeResult: 'No Result',
                        bSubmitResultDlg: true,
                        iSubmitResult: 1
                    });
                } else {
                    message.warning(data.message);
                }
            }).catch(function (error) {
                console.log(error);
                this.setState({
                    ...this.state,
                    bScanResultDlg: false,
                    strQRCodeResult: 'No Result',
                    strBarCodeResult: 'No Result',
                    bSubmitResultDlg: true,
                    iSubmitResult: -1
                });
            });
        }
        // 已录入，还未签收
        if (this.state.iExpressPackageStatus === 2) {
            this.objExpressEnter.status = 1;
            this.objExpressEnter.enter_no = this.strCurrentEnterNo;
            this.objExpressEnter.receiver = this.strCurrentReceiver;
            message.loading({ content: '快递签收中……', key: g_strMessageKey });
            axios.post('/UpdateExpressEnter', {
                objExpressEnter: this.objExpressEnter
            }).then(({ data }) => {
                message.success({ content: "快递签收完成!", key: g_strMessageKey, duration: 2 });
                this.bScanResultDlg = false;
                this.setState({
                    ...this.state,
                    bScanResultDlg: false,
                    strQRCodeResult: 'No Result',
                    strBarCodeResult: 'No Result',
                    bSubmitResultDlg: true,
                    iSubmitResult: 2
                });
            }).catch(function (error) {
                console.log(error);
                this.setState({
                    ...this.state,
                    bScanResultDlg: false,
                    strQRCodeResult: 'No Result',
                    strBarCodeResult: 'No Result',
                    bSubmitResultDlg: true,
                    iSubmitResult: -1
                });
            });
        }
        // 已签收，还未录入明细
        if (this.state.iExpressPackageStatus >= 3) {
            this.objExpressEnter.enter_no = this.strCurrentEnterNo;
            this.objExpressEnter.receiver = this.strCurrentReceiver;
            this.objExpressEnter.storage_code = this.strCurrentStorageCode;
            this.objExpressEnter.express_info.item_weight = this.fItemWeight;
            this.objExpressEnter.express_info.item_length = this.fItemLength;
            this.objExpressEnter.express_info.item_width = this.fItemWidth;
            this.objExpressEnter.express_info.item_height = this.fItemHeight;
            this.objExpressEnter.express_info.item_value = parseFloat(this.fItemLength) * parseFloat(this.fItemWidth) * parseFloat(this.fItemHeight);
            this.objExpressEnter.express_info.value_weight = this.fItemValueWeight;
            this.objExpressEnter.express_info.price_weight = this.fItemPriceWeight;
            this.objExpressEnter.express_info = JSON.stringify(this.objExpressEnter.express_info);
            if (!bConfirmButton) {
                this.objExpressEnter.status = 1;
                message.loading({ content: '快递信息更新中……', key: g_strMessageKey });
            } else {
                this.objExpressEnter.status = 2;
                if (this.refs.package_no)
                    if (this.refs.package_no.state.value === null || this.refs.package_no.state.value === "") {
                        message.warning('合箱编码不能为空！');
                        return;
                    }
                message.loading({ content: '快递合箱/出仓中……', key: g_strMessageKey });
            }
            axios.post('/UpdateExpressEnter', {
                objExpressEnter: this.objExpressEnter
            }).then(({ data }) => {
                if (!bConfirmButton) {
                    message.success({ content: "快递信息更新完成!", key: g_strMessageKey, duration: 2 });
                    this.bScanResultDlg = false;
                    this.setState({
                        ...this.state,
                        bScanResultDlg: false,
                        strQRCodeResult: 'No Result',
                        strBarCodeResult: 'No Result',
                        bSubmitResultDlg: true,
                        iSubmitResult: 3
                    });
                } else {
                    this.objPackageCombine.store_id = this.props.objStoreInfo.id;
                    this.objPackageCombine.status = 0;
                    this.objPackageCombine.transit_way = 0;
                    this.objPackageCombine.ship_date = this.state.iShipDate;
                    this.objPackageCombine.package_type = this.state.iItemType;
                    this.objPackageCombine.express_info = [];
                    this.objPackageCombine.express_info.push({ express_order: this.objExpressEnter.express_order, receiver: this.objExpressEnter.receiver });
                    this.objPackageCombine.express_info = JSON.stringify(this.objPackageCombine.express_info);
                    axios.post('/ScanPackageCombine', {
                        objPackageCombine: this.objPackageCombine
                    }).then(({ data }) => {
                        message.success({ content: "快递合箱/出仓完成!", key: g_strMessageKey, duration: 2 });
                        this.bScanResultDlg = false;
                        this.setState({
                            ...this.state,
                            bScanResultDlg: false,
                            strQRCodeResult: 'No Result',
                            strBarCodeResult: 'No Result',
                            bSubmitResultDlg: true,
                            iSubmitResult: 4
                        });
                    }).catch(function (error) {
                        console.log(error);
                        this.bScanResultDlg = false;
                        this.setState({
                            ...this.state,
                            bScanResultDlg: false,
                            strQRCodeResult: 'No Result',
                            strBarCodeResult: 'No Result',
                            bSubmitResultDlg: true,
                            iSubmitResult: -2
                        });
                    });
                }
            }).catch(function (error) {
                console.log(error);
                this.setState({
                    ...this.state,
                    bScanResultDlg: false,
                    strQRCodeResult: 'No Result',
                    strBarCodeResult: 'No Result',
                    bSubmitResultDlg: true,
                    iSubmitResult: -1
                });
            });
        }

    }
    // 重新扫描
    RestartScan = () => {
        if (this.state.iExpressPackageStatus === 0)
            return;
        this.bScanResultDlg = false;
        this.setState({
            ...this.state,
            bScanResultDlg: false,
            bSubmitResultDlg: false,
            iSubmitResult: 0,
            strQRCodeResult: 'No Result',
            strBarCodeResult: 'No Result',
            iExpressPackageStatus: 0
        });
    }
    // 获取上一次扫描的收件人名
    GetLastScanCode = () => {
        // 存储在数据库
        axios('/GetLastScanRecord', { params: { code: this.props.strUserCode } })
            .then(({ data }) => {
                if (data[0].scan_record !== null && data[0].scan_record !== "") {
                    let objScanRecord = JSON.parse(data[0].scan_record);
                    this.strCurrentReceiver = objScanRecord.user_code;
                    this.strCurrentEnterNo = objScanRecord.enter_no;
                    this.strCurrentPackageCode = objScanRecord.package_no;
                    console.log("GetLastScanRecord : ", objScanRecord);
                }
            }).catch(function (error) { console.log(error); });
    }
    // 更新上一次扫描的信息
    UpdateScanRecord = () => {
        axios.post('/UpdateScanRecord', {
            code: this.props.strUserCode, scan_record: { user_code: this.strCurrentReceiver, enter_no: this.strCurrentEnterNo, package_no: this.strCurrentPackageCode }
        }).then(({ data }) => {
            console.log("UpdateScanRecord Complete", data, this.strCurrentPackageCode);
        }).catch(function (error) { console.log(error); });
    }
    // 自动填充历史合箱编码
    AutoFillPackageNo = (index) => {
        console.log("AutoFillPackageNo : ", index);
        if (index === 0)
            this.refs.edit_auto_order_no.state.value = this.strCurrentPackageCode;
        if (index === 1) {
            this.refs.order_input.state.value = this.strCurrentPackageCode;
            console.log("this.strCurrentPackageCode = " + this.strCurrentPackageCode)
        }

        this.setState({
            ...this.state,
            bAutoFill: true
        })
    }
    // 签收/进仓成功/失败的提示框
    SubmitResultDlg = (bShow) => {
        this.setState({
            ...this.state,
            bSubmitResultDlg: bShow
        })
    }
    // 货物信息发生变化
    ItemInfoChange = (index, e) => {
        // console.log(index, e.target.value);
        let fItemValueWeight = 0.0;
        let fItemPriceWeight = 0.0;
        // 净重
        if (index === 0) {
            let fItemWeight = e.target.value;


            if (this.fItemValueWeight === "")
                fItemPriceWeight = fItemWeight;
            else {
                if (parseFloat(fItemWeight) >= parseFloat(this.fItemValueWeight))
                    fItemPriceWeight = fItemWeight;
                else
                    fItemPriceWeight = this.fItemValueWeight;
            }


            this.fItemWeight = fItemWeight;
            this.fItemPriceWeight = fItemPriceWeight;
        }
        // 长
        if (index === 1) {
            let fItemLength = e.target.value;
            fItemValueWeight = parseFloat(fItemLength) * parseFloat(this.fItemWidth) * parseFloat(this.fItemHeight) / 6000;
            if (fItemLength === "" || this.fItemWidth === "" || this.fItemHeight === "")
                fItemPriceWeight = this.fItemWeight;
            if (parseFloat(this.fItemWeight) >= parseFloat(fItemValueWeight))
                fItemPriceWeight = this.fItemWeight;
            else
                fItemPriceWeight = fItemValueWeight;
            this.fItemLength = fItemLength;
            this.fItemValueWeight = fItemValueWeight;
            this.fItemPriceWeight = fItemPriceWeight;
        }
        // 宽
        if (index === 2) {
            let fItemWidth = e.target.value;
            fItemValueWeight = parseFloat(this.fItemLength) * parseFloat(fItemWidth) * parseFloat(this.fItemHeight) / 6000;
            if (this.fItemLength === "" || fItemWidth === "" || this.fItemHeight === "")
                fItemPriceWeight = this.fItemWeight;
            if (parseFloat(this.fItemWeight) >= parseFloat(fItemValueWeight))
                fItemPriceWeight = this.fItemWeight;
            else
                fItemPriceWeight = fItemValueWeight;
            this.fItemWidth = fItemWidth;
            this.fItemValueWeight = fItemValueWeight;
            this.fItemPriceWeight = fItemPriceWeight;
        }
        // 高
        if (index === 3) {
            let fItemHeight = e.target.value;
            fItemValueWeight = parseFloat(fItemHeight) * parseFloat(this.fItemWidth) * parseFloat(this.fItemLength) / 6000;
            if (this.fItemLength === "" || this.fItemWidth === "" || fItemHeight === "")
                fItemPriceWeight = this.fItemWeight;
            if (parseFloat(this.fItemWeight) >= parseFloat(fItemValueWeight))
                fItemPriceWeight = this.fItemWeight;
            else
                fItemPriceWeight = fItemValueWeight;
            this.fItemHeight = fItemHeight;
            this.fItemValueWeight = fItemValueWeight;
            this.fItemPriceWeight = fItemPriceWeight;
        }
        this.setState({
            ...this.state,
            bItemInfoChange: true
        });

    }
    // 物品信息发生变化
    ItemDetailsChange = (indexItem, value, e) => {
        if (value === 0) {
            this.objExpressEnter.express_info.item_info[indexItem].name = e.target.value;
        }
        if (value === 1) {
            this.objExpressEnter.express_info.item_info[indexItem].num = e.target.value;
        }
        this.setState({
            ...this.state,
            bItemInfoChange: true
        });
    }
    // 移除一个物品信息
    RemoveItemInfo = (indexItem) => {
        this.objExpressEnter.express_info.item_info.splice(indexItem, 1);

        this.setState({
            ...this.state,
            bItemInfoChange: true
        });

    }
    // 切换船期
    SwitchShipDate = (index) => {
        if (this.refs.package_no) {
            if (this.refs.enter_no.state.value !== "" && this.refs.receiver.state.value !== "")
                this.refs.package_no.state.value = this.refs.enter_no.state.value + "/" +
                    this.refs.receiver.state.value + "/" + this.strCurrentMonth +
                    this.arrShipDateShot[index] + this.arrItemTypeShort[this.state.iItemType];

        }
        this.setState({
            ...this.state,
            iShipDate: index
        });
    }
    // 切换货物品类
    SwitchItemType = (index) => {
        if (this.refs.package_no) {
            if (this.refs.enter_no.state.value !== "" && this.refs.receiver.state.value !== "")
                this.refs.package_no.state.value = this.refs.enter_no.state.value + "/" +
                    this.refs.receiver.state.value + "/" + this.strCurrentMonth +
                    this.arrShipDateShot[this.state.iShipDate] + this.arrItemTypeShort[index];
        }
        this.setState({
            ...this.state,
            iItemType: index
        });
    }
    // 扫码用途切换
    ScanPurposeChange = (e) => {
        console.log('radio checked', e.target.value);
        this.objCurrentExpress = -1;
        this.objCurrentPackage = -1;
        this.arrExpressInfo = [];
        this.refs.order_input.state.value = "";
        if (this.refs.code_scanner_input !== undefined)
            this.refs.code_scanner_input.state.value = "";
        this.setState({
            iScanPurpose: e.target.value,
        });
    }
    // 直接扫码预报签收
    DirectExpressEnter = (strExpressOrder) => {
        this.setState({
            ...this.state,
            iScanPurpose: -1
        });
    }
    // 确认直接签收
    ConfirmExpressEnter = (index) => {
        // 签收
        if (index === 1) {
            if (this.refs.user_code.state.value === undefined || this.refs.user_code.state.value === null ||
                this.refs.user_code.state.value === "") {
                message.warning('请填写包裹上的用户编码！');
                return;
            }
            if (this.refs.user_code.state.value.trim() === "") {
                message.warning('请填写包裹上的用户编码！');
                return;
            }
            let strInviteCode = 'LK39';
            if (this.state.iShipMethod === 1)
                strInviteCode = 'JW';
            let objExpressEnter = {
                enter_no: strInviteCode,
                storage_code: this.refs.user_code.state.value,
                transit_type: this.state.iShipMethod,
                status: 1,
                express_order: this.refs.order_input.state.value,
                express_info: "",
                store_id: this.props.objStoreInfo.id,
                get_date: new Date()
            };
            console.log(objExpressEnter);
            console.log("直接签收入库！");
            message.loading({ content: '正在录入包裹信息……', key: g_strMessageKey });
            axios.post('/NewExpressEnter', {
                objExpressEnter: objExpressEnter
            }).then(({ data }) => {
                console.log(data);
                if (data.status === 1) {
                    message.success({ content: "包裹已入库，请尽快补充物品信息！", key: g_strMessageKey, duration: 2 });
                    this.setState({
                        ...this.state,
                        iScanPurpose: 0
                    });
                    this.InputConfirmButtonClicked(0);
                } else {
                    message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
                }
            }).catch(function (error) { console.log(error); });
            this.strCurrentEnterNo = this.refs.user_code.state.value;
            this.UpdateScanRecord();

        }
        // 不签收
        if (index === 0) {
            this.setState({
                ...this.state,
                iScanPurpose: 0
            });
        }
    }
    // 签收/拣包按钮点击
    InputConfirmButtonClicked = (index) => {
        // 搜索
        if (index === 0) {
            // 扫码签收
            if (this.state.iScanPurpose <= 0) {
                let strExpressOrder = this.refs.order_input.state.value;
                // 根据扫描到的单号进行搜索
                message.loading({ content: '包裹搜索中……', key: g_strMessageKey });
                axios('/GetExpressEnter', { params: { express_order: strExpressOrder, store_id: this.props.objStoreInfo.id } })
                    .then(({ data }) => {
                        console.log("包裹信息", data);
                        if (data.length <= 0) {
                            message.warning({ content: "未找到相应包裹！", key: g_strMessageKey, duration: 2 });
                            this.objCurrentExpress = -1;
                            // 走直接签收入库流程
                            this.DirectExpressEnter(strExpressOrder);
                            this.setState({
                                ...this.state,
                                iExpressPackageStatus: 0
                            });
                        } else {
                            message.success({ content: "包裹搜索完成！", key: g_strMessageKey, duration: 2 });
                            this.objCurrentExpress = data[0];
                            this.setState({
                                ...this.state,
                                iExpressPackageStatus: 0,
                                iScanPurpose: 0
                            });
                        }
                    }).catch(function (error) { console.log(error); });
            }
            // 运单查找
            if (this.state.iScanPurpose === 1) {
                let strPackageNo = this.refs.order_input.state.value;
                console.log("当前搜索的运单:", strPackageNo);
                if (strPackageNo === undefined || strPackageNo === null || strPackageNo === '') {
                    message.warning("请扫描或输入需要查询的单号！"); return;
                }
                this.strCurrentPackageCode = strPackageNo;
                this.UpdateScanRecord();
                message.loading({ content: '运单搜索中……', key: g_strMessageKey });
                axios('/GetPackageDetails', { params: { package_no: strPackageNo, store_id: this.props.objStoreInfo.id } })
                    .then(({ data }) => {
                        if (data.length <= 0) {
                            message.success({ content: "未找到相应运单！", key: g_strMessageKey, duration: 2 });
                            this.objCurrentPackage = -1;
                        } else {
                            message.success({ content: "运单搜索完成！", key: g_strMessageKey, duration: 2 });
                            this.objCurrentPackage = data[0];
                            this.arrExpressInfo = JSON.parse(this.objCurrentPackage.express_info);
                            for (let i = 0; i < this.arrExpressInfo.length; i++) {
                                if (this.arrExpressInfo[i].checked === undefined || this.arrExpressInfo[i].checked === null)
                                    this.arrExpressInfo[i].checked = 0;
                            }
                        }
                        this.setState({
                            ...this.state,
                            iExpressPackageStatus: 0
                        });
                    }).catch(function (error) { console.log(error); });
            }
        }
        // 签收/保存拣包状态
        if (index === 1) {
            if (this.state.iScanPurpose === 0 && this.objCurrentExpress !== 1 && typeof (this.objCurrentExpress) === 'object') {
                let iStatus = parseInt(this.objCurrentExpress.status, 10);
                if (iStatus <= 0) {
                    this.objCurrentExpress.status = 1;
                    message.loading({ content: '正在更新包裹状态……', key: g_strMessageKey });
                    axios.post('/UpdateExpressEnter', {
                        objExpressEnter: this.objCurrentExpress
                    }).then(({ data }) => {
                        message.success({ content: "包裹已签收！", key: g_strMessageKey, duration: 2 });
                        this.setState({
                            ...this.state,
                            iExpressPackageStatus: 1
                        });
                    }).catch(function (error) { console.log(error); });


                } else {
                    message.info("包裹状态：" + this.arrExpressStatus[iStatus] + ", 无需签收！");
                }
            }
            if (this.state.iScanPurpose === 1 && this.objCurrentPackage !== 1 && typeof (this.objCurrentPackage) === 'object') {
                this.objCurrentPackage.express_info = this.arrExpressInfo;
                message.loading({ content: '捡包状态保存中……', key: g_strMessageKey });
                axios.post('/UpdatePackageCombine', {
                    objPackageCombine: this.objCurrentPackage
                }).then(({ data }) => {
                    message.success({ content: "捡包状态已保存！", key: g_strMessageKey, duration: 2 });
                    this.setState({
                        ...this.state,
                        iExpressPackageStatus: 1
                    });
                }).catch(function (error) { console.log(error); });
            }
        }
        // 检查运单包裹
        if (index === 2) {
            let strExpressInput = this.refs.express_input.state.value;
            if (strExpressInput === undefined || strExpressInput === null || strExpressInput === '') {
                message.warning('请输入快递单号！'); return;
            }
            for (let i = 0; i < this.arrExpressInfo.length; i++) {
                if (strExpressInput === this.arrExpressInfo[i].express_order) {
                    message.success("该包裹属于该运单，已分拣！");
                    this.arrExpressInfo[i].checked = 1;
                    let bAllCheck = true;
                    for (let j = 0; j < this.arrExpressInfo.length; j++) {
                        if (this.arrExpressInfo[j].checked <= 0) {
                            bAllCheck = false; break;
                        }
                    }
                    if (bAllCheck) {
                        if (this.objCurrentPackage.status < 2) {
                            this.objCurrentPackage.status = 2;
                            message.info("已全部捡包，请注意保存！");
                        }

                    }
                    this.setState({
                        ...this.state,
                        iExpressPackageStatus: 1
                    });
                    return;
                }
            }
            message.warning("该包裹不属于该运单！");
            this.setState({
                ...this.state,
                iExpressPackageStatus: 1
            });
        }
    }
    // 切换货运方式
    SwitchShipMethod = (index) => {
        this.setState({
            ...this.state,
            iShipMethod: index
        });
    }
    // 切换货运方式
    SwitchExpressPackageStatus = (index) => {
        this.setState({
            ...this.state,
            iExpressPackageStatus: index
        });
    }
    // 编辑用户名称/编码
    EditUserInfo = (index) => {
        let iStatus = 0;
        if (index === 0) {
            iStatus = this.objCurrentExpress.status;
            if (this.objCurrentExpress.express_info === undefined || this.objCurrentExpress.express_info === null || this.objCurrentExpress.express_info === '') {
                this.objCurrentExpress.express_info = {
                    item_info: [{ cn: '', en: '', num: 1 }]
                };
                this.objCurrentExpress.express_info = JSON.stringify(this.objCurrentExpress.express_info);
            }
            this.arrItemInfo = JSON.parse(this.objCurrentExpress.express_info);
            this.arrItemInfo = this.arrItemInfo.item_info;
        }
        if (index === 1) {
            iStatus = this.objCurrentPackage.status;
            console.log(this.objCurrentPackage, this.arrExpressInfo);

        }
        this.setState({
            ...this.state,
            iEditExpresssInfo: index,
            iExpressPackageStatus: iStatus
        });
    }
    // 编辑信息确认/取消
    EditConfirm = (bEdit) => {
        if (bEdit) {
            // 包裹信息更新
            if (this.state.iScanPurpose === 0) {
                if (this.objCurrentExpress.transit_type === 0)
                    for (let i = 0; i < this.arrItemInfo.length; i++) {
                        if (this.arrItemInfo[i].cn === undefined || this.arrItemInfo[i].cn === null || this.arrItemInfo[i].cn === '') {
                            message.warning('海运：物品名称不能为空！'); return;
                        }
                        if (this.arrItemInfo[i].num === undefined || this.arrItemInfo[i].num === null || this.arrItemInfo[i].num === '') {
                            message.warning('海运：物品数量不能为空！'); return;
                        }
                        if (this.arrItemInfo[i].num <= 0) {

                            message.warning('海运物品数量不能小于等于 0 ！'); return;
                        }
                    }
                this.objCurrentExpress.express_info = JSON.parse(this.objCurrentExpress.express_info);
                this.objCurrentExpress.express_info.item_info = this.arrItemInfo;
                this.objCurrentExpress.express_info = JSON.stringify(this.objCurrentExpress.express_info);
                let strCode = this.refs.edit_user_code.state.value;
                if (strCode !== undefined && strCode !== null && strCode !== "")
                    this.objCurrentExpress.storage_code = strCode;
                this.objCurrentExpress.status = this.state.iExpressPackageStatus;
                this.objCurrentExpress.auto_order_no = this.refs.edit_auto_order_no.state.value;
                if (this.objCurrentExpress.auto_order_no === undefined || this.objCurrentExpress.auto_order_no === null || this.objCurrentExpress.auto_order_no === "") {
                    console.log("No 合箱码 Yet :", this.objCurrentExpress.auto_order_no);
                    this.objCurrentExpress.status = 2;
                }
                if (this.objCurrentExpress.status >= 1 &&
                    (this.objCurrentExpress.get_date === null || this.objCurrentExpress.get_date === undefined || this.objCurrentExpress.get_date === ""))
                    this.objCurrentExpress.get_date = new Date();
                console.log("Update Express Enter : ", this.objCurrentExpress);
                message.loading({ content: '包裹信息更新中……', key: g_strMessageKey });
                axios.post('/UpdateExpressEnter', {
                    objExpressEnter: this.objCurrentExpress
                }).then(({ data }) => {
                    this.arrItemInfo = [];
                    message.success({ content: "包裹信息更新完成!", key: g_strMessageKey, duration: 2 });
                    this.setState({
                        ...this.state,
                        iEditExpresssInfo: -1
                    });
                }).catch(function (error) { console.log(error); });
                this.strCurrentPackageCode = this.refs.edit_auto_order_no.state.value;
                this.UpdateScanRecord();
            }
            // 运单/合箱信息更新
            if (this.state.iScanPurpose === 1) {
                let strAutoOrder = this.refs.auto_order_no.state.value;
                if (strAutoOrder !== undefined && strAutoOrder !== null && strAutoOrder !== "")
                    this.objCurrentPackage.auto_order_no = strAutoOrder;
                this.objCurrentPackage.express_info = this.arrExpressInfo;

                let bDeleteRepeat = false;
                let bRepeatOrder = true;
                while (bRepeatOrder) {
                    bRepeatOrder = false;
                    for (let i = 0; i < this.objCurrentPackage.express_info.length; i++) {
                        for (let j = 0; j < this.objCurrentPackage.express_info.length; j++) {
                            if (this.objCurrentPackage.express_info[i].express_order === this.objCurrentPackage.express_info[j].express_order &&
                                i !== j) {
                                this.objCurrentPackage.express_info.splice(i, 1);
                                bRepeatOrder = true;
                                bDeleteRepeat = true;
                                break;
                            }
                        }
                    }
                }
                if (bDeleteRepeat)
                    message.info("发现重复的物流单号，已自动去除！");
                this.objCurrentPackage.status = this.state.iExpressPackageStatus;
                console.log("Edit Confirm1 : ", this.objCurrentPackage.status);
                message.loading({ content: '运单信息更新中……', key: g_strMessageKey });
                axios.post('/UpdatePackageCombine', {
                    objPackageCombine: this.objCurrentPackage
                }).then(({ data }) => {
                    message.success({ content: "运单状态已更新！", key: g_strMessageKey, duration: 2 });
                    this.setState({
                        ...this.state,
                        iEditExpresssInfo: -1
                    });
                }).catch(function (error) { console.log(error); });
            }
        } else {
            this.arrItemInfo = [];
            this.setState({
                ...this.state,
                iEditExpresssInfo: -1
            });
        }
    }
    // 新增货物明细
    AddNewItemInfo = () => {
        console.log("AdNewItemInfo : ", this.arrItemInfo);
        let objItemInfo = {
            cn: '', en: '', num: 1
        };
        this.arrItemInfo.splice(0, 0, objItemInfo);
        // this.objCurrentExpress.express_info = JSON.parse(this.objCurrentExpress.express_info);
        // this.objCurrentExpress.express_info.item_info.push(objItemInfo);
        // this.objCurrentExpress.express_info = JSON.stringify(this.objCurrentExpress.express_info);
        this.setState({
            ...this.state,
            bItemInfoChange: true
        });
    }
    // 新增快递单号
    AddNewExpressInfo = () => {
        let objExpressInfo = {
            express_order: '', storage_code: this.arrExpressInfo[0].storage_code, user_name: this.arrExpressInfo[0].user_name,
            express_info: '', checked: 0
        };
        this.arrExpressInfo.splice(0, 0, objExpressInfo);
        this.setState({
            ...this.state,
            bItemInfoChange: true
        });
    }
    // 物品信息发生变化
    ItemDetailsChange = (indexItem, value, e) => {
        if (value === 0) {
            this.arrItemInfo[indexItem].cn = e.target.value;
        }
        if (value === 1) {
            this.arrItemInfo[indexItem].num = e.target.value;
        }
        if (value === 2) {
            this.arrExpressInfo[indexItem].express_order = e.target.value;
        }
        this.setState({
            ...this.state,
            bItemInfoChange: true
        });
    }
    // 移除一个物品信息
    RemoveItemInfo = (indexItem) => {
        if (this.arrItemInfo.length <= 1) {
            if (this.objCurrentExpress.transit_type === 0) {
                message.warning("物品信息不能为空！"); return;
            } else
                this.arrItemInfo = [];
        } else {
            this.arrItemInfo.splice(indexItem, 1);
        }

        this.setState({
            ...this.state,
            bItemInfoChange: true
        });
    }
    // 移除快递单号
    RemoveExpressInfo = (indexItem) => {
        if (this.arrExpressInfo.length <= 1) {
            message.warning("运单至少要包含一个包裹！"); return;
        }
        this.arrExpressInfo.splice(indexItem, 1);
        this.setState({
            ...this.state,
            bItemInfoChange: true
        });
    }
    // 清空查询输入框
    ClearOrderInput = (index) => {
        if (index === 1) {
            this.refs.express_input.state.value = '';
            this.setState({
                ...this.state,
                iScanPurpose: this.state.iScanPurpose
            });
        } else {
            this.refs.order_input.state.value = '';
            this.objCurrentExpress = -1;
            this.objCurrentPackage = -1;
            this.arrExpressInfo = [];
            this.setState({
                ...this.state,
                iExpressPackageStatus: 0,
                iScanPurpose: this.state.iScanPurpose
            });
        }
    }

    // 渲染完毕……
    componentDidMount() {
        console.log("scan package : xiyouji / sooning ", this.props.strUserCode, this.props.objStoreInfo.id);
        this.GetLastScanCode();

    }

    /******** 拍照相关 ********/
    handleWebcamDidMount = (elem) => {
        this.refs = elem;
    }
    TakePicture = () => {
        message.info("已拍照！");
        this.setState({
            ...this.state,
            photo: this.refs.getScreenshot()
        });
    }
    UploadPicture = () => {
        if (this.state.photo === null)
            return;
        message.loading({ content: '照片提交中……', key: g_strMessageKey });
        axios.post('/UploadImageToCloudinary',
            {
                storeName: this.props.objStoreInfo.id.toString() + "-" + this.props.objStoreInfo.store_name,
                imgName: 'EEP' + new Date().toISOString(),
                imgUrl: this.state.photo
            }).then((response) => {
                console.log(response.data);
                if (response.data === -1) {
                    message.success({ content: "上传失败，请重新尝试！", key: g_strMessageKey, duration: 2 });
                } else {
                    message.success({ content: "照片已提交到云端！", key: g_strMessageKey, duration: 2 });
                }
                this.setState({
                    ...this.state,
                    photo: null
                });
            }).catch(function (error) {
                console.log(error);
                message.success({ content: "上传失败，请重新尝试！", key: g_strMessageKey, duration: 2 });
                this.setState({
                    ...this.state,
                    photo: null
                });
            });
    }

    render() {
        let strQRButtonStyle = "code_scanner_method_switch_button_select";
        let strBARButtonStyle = "code_scanner_method_switch_button";
        let strPhotoButtonStyle = "code_scanner_method_switch_button";
        let strResultOrder = "";
        // 默认扫码枪模式
        let strInputTip = "点击输入框后，扫描快递包裹";
        let strInputButotn = "签 收";
        if (this.state.iScanPurpose === 1) {
            strInputTip = "点击输入框后，扫描运单编号";
            strInputButotn = "保 存";
        }
        let strInputButtonStyle = "code_scanner_input_confirm_button code_scanner_input_confirm_button_enable";
        let uiOrderInfoArea = null;
        if (this.state.iScanPurpose === 0 && this.objCurrentExpress !== 1 && typeof (this.objCurrentExpress) === 'object') {
            strInputButtonStyle = "code_scanner_input_confirm_button";
            let uiItemList = null;
            if (this.objCurrentExpress.express_info !== undefined && this.objCurrentExpress.express_info !== null &&
                this.objCurrentExpress.express_info !== "") {
                let arrItemInfo = JSON.parse(this.objCurrentExpress.express_info);
                arrItemInfo = arrItemInfo.item_info;
                uiItemList = (
                    arrItemInfo.map((item, indexItem) => {
                        return (
                            <div key={indexItem} className="code_scanner_oder_info_row">
                                {item.cn + " x " + item.num + " 件"}
                            </div>
                        );
                    })
                );
            } else {
                uiItemList = (
                    <div className="code_scanner_oder_info_row">
                        无
                    </div>
                );
            }
            uiOrderInfoArea = (
                <div className="code_scanner_order_info_area">
                    <div className="code_scanner_oder_info_row">
                        <b>{"物流单号："}</b>
                        {this.objCurrentExpress.express_order}
                        <div className="code_scanner_edit_button" onClick={this.EditUserInfo.bind(this.setState, 0)}>编辑</div>
                    </div>
                    <div className="code_scanner_oder_info_row">
                        <b>{"货运方式："}</b>
                        {this.arrShipMethod[this.objCurrentExpress.transit_type]}
                    </div>
                    <div className="code_scanner_oder_info_row">
                        <b>{"合箱编码："}</b>
                        {this.objCurrentExpress.auto_order_no}
                    </div>
                    <div className="code_scanner_oder_info_row">
                        <b>{"用户信息："}</b>
                        {this.objCurrentExpress.user_name + " ( " + this.objCurrentExpress.storage_code + " )"}

                    </div>
                    <div className="code_scanner_oder_info_row">
                        <b>{"包裹状态："}</b>
                        {this.arrExpressStatus[this.objCurrentExpress.status]}
                    </div>
                    <div className="code_scanner_oder_info_row">
                        <b>{"物品信息："}</b>
                    </div>
                    {uiItemList}
                </div>
            );
        }
        if (this.state.iScanPurpose === 1 && this.objCurrentPackage !== 1 && typeof (this.objCurrentPackage) === 'object') {
            strInputButtonStyle = "code_scanner_input_confirm_button";
            let uiExpressList = null;
            let iExpressNum = 0;
            if (this.objCurrentPackage.express_info !== undefined) {
                uiExpressList = (
                    this.arrExpressInfo.map((express, indexExpress) => {
                        let uiCheck = null;
                        if (express.checked === 1) {
                            uiCheck = (
                                <Icon type="check-circle" style={{ marginLeft: "2.5vw", color: 'darkcyan' }} />
                            );
                        }
                        return (
                            <div key={indexExpress} className="code_scanner_oder_info_row">
                                {express.express_order}
                                {uiCheck}
                            </div>
                        );
                    })
                );
                iExpressNum = this.arrExpressInfo.length;
            }
            uiOrderInfoArea = (
                <div className="code_scanner_order_info_area">
                    <div className="code_scanner_oder_info_row">
                        <b>{"分拣："}</b>
                        <Input className="code_scanner_input" style={{ width: '40vw', marginLeft: '0px', marginRight: '0px' }}
                            placeholder={'扫描快递单号'} ref="express_input" />
                        <Icon type="close-circle" className="code_scanner_search_button" onClick={this.ClearOrderInput.bind(this, 1)}
                            style={{ background: 'grey', marginLeft: '0px', marginRight: '6vw', borderRadius: '0px', fontWeight: 'bold', fontSize: '5vw' }} />
                        <div className="code_scanner_search_button" onClick={this.InputConfirmButtonClicked.bind(this, 2)}>
                            <Icon type="check" />
                        </div>
                    </div>
                    <div className="code_scanner_oder_info_row" style={{ marginTop: '15px' }}>
                        <b>{"单号："}</b>
                        {this.objCurrentPackage.package_no}
                        <div className="code_scanner_edit_button" onClick={this.EditUserInfo.bind(this.setState, 1)}>编辑</div>
                    </div>
                    <div className="code_scanner_oder_info_row" >
                        <b>{"合箱编码："}</b>
                        {this.objCurrentPackage.auto_order_no}
                    </div>
                    <div className="code_scanner_oder_info_row">
                        <b>{"用户："}</b>
                        {this.arrExpressInfo[0].user_name + " ( " + this.arrExpressInfo[0].storage_code + " )"}
                    </div>
                    <div className="code_scanner_oder_info_row">
                        <b>{"状态："}</b>
                        {this.arrPackageStatus[this.objCurrentPackage.status]}
                    </div>
                    <div className="code_scanner_oder_info_row">
                        <b>{"包裹："}</b>{"共 " + iExpressNum + " 个"}
                    </div>
                    {uiExpressList}
                    <div style={{ marginBottom: '20px' }} />
                </div>
            );
        }
        if (this.state.iScanPurpose === -1) {
            // 货运方式
            let uiShipMethod = (
                <Menu>
                    {this.arrShipMethod.map((item, index) => {
                        return (
                            <Menu.Item key={index} onClick={this.SwitchShipMethod.bind(this, index)}>
                                {item}
                            </Menu.Item>
                        );
                    })}
                </Menu>
            );
            uiOrderInfoArea = (
                <div className="code_scanner_order_info_area">
                    <div className="code_scanner_oder_info_row">
                        {"该包裹未预报，无法获取物品信息"}
                    </div>
                    <div className="code_scanner_oder_info_row">
                        {"需要直接签收入库码？"}
                    </div>
                    <div className="code_scanner_oder_info_row">
                        <div className="code_scanner_confirm_button" onClick={this.ConfirmExpressEnter.bind(this, 1)}>
                            签收入库
                        </div>
                        <div className="code_scanner_confirm_button" style={{ marginLeft: 'auto', marginRight: '5vw' }}
                            onClick={this.ConfirmExpressEnter.bind(this, 0)}>
                            算了
                        </div>
                    </div>
                    <div className="code_scanner_oder_info_row" style={{ marginTop: '5vw', marginBottom: '2.5vw' }}>
                        {"用户编码"}
                        <Input className="code_scanner_input" style={{ width: '50vw', marginLeft: '6.95vw' }}
                            placeholder={'输入包裹上的用户编码'} ref="user_code" defaultValue={this.strCurrentEnterNo} />
                    </div>
                    <div className="code_scanner_oder_info_row" style={{ marginTop: '2.5vw', marginBottom: '5vw' }}>
                        {"货运方式"}
                        <Dropdown className="code_scanner_input" trigger={['click']}
                            overlay={uiShipMethod} placement="bottomLeft"
                            style={{ width: '50vw', maxWidth: '50vw' }}>
                            <Button style={{ width: '50vw', maxWidth: '50vw' }}
                            >{this.arrShipMethod[this.state.iShipMethod]}<Icon type="caret-down" /></Button>
                        </Dropdown>
                    </div>
                </div>
            );
        }
        let iScanPurpose = this.state.iScanPurpose;
        if (iScanPurpose < 0)
            iScanPurpose = 0;
        let uiHistoryButton = null;
        if (iScanPurpose === 1) {
            uiHistoryButton = (
                <div className="code_scanner_search_button" onClick={this.AutoFillPackageNo.bind(this, 1)}>
                    <Icon type="history" />
                </div>
            );
        }
        let uiScanArea = (
            <div>
                <div className="code_scanner_title_sub" >
                    <Radio.Group onChange={this.ScanPurposeChange.bind(this)} value={iScanPurpose} className="code_scanner_input_row"  >
                        <Radio value={0} className="code_scanner_input_row" style={{ fontSize: '4vw' }}>扫码签收</Radio>
                        <Radio value={1} className="code_scanner_input_row" style={{ fontSize: '4vw' }}>合箱拣包</Radio>
                    </Radio.Group>
                    <div className={strInputButtonStyle} onClick={this.InputConfirmButtonClicked.bind(this, 1)}>{strInputButotn}</div>
                </div>
                <div className="code_scanner_input_row">
                    <Input className="code_scanner_input" placeholder={strInputTip} ref="order_input" style={{ width: '60vw', marginRight: '0px' }} />
                    <Icon type="close-circle" className="code_scanner_search_button" onClick={this.ClearOrderInput.bind(this, 0)}
                        style={{ background: 'grey', marginLeft: '0px', marginRight: '2.5vw', borderRadius: '0px', fontWeight: 'bold', fontSize: '5vw' }} />
                    <div className="code_scanner_search_button" onClick={this.InputConfirmButtonClicked.bind(this, 0)} style={{ marginRight: '2.5vw' }} >
                        <Icon type="search" />
                    </div>
                    {uiHistoryButton}
                </div>
                {uiOrderInfoArea}
            </div>
        );
        // 手机扫码模式
        if (this.state.iScanMethod === 1) {
            strQRButtonStyle = "code_scanner_method_switch_button";
            strBARButtonStyle = "code_scanner_method_switch_button_select";
            strPhotoButtonStyle = "code_scanner_method_switch_button";
            strResultOrder = this.state.strBarCodeResult;
            uiScanArea = (
                <div>
                    {/* <div>{"Bar Result : " + this.state.strBarCodeResult}</div> */}
                    <BarcodeScannerComponent
                        // width={450} height={350}
                        style={{ width: '100%' }}
                        onUpdate={this.BarCodeScanUpdate} />
                </div>
            );
        }
        // 拍照模式
        if (this.state.iScanMethod === 2) {
            strQRButtonStyle = "code_scanner_method_switch_button";
            strBARButtonStyle = "code_scanner_method_switch_button";
            strPhotoButtonStyle = "code_scanner_method_switch_button_select";
            let uiTakePicture = null;
            let strUploadStyle = "code_scanner_upload_photo_button code_scanner_upload_photo_button_disable";
            if (this.state.photo !== null) {
                strUploadStyle = "code_scanner_upload_photo_button";
                uiTakePicture = (
                    <img src={this.state.photo} className="code_scanner_photo_shot" alt='' />
                );
            }
            uiScanArea = (
                <div className="code_scanner_photo_area">
                    <Webcam audio={false} ref={this.handleWebcamDidMount} screenshotFormat={'image'}
                        videoConstraints={{ width: 1280, height: 720, facingMode: "environment" }}
                        minScreenshotWidth={1280} minScreenshotHeight={720} className="code_scanner_photo_shot" />
                    {uiTakePicture}
                    <div className="code_scanner_take_photo_button" onClick={this.TakePicture.bind(this)} >
                        <Icon type="camera" style={{ color: 'white', fontWeight: 'bold', fontSize: '25px' }} />
                    </div>
                    <div className={strUploadStyle} onClick={this.UploadPicture.bind(this)}>上传图片</div>
                </div>
            );

        }
        let strOkButtonText = "处理中……";
        let strCancelButton = "扫错了，重来";
        let uiReceiver = null;
        let uiEnterNo = null;  // 来自哪个代理
        let uiStorageCode = null;  // 自动分配的用户仓库编码
        let uiPackageCombine = null;
        let uiComplete = null;
        let uiShipDate = null;
        let uiItemType = null;
        if (this.state.iExpressPackageStatus === 1) {
            strOkButtonText = "快递进仓&签收";
            uiEnterNo = (
                <div className="package_scan_item_info_row" style={{ marginTop: '5vw' }}>
                    <div className="package_scan_item_info_title">进仓编码：</div>
                    <Input className="package_scan_item_info_input" ref="enter_no" defaultValue={this.strCurrentEnterNo} />
                </div>
            );
            uiReceiver = (
                <div className="package_scan_item_info_row" style={{ marginTop: '5vw' }}>
                    <div className="package_scan_item_info_title">收件人名：</div>
                    <Input className="package_scan_item_info_input" ref="receiver" defaultValue={this.strCurrentReceiver} />
                </div>
            );
        }
        if (this.state.iExpressPackageStatus === 2) {
            strOkButtonText = "快递签收";
            uiReceiver = (
                <div className="package_scan_item_info_row" style={{ marginTop: '5vw' }}>
                    <div className="package_scan_item_info_title">{"收件人名：" + this.objExpressEnter.receiver}</div>
                </div>
            );
            uiEnterNo = (
                <div className="package_scan_item_info_row" style={{ marginTop: '5vw' }}>
                    <div className="package_scan_item_info_title">进仓编码：</div>
                    <Input className="package_scan_item_info_input" ref="enter_no" defaultValue={this.strCurrentEnterNo} />
                </div>
            );
        }
        let uiItemInfo = null;
        if (this.state.iExpressPackageStatus >= 3) {
            strOkButtonText = "合箱/出仓";
            uiPackageCombine = (
                <div className="package_scan_item_info_row" style={{ marginTop: '5vw' }}>
                    <div className="package_scan_item_info_title">合箱编码：</div>
                    <Input className="package_scan_item_info_input" ref="package_no" defaultValue={this.strCurrentPackageCode} />
                </div>
            );
            uiEnterNo = (
                <div className="package_scan_item_info_row" style={{ marginTop: '5vw' }}>
                    <div className="package_scan_item_info_title">进仓编码：</div>
                    <Input className="package_scan_item_info_input" ref="enter_no" defaultValue={this.strCurrentEnterNo} />
                </div>
            );
            uiReceiver = (
                <div className="package_scan_item_info_row" style={{ marginTop: '5vw' }}>
                    <div className="package_scan_item_info_title">收件人名：</div>
                    <Input className="package_scan_item_info_input" ref="receiver" defaultValue={this.strCurrentReceiver} />
                </div>
            );
            uiStorageCode = (
                <div className="package_scan_item_info_row" style={{ marginTop: '5vw' }}>
                    <div className="package_scan_item_info_title">用户编码：</div>
                    <Input className="package_scan_item_info_input" ref="storage_code" defaultValue={this.strStorageCode} />
                </div>
            );
            // 进仓编号中的物品信息列表
            let uiItemInfoList = null;
            if (this.objExpressEnter.express_info !== null) {
                if (this.objExpressEnter.express_info.item_info)
                    if (this.objExpressEnter.express_info.item_info !== null) {
                        if (this.objExpressEnter.express_info.item_info.length > 0) {
                            uiItemInfoList = (
                                this.objExpressEnter.express_info.item_info.map((item, indexItem) => {
                                    let strItemBlockStyle = "express_item_info_block";
                                    if (indexItem % 2 === 0)
                                        strItemBlockStyle = "express_item_info_block_light";
                                    return (
                                        <div key={indexItem} className={strItemBlockStyle}>
                                            <div className="express_item_info_row">
                                                <div className="express_enter_edit_title_m">明细</div>
                                                <Input style={{ width: '30%' }} ref={"item_name_" + indexItem}
                                                    defaultValue={item.name} value={item.name}
                                                    onChange={this.ItemDetailsChange.bind(this, indexItem, 0)} />
                                                <div className="express_enter_edit_title_m">件数</div>
                                                <Input style={{ width: '20%' }} ref={"item_num_" + indexItem}
                                                    defaultValue={item.num} value={item.num}
                                                    onChange={this.ItemDetailsChange.bind(this, indexItem, 1)} />
                                                <div className="express_item_delete_button" style={{ marginLeft: 'auto', marginRight: '0px' }}
                                                    onClick={this.RemoveItemInfo.bind(this, indexItem)}>
                                                    <Icon type="delete" theme="filled" />
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            );
                        }
                    }
            }
            // 包括详细信息
            uiItemInfo = (
                <div>
                    <div className="package_scan_item_info_row" style={{ marginTop: '5vw' }}>
                        <div className="package_scan_item_info_title">货物净重：</div>
                        <Input className="package_scan_item_info_input package_scan_item_info_input_addon" ref="item_weight"
                            defaultValue={''} value={this.fItemWeight} onChange={this.ItemInfoChange.bind(this, 0)} />
                        <div className="package_scan_item_info_after">kg</div>
                    </div>
                    <div className="package_scan_item_info_row" style={{ marginTop: '5vw' }}>
                        <div className="package_scan_item_info_title">货物长度：</div>
                        <Input className="package_scan_item_info_input package_scan_item_info_input_addon" ref="item_length"
                            defaultValue={''} value={this.fItemLength} onChange={this.ItemInfoChange.bind(this, 1)} />
                        <div className="package_scan_item_info_after ">cm</div>
                    </div>
                    <div className="package_scan_item_info_row" style={{ marginTop: '5vw' }}>
                        <div className="package_scan_item_info_title ">货物宽度：</div>
                        <Input className="package_scan_item_info_input package_scan_item_info_input_addon" ref="item_width"
                            defaultValue={''} value={this.fItemWidth} onChange={this.ItemInfoChange.bind(this, 2)} />
                        <div className="package_scan_item_info_after">cm</div>
                    </div>
                    <div className="package_scan_item_info_row" style={{ marginTop: '5vw' }}>
                        <div className="package_scan_item_info_title ">货物高度：</div>
                        <Input className="package_scan_item_info_input package_scan_item_info_input_addon" ref="item_height"
                            defaultValue={''} value={this.fItemHeight} onChange={this.ItemInfoChange.bind(this, 3)} />
                        <div className="package_scan_item_info_after">cm</div>
                    </div>
                    <div className="package_scan_item_info_row" style={{ marginTop: '5vw' }}>
                        <div className="package_scan_item_info_title">{"计费重量：" + parseFloat(this.fItemPriceWeight).toFixed(2) + " kg"}</div>
                    </div>
                    <div className="package_scan_item_info_add_button" onClick={this.AddNewItemInfo}>+ 物品信息</div>
                    {uiItemInfoList}
                </div>
            );
            // 船期
            let uiShipDateMenu = (
                <Menu>
                    {this.arrShipDate.map((item, index) => {
                        return (
                            <Menu.Item key={index} onClick={this.SwitchShipDate.bind(this, index)}>
                                {item}
                            </Menu.Item>
                        );
                    })}
                </Menu>
            );
            uiShipDate = (
                <div className="package_scan_item_info_row" style={{ marginTop: '5vw' }}>
                    <div className="package_scan_item_info_title">本月船期：</div>
                    <Dropdown className="order_detail_dropdown_m" trigger={['click']}
                        overlay={uiShipDateMenu} placement="bottomLeft">
                        <Button>{this.arrShipDate[this.state.iShipDate]}<Icon type="caret-down" /></Button>
                    </Dropdown>
                </div>
            );
            // 货物品类
            let uiItemTypeMenu = (
                <Menu>
                    {this.arrItemType.map((item, index) => {
                        return (
                            <Menu.Item key={index} onClick={this.SwitchItemType.bind(this, index)}>
                                {item}
                            </Menu.Item>
                        );
                    })}
                </Menu>
            );
            uiItemType = (
                <div className="package_scan_item_info_row" style={{ marginTop: '5vw' }}>
                    <div className="package_scan_item_info_title">货物品类：</div>
                    <Dropdown className="order_detail_dropdown_m" trigger={['click']}
                        overlay={uiItemTypeMenu} placement="bottomLeft">
                        <Button>{this.arrItemType[this.state.iItemType]}<Icon type="caret-down" /></Button>
                    </Dropdown>
                </div>
            );
        }
        if (this.state.iExpressPackageStatus >= 5) {
            uiEnterNo = (
                <div className="package_scan_item_info_row" style={{ marginTop: '5vw' }}>
                    <div className="package_scan_item_info_title">{"进仓编码：" + this.objExpressEnter.enter_no}</div>
                </div>
            );
            uiReceiver = (
                <div className="package_scan_item_info_row" style={{ marginTop: '5vw' }}>
                    <div className="package_scan_item_info_title">{"收件人名：" + this.objExpressEnter.receiver}</div>
                </div>
            );
            uiStorageCode = (
                <div className="package_scan_item_info_row" style={{ marginTop: '5vw' }}>
                    <div className="package_scan_item_info_title">{"用户编码：" + this.objExpressEnter.storage_code}</div>
                </div>
            );
            uiPackageCombine = (
                <div className="package_scan_item_info_row" style={{ marginTop: '5vw' }}>
                    <div className="package_scan_item_info_title">{"合箱编码：" + this.objExpressEnter.package_no}</div>
                </div>
            );
        }
        let uiOnCancel = this.RestartScan;
        if (this.state.iExpressPackageStatus >= 3 && this.state.iExpressPackageStatus <= 4) {
            strCancelButton = "保存信息";
            uiOnCancel = this.ScanResultProcess.bind(this, false);
        }
        let uiResultDlg = null;
        if (this.state.bScanResultDlg) {
            let uiTitle = (
                <div className="package_scan_result_dlg_title">
                    扫描结果
                    <div className="package_scan_result_dlg_close_button" onClick={this.RestartScan}>x</div>
                </div>
            );
            uiResultDlg = (
                <Modal title={uiTitle} visible={this.state.bScanResultDlg} centered={true}
                    cancelText={strCancelButton} okText={strOkButtonText}
                    closable={false} maskClosable={false} width={'95vw'}
                    onOk={this.ScanResultProcess.bind(this, true)} onCancel={uiOnCancel} >
                    <div className="package_scan_item_info_title">{"快递单号：" + strResultOrder}</div>
                    {uiPackageCombine}
                    {uiShipDate}
                    {uiItemType}
                    {uiEnterNo}
                    {uiReceiver}
                    {uiStorageCode}
                    {uiComplete}
                    {uiItemInfo}
                </Modal>
            );
            uiScanArea = null;
        }
        let uiSubmitDlg = null;
        if (this.state.bSubmitResultDlg) {
            let strSubmitResult = "";
            let strOkButtonText = "好的";
            if (this.state.iSubmitResult === 1) {
                strSubmitResult = "快递 " + this.objExpressEnter.express_order + " 签收完成！";
            }
            if (this.state.iSubmitResult === 2) {
                strSubmitResult = "快递 " + this.objExpressEnter.express_order + " 签收完成！";
            }
            if (this.state.iSubmitResult === 3) {
                strSubmitResult = "快递 " + this.objExpressEnter.express_order + " 信息更新完成！";
            }
            if (this.state.iSubmitResult === 4) {
                strSubmitResult = "快递 " + this.objExpressEnter.express_order + " 合箱完成！";
            }
            if (this.state.iSubmitResult === -1) {
                strSubmitResult = "快递 " + this.objExpressEnter.express_order + " 签收失败，请重新扫描！";
                strOkButtonText = "再试一次";
            }
            if (this.state.iSubmitResult === -2) {
                strSubmitResult = "快递 " + this.objExpressEnter.express_order + " 合箱完成，请重新扫描！";
                strOkButtonText = "再试一次";
            }
            uiSubmitDlg = (
                <Modal title="提交结果" visible={this.state.bSubmitResultDlg} centered={true} okText={strOkButtonText} cancelText={"取消"} closable={false} maskClosable={false}
                    onOk={this.RestartScan} onCancel={this.RestartScan} >
                    {strSubmitResult}
                </Modal>
            );
            uiScanArea = null;
        }
        let uiOgeScanResult = (
            <div className="code_scanner_oge_result">{this.state.strOgeScanResult}</div>
        );
        if (this.state.iScanMethod === 0)
            uiOgeScanResult = null;
        let uiEditDlg = null;
        if (this.state.iEditExpresssInfo >= 0) {
            // 编辑包裹
            if (this.state.iScanPurpose === 0) {
                let menuExpressStatus = (
                    <Menu>
                        {this.arrExpressStatus.map((status, index) => {
                            return (
                                <Menu.Item key={index} onClick={this.SwitchExpressPackageStatus.bind(this, index)}>
                                    {status}
                                </Menu.Item>
                            );
                        })}
                    </Menu>
                );
                // 进仓编号中的物品信息列表
                let uiItemInfoList = null;
                if (this.objCurrentExpress.express_info !== undefined && this.objCurrentExpress.express_info !== null &&
                    this.objCurrentExpress.express_info !== "") {
                    uiItemInfoList = (
                        this.arrItemInfo.map((item, indexItem) => {
                            let strItemBlockStyle = "express_item_info_block";
                            if (indexItem % 2 === 0)
                                strItemBlockStyle = "express_item_info_block_light";
                            return (
                                <div key={indexItem} className={strItemBlockStyle}>
                                    <div className="express_item_info_row">
                                        <div className="express_enter_edit_title_m">名称</div>
                                        <Input style={{ width: '30%' }} ref={"item_name_" + indexItem}
                                            defaultValue={item.cn} value={item.cn}
                                            onChange={this.ItemDetailsChange.bind(this, indexItem, 0)} />
                                        <div className="express_enter_edit_title_m">件数</div>
                                        <Input style={{ width: '20%' }} ref={"item_num_" + indexItem}
                                            defaultValue={item.num} value={item.num}
                                            onChange={this.ItemDetailsChange.bind(this, indexItem, 1)} />
                                        <div className="express_item_delete_button" style={{ marginLeft: 'auto', marginRight: '0px' }}
                                            onClick={this.RemoveItemInfo.bind(this, indexItem)}>
                                            <Icon type="delete" theme="filled" />
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    );
                }
                uiEditDlg = (
                    <Modal title={'编辑包裹信息'} visible cancelText={'取消'} okText={'确认'}
                        closable={true} maskClosable={true} width={'95vw'}
                        onOk={this.EditConfirm.bind(this, true)} onCancel={this.EditConfirm.bind(this, false)} >
                        {/* <div className="package_scan_item_info_title" style={{ marginBottom: '20px' }}>
                            {"用户名称"}
                            <Input className="code_scanner_input" style={{ width: '50vw', marginLeft: '10px' }}
                                defaultValue={this.objCurrentExpress.user_name} ref="edit_user_name" />
                        </div> */}
                        <div className="package_scan_item_info_title" style={{ marginBottom: '20px', width: '100%' }}>
                            {"用户编码"}
                            <Input className="code_scanner_input" style={{ width: '50vw', marginLeft: '10px' }}
                                defaultValue={this.objCurrentExpress.storage_code} ref="edit_user_code" />
                        </div>
                        <div className="package_scan_item_info_title" style={{ marginBottom: '20px', width: '100%' }}>
                            {"包裹状态"}
                            <Dropdown className="code_scanner_input" trigger={['click']}
                                overlay={menuExpressStatus} placement="bottomLeft"
                                style={{ width: '50vw', marginLeft: '10px' }}>
                                <Button style={{ width: '50vw', maxWidth: '50vw', marginLeft: '10px' }}>
                                    {this.arrExpressStatus[this.state.iExpressPackageStatus]}<Icon type="caret-down" />
                                </Button>
                            </Dropdown>
                        </div>
                        <div className="package_scan_item_info_title" style={{ marginBottom: '20px', width: '100%' }}>
                            {"合箱编码"}
                            <Input className="code_scanner_input" style={{ width: '50vw', marginLeft: '10px' }}
                                defaultValue={this.objCurrentExpress.auto_order_no} ref="edit_auto_order_no" />
                            <div className="package_scan_item_info_add_button" onClick={this.AutoFillPackageNo.bind(this, 0)}
                                style={{ width: '35px', height: '35px', marginLeft: '0px', marginRight: '0px', marginBottom: '20px' }}>
                                <Icon type="history" style={{ fontSize: '5vw', fontWeight: 'bold' }} /></div>
                        </div>
                        <div className="package_scan_item_info_add_button" onClick={this.AddNewItemInfo}>+ 物品信息</div>
                        {uiItemInfoList}
                    </Modal>
                );
            }
            // 编辑运单
            if (this.state.iScanPurpose === 1) {
                let menuPackageStatus = (
                    <Menu>
                        {this.arrPackageStatus.map((status, index) => {
                            return (
                                <Menu.Item key={index} onClick={this.SwitchExpressPackageStatus.bind(this, index)}>
                                    {status}
                                </Menu.Item>
                            );
                        })}
                    </Menu>
                );
                // 进仓编号中的物品信息列表
                let uiExpressList = null;
                uiExpressList = (
                    this.arrExpressInfo.map((express, indexExpress) => {
                        let strItemBlockStyle = "express_item_info_block";
                        if (indexExpress % 2 === 0)
                            strItemBlockStyle = "express_item_info_block_light";
                        return (
                            <div key={indexExpress} className={strItemBlockStyle}>
                                <div className="express_item_info_row">
                                    <div className="express_enter_edit_title_m">单号</div>
                                    <Input style={{ width: '70%' }} ref={"express_order" + indexExpress}
                                        defaultValue={express.express_order} value={express.express_order}
                                        onChange={this.ItemDetailsChange.bind(this, indexExpress, 2)} />
                                    {/* <div className="express_enter_edit_title_m">件数</div>
                                    <Input style={{ width: '20%' }} ref={"item_num_" + indexItem}
                                        defaultValue={express.num} value={express.num}
                                        onChange={this.ItemDetailsChange.bind(this, indexItem, 1)} /> */}
                                    <div className="express_item_delete_button" style={{ marginLeft: 'auto', marginRight: '0px' }}
                                        onClick={this.RemoveExpressInfo.bind(this, indexExpress)}>
                                        <Icon type="delete" theme="filled" />
                                    </div>
                                </div>
                            </div>
                        );
                    })
                );
                uiEditDlg = (
                    <Modal title={'编辑运单'} visible cancelText={'取消'} okText={'确认'}
                        closable={true} maskClosable={true} width={'95vw'}
                        onOk={this.EditConfirm.bind(this, true)} onCancel={this.EditConfirm.bind(this, false)} >
                        <div className="package_scan_item_info_title" style={{ marginBottom: '20px' }}>
                            {"合箱编码"}
                            <Input className="code_scanner_input" style={{ width: '50vw', marginLeft: '10px' }}
                                defaultValue={this.objCurrentPackage.auto_order_no} ref="auto_order_no" />
                        </div>
                        {/* <div className="package_scan_item_info_title" style={{ marginBottom: '20px' }}>
                            {"用户编码"}
                            <Input className="code_scanner_input" style={{ width: '50vw', marginLeft: '10px' }}
                                defaultValue={this.arrExpressInfo[0].storage_code} ref="edit_user_code" />
                        </div> */}
                        <div className="package_scan_item_info_title" style={{ marginBottom: '20px' }}>
                            {"运单状态"}
                            <Dropdown className="code_scanner_input" trigger={['click']}
                                overlay={menuPackageStatus} placement="bottomLeft"
                                style={{ width: '50vw', marginLeft: '10px' }}>
                                <Button style={{ width: '50vw', maxWidth: '50vw', marginLeft: '10px' }}>
                                    {this.arrPackageStatus[this.state.iExpressPackageStatus]}<Icon type="caret-down" />
                                </Button>
                            </Dropdown>
                        </div>
                        {/* <div className="package_scan_item_info_add_button" onClick={this.AddNewExpressInfo}>+ 包裹信息</div> */}
                        {uiExpressList}
                    </Modal>
                );
            }
        }

        return (
            <div className="code_scanner_page">
                <div className="code_scanner_title">
                    <div className={strQRButtonStyle} onClick={this.SwitchScanMethod.bind(this, 0)}>扫码枪</div>
                    <div className={strBARButtonStyle} onClick={this.SwitchScanMethod.bind(this, 1)}>手机扫码</div>
                    <div className={strPhotoButtonStyle} onClick={this.SwitchScanMethod.bind(this, 2)}>拍照</div>
                </div>
                {uiScanArea}
                {uiOgeScanResult}
                {uiResultDlg}
                {uiSubmitDlg}
                {uiEditDlg}
            </div>
        );

    }
}
export default ScannerPackage;
