import React, { Component } from 'react';
import axios from 'axios';
import XLSX from 'xlsx';

import Spin from 'antd/es/spin';
import Table from 'antd/es/table';
import Button from 'antd/es/button';
import Input from 'antd/es/input';
import Icon from 'antd/es/icon';
import Modal from 'antd/es/modal';
import message from 'antd/es/message';

import '../../../styles/Background/ego_manager.css';
import '../../../styles/Background/storage_manager.css';
import '../../../styles/Background/UI_Module/user_management.css';
import { UserActiveUpdate } from "../../../functions/DataStatistic.js"

const { TextArea } = Input;

const g_strMessageKey = 'updating';
global.arrStorageInData = [];

// 将一个sheet转成最终的excel文件的blob对象，然后利用URL.createObjectURL下载
function sheet2blob(sheet, sheetName) {
    sheetName = sheetName || 'sheet1';
    var workbook = {
        SheetNames: [sheetName],
        Sheets: {}
    };
    workbook.Sheets[sheetName] = sheet;
    // 生成excel的配置项
    var wopts = {
        bookType: 'xlsx', // 要生成的文件类型
        bookSST: false, // 是否生成Shared String Table，官方解释是，如果开启生成速度会下降，但在低版本IOS设备上有更好的兼容性
        type: 'binary'
    };
    var wbout = XLSX.write(workbook, wopts);
    var blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });
    // 字符串转ArrayBuffer
    function s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
    }
    return blob;
}
//  通用的打开下载对话框方法，没有测试过具体兼容性
//  @param url 下载地址，也可以是一个blob对象，必选
//  @param saveName 保存文件名，可选
function openDownloadDialog(url, saveName) {
    if (typeof url == 'object' && url instanceof Blob) {
        url = URL.createObjectURL(url); // 创建blob地址
    }
    var aLink = document.createElement('a');
    aLink.href = url;
    aLink.download = saveName || ''; // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，file:///模式下不会生效
    var event;
    if (window.MouseEvent) event = new MouseEvent('click');
    else {
        event = document.createEvent('MouseEvents');
        event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
    }
    aLink.dispatchEvent(event);
}

// 表格
const arrTableHeader1 = [
    // { title: 'ID', dataIndex: 'id', width: 35, ellipsis: true },
    { title: '客户', dataIndex: 'client_name', width: 100, ellipsis: true },
    { title: '柜号', dataIndex: 'closet', width: 100, ellipsis: true },
    { title: '货物名称', dataIndex: 'item_name', width: 100, ellipsis: true },
    { title: 'Ref#', dataIndex: 'item_ref', width: 75, ellipsis: true },
    { title: '库存', dataIndex: 'item_num', width: 50, ellipsis: true },
    { title: '库位/货架', dataIndex: 'location', width: 80, ellipsis: true },
    { title: '入库日期', dataIndex: 'storage_in_date_str', width: 75, ellipsis: true }
    // { title: '出库日期', dataIndex: 'out_time_str', width: 75, ellipsis: true }
];
const arrTableHeader2 = [
    { title: '柜号', dataIndex: 'closet', width: 100, ellipsis: true },
    { title: '货物名称', dataIndex: 'item_name', width: 100, ellipsis: true },
    { title: 'Ref#', dataIndex: 'item_ref', width: 75, ellipsis: true },
    { title: '库存', dataIndex: 'item_num', width: 50, ellipsis: true },
    { title: '库位/货架', dataIndex: 'location', width: 80, ellipsis: true },
    { title: '入库日期', dataIndex: 'storage_in_date_str', width: 75, ellipsis: true }
    // { title: '出库日期', dataIndex: 'out_time_str', width: 75, ellipsis: true }
];

class LogisticStorageInventory extends Component {
    constructor(props) {
        super();
        this.state = {
            btnControlDisable: [false, true, true],  // 操作按钮控制
            btnCopyPasteDisable: [true, true],  // 操作按钮控制
            selectedRowKeys: [],  // Check here to configure the default column
            iDlgType: -1,
            bShowDlg: false,
            bLoading: true,
            bSelectClientDlg: false,
            bExport: true
        };
        // 成员变量
        this.arrOgeTableData = [];
        this.arrTableData = [];  // 用于表格的数组
        this.objCurrentStorageIn = {};
        this.arrItemList = [];
        this.objItemInit = {
            name: "", notes: "", reference: '', num: 0, weight: '0.0', length: '0.0', width: '0.0', height: '0.0'
        };
        this.iCurrentClient = 0;
        this.arrSelectStorage = [];
    }

    // 选中某行的响应函数
    selectRow = (record) => {
        const selectedRowKeys = [...this.state.selectedRowKeys];
        if (selectedRowKeys.indexOf(record.key) >= 0) {
            selectedRowKeys.splice(selectedRowKeys.indexOf(record.key), 1);
        } else {
            selectedRowKeys.push(record.key);
        }
        this.SelectedRowStateSetting(selectedRowKeys);
    }
    // 选中的行发生变化的响应函数
    onSelectedRowKeysChange = (selectedRowKeys) => {
        this.SelectedRowStateSetting(selectedRowKeys);
    }
    // 处理选中的表行行
    SelectedRowStateSetting = (selectedRowKeys) => {
        let btnControlDisable = [];
        let btnCopyPasteDisable = [];
        let bExport = true;
        switch (selectedRowKeys.length) {
            case 0: {
                btnControlDisable = [false, true, true];
                btnCopyPasteDisable = [true, true];
                this.objCurrentStorageIn = {};
                break;
            }
            case 1: {
                btnControlDisable = [false, false, false];
                btnCopyPasteDisable = [false, true];
                // for (let i = 0; i < this.arrTableData.length; i++) {
                //     if (this.arrTableData[i].key === selectedRowKeys[0]) {
                //         this.objCurrentStorageIn = this.arrTableData[i];
                //         break;
                //     }
                // }
                this.arrSelectStorage = [];
                btnControlDisable = [false, false, false, false];
                for (let i = 0; i < this.arrTableData.length; i++) {
                    if (this.arrTableData[i].key === selectedRowKeys[0]) {
                        this.objCurrentStorageIn = this.arrTableData[i];
                        this.arrSelectStorage.push(this.arrTableData[i]);
                        break;
                    }
                }
                bExport = false;
                break;
            }
            default: {
                btnControlDisable = [false, true, true]; btnCopyPasteDisable = [true, true];
                bExport = false;
                this.arrSelectStorage = [];
                btnControlDisable = [false, true, true, false];
                for (let j = 0; j < this.arrTableData.length; j++) {
                    for (let i = 0; i < this.arrTableData.length; i++) {
                        if (this.arrTableData[i].key === selectedRowKeys[j]) {
                            this.arrSelectStorage.push(this.arrTableData[i]);
                            break;
                        }
                    }
                }
                console.log(this.arrSelectStorage);
                break;
            }
        }
        this.setState({
            ...this.state,
            selectedRowKeys,
            btnControlDisable: btnControlDisable,
            btnCopyPasteDisable: btnCopyPasteDisable,
            bExport: bExport
        });
    }
    // 关键词处理
    KeywordsProcess = (strKeywords) => {
        let newKeywords = "";
        for (let i = 0; i < strKeywords.length; i++) {
            if (strKeywords[i] !== " ") {
                if (i === strKeywords.length - 1)
                    newKeywords += strKeywords[i];
                else
                    newKeywords += strKeywords[i] + "%";
            }
        }
        return newKeywords;
    }
    // 搜索入库名
    StorageInSearch = (bShowAll) => {
        if (this.props.iUserLevel) {
            if (bShowAll) {
                this.refs.closet_search.state.value = "";
                this.refs.name_search.state.value = "";
                this.GetStorageData();
            } else {
                let strClient = "";
                let strCloset = this.refs.closet_search.state.value;
                let strKeywords = this.refs.name_search.state.value;
                if (strClient === undefined || strClient === null)
                    strClient = "";
                if (strCloset === undefined || strCloset === null)
                    strCloset = "";
                if (strKeywords === undefined || strKeywords === null)
                    strKeywords = "";
                this.setState({
                    ...this.state,
                    bLoading: true,
                    selectedRowKeys: [],
                    btnControlDisable: [false, true, true]
                });

                axios('/SearchStorageIn', {
                    params: {
                        client: this.KeywordsProcess(strClient),
                        closet: this.KeywordsProcess(strCloset),
                        key: this.KeywordsProcess(strKeywords),
                        store_id: this.props.iStoreID
                    }
                }).then(({ data }) => {
                    let data1 = data;
                    // console.log("search results : ", data);
                    axios('/GetStorageOut', { params: { store_id: this.props.iStoreID } })
                        .then(({ data }) => {
                            let data2 = data;
                            this.arrTableData = this.OrganizeStorageData(data1, data2);
                            this.setState({
                                ...this.state,
                                bLoading: false,
                                objFilter: {
                                    strKeyword: strKeywords,
                                    iCurrentUserStatus: 0,
                                    iCurrentUserType: 0
                                }
                            });
                        }).catch(function (error) { console.log(error); });

                }).catch(function (error) { console.log(error); });
            }
        } else {
            if (bShowAll) {
                this.refs.client_filter.state.value = "";
                this.refs.closet_search.state.value = "";
                this.refs.name_search.state.value = "";
                this.GetStorageData();
            } else {
                let strClient = this.refs.client_filter.state.value;
                let strCloset = this.refs.closet_search.state.value;
                let strKeywords = this.refs.name_search.state.value;
                if (strClient === undefined || strClient === null)
                    strClient = "";
                if (strCloset === undefined || strCloset === null)
                    strCloset = "";
                if (strKeywords === undefined || strKeywords === null)
                    strKeywords = "";
                this.setState({
                    ...this.state,
                    bLoading: true,
                    selectedRowKeys: [],
                    btnControlDisable: [false, true, true]
                });

                axios('/SearchStorageIn', {
                    params: {
                        client: this.KeywordsProcess(strClient),
                        closet: this.KeywordsProcess(strCloset),
                        key: this.KeywordsProcess(strKeywords),
                        store_id: this.props.iStoreID
                    }
                }).then(({ data }) => {
                    let data1 = data;
                    // console.log("search results : ", data);
                    axios('/GetStorageOut', { params: { store_id: this.props.iStoreID } })
                        .then(({ data }) => {
                            let data2 = data;
                            this.arrTableData = this.OrganizeStorageData(data1, data2);
                            this.setState({
                                ...this.state,
                                bLoading: false,
                                objFilter: {
                                    strKeyword: strKeywords,
                                    iCurrentUserStatus: 0,
                                    iCurrentUserType: 0
                                }
                            });
                        }).catch(function (error) { console.log(error); });

                }).catch(function (error) { console.log(error); });
            }
        }


    }

    /* 自定义成员函数 */
    //弹出对话框
    ShowDlg = (iDlgType, e) => {
        if (iDlgType === 0) {
            if (this.refs.closet !== undefined) {
                this.refs.closet.state.value = "";
                this.refs.comments.state.value = "";
            }
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true,
                strNewPassword: "",
                strConfirmPassword: ""
            });
        }
        if (iDlgType === 1) {
            if (this.refs.closet !== undefined) {
                this.refs.closet.state.value = this.objCurrentStorageIn.closet;
                this.refs.comments.state.value = this.objCurrentStorageIn.comments;
            }
            this.arrItemList = JSON.parse(this.objCurrentStorageIn.item_info);
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true,
                strNewPassword: "",
                strConfirmPassword: ""
            });
        }
        if (iDlgType === 2) {
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true
            });
        }
    }
    // 弹窗确认按钮响应
    handleOk = e => {
        if (this.state.iDlgType <= 1) {
            let strCloset = this.refs.closet.state.value;
            if (strCloset === null || strCloset === "" || strCloset === undefined) {
                message.warning("柜号不能为空！"); return;
            }
            let strClient = this.refs.client_name.state.value;
            if (strClient === null || strClient === "" || strClient === undefined) {
                message.warning("客户不能为空"); return;
            }
            let bItemCheck = true;
            if (this.arrItemList.length >= 1) {
                for (let i = 0; i < this.arrItemList.length; i++) {
                    if (this.arrItemList[i].name === "" || this.arrItemList[i].name === undefined || this.arrItemList[i].name === null) {
                        message.warning("货物名称不能为空！");
                        bItemCheck = false;
                        break;
                    }
                    if (this.arrItemList[i].num === "" || this.arrItemList[i].num === undefined || this.arrItemList[i].num === null || this.arrItemList[i].num <= 0) {
                        message.warning("货物数量不能为空！");
                        bItemCheck = false;
                        break;
                    }
                    if (this.arrItemList[i].weight === "" || this.arrItemList[i].weight === undefined || this.arrItemList[i].weight === null) {
                        message.warning("货物重量不能为空！");
                        bItemCheck = false;
                        break;
                    }
                }
            } else {
                message.warning("货物不能为空！");
                bItemCheck = false;
            }
            if (!bItemCheck)
                return;
            let objNewStorageIn = {
                store_id: this.props.iStoreID,
                // user_id: this.props.iUserID,
                // user_name: this.props.strUserName,
                // team: this.props.iUserTeam,
                client_id: this.iCurrentClient,
                client_name: strClient,
                closet: strCloset,
                comments: this.refs.comments.state.value,
                item_info: JSON.stringify(this.arrItemList),
                // create_time: new Date(),
                update_time: new Date()
            };
            if (this.state.iDlgType === 0) {
                objNewStorageIn = {
                    store_id: this.props.iStoreID,
                    user_id: this.props.iUserID,
                    user_name: this.props.strUserName,
                    team: this.props.iUserTeam,
                    client_id: this.iCurrentClient,
                    client_name: strClient,
                    closet: strCloset,
                    comments: this.refs.comments.state.value,
                    item_info: JSON.stringify(this.arrItemList),
                    create_time: new Date(),
                    update_time: new Date()
                };
                message.loading({ content: '正在创建新的入库记录……', key: g_strMessageKey });
                axios.post('/CreateNewStorageIn', objNewStorageIn)
                    .then(({ data }) => {
                        if (data.status === 1) {
                            this.GetStorageData();
                        } else {
                            message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
                        }
                    }).catch(function (error) { console.log(error); });
            }
            if (this.state.iDlgType === 1) {
                message.loading({ content: '正在更新入库记录……', key: g_strMessageKey });
                axios.post('/UpdateStorageIn', {
                    objCurrentStorageIn: this.objCurrentStorageIn,
                    objNewStorageIn: objNewStorageIn
                }).then(({ data }) => {
                    if (data.status === 1)
                        this.GetStorageData();
                    else
                        message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
                }).catch(function (error) { console.log(error); });
            }
        }
        // 删除入库记录
        if (this.state.iDlgType === 2) {
            message.loading({ content: '入库删除中……', key: g_strMessageKey });
            axios.post('/DeleteStorageIn', { id: this.objCurrentStorageIn.id })
                .then(({ data }) => {
                    if (data.status === -1) {
                        message.warning({
                            content: "删除失败，该入库已绑定商家，请先检查并删除商家！",
                            key: g_strMessageKey, duration: 2
                        });
                        this.setState({
                            ...this.state,
                            bLoading: false,
                            bShowDlg: false,
                            selectedRowKeys: []
                        });
                    } else {
                        // DataStatistic
                        UserActiveUpdate(4, { active: "管理后台: 入库管理操作", action: "删除" });
                        this.GetStorageData();
                    }
                }).catch(function (error) { console.log(error); });
        }
    };
    // 弹窗取消按钮响应
    handleCancel = e => {
        this.setState({
            bShowDlg: false,
            selectedRowKeys: []
        });
    };
    // 整理StorageIn数据
    OrganizeStorageData(data1, data2) {
        let arrTableData = [];
        for (let i = 0; i < data1.length; i++) {
            let arrItemList = [];
            if (data1[i].item_info !== null && data1[i].item_info !== undefined && data1[i].item_info !== "")
                arrItemList = JSON.parse(data1[i].item_info);

            for (let j = 0; j < arrItemList.length; j++) {
                let strDate = data1[i].storage_in_date;
                if (strDate.indexOf('T') >= 0) {
                    strDate = strDate.split('T')[0];
                }
                let objStorageInData = {
                    key: i * 100 + j, id: data1[i].id,
                    client_name: data1[i].client_name,
                    closet: data1[i].closet,
                    item_name: arrItemList[j].name,
                    item_ref: arrItemList[j].reference,
                    item_num: arrItemList[j].num,  // 入库库存
                    comments: data1[i].comments,
                    location: data1[i].location,
                    storage_in_date: data1[i].storage_in_date,
                    storage_in_date_str: strDate
                }
                let strKeywords = this.refs.name_search.state.value;
                if (strKeywords !== undefined && strKeywords !== null && strKeywords !== "") {
                    if (arrItemList[j].name.indexOf(strKeywords) >= 0 || arrItemList[j].reference.indexOf(strKeywords) >= 0)
                        arrTableData.push(objStorageInData);
                } else
                    arrTableData.push(objStorageInData);
            }
        }
        // 融入出库库存
        for (let i = 0; i < data2.length; i++) {
            let arrItemList = [];
            if (data2[i].item_info !== null && data2[i].item_info !== undefined && data2[i].item_info !== "")
                arrItemList = JSON.parse(data2[i].item_info);
            for (let j = 0; j < arrItemList.length; j++) {
                for (let k = 0; k < arrTableData.length; k++) {
                    if (arrTableData[k].item_name === arrItemList[j].name) {
                        arrTableData[k].item_num = parseInt(arrTableData[k].item_num) - parseInt(arrItemList[j].out);
                        break;
                    }
                }
            }
        }
        console.log("result : ", arrTableData);

        return arrTableData;
    }
    // 从数据库获取当前的数据结构
    GetStorageData() {
        message.loading({ content: '数据加载中……', key: g_strMessageKey });
        axios('/GetStorageIn', {
            params: {
                store_id: this.props.iStoreID, level: this.props.iUserLevel,
                client_id: this.props.iClientID
            }
        })
            .then(({ data }) => {
                console.log("GetStorageInData : ", data);
                global.arrStorageInData = data;
                axios('/GetStorageOut', {
                    params: {
                        store_id: this.props.iStoreID, level: this.props.iUserLevel,
                        client_id: this.props.iClientID
                    }
                })
                    .then(({ data }) => {
                        console.log("GetStorageOutData : ", data);
                        global.arrStorageOutData = data;
                        this.arrOgeTableData = this.OrganizeStorageData(global.arrStorageInData, global.arrStorageOutData);
                        this.arrTableData = this.arrOgeTableData;
                        message.success({ content: "数据加载完成！", key: g_strMessageKey, duration: 2 });
                        this.setState({
                            ...this.state,
                            bLoading: false,
                            bShowDlg: false,
                            selectedRowKeys: []
                        });
                    }).catch(function (error) { console.log(error); });
            }).catch(function (error) { console.log(error); });
    }
    // 获取客户数据
    GetClientsData() {
        axios('/GetClients', { params: { store_id: this.props.objStoreInfo.id } })
            .then(({ data }) => {
                global.arrClientData = data;
            }).catch(function (error) { console.log(error); });
    }
    // 选择客户
    SelectClient = () => {
        // console.log("SelectClient : ", this.state.bShowDlg);
        if (this.state.bShowDlg) {
            // if (this.refs.client_search !== undefined)
            //     this.refs.client_search.state.value = "";
            this.setState({
                ...this.state,
                bSelectClientDlg: true
            });
        } else {
            // if (this.refs.client_filter !== undefined)
            //     this.refs.client_filter.state.value = "";
            this.setState({
                ...this.state,
                bSelectClientFilterDlg: true
            });
        }

    }
    // 选择客户弹窗结果
    ClientSelectResult = (index) => {
        if (this.state.bShowDlg) {
            if (index >= 0) {
                this.refs.client_name.state.value = global.arrClientData[index].name;
                this.iCurrentClient = global.arrClientData[index].id;
            }
        } else {
            if (index >= 0) {
                this.refs.client_filter.state.value = global.arrClientData[index].name;
            }
        }

        this.setState({
            ...this.state,
            bSelectClientDlg: false,
            bSelectClientFilterDlg: false
        })
    }
    // 搜索/筛选客户
    SearchClient = () => {
        this.setState({
            ...this.state,
            bLoading: false
        })
    }
    // 新增货物
    AddItem = () => {
        let objItemInit = {
            name: "", notes: "", reference: '', num: 0, weight: '0.0', length: '0.0', width: '0.0', height: '0.0'
        };
        this.arrItemList.push(objItemInit);
        this.setState({
            ...this.state,
            bLoading: false
        })
    }
    // 更新货物信息
    ItemValueChange = (index, type, e) => {
        if (type === 0) {
            this.arrItemList[index].name = e.target.value;
        }
        if (type === 1) {
            this.arrItemList[index].notes = e.target.value;
        }
        if (type === 2) {
            this.arrItemList[index].reference = e.target.value;
        }
        if (type === 3) {
            this.arrItemList[index].num = e.target.value;
        }
        if (type === 4) {
            this.arrItemList[index].weight = e.target.value;
        }
        if (type === 5) {
            this.arrItemList[index].length = e.target.value;
        }
        if (type === 6) {
            this.arrItemList[index].width = e.target.value;
        }
        if (type === 7) {
            this.arrItemList[index].height = e.target.value;
        }

        this.setState({
            ...this.state,
            bLoading: false
        })
    }
    // 新增货物
    DeleteItem = (index) => {
        if (this.arrItemList.length <= 1) {
            message.warning("至少保留一样货物！");
            return;
        }
        this.arrItemList.splice(index, 1);
        this.setState({
            ...this.state,
            bLoading: false
        })
    }
    // 导出Excel文档
    ExportExcelFile = () => {
        console.log("导出库存列表：", this.arrSelectStorage);
        let arrExportData = [
            ['客户', '柜号', '货物名称', 'Ref#', '库存', '库位/货架', '入库日期']
        ];
        for (let i = 0; i < this.arrSelectStorage.length; i++) {
            let arrData = [];
            arrData.push(this.arrSelectStorage[i].client_name);
            arrData.push(this.arrSelectStorage[i].closet);
            arrData.push(this.arrSelectStorage[i].item_name);
            arrData.push(this.arrSelectStorage[i].item_ref);
            arrData.push(parseInt(this.arrSelectStorage[i].item_num));
            arrData.push(this.arrSelectStorage[i].location);
            arrData.push(this.arrSelectStorage[i].storage_in_date);
            arrExportData.push(arrData);
        }
        console.log("export excel file", arrExportData);
        var sheet = XLSX.utils.aoa_to_sheet(arrExportData);
        openDownloadDialog(sheet2blob(sheet), '库存列表.xlsx');

    }

    /* React自带函数 */
    // 卸载异步操作设置状态
    componentWillUnmount() {
        clearTimeout(this.timeouter)
        this.setState = (state, callback) => {
            return;
        }
    }
    // 渲染完毕……
    componentDidMount() {
        this.GetStorageData();
        if (global.arrClientData.length <= 0)
            this.GetClientsData();
    }
    // 渲染函数
    render() {
        /* 入库管理页面控制栏 */
        let uiTitleArea = (
            < div className="logistics_page_title" >
                <div className="manage_stretch_right">
                    <div className="logistic_filter_title" style={{ minWidth: '36px' }}>客户</div>
                    <Input className="logistic_search_input" ref="client_filter" style={{ marginRight: '0px' }} />
                    <Button type='primary' className="manage_contents_title_margin" onClick={this.SelectClient.bind(this)}>选 择</Button>
                    <div className="logistic_filter_title" style={{ minWidth: '36px', marginLeft: '20px' }}>柜号</div>
                    <Input placeholder="柜号" ref="closet_search" defaultValue={this.state.strKeywords} />
                    <div className="logistic_filter_title" style={{ minWidth: '50px', marginLeft: '20px' }}>关键字</div>
                    <Input placeholder="货物名称/Ref#/备注/操作人" ref="name_search" defaultValue={this.state.strKeywords} />
                    <Button className="manage_contents_title_margin" type="primary" onClick={this.StorageInSearch.bind(this, false)}>搜 索
                    </Button>
                    <Button type='primary' className="manage_contents_title_margin" onClick={this.StorageInSearch.bind(this, true)}>显示全部</Button>
                </div>
                <div className="manage_title_button_right_side">
                    <Button className="manage_contents_title_margin" type="primary" onClick={this.ExportExcelFile.bind(this)} disabled={this.state.bExport}>
                        <Icon type="edit" />导出</Button>
                    {/* <Button className="manage_contents_title_margin" type="primary" onClick={this.ShowDlg.bind(this, 0)} >
                        <Icon type="plus" />新建</Button>
                    <Button className="manage_contents_title_margin" type="primary" onClick={this.ShowDlg.bind(this, 1)} disabled={this.state.btnControlDisable[1]}>
                        <Icon type="edit" />查看</Button>
                    <Button className="manage_contents_title_margin" type="danger" onClick={this.ShowDlg.bind(this, 2)} disabled={this.state.btnControlDisable[2]}>
                        <Icon type="close" /> 删除</Button> */}
                </div>
            </div >
        );
        if (this.props.iUserLevel === 1) {
            uiTitleArea = (
                < div className="logistics_page_title" >
                    <div className="manage_stretch_right">

                        <div className="logistic_filter_title" style={{ minWidth: '36px', marginLeft: '20px' }}>柜号</div>
                        <Input placeholder="柜号" ref="closet_search" defaultValue={this.state.strKeywords} />
                        <div className="logistic_filter_title" style={{ minWidth: '50px', marginLeft: '20px' }}>关键字</div>
                        <Input placeholder="货物名称/Ref#/备注/操作人" ref="name_search" defaultValue={this.state.strKeywords} />
                        <Button className="manage_contents_title_margin" type="primary" onClick={this.StorageInSearch.bind(this, false)}>搜 索
                    </Button>
                        <Button type='primary' className="manage_contents_title_margin" onClick={this.StorageInSearch.bind(this, true)}>显示全部</Button>
                    </div>
                    <div className="manage_title_button_right_side">
                        <Button className="manage_contents_title_margin" type="primary" onClick={this.ExportExcelFile.bind(this)} disabled={this.state.bExport}>
                            <Icon type="edit" />导出</Button>
                    </div>
                </div >
            );
        }

        // 如果界面上需要显示的内容已经从数据库获得
        if (!this.state.bLoading) {
            // 主体表格相关
            const { selectedRowKeys } = this.state;
            const rowSelection = {
                selectedRowKeys,
                onChange: this.onSelectedRowKeysChange,
                columnWidth: 30
            };
            // 表格分页属性
            const paginationProps = {
                showSizeChanger: true,
                showQuickJumper: false,
                showTotal: () => `共 ${this.arrTableData.length} 条 库存记录`,
                defaultPageSize: 10,
                defaultCurrent: this.state.iCurrentPage,
                pageSizeOptions: ['10', '20', '30', '40', '50'],
                total: this.arrTableData.length,
                position: 'bottom'
            };

            let uiItemList = null;
            if (this.arrItemList.length <= 0) {
                let objItemInit = {
                    name: "", notes: "", reference: '', num: 0, weight: '0.0', length: '0.0', width: '0.0', height: '0.0'
                };
                this.arrItemList.push(objItemInit);
            }
            let iItemTotalNum = 0;
            uiItemList = this.arrItemList.map((item, index) => {
                let strStyle = "logistics_item_list_area1 ";
                if (index % 2 !== 0)
                    strStyle += "logistics_item_list_area2";
                iItemTotalNum += parseInt(item.num);
                return (
                    <div className={strStyle} key={index}>
                        <div className="user_edit_info_row">
                            <div className="user_edit_info_title" style={{ color: 'lightcoral' }}>名称：</div>
                            <Input className="user_edit_info_input" ref={"name" + index} style={{ marginRight: '30px' }}
                                value={item.name} onChange={this.ItemValueChange.bind(this, index, 0)} />
                            <div className="user_edit_info_title">描述：</div>
                            <Input className="user_edit_info_input_long" ref={"notes" + index} style={{ marginRight: '30px' }}
                                value={item.notes} onChange={this.ItemValueChange.bind(this, index, 1)} />
                            <Button className="logistics_small_button" type="danger" onClick={this.DeleteItem.bind(this, index)}>X</Button>
                        </div>
                        <div className="user_edit_info_row">
                            <div className="user_edit_info_title" style={{ color: 'lightcoral' }}>数量：</div>
                            <Input className="user_edit_info_input" ref={"num" + index} style={{ marginRight: '30px' }}
                                value={item.num} onChange={this.ItemValueChange.bind(this, index, 3)} />
                            <div className="user_edit_info_title" style={{ color: 'lightcoral' }}>重量：</div>
                            <Input className="user_edit_info_input" ref={"weight" + index} style={{ marginRight: '30px' }} addonAfter={"KG"}
                                value={item.weight} onChange={this.ItemValueChange.bind(this, index, 4)} />
                            <div className="user_edit_info_title">Reference</div>
                            <Input className="user_edit_info_input" ref={"reference" + index} style={{ marginRight: '30px' }}
                                value={item.reference} onChange={this.ItemValueChange.bind(this, index, 2)} />
                        </div>
                        <div className="user_edit_info_row" >
                            <div className="user_edit_info_title">长度：</div>
                            <Input className="user_edit_info_input" ref={"length" + index} style={{ marginRight: '30px' }} addonAfter={"CM"}
                                value={item.length} onChange={this.ItemValueChange.bind(this, index, 5)} />
                            <div className="user_edit_info_title">宽度：</div>
                            <Input className="user_edit_info_input" ref={"width" + index} style={{ marginRight: '30px' }} addonAfter={"CM"}
                                value={item.width} onChange={this.ItemValueChange.bind(this, index, 6)} />
                            <div className="user_edit_info_title">高度：</div>
                            <Input className="user_edit_info_input" ref={"height" + index} style={{ marginRight: '30px' }} addonAfter={"CM"}
                                value={item.height} onChange={this.ItemValueChange.bind(this, index, 7)} />
                        </div>
                    </div>);
            });

            // 入库记录编辑弹窗
            let uiModal = (
                <Modal title="入库记录" width={1000} visible={this.state.bShowDlg}
                    okText="确定" cancelText="取消" onOk={this.handleOk} onCancel={this.handleCancel} >
                    <div className="user_edit_info_row" >
                        <div className="user_edit_info_title" style={{ color: 'lightcoral' }}>柜号：</div>
                        <Input className="user_edit_info_input" ref="closet" style={{ marginRight: '30px' }}
                            defaultValue={this.objCurrentStorageIn.closet} />
                        <div className="user_edit_info_title" style={{ color: 'lightcoral' }}>客户：</div>
                        <Input className="user_edit_info_input" ref="client_name" style={{ marginRight: '30px' }}
                            defaultValue={this.objCurrentStorageIn.client_name} disabled />
                        <Button className="manage_contents_title_margin" type="primary" onClick={this.SelectClient.bind(this)}> 选择</Button>
                    </div>
                    <div className="user_edit_info_row" style={{ marginTop: '20px' }}>
                        <div className="user_edit_info_title" style={{ marginTop: '10px', marginBottom: 'auto' }}>备注：</div>
                        {/* <Input className="user_edit_info_input_full" ref="comments" style={{ marginRight: '0px' }}
                            defaultValue={this.objCurrentStorageIn.comments} /> */}
                        <TextArea size="large" autoSize={{ minRows: 2 }} allowClear={true} ref="comments" style={{ marginBottom: '15px' }}
                            placeholder="" defaultValue={this.objCurrentStorageIn.comments} />
                    </div>
                    <div className="logistics_block_title" >
                        货物列表
                        <div className="user_edit_info_input" style={{ marginLeft: 'auto', marginRight: '0px' }}>{"种类：" + this.arrItemList.length}</div>
                        <div className="user_edit_info_input" >{"总数：" + iItemTotalNum}</div>
                        <Button type="primary" className="jeton_order_block_button" style={{ marginLefr: 'auto', marginRight: '0px' }}
                            onClick={this.AddItem}>新增 +</Button>
                    </div>
                    {uiItemList}
                </Modal>
            );
            if (this.state.iDlgType === 2) {
                uiModal = (
                    <Modal width={500} title={"删除入库记录"} visible={this.state.bShowDlg} okType="danger"
                        onOk={this.handleOk} okText="确定"
                        onCancel={this.handleCancel} cancelText="取消">
                        <div className="modal_item">
                            <div>
                                {/* {this.objCurrentStorageIn.id} */}
                                确定删除选中的入库？
                            </div>
                        </div>
                    </Modal>
                );
            }
            // 选择客户弹窗
            let uiClientSearchTitle = (
                <div className="logistic_row">
                    <div >选择客户</div>
                    <Input className="logistic_search_input" ref="client_search" defaultValue="" style={{ marginRight: '0px', marginLeft: '10px' }} allowClear />
                    <Button type="primary" onClick={this.SearchClient} style={{ width: '30px', marginRight: 'auto' }} className="logistic_row">
                        <Icon type="search" /></Button>
                </div>);
            let uiClientList = (
                <div className="storage_sku_list_area">
                    {global.arrClientData.map((client, index) => {
                        if (this.refs.client_search !== undefined) {
                            let strClientSearch = this.refs.client_search.state.value;
                            if (strClientSearch !== "" && strClientSearch !== null) {
                                if (client.name.toLowerCase().indexOf(strClientSearch.toLowerCase()) >= 0)
                                    return (
                                        <div className="storage_sku_list_row" key={index} onClick={this.ClientSelectResult.bind(this, index)}>
                                            {client.name}
                                        </div>
                                    );
                                else return null
                            } else {
                                return (
                                    <div className="storage_sku_list_row" key={index} onClick={this.ClientSelectResult.bind(this, index)}>
                                        {client.name}
                                    </div>
                                );
                            }
                        } else {
                            return (
                                <div className="storage_sku_list_row" key={index} onClick={this.ClientSelectResult.bind(this, index)}>
                                    {client.name}
                                </div>
                            );
                        }
                    })}
                </div>
            );
            let uiClientFilterDlg = (
                <Modal width={450} title={uiClientSearchTitle} visible={this.state.bSelectClientFilterDlg} closable={true} maskClosable={true} footer={null}
                    onCancel={this.ClientSelectResult.bind(this, -1)}
                > {uiClientList} </Modal>);
            let uiSelectClientDlg = (
                <Modal width={450} title={uiClientSearchTitle} visible={this.state.bSelectClientDlg} closable={true} maskClosable={true} footer={null}
                    onCancel={this.ClientSelectResult.bind(this, -1)}
                > {uiClientList} </Modal>);


            let strAreaStyle = "logistics_page_contents";
            let arrTableHeader = arrTableHeader1;
            if (this.props.iUserLevel === 1)
                arrTableHeader = arrTableHeader2;
            return (
                <div className={strAreaStyle} style={{ paddingTop: ' 50px' }}/*"store_manage_contents store_manage_top_space"*/>
                    {uiTitleArea}
                    {uiModal}
                    {uiSelectClientDlg}
                    {uiClientFilterDlg}
                    {/* 表格主体 */}
                    <Table style={{ margin: '20px', width: '97.5%', overflowX: 'auto' }} bordered
                        rowSelection={rowSelection} pagination={paginationProps}
                        columns={arrTableHeader} dataSource={this.arrTableData}
                        onRow={(record) => ({ onClick: () => { this.selectRow(record); } })} />
                </div >
            );
        } else {
            return (
                <div className="logistics_page_contents">
                    {uiTitleArea}
                    <Spin className="manage_empty_loading" size="large" tip="加载中……" />
                </div>
            );
        }
    }
}
export default LogisticStorageInventory;
