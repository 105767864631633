import React, { Component } from "react";
import axios from 'axios';
import cookie from 'react-cookies'

import Empty from 'antd/es/empty';
import Spin from 'antd/es/spin';
import Menu from 'antd/es/menu';
import Icon from 'antd/es/icon';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import Dropdown from 'antd/es/dropdown';
import message from 'antd/es/message';

import GeneralDataShow from "./ManagerPage/GeneralDataShow.js";
import StoreInfoEdit from "./ManagerPage/StoreInfoEdit.js";
import StoreImageManagement from "./ManagerPage/StoreImageManagement.js";
import StoreItemManagement from "./ManagerPage/StoreItemManagement.js";
import ItemTypeManagement from "./ManagerPage/ItemTypeManagement.js";
import DeliveryFeeCalculator from "./ManagerPage/DeliveryFeeCalculator.js";
import CouponCodeManager from "./ManagerPage/CouponCodeManager.js";
import StoreOrderManagement from "./ManagerPage/StoreOrderManagement.js";
import AutoOrderGenerator from "./ManagerPage/AutoOrderGenerator.js";
import TransitStatusManagement from "./ManagerPage/TransitStatusManagement.js";
import TransitPriceManager from "./ManagerPage/TransitPriceManager.js";
import TransitDateManager from "./ManagerPage/TransitDateManager.js";
import ExpressEnter from "./ManagerPage/ExpressEnter.js";
import ExpressEnterJW from "./ManagerPage/ExpressEnterJW.js";
import CACNOrder from "./ManagerPage/CACNOrder.js";
import PackageCombine from "./ManagerPage/PackageCombine.js";
import PackageCombineJW from "./ManagerPage/PackageCombineJW.js";
import CommentsManager from "./ManagerPage/CommentsManager.js";
import CityPortManager from "./ManagerPage/CityPortManager.js";
import CusomsFileManager from "./ManagerPage/CustomsFileManager.js";
import InvoiceFileManager from "./ManagerPage/InvoiceFileManager.js";
import StoreUserManager from "./ManagerPage/StoreUserManager.js";
import StoreUserManagerJW from "./ManagerPage/StoreUserManagerJW.js";
import StoreClientManagerJW from "./ManagerPage/StoreClientManagerJW.js";
import StoreClientManager from "./ManagerPage/StoreClientManager.js";
import PredictionOrderList from "./ManagerPage/PredictionOrderList.js";
import StoreShipperManager from "./ManagerPage/StoreShipperManager.js";
import StoreReceiverManager from "./ManagerPage/StoreReceiverManager.js";

import { deviceType } from "react-device-detect";
import { UserActiveUpdate } from "../../functions/DataStatistic.js"

import "../../styles/Background/ego_manager.css";
import "../../styles/Background/store_manager.css";

const { SubMenu } = Menu;
const g_strMessageKey = 'updating';

function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class StoreManager extends Component {
    // 构造函数
    constructor(props) {
        super();
        this.state = {
            bLoading: true,  // 加载状态
            bLogin: false,  // 登录状态
            iCurrentInterface: 0,  //当前显示的中心区域内容ID
            iCurrentTopBarIndex: 0,
            arrExpressEnter: []
        };
        this.arrSideMenuButton = [
            { id: 0, name: "数据统计", icon: "rise" },
            { id: 1, name: "商家信息", icon: "shop" },
            { id: 2, name: "宣传图片", icon: "picture" },
            { id: 3, name: "商品管理", icon: "gift" },
            { id: 4, name: "类别标签", icon: "appstore" },
            { id: 5, name: "优惠设置", icon: "tag" },
            { id: 6, name: "订单列表", icon: "profile" },
            { id: 7, name: "运费计算", icon: "dollar" }
        ];
        this.arrTopMenuButton = [
            { id: 0, name: "中国 → 加拿大", icon: "car" },
            { id: 1, name: "加拿大 → 中国", icon: "rocket" },
            // { id: 2, name: "系统设置", icon: "setting" }
        ];

        this.strCurrentUserName = '';
        this.strCurrentUserCode = '';
        this.iCurrentUserLevel = 0;
        this.objStoreInfo = { store_name: '' };

        let strDevice = deviceType;
        if (strDevice === "mobile")
            this.bMStation = true;
        else
            this.bMStation = false;

        this.strTargetOrderNo = "";
        this.strCurrentOid = "";

        this.iAccountSwitch = -1;
    }
    // 切换顶部栏菜单
    SwitchTopBarMenu = (iMenuID) => {
        this.setState({
            ...this.state,
            bLoading: true
        });
        let iCurrentInterface = 0;
        if (iMenuID === 0) {
            iCurrentInterface = this.arrSideMenuButton[0].id;
            this.arrSideMenuButton = [
                { id: 0, name: "包裹管理", icon: "code-sandbox" },
                { id: 1, name: "运单管理", icon: "snippets" },
                { id: 2, name: "用户管理", icon: "user" },
                { id: 3, name: "收件人", icon: "usergroup-add" }
            ];
        }
        if (iMenuID === 1) {
            iCurrentInterface = this.arrSideMenuButton[0].id;
            this.arrSideMenuButton = [
                { id: 0, name: "订单管理", icon: "snippets" },
                { id: 1, name: "用户管理", icon: "user" },
                { id: 2, name: "发件人", icon: "usergroup-add" },
                { id: 3, name: "收件人", icon: "usergroup-add" }
            ];

        }
        this.setState({
            ...this.state,
            bLoading: false,
            iCurrentTopBarIndex: iMenuID,
            iCurrentInterface: iCurrentInterface
        });
    }
    // 切换侧边栏菜单
    SwitchSideMenu = (iMenuID) => {

        if (iMenuID === 5 && this.bMStation) {
            message.info("手机版数据统计还未开放！");
            return;
        }
        this.setState({
            ...this.state,
            bLoading: true,
            arrExpressEnter: []
        });
        if (this.objStoreInfo.type === 5) {
            this.setState({
                ...this.state,
                bLoading: false,
                bLogin: true,
                // iCurrentInterface: this.arrSideMenuButton[iMenuID].id,
                iCurrentInterface: iMenuID
            });
        } else {
            switch (iMenuID) {
                case 0: {
                    if (this.strCurrentUserName.indexOf('_a') >= 0) {
                        if (global.arrItemTypeData.length === 0) {
                            this.GetItemTypeInfo(iMenuID);
                        } else {
                            this.setState({
                                ...this.state,
                                bLoading: false,
                                bLogin: true,
                                // iCurrentInterface: this.arrSideMenuButton[iMenuID].id,
                                iCurrentInterface: iMenuID
                            });
                        }
                    } else {
                        this.setState({
                            ...this.state,
                            bLoading: false,
                            bLogin: true,
                            // iCurrentInterface: this.arrSideMenuButton[iMenuID].id
                            iCurrentInterface: iMenuID
                        });
                    }
                    break;
                }
                case 3: {  // 商品页面
                    if (global.arrItemTypeData.length === 0) {
                        this.GetItemTypeInfo(iMenuID);
                    } else {
                        this.setState({
                            ...this.state,
                            bLoading: false,
                            bLogin: true,
                            // iCurrentInterface: this.arrSideMenuButton[iMenuID].id
                            iCurrentInterface: iMenuID
                        });
                    }
                    break;
                }
                default: {
                    this.setState({
                        ...this.state,
                        bLoading: false,
                        bLogin: true,
                        // iCurrentInterface: this.arrSideMenuButton[iMenuID].id
                        iCurrentInterface: iMenuID
                    });
                    break;
                }
            }
        }
    }
    // 获取商品/服务类别信息
    GetItemTypeInfo = (iMenuID) => {
        axios('/GetStoreItemTypes', { params: { store_id: this.objStoreInfo.id } }).then(
            ({ data }) => {
                global.arrItemTypeData = data;
                // 切换到商品种类页面
                this.setState({
                    ...this.state,
                    bLoading: false,
                    bLogin: true,
                    iCurrentInterface: this.arrSideMenuButton[iMenuID].id
                });
            });
    }
    // 登录表单提交响应
    LoginSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                // DataStatistic
                UserActiveUpdate(0, { active: "商家后台: 尝试登录", user: values.username });
                message.loading({ content: '登录中……', key: g_strMessageKey });
                this.setState({
                    ...this.state,
                    bLoading: true
                });
                this.TryLogin(values.username, values.password)
            }
        });
    };
    // 尝试登录
    TryLogin = (name, password) => {
        let objUserInfo = { name: name, password: password };
        axios('/StoreBackEndLogin', { params: objUserInfo })
            .then(({ data }) => {
                if (data.status === -1) {
                    message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
                    this.setState({
                        ...this.state,
                        bLoading: false
                    });
                } else {
                    message.success({ content: data.message, key: g_strMessageKey, duration: 2 });
                    this.strCurrentUserName = name;
                    this.strCurrentUserCode = data.code;
                    this.iCurrentUserLevel = parseInt(data.level, 10);
                    cookie.save('store_user_code', this.strCurrentUserCode, { path: '/' });
                    cookie.save('store_login_status', 1, { path: '/' });
                    // DataStatistic
                    UserActiveUpdate(0, { active: "商家后台: 登录成功", user: name });
                    this.GetStoreInfo();
                }
            }).catch(function (error) { console.log(error); });
    }
    // 退出登录
    LogoutClick = () => {
        global.arrItemTypeData = [];
        // DataStatistic
        // UserActiveUpdate(0, { active: "商家后台: 退出登录", user: this.strCurrentUserName });
        cookie.remove('store_user_code', { path: '/' });
        cookie.save('store_login_status', -1, { path: '/' });
        this.setState({
            ...this.state,
            bLogin: false
        });
    }
    // 获取当前登录的商家的基本信息
    GetStoreInfo = () => {
        axios('/GetUserRelevantStoreID', { params: { code: this.strCurrentUserCode } })
            .then(({ data }) => {
                console.log("GetUserRelevantStoreID : ", data);
                if (data.status === -1) {
                    message.warning(data.message);
                    this.setState({
                        ...this.state,
                        bLoading: false
                    });
                } else {

                    if (data.level >= 6 && (data.name === 'zhihe' || data.name === 'zhihecn' || data.name === 'xiyouji'))
                        this.iAccountSwitch = 1;
                    this.strCurrentUserName = data.name;
                    this.iCurrentUserLevel = data.level;
                    console.log(' this.strCurrentUserName = ', this.strCurrentUserName, this.iCurrentUserLevel);

                    axios('/GetStoreInfo', { params: { id: data.store_id } })
                        .then(({ data }) => {
                            this.objStoreInfo = data[0];
                            let iNewInterface = this.state.iCurrentInterface;
                            if (this.objStoreInfo.type === 5) {
                                iNewInterface = 0;
                                if (this.iCurrentUserLevel >= 9) {
                                    this.arrSideMenuButton = [
                                        { id: 0, name: "快递进仓", icon: "code-sandbox" },
                                        { id: 1, name: "合箱管理", icon: "cluster" },
                                        { id: 2, name: "出单系统", icon: "profile" },
                                        { id: 3, name: '货运价格', icon: "dollar" },
                                        { id: 4, name: "用户管理", icon: "user" },
                                        { id: 5, name: '城市港口', icon: "global" },
                                        { id: 6, name: "物流状态", icon: "car" },
                                        { id: 7, name: "数据统计", icon: "rise" },
                                        { id: 8, name: "备忘记录", icon: "snippets" },
                                        { id: 9, name: "海关文件", icon: "audit" },
                                        { id: 10, name: "发票文件", icon: "file-done" },
                                        {
                                            id: 11, name: "预报", icon: "notification",
                                            sub: [{ id: 110, name: "客户" }, { id: 111, name: "预报单" }]
                                        },
                                    ];
                                    if (this.objStoreInfo.id === 20000) {
                                        this.arrSideMenuButton = [
                                            { id: 0, name: "快递进仓", icon: "code-sandbox" },
                                            { id: 1, name: "合箱管理", icon: "cluster" },
                                            { id: 2, name: "出单系统", icon: "profile" },
                                            { id: 3, name: '货运价格', icon: "dollar" },
                                            { id: 4, name: "船期管理", icon: "calendar" },
                                            { id: 5, name: "物流状态", icon: "car" },
                                            { id: 6, name: "用户管理", icon: "user" },
                                            {
                                                id: 7, name: "预报", icon: "notification",
                                                sub: [{ id: 70, name: "客户" }, { id: 71, name: "预报单" }]
                                            }
                                        ];
                                    }
                                } else {
                                    if (this.iCurrentUserLevel >= 6) {
                                        this.arrSideMenuButton = [
                                            { id: 0, name: "快递进仓", icon: "code-sandbox" },
                                            { id: 1, name: "合箱管理", icon: "cluster" },
                                            { id: 2, name: "出单系统", icon: "profile" },
                                            { id: 3, name: '货运价格', icon: "dollar" }
                                        ];
                                        if (this.iCurrentUserLevel >= 7) {
                                            this.arrSideMenuButton = [
                                                { id: 0, name: "快递进仓", icon: "code-sandbox" },
                                                { id: 1, name: "合箱管理", icon: "cluster" },
                                                { id: 2, name: "出单系统", icon: "profile" },
                                                { id: 3, name: '货运价格', icon: "dollar" },
                                                { id: 4, name: "海关文件", icon: "audit" },
                                                { id: 5, name: "发票文件", icon: "file-done" },
                                                {
                                                    id: 11, name: "预报", icon: "notification",
                                                    sub: [{ id: 110, name: "客户" }, { id: 111, name: "预报单" }]
                                                }
                                            ];
                                        }
                                        if (this.objStoreInfo.id === 20000)
                                            this.arrSideMenuButton = [
                                                { id: 0, name: "快递进仓", icon: "code-sandbox" },
                                                { id: 1, name: "合箱管理", icon: "cluster" },
                                                { id: 2, name: "出单系统", icon: "profile" },
                                                { id: 3, name: '货运价格', icon: "dollar" },
                                                { id: 4, name: "船期管理", icon: "calendar" },
                                                { id: 5, name: "物流状态", icon: "car" },
                                                { id: 6, name: "用户管理", icon: "user" },
                                                {
                                                    id: 7, name: "预报", icon: "notification",
                                                    sub: [{ id: 70, name: "客户" }, { id: 71, name: "预报单" }]
                                                }
                                            ];
                                    } else {
                                        this.arrSideMenuButton = [
                                            { id: 0, name: "快递进仓", icon: "code-sandbox" },
                                            { id: 1, name: "海运预报", icon: "profile" },
                                        ];
                                        if (this.objStoreInfo.id === 20000) {
                                            this.arrSideMenuButton = [
                                                { id: 0, name: "快递进仓", icon: "code-sandbox" },
                                                { id: 1, name: "合箱管理", icon: "cluster" }
                                            ];
                                        }
                                    }
                                }
                            } else {
                                this.arrSideMenuButton = [];
                                if (this.strCurrentUserName.indexOf('_a') >= 0) {
                                    this.arrSideMenuButton = [
                                        { id: 0, name: "商品管理", icon: "gift" }
                                    ];
                                } else {
                                    this.arrSideMenuButton = [
                                        { id: 0, name: "数据统计", icon: "rise" },
                                        { id: 1, name: "商家信息", icon: "shop" },
                                        { id: 2, name: "宣传图片", icon: "picture" },
                                        { id: 3, name: "商品管理", icon: "gift" },
                                        { id: 4, name: "类别标签", icon: "appstore" },
                                        { id: 5, name: "优惠设置", icon: "tag" },
                                        { id: 6, name: "订单列表", icon: "profile" },
                                        { id: 7, name: "运费计算", icon: "dollar" }
                                    ];
                                }
                                if (this.state.iCurrentInterface > 6 || this.state.iCurrentInterface >= this.arrSideMenuButton.length)
                                    iNewInterface = 0;
                            }
                            // 西邮寄账号
                            if (this.objStoreInfo.id === 30000) {
                                this.arrSideMenuButton = [
                                    { id: 0, name: "包裹管理", icon: "code-sandbox" },
                                    { id: 1, name: "运单管理", icon: "snippets" },
                                    { id: 2, name: "用户管理", icon: "user" },
                                    { id: 3, name: "收件人", icon: "usergroup-add" }
                                ];
                                if (this.state.iCurrentTopBarIndex === 1)
                                    this.arrSideMenuButton = [
                                        { id: 0, name: "订单管理", icon: "snippets" },
                                        { id: 1, name: "用户管理", icon: "user" },
                                        { id: 2, name: "发件人", icon: "usergroup-add" },
                                        { id: 3, name: "收件人", icon: "usergroup-add" }
                                    ];
                                document.title = "西邮寄物流";
                            }

                            this.SwitchSideMenu(iNewInterface);
                        }).catch(function (error) { console.log(error); });
                }
            }).catch(function (error) { console.log(error); });
    }
    // 出单系统打开海关文件的回调函数
    AutoOrderToCustomFile = (iType, strOrderNo) => {
        this.strTargetOrderNo = strOrderNo;
        if (this.iCurrentUserLevel === 7)
            this.SwitchSideMenu(4);
        else
            this.SwitchSideMenu(9);
    }
    // 出单系统打开Invoice文件的回调函数
    AutoOrderToInvoiceFile = (iType, strOrderNo) => {
        this.strTargetOrderNo = strOrderNo;
        if (this.iCurrentUserLevel === 7)
            this.SwitchSideMenu(5);
        else
            this.SwitchSideMenu(10);
    }
    // 重置目标海关文件
    TargetOrderNoReset = () => {
        this.strTargetOrderNo = "";
        this.setState({
            ...this.state,
            bLoading: false
        })
    }
    // 海关文件返回出单系统
    CustomFileToAutoOrder = (strOrderNo) => {
        this.SwitchSideMenu(2);
    }
    // 海关文件返回出单系统
    InvoiceFileToAutoOrder = (strOrderNo) => {
        this.SwitchSideMenu(2);
    }
    //提交运单
    CreatePackageCombine = (arrExpressEnter) => {
        // console.log("CreatePackageCombine : ", arrExpressEnter);
        this.setState({
            ...this.state,
            iCurrentInterface: 1,
            arrExpressEnter: arrExpressEnter
        });
    }
    // 重置选中包裹信息
    ResetExpressOrderInfo = () => {
        this.setState({
            ...this.state,
            arrExpressEnter: []
        })
    }
    // 预报单和正式出单的跳转
    AutoOrderAndPrediction = (index, strOid) => {
        this.strTargetOrderNo = strOid;
        // 预报单跳转正式单
        if (index === 0) {
            this.SwitchSideMenu(2);
        }
        // 正式单跳转预报单
        if (index === 1) {
            this.SwitchSideMenu(111);
        }
    }
    // 目标单号重置
    TargetOrderNoReset = () => {
        this.strTargetOrderNo = "";
        this.setState({
            ...this.state,
            bLogin: this.state.bLogin
        })
    }
    // 切换账号
    SwitchUserAccount = () => {
        console.log(' this.strCurrentUserName = ', this.strCurrentUserName, this.iCurrentUserLevel);
        if (this.strCurrentUserName.indexOf('zhihe') >= 0) {
            this.strCurrentUserName = 'xiyouji';
            this.iCurrentUserLevel = 10;
            this.RefreshStoreInfo(30000);
        } else {
            if (this.strCurrentUserName.indexOf('xiyouji') >= 0) {
                this.strCurrentUserName = 'zhihecn';
                this.iCurrentUserLevel = 6;
                this.RefreshStoreInfo(20000);
            }
        }
    }
    // 刷新商家信息
    RefreshStoreInfo = (iStoreID) => {
        axios('/GetStoreInfo', { params: { id: iStoreID } })
            .then(({ data }) => {
                this.objStoreInfo = data[0];
                let iNewInterface = this.state.iCurrentInterface;
                if (this.objStoreInfo.type === 5) {
                    iNewInterface = 0;
                    if (this.iCurrentUserLevel >= 9) {
                        this.arrSideMenuButton = [
                            { id: 0, name: "快递进仓", icon: "code-sandbox" },
                            { id: 1, name: "合箱管理", icon: "cluster" },
                            { id: 2, name: "出单系统", icon: "profile" },
                            { id: 3, name: '货运价格', icon: "dollar" },
                            { id: 4, name: "用户管理", icon: "user" },
                            { id: 5, name: '城市港口', icon: "global" },
                            { id: 6, name: "物流状态", icon: "car" },
                            { id: 7, name: "数据统计", icon: "rise" },
                            { id: 8, name: "备忘记录", icon: "snippets" },
                            { id: 9, name: "海关文件", icon: "audit" },
                            { id: 10, name: "发票文件", icon: "file-done" },
                            {
                                id: 11, name: "预报", icon: "notification",
                                sub: [{ id: 110, name: "客户" }, { id: 111, name: "预报单" }]
                            },
                        ];
                        if (this.objStoreInfo.id === 20000) {
                            this.arrSideMenuButton = [
                                { id: 0, name: "快递进仓", icon: "code-sandbox" },
                                { id: 1, name: "合箱管理", icon: "cluster" },
                                { id: 2, name: "出单系统", icon: "profile" },
                                { id: 3, name: '货运价格', icon: "dollar" },
                                { id: 4, name: "船期管理", icon: "calendar" },
                                { id: 5, name: "物流状态", icon: "car" },
                                { id: 6, name: "用户管理", icon: "user" },
                                {
                                    id: 7, name: "预报", icon: "notification",
                                    sub: [{ id: 70, name: "客户" }, { id: 71, name: "预报单" }]
                                }
                            ];
                        }
                    } else {
                        if (this.iCurrentUserLevel >= 6) {
                            this.arrSideMenuButton = [
                                { id: 0, name: "快递进仓", icon: "code-sandbox" },
                                { id: 1, name: "合箱管理", icon: "cluster" },
                                { id: 2, name: "出单系统", icon: "profile" },
                                { id: 3, name: '货运价格', icon: "dollar" }
                            ];
                            if (this.iCurrentUserLevel >= 7) {
                                this.arrSideMenuButton = [
                                    { id: 0, name: "快递进仓", icon: "code-sandbox" },
                                    { id: 1, name: "合箱管理", icon: "cluster" },
                                    { id: 2, name: "出单系统", icon: "profile" },
                                    { id: 3, name: '货运价格', icon: "dollar" },
                                    { id: 4, name: "海关文件", icon: "audit" },
                                    { id: 5, name: "发票文件", icon: "file-done" },
                                    {
                                        id: 11, name: "预报", icon: "notification",
                                        sub: [{ id: 110, name: "客户" }, { id: 111, name: "预报单" }]
                                    }
                                ];
                            }
                            if (this.objStoreInfo.id === 20000)
                                this.arrSideMenuButton = [
                                    { id: 0, name: "快递进仓", icon: "code-sandbox" },
                                    { id: 1, name: "合箱管理", icon: "cluster" },
                                    { id: 2, name: "出单系统", icon: "profile" },
                                    { id: 3, name: '货运价格', icon: "dollar" },
                                    { id: 4, name: "用户管理", icon: "user" },
                                    {
                                        id: 11, name: "预报", icon: "notification",
                                        sub: [{ id: 110, name: "客户" }, { id: 111, name: "预报单" }]
                                    }
                                ];
                        } else {
                            this.arrSideMenuButton = [
                                { id: 0, name: "快递进仓", icon: "code-sandbox" },
                                { id: 1, name: "海运预报", icon: "profile" },
                            ];
                        }
                    }
                } else {
                    this.arrSideMenuButton = [];
                    if (this.strCurrentUserName.indexOf('_a') >= 0) {
                        this.arrSideMenuButton = [
                            { id: 0, name: "商品管理", icon: "gift" }
                        ];
                    } else {
                        this.arrSideMenuButton = [
                            { id: 0, name: "数据统计", icon: "rise" },
                            { id: 1, name: "商家信息", icon: "shop" },
                            { id: 2, name: "宣传图片", icon: "picture" },
                            { id: 3, name: "商品管理", icon: "gift" },
                            { id: 4, name: "类别标签", icon: "appstore" },
                            { id: 5, name: "优惠设置", icon: "tag" },
                            { id: 6, name: "订单列表", icon: "profile" },
                            { id: 7, name: "运费计算", icon: "dollar" }
                        ];
                    }
                    if (this.state.iCurrentInterface > 6 || this.state.iCurrentInterface >= this.arrSideMenuButton.length)
                        iNewInterface = 0;
                }
                // 西邮寄账号
                if (this.objStoreInfo.id === 30000) {
                    this.arrSideMenuButton = [
                        { id: 0, name: "包裹管理", icon: "code-sandbox" },
                        { id: 1, name: "运单管理", icon: "snippets" },
                        { id: 2, name: "用户管理", icon: "user" },
                        { id: 3, name: "收件人", icon: "usergroup-add" }
                    ];
                    if (this.state.iCurrentTopBarIndex === 1)
                        this.arrSideMenuButton = [
                            { id: 0, name: "订单管理", icon: "snippets" },
                            { id: 1, name: "用户管理", icon: "user" },
                            { id: 2, name: "发件人", icon: "usergroup-add" },
                            { id: 3, name: "收件人", icon: "usergroup-add" }
                        ];
                    document.title = "西邮寄物流";
                }
                this.SwitchSideMenu(iNewInterface);
            }).catch(function (error) { console.log(error); });
    }

    // 渲染完毕……
    componentDidMount() {
        let strUserCode = cookie.load('store_user_code');
        let iUserLoginStatus = cookie.load('store_login_status');
        if ((iUserLoginStatus === 1 || iUserLoginStatus === '1') && strUserCode !== undefined) {
            this.strCurrentUserCode = strUserCode;
            if (this.strCurrentUserCode.indexOf('u_') >= 0)
                this.iCurrentUserLevel = 5;
            if (this.strCurrentUserCode.indexOf('s_') >= 0)
                this.iCurrentUserLevel = 9;
            this.GetStoreInfo();
        } else {
            this.setState({
                ...this.state,
                bLoading: false,
                bLogin: false
            });
        }
        document.title = "Mouthing管理后台";
    }

    render() {
        const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;
        // Only show error after a field is touched.
        const usernameError = isFieldTouched('username') && getFieldError('username');
        const passwordError = isFieldTouched('password') && getFieldError('password');
        // 登录区域
        let strTitle = '欢迎回来,  ' + this.strCurrentUserName;
        let uiLogInForm = (
            <Form className="manage_login_form" layout="inline" onSubmit={this.LoginSubmit}>
                <Form.Item validateStatus={usernameError ? 'error' : ''} help={usernameError || ''}>
                    {getFieldDecorator('username')(
                        <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="用户名" />,
                    )}
                </Form.Item>
                <Form.Item validateStatus={passwordError ? 'error' : ''} help={passwordError || ''}>
                    {getFieldDecorator('password')(
                        <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                            type="password" placeholder="密码" />,
                    )}
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" disabled={hasErrors(getFieldsError())}>
                        登 陆
                    </Button>
                </Form.Item>
            </Form>
        );
        let uiStoreInfo = <div className="store_backend_title" />
        // 侧边栏
        let uiSideBar = <div />;
        // 顶部栏
        let uiLogo = <div className="store_manage_logo" >商家后台</div>;
        if (this.bMStation)
            uiLogo = null;
        let uiNavBar = (
            <div className="manage_top_bar">
                {uiLogo}
                <div className="manage_top_bar_menu">

                </div>
                {uiLogInForm}
            </div>
        );
        if (this.state.bLogin) {
            let strStoreName = this.objStoreInfo.store_name;
            if (this.strCurrentUserName === 'xiyouji')
                strStoreName = "西邮寄";
            uiStoreInfo = (
                <div className="store_backend_title">
                    {"商家-" + strStoreName + "　id-" + this.objStoreInfo.id}
                </div>
            );
            uiLogInForm = (
                <div className="manage_login_form">
                    <div className="welcome_back_title">{strTitle}</div>
                    <Button className="logout_btn" type="primary" htmlType="submit"
                        onClick={this.LogoutClick}>退出登录</Button>
                </div>
            );
            uiSideBar = (
                <Menu className="store_manage_side_bar" defaultSelectedKeys={[this.state.iCurrentInterface.toString()]}
                    selectedKeys={[this.state.iCurrentInterface.toString()]}
                    mode="inline" theme="dark" inlineCollapsed={this.state.collapsed}  >
                    {this.arrSideMenuButton.map((menu, index) => {
                        let uiMenuItem = (
                            <Menu.Item key={menu.id} onClick={this.SwitchSideMenu.bind(this, menu.id)}>
                                <Icon type={menu.icon} />{menu.name}
                            </Menu.Item>
                        );
                        if (menu.sub) {
                            uiMenuItem = (
                                <SubMenu key={menu.id} title={<span><Icon type={menu.icon} />{menu.name}</span>}>
                                    {menu.sub.map((sub, sub_index) => {
                                        return (
                                            <Menu.Item key={sub.id} onClick={this.SwitchSideMenu.bind(this, sub.id)}>{sub.name}</Menu.Item>
                                        );
                                    })}
                                </SubMenu>
                            );
                        }
                        return (uiMenuItem);
                    })}
                </Menu>
            );
            let uiNavBarMenu = null;
            let uiMainTitle = (
                <div className="store_manage_logo" >商家后台</div>
            );
            let uiSwitchAccountButton = null;
            if (this.iAccountSwitch === 1)
                uiSwitchAccountButton = (
                    <Button type='primary' style={{ marginLeft: '20px' }} onClick={this.SwitchUserAccount}>切换账号</Button>
                );
            if (this.objStoreInfo.id === 30000) {
                uiNavBarMenu = (
                    <div className="manage_top_bar_menu">
                        <Menu className="manage_top_bar_menu" defaultSelectedKeys={["0"]} mode="horizontal"
                            theme="dark" onClick={this.handleClick} >
                            {this.arrTopMenuButton.map((item, index) => {
                                return (
                                    <Menu.Item key={index} onClick={this.SwitchTopBarMenu.bind(this, index)}>
                                        <Icon type={item.icon} />{item.name}
                                    </Menu.Item>
                                );
                            })}
                        </Menu>
                        {uiSwitchAccountButton}
                    </div>
                );
                uiMainTitle = (
                    <div className="store_manage_logo" >西邮寄物流</div>
                );
                uiStoreInfo = null;
                uiLogInForm = (
                    <div className="manage_login_form">
                        <Button className="logout_btn" type="primary" htmlType="submit"
                            onClick={this.LogoutClick}>退出登录</Button>
                    </div>
                );
            } else {
                uiNavBarMenu = (
                    <div className="manage_top_bar_menu">
                        {uiSwitchAccountButton}
                    </div>
                );
            }

            uiNavBar = (
                <div className="manage_top_bar">
                    {uiMainTitle}
                    {uiNavBarMenu}
                    {uiStoreInfo}
                    {uiLogInForm}
                </div>
            );
        }
        let uiCentralArea;
        // 是否还在加载中……
        if (this.state.bLoading) {
            uiCentralArea = (<Spin className="manage_empty_loading" size="large" tip="加载中……" />);
        } else {
            if (this.state.bLogin) {
                if (this.objStoreInfo.type === 5) {
                    let uiExpressEnter = null;
                    let uiPackageCombine = null;
                    let uiAutoOrder = null;
                    let uiPriceManager = null;
                    let uiTransitDate = null;
                    let uiUserManager = null;
                    let uiShipperManager = null;
                    let uiClientManager = null;
                    let uiCustomsFile = null;
                    let uiInvoiceFile = null;
                    let uiPredictionList = null;

                    let uiStatisticData = null;
                    let uiTransitStatus = null;
                    let uiComments = null;
                    let uiCityPort = null;

                    // 西邮寄物流系统
                    if (this.objStoreInfo.id === 30000) {
                        uiExpressEnter = (
                            this.state.iCurrentInterface === this.arrSideMenuButton[0].id && <ExpressEnterJW iStoreID={this.objStoreInfo.id}
                                iUserLevel={this.iCurrentUserLevel} strUserName={this.strCurrentUserName} bMStation={this.bMStation}
                                CreatePackageCombine={this.CreatePackageCombine.bind(this)} />
                        );
                        if (this.state.iCurrentTopBarIndex === 0) {
                            uiPackageCombine = (
                                this.state.iCurrentInterface === this.arrSideMenuButton[1].id && <PackageCombineJW iStoreID={this.objStoreInfo.id}
                                    iUserLevel={this.iCurrentUserLevel} strUserName={this.strCurrentUserName} bMStation={this.bMStation}
                                    arrExpressEnter={this.state.arrExpressEnter} ResetExpressOrderInfo={this.ResetExpressOrderInfo.bind(this)} />
                            );
                            uiUserManager = (
                                this.state.iCurrentInterface === this.arrSideMenuButton[2].id && <StoreUserManagerJW iStoreID={this.objStoreInfo.id}
                                    iUserLevel={this.iCurrentUserLevel} bMStation={this.bMStation} />
                            );
                            uiClientManager = (
                                this.state.iCurrentInterface === this.arrSideMenuButton[3].id && <StoreClientManagerJW iStoreID={this.objStoreInfo.id}
                                    iUserLevel={this.iCurrentUserLevel} bMStation={this.bMStation} />
                            );
                        }
                        // CA To CN Order
                        if (this.state.iCurrentTopBarIndex === 1) {
                            uiExpressEnter = (
                                this.state.iCurrentInterface === this.arrSideMenuButton[0].id && <CACNOrder iStoreID={this.objStoreInfo.id}
                                    iUserLevel={this.iCurrentUserLevel} strUserName={this.strCurrentUserName} bMStation={this.bMStation}
                                    CreatePackageCombine={this.CreatePackageCombine.bind(this)} />
                            );
                            uiUserManager = (
                                this.state.iCurrentInterface === this.arrSideMenuButton[1].id && <StoreUserManagerJW iStoreID={this.objStoreInfo.id}
                                    iUserLevel={this.iCurrentUserLevel} bMStation={this.bMStation} />
                            );
                            uiShipperManager = (
                                this.state.iCurrentInterface === this.arrSideMenuButton[2].id && <StoreShipperManager iStoreID={this.objStoreInfo.id}
                                    iUserLevel={this.iCurrentUserLevel} bMStation={this.bMStation} />
                            );
                            uiClientManager = (
                                this.state.iCurrentInterface === this.arrSideMenuButton[3].id && <StoreReceiverManager iStoreID={this.objStoreInfo.id}
                                    iUserLevel={this.iCurrentUserLevel} bMStation={this.bMStation} />
                            );
                        }
                    }
                    // Lekon 物流
                    if (this.objStoreInfo.id === 10000) {
                        uiExpressEnter = (
                            this.state.iCurrentInterface === this.arrSideMenuButton[0].id && <ExpressEnter iStoreID={this.objStoreInfo.id}
                                iUserLevel={this.iCurrentUserLevel} strUserName={this.strCurrentUserName} bMStation={this.bMStation} />
                        );
                        if (this.objStoreInfo.type === 5 && this.iCurrentUserLevel === 5) {

                            uiPredictionList = (
                                this.state.iCurrentInterface === this.arrSideMenuButton[1].id && <PredictionOrderList objStoreInfo={this.objStoreInfo}
                                    iUserLevel={this.iCurrentUserLevel} bMStation={this.bMStation} AutoOrderAndPrediction={this.AutoOrderAndPrediction}
                                    AutoOrderToCustomFile={this.AutoOrderToCustomFile} AutoOrderToInvoiceFile={this.AutoOrderToInvoiceFile} />
                            );
                        }
                        if (this.objStoreInfo.type === 5 && this.iCurrentUserLevel >= 6) {
                            uiPackageCombine = (
                                this.state.iCurrentInterface === this.arrSideMenuButton[1].id && <PackageCombine iStoreID={this.objStoreInfo.id}
                                    iUserLevel={this.iCurrentUserLevel} strUserName={this.strCurrentUserName} bMStation={this.bMStation} />
                            );
                            uiAutoOrder = (
                                this.state.iCurrentInterface === this.arrSideMenuButton[2].id && <AutoOrderGenerator objStoreInfo={this.objStoreInfo}
                                    iUserLevel={this.iCurrentUserLevel} bMStation={this.bMStation} AutoOrderAndPrediction={this.AutoOrderAndPrediction}
                                    AutoOrderToCustomFile={this.AutoOrderToCustomFile} AutoOrderToInvoiceFile={this.AutoOrderToInvoiceFile}
                                    strTargetOrderNo={this.strTargetOrderNo} TargetOrderNoReset={this.TargetOrderNoReset} />
                            );
                            uiPriceManager = (
                                this.state.iCurrentInterface === this.arrSideMenuButton[3].id && <TransitPriceManager iStoreID={this.objStoreInfo.id}
                                    iUserLevel={this.iCurrentUserLevel} bMStation={this.bMStation} />
                            );
                            if (this.iCurrentUserLevel >= 7) {
                                uiCustomsFile = (
                                    this.state.iCurrentInterface === this.arrSideMenuButton[4].id && <CusomsFileManager objStoreInfo={this.objStoreInfo}
                                        iUserLevel={this.iCurrentUserLevel} bMStation={this.bMStation} strTargetOrderNo={this.strTargetOrderNo}
                                        TargetOrderNoReset={this.TargetOrderNoReset} CustomFileToAutoOrder={this.CustomFileToAutoOrder} />
                                );
                                uiInvoiceFile = (
                                    this.state.iCurrentInterface === this.arrSideMenuButton[5].id && <InvoiceFileManager iStoreID={this.objStoreInfo.id}
                                        iUserLevel={this.iCurrentUserLevel} bMStation={this.bMStation} strTargetOrderNo={this.strTargetOrderNo}
                                        TargetOrderNoReset={this.TargetOrderNoReset} InvoiceFileToAutoOrder={this.InvoiceFileToAutoOrder} />
                                );
                            }
                            if (this.objStoreInfo.id === 20000) {
                                uiUserManager = (
                                    this.state.iCurrentInterface === this.arrSideMenuButton[4].id && <StoreUserManager iStoreID={this.objStoreInfo.id}
                                        iUserLevel={this.iCurrentUserLevel} bMStation={this.bMStation} />
                                );
                            }
                        }
                        if (this.objStoreInfo.type === 5 && this.iCurrentUserLevel >= 9) {
                            uiUserManager = (
                                this.state.iCurrentInterface === this.arrSideMenuButton[4].id && <StoreUserManager iStoreID={this.objStoreInfo.id}
                                    iUserLevel={this.iCurrentUserLevel} bMStation={this.bMStation} />
                            );
                            uiCityPort = (
                                this.state.iCurrentInterface === this.arrSideMenuButton[5].id && <CityPortManager iStoreID={this.objStoreInfo.id}
                                    iUserLevel={this.iCurrentUserLevel} bMStation={this.bMStation} />
                            );
                            uiTransitStatus = (
                                this.state.iCurrentInterface === this.arrSideMenuButton[6].id && <TransitStatusManagement objStoreInfo={this.objStoreInfo} bMStation={this.bMStation} />
                            );
                            uiStatisticData = (
                                this.state.iCurrentInterface === this.arrSideMenuButton[7].id &&
                                <GeneralDataShow bStore={true} iStoreID={this.objStoreInfo.id} bMStation={this.bMStation} />
                            );
                            uiComments = (
                                this.state.iCurrentInterface === this.arrSideMenuButton[8].id && <CommentsManager iStoreID={this.objStoreInfo.id}
                                    iUserLevel={this.iCurrentUserLevel} bMStation={this.bMStation} />
                            );
                            uiCustomsFile = (
                                this.state.iCurrentInterface === this.arrSideMenuButton[9].id && <CusomsFileManager objStoreInfo={this.objStoreInfo}
                                    iUserLevel={this.iCurrentUserLevel} bMStation={this.bMStation} strTargetOrderNo={this.strTargetOrderNo}
                                    TargetOrderNoReset={this.TargetOrderNoReset} CustomFileToAutoOrder={this.CustomFileToAutoOrder} />
                            );
                            uiInvoiceFile = (
                                this.state.iCurrentInterface === this.arrSideMenuButton[10].id && <InvoiceFileManager iStoreID={this.objStoreInfo.id}
                                    iUserLevel={this.iCurrentUserLevel} bMStation={this.bMStation} strTargetOrderNo={this.strTargetOrderNo}
                                    TargetOrderNoReset={this.TargetOrderNoReset} InvoiceFileToAutoOrder={this.InvoiceFileToAutoOrder} />
                            );
                            uiClientManager = (
                                this.state.iCurrentInterface === this.arrSideMenuButton[11].sub[0].id && <StoreClientManager iStoreID={this.objStoreInfo.id}
                                    iUserLevel={this.iCurrentUserLevel} bMStation={this.bMStation} />
                            );
                            uiPredictionList = (
                                this.state.iCurrentInterface === this.arrSideMenuButton[11].sub[1].id && <PredictionOrderList objStoreInfo={this.objStoreInfo}
                                    iUserLevel={this.iCurrentUserLevel} bMStation={this.bMStation} AutoOrderAndPrediction={this.AutoOrderAndPrediction}
                                    AutoOrderToCustomFile={this.AutoOrderToCustomFile} AutoOrderToInvoiceFile={this.AutoOrderToInvoiceFile}
                                    strTargetOrderNo={this.strTargetOrderNo} TargetOrderNoReset={this.TargetOrderNoReset} />
                            );
                        }
                        if (this.objStoreInfo.type === 5 && this.iCurrentUserLevel >= 6 && this.iCurrentUserLevel < 9) {
                            let iMenuIndex = 5;
                            if (this.iCurrentUserLevel >= 7)
                                iMenuIndex = 6;
                            uiClientManager = (
                                this.state.iCurrentInterface === this.arrSideMenuButton[iMenuIndex].sub[0].id && <StoreClientManager iStoreID={this.objStoreInfo.id}
                                    iUserLevel={this.iCurrentUserLevel} bMStation={this.bMStation} />
                            );
                            uiPredictionList = (
                                this.state.iCurrentInterface === this.arrSideMenuButton[iMenuIndex].sub[1].id && <PredictionOrderList objStoreInfo={this.objStoreInfo}
                                    iUserLevel={this.iCurrentUserLevel} bMStation={this.bMStation} AutoOrderAndPrediction={this.AutoOrderAndPrediction}
                                    AutoOrderToCustomFile={this.AutoOrderToCustomFile} AutoOrderToInvoiceFile={this.AutoOrderToInvoiceFile}
                                    strTargetOrderNo={this.strTargetOrderNo} TargetOrderNoReset={this.TargetOrderNoReset} />
                            );
                        }
                    }
                    // Zhihe 物流
                    if (this.objStoreInfo.id === 20000) {
                        uiExpressEnter = (
                            this.state.iCurrentInterface === this.arrSideMenuButton[0].id && <ExpressEnter iStoreID={this.objStoreInfo.id} iUserLevel={this.iCurrentUserLevel} strUserName={this.strCurrentUserName}
                                bMStation={this.bMStation} />
                        );
                        if (this.objStoreInfo.type === 5 && this.iCurrentUserLevel === 5) {
                            uiPackageCombine = (
                                this.state.iCurrentInterface === this.arrSideMenuButton[1].id && <PackageCombine iStoreID={this.objStoreInfo.id}
                                    iUserLevel={this.iCurrentUserLevel} strUserName={this.strCurrentUserName} bMStation={this.bMStation} />
                            );
                        }
                        if (this.objStoreInfo.type === 5 && this.iCurrentUserLevel >= 6) {
                            uiPackageCombine = (
                                this.state.iCurrentInterface === this.arrSideMenuButton[1].id && <PackageCombine iStoreID={this.objStoreInfo.id}
                                    iUserLevel={this.iCurrentUserLevel} strUserName={this.strCurrentUserName} bMStation={this.bMStation} />
                            );
                            uiAutoOrder = (
                                this.state.iCurrentInterface === this.arrSideMenuButton[2].id && <AutoOrderGenerator objStoreInfo={this.objStoreInfo}
                                    iUserLevel={this.iCurrentUserLevel} bMStation={this.bMStation} AutoOrderAndPrediction={this.AutoOrderAndPrediction}
                                    AutoOrderToCustomFile={this.AutoOrderToCustomFile} AutoOrderToInvoiceFile={this.AutoOrderToInvoiceFile}
                                    strTargetOrderNo={this.strTargetOrderNo} TargetOrderNoReset={this.TargetOrderNoReset} />
                            );
                            uiPriceManager = (
                                this.state.iCurrentInterface === this.arrSideMenuButton[3].id && <TransitPriceManager iStoreID={this.objStoreInfo.id}
                                    iUserLevel={this.iCurrentUserLevel} bMStation={this.bMStation} />
                            );
                            uiTransitDate = (
                                this.state.iCurrentInterface === this.arrSideMenuButton[4].id && <TransitDateManager iStoreID={this.objStoreInfo.id}
                                    iUserLevel={this.iCurrentUserLevel} bMStation={this.bMStation} />
                            );
                            uiTransitStatus = (
                                this.state.iCurrentInterface === this.arrSideMenuButton[5].id && <TransitStatusManagement objStoreInfo={this.objStoreInfo} bMStation={this.bMStation} />
                            );
                            uiUserManager = (
                                this.state.iCurrentInterface === this.arrSideMenuButton[6].id && <StoreUserManager iStoreID={this.objStoreInfo.id}
                                    iUserLevel={this.iCurrentUserLevel} bMStation={this.bMStation} />
                            );
                            uiClientManager = (
                                this.state.iCurrentInterface === this.arrSideMenuButton[7].sub[0].id && <StoreClientManager iStoreID={this.objStoreInfo.id}
                                    iUserLevel={this.iCurrentUserLevel} bMStation={this.bMStation} />
                            );
                            uiPredictionList = (
                                this.state.iCurrentInterface === this.arrSideMenuButton[7].sub[1].id && <PredictionOrderList objStoreInfo={this.objStoreInfo}
                                    iUserLevel={this.iCurrentUserLevel} bMStation={this.bMStation} AutoOrderAndPrediction={this.AutoOrderAndPrediction}
                                    AutoOrderToCustomFile={this.AutoOrderToCustomFile} AutoOrderToInvoiceFile={this.AutoOrderToInvoiceFile}
                                    strTargetOrderNo={this.strTargetOrderNo} TargetOrderNoReset={this.TargetOrderNoReset} />
                            );
                        }

                    }

                    uiCentralArea = (
                        <div>
                            {/* 0. 快递进仓 */}
                            {uiExpressEnter}
                            {/* 1. 合箱管理 */}
                            {uiPackageCombine}
                            {/* 2. 出单系统 */}
                            {uiAutoOrder}
                            {/* 3. 货运价格 */}
                            {uiPriceManager}
                            {uiTransitDate}
                            {/* 4. 用户管理 */}
                            {uiUserManager}
                            {/* 5. 城市港口 */}
                            {uiCityPort}
                            {/* 6. 物流状态管理 */}
                            {uiTransitStatus}
                            {/* 7. 商家数据概况 */}
                            {uiStatisticData}
                            {/* 8. 备忘录 */}
                            {uiComments}
                            {/* 9. 海关文件 */}
                            {uiCustomsFile}
                            {/* 10. Invoice文件 */}
                            {uiInvoiceFile}
                            {/* 11.0 客户管理 */}
                            {uiClientManager}
                            {uiShipperManager}
                            {/* 11.1 预报单 */}
                            {uiPredictionList}
                        </div>
                    );
                    // 手机站布局
                    if (this.bMStation) {
                        uiNavBar = (
                            <div className="manage_top_bar_m">
                                <Button className="logout_btn" type="primary" htmlType="submit"
                                    onClick={this.LogoutClick}>退出</Button>
                                <div className="store_backend_title_m">
                                    {this.objStoreInfo.store_name}
                                </div>
                            </div>
                        );
                        const menu = (
                            <Menu>
                                {this.arrSideMenuButton.map((item, index) => {
                                    return (
                                        <Menu.Item key={index} onClick={this.SwitchSideMenu.bind(this, index)}>
                                            <div className="store_manager_menu_text_m">
                                                <div className="store_manager_menu_icon_m">
                                                    <Icon type={item.icon} />
                                                </div>
                                                {item.name}
                                            </div>
                                        </Menu.Item>
                                    );
                                })}
                            </Menu>
                        );
                        uiSideBar = (
                            <div className="store_manager_menu_button" >
                                <Dropdown overlay={menu} trigger={['click']}>
                                    <Icon type="menu" />
                                </Dropdown>
                            </div>
                        );
                        let uiStatisticData = null;
                        let uiTransitStatus = null;
                        let uiComments = null;
                        if (this.objStoreInfo.id === 10000 && this.iCurrentUserLevel >= 9) {
                            uiCityPort = (
                                this.state.iCurrentInterface === this.arrSideMenuButton[3].id && <CityPortManager iStoreID={this.objStoreInfo.id}
                                    iUserLevel={this.iCurrentUserLevel} bMStation={this.bMStation} />
                            );
                            uiTransitStatus = (
                                this.state.iCurrentInterface === this.arrSideMenuButton[4].id && <TransitStatusManagement objStoreInfo={this.objStoreInfo}
                                    bMStation={this.bMStation} />
                            );
                            uiStatisticData = (
                                this.state.iCurrentInterface === this.arrSideMenuButton[5].id &&
                                <GeneralDataShow bStore={true} iStoreID={this.objStoreInfo.id} bMStation={this.bMStation} />
                            );
                            uiComments = (
                                this.state.iCurrentInterface === this.arrSideMenuButton[6].id && <CommentsManager iStoreID={this.objStoreInfo.id}
                                    iUserLevel={this.iCurrentUserLevel} bMStation={this.bMStation} />
                            );
                            // uiCustomsFile = (
                            //     this.state.iCurrentInterface === this.arrSideMenuButton[7].id && <CommentsManager iStoreID={this.objStoreInfo.id}
                            //         iUserLevel={this.iCurrentUserLevel} bMStation={this.bMStation} />
                            // );
                        }
                        uiCentralArea = (
                            <div className="store_manager_central_m">
                                {/* 0. 快递进仓 */}
                                {this.state.iCurrentInterface === this.arrSideMenuButton[0].id && <ExpressEnter iStoreID={this.objStoreInfo.id}
                                    iUserLevel={this.iCurrentUserLevel} bMStation={this.bMStation} />}
                                {/* 1. 合箱管理 */}
                                {this.state.iCurrentInterface === this.arrSideMenuButton[1].id && <PackageCombine iStoreID={this.objStoreInfo.id}
                                    iUserLevel={this.iCurrentUserLevel} bMStation={this.bMStation} />}
                                {/* 2. 出单系统 */}
                                {this.state.iCurrentInterface === this.arrSideMenuButton[2].id && <AutoOrderGenerator iUserLevel={this.iCurrentUserLevel}
                                    objStoreInfo={this.objStoreInfo} bMStation={this.bMStation} />}
                                {/* 3. 货运价格 */}
                                {this.state.iCurrentInterface === this.arrSideMenuButton[3].id && <TransitPriceManager iStoreID={this.objStoreInfo.id}
                                    iUserLevel={this.iCurrentUserLevel} bMStation={this.bMStation} />}
                                {/* 4. 城市港口 */}
                                {uiCityPort}
                                {/* 5. 物流状态管理 */}
                                {uiTransitStatus}
                                {/* 6. 商家数据概况 */}
                                {uiStatisticData}
                                {/* 7. 备忘录 */}
                                {uiComments}
                                {/* 8. 海关文件 */}
                                {/* {uiCustomsFile} */}
                            </div>
                        );
                    }

                } else {
                    if (this.strCurrentUserName.indexOf('_a') >= 0) {
                        uiCentralArea = (
                            <div>
                                {/* 0. 商品管理 */}
                                {this.state.iCurrentInterface === this.arrSideMenuButton[0].id &&
                                    <StoreItemManagement objStoreInfo={this.objStoreInfo} strCurrentUserName={this.strCurrentUserName} />}
                            </div>
                        );
                    } else {
                        uiCentralArea = (
                            <div>
                                {/* 0. 商家数据概况 */}
                                {this.state.iCurrentInterface === this.arrSideMenuButton[0].id && <GeneralDataShow bStore={true} iStoreID={this.objStoreInfo.id} />}
                                {/* 1. 商家信息编辑 */}
                                {this.state.iCurrentInterface === this.arrSideMenuButton[1].id && <StoreInfoEdit objStoreInfo={this.objStoreInfo} />}
                                {/* 2. 宣传图片管理 */}
                                {this.state.iCurrentInterface === this.arrSideMenuButton[2].id && <StoreImageManagement objStoreInfo={this.objStoreInfo} />}
                                {/* 3. 商品管理 */}
                                {this.state.iCurrentInterface === this.arrSideMenuButton[3].id && <StoreItemManagement objStoreInfo={this.objStoreInfo}
                                    strCurrentUserName={this.strCurrentUserName} />}
                                {/* 4. 类别管理 */}
                                {this.state.iCurrentInterface === this.arrSideMenuButton[4].id && <ItemTypeManagement objStoreInfo={this.objStoreInfo} />}
                                {/* 5. 优惠设置 */}
                                {this.state.iCurrentInterface === this.arrSideMenuButton[5].id && <CouponCodeManager objStoreInfo={this.objStoreInfo} />}
                                {/* 6. 订单列表 */}
                                {this.state.iCurrentInterface === this.arrSideMenuButton[6].id && <StoreOrderManagement objStoreInfo={this.objStoreInfo} />}
                                {/* 7. 运费计算 */}
                                {this.state.iCurrentInterface === this.arrSideMenuButton[7].id && <DeliveryFeeCalculator objStoreInfo={this.objStoreInfo} />}
                            </div>
                        );
                    }
                }
            } else {
                uiCentralArea = (<Empty className="manage_empty_loading"
                    description={<span>未登录，无法使用</span>} />);
            }
        }

        return (
            <div className="manage_page">
                {/* 顶部菜单栏 */}
                {uiNavBar}
                {/* 侧边菜单栏 */}
                {uiSideBar}
                {/* 中心区域内容 */}
                {uiCentralArea}
            </div >
        );
    }
}
export default Form.create()(StoreManager);
