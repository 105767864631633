import React, { Component } from "react";
import axios from 'axios';

// import Statistic from 'antd/es/statistic';
import Icon from 'antd/es/icon';
import Spin from 'antd/es/spin';
import Table from 'antd/es/table';
import Button from 'antd/es/button';
import Input from 'antd/es/input';
import Modal from 'antd/es/modal';
// import Dropdown from 'antd/es/dropdown';
// import Menu from 'antd/es/menu';
import message from 'antd/es/message';

import '../../../styles/Background/ego_manager.css';
import '../../../styles/Background/UI_Module/user_management.css';

import '../../../styles/icon_image.css';
import '../../../styles/Background/ego_manager.css';
import '../../../styles/Background/store_manager.css';
import '../../../styles/Background/UI_Module/general_data_show.css';

const g_strMessageKey = 'updating';
global.arrVipData = [];

// const { MonthPicker } = DatePicker;


// 表格
const arrTableHeader = [
    { title: '名称', dataIndex: 'name', width: 50, ellipsis: true },
    { title: '折扣', dataIndex: 'price', width: 50, ellipsis: true },
    { title: '等级', dataIndex: 'level', width: 50, ellipsis: true },
    { title: '备注', dataIndex: 'comments', width: 150, ellipsis: true }
];

class ShipOrderVipSetting extends Component {
    constructor(props) {
        super();
        this.state = {
            bLoading: true,
            btnControlDisable: [false, true, true],
            selectedRowKeys: [],
            iDlgType: -1,
            bShowDlg: false,
            iCurrentLevel: 0
        };

        // 商家后台统计数据
        this.iUPSOrder = 0;  // 当月单量
        this.iCPOrder = 0;  // 总单量

        // 成员变量
        this.arrOgeTableData = [];
        this.arrTableData = [];
        this.objCurrentPrice = {};
        this.arrLevel = ['MB', 'VIP', 'VVIP', 'SVIP', 'SSVIP', 'CRVIP', 'SCRVIP', 'FLAT'];
    }
    // 选中某行的响应函数
    selectRow = (record) => {
        const selectedRowKeys = [...this.state.selectedRowKeys];
        if (selectedRowKeys.indexOf(record.key) >= 0) {
            selectedRowKeys.splice(selectedRowKeys.indexOf(record.key), 1);
        } else {
            selectedRowKeys.push(record.key);
        }
        this.SelectedRowStateSetting(selectedRowKeys);
    }
    // 选中的行发生变化的响应函数
    onSelectedRowKeysChange = (selectedRowKeys) => {
        this.SelectedRowStateSetting(selectedRowKeys);
    }
    // 处理选中的表行行
    SelectedRowStateSetting = (selectedRowKeys) => {
        let btnControlDisable = [];
        switch (selectedRowKeys.length) {
            case 0: {
                btnControlDisable = [false, true, true];
                this.objCurrentPrice = {};
                break;
            }
            case 1: {
                btnControlDisable = [false, false, false];
                for (let i = 0; i < this.arrTableData.length; i++) {
                    if (this.arrTableData[i].key === selectedRowKeys[0]) {
                        this.objCurrentPrice = this.arrTableData[i];
                        break;
                    }
                }
                break;
            }
            default: {
                btnControlDisable = [false, true, true]; break;
            }
        }
        this.setState({
            ...this.state,
            selectedRowKeys: selectedRowKeys,
            btnControlDisable: btnControlDisable
        });
    }
    //弹出对话框
    ShowDlg = (iDlgType, e) => {
        if (iDlgType === 0) {
            this.objCurrentPrice = {};
            if (this.refs.name !== undefined) {
                this.refs.name.state.value = 'VIP';
                this.refs.price.state.value = 1.0;
                this.refs.level.state.value = 0;
                this.refs.comments.state.value = '';
            }
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                iCurrentLevel: 0,
                bShowDlg: true
            });
        }
        if (iDlgType === 1) {
            if (this.refs.name !== undefined) {
                this.refs.name.state.value = this.objCurrentPrice.name;
                this.refs.price.state.value = this.objCurrentPrice.price;
                this.refs.level.state.value = this.objCurrentPrice.level;
                this.refs.comments.state.value = this.objCurrentPrice.comments;
            }
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                iCurrentLevel: this.objCurrentPrice.vip,
                bShowDlg: true
            });
        }
        if (iDlgType === 2) {
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true
            });
        }
    }
    // 弹窗确认按钮响应
    handleOk = e => {
        if (this.state.iDlgType === 0) {
            let strName = this.refs.name.state.value;
            let strDiscount = this.refs.price.state.value;
            let strLevel = this.refs.level.state.value;
            let strComments = this.refs.comments.state.value;
            if (strName === undefined || strName === null || strName === "") {
                message.warning("名称不能为空！"); return;
            }
            if (strDiscount === undefined || strDiscount === null || strDiscount === "") {
                message.warning('折扣不能为空！'); return;
            }
            let fDiscount = parseFloat(strDiscount);
            if (isNaN(fDiscount)) {
                message.warning('折扣必须为数字'); return;
            }
            if (fDiscount <= 1.0) {
                message.warning('折扣必须大于1.0 ！'); return;
            }
            if (strLevel === undefined || strLevel === null || strLevel === "") {
                message.warning('等级不能为空！'); return;
            }
            let iLevel = parseFloat(strLevel);
            if (isNaN(iLevel)) {
                message.warning('等级必须为数字'); return;
            }
            if (iLevel <= 0) {
                message.warning('等级必须大于0 ！'); return;
            }
            if (strComments === undefined || strComments === null) {
                strComments = "";
            }
            let objNewPrice = {
                store_id: this.props.iStoreID,
                name: strName,
                price: fDiscount,
                level: iLevel,
                comments: strComments
            }
            console.log("new price : ", objNewPrice);
            message.loading({ content: '正在创建VIP 价格……', key: g_strMessageKey });
            axios.post('/NewVipPrice', objNewPrice).then(({ data }) => {
                console.log(data);
                if (data.status === 1) {
                    this.GetVipPriceData();
                }
                else
                    message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
            }).catch(function (error) { console.log(error); });
        }
        if (this.state.iDlgType === 1) {
            let strName = this.refs.name.state.value;
            let strDiscount = this.refs.price.state.value;
            let strLevel = this.refs.level.state.value;
            let strComments = this.refs.comments.state.value;
            if (strName === undefined || strName === null || strName === "") {
                message.warning("名称不能为空！"); return;
            }
            if (strDiscount === undefined || strDiscount === null || strDiscount === "") {
                message.warning('折扣不能为空！'); return;
            }
            let fDiscount = parseFloat(strDiscount);
            if (isNaN(fDiscount)) {
                message.warning('折扣必须为数字'); return;
            }
            if (fDiscount <= 1.0) {
                message.warning('折扣必须大于1.0 ！'); return;
            }
            if (strLevel === undefined || strLevel === null || strLevel === "") {
                message.warning('等级不能为空！'); return;
            }
            let iLevel = parseFloat(strLevel);
            if (isNaN(iLevel)) {
                message.warning('等级必须为数字'); return;
            }
            if (iLevel <= 0) {
                message.warning('等级必须大于0 ！'); return;
            }
            if (strComments === undefined || strComments === null) {
                strComments = "";
            }
            let objNewPrice = {
                store_id: this.props.iStoreID,
                name: strName,
                price: fDiscount,
                level: iLevel,
                comments: strComments
            }
            console.log("new price : ", objNewPrice);
            console.log("current price : ", this.objCurrentPrice);
            message.loading({ content: 'VIP 价格 更新中……', key: g_strMessageKey });
            axios.post('/UpdateVipPrice', {
                objCurrentPrice: this.objCurrentPrice, objNewPrice: objNewPrice
            }).then(({ data }) => {
                console.log("data : ", data);
                if (data.status === 1)
                    this.GetVipPriceData();
                else
                    message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
            }).catch(function (error) { console.log(error); });
        }
        if (this.state.iDlgType === 2) {
            message.loading({ content: 'VIP 价格 删除中……', key: g_strMessageKey });
            axios.post('/DeleteVipPrice', { id: this.objCurrentPrice.id })
                .then(({ data }) => {
                    if (data.status === -1) {
                        message.warning({ content: "删除失败，请重新尝试！", key: g_strMessageKey, duration: 2 });
                        this.setState({
                            ...this.state,
                            bLoading: false,
                            bShowDlg: false,
                            selectedRowKeys: []
                        });
                    } else {
                        this.GetVipPriceData();
                    }
                }).catch(function (error) { console.log(error); });
        }
    };
    // 弹窗取消按钮响应
    handleCancel = e => {

        this.setState({
            ...this.state,
            bShowDlg: false,
            selectedRowKeys: []
        });
    };
    // 整理User数据
    OrganizeVipPriceData(data) {
        let arrTableData = [];
        for (let i = 0; i < data.length; i++) {
            let strComments = data[i].comments;
            if (strComments === null || strComments === undefined)
                strComments = "";

            let objUserData = {
                key: i, id: data[i].id,
                store_id: data[i].store_id,
                name: data[i].name,
                price: data[i].price,
                level: data[i].level,
                comments: strComments
            }
            arrTableData.push(objUserData);
        }
        return arrTableData;
    }
    // 从数据库获取当前的数据结构
    GetVipPriceData = () => {
        message.loading({ content: '数据加载中……', key: g_strMessageKey });
        axios('/GetVipPrice', { params: { store_id: this.props.iStoreID } })
            .then(({ data }) => {
                console.log(data);
                global.arrVipData = data;
                this.arrOgeTableData = this.OrganizeVipPriceData(data);
                this.arrTableData = this.arrOgeTableData;
                if (this.state.bShowDlg) {
                    if (this.state.iDlgType === 0) {
                        message.success({ content: "新建VIP 价格成功！", key: g_strMessageKey, duration: 2 });
                    }
                    if (this.state.iDlgType === 1) {
                        message.success({ content: "VIP 价格信息已更新！", key: g_strMessageKey, duration: 2 });
                    }
                    if (this.state.iDlgType === 2) {
                        message.success({ content: "VIP 价格已删除！", key: g_strMessageKey, duration: 2 })
                    }
                } else {
                    message.success({ content: "数据加载完成！", key: g_strMessageKey, duration: 2 });
                }
                this.setState({
                    ...this.state,
                    bLoading: false,
                    bShowDlg: false,
                    selectedRowKeys: []
                });
            }).catch(function (error) { console.log(error); });
    }
    // 显示全部VIP 价格
    ShowAllVipPrice = () => {
        this.refs.name_search.state.value = "";
        this.UserNameSearch();
    }
    // 切换VIP 等级
    SwitchVIPLevel = (index) => {
        this.setState({
            ...this.state,
            iCurrentLevel: index
        });
    }


    // 卸载异步操作设置状态
    componentWillUnmount() {
        clearTimeout(this.timeouter)
        this.setState = (state, callback) => {
            return;
        }
    }
    // 渲染完毕……
    componentDidMount() {
        // console.log(this.props.iUserID, this.props.fMoney, this.props.iLevel);
        // this.OrderCalculate();
        this.GetVipPriceData();
    }

    render() {


        // let uiStoreData = (
        //     <div className="general_data_show">
        //         <div className="general_data_show_row">
        //             <div className="general_data_show_cell">
        //                 <Statistic title="余额(CAD)" value={this.props.fMoney} precision={2} prefix={<Icon type="dollar" />} />
        //             </div>
        //             <div className="general_data_show_cell">
        //                 <Statistic title="UPS单量" value={this.iUPSOrder} prefix={<Icon type="form" />} />
        //             </div>
        //             <div className="general_data_show_cell">
        //                 <Statistic title="加邮单量" value={this.iCPOrder} prefix={<Icon type="form" />} />
        //             </div>
        //         </div>
        //     </div>
        // );

        let uiContents = null;
        let uiModal = null;
        let uiNewButton = null;

        if (!this.state.bLoading) {
            let bDisabled = true;
            if (this.props.iLevel >= 9) {
                bDisabled = false;
                uiNewButton = (
                    <Button className="manage_contents_title_margin" type="primary" onClick={this.ShowDlg.bind(this, 0)} disabled={this.state.btnControlDisable[0]}>
                        <Icon type="edit" />新建</Button>
                );
            }

            // 主体表格相关
            const { selectedRowKeys } = this.state;
            const rowSelection = {
                selectedRowKeys,
                onChange: this.onSelectedRowKeysChange,
                columnWidth: 30
            };
            // 表格分页属性
            const paginationProps = {
                showSizeChanger: true,
                showQuickJumper: false,
                showTotal: () => `共 ${this.arrTableData.length} 条 VIP 价格`,
                defaultPageSize: 10,
                defaultCurrent: this.state.iCurrentPage,
                pageSizeOptions: ['10', '20', '30', '40', '50'],
                total: this.arrTableData.length,
                position: 'bottom'
            };
            // 地区下拉菜单
            // const menuVIPLevel = (
            //     <Menu>
            //         {this.arrLevel.map((level, index) => {
            //             return (
            //                 <Menu.Item key={index} onClick={this.SwitchVIPLevel.bind(this, index)}>
            //                     {level}
            //                 </Menu.Item>
            //             );
            //         })}
            //     </Menu>
            // );
            // VIP 价格信息编辑弹窗
            uiModal = (
                <Modal title="VIP 价格信息" width={999} visible={this.state.bShowDlg}
                    okText="确定" cancelText="取消" onOk={this.handleOk} onCancel={this.handleCancel} >
                    <div className="jeton_order_block">
                        <div className="jeton_order_row">
                            <div className="jeton_order_row_title jeton_order_row_title_require" >名称：</div>
                            <Input className="jeton_order_row_input" ref="name" disabled={bDisabled}
                                defaultValue={this.objCurrentPrice.name} />
                            <div className="jeton_order_row_title jeton_order_row_title_require" >折扣：</div>
                            <Input className="jeton_order_row_input" ref="price" disabled={bDisabled}
                                defaultValue={this.objCurrentPrice.price} />
                            <div className="jeton_order_row_title jeton_order_row_title_require" >等级：</div>
                            <Input className="jeton_order_row_input" ref="level" disabled={bDisabled}
                                defaultValue={this.objCurrentPrice.level} />
                        </div>
                        <div className="jeton_order_row">
                            <div className="jeton_order_row_title" >备注：</div>
                            <Input className="jeton_order_row_input_full" ref="comments"
                                defaultValue={this.objCurrentPrice.comments} />
                        </div>
                    </div>
                </Modal>
            );
            if (this.state.iDlgType === 2) {
                uiModal = (
                    <Modal width={500} title={"删除VIP 价格"} visible={this.state.bShowDlg} okType="danger"
                        onOk={this.handleOk} okText="确定"
                        onCancel={this.handleCancel} cancelText="取消">
                        <div className="modal_item">
                            <div>{this.objCurrentPrice.name}
                                <br /><br />选中VIP 价格的所有信息将被删除……<br />确定删除选中的VIP 价格？
                            </div>
                        </div>
                    </Modal>
                );
            }

            uiContents = (
                <Table style={{ margin: '10px', marginLeft: '25px', width: '60%', overflowX: 'auto' }} bordered
                    rowSelection={rowSelection} pagination={paginationProps}
                    columns={arrTableHeader} dataSource={this.arrTableData}
                    onRow={(record) => ({ onClick: () => { this.selectRow(record); } })} />
            );
        } else {
            uiContents = (<Spin className="manage_empty_loading" size="large" tip="加载中……" />);
        }

        let uiTitleArea = (
            < div className="store_contents_title" >
                {uiNewButton}
                <Button className="manage_contents_title_margin" type="primary" onClick={this.ShowDlg.bind(this, 1)} disabled={this.state.btnControlDisable[1]}>
                    <Icon type="edit" />编辑</Button>
                <Button className="manage_contents_title_margin" type="danger" onClick={this.ShowDlg.bind(this, 2)} disabled={this.state.btnControlDisable[2]}>
                    <Icon type="close" />删除</Button>
            </div >
        );

        return (
            <div className="store_manage_contents store_manage_top_space" /*style={{ height: '100%' }}*/>
                {uiTitleArea}
                {uiModal}
                {uiContents}
            </div >
        );
    }
}
export default ShipOrderVipSetting;
