import React, { Component } from 'react';
import axios from 'axios';

import Spin from 'antd/es/spin';
import Table from 'antd/es/table';
import Button from 'antd/es/button';
import Input from 'antd/es/input';
import Icon from 'antd/es/icon';
import Modal from 'antd/es/modal';
import message from 'antd/es/message';

import '../../../styles/Background/ego_manager.css';
import '../../../styles/Background/UI_Module/user_management.css';
import { UserActiveUpdate } from "../../../functions/DataStatistic.js"

const { TextArea } = Input;

const g_strMessageKey = 'updating';
global.arrClientData = [];

// 表格
const arrTableHeader = [
    // { title: 'id', dataIndex: 'id', width: 0, ellipsis: true },
    { title: '客户', dataIndex: 'name', width: 200, ellipsis: true },
    { title: '账号', dataIndex: 'user_name', width: 125, ellipsis: true },
    { title: '电话', dataIndex: 'phone', width: 150, ellipsis: true },
    { title: '邮箱', dataIndex: 'email', width: 175, ellipsis: true },
    { title: '地址', dataIndex: 'address', width: 250, ellipsis: true },
    { title: '备注', dataIndex: 'comments', width: 250, ellipsis: true }
];

class LogisticClientManager extends Component {
    constructor(props) {
        super();
        this.state = {
            btnControlDisable: [false, true, true],  // 操作按钮控制
            btnCopyPasteDisable: [true, true],  // 操作按钮控制
            selectedRowKeys: [],  // Check here to configure the default column
            objFilter: {
                strKeyword: "", iCurrentUserStatus: 0, iCurrentUserType: 0
            },
            iDlgType: -1,
            bShowDlg: false,
            bLoading: true,
            strNewPassword: "",
            strConfirmPassword: "",
            bOpenAcountDlg: false
        };
        // 成员变量
        this.arrOgeTableData = [];
        this.arrTableData = [];  // 用于表格的数组
        this.arrFilterUserStatus = ['全部', '正常使用', '审核中', '已禁用'];
        this.arrFilterUserType = ['全部', '普通客户', '商家', '管理员', '超级管理员'];
        this.arrSortType = ['客户名A-Z', '客户名Z-A', '注册时间↓', '注册时间↑'];
        this.arrSelectedUserID = [];
        this.arrSelectedUserIndex = [];
        this.objCurrentClient = {};
        // this.strNewPassword = "";
        // this.strConfirmPassword = "";

    }

    // 选中某行的响应函数
    selectRow = (record) => {
        const selectedRowKeys = [...this.state.selectedRowKeys];
        if (selectedRowKeys.indexOf(record.key) >= 0) {
            selectedRowKeys.splice(selectedRowKeys.indexOf(record.key), 1);
        } else {
            selectedRowKeys.push(record.key);
        }
        this.SelectedRowStateSetting(selectedRowKeys);
    }
    // 选中的行发生变化的响应函数
    onSelectedRowKeysChange = (selectedRowKeys) => {
        this.SelectedRowStateSetting(selectedRowKeys);
    }
    // 处理选中的表行行
    SelectedRowStateSetting = (selectedRowKeys) => {
        let btnControlDisable = [];
        let btnCopyPasteDisable = [];
        switch (selectedRowKeys.length) {
            case 0: {
                btnControlDisable = [false, true, true];
                btnCopyPasteDisable = [true, true];
                this.objCurrentClient = {};
                break;
            }
            case 1: {
                btnControlDisable = [false, false, false];
                btnCopyPasteDisable = [false, true];
                for (let i = 0; i < this.arrTableData.length; i++) {
                    if (this.arrTableData[i].key === selectedRowKeys[0]) {
                        this.objCurrentClient = this.arrTableData[i];
                        break;
                    }
                }
                break;
            }
            default: {
                btnControlDisable = [false, true, true]; btnCopyPasteDisable = [true, true]; break;
            }
        }
        this.setState({
            ...this.state,
            selectedRowKeys,
            btnControlDisable: btnControlDisable,
            btnCopyPasteDisable: btnCopyPasteDisable
        });
    }
    // 关键词处理
    KeywordsProcess = (strKeywords) => {
        let newKeywords = "";
        for (let i = 0; i < strKeywords.length; i++) {
            if (strKeywords[i] !== " ") {
                if (i === strKeywords.length - 1)
                    newKeywords += strKeywords[i];
                else
                    newKeywords += strKeywords[i] + "%";
            }
        }
        return newKeywords;
    }
    // 搜索客户名
    ClientNameSearch = () => {
        let strKeywords = this.refs.name_search.state.value;
        if (strKeywords === undefined)
            strKeywords = "";
        if (strKeywords.trim() === "") {
            this.GetClientsData();
        } else {
            this.setState({
                ...this.state,
                bLoading: true,
                selectedRowKeys: [],
                btnControlDisable: [false, true, true]
            });
            axios('/SearchClients', { params: { name: this.KeywordsProcess(strKeywords), store_id: this.props.iStoreID } })
                .then(({ data }) => {
                    this.arrTableData = this.OrganizeClientsData(data);
                    this.setState({
                        ...this.state,
                        bLoading: false,
                        objFilter: {
                            strKeyword: strKeywords,
                            iCurrentUserStatus: 0,
                            iCurrentUserType: 0
                        }
                    });
                }).catch(function (error) { console.log(error); });
        }
    }
    /* 自定义成员函数 */
    //弹出对话框
    ShowDlg = (iDlgType, e) => {
        if (iDlgType === 0) {
            if (this.refs.name !== undefined) {
                this.refs.name.state.value = "";
                this.refs.phone.state.value = "";
                this.refs.email.state.value = "";
                this.refs.address.state.value = "";
                this.refs.comments.state.value = "";
            }
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true,
                strNewPassword: "",
                strConfirmPassword: ""
            });
        }
        if (iDlgType === 1) {
            if (this.refs.name !== undefined) {
                this.refs.name.state.value = this.objCurrentClient.name;
                this.refs.phone.state.value = this.objCurrentClient.phone;
                this.refs.email.state.value = this.objCurrentClient.email;
                this.refs.address.state.value = this.objCurrentClient.address;
                this.refs.comments.state.value = this.objCurrentClient.comments;
            }
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true,
                strNewPassword: "",
                strConfirmPassword: ""
            });
        }
        if (iDlgType === 2) {
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true
            });
        }
    }
    // 弹窗确认按钮响应
    handleOk = e => {
        if (this.state.iDlgType === 0) {
            let strName = this.refs.name.state.value;
            if (strName === null || strName.trim() === "") {
                message.warning("客户名不能为空！"); return;
            }
            let objNewClient = {
                name: strName,
                phone: this.refs.phone.state.value,
                email: this.refs.email.state.value,
                address: this.refs.address.state.value,
                comments: this.refs.comments.state.value,
                store_id: this.props.iStoreID
            };
            message.loading({ content: '正在更新客户信息……', key: g_strMessageKey });
            axios.post('/CreateNewClient', objNewClient).then(
                ({ data }) => {
                    if (data.status === 1) {
                        this.GetClientsData();
                    } else {
                        message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
                    }
                }).catch(function (error) { console.log(error); });
        }
        if (this.state.iDlgType === 1) {
            let strName = this.refs.name.state.value;
            if (strName === null || strName.trim() === "") {
                message.warning("客户名不能为空！"); return;
            }
            let objNewClient = {
                name: strName,
                phone: this.refs.phone.state.value,
                email: this.refs.email.state.value,
                address: this.refs.address.state.value,
                comments: this.refs.comments.state.value,
                store_id: this.props.iStoreID
            };
            message.loading({ content: '正在更新客户信息……', key: g_strMessageKey });
            axios.post('/UpdateLogisticClient', {
                objCurrentClient: this.objCurrentClient,
                objNewClient: objNewClient
            }).then(({ data }) => {
                if (data.status === 1)
                    this.GetClientsData();
                else
                    message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
            }).catch(function (error) { console.log(error); });
        }
        if (this.state.iDlgType === 2) {
            message.loading({ content: '客户删除中……', key: g_strMessageKey });
            axios.post('/DeleteClient', { id: this.objCurrentClient.id }).then(
                ({ data }) => {
                    if (data.status === -1) {
                        message.warning({
                            content: "删除失败，该客户已绑定商家，请先检查并删除商家！",
                            key: g_strMessageKey, duration: 2
                        });
                        this.setState({
                            ...this.state,
                            bLoading: false,
                            bShowDlg: false,
                            selectedRowKeys: []
                        });
                    } else {
                        // DataStatistic
                        UserActiveUpdate(4, { active: "管理后台: 客户管理操作", action: "删除" });
                        this.GetClientsData();
                    }
                }).catch(function (error) { console.log(error); });
        }
    };
    // 弹窗取消按钮响应
    handleCancel = e => {
        this.setState({
            bShowDlg: false,
        });
    };
    // 整理Users数据
    OrganizeClientsData(data) {
        let arrTableData = [];
        for (let i = 0; i < data.length; i++) {
            let strPhone = data[i].phone;
            if (strPhone === null || strPhone === "") {
                strPhone = data[i].contact_phone;
            }
            if (data[i].price_off === null || data[i].price_off === "")
                data[i].price_off = 0;
            let objClientData = {
                key: i, id: data[i].id,
                name: data[i].name,
                phone: strPhone,
                email: data[i].email,
                address: data[i].address,
                comments: data[i].comments,
                user_id: data[i].user_id,
                user_name: data[i].user_name
            }
            arrTableData.push(objClientData);
        }
        return arrTableData;
    }
    // 从数据库获取当前的数据结构
    GetClientsData() {
        message.loading({ content: '数据加载中……', key: g_strMessageKey });
        axios('/GetClients', { params: { store_id: this.props.iStoreID } })
            .then(({ data }) => {
                global.arrClientData = data;
                this.arrOgeTableData = this.OrganizeClientsData(data);
                this.arrTableData = this.arrOgeTableData;
                if (this.state.bShowDlg) {
                    if (this.state.iDlgType === 0) {
                        message.success({ content: "新建客户信息成功！", key: g_strMessageKey, duration: 2 });
                    }
                    if (this.state.iDlgType === 1) {
                        message.success({ content: "客户信息已更新！", key: g_strMessageKey, duration: 2 });
                    }
                    if (this.state.iDlgType === 2) {
                        message.success({ content: "客户已删除！", key: g_strMessageKey, duration: 2 })
                    }
                } else {
                    message.success({ content: "数据加载完成！", key: g_strMessageKey, duration: 2 });
                }
                this.setState({
                    ...this.state,
                    bLoading: false,
                    bShowDlg: false,
                    selectedRowKeys: []
                });
            }).catch(function (error) { console.log(error); });
    }
    // 开通账号
    OpenUserAccount = () => {
        if (this.refs.user_name !== undefined)
            this.refs.user_name.state.value = this.objCurrentClient.user_name;
        this.setState({
            ...this.state,
            bOpenAcountDlg: true
        })

    }
    // 弹窗确认按钮响应
    handleOpenAcountOk = e => {
        let strName = this.refs.user_name.state.value;
        let strPhone = this.objCurrentClient.phone;
        let strEmail = this.objCurrentClient.email;
        if (strName === undefined || strName === null || strName === "") {
            message.warning("用户名不能为空！"); return;
        }
        if (this.state.strConfirmPassword !== this.state.strNewPassword) {
            message.warning("两次输入密码不一致！"); return;
        }
        if ((strPhone === undefined || strPhone === null || strPhone === "") ||
            (strEmail === undefined || strEmail === null || strEmail === "")) {
            message.warning('电话和邮箱不能为空！'); return;
        }

        let objNewUser = {
            name: strName,
            password: this.state.strNewPassword,
            level: 1,
            status: 1,
            store_id: 10000,
            phone: strPhone,
            email: strEmail,
            client: this.objCurrentClient.name,
            client_id: this.objCurrentClient.id
        }

        // 新建
        if (this.objCurrentClient.user_id === null || this.objCurrentClient.user_id === undefined || this.objCurrentClient.user_id === "") {
            if (this.state.strNewPassword === "") {
                message.warning("密码不能为空！"); return;
            }
            message.loading({ content: '正在开通客户账号……', key: g_strMessageKey });
            axios.post('/NewUser', objNewUser).then(({ data }) => {
                console.log(data);
                if (data.status === 1) {
                    let objNewClient = {
                        name: this.objCurrentClient.name,
                        user_id: data.id,
                        user_name: objNewUser.name
                    };
                    message.loading({ content: '正在更新客户信息……', key: g_strMessageKey });
                    axios.post('/UpdateLogisticClient', {
                        objCurrentClient: this.objCurrentClient,
                        objNewClient: objNewClient
                    }).then(({ data }) => {
                        if (data.status === 1) {
                            message.info({ content: data.message, key: g_strMessageKey, duration: 2 });
                            this.setState({
                                ...this.state,
                                bOpenAcountDlg: false
                            });
                        } else
                            message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
                    }).catch(function (error) { console.log(error); });
                }
                else
                    message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
            }).catch(function (error) { console.log(error); });
        }
        // 编辑
        else {
            if (objNewUser.password === "" || objNewUser.password === null || objNewUser.password === undefined) {
                objNewUser = {
                    name: strName,
                    client: this.objCurrentClient.name,
                    client_id: this.objCurrentClient.id
                }
            } else {
                objNewUser = {
                    name: strName,
                    password: this.state.strNewPassword,
                    client: this.objCurrentClient.name,
                    client_id: this.objCurrentClient.id
                }
            }
            let objCurrentUser = { id: this.objCurrentClient.user_id, name: this.objCurrentClient.user_name, client: this.objCurrentClient.client };
            console.log("new user : ", objNewUser);
            console.log("current user : ", objCurrentUser);
            message.loading({ content: '正在更新用户信息……', key: g_strMessageKey });
            axios.post('/UpdateLogisticUserInfo', {
                objCurrentUser: objCurrentUser,
                objNewUser: objNewUser
            }).then(({ data }) => {
                console.log(data);
                if (data.status === 1) {
                    let objNewClient = {
                        name: this.objCurrentClient.name,
                        user_id: data.id,
                        user_name: objNewUser.name
                    };
                    message.loading({ content: '正在更新客户信息……', key: g_strMessageKey });
                    axios.post('/UpdateLogisticClient', {
                        objCurrentClient: this.objCurrentClient,
                        objNewClient: objNewClient
                    }).then(({ data }) => {
                        if (data.status === 1) {
                            message.info({ content: data.message, key: g_strMessageKey, duration: 2 });
                            this.setState({
                                ...this.state,
                                bOpenAcountDlg: false
                            });
                        } else
                            message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
                    }).catch(function (error) { console.log(error); });
                } else
                    message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
            }).catch(function (error) { console.log(error); });
        }


    };
    // 弹窗取消按钮响应
    handleOpenAcountCancel = e => {
        this.setState({
            ...this.state,
            bOpenAcountDlg: false
        });
    };


    /* React自带函数 */
    // 卸载异步操作设置状态
    componentWillUnmount() {
        clearTimeout(this.timeouter)
        this.setState = (state, callback) => {
            return;
        }
    }
    // 渲染完毕……
    componentDidMount() {
        this.GetClientsData();

    }
    // 渲染函数
    render() {

        // 顶部栏客户类别下拉菜单
        // const uiUserTypeMenu = (
        //     <Menu>
        //         {this.arrFilterUserType.map((item, index) => {
        //             return (
        //                 <Menu.Item key={index} onClick={this.UserTypeFilterClicked.bind(this, index)}>
        //                     {item}
        //                 </Menu.Item>
        //             );
        //         })}
        //     </Menu>
        // );
        // 顶部栏客户状态下拉菜单
        // const uiUserStatusMenu = (
        //     <Menu>
        //         {this.arrFilterUserStatus.map((item, index) => {
        //             return (
        //                 <Menu.Item key={index} onClick={this.UserStatusFilterClicked.bind(this, index)}>
        //                     {item}
        //                 </Menu.Item>
        //             );
        //         })}
        //     </Menu>
        // );

        /* 客户管理页面控制栏 */
        let uiTitleArea = (
            < div className="logistics_page_title" >
                <div className="manage_stretch_right">
                    <Input placeholder="搜索客户名/代码" ref="name_search" defaultValue={this.state.strKeywords} />
                    <Button className="manage_contents_title_margin"
                        type="primary" onClick={this.ClientNameSearch}>搜索
                    </Button>
                </div>
                {/* <div className="manage_title_filter_area">
                    <span className="manage_stretch_right_tiny">客户类别</span>
                    <Dropdown className="manage_contents_title_margin manage_stretch_right"
                        overlay={uiUserTypeMenu} placement="bottomLeft">
                        <Button>
                            {this.arrFilterUserType[this.state.objFilter.iCurrentUserType]}
                            <Icon className="dropdown_icon" type="down" />
                        </Button>
                    </Dropdown>
                </div> */}
                <div className="manage_title_button_right_side">

                    <Button className="manage_contents_title_margin" type="primary" onClick={this.ShowDlg.bind(this, 0)} >
                        <Icon type="plus" />新建</Button>
                    <Button className="manage_contents_title_margin" type="primary" onClick={this.ShowDlg.bind(this, 1)} disabled={this.state.btnControlDisable[1]}>
                        <Icon type="edit" />编辑</Button>
                    <Button className="manage_contents_title_margin" type="danger" onClick={this.ShowDlg.bind(this, 2)} disabled={this.state.btnControlDisable[2]}>
                        <Icon type="close" /> 删除</Button>
                </div>
            </div >
        );

        // 如果界面上需要显示的内容已经从数据库获得
        if (!this.state.bLoading) {
            // 主体表格相关
            const { selectedRowKeys } = this.state;
            const rowSelection = {
                selectedRowKeys,
                onChange: this.onSelectedRowKeysChange,
                columnWidth: 30
            };
            // 表格分页属性
            const paginationProps = {
                showSizeChanger: true,
                showQuickJumper: false,
                showTotal: () => `共 ${this.arrTableData.length} 个 客户`,
                defaultPageSize: 10,
                defaultCurrent: this.state.iCurrentPage,
                pageSizeOptions: ['10', '20', '30', '40', '50'],
                total: this.arrTableData.length,
                position: 'bottom'
            };
            // let uiUserLevel = (
            //     <Menu>
            //         {this.arrUserLevel.map((user, index) => {
            //             return (
            //                 <Menu.Item key={index} onClick={this.ChangeUserLevel.bind(this, index)}>
            //                     {user.name}
            //                 </Menu.Item>
            //             );
            //         })}
            //     </Menu>
            // );
            // let strOpenAcount = "开通账号";
            // if (this.objCurrentClient.user_id !== undefined && this.objCurrentClient.user_id !== null && this.objCurrentClient.user_id !== "")
            //     strOpenAcount = "编辑账号";
            let uiDlgTitle = (
                <div className="user_edit_info_row">
                    <div className="user_edit_info_title">编辑客户</div>
                    {/* <Button type="primary" onClick={this.OpenUserAccount} style={{ marginLeft: 'auto', marginRight: '50px' }}>
                        {strOpenAcount}</Button> */}
                </div>
            );

            // 客户信息编辑弹窗
            let uiModal = (
                <Modal title={uiDlgTitle} width={725} visible={this.state.bShowDlg}
                    okText="确定" cancelText="取消" onOk={this.handleOk} onCancel={this.handleCancel} >
                    <div className="user_edit_info_row">
                        <div className="user_edit_info_title">名称：</div>
                        <Input className="user_edit_info_input_long" ref="name" style={{ marginRight: '25px' }}
                            defaultValue={this.objCurrentClient.name} />
                        <div className="user_edit_info_title">电话：</div>
                        <Input className="user_edit_info_input_long" ref="phone"
                            defaultValue={this.objCurrentClient.phone} />
                    </div>
                    <div className="user_edit_info_row">
                        <div className="user_edit_info_title">邮箱：</div>
                        <Input className="user_edit_info_input_full" ref="email"
                            defaultValue={this.objCurrentClient.email} />
                    </div>
                    <div className="user_edit_info_row">
                        <div className="user_edit_info_title">地址：</div>
                        <Input className="user_edit_info_input_full" ref="address"
                            defaultValue={this.objCurrentClient.address} />
                    </div>
                    <div className="user_edit_info_row">
                        <div className="user_edit_info_title">备注：</div>
                        <TextArea size="large" autoSize={{ minRows: 2 }} allowClear={true} ref="comments" style={{ marginBottom: '15px' }}
                            placeholder="" defaultValue={this.objCurrentClient.comments} />
                    </div>
                </Modal>
            );
            if (this.state.iDlgType === 2) {
                uiModal = (
                    <Modal width={500} title={"删除客户信息"} visible={this.state.bShowDlg} okType="danger"
                        onOk={this.handleOk} okText="确定"
                        onCancel={this.handleCancel} cancelText="取消">
                        <div className="modal_item">
                            <div>{this.objCurrentClient.name}
                                <br /><br />确定删除选中的客户？
                            </div>
                        </div>
                    </Modal>
                );
            }

            // 用户信息编辑弹窗
            let uiOpenAcountDlg = (
                <Modal title="开通账户" width={350} visible={this.state.bOpenAcountDlg}
                    okText="确定" cancelText="取消" onOk={this.handleOpenAcountOk} onCancel={this.handleOpenAcountCancel} >
                    <div className="user_edit_info_row">
                        <div className="user_edit_info_title">用户名：</div>
                        <Input className="user_edit_info_input" ref="user_name"
                            defaultValue={this.objCurrentClient.user_name} />
                    </div>
                    <div className="user_edit_info_row">
                        <div className="user_edit_info_title">新的密码：</div>
                        <Input.Password className="user_edit_info_input" ref="new_password"
                            onChange={e => this.setState({ strNewPassword: e.target.value })}
                            defaultValue={""} value={this.state.strNewPassword} />
                    </div>
                    <div className="user_edit_info_row">
                        <div className="user_edit_info_title">确认密码：</div>
                        <Input.Password className="user_edit_info_input" ref="comfirm_password"
                            onChange={e => this.setState({ strConfirmPassword: e.target.value })}
                            defaultValue={""} value={this.state.strConfirmPassword} />
                    </div>
                </Modal>
            );

            let arrCurrentTableHeader = arrTableHeader;
            if (this.props.iStoreID === 999)
                arrCurrentTableHeader = [
                    { title: '客户', dataIndex: 'name', width: 150, ellipsis: true },
                    { title: '电话', dataIndex: 'phone', width: 150, ellipsis: true },
                    { title: '邮箱', dataIndex: 'email', width: 175, ellipsis: true },
                    { title: '地址', dataIndex: 'address', width: 250, ellipsis: true },
                    { title: '备注', dataIndex: 'comments', width: 250, ellipsis: true }
                ];
            let strAreaStyle = "logistics_page_contents";
            return (
                <div className={strAreaStyle} style={{ paddingTop: ' 50px' }}/*"store_manage_contents store_manage_top_space"*/>
                    {uiTitleArea}
                    {uiModal}
                    {uiOpenAcountDlg}
                    {/* 表格主体 */}
                    <Table style={{ margin: '20px', width: '97.5%', overflowX: 'auto' }}
                        rowSelection={rowSelection} pagination={paginationProps}
                        columns={arrCurrentTableHeader} dataSource={this.arrTableData}
                        onRow={(record) => ({ onClick: () => { this.selectRow(record); } })} />
                </div >
            );
        } else {
            return (
                <div className="logistics_page_contents">
                    {uiTitleArea}
                    <Spin className="manage_empty_loading" size="large" tip="加载中……" />
                </div>
            );
        }
    }
}
export default LogisticClientManager;
