import React, { Component } from 'react';
import axios from 'axios';

import Spin from 'antd/es/spin';
import Table from 'antd/es/table';
import Button from 'antd/es/button';
import Input from 'antd/es/input';
import Menu from 'antd/es/menu';
import Dropdown from 'antd/es/dropdown';
import Icon from 'antd/es/icon';
import Modal from 'antd/es/modal';
import Checkbox from 'antd/es/checkbox';
import message from 'antd/es/message';
import Barcode from 'react-barcode';

import ReactToPrint from "react-to-print";

import '../../../styles/Background/ego_manager.css';
import '../../../styles/Background/storage_manager.css';
import '../../../styles/Background/UI_Module/user_management.css';

const g_strMessageKey = 'updating';

// 表格
const arrClientTableHeader = [
    { title: '出库单号', dataIndex: 'code', width: 100, ellipsis: true },
    { title: '类型', dataIndex: 'channel_str', width: 75, ellipsis: true },
    { title: '状态', dataIndex: 'status_str', width: 75, ellipsis: true },
    { title: '物流', dataIndex: 'express_no', width: 100, ellipsis: true },
    { title: '费用', dataIndex: 'service_fee', width: 50, ellipsis: true },
    { title: '备注', dataIndex: 'comments', width: 150, ellipsis: true },
    { title: '更新日期', dataIndex: 'update_date_str', width: 100, ellipsis: true }
];

const arrManagerTableHeader = [
    { title: '出库单号', dataIndex: 'code', width: 100, ellipsis: true },
    { title: '客户', dataIndex: 'user_name', width: 75, ellipsis: true },
    { title: '类型', dataIndex: 'channel_str', width: 75, ellipsis: true },
    { title: '状态', dataIndex: 'status_str', width: 75, ellipsis: true },
    { title: '物流', dataIndex: 'express_no', width: 125, ellipsis: true },
    { title: '费用', dataIndex: 'service_fee', width: 50, ellipsis: true },
    { title: '备注', dataIndex: 'comments', width: 150, ellipsis: true },
    { title: '更新日期', dataIndex: 'update_date_str', width: 100, ellipsis: true }
];

class StorageDeliveryManager extends Component {
    constructor(props) {
        super();
        this.state = {
            btnControlDisable: [false, true, true],  // 操作按钮控制
            selectedRowKeys: [],  // Check here to configure the default column
            iDlgType: -1,
            bShowDlg: false,
            bLoading: true,
            bValueWeightChange: false,
            iStatus: 0,
            iChannel: 0,
            bAddNew: false,
            bSelectSKU: false,
            bSelectUser: false
        };
        // 成员变量
        this.arrOgeTableData = [];
        this.arrTableData = [];  // 用于表格的数组
        this.objCurrentDeliveryOrder = {};
        this.fValue = 0.0;
        this.fValueWeight = 0.0;
        this.fPriceWeight = 0.0;
        this.arrStatus = ['已预报', '已出单', '已打包', '已出库', '运输中', '已送达', '作废'];
        this.arrChannel = ['Canada Post', 'UPS', 'Fedex', '自助发货'];
        this.arrItemInfo = [{ box: 0, sku: '', name: '', num: 0, num_check: 0, status: 0 }];
        this.iTotalSKUNum = 0;
        this.iTotalItemNum = 0;

        this.strScanInput = "";
        this.timeLast = new Date();
        this.timeCurrent = new Date();
        this.strLastScanCode = "";
        this.strCurrentScanCode = "";
        this.iItemIndex = 0;
        this.iSKUIndex = 0;
    }

    // 选中某行的响应函数
    selectRow = (record) => {
        const selectedRowKeys = [...this.state.selectedRowKeys];
        if (selectedRowKeys.indexOf(record.key) >= 0) {
            selectedRowKeys.splice(selectedRowKeys.indexOf(record.key), 1);
        } else {
            selectedRowKeys.push(record.key);
        }
        this.SelectedRowStateSetting(selectedRowKeys);
    }
    // 选中的行发生变化的响应函数
    onSelectedRowKeysChange = (selectedRowKeys) => {
        this.SelectedRowStateSetting(selectedRowKeys);
    }
    // 处理选中的表行行
    SelectedRowStateSetting = (selectedRowKeys) => {
        let btnControlDisable = [];
        // let btnCopyPasteDisable = [];
        switch (selectedRowKeys.length) {
            case 0: {
                btnControlDisable = [false, true, true];
                // btnCopyPasteDisable = [true, true];
                this.CurrentDeliveryOrderInit();
                break;
            }
            case 1: {
                btnControlDisable = [false, false, false];
                // btnCopyPasteDisable = [false, true];
                for (let i = 0; i < this.arrTableData.length; i++) {
                    if (this.arrTableData[i].key === selectedRowKeys[0]) {
                        this.objCurrentDeliveryOrder = this.arrTableData[i];
                        break;
                    }
                }
                break;
            }
            default: {
                btnControlDisable = [false, true, true];
                // btnCopyPasteDisable = [true, true];
                break;
            }
        }
        this.setState({
            ...this.state,
            selectedRowKeys,
            btnControlDisable: btnControlDisable
            // btnCopyPasteDisable: btnCopyPasteDisable
        });
    }
    // 关键词处理
    KeywordsProcess = (strKeywords) => {
        let newKeywords = "";
        for (let i = 0; i < strKeywords.length; i++) {
            if (strKeywords[i] !== " ") {
                if (i === strKeywords.length - 1)
                    newKeywords += strKeywords[i];
                else
                    newKeywords += strKeywords[i] + "%";
            }
        }
        return newKeywords;
    }
    // 搜索DeliveryOrder名
    // UserNameSearch = () => {
    //     let strKeywords = this.refs.user_name_search.state.value;
    //     if (strKeywords === undefined)
    //         strKeywords = "";
    //     if (strKeywords.trim() === "") {
    //         this.GetDeliveryOrder();
    //     } else {
    //         this.setState({
    //             ...this.state,
    //             bLoading: true,
    //             selectedRowKeys: [],
    //             btnControlDisable: [false, true, true]
    //         });
    //         axios('/SearchUsers', { params: { name: this.KeywordsProcess(strKeywords) } })
    //             .then(({ data }) => {
    //                 this.arrTableData = this.OrganizeDeliveryOrderData(data);
    //                 this.setState({
    //                     ...this.state,
    //                     bLoading: false,
    //                     objFilter: {
    //                         strKeyword: strKeywords,
    //                         iCurrentUserStatus: 0,
    //                         iCurrentUserType: 0
    //                     }
    //                 });
    //             }).catch(function (error) { console.log(error); });
    //     }
    // }
    // 点击了DeliveryOrder过滤器
    // UserTypeFilterClicked = (iUserType) => {
    //     let strTargetType = this.arrFilterUserType[iUserType];
    //     if (iUserType !== 0) {
    //         this.arrTableData = [];
    //         for (let i = 0; i < this.arrOgeTableData.length; i++) {
    //             if (strTargetType === this.arrOgeTableData[i].level) {
    //                 this.arrTableData.push(this.arrOgeTableData[i]);
    //             }
    //         }
    //     } else {
    //         this.arrTableData = this.arrOgeTableData;
    //     }
    //     if (this.refs.user_name_search !== undefined)
    //         this.refs.user_name_search.state.value = "";
    //     this.setState({
    //         ...this.state,
    //         objFilter: {
    //             strKeyword: "",
    //             iCurrentUserStatus: this.state.objFilter.iCurrentUserStatus,
    //             iCurrentUserType: iUserType
    //         }
    //     });
    // }
    // 点击了DeliveryOrder状态过滤器
    // UserStatusFilterClicked = (iStatus) => {
    //     this.setState({
    //         ...this.state,
    //         objFilter: {
    //             strKeyword: "", iCurrentUserStatus: iStatus, iCurrentUserType: this.state.objFilter.iCurrentUserType
    //         }
    //     });
    // }
    /* 自定义成员函数 */
    //弹出对话框
    ShowDlg = (iDlgType, e) => {
        if (iDlgType === 0) {
            if (this.refs.code !== undefined) {
                this.refs.code.state.value = "";
                if (this.refs.length !== undefined) {
                    this.refs.length.state.value = 0.0;
                    this.refs.width.state.value = 0.0;
                    this.refs.height.state.value = 0.0;
                    this.refs.value.state.value = 0.0;
                    this.refs.value_weight.state.value = 0.0;
                    this.refs.weight.state.value = 0.0;
                }
                this.refs.express_no.state.value = "";
                this.refs.service_fee.state.value = "";
                this.refs.comments.state.value = "";
            }
            this.fValue = 0.0;
            this.fValueWeight = 0.0;
            this.fPriceWeight = 0.0;
            this.CurrentDeliveryOrderInit();
            this.arrItemInfo = [{ sku: '', name: '', num: 0, num_check: 0, status: 0, inventory: 0 }];
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true,
                iStatus: 0,
                iChannel: 0
            });
        }
        if (iDlgType === 1) {
            if (this.refs.code !== undefined) {
                this.refs.code.state.value = this.objCurrentDeliveryOrder.code;
                if (this.refs.length !== undefined) {
                    this.refs.length.state.value = this.objCurrentDeliveryOrder.length;
                    this.refs.width.state.value = this.objCurrentDeliveryOrder.width;
                    this.refs.height.state.value = this.objCurrentDeliveryOrder.height;
                    this.refs.value.state.value = this.objCurrentDeliveryOrder.value;
                    this.refs.weight.state.value = this.objCurrentDeliveryOrder.weight;
                    this.refs.value_weight.state.value = this.objCurrentDeliveryOrder.value_weight;
                }
                this.refs.service_fee.state.value = this.objCurrentDeliveryOrder.service_fee;
                this.refs.express_no.state.value = this.objCurrentDeliveryOrder.express_no;
                this.refs.comments.state.value = this.objCurrentDeliveryOrder.comments;
            }
            this.fValue = parseFloat(this.objCurrentDeliveryOrder.value);
            this.fValueWeight = parseFloat(this.objCurrentDeliveryOrder.value_weight);
            this.fPriceWeight = parseFloat(this.objCurrentDeliveryOrder.price_weight);
            message.loading({ content: '数据加载中……', key: g_strMessageKey });
            axios('/GetDeliveryOrderItemInfo', { params: { delivery: this.objCurrentDeliveryOrder.code } })
                .then(({ data }) => {
                    message.success({ content: "货物信息加载完成！", key: g_strMessageKey, duration: 2 });
                    if (data.length > 0) {
                        this.arrItemInfo = data;
                        this.GetInventoryInfo();
                    } else {
                        this.arrItemInfo = [];
                    }
                    this.setState({
                        ...this.state,
                        iDlgType: iDlgType,
                        bShowDlg: true,
                        iStatus: this.objCurrentDeliveryOrder.status,
                        iChannel: this.objCurrentDeliveryOrder.channel
                    });
                }).catch(function (error) { console.log(error); });
        }
        if (iDlgType === 2) {
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true
            });
        }
    }
    // 弹窗确认按钮响应
    handleOk = e => {
        if (this.state.iDlgType === 0 || this.state.iDlgType === 1) {

            if (!this.ItemInfoCheck())
                return;

            if (this.objCurrentDeliveryOrder.receiver === null || this.objCurrentDeliveryOrder.receiver === "") {
                message.warning("请选择收件人！");
                return;
            }

            let strLength = "";
            let strWidth = "";
            let strHeight = "";
            let strValue = "";
            let strValueWeight = "";
            let strWeight = "";
            if (this.refs.length !== undefined) {
                strLength = this.refs.length.state.value;
                strWidth = this.refs.width.state.value;
                strHeight = this.refs.height.state.value;
                strValue = this.refs.value.state.value;
                strValueWeight = this.refs.value_weight.state.value;
                strWeight = this.refs.weight.state.value;
            }

            let objNewDeliveryOrder = {
                channel: this.state.iChannel,
                status: this.state.iStatus,
                length: strLength,
                width: strWidth,
                height: strHeight,
                value: strValue,
                value_weight: strValueWeight,
                weight: strWeight,
                price_weight: this.fPriceWeight,
                express_no: this.refs.express_no.state.value,
                service_fee: this.refs.service_fee.state.value,
                comments: this.refs.comments.state.value,
                receiver: this.objCurrentDeliveryOrder.receiver,
                receiver_code: this.objCurrentDeliveryOrder.receiver_code,
                user_name: this.props.strUserName,
                user_id: this.props.iUserID,
                store_id: this.props.objStoreInfo.id
            }
            if (this.state.iDlgType === 1) {
                objNewDeliveryOrder.code = this.objCurrentDeliveryOrder.code;
                objNewDeliveryOrder.user_id = this.objCurrentDeliveryOrder.user_id;
                objNewDeliveryOrder.user_name = this.objCurrentDeliveryOrder.user_name;
            }

            for (let i = 0; i < this.arrItemInfo.length; i++) {
                delete this.arrItemInfo[i].inventory;
            }
            message.loading({ content: '正在更新出库记录……', key: g_strMessageKey });
            axios.post('/UpdateDeliveryOrder', {
                objCurrentDeliveryOrder: this.objCurrentDeliveryOrder,
                objNewDeliveryOrder: objNewDeliveryOrder,
                arrItemInfo: this.arrItemInfo,
                action: this.state.iDlgType
            }).then(({ data }) => {
                if (data.status === 1) {
                    this.GetDeliveryOrder();
                } else {
                    if (data.message)
                        message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
                    else
                        message.warning({ content: "新建/更新出库记录失败！", key: g_strMessageKey, duration: 2 });
                }
            }).catch(function (error) { console.log(error); });
        }
        if (this.state.iDlgType === 2) {
            message.loading({ content: '出库记录删除中……', key: g_strMessageKey });
            axios.post('/DeleteDeliveryOrder', { id: this.objCurrentDeliveryOrder.id, code: this.objCurrentDeliveryOrder.code }).then(
                ({ data }) => {
                    if (data.status === -1) {
                        message.warning({
                            content: "删除失败，数据库中未找到指定出库单！",
                            key: g_strMessageKey, duration: 2
                        });
                        this.setState({
                            ...this.state,
                            btnControlDisable: [false, true, true],
                            bLoading: false,
                            bShowDlg: false,
                            selectedRowKeys: []
                        });
                    } else {
                        this.GetDeliveryOrder();
                    }
                }).catch(function (error) { console.log(error); });
        }
    };
    // 弹窗取消按钮响应
    handleCancel = e => {
        this.arrItemInfo = [
            [{ sku: '', name: '', num: 0, num_check: 0, status: 0 }]
        ];
        this.setState({
            bShowDlg: false,
            selectedRowKeys: [],
            btnControlDisable: [false, true, true]
        });
    };
    // 整理Users数据
    OrganizeDeliveryOrderData(data) {
        let arrTableData = [];
        for (let i = 0; i < data.length; i++) {
            let objDeliveryOrderData = data[i];
            objDeliveryOrderData.key = objDeliveryOrderData.id;
            objDeliveryOrderData.channel_str = this.arrChannel[data[i].channel];
            objDeliveryOrderData.status_str = this.arrStatus[data[i].status];
            if (objDeliveryOrderData.update_date)
                if (objDeliveryOrderData.update_date !== null)
                    if (objDeliveryOrderData.update_date.indexOf('T') >= 0)
                        objDeliveryOrderData.update_date_str = data[i].update_date.split('T')[0];
            arrTableData.push(objDeliveryOrderData);
        }
        return arrTableData;
    }
    // 从数据库获取当前的数据结构
    GetDeliveryOrder = () => {
        this.CurrentDeliveryOrderInit();
        message.loading({ content: '数据加载中……', key: g_strMessageKey });
        axios('/GetDeliveryOrder', {
            params: {
                store_id: this.props.objStoreInfo.id,
                user_id: this.props.iUserID,
                user_level: this.props.iUserLevel,
                invite_code: this.props.strInviteCode
            }
        }).then(({ data }) => {
            console.log("GetDeliveryOrder : ", data);
            this.arrOgeTableData = this.OrganizeDeliveryOrderData(data);
            this.arrTableData = this.arrOgeTableData;
            if (this.state.bShowDlg) {
                if (this.state.iDlgType === 0) {
                    message.success({ content: "新建出库预报完成！", key: g_strMessageKey, duration: 2 });
                }
                if (this.state.iDlgType === 1) {
                    message.success({ content: "出库记录信息已更新！", key: g_strMessageKey, duration: 2 });
                }
                if (this.state.iDlgType === 2) {
                    message.success({ content: "出库记录已删除！", key: g_strMessageKey, duration: 2 })
                }
            } else {
                message.success({ content: "出库信息加载完成！", key: g_strMessageKey, duration: 2 });
            }
            this.setState({
                ...this.state,
                bLoading: false,
                bShowDlg: false,
                selectedRowKeys: []
            });
        }).catch(function (error) { console.log(error); });
    }
    // 新建出库单的初始化
    CurrentDeliveryOrderInit = () => {
        this.objCurrentDeliveryOrder = {
            code: '',
            channel: 0,
            status: 0,
            length: 0.0,
            width: 0.0,
            height: 0.0,
            weight: 0.0,
            value: 0.0,
            value_weight: 0.0,
            price_weight: 0.0,
            comments: '',
            express_no: '',
            service_fee: 0.0,
            receiver: "",
            receiver_code: "",
            store_id: this.props.objStoreInfo.id,
            user_id: this.props.iUserID
        };
    }
    // 体积重量信息变化
    OnValueWeightChange = (index, e) => {
        let fLength = parseFloat(this.refs.length.state.value);
        let fWidth = parseFloat(this.refs.width.state.value);
        let fHeight = parseFloat(this.refs.height.state.value);
        let fWeight = parseFloat(this.refs.weight.state.value);
        switch (index) {
            // 长度变化
            case 0: {
                fLength = parseFloat(e.target.value);
                break;
            }
            // 宽度变化
            case 1: {
                fWidth = parseFloat(e.target.value);
                break;
            }
            // 高度变化
            case 2: {
                fHeight = parseFloat(e.target.value);
                break;
            }
            // 重量变化
            case 3: {
                fWeight = parseFloat(e.target.value);
                break;
            }
            default: break;
        }
        this.fValue = fLength * fWidth * fHeight;
        this.fValueWeight = fLength * fWidth * fHeight / 5000.0;
        if (fWeight >= this.fValueWeight)
            this.fPriceWeight = fWeight;
        else
            this.fPriceWeight = this.fValueWeight;
        this.setState({
            ...this.state,
            bValueWeightChange: true
        })
    }
    // 关键词处理
    KeywordsProcess = (strKeywords) => {
        let newKeywords = "";
        for (let i = 0; i < strKeywords.length; i++) {
            if (strKeywords[i] !== " ") {
                if (i === strKeywords.length - 1)
                    newKeywords += strKeywords[i];
                else
                    newKeywords += strKeywords[i] + "%";
            }
        }
        return newKeywords;
    }
    // 搜索库区信息名
    StorageDeliverySearch = () => {
        let strKeywords = this.refs.search.state.value;
        if (strKeywords === undefined)
            strKeywords = "";

        if (strKeywords.trim() === "") {
            this.GetDeliveryOrder();
        } else {
            this.setState({
                ...this.state,
                bLoading: true,
                selectedRowKeys: [],
                btnControlDisable: [false, true, true]
            });
            let iClientUser = 0;
            if (this.props.bClientUser)
                iClientUser = 1;
            console.log("StorageDeliverySearch : ", iClientUser, this.props.iUserID);
            axios('/SearchDeliveryOrder', { params: { name: this.KeywordsProcess(strKeywords), iClientUser: iClientUser, user_id: this.props.iUserID } })
                .then(({ data }) => {
                    this.arrTableData = this.OrganizeDeliveryOrderData(data);
                    this.setState({
                        ...this.state,
                        bLoading: false,
                        bShowDlg: false,
                        selectedRowKeys: []
                    });
                }).catch(function (error) { console.log(error); });
        }
    }
    // 点击了库区信息过滤器
    DeliveryTypeDropDownClicked = (index) => {
        this.setState({
            ...this.state,
            iChannel: index
        });
    }
    // 点击了库位类型过滤器
    DeliveryStatusDropDownClicked = (index) => {
        this.setState({
            ...this.state,
            iStatus: index
        });
    }
    // 新增一个SKU
    AddSKU = () => {
        let newSKU = { sku: '', name: '', num: 0, num_check: 0, status: 0, inventory: 0 };
        this.arrItemInfo.splice(0, 0, newSKU);
        this.setState({
            ...this.state,
            bAddNew: true
        })
    }
    // 物品信息编辑
    SKUInfoChange = (item_index, index, e) => {
        if (index === 0) {
            this.arrItemInfo[item_index].sku = e.target.value;
            this.arrItemInfo[item_index].name = "";
            for (let k = 0; k < global.arrSKUData.length; k++) {
                if (this.arrItemInfo[item_index].sku === global.arrSKUData[k].sku) {
                    this.arrItemInfo[item_index].name = global.arrSKUData[k].name
                    axios('/GetSKUInventoryRecord', { params: { store_id: this.props.objStoreInfo.id, sku: this.arrItemInfo[this.iItemIndex].sku, user_id: this.props.iUserID } })
                        .then(({ data }) => {
                            if (data.length > 0) {
                                if (data[0].num_lock === "" || data[0].num_lock === null)
                                    data[0].num_lock = 0;
                                if (isNaN(data[0].num_lock))
                                    data[0].num_lock = 0;
                                this.arrItemInfo[this.iItemIndex].inventory = parseInt(data[0].num, 10) - parseInt(data[0].num_lock, 10);
                            } else
                                this.arrItemInfo[this.iItemIndex].inventory = 0;
                            this.setState({
                                ...this.state,
                                bSelectSKU: false
                            });
                        }).catch(function (error) { console.log(error); });
                    break;
                }
            }
        }
        if (index === 1) {
            let iNum = parseInt(e.target.value, 10);
            if (isNaN(iNum))
                iNum = 0;
            this.arrItemInfo[item_index].num = iNum;
        }

        this.setState({
            ...this.state,
            bAddNew: true
        })
    }
    // 删除指定物品信息
    RemoveItem = (item_index) => {
        if (this.arrItemInfo.length <= 1) {
            message.warning("无法删除，至少要包含一种货物！");
            return;
        }
        this.arrItemInfo.splice(item_index, 1);
        this.setState({
            ...this.state,
            bAddNew: true
        })
    }
    // 货物总量统计
    TotalItemNumCalculate = () => {
        this.iTotalSKUNum = this.arrItemInfo.length;
        this.iTotalItemNum = 0;
        for (let i = 0; i < this.arrItemInfo.length; i++) {
            this.iTotalItemNum += parseInt(this.arrItemInfo[i].num, 10);
        }
    }
    // 提交数据前检查货物信息
    ItemInfoCheck = () => {
        for (let i = 0; i < this.arrItemInfo.length; i++) {
            // 库存信息检查
            if (this.objCurrentDeliveryOrder.status < 1 && this.props.iUserLevel < 9) {
                if (this.arrItemInfo[i].inventory === undefined)
                    this.arrItemInfo[i].inventory = 0;
                if (this.arrItemInfo[i].inventory === null)
                    this.arrItemInfo[i].inventory = 0;
                if (isNaN(this.arrItemInfo[i].inventory))
                    this.arrItemInfo[i].inventory = 0;
                if (this.arrItemInfo[i].inventory === "")
                    this.arrItemInfo.inventory = 0;
                if (parseInt(this.arrItemInfo[i].num, 10) > parseInt(this.arrItemInfo[i].inventory, 10)) {
                    message.warning('有货物的库存不足，请检查！');
                    return false;
                }
            }


            if (parseInt(this.arrItemInfo[i].num, 10) <= 0) {
                message.warning('有货物的数量为0，请检查！');
                return false;
            }
            let bSKUFound = false;
            for (let k = 0; k < global.arrSKUData.length; k++) {
                if (this.arrItemInfo[i].sku === global.arrSKUData[k].sku) {
                    bSKUFound = true;
                    break;
                }
            }
            if (!bSKUFound) {
                message.warning('有货物的SKU不存在，请检查！');
                return false;
            }
        }
        return true;
    }
    // 点击箱子条形码时的默认屏蔽
    OrderBarcodeClick = (event) => {
        event.preventDefault();
    }
    // 搜索SKU
    FindSKU = (item_index) => {
        if (this.objCurrentDeliveryOrder.status >= 1) {
            message.warning("已出单，无法更改SKU，请联系客服！");
            return;
        }
        if (this.props.iUserLevel >= 9) {
            message.warning("无法更改SKU信息，请联系客户！");
            return;
        }
        this.iItemIndex = item_index;
        this.ShowSKUSelection(true, 0);
    }
    // 选择SKU
    ShowSKUSelection = (bShow, sku_index) => {
        this.iSKUIndex = sku_index;
        if (!bShow && sku_index >= 0) {
            this.arrItemInfo[this.iItemIndex].sku = global.arrSKUData[this.iSKUIndex].sku;
            this.arrItemInfo[this.iItemIndex].name = global.arrSKUData[this.iSKUIndex].name;
            this.GetInventoryInfo();
        }
        // else {
        this.setState({
            ...this.state,
            bSelectSKU: bShow
        });
        // }
    }
    // 货物下架状态
    ItemStatusCheck = () => {
        let bCheck = true;
        let bDown = true;
        for (let i = 0; i < this.arrItemInfo.length; i++) {
            if (this.arrItemInfo[i].status <= 1) {
                bCheck = false; bDown = false;
            } else {
                if (this.arrItemInfo[i].status <= 2)
                    bDown = false;
            }
        }
        return { check: bCheck, down: bDown };
    }
    // 搜索收件人
    FindUser = () => {
        if (this.objCurrentDeliveryOrder.status >= 1) {
            message.warning("已出单，无法更改收件人，请联系客服！");
            return;
        }
        if (this.props.iUserLevel >= 9) {
            message.warning("无法更改收件人信息，请联系客户！");
            return;
        }
        this.ShowUserSelection(true, -1);
    }
    // 选择收件人
    ShowUserSelection = (bShow, index) => {
        if (!bShow && index >= 0) {
            this.objCurrentDeliveryOrder.receiver = global.arrUserData[index].name;
            this.objCurrentDeliveryOrder.receiver_code = global.arrUserData[index].code;
        }
        this.setState({
            ...this.state,
            bSelectUser: bShow
        });
    }
    // 货区库存信息
    GetInventoryInfo = () => {
        if (this.arrItemInfo.length > 0)
            for (let i = 0; i < this.arrItemInfo.length; i++) {
                if (this.arrItemInfo[i].sku !== null && this.arrItemInfo[i].sku !== "")
                    axios('/GetSKUInventoryRecord', { params: { store_id: this.props.objStoreInfo.id, sku: this.arrItemInfo[i].sku, user_id: this.props.iUserID } })
                        .then(({ data }) => {
                            if (data.length > 0) {
                                if (data[0].num_lock === "" || data[0].num_lock === null)
                                    data[0].num_lock = 0;
                                if (isNaN(data[0].num_lock))
                                    data[0].num_lock = 0;
                                this.arrItemInfo[i].inventory = parseInt(data[0].num, 10) - parseInt(data[0].num_lock, 10);
                            }
                            else
                                this.arrItemInfo[i].inventory = 0;
                            this.setState({
                                ...this.state,
                                bSelectSKU: false
                            });
                        }).catch(function (error) { console.log(error); });
            }

    }
    /* React自带函数 */
    // 卸载异步操作设置状态
    componentWillUnmount() {
        clearTimeout(this.timeouter);
        this.setState = (state, callback) => {
            return;
        };
        window.removeEventListener('keypress', this.ScanInputMonitor);
    }
    // 渲染完毕……
    componentDidMount() {

        // window.addEventListener('keypress', this.ScanInputMonitor);

        this.GetDeliveryOrder();
        if (global.arrSKUData.length <= 0) {
            axios('/GetSKU', { params: { store_id: this.props.objStoreInfo.id, user_id: this.props.iUserID, level: this.props.iUserLevel } })
                .then(({ data }) => {
                    global.arrSKUData = data;
                }).catch(function (error) { console.log(error); });
        }
        if (global.arrUserData.length <= 0) {
            axios('/GetStorageUser', { params: { store_id: this.props.objStoreInfo.id, client: this.props.iUserID } })
                .then(({ data }) => {
                    global.arrUserData = data;
                }).catch(function (error) { console.log(error); });
        }
    }
    // 渲染函数
    render() {
        let uiNewButton = (
            <Button className="manage_contents_title_margin" style={{ background: 'darkcyan', color: 'white', marginLeft: '5px' }}
                onClick={this.ShowDlg.bind(this, 0)} disabled={this.state.btnControlDisable[0]}>
                <Icon type="plus" />预报</Button>
        );
        let strEditButton = "编辑";
        let strEditIcon = "edit";
        if (this.props.iUserLevel >= 9) {
            uiNewButton = null;
            strEditButton = "查看";
            strEditIcon = "search";
        }
        let strSearchTips = "搜索 出库单号/客户/物流单号/备注";
        if (this.props.bClientUser)
            strSearchTips = "搜索 出库单号/物流单号/备注";
        let uiTitleArea = (
            < div className="storage_contents_title" >
                {uiNewButton}
                <Button className="manage_contents_title_margin" type="primary" onClick={this.ShowDlg.bind(this, 1)} disabled={this.state.btnControlDisable[1]}>
                    <Icon type={strEditIcon} />{strEditButton}</Button>
                <Input placeholder={strSearchTips} ref="search" defaultValue={this.state.strKeywords} style={{ width: '200px', marginLeft: '20px' }} />
                <Button className="manage_contents_title_margin"
                    type="primary" onClick={this.StorageDeliverySearch}>搜索
                    </Button>
            </div >
        );
        // 如果界面上需要显示的内容已经从数据库获得
        if (!this.state.bLoading) {
            // 主体表格相关
            const { selectedRowKeys } = this.state;
            const rowSelection = {
                selectedRowKeys,
                onChange: this.onSelectedRowKeysChange,
                columnWidth: 30
            };
            // 表格分页属性
            const paginationProps = {
                showSizeChanger: true,
                showQuickJumper: false,
                showTotal: () => `共 ${this.arrTableData.length} 个 出库单`,
                defaultPageSize: 10,
                defaultCurrent: this.state.iCurrentPage,
                pageSizeOptions: ['10', '20', '30', '40', '50'],
                total: this.arrTableData.length,
                position: 'bottom'
            };
            // 出库单类别下拉菜单
            const uiDeliveryType = (
                <Menu>
                    {this.arrChannel.map((item, index) => {
                        return (
                            <Menu.Item key={index} onClick={this.DeliveryTypeDropDownClicked.bind(this, index)}>
                                {item}
                            </Menu.Item>
                        );
                    })}
                </Menu>
            );
            // 库位类型下拉菜单
            const uiDeliveryStatus = (
                <Menu>
                    {this.arrStatus.map((item, index) => {
                        return (
                            <Menu.Item key={index} onClick={this.DeliveryStatusDropDownClicked.bind(this, index)}>
                                {item}
                            </Menu.Item>
                        );
                    })}
                </Menu>
            );
            this.TotalItemNumCalculate();
            // 出库单编辑弹窗
            let strTitle = "新建出库预报";
            if (this.state.iDlgType === 1) {
                strTitle = "编辑出库单";
                if (this.props.iUserLevel >= 9)
                    strTitle = "查看出库单";
            }

            let bDisable1 = false;
            let bDisable2 = false;
            if (this.props.iUserLevel < 9) {
                bDisable2 = true;
            } else {
                bDisable1 = true;
            }
            if (this.objCurrentDeliveryOrder.status >= 1) {
                bDisable1 = true;
            }
            let uiBarCode = null;
            if (this.objCurrentDeliveryOrder.code !== null && this.objCurrentDeliveryOrder.code !== "") {
                let strBoxBarcode = this.objCurrentDeliveryOrder.code + "-B";
                if (!this.props.bClientUser) {
                    uiBarCode = (
                        <div className="user_edit_info_row storage_edit_info_black_title">
                            <a href="请右键点击并保存该条形码" onClick={e => this.OrderBarcodeClick(e)} download={strBoxBarcode} >
                                <Barcode value={strBoxBarcode} renderer={'img'} displayValue={true} width={3} height={75} fontSize={15} background={'rgba(0, 0, 0, 0)'}
                                    ref={(el) => (this.componentRef = el)} />
                            </a>
                            <ReactToPrint trigger={() =>
                                <Button type="primary" className="storage_print_button" >
                                    <Icon type="printer" style={{ color: 'white', fontWeight: 'bold', fontSize: '15px' }} />
                                </Button>}
                                content={() => this.componentRef} />
                        </div>
                    );
                }
            }
            let uiUserSearch = (
                <div className="storage_sku_input_search" onClick={this.FindUser.bind(this)}>
                    <Icon type="search" style={{ color: 'black', fontWeight: 'bold' }} />
                </div>
            );
            let uiUserList = (
                <div className="storage_sku_list_area">
                    {global.arrUserData.map((user, user_index) => {
                        return (
                            <div className="storage_sku_list_row" key={user_index} onClick={this.ShowUserSelection.bind(this, false, user_index)}>
                                {user.name + "：" + user.address}
                            </div>
                        );
                    })}
                </div>
            );
            let uiUserSelectDlg = (
                <Modal title="选择收件人" width={600} visible={this.state.bSelectUser}
                    onCancel={this.ShowUserSelection.bind(this, false, -1)} footer={null} >
                    {uiUserList}
                </Modal>
            );
            let uiValueInfo1 = null;
            if (!this.props.bClientUser)
                uiValueInfo1 = (
                    <div className="user_edit_info_row">
                        <div className="storage_edit_info_title">长：</div>
                        <Input className="storage_edit_info_input" ref="length" addonAfter="cm" onChange={this.OnValueWeightChange.bind(this, 0)}
                            defaultValue={this.objCurrentDeliveryOrder.length} />
                        <div className="storage_edit_info_title">宽：</div>
                        <Input className="storage_edit_info_input" ref="width" addonAfter="cm" onChange={this.OnValueWeightChange.bind(this, 1)}
                            defaultValue={this.objCurrentDeliveryOrder.width} />
                        <div className="storage_edit_info_title">高：</div>
                        <Input className="storage_edit_info_input" ref="height" addonAfter="cm" onChange={this.OnValueWeightChange.bind(this, 2)}
                            defaultValue={this.objCurrentDeliveryOrder.height} />
                    </div>
                );
            let uiValueInfo2 = null;
            if (isNaN(this.fValue))
                this.fValue = 0.0;
            if (isNaN(this.fValueWeight))
                this.fValueWeight = 0.0;
            if (!this.props.bClientUser)
                uiValueInfo2 = (
                    <div className="user_edit_info_row">
                        <div className="storage_edit_info_title">体积：</div>
                        <Input className="storage_edit_info_input" ref="value" disabled addonAfter="cm3" value={this.fValue}
                            defaultValue={this.fValue} />
                        <div className="storage_edit_info_title">体积重：</div>
                        <Input className="storage_edit_info_input" ref="value_weight" disabled addonAfter="kg" value={this.fValueWeight}
                            defaultValue={this.fValueWeight} />
                        <div className="storage_edit_info_title">称重：</div>
                        <Input className="storage_edit_info_input" ref="weight" addonAfter="kg" onChange={this.OnValueWeightChange.bind(this, 3)}
                            defaultValue={this.objCurrentDeliveryOrder.weight} />
                    </div>
                );
            let iModalWidth = 960;
            if (this.state.iDlgType === 0 || this.objCurrentDeliveryOrder.status >= 1)
                iModalWidth = 910;
            let uiModal = (
                <Modal title={strTitle} width={iModalWidth} visible={this.state.bShowDlg}
                    okText="确定" cancelText="取消" onOk={this.handleOk} onCancel={this.handleCancel} >
                    <div className="user_edit_info_row">
                        <div className="storage_edit_info_title">单号:</div>
                        <Input className="storage_edit_info_input" ref="code" disabled
                            defaultValue={this.objCurrentDeliveryOrder.code} />
                        <div className="storage_edit_info_title">类别:</div>
                        <Dropdown className="storage_edit_info_input" overlay={uiDeliveryType} placement="bottomLeft" disabled={bDisable1}>
                            <Button className="storage_edit_info_input">
                                {this.arrChannel[this.state.iChannel]}
                                <Icon type="caret-down" style={{ marginLeft: '10px' }} />
                            </Button>
                        </Dropdown>
                        <div className="storage_edit_info_title">状态:</div>
                        <Dropdown className="storage_edit_info_input" overlay={uiDeliveryStatus} placement="bottomLeft">
                            <Button className="storage_edit_info_input" >
                                {this.arrStatus[this.state.iStatus]}
                                <Icon type="caret-down" style={{ marginLeft: '10px' }} />
                            </Button>
                        </Dropdown>
                    </div>
                    {uiValueInfo1}
                    {uiValueInfo2}
                    <div className="user_edit_info_row">
                        <div className="storage_edit_info_title">计费重量：</div>
                        <Input className="storage_edit_info_input" ref="price_weight" disabled addonAfter="kg" value={this.fPriceWeight}
                            defaultValue={this.objCurrentDeliveryOrder.price_weight} />
                        <div className="storage_edit_info_title">物流：</div>
                        <Input className="storage_edit_info_input" ref="express_no" placeholder={'请填写物流单号'}
                            defaultValue={this.objCurrentDeliveryOrder.express_no} disabled={bDisable2} />
                        <div className="storage_edit_info_title">费用：</div>
                        <Input className="storage_edit_info_input" ref="service_fee" addonBefore="$"
                            defaultValue={this.objCurrentDeliveryOrder.service_fee} disabled={bDisable2} />
                    </div>
                    <div className="user_edit_info_row">
                        <div className="storage_edit_info_title">收件人:</div>
                        <Input className="storage_edit_info_input" ref="receiver" disabled
                            value={this.objCurrentDeliveryOrder.receiver} addonAfter={uiUserSearch} />
                        <div className="storage_edit_info_title">备注：</div>
                        <Input className="storage_edit_info_input_mid2" ref="comments"
                            defaultValue={this.objCurrentDeliveryOrder.comments} />
                        {uiUserSelectDlg}
                    </div>
                    <div className="storage_edit_info_block">
                        <div className="user_edit_info_row storage_edit_info_black_title">
                            <b>出货信息</b>：共 {this.iTotalSKUNum} 种, {this.iTotalItemNum} 件 货物
                            <Button className="storage_edit_info_add_button" onClick={this.AddSKU} disabled={bDisable1}>
                                + SKU
                            </Button>
                        </div>

                        {uiBarCode}
                        {this.arrItemInfo.map((item, item_index) => {
                            let strBlockStyle = "storage_edit_info_sub_block";
                            if (item_index % 2 !== 0)
                                strBlockStyle += " storage_edit_info_sub_block_dark";
                            if (this.arrItemInfo.length % 2 === 0) {
                                strBlockStyle = "storage_edit_info_sub_block storage_edit_info_sub_block_dark";
                                if (item_index % 2 !== 0)
                                    strBlockStyle = "storage_edit_info_sub_block";
                            }
                            let uiChecked = null;
                            if (this.objCurrentDeliveryOrder.code !== null && this.objCurrentDeliveryOrder.code !== "") {
                                if (parseInt(item.status, 10) === 0 || item.status === null)
                                    uiChecked = (
                                        <Checkbox checked={false} disabled style={{ marginLeft: '5px' }}>未处理</Checkbox>
                                    );
                                if (parseInt(item.status, 10) === 1)
                                    uiChecked = (
                                        <Checkbox checked={true} style={{ marginLeft: '5px' }}>已拣货</Checkbox>
                                    );
                                if (parseInt(item.status, 10) === 2)
                                    uiChecked = (
                                        <Checkbox checked={true} style={{ marginLeft: '5px' }}>已出库</Checkbox>
                                    );
                            }
                            let uiSKUSearch = (
                                <div className="storage_sku_input_search" onClick={this.FindSKU.bind(this, item_index, item_index)}>
                                    <Icon type="search" style={{ color: 'black', fontWeight: 'bold' }} />
                                </div>
                            );
                            let uiSKUList = (
                                <div className="storage_sku_list_area">
                                    {global.arrSKUData.map((sku, sku_index) => {
                                        return (
                                            <div className="storage_sku_list_row" key={sku_index} onClick={this.ShowSKUSelection.bind(this, false, sku_index)}>
                                                {sku.sku + "：" + sku.name}
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                            let uiSKUSelectDlg = (
                                <Modal title="选择SKU" width={500} visible={this.state.bSelectSKU}
                                    onCancel={this.ShowSKUSelection.bind(this, false, -1)} footer={null} >
                                    {uiSKUList}
                                </Modal>
                            );
                            let uiInventoryTitle = null;
                            let uiInventory = null;
                            let uiDeleteButton = null;
                            if (this.props.bClientUser && this.objCurrentDeliveryOrder.status < 1) {
                                uiInventoryTitle = (
                                    <div className="storage_edit_box_info_title">可用库存：</div>
                                );
                                uiInventory = (
                                    <Input className="storage_edit_box_info_input_short" ref={"inventory_" + item_index}
                                        defaultValue={item.inventory} value={item.inventory} disabled={true} />
                                );
                                uiDeleteButton = (
                                    <div className="storage_edit_info_delete_button" onClick={this.RemoveItem.bind(this, item_index)}>
                                        <Icon type="delete" style={{ marginLeft: '5px', color: 'lightcoral', fontWeight: 'bold', fontSize: '17.5px' }} /></div>
                                );
                            }
                            return (
                                <div className={strBlockStyle} key={item_index}>
                                    <div className="user_edit_info_row" key={item_index * 100 + item_index}>
                                        <div className="storage_edit_box_info_title">SKU：</div>
                                        <Input className="storage_edit_box_info_input" ref={"sku_" + item_index} disabled={bDisable1}
                                            defaultValue={item.sku} value={item.sku} onChange={this.SKUInfoChange.bind(this, item_index, 0)}
                                            addonAfter={uiSKUSearch} />
                                        <div className="storage_edit_box_info_title">名称：</div>
                                        <Input className="storage_edit_box_info_input_mid" ref={"name_" + item_index} disabled
                                            defaultValue={item.name} value={item.name} />
                                        <div className="storage_edit_box_info_title">数量：</div>
                                        <Input className="storage_edit_box_info_input_short" ref={"num_" + item_index}
                                            defaultValue={item.num} value={item.num} disabled={bDisable1}
                                            onChange={this.SKUInfoChange.bind(this, item_index, 1)} />
                                        {uiInventoryTitle}
                                        {uiInventory}
                                        {uiChecked}
                                        {uiDeleteButton}
                                    </div>
                                    {uiSKUSelectDlg}
                                </div>
                            );
                        })
                        }
                    </div>
                </Modal >
            );
            if (this.state.iDlgType === 2) {
                uiModal = (
                    <Modal width={500} title={"删除出库单"} visible={this.state.bShowDlg} okType="danger"
                        onOk={this.handleOk} okText="确定"
                        onCancel={this.handleCancel} cancelText="取消">
                        <div className="modal_item">
                            <div>{this.objCurrentDeliveryOrder.code}
                                <br /><br />确定删除选中的出库单？
                            </div>
                        </div>
                    </Modal>
                );
            }

            let uiTableHeader = arrClientTableHeader;
            if (this.props.iUserLevel >= 9)
                uiTableHeader = arrManagerTableHeader;
            return (
                <div className="storage_contents_area">
                    {uiTitleArea}
                    {uiModal}
                    {/* 表格主体 */}
                    <Table style={{ margin: '20px', width: '96%', overflowX: 'auto' }}
                        rowSelection={rowSelection} pagination={paginationProps}
                        columns={uiTableHeader} dataSource={this.arrTableData} bordered
                        onRow={(record) => ({ onClick: () => { this.selectRow(record); } })} />
                </div >
            );
        } else {
            return (
                <div className="storage_contents_area">
                    {uiTitleArea}
                    <Spin className="manage_empty_loading" size="large" tip="加载中……" />
                </div>
            );
        }
    }

}
export default StorageDeliveryManager;
