import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
import XLSX from 'xlsx';

import Spin from 'antd/es/spin';
import Table from 'antd/es/table';
import Button from 'antd/es/button';
import Input from 'antd/es/input';
import Icon from 'antd/es/icon';
import Modal from 'antd/es/modal';
import AutoComplete from 'antd/es/auto-complete';
import DatePicker from 'antd/es/date-picker';
import Menu from 'antd/es/menu';
import Dropdown from 'antd/es/dropdown';
import message from 'antd/es/message';

import '../../../styles/Background/ego_manager.css';
import '../../../styles/Background/UI_Module/user_management.css';
import { UserActiveUpdate } from "../../../functions/DataStatistic.js"

const { TextArea } = Input;
const { MonthPicker } = DatePicker;

const g_strMessageKey = 'updating';
global.arrStorageOutData = [];

// 表格
const arrTableHeader1 = [
    // { title: 'ID', dataIndex: 'id', width: 30, ellipsis: true },
    { title: '客户', dataIndex: 'client_name', width: 90, ellipsis: true },
    { title: '柜号', dataIndex: 'closet', width: 90, ellipsis: true },
    { title: '运单号', dataIndex: 'order_no', width: 90, ellipsis: true },
    { title: '出库方式', dataIndex: 'outway_str', width: 55, ellipsis: true },
    { title: '物流方式', dataIndex: 'transitway_str', width: 55, ellipsis: true },
    { title: '货物信息', dataIndex: 'item_info_str', width: 135, ellipsis: true },
    { title: '操作人', dataIndex: 'user_name', width: 50, ellipsis: true },
    { title: '出库日期', dataIndex: 'storage_out_date', width: 70, ellipsis: true },
    { title: '备注', dataIndex: 'comments', width: 100, ellipsis: true }
];
const arrTableHeader2 = [
    { title: '柜号', dataIndex: 'closet', width: 90, ellipsis: true },
    { title: '运单号', dataIndex: 'order_no', width: 90, ellipsis: true },
    { title: '出库方式', dataIndex: 'outway_str', width: 55, ellipsis: true },
    { title: '物流方式', dataIndex: 'transitway_str', width: 55, ellipsis: true },
    { title: '货物信息', dataIndex: 'item_info_str', width: 135, ellipsis: true },
    { title: '操作人', dataIndex: 'user_name', width: 50, ellipsis: true },
    { title: '出库日期', dataIndex: 'storage_out_date', width: 70, ellipsis: true },
    { title: '备注', dataIndex: 'comments', width: 100, ellipsis: true }
];


// 将一个sheet转成最终的excel文件的blob对象，然后利用URL.createObjectURL下载
function sheet2blob(sheet, sheetName) {
    sheetName = sheetName || 'sheet1';
    var workbook = {
        SheetNames: [sheetName],
        Sheets: {}
    };
    workbook.Sheets[sheetName] = sheet;
    // 生成excel的配置项
    var wopts = {
        bookType: 'xlsx', // 要生成的文件类型
        bookSST: false, // 是否生成Shared String Table，官方解释是，如果开启生成速度会下降，但在低版本IOS设备上有更好的兼容性
        type: 'binary'
    };
    var wbout = XLSX.write(workbook, wopts);
    var blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });
    // 字符串转ArrayBuffer
    function s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
    }
    return blob;
}
//  通用的打开下载对话框方法，没有测试过具体兼容性
//  @param url 下载地址，也可以是一个blob对象，必选
//  @param saveName 保存文件名，可选
function openDownloadDialog(url, saveName) {
    if (typeof url == 'object' && url instanceof Blob) {
        url = URL.createObjectURL(url); // 创建blob地址
    }
    var aLink = document.createElement('a');
    aLink.href = url;
    aLink.download = saveName || ''; // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，file:///模式下不会生效
    var event;
    if (window.MouseEvent) event = new MouseEvent('click');
    else {
        event = document.createEvent('MouseEvents');
        event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
    }
    aLink.dispatchEvent(event);
}

class LogisticStorageOut extends Component {
    constructor(props) {
        super();
        this.state = {
            btnControlDisable: [false, true, true],  // 操作按钮控制
            btnCopyPasteDisable: [true, true],  // 操作按钮控制
            selectedRowKeys: [],  // Check here to configure the default column
            iDlgType: -1,
            bShowDlg: false,
            bLoading: true,
            bSelectClientDlg: false,
            dateStorageOut: new Date(),
            iOutWay: 0,
            iTransitWay: 0,
            bExport: true
        };
        // 成员变量
        this.arrOgeTableData = [];
        this.arrTableData = [];  // 用于表格的数组
        this.objCurrentStorageOut = {};
        this.arrClientDataSource = [];
        this.arrStorageInDataSource = [];
        this.arrItemDataSource = [];  // 可选择出库的货物列表(只有货物名)
        this.arrItemList = [];  // 出库货物列表
        this.arrItemInfo = [];  // 当前客户、货柜中的货物信息列表（有货物的全部信息）
        this.iCurrentClient = -1;
        this.strCurrentClient = "";
        this.strCurrentCloset = "";
        this.arrOutWay = ['箱', '托'];
        this.arrTransitWay = ['自提', '卡派', '快递'];
        this.strMonthFilter = "";
    }

    // 选中某行的响应函数
    selectRow = (record) => {
        const selectedRowKeys = [...this.state.selectedRowKeys];
        if (selectedRowKeys.indexOf(record.key) >= 0) {
            selectedRowKeys.splice(selectedRowKeys.indexOf(record.key), 1);
        } else {
            selectedRowKeys.push(record.key);
        }
        this.SelectedRowStateSetting(selectedRowKeys);
    }
    // 选中的行发生变化的响应函数
    onSelectedRowKeysChange = (selectedRowKeys) => {
        this.SelectedRowStateSetting(selectedRowKeys);
    }
    // 处理选中的表行行
    SelectedRowStateSetting = (selectedRowKeys) => {
        let btnControlDisable = [];
        let btnCopyPasteDisable = [];
        let bExport = true;
        switch (selectedRowKeys.length) {
            case 0: {
                btnControlDisable = [false, true, true];
                btnCopyPasteDisable = [true, true];
                this.objCurrentStorageOut = {};
                break;
            }
            case 1: {
                btnControlDisable = [false, false, false];
                btnCopyPasteDisable = [false, true];
                // for (let i = 0; i < this.arrTableData.length; i++) {
                //     if (this.arrTableData[i].key === selectedRowKeys[0]) {
                //         this.objCurrentStorageOut = this.arrTableData[i];
                //         break;
                //     }
                // }
                this.arrSelectStorage = [];
                btnControlDisable = [false, false, false, false];
                for (let i = 0; i < this.arrTableData.length; i++) {
                    if (this.arrTableData[i].key === selectedRowKeys[0]) {
                        this.objCurrentStorageOut = this.arrTableData[i];
                        this.arrSelectStorage.push(this.arrTableData[i]);
                        break;
                    }
                }
                bExport = false;
                break;
            }
            default: {
                btnControlDisable = [false, true, true]; btnCopyPasteDisable = [true, true];
                bExport = false;
                this.arrSelectStorage = [];
                btnControlDisable = [false, true, true, false];
                for (let j = 0; j < this.arrTableData.length; j++) {
                    for (let i = 0; i < this.arrTableData.length; i++) {
                        if (this.arrTableData[i].key === selectedRowKeys[j]) {
                            this.arrSelectStorage.push(this.arrTableData[i]);
                            break;
                        }
                    }
                }
                console.log(this.arrSelectStorage);
                break;
            }
        }
        this.setState({
            ...this.state,
            selectedRowKeys,
            btnControlDisable: btnControlDisable,
            btnCopyPasteDisable: btnCopyPasteDisable,
            bExport: bExport
        });
    }
    // 关键词处理
    KeywordsProcess = (strKeywords) => {
        let newKeywords = "";
        for (let i = 0; i < strKeywords.length; i++) {
            if (strKeywords[i] !== " ") {
                if (i === strKeywords.length - 1)
                    newKeywords += strKeywords[i];
                else
                    newKeywords += strKeywords[i] + "%";
            }
        }
        return newKeywords;
    }
    // 搜索出库名
    StorageOutSearch = () => {
        let strKeywords = this.refs.name_search.state.value;
        if (strKeywords === undefined)
            strKeywords = "";
        if (strKeywords.trim() === "") {
            this.GetStorageOutData();
        } else {
            this.setState({
                ...this.state,
                bLoading: true,
                selectedRowKeys: [],
                btnControlDisable: [false, true, true]
            });
            axios('/SearchStorageOut', { params: { name: this.KeywordsProcess(strKeywords), store_id: this.props.iStoreID } })
                .then(({ data }) => {
                    this.arrTableData = this.OrganizeStorageOutData(data);
                    this.setState({
                        ...this.state,
                        bLoading: false,
                        objFilter: {
                            strKeyword: strKeywords,
                            iCurrentUserStatus: 0,
                            iCurrentUserType: 0
                        }
                    });
                }).catch(function (error) { console.log(error); });
        }
    }
    // 预计到达日期更改
    StorageOutDateChange = (date, dateString) => {
        this.setState({
            ...this.state,
            dateStorageOut: dateString
        });
    }

    /* 自定义成员函数 */
    //弹出对话框
    ShowDlg = (iDlgType, e) => {
        if (iDlgType === 0) {
            this.strCurrentClient = "";
            this.strCurrentCloset = "";
            if (this.refs.comments !== undefined) {
                this.refs.order_no.state.value = "";
                this.refs.comments.state.value = "";
            }
            this.arrItemList = [];
            let objItemInit = {
                name: "", num: 0, out: 0, barcode: ''
            };
            this.arrItemList.push(objItemInit);
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true,
                dateStorageOut: new Date(),
                iOutWay: 0,
                iTransitWay: 0
            });
        }
        if (iDlgType === 1) {
            console.log("ShowDlg edit", this.objCurrentStorageOut);
            this.strCurrentClient = this.objCurrentStorageOut.client_name;
            this.strCurrentCloset = this.objCurrentStorageOut.closet;
            this.arrItemList = JSON.parse(this.objCurrentStorageOut.item_info);
            if (this.refs.comments !== undefined) {
                this.refs.order_no.state.value = this.objCurrentStorageOut.order_no;
                this.refs.comments.state.value = this.objCurrentStorageOut.comments;
            }
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true,
                iOutWay: 0,
                iTransitWay: 0,
                dateStorageOut: this.objCurrentStorageOut.storage_out_date
            });
        }
        if (iDlgType === 2) {
            this.setState({
                ...this.state,
                iDlgType: iDlgType,
                bShowDlg: true
            });
        }
    }
    // 弹窗确认按钮响应
    handleOk = e => {
        if (this.state.iDlgType === 0) {
            let strClient = this.strCurrentClient;
            if (strClient === null || strClient.trim() === "") {
                message.warning("客户名不能为空！"); return;
            }
            if (this.iCurrentClient < 0) {
                message.warning("客户不存在，请重新选择！"); return;
            }
            let strCloset = this.strCurrentCloset;
            if (strCloset === null || strCloset.trim() === "") {
                message.warning("出库名不能为空！"); return;
            }

            for (let i = 0; i < this.arrItemList.length; i++) {
                this.arrItemList[i].num = parseInt(this.arrItemList[i].num);
                this.arrItemList[i].out = parseInt(this.arrItemList[i].out);
                this.arrItemList[i].num -= this.arrItemList[i].out;
                let arrBarcode = this.arrItemList[i].barcode.split('\n');
                for (let j = 0; j < arrBarcode.length; j++) {
                    if (arrBarcode[i] !== undefined && arrBarcode[i] !== null && typeof (arrBarcode[i]) === 'string')
                        arrBarcode[i] = arrBarcode[i].trim();
                    if (arrBarcode[i] === '') {
                        message.warning("货物编码有空行，请检查！"); return;
                    }
                }
            }
            let objNewStorageOut = {
                store_id: this.props.iStoreID,
                user_id: this.props.iUserID,
                user_name: this.props.strUserName,
                team: this.props.iUserTeam,
                item_info: JSON.stringify(this.arrItemList),
                order_no: this.refs.order_no.state.value,
                closet: strCloset,
                client_id: this.iCurrentClient,
                client_name: strClient,
                comments: this.refs.comments.state.value,
                outway: this.state.iOutWay,
                transitway: this.state.iTransitWay,
                storage_out_date: this.state.dateStorageOut,
                create_time: new Date(),
                update_time: new Date()
            };
            console.log("objNewStorageOut : ", objNewStorageOut);
            // return;
            message.loading({ content: '正在创建新的出库记录……', key: g_strMessageKey });
            axios.post('/CreateNewStorageOut', objNewStorageOut)
                .then(({ data }) => {
                    if (data.status === 1) {
                        this.GetStorageOutData();
                    } else {
                        message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
                    }
                }).catch(function (error) { console.log(error); });
        }
        if (this.state.iDlgType === 1) {
            let strClient = this.strCurrentClient;
            if (strClient === null || strClient.trim() === "") {
                message.warning("客户名不能为空！"); return;
            }
            let iClient = this.objCurrentStorageOut.client_id;
            if (this.iCurrentClient > 0)
                iClient = this.iCurrentClient;
            let strCloset = this.strCurrentCloset;
            if (strCloset === null || strCloset.trim() === "") {
                message.warning("出库名不能为空！"); return;
            }
            let objNewStorageOut = {
                store_id: this.props.iStoreID,
                user_id: this.props.iUserID,
                user_name: this.props.strUserName,
                team: this.props.iUserTeam,
                item_info: JSON.stringify(this.arrItemList),
                order_no: this.refs.order_no.state.value,
                closet: strCloset,
                client_id: iClient,
                client_name: strClient,
                comments: this.refs.comments.state.value,
                outway: this.state.iOutWay,
                transitway: this.state.iTransitWay,
                storage_out_date: this.state.dateStorageOut,
                // create_time: new Date(),
                update_time: new Date()
            };
            message.loading({ content: '正在更新出库记录……', key: g_strMessageKey });
            axios.post('/UpdateStorageOut', {
                objCurrentStorageOut: this.objCurrentStorageOut,
                objNewStorageOut: objNewStorageOut
            }).then(({ data }) => {
                if (data.status === 1)
                    this.GetStorageOutData();
                else
                    message.warning({ content: data.message, key: g_strMessageKey, duration: 2 });
            }).catch(function (error) { console.log(error); });
        }
        if (this.state.iDlgType === 2) {
            message.loading({ content: '出库删除中……', key: g_strMessageKey });
            axios.post('/DeleteStorageOut', { id: this.objCurrentStorageOut.id }).then(
                ({ data }) => {
                    if (data.status === -1) {
                        message.warning({
                            content: "删除失败，该出库已绑定商家，请先检查并删除商家！",
                            key: g_strMessageKey, duration: 2
                        });
                        this.setState({
                            ...this.state,
                            bLoading: false,
                            bShowDlg: false,
                            selectedRowKeys: []
                        });
                    } else {
                        // DataStatistic
                        UserActiveUpdate(4, { active: "管理后台: 出库管理操作", action: "删除" });
                        this.GetStorageOutData();
                    }
                }).catch(function (error) { console.log(error); });
        }
    };
    // 弹窗取消按钮响应
    handleCancel = e => {
        this.setState({
            bShowDlg: false,
        });
    };
    // 整理StorageOut数据
    OrganizeStorageOutData(data) {
        let arrTableData = [];

        for (let i = 0; i < data.length; i++) {
            let arrItemInfo = JSON.parse(data[i].item_info);
            let iItemNum = 0;
            for (let j = 0; j < arrItemInfo.length; j++)
                iItemNum += arrItemInfo[j].out;
            let strItemInfo = arrItemInfo[0].name + " 等 " + arrItemInfo.length.toString() + " 种 " + iItemNum.toString() + " 件 物品";
            let strOutDate = data[i].storage_out_date;
            if (strOutDate.indexOf('T') >= 0)
                strOutDate = strOutDate.split('T')[0];
            let objStorageOutData = {
                key: i, id: data[i].id,
                client_id: data[i].client_id,
                client_name: data[i].client_name,
                user_id: data[i].user_id,
                user_name: data[i].user_name,
                team: data[i].team,
                order_no: data[i].order_no,
                closet: data[i].closet,
                outway: data[i].outway,
                transitway: data[i].transitway,
                outway_str: this.arrOutWay[data[i].outway],
                transitway_str: this.arrTransitWay[data[i].transitway],
                item_info: data[i].item_info,
                item_info_str: strItemInfo,
                comments: data[i].comments,
                storage_out_date: strOutDate,
            }
            if (data[i].storage_out_date.indexOf(this.strMonthFilter) >= 0)
                arrTableData.push(objStorageOutData);
        }
        return arrTableData;
    }
    // 从数据库获取当前的数据结构
    GetStorageOutData() {
        message.loading({ content: '数据加载中……', key: g_strMessageKey });
        axios('/GetStorageOut', {
            params: {
                store_id: this.props.iStoreID, level: this.props.iUserLevel,
                client_id: this.props.iClientID
            }
        }).then(({ data }) => {
            console.log("GetStorageOut : ", data);
            global.arrStorageOutData = data;
            this.arrOgeTableData = this.OrganizeStorageOutData(data);
            this.arrTableData = this.arrOgeTableData;
            if (this.state.bShowDlg) {
                if (this.state.iDlgType === 0) {
                    message.success({ content: "新建出库记录成功！", key: g_strMessageKey, duration: 2 });
                }
                if (this.state.iDlgType === 1) {
                    message.success({ content: "出库记录已更新！", key: g_strMessageKey, duration: 2 });
                }
                if (this.state.iDlgType === 2) {
                    message.success({ content: "出库已删除！", key: g_strMessageKey, duration: 2 })
                }
            } else {
                message.success({ content: "数据加载完成！", key: g_strMessageKey, duration: 2 });
            }
            this.setState({
                ...this.state,
                bLoading: false,
                bShowDlg: false,
                selectedRowKeys: []
            });
        }).catch(function (error) { console.log(error); });
    }
    // 关键词处理
    KeywordsProcess = (strKeywords) => {
        let newKeywords = "";
        for (let i = 0; i < strKeywords.length; i++) {
            if (strKeywords[i] !== " ") {
                if (i === strKeywords.length - 1)
                    newKeywords += strKeywords[i];
                else
                    newKeywords += strKeywords[i] + "%";
            }
        }
        return newKeywords;
    }
    // 搜索出库记录
    StorageOutSearch = (bShowAll) => {
        if (this.props.iUserLevel === 1) {
            if (bShowAll) {

                this.refs.closet_search.state.value = "";
                this.refs.name_search.state.value = "";
                this.strMonthFilter = "";
                this.GetStorageOutData();

            } else {
                let strClient = "";
                let strCloset = this.refs.closet_search.state.value;
                let strKeywords = this.refs.name_search.state.value;
                if (strClient === undefined || strClient === null)
                    strClient = "";
                if (strCloset === undefined || strCloset === null)
                    strCloset = "";
                if (strKeywords === undefined || strKeywords === null)
                    strKeywords = "";
                this.setState({
                    ...this.state,
                    bLoading: true,
                    selectedRowKeys: [],
                    btnControlDisable: [false, true, true]
                });
                axios('/SearchStorageOut', {
                    params: {
                        client: this.KeywordsProcess(strClient),
                        closet: this.KeywordsProcess(strCloset),
                        key: this.KeywordsProcess(strKeywords),
                        store_id: this.props.iStoreID
                    }
                }).then(({ data }) => {
                    this.arrTableData = this.OrganizeStorageOutData(data);
                    this.setState({
                        ...this.state,
                        bLoading: false,
                        objFilter: {
                            strKeyword: strKeywords,
                            iCurrentUserStatus: 0,
                            iCurrentUserType: 0
                        }
                    });
                }).catch(function (error) { console.log(error); });
            }
        } else {
            if (bShowAll) {
                this.refs.client_filter.state.value = "";
                this.refs.closet_search.state.value = "";
                this.refs.name_search.state.value = "";
                this.strMonthFilter = "";
                this.GetStorageOutData();

            } else {
                let strClient = this.refs.client_filter.state.value;
                let strCloset = this.refs.closet_search.state.value;
                let strKeywords = this.refs.name_search.state.value;
                if (strClient === undefined || strClient === null)
                    strClient = "";
                if (strCloset === undefined || strCloset === null)
                    strCloset = "";
                if (strKeywords === undefined || strKeywords === null)
                    strKeywords = "";
                this.setState({
                    ...this.state,
                    bLoading: true,
                    selectedRowKeys: [],
                    btnControlDisable: [false, true, true]
                });
                axios('/SearchStorageOut', {
                    params: {
                        client: this.KeywordsProcess(strClient),
                        closet: this.KeywordsProcess(strCloset),
                        key: this.KeywordsProcess(strKeywords),
                        store_id: this.props.iStoreID
                    }
                }).then(({ data }) => {
                    this.arrTableData = this.OrganizeStorageOutData(data);
                    this.setState({
                        ...this.state,
                        bLoading: false,
                        objFilter: {
                            strKeyword: strKeywords,
                            iCurrentUserStatus: 0,
                            iCurrentUserType: 0
                        }
                    });
                }).catch(function (error) { console.log(error); });
            }
        }

    }
    // 获取客户数据
    GetClientsData() {
        axios('/GetClients', { params: { store_id: this.props.objStoreInfo.id } })
            .then(({ data }) => {
                global.arrClientData = data;
                for (let i = 0; i < global.arrClientData.length; i++) {
                    this.arrClientDataSource.push(global.arrClientData[i].name);
                }
            }).catch(function (error) { console.log(error); });
    }
    // 选择客户
    SelectClient = () => {
        console.log("SelectClient : ", this.state.bShowDlg);
        if (this.state.bShowDlg) {
            // if (this.refs.client_search !== undefined)
            //     this.refs.client_search.state.value = "";
            this.setState({
                ...this.state,
                bSelectClientDlg: true
            });
        } else {
            // if (this.refs.client_filter !== undefined)
            //     this.refs.client_filter.state.value = "";
            this.setState({
                ...this.state,
                bSelectClientFilterDlg: true
            });
        }

    }
    // 选择客户弹窗结果
    ClientSelectResult = (index) => {
        if (this.state.bShowDlg) {
            if (index >= 0) {
                this.refs.client_name.state.value = global.arrClientData[index].name;
                this.iCurrentClient = global.arrClientData[index].id;
            }
        } else {
            if (index >= 0) {
                this.refs.client_filter.state.value = global.arrClientData[index].name;
            }
        }

        this.setState({
            ...this.state,
            bSelectClientDlg: false,
            bSelectClientFilterDlg: false
        })
    }
    // 搜索/筛选客户
    SearchClient = () => {
        this.setState({
            ...this.state,
            bLoading: false
        })
    }
    // 从数据库获取当前的数据结构
    GetStorageInData() {
        message.loading({ content: '数据加载中……', key: g_strMessageKey });
        axios('/GetStorageIn', { params: { store_id: this.props.iStoreID } })
            .then(({ data }) => {
                global.arrStorageInData = data;
                console.log("Storage In : ", data);
                // this.arrStorageInDataSource = [global.arrStorageInData[0].closet];
                // for (let i = 0; i < global.arrStorageInData.length; i++) {
                //     for (let j = 0; j < this.arrStorageInDataSource.length; j++) {
                //         if (global.arrStorageInData[i].closet !== this.arrStorageInDataSource[j]) {
                //             this.arrStorageInDataSource.push(global.arrStorageInData[i].closet);
                //             break;
                //         }
                //     }
                // }
                // console.log("storage data source : ", this.arrStorageInDataSource);
            }).catch(function (error) { console.log(error); });
    }
    // 获取客户的货柜信息
    GetClientCloset = () => {
        this.arrStorageInDataSource = [];
        for (let i = 0; i < global.arrStorageInData.length; i++) {
            if (global.arrStorageInData[i].client_name === this.strCurrentClient) {
                this.arrStorageInDataSource.push(global.arrStorageInData[i].closet);
                break;
            }
        }
        if (this.arrStorageInDataSource.length >= 1) {
            for (let i = 0; i < global.arrStorageInData.length; i++) {
                if (global.arrStorageInData[i].client_name === this.strCurrentClient) {
                    for (let j = 0; j < this.arrStorageInDataSource.length; j++) {
                        if (global.arrStorageInData[i].closet !== this.arrStorageInDataSource[j]) {
                            this.arrStorageInDataSource.push(global.arrStorageInData[i].closet);
                            break;
                        }
                    }
                }
            }
        }
        // console.log("GetClientCloset : ", this.arrStorageInDataSource);
    }
    // 获取货柜中的货物信息
    GetClosetItem = () => {
        this.arrItemDataSource = [];
        this.arrItemInfo = [];
        // 调出所有入库记录
        for (let i = 0; i < global.arrStorageInData.length; i++) {
            if (global.arrStorageInData[i].client_name === this.strCurrentClient && global.arrStorageInData[i].closet === this.strCurrentCloset) {
                this.arrItemInfo = JSON.parse(global.arrStorageInData[i].item_info);
                break;
            }
        }
        // 匹配所有出库记录
        for (let i = 0; i < global.arrStorageOutData.length; i++) {
            if (global.arrStorageOutData[i].client_name === this.strCurrentClient && global.arrStorageOutData[i].closet === this.strCurrentCloset) {
                let arrItemInfo = JSON.parse(global.arrStorageOutData[i].item_info);
                for (let j = 0; j < arrItemInfo.length; j++) {
                    for (let k = 0; k < this.arrItemInfo.length; k++) {
                        if (this.arrItemInfo[k].name === arrItemInfo[j].name) {
                            this.arrItemInfo[k].num -= arrItemInfo[j].out;
                            break;
                        }
                    }
                }
                break;
            }
        }
        // 显示有库存的货物
        for (let i = 0; i < this.arrItemInfo.length; i++) {
            this.arrItemDataSource.push(this.arrItemInfo[i].name);
        }
    }
    // 新增货物
    AddItem = () => {
        let objItemInit = {
            name: "", num: 0, out: 0, barcode: ''
        };
        this.arrItemList.push(objItemInit);
        this.setState({
            ...this.state,
            bLoading: false
        })
    }
    // 新增货物
    DeleteItem = (index) => {
        if (this.arrItemList.length <= 1) {
            message.warning("至少保留一样货物！");
            return;
        }
        console.log("DeleteItem1: ", index);
        this.arrItemList.splice(index, 1);
        console.log("DeleteItem2 : ", this.arrItemList);
        this.setState({
            ...this.state,
            bLoading: false
        })
    }
    // 更新货物信息
    KeyValueChange = (index, type, e) => {
        if (index === -1) {
            if (type === 0) {
                this.strCurrentClient = e;
                this.iCurrentClient = -1;
                for (let i = 0; i < global.arrClientData.length; i++) {
                    if (this.strCurrentClient === global.arrClientData[i].name) {
                        this.iCurrentClient = global.arrClientData[i].id;
                        break;
                    }
                }
            }
            if (type === 1) {
                this.strCurrentCloset = e;
            }
        } else {
            if (type === 0) {
                this.arrItemList[index].name = e;
                for (let i = 0; i < this.arrItemInfo.length; i++) {
                    if (e === this.arrItemInfo[i].name) {
                        this.arrItemList[index].num = this.arrItemInfo[i].num;
                    }
                }
            }
            if (type === 1) {
                this.arrItemList[index].out = e.target.value;
                let iOutNum = parseInt(e.target.value);
                if (isNaN(iOutNum)) {
                    iOutNum = 0;
                }
                this.arrItemList[index].out = iOutNum;
                let iInventory = parseInt(this.arrItemList[index].num);
                if (iOutNum > iInventory) {
                    message.warning("库存不足，请检查！");
                    this.arrItemList[index].out = this.arrItemList[index].num;
                }
            }
            if (type === 2) {
                let strBarcode = e.target.value;
                // let arrBarcode = strBarcode.split('\n');
                // for (let i = 0; i < arrBarcode.length; i++) {
                //     if (arrBarcode[i] !== undefined && arrBarcode[i] !== null && typeof (arrBarcode[i]) === 'string')
                //         arrBarcode[i] = arrBarcode[i].trim();
                // }
                this.arrItemList[index].barcode = strBarcode;
                // this.arrItemList[index].out = arrBarcode.length;
            }
            if (type === 3) {
                let strSerialNo = e.target.value;
                // let arrBarcode = strBarcode.split('\n');
                // for (let i = 0; i < arrBarcode.length; i++) {
                //     if (arrBarcode[i] !== undefined && arrBarcode[i] !== null && typeof (arrBarcode[i]) === 'string')
                //         arrBarcode[i] = arrBarcode[i].trim();
                // }
                this.arrItemList[index].serial = strSerialNo;
                // this.arrItemList[index].out = arrBarcode.length;
            }
        }


        this.setState({
            ...this.state,
            bLoading: false
        })
    }
    // 出库方式变更
    OutWayChange = (index) => {
        this.setState({
            ...this.state,
            iOutWay: index
        })
    }
    // 物流方式变更
    TransitWayChange = (index) => {
        this.setState({
            ...this.state,
            iTransitWay: index
        })
    }
    // 月份过滤器
    MonthFilter = (date, dateString) => {
        console.log(dateString);
        this.dateMonthFilter = date;
        this.strMonthFilter = dateString;
        this.StorageOutSearch(false);
        // this.arrTableData = this.OrganizeStorageOutData(this.arrOgeTableData);
        this.setState({
            ...this.state,
            bLoading: false
        })
    }
    // 导出Excel文档
    ExportExcelFile = () => {
        console.log("导出出库记录：", this.arrSelectStorage);
        let arrExportData = [
            ['客户', '柜号', '出库日期', '出库方式', '物流方式', '运单号', '备注', '货物名称', '出库数量', '条形码记录']
        ];
        for (let i = 0; i < this.arrSelectStorage.length; i++) {
            let arrData = [];
            arrData.push(this.arrSelectStorage[i].client_name);
            arrData.push(this.arrSelectStorage[i].closet);
            arrData.push(this.arrSelectStorage[i].storage_out_date);
            arrData.push(this.arrOutWay[this.arrSelectStorage[i].outway]);
            arrData.push(this.arrTransitWay[this.arrSelectStorage[i].transitway]);
            arrData.push(this.arrSelectStorage[i].order_no);
            arrData.push(this.arrSelectStorage[i].comments);
            let arrItemInfo = JSON.parse(this.arrSelectStorage[i].item_info);
            arrData.push(arrItemInfo[0].name);
            arrData.push(parseInt(arrItemInfo[0].num));
            let strBarcode = arrItemInfo[0].barcode;

            if (strBarcode.indexOf('\n') >= 0)
                strBarcode = strBarcode.replaceAll('\n', ', ');
            arrData.push(strBarcode);
            arrExportData.push(arrData);
            if (arrItemInfo.length > 1) {
                arrData = ['', '', '', '', '', '', '']
                for (let j = 1; j < arrItemInfo.length; j++) {
                    arrData.push(arrItemInfo[j].name);
                    arrData.push(parseInt(arrItemInfo[j].num));
                    let strBarcode = arrItemInfo[j].barcode;

                    if (strBarcode.indexOf('\n') >= 0)
                        strBarcode = strBarcode.replaceAll('\n', ', ');
                    arrData.push(strBarcode);
                    arrExportData.push(arrData);
                }
            }
        }
        console.log("export excel file", arrExportData);
        var sheet = XLSX.utils.aoa_to_sheet(arrExportData);
        openDownloadDialog(sheet2blob(sheet), '出库记录.xlsx');

    }

    /* React自带函数 */
    // 卸载异步操作设置状态
    componentWillUnmount() {
        clearTimeout(this.timeouter)
        this.setState = (state, callback) => {
            return;
        }
    }
    // 渲染完毕……
    componentDidMount() {
        this.GetStorageOutData();
        if (global.arrClientData.length <= 0)
            this.GetClientsData();
        else {
            for (let i = 0; i < global.arrClientData.length; i++) {
                this.arrClientDataSource.push(global.arrClientData[i].name);
            }
        }
        if (global.arrStorageInData.length <= 0)
            this.GetStorageInData();
    }
    // 渲染函数
    render() {

        const dateFormat = 'YYYY-MM-DD';

        /* 出库管理页面控制栏 */
        let uiNewButton = (
            <Button className="manage_contents_title_margin" type="primary" onClick={this.ShowDlg.bind(this, 0)} >
                <Icon type="plus" />新建</Button>
        );
        let uiEditButton = (
            <Button className="manage_contents_title_margin" type="primary" onClick={this.ShowDlg.bind(this, 1)} disabled={this.state.btnControlDisable[1]}>
                <Icon type="edit" />编辑</Button>
        );
        let uiDeleteButton = (
            <Button className="manage_contents_title_margin" type="danger" onClick={this.ShowDlg.bind(this, 2)} disabled={this.state.btnControlDisable[2]}>
                <Icon type="close" /> 删除</Button>
        );
        if (this.props.iUserLevel === 1) {
            uiEditButton = (
                <Button className="manage_contents_title_margin" type="primary" onClick={this.ShowDlg.bind(this, 1)} disabled={this.state.btnControlDisable[1]}>
                    <Icon type="search" />查看</Button>
            );
            uiDeleteButton = null;
            uiNewButton = null;
        }
        let uiTitleArea = (
            <div>
                < div className="logistics_page_title" >
                    <div className="manage_stretch_right">
                        <div className="logistic_filter_title" style={{ minWidth: '36px' }}>月份</div>
                        <MonthPicker style={{ minWidth: '150px', marginRight: '20px' }} onChange={this.MonthFilter} value={this.dateMonthFilter} placeholder="Select month" />
                        <div className="logistic_filter_title" style={{ minWidth: '36px' }}>客户</div>
                        <Input className="logistic_search_input" ref="client_filter" style={{ marginRight: '0px' }} />
                        <Button type='primary' className="manage_contents_title_margin" onClick={this.SelectClient.bind(this)}>选 择</Button>
                        <div className="logistic_filter_title" style={{ minWidth: '36px', marginLeft: '20px' }}>柜号</div>
                        <Input ref="closet_search" defaultValue={this.state.strKeywords} />
                        <div className="logistic_filter_title" style={{ minWidth: '50px', marginLeft: '20px' }}>关键字</div>
                        <Input placeholder="货物名称/Ref#/备注/操作人" ref="name_search" defaultValue={this.state.strKeywords} />
                        <Button className="manage_contents_title_margin" type="primary" onClick={this.StorageOutSearch.bind(this, false)}>搜 索
                    </Button>
                        <Button type='primary' className="manage_contents_title_margin" onClick={this.StorageOutSearch.bind(this, true)}>显示全部</Button>
                    </div>
                </div >
                <div className="logistics_page_title_sec">
                    {uiNewButton}
                    {uiEditButton}
                    {uiDeleteButton}
                    <Button className="manage_contents_title_margin" type="primary" onClick={this.ExportExcelFile.bind(this)} disabled={this.state.bExport}>
                        <Icon type="edit" />导出</Button>
                </div>
            </div>

        );
        if (this.props.iUserLevel === 1) {
            uiTitleArea = (
                <div>
                    < div className="logistics_page_title" >
                        <div className="manage_stretch_right">
                            <div className="logistic_filter_title" style={{ minWidth: '36px' }}>月份</div>
                            <MonthPicker style={{ minWidth: '150px', marginRight: '20px' }} onChange={this.MonthFilter} value={this.dateMonthFilter} placeholder="Select month" />

                            <div className="logistic_filter_title" style={{ minWidth: '36px', marginLeft: '20px' }}>柜号</div>
                            <Input ref="closet_search" defaultValue={this.state.strKeywords} />
                            <div className="logistic_filter_title" style={{ minWidth: '50px', marginLeft: '20px' }}>关键字</div>
                            <Input placeholder="货物名称/Ref#/备注/操作人" ref="name_search" defaultValue={this.state.strKeywords} />
                            <Button className="manage_contents_title_margin" type="primary" onClick={this.StorageOutSearch.bind(this, false)}>搜 索
                    </Button>
                            <Button type='primary' className="manage_contents_title_margin" onClick={this.StorageOutSearch.bind(this, true)}>显示全部</Button>
                        </div>
                    </div >
                    <div className="logistics_page_title_sec">
                        {uiNewButton}
                        {uiEditButton}
                        {uiDeleteButton}
                        <Button className="manage_contents_title_margin" type="primary" onClick={this.ExportExcelFile.bind(this)} disabled={this.state.bExport}>
                            <Icon type="edit" />导出</Button>
                    </div>
                </div>

            );
        }

        // 如果界面上需要显示的内容已经从数据库获得
        if (!this.state.bLoading) {
            // 主体表格相关
            const { selectedRowKeys } = this.state;
            const rowSelection = {
                selectedRowKeys,
                onChange: this.onSelectedRowKeysChange,
                columnWidth: 30
            };
            // 表格分页属性
            const paginationProps = {
                showSizeChanger: true,
                showQuickJumper: false,
                showTotal: () => `共 ${this.arrTableData.length} 个 出库`,
                defaultPageSize: 10,
                defaultCurrent: this.state.iCurrentPage,
                pageSizeOptions: ['10', '20', '30', '40', '50'],
                total: this.arrTableData.length,
                position: 'bottom'
            };

            let uiItemList = null;
            if (this.arrItemList.length <= 0) {
                let objItemInit = {
                    name: "", num: 0, out: 0, barcode: ''
                };
                this.arrItemList.push(objItemInit);
            }
            let bClosetDisabled = true;
            if (this.strCurrentClient !== "" && this.strCurrentClient !== null) {
                if (typeof (this.strCurrentClient) === 'string') {
                    this.strCurrentClient = this.strCurrentClient.trim(' ');
                    if (this.strCurrentClient !== "")
                        bClosetDisabled = false;
                } else
                    bClosetDisabled = false;
            }
            if (!bClosetDisabled) {
                this.GetClientCloset();
            }
            let bItemDisabled = true;
            if (this.strCurrentCloset !== "" && this.strCurrentCloset !== null) {
                if (typeof (this.strCurrentCloset) === 'string') {
                    this.strCurrentCloset = this.strCurrentCloset.trim(' ');
                    if (this.strCurrentCloset !== "")
                        bItemDisabled = false;
                } else
                    bItemDisabled = false;
            }
            if (!bClosetDisabled && !bItemDisabled) {
                bItemDisabled = false;
                this.GetClosetItem();
            } else
                bItemDisabled = true;

            if (this.props.iUserLevel === 1) {
                bItemDisabled = true;
            }


            let iItemTotalNum = 0;
            uiItemList = this.arrItemList.map((item, index) => {
                let strStyle = "logistics_item_list_area1 ";
                if (index % 2 !== 0)
                    strStyle += "logistics_item_list_area2";
                iItemTotalNum += parseInt(item.out);
                if (this.props.iUserLevel === 1) {
                    return (
                        <div className={strStyle} key={index}>
                            <div className="user_edit_info_row" style={{ marginTop: '0px' }}>
                                <div className="logistics_edit_info_title" style={{ color: 'lightcoral' }}>货物：</div>
                                <Input className="user_edit_info_input"
                                    style={{ marginRight: '30px', background: 'white', color: 'black' }}
                                    value={item.name} disabled={bItemDisabled} />
                                <div className="logistics_edit_info_title">库存：</div>
                                <Input className="user_edit_info_input" ref="num" style={{ marginRight: '30px', background: 'white', color: 'black' }} disabled value={item.num} />
                                <div className="logistics_edit_info_title" style={{ color: 'lightcoral' }}>出库：</div>
                                <Input className="user_edit_info_input" ref="out" style={{ marginRight: '35px', background: 'white', color: 'black' }} disabled={bItemDisabled}
                                    defaultValue={item.out} value={item.out} onChange={this.KeyValueChange.bind(this, index, 1)} />
                                <Button className="logistics_small_button" type="danger" onClick={this.DeleteItem.bind(this, index)}
                                    disabled={bItemDisabled}>X</Button>
                            </div>
                            <div className="user_edit_info_row" style={{ marginTop: '20px', marginBottom: '0px' }} >
                                <div className="logistics_edit_info_title">Serial No.</div>
                                <TextArea size="large" autoSize={{ minRows: 2, maxRows: 5 }} allowClear={true} ref={"serial"} style={{ marginBottom: '0px', background: 'white', color: 'black' }}
                                    placeholder="*填写货物内容*  或  *扫描货物条形码，以回车分割*" value={item.serial}
                                    onChange={this.KeyValueChange.bind(this, index, 2)} />
                            </div>
                            <div className="user_edit_info_row" style={{ marginTop: '20px', marginBottom: '0px' }} >
                                <div className="logistics_edit_info_title">备注</div>
                                <TextArea size="large" autoSize={{ minRows: 2, maxRows: 5 }} allowClear={true} ref={"barcode"} style={{ marginBottom: '0px', background: 'white', color: 'black' }}
                                    placeholder="*填写货物内容*  或  *扫描货物条形码，以回车分割*" value={item.barcode}
                                    onChange={this.KeyValueChange.bind(this, index, 2)} />
                            </div>
                        </div>);
                } else {
                    return (
                        <div className={strStyle} key={index}>
                            <div className="user_edit_info_row" style={{ marginTop: '0px' }}>
                                <div className="logistics_edit_info_title" style={{ color: 'lightcoral' }}>货物：</div>
                                <AutoComplete className="user_edit_info_input" style={{ marginRight: '30px' }} dataSource={this.arrItemDataSource}
                                    filterOption={(inputValue, option) => option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                                    value={item.name} onChange={this.KeyValueChange.bind(this, index, 0)} disabled={bItemDisabled} />
                                <div className="logistics_edit_info_title">库存：</div>
                                <Input className="user_edit_info_input" ref="num" style={{ marginRight: '30px' }} disabled value={item.num} />
                                <div className="logistics_edit_info_title" style={{ color: 'lightcoral' }}>出库：</div>
                                <Input className="user_edit_info_input" ref="out" style={{ marginRight: '35px' }} disabled={bItemDisabled}
                                    defaultValue={item.out} value={item.out} onChange={this.KeyValueChange.bind(this, index, 1)} />
                                <Button className="logistics_small_button" type="danger" onClick={this.DeleteItem.bind(this, index)}
                                    disabled={bItemDisabled}>X</Button>
                            </div>
                            <div className="user_edit_info_row" style={{ marginTop: '20px', marginBottom: '0px' }} >
                                <div className="logistics_edit_info_title">Serial No.</div>
                                <TextArea size="large" autoSize={{ minRows: 2, maxRows: 5 }} allowClear={true} ref={"serial"}
                                    style={{ marginBottom: '0px' }}
                                    placeholder="*填写货物的序列编号*" value={item.serial} disabled={bItemDisabled}
                                    onChange={this.KeyValueChange.bind(this, index, 3)} />
                            </div>
                            <div className="user_edit_info_row" style={{ marginTop: '20px', marginBottom: '0px' }} >
                                <div className="logistics_edit_info_title">备注</div>
                                <TextArea size="large" autoSize={{ minRows: 2, maxRows: 5 }} allowClear={true} ref={"barcode"}
                                    style={{ marginBottom: '0px' }}
                                    placeholder="*填写货物内容*  或  *扫描货物条形码，以回车分割*" value={item.barcode} disabled={bItemDisabled}
                                    onChange={this.KeyValueChange.bind(this, index, 2)} />
                            </div>
                        </div>);
                }


            });

            let uiOutWay = (
                <Menu>
                    {this.arrOutWay.map((out, index) => {
                        return (
                            <Menu.Item key={index} onClick={this.OutWayChange.bind(this, index)}>
                                {out}
                            </Menu.Item>
                        );
                    })}
                </Menu>
            );
            let uiTransitWay = (
                <Menu>
                    {this.arrTransitWay.map((transit, index) => {
                        return (
                            <Menu.Item key={index} onClick={this.TransitWayChange.bind(this, index)}>
                                {transit}
                            </Menu.Item>
                        );
                    })}
                </Menu>
            );

            let bDisabled = false;
            if (this.props.iUserLevel === 1) {
                bClosetDisabled = true;
                bDisabled = true;
            }



            // 出库记录编辑弹窗
            let uiModal = (
                <Modal title="出库记录" width={990} visible={this.state.bShowDlg}
                    okText="提交" cancelText="取消" onOk={this.handleOk} onCancel={this.handleCancel} >
                    <div className="user_edit_info_row" style={{ marginBottom: '20px' }}>
                        <div className="logistics_edit_info_title" style={{ color: 'lightcoral' }}>客户：</div>
                        <AutoComplete className="user_edit_info_input" style={{ marginRight: '80px' }} dataSource={this.arrClientDataSource}
                            value={this.strCurrentClient} onChange={this.KeyValueChange.bind(this, -1, 0)} disabled={bDisabled}
                            filterOption={(inputValue, option) => option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1} />
                        <div className="logistics_edit_info_title" style={{ color: 'lightcoral' }}>柜号：</div>
                        <AutoComplete className="user_edit_info_input" style={{ marginRight: '80px' }} dataSource={this.arrStorageInDataSource}
                            onChange={this.KeyValueChange.bind(this, -1, 1)} disabled={bClosetDisabled} value={this.strCurrentCloset}
                            filterOption={(inputValue, option) => option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1} />
                        <div className="logistics_edit_info_title" style={{ color: 'lightcoral' }}>出库日期</div>
                        <DatePicker className="user_edit_info_input" onChange={this.StorageOutDateChange} style={{ marginRight: '0px' }}
                            value={moment(this.state.dateStorageOut, dateFormat)} disabled={bClosetDisabled}
                            defaultValue={moment(this.state.dateStorageOut, dateFormat)} />
                    </div>
                    <div className="user_edit_info_row" style={{ marginBottom: '20px' }}>
                        <div className="logistics_edit_info_title" style={{ color: 'lightcoral' }}>出库方式</div>
                        {/* <Input className="user_edit_info_input" ref="order_no" style={{ marginRight: '80px' }}
                            defaultValue={this.objCurrentStorageOut.order_no} /> */}
                        <Dropdown className="user_edit_info_input" trigger={['click']} disabled={bClosetDisabled}
                            overlay={uiOutWay} placement="bottomLeft" true>
                            <Button style={{ marginRight: '80px' }}>{this.arrOutWay[this.state.iOutWay]}
                                <Icon type="caret-down" /></Button>
                        </Dropdown>
                        <div className="logistics_edit_info_title" style={{ color: 'lightcoral' }}>物流方式</div>
                        {/* <Input className="user_edit_info_input" ref="order_no" style={{ marginRight: '80px' }}
                            defaultValue={this.objCurrentStorageOut.order_no} /> */}
                        <Dropdown className="user_edit_info_input" trigger={['click']} disabled={bClosetDisabled}
                            overlay={uiTransitWay} placement="bottomLeft" true>
                            <Button style={{ marginRight: '80px' }}>{this.arrTransitWay[this.state.iTransitWay]}
                                <Icon type="caret-down" /></Button>
                        </Dropdown>
                        <div className="logistics_edit_info_title">运单号：</div>
                        <Input className="user_edit_info_input" ref="order_no" style={{ marginRight: '0px' }} disabled={bClosetDisabled}
                            defaultValue={this.objCurrentStorageOut.order_no} />
                    </div>
                    <div className="user_edit_info_row" style={{ marginBottom: '20px', marginRight: '0px' }}>
                        <div className="logistics_edit_info_title" style={{ marginTop: '10px', marginBottom: 'auto' }}>备注：</div>
                        <TextArea size="large" autoSize={{ minRows: 2 }} allowClear={true} ref="comments" style={{ marginBottom: '20px' }}
                            placeholder="" defaultValue={this.objCurrentStorageOut.comments} disabled={bClosetDisabled} />
                    </div>
                    <div className="logistics_block_title" >
                        货物列表
                        <div className="user_edit_info_input" style={{ marginLeft: 'auto', marginRight: '0px' }}>{"种类：" + this.arrItemList.length}</div>
                        <div className="user_edit_info_input" >{"总数：" + iItemTotalNum}</div>
                        <Button type="primary" className="jeton_order_block_button" style={{ marginLefr: 'auto', marginRight: '0px' }} disabled={bItemDisabled}
                            onClick={this.AddItem}>新增 +</Button>
                    </div>
                    {uiItemList}
                </Modal>
            );

            if (this.props.iUserLevel === 1) {
                uiModal = (
                    <Modal title="出库记录" width={990} visible={this.state.bShowDlg} footer={null} onCancel={this.handleCancel} >
                        <div className="user_edit_info_row" style={{ marginBottom: '20px' }}>
                            <div className="logistics_edit_info_title" style={{ color: 'lightcoral' }}>客户：</div>
                            <Input className="user_edit_info_input"
                                style={{ marginRight: '80px', background: 'white', color: 'black' }}
                                value={this.strCurrentClient} disabled={bDisabled} />
                            <div className="logistics_edit_info_title" style={{ color: 'lightcoral' }}>柜号：</div>
                            <Input className="user_edit_info_input" style={{ marginRight: '80px', background: 'white', color: 'black' }} disabled={bClosetDisabled} value={this.strCurrentCloset} />
                            <div className="logistics_edit_info_title" style={{ color: 'lightcoral' }}>出库日期</div>
                            <DatePicker className="user_edit_info_input" onChange={this.StorageOutDateChange} style={{ marginRight: '0px', background: 'white', color: 'black' }}
                                value={moment(this.state.dateStorageOut, dateFormat)}
                                defaultValue={moment(this.state.dateStorageOut, dateFormat)} />
                        </div>
                        <div className="user_edit_info_row" style={{ marginBottom: '20px' }}>
                            <div className="logistics_edit_info_title" style={{ color: 'lightcoral' }}>出库方式</div>
                            <Dropdown className="user_edit_info_input" trigger={['click']} disabled={bClosetDisabled}
                                overlay={uiOutWay} placement="bottomLeft" true>
                                <Button style={{ marginRight: '80px', background: 'white', color: 'black' }}>{this.arrOutWay[this.state.iOutWay]}
                                    <Icon type="caret-down" /></Button>
                            </Dropdown>
                            <div className="logistics_edit_info_title" style={{ color: 'lightcoral' }}>物流方式</div>
                            <Dropdown className="user_edit_info_input" trigger={['click']} disabled={bClosetDisabled}
                                overlay={uiTransitWay} placement="bottomLeft" true>
                                <Button style={{ marginRight: '80px', background: 'white', color: 'black' }}>{this.arrTransitWay[this.state.iTransitWay]}
                                    <Icon type="caret-down" /></Button>
                            </Dropdown>
                            <div className="logistics_edit_info_title">运单号：</div>
                            <Input className="user_edit_info_input" ref="order_no" style={{ marginRight: '0px', background: 'white', color: 'black' }} disabled={bClosetDisabled}
                                defaultValue={this.objCurrentStorageOut.order_no} />
                        </div>
                        <div className="user_edit_info_row" style={{ marginBottom: '20px', marginRight: '0px', background: 'white', color: 'black' }}>
                            <div className="logistics_edit_info_title" style={{ marginTop: '10px', marginBottom: 'auto' }}>备注：</div>
                            <TextArea size="large" autoSize={{ minRows: 2 }} allowClear={true} ref="comments" style={{ marginBottom: '20px' }}
                                placeholder="" defaultValue={this.objCurrentStorageOut.comments} />
                        </div>
                        <div className="logistics_block_title" >
                            货物列表
                        <div className="user_edit_info_input" style={{ marginLeft: 'auto', marginRight: '0px', background: 'white', color: 'black' }}>{"种类：" + this.arrItemList.length}</div>
                            <div className="user_edit_info_input" >{"总数：" + iItemTotalNum}</div>
                            <Button type="primary" className="jeton_order_block_button" style={{ marginLefr: 'auto', marginRight: '0px' }} disabled={bItemDisabled}
                                onClick={this.AddItem}>新增 +</Button>
                        </div>
                        {uiItemList}
                    </Modal>
                );
            }

            if (this.state.iDlgType === 2) {
                uiModal = (
                    <Modal width={500} title={"删除出库记录"} visible={this.state.bShowDlg} okType="danger"
                        onOk={this.handleOk} okText="确定"
                        onCancel={this.handleCancel} cancelText="取消">
                        <div className="modal_item">
                            <div>
                                {/* {this.objCurrentStorageOut.id} */}
                                确定删除选中的出库？
                            </div>
                        </div>
                    </Modal>
                );
            }
            // 选择客户弹窗
            let uiClientSearchTitle = (
                <div className="logistic_row">
                    <div >选择客户</div>
                    <Input className="logistic_search_input" ref="client_search" defaultValue="" style={{ marginRight: '0px', marginLeft: '10px' }} allowClear />
                    <Button type="primary" onClick={this.SearchClient} style={{ width: '30px', marginRight: 'auto' }} className="logistic_row">
                        <Icon type="search" /></Button>
                </div>);
            let uiClientList = (
                <div className="storage_sku_list_area">
                    {global.arrClientData.map((client, index) => {
                        if (this.refs.client_search !== undefined) {
                            let strClientSearch = this.refs.client_search.state.value;
                            if (strClientSearch !== "" && strClientSearch !== null) {
                                if (client.name.toLowerCase().indexOf(strClientSearch.toLowerCase()) >= 0)
                                    return (
                                        <div className="storage_sku_list_row" key={index} onClick={this.ClientSelectResult.bind(this, index)}>
                                            {client.name}
                                        </div>
                                    );
                                else return null
                            } else {
                                return (
                                    <div className="storage_sku_list_row" key={index} onClick={this.ClientSelectResult.bind(this, index)}>
                                        {client.name}
                                    </div>
                                );
                            }
                        } else {
                            return (
                                <div className="storage_sku_list_row" key={index} onClick={this.ClientSelectResult.bind(this, index)}>
                                    {client.name}
                                </div>
                            );
                        }
                    })}
                </div>
            );
            let uiClientFilterDlg = (
                <Modal width={450} title={uiClientSearchTitle} visible={this.state.bSelectClientFilterDlg} closable={true} maskClosable={true} footer={null}
                    onCancel={this.ClientSelectResult.bind(this, -1)}
                > {uiClientList} </Modal>);
            let uiSelectClientDlg = (
                <Modal width={450} title={uiClientSearchTitle} visible={this.state.bSelectClientDlg} closable={true} maskClosable={true} footer={null}
                    onCancel={this.ClientSelectResult.bind(this, -1)}
                > {uiClientList} </Modal>);


            let strAreaStyle = "logistics_page_contents";
            let arrTableHeader = arrTableHeader1;
            if (this.props.iUserLevel === 1)
                arrTableHeader = arrTableHeader2;
            return (
                <div className={strAreaStyle} style={{ paddingTop: ' 100px' }}>
                    {uiTitleArea}
                    {uiModal}
                    {uiClientFilterDlg}
                    {uiSelectClientDlg}
                    {/* 表格主体 */}
                    <Table style={{ margin: '20px', width: '97.5%', overflowX: 'auto' }} bordered
                        rowSelection={rowSelection} pagination={paginationProps}
                        columns={arrTableHeader} dataSource={this.arrTableData}
                        onRow={(record) => ({ onClick: () => { this.selectRow(record); } })} />
                </div >
            );
        } else {
            return (
                <div className="logistics_page_contents">
                    {uiTitleArea}
                    <Spin className="manage_empty_loading" size="large" tip="加载中……" />
                </div>
            );
        }
    }
}
export default LogisticStorageOut;
