import React, { Component } from "react";
import axios from 'axios';
import moment from 'moment';
import XLSX from 'xlsx';

import "../../../styles/Background/UI_Module/store_item_management.css";
import '../../../styles/Background/store_manager.css';
import '../../../styles/Background/ego_manager.css';

import Upload from 'antd/es/upload';
import Modal from 'antd/es/modal';
import Table from 'antd/es/table';
import Input from 'antd/es/input';
import Menu from 'antd/es/menu';
import Dropdown from 'antd/es/dropdown';
import Icon from 'antd/es/icon';
import Button from 'antd/es/button';
import Tag from 'antd/es/tag';
import Select from 'antd/es/select';
import DatePicker from 'antd/es/date-picker';
import Spin from 'antd/es/spin';
import Checkbox from 'antd/es/checkbox';
import message from 'antd/es/message';

import { UserActiveUpdate } from "../../../functions/DataStatistic.js"

const g_strMessageKey = 'updating';

// 获取图片的base64编码
function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}
// 从本地选择文件上传之前的检测判断（大小不能超过2M等）
function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('请选择jpg或png格式的图片文件上传');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('图片不能大于2Ｍ');
    }
    return isJpgOrPng && isLt2M;
}

const arrTableHeader = [
    { title: 'id', dataIndex: 'id', width: 50, ellipsis: true },
    { title: '名称', dataIndex: 'name', width: 250, ellipsis: true },
    { title: '序号', dataIndex: 'item_order', width: 50, ellipsis: true },
    { title: '状态', dataIndex: 'status_str', width: 75, ellipsis: true },
    { title: '类别/标签', dataIndex: 'type', width: 150, ellipsis: true },
    { title: '价格($)', dataIndex: 'price', width: 100, ellipsis: true },
    // { title: '零售价($)', dataIndex: 'retail_price', width: 125, ellipsis: true },
    { title: '库存', dataIndex: 'inventory', width: 100, ellipsis: true },
    { title: '销量', dataIndex: 'sale', width: 100, ellipsis: true },
    { title: '进购物车次数', dataIndex: 'in_cart', width: 125, ellipsis: true },
    { title: '浏览量', dataIndex: 'view', width: 100, ellipsis: true },
    { title: '标注', dataIndex: 'options', width: 200, ellipsis: true },
];

// csv转sheet对象
// function csv2sheet(csv) {
//     var sheet = {}; // 将要生成的sheet
//     csv = csv.split('\n');
//     csv.forEach(function (row, i) {
//         row = row.split(',');
//         if (i == 0) sheet['!ref'] = 'A1:' + String.fromCharCode(65 + row.length - 1) + (csv.length - 1);
//         row.forEach(function (col, j) {
//             sheet[String.fromCharCode(65 + j) + (i + 1)] = { v: col };
//         });
//     });
//     return sheet;
// }
// 将一个sheet转成最终的excel文件的blob对象，然后利用URL.createObjectURL下载
function sheet2blob(sheet, sheetName) {
    sheetName = sheetName || 'sheet1';
    var workbook = {
        SheetNames: [sheetName],
        Sheets: {}
    };
    workbook.Sheets[sheetName] = sheet;
    // 生成excel的配置项
    var wopts = {
        bookType: 'xlsx', // 要生成的文件类型
        bookSST: false, // 是否生成Shared String Table，官方解释是，如果开启生成速度会下降，但在低版本IOS设备上有更好的兼容性
        type: 'binary'
    };
    var wbout = XLSX.write(workbook, wopts);
    var blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });
    // 字符串转ArrayBuffer
    function s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
    }
    return blob;
}
//  通用的打开下载对话框方法，没有测试过具体兼容性
//  @param url 下载地址，也可以是一个blob对象，必选
//  @param saveName 保存文件名，可选
function openDownloadDialog(url, saveName) {
    if (typeof url == 'object' && url instanceof Blob) {
        url = URL.createObjectURL(url); // 创建blob地址
    }
    var aLink = document.createElement('a');
    aLink.href = url;
    aLink.download = saveName || ''; // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，file:///模式下不会生效
    var event;
    if (window.MouseEvent) event = new MouseEvent('click');
    else {
        event = document.createEvent('MouseEvents');
        event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
    }
    aLink.dispatchEvent(event);
}

const { Option } = Select;
const { TextArea } = Input;

class StoreItemManagement extends Component {
    constructor(props) {
        super();
        this.strToday = new Date();
        this.strToday = this.strToday.toISOString().split('T')[0];
        this.state = {
            btnControlDisable: [false, true, true],  // 操作按钮控制
            btnCopyPasteDisable: [true, true],  // 赋值粘贴按钮
            selectedRowKeys: [],
            objFilter: {
                strKeyword: "",
                iCurrentItemType: 0,
                iCurrentSortType: 0,
            },
            bDlgShow: false,
            bMultiDlgShow: false,
            arrMultiEdit: [false, false, false, false],
            iDlgType: 0,  // 0-新建，1-编辑，2-删除
            bLogoLoading: false,
            bImgLoading: false,
            strLogoUrl: '',
            strImgUrl: '',
            arrSelectedTypes: [],
            bSubmit: false,
            bLoading: true,
            iCurrentPage: 1,
            iCurrentCutPriceStep: 0,
            expire_date: this.strToday,
            iCurrentItemStatus: 0,
            iUploadProgress: 0
        };
        this.arrTableRow = [];
        this.arrItemTypes = ['全部'];
        this.arrItemOptions = [];
        for (let i = 0; i < global.arrItemTypeData.length; i++) {
            this.arrItemTypes.push(global.arrItemTypeData[i].type_name);
            this.arrItemOptions.push(<Option key={i}>{this.arrItemTypes[i + 1]}</Option>);
        }
        this.arrSortType = ['名称A→Z', '名称Z→A', '价格低→高', '价格高→低'];
        this.objCurrentItem = { id: 0, name: '', item_order: 0, price: '', type: [], options: '', description: '', image: '', logo: '' };

        this.bLogoLinkChange = false;
        this.bLogoLocalUpload = false;
        this.bImgLinkChange = false;
        this.bImgLocalUpload = false;
        this.arrDeleteID = [];  // 用于向数据库发送请求删除相应商品
        this.arrDeleteName = [];  // 用于在弹窗中提示用户删除的物品名称
        this.arrSelectItems = [];  // 选中的多个商品
        this.objCopiedItem = {};  // 拷贝留存的商品结构
        this.iCurrentTypeFilter = 0;  // 当前选中商品类型筛选器的Index
        this.strCurrentTypeFilter = '%';  // 当前选中商品类型筛选器的类别ID

        this.objCurrentCutPrice = { id: 0, item: 0, store: 0, num: 0, price: "", expire_date: this.strToday };
        this.arrCutPriceDate = [];
        this.arrCutPriceSteps = [
            { num: 10, price: 19.99 },
            { num: "第二阶段", price: "暂未开放" },
            { num: "第三阶段", price: "暂未开放" },
        ];
        this.strDefaultCutPriceNum = 10;
        this.strDefaultCutPriceValue = 9.99;
        this.arrItemStatus = ["编辑中", "已上架", "已售罄", "暂时缺货"];
    }
    // 选中表格某行
    selectRow = (record) => {
        const selectedRowKeys = [...this.state.selectedRowKeys];
        if (selectedRowKeys.indexOf(record.key) >= 0) {
            selectedRowKeys.splice(selectedRowKeys.indexOf(record.key), 1);
        } else {
            selectedRowKeys.push(record.key);
        }
        this.SelectedRowStateSetting(selectedRowKeys);
    }
    // 点击选择框选中表格某一行
    onSelectedRowKeysChange = (selectedRowKeys) => {
        this.SelectedRowStateSetting(selectedRowKeys);
    }
    // 处理选中的表行行
    SelectedRowStateSetting = (selectedRowKeys) => {
        let btnControlDisable = [];
        let btnCopyPasteDisable = [];
        switch (selectedRowKeys.length) {
            case 0: {
                btnControlDisable = [false, true, true];
                btnCopyPasteDisable = [true, true];
                this.objCurrentItem = {};
                this.objCurrentCutPrice = { id: 0, item: 0, store: 0, num: 0, price: "", expire_date: this.strToday };
                break;
            }
            case 1: {
                btnControlDisable = [false, false, false];
                btnCopyPasteDisable = [false, true];
                this.GetSelectedItemInfo(selectedRowKeys[0]);
                break;
            }
            default: btnControlDisable = [false, true, false]; btnCopyPasteDisable = [true, true]; break;
        }
        this.setState({
            ...this.state,
            selectedRowKeys,
            btnControlDisable: btnControlDisable,
            btnCopyPasteDisable: btnCopyPasteDisable
        });
    }
    // 选中行的数据获取（只针对通过勾选框选中表格某一行的情况）
    GetSelectedItemInfo = (strSelectedKey) => {
        for (let i = 0; i < this.arrTableRow.length; i++) {
            if (this.arrTableRow[i].key === strSelectedKey) {
                this.objCurrentItem = Object.assign({}, this.arrTableRow[i]);
                this.objCurrentItem.logo = this.objCurrentItem.logo.props.src;
                this.objCurrentItem.image = this.objCurrentItem.image.props.src;
                let arrSelectedTypes = [];
                if (this.objCurrentItem.type.props.children.length > 0) {
                    for (let i = 0; i < this.objCurrentItem.type.props.children.length; i++) {
                        let iTagIndex = this.TagNameToIndex(this.objCurrentItem.type.props.children[i].props.children);
                        arrSelectedTypes.push(iTagIndex.toString());  // 需要在编辑时显示标签，格式位文本
                    }
                }
                this.objCurrentItem.type = arrSelectedTypes;
                if (this.arrCutPriceDate.length > 0)
                    for (let i = 0; i < this.arrCutPriceDate.length; i++) {
                        if (this.objCurrentItem.id === this.arrCutPriceDate[i].item) {
                            this.objCurrentCutPrice = this.arrCutPriceDate[i];
                            break;
                        }
                    }
                if (this.objCurrentItem.id === 0) {
                    this.objCurrentCutPrice.num = this.strDefaultCutPriceNum;
                    this.objCurrentCutPrice.price = this.strDefaultCutPriceValue;
                }
                break;
            }
        }
    }
    // 拷贝一行数据
    CopyItem = () => {
        this.objCopiedItem = {};
        this.objCopiedItem = Object.assign({}, this.objCurrentItem);
        this.setState({
            ...this.state,
            btnCopyPasteDisable: [true, false]
        });
        const hide = message.success("已复制物品：<" + this.objCopiedItem.name + ">");
        setTimeout(hide, 2000);
    }
    // 粘贴一行数据
    PasteItem = () => {
        let objItem = {};
        objItem.store_id = this.props.objStoreInfo.id;
        objItem.name = this.objCopiedItem.name;
        objItem.item_order = this.objCopiedItem.item_order;
        objItem.item_unit = this.objCopiedItem.item_unit;
        objItem.cost = this.objCopiedItem.cost;
        objItem.sku = this.objCopiedItem.sku;
        objItem.length = this.objCopiedItem.length;
        objItem.width = this.objCopiedItem.width;
        objItem.height = this.objCopiedItem.height;
        // objItem.express_fee = this.objCopiedItem.express_fee;
        objItem.price = this.objCopiedItem.price;
        objItem.retail_price = this.objCopiedItem.retail_price;
        objItem.inventory = this.objCopiedItem.inventory;
        objItem.type = "";
        if (this.objCopiedItem.type.length > 0) {
            for (let i = 0; i < this.objCopiedItem.type.length; i++) {
                let iTagIndex = parseInt(this.objCopiedItem.type[i], 10);
                objItem.type += global.arrItemTypeData[iTagIndex].id.toString();
                if (i < this.objCopiedItem.type.length - 1)
                    objItem.type += ",";
            }
        }
        objItem.options = this.objCopiedItem.options;
        objItem.description = this.objCopiedItem.description;
        objItem.logo = this.objCopiedItem.logo;
        objItem.image = this.objCopiedItem.image;
        objItem.hot = 0; objItem.view = 0; objItem.sale = 0;
        objItem.status = 0;
        message.loading({ content: '拷贝中……', key: g_strMessageKey });
        axios.post('/NewItem', { item: objItem, type: this.strCurrentTypeFilter })
            .then(({ data }) => {
                message.success({ content: '拷贝商品：<' + objItem.name + "> 成功！", key: g_strMessageKey, duration: 2 });
                // 重新返回当前商家的所有商品信息
                this.RefreshItemTable(data);
                this.setState({
                    ...this.state,
                    btnControlDisable: [false, true, true],
                    btnCopyPasteDisable: [true, true],
                    selectedRowKeys: []
                });
            });
    }
    // 重置数据
    PresetNewDlg = () => {
        // 新建商品前的对话框中控件的数值重置
        if (this.refs.item_name !== undefined) {
            this.refs.item_name.state.value = "";
            this.refs.item_order.state.value = 0;
            this.refs.item_price.state.value = "";
            this.refs.item_comments.state.value = "";
            this.refs.item_description.state.value = "";
            this.refs.item_logo.state.value = "";
            this.refs.item_image.state.value = "";
            this.refs.item_cost.state.value = "";
            this.refs.item_sku.state.value = "";
            this.refs.item_length.state.value = "";
            this.refs.item_width.state.value = "";
            this.refs.item_height.state.value = "";
            // this.refs.item_express_fee.state.value = "";
            this.refs.item_unit.state.value = "";
            this.refs.item_retail_price.state.value = "";
            this.refs.item_inventory.state.value = "0";
            this.refs.item_sale.state.value = "0";
            this.refs.item_discount.state.value = "0";
        }
    }
    // 编辑某一行商品数据前的信息设置
    PresetEditDlg = () => {
        if (this.refs.item_name !== undefined) {
            this.refs.item_name.state.value = this.objCurrentItem.name;
            this.refs.item_order.state.value = this.objCurrentItem.item_order;
            this.refs.item_price.state.value = this.objCurrentItem.price;
            this.refs.item_comments.state.value = this.objCurrentItem.options;
            this.refs.item_description.state.value = this.objCurrentItem.description;
            this.refs.item_logo.state.value = this.objCurrentItem.logo;
            this.refs.item_image.state.value = this.objCurrentItem.image;
            this.refs.item_cost.state.value = this.objCurrentItem.cost;
            this.refs.item_sku.state.value = this.objCurrentItem.sku;
            this.refs.item_length.state.value = this.objCurrentItem.length;
            this.refs.item_width.state.value = this.objCurrentItem.width;
            this.refs.item_height.state.value = this.objCurrentItem.height;
            // this.refs.item_express_fee.state.value = this.objCurrentItem.express_fee;
            this.refs.item_unit.state.value = this.objCurrentItem.item_unit;
            this.refs.item_retail_price.state.value = this.objCurrentItem.retail_price;
            this.refs.item_inventory.state.value = this.objCurrentItem.inventory;
            this.refs.item_sale.state.value = this.objCurrentItem.sale;
            this.refs.item_discount.state.value = this.objCurrentItem.discount;
        }
    }
    // 点击了商品分类过滤器
    ItemTypeFilterClicked = (iItemType) => {
        if (iItemType === this.iCurrentTypeFilter)
            return;
        this.iCurrentTypeFilter = iItemType;
        this.strCurrentTypeFilter = '%';
        if (this.iCurrentTypeFilter > 0 && global.arrItemTypeData.length > 0)
            this.strCurrentTypeFilter = '%' + global.arrItemTypeData[this.iCurrentTypeFilter - 1].id.toString() + '%';
        this.GetStoreItems();
        this.setState({
            ...this.state,
            objFilter: {
                strKeyword: "",
                iCurrentItemType: iItemType,
                iCurrentSortType: this.state.objFilter.iCurrentSortType
            }
        });

    }
    // 点击了排序过滤器
    SortTypeFilterClicked = (iSortType) => {
        this.setState({
            ...this.state,
            objFilter: {
                strKeyword: "",
                iCurrentItemType: this.state.objFilter.iCurrentItemType,
                iCurrentSortType: iSortType
            }
        });
    }
    //弹出对话框
    ShowDlg = (iDlgType) => {
        switch (iDlgType) {
            case 0: {
                if (global.arrItemTypeData.length <= 0) {
                    message.warning("还未创建任何商品分类/标签，不能新建商品/服务！");
                    return;
                }
                this.PresetNewDlg();
                this.setState({
                    ...this.state,
                    strLogoUrl: '',
                    strImgUrl: '',
                    arrSelectedTypes: [],
                    iDlgType: 0,
                    bDlgShow: true,
                    iCurrentItemStatus: 0
                });
                break;
            }
            case 1: {
                this.PresetEditDlg();
                // message.loading({ content: '库存信息更新中……', key: g_strMessageKey });
                // axios('/GetItemInventory', { params: { store_id: this.props.objStoreInfo.id, item_id: this.objCurrentItem.id } })
                //     .then(({ data }) => {
                //         console.log("GetItemInventory : ", data);
                //         message.success({ content: "库存信息加载完成！", key: g_strMessageKey, duration: 2 });
                //         if (data.inventory !== 0) {
                //             let strInventory = "";
                //             if (data.inventory.length > 1) {
                //                 for (let i = 0; i < data.inventory.length; i++) {
                //                     if (i === data.inventory.length - 1)
                //                         strInventory += data.inventory[i];
                //                     else
                //                         strInventory += data.inventory[i].toString() + ",";
                //                 }
                //             } else {
                //                 strInventory = data.inventory[0].toString();
                //             }
                //             if (this.refs.item_inventory !== undefined)
                //                 this.refs.item_inventory.state.value = strInventory;
                //             this.objCurrentItem.inventory = strInventory;
                //         }
                //         this.setState({
                //             ...this.state,
                //             strLogoUrl: this.objCurrentItem.logo,
                //             strImgUrl: this.objCurrentItem.image,
                //             arrSelectedTypes: this.objCurrentItem.type,
                //             iDlgType: 1,
                //             bDlgShow: true,
                //             iCurrentItemStatus: this.objCurrentItem.status
                //         });
                //     });
                this.setState({
                    ...this.state,
                    strLogoUrl: this.objCurrentItem.logo,
                    strImgUrl: this.objCurrentItem.image,
                    arrSelectedTypes: this.objCurrentItem.type,
                    iDlgType: 1,
                    bDlgShow: true,
                    iCurrentItemStatus: this.objCurrentItem.status
                });
                break;
            }
            case 2: {
                this.arrDeleteID = [];
                this.arrDeleteName = [];
                for (let i = 0; i < this.state.selectedRowKeys.length; i++) {
                    let iIndex = this.state.selectedRowKeys[i];
                    this.arrDeleteID.push(this.arrTableRow[iIndex].id);
                    this.arrDeleteName.push(this.arrTableRow[iIndex].name);
                }
                this.setState({
                    ...this.state,
                    iDlgType: 2,
                    bDlgShow: true,
                });
                break;
            }
            case 3: {
                if (this.refs.cut_price_num) {
                    this.refs.cut_price_num.state.value = this.objCurrentCutPrice.num;
                    this.refs.cut_price_value.state.value = this.objCurrentCutPrice.price;
                    if (this.objCurrentItem.id === 0) {
                        this.objCurrentCutPrice.num = this.strDefaultCutPriceNum;
                        this.objCurrentCutPrice.price = this.strDefaultCutPriceValue;
                    }
                }
                this.setState({
                    ...this.state,
                    strLogoUrl: this.objCurrentItem.logo,
                    strImgUrl: this.objCurrentItem.image,
                    arrSelectedTypes: this.objCurrentItem.type,
                    iDlgType: 3,
                    bDlgShow: true,
                    iCurrentCutPriceStep: 0,
                    expire_date: this.objCurrentCutPrice.expire_date
                });
                break;
            }
            case 4: {
                this.arrSelectItems = [];
                for (let i = 0; i < this.state.selectedRowKeys.length; i++) {
                    let iIndex = this.state.selectedRowKeys[i];
                    this.arrSelectItems.push(this.arrTableRow[iIndex]);
                }
                this.setState({
                    ...this.state,
                    iDlgType: 4,
                    bDlgShow: false,
                    bMultiDlgShow: true,
                    arrMultiEdit: [false, false, false, false],
                    arrSelectedTypes: []
                });
                break;
            }
            default: {
                this.setState({
                    ...this.state,
                    bDlgShow: true,
                });
            }
        }
    };
    // 在数据库中新增/更新商品信息
    UpdateItemInfo() {
        this.objCurrentItem.logo = this.refs.item_logo.state.value;
        this.objCurrentItem.image = this.refs.item_image.state.value;
        let objItem = {};
        objItem.store_id = this.props.objStoreInfo.id;
        objItem.name = this.objCurrentItem.name;
        objItem.item_order = parseInt(this.objCurrentItem.item_order, 10);
        objItem.price = this.objCurrentItem.price;
        objItem.type = "";
        if (this.objCurrentItem.type.length > 0) {
            for (let i = 0; i < this.objCurrentItem.type.length; i++) {
                let iTagIndex = parseInt(this.objCurrentItem.type[i], 10);
                objItem.type += global.arrItemTypeData[iTagIndex].id.toString();
                if (i < this.objCurrentItem.type.length - 1)
                    objItem.type += ",";
            }
        }
        objItem.options = this.objCurrentItem.options;
        objItem.description = this.objCurrentItem.description;
        objItem.logo = this.objCurrentItem.logo;
        objItem.image = this.objCurrentItem.image;
        objItem.status = this.objCurrentItem.status;
        objItem.cost = this.objCurrentItem.cost;
        objItem.length = this.objCurrentItem.length;
        objItem.width = this.objCurrentItem.width;
        objItem.height = this.objCurrentItem.height;
        // objItem.express_fee = this.objCurrentItem.express_fee;
        objItem.item_unit = this.objCurrentItem.item_unit;
        objItem.retail_price = this.objCurrentItem.retail_price;
        objItem.discount = this.objCurrentItem.discount;
        if (this.objCurrentItem.inventory !== null && this.objCurrentItem.inventory !== undefined)
            objItem.inventory = this.objCurrentItem.inventory.trim();
        else
            objItem.inventory = '0';
        if (this.objCurrentItem.sale !== null && this.objCurrentItem.sale !== undefined)
            objItem.sale = this.objCurrentItem.sale.trim();
        else
            objItem.inventory = '0';
        // 新建商品
        if (this.state.iDlgType === 0) {
            message.loading({ content: '正在生成新商品：<' + objItem.name + '>', key: g_strMessageKey });
            objItem.hot = 0; objItem.view = 0; objItem.sale = '0'; objItem.in_cart = '0';
            this.strCurrentTypeFilter = '%';
            axios.post('/NewItem', { item: objItem, type: this.strCurrentTypeFilter })
                .then(({ data }) => {
                    // DataStatistic
                    UserActiveUpdate(4, {
                        active: "商家后台: 商品管理操作",
                        action: "新建", name: objItem.name
                    });
                    message.success({ content: '新商品：<' + objItem.name + "> 创建成功！", key: g_strMessageKey, duration: 2 });
                    // 重新返回当前商家的所有商品信息
                    this.RefreshItemTable(data);
                    this.setState({
                        ...this.state,
                        btnControlDisable: [false, true, true],
                        selectedRowKeys: [],
                        bDlgShow: false
                    });
                });
        }
        // 编辑商品
        if (this.state.iDlgType === 1) {
            message.loading({
                content: '正在更新商品：<' + objItem.name + '> 的相关信息',
                key: g_strMessageKey
            });
            objItem.id = this.objCurrentItem.id;
            axios.post('/EditItem', { item: objItem, type: this.strCurrentTypeFilter })
                .then(({ data }) => {
                    // DataStatistic
                    UserActiveUpdate(4, {
                        active: "商家后台: 商品管理操作",
                        action: "编辑", name: objItem.name
                    });
                    message.success({ content: '商品：<' + objItem.name + "> 信息已更新！", key: g_strMessageKey, duration: 2 });
                    // 重新返回当前商家的所有商品信息
                    this.RefreshItemTable(data);
                    this.setState({
                        ...this.state,
                        bDlgShow: false
                    });
                });
        }
    }
    // 点击确认键
    ConfirmButtonClick = (e) => {
        // 删除商品
        if (this.state.iDlgType === 2) {
            message.loading({ content: '删除商品中……', key: g_strMessageKey });
            axios.post('/DeleteItems', { arrDeleteItems: this.arrDeleteID, store_id: this.props.objStoreInfo.id, type: this.strCurrentTypeFilter })
                .then(({ data }) => {
                    let strItemID = "";
                    for (let i = 0; i < this.arrDeleteID.length; i++) {
                        if (i === this.arrDeleteID.length - 1)
                            strItemID += this.arrDeleteID[i].toString();
                        else
                            strItemID += this.arrDeleteID[i].toString() + ",";
                    }
                    // DataStatistic
                    UserActiveUpdate(4, {
                        active: "商家后台: 商品管理操作",
                        action: "删除", delete: strItemID
                    });
                    message.success({ content: '删除完成！', key: g_strMessageKey, duration: 2 });
                    // 重新返回当前商家的所有商品信息
                    this.RefreshItemTable(data);
                    this.setState({
                        ...this.state,
                        btnCopyPasteDisable: [true, true],
                        btnControlDisable: [false, true, true],
                        selectedRowKeys: [],
                        bDlgShow: false
                    });
                });
        }
        // 更新团购信息
        if (this.state.iDlgType === 3) {
            let strPrice = "";
            let strNum = "";
            if (this.refs.cut_price_num) {
                strPrice = this.refs.cut_price_value.state.value;
                strNum = this.refs.cut_price_num.state.value;
            }
            if (strPrice === "" || strNum === "") {
                message.warning("团购人数或价位不是有效值！");
                return;
            }
            let objCutPrice = {
                item: this.objCurrentItem.id,
                store: this.props.objStoreInfo.id,
                step: 0,
                price: strPrice,
                num: strNum,
                expire_date: this.state.expire_date
            }
            console.log("Update Cut Price", objCutPrice);
            axios.post('/UpdateCutPrice', { objCutPrice: objCutPrice })
                .then(({ data }) => {
                    console.log(data);
                    this.GetCutPriceInfo();
                    this.setState({
                        ...this.state,
                        bDlgShow: false
                    });
                });
        }
        // 新建/编辑商品信息
        if (this.state.iDlgType === 0 || this.state.iDlgType === 1) {
            this.objCurrentItem.name = this.refs.item_name.state.value;
            if (this.objCurrentItem.name === "") {
                message.warning('商品名称不能为空！');
                return;
            }
            this.objCurrentItem.item_order = this.refs.item_order.state.value;
            if (this.objCurrentItem.item_order === "" || this.objCurrentItem.item_order === null) {
                this.objCurrentItem.item_order = 0;
            }
            this.objCurrentItem.price = this.refs.item_price.state.value;
            this.objCurrentItem.cost = this.refs.item_cost.state.value;
            this.objCurrentItem.sku = this.refs.item_sku.state.value;
            this.objCurrentItem.length = this.refs.item_length.state.value;
            this.objCurrentItem.width = this.refs.item_width.state.value;
            this.objCurrentItem.height = this.refs.item_height.state.value;
            // this.objCurrentItem.express_fee = this.refs.item_express_fee.state.value;
            this.objCurrentItem.discount = this.refs.item_discount.state.value;
            this.objCurrentItem.express_fee = "";
            this.objCurrentItem.item_unit = this.refs.item_unit.state.value;
            if (this.refs.item_inventory.state.value === "" || this.refs.item_inventory.state.value === null) {
                this.objCurrentItem.inventory = '0';
            } else {
                this.objCurrentItem.inventory = this.refs.item_inventory.state.value;
            }
            if (this.refs.item_sale.state.value === "" || this.refs.item_sale.state.value === null) {
                this.objCurrentItem.sale = '0';
            } else {
                this.objCurrentItem.sale = this.refs.item_sale.state.value;
            }
            if (this.refs.item_retail_price.state.value === "" || this.refs.item_retail_price.state.value === null) {
                this.objCurrentItem.retail_price = 0;
            } else {
                this.objCurrentItem.retail_price = this.refs.item_retail_price.state.value;
            }
            this.objCurrentItem.options = this.refs.item_comments.state.value;
            this.objCurrentItem.description = this.refs.item_description.state.value;
            if (this.state.arrSelectedTypes.length <= 0) {
                message.warning('商品/服务的类别/标签不能为空！');
                return;
            }
            this.objCurrentItem.type = this.state.arrSelectedTypes;
            this.objCurrentItem.status = this.state.iCurrentItemStatus;

            // 如果没有本地新上传的图标和图片
            if (!this.bImgLocalUpload && !this.bLogoLocalUpload) {
                if (this.bImgLinkChange) {
                    this.bImgLinkChange = false;
                }
                if (this.bLogoLinkChange) {
                    this.bLogoLinkChange = false;
                }
                this.UpdateItemInfo();
            } else {
                // 如果有本地新上传的图标
                if (this.bLogoLocalUpload) {
                    this.bLogoLocalUpload = false;
                    this.bLogoLinkChange = false;
                    let strLogoName = "Item_Logo_" + this.objCurrentItem.name;
                    // 上传到云端
                    message.loading({ content: '正在上传商品的图标……', key: g_strMessageKey });
                    axios.post('/UploadImageToCloudinary', {
                        storeName: this.props.objStoreInfo.id.toString() + "-" + this.props.objStoreInfo.store_name,
                        imgName: strLogoName,
                        imgUrl: this.state.strLogoUrl
                    }).then((response) => {
                        message.success({
                            content: '商品图标上传完成！',
                            key: g_strMessageKey, duration: 2
                        });
                        this.refs.item_logo.state.value = response.data;
                        // 如果有本地新上传的图片
                        if (this.bImgLocalUpload) {
                            this.bImgLocalUpload = false;
                            this.bImgLinkChange = false;
                            let strImgName = "Item_Image_" + this.objCurrentItem.name;
                            // 上传到云端
                            message.loading({ content: '正在上传商品的图片……', key: g_strMessageKey });
                            axios.post('/UploadImageToCloudinary', {
                                storeName: this.props.objStoreInfo.id.toString() + "-" + this.props.objStoreInfo.store_name,
                                imgName: strImgName,
                                imgUrl: this.state.strImgUrl
                            }).then((response) => {
                                message.success({
                                    content: '商品图片上传完成！',
                                    key: g_strMessageKey, duration: 2
                                });
                                this.refs.item_image.state.value = response.data;
                                this.UpdateItemInfo();
                            });
                        } else {
                            this.UpdateItemInfo();
                        }
                    });
                } else {
                    // 如果有本地新上传的图片
                    if (this.bImgLocalUpload) {
                        this.bImgLocalUpload = false;
                        this.bImgLinkChange = false;
                        let strImgName = "Item_Image_" + this.objCurrentItem.name;
                        // 上传到云端
                        message.loading({ content: '正在上传商品的图片……' });
                        axios.post('/UploadImageToCloudinary', {
                            storeName: this.props.objStoreInfo.id.toString() + "-" + this.props.objStoreInfo.store_name,
                            imgName: strImgName,
                            imgUrl: this.state.strImgUrl
                        }).then((response) => {
                            message.success({
                                content: '商品图片上传完成！',
                                key: g_strMessageKey, duration: 2
                            });
                            this.refs.item_image.state.value = response.data;
                            this.UpdateItemInfo();
                        });
                    }
                }
            }
        }
        // 批量编辑商品信息
        if (this.state.iDlgType === 4) {

            let bMultiEdit = false;
            for (let i = 0; i < this.state.arrMultiEdit.length; i++) {
                if (this.state.arrMultiEdit[i]) {
                    bMultiEdit = true; break;
                }
            }
            if (!bMultiEdit) {
                message.warning("请至少选中一项需要批量编辑的信息！");
                return;
            }

            for (let i = 0; i < this.arrSelectItems.length; i++) {
                let objItem = {
                    id: this.arrSelectItems[i].id,
                    // status: this.arrSelectItems[i].status,
                    // inventory: this.arrSelectItems[i].inventory,
                    // type: this.arrSelectItems[i].type,
                    store_id: this.props.objStoreInfo.id
                }
                console.log("this.state.arrMultiEdit : ", this.state.arrMultiEdit);
                if (this.state.arrMultiEdit[0]) {
                    this.arrSelectItems[i].status = this.state.iCurrentItemStatus;
                    objItem.status = this.state.iCurrentItemStatus;
                }
                if (this.state.arrMultiEdit[1]) {
                    if (this.refs.item_inventory.state.value === "" || this.refs.item_inventory.state.value === null) {
                        this.arrSelectItems[i].inventory = '0';
                    } else {
                        this.arrSelectItems[i].inventory = this.refs.item_inventory.state.value;
                    }
                    objItem.inventory = this.arrSelectItems[i].inventory;
                }
                if (this.state.arrMultiEdit[2]) {
                    if (this.state.arrSelectedTypes.length <= 0) {
                        message.warning('商品/服务的类别/标签不能为空！');
                        return;
                    }
                    this.arrSelectItems[i].type = this.state.arrSelectedTypes;
                    objItem.type = "";
                    if (this.arrSelectItems[i].type.length > 0) {
                        for (let j = 0; j < this.arrSelectItems[i].type.length; j++) {
                            let iTagIndex = parseInt(this.arrSelectItems[i].type[j], 10);
                            objItem.type += global.arrItemTypeData[iTagIndex].id.toString();
                            if (j < this.arrSelectItems[i].type.length - 1)
                                objItem.type += ",";
                        }
                    }
                    console.log(objItem.type);
                }
                if (this.state.arrMultiEdit[3]) {
                    if (this.refs.item_discount.state.value === "" || this.refs.item_discount.state.value === null) {
                        this.arrSelectItems[i].discount = '0';
                    } else {
                        this.arrSelectItems[i].discount = this.refs.item_discount.state.value;
                    }
                    objItem.discount = this.arrSelectItems[i].discount;
                }
                console.log("item " + i + " : ", objItem);
                message.loading({ content: '正在批量更新商品的相关信息', key: g_strMessageKey });
                axios.post('/EditItem', { item: objItem, type: this.strCurrentTypeFilter })
                    .then(({ data }) => {
                        if (i >= this.arrSelectItems.length - 1) {
                            message.success({ content: '商品信息已更新！', key: g_strMessageKey, duration: 2 });
                            // 重新返回当前商家的所有商品信息
                            this.RefreshItemTable(data);
                            this.setState({
                                ...this.state,
                                bDlgShow: false,
                                bMultiDlgShow: false,
                                selectedRowKeys: [],
                                arrMultiEdit: [false, false, false, false],
                                arrSelectedTypes: []
                            });
                        }
                    });
            }
        }
    };
    // 点击取消键
    CancelButtonClick = (e) => {
        this.setState({
            ...this.state,
            bDlgShow: false,
            bMultiDlgShow: false,
            selectedRowKeys: [],
            arrMultiEdit: [false, false, false, false],
            arrSelectedTypes: []
        });
    };
    // 类别选择监控
    TagSelectChange = (arrTagIndex) => {
        this.setState({
            ...this.state,
            arrSelectedTypes: arrTagIndex
        });
    }
    // 图标链接输入框有变化时
    LogoInputChange = (e) => {
        if (!this.bLogoLinkChange) {
            this.bLogoLinkChange = true;
        }
    }
    // 图片链接输入框有变化时
    ImageInputChange = (e) => {
        if (!this.bImgLinkChange) {
            this.bImgLinkChange = true;
        }
    }
    // 本地上传完毕后的显示（只在上传按钮处显示本地图片）
    AfterLocalUpload = (bLogo, strImgUrl) => {
        if (bLogo) {
            this.bLogoLocalUpload = true;
            // 此时的imgUrl只是base64格式的字符串，不是网络地址
            this.setState({
                ...this.state,
                strLogoUrl: strImgUrl,
                bLogoLoading: false
            });
        } else {
            this.bImgLocalUpload = true;
            // 此时的imgUrl只是base64格式的字符串，不是网络地址
            this.setState({
                ...this.state,
                strImgUrl: strImgUrl,
                bImgLoading: false
            });
        }
    }
    // 从本地上传图片，转化为Base64格式的状态监控
    UploadToBase64 = (bLogo, info) => {
        if (bLogo) {
            if (info.file.status === 'uploading') {
                if (!this.state.bLogoLoading)
                    message.loading({ content: '本地图片加载中……', key: g_strMessageKey });
                this.setState({
                    ...this.state,
                    bLogoLoading: true
                });
                return;
            }
        } else {
            if (info.file.status === 'uploading') {
                if (!this.state.bImgLoading)
                    message.loading({ content: '本地图片加载中……', key: g_strMessageKey });
                this.setState({
                    ...this.state,
                    bImgLoading: true
                });
                return;
            }
        }
        if (info.file.status === 'done') {
            message.success({ content: '图片加载完成！', key: g_strMessageKey, duration: 2 });
            // 获取Base64格式的链接（字符串极长，无法直接存储到DB）
            getBase64(info.file.originFileObj, this.AfterLocalUpload.bind(this, bLogo));
        }
    };
    // 刷新商品列表
    RefreshItemTable = (arrItemData) => {
        this.arrTableRow = [];
        if (arrItemData.length > 0) {
            for (let i = 0; i < arrItemData.length; i++) {
                let objItem = {};
                objItem.key = i
                objItem.id = arrItemData[i].id
                objItem.name = arrItemData[i].name;
                objItem.item_order = arrItemData[i].item_order;
                objItem.price = arrItemData[i].price;
                objItem.logo_str = arrItemData[i].logo;
                objItem.image_str = arrItemData[i].image;
                objItem.logo = arrItemData[i].logo;
                objItem.image = arrItemData[i].image;
                let uiItemLogo = (
                    <img className="store_item_logo" src={arrItemData[i].logo} alt='' />
                );
                objItem.logo = uiItemLogo;
                let uiItemImage = (
                    <img className="store_item_image" src={arrItemData[i].image} alt='' />
                );
                objItem.image = uiItemImage;
                objItem.options = arrItemData[i].options;
                objItem.description = arrItemData[i].description;
                objItem.type = arrItemData[i].type.split(',');
                let strType = "";
                let uiTagColumn = (
                    <div className="store_item_tag_column">
                        {objItem.type.map((tag, index) => {
                            let strTagName = this.TagIdToName(parseInt(tag, 10));
                            strType += strTagName;
                            if (index < objItem.type.length - 1)
                                strType += ",";
                            return (
                                <Tag className="store_item_tag_item" color="blue" key={index}>{strTagName}</Tag>
                            );
                        })}
                    </div>
                );
                objItem.type_str = strType;
                objItem.type = uiTagColumn;
                objItem.status = arrItemData[i].status;
                objItem.status_str = this.arrItemStatus[arrItemData[i].status];
                objItem.cost = arrItemData[i].cost;
                objItem.sku = arrItemData[i].sku;
                objItem.length = arrItemData[i].length;
                objItem.width = arrItemData[i].width;
                objItem.height = arrItemData[i].height;
                // objItem.express_fee = arrItemData[i].express_fee;
                objItem.item_unit = arrItemData[i].item_unit;
                objItem.inventory = arrItemData[i].inventory;
                if (arrItemData[i].inventory !== null)
                    objItem.inventory = arrItemData[i].inventory.trim();
                else
                    objItem.inventory = '0';
                if (arrItemData[i].sale !== null && arrItemData[i].sale !== "" && typeof (arrItemData[i].sale) === 'string')
                    objItem.sale = arrItemData[i].sale.trim();
                else
                    objItem.sale = '0';
                if (arrItemData[i].in_cart !== null && arrItemData[i].sale !== "")
                    objItem.in_cart = arrItemData[i].in_cart;
                else
                    objItem.in_cart = 0;
                if (arrItemData[i].view !== null)
                    objItem.view = arrItemData[i].view;
                else
                    objItem.view = 0;
                objItem.retail_price = arrItemData[i].retail_price;
                objItem.discount = arrItemData[i].discount;
                this.arrTableRow.push(objItem);
            }
        }
        if (this.state.iDlgType === 0 && this.state.selectedRowKeys.length > 0) {
            this.setState({
                ...this.state,
                btnControlDisable: [false, true, true],
                btnCopyPasteDisable: [true, true],
                selectedRowKeys: [],
                objFilter: {
                    strKeyword: "",
                    iCurrentItemType: 0,
                    iCurrentSortType: 0,
                },
                bLoading: false,
                bDlgShow: false,
                bMultiDlgShow: false
            });
        } else {
            this.setState({
                ...this.state,
                btnControlDisable: [false, true, true],
                btnCopyPasteDisable: [true, true],
                selectedRowKeys: [],
                bLoading: false,
                bDlgShow: false,
                bMultiDlgShow: false
            });
        }
    }
    // 根据ID获取Tag的名称
    TagIdToName = (iTag) => {
        let strTagName = "";
        if (global.arrItemTypeData.length > 0) {
            for (let i = 0; i < global.arrItemTypeData.length; i++) {
                if (iTag === global.arrItemTypeData[i].id) {
                    strTagName = global.arrItemTypeData[i].type_name;
                    break;
                }
            }
        }
        return strTagName;
    }
    // 根据Tag的名称获取Tag的Index
    TagNameToIndex = (strTag) => {
        let iTagIndex = 0;
        if (global.arrItemTypeData.length > 0) {
            for (let i = 0; i < global.arrItemTypeData.length; i++) {
                if (strTag === global.arrItemTypeData[i].type_name) {
                    iTagIndex = i;
                    break;
                }
            }
        }
        return iTagIndex;
    }
    // 过滤商品列表
    FilterItems = () => {
        if (this.state.objFilter.iCurrentItemType === this.iCurrentTypeFilter)
            return;
        this.iCurrentTypeFilter = this.state.objFilter.iCurrentItemType;
        this.strCurrentTypeFilter = '%';
        if (this.iCurrentTypeFilter > 0 && global.arrItemTypeData.length > 0)
            this.strCurrentTypeFilter = '%' + global.arrItemTypeData[this.iCurrentTypeFilter - 1].id.toString() + '%';
        this.GetStoreItems();
    }
    // 获取所有商品信息
    GetStoreItems = () => {
        message.loading({ content: '数据加载中……', key: g_strMessageKey });
        axios('/GetAllItems', { params: { store_id: this.props.objStoreInfo.id, type: this.strCurrentTypeFilter } }).then(
            ({ data }) => {
                message.success({ content: "数据加载完成！", key: g_strMessageKey, duration: 2 });
                this.RefreshItemTable(data);
            });
    }
    // 关键词处理
    KeywordsProcess = (strKeywords) => {
        if (parseInt(strKeywords, 10) % 1 === 0)
            return parseInt(strKeywords, 10);
        let newKeywords = "";
        for (let i = 0; i < strKeywords.length; i++) {
            if (strKeywords[i] !== " ") {
                if (i === strKeywords.length - 1)
                    newKeywords += strKeywords[i];
                else
                    newKeywords += strKeywords[i] + "%";
            }
        }
        return newKeywords;
    }
    // 搜索商品
    StoreItemSearch = () => {
        let strKeywords = this.refs.store_item_input.state.value;
        if (strKeywords === undefined)
            strKeywords = "";
        if (strKeywords.trim() === "") {
            this.GetStoreItems();
        } else {
            this.setState({
                ...this.state,
                selectedRowKeys: [],
                btnControlDisable: [false, true, true],  // 操作按钮控制
                btnCopyPasteDisable: [true, true],  // 赋值粘贴按钮
            });
            axios('/SearchItems', { params: { name: this.KeywordsProcess(strKeywords), store_id: this.props.objStoreInfo.id, type: this.strCurrentTypeFilter } })
                .then(({ data }) => {
                    this.RefreshItemTable(data);
                }).catch(function (error) { console.log(error); });
        }
    }
    // 获取团购信息
    GetCutPriceInfo = () => {
        axios('/GetCutPrice', {
            params: { store: this.props.objStoreInfo.id }
        }).then(({ data }) => {
            this.arrCutPriceDate = data;
        }).catch(function (error) { console.log(error); });
    }
    // 团购进度条设置
    CutPriceStepChange = current => {
        if (this.refs.cut_price_num) {
            this.refs.cut_price_num.state.value = this.arrCutPriceSteps[current].num;
            this.refs.cut_price_value.state.value = this.arrCutPriceSteps[current].price;
        }
        this.setState({
            ...this.state,
            iCurrentCutPriceStep: current
        });
    };
    // 团购截止日期更改
    ExpireDateChange = (date, dateString) => {
        this.setState({
            ...this.state,
            expire_date: dateString
        });
    }
    // 商品类别下拉菜单
    DlgItemTypeClick = (index) => {
        this.setState({
            ...this.state,
            iCurrentItemStatus: index
        });
    }
    // 上传商品列表
    UploadItemList = (file) => {
        // 获取上传的文件对象
        const { files } = file.target;
        // 通过FileReader对象读取文件
        const fileReader = new FileReader();
        console.log("upload file : ", files);
        this.setState({
            ...this.state,
            bLoading: true
        });
        fileReader.onload = event => {
            message.loading({ content: '正在上传商品列表文件……', key: g_strMessageKey });
            try {
                const { result } = event.target;
                // 以二进制流方式读取得到整份excel表格对象
                const workbook = XLSX.read(result, { type: 'binary' });
                // 存储获取到的数据
                let data = [];
                // 遍历每张工作表进行读取（这里默认只读取第一张表）
                for (const sheet in workbook.Sheets) {
                    // esline-disable-next-line
                    if (workbook.Sheets.hasOwnProperty(sheet)) {
                        // 利用 sheet_to_json 方法将 excel 转成 json 数据
                        data = data.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
                        // break; // 如果只取第一张表，就取消注释这行
                    }
                }
                // 最终获取到并且格式化后的 json 数据
                message.success({ content: "数据上传完成！", key: g_strMessageKey, duration: 2 });
                if (data.length > 0) {
                    this.ProcessItemData(data);
                } else {
                    message.warning("文件中的数据为空，请重新选择！")
                }
                if (this.refs.file_upload) {
                    document.getElementById("file_upload").value = "";
                }
            } catch (e) {
                console.log("upload error : ", e);
                // 这里可以抛出文件类型错误不正确的相关提示
                message.error('文件类型不正确！');
            }
        };
        // 以二进制方式打开文件
        fileReader.readAsBinaryString(files[0]);
    }
    // 逐一处理HSCode数据
    ProcessItemData = (data) => {
        console.log("ProcessItemData : ", data);
        if (data.length <= 0) {
            message.warning({ content: '未找到有效的商品信息，请检查上传的文件！', key: g_strMessageKey, duration: 2 });
            this.setState({
                ...this.state,
                bLoading: false
            });
            return;
        }
        let dataExcel = [];
        let dataExcelEdit = [];
        // 检查重复项
        for (let i = 0; i < data.length; i++) {
            let bRepeat = false;
            for (let j = 0; j < this.arrTableRow.length; j++) {
                if (data[i].Name === this.arrTableRow[j].name && data[i].Type === this.arrTableRow[j].type_str) {
                    bRepeat = true;
                    break;
                }
            }
            if (!bRepeat)
                dataExcel.push(data[i]);
            else
                dataExcelEdit.push(data[i]);
        }
        message.loading({ content: '正在录入/更新商品信息……', key: g_strMessageKey });
        // 录入新的商品
        if (dataExcel.length > 0) {
            for (let i = 0; i < dataExcel.length; i++) {
                let objItem = {
                    description: "", express_fee: "",
                    hot: 0,
                    image: "", logo: "",
                    item_order: -1,
                    name: "", options: "", price: "0.00",
                    sale: 0, status: 0,
                    store_id: this.props.objStoreInfo.id,
                    type: -1, type_order: -1, view: 0,
                    inventory: "0",
                    item_unit: "0",
                    cost: "0",
                    sku: "0",
                    length: "0",
                    width: "0",
                    height: "0",
                    discount: '0'
                }
                objItem.name = dataExcel[i].Name;
                objItem.item_order = dataExcel[i].Order;
                objItem.price = dataExcel[i].Price;
                if (global.arrItemTypeData.length > 0)
                    for (let j = 0; j < global.arrItemTypeData.length; j++) {
                        let iIndex = global.arrItemTypeData[j].type_name.indexOf(dataExcel[i].Type);
                        if (iIndex >= 0) {
                            objItem.type = global.arrItemTypeData[j].id;
                            break;
                        }
                        else
                            objItem.type = "";
                    }
                objItem.options = dataExcel[i].Options;
                if (dataExcel[i].Description !== undefined && dataExcel[i].Description !== null && dataExcel[i].Description !== "")
                    objItem.description = dataExcel[i].Description;
                else
                    objItem.description = "";
                objItem.image = dataExcel[i].Image;
                objItem.logo = dataExcel[i].Logo;
                objItem.inventory = dataExcel[i].Inventory;
                objItem.item_unit = dataExcel[i].Weight;
                objItem.cost = dataExcel[i].Cost;
                objItem.sku = dataExcel[i].Sku;
                objItem.length = dataExcel[i].Length;
                objItem.width = dataExcel[i].Width;
                objItem.height = dataExcel[i].Height;
                objItem.discount = dataExcel[i].Discount;
                if (objItem.image === null || objItem.image === undefined)
                    objItem.image = "";
                if (objItem.logo === null || objItem.image === undefined)
                    objItem.logo = "";
                console.log("New Item " + i + " : ", objItem);
                axios.post('/NewItem', { item: objItem, type: this.strCurrentTypeFilter })
                    .then(({ data }) => {
                        if (i >= dataExcel.length - 1) {
                            message.success({ content: '批量录入商品完成！', key: g_strMessageKey, duration: 2 });
                            this.RefreshItemTable(data);
                            this.setState({
                                ...this.state,
                                bLoading: false,
                                btnControlDisable: [false, true, true],
                                btnCopyPasteDisable: [true, true],
                                selectedRowKeys: []
                            });
                        } else {
                            this.setState({
                                ...this.state,
                                bLoading: false,
                                btnControlDisable: [false, true, true],
                                btnCopyPasteDisable: [true, true],
                                selectedRowKeys: []
                            });
                        }
                    });
            }
        }
        // 更新已有商品信息
        if (dataExcelEdit.length > 0) {
            for (let i = 0; i < dataExcelEdit.length; i++) {
                let objItem = {
                    description: "",
                    image: "", logo: "",
                    item_order: -1,
                    name: "", options: "", price: "0.00",
                    store_id: this.props.objStoreInfo.id,
                    type: -1,
                    inventory: "0",
                    item_unit: "0",
                    cost: "0",
                    sku: "0",
                    length: "0",
                    width: "0",
                    height: "0",
                    discount: '0'
                }
                objItem.name = dataExcelEdit[i].Name;
                objItem.item_order = dataExcelEdit[i].Order;
                objItem.price = dataExcelEdit[i].Price;
                if (global.arrItemTypeData.length > 0)
                    for (let j = 0; j < global.arrItemTypeData.length; j++) {
                        let iIndex = global.arrItemTypeData[j].type_name.indexOf(dataExcelEdit[i].Type);
                        if (iIndex >= 0) {
                            objItem.type = global.arrItemTypeData[j].id;
                            break;
                        }
                        else
                            objItem.type = "";
                    }
                objItem.options = dataExcelEdit[i].Options;
                if (dataExcelEdit[i].Description !== undefined && dataExcelEdit[i].Description !== null && dataExcelEdit[i].Description !== "")
                    objItem.description = dataExcelEdit[i].Description;
                else
                    objItem.description = "";
                objItem.image = dataExcelEdit[i].Image;
                objItem.logo = dataExcelEdit[i].Logo;
                objItem.inventory = dataExcelEdit[i].Inventory;
                objItem.item_unit = dataExcelEdit[i].Weight;
                objItem.cost = dataExcelEdit[i].Cost;
                objItem.sku = dataExcelEdit[i].Sku;
                objItem.length = dataExcelEdit[i].Length;
                objItem.width = dataExcelEdit[i].Width;
                objItem.height = dataExcelEdit[i].Height;
                objItem.discount = dataExcelEdit[i].Discount;
                if (objItem.image === null || objItem.image === undefined)
                    objItem.image = "";
                if (objItem.logo === null || objItem.image === undefined)
                    objItem.logo = "";
                console.log("Edit Item " + i + " : ", objItem);
                axios.post('/EditItem', { item: objItem, type: this.strCurrentTypeFilter })
                    .then(({ data }) => {
                        if (i >= dataExcelEdit.length - 1) {
                            message.success({ content: '批量更新商品信息完成！', key: g_strMessageKey, duration: 2 });
                            this.RefreshItemTable(data);
                            this.setState({
                                ...this.state,
                                bLoading: false,
                                btnControlDisable: [false, true, true],
                                btnCopyPasteDisable: [true, true],
                                selectedRowKeys: []
                            });
                        } else {
                            this.setState({
                                ...this.state,
                                bLoading: false,
                                btnControlDisable: [false, true, true],
                                btnCopyPasteDisable: [true, true],
                                selectedRowKeys: []
                            });
                        }
                    });
            }
        }
        if (dataExcel.length <= 0 && dataExcelEdit.length <= 0) {
            message.warning({ content: '无需要录入/更新的商品信息！', key: g_strMessageKey, duration: 2 });
            this.setState({
                ...this.state,
                bLoading: false
            });
        }
    }
    // 点击了导出Excel文档按钮
    ClickExportButton = () => {
        message.loading({ content: '数据处理中……', key: g_strMessageKey });
        this.ExportExcelFile();
    }
    // 导出Excel文档
    ExportExcelFile = () => {
        let arrExportData = [['Order', 'Name', 'Type', 'Price', 'Options', 'Descriptions', 'Image', 'Logo',
            'Inventory', 'Weight', 'Cost', 'Discount', 'Sku', 'Length', 'Width', 'Height']];
        console.log("ExportExcelFile : ", this.arrTableRow);

        for (let i = 0; i < this.arrTableRow.length; i++) {
            let arrData = [];
            arrData.push(this.arrTableRow[i].item_order);
            arrData.push(this.arrTableRow[i].name);
            arrData.push(this.arrTableRow[i].type_str);
            arrData.push(this.arrTableRow[i].price);
            arrData.push(this.arrTableRow[i].options);
            arrData.push(this.arrTableRow[i].description);
            arrData.push(this.arrTableRow[i].image_str);
            arrData.push(this.arrTableRow[i].logo_str);
            arrData.push(this.arrTableRow[i].inventory);
            arrData.push(this.arrTableRow[i].item_unit);
            arrData.push(this.arrTableRow[i].cost);
            arrData.push(this.arrTableRow[i].discount);
            arrData.push(this.arrTableRow[i].sku);
            arrData.push(this.arrTableRow[i].length);
            arrData.push(this.arrTableRow[i].width);
            arrData.push(this.arrTableRow[i].height);
            arrExportData.push(arrData);
        }
        console.log("export data : ", arrExportData);
        message.success({ content: "数据处理完成！", key: g_strMessageKey, duration: 2 });
        var sheet = XLSX.utils.aoa_to_sheet(arrExportData);
        openDownloadDialog(sheet2blob(sheet), '商品列表.xlsx');
    }
    // 显示全部商品
    ShowAllItems = () => {
        if (this.refs.store_item_input !== undefined)
            this.refs.store_item_input.state.value = "";
        this.GetStoreItems();
    }
    // 批量编辑勾选框
    MultiEditCheckboxChange = (index, e) => {
        console.log(index, e.target.checked);
        let arrMultiEdit = this.state.arrMultiEdit;
        arrMultiEdit[index] = e.target.checked;

        this.setState({
            ...this.state,
            arrMultiEdit: arrMultiEdit
        });
    }

    // 卸载异步操作设置状态
    componentWillUnmount() {
        clearTimeout(this.timeouter)
        this.setState = (state, callback) => {
            return;
        }
    }
    // 渲染完毕……
    componentDidMount() {
        this.GetStoreItems();
        this.GetCutPriceInfo();
        this.strDefaultCutPriceNum = this.arrCutPriceSteps[0].num;
        this.strDefaultCutPriceValue = this.arrCutPriceSteps[0].price;
    }

    render() {
        // 顶部栏主分类下拉菜单
        const uiItemTypeMenu = (
            <Menu>
                {this.arrItemTypes.map((item, index) => {
                    return (
                        <Menu.Item key={index} onClick={this.ItemTypeFilterClicked.bind(this, index)} >
                            {item}
                        </Menu.Item>
                    );
                })}
            </Menu>
        );
        // 新建按钮的样式控制
        let strNewButtonStyle = { backgroundColor: 'lightseagreen', color: 'white' };
        let strCopyButtonStyle = { backgroundColor: '#20b2aa', color: 'white', oppacity: '1' };
        if (this.state.btnCopyPasteDisable[0])
            strCopyButtonStyle = { backgroundColor: 'white', color: 'gray', oppacity: '0.5' };
        // 主体表格相关
        const { selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectedRowKeysChange,
            columnWidth: 30
        };

        // 上传图标按钮
        let uiUploadLogoButton = (
            <div>
                <Icon type={this.state.bLogoLoading ? 'loading' : 'plus'} />
                <div className="store_image_upload_text">上传</div>
            </div>
        );
        // 上传图片按钮
        let uiUploadImageButton = (
            <div>
                <Icon type={this.state.bImgLoading ? 'loading' : 'plus'} />
                <div className="store_image_upload_text">上传</div>
            </div>
        );
        // 对话框
        let strDlgTitle = "";
        let objItem = {};
        let strLogoUrl = this.state.strLogoUrl;
        let strImageUrl = this.state.strImgUrl;

        if (this.state.iDlgType === 0) {
            strDlgTitle = "新增商品/服务";
            objItem = {
                name: '', price: '', type: [], options: '', description: '', image: '', logo: '', item_order: 0,
                cost: '0', sku: '', length: '0', width: '0', height: '0'
            };
        }
        if (this.state.iDlgType === 1) {
            strDlgTitle = "编辑商品/服务";
            objItem = this.objCurrentItem;
            strLogoUrl = objItem.logo;
            strImageUrl = objItem.image;
        }
        if (this.state.iDlgType === 3) {
            strDlgTitle = "设置团购商品：" + this.objCurrentItem.name + ", 原价：$" + this.objCurrentItem.price;
        }
        const dateFormat = 'YYYY/MM/DD';
        // 对话框内容
        let uiDlg = null;
        let uiMultiDlg = null;
        let uiCutPriceDlg = null;
        // 新建/编辑
        if (this.state.iDlgType <= 1) {
            // 商品状态下拉菜单
            const uiItemStatus = (
                <Menu>
                    {this.arrItemStatus.map((item, index) => {
                        return (
                            <Menu.Item key={index} onClick={this.DlgItemTypeClick.bind(this, index)}>
                                {item}
                            </Menu.Item>
                        );
                    })}
                </Menu>
            );
            uiDlg = (
                <Modal title={strDlgTitle} visible={this.state.bDlgShow} okText="确定" cancelText="取消" width={750}
                    onOk={this.ConfirmButtonClick} onCancel={this.CancelButtonClick} >
                    <div className="item_info_row">
                        <div className="item_info_title">名称:</div><Input className="item_info_input_long" ref="item_name" placeholder="　必填……"
                            defaultValue={objItem.name} />
                    </div>
                    <div className="item_info_row">
                        <div className="item_info_title">价格:</div><Input prefix="$" suffix="CAD" className="item_info_input item_info_input_nopadding"
                            ref="item_price" defaultValue={objItem.price} />
                        <div className="item_info_title">成本:</div><Input prefix="$" suffix="CAD" className="item_info_input item_info_input_nopadding"
                            ref="item_cost" defaultValue={objItem.cost} />
                        <div className="item_info_title">折扣:</div><Input suffix="%" className="item_info_input item_info_input_nopadding"
                            ref="item_discount" defaultValue={objItem.discount} />
                    </div>
                    <div className="item_info_row">
                        <div className="item_info_title">库存:</div><Input className="item_info_input" ref="item_inventory"
                            defaultValue={objItem.inventory} />
                        <div className="item_info_title">销量:</div><Input className="item_info_input" ref="item_sale"
                            defaultValue={objItem.sale} />
                        {/* <div className="item_info_title">热度:</div><Input className="item_info_input" ref="item_hot" disabled
                            defaultValue={objItem.item_hot} /> */}
                        <div className="item_info_title">零售:</div><Input prefix="$" suffix="CAD" className="item_info_input item_info_input_nopadding"
                            ref="item_retail_price" defaultValue={objItem.retail_price} />
                    </div>
                    <div className="item_info_row">
                        {/* <div className="item_info_title">运费:</div><Input prefix="$" suffix="CAD" className="item_info_input item_info_input_nopadding"
                            ref="item_express_fee" defaultValue={objItem.express_fee} /> */}
                        <div className="item_info_title">SKU:</div><Input className="item_info_input_long"
                            ref="item_sku" defaultValue={objItem.sku} />

                    </div>
                    <div className="item_info_row">
                        <div className="item_info_title">长度:</div><Input suffix="cm" className="item_info_input item_info_input_nopadding"
                            ref="item_length" defaultValue={objItem.length} />
                        <div className="item_info_title">宽度:</div><Input suffix="cm" className="item_info_input item_info_input_nopadding"
                            ref="item_width" defaultValue={objItem.width} />
                        <div className="item_info_title">高度:</div><Input suffix="cm" className="item_info_input item_info_input_nopadding"
                            ref="item_height" defaultValue={objItem.height} />
                    </div>
                    <div className="item_info_row">
                        <div className="item_info_title">序号:</div><Input className="item_info_input" ref="item_order" placeholder="　必填……"
                            defaultValue={objItem.item_order} />
                        <div className="item_info_title">状态:</div>
                        <Dropdown className="item_info_input"
                            overlay={uiItemStatus} placement="bottomLeft">
                            <Button>
                                {this.arrItemStatus[this.state.iCurrentItemStatus]} <Icon type="caret-down" />
                            </Button>
                        </Dropdown>
                        <div className="item_info_title">重量:</div><Input suffix="kg" className="item_info_input item_info_input_nopadding"
                            ref="item_unit" defaultValue={objItem.item_unit} />
                    </div>
                    <div className="item_info_row">
                        <div className="item_info_title_up">标注:</div><TextArea className='item_info_input_area' rows={2} size="default"
                            placeholder="" ref="item_comments" defaultValue={objItem.options} />
                    </div>
                    <div className="item_info_row">
                        {/* <div className="item_info_title">描述:</div><Input className="item_info_input_long" ref="item_description" defaultValue={objItem.description} /> */}
                        <div className="item_info_title_up">描述:</div><TextArea className='item_info_input_area' rows={2} size="default"
                            placeholder="" ref="item_description" defaultValue={objItem.description} />
                    </div>
                    <div className="item_info_row">
                        <div className="item_info_title">类别:</div><Select mode="multiple" className="item_info_input_long item_info_input_nopadding"
                            style={{ width: '395px' }} placeholder="可多选，不选则默认为全部" labelInValue={false}
                            onChange={this.TagSelectChange} value={this.state.arrSelectedTypes} >{this.arrItemOptions}</Select>
                    </div>
                    <div className="item_info_row">
                        <div className="item_info_title item_info_title_up">图标:</div>
                        <div>
                            <Input className="item_info_input_long item_info_input_nopadding" placeholder="图标的链接"
                                allowClear={true} ref="item_logo" defaultValue={strLogoUrl} onChange={this.LogoInputChange} />
                            <Upload className="item_image_upload" name="logo"
                                listType="picture-card" showUploadList={false}
                                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                beforeUpload={beforeUpload} onChange={this.UploadToBase64.bind(this, true)} >
                                {this.state.strLogoUrl ? <img src={this.state.strLogoUrl} alt="logo" style={{ width: '80px', height: 'auto' }} /> : uiUploadLogoButton}
                            </Upload>
                        </div>
                    </div>
                    <div className="item_info_row">
                        <div className="item_info_title item_info_title_up">图片:</div>
                        <div>
                            <Input className="item_info_input_long item_info_input_nopadding" placeholder="图片的链接"
                                allowClear={true} ref="item_image" defaultValue={strImageUrl} onChange={this.ImageInputChange} />
                            <Upload className="item_image_upload" name="image"
                                listType="picture-card" showUploadList={false}
                                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                beforeUpload={beforeUpload} onChange={this.UploadToBase64.bind(this, false)} >
                                {this.state.strImgUrl ? <img src={this.state.strImgUrl} alt="logo" style={{ width: '160px', height: 'auto' }} /> : uiUploadImageButton}
                            </Upload>
                        </div>
                    </div>
                </Modal>
            );
        }
        // 删除对话框
        if (this.state.iDlgType === 2) {
            strDlgTitle = "删除选中商品";
            let strDeleteItems = "是否删除：";
            for (let i = 0; i < this.arrDeleteName.length; i++) {
                strDeleteItems += "<" + this.arrDeleteName[i] + ">";
                if (i !== this.arrDeleteName.length - 1)
                    strDeleteItems += ", ";
            }
            strDeleteItems += " 等商品";
            uiDlg = (
                <Modal title={strDlgTitle} visible={this.state.bDlgShow} okText="确定" cancelText="取消"
                    onOk={this.ConfirmButtonClick} onCancel={this.CancelButtonClick} >
                    {strDeleteItems}
                </Modal>
            );
        }
        // 团购设置对话框
        if (this.state.iDlgType === 3) {
            uiCutPriceDlg = (
                <Modal title={strDlgTitle} visible={this.state.bDlgShow} okText="确定" cancelText="取消"
                    width={'50vw'} centered={true}
                    onOk={this.ConfirmButtonClick} onCancel={this.CancelButtonClick} >
                    <div className="cut_price_setting">
                        人数:　<Input className="item_info_input" ref="cut_price_num" placeholder="　必填……" defaultValue={this.objCurrentCutPrice.num} />
                            价位:　<Input className="item_info_input" ref="cut_price_value" placeholder="　必填……" defaultValue={this.objCurrentCutPrice.price} />
                            截止日期:　 <DatePicker className="order_date_pickup" onChange={this.ExpireDateChange}
                            value={moment(this.state.expire_date, dateFormat)}
                            defaultValue={moment(this.strToday, dateFormat)} />
                    </div>
                </Modal>
            );
        }
        // 批量编辑
        if (this.state.iDlgType === 4) {
            // 商品状态下拉菜单
            const uiItemStatus = (
                <Menu>
                    {this.arrItemStatus.map((item, index) => {
                        return (
                            <Menu.Item key={index} onClick={this.DlgItemTypeClick.bind(this, index)}>
                                {item}
                            </Menu.Item>
                        );
                    })}
                </Menu>
            );
            console.log("render : ", this.state.arrMultiEdit);
            uiDlg = (
                <Modal title={"批量编辑商品信息"} visible={this.state.bMultiDlgShow} okText="确定" cancelText="取消" width={350}
                    onOk={this.ConfirmButtonClick} onCancel={this.CancelButtonClick} >
                    <div className="item_info_row">
                        <Checkbox onChange={this.MultiEditCheckboxChange.bind(this, 0)} style={{ marginRight: '10px' }} checked={this.state.arrMultiEdit[0]} />
                        <div className="item_info_title">状态:</div>
                        <Dropdown className="item_info_input" overlay={uiItemStatus} placement="bottomLeft" style={{ width: '200px' }}>
                            <Button style={{ width: '200px' }}>
                                {this.arrItemStatus[this.state.iCurrentItemStatus]} <Icon type="caret-down" />
                            </Button>
                        </Dropdown>
                    </div>
                    <div className="item_info_row">
                        <Checkbox onChange={this.MultiEditCheckboxChange.bind(this, 1)} style={{ marginRight: '10px' }} checked={this.state.arrMultiEdit[1]} />
                        <div className="item_info_title">库存:</div><Input className="item_info_input" ref="item_inventory" style={{ width: '200px' }}
                            defaultValue={objItem.inventory} />
                    </div>
                    <div className="item_info_row">
                        <Checkbox onChange={this.MultiEditCheckboxChange.bind(this, 2)} style={{ marginRight: '10px' }} checked={this.state.arrMultiEdit[2]} />
                        <div className="item_info_title">类别:</div>
                        <Select mode="multiple" className="item_info_input_nopadding" style={{ width: '200px' }} placeholder="可多选" labelInValue={false}
                            onChange={this.TagSelectChange} value={this.state.arrSelectedTypes} >{this.arrItemOptions}</Select>
                    </div>
                    <div className="item_info_row">
                        <Checkbox onChange={this.MultiEditCheckboxChange.bind(this, 3)} style={{ marginRight: '10px' }} checked={this.state.arrMultiEdit[3]} />
                        <div className="item_info_title">折扣:</div>
                        <Input className="item_info_input" ref="item_discount" style={{ width: '200px', minWidth: '200px', maxWidth: '200px' }}
                            placeholder="例：打九折请输入90"
                            defaultValue={objItem.discount} />
                    </div>
                </Modal>
            );
        }

        // 表格分页属性
        const paginationProps = {
            showSizeChanger: true,
            showQuickJumper: false,
            showTotal: () => `共 ${this.arrTableRow.length} 个商品/服务`,
            defaultPageSize: 10,
            defaultCurrent: this.state.iCurrentPage,
            pageSizeOptions: ['10', '20', '30', '40', '50', '100'],
            total: this.arrTableRow.length,
            position: 'bottom'
        };
        let uiItemEditArea1 = null;
        let uiItemEditArea2 = null;
        let uiItemEditArea3 = null;
        // if (this.props.strCurrentUserName.indexOf('_a') < 0) {
        uiItemEditArea1 = (
            <div /*className="manage_title_button_right_side"*/>
                <Button className="manage_contents_title_margin" style={strNewButtonStyle}
                    onClick={this.ShowDlg.bind(this, 0)} disabled={this.state.btnControlDisable[0]}>
                    <Icon type="plus" />新建</Button>
                <Button className="manage_contents_title_margin" type="primary" onClick={this.ShowDlg.bind(this, 1)} disabled={this.state.btnControlDisable[1]}>
                    <Icon type="edit" />编辑</Button>
                <Button className="manage_contents_title_margin" type="primary" onClick={this.ShowDlg.bind(this, 4)} disabled={this.state.btnControlDisable[2]}>
                    <Icon type="edit" />批量编辑</Button>
                {/* <Button className="manage_contents_title_margin" type="primary" onClick={this.ShowDlg.bind(this, 3)} disabled={this.state.btnControlDisable[1]}>
                        <Icon type="shopping" /> 团购</Button> */}
                <Button className="manage_contents_title_margin" type="danger" onClick={this.ShowDlg.bind(this, 2)} disabled={this.state.btnControlDisable[2]}>
                    <Icon type="close" /> 删除</Button>
            </div>
        );
        uiItemEditArea2 = (
            <div className="manage_title_button_right_side" style={{ marginLeft: '20px', marginRight: '20px' }}>
                <Button className="manage_contents_title_margin" style={strCopyButtonStyle}
                    onClick={this.CopyItem} disabled={this.state.btnCopyPasteDisable[0]}>
                    <Icon type="copy" />复制</Button>
                <Button className="manage_contents_title_margin" type="primary" onClick={this.PasteItem} disabled={this.state.btnCopyPasteDisable[1]}>
                    <Icon type="edit" /> 粘贴</Button>
                {/* <Button className="manage_contents_title_margin" type="primary" onClick={this.UploadItemList} >
                        <Icon type="upload" />上传</Button> */}
            </div>
        );
        uiItemEditArea3 = (
            <div className="manage_title_button_right_side">
                <Button className="manage_contents_title_margin export_button_style" style={{ marginRight: '20px' }} onClick={this.ClickExportButton}>
                    <Icon type="export" />导出商品列表</Button>
                <div className="store_item_title_text">上传：</div>
                <input className="manage_contents_title_margin" type='file' accept='.xlsx, .xls' onChange={this.UploadItemList} ref="file_upload" id="file_upload" />
            </div>
        );
        // }
        let uiLoading = null;
        let uiTable = null;
        if (this.state.bLoading) {
            uiLoading = (<Spin className="manage_empty_loading" size="large" tip="加载中……" />);
        } else {
            uiTable = (
                <Table className="item_table" dataSource={this.arrTableRow} pagination={paginationProps}
                    rowSelection={rowSelection} columns={arrTableHeader}
                    onRow={(record) => ({ onClick: () => { this.selectRow(record); } })} />
            );
        }

        return (
            <div className="store_manage_contents store_manage_top_space_double">
                {/* 商店管理页面控制栏 */}
                <div className="store_contents_title">
                    <div className="store_item_title_text">类别：</div>
                    <Dropdown className="manage_contents_title_margin manage_stretch_right"
                        overlay={uiItemTypeMenu} placement="bottomLeft">
                        <Button>
                            {this.arrItemTypes[this.state.objFilter.iCurrentItemType]}
                            <Icon type="caret-down" />
                        </Button>
                    </Dropdown>
                    <Input className="store_item_input" placeholder="搜索商品ID/名称" ref="store_item_input" />
                    <Button className="manage_stretch_right" type="primary"
                        onClick={this.StoreItemSearch}>搜索</Button>
                    <Button className="manage_stretch_left manage_refresh_button" type="primary"
                        onClick={this.ShowAllItems}>
                        <Icon type="redo" />
                    </Button>
                </div>
                <div className="store_contents_title_sec">
                    {uiItemEditArea1}
                    {uiItemEditArea2}
                    {uiItemEditArea3}
                </div>
                {uiLoading}
                {/* 表格主体 */}
                {uiTable}
                {/* 新建/编辑/删除弹窗 */}
                {uiDlg}
                {uiMultiDlg}
                {uiCutPriceDlg}
            </div>
        );
    }
}
export default StoreItemManagement;
