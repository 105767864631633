import React, { Component } from "react";

import Modal from 'antd/es/modal';
import Input from 'antd/es/input';

import '../../../styles/Background/UI_Module/customs_file.css';
import '../../../styles/Background/UI_Module/invoice_file.css';
import { message } from "antd";

// const { TextArea } = Input;

class PDFInvoice extends Component {
    constructor(props) {
        super();
        this.state = {
            bEditing: false,
            bBillToEditDlg: false,
            bInvoiceInfoEditDlg: false,
            bItemListEditDlg: false,
            bBalanceDueEditDlg: false
        };

        this.iSelectedItem = -1;
        this.iEditItem = 0;
    }
    // 点击BillTo区域
    BillToAreaClicked = () => {
        this.setState({
            ...this.state,
            bBillToEditDlg: true
        });
    }
    // BillTo信息确认/取消
    BillToInfoConfirmCancel = (bConfirm) => {
        if (bConfirm) {
            if (this.refs.bill_to_name)
                this.props.objCurrentInvoice.bill_to_name = this.refs.bill_to_name.state.value;
            if (this.refs.bill_to_company)
                this.props.objCurrentInvoice.bill_to_company = this.refs.bill_to_company.state.value;
            if (this.refs.bill_to_address)
                this.props.objCurrentInvoice.bill_to_address = this.refs.bill_to_address.state.value;
        }
        this.setState({
            ...this.state,
            bBillToEditDlg: false
        });
    }
    // 点击Invoice信息区域
    InvoiceInfoAreaClicked = () => {
        this.setState({
            ...this.state,
            bInvoiceInfoEditDlg: true
        });
    }
    // Invoice信息确认/取消
    InvoiceInfoConfirmCancel = (bConfirm) => {
        if (bConfirm) {
            if (this.refs.invoice_no)
                this.props.objCurrentInvoice.invoice_no = this.refs.invoice_no.state.value;
            if (this.refs.invoice_date)
                this.props.objCurrentInvoice.invoice_date = this.refs.invoice_date.state.value;
            if (this.refs.due_date)
                this.props.objCurrentInvoice.due_date = this.refs.due_date.state.value;
        }
        this.setState({
            ...this.state,
            bInvoiceInfoEditDlg: false
        });
    }
    // 点击货物明细区域
    ItemListAreaClicked = (index) => {
        this.iSelectedItem = index;
        if (this.iSelectedItem >= 0) {
            this.iEditItem = 2;
            if (this.refs.item_type) {
                this.refs.item_type.state.value = this.props.arrItemList[this.iSelectedItem].type;
                this.refs.item_description.state.value = this.props.arrItemList[this.iSelectedItem].description;
                this.refs.item_qty.state.value = this.props.arrItemList[this.iSelectedItem].qty;
                this.refs.item_rate.state.value = this.props.arrItemList[this.iSelectedItem].rate;
            }
        } else {
            this.iEditItem = 1;
            if (this.refs.item_type) {
                this.refs.item_type.state.value = "";
                this.refs.item_description.state.value = "";
                this.refs.item_qty.state.value = "";
                this.refs.item_rate.state.value = "";
            }
        }
        this.setState({
            ...this.state,
            bItemListEditDlg: true
        });
    }
    // 货物明细信息确认/取消
    ItemListConfirmCancel = (bConfirm) => {
        if (bConfirm) {
            if (this.refs.item_type) {
                // 新建一个物品的信息
                if (this.iEditItem === 1) {
                    let strType = this.refs.item_type.state.value;
                    let strDescription = this.refs.item_description.state.value;
                    let fQty = this.refs.item_qty.state.value;
                    let fRate = this.refs.item_rate.state.value;
                    let fAmount = parseFloat(fQty) * parseFloat(fRate);
                    let objItemDetails = {
                        type: strType,
                        description: strDescription,
                        qty: parseFloat(fQty).toFixed(4),
                        rate: parseFloat(fRate).toFixed(4),
                        amount: parseFloat(fAmount).toFixed(4),
                    }
                    this.props.arrItemList.push(objItemDetails);
                }
                // 编辑一个物品的信息
                if (this.iEditItem === 2) {
                    this.props.arrItemList[this.iSelectedItem].type = this.refs.item_type.state.value;
                    this.props.arrItemList[this.iSelectedItem].description = this.refs.item_description.state.value;
                    this.props.arrItemList[this.iSelectedItem].qty = this.refs.item_qty.state.value;
                    this.props.arrItemList[this.iSelectedItem].rate = this.refs.item_rate.state.value;
                    this.props.arrItemList[this.iSelectedItem].amount = parseFloat(this.props.arrItemList[this.iSelectedItem].qty) *
                        parseFloat(this.props.arrItemList[this.iSelectedItem].rate);
                    this.props.arrItemList[this.iSelectedItem].qty = parseFloat(this.props.arrItemList[this.iSelectedItem].qty).toFixed(4);
                    this.props.arrItemList[this.iSelectedItem].rate = parseFloat(this.props.arrItemList[this.iSelectedItem].rate).toFixed(4);
                    this.props.arrItemList[this.iSelectedItem].amount = parseFloat(this.props.arrItemList[this.iSelectedItem].amount).toFixed(4);
                }
            }
        }
        this.iEditItem = 0;
        this.setState({
            ...this.state,
            bItemListEditDlg: false
        });
    }
    // 点击Balance Due区域
    BalanceDueClicked = () => {
        this.setState({
            ...this.state,
            bBalanceDueEditDlg: true
        });
    }
    // Balance Due 数值确认/取消
    BalanceDueConfirmCancel = (bConfirm) => {
        if (bConfirm) {
            if (this.refs.balance_due)
                this.props.objCurrentInvoice.balance_due = this.refs.balance_due.state.value;
        }
        this.setState({
            ...this.state,
            bBalanceDueEditDlg: false
        });
    }
    // 删除一行货物明细
    DeleteItem = () => {
        message.info("指定的货物明细已经删除！");
        this.props.arrItemList.splice(this.iSelectedItem, 1);
        this.iSelectedItem = -1;
        this.setState({
            ...this.state,
            bItemListEditDlg: false
        });
    }

    // 渲染完毕……
    componentDidMount() {

    }

    render() {

        // Invoice Title Area
        let uiInvoiceTitle = (
            <div className="invoice_title_area">
                <div className="invoice_company_info_area">
                    <div className="invoice_company_title">
                        LEKON LOGISTICS AND TRADING CO.,LTD.
                    </div>
                    <div className="invoice_company_info_row">
                        6211 Westminster Hwy Unit 130, Richmond BC V7C 4V4
                    </div>
                    <div className="invoice_company_info_row">
                        +1 7789520886
                    </div>
                    <div className="invoice_company_info_row">
                        csr@lekonlogistics.com
                    </div>
                    <div className="invoice_company_info_row">
                        Business Number 739617470
                    </div>
                </div>
                <img className="invoice_logo" alt=''
                    src={"https://res.cloudinary.com/jingtu-ego-test/image/upload/v1609136102/0-%E9%B2%B8%E5%9B%BEWhaleMap/LekonLogo_t6own9.png"} />
            </div>
        );
        // Invoice Blue
        let uiInvoiceBlue = (
            <div className="invoice_blue">INVOICE</div>
        );
        // Invoice Main Info Area
        let uiBillToEditDlg = (
            <Modal title="编辑 BILL TO 信息" visible={this.state.bBillToEditDlg} width={'35%'}
                onOk={this.BillToInfoConfirmCancel.bind(this, true)} onCancel={this.BillToInfoConfirmCancel.bind(this, false)}
                okText={"确定"} cancelText={"取消"}>
                <div className="invoice_billto_info_title">Name:</div>
                <Input className="invoice_billto_info_title invoice_billto_info_title_input" ref="bill_to_name" defaultValue={this.props.objCurrentInvoice.bill_to_name} />
                <div className="invoice_billto_info_title">Company:</div>
                <Input className="invoice_billto_info_title invoice_billto_info_title_input" ref="bill_to_company" defaultValue={this.props.objCurrentInvoice.bill_to_company} />
                <div className="invoice_billto_info_title">Address:</div>
                <Input className="invoice_billto_info_title invoice_billto_info_title_input" ref="bill_to_address" defaultValue={this.props.objCurrentInvoice.bill_to_address} />
            </Modal>
        );
        let uiInvoiceInfoEditDlg = (
            <Modal title="编辑 Invoice 信息" visible={this.state.bInvoiceInfoEditDlg} width={'30%'}
                onOk={this.InvoiceInfoConfirmCancel.bind(this, true)} onCancel={this.InvoiceInfoConfirmCancel.bind(this, false)}
                okText={"确定"} cancelText={"取消"}>
                <div className="invoice_billto_info_title">Invoice No:</div>
                <Input className="invoice_billto_info_title invoice_billto_info_title_input" ref="invoice_no" defaultValue={this.props.objCurrentInvoice.invoice_no} />
                <div className="invoice_billto_info_title">Date:</div>
                <Input className="invoice_billto_info_title invoice_billto_info_title_input" ref="invoice_date" defaultValue={this.props.objCurrentInvoice.invoice_date} />
                <div className="invoice_billto_info_title">Due Date:</div>
                <Input className="invoice_billto_info_title invoice_billto_info_title_input" ref="due_date" defaultValue={this.props.objCurrentInvoice.due_date} />
            </Modal>
        );
        let uiBillToName = null;
        let uiBillToCompany = null;
        let uiBillToAddress = null;
        uiBillToName = (
            <div className="invoice_billto_info_row">
                {this.props.objCurrentInvoice.bill_to_name}
            </div>
        );
        uiBillToCompany = (
            <div className="invoice_billto_info_row">
                {this.props.objCurrentInvoice.bill_to_company}
            </div>
        );
        uiBillToAddress = (
            <div className="invoice_billto_info_row">
                {this.props.objCurrentInvoice.bill_to_address}
            </div>
        );
        if (this.props.objCurrentInvoice.bill_to_name === "" && this.props.objCurrentInvoice.bill_to_company === "" && this.props.objCurrentInvoice.bill_to_address === "")
            uiBillToName = (
                <div className="invoice_billto_info_row" style={{ fontSize: '7mm' }}>
                    双击编辑BillTo信息
                </div>
            );
        let uiInvoiceNo = null;
        let uiInvoiceDate = null;
        let uiInvoiceDueDate = null;
        uiInvoiceNo = (
            <div className="invoice_main_info_content">
                {this.props.objCurrentInvoice.invoice_no}
            </div>
        );
        uiInvoiceDate = (
            <div className="invoice_main_info_content">
                {this.props.objCurrentInvoice.invoice_date}
            </div>
        );
        uiInvoiceDueDate = (
            <div className="invoice_main_info_content">
                {this.props.objCurrentInvoice.due_date}
            </div>
        );
        if (this.props.objCurrentInvoice.invoice_no === "" && this.props.objCurrentInvoice.invoice_date === "" && this.props.objCurrentInvoice.due_date === "")
            uiInvoiceNo = (
                <div className="invoice_billto_info_row" style={{ fontSize: '7mm' }}>
                    双击编辑Invoice信息
                </div>
            );
        let InvoiceMainInfoArea = (
            <div className="invoice_main_info_area">
                <div className="invoice_billto_area" onDoubleClick={this.BillToAreaClicked}>
                    <div className="invoice_billto_title">
                        BILL TO
                    </div>
                    {uiBillToName}
                    {uiBillToCompany}
                    {uiBillToAddress}
                </div>
                {uiBillToEditDlg}
                <div className="invoice_main_info" onDoubleClick={this.InvoiceInfoAreaClicked}>
                    <div className="invoice_main_info_row">
                        <div className="invoice_main_info_title">
                            INVOICE #
                        </div>
                        {uiInvoiceNo}
                    </div>
                    <div className="invoice_main_info_row">
                        <div className="invoice_main_info_title">
                            DATE
                        </div>
                        {uiInvoiceDate}
                    </div>
                    <div className="invoice_main_info_row">
                        <div className="invoice_main_info_title">
                            DUE DATE
                        </div>
                        {uiInvoiceDueDate}
                    </div>
                    <div className="invoice_main_info_row">
                        <div className="invoice_main_info_title">
                            TERMS
                        </div>
                        <div className="invoice_main_info_content">
                            DUE ON RECEIPT
                        </div>
                    </div>
                </div>
                {uiInvoiceInfoEditDlg}
            </div>
        );
        // Invoice Details Title
        let uiInvoiceDetailsTitle = (
            <div className="invoice_details_area">
                <div className="invoice_details_title" onDoubleClick={this.ItemListAreaClicked.bind(this, -1)}>
                    <div className="invoice_details_item_type">
                    </div>
                    <div className="invoice_details_item_description">
                        DESCRIPTION
                    </div>
                    <div className="invoice_details_item_qty">
                        QTY
                    </div>
                    <div className="invoice_details_item_rate">
                        RATE
                    </div>
                    <div className="invoice_details_item_amount">
                        AMOUNT
                    </div>
                </div>
            </div>
        );
        // Item List Edit Dlg
        let uiDeleteItem = null;
        let strItemType = "", strItemDescription = "", strItemQty = "", strItemRate = "";
        if (this.iSelectedItem >= 0) {
            strItemType = this.props.arrItemList[this.iSelectedItem].type;
            strItemDescription = this.props.arrItemList[this.iSelectedItem].description;
            strItemQty = this.props.arrItemList[this.iSelectedItem].qty;
            strItemRate = this.props.arrItemList[this.iSelectedItem].rate;
            uiDeleteItem = (
                <div className="invoice_billto_info_title invoice_item_delete_button" onClick={this.DeleteItem}>
                    删除该货物明细</div>
            );
        }
        let uiItemListEditDlg = (
            <Modal title="编辑 货物明细" visible={this.state.bItemListEditDlg} width={'30%'}
                onOk={this.ItemListConfirmCancel.bind(this, true)} onCancel={this.ItemListConfirmCancel.bind(this, false)}
                okText={"确定"} cancelText={"取消"}>
                <div className="invoice_billto_info_title">Type:</div>
                <Input className="invoice_billto_info_title invoice_billto_info_title_input" ref="item_type" defaultValue={strItemType} />
                <div className="invoice_billto_info_title">Description:</div>
                <Input className="invoice_billto_info_title invoice_billto_info_title_input" ref="item_description" defaultValue={strItemDescription} />
                <div className="invoice_billto_info_title">Qty:</div>
                <Input className="invoice_billto_info_title invoice_billto_info_title_input" ref="item_qty" defaultValue={parseFloat(strItemQty).toFixed(2)} />
                <div className="invoice_billto_info_title">Rate:</div>
                <Input className="invoice_billto_info_title invoice_billto_info_title_input" ref="item_rate" defaultValue={parseFloat(strItemRate).toFixed(2)} />
                {uiDeleteItem}
            </Modal>
        );
        // Invoice Details Rows
        let uiInvoiceDetailsRows = (
            <div className="invoice_details_area_rows" style={{ paddingTop: '5mm', fontSize: '7mm' }}
                onDoubleClick={this.ItemListAreaClicked.bind(this, -1)}>
                提示：双击蓝色表头可以 "新建" 货物明细，双击已有的货物明细可以"修改"
                {uiItemListEditDlg}
            </div>);
        let fTotalPayment = 0.0;
        if (this.props.arrItemList.length > 0) {
            uiInvoiceDetailsRows = (
                <div className="invoice_details_area_rows" >
                    {this.props.arrItemList.map((item, index) => {
                        fTotalPayment += parseFloat(item.amount);
                        return (
                            <div className="invoice_details_row" key={index} onDoubleClick={this.ItemListAreaClicked.bind(this, index)}>
                                <div className="invoice_details_item_type">
                                    {item.type}
                                </div>
                                <div className="invoice_details_item_description invoice_details_row_text">
                                    {item.description}
                                </div>
                                <div className="invoice_details_item_qty invoice_details_row_text">
                                    {parseFloat(item.qty).toFixed(2)}
                                </div>
                                <div className="invoice_details_item_rate invoice_details_row_text">
                                    {parseFloat(item.rate).toFixed(2)}
                                </div>
                                <div className="invoice_details_item_amount invoice_details_row_text">
                                    {parseFloat(item.amount).toFixed(2)}
                                </div>
                            </div>
                        );
                    })}
                    {uiItemListEditDlg}
                </div>
            );
            this.props.objCurrentInvoice.total_payment = fTotalPayment;
        }
        // Balance Due
        let uiBalanceDueEditDlg = (
            <Modal title="编辑 BALANCE DUE 信息" visible={this.state.bBalanceDueEditDlg} width={'20%'}
                onOk={this.BalanceDueConfirmCancel.bind(this, true)} onCancel={this.BalanceDueConfirmCancel.bind(this, false)}
                okText={"确定"} cancelText={"取消"}>
                <div className="invoice_billto_info_title">Balance Due:</div>
                <Input className="invoice_billto_info_title invoice_billto_info_title_input" ref="balance_due" defaultValue={this.props.objCurrentInvoice.balance_due} />
            </Modal>
        );
        // Invoice Details Bottom Area
        let uiInvoiceDetailsBottom = (
            <div className="invoice_details_bottom_area">
                <div className="invoice_details_bottom_type">
                    <b>E-transfer:</b><br />
                    lekoninternational@gmail.com<br /><br />
                    <b>Bank Information:</b><br />
                    Canadian Imperial Bank of Commerce<br />
                    Transit number: 03400<br />
                    Institution number: 010<br />
                    Account number: 1056913<br />
                    7170 Kingsway, Burnaby BC V5E1E8<br />
                </div>
                <div className="invoice_details_item_description invoice_details_row_text" onDoubleClick={this.BalanceDueClicked}>
                    PAYMENT<br />
                    BALANCE DUE
                </div>
                <div className="invoice_details_item_qty invoice_details_row_text" />
                <div className="invoice_details_item_rate invoice_details_row_text" />
                <div className="invoice_details_item_amount invoice_details_row_text" onDoubleClick={this.BalanceDueClicked}>
                    {parseFloat(fTotalPayment).toFixed(2)}<br />
                    <div className="invoice_details_total_amount_text">${parseFloat(this.props.objCurrentInvoice.balance_due).toFixed(2)}</div>
                </div>
                {uiBalanceDueEditDlg}
            </div >
        );
        // // Invoice Bottom Info Area
        // let uiInvoiceBottomInfoArea = (
        //     <div style={{ position: 'reletive' }}>
        //         <div className="invoice_bottom_info_area">
        //             业务范围：<br />
        //         海运 / 空运 / 快递 / 北美内陆运输 / 采购<br />
        //         Service:<br />
        //         Ocean Freight / Air Freight / Global Express / North America Inland Trucking / China Sourcing<br />
        //         For General Inquiries：<br />
        //         Email: csr@lekonlogistics.com<br />
        //         Cell Phone: (778) 952 0886<br />
        //         </div>
        //     </div>
        // );
        // Invoice PDF Body
        let uiInvoicePdf = (
            <div className="pdf_area" id={"invoice"} >
                <img className="invoice_bgp" alt=''
                    src={"https://res.cloudinary.com/jingtu-ego-test/image/upload/v1609542715/0-%E9%B2%B8%E5%9B%BEWhaleMap/InvoiceBGP_jrpoel.jpg"} />
                <div className="invoice_pdf_area">
                    {uiInvoiceTitle}
                    {uiInvoiceBlue}
                    {InvoiceMainInfoArea}
                    {uiInvoiceDetailsTitle}
                    {uiInvoiceDetailsRows}
                    <div className="invoice_details_bottom_space" />
                    {uiInvoiceDetailsBottom}
                </div>
            </div>
        );



        return (
            <div className="">
                {uiInvoicePdf}
            </div>
        );
    }
}
export default PDFInvoice;
